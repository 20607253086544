import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiLocation,
  ApiSpace,
  ApiVenue,
  ApiVenueSummary,
  CreateApiEventOccurrence,
  UpdateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAfter, lastDayOfMonth, subHours } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { StyledDatePicker } from "../../../../components/inputs/StyledDatePicker";
import { VenueAutocomplete } from "../../../../components/selects/VenueAutocomplete";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  EventOccurrenceUpdateProps,
  removeQuestionResponse,
  updateEventDetailLastUpdated,
  updateSingleEventOccurrence,
} from "../../../../store/events/event-details.slice";
import { setIsConflictsModalOpen } from "../../../../store/events/event-modal.slice";
import { createUpdateEventComment } from "../../../../store/events/thunks/eventComments.thunk";
import { addTwoHours } from "../../../../utils/addTwoHoursToDate";
import { compareTwoDates } from "../../../../utils/datesValidation";
import { filterAllowedDays } from "../../../../utils/filterAllowedDays";
import { filterAllowedTime } from "../../../../utils/filterAllowedTime";
import { getAllUniqueChildObjects } from "../../../../utils/getAllUniqueChildObjects";
import { getId } from "../../../../utils/getId";
import { getNearestVenueDate } from "../../../../utils/getNearestVenueDate";
import { getObjectArraysDifference } from "../../../../utils/getObjectArraysDifference";
import { getVenueTimes } from "../../../../utils/getVenueTimes";
import {
  addTwoHoursToEndDate,
  updateTimes,
} from "../../../../utils/updateTimes";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { CommentForm } from "../../../request-form/comments/CommentForm";
import { SpacesForm } from "../../spaces/SpaceForm";
import { NoAvailableImage } from "../../spaces/SpaceFormViews";
import { ConflictAlert } from "../form-components/ConflictsAlert";
import { LimitVenueInfo } from "../form-components/LimitVenueInfo";

interface EditSingleOccurrenceProps {
  eventId: string;
  occurrence: EventOccurrenceUpdateProps;
  onClose: () => void;
}

const DEFAULT_START_HOUR = 8;
const DEFAULT_END_HOUR = 10;
const DEFAULT_MINUTE_SECONDS = 0;
export const getDefaultDates = () => {
  const now = new Date();
  now.setHours(DEFAULT_START_HOUR);
  now.setMinutes(DEFAULT_MINUTE_SECONDS);
  now.setSeconds(DEFAULT_MINUTE_SECONDS);
  const lastDayOfTheMonth = lastDayOfMonth(now);
  lastDayOfTheMonth.setHours(DEFAULT_END_HOUR);
  lastDayOfTheMonth.setMinutes(DEFAULT_MINUTE_SECONDS);
  lastDayOfTheMonth.setSeconds(DEFAULT_MINUTE_SECONDS);
  return { initStartDate: now, initEndDate: lastDayOfTheMonth };
};

const HOURS_FORMAT = "hh:mmaa";
export const EditSingleOccurrence: FC<EditSingleOccurrenceProps> = ({
  eventId,
  occurrence,
  onClose,
}) => {
  const errorColor = useColorModeValue("red.500", "red.300");
  const [start, setStart] = useState(new Date(occurrence.start));
  const [startHour, setStartHour] = useState(new Date(occurrence.start));
  const [endHour, setEndHour] = useState(new Date(occurrence.end));
  const [venue, setVenue] = useState<ApiVenueSummary | null>(occurrence.venue);
  const [venueDetail, setVenueDetail] = useState<ApiVenue>();

  const [conflict, setConflict] = useState(occurrence.conflicts);
  const [spaces, setSpaces] = useState(occurrence.spaces);
  const [currentComment, setCurrentComment] = useState<ApiComment>();
  const [venueStartValidHour, setVenueStartHour] = useState<Date | null>();
  const [venueEndValidHour, setVenueEndHour] = useState<Date | null>();

  const { event, eventDates } = useSelector(
    (state: RootState) => state.eventDetails
  );

  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const dispatch = useDispatch();

  const isDesktop = useBreakpointValue({
    base: false,
    sm: true,
    md: true,
    lg: true,
  });
  const iconColor = useColorModeValue("gray.500", "white");
  const { limitAllEventsByVenueHours } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );
  const { isOpen, onOpen, onClose: closeSpacesModal } = useDisclosure();

  const mapOccurrenceValues = useCallback(() => {
    if (!venue) return;
    const newStart = updateTimes(start, startHour);
    const newEnd = updateTimes(start, endHour);
    const occurrenceToUpdate: UpdateApiEventOccurrence & { id: string } = {
      id: occurrence.id,
      start: newStart.toISOString(),
      end: newEnd.toISOString(),
      venue: venue.id,
      spaces: spaces,
    };
    return occurrenceToUpdate;
  }, [endHour, occurrence.id, spaces, start, startHour, venue]);

  const validateHours = useCallback(() => {
    return compareTwoDates(startHour, endHour);
  }, [endHour, startHour]);

  const invalidHours = useMemo(() => validateHours(), [validateHours]);

  const handleOnCheckConflicts = useCallback(async () => {
    if (!venue) return;

    const occurrenceToCheck = {
      id: occurrence.id,
      start: startHour.toISOString(),
      end: endHour.toISOString(),
      venue: venue.id,
      spaces: spaces.map((s) => s.id),
    };
    const [first] = await apiClient.checkEventConflicts(currentAccount.id, [
      occurrenceToCheck,
    ]);
    setConflict(first);
  }, [
    apiClient,
    currentAccount.id,
    occurrence.id,
    startHour,
    endHour,
    venue,
    spaces,
  ]);

  const saveComment = useCallback(
    (comment: string) => {
      if (!eventId) return;
      thunkDispatch(
        createUpdateEventComment({
          apiClient,
          accountId: currentAccount.id,
          eventId: eventId,
          commentId: undefined,
          isPublic: true,
          commentText: comment,
        })
      );
    },
    [apiClient, currentAccount.id, eventId, thunkDispatch]
  );

  const handleDeleteResponses = useCallback(
    (
      newOccurrence: Partial<
        Omit<CreateApiEventOccurrence, "scheduledJobs">
      > & {
        id: string;
      }
    ) => {
      const remainingOccurrences: EventOccurrenceUpdateProps[] = [];
      let oldOccurrence: EventOccurrenceUpdateProps | null = null;
      eventDates.forEach((eventDate) => {
        if (eventDate.id === newOccurrence.id && eventDate.active) {
          oldOccurrence = eventDate;
        } else if (eventDate.active) {
          remainingOccurrences.push(eventDate);
        }
      });

      let venueResponseToRemove: string | undefined = undefined;

      if (
        newOccurrence.venue &&
        occurrence.venue.id !== getId(newOccurrence.venue)
      ) {
        venueResponseToRemove = !remainingOccurrences.some(
          (date) => date.venue.id === occurrence.venue.id
        )
          ? occurrence.venue.id
          : undefined;
      }

      // Check what spaces need to be deleted
      const chagedSpaces = oldOccurrence
        ? (oldOccurrence as EventOccurrenceUpdateProps).spaces.filter(
            (space) => {
              if (!newOccurrence.spaces) return false;
              return newOccurrence.spaces.every((s) => getId(s) !== space.id);
            }
          )
        : [];

      const oldSpaces = getAllUniqueChildObjects(
        remainingOccurrences,
        "spaces"
      );
      let spacesResponseToRemove: string[] = chagedSpaces
        ? getObjectArraysDifference(chagedSpaces, oldSpaces).map((o) => o.id)
        : [];

      // Remove the venue
      if (event && venueResponseToRemove) {
        apiClient
          .bulkDeleteQuestionResponse(currentAccount.id, {
            eventId: event.id,
            venueIds: venueResponseToRemove,
          })
          .then((response) => {
            dispatch(removeQuestionResponse(response));
          });
      }

      // Remove the spaces
      if (event && spacesResponseToRemove.length > 0) {
        apiClient
          .bulkDeleteQuestionResponse(currentAccount.id, {
            eventId: event.id,
            spaceIds: spacesResponseToRemove,
          })
          .then((response) => {
            dispatch(removeQuestionResponse(response));
          });
      }
    },
    [
      apiClient,
      currentAccount.id,
      dispatch,
      event,
      eventDates,
      occurrence.venue.id,
    ]
  );

  const handleOnSave = useCallback(() => {
    const occurrenceToUpdate = mapOccurrenceValues();
    occurrenceToUpdate && handleDeleteResponses(occurrenceToUpdate);
    occurrenceToUpdate &&
      thunkDispatch(
        updateSingleEventOccurrence({
          apiClient,
          accountId: currentAccount.id,
          eventId,
          occurrenceId: occurrence.id,
          occurrence: occurrenceToUpdate,
          conflicts: conflict,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          res.response.updated &&
            dispatch(
              updateEventDetailLastUpdated({
                lastUpdated: res.response.updated,
                updatedBy: currentUser,
              })
            );
          currentComment &&
            currentComment.comment !== "" &&
            saveComment(currentComment.comment);
          showToast("success", "Event occurrence updated successfully.");
          onClose();
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again!");
        });
  }, [
    apiClient,
    conflict,
    currentAccount.id,
    currentComment,
    currentUser,
    dispatch,
    eventId,
    handleDeleteResponses,
    mapOccurrenceValues,
    occurrence.id,
    onClose,
    saveComment,
    showToast,
    thunkDispatch,
  ]);

  const getVenueHours = useCallback(
    (selectedDate: Date | null) => {
      return getVenueTimes(selectedDate, venue);
    },
    [venue]
  );

  const setVenueHours = useCallback(
    (date: Date | undefined) => {
      const startDate = date ? date : start;
      const venueHours = getVenueHours(startDate);
      if (!venueHours) return;
      const {
        venueStartHour,
        venueStartMinutes,
        venueEndHour,
        venueEndMinutes,
      } = venueHours;

      const newDateCopy = new Date(startDate);
      newDateCopy.setHours(venueStartHour);
      newDateCopy.setMinutes(venueStartMinutes);
      date && setStartHour(newDateCopy);
      limitAllEventsByVenueHours && setVenueStartHour(newDateCopy);

      const endDateCopy = new Date(startDate);
      endDateCopy.setHours(venueEndHour);
      endDateCopy.setMinutes(venueEndMinutes);
      date && setEndHour(endDateCopy);
      limitAllEventsByVenueHours && setVenueEndHour(endDateCopy);
    },
    [getVenueHours, start, limitAllEventsByVenueHours]
  );

  const handleOnChangeStartDate = useCallback(
    (date: Date | null) => {
      if (!date) return;
      setStart(date);
      const newStartHour = updateTimes(startHour, date, false, false);
      const newEndHour = addTwoHoursToEndDate(newStartHour);

      setStartHour(newStartHour);
      setEndHour(newEndHour);

      limitAllEventsByVenueHours && setVenueHours(date);
    },
    [startHour, setVenueHours, limitAllEventsByVenueHours]
  );

  const setInitialDate = useCallback((newVenue: ApiVenueSummary | null) => {
    if (!newVenue) {
      setStart(getDefaultDates().initStartDate);
      return;
    }
    const nearestDate = getNearestVenueDate(newVenue);
    setStart(nearestDate);
  }, []);

  const onSelectedVenueChange = useCallback(
    (newVenue: ApiVenueSummary | null) => {
      if (newVenue === null) {
        return;
      }
      const date = getNearestVenueDate(newVenue);
      const venueHours = getVenueTimes(date, newVenue);

      if (!venueHours || !date) {
        return;
      }
      const {
        venueStartHour,
        venueStartMinutes,
        venueEndHour,
        venueEndMinutes,
      } = venueHours;

      if (Number.isNaN(venueStartHour) || Number.isNaN(venueStartMinutes)) {
        return;
      }

      const newDateCopy = new Date(date);
      newDateCopy.setHours(venueStartHour);
      newDateCopy.setMinutes(venueStartMinutes);
      setStartHour(newDateCopy);
      limitAllEventsByVenueHours && setVenueStartHour(newDateCopy);

      if (date) {
        if (Number.isNaN(date)) {
          const endDateCopy = new Date(date);
          const { newHours, newMinutes } = addTwoHours(
            venueStartHour,
            venueStartMinutes
          );
          endDateCopy.setHours(newHours);
          endDateCopy.setMinutes(newMinutes);
          setEndHour(endDateCopy);
          limitAllEventsByVenueHours && setVenueEndHour(endDateCopy);
          return;
        }

        const endDateCopy = new Date(date);
        endDateCopy.setHours(venueEndHour);
        endDateCopy.setMinutes(venueEndMinutes);
        setEndHour(endDateCopy);
        limitAllEventsByVenueHours && setVenueEndHour(endDateCopy);
      }
    },
    [limitAllEventsByVenueHours]
  );

  const handleOnChangeVenue = useCallback(
    (newVenue: ApiVenueSummary | null) => {
      setVenue(newVenue);
      setSpaces([]);
      limitAllEventsByVenueHours && onSelectedVenueChange(newVenue);
      limitAllEventsByVenueHours && setInitialDate(newVenue);
    },
    [onSelectedVenueChange, setInitialDate, limitAllEventsByVenueHours]
  );

  const handleOnAddSpaces = useCallback((values: ApiSpace[]) => {
    setSpaces(values);
  }, []);

  const filterDay = useCallback(
    (date: Date) => {
      return filterAllowedDays({ date, venue });
    },
    [venue]
  );

  const filterTime = useCallback(
    (time: Date) => {
      return filterAllowedTime(time, venueStartValidHour, venueEndValidHour);
    },
    [venueStartValidHour, venueEndValidHour]
  );

  const handleOnChangeStartHour = useCallback(
    (date: Date | null) => {
      if (!date) return;
      setStartHour(date);
      const newEndHour = addTwoHoursToEndDate(date);
      if (
        limitAllEventsByVenueHours &&
        venueEndValidHour &&
        newEndHour.getTime() > venueEndValidHour.getTime()
      ) {
        setEndHour(venueEndValidHour);
        return;
      }
      setEndHour(newEndHour);
    },
    [venueEndValidHour, limitAllEventsByVenueHours]
  );

  const handleOnChangeEndHour = useCallback(
    (date: Date | null) => {
      if (!date) return;
      setEndHour(date);

      if (startHour && isAfter(startHour, date)) {
        const newStartDate = new Date(date);
        const endHour = date.getHours();

        if (endHour <= 2) {
          newStartDate.setHours(0);
          newStartDate.setMinutes(0);
          setStartHour(newStartDate);
          return;
        }
        setStartHour(subHours(date, 2));
      }
    },
    [startHour]
  );

  const handleShowConflicts = useCallback(() => {
    if (!venueDetail || !venue) return;

    const occurrenceToUpdate = mapOccurrenceValues();
    if (!occurrenceToUpdate) return;

    const spacesCopy = venueDetail.spaces.filter((s) =>
      spaces.some((space) => space.id === s.id)
    );
    const workingOccurrence: EventOccurrenceUpdateProps = {
      ...occurrence,
      start: occurrenceToUpdate.start
        ? occurrenceToUpdate.start
        : occurrence.start,
      end: occurrenceToUpdate.end ? occurrenceToUpdate.end : occurrence.end,
      venue: venue,
      spaces: spacesCopy,
    };
    dispatch(
      setIsConflictsModalOpen({
        isOpen: true,
        workingEventId: eventId,
        workingOccurrence: workingOccurrence,
      })
    );
  }, [
    dispatch,
    eventId,
    occurrence,
    mapOccurrenceValues,
    spaces,
    venue,
    venueDetail,
  ]);

  const handleRemoveSpace = useCallback(
    (spaceId: String) => {
      const index = spaces.findIndex((space) => space.id === spaceId);
      if (index === -1) return;
      const spacesCopy = [...spaces];
      spacesCopy.splice(index, 1);
      setSpaces(spacesCopy);
    },
    [spaces]
  );

  useEffect(() => {
    limitAllEventsByVenueHours && setVenueHours(undefined);
  }, [setVenueHours, limitAllEventsByVenueHours]);

  useEffect(() => {
    handleOnCheckConflicts();
  }, [handleOnCheckConflicts]);

  useEffect(() => {
    setCurrentComment({
      id: "",
      createdBy: currentUser,
      comment: "",
      isPublic: true,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      updatedBy: null,
      mentioned: [],
    });
  }, [currentUser]);

  useEffect(() => {
    if (!venue) {
      setVenueDetail(undefined);
      return;
    }
    if (venueDetail && venueDetail.id === venue.id) {
      return;
    }

    // clear venue detail to prevent race conditions while loading
    setVenueDetail(undefined);
    apiClient.getVenue(currentAccount.id, venue.id).then((res) => {
      setVenueDetail(res);
    });
  }, [apiClient, currentAccount.id, venue, venueDetail]);

  return (
    <>
      {occurrence && (
        <Box display="flex" flexDir="column" gap={4} w="100%">
          {conflict.hasConflict && (
            <ConflictAlert
              includeDescription={false}
              showConflictsLink
              onClickShowConflicts={handleShowConflicts}
            />
          )}
          <Grid templateColumns="repeat(8, 1fr)" gap={5}>
            <GridItem colSpan={8}>
              <Text fontSize="sm" py={1}>
                Change Date
              </Text>
              <StyledDatePicker
                name="start"
                value={start}
                showCustomLocaleInput
                onChange={handleOnChangeStartDate}
                filterDate={limitAllEventsByVenueHours ? filterDay : undefined}
              />
            </GridItem>

            <GridItem colSpan={[4, 4, 3]}>
              <Text fontSize="sm" py={1}>
                Event starts at
              </Text>
              <StyledDatePicker
                name="startHour"
                value={startHour}
                format={HOURS_FORMAT}
                showTime
                showTimeOnly
                className="hourPicker"
                onChange={handleOnChangeStartHour}
                hasError={invalidHours}
                filterTime={limitAllEventsByVenueHours ? filterTime : undefined}
              />
            </GridItem>

            <GridItem colSpan={[4, 4, 3]}>
              <Text fontSize="sm" py={1}>
                Event ends at
              </Text>
              <StyledDatePicker
                name="endHour"
                value={endHour}
                format={HOURS_FORMAT}
                showTime
                showTimeOnly
                className="hourPicker"
                onChange={handleOnChangeEndHour}
                hasError={invalidHours}
                filterTime={limitAllEventsByVenueHours ? filterTime : undefined}
              />
            </GridItem>
            {limitAllEventsByVenueHours && (
              <GridItem colSpan={8}>
                <LimitVenueInfo />
              </GridItem>
            )}

            {invalidHours && (
              <GridItem colSpan={8} mt={-2}>
                <Text color={errorColor} fontSize="sm">
                  Start hour must be less than end hour
                </Text>
              </GridItem>
            )}

            <GridItem colSpan={8}>
              <Divider />
            </GridItem>

            <GridItem colSpan={8}>
              <Text fontSize="sm" py={1}>
                Choose a venue
              </Text>
              <VenueAutocomplete
                name="venue"
                value={venue as ApiVenue}
                onChange={handleOnChangeVenue}
              />
            </GridItem>

            <GridItem colSpan={8} display="flex" flexDir="column" gap={4}>
              <Flex justifyContent="space-between">
                <Text>{`Spaces (${spaces.length})`}</Text>
                <Button colorScheme="blue" size="sm" onClick={onOpen}>
                  <Icon as={MdAdd} mr={2} /> Add Spaces
                </Button>
              </Flex>
              <Flex flexDir="column" gap={4}>
                {spaces.map((space) => (
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    key={`editOccurrence::${space.id}`}
                  >
                    <Flex gap={2} alignItems="center">
                      {space.defaultAttachment ? (
                        <Image
                          w="50px"
                          h="48px"
                          borderRadius={6}
                          src={space.defaultAttachment.url}
                        />
                      ) : (
                        <NoAvailableImage
                          showText={false}
                          iconProps={{ boxSize: "14px" }}
                          containerProps={{ w: "50px", h: "48px" }}
                        />
                      )}
                      <Text fontWeight="semibold">{space.location.name}</Text>
                    </Flex>

                    <Icon
                      cursor="pointer"
                      color={iconColor}
                      as={IoTrashOutline}
                      onClick={() => handleRemoveSpace(space.id)}
                    />
                  </Flex>
                ))}
              </Flex>
            </GridItem>

            <GridItem colSpan={8}>
              <Divider />
            </GridItem>

            <GridItem colSpan={6}>
              <Heading fontSize="xl">Add a comment</Heading>
            </GridItem>

            <GridItem colSpan={8}>
              <CommentForm
                commentFrom="event"
                onSave={() => {}}
                comment={currentComment}
                autoFocusComment={false}
                showCommentButtons={false}
                setCurrentComment={setCurrentComment}
              />
            </GridItem>

            <GridItem colSpan={8}>
              <Divider />
            </GridItem>

            <GridItem display="flex" colSpan={8} justifyContent="space-between">
              <Button
                size="sm"
                variant="outline"
                borderColor="blue.500"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={handleOnSave}
                disabled={invalidHours}
              >
                Save Changes
              </Button>
            </GridItem>
          </Grid>
        </Box>
      )}

      {isOpen && occurrence && (
        <AccountModal
          isOpen={isOpen}
          onClose={closeSpacesModal}
          contentProps={{ minW: isDesktop ? "xl" : undefined }}
          title={`Spaces (${spaces?.length})`}
          content={
            <SpacesForm
              viewMode="list"
              // includeCarousel
              values={spaces}
              cb={handleOnAddSpaces}
              onClose={closeSpacesModal}
              venue={venue}
              action="get"
              parent={venue ? (venue.location as ApiLocation) : null}
            />
          }
        />
      )}
    </>
  );
};
