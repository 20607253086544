import {
  ApiDay,
  ApiVenue,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import { getDay } from "date-fns";
import { getDayName } from "./getDayName";

interface FilterAllowedDaysParams {
  date: Date;
  venue: ApiVenue | ApiVenueSummary | null;
}

export function filterAllowedDays({ date, venue }: FilterAllowedDaysParams) {
  if (!venue) return true;
  const day = getDay(date);
  const dayName: ApiDay = getDayName(day).toLowerCase() as ApiDay;
  return venue.hours[dayName].isOpen;
}
