import {
  Container,
  Divider,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { useProductSubscriptions } from "../../components/shell/AppShell";
import { RootState } from "../../store";
import { setCurrentReport } from "../../store/reports/reports.slice";
import { SYSTEM_MULTI_PRODUCT_REPORT } from "../../utils/reports";
import { ReportsByProduct } from "./ReportsByProduct";

export type ReportPageProps = {};
export type ReportRouteParams = {
  id?: string;
  product?: string;
};
export const ReportPage: FC<ReportPageProps> = () => {
  const { hasRequests, hasEvents, hasInventory } = useProductSubscriptions();

  const { isProductAdmin, isEventAdmin, isInventoryAdmin } =
    useAuthentication();

  const { reportsSummary } = useSelector(
    (state: RootState) => state.localCache,
  );
  const { product: productName } = useParams<ReportRouteParams>();
  const [tabIndex, setTabIndex] = useState<number>(-1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productTabs = useMemo(() => {
    const tabs: { label: string; index: number }[] = [];
    let tabIdx = 0;
    if (
      hasRequests &&
      isProductAdmin &&
      reportsSummary["HeroHQ"] &&
      reportsSummary["HeroHQ"] > 0
    ) {
      tabs.push({
        label: "HeroHQ",
        index: tabIdx,
      });
      tabIdx++;
    }
    if (
      hasEvents &&
      isEventAdmin &&
      reportsSummary["Events"] &&
      reportsSummary["Events"] > 0
    ) {
      tabs.push({
        label: "Events",
        index: tabIdx,
      });
      tabIdx++;
    }

    if (
      hasInventory &&
      isInventoryAdmin &&
      reportsSummary["InventoryHQ"] &&
      reportsSummary["InventoryHQ"] > 0
    ) {
      tabs.push({
        label: "InventoryHQ",
        index: tabIdx,
      });
      tabIdx++;
    }

    if (reportsSummary["Misc."] && reportsSummary["Misc."] > 0)
      tabs.push({
        label: SYSTEM_MULTI_PRODUCT_REPORT.name,
        index: tabIdx,
      });

    return tabs;
  }, [
    hasRequests,
    hasEvents,
    hasInventory,
    isEventAdmin,
    isInventoryAdmin,
    isProductAdmin,
    reportsSummary,
  ]);

  const handleTabsChange = useCallback(
    (index: number) => {
      dispatch(setCurrentReport(undefined));
      const product = productTabs.find((tab) => tab.index === index);
      if (!product) return;
      setTabIndex(index);
      navigate(`/reports/${product.label.toLowerCase()}`);
    },
    [dispatch, navigate, productTabs],
  );

  // Effect to set the tab index from the url on first load.
  // on the tabIndex is not the default, this is no longer needed.
  useEffect(() => {
    if (tabIndex !== -1 || productTabs.length === 0) return;

    let selectedTabName = productName;
    // first load of /reports
    if (!selectedTabName) {
      const firstTab = Object.keys(reportsSummary).find(
        (k) => k.toLowerCase() !== "total",
      );
      if (firstTab) {
        selectedTabName = firstTab.toLowerCase();
        navigate(`/reports/${firstTab.toLowerCase()}`);
      }
    }

    // this shouldn't happen by this point
    if (!selectedTabName) return;

    if (productTabs.length > 0) {
      const productIdx = productTabs.findIndex(
        (tab) => tab.label.toLowerCase() === selectedTabName?.toLowerCase(),
      );
      if (productIdx !== -1) {
        setTabIndex(productIdx);
      }
    }
  }, [productName, reportsSummary, navigate, productTabs, tabIndex]);

  return (
    <Container maxWidth="8xl" display="flex" gap={4} flexDirection="column">
      <Tabs
        position="relative"
        variant="unstyled"
        index={tabIndex}
        onChange={handleTabsChange}
        isLazy={true}
      >
        <Flex alignItems="center" gap={8} flexWrap="wrap">
          <Heading
            mr={4}
            fontSize="3xl"
            w="max-content"
            display={["none", "none", "block"]}
          >
            Reports
          </Heading>
          <TabList>
            {productTabs.map((tab) => (
              <Tab key={tab.label} _focus={{ boxShadow: "none" }}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Flex>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />

        <Divider my={2} />
        <TabPanels>
          {productTabs.map((tab) => (
            <TabPanel px={0} key={`tab::${tab.label}`} w="full">
              <ReportsByProduct product={tab.label} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Container>
  );
};
