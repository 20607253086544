import {
  Box,
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import {
  createElement,
  FC,
  FunctionComponentElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RiFilter3Line } from "react-icons/ri";
import { useAuthentication } from "../../../../../../components/auth/AuthProvider";
import { LocationFilter } from "../../../../../../components/filters/LocationFilter";
import { useAllowedLocations } from "../../../../../../hooks/useAllowedLocation";
import { useScreenBreakpoints } from "../../../../../../hooks/useScreenBreakpoints";
import { useThunkDispatch } from "../../../../../../store";
import { findProjectRequests } from "../../../../../../store/planning-hq/requests/findRequests.thunk";
import { updateFilters } from "../../../../../../store/planning-hq/requests/updateFilters.thunk";
import { RequestListFilterState } from "../../../../../../store/request-list.slice";
import { useCategoryUtils } from "../../../../../../utils/categoryUtils";
import { AssetFilter } from "../../../../../requests/filters/AssetFilter";
import { CategoryFilter } from "../../../../../requests/filters/CategoryFilter";
import { EventFilter } from "../../../../../requests/filters/EventFilter";
import { PersonFilter } from "../../../../../requests/filters/PersonFilter";
import { ReasonFilter } from "../../../../../requests/filters/ReasonFilter";
import { RequestStatusFilter } from "../../../../../requests/filters/RequestStatusFilter";
import { ScheduledRequestFilter } from "../../../../../requests/filters/ScheduledRequestFilter";
import { ServiceFilter } from "../../../../../requests/filters/ServiceFilter";
import { TypeFilter } from "../../../../../requests/filters/TypeFilter";
import { WorkflowFilter } from "../../../../../requests/filters/WorkflowFilter";
import { MoreFilters } from "./MoreFilters";

export const InitialFilters: Array<keyof Partial<RequestListFilterState>> = [
  "workflows",
  "statuses",
];
export const AdditionalFilters: Array<keyof Partial<RequestListFilterState>> = [
  "locations",
  "persons",
  "categories",
  "assets",
  "reasons",
  "types",
  "scheduledRequest",
  "events",
  "services",
];
export const Filters = [...InitialFilters, ...AdditionalFilters] as const;
export type InitialFilter = (typeof InitialFilters)[number];
export type AdditionalFilter = (typeof AdditionalFilters)[number];
export type Filter = (typeof Filters)[number];
type GeneralFiltersProps = {
  project: ApiProject;
  filters: { [key: string]: any };
};

export const GeneralFilters: FC<GeneralFiltersProps> = ({
  project,
  filters,
}) => {
  const { apiClient } = useAuthentication();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { isDesktop } = useScreenBreakpoints();

  const [reqFilters, setReqFilters] = useState<{ [key: string]: any }>();

  const thunkDispatch = useThunkDispatch();
  const [filtersList, setFiltersList] = useState<
    Array<InitialFilter | AdditionalFilter>
  >([...InitialFilters]);

  const { allowedLocations } = useAllowedLocations();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();

  const handleUpdateFilters = useCallback(
    (delta: Partial<RequestListFilterState>) => {
      setReqFilters({
        ...reqFilters,
        ...delta,
      });
    },
    [reqFilters]
  );
  const filterComponents = useMemo(() => {
    if (!reqFilters) return {};
    const {
      workflows,
      locations,
      statuses,
      persons,
      categories,
      assets,
      reasons,
      types,
      scheduledRequest,
      events,
      services,
    } = reqFilters;
    const filtersC: {
      [key in keyof Partial<RequestListFilterState>]: FunctionComponentElement<any>;
    } = {
      workflows: createElement(WorkflowFilter, {
        key: "prj-requests:workflow-filter",
        value: workflows,
        onChange: (workflows) => {
          handleUpdateFilters({ workflows: workflows.map((w) => w.id) });
        },
      }),
      locations: createElement(LocationFilter, {
        key: "prj-requests:location-filter",
        value: locations,
        allowedLocations: allowedLocations,
        onChange: (locations) => {
          handleUpdateFilters({ locations: locations.value.map((l) => l.id) });
        },
      }),

      statuses: createElement(RequestStatusFilter, {
        key: "prj-requests:status-filter",
        value: statuses,
        onChange: (statuses) => {
          handleUpdateFilters({ statuses });
        },
      }),
      persons: createElement(PersonFilter, {
        key: "prj-requests:person-filter",
        value: persons,
        onChange: (persons) => {
          handleUpdateFilters({ persons });
        },
      }),
      categories: createElement(CategoryFilter, {
        key: "prj-requests:category-filter",
        value: categories,
        onChange: (categories) => {
          const categoryChilds = findAllChildrenForNodesRecursive(categories);
          let newValues = Array.from(categoryChilds);
          handleUpdateFilters({
            categories: newValues ? newValues.map((l) => l.id) : [],
          });
        },
      }),
      assets: createElement(AssetFilter, {
        key: "prj-requests:assets-filter",
        value: assets,
        onChange: (assets) => {
          handleUpdateFilters({ assets: assets.map((a) => a.id) });
        },
      }),
      reasons: createElement(ReasonFilter, {
        key: "prj-requests:reasons-filter",
        value: reasons,
        onChange: (reasons) => {
          handleUpdateFilters({ reasons: reasons.map((r) => r.id) });
        },
      }),
      types: createElement(TypeFilter, {
        key: "prj-requests:type-filter",
        value: types,
        onChange: (types) => {
          handleUpdateFilters({ types });
        },
      }),
      scheduledRequest: createElement(ScheduledRequestFilter, {
        key: "prj-requests:scheduled-filter",
        value: scheduledRequest,
        onChange: (schedules) => {
          handleUpdateFilters({ scheduledRequest: schedules.map((s) => s.id) });
        },
      }),
      events: createElement(EventFilter, {
        key: "prj-requests:events-filter",
        value: events,
        onChange: (events) =>
          handleUpdateFilters({ events: events.map((e) => e.id) }),
      }),
      services: createElement(ServiceFilter, {
        key: "prj-requests:services-filter",
        value: services,
        onChange: (services) =>
          handleUpdateFilters({ services: services.map((s) => s.id) }),
      }),
    };
    return filtersC;
  }, [
    allowedLocations,
    findAllChildrenForNodesRecursive,
    handleUpdateFilters,
    reqFilters,
  ]);

  const onFilterChange = useCallback(
    (moreFilters: Array<AdditionalFilter>) => {
      if (!reqFilters) return;
      const a = [...InitialFilters, ...moreFilters];
      setFiltersList(a);
      a.forEach((element) => {
        const t = reqFilters[element];
        if (
          !filtersList.find((f) => f === element) &&
          Array.isArray(t) &&
          t.length > 0
        ) {
          thunkDispatch(updateFilters({ [element]: [] }));
        }
      });
    },
    [reqFilters, thunkDispatch, filtersList]
  );

  const applyFilters = useCallback(() => {
    if (!reqFilters) return;
    thunkDispatch(updateFilters(reqFilters)).then(() => {
      thunkDispatch(
        findProjectRequests({
          apiClient,
          projectId: project.id,
        })
      );
    });

    onClose();
  }, [project, apiClient, onClose, thunkDispatch, reqFilters]);

  useEffect(() => {
    setReqFilters(filters);
  }, [filters]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement={isDesktop ? "right-start" : "auto"}
        preventOverflow={isDesktop ? false : true}
        flip={isDesktop ? false : true}
      >
        <PopoverTrigger>
          <IconButton
            size="sm"
            icon={<RiFilter3Line />}
            aria-label="project-filters"
            colorScheme="gray"
            variant="outline"
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="400px">
            <PopoverArrow />
            <PopoverHeader>Filters</PopoverHeader>
            {reqFilters && (
              <PopoverBody display="flex" flexDir="column" gap={2}>
                {/**
                 * TODO: priority filter
                 */}
                <VStack align="stretch" w="full" gap={2}>
                  {filtersList.map((filter) => {
                    return filterComponents[filter];
                  })}
                </VStack>
                <Box flexBasis="50%">
                  <MoreFilters onFilterChange={onFilterChange} />
                </Box>
              </PopoverBody>
            )}
            <PopoverFooter>
              <HStack gap={2} justifyContent="end">
                <Button
                  variant="outline"
                  colorScheme="blue"
                  size="sm"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button colorScheme="blue" onClick={applyFilters} size="sm">
                  Apply
                </Button>
              </HStack>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};
