import {
  ApiComment,
  ApiLaborTransaction,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine/dist/SchemaRuleEngine";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { TransactionLaborerWithGroups } from "../pages/request-form/transactions/labors/LaborTransactionForm";
import { RootState, useThunkDispatch } from "../store";
import { updateRequestTransition } from "../store/request-column-view.slice";
import { unloadForm } from "../store/request-form/request-form.slice";
import { createTransaction } from "../store/request-form/thunks/createTransaction.thunk";
import { saveComment } from "../store/request-form/thunks/saveComment.thunk";
import { updateRequest } from "../store/request-form/thunks/updateRequest.thunk";
import { useShowToast } from "./showToast";

export function useChangeStatus() {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const { apiClient, currentAccount, currentUser, isProductAdmin } =
    useAuthentication();
  const showToast = useShowToast();

  const { request, policy, workflow, schemaFields } = useSelector(
    (state: RootState) => state.requestForm
  );

  const handleOnSaveLaborTransaction = useCallback(
    (laborTransaction?: TransactionLaborerWithGroups) => {
      if (!laborTransaction) return;
      if (!laborTransaction.laborer.isGroup) {
        thunkDispatch(
          createTransaction({
            apiClient,
            account: currentAccount,
            transaction: laborTransaction,
            request,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            showToast("error", "Error Saving Labor Transaction");
          });
      }

      if (
        laborTransaction.laborer.isGroup &&
        laborTransaction.laborer.groupMembers
      ) {
        const { groupMembers } = laborTransaction.laborer;
        Promise.all(
          groupMembers.map((member) => {
            return thunkDispatch(
              createTransaction({
                apiClient,
                account: currentAccount,
                transaction: { ...laborTransaction, laborer: member },
                request,
              })
            );
          })
        )
          .then(() => {
            showToast("success", "Labor transactions created successfully");
          })
          .catch((error) => {
            showToast("error", "Error Saving Labor Transactions");
          });
      }
    },
    [apiClient, currentAccount, request, showToast, thunkDispatch]
  );

  const handleChangeModalSave = useCallback(
    ({
      delta,
      comment,
      laborTransaction,
    }: {
      delta: Partial<ApiRequest>;
      comment?: ApiComment;
      laborTransaction?: ApiLaborTransaction;
    }) => {
      if (!policy || !workflow || !schemaFields) return;
      const engine = new SchemaRulesEngine({
        account: currentAccount,
        form: "full",
        policy: policy,
        workflow: workflow,
        user: currentUser,
        schemaFields,
        isProductAdmin,
      });

      return thunkDispatch(
        updateRequest({
          account: currentAccount,
          apiClient: apiClient,
          idOrKey: request!.key,
          request: request as ApiRequest,
          delta,
          engine: engine!,
          isChangeModal: true,
          isProductAdmin,
        })
      )
        .then(unwrapResult)
        .then(
          (response) => {
            thunkDispatch(
              updateRequestTransition({ request: response.request })
            );

            showToast("success", "Request saved");

            if (comment) {
              thunkDispatch(
                saveComment({
                  apiClient,
                  account: currentAccount,
                  comment,
                  request: request!,
                })
              )
                .then(unwrapResult)
                .catch((e) => {
                  showToast("error", "Error Saving Comment");
                });
            }

            handleOnSaveLaborTransaction(laborTransaction);
          },
          (err) => {
            if (err.changeVerification || err.delta) {
              return;
            }

            showToast("error", "Error Saving Request");
          }
        );
    },
    [
      currentUser,
      showToast,
      thunkDispatch,
      currentAccount,
      apiClient,
      request,
      isProductAdmin,
      handleOnSaveLaborTransaction,
      policy,
      workflow,
      schemaFields,
    ]
  );

  const handleChangeModalCancel = useCallback(() => {
    dispatch(unloadForm());
  }, [dispatch]);

  return {
    handleChangeModalSave,
    handleChangeModalCancel,
  };
}
