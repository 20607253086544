import { FormikErrors, useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { usePrevious } from "react-use";
import { areObjectsEquals } from "../utils/compareObjects";

interface FormikObserverProps {
  cb: (
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void,
    isSubmitting: boolean,
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
    isValid: boolean,
    validateForm: (values: any) => Promise<FormikErrors<any>>
  ) => void;
}

export const FormikObserver: FC<FormikObserverProps> = ({ cb }) => {
  const {
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    isValid,
    validateForm,
  } = useFormikContext();
  const prevValues = usePrevious(values);

  useEffect(() => {
    const areEquals = areObjectsEquals(prevValues || {}, values || {});
    if (areEquals) return;

    // Sending formik values and setter to the callback
    cb(
      values,
      setFieldValue,
      isSubmitting,
      handleSubmit,
      isValid,
      validateForm
    );
  }, [
    cb,
    prevValues,
    setFieldValue,
    values,
    isSubmitting,
    handleSubmit,
    isValid,
    validateForm,
  ]);

  return null;
};
