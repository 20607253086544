import {
  Box,
  Center,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { NotificationsHistory } from "../../../components/notifications/NotificationsHistory";
import { Pager } from "../../../components/pager/Pager";
import { RootState, useThunkDispatch } from "../../../store";
import { setRequestNotificationCurrentPage } from "../../../store/request-form/request-form.slice";
import { loadRequestNotification } from "../../../store/request-form/thunks/requestNotification.thunk";

export const RequestNotification = () => {
  const { apiClient, currentAccount } = useAuthentication();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { isOpen, onToggle } = useDisclosure();

  const { request, notifications } = useSelector(
    (state: RootState) => state.requestForm
  );

  const handleOnChangePage = useCallback(
    (value: number) => {
      dispatch(setRequestNotificationCurrentPage(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!request || !isOpen) return;
    thunkDispatch(
      loadRequestNotification({
        apiClient,
        accountId: currentAccount.id,
        requestId: request.id,
      })
    );
  }, [
    apiClient,
    currentAccount.id,
    request,
    thunkDispatch,
    isOpen,
    notifications.page,
  ]);

  return (
    <Flex gap={6} flexDir="column">
      <Heading
        fontSize="lg"
        display="flex"
        w="max-content"
        cursor="pointer"
        onClick={onToggle}
        alignItems="center"
      >
        Request Notifications
        <Icon as={isOpen ? MdArrowDropUp : MdArrowDropDown} />
      </Heading>
      <Collapse in={isOpen} unmountOnExit>
        {notifications.loading === "pending" && (
          <Center w="100%">
            <Spinner color="blue.500" />
          </Center>
        )}

        {notifications.loading === "failed" && (
          <Text width="100%">Notifications could not be loaded.</Text>
        )}

        {notifications.loading === "succeeded" && (
          <Box>
            {notifications.data.length === 0 ? (
              <Text width="100%">
                There are no notifications for this request.
              </Text>
            ) : (
              <VStack alignItems="flex-start" gap={4} divider={<Divider />}>
                <Text width="100%" fontWeight="light">
                  Request Notifications are stored for 180 days.
                </Text>
                <NotificationsHistory notifications={notifications.data} />
                <Box w="100%">
                  <Pager
                    pageSize={notifications.pageSize}
                    currentPage={notifications.page}
                    total={notifications.total}
                    onPageChange={handleOnChangePage}
                  />
                </Box>
              </VStack>
            )}
          </Box>
        )}
      </Collapse>
    </Flex>
  );
};
