import {
  ApiClient,
  ApiPagingOptions,
  ApiService,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface loadServiceThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: ApiServiceFilter;
}

export interface ApiServiceFilter extends ApiPagingOptions {
  search?: string;
  includeInactive?: boolean;
}

export const loadServices = createAsyncThunk(
  "event/services/load",
  async (params: loadServiceThunkParams, ThunkAPI) => {
    let { filters } = (ThunkAPI.getState() as RootState).serviceList;
    if (params.filters) filters = params.filters;
    const { apiClient, accountId } = params;

    const response = await apiClient.findServices(accountId, filters);
    return response;
  }
);

export interface ServiceListSliceState {
  data: ApiService[];
  total: number;
  filters: ApiServiceFilter;
}

const DEFAULT_SERVICE_FILTERS: ApiServiceFilter = {
  page: 1,
  search: "",
  pageSize: 20,
  includeInactive: false,
};

export const serviceList = createSlice({
  name: "serviceList",
  initialState: {
    data: [],
    total: 1,
    filters: { ...DEFAULT_SERVICE_FILTERS },
  } as ServiceListSliceState,
  reducers: {
    unload: (state: ServiceListSliceState) => {
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_SERVICE_FILTERS;
    },
    updateFilters: (
      state: ServiceListSliceState,
      action: PayloadAction<ApiServiceFilter>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadServices.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filters.page = options.page;
      state.filters.pageSize = options.pageSize;
      state.data = data;
      state.total = total;
    });
  },
});

export const { unload, updateFilters } = serviceList.actions;

export default serviceList.reducer;
