import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionCreatorWithPayload, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosProgressEvent } from "axios";
import { RootState } from "../..";
import { Attachment } from "../../../components/attachments/Attachments";

export interface AddProjectAttachmentThunkParams {
  apiClient: ApiClient;
  projectId: string;
  attachment: Attachment;
  createAttachmentAction: ActionCreatorWithPayload<Attachment, string>;
  updateAttachmentAction: ActionCreatorWithPayload<Attachment, string>;
}

export const uploadAttachment = createAsyncThunk(
  "projectAttachment/upload",
  async (params: AddProjectAttachmentThunkParams, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { currentAccount } = state.auth;
    const {
      apiClient,
      projectId,
      attachment,
      createAttachmentAction,
      updateAttachmentAction,
    } = params;

    const upload = await apiClient.createUpload(currentAccount.id);

    const newAttachment = {
      ...attachment,
      isUploading: true,
      uploadId: upload.id,
      progress: 0,
    };
    dispatch(
      createAttachmentAction({
        ...newAttachment,
      })
    );

    await axios.put(upload.url, attachment.file, {
      headers: { "Content-Type": attachment.file!.type },
      onUploadProgress: (progressEvent: AxiosProgressEvent) =>
        dispatch(
          updateAttachmentAction({
            ...newAttachment,
            progress: Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            ),
          })
        ),
    });

    dispatch(
      updateAttachmentAction({
        ...newAttachment,
        progress: 100,
      })
    );

    const apiAttachment = await apiClient.createProjectAttachment(
      currentAccount.id,
      projectId,
      {
        uploadId: upload.id,
        name: newAttachment.name,
      }
    );

    dispatch(
      updateAttachmentAction({
        ...newAttachment,
        file: undefined,
        isNew: false,
        progress: undefined,
        uploadId: apiAttachment.id,
        url: apiAttachment.url,
      })
    );
  }
);
