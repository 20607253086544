import { ApiRequest } from "@operations-hero/lib-api-client";

export function compareRequests(req1: ApiRequest, req2: ApiRequest): number {
  const dateA: Date | null = req1.statusUpdated
    ? new Date(req1.statusUpdated)
    : null;
  const dateB: Date | null = req2.statusUpdated
    ? new Date(req2.statusUpdated)
    : null;

  if (req1.status === "closed" && req2.status === "closed") {
    if (dateA && dateB) {
      return dateA.getTime() - dateB.getTime(); // Sort by ascending date
    } else if (dateA) {
      return -1; // req2.statusUpdated is null, req1 is more recent
    } else if (dateB) {
      return 1;
    } else {
      return 0;
    }
  } else if (req1.status === "closed") {
    return 1; // req1 is closed, req2 is open - sort to bottom
  } else if (req2.status === "closed") {
    return -1;
  } else {
    return 0;
  }
}
