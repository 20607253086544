import { ApiClient, ApiRequest } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { isObjectEmpty } from "../../../utils/compareObjects";
import { getProject } from "../project-page";
import { ScheduleItem, ScheduleState } from "./types";

type UpdateTimelineParams = {
  apiClient: ApiClient;
  projectId: string;
  item: ScheduleItem;
};

export const updateProjectSchedule = createAsyncThunk(
  "project-requests-scheduling/update",
  async ({ apiClient, projectId, item }: UpdateTimelineParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { currentAccount } = state.auth;
    const { scheduling } = state.projectRequestsScheduling;
    const { requestsCache } = state.projectRequests;

    const updatedScheduleItems = {
      items: {
        ...scheduling.items,
        [item.id]: item,
      },
    };

    const updatedProject = await apiClient.updateProject(
      currentAccount.id,
      projectId,
      {
        scheduling: updatedScheduleItems,
      }
    );

    const requestToUpdate = requestsCache[item.id];
    let updatedRequest: ApiRequest | null = null;
    if (requestToUpdate) {
      updatedRequest = await apiClient.updateRequest(
        currentAccount.id,
        item.id,
        {
          scheduling: {
            ...requestToUpdate.scheduling,
            start: item.start,
            due: item.end,
          },
        }
      );
    }

    return { updatedProject, updatedRequest };
  }
);

const projectRequestsScheduling = createSlice({
  name: "project-requests-scheduling",
  initialState: {
    scheduling: { items: {} } as ScheduleState,
  },
  reducers: {
    updatemyCustomSchedule: (state, action: PayloadAction<any>) => {
      state.scheduling = action.payload;
    },

    unloadProjectScheduling: (state) => {
      state.scheduling = { items: {} };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProject.fulfilled, (state, action) => {
      const { scheduling } = action.payload;
      if (!scheduling || isObjectEmpty(scheduling)) {
        state.scheduling = {
          items: {},
        };
      } else {
        state.scheduling = action.payload.scheduling as ScheduleState;
      }
    });
    builder.addCase(updateProjectSchedule.fulfilled, (state, action) => {
      state.scheduling = action.payload.updatedProject
        .scheduling as ScheduleState;
    });
  },
});

export const { updatemyCustomSchedule, unloadProjectScheduling } =
  projectRequestsScheduling.actions;

export default projectRequestsScheduling.reducer;
