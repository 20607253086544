import { ApiClient, ApiFundingSource } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LoadingStatus } from "../types";

type FundingSourceFormSliceProps = {
  workingFundingSource: ApiFundingSource | null;
  updateLoadingStatus: LoadingStatus;
};
type UpdateFundingSourceParams = {
  id: string;
  apiClient: ApiClient;
  delta: Partial<ApiFundingSource>;
};

export const updateFundingSource = createAsyncThunk<
  ApiFundingSource,
  UpdateFundingSourceParams
>("funding-source/update", async ({ id, apiClient, delta }, thunkAPI) => {
  const rootState = thunkAPI.getState() as RootState;
  const { currentAccount } = rootState.auth;
  const updatedFundingSource = await apiClient.updateFundingSource(
    currentAccount.id,
    id,
    delta
  );

  return updatedFundingSource;
});

const initialState: FundingSourceFormSliceProps = {
  workingFundingSource: null,
  updateLoadingStatus: "idle",
};

const fundingSourceFormSlice = createSlice({
  name: "funding-source/details",
  initialState,
  reducers: {
    setUpdateStatus: (
      state,
      action: PayloadAction<FundingSourceFormSliceProps["updateLoadingStatus"]>
    ) => {
      state.updateLoadingStatus = action.payload;
    },
    setWorkingFundingSource: (
      state,
      action: PayloadAction<FundingSourceFormSliceProps["workingFundingSource"]>
    ) => {
      state.workingFundingSource = action.payload;
    },
    unload: (state) => {
      state.workingFundingSource = null;
      state.updateLoadingStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFundingSource.pending, (state) => {
      state.updateLoadingStatus = "pending";
    });

    builder.addCase(updateFundingSource.fulfilled, (state, action) => {
      state.updateLoadingStatus = "fulfilled";
      state.workingFundingSource = action.payload;
    });
  },
});

export const { unload, setWorkingFundingSource, setUpdateStatus } =
  fundingSourceFormSlice.actions;
export default fundingSourceFormSlice.reducer;
