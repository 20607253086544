import { ApiReportingCategory } from "@operations-hero/lib-api-client";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useCategoryUtils = () => {
  const { categoriesMap } = useSelector((state: RootState) => state.localCache);

  const findAllChildrenForNodesRecursive = (
    values: (ApiReportingCategory | string)[]
  ) => {
    const result = new Set<ApiReportingCategory>();
    values.forEach((value) => {
      //hidratate category for children property
      const category = typeof value === "string" ? categoriesMap[value] : value;
      //if it doesn't has a child and it's already added finish this iteration
      result.add(category);
      if (category.children.length === 0) {
        return null;
      }
      category.children.forEach((child) => {
        //add each child and look if it this child has children and add them
        result.add(child);
        if (child.children.length === 0) {
          return null;
        }
        findAllChildrenForNodesRecursive([child]);
      });
    });
    return result;
  };

  return { findAllChildrenForNodesRecursive };
};

export function findCategoryChildrenFunction(
  categoryMap: { [key: string]: ApiReportingCategory },
  categories: string[],
  result: Set<string>
) {
  categories.forEach((cat) => {
    result.add(cat);
    Object.keys(categoryMap).forEach((keyMap) => {
      if (
        !categoryMap[keyMap].children.some((child) => child.id === cat) &&
        categoryMap[keyMap].id !== cat
      ) {
        return null;
      }
      result.add(categoryMap[keyMap].id);

      categoryMap[keyMap].children.forEach((child) => result.add(child.id));
    });
  });
  return result;
}
