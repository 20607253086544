import { Select, StylesConfig } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";
import { CustomSelectComponentProp } from "./select-overrides-types";
import { LabelValue } from "./StateSelect";

export interface StyledSelectProps {
  components?: any;
  defaultValue?: any;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  onChange?: (item: any) => void;
  options?: any[];
  placeholder?: string;
  value?: any;
  name?: string;
  customGroup?: { label: string; options: LabelValue[] };
  styles?: StylesConfig<any, boolean, any>;
  menuPortalTarget?: HTMLElement | null;
}

export const StyledSelect = ({
  defaultValue,
  isClearable,
  isDisabled,
  isMulti,
  onChange,
  options,
  placeholder,
  value,
  name,
  customGroup,
  styles,
  menuPortalTarget,
}: StyledSelectProps) => {
  const handleChange = useCallback(
    (item: any | null) => {
      if (onChange) onChange(item);
    },
    [onChange]
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
    };
  }, []);

  const selectValue = useMemo(() => {
    if (typeof value !== "object" && options) {
      return options.find(
        (opt) => opt.value === String(value) || opt.id === String(value)
      );
    }

    return value;
  }, [options, value]);

  const selectOptions = useMemo(() => {
    if (customGroup) {
      return [{ ...customGroup }, { label: "All", options }];
    }

    return options;
  }, [customGroup, options]);

  return (
    <Select
      menuPortalTarget={menuPortalTarget}
      styles={styles}
      chakraStyles={commonStyles}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      value={selectValue}
      options={selectOptions}
      onChange={handleChange}
      isClearable={isClearable}
      isMulti={isMulti}
      placeholder={placeholder}
      name={name}
      components={components}
    />
  );
};
