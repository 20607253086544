import {
  compactLocationIdsFromExpansion,
  expandLocationIdsFromCompaction,
} from "@operations-hero/lib-location-compactor";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../store";
import { hasKey } from "../utils/hasKey";

export interface UseQueryStringFilterOptions<T> {
  defaultValue: T;
  pathname?: string;
  qsParamName?: string;
  compactExpandLocation?: {
    allLocationsKey: string;
    includedCompactedLocationKey: string;
    excludedCompactedLocationKey: string;
  };
}

export function useQueryStringFilter<T>({
  defaultValue,
  pathname,
  qsParamName = "filter",
  compactExpandLocation,
}: UseQueryStringFilterOptions<T>) {
  const navigate = useNavigate();
  const { pathname: currentPathname, search } = useLocation();
  const { locationMap, descendantsMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const [filterInitialize, setFilterInitialize] = useState(false);

  const isUpdating = useRef(false);
  const [filter, setFilter] = useState<T>(defaultValue);

  const globalQuery = useMemo(() => new URLSearchParams(search), [search]);

  const filterParam = useMemo(() => {
    const queryString = new URLSearchParams(search);
    return queryString.get(qsParamName);
  }, [search, qsParamName]);

  const updateQueryString = useCallback(
    (newValue: T) => {
      isUpdating.current = true;
      setFilter(newValue);

      //@ts-ignore
      const cleaned = Object.entries(newValue).reduce<Partial<T>>(
        (result, [key, value]) => {
          if (Array.isArray(value) && value.length === 0) {
            return result;
          }

          if (typeof value === "string" && !value) {
            return result;
          }

          if (value === null || typeof value === "undefined") {
            return result;
          }

          // @ts-ignore
          result[key] = value;
          if (key === compactExpandLocation?.allLocationsKey) {
            delete (result as any)[compactExpandLocation.allLocationsKey];
          }
          return result;
        },
        {}
      );

      if (Object.keys(cleaned).length === 0) {
        globalQuery.delete(qsParamName);
        navigate({
          pathname: pathname || currentPathname,
          search: globalQuery.toString(),
        });
        return;
      }

      const base64filter = btoa(encodeURIComponent(JSON.stringify(cleaned)));
      globalQuery.set(qsParamName, base64filter);
      navigate({
        pathname: pathname || currentPathname,
        search: globalQuery.toString(),
      });
    },
    [
      globalQuery,
      qsParamName,
      navigate,
      pathname,
      currentPathname,
      compactExpandLocation?.allLocationsKey,
    ]
  );

  const hydratePartial = useCallback(
    (parsed: Partial<T>) => {
      //@ts-ignore
      const result = Object.entries(defaultValue).reduce<T>(
        (result, [key, value]) => {
          // @ts-ignore;
          const parseValue = parsed[key];

          if (Array.isArray(value) && !parseValue) {
            // @ts-ignore;
            parsed[key] = [];
            return result;
          }

          if (typeof value === "string" && !parseValue) {
            // @ts-ignore;
            parsed[key] = "";
            return result;
          }

          if (value === null && !parseValue) {
            // @ts-ignore;
            parsed[key] = null;
            return result;
          }

          return result;
        },
        // This is is being built into a full T from a Partial<T>
        parsed as T
      );

      return result;
    },
    [defaultValue]
  );

  useEffect(
    () => {
      // this prevents a circular loop
      if (isUpdating.current) {
        isUpdating.current = false;
        return;
      }

      // initial load, need to see if the url has a value for us to set
      if (!filterParam) {
        setFilterInitialize(true);
        return;
      }

      try {
        let parsedFilter: Partial<T> = JSON.parse(
          decodeURIComponent(atob(filterParam))
        );

        if (typeof compactExpandLocation !== "undefined") {
          // legacy location filter is a list of ids,
          // needs to be convert
          let locations = [];
          let l = [];
          let el = [];
          if (hasKey(parsedFilter, compactExpandLocation.allLocationsKey)) {
            locations =
              (parsedFilter as any)[compactExpandLocation.allLocationsKey] ??
              [];
          }

          if (
            hasKey(
              parsedFilter,
              compactExpandLocation.includedCompactedLocationKey
            )
          ) {
            l =
              (parsedFilter as any)[
                compactExpandLocation.includedCompactedLocationKey
              ] ?? [];
          }

          if (
            hasKey(
              parsedFilter,
              compactExpandLocation.excludedCompactedLocationKey
            )
          ) {
            el =
              (parsedFilter as any)[
                compactExpandLocation.excludedCompactedLocationKey
              ] ?? [];
          }
          if (locations.length > 0) {
            // this needs to be compacted for el and l state
            const { l: lCompact, el: elCompact } =
              compactLocationIdsFromExpansion({
                locations,
                locationMap,
                descendantsMap,
              });
            l = lCompact;
            el = elCompact;
          }

          // This is in the new format
          else if (l?.length > 0 || el?.length > 0) {
            // this needs to be expanded for the location filter state
            locations = expandLocationIdsFromCompaction({
              includeList: l,
              excludeList: el,
              locationMap,
              descendantsMap,
            });
          }
          parsedFilter = {
            ...parsedFilter,
            [compactExpandLocation.allLocationsKey]: locations,
            [compactExpandLocation.includedCompactedLocationKey]: l,
            [compactExpandLocation.excludedCompactedLocationKey]: el,
          };
        }
        // need to merge back in empty arrays and empty strings;
        const reverseCleaned = hydratePartial(parsedFilter);
        setFilter(reverseCleaned);
      } catch (e) {
      } finally {
        setFilterInitialize(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterParam,
      hydratePartial,
      filterInitialize,
      compactExpandLocation?.allLocationsKey,
      compactExpandLocation?.includedCompactedLocationKey,
      compactExpandLocation?.excludedCompactedLocationKey,
      descendantsMap,
      locationMap,
    ]
  );

  const cleanFilter = useCallback(
    (defaultValues: T, qsParamName: string) => {
      setFilter(defaultValues);
      globalQuery.delete(qsParamName);
      const newGlobalQuery = globalQuery.toString();

      navigate({
        pathname: currentPathname,
        search: newGlobalQuery,
      });
    },
    [currentPathname, globalQuery, navigate]
  );

  return {
    filterInitialized: filterInitialize,
    filter,
    cleanFilter,
    updateQueryString,
  };
}
