import { Box, Heading, Stack, StackItem, usePrevious } from "@chakra-ui/react";
import { ApiFrequency, ApiWeeksOfMonth } from "@operations-hero/lib-api-client";
import { SingleValue } from "chakra-react-select";
import { isEqual } from "date-fns";
import { FC, useCallback, useEffect, useReducer } from "react";
import { ActionTypeEnum, CronReducer } from "./cron-helpers/CronHelper";
import {
  CronBoxOption,
  getInitialStateFromCronString,
} from "./cron-helpers/CronOptions";
import { RecurrenceSelect } from "./cron-selects/RecurrenceSelect";
import { RepeatsOnSelect } from "./cron-selects/RepeatOnSelect";
import { CronOptions } from "./CronBoxOptions";
import { CronNumberInput } from "./CronNumberInput";

export interface InitialCustomCronProps {
  initialCronExpression: string;
  initialFrecuency: ApiFrequency;
  initialExecuteEvery: number;
  weekOfMonth: ApiWeeksOfMonth;
}

export interface CronValues {
  cronExpression: string;
  frequency: ApiFrequency;
  executeEvery: number;
  weekOfMonth: ApiWeeksOfMonth;
}

interface CustomCronGeneratorProps {
  selectedStartDate: Date;
  InitialCustomCronValues: InitialCustomCronProps;
  withWeeklyOptions: boolean;
  getCronExpression?: (cronValues: CronValues) => void;
  disabledDays?: string;
  allowedDays?: number[];
}

export const CustomCronGenerator: FC<CustomCronGeneratorProps> = ({
  selectedStartDate,
  InitialCustomCronValues,
  withWeeklyOptions: withWeklyOptions,
  getCronExpression,
  disabledDays,
  allowedDays,
}) => {
  const prevSelectedDate = usePrevious(new Date(selectedStartDate));
  const [state, cronDispatch] = useReducer(
    CronReducer,
    getInitialStateFromCronString(InitialCustomCronValues, selectedStartDate)
  );

  const handleToggleDay = useCallback((selectedDay: CronBoxOption) => {
    cronDispatch({ type: ActionTypeEnum.ToggleDay, payload: selectedDay });
  }, []);

  const handleToggleMonth = useCallback((selectedMonth: CronBoxOption) => {
    cronDispatch({
      type: ActionTypeEnum.ToggleMonth,
      payload: selectedMonth,
    });
  }, []);

  const handleChangeRecurrence = useCallback(
    (recurrenceOption: SingleValue<CronBoxOption>) => {
      cronDispatch({
        type: ActionTypeEnum.SetRecurrence,
        payload: {
          option: recurrenceOption,
          selectedDateStr: selectedStartDate.toISOString(),
        },
      });
    },
    [selectedStartDate]
  );

  const handleOnChangeRepeatsOn = useCallback(
    (option: SingleValue<CronBoxOption>) => {
      cronDispatch({
        type: ActionTypeEnum.SetRepeatsOn,
        payload: {
          option,
          selectedDateStr: selectedStartDate
            ? selectedStartDate.toISOString()
            : null,
        },
      });
    },
    [selectedStartDate]
  );

  const handleOnChangeExecuteEvery = useCallback((value: number) => {
    cronDispatch({
      type: ActionTypeEnum.SetExecuteEvery,
      payload: value,
    });
  }, []);

  useEffect(() => {
    if (state.recurrence) {
      getCronExpression &&
        getCronExpression({
          cronExpression: state.cronExpression,
          frequency: state.recurrence.value as ApiFrequency,
          executeEvery: state.executeEvery,
          weekOfMonth: state.weekOfMonth,
        });
    }
  }, [
    state.cronExpression,
    state.recurrence,
    state.executeEvery,
    state.weekOfMonth,
    getCronExpression,
  ]);

  useEffect(() => {
    if (!isEqual(prevSelectedDate, selectedStartDate)) {
      cronDispatch({
        type: ActionTypeEnum.SetCronHourMinuteAndWeekOfMonth,
        payload: {
          selectedDateStr: selectedStartDate.toISOString(),
          weekOfMonth: InitialCustomCronValues.weekOfMonth,
        },
      });
    }
  }, [
    selectedStartDate,
    prevSelectedDate,
    InitialCustomCronValues.initialCronExpression,
    InitialCustomCronValues.weekOfMonth,
  ]);

  return (
    <Stack flexDir="row" flexWrap="wrap" mb={2}>
      <Heading size="md">Recurrence</Heading>

      <StackItem w="100%" display="flex" flexWrap="wrap" alignItems="center">
        <CronNumberInput
          name="repeatEvery"
          mr={4}
          min={1}
          max={99}
          maxW="74px"
          label="Repeat every"
          onChange={handleOnChangeExecuteEvery}
          value={state.executeEvery}
        >
          <RecurrenceSelect
            value={state.recurrence}
            onRecurrenceChange={handleChangeRecurrence}
          />

          {state.recurrence && state.recurrence.value === "monthly" && (
            <RepeatsOnSelect
              selectedStartDate={selectedStartDate}
              value={state.repeatsOn}
              handleChangeRepeat={handleOnChangeRepeatsOn}
              withWeklyOptions={withWeklyOptions}
            />
          )}
        </CronNumberInput>
      </StackItem>

      <Box display="flex" gap="2" flexWrap="wrap-reverse">
        {state.recurrence &&
          (state.recurrence.value === "weekly" ||
            state.recurrence.value === "monthly") && (
            <StackItem>
              <CronOptions
                label="For the months of"
                options={state.months}
                onChangeOption={handleToggleMonth}
                boxProps={{ maxW: "310px" }}
              />
            </StackItem>
          )}

        {state.recurrence &&
          (state.recurrence.value === "weekly" ||
            (state.recurrence.value === "monthly" && state.weekOfMonth)) && (
            <StackItem>
              <CronOptions
                label="On days"
                options={state.days}
                onChangeOption={handleToggleDay}
                boxProps={{ maxW: "330px" }}
                enabledDay={disabledDays}
                allowedDays={allowedDays}
              />
            </StackItem>
          )}
      </Box>
    </Stack>
  );
};
