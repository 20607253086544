import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Icon,
} from "@chakra-ui/react";
import { Dispatch, FC, SetStateAction } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

interface AttachmentAccordionProps {
  defaultIndex?: number[];
  setAccordionItem?: Dispatch<SetStateAction<number[] | undefined>>;
  children?: React.ReactNode;
}

export const AttachmentsAccordion: FC<AttachmentAccordionProps> = ({
  children,
  defaultIndex,
  setAccordionItem,
}) => {
  const handleOnClick = (isExpanded: boolean) => {
    if (isExpanded) {
      setAccordionItem && setAccordionItem(undefined);
    }
  };

  return (
    <Accordion allowToggle index={defaultIndex}>
      <AccordionItem borderY="none" isDisabled={false}>
        {(props) => (
          <>
            <AccordionButton
              ml={-2}
              w="max-content"
              color="blue.500"
              fontWeight="bold"
              onClick={() => handleOnClick(props.isExpanded)}
              _focus={{ boxShadow: "none" }}
              _hover={{ bgColor: "transparent" }}
            >
              {props.isExpanded ? "hide files" : "view files"}
              <Icon
                boxSize="24px"
                mt={1}
                as={props.isExpanded ? RiArrowDropUpLine : RiArrowDropDownLine}
              />
            </AccordionButton>
            <AccordionPanel>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
