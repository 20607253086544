import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface RemoveTaskbookParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  requestTaskbookId: string;
}

export const removeTaskbook = createAsyncThunk(
  "requests/removeTaskbook",
  async (params: RemoveTaskbookParams, thunkAPI) => {
    const { apiClient, accountId, requestId, requestTaskbookId } = params;

    await apiClient.removeRequestTaskbook(
      accountId,
      requestId,
      requestTaskbookId
    );

    return requestTaskbookId;
  }
);

export const removeTaskbookHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(removeTaskbook.fulfilled, (state, action) => {
    const taskbookIndex = state.taskbooks.findIndex(
      (taskbook) => taskbook.id === action.payload
    );
    state.taskbooks.splice(taskbookIndex, 1);
    state.taskbooksTotal -= 1;
  });
};
