import FullCalendar from "@fullcalendar/react";
import { MutableRefObject } from "react";

type nextOrPrevType = "next" | "previous";

export type CalendarMode = "requests" | "events";

export enum CalendarView {
  calendar = "calendar",
  assignee = "assignee",
  location = "location",
}

export enum CalendarType {
  timeGridWeek = "timeGridWeek",
  dayGridMonth = "dayGridMonth",
  timeGridDay = "timeGridDay",
  resourceTimelineMonth = "resourceTimelineMonth",
  resourceTimelineWeek = "resourceTimelineWeek",
  resourceTimelineDay = "resourceTimelineDay",
  listWeek = "listWeek",
  listMonth = "listMonth",
}

interface CustomButtonsParams<T> {
  filter: T;
  calendarMode?: CalendarMode;
  updateFilter: (newValue: T) => void;
  calendarComponentRef: MutableRefObject<FullCalendar | null>;
  openPrintModal?: () => void;
}

async function setDatesRange<T>(params: CustomButtonsParams<T>) {
  const { calendarComponentRef, updateFilter, filter } = params;
  if (!calendarComponentRef.current) return;
  const calendarApi = calendarComponentRef.current.getApi();
  const currentData = calendarApi.currentDataManager?.getCurrentData();

  if (!currentData || !currentData.dateProfile.activeRange) return;
  const { start: startDate, end: endDate } =
    currentData.dateProfile.activeRange;

  await updateFilter({
    ...filter,
    start: [startDate.toISOString(), endDate.toISOString()],
  });
}

function handleClickNextOrPrevious<T>(
  value: nextOrPrevType,
  params: CustomButtonsParams<T>
) {
  const { calendarComponentRef, updateFilter, filter, calendarMode } = params;
  if (!calendarComponentRef.current) return;
  const calendarApi = calendarComponentRef.current.getApi();
  value === "next" && calendarApi.next();
  value === "previous" && calendarApi.prev();
  setDatesRange({
    calendarMode,
    calendarComponentRef,
    updateFilter,
    filter,
  });
}

function handleClickHeaderToolOptions<T>(
  value: CalendarType,
  params: CustomButtonsParams<T>
) {
  const { calendarComponentRef, updateFilter, filter, calendarMode } = params;
  if (!calendarComponentRef.current) return;
  const calendarApi = calendarComponentRef.current.getApi();
  calendarApi.changeView(value);
  setDatesRange({
    calendarMode,
    calendarComponentRef,
    updateFilter,
    filter,
  });
}

function printList<T>(params: CustomButtonsParams<T>) {
  const { calendarComponentRef, openPrintModal } = params;
  if (!calendarComponentRef || !calendarComponentRef.current) return;
  openPrintModal && openPrintModal();
}

export function customButtons<T>({
  filter,
  calendarComponentRef,
  updateFilter,
  calendarMode,
  openPrintModal,
}: CustomButtonsParams<T>) {
  return {
    next: {
      click: () =>
        handleClickNextOrPrevious("next", {
          calendarMode,
          calendarComponentRef,
          filter,
          updateFilter,
        }),
    },
    prev: {
      click: () =>
        handleClickNextOrPrevious("previous", {
          calendarMode,
          calendarComponentRef,
          filter,
          updateFilter,
        }),
    },
    dayGridMonth: {
      text: "Month",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.dayGridMonth, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    timeGridWeek: {
      text: "Week",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.timeGridWeek, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    timeGridDay: {
      text: "Day",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.timeGridDay, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    resourceTimelineMonth: {
      text: "Month",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.resourceTimelineMonth, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    resourceTimelineWeek: {
      text: "Week",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.resourceTimelineWeek, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    resourceTimelineDay: {
      text: "Day",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.resourceTimelineDay, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    listWeek: {
      text: "List Week",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.listWeek, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    listMonth: {
      text: "List Month",
      click: () =>
        handleClickHeaderToolOptions(CalendarType.listMonth, {
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
        }),
    },
    print: {
      click: () =>
        printList({
          calendarMode,
          filter,
          calendarComponentRef,
          updateFilter,
          openPrintModal,
        }),
    },
  };
}
