import {
  ApiClient,
  ApiPagedResult,
  ApiPagingProjectOptions,
  ApiProject,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export type ProjectListFilters = ApiPagingProjectOptions & {
  status?: string | string[];
  supervisor?: string | string[];
  total: number;
};

export type LoadingStatus = "idle" | "pending" | "fulfilled" | "rejected";
export type ProjectListSliceProps = {
  projects: ApiProject[];
  filters: ProjectListFilters;
  loadingStatus: LoadingStatus;
};

const initialState: ProjectListSliceProps = {
  projects: [],
  loadingStatus: "idle",
  filters: {
    page: 1,
    pageSize: 20,
    total: 0,
    includeInactive: false,
  },
};

type LoadProjectsParams = {
  apiClient: ApiClient;
  filters?: ApiPagingProjectOptions & {
    status?: string | string[];
    supervisor?: string | string[];
    total: number;
  };
};
export const loadProjects = createAsyncThunk<
  ApiPagedResult<ApiProject>,
  LoadProjectsParams
>(
  "projects/load",
  async ({ apiClient, filters: filtersToOverride }, thunkAPI) => {
    const { auth, projectListSlice } = thunkAPI.getState() as RootState;
    const { currentAccount } = auth;

    const { filters } = projectListSlice;
    const response = await apiClient.findProjects(
      currentAccount.id,
      filtersToOverride ? filtersToOverride : filters
    );
    return response;
  }
);

export const updateFilters = createAsyncThunk(
  "filters/update",
  (filters: Partial<ProjectListSliceProps["filters"]>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { projectListSlice } = state;
    const updated = {
      ...projectListSlice.filters,
      ...filters,
    };
    return updated;
  }
);

export const clearFilters = createAsyncThunk(
  "filters/clear",
  (params, thunkAPI) => {
    const clearedFilters = { ...initialState.filters };
    return clearedFilters;
  }
);

const projectListSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    unload: (state) => {
      state.projects = [];
      state.loadingStatus = "idle";
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProjects.pending, (state, action) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(loadProjects.rejected, (state, action) => {
      state.loadingStatus = "rejected";
    });
    builder.addCase(loadProjects.fulfilled, (state, action) => {
      state.loadingStatus = "fulfilled";
      const { data, total } = action.payload;
      state.projects = data;
      state.filters = {
        ...state.filters,
        total,
      };
    });
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
    });
    builder.addCase(clearFilters.fulfilled, (state, action) => {
      state.filters = { ...initialState.filters };
    });
  },
});

export const { unload } = projectListSlice.actions;
export default projectListSlice.reducer;
