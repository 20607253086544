import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Collapse,
  Flex,
  Icon,
  Link,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAllowedDashboards } from "../../../../hooks/useDashboards";
import { useNavActionItems } from "../../../../hooks/useNavActionItems";
import {
  NavItem,
  useNavProductItems,
} from "../../../../hooks/useNavProductItems";

export type MobileSideBarProps = {
  isOpen: boolean;
  onToggle: () => void;
};

export const MobileSideBar: FC<MobileSideBarProps> = ({ isOpen, onToggle }) => {
  const { navItems: productItems, energyCallback } = useNavProductItems();
  const { hasReports, hasCalendar } = useNavActionItems();
  const navItems = useMemo(() => {
    const items = [...productItems];
    if (hasCalendar) {
      items.push({
        label: "Calendar",
        to: "/calendar",
      });
    }

    if (hasReports) {
      items.push({
        label: "Reports",
        to: "/reports",
      });
    }
    return items;
  }, [hasReports, hasCalendar, productItems]);
  return (
    <>
      <Collapse in={isOpen} animateOpacity>
        <SideBarMenu
          navItems={navItems}
          onClick={onToggle}
          energyCallback={energyCallback}
        />
      </Collapse>
    </>
  );
};

export const SideBarMenu = ({
  navItems,
  onClick,
  energyCallback,
}: {
  navItems: NavItem[];
  onClick: () => void;
  energyCallback: () => void;
}) => {
  const { userDashboards } = useAllowedDashboards();

  return (
    <Stack bg={useColorModeValue("white", "gray.800")} p={4}>
      {userDashboards.length > 0 &&
        userDashboards.map((item) => (
          <SideBarMenuItem
            to={item.path}
            onClick={onClick}
            label={`${item.label} Dashboard`}
            key={`DashboardMenu::${item.label}`}
          />
        ))}

      {navItems.map((navItem) => (
        <SideBarMenuItem
          key={navItem.label}
          {...navItem}
          onClick={navItem.label === "Energy" ? energyCallback : onClick}
        />
      ))}
    </Stack>
  );
};

const SideBarMenuItem = ({
  label,
  children,
  href,
  to,
  Element,
  onClick,
}: NavItem & { onClick: () => void }) => {
  const { isOpen, onToggle } = useDisclosure();
  const selectedItemColor = useColorModeValue("gray.600", "gray.200");

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        {Element != null ? (
          <Element onClick={onClick} />
        ) : (
          <Link
            as={RouterLink}
            to={to ? to : ""}
            fontWeight="600"
            color={selectedItemColor}
            onClick={onClick}
          >
            {label}
          </Link>
        )}
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) =>
              child.Element ? (
                <child.Element onClick={() => {}} />
              ) : (
                <Link
                  key={child.label}
                  py={2}
                  as={child.to ? RouterLink : undefined}
                  href={child.href ?? undefined}
                  to={child.to ? child.to : ""}
                >
                  {child.label}
                </Link>
              )
            )}
        </Stack>
      </Collapse>
    </Stack>
  );
};
