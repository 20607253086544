import { Badge, Box, Icon } from "@chakra-ui/react";
import {
  ApiRequestPriority,
  ApiRequestType,
} from "@operations-hero/lib-api-client";
import {
  ChakraStylesConfig,
  GroupBase,
  OptionProps,
  Select,
} from "chakra-react-select";
import React, { useMemo } from "react";
import { IoCaretDownOutline } from "react-icons/io5";
import { createOptionComponent, CustomSelectStyles } from "./select-overrides";
import { CustomSelectComponentProp } from "./select-overrides-types";

export const BADGE_LABEL_COLOR = "#2596be";
export interface RequestTypeSelectProps {
  type: ApiRequestType;
  onChange?: (status: ApiRequestType) => void;
  isDisabled?: boolean;
  priority?: ApiRequestPriority;
}

export type RequestTypeValueLabel = {
  value: ApiRequestType;
  label: string;
};

type RequestTypeSelectStyles = ChakraStylesConfig<
  { value: ApiRequestType; label: ApiRequestType },
  false,
  GroupBase<{ value: ApiRequestType; label: ApiRequestType }>
>;

type RequestTypeProps = OptionProps<
  RequestTypeValueLabel,
  false,
  GroupBase<RequestTypeValueLabel>
>;

export interface TypeBadgeProps {
  value: RequestTypeValueLabel;
  isControlValue?: boolean;
  isDisabled?: boolean;
  isSelected: boolean;
}

const TypeBadge: React.FC<TypeBadgeProps> = ({
  value,
  isDisabled,
  isControlValue,
  isSelected,
}) => (
  <Badge
    key={`${value}`}
    fontSize="sm"
    colorScheme="gray"
    variant="none"
    color={isSelected ? "white" : BADGE_LABEL_COLOR}
  >
    {value.label}
    {!isDisabled && isControlValue && (
      <Icon as={IoCaretDownOutline} boxSize={3} />
    )}
  </Badge>
);

const CustomOptionComponent = createOptionComponent(TypeBadge);

const CustomSingleValueComponent = (props: RequestTypeProps) => {
  const { data, innerProps, isDisabled } = props;
  return (
    <Box {...innerProps} cursor="pointer">
      <TypeBadge
        value={data}
        isControlValue
        isDisabled={isDisabled}
        isSelected={false}
      />
    </Box>
  );
};

export const RequestTypeSelect = ({
  type,
  onChange,
  isDisabled,
  priority,
}: RequestTypeSelectProps) => {
  const options = [
    ApiRequestType.corrective,
    ApiRequestType.scheduled,
    ApiRequestType.triggered,
    ApiRequestType.predetermined,
    ApiRequestType.predictive,
  ].map((x) => ({
    value: x,
    label: x,
  }));

  const handleChange = (item: RequestTypeValueLabel | null) => {
    if (onChange && item) onChange(item.value);
  };

  const customStyles = useMemo(
    () => CustomSelectStyles() as RequestTypeSelectStyles,
    [],
  );

  const components = useMemo(
    (): CustomSelectComponentProp => ({
      SingleValue: (props: RequestTypeProps) =>
        CustomSingleValueComponent(props),
      Option: (props: RequestTypeProps) => CustomOptionComponent(props),
      DropdownIndicator: null,
      IndicatorSeparator: null,
    }),
    [],
  );

  return (
    <Select
      value={options.find((x) => x.value === type)}
      options={options}
      onChange={handleChange}
      components={components}
      getOptionLabel={(entry) => entry.label}
      isSearchable={false}
      chakraStyles={customStyles}
      menuPlacement="auto"
      isDisabled={isDisabled}
    />
  );
};
