import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { BulkPrintFormat } from "./documents/BulkPrintFormat";

export interface BulkPrintModalProps {
  isOpen: boolean;
  onClose: (selectedFormat?: BulkPrintFormat) => void;
  loading: boolean;
  bulkMode?: "selection" | "all";
}

export const BulkPrintModal = ({
  isOpen,
  onClose,
  loading,
  bulkMode = "all",
}: BulkPrintModalProps) => {
  const { requests: allRequests } = useSelector(
    (state: RootState) => state.requestList
  );
  const { requests: bulkRequests } = useSelector(
    (state: RootState) => state.requestsBulkActionsSlice
  );

  const [format, setFormat] = useState<BulkPrintFormat>();
  const [requests, setRequests] = useState<ApiRequest[]>([]);
  const modalClose = useCallback(
    (selectedFormat?: BulkPrintFormat) => {
      setFormat(selectedFormat);
      onClose(selectedFormat);
    },
    [onClose]
  );
  useEffect(() => {
    if (bulkMode === "all") setRequests(allRequests);
    else {
      setRequests(
        bulkRequests
          .filter((item) => item.selected === true)
          .map((item) => item.request)
      );
    }
  }, [allRequests, bulkRequests, bulkMode]);
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={() => modalClose()}
      scrollBehavior={"inside"}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Bulk Print</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} mb={4}>
            <Text>
              Which format would you like to bulk print the current page with{" "}
              {requests.length} {requests.length === 1 ? "request" : "requests"}
            </Text>
            {loading ? (
              <Flex w="100%" flexDirection="column" alignItems="center" gap={4}>
                <Skeleton w="100%" h="40px" borderRadius={6} />
                <Skeleton w="100%" h="40px" borderRadius={6} />
                <Skeleton w="100%" h="40px" borderRadius={6} />
              </Flex>
            ) : (
              <>
                <Button
                  size="lg"
                  flexDir={"column"}
                  py={4}
                  onClick={() => modalClose("full")}
                  isDisabled={loading}
                  isLoading={loading && format === "full"}
                >
                  <Text fontSize={"md"}>Full Format</Text>
                  <Text fontSize="sm">
                    Includes a notes section and signature line
                  </Text>
                </Button>

                <Button
                  size="lg"
                  flexDir={"column"}
                  py={4}
                  onClick={() => modalClose("slim")}
                  isDisabled={loading}
                  isLoading={loading && format === "slim"}
                >
                  <Text fontSize={"md"}>Slim Format</Text>
                  <Text fontSize="sm">Fits 2 per page, excludes notes</Text>
                </Button>
                <Button
                  size="lg"
                  flexDir={"column"}
                  py={4}
                  onClick={() => modalClose("listing")}
                  isDisabled={loading}
                  isLoading={loading && format === "listing"}
                >
                  <Text fontSize={"md"}>Listing Format</Text>
                  <Text fontSize="sm">
                    Fits several per page, excludes notes
                  </Text>
                </Button>
              </>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
