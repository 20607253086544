import { Box } from "@chakra-ui/react";
import { ApiParsedAsset, ApiVendor } from "@operations-hero/lib-api-client";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Attachment } from "../../../../components/attachments/Attachments";
import { RootState } from "../../../../store";
import NameplateDataModalPage from "./AiPhotoQualityRatingsModal";
import EditAiAssetModal from "./EditAiAssetForm";
import VerifyAssetModal from "./VerifyAssetForm";

interface ParsedAssetModalProps {
  isNameplateModalOpen: boolean;
  apiParsedAsset: ApiParsedAsset;
  latlong: { lat: string; long: string };
  closeNameplateDataModal: () => void;
  scannedAttachments: Attachment[];
  onClose: () => void;
  manufacturer: ApiVendor | null;
}

const VerifyAssetSection: React.FC<ParsedAssetModalProps> = ({
  isNameplateModalOpen,
  apiParsedAsset,
  latlong,
  closeNameplateDataModal,
  scannedAttachments,
  onClose,
  manufacturer,
}) => {
  const codeRef = React.createRef<HTMLDivElement>();
  const { assetId } = useParams<{ assetId?: string }>();
  const { apiAsset } = useSelector((state: RootState) => state.aiAssetSlice);

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [codeRef]);

  return (
    <>
      {isNameplateModalOpen && (
        <NameplateDataModalPage
          onClose={closeNameplateDataModal}
          parsedAsset={apiParsedAsset}
        />
      )}
      <Box ref={codeRef} pb={10}>
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <Box width="100%" height="2px" backgroundColor="gray.300" />
        </Box>
        <Box>
          {assetId || apiAsset ? (
            <EditAiAssetModal
              parsedAsset={apiParsedAsset}
              latlong={latlong}
              scannedAttachments={scannedAttachments}
              manufacturer={manufacturer}
              onClose={onClose}
            />
          ) : (
            <VerifyAssetModal
              parsedAsset={apiParsedAsset}
              latlong={latlong}
              scannedAttachments={scannedAttachments}
              manufacturer={manufacturer}
              onClose={onClose}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default VerifyAssetSection;
