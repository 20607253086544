import { Box, Skeleton, useBreakpointValue } from "@chakra-ui/react";

const SkeletonMobile = () => (
  <Box p={6} display="flex" gap={4}>
    <Box display="flex" flexDirection="column" w="25%" gap={3}>
      <Skeleton height={4} />
    </Box>
    <Box display="flex" flexDirection="column" w="25%" gap={3}>
      <Skeleton height={4} />
    </Box>
    <Box display="flex" flexDirection="column" w="25%" gap={3}>
      <Skeleton height={4} />
    </Box>
    <Box display="flex" flexDirection="column" w="25%" gap={3}>
      <Skeleton height={4} />
    </Box>
  </Box>
);

const SkeletonDesktop = () => (
  <Box p={6} display="flex" gap={4}>
    <Box display="flex" flexDirection="column" w="16.6%" gap={3}>
      <Skeleton height={5} />
    </Box>
    <Box display="flex" flexDirection="column" w="16.6%" gap={3}>
      <Skeleton height={5} />
    </Box>
    <Box display="flex" flexDirection="column" w="16.6%" gap={3}>
      <Skeleton height={5} />
    </Box>
    <Box display="flex" flexDirection="column" w="16.6%" gap={3}>
      <Skeleton height={5} />
    </Box>
    <Box display="flex" flexDirection="column" w="16.6%" gap={3}>
      <Skeleton height={5} />
    </Box>
    <Box display="flex" flexDirection="column" w="16.6%" gap={3}>
      <Skeleton height={5} />
    </Box>
  </Box>
);
interface SkeletonProps {
  alwaysMobile?: boolean;
}
export const SkeletonInventory = ({ alwaysMobile }: SkeletonProps) => {
  const isCondensed = useBreakpointValue({
    base: true,
    md: alwaysMobile ? true : false,
  });
  return (
    <Box as="ul">
      {isCondensed
        ? [1, 2, 3].map((item) => <SkeletonMobile key={item} />)
        : [1, 2, 3].map((item) => <SkeletonDesktop key={item} />)}
    </Box>
  );
};
