import { Box, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { ApiLocationType } from "@operations-hero/lib-api-client";
import React, { useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

type ValueLabel = {
  value: string;
  label: string;
};

export const LocationTypeFilter = ({
  onChange,
}: {
  onChange: React.Dispatch<React.SetStateAction<ApiLocationType | undefined>>;
}) => {
  const locations = useSelector(
    (state: RootState) => state.localCache.locations
  );
  const locationStats = useMemo(
    () =>
      locations.reduce<{ [key: string]: number }>((stats, location) => {
        stats[location.type] = (stats[location.type] || 0) + 1;

        return stats;
      }, {}),
    [locations]
  );
  const [values, setValues] = useState<ValueLabel[]>([
    {
      value: "all",
      label: `All (${Object.values(locationStats).reduce((val, sum) => {
        sum = sum + val;
        return sum;
      })})`,
    },
  ]);

  const DropdownRenderer = ({ props, methods }: SelectRenderer<ValueLabel>) => {
    const bgColor = useColorModeValue(undefined, "gray.700");
    const textColor = useColorModeValue("gray.400", "gray.800");

    return (
      // eslint-disable-next-line react/jsx-no-undef
      <Box p={2} backgroundColor={bgColor}>
        <VStack width="100%">
          {props.options.map((p) => (
            <Box width="100%" key={`typeFilter::${p.value}`}>
              <Text
                color={methods.isSelected(p) ? textColor : "inherit"}
                width="100%"
                onClick={() => methods.addItem(p)}
              >
                {capitalizeFirstLetter(p.label)}
              </Text>
            </Box>
          ))}
          none
        </VStack>
      </Box>
    );
  };

  const ContentRenderer = ({ state }: SelectRenderer<ValueLabel>) => {
    return (
      <Box p={1} width="150px">
        {state.values.length === 0 && "Show All Categories"}
        {state.values.length === 1 && (
          <Text>
            {state.values[0].value === "all"
              ? "Show All Categories"
              : capitalizeFirstLetter(state.values[0].value)}
          </Text>
        )}
      </Box>
    );
  };

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const options = useMemo(() => {
    const val = Object.values(ApiLocationType).map((type) => {
      return {
        value: type as string,
        label: `${type} (${locationStats[type] || 0})`,
      };
    });
    val.unshift({
      value: "all",
      label: `All (${Object.values(locationStats).reduce((val, sum) => {
        sum = sum + val;
        return sum;
      })})`,
    });
    return val;
  }, [locationStats]);

  return (
    <Select
      multi={false}
      className={themeClass}
      options={options}
      values={values}
      onChange={(val) => {
        if (val && val.length > 0 && val[0].value !== "all") {
          onChange(val[0].value as ApiLocationType);
        } else {
          onChange(undefined);
        }
        setValues(val);
      }}
      searchable={true}
      searchBy="label"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      dropdownHeight="300"
      dropdownRenderer={(props) => <DropdownRenderer {...props} />}
      contentRenderer={ContentRenderer}
    />
  );
};
