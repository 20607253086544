import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Container,
  Flex,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useInterval } from "usehooks-ts";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ReadOnlyTextEditor } from "../../../components/form-helpers/rich-text-editor/RichTextEditorReadOnly";
import { RootState, useThunkDispatch } from "../../../store";
import {
  AnnouncementSetting,
  ANNOUNCEMENT_STYLE,
  setAnnouncement,
  setIsAnnouncementExpired,
  SYSTEM_ANNOUNCEMENT,
} from "../../../store/announcement-slice";

export const Announcement = () => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });
  const { apiClient, currentAccount } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const { announcement, isAnnouncementExpired } = useSelector(
    (state: RootState) => state.announcement,
  );

  const getAnnouncement = useCallback(async () => {
    const getAnnouncement = await apiClient.getAccountSettings(
      currentAccount.id,
      [SYSTEM_ANNOUNCEMENT],
    );
    thunkDispatch(
      setAnnouncement(
        getAnnouncement[SYSTEM_ANNOUNCEMENT] as AnnouncementSetting,
      ),
    );

    const now = new Date().getTime();
    const announcement = getAnnouncement[
      SYSTEM_ANNOUNCEMENT
    ] as AnnouncementSetting;

    if (!announcement) {
      return;
    }

    if (announcement.expires === null) {
      thunkDispatch(setIsAnnouncementExpired(false));
    } else {
      const expires = new Date(announcement?.expires as string).getTime();
      if (now >= expires) thunkDispatch(setIsAnnouncementExpired(true));
      else return thunkDispatch(setIsAnnouncementExpired(false));
    }
  }, [apiClient, currentAccount.id, thunkDispatch]);

  useInterval(() => {
    getAnnouncement();
  }, 300000);

  useEffect(() => {
    getAnnouncement();
  }, [getAnnouncement]);

  return isVisible &&
    announcement &&
    announcement.message &&
    !isAnnouncementExpired ? (
    <Flex>
      <Container maxWidth="8xl" pt={4} pb={0}>
        <Alert
          status={
            announcement.color && ANNOUNCEMENT_STYLE[announcement.color]
              ? (ANNOUNCEMENT_STYLE[announcement.color] as
                  | "success"
                  | "info"
                  | "warning"
                  | "error"
                  | undefined)
              : "info"
          }
        >
          <AlertIcon />
          <Stack flex="1">
            <AlertTitle mr={2}>Announcement</AlertTitle>
            <AlertDescription>
              <ReadOnlyTextEditor value={announcement.message} />
            </AlertDescription>
          </Stack>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={onClose}
          />
        </Alert>
      </Container>
    </Flex>
  ) : null;
};
