import { Box } from "@chakra-ui/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar, { ToolbarInput } from "@fullcalendar/react";
import { MutableRefObject, useCallback, useMemo } from "react";
import "./mini-calendar.css";

export type MiniCalenderProps = {
  onDateClick: (arg: { date: Date }) => void;
  miniCalendarRef: MutableRefObject<FullCalendar | null>;
};

export const MiniCalendar = ({
  onDateClick,
  miniCalendarRef,
}: MiniCalenderProps) => {
  const miniCalenderToolbar = useMemo<ToolbarInput>(
    () => ({
      start: "title",
      center: "",
      end: "prev,next",
    }),
    [],
  );
  const miniCalendarDayHeaderContent = useCallback((hookProps: any) => {
    const { dow } = hookProps;
    if (dow === 0) return "S";
    if (dow === 1) return "M";
    if (dow === 2) return "T";
    if (dow === 3) return "W";
    if (dow === 4) return "T";
    if (dow === 5) return "F";
    if (dow === 6) return "S";
    return hookProps.dow;
  }, []);

  return (
    <Box className="mini-calendar">
      <FullCalendar
        plugins={[interactionPlugin, dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={miniCalenderToolbar}
        dayHeaderContent={miniCalendarDayHeaderContent}
        height="300px"
        dateClick={onDateClick}
        ref={miniCalendarRef}
        schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE}
      />
    </Box>
  );
};
