export const isInsuranceExpired = (
  insuranceExpiration?: string | null,
  occurrenceStart?: string | null
) => {
  if (!insuranceExpiration) return;
  const insurance = new Date(insuranceExpiration);
  const start = occurrenceStart ? new Date(occurrenceStart) : new Date();
  if (insurance.getTime() < start.getTime()) {
    return true;
  }
  return false;
};
