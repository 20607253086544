import { Box, Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import {
  ApiServiceSumary,
  ApiSpaceSummary,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import {
  ApiQuestionResponse,
  CreateApiQuestionResponse,
} from "@operations-hero/lib-api-client/dist/models/QuestionResponse";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../../hooks/showToast";
import { RootState } from "../../../../../store";
import {
  addQuestionResponse,
  updateQuestionResponse,
} from "../../../../../store/events/event-details.slice";
import { QuestionResponseFields } from "./QuestionReponseFields";
import {
  QuestionsResponses,
  questionsResponsesReducer,
} from "./QuestionResponsesReducer";

export const EditQuestionsResponses = ({
  eventId,
  closeQuestions,
  hideDivider,
}: {
  eventId: string;
  closeQuestions?: () => void;
  hideDivider?: boolean;
}) => {
  const dispatch = useDispatch();
  const { currentAccount, apiClient } = useAuthentication();
  const showToast = useShowToast();

  const [isLoading, setIsLoading] = useState(false);

  const { eventDates, questionResponses, event } = useSelector(
    (state: RootState) => state.eventDetails
  );

  const [uniqueVenueDates, setUniqueVenueDates] = useState<ApiVenueSummary[]>();
  const [uniqueSpacesDates, setUniqueSpacesDates] =
    useState<ApiSpaceSummary[]>();

  const [questionResponsesRecord, setQuestionResponsesRecord] =
    useState<Record<string, ApiQuestionResponse>>();

  const venueQuestionState: QuestionsResponses[] = useMemo(() => {
    const response: QuestionsResponses[] = [];
    uniqueVenueDates &&
      uniqueVenueDates.forEach((venue) => {
        response.push({
          eventId: eventId,
          object: venue,
          questionsResponses: venue.questions.map((question) => {
            return {
              question,
              questionResponse:
                questionResponsesRecord &&
                questionResponsesRecord[`${question.id}:${venue.id}`]
                  ? questionResponsesRecord[`${question.id}:${venue.id}`]
                  : ({
                      id: "new",
                      questionId: question.id,
                      eventId: eventId,
                      venueId: venue.id,
                      spaceId: null,
                      type: question.type,
                    } as ApiQuestionResponse),
            };
          }),
        });
      });
    return response;
  }, [eventId, questionResponsesRecord, uniqueVenueDates]);

  const spaceQuestionState: QuestionsResponses[] = useMemo(() => {
    const response: QuestionsResponses[] = [];
    uniqueSpacesDates &&
      uniqueSpacesDates.forEach((space) => {
        response.push({
          eventId: eventId,
          object: space,
          questionsResponses: space.questions.map((question) => {
            return {
              question,
              questionResponse:
                questionResponsesRecord &&
                questionResponsesRecord[`${question.id}:${space.id}`]
                  ? questionResponsesRecord[`${question.id}:${space.id}`]
                  : ({
                      id: "new",
                      questionId: question.id,
                      eventId: eventId,
                      venueId: null,
                      spaceId: space.id,
                      type: question.type,
                    } as ApiQuestionResponse),
            };
          }),
        });
      });
    return response;
  }, [eventId, questionResponsesRecord, uniqueSpacesDates]);

  const serviceQuestionState: QuestionsResponses[] = useMemo(() => {
    if (!event) return [];
    const response: QuestionsResponses[] = [];
    event.services.forEach((service) => {
      if (service.questions.length > 0) {
        response.push({
          eventId: eventId,
          object: service as ApiServiceSumary,
          questionsResponses: service.questions.map((question) => {
            return {
              question,
              questionResponse:
                questionResponsesRecord &&
                questionResponsesRecord[`${question.id}:${service.id}`]
                  ? questionResponsesRecord[`${question.id}:${service.id}`]
                  : ({
                      id: "new",
                      questionId: question.id,
                      eventId: eventId,
                      venueId: null,
                      spaceId: null,
                      serviceId: (service as ApiServiceSumary).id,
                      type: question.type,
                    } as ApiQuestionResponse),
            };
          }),
        });
      }
    });

    return response;
  }, [event, eventId, questionResponsesRecord]);

  const [venueQuestionsResponses, dispatchVenueQuestionsResponses] = useReducer(
    questionsResponsesReducer,
    venueQuestionState
  );

  const [spaceQuestionsResponses, dispatchSpaceQuestionsResponses] = useReducer(
    questionsResponsesReducer,
    spaceQuestionState
  );

  const [serviceQuestionsResponses, dispatchServiceQuestionsResponses] =
    useReducer(questionsResponsesReducer, serviceQuestionState);

  const handleChangeVenueResponse = useCallback(
    (
      idxVenue: number,
      idxResponse: number,
      value: string | number | boolean | string[]
    ) => {
      const newResponse = {
        ...venueQuestionsResponses[idxVenue].questionsResponses[idxResponse]
          .questionResponse,
      };
      newResponse.value = value;
      const newValues = [...venueQuestionsResponses];
      newValues[idxVenue].questionsResponses[idxResponse].questionResponse =
        newResponse;
      dispatchVenueQuestionsResponses(newValues);
    },
    [venueQuestionsResponses]
  );

  const handleChangeSpaceResponse = useCallback(
    (
      idxSpace: number,
      idxResponse: number,
      value: string | number | boolean | string[]
    ) => {
      const newResponse = {
        ...spaceQuestionsResponses[idxSpace].questionsResponses[idxResponse]
          .questionResponse,
      };
      newResponse.value = value;
      const newValues = [...spaceQuestionsResponses];
      newValues[idxSpace].questionsResponses[idxResponse].questionResponse =
        newResponse;
      dispatchSpaceQuestionsResponses(newValues);
    },
    [spaceQuestionsResponses]
  );

  const handleChangeServiceResponse = useCallback(
    (
      idxService: number,
      idxResponse: number,
      value: string | number | boolean | string[]
    ) => {
      const newResponse = {
        ...serviceQuestionsResponses[idxService].questionsResponses[idxResponse]
          .questionResponse,
      };
      newResponse.value = value;
      const newValues = [...serviceQuestionsResponses];
      newValues[idxService].questionsResponses[idxResponse].questionResponse =
        newResponse;
      dispatchServiceQuestionsResponses(newValues);
    },
    [serviceQuestionsResponses]
  );

  const handleOnSaveResponses = useCallback(() => {
    setIsLoading(true);
    let promiseVenues: Promise<void>[] = [];
    let promiseSpaces: Promise<void>[] = [];
    let promiseServices: Promise<void>[] = [];

    venueQuestionsResponses.forEach((venueResp) => {
      promiseVenues = venueResp.questionsResponses.map(async (resp, idx) => {
        if (resp.questionResponse.id !== "new") {
          return apiClient
            .updateQuestionResponse(
              currentAccount.id,
              resp.questionResponse.id,
              {
                value: resp.questionResponse.value,
                type: resp.questionResponse.type,
              }
            )
            .then((response) => {
              dispatch(updateQuestionResponse(response));
            });
        }

        if (
          resp.questionResponse.id === "new" &&
          resp.questionResponse.value !== undefined
        ) {
          const responseToCreate: CreateApiQuestionResponse = {
            questionId: resp.questionResponse.questionId,
            eventId,
            venueId: resp.questionResponse.venueId,
            spaceId: resp.questionResponse.spaceId,
            serviceId: resp.questionResponse.serviceId,
            type: resp.questionResponse.type,
            value: resp.questionResponse.value,
          };
          return apiClient
            .createQuestionResponse(currentAccount.id, {
              ...responseToCreate,
            })
            .then((response) => {
              dispatch(addQuestionResponse(response));
            });
        }
      });
    });

    spaceQuestionsResponses.forEach((spaceResp, index) => {
      promiseSpaces = spaceResp.questionsResponses.map(async (resp, idx) => {
        if (resp.questionResponse.id !== "new") {
          return apiClient
            .updateQuestionResponse(
              currentAccount.id,
              resp.questionResponse.id,
              {
                value: resp.questionResponse.value,
                type: resp.questionResponse.type,
              }
            )
            .then((response) => {
              dispatch(updateQuestionResponse(response));
            });
        }

        if (
          resp.questionResponse.id === "new" &&
          resp.questionResponse.value !== undefined
        ) {
          const responseToCreate: CreateApiQuestionResponse = {
            questionId: resp.questionResponse.questionId,
            eventId,
            venueId: resp.questionResponse.venueId,
            spaceId: resp.questionResponse.spaceId,
            serviceId: resp.questionResponse.serviceId,
            type: resp.questionResponse.type,
            value: resp.questionResponse.value,
          };
          return apiClient
            .createQuestionResponse(currentAccount.id, {
              ...responseToCreate,
            })
            .then((response) => {
              dispatch(addQuestionResponse(response));
            });
        }
      });
    });

    serviceQuestionsResponses.forEach((serviceResp, index) => {
      promiseServices = serviceResp.questionsResponses.map(
        async (resp, idx) => {
          if (resp.questionResponse.id !== "new") {
            return apiClient
              .updateQuestionResponse(
                currentAccount.id,
                resp.questionResponse.id,
                {
                  value: resp.questionResponse.value,
                  type: resp.questionResponse.type,
                }
              )
              .then((response) => {
                dispatch(updateQuestionResponse(response));
              });
          }

          if (resp.questionResponse.id === "new") {
            const responseToCreate: CreateApiQuestionResponse = {
              questionId: resp.questionResponse.questionId,
              eventId,
              venueId: resp.questionResponse.venueId,
              spaceId: resp.questionResponse.spaceId,
              serviceId: resp.questionResponse.serviceId,
              type: resp.questionResponse.type,
              value: resp.questionResponse.value,
            };
            return apiClient
              .createQuestionResponse(currentAccount.id, {
                ...responseToCreate,
              })
              .then((response) => {
                dispatch(addQuestionResponse(response));
              });
          }
        }
      );
    });

    Promise.all([...promiseVenues, ...promiseSpaces, ...promiseServices])
      .then(() => {
        showToast("success", "Question responses were successfully updated.");
      })
      .catch(() => {
        showToast(
          "error",
          "Something went wrong when updating the question responses."
        );
      })
      .finally(() => {
        closeQuestions && closeQuestions();
        setIsLoading(false);
      });
  }, [
    apiClient,
    closeQuestions,
    currentAccount.id,
    dispatch,
    eventId,
    serviceQuestionsResponses,
    showToast,
    spaceQuestionsResponses,
    venueQuestionsResponses,
  ]);

  useEffect(() => {
    dispatchVenueQuestionsResponses(venueQuestionState);
  }, [venueQuestionState]);

  useEffect(() => {
    dispatchSpaceQuestionsResponses(spaceQuestionState);
  }, [spaceQuestionState]);

  useEffect(() => {
    dispatchServiceQuestionsResponses(serviceQuestionState);
  }, [serviceQuestionState]);

  useEffect(() => {
    const res = questionResponses.reduce(
      (result, data) => {
        if (data.venueId) result[`${data.questionId}:${data.venueId}`] = data;
        if (data.spaceId) result[`${data.questionId}:${data.spaceId}`] = data;
        if (data.serviceId)
          result[`${data.questionId}:${data.serviceId}`] = data;
        return result;
      },
      {} as Record<string, ApiQuestionResponse>
    );
    setQuestionResponsesRecord(res);
  }, [questionResponses]);

  useEffect(() => {
    const venue: Record<string, ApiVenueSummary> = {};
    const spaces: Record<string, ApiSpaceSummary> = {};
    eventDates.forEach((eventDate) => {
      if (eventDate.active) {
        venue[eventDate.venue.id] = eventDate.venue;
        eventDate.spaces.forEach((s) => {
          spaces[s.id] = s;
        });
      }
    });

    setUniqueVenueDates(Object.values(venue));
    setUniqueSpacesDates(Object.values(spaces));
  }, [eventDates]);

  return (
    <VStack gap={4} paddingBottom={4}>
      {venueQuestionsResponses.some(
        (vqr) => vqr.questionsResponses.length > 0
      ) ||
      spaceQuestionsResponses.some(
        (sqr) => sqr.questionsResponses.length > 0
      ) ||
      serviceQuestionsResponses.some(
        (sqr) => sqr.questionsResponses.length > 0
      ) ? (
        <>
          {venueQuestionsResponses.map((venueQuestionResponse, index) => {
            return (
              <Fragment
                key={`venueResponses::${venueQuestionResponse.object.id}${index}`}
              >
                {venueQuestionResponse.questionsResponses.length > 0 && (
                  <Text fontWeight="semibold" width="100%">
                    {(venueQuestionResponse.object as ApiVenueSummary).name}
                  </Text>
                )}
                {venueQuestionResponse.questionsResponses.map((vr, rIdx) => {
                  return (
                    <Box
                      gap={4}
                      width="100%"
                      key={`questionsResponses::${vr.question.id}::${vr.questionResponse.id}`}
                      position="relative"
                    >
                      <QuestionResponseFields
                        question={vr.question}
                        value={vr.questionResponse?.value}
                        index={rIdx + 1}
                        onChange={(
                          value: string | number | boolean | string[]
                        ) => {
                          handleChangeVenueResponse(index, rIdx, value);
                        }}
                        isInvalid={
                          vr.question.required && !vr.questionResponse?.value
                        }
                      />
                    </Box>
                  );
                })}
              </Fragment>
            );
          })}

          {spaceQuestionsResponses.map((spaceQuestionResponse, index) => {
            return (
              <Fragment
                key={`venueResponses::${spaceQuestionResponse.object.id}${index}`}
              >
                {spaceQuestionResponse.questionsResponses.length > 0 && (
                  <Text fontWeight="semibold" width="100%">
                    {
                      (spaceQuestionResponse.object as ApiSpaceSummary).location
                        .name
                    }
                  </Text>
                )}
                {spaceQuestionResponse.questionsResponses.map(
                  (sr, idxResponse) => {
                    return (
                      <Box
                        gap={4}
                        width="100%"
                        key={`questionsResponses::${sr.question.id}::${sr.questionResponse.id}`}
                        position="relative"
                      >
                        <QuestionResponseFields
                          question={sr.question}
                          value={sr.questionResponse.value}
                          index={idxResponse + 1}
                          onChange={(
                            value: string | number | boolean | string[]
                          ) => {
                            handleChangeSpaceResponse(
                              index,
                              idxResponse,
                              value
                            );
                          }}
                          isInvalid={
                            sr.question.required && !sr.questionResponse.value
                          }
                        />
                      </Box>
                    );
                  }
                )}
              </Fragment>
            );
          })}

          {serviceQuestionsResponses.map((serviceQuestionResponse, index) => {
            return (
              <Fragment
                key={`serviceResponses::${serviceQuestionResponse.object.id}${index}`}
              >
                {serviceQuestionResponse.questionsResponses.length > 0 && (
                  <Text fontWeight="semibold" width="100%">
                    {(serviceQuestionResponse.object as ApiServiceSumary).name}
                  </Text>
                )}
                {serviceQuestionResponse.questionsResponses.map((vr, rIdx) => {
                  return (
                    <Box
                      gap={4}
                      width="100%"
                      key={`questionsResponses::${vr.question.id}::`}
                      position="relative"
                    >
                      <QuestionResponseFields
                        question={vr.question}
                        value={vr.questionResponse?.value}
                        index={rIdx + 1}
                        onChange={(
                          value: string | number | boolean | string[]
                        ) => {
                          handleChangeServiceResponse(index, rIdx, value);
                        }}
                        isInvalid={
                          vr.question.required && !vr.questionResponse?.value
                        }
                      />
                    </Box>
                  );
                })}
              </Fragment>
            );
          })}
          <HStack width="100%" justifyContent="flex-end">
            <Button
              float="right"
              size="sm"
              colorScheme="blue"
              onClick={handleOnSaveResponses}
              isLoading={isLoading}
            >
              Save Responses
            </Button>
          </HStack>
        </>
      ) : (
        <Text width="100%">No questions associated</Text>
      )}
      {!hideDivider && <Divider />}
    </VStack>
  );
};
