import { Box, BoxProps, Button, useDisclosure } from "@chakra-ui/react";
import { ApiEventGroup, ApiGroupStatus } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../store";
import {
  cleanSelectedGroup,
  getEventGroup,
  unloadGroups,
} from "../../store/event-group-slice";
import { GroupApprovalModal } from "./GroupApprovalModal";

interface EventGroupApprovalParameters {
  groupId?: string;
  eventId?: string;
  hideButton: boolean;
  eventGroupStatus?: ApiGroupStatus;
  showOnlyApproveButton?: boolean;
  boxProps?: BoxProps;
}

export const EventGroupApprovalButton: FC<EventGroupApprovalParameters> = ({
  groupId,
  eventId,
  hideButton,
  eventGroupStatus,
  showOnlyApproveButton,
  boxProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { eventGroupList, eventDetails } = useSelector(
    (state: RootState) => state,
  );
  const [isApproved, setIsApproved] = useState(
    eventGroupList.selectedEventGroup
      ? eventGroupList.selectedEventGroup?.status
      : false,
  );

  const thunkDispatch = useThunkDispatch();
  const { currentAccount, apiClient } = useAuthentication();
  const dispatch = useDispatch();

  const handleIsApproved = useCallback(() => {
    const currentEventGroup = eventDetails.event
      ? eventDetails.event.eventGroup
      : eventGroupList.selectedEventGroup !== null
        ? eventGroupList.selectedEventGroup
        : eventGroupList.data.find(
            (eventGroup: ApiEventGroup) => eventGroup.id === groupId,
          );
    setIsApproved(currentEventGroup?.status === ApiGroupStatus.approved);
  }, [
    eventGroupList.data,
    eventGroupList.selectedEventGroup,
    eventDetails,
    groupId,
  ]);

  const handleOncloseModal = useCallback(() => {
    handleIsApproved();
    onClose();
    dispatch(cleanSelectedGroup());
    eventId && dispatch(unloadGroups());
  }, [handleIsApproved, onClose, dispatch, eventId]);

  const handleOnOpenModal = useCallback(() => {
    onOpen();
  }, [onOpen]);

  useEffect(() => {
    handleIsApproved();
  }, [handleIsApproved]);

  useEffect(() => {
    if (
      eventGroupList.data.length === 0 &&
      eventGroupList.selectedEventGroup === null &&
      groupId
    ) {
      thunkDispatch(
        getEventGroup({
          apiClient,
          accountId: currentAccount.id,
          groupId: groupId,
        }),
      );
    }
  }, [
    apiClient,
    currentAccount.id,
    eventGroupList.data.length,
    eventGroupList.selectedEventGroup,
    groupId,
    thunkDispatch,
  ]);

  useEffect(() => {
    dispatch(cleanSelectedGroup());
  }, [dispatch]);

  return (
    <>
      <Box
        mt={1}
        display="flex"
        gap={2}
        justifyContent={["flex-start", "flex-start", "flex-end"]}
        {...boxProps}
      >
        {(!isApproved || !hideButton) && !showOnlyApproveButton && (
          <Button
            size="sm"
            minW="40px"
            variant={isApproved ? "outline" : "solid"}
            borderColor="blue.500"
            colorScheme={isApproved ? "red" : "blue"}
            _hover={{ opacity: 1 }}
            onClick={handleOnOpenModal}
          >
            {!isApproved ? "Approve this group" : "Decline this group"}
          </Button>
        )}

        {showOnlyApproveButton &&
          eventGroupStatus !== ApiGroupStatus.approved && (
            <Button
              size="xs"
              minW="80px"
              colorScheme="blue"
              _hover={{ opacity: 1 }}
              onClick={handleOnOpenModal}
            >
              Approve
            </Button>
          )}
      </Box>

      {isOpen && (!isApproved || !hideButton) && (
        <GroupApprovalModal
          isOpen={isOpen}
          handleOnCLose={handleOncloseModal}
          eventGroupId={groupId}
          eventId={eventId}
        />
      )}
    </>
  );
};
