import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Container,
  HStack,
  IconButton,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { AccountMenu } from "../AccountMenu";
import LogoSmall from "../Logo.sm";
import { SmallProfileButton } from "../ProfileButton.sm";
import { NavItems } from "./MobileNavItems";
import { MobileSideBar } from "./MobileSideBar";
import { StaticNavItems } from "./StaticMobileNavItems";

type MobileNavProps = {};

export const MobileNav: FC<MobileNavProps> = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [showStaticNavItems, setShowStaticNavItems] = useState(false);
  const [isMobileSmall, isMobileMedium = false, isMobileLarge = false] =
    useMediaQuery([
      "(min-width: 0px)",
      "(min-width: 376px) and (max-width: 768px)",
      "(min-width: 769px)",
    ]);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("iPhone") !== -1) {
      return setShowStaticNavItems(true);
    }
  }, []);

  return (
    <>
      <Container maxW="8xl" px={3} py={0} minH={"60px"}>
        <HStack maxW="8xl" minH={"60px"} gap={1}>
          {isMobileSmall && !isMobileMedium && !isMobileLarge && (
            <>
              <IconButton
                size="sm"
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
                onBlur={onClose}
              />
              {showStaticNavItems ? (
                <StaticNavItems mediaQueries={[true, false]} />
              ) : (
                <NavItems mediaQueries={[true, false]} />
              )}
              <AccountMenu mediaQueries={[true]}>
                {(user) => <SmallProfileButton user={user} />}
              </AccountMenu>
            </>
          )}
          {isMobileMedium && (
            <>
              <IconButton
                size="sm"
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
                onBlur={onClose}
              />
              <LogoSmall />
              {showStaticNavItems ? (
                <StaticNavItems mediaQueries={[false, true]} />
              ) : (
                <NavItems mediaQueries={[false, true]} />
              )}
              <AccountMenu mediaQueries={[true]}>
                {(user) => <SmallProfileButton user={user} />}
              </AccountMenu>
            </>
          )}
          {isMobileLarge && (
            <>
              <IconButton
                size="sm"
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
                onBlur={onClose}
              />
              <LogoSmall />
              {showStaticNavItems ? (
                <StaticNavItems mediaQueries={[false, false, true]} />
              ) : (
                <NavItems mediaQueries={[false, false, true]} />
              )}
              <AccountMenu mediaQueries={[false, true]}>
                {(user) => <SmallProfileButton user={user} />}
              </AccountMenu>
            </>
          )}
        </HStack>
      </Container>
      <MobileSideBar
        isOpen={isOpen}
        onToggle={() => {
          if (isOpen) onClose();
        }}
      />
    </>
  );
};
