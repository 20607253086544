import {
  Box,
  Checkbox,
  Grid,
  GridItem,
  Icon,
  LinkBoxProps,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { IoWarning } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLongPress } from "../../../hooks/useLongPress";
import { RootState } from "../../../store";

export interface SelectableRequestMobileProps extends LinkBoxProps {
  selected?: boolean;
  setSelectedState?: () => void;
  linkTo?: string;
  canBeSelected: boolean;
  notAllowedText?: string;
}
export const SelectableRequestMobile: FC<SelectableRequestMobileProps> = ({
  selected,
  setSelectedState,
  linkTo,
  children,
  canBeSelected = true,
  notAllowedText,
}) => {
  const { handlers } = useLongPress({
    onLongPress: setSelectedState ? setSelectedState : () => {},
  });

  const { selectMode: mode } = useSelector(
    (root: RootState) => root.requestsBulkActionsSlice
  );

  const hoverOrSelectedColor = useColorModeValue("blue.50", "whiteAlpha.300");
  const checkboxBgColor = useColorModeValue("white", "blue.800");
  const disabledTextColor = useColorModeValue("gray.500", "white");
  const disabledBgColot = useColorModeValue("gray.100", "whiteAlpha.400");
  const bgColor = useColorModeValue("whiteAlpha", "gray.700");

  return mode === "bulk" ? (
    canBeSelected ? (
      <Box
        borderWidth={1}
        borderStyle="solid"
        borderColor={selected ? "blue.500" : "gray.300"}
        bg={selected ? hoverOrSelectedColor : "unset"}
        borderRadius="md"
        cursor="pointer"
        _hover={{
          borderColor: "blue.500",
          bg: hoverOrSelectedColor,
        }}
        role="group"
        justifyContent="space-between"
        position="relative"
        display="flex"
        flexDirection="row"
        alignItems="center"
        w="100%"
      >
        <Checkbox
          bgColor={checkboxBgColor}
          isChecked={selected}
          onChange={setSelectedState}
          display={selected ? "flex" : "none"}
          borderColor="blue.500"
          role="group"
          position="absolute"
          ml="-2"
        />
        <Box onClick={setSelectedState} w="full" maxWidth="full">
          {children}
        </Box>
      </Box>
    ) : (
      <Grid templateColumns="1fr" templateRows="1fr" role="group" w="100%">
        <GridItem gridRow={1} gridColumn={1} role="group">
          <Box
            borderWidth={1}
            borderStyle="solid"
            borderColor={selected ? "blue.500" : "gray.300"}
            borderRadius="md"
            opacity="50%"
          >
            {children}
          </Box>
        </GridItem>
        <GridItem gridRow={1} gridColumn={1} role="group">
          <Box
            flexDir="row"
            display="none"
            _groupHover={{
              display: "flex",
              outline: "solid 1px",
              outlineColor: "gray.50",
            }}
            bg={disabledBgColot}
            opacity="85%"
            w="full"
            h="full"
            color="black"
            justifyContent="center"
            alignItems="center"
            columnGap="4"
            userSelect="none"
            borderRadius="md"
          >
            <Icon as={IoWarning} boxSize="6" color={disabledTextColor} />
            <VStack alignItems="left" spacing="0.5" color={disabledTextColor}>
              <Text>Cannot select request</Text>
              <Text>{notAllowedText}</Text>
            </VStack>
          </Box>
        </GridItem>
      </Grid>
    )
  ) : (
    <Box
      borderWidth={1}
      borderStyle="solid"
      borderColor="gray.300"
      borderRadius="md"
      _hover={{
        borderColor: "blue.500",
        bg: hoverOrSelectedColor,
      }}
      role="group"
      justifyContent="space-between"
      {...handlers}
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      bgColor={bgColor}
      w="full"
    >
      <Box width="full" role="group">
        <Link to={linkTo || "#"}>{children}</Link>
      </Box>
    </Box>
  );
};
