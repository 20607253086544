import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiVendor } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountModal } from "../../pages/account-settings/account-modal/AccountModal";
import { VendorContacts } from "../../pages/account-settings/vendors/contacts/VendorContacts";
import { RootState } from "../../store";
import {
  cleanWorkingContacts,
  setIsOpenContactModal,
  setWorkingVendor,
} from "../../store/vendors/vendors-slice";
import { VendorAutocomplete } from "../selects/VendorsAutocomplete";

interface VendorsAutocompleteControlProps {
  label: string;
  name: string;
  value: ApiVendor | null;
  isClearable?: boolean;
  placeholder?: string;
  addVendorLink?: boolean;
  isSupplier?: boolean;
  isManufacturer?: boolean;
  isServiceProvider?: boolean;
  showLabel?: string;
  isSupplierOnly?: boolean;
  aiSearch?: string;
}

export const VendorAutocompleteControl: FC<VendorsAutocompleteControlProps> = ({
  label,
  name,
  value,
  placeholder,
  isClearable,
  addVendorLink,
  showLabel,
  isSupplier,
  isManufacturer,
  isServiceProvider,
  isSupplierOnly,
  aiSearch,
}) => {
  const dispatch = useDispatch();
  const linkColor = useColorModeValue("blue.500", "blue.300");
  const { isOpenContactModal, workingVendor, workingVendorContacts } =
    useSelector((state: RootState) => state.vendorSlice);

  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({
    name: name,
    value: value ? value.name : "" || undefined,
  });

  const handleOnOpenContactsModal = useCallback(
    (vendor: ApiVendor) => {
      dispatch(setWorkingVendor(vendor));
      dispatch(setIsOpenContactModal({ isOpen: true, isReadOnly: true }));
    },
    [dispatch]
  );

  const handleOnCloseContactsModal = useCallback(() => {
    dispatch(setWorkingVendor(null));
    dispatch(setIsOpenContactModal({ isOpen: false, isReadOnly: false }));
    dispatch(cleanWorkingContacts());
  }, [dispatch]);

  const handleOnChange = useCallback(
    (value: ApiVendor | null) => {
      helper.setValue(value);
      // See this issue with running setTouched and setValue in the same hook,
      // https://github.com/jaredpalmer/formik/issues/2083
      // This fixes a bug where the value is set, yet validation runs on the old value.
      setImmediate(() => helper.setTouched(true));
    },
    [helper]
  );

  return (
    <>
      <FormControl
        isInvalid={!!meta.error && (meta.touched || submitCount > 0)}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <FormLabel htmlFor={name} w="max-content">
            {label}
          </FormLabel>
          {field.value && addVendorLink && (
            <Text
              color={linkColor}
              cursor="pointer"
              onClick={() => handleOnOpenContactsModal(field.value)}
            >
              {showLabel ? showLabel : "View Details"}
            </Text>
          )}
        </Flex>
        <VendorAutocomplete
          {...field}
          isSupplier={isSupplier}
          isManufacturer={isManufacturer}
          isServiceProvider={isServiceProvider}
          onChange={handleOnChange}
          isClearable={isClearable}
          placeholder={placeholder}
          isSupplierOnly={isSupplierOnly}
          aiSearch={aiSearch}
        />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>

      {field.value && addVendorLink && workingVendor && (
        <AccountModal
          isOpen={isOpenContactModal}
          title={`${workingVendor.name} ${
            !workingVendorContacts.isReadOnly ? "Contacts" : ""
          }`}
          onClose={handleOnCloseContactsModal}
          contentProps={{ maxW: "2xl" }}
          content={
            <VendorContacts isReadOnly={workingVendorContacts.isReadOnly} />
          }
        />
      )}
    </>
  );
};
