import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiRequestTaskBookTask,
  ApiTaskBookType,
} from "@operations-hero/lib-api-client";
import {
  FC,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { TiArrowForward } from "react-icons/ti";
import { useParams } from "react-router-dom";
import { AttachmentFileCard } from "../../../../components/attachments/AttachmentFileCard";
import {
  Attachment,
  mapApiAttachments,
} from "../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { StepIcon } from "../../../../components/custom-icons/StepIcon";
import { ReadOnlyTextEditor } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorReadOnly";
import { Rating } from "../../../../components/rating/Rating";
import { RATING_SCALE } from "../TaskbooksData";

interface RequestTasksProps {
  taskbookId: string;
  userReadSafetyNotes: boolean;
  taskBookType: ApiTaskBookType;
  verifySafetyNotesRead: () => void;
  tasks: ApiRequestTaskBookTask[];
  tasksRef: MutableRefObject<RequestWithAttachments[] | null>;
}

export interface RequestWithAttachments extends ApiRequestTaskBookTask {
  attachments: Attachment[];
}

interface CustomElement {
  [key: string]: ReactElement<Element> | string;
}

export type TaskStatus = "complete" | "incomplete" | "skipped" | null;

export const RequestTasks: FC<RequestTasksProps> = ({
  tasks,
  tasksRef,
  taskbookId,
  taskBookType,
  verifySafetyNotesRead,
  userReadSafetyNotes,
}) => {
  const [workingTasks, setWorkingTasks] = useState<
    RequestWithAttachments[] | null
  >(null);

  const { apiClient, currentAccount } = useAuthentication();
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const markedLabelColor = useColorModeValue("black", "white");
  const isDesktop = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  });
  const { key, requestTaskbookId, readOnly } = useParams<{
    key?: string;
    requestTaskbookId: string;
    readOnly?: string;
  }>();

  tasksRef.current = workingTasks;

  const getData = useCallback(async () => {
    const newTasks: RequestWithAttachments[] = await Promise.all(
      tasks.map(async (task) => {
        const { data } = await apiClient.findTaskbookTaskAttachments(
          currentAccount.id,
          taskbookId,
          task.task.id
        );
        return { ...task, attachments: mapApiAttachments(data) };
      })
    );
    setWorkingTasks(newTasks);
  }, [apiClient, currentAccount, taskbookId, tasks]);

  useEffect(() => {
    getData();
  }, [getData]);

  const updateRequestTask = useCallback(
    async ({
      requestTaskbookTaskId,
      newStatus,
      score,
    }: {
      requestTaskbookTaskId: string;
      newStatus?: TaskStatus;
      score?: number;
    }) => {
      if (!userReadSafetyNotes) {
        verifySafetyNotesRead();
        return;
      }
      if (key && workingTasks && requestTaskbookId) {
        const updatedTask = await apiClient.updateRequestTaskbookTask(
          currentAccount.id,
          key,
          requestTaskbookId,
          requestTaskbookTaskId,
          {
            status: newStatus,
            score,
            completed: new Date().toISOString(),
          }
        );

        const newTasks = workingTasks.map((task) => {
          if (task.id === updatedTask.id) {
            return { ...updatedTask, attachments: task.attachments };
          }
          return task;
        });

        setWorkingTasks(newTasks);
      }
    },
    [
      apiClient,
      currentAccount.id,
      key,
      requestTaskbookId,
      userReadSafetyNotes,
      verifySafetyNotesRead,
      workingTasks,
    ]
  );

  const getStatusText = useCallback(
    (status: string) => {
      const iconStyles = {
        verticalAlign: "-2px",
      };

      const stateIcons: CustomElement = {
        complete: <Icon as={AiOutlineCheck} style={iconStyles} />,
        incomplete: <Icon as={AiOutlineClose} style={iconStyles} />,
        skipped: <Icon as={TiArrowForward} style={iconStyles} />,
        failed: <Icon as={AiOutlineClose} style={iconStyles} />,
      };

      const labels: CustomElement = {
        complete: "completed",
        skipped: "skipped",
        incomplete: taskBookType === "checklist" ? "incomplete" : "failed",
      };

      const textColor =
        taskBookType === "passfail" && status === "incomplete"
          ? "red.500"
          : markedLabelColor;

      return (
        <Text
          mr={4}
          mb={2}
          as="span"
          fontWeight="bold"
          verticalAlign="2px"
          color={textColor}
        >
          {stateIcons[status]} {labels[status]}
        </Text>
      );
    },
    [taskBookType, markedLabelColor]
  );

  const getStatusButtons = useCallback(
    ({ status, completed, id, task }: ApiRequestTaskBookTask) => {
      const buttons: CustomElement = {
        complete: (
          <Button
            mr={4}
            mb={2}
            size="sm"
            colorScheme="blue"
            variant={completed ? "outline" : "solid"}
            onClick={() =>
              updateRequestTask({
                requestTaskbookTaskId: id,
                newStatus: "complete",
              })
            }
          >
            <Icon as={AiOutlineCheck} mr={2} />
            {taskBookType === "checklist" ? "Mark Complete" : "Mark as Passed"}
          </Button>
        ),
        incomplete: (
          <Button
            mr={4}
            mb={2}
            size="sm"
            colorScheme="blue"
            variant={completed || !status ? "outline" : "solid"}
            onClick={() =>
              updateRequestTask({
                requestTaskbookTaskId: id,
                newStatus: "incomplete",
              })
            }
          >
            <Icon as={AiOutlineClose} mr={2} />
            {taskBookType === "checklist"
              ? "Mark Incomplete"
              : "Mark as Failed"}
          </Button>
        ),
        skipped: (
          <Button
            mr={4}
            mb={2}
            size="sm"
            colorScheme="blue"
            variant={completed || !status ? "outline" : "solid"}
            onClick={() =>
              updateRequestTask({
                requestTaskbookTaskId: id,
                newStatus: "skipped",
              })
            }
          >
            Skip
          </Button>
        ),
      };

      if (!completed && !status) {
        return (
          <Flex
            w="100%"
            justifyContent="flex-end"
            flexDir={["column", "row", "row", "row"]}
          >
            {buttons["complete"]}
            {buttons["incomplete"]}
            {!task.isRequired && buttons["skipped"]}
          </Flex>
        );
      }

      if (completed && status) {
        if (status !== "complete") {
          return (
            <Flex
              w="100%"
              justifyContent="flex-end"
              flexDir={["column", "row", "row", "row"]}
            >
              {status !== null && getStatusText(status)}
              {buttons["complete"]}
              {status === "skipped"
                ? buttons["incomplete"]
                : !task.isRequired && buttons["skipped"]}
            </Flex>
          );
        } else {
          return (
            <Flex
              w="100%"
              justifyContent="flex-end"
              flexDir={["column", "row", "row", "row"]}
            >
              {status !== null && getStatusText(status)}
              {buttons["incomplete"]}
              {!task.isRequired && buttons["skipped"]}
            </Flex>
          );
        }
      }
    },
    [taskBookType, updateRequestTask, getStatusText]
  );

  const getMarkedText = useCallback(
    ({
      status,
      updated,
      score,
    }: {
      status?: TaskStatus;
      updated: string | null;
      score?: number | null;
    }) => {
      if (taskBookType === ApiTaskBookType.scoring) {
        return ` rated this task as ${score} on ${new Date(
          updated as string
        ).toLocaleString()}`;
      }
      return ` marked this task as ${status} on ${new Date(
        updated as string
      ).toLocaleString()}`;
    },
    [taskBookType]
  );

  return workingTasks ? (
    <Box mt={4}>
      <Heading size="md" mb={8}>
        Tasks
      </Heading>

      {workingTasks.map((task) => (
        <Box key={task.id} py={4} id={task.id}>
          <Flex
            justifyContent="space-between"
            w="100%"
            flexDir={["column", "column", "column", "row"]}
          >
            <Box
              display="flex"
              alignItems="center"
              w={["100%", "100%", "100%", "68%"]}
              pr="5px"
            >
              <StepIcon
                type={task.status === null ? "number" : task.status}
                step={task.task.order}
              />

              <Text fontWeight="bold" ml={2} display="flex">
                {task.task.name}
                {!task.task.isRequired && (
                  <Text ml={2} color="gray.500" as="span">
                    (Optional)
                  </Text>
                )}
              </Text>
            </Box>
            {isDesktop && taskBookType !== ApiTaskBookType.scoring && (
              <Box w={["100%", "32%", "32%", "32%"]}>
                {getStatusButtons(task)}
              </Box>
            )}
          </Flex>

          {task.task.description && task.task.description.length > 0 && (
            <Box mt={2} pl={8}>
              <ReadOnlyTextEditor value={task.task.description} />
            </Box>
          )}
          {task.attachments.map((attachment) => (
            <Box my={2} ml={10}>
              <SimpleGrid spacing="10" columns={[2, 3, 4, 4, 5]}>
                <AttachmentFileCard
                  key={attachment.uploadId}
                  attachment={attachment}
                />
              </SimpleGrid>
            </Box>
          ))}

          {task.completed && task.updatedBy && (
            <Box ml={10} mt={1}>
              <UserBadge value={task.updatedBy} />
              <Text verticalAlign="sub" as="span">
                {getMarkedText({
                  updated: task.updated,
                  status: task.status,
                  score: task.score,
                })}
              </Text>
            </Box>
          )}
          <Box
            mt={4}
            ml={10}
            display={isMobile ? "flex" : "inline-block"}
            alignItems="flex-start"
            flexDirection="column"
          >
            {!readOnly &&
              (taskBookType === ApiTaskBookType.scoring ? (
                <Rating
                  scale={RATING_SCALE}
                  score={task.score}
                  onClick={(score) =>
                    updateRequestTask({
                      requestTaskbookTaskId: task.id,
                      score,
                      newStatus: "complete",
                    })
                  }
                  blockClickAction={!userReadSafetyNotes}
                />
              ) : (
                !isDesktop && getStatusButtons(task)
              ))}
          </Box>
          <Divider mt={4} />
        </Box>
      ))}
    </Box>
  ) : null;
};
