import {
  ApiClient,
  ApiLaborTransaction,
  ApiRequest,
  ApiRequestsQueryFilter,
  ApiTransaction,
  ApiTransactionType,
  ApiUser,
  ApiUserSummary,
  CreateApiLaborTransaction,
  LaborType,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format, parse } from "date-fns";
import { compareRequests } from "../utils/compareRequests";

interface LoadTimesheetThunkParams {
  apiClient: ApiClient;
  accountId: string;
  userId: string;
  weekSelected: string[];
}

interface CreateUpdateTransactionThunkParams {
  apiClient: ApiClient;
  accountId: string;
  workingTransaction: ApiLaborTransaction;
  key: string;
}

export interface TimesheetListState {
  total: number;
  isWorkingTransaction?: string;
  requestsMap: Record<string, ApiRequest>;
  transactionsMap: Record<string, ApiLaborTransaction[]>;
}

export const DATE_FORMAT_TIMESHEET = "yyyy-MMM-dd";

export const loadTimesheet = createAsyncThunk(
  "timesheet/load",
  async (params: LoadTimesheetThunkParams, ThunkAPI) => {
    const { apiClient, accountId, userId, weekSelected } = params;

    const requests = await apiClient.findRequests(accountId, {
      quickFilter: ApiRequestsQueryFilter.RECENTLY_CLOSED,
      assignee: userId,
    });
    if (requests.data.length === 0) {
      return { requests: [], transactions: [] };
    }
    const transactions = await apiClient.findAccountTransactions(accountId, {
      requestIdsOrKeys: requests.data.map((req) => req.key),
      datePerformed: weekSelected,
      createdBy: userId,
      type: ApiTransactionType.labor,
    });

    const restTransactions = await apiClient.findAccountTransactions(
      accountId,
      {
        datePerformed: weekSelected,
        type: ApiTransactionType.labor,
        createdBy: userId,
        notInRequestIdsOrKeys: requests.data.map((req) => req.id),
      }
    );
    const idsOrKeys = restTransactions.data.map((tr) => tr.requestId);
    const restRequests =
      idsOrKeys.length > 0
        ? await apiClient.findRequests(accountId, {
            idsOrKeys,
          })
        : { data: [] };

    const combinedRequests = [...requests.data, ...restRequests.data];
    const uniqueRequests: Record<string, ApiRequest> = {};

    combinedRequests.forEach((req) => {
      if (!uniqueRequests[req.id]) {
        uniqueRequests[req.id] = req;
      }
    });
    const combinedTransactions = [
      ...transactions.data,
      ...restTransactions.data,
    ];
    const uniqueTransactions: Record<string, ApiTransaction> = {};

    combinedTransactions.forEach((tr) => {
      if (!uniqueTransactions[tr.id]) {
        uniqueTransactions[tr.id] = tr;
      }
    });

    return {
      requests: Object.values(uniqueRequests),
      transactions: Object.values(uniqueTransactions),
    };
  }
);

export const updateTransactionTimesheet = createAsyncThunk(
  "timesheet/update-transaction",
  async (params: CreateUpdateTransactionThunkParams, ThunkAPI) => {
    const { apiClient, accountId, workingTransaction, key } = params;

    const updateTransaction = {
      hours: workingTransaction.hours,
      laborType: workingTransaction.laborType,
    };

    const transaction = await apiClient.updateRequestTransaction(
      accountId,
      workingTransaction.requestKey,
      workingTransaction.id,
      updateTransaction
    );

    return { transaction, key };
  }
);

export const createTransactionTimesheet = createAsyncThunk(
  "timesheet/create-transaction",
  async (params: CreateUpdateTransactionThunkParams, ThunkAPI) => {
    const { apiClient, accountId, workingTransaction, key } = params;

    const transaction = await apiClient.createRequestTransaction(
      accountId,
      workingTransaction.requestKey,
      workingTransaction as CreateApiLaborTransaction
    );

    return { transaction, key };
  }
);

export const deleteTransactionTimesheet = createAsyncThunk(
  "timesheet/delete-transaction",
  async (params: CreateUpdateTransactionThunkParams, ThunkAPI) => {
    const { apiClient, accountId, workingTransaction, key } = params;

    const transaction = workingTransaction;

    if (workingTransaction.id !== "new") {
      await apiClient.deleteRequestTransaction(
        accountId,
        workingTransaction.requestKey,
        workingTransaction.id
      );
    }

    return { transaction, key };
  }
);

export const timesheet = createSlice({
  name: "timesheet",
  initialState: {
    total: 1,
    isWorkingTransaction: undefined,
  } as TimesheetListState,
  reducers: {
    unloadNewTransactions: (state, payload: PayloadAction<string>) => {
      const key = payload.payload;
      state.isWorkingTransaction = undefined;
      const transactionArray = state.transactionsMap[key];
      if (transactionArray && transactionArray.length) {
        state.transactionsMap[key] = transactionArray.filter(
          (tr) => tr.id !== "new"
        );
      }
    },
    setIsWorkingTransaction: (
      state,
      payload: PayloadAction<string | undefined>
    ) => {
      state.isWorkingTransaction = payload.payload;
    },
    addTransactionToMap: (
      state,
      payload: PayloadAction<{ key: string; user: ApiUser }>
    ) => {
      const { key, user } = payload.payload;

      const userSummary: ApiUserSummary = {
        id: user.id,
        email: user.email,
        phone: user.phone,
        firstName: user.firstName,
        lastName: user.lastName,
        profileImage: user.profileImage,
        timeZone: user.timeZone,
      };
      const requestKey = key.split("::")[0];
      // Format date is DATE_FORMAT_TIMESHEET i.e. 2021-Jan-01
      const datePerformedKey = key.split("::")[1];
      // local parse date then convert to ISO string
      // this prevents the date from being off by a day when reloading the page
      const datePerformed = parse(
        `${datePerformedKey} 00:00:00`,
        `${DATE_FORMAT_TIMESHEET} HH:mm:ss`,
        new Date()
      ).toISOString();

      const request = state.requestsMap[requestKey];
      const newTransaction = {
        id: "new",
        budget: request.budget,
        datePerformed,
        laborType: LaborType.regular,
        requestKey,
        type: ApiTransactionType.labor,
        laborer: userSummary,
        created: new Date().toISOString(),
        createdBy: userSummary,
        hourlyRate: 0,
        attachmentCount: 0,
        hours: 0,
        requestId: request.id,
        total: 0,
        updated: new Date().toISOString(),
        updatedBy: userSummary,
      } as ApiLaborTransaction;
      state.isWorkingTransaction = newTransaction.id;
      if (state.transactionsMap[key]?.length) {
        const transactionArray = state.transactionsMap[key];
        transactionArray.push(newTransaction);
        state.transactionsMap[key] = transactionArray;
        return;
      }
      state.transactionsMap[key] = [newTransaction];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadTimesheet.fulfilled, (state, action) => {
      const { requests, transactions } = action.payload;
      const listRequests = requests.sort(compareRequests);
      state.requestsMap = listRequests.reduce<Record<string, ApiRequest>>(
        (result, req) => {
          result[req.key] = req;
          return result;
        },
        {}
      );
      state.transactionsMap = transactions.reduce<
        Record<string, ApiLaborTransaction[]>
      >((result, tr) => {
        const date = format(new Date(tr.datePerformed), DATE_FORMAT_TIMESHEET);
        const key: string = `${tr.requestKey}::${date}`;
        if (!result[key]?.length) {
          result[key] = [tr as ApiLaborTransaction];
          return result;
        }
        result[key].push(tr as ApiLaborTransaction);
        return result;
      }, {});
    });

    builder.addCase(updateTransactionTimesheet.fulfilled, (state, action) => {
      const { transaction, key } = action.payload;

      const transactionArray = state.transactionsMap[key];
      const index = transactionArray.findIndex(
        (tr) => tr.id === transaction.id
      );
      if (index !== -1) {
        transactionArray[index] = transaction as ApiLaborTransaction;
        state.transactionsMap[key] = transactionArray;
      }
    });
    builder.addCase(createTransactionTimesheet.fulfilled, (state, action) => {
      const { transaction, key } = action.payload;

      const transactionArray = state.transactionsMap[key];
      const index = transactionArray.findIndex((tr) => tr.id === "new");
      if (index !== -1) {
        transactionArray[index] = transaction as ApiLaborTransaction;
        state.transactionsMap[key] = transactionArray;
      }
    });
    builder.addCase(deleteTransactionTimesheet.fulfilled, (state, action) => {
      const { transaction, key } = action.payload;

      const transactionArray = state.transactionsMap[key];
      const index = transactionArray.findIndex(
        (tr) => tr.id === transaction.id
      );

      if (index !== -1) {
        transactionArray.splice(index, 1);
        state.transactionsMap[key] = transactionArray;
      }
    });
  },
});

export const {
  unloadNewTransactions,
  setIsWorkingTransaction,
  addTransactionToMap,
} = timesheet.actions;

export default timesheet.reducer;
