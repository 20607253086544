import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  chakra,
  CloseButton,
} from "@chakra-ui/react";
import { ApiEvent } from "@operations-hero/lib-api-client";
import React from "react";

export interface NewEventToastProps {
  onClick?: () => void;
  onClose?: () => void;
  event: ApiEvent;
}

export const NewEventToast = ({
  onClick,
  event,
  onClose,
}: NewEventToastProps) => {
  return (
    <Alert
      status="success"
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={8}
      textAlign="start"
      width="auto"
      cursor="pointer"
    >
      <AlertIcon />
      <chakra.div flex="1" onClick={onClick}>
        <AlertTitle>Event Created - {event.key}</AlertTitle>
        <AlertDescription display="block">
          Click to view {event.name}
        </AlertDescription>
      </chakra.div>
      <CloseButton
        size="sm"
        onClick={onClose}
        position="absolute"
        insetEnd={1}
        top={1}
      />
    </Alert>
  );
};
