import { Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { FC } from "react";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { InventoryOrderItem } from "../../../../store/inventory/inventory-order-item-list.slice";
import { VerifyOrderItemControl } from "../inventory-placed-orders/VerifyOrderItemControl";
import { InventoryOrderValues } from "../InventoryOrderForm";

interface UpdateInventoryOrderRowItemFormProps {
  items: InventoryOrderItem[];
  values: InventoryOrderValues;
}

export const UpdateInventoryOrderRowItemForm: FC<
  UpdateInventoryOrderRowItemFormProps
> = ({ items, values }) => {
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });

  return (
    <>
      {items.map((item, index) => (
        <Flex
          key={`item-order:"${item.item.id}`}
          flexDir="column"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          opacity={values.items[index].backOrder ? undefined : "0.3"}
        >
          <Flex w="100%" flexDir="row">
            <Flex gap={2} w="30%" alignItems="center">
              <Image
                boxSize="40px"
                src={item.item.image ? item.item.image : undefined}
              />
              <Flex flexDir="column" justifyContent="center">
                <Text fontWeight="bold">{item.item.name}</Text>
                {item.item.identifiers.externalId && (
                  <Text>#{item.item.identifiers.externalId}</Text>
                )}
              </Flex>
            </Flex>
            <Flex w="17.5%" flexDir="column" alignItems="center" flexShrink={0}>
              <Text fontSize={isDesktop ? "m" : "xs"}>Backorder</Text>
              <Text mt={4} fontWeight="bold">
                {values.items[index].restBackorder}
              </Text>
            </Flex>
            <Flex
              w="17.5%"
              flexDir="column"
              alignItems="center"
              flexShrink={0}
              mr={2}
              justifyContent="center"
            >
              <NumberInputControl
                name={`items[${index}].delivered`}
                value={item.delivered}
                label="Delivered"
                isDisabled={!values.items[index].backOrder}
                labelProps={{
                  fontSize: isDesktop ? "m" : "xs",
                }}
                max={values.items[index].restBackorder}
                numberInputFieldProps={{
                  maxHeight: "36px",
                  paddingRight: "1rem",
                }}
                inputGroupProps={{ maxWidth: "68px" }}
              />
            </Flex>
            <Flex
              w="17.5%"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              flexShrink={0}
            >
              <NumberInputControl
                name={`items[${index}].unitCost`}
                value={item.unitCost}
                label="Unit Cost"
                isDisabled={!values.items[index].backOrder}
                labelProps={{
                  fontSize: isDesktop ? "m" : "xs",
                }}
                numberInputFieldProps={{
                  maxHeight: "36px",
                  paddingRight: "0.8rem",
                  paddingLeft: "0.8rem",
                }}
                inputGroupProps={{ maxWidth: "92px" }}
                precision={4}
              />
            </Flex>
            <Flex w="17.5%" flexDir="column" alignItems="center" flexShrink={0}>
              <Text fontSize={isDesktop ? "m" : "xs"}>Total Cost</Text>
              <Text mt={4} fontWeight="bold">
                ${(item.unitCost * values.items[index].delivered).toFixed(2)}
              </Text>
            </Flex>
          </Flex>

          <VerifyOrderItemControl
            name={`items[${index}]`}
            value={values.items[index]}
            isUpdateModal={true}
          />
        </Flex>
      ))}
    </>
  );
};
