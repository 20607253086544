import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import {
  GroupBase,
  OptionProps,
  Select,
  SingleValueProps,
} from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { useAvailableFiscalYear } from "../../pages/planning-hq/funding-source/useAvailableFiscalYears";
import { FiscalYearRange } from "../../store/planning-hq/types";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";

export interface FiscalYearSelectProps {
  fiscalYear: FiscalYearRange | undefined;
  onChange?: (fiscalYear: FiscalYearRange) => void;
  isInvalid?: boolean;
}

type ValueLabel = {
  value: FiscalYearRange;
  label: string;
};

const CustomOptionComponent = (
  props: OptionProps<ValueLabel, false, GroupBase<ValueLabel>>
) => {
  const { data, innerRef, innerProps } = props;
  const hoverBgColor = useColorModeValue("gray.100", "whiteAlpha.100");
  return (
    <Box
      {...innerProps}
      p={2}
      ref={innerRef}
      _hover={{ backgroundColor: hoverBgColor }}
      cursor="pointer"
    >
      <Text>{data.label}</Text>
    </Box>
  );
};

const CustomSingleValueComponent = (
  props: SingleValueProps<ValueLabel, false, GroupBase<ValueLabel>>
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <Text>{data.label}</Text>
    </Box>
  );
};

export const FiscalYearSelect = ({
  fiscalYear,
  onChange,
  isInvalid = false,
}: FiscalYearSelectProps) => {
  const { ranges } = useAvailableFiscalYear();

  const options = useMemo(
    () =>
      Object.values(ranges).map((range) => ({
        value: {
          start: range.start,
          end: range.end,
        },
        label: range.label,
      })),
    [ranges]
  );

  const components = useMemo(
    () => ({
      SingleValue: CustomSingleValueComponent,
      Option: CustomOptionComponent,
      ...getCustomSelectComponents(),
    }),
    []
  );

  const handleChange = useCallback(
    (item: ValueLabel | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange]
  );

  return (
    <Select
      isInvalid={isInvalid}
      options={options}
      components={components}
      onChange={handleChange}
      chakraStyles={commonStyles}
      value={
        options.find(
          (x) =>
            x.value.start === fiscalYear?.start &&
            x.value.end === fiscalYear.end
        ) || null
      }
    />
  );
};
