import { HStack, Text } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
export interface BulkContentColumnProps {
  request: ApiRequest;
}
export const BulkContentColumn: FC<BulkContentColumnProps> = ({ request }) => {
  const { transactionsSummary, isTotalDataLoading } = useSelector(
    (store: RootState) => store.requestsBulkActionsSlice
  );
  return isTotalDataLoading ? (
    <HStack>
      <Text fontStyle="italic" color="gray.500">
        Loading...
      </Text>
    </HStack>
  ) : (
    <>
      <HStack fontSize="sm">
        <Text fontWeight="bold">Total logged hours:</Text>

        <Text>
          {transactionsSummary[request.id]
            ? transactionsSummary[request.id].totalHours
            : 0}
        </Text>
      </HStack>
      <HStack fontSize="sm">
        <Text fontWeight="bold">Total expenses:</Text>
        <Text>{`$ ${
          transactionsSummary[request.id]
            ? transactionsSummary[request.id].totalPurchases
            : 0
        }`}</Text>
      </HStack>
    </>
  );
};
