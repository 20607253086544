import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { Box, Avatar, Text, BoxProps } from "@chakra-ui/react";
import { FC } from "react";

interface EventOwnerBadgeProps {
  owner: ApiUserSummary;
  boxProps?: BoxProps;
}
export const EventOwnerBadge: FC<EventOwnerBadgeProps> = ({
  owner,
  boxProps,
}) => {
  return (
    <Box gap={2} flexDir="row" display="flex" {...boxProps}>
      <Avatar src={owner.profileImage} name="Group Owner" />
      <Box>
        <Text fontSize="sm" color="gray.600">
          Group Owner
        </Text>
        <Text fontWeight="bold">
          {owner.firstName} {owner.lastName}
        </Text>
      </Box>
    </Box>
  );
};
