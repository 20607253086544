import { Box, useColorMode, useMediaQuery } from "@chakra-ui/react";
import { DesktopNav } from "./desktop/DesktopNav";
import { MobileNav } from "./mobile/MobileNav";

export function NavBar() {
  const [isDesktop] = useMediaQuery(["(min-width: 1024px)"]);
  const { colorMode } = useColorMode();

  return (
    <Box
      p={0}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={colorMode === "light" ? "gray.200" : "gray.900"}
    >
      {isDesktop ? <DesktopNav /> : <MobileNav />}
    </Box>
  );
}
