import { Box, Flex, Text, useColorModeValue as mode } from "@chakra-ui/react";
import React from "react";
import { RequestListFilterState } from "../../store/request-list.slice";

export interface StatCardProps {
  data: {
    label: string;
    value: number | string;
    filters: RequestListFilterState;
  };
  onCardClick: (filters: RequestListFilterState) => void;
}

// function format(value: number, currency: string) {
//   return new Intl.NumberFormat("en-US", { style: "decimal", currency }).format(
//     value
//   );
// }

export function StatCard(props: StatCardProps) {
  const { label, value, filters } = props.data;

  return (
    <Flex
      direction="column"
      align="center"
      p="6"
      bg={mode("white", "gray.700")}
      rounded="8px"
      shadow="base"
      color={mode("gray.500", "gray.400")}
      textAlign="center"
      onClick={() => props.onCardClick(filters)}
      cursor="pointer"
    >
      <Box>
        <Text
          fontSize="xs"
          fontWeight="bold"
          textTransform="uppercase"
          letterSpacing="wide"
        >
          {label}
        </Text>
        <Box mt={3} textAlign="center">
          <Text
            as="span"
            color={mode("gray.800", "white")}
            fontSize="4xl"
            fontWeight="bold"
            lineHeight="1"
            pt={3}
          >
            {value}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
