import { Box, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { GroupBase, SingleValueProps } from "react-select";
import { AssetConditionBadge } from "../badges/AssetConditionBadge";
import { createOptionComponent } from "./select-overrides";
import { StyledSelect } from "./StyledSelect";

export type TimePeriodKey = "last1d" | "last7d" | "last14d" | "last30d";

export const TimePeriod: { [key in TimePeriodKey]: string } = {
  last1d: "1 Day",
  last7d: "7 Days",
  last14d: "14 Days",
  last30d: "30 Days",
};

export interface ColumnViewRequestPeriodSelectProps {
  value: TimePeriodKey;
  onChange?: (value: TimePeriodKey) => void;
  isDisabled?: boolean;
  name?: string;
  timePeriodValues: TimePeriodKey[];
}

type ValueLabel = {
  value: TimePeriodKey;
  label: string;
};

const CustomOptionComponent = createOptionComponent(AssetConditionBadge);

const CustomSingleValueComponent = (
  props: SingleValueProps<ValueLabel, false, GroupBase<ValueLabel>>,
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <Text>{data.value}</Text>
    </Box>
  );
};

export const ColumnViewRequestPeriodSelect = ({
  value,
  onChange,
  isDisabled,
  name,
  timePeriodValues,
}: ColumnViewRequestPeriodSelectProps) => {
  const options = timePeriodValues.map((x) => ({
    value: x,
    label: TimePeriod[x],
  }));

  const handleChange = (item: ValueLabel | null) => {
    if (onChange && item) onChange(item.value);
  };

  const components = useMemo(
    () => ({
      SingleValue: CustomSingleValueComponent,
      Option: CustomOptionComponent,
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    }),
    [],
  );

  return (
    <StyledSelect
      value={options.find((x) => x.value === value)}
      options={options}
      onChange={handleChange}
      components={components}
      isDisabled={isDisabled}
      name={name}
    />
  );
};
