import { Box, Icon, MenuItem, useColorModeValue } from "@chakra-ui/react";
import { FC } from "react";
import { FiLogOut } from "react-icons/fi";
import { useAuthentication } from "../../../auth/AuthProvider";

type LogoutButtonProps = {};

export const LogoutButton: FC<LogoutButtonProps> = () => {
  const { currentAccount } = useAuthentication();
  const iconsColor = useColorModeValue("blue.500", "blue.300");

  return (
    <Box>
      <MenuItem
        as="a"
        _hover={{ background: "none" }}
        href={`${process.env.REACT_APP_AUTH_SERVER}/logout?redirectUrl=/login?accountId=${currentAccount.id}`}
      >
        <Icon as={FiLogOut} mr={1} color={iconsColor} />
        Logout
      </MenuItem>
    </Box>
  );
};
