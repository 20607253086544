import {
  ApiClient,
  ApiRentableEquipment,
  CreateApiRentableEquipment,
  UpdateApiRentableEquipment,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface LoadEquipmentProps {
  accountId: string;
  apiClient: ApiClient;
}

export const loadEquipment = createAsyncThunk(
  "account/load-equipment",
  async (params: LoadEquipmentProps, thunkAPI) => {
    const { filters } = (thunkAPI.getState() as RootState).equipments;

    const { apiClient, accountId } = params;
    const response = await apiClient.findRentableEquipment(accountId, {
      ...filters,
    });
    return response;
  }
);

export interface GetEquipmentProps extends LoadEquipmentProps {
  equipmentId: string;
}

export const getEquipment = createAsyncThunk(
  "account/get-equipment",
  async (params: GetEquipmentProps) => {
    const { apiClient, accountId, equipmentId } = params;
    const response = await apiClient.getRentableEquipment(
      accountId,
      equipmentId
    );
    return response;
  }
);

export interface CreateEquipmentProps {
  accountId: string;
  apiClient: ApiClient;
  equipment: CreateApiRentableEquipment;
}

export const createEquipment = createAsyncThunk(
  "account/new-equipment",
  async (params: CreateEquipmentProps) => {
    const { apiClient, accountId, equipment } = params;
    const response = await apiClient.createRentableEquipment(
      accountId,
      equipment
    );
    return response;
  }
);

export interface UpdateEquipmentParams {
  accountId: string;
  equipmentId: string;
  apiClient: ApiClient;
  equipment: UpdateApiRentableEquipment;
}

export const updateEquipment = createAsyncThunk(
  "account/update-equipment",
  async (params: UpdateEquipmentParams) => {
    const { apiClient, accountId, equipmentId, equipment } = params;
    const response = await apiClient.updateRentableEquipment(
      accountId,
      equipmentId,
      equipment
    );
    return response;
  }
);

interface DeactivateEquipmentProps {
  accountId: string;
  equipmentId: string;
  apiClient: ApiClient;
}

export const deactivateEquipment = createAsyncThunk(
  "account/deactivate-equipment",
  async (params: DeactivateEquipmentProps) => {
    const { apiClient, accountId, equipmentId } = params;
    await apiClient.deactivateRentableEquipment(accountId, equipmentId);
    return equipmentId;
  }
);

export const reactivateEquipment = createAsyncThunk(
  "account/reactivate-equipment",
  async (params: DeactivateEquipmentProps) => {
    const { apiClient, accountId, equipmentId } = params;
    await apiClient.reactivateRentableEquipment(accountId, equipmentId);
    return equipmentId;
  }
);

interface EquipmentFiltersProps {
  page: number;
  pageSize: number;
  search?: string;
  includeInactive: boolean;
}

interface EquipmentSliceProps {
  total: number;
  data: ApiRentableEquipment[];
  filters: EquipmentFiltersProps;
  workingEquipment: ApiRentableEquipment | null;
}

const DEFAULT_EQUIPMENT_FILTERS = {
  page: 1,
  pageSize: 20,
  search: undefined,
  includeInactive: false,
};

const DEFAULT_EQUIPMENT_VALUES: EquipmentSliceProps = {
  total: 0,
  workingEquipment: null,
  filters: DEFAULT_EQUIPMENT_FILTERS,
  data: [] as ApiRentableEquipment[],
};

export const rentableEquipment = createSlice({
  name: "equipments-slice",
  initialState: { ...DEFAULT_EQUIPMENT_VALUES },
  reducers: {
    cleanWorkingEquipment: (state) => {
      state.workingEquipment = null;
    },
    updateFilters: (
      state,
      { payload }: PayloadAction<Partial<EquipmentFiltersProps>>
    ) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    unloadEquipment: (state) => {
      state.data = [];
      state.total = 0;
      state.filters = { ...DEFAULT_EQUIPMENT_FILTERS };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createEquipment.fulfilled, (state, action) => {
      state.total++;
      state.data.unshift(action.payload);
    });

    builder.addCase(loadEquipment.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
    });

    builder.addCase(getEquipment.fulfilled, (state, action) => {
      state.workingEquipment = action.payload;
    });

    builder.addCase(updateEquipment.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (equipment) => equipment.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    });

    builder.addCase(deactivateEquipment.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (equipment) => equipment.id === action.payload
      );
      if (index !== -1) {
        if (!state.filters.includeInactive) {
          state.data.splice(index, 1);
        } else {
          state.data[index].active = false;
        }
      }
    });

    builder.addCase(reactivateEquipment.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (equipment) => equipment.id === action.payload
      );
      if (index !== -1) {
        state.data[index].active = true;
      }
    });
  },
});

export const { cleanWorkingEquipment, updateFilters, unloadEquipment } =
  rentableEquipment.actions;

export default rentableEquipment.reducer;
