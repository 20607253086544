import {
  Flex,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Text,
} from "@chakra-ui/react";

export interface RadioOption {
  label: string;
  value: string;
}

export interface AutoSavingFormControlProps {
  value: string;
  options: RadioOption[];
  radioProps?: RadioProps;
  radioGroupLabel?: string;
  radioGroupProps?: RadioGroupProps;
  onSave: (value: string) => Promise<void>;
}

export const AutoSavingRadio = (props: AutoSavingFormControlProps) => {
  const {
    value,
    onSave,
    options,
    radioProps,
    radioGroupProps,
    radioGroupLabel,
  } = props;

  const handleChange = (value: string) => {
    onSave(value);
  };

  return (
    <Flex flexDir="column" gap={2}>
      {radioGroupLabel && (
        <Text as="span" fontSize="lg">
          {radioGroupLabel}
        </Text>
      )}
      <RadioGroup onChange={handleChange} value={value} {...radioGroupProps}>
        <Flex gap={4}>
          {options.map((item, idx) => (
            <Radio
              size="lg"
              value={item.value}
              {...radioProps}
              key={`radioOption::${item.label}${idx}`}
            >
              {item.label}
            </Radio>
          ))}
        </Flex>
      </RadioGroup>
    </Flex>
  );
};
