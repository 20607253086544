import {
  ApiBudget,
  ApiClient,
  ApiPagedResult,
  CreateApiBudget,
  UpdateApiBudget,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface GetBudgetsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  search?: string;
}

export const loadBudgets = createAsyncThunk(
  "account/loadBudgets",
  async (params: GetBudgetsThunkParams, thunkAPI) => {
    const { apiClient, accountId, search } = params;
    const state = thunkAPI.getState() as RootState;

    const response = await apiClient.findBudgets(accountId, {
      page: search ? 1 : state.budgets.budgetsCurrentPage,
      pageSize: search ? 20 : state.budgets.pageSize,
      includeInactive: state.budgets.showDisabled,
      search,
    });

    thunkAPI.dispatch(loadBudgetsData(response));
  }
);

export interface CreateBudgetThunkParams {
  apiClient: ApiClient;
  accountId: string;
  budget: CreateApiBudget;
}

export const createBudget = createAsyncThunk(
  "account/budgets",
  async (params: CreateBudgetThunkParams, thunkAPI) => {
    const { apiClient, accountId, budget } = params;

    const response = await apiClient.createBudget(accountId, budget);
    thunkAPI.dispatch(addBudget(response));
  }
);

export interface UpdateBudgetThunkParams {
  apiClient: ApiClient;
  accountId: string;
  budgetId: string;
  budget: UpdateApiBudget;
}

export const updateBudget = createAsyncThunk(
  "account/updateBudget",
  async (params: UpdateBudgetThunkParams, thunkAPI) => {
    const { apiClient, accountId, budgetId, budget } = params;

    const response = await apiClient.updateBudget(accountId, budgetId, budget);
    thunkAPI.dispatch(updateBudgetData(response));
  }
);

export type BudgetActionType = "deactive" | "reactive";

export interface ToggleOnOrOffBudgetThunkParams {
  apiClient: ApiClient;
  accountId: string;
  budgetId: string;
  type: BudgetActionType;
}

export const toggleActivateBudget = createAsyncThunk(
  "account/toogleActive",
  async (params: ToggleOnOrOffBudgetThunkParams, thunkAPI) => {
    const { apiClient, accountId, budgetId, type } = params;
    if (type === "deactive") {
      await apiClient.deactivateBudget(accountId, budgetId);
      thunkAPI.dispatch(deleteBudget(budgetId));
    }
    if (type === "reactive") {
      const response = await apiClient.reactivateBudget(accountId, budgetId);
      thunkAPI.dispatch(updateBudgetData(response));
    }
  }
);

export interface BudgetSliceState {
  budgetsData: ApiBudget[];
  budgetsCurrentPage: number;
  budgetsTotalPages: number;
  showDisabled: boolean;
  pageSize: number;
}

export const budgetSlice = createSlice({
  name: "budgets",
  initialState: {
    budgetsData: [],
    budgetsCurrentPage: 1,
    budgetsTotalPages: 1,
    pageSize: 20,
    showDisabled: false,
  } as BudgetSliceState,
  reducers: {
    loadBudgetsData: (
      state,
      action: PayloadAction<ApiPagedResult<ApiBudget>>
    ) => {
      state.budgetsData = action.payload.data;
      state.budgetsCurrentPage = action.payload.options.page || 1;
      state.budgetsTotalPages = action.payload.total;
    },
    addBudget: (state, action: PayloadAction<ApiBudget>) => {
      state.budgetsData.unshift(action.payload);
    },
    updateBudgetData: (state, action: PayloadAction<ApiBudget>) => {
      const budgetIndex = state.budgetsData.findIndex(
        (x) => x.id === action.payload.id
      );
      if (budgetIndex !== -1) {
        state.budgetsData[budgetIndex] = action.payload;
      }
    },
    toggleShowDisabled: (state, action: PayloadAction<boolean>) => {
      state.showDisabled = action.payload;
    },
    deleteBudget: (state, action: PayloadAction<string>) => {
      const budgetIndex = state.budgetsData.findIndex(
        (x) => x.id === action.payload
      );
      if (state.showDisabled) {
        state.budgetsData[budgetIndex].active = false;
      }
      if (!state.showDisabled && budgetIndex !== -1) {
        state.budgetsData.splice(budgetIndex, 1);
      }
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.budgetsCurrentPage = action.payload;
    },
    unloadBudgets: (state) => {
      state.budgetsData = [];
      state.budgetsCurrentPage = 1;
      state.budgetsTotalPages = 0;
      state.showDisabled = false;
    },
  },
});

export const {
  loadBudgetsData,
  addBudget,
  updateBudgetData,
  toggleShowDisabled,
  deleteBudget,
  unloadBudgets,
  setCurrentPage,
} = budgetSlice.actions;

export default budgetSlice.reducer;
