import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React from "react";

export interface SwitchControlProps {
  label: string;
  name: string;
  isChecked?: boolean;
  size?: string;
  switchPosition?: SwitchPosition;
}

export enum SwitchPosition {
  SIDE = "LEFT-SIDE",
  BELOW = "BELOW",
}

export const SwitchControl = ({
  name,
  isChecked,
  label,
  size,
  switchPosition = SwitchPosition.BELOW,
}: SwitchControlProps) => {
  const { submitCount } = useFormikContext();
  const [field, meta] = useField({
    name,
    checked: isChecked,
  });

  return (
    <FormControl
      isInvalid={!!meta.error && (meta.touched || submitCount > 0)}
      display={
        switchPosition === SwitchPosition.BELOW ? "inline-block" : "inline-flex"
      }
    >
      {switchPosition === SwitchPosition.BELOW && (
        <FormLabel htmlFor={name}>{label}</FormLabel>
      )}
      <Switch
        {...field}
        id={name}
        isChecked={field.value}
        size={size || "md"}
      />
      {switchPosition === SwitchPosition.SIDE && (
        <FormLabel htmlFor={name} ml={4} mt={-0.5}>
          {label}
        </FormLabel>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
