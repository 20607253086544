import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { ApiRequest, ApiWorkflow } from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ModalProvider } from "../../hooks/useIsInModal";
import { RootState, useThunkDispatch } from "../../store";
import { unloadFormikModal } from "../../store/formik-modal.slice";
import { setNewRequestDialogIsOpen } from "../../store/new-request-form.slice";
import { loadRequests } from "../../store/request-list.slice";
import { useAuthentication } from "../auth/AuthProvider";
import { NewRequestForm, NewRequestFormContext } from "./NewRequestForm";
import { NewRequestFormSkeleton } from "./NewRequestFormSkeleton";
import { NewRequestToast } from "./NewRequestToast";

export const NewRequestModal = () => {
  const { currentUser, currentAccount, apiClient, isProductAdmin } =
    useAuthentication();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isOpen = useSelector(
    (state: RootState) => state.newRequestForm.newRequestDialogIsOpen
  );
  const { workflows, policyMap, isRequesterOnly, isApprover } = useSelector(
    (state: RootState) => state.localCache
  );
  const { values, isSubmitting, handleSubmit, isUploading } = useSelector(
    (state: RootState) => state.formikModalSlice
  );

  const [errorText, setErrorText] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formContext, setFormContext] = useState<NewRequestFormContext>();
  const [copiedToClipboard, setCopiedClipboard] = useState(false);
  const titleSize = useBreakpointValue({ base: "md", md: "lg" });
  const showWorkflowInHeader = useBreakpointValue({ base: false, md: true });

  const toast = useToast();

  const isApproverOnly = useMemo(() => {
    return isApprover && !isProductAdmin;
  }, [isApprover, isProductAdmin]);

  const handleOnClose = useCallback(() => {
    if (isRequesterOnly || isApproverOnly) setFormContext(undefined);
    dispatch(setNewRequestDialogIsOpen(false));
  }, [dispatch, isApproverOnly, isRequesterOnly]);

  const handleSave = useCallback(
    (request: ApiRequest) => {
      handleOnClose();

      if (location.pathname === "/requests") {
        thunkDispatch(loadRequests({ apiClient, account: currentAccount }));
      }

      const handleToastClick = () => {
        navigate(`/requests/${request.key}`);
        toast.close("create-wo");
      };

      toast({
        duration: 5000,
        id: "create-wo",
        isClosable: true,
        position: "top",
        render: ({ id, onClose }) => {
          return (
            <NewRequestToast
              request={request}
              onClose={onClose}
              onClick={handleToastClick}
            />
          );
        },
      });
    },
    [
      apiClient,
      currentAccount,
      toast,
      navigate,
      location,
      handleOnClose,
      thunkDispatch,
    ]
  );

  const initForm = useCallback(
    async (workflow?: ApiWorkflow) => {
      try {
        if (
          !workflow &&
          (isRequesterOnly || isApproverOnly) &&
          workflows.length > 1
        ) {
          setIsLoading(false);
          return;
        }
        workflow = workflow || workflows[0];

        if (!workflow) {
          setErrorText("No workflows available to create a request.");
          setIsLoading(false);
          return;
        }

        const policy = policyMap[workflow.id];

        // this is loaded to ensure the latest fields are present
        const [{ data: schemaFields }] = await Promise.all([
          apiClient.findWorkflowSchemaFields(
            currentAccount.id,
            workflow.schema.id,
            { pageSize: 50 }
          ),
        ]);

        const rulesEngine = new SchemaRulesEngine({
          account: currentAccount,
          form: "create",
          schemaFields,
          user: currentUser,
          workflow,
          policy,
          isProductAdmin,
        });

        setFormContext({
          workflow,
          schemaFields,
          rulesEngine,
          policy,
        });
        setErrorText(undefined);
      } catch (e) {
        setErrorText("Error loading form");
      } finally {
        setIsLoading(false);
      }
    },
    [
      isRequesterOnly,
      isApproverOnly,
      workflows,
      policyMap,
      apiClient,
      currentAccount,
      currentUser,
      isProductAdmin,
    ]
  );

  const handleWorkflowChange = useCallback(
    (workflow: ApiWorkflow | null) => {
      if (workflow === null) {
        return;
      }
      localStorage.setItem(
        `${currentAccount.id}:new-request-wf`,
        workflow.requestSlug
      );
      initForm(workflow);
    },
    [initForm, currentAccount]
  );

  useEffect(() => {
    if (!isOpen) {
      setCopiedClipboard(false);
      return;
    }

    if (isRequesterOnly || isApproverOnly) {
      initForm(undefined);
      return;
    }
    const wfSlug = localStorage.getItem(`${currentAccount.id}:new-request-wf`);
    const wf = !wfSlug
      ? undefined
      : workflows.find((w) => w.requestSlug === wfSlug);
    initForm(wf);
  }, [
    dispatch,
    initForm,
    isOpen,
    currentAccount,
    workflows,
    isRequesterOnly,
    isApproverOnly,
  ]);

  const copyToClipboard = useCallback(() => {
    if (!formContext) {
      return;
    }
    const newRequestPath = `${window.location.protocol}//${window.location.host}/?accountId=${currentAccount.id}&workflow=${formContext.workflow.requestSlug}&newRequest=true`;
    navigator.clipboard.writeText(newRequestPath).then(() => {
      toast({
        duration: 2000,
        isClosable: true,
        position: "top",
        status: "success",
        title: '"New request form" path copied to clipboard!',
      });
      setCopiedClipboard(true);
    });
  }, [toast, currentAccount, formContext]);

  useEffect(() => {
    return () => {
      dispatch(unloadFormikModal());
    };
  }, [dispatch]);

  return isOpen ? (
    <ModalProvider>
      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        closeOnEsc={true}
        closeOnOverlayClick={false}
        preserveScrollBarGap
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxWidth="4xl" mt={6}>
          <ModalHeader>
            {showWorkflowInHeader && (
              <SimpleGrid columns={currentAccount.logo ? 2 : 1}>
                <Box>
                  <Heading size={titleSize}>
                    New Request
                    <Icon
                      ml={2}
                      boxSize={5}
                      cursor="pointer"
                      as={MdContentCopy}
                      onClick={copyToClipboard}
                      color={copiedToClipboard ? "blue.500" : "gray.500"}
                    />
                  </Heading>
                </Box>
                {currentAccount.logo && (
                  <Flex justifyContent="flex-end">
                    <Img
                      src={currentAccount.logo}
                      mr={2}
                      alt="Logo"
                      maxH="60px"
                      maxW="120px"
                    />
                  </Flex>
                )}
              </SimpleGrid>
            )}
          </ModalHeader>
          <ModalBody overflowY="auto" minHeight={["270px", "100px"]}>
            {isLoading && <NewRequestFormSkeleton />}
            {!isLoading && errorText ? (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>{errorText}</AlertTitle>
              </Alert>
            ) : null}

            {!isLoading && !errorText && currentAccount.logo ? (
              <Stack mb={3} pt={2} display={["flex", null, "none"]}>
                <Flex justifyContent="center">
                  <Img
                    src={currentAccount.logo}
                    mr={2}
                    alt="Logo"
                    maxH="100px"
                    maxW="200px"
                  />
                </Flex>
                <Flex justifyContent="center">
                  <Heading>New Request</Heading>
                </Flex>
              </Stack>
            ) : null}
            {isOpen && !isLoading && !errorText ? (
              <NewRequestForm
                onSave={handleSave}
                context={formContext}
                onCancel={handleOnClose}
                handleWorkflowChange={handleWorkflowChange}
                hideWorkflow={workflows.length === 1}
              />
            ) : null}
          </ModalBody>
          <ModalFooter
            justifyContent="space-between"
            position="sticky"
            bottom="0"
            zIndex="1"
            w="100%"
            paddingTop={2}
          >
            <Button
              variant="outline"
              isDisabled={isSubmitting}
              onClick={handleOnClose}
              size="md"
              p={3}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="solid"
              colorScheme="blue"
              isDisabled={isSubmitting || isUploading || !formContext}
              size="md"
              isLoading={isSubmitting}
              onClick={() => handleSubmit(values)}
            >
              Save Request
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ModalProvider>
  ) : null;
};
