import { Text, TextProps } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDateTimeFormatter } from "../../hooks/useIntlFormatter";
import { RootState } from "../../store";

interface LocaleDateProps {
  date: string | Date;
  textProps?: TextProps;
  localeAsParam?: string;
  options?: Intl.DateTimeFormatOptions;
}

export const LocaleDate: FC<LocaleDateProps> = ({
  date,
  options,
  textProps,
  localeAsParam,
}) => {
  const { locale } = useSelector((state: RootState) => state.global);
  const { formatDateTime } = useDateTimeFormatter(localeAsParam || locale);

  const formattedDate = useMemo(() => {
    if (typeof date === "string") {
      return formatDateTime(new Date(date), options);
    }
    return formatDateTime(date, options);
  }, [date, formatDateTime, options]);

  return (
    <Text as="span" isTruncated {...textProps}>
      {formattedDate}
    </Text>
  );
};

export const DEFAULT_LOCALE_DATE_OPTS: Intl.DateTimeFormatOptions = {
  weekday: "long",
  day: "2-digit",
  month: "short",
  year: "numeric",
};

export const DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};
