import { Icon, IconProps } from "@chakra-ui/icon";
import { HiOutlineSparkles } from "react-icons/hi2";

export const Sparkle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4177_27058)">
        <path
          d="M7.50001 3.33331L9.58334 7.91665L14.1667 9.99998L9.58334 12.0833L7.50001 16.6666L5.41668 12.0833L0.833344 9.99998L5.41668 7.91665L7.50001 3.33331ZM7.50001 7.35831L6.66668 9.16665L4.85834 9.99998L6.66668 10.8333L7.50001 12.6416L8.33334 10.8333L10.1417 9.99998L8.33334 9.16665L7.50001 7.35831ZM15.8333 7.49998L14.7833 5.21665L12.5 4.16665L14.7833 3.12498L15.8333 0.833313L16.875 3.12498L19.1667 4.16665L16.875 5.21665L15.8333 7.49998ZM15.8333 19.1666L14.7833 16.8833L12.5 15.8333L14.7833 14.7916L15.8333 12.5L16.875 14.7916L19.1667 15.8333L16.875 16.8833L15.8333 19.1666Z"
          fill="#A0AEC0"
        />
      </g>
      <defs>
        <clipPath id="clip0_4177_27058">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SparkleWithAnimation = (props: IconProps) => {
  return (
    <Icon
      as={HiOutlineSparkles}
      ml={2}
      boxSize="1.1em"
      className="ai-sparkle-animation"
      {...props}
    />
  );
};
