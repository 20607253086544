import {
  Box,
  Checkbox,
  Divider,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiSafetyNote,
  ApiUserSummaryAccepted,
} from "@operations-hero/lib-api-client";
import { Dispatch, FC, Fragment, SetStateAction, useCallback } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { ReadOnlyTextEditor } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorReadOnly";

interface RequestSafetyNotesProps {
  id: string;
  taskBookName: string;
  risks: ApiSafetyNote[];
  showSafetyError: boolean;
  warnings: ApiSafetyNote[];
  userReadSafetyNotes: boolean;
  safetyNotesAcceptedBy: ApiUserSummaryAccepted[];
  setShowSafetyError: Dispatch<SetStateAction<boolean>>;
  setUserReadSafetyNotes: Dispatch<SetStateAction<boolean>>;
  startTaskbook?: () => void;
  hasStarted?: boolean;
}

export const RequestSafetyNotes: FC<RequestSafetyNotesProps> = ({
  id,
  risks,
  warnings,
  taskBookName,
  showSafetyError,
  setShowSafetyError,
  userReadSafetyNotes,
  safetyNotesAcceptedBy,
  setUserReadSafetyNotes,
  startTaskbook,
  hasStarted,
}) => {
  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const { key, requestTaskbookId } = useParams<{
    key?: string;
    requestTaskbookId: string;
  }>();

  const backgroundColor = useColorModeValue("orange.100", "blue.900");
  const acceptedSafetyNotesColor = useColorModeValue("gray.100", "blue.900");
  const userBadgeBackgroundColor = useColorModeValue("white", "blue.800");
  const errorColor = useColorModeValue("red.500", "red.300");

  const handleSafetyNotesAccepted = useCallback(async () => {
    const index = safetyNotesAcceptedBy.findIndex(
      ({ user }) => user.id === currentUser.id,
    );

    const newSafetyNotesAcceptedBy = safetyNotesAcceptedBy;
    if (index === -1) {
      newSafetyNotesAcceptedBy.push({
        user: currentUser,
        acceptedDate: new Date().toISOString(),
      });
      setUserReadSafetyNotes(true);
      setShowSafetyError(false);
    } else {
      newSafetyNotesAcceptedBy.splice(index, 1);
      setUserReadSafetyNotes(false);
    }

    if (key && requestTaskbookId) {
      await apiClient.updateRequestTaskbook(
        currentAccount.id,
        key,
        requestTaskbookId,
        { safetyNotesAcceptedBy: newSafetyNotesAcceptedBy },
      );
    }

    if (!hasStarted && startTaskbook) {
      startTaskbook();
    }
  }, [
    safetyNotesAcceptedBy,
    key,
    currentUser,
    setUserReadSafetyNotes,
    setShowSafetyError,
    apiClient,
    currentAccount.id,
    requestTaskbookId,
    hasStarted,
    startTaskbook,
  ]);

  return (
    <Box
      p={6}
      w="100%"
      borderRadius={4}
      border="1px solid"
      bgColor={userReadSafetyNotes ? "transparent" : backgroundColor}
      borderColor={userReadSafetyNotes ? "orange.400" : " transparent"}
    >
      <Heading fontSize="3xl" color="orange.400">
        <Icon as={RiErrorWarningFill} verticalAlign="-4px" /> Safety Notes
      </Heading>
      <Divider borderColor="orange.400" pt={2} />

      {risks.length > 0 && (
        <Box py={2}>
          <Text fontSize="xl" color="orange.400" fontWeight="bold">
            Risks
          </Text>
          {risks.map((risk) => (
            <Fragment key={risk.id}>
              <ReadOnlyTextEditor value={risk.description} />
            </Fragment>
          ))}
          <Divider borderColor="orange.400" pt={2} />
        </Box>
      )}

      {warnings.length > 0 && (
        <Box py={2}>
          <Text fontSize="xl" color="orange.400" fontWeight="bold">
            Warnings
          </Text>
          {warnings.map((warning) => (
            <Fragment key={warning.id}>
              <ReadOnlyTextEditor value={warning.description} />
            </Fragment>
          ))}
        </Box>
      )}

      <Box
        id={id}
        w="100%"
        p={[2, 2, 6, 6]}
        borderRadius={8}
        bgColor={
          userReadSafetyNotes
            ? acceptedSafetyNotesColor
            : userBadgeBackgroundColor
        }
      >
        <Box
          p={4}
          h="44px"
          display="flex"
          w="max-content"
          borderRadius={6}
          alignItems="center"
          bgColor={
            userReadSafetyNotes
              ? userBadgeBackgroundColor
              : acceptedSafetyNotesColor
          }
        >
          <UserBadge value={currentUser} />
        </Box>
        <Checkbox
          style={{ touchAction: "none" }}
          mt={2}
          value="anithing"
          colorScheme="blue"
          isChecked={userReadSafetyNotes}
          onChange={handleSafetyNotesAccepted}
        >
          I have read the Safety Notes for
          <Text as="span" fontWeight="bold">
            {` ${taskBookName} `}
          </Text>
          task book. I acknowledge I have read the safety notes.
        </Checkbox>
        {showSafetyError && (
          <Text color={errorColor} fontSize="sm" fontWeight="semibold">
            Please, review the Safety Notes and check the box to acknowledge and
            continue to the tasks.
          </Text>
        )}
      </Box>
    </Box>
  );
};
