import { ApiEvent, ApiEventStatus } from "@operations-hero/lib-api-client";
import { EventTransition } from "./event-details.slice";
export const generateWorkflowStatusMap = () => {
  const result = [ApiEventStatus.pending];
  result.push(ApiEventStatus.confirmed);

  return result;
};
export const canReOpen = (
  event: ApiEvent,
  isEventAdmin: boolean,
  isVenueManager: boolean
) => {
  if (
    event.status === ApiEventStatus.confirmed ||
    (event.status === ApiEventStatus.declined &&
      (isEventAdmin || isVenueManager))
  ) {
    return true;
  }

  return false;
};
export const canHandleStatus = (
  event: ApiEvent,
  isEventAdmin: boolean,
  isVenueManager: boolean
) => {
  if (
    event.status === ApiEventStatus.confirmed &&
    (isEventAdmin || isVenueManager)
  ) {
    return false;
  }

  return true;
};
export const generateTransition = (
  event: ApiEvent,
  isEventAdmin: boolean,
  isVenueManager: boolean
): EventTransition | null => {
  if (!event) {
    return null;
  }

  if (
    event.status === ApiEventStatus.confirmed ||
    event.status === ApiEventStatus.declined
  ) {
    return null;
  }

  //Generates an order list of status changes for the primary path
  // ignores denied and held
  const map = generateWorkflowStatusMap();
  const indexOf = map.indexOf(event.status);
  const backwards = indexOf === 0 ? [] : map.splice(0, indexOf).reverse();
  // Hold and denied are not in the array
  const next = indexOf === -1 ? map[0] : map[1];
  const forwards = indexOf === -1 ? map.splice(1) : map.splice(2) || [];

  if (isEventAdmin || isVenueManager) {
    // no need to run the full cancel check
    return { next, backwards, forwards, canCancel: true };
  }

  if (!canHandleStatus(event, isEventAdmin, isVenueManager)) {
    return null;
  }

  return {
    next,
    backwards: backwards.filter((s) =>
      canHandleStatus(event, isEventAdmin, isVenueManager)
    ),
    forwards: forwards.filter((s) =>
      canHandleStatus(event, isEventAdmin, isVenueManager)
    ),
    canCancel: isEventAdmin,
  };
};
