import {
  Alert,
  AlertIcon,
  Divider,
  GridItem,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiScheduledRequest } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { DateBadge } from "../../components/badges/DateBadge";
import { RootState } from "../../store";
import { ScheduleForms } from "../account-settings/scheduled-request-form/ScheduleForms";

export const ScheduledRequestSection: FC<{ scheduledRequestId: string }> = ({
  scheduledRequestId,
}) => {
  const { currentAccount, apiClient, isProductAdmin } = useAuthentication();
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });
  const nameColor = useColorModeValue("blue.500", "blue.300");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const policy = useSelector((state: RootState) => state.requestForm.policy);
  const [scheduledRequest, setScheduledRequest] =
    useState<ApiScheduledRequest>();

  const getScheduledRequests = useCallback(() => {
    apiClient
      .getScheduleRequest(currentAccount.id, scheduledRequestId)
      .then((response) => {
        setScheduledRequest(response);
      });
  }, [apiClient, currentAccount.id, scheduledRequestId]);

  useEffect(() => {
    getScheduledRequests();
  }, [getScheduledRequests]);

  return scheduledRequest ? (
    <>
      <GridItem colSpan={[12, null, 12]}>
        <Alert status="info">
          <AlertIcon />
          This request was generated from the following schedule
        </Alert>
      </GridItem>
      <GridItem colSpan={[12, null, 12]}>
        <HStack>
          <Heading size="md">Scheduled Request</Heading>
        </HStack>
      </GridItem>
      <GridItem colSpan={[12, null, 5]}>
        <Text fontWeight="bold">Scheduled Name</Text>
        {isMobile && (
          <Text
            onClick={isProductAdmin || policy?.admin ? onOpen : () => {}}
            as="u"
            color={nameColor}
            _hover={{
              cursor: isProductAdmin || policy?.admin ? "pointer" : "undefined",
            }}
          >
            {scheduledRequest.name}
          </Text>
        )}
      </GridItem>
      <GridItem colSpan={[12, null, 3]}>
        <Text fontWeight="bold">Number of Requests</Text>
        {isMobile && scheduledRequest.requestsGenerated}
      </GridItem>
      <GridItem colSpan={[12, null, 4]}>
        <Text fontWeight="bold">Next Run</Text>
        {isMobile && (
          <DateBadge
            date={scheduledRequest.schedules[0]?.nextRuns.find(
              (nextRun) => new Date(nextRun) > new Date()
            )}
          />
        )}
      </GridItem>
      {!isMobile && (
        <>
          <GridItem colSpan={[12, null, 5]}>
            <Text
              onClick={isProductAdmin || policy?.admin ? onOpen : () => {}}
              as="u"
              color={nameColor}
              _hover={{
                cursor:
                  isProductAdmin || policy?.admin ? "pointer" : "undefined",
              }}
            >
              {scheduledRequest.name}
            </Text>
          </GridItem>
          <GridItem colSpan={[12, null, 3]}>
            {scheduledRequest.requestsGenerated}
          </GridItem>
          <GridItem colSpan={[12, null, 4]}>
            <DateBadge
              date={scheduledRequest.schedules[0]?.nextRuns.find(
                (nextRun) => new Date(nextRun) > new Date()
              )}
            />
          </GridItem>
        </>
      )}
      <ScheduleForms
        isOpen={isOpen}
        onClose={onClose}
        workingScheduledRequestId={scheduledRequest && scheduledRequest.id}
      />
      <GridItem colSpan={[12, null, 12]}>
        <Divider mt={2} mb={2} />
      </GridItem>
    </>
  ) : null;
};
