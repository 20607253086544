import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiVendor } from "@operations-hero/lib-api-client";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../store";
import {
  initVendorFilter,
  loadVendorFilter,
} from "../../store/transaction-list.slice";
import { useAuthentication } from "../auth/AuthProvider";
import { AdditionalNumberBadge } from "../badges/AdditionalNumberBadge";
import { VendorBadge } from "../badges/VendorBadge";

export interface VendorFilterProps {
  values: (ApiVendor | string)[];
  onChange: (value: ApiVendor[]) => void;
}

export const VendorFilterBadge = ({ value }: { value: ApiVendor }) => {
  return <HStack>{value && <Box>{value.name}</Box>}</HStack>;
};

const ContentRenderer = ({ props, state }: SelectRenderer<ApiVendor>) => {
  const { values } = props;
  return (
    <Box p={1}>
      {values.length === 0 && "Vendor"}
      {values.length === 1 && <Text>{values[0].name}</Text>}
      {values.length > 1 && (
        <Flex alignItems="center">
          <Text mr={1}>{values[0].name}</Text>
          <AdditionalNumberBadge number={values.length - 1} />
        </Flex>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiVendor>) => {
  const isDisabled = useMemo(
    () => (item: ApiVendor) =>
      props.values.includes(item) && !methods.isSelected(item),
    [props.values, methods]
  );

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Vendors"
        />
      </Box>
      <Box>
        {
          //@ts-ignore
          (state.search ? state.searchResults : props.options).map((item) => {
            return (
              <Checkbox
                key={item.id}
                isChecked={(() => props.values.includes(item))()}
                onChange={
                  isDisabled(item) ? undefined : () => methods.addItem(item)
                }
                w="100%"
                p={1}
                disabled={isDisabled(item)}
              >
                <VendorBadge fontSize={16} value={item} />
              </Checkbox>
            );
          })
        }
      </Box>
    </Box>
  );
};

export const VendorFilter: FC<VendorFilterProps> = ({ values, onChange }) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const { vendor: selectedValues } = useSelector(
    (state: RootState) => state.transactionList.filters
  );

  const { vendors: vendorFilterOptions, initCompleted } = useSelector(
    (state: RootState) => state.transactionList
  );
  const [search, setSearch] = useState<string>("");

  const initOnce = useRef(false);
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const [filterInitCompleted, setFilterInitCompleted] = useState(false);

  const filteredValues = useMemo(
    () =>
      vendorFilterOptions.filter((budg) =>
        values.find((val) => val === budg.id) ? true : false
      ),
    [vendorFilterOptions, values]
  );

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiVendor>) => {
      if (!state.search) {
        setSearch("");
      }
      setSearch(state.search);
      return props.options;
    },
    []
  );

  useEffect(() => {
    if (!initCompleted || initOnce.current) {
      return;
    }
    initOnce.current = true;

    const promise =
      !selectedValues || selectedValues.length === 0
        ? Promise.resolve()
        : thunkDispatch(
            initVendorFilter({
              apiClient,
              account,
              selectedValues,
            })
          );

    promise.then(() => setFilterInitCompleted(true));
  }, [apiClient, account, thunkDispatch, initCompleted, selectedValues]);

  useEffect(() => {
    if (!filterInitCompleted) {
      return;
    }
    thunkDispatch(
      loadVendorFilter({
        apiClient,
        account,
        search,
      })
    );
  }, [apiClient, account, thunkDispatch, filterInitCompleted, search]);

  return filterInitCompleted ? (
    <Select
      multi
      options={vendorFilterOptions}
      values={filteredValues}
      className={themeClass}
      onChange={(value) => onChange(value)}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      clearOnSelect={false}
      clearable={false}
      contentRenderer={ContentRenderer}
      dropdownRenderer={DropdownRenderer}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  ) : null;
};
