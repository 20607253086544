import {
  Box,
  Button,
  HStack,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  clearBulkEditDataAsync,
  loadBulkEditDataAsync,
} from "../../../store/request-form/request-bulk-actions.slice";
import { loadRequests } from "../../../store/request-list.slice";
import { loadTransitionMap } from "../../../store/requests/request-transitions.thunk";
import { BulkEditModal } from "../bulk-edit/BulkEditModal";
import { BulkPrintButton } from "../print/BulkPrintButton";

interface BulkHeaderOptionsProps {
  handleSelectAll?: () => void;
}
export const BulkHeaderOptions: FC<BulkHeaderOptionsProps> = ({
  handleSelectAll,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useThunkDispatch();
  const {
    totalSelected,
    updateStatus,
    successfulUpdateMessage,
    partialUpdateMessage,
    failedUpdateMessage,
    totalAllowedForSelection,
    bulkEditStep,
    loadingEditData,
  } = useSelector((state: RootState) => state.requestsBulkActionsSlice);

  const { totalRequests } = useSelector(
    (state: RootState) => state.requestList
  );

  const showToast = useShowToast();
  const buttonColor = useColorModeValue("white", "unset");

  const openBulkEditModal = useCallback(() => {
    onOpen();
    dispatch(
      loadBulkEditDataAsync({ apiClient, accountId: currentAccount.id })
    );
  }, [apiClient, currentAccount.id, dispatch, onOpen]);

  useEffect(() => {
    if (totalSelected === 0 && isOpen) {
      onClose();
    }
  }, [totalSelected, isOpen, onClose]);

  useEffect(() => {
    if (
      updateStatus === "success" ||
      updateStatus === "successWithErrors" ||
      updateStatus === "failed"
    ) {
      dispatch(
        loadRequests({
          apiClient,
          account: currentAccount,
        })
      )
        .unwrap()
        .then((result) => {
          if (updateStatus === "failed") {
            showToast("error", failedUpdateMessage, undefined, undefined, 3000);
          } else {
            showToast(
              "success",
              successfulUpdateMessage,
              undefined,
              undefined,
              3000
            );
            if (updateStatus === "successWithErrors")
              showToast(
                "error",
                partialUpdateMessage,
                undefined,
                undefined,
                3000
              );
            dispatch(loadTransitionMap({ requests: result.requests.data }));
          }
        });
    }
  }, [
    updateStatus,
    apiClient,
    currentAccount,
    dispatch,
    showToast,
    successfulUpdateMessage,
    partialUpdateMessage,
    failedUpdateMessage,
  ]);

  useEffect(() => {
    if (bulkEditStep === "none" && isOpen && loadingEditData === "succeeded") {
      onClose();
      dispatch(clearBulkEditDataAsync());
    }
  }, [bulkEditStep, dispatch, isOpen, onClose, loadingEditData]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-around"
        flexDirection="row"
        w="full"
        alignItems="center"
        columnGap="2"
      >
        <HStack>
          <Button
            variant="ghost"
            colorScheme="blue"
            size="sm"
            onClick={handleSelectAll}
          >
            {totalSelected === totalAllowedForSelection
              ? "Unselect All"
              : "Select All"}
          </Button>
          <Button
            variant="outline"
            colorScheme="gray"
            size="sm"
            bg={buttonColor}
            onClick={openBulkEditModal}
          >
            Bulk Edits
          </Button>
          <BulkPrintButton bulkMode="selection" />
        </HStack>
        <Spacer />
        <Text
          fontWeight="semibold"
          fontSize="sm"
        >{`${totalSelected} of ${totalRequests} Selected`}</Text>
      </Box>
      <BulkEditModal isOpen={isOpen} onClose={onClose}></BulkEditModal>
    </>
  );
};
