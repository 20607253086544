import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import { ApiItemAdjustmentType } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { changeQuantityFormSchema } from "../../pages/inventory/inventory-items/quantity/ChangeQuantirySchema";
import { InventoryTypeAutocomplete } from "../selects/InventoryTypeAutocomplete";

export interface InventoryTypeSelectControlProps {
  label: string;
  name: string;
  value: ApiItemAdjustmentType | null;
  placeholder?: string;
  helperText?: string;
  labelProps?: FormLabelProps;
  shouldResetOnChange?: boolean;
  setSchema?: (schema: any) => void;
  isDisabled?: boolean;
}

export const InventoryTypeAutocompleteControl = ({
  name,
  value,
  label,
  placeholder,
  helperText,
  labelProps,
  shouldResetOnChange,
  setSchema,
  isDisabled,
}: InventoryTypeSelectControlProps) => {
  const { submitCount, resetForm } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (value: ApiItemAdjustmentType | null) => {
      shouldResetOnChange && resetForm();
      setSchema && value !== null && setSchema(changeQuantityFormSchema(value));

      helper.setTouched(true);
      helper.setValue(value || null);
    },
    [helper, resetForm, setSchema, shouldResetOnChange]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name} {...labelProps}>
        {label}
      </FormLabel>
      <InventoryTypeAutocomplete
        {...field}
        onChange={handleOnChange}
        isDisabled={isDisabled}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
