import {
  GridItem,
  Icon,
  IconButton,
  Stack,
  StackItem,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiEventGroup, ExportType } from "@operations-hero/lib-api-client";
import { useCallback, useEffect } from "react";
import { IoDownloadOutline } from "react-icons/io5";
import { RiFilterOffLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { QuickFilterButton } from "../../../components/buttons/QuickFilterButton";
import { ExportModal } from "../../../components/export/ExportModal";
import { SortDirectionFilter } from "../../../components/filters/SortDirectionFilter";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { PageData } from "../../../components/pager/PageData";
import { Pager } from "../../../components/pager/Pager";
import { RootState, useThunkDispatch } from "../../../store";
import {
  INITIAL_PORTAL_FILTERS,
  loadEventGroupPortalUsers,
  setPortalUsersFilter,
  unloadEventGroupPortalUsersSlice,
} from "../../../store/portal-users.slice";
import { EventGroupFilter } from "../../events/events-list/filters/EventGroupFilter";
import { AccountPageHeader } from "../account-components/AccountHeader";
import { PortalUserList } from "./PortalUsersList";

export default function PortalUsersListPage() {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
  });
  const { apiClient, currentAccount } = useAuthentication();
  const { filters, total } = useSelector(
    (state: RootState) => state.eventGroupPortalUsersSlice,
  );

  useEffect(() => {
    thunkDispatch(
      loadEventGroupPortalUsers({ apiClient, accountId: currentAccount.id }),
    );
  }, [apiClient, currentAccount.id, thunkDispatch, filters]);

  useEffect(() => {
    return () => {
      dispatch(unloadEventGroupPortalUsersSlice());
    };
  }, [dispatch]);

  const handleIncludeInactive = useCallback(() => {
    dispatch(
      setPortalUsersFilter({ includeInactive: !filters.includeInactive }),
    );
  }, [dispatch, filters.includeInactive]);

  const handleOnChangeSearch = useCallback(
    (value: string) => {
      dispatch(setPortalUsersFilter({ page: 1, search: value }));
    },
    [dispatch],
  );

  const handleOnPageChange = useCallback(
    (value: number) => {
      dispatch(setPortalUsersFilter({ page: value }));
    },
    [dispatch],
  );
  const handleOnChangeEventGroup = useCallback(
    (values: ApiEventGroup[]) => {
      dispatch(
        setPortalUsersFilter({ eventGroups: [...values.map((x) => x.id)] }),
      );
    },
    [dispatch],
  );
  const handleClearFilters = useCallback(() => {
    dispatch(setPortalUsersFilter(INITIAL_PORTAL_FILTERS));
  }, [dispatch]);
  const handleSortDirectionChange = useCallback(
    (value: string | string[]) => {
      const single = Array.isArray(value) ? value[0] : value;
      const direction = single === "asc" ? "asc" : "desc";
      dispatch(setPortalUsersFilter({ direction }));
    },
    [dispatch],
  );
  const handleSortFieldChange = useCallback(
    (value: string | string[]) => {
      const sort = Array.isArray(value) ? value[0] : value;
      dispatch(setPortalUsersFilter({ sort }));
    },
    [dispatch],
  );
  return (
    <>
      <Stack>
        <AccountPageHeader
          title="Portal  Users"
          collapseLabel="filters"
          buttonsLeftTitle={
            <QuickFilterButton
              activeText="Include Inactive"
              onClick={handleIncludeInactive}
              isActive={Boolean(filters.includeInactive)}
            />
          }
          buttonsTitle={
            <IconButton
              ml={2}
              colorScheme="blue"
              aria-label="Export"
              variant="outline"
              icon={<IoDownloadOutline />}
              onClick={onExportModalOpen}
              size={isMobile ? "sm" : "md"}
            />
          }
        >
          <GridItem colSpan={[12, 12, 1]} display="inline-flex" gap={2}>
            <EventGroupFilter
              value={filters.eventGroups as string[]}
              onChange={handleOnChangeEventGroup}
            />
          </GridItem>
          <GridItem
            colSpan={[12, 12, 6, 10]}
            colStart={[1, 1, 7, 9]}
            display="flex"
          >
            <AccountSearchBox
              searchPlaceholder="Search name  / email"
              onInputChange={handleOnChangeSearch}
            />
            <IconButton
              mx={2}
              icon={<Icon as={RiFilterOffLine} />}
              aria-label="Sort"
              colorScheme="blue"
              variant="outline"
              onClick={handleClearFilters}
            />
            <SortDirectionFilter
              handleSortDirectionChange={handleSortDirectionChange}
              handleSortFieldChange={handleSortFieldChange}
              direction={filters.direction}
              sort={filters.sort}
              sortOptions={{
                created: "Created",
                updated: "Updated",
                name: "Name",
              }}
            />
          </GridItem>
        </AccountPageHeader>

        <StackItem>
          <PageData
            total={total}
            currentPage={filters.page || 1}
            pageSize={filters.pageSize || 1}
            textProps={{ fontSize: "14px" }}
          />
        </StackItem>
        <StackItem>
          <PortalUserList />
        </StackItem>
      </Stack>

      {filters.pageSize && filters.page && (
        <Pager
          showDetails
          total={total}
          currentPage={filters.page}
          pageSize={filters.pageSize}
          onPageChange={handleOnPageChange}
        />
      )}

      {isExportModalOpen && (
        <ExportModal
          isOpen={isExportModalOpen}
          onClose={onExportModalClose}
          total={total}
          options={{ ...filters }}
          type={ExportType.portalUsers}
        />
      )}
    </>
  );
}
