import { Flex, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { ApiComment } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { BsTrash } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { useConvertMentions } from "../../hooks/useConvertMentions";
import { UserBadge } from "../badges/UserBadge";
import {
  DEFAULT_LOCALE_DATE_OPTS,
  DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
  LocaleDate,
} from "../dates/LocaleDate";
import { ReadOnlyTextEditorComments } from "../form-helpers/rich-text-editor/CommentsTextEditorReadOnly";

type CommentBaseProps = {
  comment: ApiComment;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export const CommentBase: FC<CommentBaseProps> = ({
  comment,
  onDeleteClick,
  onEditClick,
}) => {
  const { createdBy, updated, comment: commentString, mentioned } = comment;

  const { editorState: contentToDisplay } = useConvertMentions({
    value: commentString,
    mentioned: mentioned,
  });

  return (
    <Flex flexDir="column" gap={4}>
      <HStack justify="space-between">
        <VStack align="stretch" alignItems="start" spacing="unset" gap="unset">
          <UserBadge value={createdBy} />
          {updated && (
            <Text color="gray.500" fontSize="xs" as="span">
              <LocaleDate
                date={updated}
                options={DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS}
              />
            </Text>
          )}
        </VStack>
        <HStack>
          <IconButton
            aria-label="edit-comment"
            icon={<MdModeEditOutline />}
            variant="ghost"
            colorScheme="blue"
            onClick={onEditClick}
          />
          <IconButton
            aria-label="delete-comment"
            icon={<BsTrash />}
            colorScheme="blue"
            variant="ghost"
            onClick={onDeleteClick}
          />
        </HStack>
      </HStack>
      <Flex>
        <ReadOnlyTextEditorComments value={contentToDisplay} />
      </Flex>
      {comment.updated > comment.created ? (
        <Text mt={1} as="span" fontSize="xs" display="flex" color="gray.500">
          (edited
          <LocaleDate
            date={comment.updated}
            textProps={{ ml: 1, fontWeight: "normal" }}
            options={{
              ...DEFAULT_LOCALE_DATE_OPTS,
              hour: "2-digit",
              minute: "2-digit",
            }}
          />
          )
        </Text>
      ) : null}
    </Flex>
  );
};
