import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
} from "@chakra-ui/react";
import {
  ApiLocationSummary,
  ApiRequestAssignee,
  ApiWorkflowReference,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { MultiValue } from "chakra-react-select";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { AssigneeAutocomplete } from "../selects/AssigneeAutocomplete";

export interface AssigneeAutocompleteControlProps {
  label?: string;
  name: string;
  value: ApiRequestAssignee[] | null;
  workflow: ApiWorkflowReference | null;
  location?: ApiLocationSummary;
  reportingCategory?: ApiWorkflowReportingCategorySummary;
}

export const AssigneeAutocompleteControl = ({
  name,
  value,
  label,
  workflow,
  location,
  reportingCategory,
}: AssigneeAutocompleteControlProps) => {
  const { submitCount } = useFormikContext();
  const { currentUser } = useAuthentication();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (user: MultiValue<ApiRequestAssignee>) => {
      helper.setTouched(true);
      helper.setValue(user || null);
    },
    [helper]
  );

  const handleAssignSelf = useCallback(() => {
    handleOnChange([{ type: "user", assignee: currentUser }]);
  }, [currentUser, handleOnChange]);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <Flex
        justifyContent={label ? "space-between" : "end"}
        alignContent="flex-start"
      >
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <Link color="blue.400" onClick={handleAssignSelf}>
          Assign to me
        </Link>
      </Flex>
      <AssigneeAutocomplete
        {...field}
        workflow={workflow}
        location={location}
        reportingCategory={reportingCategory}
        onChange={handleOnChange}
        name={name}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
