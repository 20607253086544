import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { VStack } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setChangeType } from "../../../store/request-column-view.slice";
import { useColumnViewContext } from "./ColumnViewContext";
import { RequestBase } from "./RequestBase";

export type DraggableRequestProps = {
  request: ApiRequest;
  index: number;
};

export type State =
  | { type: "idle" }
  | { type: "preview"; container: HTMLElement }
  | { type: "dragging" };

export const DraggableRequest: FC<DraggableRequestProps> = ({
  request,
  index,
}) => {
  const ref = useRef(null);
  const [state, setState] = useState<State>({ type: "idle" });
  const [closesEdge, setClosestEdge] = useState<Edge | null>(null);

  const { initWorkingRequest } = useColumnViewContext();

  const dispatch = useDispatch();

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    return combine(
      draggable({
        element: el,
        getInitialData: () => ({
          ...request,
          dropElementType: "item",
        }),
        onDragStart: (args) => {
          const requestData: ApiRequest = {
            ...args.source.data,
          } as unknown as ApiRequest;
          setState({ type: "dragging" });
          dispatch(setChangeType("dnd"));
          initWorkingRequest(requestData);
        },

        onDrop: () => setState({ type: "idle" }),
      }),
      dropTargetForElements({
        element: el,
        getData: ({ input, element }) => {
          const attach = attachClosestEdge(
            {
              ...request,
              dropElementType: "item",
            },
            {
              input,
              element,
              allowedEdges: ["top", "bottom"],
            }
          );
          return attach;
        },
        onDragEnter: (args) => {
          const closestEdgeOfTarget: Edge | null = extractClosestEdge(
            args.self.data
          );
          setClosestEdge(closestEdgeOfTarget);
        },

        onDrag: (args) => {
          const closestEdgeOfTarget: Edge | null = extractClosestEdge(
            args.self.data
          );
          setClosestEdge(closestEdgeOfTarget);
        },
        onDrop: (args) => {
          setClosestEdge(null);
        },
        onDragLeave: (args) => {
          setClosestEdge(null);
        },
      })
    );
  }, [request, dispatch, initWorkingRequest]);

  return (
    <VStack gap="none" spacing="none" ref={ref}>
      {closesEdge === "top" && (
        <hr
          style={{
            height: 5,
            backgroundColor: "#90CDF4",
            width: "95%",
            borderRadius: 10,
            marginTop: 5,
            marginBottom: 5,
          }}
        />
      )}
      <RequestBase request={request} state={state} index={index} />
      {closesEdge === "bottom" && (
        <hr
          style={{
            backgroundColor: "#90CDF4",
            height: 5,
            width: "95%",
            borderRadius: 10,
            marginTop: 5,
            marginBottom: 5,
          }}
        />
      )}
    </VStack>
  );
};
