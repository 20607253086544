import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { EstimatedExpenses } from "../../pages/request-form/transactions/purchases/EstimateExpenses";

export interface EstimatedExpensesControlProps {
  label: string;
  name: string;
  value: number | null;
  placeholder?: string;
  helperText?: string;
}

export const EstimatedExpensesControl: FC<EstimatedExpensesControlProps> = ({
  label,
  name,
  value,
  placeholder,
  helperText,
}) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (budget: Partial<ApiRequest>) => {
      helper.setTouched(true);
      helper.setValue(budget.estimatedCost || null);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <EstimatedExpenses
        {...field}
        handlePropertyChange={handleOnChange}
        isCostSection
        hideLabel
        inputProps={{ width: undefined }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
