import { Skeleton, VStack } from "@chakra-ui/react";
import { memo, useMemo } from "react";

export const SkeletonCard = () => {
  return <Skeleton as="li" height="48" rounded="md" w="full"></Skeleton>;
};

export const SkeletonColumn = memo(() => {
  const cards = useMemo(() => {
    const columnLength = Math.floor(Math.random() * 2) + 1;
    const elements = [];
    for (let index = 0; index < columnLength; index++) {
      elements.push(<SkeletonCard key={index} />);
    }

    return elements;
  }, []);

  return (
    <VStack gap="1" justify="stretch" w="full" mt="1">
      {cards.map((item) => item)}
    </VStack>
  );
});
