import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { ResourceTimelineView } from "../../components/custom-resource-timeline/ResourceTimelineView";

const mapStateToProps = (state: RootState) => {
  const { project } = state.projectPageSlice;
  if (!project || !project.start || !project.end) {
    return null;
  }

  return {
    project: {
      start: project.start,
      end: project.end,
    },
  };
};

export default connect(mapStateToProps)(ResourceTimelineView);
