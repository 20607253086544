import { CloseIcon } from "@chakra-ui/icons";
import { Box, IconButton, IconButtonProps } from "@chakra-ui/react";
import {
  ApiAssignee,
  ApiLocationSummary,
  ApiRequestAssignee,
  ApiWorkflowReference,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { AsyncSelect, MultiValue } from "chakra-react-select";
import { FocusEventHandler, useCallback, useMemo } from "react";
import { MultiValueGenericProps } from "react-select";
import { useAuthentication } from "../auth/AuthProvider";
import { GroupBadge } from "../badges/GroupBadge";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  AssigneeOptionsProps,
  AssigneMultiValueRemove,
  CustomSelectComponentProp,
} from "./select-overrides-types";

export interface AssigneeAutocompleteProps {
  value: ApiRequestAssignee[];
  workflow: ApiWorkflowReference | null;
  location?: ApiLocationSummary;
  reportingCategory?: ApiWorkflowReportingCategorySummary;
  onChange: (value: MultiValue<ApiRequestAssignee>) => void;

  name?: string;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
}

const AssigneeBadge = ({ value }: { value: ApiRequestAssignee }) => {
  return value.type === "group" ? (
    <GroupBadge value={value.assignee} />
  ) : (
    <UserBadge value={value.assignee} />
  );
};

const CustomOptionComponent = createOptionComponent(AssigneeBadge);

function CustomMultiValueLabelComponent(
  props: MultiValueGenericProps<ApiRequestAssignee>
) {
  const { data, innerProps } = props;
  return data.type === "user" ? (
    <Box {...innerProps}>
      <UserBadge value={data.assignee} />
    </Box>
  ) : (
    <Box {...innerProps}>
      <GroupBadge value={data.assignee} />
    </Box>
  );
}

function CustomMultiValueRemoveComponent(props: AssigneMultiValueRemove) {
  const { innerProps, selectProps } = props;
  const buttonProps: IconButtonProps = {
    isDisabled: selectProps.isDisabled,
    "aria-label": "Remove option",
  };
  return (
    <Box {...innerProps} pr={2}>
      <IconButton
        variant="unstyled"
        icon={<CloseIcon boxSize="3" />}
        {...buttonProps}
        _hover={{
          background: "none",
        }}
        height="fit-content"
        lineHeight="none"
        aria-label="Remove option"
      />
    </Box>
  );
}

export const AssigneeAutocomplete = ({
  workflow,
  onChange,
  onBlur,
  name,
  value,
  isDisabled,
  location,
  reportingCategory,
}: AssigneeAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const handleChange = useCallback(
    (newValue: MultiValue<ApiRequestAssignee>) => {
      onChange(newValue);
    },
    [onChange]
  );

  const workflowId = useMemo(() => {
    if (!workflow) return undefined;
    return typeof workflow === "string" ? workflow : workflow.id;
  }, [workflow]);

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      if (!workflowId) {
        cb([]);
        return;
      }
      apiClient
        .findWorkflowAssignees(currentAccount.id, workflowId, {
          search: inputValue,
          pageSize: 20,
          location: location ? location.id : undefined,
          category: reportingCategory ? reportingCategory.id : undefined,
        })
        .then((results) => {
          cb(results.data);
        });
    },
    [apiClient, currentAccount.id, location, workflowId, reportingCategory]
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: AssigneeOptionsProps) => CustomOptionComponent(props),
      MultiValueLabel: (props: MultiValueGenericProps<ApiAssignee>) =>
        CustomMultiValueLabelComponent(props),
      MultiValueRemove: (props: AssigneMultiValueRemove) =>
        CustomMultiValueRemoveComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      //https://github.com/JedWatson/react-select/issues/1581
      key={`assigneeKey:${workflowId}::${location && location.id}::${
        reportingCategory && reportingCategory.id
      }`}
      cacheOptions={true}
      components={components}
      defaultOptions={true}
      isDisabled={isDisabled}
      isMulti={true}
      loadOptions={loadOptions}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      placeholder="Unassigned"
      value={value}
      getOptionValue={(item: ApiRequestAssignee) => item.assignee.id}
      chakraStyles={commonStyles}
    />
  );
};
