import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  ModalFooter,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiProjectStatus } from "@operations-hero/lib-api-client";
import { FC, useMemo } from "react";
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { UsersFilter } from "../../../../components/filters/UserFilter";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { ProjectStatusFilter } from "../filters/StatusFilter";
import { HeaderProps } from "./Header";

type MobileHeaderProps = HeaderProps;

export const MobileHeader: FC<MobileHeaderProps> = ({
  filters,
  updateFilters,
  clearFilters,
  onNewProjectClick,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const getAssigneeValues = useMemo(() => {
    if (!filters.supervisor) return [];
    if (Array.isArray(filters.supervisor)) return filters.supervisor;
    return [filters.supervisor];
  }, [filters.supervisor]);

  return (
    <>
      <HStack justify="space-between" wrap="wrap">
        <QuickFilterButton
          activeText="Include Inactive"
          isActive={filters.includeInactive || false}
          onClick={() =>
            updateFilters({
              includeInactive: !filters.includeInactive,
            })
          }
        />
        <HStack wrap="wrap">
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
            onClick={onNewProjectClick}
            size="sm"
          >
            Add Project
          </Button>
          <Button
            leftIcon={<Icon as={RiFilterLine} />}
            size="sm"
            colorScheme="blue"
            onClick={onOpen}
            aria-label="Filters"
          >
            Filters
          </Button>
          <IconButton
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Clear"
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={clearFilters}
          />
        </HStack>
      </HStack>
      {isOpen && (
        <AccountModal
          isOpen={isOpen}
          onClose={onClose}
          title={<Text>Select filters</Text>}
          bodyProps={{ m: 0 }}
          content={
            <VStack align="stretch" w="full" gap={6}>
              <InputGroup flexGrow={1}>
                <InputLeftElement children={<SearchIcon color="gray.300" />} />
                <Input
                  type="text"
                  placeholder="Search"
                  value={filters.search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateFilters({ search: e.target.value });
                  }}
                />
              </InputGroup>
              <HStack>
                <UsersFilter
                  value={getAssigneeValues}
                  onChange={(value) => {
                    updateFilters({ supervisor: value.map((v) => v.id) });
                  }}
                  filterOptions={{
                    excludeRole: "Portal User",
                  }}
                />
                <ProjectStatusFilter
                  value={
                    filters.status ? (filters.status as ApiProjectStatus[]) : []
                  }
                  onChange={(value) => updateFilters({ status: value })}
                  status={[
                    ApiProjectStatus.approved,
                    ApiProjectStatus.completed,
                    ApiProjectStatus.pending,
                    ApiProjectStatus.started,
                  ]}
                />
              </HStack>
            </VStack>
          }
          footer={
            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          }
        />
      )}
    </>
  );
};
