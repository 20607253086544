import { Divider, Grid, GridItem, Icon } from "@chakra-ui/react";
import {
  ApiPurchaseTransaction,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import React, { useCallback } from "react";
import { IoMdAttach } from "react-icons/io";
import { IoTrashSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { OutlinedIconButton } from "../../../../components/custom-icons/OutlinedIconButton";
import { RootState } from "../../../../store";
import { TRANSACTION_DATE_FORMAT } from "../transactions-helper";
import { ExpensesDataProps } from "./ExpensesData";
import { TransactionEnum } from "./ExpensesTable";

export const MobileExpensesTable: React.FC<ExpensesDataProps> = ({
  onOpenEditModal,
  setWorkingPurchase,
  openRemoveModal,
  canEditAndDelete,
}) => {
  const { purchasesData } = useSelector(
    (state: RootState) => state.requestForm.transactions.purchases
  );

  const purchases = purchasesData as ApiPurchaseTransaction[];

  const handleClickButtons = useCallback(
    (purchase: UpdateApiPurchaseTransaction, action: TransactionEnum) => {
      setWorkingPurchase(purchase as ApiPurchaseTransaction);
      TransactionEnum.EDIT === action && onOpenEditModal();
      TransactionEnum.REMOVE === action && openRemoveModal && openRemoveModal();
    },
    [setWorkingPurchase, onOpenEditModal, openRemoveModal]
  );

  return (
    <Grid templateColumns={["repeat(2, 2fr)"]} gap={2}>
      {purchases.map((purchase) => (
        <React.Fragment key={purchase.id}>
          <GridItem colSpan={1} fontWeight="700">
            {purchase.vendor?.name}
          </GridItem>
          <GridItem colSpan={1} fontWeight="700" textAlign="right">
            {format(new Date(purchase.datePerformed), TRANSACTION_DATE_FORMAT)}
          </GridItem>
          <GridItem colSpan={1} fontWeight="500">
            {purchase.description}
          </GridItem>
          <GridItem colSpan={1} fontWeight="500" textAlign="right">
            ${purchase.total}
          </GridItem>
          {canEditAndDelete && canEditAndDelete(purchase) && (
            <GridItem
              colSpan={2}
              textAlign="right"
              pt={2}
              display="flex"
              flexDirection="row"
              justifyContent="right"
              gap="2"
            >
              {purchase.attachmentCount > 0 ? (
                <OutlinedIconButton
                  icon={<Icon as={IoMdAttach} boxSize={5} />}
                />
              ) : null}
              <OutlinedIconButton
                icon={<Icon as={IoTrashSharp} boxSize={5} />}
                onClick={() =>
                  handleClickButtons(purchase, TransactionEnum.REMOVE)
                }
              />
              <OutlinedIconButton
                icon={<Icon as={MdEdit} boxSize={5} />}
                onClick={() =>
                  handleClickButtons(purchase, TransactionEnum.EDIT)
                }
              />
            </GridItem>
          )}
          <GridItem colSpan={2} mt={4} mb={4}>
            <Divider />
          </GridItem>
        </React.Fragment>
      ))}
    </Grid>
  );
};
