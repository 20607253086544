import { IconButton, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import { RiPieChartLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";

type ReportButtonsProps = {};

export const ReportsButton: FC<ReportButtonsProps> = () => {
  return (
    <Tooltip label="Reports" hasArrow gutter={12}>
      <IconButton
        icon={<RiPieChartLine />}
        aria-label="Reports"
        size="sm"
        fontSize="18px"
        variant="outline"
        colorScheme="blue"
        as={RouterLink}
        to={"/reports"}
      />
    </Tooltip>
  );
};
