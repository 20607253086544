import {
  ApiRequest,
  ApiRequestStatus,
  ApiWorkflow,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { createContext, useContext } from "react";

export type ChangeType = "dnd" | "lazy" | "unset";
export type ColumnViewContextProps = {
  workingRequest: ApiRequest | null;
  workingPolicy: WorkflowPolicy | null;
  workingWorkflow: ApiWorkflow | null;
  engine: SchemaRulesEngine | null;
  initWorkingRequest: (request: ApiRequest) => Promise<void>;
  changeStatusValidation: ((status: ApiRequestStatus) => Promise<any>) | null;
  setSelectedRequest: (request: ApiRequest | null) => void;
  enabledStatuses: ApiRequestStatus[];
};

export const ColumnViewContext = createContext<ColumnViewContextProps | null>(
  null
);

export function useColumnViewContext() {
  const value = useContext(ColumnViewContext);
  if (!value) throw new Error("column view context provider");
  return value;
}
