import { Button, Flex, Icon, Image, useColorModeValue } from "@chakra-ui/react";
import React, { FC } from "react";
import { MdAdd, MdRefresh, MdRemove } from "react-icons/md";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import { Attachment } from "../../attachments/Attachments";

interface ZoomImageProps {
  attachment: Attachment;
}

export const ZoomableImage: FC<ZoomImageProps> = ({ attachment }) => {
  const controlsBgColor = useColorModeValue("gray.200", "whiteAlpha.300");

  const Controls = React.memo(() => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <Flex bgColor={controlsBgColor} px={4} borderRadius={6}>
        <Button variant="ghost" size="sm" onClick={() => zoomIn()}>
          <Icon as={MdAdd} />
        </Button>
        <Button variant="ghost" size="sm" onClick={() => zoomOut()}>
          <Icon as={MdRemove} />
        </Button>
        <Button variant="ghost" size="sm" onClick={() => resetTransform()}>
          <Icon as={MdRefresh} />
        </Button>
      </Flex>
    );
  });

  return (
    <Flex w="100%">
      <TransformWrapper initialScale={1} centerOnInit>
        <Flex w="100%" flexDir="column" gap={2}>
          <TransformComponent
            wrapperStyle={{ display: "flex", minWidth: "100%" }}
          >
            <Image
              minW="100%"
              alt={attachment.name}
              src={attachment.url || ""}
              height={[
                "calc(100vh - 360px)",
                "calc(100vh - 360px)",
                "calc(100vh - 220px)",
              ]}
            />
          </TransformComponent>

          <Flex w="100%" justifyContent="center">
            <Controls />
          </Flex>
        </Flex>
      </TransformWrapper>
    </Flex>
  );
};
