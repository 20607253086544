import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  ApiEventOccurrence,
  ApiSpaceSummary,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { EventOccurrenceUpdateProps } from "../../../../store/events/event-details.slice";
import { CreateEventOccurrencesForm } from "../CreateEventOccurrences";
import { DeleteOccurrences } from "./DeleteOccurrences";
import { EditOccurrences } from "./EditOccurrences";
import { EditSingleOccurrence } from "./EditSingleOccurrence";

export type OccurrenceModalType = "cancel" | "edit" | "save" | "create";

export interface OccurrenceModalProps {
  isOpen: boolean;
  eventId: string;
  venue?: ApiVenueSummary;
  spaces?: ApiSpaceSummary[];
  onClose: () => void;
  occurrencesIds?: string[];
  modalType: OccurrenceModalType;
  occurrence?: EventOccurrenceUpdateProps;
  occurrences?: ApiEventOccurrence[];
  setLocalOccurrences?: Dispatch<SetStateAction<EventOccurrenceUpdateProps[]>>;
  handleOnSaveOccurrences?: () => void;
}

export const OccurrenceModal: FC<OccurrenceModalProps> = ({
  isOpen,
  eventId,
  onClose,
  modalType,
  occurrence,
  occurrencesIds,
  occurrences,
  spaces,
  venue,
  setLocalOccurrences,
  handleOnSaveOccurrences,
}) => {
  const title = useMemo(() => {
    if (modalType === "edit") return "Update event occurrence";
    if (modalType === "save") return "Save event occurrences changes";
    if (modalType === "create") return "Create Event Occurrence(s)";

    if (occurrencesIds && occurrencesIds.length > 0) {
      return occurrencesIds.length === 1
        ? "Cancel this event occurence"
        : `Are you sure you want to cancel all ${occurrencesIds.length} event occurences?`;
    }
  }, [modalType, occurrencesIds]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent pb={4} maxW={modalType === "create" ? "2xl" : "xl"}>
        <ModalHeader pt={6} maxW="95%">
          <Heading fontSize="2xl">{title}</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalType === "cancel" && (
            <DeleteOccurrences
              onClose={onClose}
              eventId={eventId}
              occurrencesIds={occurrencesIds}
              occurrences={occurrences}
              setLocalOccurrences={setLocalOccurrences}
            />
          )}

          {modalType === "edit" && occurrence && (
            <EditSingleOccurrence
              occurrence={occurrence}
              eventId={eventId}
              onClose={onClose}
            />
          )}

          {modalType === "save" && (
            <EditOccurrences
              eventId={eventId}
              onClose={onClose}
              handleOnSaveOccurrences={handleOnSaveOccurrences}
            />
          )}

          {modalType === "create" && (
            <CreateEventOccurrencesForm
              eventId={eventId}
              onClose={onClose}
              venue={venue}
              spaces={spaces}
              firstOccurrence={occurrences ? occurrences[0] : undefined}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
