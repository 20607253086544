import {
  Badge,
  Box,
  Icon,
  Text,
  TextProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  ApiLocation,
  ApiLocationSummary,
  ApiLocationType,
} from "@operations-hero/lib-api-client";
import React, { useEffect, useMemo, useState } from "react";
import { IconBaseProps, IconType } from "react-icons";
import { BiArea, BiBuildings } from "react-icons/bi";
import { FaWarehouse } from "react-icons/fa";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IoAmericanFootballOutline } from "react-icons/io5";
import { RiDoorOpenLine, RiRoadMapLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useLocationUtils } from "../../utils/locationUtils";

export interface LocationBadgeProps {
  value: ApiLocationSummary | string | null;
  showParent?: boolean;
  isChild?: boolean;
  onlyParent?: boolean;
  onlyChildren?: boolean;
  showNumberChildren?: boolean;
}
//issue with Gr icon and dark mode
//https://stackoverflow.com/questions/67250054/cannot-change-gr-colors-in-react-icons
const GrElevator = ({ color, size, title, className }: IconBaseProps) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size ? size : "1rem"}
      width={size ? size : "1rem"}
      style={{ color }}
      className={className ? className : ""}
    >
      {title ? <title>{title}</title> : null}
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M1,2.991155 C1,1.89147046 1.88967395,1 2.991155,1 L21.008845,1 C22.1085295,1 23,1.88967395 23,2.991155 L23,21.008845 C23,22.1085295 22.1103261,23 21.008845,23 L2.991155,23 C1.89147046,23 1,22.1103261 1,21.008845 L1,2.991155 Z M16.5,8 L18,10 L15,10 L16.5,8 Z M16.5,16 L18,14 L15,14 L16.5,16 Z M5,13 L6.55613518,9.88772964 C6.80127495,9.3974501 7.44386482,9 8,9 L8,9 C8.55228475,9 9.19907951,9.39815903 9.44386482,9.88772964 L11,13 M6.5,18 L7.75,10 L8,10 L8.25,10 L9.5,18 M8,7 C8.55228475,7 9,6.55228475 9,6 C9,5.44771525 8.55228475,5 8,5 C7.44771525,5 7,5.44771525 7,6 C7,6.55228475 7.44771525,7 8,7 Z"
      ></path>
    </svg>
  );
};
const GrStorage = ({ color, size, title, className }: IconBaseProps) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size ? size : "1rem"}
      width={size ? size : "1rem"}
      style={{ color }}
      className={className ? className : ""}
    >
      {title ? <title>{title}</title> : null}
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M2,5.07692308 C2,5.07692308 3.66666667,2 12,2 C20.3333333,2 22,5.07692308 22,5.07692308 L22,18.9230769 C22,18.9230769 20.3333333,22 12,22 C3.66666667,22 2,18.9230769 2,18.9230769 L2,5.07692308 Z M2,13 C2,13 5.33333333,16 12,16 C18.6666667,16 22,13 22,13 M2,7 C2,7 5.33333333,10 12,10 C18.6666667,10 22,7 22,7"
      ></path>
    </svg>
  );
};

export const LocationTypeIconMap: Record<ApiLocationType, IconType> = {
  region: RiRoadMapLine,
  field: IoAmericanFootballOutline,
  building: BiBuildings,
  area: BiArea,
  //"outdoor-area": GiParkBench,
  room: RiDoorOpenLine,
  floor: GrElevator,
  storage: GrStorage,
  warehouse: FaWarehouse,
};

export const LocationBadge = ({
  value,
  showParent = false,
  isChild = false,
  onlyParent,
  onlyChildren,
  showNumberChildren,
}: LocationBadgeProps) => {
  const locationMap = useSelector(
    (state: RootState) => state.localCache.locationMap
  );

  const [currentLocation, setCurrentLocation] = useState<ApiLocationSummary>();
  const [parent, setParent] = useState<ApiLocationSummary>();
  const [children, setChildren] = useState<ApiLocationSummary[]>();

  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const { getChildrenId } = useLocationUtils();
  const ancestors = useMemo(() => {
    if (!currentLocation) return null;

    const parents = [];
    let locationChildren = [];
    let location = currentLocation;

    while (location.parent !== null) {
      const parent = locationMap[location.parent];
      parents.unshift(parent);
      location = parent;
      locationChildren.unshift(location);
      setParent(location);
    }
    locationChildren.shift();
    locationChildren.push(currentLocation);
    setChildren(locationChildren);

    return parents;
  }, [currentLocation, locationMap]);

  const locationChilds = useMemo(() => {
    if (!currentLocation || !showNumberChildren) {
      return 0;
    }
    return getChildrenId([currentLocation as ApiLocation]).length - 1;
  }, [getChildrenId, currentLocation, showNumberChildren]);
  useEffect(() => {
    if (typeof value === "string") {
      setCurrentLocation(locationMap[value]);
    } else if (value === null) {
      setCurrentLocation(undefined);
    } else {
      setCurrentLocation(value);
    }
  }, [locationMap, value]);

  return currentLocation != null ? (
    <>
      <Box display="inline-block">
        {ancestors &&
          !onlyParent &&
          !onlyChildren &&
          ancestors.map((location) => (
            <React.Fragment key={location.id}>
              <LocationNode location={location} pr={2} />
              <Icon as={HiArrowNarrowRight}></Icon>
            </React.Fragment>
          ))}
        {!onlyChildren && !onlyParent && (
          <>
            <LocationNode
              location={currentLocation}
              pl={ancestors && ancestors.length > 0 ? 2 : 0}
            />
            {locationChilds > 0 && (
              <Badge
                ml={2}
                variant="solid"
                fontSize={isTablet ? "xs" : "sm"}
                colorScheme="gray"
              >
                {`+${locationChilds} locations`}
              </Badge>
            )}
          </>
        )}
        {onlyParent
          ? parent && (
              <Text fontSize="sm" fontWeight="bold">
                {" "}
                <LocationNode location={parent} />{" "}
              </Text>
            )
          : null}

        {onlyChildren &&
          children &&
          (!currentLocation.parent ? (
            <Text fontSize="sm" fontWeight="bold">
              <LocationNode location={currentLocation} />
            </Text>
          ) : !isTablet ? (
            <Text fontSize="sm" colorScheme="grey" isTruncated>
              {children.map((l) => l.name).join(" > ")}
            </Text>
          ) : (
            children.map((location) => (
              <Box pr={2} key={location.id} isTruncated>
                <Box alignItems="center" display="flex" isTruncated>
                  <Text fontSize="14px" mr={1} isTruncated maxWidth="200px">
                    {location.name}
                  </Text>
                  <Text>{" > "}</Text>
                </Box>
              </Box>
            ))
          ))}
      </Box>
    </>
  ) : (
    <Text>Location not set</Text>
  );
};

const LocationNode = ({
  location,
  ...props
}: { location: ApiLocationSummary } & TextProps) => {
  return (
    <Text as="span" display="inline-block" {...props}>
      <Icon as={LocationTypeIconMap[location.type]} mt={-1} mr={2} />
      <Text as="span" display="inline">
        {location.name}
      </Text>
    </Text>
  );
};
