import {
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiInventoryItem,
  ApiInventoryType,
  ApiLocation,
  ApiRequestsQueryFilter,
  ApiRequestStatus,
} from "@operations-hero/lib-api-client";
import {
  FC,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountModal } from "../../pages/account-settings/account-modal/AccountModal";
import { AssetCard } from "../../pages/account-settings/asset-list/AssetCard";
import { CheckoutForm } from "../../pages/inventory/inventory-items/inventory-checkin-checkout/CheckoutForm";
import { ChangeQuantityForm } from "../../pages/inventory/inventory-items/quantity/ChangeQuantityForm";
import ItemCart from "../../pages/inventory/qr-lookup-modals/ItemCart";
import QrLookup from "../../pages/inventory/qr-lookup-modals/QrLookup";
import { AssetDetailModal } from "../../pages/request-form/assets/AssetDetailModal";
import { RootState } from "../../store";
import {
  setIsFromQRModal,
  setIsOpenChangeQuantityModal,
  setIsOpenCheckoutModal,
} from "../../store/inventory/inventory-item-list.slice";
import {
  addAsset,
  setLocationId,
  setNewRequestDialogIsOpen,
  setReportingCategoryId,
} from "../../store/new-request-form.slice";
import { setQRLookupModalIsOpen } from "../../store/qr-lookup.slice";
import {
  RequestListFilterState,
  updateRequestFilters,
} from "../../store/request-list.slice";
import { filtersInitialState } from "../../store/requests/defaults";
import { useAuthentication } from "../auth/AuthProvider";
import { LocationTwoLine } from "../badges/LocationTwoLine";
import { FallbackSpinner } from "../code-splitting/FallbackSpinner";
import { OnResultFunction } from "../qr-reader/qrReaderTypes";

const QrReader = lazy(() => import("../qr-reader/QrReader"));
const QrViewFinder = lazy(() => import("../qr-quick-scan/QrViewFinder"));

export const QRCodeLookup: FC = () => {
  const { apiClient, currentAccount, isProductAdmin } = useAuthentication();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isOpen = useSelector(
    (state: RootState) => state.qrLookup.qrLookupModalIsOpen
  );

  const { locationMap, isRequesterOnly } = useSelector(
    (state: RootState) => state.localCache
  );

  const {
    isOpenChangeQuantityModal,
    isOpenCheckoutModal,
    workingItem,
    isFromQR,
  } = useSelector((state: RootState) => state.inventoryItemsListSlice);

  const [data, setData] = useState("");
  const [typeName, setTypeName] = useState("QRCODE");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [assets, setAssets] = useState<ApiAsset[]>([]);
  const [locations, setLocations] = useState<ApiLocation[]>([]);
  const [items, setItems] = useState<ApiInventoryItem[]>([]);

  const [selectedAsset, setSelectedAsset] = useState<ApiAsset>();
  const [selectedLocation, setSelectedLocation] = useState<ApiLocation>();
  const [selectedItem, setSelectedItem] = useState<ApiInventoryItem>();
  const [requestCount, setRequestCount] = useState(0);
  // const [scheduledRequestCount, setScheduledRequestCount] = useState(0);

  const {
    isOpen: assetDetailIsOpen,
    onClose: assetDetailsOnClose,
    onOpen: assetDetailOnOpen,
  } = useDisclosure();
  const [assetToShow, setAssetToShow] = useState<ApiAsset>();
  const [itemtoShow, setItemToShow] = useState<ApiInventoryItem>();

  const {
    isOpen: isOpenQrLookup,
    onOpen: onOpenQrLookup,
    onClose: onCloseQrLookup,
  } = useDisclosure();

  const isSelectedItem = useMemo(
    () => !!selectedAsset || !!selectedLocation || false,
    [selectedAsset, selectedLocation]
  );

  const videoContraints = useMemo(
    () => ({ facingMode: { ideal: "environment" } }),
    []
  );

  const isNoResults = useMemo(
    () =>
      data &&
      !isLoading &&
      !isSelectedItem &&
      !assets.length &&
      !locations.length &&
      !items.length,
    [data, isLoading, isSelectedItem, assets, locations, items.length]
  );

  const clearState = useCallback(() => {
    setData("");
    setTypeName("QRCODE");
    setIsLoading(false);
    setAssets([]);
    setLocations([]);
    setSelectedAsset(undefined);
    setSelectedLocation(undefined);
    setSelectedItem(undefined);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(setQRLookupModalIsOpen(false));
  }, [dispatch]);

  const selectLocationFactory = useCallback(
    (item: ApiLocation) => () => {
      setSelectedLocation(item);
    },
    []
  );

  const selectAssetFactory = useCallback(
    (item: ApiAsset) => () => {
      setSelectedAsset(item);
    },
    []
  );

  const selectItemFactory = useCallback(
    (item: ApiInventoryItem) => () => {
      setSelectedItem(item);
      setItemToShow(item);
      onOpenQrLookup();
      handleClose();
    },
    [onOpenQrLookup, handleClose]
  );

  const openRequest = useCallback(() => {
    handleClose();

    const location = selectedLocation || selectedAsset?.location;

    if (location) {
      localStorage.setItem(
        `${currentAccount.id}-request-location`,
        location.id
      );
    }

    if (selectedAsset) {
      dispatch(addAsset(selectedAsset));
      if (selectedAsset.reportingCategory)
        dispatch(setReportingCategoryId(selectedAsset.reportingCategory.id));
      if (selectedAsset.location)
        dispatch(setLocationId(selectedAsset.location.id));
    }

    if (selectedItem) {
      setItemToShow(selectedItem);
      onOpenQrLookup();
    }

    dispatch(setNewRequestDialogIsOpen(true));
  }, [
    currentAccount,
    selectedLocation,
    selectedAsset,
    dispatch,
    handleClose,
    onOpenQrLookup,
    selectedItem,
  ]);

  const handleResult = useCallback<OnResultFunction>((result, error) => {
    if (!!result) {
      setData(result.decode());
      setTypeName((result.typeName || "").replace("ZBAR_", ""));
    }

    if (!!error) {
      setError(error.message);
    }
  }, []);

  const handleAssetModalClose = useCallback(() => {
    setAssetToShow(undefined);
    assetDetailsOnClose();
  }, [assetDetailsOnClose]);

  const handleViewAsset = useCallback(() => {
    if (!selectedAsset) return;
    setAssetToShow(selectedAsset);
    handleClose();
    assetDetailOnOpen();
  }, [assetDetailOnOpen, handleClose, selectedAsset]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setIsLoading(true);
    Promise.all([
      apiClient
        .findAssets(currentAccount.id, { externalIds: [data] })
        .then((r) => r.data),
      Promise.resolve(
        Object.values(locationMap).filter(
          (l) => l.externalId === data && l.active
        )
      ),
      apiClient
        .findInventoryItem(currentAccount.id, { search: data })
        .then((response) => response.data),
    ])
      .then(([foundAssets, foundLocations, foundItems]) => {
        setAssets(foundAssets || []);
        setLocations(foundLocations || []);
        setItems(foundItems || []);

        if (
          foundAssets.length === 1 &&
          foundLocations.length === 0 &&
          foundItems.length === 0
        ) {
          setSelectedAsset(foundAssets[0]);
        }
        if (
          foundLocations.length === 1 &&
          foundAssets.length === 0 &&
          foundItems.length === 0
        ) {
          setSelectedLocation(foundLocations[0]);
        }
        if (
          foundItems.length === 1 &&
          foundAssets.length === 0 &&
          foundLocations.length === 0
        ) {
          setSelectedItem(foundItems[0]);
          setItemToShow(foundItems[0]);
          handleClose();
          onOpenQrLookup();
        }
      })
      .finally(() => setIsLoading(false));
    // get locations from localCache
  }, [
    data,
    apiClient,
    locationMap,
    currentAccount,
    onOpenQrLookup,
    handleClose,
  ]);

  const filter = useMemo(() => {
    const baseFilter: RequestListFilterState = {
      ...filtersInitialState,
      statuses: [
        ApiRequestStatus.new,
        ApiRequestStatus.approved,
        ApiRequestStatus.queued,
        ApiRequestStatus.started,
      ],
      quickFilter: ApiRequestsQueryFilter.ALL,
      moreFilters: ["assets"],
    };
    if (selectedAsset) {
      return btoa(
        JSON.stringify({
          ...baseFilter,
          assets: [selectedAsset.id],
        })
      );
    }
    if (selectedLocation) {
      return btoa(
        JSON.stringify({
          ...baseFilter,
          locations: [selectedLocation.id],
        })
      );
    }
    return "";
  }, [selectedAsset, selectedLocation]);

  const handleRedirection = useCallback(() => {
    navigate(`/requests?filter=${filter}`);

    // if we're already in the requests page we'll just update the current filters
    if (location.pathname.includes("requests")) {
      let decodedFilter = null;
      try {
        decodedFilter = JSON.parse(atob(filter));
      } catch (e) {
        decodedFilter = null;
      }

      if (decodedFilter) {
        dispatch(updateRequestFilters(decodedFilter));
      }
    }
    handleClose();
  }, [filter, handleClose, navigate, location.pathname, dispatch]);

  const handleOnCloseQuantityModal = useCallback(() => {
    dispatch(setIsOpenChangeQuantityModal(false));
    dispatch(setIsFromQRModal(false));
  }, [dispatch]);

  const handleOnCloseCheckoutModal = useCallback(() => {
    dispatch(setIsOpenCheckoutModal(false));
    dispatch(setIsFromQRModal(false));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedAsset && !selectedLocation) {
      // update counts to 0
      return;
    }

    if (isRequesterOnly) {
      return;
    }

    setIsLoading(true);

    Promise.all([
      apiClient.findRequests(currentAccount.id, {
        pageSize: 1,
        status: [
          ApiRequestStatus.new,
          ApiRequestStatus.approved,
          ApiRequestStatus.queued,
          ApiRequestStatus.started,
        ],
        location: selectedLocation?.id,
        asset: selectedAsset?.id,
      }),
      // apiClient.findScheduledRequests(currentAccount.id, {
      //   pageSize: 1,
      //   location: selectedLocation?.id,
      //   asset: selectedAsset?.id,
      // }),
    ])
      .then(([r /*, s*/]) => {
        setRequestCount(r.total);
        // setScheduledRequestCount(s.total);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    selectedAsset,
    selectedLocation,
    apiClient,
    currentAccount,
    isRequesterOnly,
  ]);

  // this cleans up the state when the dialog is closed
  useEffect(() => {
    setError("");
    clearState();
  }, [isOpen, clearState]);

  return (
    <>
      {assetDetailIsOpen && assetToShow && (
        <AssetDetailModal asset={assetToShow} onClose={handleAssetModalClose} />
      )}
      {isOpenQrLookup && itemtoShow && (
        <AccountModal
          isOpen={isOpenQrLookup}
          onClose={onCloseQrLookup}
          title="QR Lookup"
          content={
            <QrLookup item={itemtoShow} onCloseQRLookup={onCloseQrLookup} />
          }
        />
      )}
      {isOpenChangeQuantityModal && workingItem && isFromQR && (
        <AccountModal
          content={<ChangeQuantityForm onClose={handleOnCloseQuantityModal} />}
          title={`Change Quantity: ${workingItem.name} ${workingItem.identifiers.externalId}`}
          titleProps={{ fontSize: "md" }}
          onClose={handleOnCloseQuantityModal}
          isOpen={isOpenChangeQuantityModal}
        />
      )}
      {isOpenCheckoutModal && workingItem && isFromQR && (
        <AccountModal
          content={<CheckoutForm onClose={handleOnCloseCheckoutModal} />}
          title={
            workingItem.type === ApiInventoryType.bulkCheckout
              ? "Bulk Check Out Item"
              : "Check Out Item"
          }
          titleProps={{ fontSize: "lg" }}
          onClose={handleOnCloseCheckoutModal}
          isOpen={isOpenCheckoutModal}
        />
      )}
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          closeOnEsc={true}
          closeOnOverlayClick={true}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent pb={6}>
            <ModalHeader>Lookup QR code or Barcode</ModalHeader>
            <ModalCloseButton />
            <Suspense fallback={<FallbackSpinner />}>
              <ModalBody>
                {error && <Alert>{error}</Alert>}
                {!data && (
                  <QrReader
                    ViewFinder={QrViewFinder}
                    onResult={handleResult}
                    constraints={videoContraints}
                    scanDelay={250}
                    videoId={"qr-lookup-modal-video"}
                  />
                )}

                {data && (
                  <Stack spacing={8} divider={<Divider />}>
                    <Center>
                      <Text isTruncated>
                        {typeName ? `Type ${typeName}: ` : ""}
                        <Text as="span" fontWeight="bolder">
                          {data}
                        </Text>
                      </Text>
                      <Spacer />
                      <Button size="sm" onClick={clearState}>
                        Scan again
                      </Button>
                    </Center>
                    {isNoResults && (
                      <Center py={8}>
                        <Text>No Assets, Locations, or Items found</Text>
                      </Center>
                    )}

                    {!isSelectedItem && locations && locations.length > 0 && (
                      <Stack spacing={4}>
                        <Heading size={"sm"}>Locations found</Heading>
                        {locations.map((l) => (
                          <Center key={`qr-location::${l.id}`}>
                            <LocationTwoLine value={l} />
                            <Spacer />
                            <Box ml={4}>
                              <Button
                                colorScheme="blue"
                                size="sm"
                                onClick={selectLocationFactory(l)}
                              >
                                Select Location
                              </Button>
                            </Box>
                          </Center>
                        ))}
                      </Stack>
                    )}
                    {!isSelectedItem && assets && assets.length > 0 && (
                      <Stack spacing={4}>
                        <Heading size={"sm"}>Assets found</Heading>
                        {assets.map((a) => (
                          <AssetCard
                            asset={a}
                            key={`qr-asset::${a.id}`}
                            condensed={true}
                          >
                            <Button
                              colorScheme="blue"
                              size="sm"
                              onClick={selectAssetFactory(a)}
                            >
                              Select Asset
                            </Button>
                          </AssetCard>
                        ))}
                      </Stack>
                    )}
                    {!isSelectedItem && items && items.length > 0 && (
                      <Stack spacing={4}>
                        <Heading size={"sm"}>Items found</Heading>
                        {items.map((i) => (
                          <ItemCart item={i} key={`itemCart::${i.id}`}>
                            <Button
                              colorScheme="blue"
                              size="sm"
                              onClick={selectItemFactory(i)}
                            >
                              Select Item
                            </Button>
                          </ItemCart>
                        ))}
                      </Stack>
                    )}

                    {isSelectedItem && (
                      <Box>
                        {selectedAsset && (
                          <AssetCard asset={selectedAsset} condensed={true}>
                            <></>
                          </AssetCard>
                        )}

                        {selectedLocation && (
                          <LocationTwoLine value={selectedLocation} />
                        )}
                      </Box>
                    )}
                    {isSelectedItem && !isLoading && (
                      <Grid templateColumns={["repeat(2, 1fr)"]} gap={4}>
                        <GridItem colSpan={2}>
                          <Button
                            w="100%"
                            height={16}
                            p={2}
                            colorScheme={"blue"}
                            onClick={openRequest}
                          >
                            Create a Request
                          </Button>
                        </GridItem>
                        {isProductAdmin && (
                          <GridItem colSpan={2}>
                            <Button
                              as={Link}
                              w="100%"
                              height={16}
                              p={2}
                              onClick={handleClose}
                              to={
                                selectedAsset
                                  ? `/account/assets/${selectedAsset.id}`
                                  : `/account/locations/${selectedLocation!.id}` // has to be either asset or location
                              }
                            >
                              Edit {selectedAsset ? "Asset" : "Location"}
                            </Button>
                          </GridItem>
                        )}
                        {!isProductAdmin &&
                          !isRequesterOnly &&
                          selectedAsset && (
                            <GridItem colSpan={2}>
                              <Button
                                w="100%"
                                height={16}
                                p={2}
                                onClick={handleViewAsset}
                              >
                                View Asset
                              </Button>
                            </GridItem>
                          )}
                        {!isRequesterOnly && (
                          <GridItem colSpan={2}>
                            <Button
                              textAlign="center"
                              w="100%"
                              height={16}
                              p={2}
                              onClick={handleRedirection}
                            >
                              View {requestCount}
                              <br /> Open Requests
                            </Button>
                          </GridItem>
                        )}

                        {/* TODO: deeplink scheduled requests with filter */}
                        {/* {isProductAdmin && (
                    <GridItem colSpan={1}>
                      <Button w="100%" height={16} p={2}>
                        {scheduledRequestCount} Schedules
                      </Button>
                    </GridItem>
                  )} */}
                      </Grid>
                    )}
                    {isLoading && (
                      <Center py={8}>
                        <Spinner size="xl" />
                      </Center>
                    )}
                  </Stack>
                )}
              </ModalBody>
            </Suspense>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
