import {
  Box,
  Checkbox,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiRate,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { FC } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { GetItemRateType } from "../edit-form-components/EventServiceItem";
import {
  RentableEquipmentRender,
  SpaceBasicData,
} from "./EventEquipmentSection";
import { ItemPrice } from "./ItemPrice";

interface RentableEquipmentItemProps {
  eventHoursDuration: number;
  equipment: RentableEquipmentRender;
  handleOnChangeEquipment: (equipment: RentableEquipmentRender) => void;
  handleOnChangeQuantity: (
    value: number,
    equipment: RentableEquipmentRender
  ) => void;
  getQuantity: (equipment: RentableEquipmentRender) => {
    quantity: number | undefined;
    error: boolean | undefined;
    errorMessage: string | undefined;
  };
  getAllRatesByItemId: (itemId: string) => ApiRate[] | undefined;
  getItemRate: GetItemRateType;
  occurrences: CreateApiEventOccurrence[];
  space?: SpaceBasicData;
}

export const RentableEquipmentItem: FC<RentableEquipmentItemProps> = ({
  equipment,
  getQuantity,
  eventHoursDuration,
  handleOnChangeQuantity,
  handleOnChangeEquipment,
  getAllRatesByItemId,
  getItemRate,
  occurrences,
  space,
}) => {
  const errorColor = useColorModeValue("red.500", "red.300");
  const tooltipColor = useColorModeValue("gray.500", "white");

  const { quantity, error, errorMessage } = getQuantity(equipment);

  const rate = getItemRate(
    space ? space.rentableEquipmentId : equipment.id,
    Boolean(quantity),
    occurrences,
    eventHoursDuration,
    quantity
  );

  const ratesByItem = getAllRatesByItemId(
    space ? space.rentableEquipmentId : equipment.id
  );

  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  return (
    <Flex flexDirection="column">
      <Flex
        justifyContent="space-between"
        flexDir={["column", "column", "row"]}
      >
        <Checkbox
          fontSize="md"
          fontWeight="bold"
          onChange={() => handleOnChangeEquipment(equipment)}
          isChecked={Boolean(quantity)}
        >
          <Flex gap={1} alignItems="center">
            {equipment.name}
            {rate && enableInvoicesForEvents && (
              <ItemPrice ratesByItem={ratesByItem} />
            )}
            {space && (
              <Tooltip label={space.name} fontSize="md">
                <Box color={tooltipColor}>
                  <IoMdInformationCircle />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Checkbox>

        <Flex gap={4} alignItems="center">
          <Text as="span" fontSize="15px" w={["max-content"]}>
            How many do you need?
          </Text>
          <Flex
            gap={[2, 2, 4]}
            flex={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <NumberInput
              size="md"
              maxW="80px"
              value={quantity}
              onChange={(_, value) => handleOnChangeQuantity(value, equipment)}
              isDisabled={quantity !== undefined ? false : true}
            >
              <NumberInputField borderRadius={6} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {rate && enableInvoicesForEvents && (
              <Text as="span" w="70px" textAlign="right">
                {formatCurrency(
                  rate
                    ? rate.reduce((sum, rate) => {
                        return sum + rate.total;
                      }, 0)
                    : 0
                )}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      {error && (
        <Text mt={1} color={errorColor} fontSize="sm" textAlign="right">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};
