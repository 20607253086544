import { Icon } from "@chakra-ui/react";
import { ApiLoginMethod } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import { MdPassword } from "react-icons/md";
interface MethodIconProps {
  methodLogin?: ApiLoginMethod;
}
export const MethodIcon: FC<MethodIconProps> = ({ methodLogin }) => {
  if (methodLogin === ApiLoginMethod.microsoft) {
    return <Icon as={FaMicrosoft} boxSize={5} />;
  }
  if (methodLogin === ApiLoginMethod.google) {
    return <Icon as={FaGoogle} boxSize={5} />;
  }
  if (methodLogin === ApiLoginMethod.password) {
    return <Icon as={MdPassword} boxSize={5} />;
  }
  return null;
};
