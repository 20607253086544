import { Box, Textarea, useColorModeValue } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

export interface AutoGrowTextAreaProps {
  value?: string | null;
  onChange?: (value: string) => void;
  autoFocusTextarea?: boolean;
  isDisabled?: boolean;
  name?: string;
  placeHolder?: string;
}

export const AutoGrowTextarea = ({
  onChange,
  value,
  autoFocusTextarea,
  isDisabled,
  name,
  placeHolder,
}: AutoGrowTextAreaProps) => {
  const [text, setText] = useState("");

  const bgColor = useColorModeValue("white", "");
  const handleOnInput = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      // Not sure why Typescript no likey
      // see text area part of https://reactjs.org/docs/forms.html
      //@ts-ignore
      setText(event.target.value);
    },
    []
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      if (onChange) onChange(event.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    setText(value || "");
  }, [value]);

  return (
    <Box display="grid" maxW="100%">
      <Box
        lineHeight="1.375"
        p={2}
        color={"red"}
        border="1px"
        style={{
          whiteSpace: "pre-wrap",
          visibility: "hidden",
          gridArea: "1 / 1 / 2 / 2",
          overflowWrap: "anywhere",
        }}
      >
        {/* 
          space is required for when the users presses enter at the end of the textarea
          without it the div does not grow until there is a character after newline
         */}
        {text}{" "}
      </Box>
      <Textarea
        p={2}
        height="100%"
        value={text}
        backgroundColor={bgColor}
        onFocus={(e) => {
          e.target.setSelectionRange(text.length + 1, text.length + 1);
        }}
        style={{
          gridArea: "1 / 1 / 2 / 2",
          resize: "none",
          overflow: "hidden",
          overflowWrap: "anywhere",
          whiteSpace: "pre-wrap",
        }}
        onInput={handleOnInput}
        onBlur={handleOnBlur}
        autoFocus={autoFocusTextarea}
        isDisabled={isDisabled}
        name={name}
        placeholder={placeHolder}
      ></Textarea>
    </Box>
  );
};
