import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import {
  ApiLaborTransaction,
  ApiUserSummary,
  ApiWorkflowReference,
  LaborType,
} from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import HoursSpinner from "../../../components/inputs/HoursSpinner";
import { StyledDatePicker } from "../../../components/inputs/StyledDatePicker";
import LaborTypeAutocomplete from "../../../components/selects/LaborTypeAutocomplete";
import { SingleAssigneeAutocomplete } from "../../../components/selects/SingleAssigneeAutocomplete";

export enum HoursOperation {
  ADDITION = "ADDITION",
  SUBTRACTION = "SUBTRACTION",
  REPLACE = "REPLACE",
}

const MIN_HOUR = 0;
const MAX_HOUR = 23.99;
const HOUR_STEP = 0.25;

export interface LaborFormProps {
  transaction: ApiLaborTransaction;
  onChange: (value: ApiLaborTransaction) => void;
  workflow: ApiWorkflowReference | null;
}

type Laborer = ApiUserSummary & {
  isGroup?: boolean;
  groupMembers?: ApiUserSummary[];
};

export type TransactionLaborerWithGroups = ApiLaborTransaction & {
  laborer: Laborer;
};

export const LaborForm = ({
  transaction,
  onChange,
  workflow,
}: LaborFormProps) => {
  const { apiClient, currentAccount } = useAuthentication();

  const handleLaborTypeOnChange = useCallback(
    (value: LaborType) => {
      onChange({ ...transaction, laborType: value });
    },
    [transaction, onChange]
  );

  const handleDateChanged = useCallback(
    (date: Date | string | null) => {
      const datePerformed: Date =
        typeof date === "string" || date === null ? new Date(date || "") : date;
      datePerformed.setHours(0);
      datePerformed.setMinutes(0);
      datePerformed.setSeconds(0);
      onChange({
        ...transaction,
        datePerformed: datePerformed
          ? new Date(datePerformed).toISOString()
          : "",
      });
    },
    [transaction, onChange]
  );

  const handleHoursChange = useCallback(
    (value: number) => {
      onChange({ ...transaction, hours: value });
    },
    [transaction, onChange]
  );

  const handleUserOnChange = useCallback(
    (value: Laborer | null) => {
      if (value?.isGroup && value.groupMembers?.length === 0) {
        apiClient
          .findUserGroupMembers(currentAccount.id, value.id)
          .then((response) => {
            const newValue = {
              ...value,
              groupMembers: response.data.map((member) => member.user),
            };
            onChange({ ...transaction, laborer: newValue });
          });
        return;
      }
      onChange({ ...transaction, laborer: value as ApiUserSummary });
    },
    [transaction, onChange, apiClient, currentAccount.id]
  );

  useEffect(() => {
    if (transaction.hours > MAX_HOUR) {
      onChange({ ...transaction, hours: MAX_HOUR });
    }
    if (transaction.hours < MIN_HOUR) {
      onChange({ ...transaction, hours: MIN_HOUR });
    }
  }, [transaction, onChange]);

  return (
    <Box display="flex" flexDir="column" gap={2}>
      <FormControl id="laborer">
        <FormLabel htmlFor="laborer" mb="0">
          Laborer
        </FormLabel>
        <SingleAssigneeAutocomplete
          value={transaction.laborer}
          onChange={handleUserOnChange}
          workflow={workflow}
          displayLeadGroups={true}
        />
      </FormControl>
      <FormControl id="labor-type">
        <FormLabel htmlFor="labor-type" mb="0" mt="4">
          Labor Type
        </FormLabel>
        <LaborTypeAutocomplete
          onChange={handleLaborTypeOnChange}
          value={transaction.laborType}
        />
      </FormControl>

      <FormControl mt={4} id="datePerformed">
        <FormLabel htmlFor="datePerformed" mb="0">
          Date Performed
        </FormLabel>
        <StyledDatePicker
          value={transaction.datePerformed}
          onChange={handleDateChanged}
          name="labor-date-performed"
        />
      </FormControl>
      <FormControl id="hours" mt={7}>
        <FormLabel htmlFor="hours" mb="0">
          Time spent
        </FormLabel>
        <HoursSpinner
          value={transaction.hours}
          onChange={handleHoursChange}
          includeStepButtons
          includeQuickButtons
          min={MIN_HOUR}
          max={MAX_HOUR}
          step={HOUR_STEP}
        />
      </FormControl>
    </Box>
  );
};
