import {
  Button,
  Flex,
  GridItem,
  HStack,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiLocation,
  ApiLocationType,
  ExportType,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoDownloadOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { QuickFilterButton } from "../../../components/buttons/QuickFilterButton";
import { ExportModal } from "../../../components/export/ExportModal";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { RootState, useThunkDispatch } from "../../../store";
import {
  expandAllLocationMap,
  loadLocationsExpandedMap,
  loadLocationsProducts,
} from "../../../store/locations.slice";
import { AccountPageHeader } from "../account-components/AccountHeader";
import { LocationTree, LocationTreeList } from "./LocationTree";
import { LocationTypeFilter } from "./LocationTypeFilter";

type LocationMap = {
  [key: string]: ApiLocation;
};

interface LocationState {
  autoFocus: boolean;
}

export enum Products {
  HeroHQ = "HeroHQ",
  Events = "Events",
  InventoryHQ = "InventoryHQ",
}

const buildTree = (
  nodeMap: LocationMap,
  searchedNodes: LocationMap = {},
): LocationTree[] => {
  const allNodes = Object.values(nodeMap);
  const parentNodes = allNodes.filter((l) => l.parent == null);

  const calculateNode = (loc: ApiLocation): LocationTree => {
    return {
      node: loc,
      children: allNodes
        .filter((n) => n.parent === loc.id)
        .map((n) => calculateNode(n)),
      matched: searchedNodes[loc.id] !== undefined,
    };
  };

  return parentNodes.map((x) => calculateNode(x));
};

export default function LocationList() {
  const navigate = useNavigate();
  const locationHook = useLocation();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
  });

  const locationState = locationHook.state as LocationState | undefined;

  const locationMapAll = useSelector(
    (state: RootState) => state.localCache.locationMap,
  );
  const { expandedMap, locationsProducts } = useSelector(
    (state: RootState) => state.locations,
  );
  const { subscriptions } = useSelector((state: RootState) => state.localCache);
  const [tree, setTree] = useState<LocationTree[]>([]);
  const [searchLocMap, setSearchLocMap] = useState<LocationMap>({});
  const [includeInactive, setIncludeInactive] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const [typeFilter, setTypeFilter] = useState<ApiLocationType>();
  const asteriskColor = useColorModeValue("#E53E3E", "#FC8181");

  const locationMap = useMemo(() => {
    if (includeInactive) {
      if (showHidden) {
        let parentHidden: Record<string, ApiLocation> = {};
        const hidden = Object.values(locationMapAll)
          .filter((loc) => {
            if (locationsProducts[loc.id].length > 0) {
              const tree = loc.treePath.split(".");
              tree.pop();
              tree.forEach((t) => (parentHidden[t] = locationMapAll[t]));
              return true;
            }
            return false;
          })
          .reduce(
            (acc, loc) => {
              acc[loc.id] = loc;
              return acc;
            },
            {} as Record<string, ApiLocation>,
          );
        return Object.assign(parentHidden, hidden);
      }

      return locationMapAll;
    }
    if (showHidden) {
      let parentHidden: Record<string, ApiLocation> = {};
      const hidden = Object.values(locationMapAll)
        .filter((loc) => {
          if (loc.active && locationsProducts[loc.id].length > 0) {
            const tree = loc.treePath.split(".");
            tree.pop();
            tree.forEach((t) => (parentHidden[t] = locationMapAll[t]));
            return true;
          }
          return false;
        })
        .reduce(
          (acc, loc) => {
            acc[loc.id] = loc;
            return acc;
          },
          {} as Record<string, ApiLocation>,
        );
      return Object.assign(parentHidden, hidden);
    }
    return Object.values(locationMapAll)
      .filter((loc) => loc.active)
      .reduce(
        (acc, loc) => {
          acc[loc.id] = loc;
          return acc;
        },
        {} as Record<string, ApiLocation>,
      );
  }, [includeInactive, showHidden, locationMapAll, locationsProducts]);

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(locationMap), ["name", "address"], {
        sort: true,
      }),
    [locationMap],
  );

  const enabledProducts = useMemo(() => {
    return subscriptions.filter((s) =>
      Object.values(Products).includes(s.product.name as Products),
    );
  }, [subscriptions]);

  const handleNewClick = useCallback(() => {
    navigate("/account/locations/new");
  }, [navigate]);

  const getAllLocations = useCallback(
    (foundLocations: ApiLocation[]) => {
      const allLocations = foundLocations.reduce<{
        [key: string]: ApiLocation;
      }>((map, foundLocation) => {
        map[foundLocation.id] = foundLocation;

        let child = { ...foundLocation };
        // gather up all the parents to build the tree
        while (child.parent != null && child.parent !== child.id) {
          const parent = locationMap[child.parent];
          // this shouldn't happen unless the data is busted
          if (!parent) {
            break;
          }
          map[parent.id] = parent;
          child = parent;
        }

        return map;
      }, {});
      return allLocations;
    },
    [locationMap],
  );

  const handleSearch = useCallback(
    (value: string) => {
      if (value == null || value.length === 0) {
        setTree(buildTree(locationMap));
        setSearchLocMap(locationMap);
        return;
      }
      const foundLocations = fuzzySearch.search(value);

      const allLocations = getAllLocations(foundLocations);

      const searchedMap = foundLocations.reduce<LocationMap>((map, l) => {
        map[l.id] = l;
        return map;
      }, {});
      setSearchLocMap(allLocations);
      const newTree = buildTree(allLocations, searchedMap);

      setTree(newTree);
    },
    [fuzzySearch, getAllLocations, locationMap],
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      handleSearch(value);
    },
    [handleSearch],
  );

  const handleIncludeInactive = useCallback((includeInactive: boolean) => {
    setIncludeInactive(includeInactive);
  }, []);

  const handleType = useCallback(
    (value?: ApiLocationType) => {
      if (!value) {
        setTree(buildTree(locationMap));
        setSearchLocMap(locationMap);
        return;
      }
      const foundLocations = Object.values(locationMap).filter(
        (loc) => loc.type === value,
      );

      const allLocations = getAllLocations(foundLocations);

      const searchedMap = foundLocations.reduce<LocationMap>((map, l) => {
        map[l.id] = l;
        return map;
      }, {});
      setSearchLocMap(allLocations);
      const newTree = buildTree(allLocations, searchedMap);

      setTree(newTree);
    },
    [getAllLocations, locationMap],
  );

  const handleShowHidden = useCallback((showHidden: boolean) => {
    setShowHidden(showHidden);
  }, []);

  useEffect(() => {
    handleSearch("");
  }, [handleSearch]);

  useEffect(() => {
    if (Object.keys(expandedMap).length === 0) {
      thunkDispatch(loadLocationsExpandedMap({ initExpandedMap: true }));
    }
  }, [thunkDispatch, expandedMap]);

  useEffect(() => {
    handleType(typeFilter);
  }, [handleType, typeFilter]);

  useEffect(() => {
    if (Object.keys(locationsProducts).length === 0) {
      thunkDispatch(loadLocationsProducts({ initExpandedMap: true }));
    }
  }, [thunkDispatch, expandedMap, locationsProducts]);

  return (
    <Stack minH="100%">
      <AccountPageHeader
        title="Locations"
        counter={Object.keys(expandedMap).length}
        collapseLabel="Search"
        buttonLabel="Add Location"
        autoFocus={Boolean(locationState?.autoFocus)}
        onClickCreateButton={handleNewClick}
        buttonsTitle={
          <IconButton
            ml={2}
            colorScheme="blue"
            aria-label="Export"
            variant="outline"
            icon={<IoDownloadOutline />}
            onClick={onExportModalOpen}
            size={isMobile ? "sm" : "md"}
          />
        }
        children={
          <>
            <GridItem
              colSpan={[12, 12, 1]}
              display={["block", "inline-flex"]}
              gap={2}
            >
              <LocationTypeFilter onChange={setTypeFilter} />
              <HStack paddingTop={[2, "unset"]}>
                <QuickFilterButton
                  activeText="Show Inactive"
                  isActive={includeInactive || false}
                  onClick={() => handleIncludeInactive(!includeInactive)}
                />
                <QuickFilterButton
                  activeText="Show Hidden"
                  isActive={showHidden || false}
                  onClick={() => {
                    handleShowHidden(!showHidden);
                  }}
                />
              </HStack>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 10]} colStart={[1, 1, 7, 9]}>
              <AccountSearchBox
                isUniqueElement={false}
                onInputChange={handleSearchChange}
                searchPlaceholder="Search Locations"
              />
            </GridItem>
          </>
        }
      />
      <Flex justifyContent="space-between" display={["block", "flex"]}>
        <Text fontSize={["small", "medium"]}>
          Locations not visible for at least one product, are marked with an (
          <span style={{ color: asteriskColor }}>*</span>)
        </Text>
        <HStack justifyContent="space-between" paddingTop={2}>
          <Button
            variant="outline"
            colorScheme="blue"
            mr={2}
            onClick={() => dispatch(expandAllLocationMap(true))}
            size={isMobile ? "sm" : "md"}
          >
            Expand All
          </Button>
          <Button
            variant="outline"
            colorScheme="blue"
            onClick={() => dispatch(expandAllLocationMap(false))}
            size={isMobile ? "sm" : "md"}
          >
            Collapse All
          </Button>
        </HStack>
      </Flex>
      <HStack width="100%" paddingY={2}>
        <HStack width={["50%", "40%"]} isTruncated>
          <Text fontWeight="bold" pl={6} fontSize={["small", "unset"]}>
            Locations
          </Text>
        </HStack>
        <Text
          fontWeight="bold"
          width={`${(isMobile ? 50 : 60) / (enabledProducts.length + 1)}%`}
          fontSize={["small", "unset"]}
          isTruncated
        >
          CATEGORY
        </Text>
        {enabledProducts.map((p) => (
          <Text
            key={`product::${p.id}`}
            width={`${(isMobile ? 50 : 60) / (enabledProducts.length + 1)}%`}
            fontWeight="bold"
            textAlign="center"
            fontSize={["small", "unset"]}
            isTruncated
          >
            {p.product.name}
          </Text>
        ))}
      </HStack>
      <LocationTreeList tree={tree} showHidden={showHidden} />
      {isExportModalOpen && (
        <ExportModal
          isOpen={isExportModalOpen}
          onClose={onExportModalClose}
          total={Object.values(searchLocMap).length}
          options={{
            ids: Object.values(searchLocMap).map((loc) => loc.id),
          }}
          type={ExportType.location}
        />
      )}
    </Stack>
  );
}
