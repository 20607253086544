import { ApiRateType } from "@operations-hero/lib-api-client";

export const formatCurrency = (value?: number | string | null) => {
  if (value === undefined || value === null || value === "") return "";
  const decimalValue =
    typeof value === "string" ? parseFloat(value).toFixed(2) : value.toFixed(2);
  return parseFloat(decimalValue.toString()).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  });
};

export const formatRateType = {
  [ApiRateType.each]: "/per hour",
  [ApiRateType.flat]: " flat",
  [ApiRateType.hourly]: " base",
};

export const formatRateLabels = {
  [ApiRateType.each]: "Per hour",
  [ApiRateType.flat]: "Flat fee",
  [ApiRateType.hourly]: "Base rate",
};
