import { Button } from "@chakra-ui/react";
import { ApiComment } from "@operations-hero/lib-api-client";
import { FC, useCallback, useState } from "react";
import { CommentBase } from "./CommentBase";
import { CommentContainer } from "./CommentContainer";
import { CommentForm } from "./CommentForm";
import { CommentFormActions } from "./CommentFormActions";
import { CommentHeader } from "./CommentHeader";

type CommentMode = "read" | "edit";
type CommentProps = {
  comment: ApiComment;
  onSaveChanges: (comment: ApiComment) => Promise<ApiComment | void>;
  onDelete: (comment: ApiComment) => Promise<boolean | void>;
};

export const CommentItem: FC<CommentProps> = ({
  comment,
  onSaveChanges,
  onDelete,
}) => {
  const [mode, setMode] = useState<CommentMode>("read");
  const [currentComment, setCurrentComment] = useState<ApiComment>(comment);

  const handleSaveChanges = useCallback(async () => {
    const updatedComment = await onSaveChanges(currentComment);
    if (!updatedComment) return;
    setCurrentComment(updatedComment);
    setMode("read");
  }, [currentComment, onSaveChanges]);

  const handleCommentChange = useCallback(
    (delta: Partial<ApiComment>) => {
      setCurrentComment({
        ...currentComment,
        ...delta,
      });
    },
    [currentComment]
  );

  return (
    <CommentContainer>
      {mode === "read" ? (
        <CommentBase
          comment={currentComment}
          onDeleteClick={() => onDelete(comment)}
          onEditClick={() => {
            setMode("edit");
          }}
        />
      ) : (
        <>
          <CommentHeader
            onVisibilityChange={(value) =>
              handleCommentChange({
                isPublic: value === "public" ? true : false,
              })
            }
            workingComment={currentComment}
            showAlert={false}
          />

          <CommentForm
            onCommentChange={(value) => handleCommentChange({ comment: value })}
            comment={currentComment}
          ></CommentForm>

          <CommentFormActions justifyContent="start">
            <Button
              size="xs"
              onClick={() => {
                setCurrentComment(comment);
                setMode("read");
              }}
              variant="outline"
              colorScheme="blue"
              aria-label={`cancel::${comment.id}`}
            >
              Cancel
            </Button>
            <Button
              aria-label={`edit::${comment.id}`}
              size="xs"
              variant="outline"
              colorScheme="blue"
              onClick={handleSaveChanges}
            >
              Save
            </Button>
          </CommentFormActions>
        </>
      )}
    </CommentContainer>
  );
};
