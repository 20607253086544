import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  MenuItem,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { RootState } from "../../../store";
import {
  MenuSection,
  setCurrentMenuSection,
} from "../../../store/local-cache.slice";
import { useAuthentication } from "../../auth/AuthProvider";
import { useProductSubscriptions } from "../AppShell";
import { LogoutButton } from "./mega-menu/LogoutButton";
import { QuickAccessMenuItems } from "./mega-menu/QuickAccessMenuItems";
import {
  accountsMenu,
  eventsHQMenu,
  inventoryHQMenu,
  requestHQMenu,
} from "./menuOptions";

interface MegaMenuProps {
  onOpenSwitchModal: () => void;
  mediaQueries: boolean[];
}

const MARGIN = 1.5;

export const MegaMenu: FC<MegaMenuProps> = ({
  onOpenSwitchModal,
  mediaQueries,
}) => {
  const schoolNameColor = useColorModeValue("gray.500", "whiteAlpha.800");
  const dispatch = useDispatch();

  const [productsCount, setProductsCount] = useState<number>(0);

  const [, , isDesktop = false] = mediaQueries;

  const { hasEvents, hasRequests, hasInventory, hasPlanning } =
    useProductSubscriptions();
  const { currentAccount, isProductAdmin, isEventAdmin, isInventoryAdmin } =
    useAuthentication();
  const { enableInvoicesForEvents, isEnablePublicPortal } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const handleSetCurrentSection = useCallback(
    (section: MenuSection) => {
      dispatch(setCurrentMenuSection({ section }));
    },
    [dispatch]
  );

  const memoizedEventsMenu = useMemo(() => {
    const eventsCopy = [...eventsHQMenu];
    if (enableInvoicesForEvents) {
      eventsCopy.push(
        {
          route: "/account/rate-sheet",
          text: "Rate Sheet",
        },
        {
          route: "/account/invoice-list",
          text: "Invoices",
        },
        {
          route: "/account/payments-list",
          text: "Payments",
        }
      );
    }
    if (isEnablePublicPortal) {
      eventsCopy.push({
        route: "/account/portal-users",
        text: "Portal Users",
      });
    }

    return eventsCopy;
  }, [enableInvoicesForEvents, isEnablePublicPortal]);

  const memoizedHeroHQMenu = useMemo(() => {
    const menuOptions = [...requestHQMenu];
    if (!hasPlanning) {
      menuOptions.splice(4, 0, {
        route: generatePath("/planning/:section", { section: "projects" }),
        text: "Projects",
      });
    }

    return menuOptions;
  }, [hasPlanning]);

  const MenuGroupWitdh = useMemo(() => {
    let productsCount = 1;
    if (isProductAdmin && hasRequests) {
      productsCount++;
    }

    if (isEventAdmin && hasEvents) {
      productsCount++;
    }

    if (isInventoryAdmin && hasInventory) {
      productsCount++;
    }
    setProductsCount(productsCount);

    return 100 / productsCount - MARGIN;
  }, [
    hasEvents,
    hasInventory,
    hasRequests,
    isEventAdmin,
    isInventoryAdmin,
    isProductAdmin,
  ]);

  return (
    <>
      <Box px={4} py={2}>
        <Text color={schoolNameColor} fontWeight="bold">
          {currentAccount.name}
        </Text>
        <Divider border="1px" mt={2} />
      </Box>

      <Flex display="flex" justifyContent="space-between">
        {isDesktop ? (
          <HStack gap={2} flex={1} minW="60%" wrap="wrap">
            <QuickAccessMenuItems onOpenSwitchModal={onOpenSwitchModal} />
            <Box
              display="flex"
              flexDir="row"
              justifyItems="center"
              justifyContent="end"
              flex={1}
            >
              <LogoutButton />
            </Box>
          </HStack>
        ) : (
          <>
            <SimpleGrid
              gap={2}
              flex={1}
              w="fit-content"
              rowGap={0}
              minChildWidth={productsCount >= 2 ? "170px" : "100px"}
              columns={3}
              pr={productsCount > 2 ? 12 : "unset"}
            >
              <QuickAccessMenuItems onOpenSwitchModal={onOpenSwitchModal} />
            </SimpleGrid>
            <Box
              display="flex"
              flexDir="row"
              justifyItems="center"
              justifyContent="end"
            >
              <LogoutButton />
            </Box>
          </>
        )}
      </Flex>

      <Grid
        gap={4}
        px={4}
        py={4}
        templateColumns={`repeat(auto-fill, minmax(${MenuGroupWitdh}%, 1fr))`}
        w="100%"
      >
        <GridItem>
          <Heading size="sm" w="100%">
            Account
          </Heading>
          <Divider w="100%" />
          {accountsMenu.map((item) => (
            <MenuItem
              pl={0}
              to={item.route}
              as={RouterLink}
              key={item.route}
              _hover={{ background: "none" }}
              onClick={() => handleSetCurrentSection("account")}
            >
              {item.text}
            </MenuItem>
          ))}
        </GridItem>

        {hasRequests && isProductAdmin && (
          <GridItem display="flex" flexDir="column">
            <Heading size="sm" w="100%">
              HeroHQ
            </Heading>
            <Divider w="100%" />
            {memoizedHeroHQMenu.map((item) => (
              <MenuItem
                pl={0}
                to={item.route}
                as={RouterLink}
                key={item.route}
                _hover={{ background: "none" }}
                onClick={() => handleSetCurrentSection("requests")}
              >
                {item.text}
              </MenuItem>
            ))}
          </GridItem>
        )}

        {hasEvents && isEventAdmin && (
          <GridItem display="flex" flexDir="column">
            <Heading size="sm" w="100%">
              EventsHQ
            </Heading>
            <Divider w="100%" />
            {memoizedEventsMenu.map((item) => (
              <MenuItem
                pl={0}
                to={item.route}
                as={RouterLink}
                key={item.route}
                _hover={{ background: "none" }}
                onClick={() => handleSetCurrentSection("events")}
              >
                {item.text}
              </MenuItem>
            ))}
          </GridItem>
        )}

        {hasInventory && isInventoryAdmin && (
          <GridItem display="flex" flexDir="column">
            <Heading size="sm" w="100%">
              InventoryHQ
            </Heading>
            <Divider w="100%" />
            {inventoryHQMenu.map((item) => (
              <MenuItem
                pl={0}
                to={item.route}
                as={RouterLink}
                key={item.route}
                _hover={{ background: "none" }}
                onClick={() => handleSetCurrentSection("inventory")}
              >
                {item.text}
              </MenuItem>
            ))}
          </GridItem>
        )}
      </Grid>
    </>
  );
};
