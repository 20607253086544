// Regex for full hex color codes : #FFFFFF, #000
export const HEX_COLOR_CODE_FULL = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

// Regex for short hex color code format : FFFFFF, 000
export const HEX_COLOR_CODE_SHORT = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

/**
 *
 * @param s Any string
 * @returns The boolean value if the strings matches the Full Hex Color Code pattern: #FFFFFF, #000
 */
export const isHexColorCodeFull = (s: string) => {
  return !!s.match(HEX_COLOR_CODE_FULL);
};

/**
 *
 * @param s Any string
 * @returns The boolean value if the strings matches the Short Hex Color Code pattern: FFFFFF, 000
 */
export const isHexColorCodeShort = (s: string) => {
  return !!s.match(HEX_COLOR_CODE_SHORT);
};

/**
 *
 * @param hexCode The value to check
 * @param validate If true, it will check if the provided hexCode is a valid (Full | Short) Hex Color Code. True by default
 * @returns The formatted hex code. If it did not pass validation, will return an empty string
 */
export const formatToFullHexColorCode = (
  hexCode: string,
  validate: boolean = true
) => {
  if (!validate) return `#${hexCode}`;
  if (isHexColorCodeFull(hexCode)) return hexCode;
  if (isHexColorCodeShort(hexCode)) return `#${hexCode}`;
  return "";
};

/**
 *
 * @param hexCode The value to check
 * @param validate If true, it will check if the provided hexCode is a valid (Full | Short) Hex Color Code. True by default
 * @returns The formatted hex code. If it did not pass validation, will return an empty string
 */
export const formatToShortHexColorCode = (
  hexCode: string,
  validate: boolean = true
) => {
  if (!validate && hexCode.length > 0) return hexCode.substring(1);
  if (isHexColorCodeShort(hexCode)) return hexCode;
  if (validate && hexCode.length > 0 && isHexColorCodeFull(hexCode))
    return hexCode.substring(1);

  return "";
};
