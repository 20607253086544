import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { AutoGrowTextarea } from "../inputs/AutoGrowTextarea";

export interface TextAreaControlProps {
  label?: string;
  name: string;
  value: string;
  placeholder?: string;
  autoFocusTextarea?: boolean;
}

export const TextAreaControl = ({
  name,
  value,
  placeholder,
  label,
  autoFocusTextarea = false,
}: TextAreaControlProps) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({
    name,
    value: value === null ? undefined : value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (text: string) => {
      helper.setTouched(true);
      helper.setValue(text);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <AutoGrowTextarea
        {...field}
        onChange={handleOnChange}
        autoFocusTextarea={autoFocusTextarea}
        value={field.value || ""}
        placeHolder={placeholder}
      />
      <FormErrorMessage>{meta.error ? meta.error : "a"}</FormErrorMessage>
    </FormControl>
  );
};
