import {
  ApiRequest,
  ApiRequestStatus,
  ApiRequestTaskBook,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { useProductSubscriptions } from "../../../components/shell/AppShell";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  RequestCard,
  RequestsByStatusMap,
  unloadChangeValues,
  updateRequestsByGroup,
} from "../../../store/request-column-view.slice";
import {
  ChangeModalState,
  setUpdateRequestStatus,
  setVisibleSchemaFields,
  unloadForm,
  UpdateRequestStatus,
  updateTaskBooksCompleted,
} from "../../../store/request-form/request-form.slice";
import { initRequestForm } from "../../../store/request-form/thunks/initRequestForm.thunk";
import { loadComments } from "../../../store/request-form/thunks/loadComments.thunk";
import { updateRequest } from "../../../store/request-form/thunks/updateRequest.thunk";
import { isObjectEmpty } from "../../../utils/compareObjects";
import { Board } from "./Board";
import { ColumnViewContext, ColumnViewContextProps } from "./ColumnViewContext";

export const revert = (
  requestMap: RequestsByStatusMap,
  source: RequestCard,
  target: RequestCard
) => {
  const { request: sourceRequest, index: sourceIndex } = source;
  const { request: targetRequest } = target;

  if (isObjectEmpty(source) || !sourceRequest) return;
  if (isObjectEmpty(target) || !targetRequest) return;

  const sourceColumn = [...(requestMap[sourceRequest.status] ?? [])];
  const targetColumn = [...(requestMap[targetRequest.status] ?? [])];

  sourceColumn.splice(sourceIndex, 0, sourceRequest);

  return {
    orderedTarget: targetColumn.filter(
      (request) => request.id !== sourceRequest.id
    ),
    orderedSource: [...sourceColumn],
  };
};

export const ReOrderItems = (
  sourceList: ApiRequest[],
  targetList: ApiRequest[],
  source: RequestCard,
  target: RequestCard,
  orderType: "dnd" | "lazy"
) => {
  const { request: sourceRequest } = source;
  const { request: targetRequest, edge } = target;

  if (isObjectEmpty(source) || !sourceRequest) return;
  if (isObjectEmpty(target) || !targetRequest) return;

  const sourceColumn = [...sourceList];
  const targetColumn = [...targetList];

  const tIndex = target.index || 0;

  const updatedSourceRequest = {
    ...source.request,
    status: targetRequest.status,
  };
  if (orderType === "lazy") {
    targetColumn.splice(0, 0, updatedSourceRequest);
    return {
      orderedTarget: [...targetColumn],
      orderedSource: sourceColumn.filter(
        (item) => item.id !== sourceRequest.id
      ),
    };
  }

  if (!edge) {
    targetColumn.push(updatedSourceRequest);
  }

  if (edge === "top") {
    targetColumn.splice(tIndex, 0, updatedSourceRequest);
  }

  if (edge === "bottom") {
    targetColumn.splice(tIndex + 1, 0, updatedSourceRequest);
  }

  return {
    orderedTarget: targetColumn,
    orderedSource: sourceColumn.filter((item) => item.id !== sourceRequest.id),
  };
};

export const ColumnView = () => {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const { policyMap, workflowMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const [selectedRequest, setSelectedRequest] = useState<ApiRequest | null>(
    null
  );
  const {
    request: initializedRequest,
    schemaFields,
    workflow,
    taskBooksCompleted,
    isInit,
    taskbooks: requestTaskbooks,
    updateRequestStatus,
  } = useSelector((state: RootState) => state.requestForm);

  const { source, target, currentChangeType, requestsByStatus } = useSelector(
    (state: RootState) => state.requestsColumnViewSlice
  );
  const { currentAccount, currentUser, isProductAdmin, apiClient } =
    useAuthentication();
  const { hasInventory } = useProductSubscriptions();

  const { columnViewStatuses } = useSelector(
    (state: RootState) => state.requestsSlice
  );

  const showToast = useShowToast();

  const initWorkingRequest = useCallback(
    async (request: ApiRequest) => {
      await thunkDispatch(
        initRequestForm({
          apiClient,
          account: currentAccount,
          user: currentUser,
          isProductAdmin,
          key: request.key,
          hasInventory,
        })
      ).then(unwrapResult);

      await thunkDispatch(
        loadComments({
          apiClient,
          account: currentAccount,
          key: request.key,
          user: currentUser,
        })
      );
    },
    [
      currentAccount,
      currentUser,
      isProductAdmin,
      hasInventory,
      apiClient,
      thunkDispatch,
    ]
  );

  const handleChangeTaskBooksCompleted = useCallback(
    (value: boolean) => {
      dispatch(updateTaskBooksCompleted(value));
    },
    [dispatch]
  );

  const handleUpdateRequestResult = useCallback(
    (value: UpdateRequestStatus) => {
      dispatch(setUpdateRequestStatus(value));
    },
    [dispatch]
  );

  const handleStatusChange = useCallback(
    (engine: SchemaRulesEngine) => async (status: ApiRequestStatus) => {
      if (!initializedRequest) return;
      const request = initializedRequest;
      return thunkDispatch(
        updateRequest({
          apiClient,
          account: currentAccount,
          delta: { status },
          isChangeModal: false,
          idOrKey: request.key,
          request,
          isProductAdmin,
          engine,
        })
      )
        .then(unwrapResult)
        .catch(async (error: ChangeModalState) => {
          if (
            !error.changeVerification ||
            !error.delta ||
            !(
              error.changeVerification.delta.status === "review" ||
              error.changeVerification.delta.status === "closed"
            )
          ) {
            handleChangeTaskBooksCompleted(true);
            handleUpdateRequestResult("success");
            return;
          }

          let taskbooks: ApiRequestTaskBook[] = [];
          if (isInit) {
            taskbooks = requestTaskbooks;
          } else {
            const response = await apiClient.findRequestTaskbooks(
              currentAccount.id,
              request.id
            );
            taskbooks = response.data;
          }

          if (
            workflow?.requireTaskbooksCompletion &&
            taskbooks.some((taskBook) => taskBook.completed === null)
          ) {
            handleChangeTaskBooksCompleted(false);
            showToast(
              "error",
              "Please complete all taskbooks before completing the request."
            );
            handleUpdateRequestResult("failed");
            return;
          }

          handleChangeTaskBooksCompleted(true);
          handleUpdateRequestResult("success");
          return;
        });
    },
    [
      requestTaskbooks,
      isInit,
      initializedRequest,
      workflow,
      showToast,
      thunkDispatch,
      handleChangeTaskBooksCompleted,
      handleUpdateRequestResult,
      apiClient,
      currentAccount,
      isProductAdmin,
    ]
  );

  const value: ColumnViewContextProps = useMemo(() => {
    const policy = selectedRequest
      ? policyMap[selectedRequest.workflow.id]
      : null;
    const workflow = selectedRequest
      ? workflowMap[selectedRequest.workflow.id]
      : null;
    let engine: SchemaRulesEngine | null = null;
    if (workflow && policy && selectedRequest) {
      engine = new SchemaRulesEngine({
        account: currentAccount,
        form: "full",
        policy: policy,
        workflow: workflow,
        user: currentUser,
        schemaFields,
        isProductAdmin,
      });
    }
    return {
      workingRequest: selectedRequest,
      workingPolicy: policy,
      workingWorkflow: workflow,
      engine: engine,
      initWorkingRequest,
      changeStatusValidation: engine ? handleStatusChange(engine) : null,
      setSelectedRequest: (request: ApiRequest | null) => {
        setSelectedRequest(request);
      },
      enabledStatuses: columnViewStatuses,
    };
  }, [
    columnViewStatuses,
    handleStatusChange,
    selectedRequest,
    initWorkingRequest,
    currentAccount,
    currentUser,
    isProductAdmin,
    policyMap,
    schemaFields,
    workflowMap,
  ]);

  useEffect(() => {
    const engine = value.engine;
    if (!engine || !initializedRequest) return;
    const fields = engine.getVisibleFields({
      request: initializedRequest,
      includeDeleted: false,
    });

    dispatch(setVisibleSchemaFields(fields));
  }, [initializedRequest, dispatch, value]);

  useEffect(() => {
    if (
      taskBooksCompleted === false &&
      source &&
      target &&
      currentChangeType === "lazy"
    ) {
      dispatch(unloadChangeValues());
      dispatch(unloadForm());
      setSelectedRequest(null);
    }
    // eslint-disable-next-line
  }, [taskBooksCompleted, dispatch, source, target]);

  useEffect(() => {
    if (updateRequestStatus === "idle" || updateRequestStatus === "success")
      return;
    if (!source || !target) return;

    showToast("error", "An error occurred while trying to change columns");

    const revertedColumns = revert(requestsByStatus, source, target);
    if (!revertedColumns) return;

    thunkDispatch(
      updateRequestsByGroup({
        requestsMap: {
          ...requestsByStatus,
          [source.request.status]: revertedColumns.orderedSource,
          [target.request.status]: revertedColumns.orderedTarget,
        },
      })
    ).then(() => {
      dispatch(unloadChangeValues());
      dispatch(unloadForm());
      setSelectedRequest(null);
    });
    // eslint-disable-next-line
  }, [updateRequestStatus, dispatch, thunkDispatch, showToast]);

  return (
    <ColumnViewContext.Provider value={value}>
      <Board />
    </ColumnViewContext.Provider>
  );
};
