import { ApiRequestStatus } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import {
  SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
  SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT,
} from "../utils/emailSettingUtils";
import { updateUserSettingToLocalCache } from "./local-cache.slice";

export type RequestDisplayMode = "column" | "row";

export type RequestSliceProps = {
  initDisplayConfig: boolean;
  displayMode: RequestDisplayMode;
  hasTechPlus: boolean;
  columnViewStatuses: ApiRequestStatus[];
};

const initialState: RequestSliceProps = {
  initDisplayConfig: false,
  displayMode: "row",
  hasTechPlus: false,
  columnViewStatuses: [],
};

export const initDisplayModes = createAsyncThunk<
  {
    displayMode: RequestDisplayMode;
    hasTechPlus: boolean;
    columnViewStatuses: ApiRequestStatus[];
  },
  { filters: any }
>("requests/init", async ({ filters }, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { workflowMap, policyMap, userSettings } = state.localCache;
  const { isProductAdmin } = state.auth;

  let hasTechPlus = false;
  for (const key in workflowMap) {
    const policy = policyMap[workflowMap[key].id];
    const { admin, technician, reviewer } = policy;
    if (isProductAdmin || admin || technician || reviewer) {
      hasTechPlus = true;
      break;
    }
  }

  if (!hasTechPlus)
    return { displayMode: "row", hasTechPlus, columnViewStatuses: [] };

  const result: {
    displayMode: RequestDisplayMode;
    hasTechPlus: boolean;
    columnViewStatuses: ApiRequestStatus[];
  } = {
    displayMode: "row",
    hasTechPlus: true,
    columnViewStatuses: [],
  };
  const defaultViewSettingValue =
    userSettings[SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT];
  const defaultViewSetting = defaultViewSettingValue as boolean;
  if (defaultViewSetting) {
    result.displayMode = "column";
  }

  if (
    filters &&
    filters["displayModeFilter"] &&
    (filters["displayModeFilter"] === "row" ||
      filters["displayModeFilter"] === "column")
  ) {
    result.displayMode = filters["displayModeFilter"];
  }
  const enabled = (Object.keys(ApiRequestStatus) as ApiRequestStatus[]).reduce(
    (map, status) => {
      map[status] = true;
      return map;
    },
    {} as { [key in ApiRequestStatus]?: boolean }
  );

  let hasReview = false;
  let hasApprove = false;
  let hasCancel = false;
  for (const key in workflowMap) {
    const workflow = workflowMap[key];
    if (workflow.requireReviewerBeforeClose) {
      hasReview = true;
    }

    if (workflow.requireApproverForNewRequests) hasApprove = true;

    const { admin, approver, technician, reviewer } = policyMap[workflow.id];

    if (isProductAdmin || approver || technician || reviewer || admin) {
      hasCancel = true;
    }

    if (hasReview && hasApprove && hasCancel) break;
  }

  if (!hasReview) delete enabled.review;
  if (!hasApprove) delete enabled.approved;
  if (!hasCancel) delete enabled.canceled;

  const columnVisibility = userSettings[
    SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
  ] as {
    [key in ApiRequestStatus]?: boolean;
  };

  (Object.keys(enabled) as ApiRequestStatus[]).forEach((statusKey) => {
    if (!columnVisibility[statusKey]) {
      delete enabled[statusKey];
    }
  });

  result.columnViewStatuses = Object.keys(enabled) as ApiRequestStatus[];

  return result;
});

export const unloadRequestsSlice = createAsyncThunk(
  "requests/global",
  async (params, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { userSettings } = state.localCache;
    const isColumnDefault = userSettings[
      SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT
    ] as boolean;
    return {
      isColumnDefault,
    };
  }
);

const requestsSlice = createSlice({
  name: "request/global",
  initialState,
  reducers: {
    switchMode: (
      state,
      action: PayloadAction<RequestSliceProps["displayMode"]>
    ) => {
      state.displayMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initDisplayModes.fulfilled, (state, action) => {
      const { displayMode, hasTechPlus, columnViewStatuses } = action.payload;
      state.displayMode = displayMode;
      state.initDisplayConfig = true;
      state.hasTechPlus = hasTechPlus;
      state.columnViewStatuses = columnViewStatuses;
    });

    builder.addCase(updateUserSettingToLocalCache, (state, action) => {
      const { key, value } = action.payload;
      if (key === SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT && value) {
        state.displayMode = "column";
      }
      if (key === SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT && !value) {
        state.displayMode = "row";
      }
    });
    builder.addCase(unloadRequestsSlice.fulfilled, (state, action) => {
      state.initDisplayConfig = false;
      state.displayMode = "row";
      state.hasTechPlus = false;
      state.columnViewStatuses = [];
      const { isColumnDefault } = action.payload;
      if (isColumnDefault) state.displayMode = "column";
      else {
        state.displayMode = "row";
      }
    });
  },
});

export const { switchMode } = requestsSlice.actions;

export default requestsSlice.reducer;
