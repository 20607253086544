import { Square, Text, useColorModeValue } from "@chakra-ui/react";
import { ApiFundingSource } from "@operations-hero/lib-api-client";
import moment from "moment";
import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/data-table/DataTable";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { FundingSourceCard } from "./FundingSourceCard";

export type FundingSourcesTableProps = {
  fundingSources: ApiFundingSource[];
};

export const FundingSourceTable: FC<FundingSourcesTableProps> = ({
  fundingSources,
}) => {
  const navigate = useNavigate();
  const rowBg = useColorModeValue("gray.100", "blue.800");

  const { isDesktop } = useScreenBreakpoints();

  const formatter = useMemo(() => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  }, []);

  let columns = useMemo<DataTableColumn<ApiFundingSource>[]>(
    () => [
      {
        Header: "Color Id",
        accessor: (fundingSource) => fundingSource,
        width: 30,
        maxWidth: 30,
        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return (
            <Square
              rounded="md"
              size="25px"
              bg={value.colorId}
              cursor={"pointer"}
              borderWidth="1px"
              borderStyle="solid"
              borderColor="blue.800"
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: (fundingSource) => fundingSource,
        disableSortBy: true,
        width: 120,
        maxWidth: 120,
        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return <Text>{value.name}</Text>;
        },
      },
      {
        Header: "Contact Name",
        accessor: (fundingSource) => fundingSource,
        width: 70,
        maxWidth: 70,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return <Text>{value.contactName}</Text>;
        },
      },
      {
        Header: "Position",
        accessor: (fundingSource) => fundingSource,
        disableSortBy: true,
        width: 70,
        maxWidth: 70,
        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return <Text>{value.contactPosition}</Text>;
        },
      },
      {
        Header: "Fiscal Year",
        width: 100,
        maxWidth: 100,
        accessor: (fundingSource) => {
          const fiscalYearStart = moment(
            new Date(fundingSource.fiscalYearStart)
          ).format("MMMM YYYY");
          const fiscalYearEnd = moment(
            new Date(fundingSource.fiscalYearEnd)
          ).format("MMMM YYYY");

          return {
            fiscalYearStart,
            fiscalYearEnd,
          };
        },
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<
          ApiFundingSource,
          {
            fiscalYearStart: string;
            fiscalYearEnd: string;
          }
        >) => {
          return (
            <Text>{`${value.fiscalYearStart} -  ${value.fiscalYearEnd}`}</Text>
          );
        },
      },
      {
        Header: "Fund",
        accessor: (fundingSource) => fundingSource,
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return <Text>{formatter.format(value.amount)}</Text>;
        },
      },
      {
        Header: "Spent",
        accessor: (fundingSource) => fundingSource,
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return <Text>{formatter.format(value.fundsSpent)}</Text>;
        },
      },
      {
        Header: "Remaining",
        accessor: (fundingSource) => fundingSource,
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return <Text>{formatter.format(value.fundsRemaining)}</Text>;
        },
      },

      {
        Header: "Last update",
        accessor: (fundingSource) => {
          const lastUpdated = moment(new Date(fundingSource.updated)).format(
            "ll"
          );
          return { lastUpdated };
        },
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<ApiFundingSource, { lastUpdated: string }>) => {
          return <Text>{value.lastUpdated}</Text>;
        },
      },
    ],
    [formatter]
  );

  let cards = useMemo<DataTableColumn<ApiFundingSource>[]>(() => {
    return [
      {
        Header: "Col1",
        accessor: (fundingSource) => fundingSource,
        width: 200,

        Cell: ({ value }: CellProps<ApiFundingSource, ApiFundingSource>) => {
          return <FundingSourceCard fundingSource={value} />;
        },
        boxProps: {
          borderStyle: "hidden",
        },
      },
    ];
  }, []);

  const onRowClick = useCallback(
    (fundingSource: ApiFundingSource) => {
      navigate(`/planning/funding-sources/${fundingSource.id}/details`);
    },
    [navigate]
  );

  return (
    <DataTable
      columns={isDesktop ? columns : cards}
      data={fundingSources}
      headerRest={{ fontSize: "sm" }}
      onRowClick={onRowClick}
      hiddenHeader={isDesktop ? false : true}
      rowProps={{
        cursor: "pointer",
        _hover: isDesktop
          ? {
              bg: rowBg,
            }
          : undefined,
      }}
      rest={{
        overflow: "hidden",
      }}
    />
  );
};
