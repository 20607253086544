import {
  AbsoluteCenter,
  AbsoluteCenterProps,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

interface ProgressbarProps extends AbsoluteCenterProps {
  value: number;
}

export const Progressbar = (props: ProgressbarProps) => {
  const { value, ...rest } = props;
  const completeButtonColor = useColorModeValue(
    "blue.500",
    "ScheduledRequestColors.stepCircleActive"
  );
  const incompleteButtonColor = useColorModeValue(
    "gray.200",
    "ScheduledRequestColors.stepCircleInactive"
  );
  return (
    <AbsoluteCenter
      height="2"
      width="full"
      axis="vertical"
      role="progressbar"
      aria-valuemin={0}
      position="absolute"
      aria-valuemax={100}
      aria-valuenow={value}
      aria-valuetext={`Progress: ${value}%`}
      bg={incompleteButtonColor}
      {...rest}
    >
      <Box bg={completeButtonColor} height="inherit" width={`${value}%`} />
    </AbsoluteCenter>
  );
};
