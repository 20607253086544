import {
  ApiAsset,
  ApiComment,
  ApiGallery,
  ApiInventoryRequest,
  ApiIssuanceTransaction,
  ApiLocation,
  ApiPagingOptions,
  ApiProject,
  ApiRequest,
  ApiRequestAsset,
  ApiRequestAssociation,
  ApiRequestReminder,
  ApiRequestStatus,
  ApiRequestTaskBook,
  ApiTransaction,
  ApiWorkflow,
  ApiWorkflowField,
  ApiWorkflowSchemaField,
  InventoryRequestSummary,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { VerifyChangeResult } from "@operations-hero/lib-rule-engine";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Attachment } from "../../components/attachments/Attachments";
import {
  RequestNotificationStatus,
  TimelineStatus,
} from "../../types/redux-slices-types";
import { addAssetHandlers } from "./thunks/addAsset.thunk";
import { addTaskbookHandlers } from "./thunks/addTaskbook.thunk";
import { createUpadeCommentsHandlers } from "./thunks/createEditCommentThunk";
import { createTransactionHandlers } from "./thunks/createTransaction.thunk";
import { createTransactionAttachmentHandlers } from "./thunks/createTransactionAttachment.thunk";
import { saveRequestFollowersHandler } from "./thunks/followers.thunk";
import { initRequestFormHandlers } from "./thunks/initRequestForm.thunk";
import { initRequestFormFromListHandlers } from "./thunks/initRequestFormFromList.thunk";
import { loadInventoryRequestsHandler } from "./thunks/inventoryRequests.thunk";
import { loadAssetsHandlers } from "./thunks/loadAssets.thunk";
import { loadCommentsHandlers } from "./thunks/loadComments.thunk";
import {
  loadGalleryImagesHandlers,
  loadLocationsGalleryHandlers,
} from "./thunks/loadLocationGallery.thunk";
import { loadTaskbooksHandlers } from "./thunks/loadTaskbooks.thunk";
import { loadTransactionAttachmentsHandlers } from "./thunks/loadTransactionAttachments.thunk";
import { loadTransactionsHandlers } from "./thunks/loadTransactions.thunk";
import {
  createRequestReminderHandler,
  loadRequestRemindersHandler,
  updateRequestReminderHandler,
} from "./thunks/reminder.thunk";
import { removeAssetHandlers } from "./thunks/removeAsset.thunk";
import { removeAttachmentHandlers } from "./thunks/removeAttachment.thunk";
import { removeTaskbookHandlers } from "./thunks/removeTaskbook.thunk";
import { removeTransactionHandlers } from "./thunks/removeTransaction.thunk";
import {
  createRequestAssociationHandler,
  loadRequestAssociationsHandler,
  removeRequestAssociationHandler,
} from "./thunks/requestAssociations.thunk";
import {
  DEFAULT_REQUEST_NOTIFICATION,
  requestNotificationHandler,
} from "./thunks/requestNotification.thunk";
import {
  DEFAULT_TIMELINE,
  requestTimelineHandler,
} from "./thunks/requestTimeline.thunk";
import { saveCommentHandlers } from "./thunks/saveComment.thunk";
import { updateRequestHandlers } from "./thunks/updateRequest.thunk";
import { updateTaskbookHandlers } from "./thunks/updateTaskbook.thunk";
import { updateTransactionHandlers } from "./thunks/updateTransaction.thunk";

export enum AttachmentTypeEnum {
  REQUEST = "REQUEST",
  REQUEST_TRANSACTION = "REQUEST_TRANSACTION",
}

const DEFAULT_REQUEST_ASSOCIATIONS = {
  page: 1,
  pageSize: 10,
  total: 0,
  data: [] as ApiRequestAssociation[],
};

const DEFAULT_INVENTORY_REQUEST = {
  page: 1,
  pageSize: 10,
  total: 0,
  data: [] as ApiInventoryRequest[],
};

const PAGE_SIZE_REMINDER = 10;

export type UpdateRequestStatus = "idle" | "success" | "failed";

export interface ChangeModalState {
  changeVerification: VerifyChangeResult | null;
  delta: Partial<ApiRequest> | null;
  requiredFields: ApiWorkflowField[] | null;
}

export interface RequestTransition {
  next: ApiRequestStatus;
  backwards: ApiRequestStatus[];
  forwards: ApiRequestStatus[];
  canCancel: boolean;
}

export interface RequestSliceState {
  attachments: Attachment[];
  request: ApiRequest | null;
  schemaFields: ApiWorkflowSchemaField[];
  visibleFields: ApiWorkflowField[];
  workflow: ApiWorkflow | null;
  policy: WorkflowPolicy | null;
  transactions: {
    labors: {
      laborsData: ApiTransaction[];
      laborsTotal: number;
      laborsCurrentPage?: number;
    };
    totalHours: number;
    purchases: {
      purchasesData: ApiTransaction[];
      purchasesTotal: number;
      purchasesCurrentPage?: number;
    };
    totalPurchases: number;
    workingTransactionAttachments: Attachment[];
    issuance: {
      issuanceData: ApiTransaction[];
      issuanceTotal: number;
      issuanceCurrentPage?: number;
    };
    issuancesReturns: {
      returnsData: Record<string, ApiIssuanceTransaction[]>;
    };
  };
  assets: {
    pageSize: number;
    page: number;
    data: ApiRequestAsset[];
    total: number;
  };
  taskbooks: ApiRequestTaskBook[];
  isRequesterOnly: boolean;
  isContractorOnly: boolean;
  isContractor: boolean;
  isApproverOnly: boolean;
  isInit: boolean;
  formIsLocked: boolean;
  canReopen: boolean;
  changeModal: ChangeModalState;
  transition: RequestTransition | null;
  comments: ApiComment[];
  commentsTotal: number;
  commentsCurrentPage?: number;
  locationMap: { [key: string]: ApiLocation };
  descendantsMap: { [key: string]: string[] };
  taskBooksCompleted: boolean;
  isCurrentRequester: boolean;
  requestAssociations: {
    page: number;
    pageSize: number;
    total: number;
    data: ApiRequestAssociation[];
  };
  timeline: TimelineStatus;
  isOneClickAction: boolean;
  notifications: RequestNotificationStatus;
  taskbooksTotal: number;
  assetsTotal: number;
  expensesTotal: number;
  inventoryTotal: number;
  laborTotal: number;
  issuanceTotal: number;
  inventoryRequests: {
    page: number;
    pageSize: number;
    total: number;
    data: InventoryRequestSummary[];
  };
  schemaVerificationFailed?: boolean;
  isAutomaticTransition?: boolean;
  updateRequestStatus: UpdateRequestStatus;
  locationGallery: {
    isOpen: boolean;
    loading: UpdateRequestStatus;
    images: Attachment[];
    imagesPage: number;
    imagesTotal: number;
    imagesPageSize: number;
    gallery: ApiGallery | null;
  };
  project: ApiProject | null;
  reminders: {
    loading: "idle" | "pending" | "succeeded" | "failed";
    data: ApiRequestReminder[];
    total: number;
    filters: ApiPagingOptions;
  };
}

export interface AttachmentType {
  attachmentType: AttachmentTypeEnum;
}

export const requestSlice = createSlice({
  name: "request",
  initialState: {
    allowedLocations: [],
    locationMap: {},
    descendantsMap: {},
    attachments: [],
    request: null,
    schemaFields: [],
    visibleFields: [],
    workflow: null,
    policy: null,
    isRequesterOnly: true,
    isContractorOnly: true,
    isContractor: true,
    isApproverOnly: true,
    isInit: false,
    formIsLocked: false,
    canReopen: false,
    changeModal: {
      changeVerification: null,
      delta: null,
      requiredFields: null,
    },
    transition: null,
    comments: [],
    commentsTotal: 0,
    commentsCurrentPage: 1,
    assets: {
      pageSize: 20,
      page: 1,
      data: [],
      total: 0,
    },
    taskbooks: [],
    transactions: {
      labors: {
        laborsData: [],
        laborsTotal: 0,
        laborsCurrentPage: 1,
      },
      totalHours: 0,
      purchases: {
        purchasesData: [],
        purchasesTotal: 0,
        purchasesCurrentPage: 1,
      },
      totalPurchases: 0,
      workingTransactionAttachments: [],
      issuance: {
        issuanceData: [],
        issuanceTotal: 0,
        issuanceCurrentPage: 1,
      },
      issuancesReturns: {
        returnsData: {},
      },
    },
    taskBooksCompleted: true,
    isCurrentRequester: true,
    requestAssociations: DEFAULT_REQUEST_ASSOCIATIONS,
    timeline: DEFAULT_TIMELINE,
    isOneClickAction: false,
    notifications: DEFAULT_REQUEST_NOTIFICATION,
    taskbooksTotal: 0,
    assetsTotal: 0,
    expensesTotal: 0,
    inventoryTotal: 0,
    laborTotal: 0,
    issuanceTotal: 0,
    inventoryRequests: DEFAULT_INVENTORY_REQUEST,
    updateRequestStatus: "idle",
    locationGallery: {
      images: [],
      isOpen: false,
      gallery: null,
      imagesPage: 1,
      imagesTotal: 0,
      loading: "idle",
      imagesPageSize: 10,
    },
    project: null,
    reminders: {
      loading: "idle",
      data: [],
      total: 0,
      filters: { pageSize: PAGE_SIZE_REMINDER, page: 1 },
    },
  } as RequestSliceState,
  reducers: {
    cancelChange: (state: RequestSliceState) => {
      state.changeModal = {
        changeVerification: null,
        delta: null,
        requiredFields: null,
      };
    },
    addAttachment: (
      state: RequestSliceState,
      action: PayloadAction<Attachment & AttachmentType>
    ) => {
      const { attachmentType } = action.payload;
      if (attachmentType === AttachmentTypeEnum.REQUEST) {
        const { attachments } = state;
        if (
          attachments.some(
            (attachement) =>
              attachement.isNew === true &&
              attachement.name === action.payload.name
          )
        ) {
          return;
        }
        state.attachments.push(action.payload);
      }

      if (attachmentType === AttachmentTypeEnum.REQUEST_TRANSACTION) {
        const { workingTransactionAttachments } = state.transactions;
        if (
          workingTransactionAttachments.some(
            (attachement) =>
              attachement.isNew === true &&
              attachement.name === action.payload.name
          )
        ) {
          return;
        }
        state.transactions.workingTransactionAttachments.push(action.payload);
      }
    },
    removeAttachmentReducer: (
      state: RequestSliceState,
      action: PayloadAction<Attachment & AttachmentType>
    ) => {
      const { attachmentType } = action.payload;

      if (attachmentType === AttachmentTypeEnum.REQUEST) {
        const index = state.attachments.findIndex(
          (x) => x.isNew === true && x.name === action.payload.name
        );

        state.attachments.splice(index, 1);
      }
      if (attachmentType === AttachmentTypeEnum.REQUEST_TRANSACTION) {
        const { workingTransactionAttachments } = state.transactions;
        const index = workingTransactionAttachments.findIndex(
          (x) => x.name === action.payload.name
        );

        state.transactions.workingTransactionAttachments.splice(index, 1);
      }
    },
    updateAttachment: (
      state: RequestSliceState,
      action: PayloadAction<Attachment & AttachmentType>
    ) => {
      const { attachmentType } = action.payload;

      if (attachmentType === AttachmentTypeEnum.REQUEST) {
        const index = state.attachments.findIndex(
          (x) => x.isNew === true && x.name === action.payload.name
        );

        state.attachments[index] = action.payload;
      }
      if (attachmentType === AttachmentTypeEnum.REQUEST_TRANSACTION) {
        const index =
          state.transactions.workingTransactionAttachments.findIndex(
            (x) => x.isNew === true && x.name === action.payload.name
          );

        state.transactions.workingTransactionAttachments[index] =
          action.payload;
      }
    },
    cleanTransactionAttachements: (state: RequestSliceState) => {
      state.transactions.workingTransactionAttachments = [];
    },
    setLaborsTransactionsCurrentPage: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.transactions.labors.laborsCurrentPage = payload.payload;
    },
    setTotalHoursTransactionChange: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.transactions.totalHours =
        Number(state.transactions.totalHours) + payload.payload; //issue redux taking state as string?
    },
    setPurchasesTransactionsCurrentPage: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.transactions.purchases.purchasesCurrentPage = payload.payload;
    },
    setTotalPurchaseTransactionChange: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.transactions.totalPurchases =
        Number(state.transactions.totalPurchases) + payload.payload;
    },
    setIssuanceTransactionsCurrentPage: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.transactions.issuance.issuanceCurrentPage = payload.payload;
    },
    setCommentsCurrentPage: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.commentsCurrentPage = payload.payload;
    },
    setRequestAssociationsPage: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.requestAssociations.page = payload.payload;
    },
    setAssetsCurrentPage: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.assets.page = payload.payload;
    },
    setTimelineCurrentPage: (
      state: RequestSliceState,
      action: PayloadAction<number>
    ) => {
      state.timeline.page = action.payload;
    },
    updateRequestAsset: (
      state: RequestSliceState,
      { payload }: PayloadAction<ApiAsset>
    ) => {
      const idx = state.assets.data.findIndex((a) => a.id === payload.id);
      if (idx === -1) return;

      state.assets.data[idx] = payload;
    },
    updateRequestSliceData: (
      state: RequestSliceState,
      action: PayloadAction<Partial<ApiRequest>>
    ) => {
      if (!state.request) return;
      state.request = { ...state.request, ...action.payload };
    },
    setIsOneClickRequestAction: (
      state: RequestSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.isOneClickAction = action.payload;
    },
    setRequestNotificationCurrentPage: (
      state: RequestSliceState,
      action: PayloadAction<number>
    ) => {
      state.notifications.page = action.payload;
    },
    setProject: (
      state: RequestSliceState,
      action: PayloadAction<RequestSliceState["project"]>
    ) => {
      state.project = action.payload;
    },
    unloadForm: (state: RequestSliceState) => {
      state.attachments = [];
      state.changeModal = {
        changeVerification: null,
        delta: null,
        requiredFields: null,
      };
      state.request = null;
      state.schemaFields = [];
      state.workflow = null;
      state.policy = null;
      state.assets = {
        pageSize: 20,
        page: 1,
        data: [],
        total: 0,
      };
      state.comments = [];
      state.commentsCurrentPage = 1;
      state.commentsTotal = 0;
      state.transactions = {
        labors: {
          laborsData: [],
          laborsTotal: 0,
          laborsCurrentPage: 1,
        },
        totalHours: 0,
        purchases: {
          purchasesData: [],
          purchasesTotal: 0,
          purchasesCurrentPage: 1,
        },
        totalPurchases: 0,
        workingTransactionAttachments: [],
        issuance: {
          issuanceData: [],
          issuanceTotal: 0,
          issuanceCurrentPage: 1,
        },
        issuancesReturns: {
          returnsData: {},
        },
      };
      state.taskbooks = [];
      state.isInit = false;
      state.isCurrentRequester = false;
      state.requestAssociations = DEFAULT_REQUEST_ASSOCIATIONS;
      state.notifications =
        DEFAULT_REQUEST_NOTIFICATION as RequestNotificationStatus;
      state.taskbooksTotal = 0;
      state.assetsTotal = 0;
      state.laborTotal = 0;
      state.expensesTotal = 0;
      state.inventoryTotal = 0;
      state.inventoryRequests = DEFAULT_INVENTORY_REQUEST;
      state.visibleFields = [];
      state.updateRequestStatus = "idle";
      state.locationGallery = {
        images: [],
        isOpen: false,
        gallery: null,
        imagesPage: 1,
        imagesTotal: 0,
        loading: "idle",
        imagesPageSize: 10,
      };
      state.reminders = {
        loading: "idle",
        data: [],
        total: 0,
        filters: { pageSize: PAGE_SIZE_REMINDER },
      };
    },
    updateTaskBooksCompleted: (state, action) => {
      state.taskBooksCompleted = action.payload;
    },
    setInventoryRequestsPage: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.inventoryRequests.page = payload.payload;
    },
    setTotalInventoryChange: (
      state: RequestSliceState,
      payload: PayloadAction<number>
    ) => {
      state.inventoryTotal = Number(state.inventoryTotal) + payload.payload;
    },
    setIsAutomaticTransition: (
      state: RequestSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.isAutomaticTransition = action.payload;
    },
    setVisibleSchemaFields: (
      state: RequestSliceState,
      action: PayloadAction<ApiWorkflowField[]>
    ) => {
      state.visibleFields = action.payload;
    },
    setUpdateRequestStatus: (
      state,
      action: PayloadAction<RequestSliceState["updateRequestStatus"]>
    ) => {
      state.updateRequestStatus = action.payload;
    },
    setIsOpenLocationGallery: (
      state: RequestSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.locationGallery.isOpen = action.payload;
    },
    setGalleryImagesPage: (
      state: RequestSliceState,
      action: PayloadAction<number>
    ) => {
      state.locationGallery.imagesPage = action.payload;
    },
    updateRemindersFilters: (
      state: RequestSliceState,
      action: PayloadAction<ApiPagingOptions>
    ) => {
      state.reminders.filters = {
        ...state.reminders.filters,
        ...action.payload,
      };
      state.reminders.loading = "idle";
    },
  },
  extraReducers: (builder) => {
    /* requests */
    initRequestFormHandlers(builder);
    initRequestFormFromListHandlers(builder);
    updateRequestHandlers(builder);

    /* attachments */
    // all request attachments interactions are dispatched

    /* assets */
    loadAssetsHandlers(builder);
    addAssetHandlers(builder);
    removeAssetHandlers(builder);

    /* comments */
    loadCommentsHandlers(builder);
    saveCommentHandlers(builder);

    /* taskbooks */
    addTaskbookHandlers(builder);
    removeTaskbookHandlers(builder);
    updateTaskbookHandlers(builder);
    loadTaskbooksHandlers(builder);

    /* transactions */
    loadTransactionsHandlers(builder);
    createTransactionHandlers(builder);
    updateTransactionHandlers(builder);
    removeTransactionHandlers(builder);
    loadTransactionAttachmentsHandlers(builder);
    createTransactionAttachmentHandlers(builder);
    removeAttachmentHandlers(builder);
    createUpadeCommentsHandlers(builder);

    /* request associations */
    loadRequestAssociationsHandler(builder);
    createRequestAssociationHandler(builder);
    removeRequestAssociationHandler(builder);

    /* request timeline */
    requestTimelineHandler(builder);

    /* request notification */
    requestNotificationHandler(builder);

    /* inventory requests */
    loadInventoryRequestsHandler(builder);

    /* location gallery */
    loadLocationsGalleryHandlers(builder);
    loadGalleryImagesHandlers(builder);

    /* FollowBy */
    saveRequestFollowersHandler(builder);

    /* Reminder */
    createRequestReminderHandler(builder);
    updateRequestReminderHandler(builder);
    loadRequestRemindersHandler(builder);
  },
});

// Action creators are generated for each case reducer function
export const {
  cancelChange,
  unloadForm,
  addAttachment,
  updateAttachment,
  updateRequestAsset,
  removeAttachmentReducer,
  cleanTransactionAttachements,
  setLaborsTransactionsCurrentPage,
  setTotalHoursTransactionChange,
  setPurchasesTransactionsCurrentPage,
  setTotalPurchaseTransactionChange,
  setIssuanceTransactionsCurrentPage,
  setCommentsCurrentPage,
  setAssetsCurrentPage,
  updateTaskBooksCompleted,
  setRequestAssociationsPage,
  setTimelineCurrentPage,
  updateRequestSliceData,
  setIsOneClickRequestAction,
  setRequestNotificationCurrentPage,
  setInventoryRequestsPage,
  setTotalInventoryChange,
  setIsAutomaticTransition,
  setVisibleSchemaFields,
  setUpdateRequestStatus,
  setIsOpenLocationGallery,
  setGalleryImagesPage,
  setProject,
  updateRemindersFilters,
} = requestSlice.actions;

export default requestSlice.reducer;
