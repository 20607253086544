import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, HStack, Text } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/menu";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RiCalendar2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  AbsoluteDateFilter,
  DateFields,
  RelativeDateFilter,
  RelativeDateOptions,
  setDateField,
  setIsCustomDate,
  setIsFiscalYear,
  updateRequestFilters,
} from "../../../store/request-list.slice";
import { MenuCalendar } from "./RangeDates";

const REMOVE_TIME = /[T ]/i;
type RequestDateFilterProps = {
  isDisabled?: boolean;
};

export const RequestDateFilter: FC<RequestDateFilterProps> = ({
  isDisabled = false,
}) => {
  const bgColor = useColorModeValue("white", "whiteAlpha.300");
  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = !isMobileMode && !isTablet ? true : false;
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState("");

  const { date } = useSelector((state: RootState) => state.requestList.filters);
  const { dateField, accountDetail } = useSelector(
    (state: RootState) => state.requestList,
  );

  const stateDateField = useMemo(
    () => (date ? date.field : dateField),
    [date, dateField],
  );

  const handleSetDateField = useCallback(
    (value: string | string[]) => {
      const dateField = Array.isArray(value) ? value[0] : value;
      dispatch(setDateField(dateField as DateFields));
      if (date) {
        const updatedDate =
          date.type === "relative"
            ? ({
                field: dateField as DateFields,
                type: date.type,
                value: date.value,
              } as RelativeDateFilter)
            : date.isFiscalYear
              ? ({
                  field: dateField as DateFields,
                  type: date.type,
                  value: date.value,
                  isFiscalYear: true,
                  fiscalYear: date.fiscalYear,
                } as AbsoluteDateFilter)
              : ({
                  field: dateField as DateFields,
                  type: date.type,
                  value: date.value,
                  isFiscalYear: false,
                } as AbsoluteDateFilter);
        dispatch(updateRequestFilters({ date: updatedDate }));
      }
    },
    [dispatch, date],
  );

  const handleIsCustomChange = useCallback(
    (value: boolean) => {
      const custom = Array.isArray(value) ? value[0] : value;
      dispatch(setIsCustomDate(custom));
    },
    [dispatch],
  );

  const calculateFiscalYear = useCallback(
    (value: "thisfiscalyear" | "lastfiscalyear") => {
      if (!accountDetail) {
        return [];
      }

      const { fiscalStartMonth } = accountDetail;

      const currentYear = new Date().getFullYear();
      var dateStartThisFiscalYear = new Date(
        currentYear,
        fiscalStartMonth! - 1,
        1,
      );
      var dateEndThisFiscalYear = new Date(
        currentYear + 1,
        fiscalStartMonth! - 1,
        0,
      );

      var dateStartLastFiscalYear = new Date(
        currentYear - 1,
        fiscalStartMonth! - 1,
        1,
      );
      var dateEndLastFiscalYear = new Date(
        currentYear,
        fiscalStartMonth! - 1,
        0,
      );

      return value === "thisfiscalyear"
        ? [
            dateStartThisFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
            dateEndThisFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
          ]
        : [
            dateStartLastFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
            dateEndLastFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
          ];
    },
    [accountDetail],
  );

  const handleDateRelativeChange = useCallback(
    (value: string | string[]) => {
      const relative = Array.isArray(value) ? value[0] : value;
      setDateRange(relative);
      if (relative === "custom") {
        handleIsCustomChange(true);
      } else {
        handleIsCustomChange(false);
        const field = dateField ? dateField : date && date.field;
        if (field) {
          if (relative === "thisfiscalyear" || relative === "lastfiscalyear") {
            const absoluteDate: AbsoluteDateFilter = {
              field: field,
              type: "absolute",
              value: calculateFiscalYear(relative),
            };
            dispatch(updateRequestFilters({ date: absoluteDate }));
            dispatch(
              setIsFiscalYear({ isFiscalYear: true, fiscalYear: relative }),
            );
            return;
          }

          const relativeDate: RelativeDateFilter = {
            field: field,
            type: "relative",
            value: relative as RelativeDateOptions,
          };
          dispatch(updateRequestFilters({ date: relativeDate }));
        }
      }
    },
    [calculateFiscalYear, dateField, date, dispatch, handleIsCustomChange],
  );

  useEffect(() => {
    if (date) {
      if (date.type === "absolute" && !date.isFiscalYear) {
        setDateRange("custom");
        dispatch(setIsCustomDate(true));
      } else {
        date.type === "absolute" &&
          date.fiscalYear &&
          setDateRange(date.fiscalYear);

        typeof date.value === "string" && setDateRange(date.value);
      }
    } else {
      setDateRange("");
    }
  }, [calculateFiscalYear, date, dateField, dispatch, stateDateField]);

  return (
    <Box>
      <Menu closeOnSelect={false} flip={false} strategy="fixed">
        <MenuButton
          as={IconButton}
          icon={<RiCalendar2Fill />}
          aria-label="Field"
          bgColor={bgColor}
          colorScheme="blue"
          variant="outline"
          size={isDesktop ? "md" : "sm"}
          id="menu-button-relative"
          disabled={isDisabled}
        />
        <MenuList zIndex={3}>
          {isDesktop ? (
            <>
              <Box>
                <Text m={4} fontSize="sm" fontWeight="medium">
                  Select Date Type
                </Text>
              </Box>
              <HStack spacing="17%">
                <MenuOptionGroup
                  value={stateDateField}
                  onChange={handleSetDateField}
                  type="radio"
                >
                  <MenuItemOption value="created">Created</MenuItemOption>
                  <MenuItemOption value="due">Due Date</MenuItemOption>
                  <MenuItemOption value="start">Start Date</MenuItemOption>
                </MenuOptionGroup>
                <MenuOptionGroup
                  value={stateDateField}
                  onChange={handleSetDateField}
                  type="radio"
                >
                  <MenuItemOption value="updated">Updated</MenuItemOption>
                  <MenuItemOption value="completed">Completed</MenuItemOption>
                  <MenuItemOption value="statusUpdated">
                    Status Updated
                  </MenuItemOption>
                </MenuOptionGroup>
              </HStack>
              <MenuDivider />
              <Box>
                <Text m={4} fontSize="sm" fontWeight="medium">
                  Select Date Range
                </Text>
              </Box>
              <HStack>
                <MenuOptionGroup
                  value={dateRange}
                  onChange={handleDateRelativeChange}
                  type="radio"
                >
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="last7d"
                    closeOnSelect
                  >
                    Last 7 days
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="next7d"
                    closeOnSelect
                  >
                    Next 7 days
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="last30d"
                    closeOnSelect
                  >
                    Last 30 days
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="next30d"
                    closeOnSelect
                  >
                    Next 30 days
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="thisWeek"
                    closeOnSelect
                  >
                    This week (Sun - Sat)
                  </MenuItemOption>
                  {accountDetail?.fiscalStartMonth && (
                    <MenuItemOption
                      isDisabled={!stateDateField ? true : false}
                      value="thisfiscalyear"
                      closeOnSelect
                    >
                      This Fiscal Year
                    </MenuItemOption>
                  )}
                </MenuOptionGroup>
                <MenuOptionGroup
                  value={dateRange}
                  onChange={handleDateRelativeChange}
                  type="radio"
                >
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="lastWeek"
                    closeOnSelect
                  >
                    Last Week(Sun - Sat)
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="nextWeek"
                    closeOnSelect
                  >
                    Next Week (Sun - Sat)
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="thisMonth"
                    closeOnSelect
                  >
                    This Month (1st- EOM)
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="lastMonth"
                    closeOnSelect
                  >
                    Last Month (1st- EOM)
                  </MenuItemOption>
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="nextMonth"
                    closeOnSelect
                  >
                    Next month (1st- EOM)
                  </MenuItemOption>
                  {accountDetail?.fiscalStartMonth && (
                    <MenuItemOption
                      isDisabled={!stateDateField ? true : false}
                      value="lastfiscalyear"
                      closeOnSelect
                    >
                      Last Fiscal Year
                    </MenuItemOption>
                  )}
                </MenuOptionGroup>
              </HStack>
              <HStack>
                <MenuOptionGroup
                  value={dateRange}
                  onChange={handleDateRelativeChange}
                  type="radio"
                >
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="custom"
                  >
                    Custom
                  </MenuItemOption>
                  <MenuCalendar />
                </MenuOptionGroup>
              </HStack>
            </>
          ) : (
            <>
              <MenuOptionGroup
                title="Select Date Type"
                value={stateDateField}
                onChange={handleSetDateField}
                type="radio"
              >
                <MenuItemOption value="created">Created</MenuItemOption>
                <MenuItemOption value="due">Due Date</MenuItemOption>
                <MenuItemOption value="start">Start Date</MenuItemOption>
                <MenuItemOption value="updated">Updated</MenuItemOption>
                <MenuItemOption value="completed">Completed</MenuItemOption>
                <MenuItemOption value="statusUpdated">
                  Status Updated
                </MenuItemOption>
              </MenuOptionGroup>
              <MenuDivider />
              <MenuOptionGroup
                title="Select Date Range"
                value={dateRange}
                onChange={handleDateRelativeChange}
                type="radio"
              >
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="last7d"
                  closeOnSelect
                >
                  Last 7 days
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="next7d"
                  closeOnSelect
                >
                  Next 7 days
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="last30d"
                  closeOnSelect
                >
                  Last 30 days
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="next30d"
                  closeOnSelect
                >
                  Next 30 days
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="thisWeek"
                  closeOnSelect
                >
                  This week (Sun - Sat)
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="lastWeek"
                  closeOnSelect
                >
                  Last Week(Sun - Sat)
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="nextWeek"
                  closeOnSelect
                >
                  Next Week (Sun - Sat)
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="thisMonth"
                  closeOnSelect
                >
                  This Month (1st- EOM)
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="lastMonth"
                  closeOnSelect
                >
                  Last Month (1st- EOM)
                </MenuItemOption>
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="nextMonth"
                  closeOnSelect
                >
                  Next month (1st- EOM)
                </MenuItemOption>
                {accountDetail?.fiscalStartMonth && (
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="thisfiscalyear"
                    closeOnSelect
                  >
                    This Fiscal Year
                  </MenuItemOption>
                )}
                {accountDetail?.fiscalStartMonth && (
                  <MenuItemOption
                    isDisabled={!stateDateField ? true : false}
                    value="lastfiscalyear"
                    closeOnSelect
                  >
                    Last Fiscal Year
                  </MenuItemOption>
                )}
                <MenuItemOption
                  isDisabled={!stateDateField ? true : false}
                  value="custom"
                >
                  Custom
                </MenuItemOption>
                <MenuCalendar />
              </MenuOptionGroup>
            </>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};
