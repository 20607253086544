import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { FC, ReactElement, RefObject, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormikObserver } from "../../hooks/formikObserver";
import { RootState } from "../../store";
import { CountryPhoneCodeParams } from "../form-helpers/CountryPhoneCodeSelectControl";
import { PhoneValue } from "../inputs/PhoneInput";
import { phoneVerificationFormSchema } from "./PhoneVerificationFormSchema";

export type PhoneVerificationFormParams = {
  phone: PhoneValue;
  countryCode: CountryPhoneCodeParams;
  code: string | null;
};

type PhoneVerificationFormProps = {
  formRef: RefObject<FormikProps<PhoneVerificationFormParams>>;
  step: ReactElement;
  initialValues: PhoneVerificationFormParams;
  onChange: (isValid: boolean) => void;
};

export const PhoneVerificationForm: FC<PhoneVerificationFormProps> = ({
  formRef,
  step,
  initialValues,
  onChange,
}) => {
  const { verificationId, verificationStatus } = useSelector(
    (root: RootState) => root.phoneVerificationSlice,
  );

  const schema = useMemo(
    () => phoneVerificationFormSchema(verificationId, verificationStatus),
    [verificationStatus, verificationId],
  );

  const handleOnChange = useCallback(
    async (
      values: PhoneVerificationFormParams,
      setFieldValue: any,
      isSubmitting: boolean,
      handleSubmit: any,
      isValid: boolean,
      validateForm: (
        values: PhoneVerificationFormParams,
      ) => Promise<FormikErrors<PhoneVerificationFormParams>>,
    ) => {
      const errors = await validateForm(values);

      if (errors.code || errors.phone) {
        onChange(false);
        return;
      }

      onChange(true);
    },
    [onChange],
  );

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={schema}
      >
        {() => (
          <Form>
            <FormikObserver cb={handleOnChange} />
            {step}
          </Form>
        )}
      </Formik>
    </>
  );
};
