export const areObjectsEquals = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !areObjectsEquals(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const isObject = (obj: any) => {
  return obj !== null && obj !== undefined && typeof obj === "object";
};

export const getChangedPropsFromObject = <O = any, I = any>(
  input: I,
  ouput: I
) => {
  const keys = Object.keys(ouput as Object);

  const res = keys.reduce((prevValue, currentValues) => {
    if (
      Array.isArray((ouput as any)[currentValues]) ||
      (typeof (ouput as any)[currentValues] === "object" &&
        (ouput as any)[currentValues] !== null)
    ) {
      if (
        (input as any).hasOwnProperty(currentValues) &&
        JSON.stringify((input as any)[currentValues]) !==
          JSON.stringify((ouput as any)[currentValues])
      ) {
        return {
          ...prevValue,
          [currentValues]: (ouput as any)[currentValues],
        };
      }
      return prevValue;
    }
    if (
      (input as any).hasOwnProperty(currentValues) &&
      (input as any)[currentValues] !== (ouput as any)[currentValues]
    ) {
      return {
        ...prevValue,
        [currentValues]: (ouput as any)[currentValues],
      };
    }
    return prevValue;
  }, {} as any);

  return res as O;
};

export const isObjectEmpty = (obj: any) => {
  const isValidObject = isObject(obj);

  if (isValidObject) {
    return Object.keys(obj).length === 0;
  }
  return false;
};

export function checkArray<T>(value: T) {
  if (Array.isArray(value)) {
    return value.length === 0 ? undefined : (value as T);
  }
  return undefined;
}
