import {
  Divider,
  Grid,
  GridItem,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ApiRequest, ApiRequestStatus } from "@operations-hero/lib-api-client";
import { compareAsc } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DateBadge } from "../../../components/badges/DateBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { StyledDatePicker } from "../../../components/inputs/StyledDatePicker";
import { RootState } from "../../../store";

interface TimeLineProps {
  handlePropertyChange: (
    delta: Partial<ApiRequest>,
    isChangeModal: boolean
  ) => void;
}

export const TimeLine: React.FC<TimeLineProps> = ({ handlePropertyChange }) => {
  const isDetailFolded = useBreakpointValue({ base: true, lg: false });
  const request = useSelector((state: RootState) => state.requestForm.request);
  const { isTechnician, isAdmin, isReviewer } = useSelector(
    (state: RootState) => state.localCache
  );
  const { isProductAdmin } = useAuthentication();

  const comments = useSelector(
    (state: RootState) => state.requestForm.comments
  );

  const compareDates = (firstDate: string, secondDate: string) => {
    const formatedFirstDate = new Date(firstDate);
    const formatedSecondDate = new Date(secondDate);
    const result = compareAsc(formatedFirstDate, formatedSecondDate);

    switch (result) {
      case 1:
        return firstDate;
      case -1:
        return secondDate;
      default:
        return firstDate;
    }
  };

  const lastUpdated = useMemo(() => {
    if (!request) {
      return null;
    }

    if (request.updated && comments.length > 0) {
      const greaterDate = compareDates(request.updated, comments[0].created);
      return greaterDate;
    }

    return request.updated || comments[0].created || null;
  }, [comments, request]);

  const handleCompletedDateChange = useCallback(
    (value: Date | null) => {
      if (request) {
        const newScheduling = {
          ...request.scheduling,
          completed: value === null ? null : value.toISOString(),
        };
        handlePropertyChange({ scheduling: newScheduling }, true);
      }
    },
    [handlePropertyChange, request]
  );

  const showCompletionDateField = useMemo(() => {
    if (!request || request.status !== ApiRequestStatus.review) return false;
    const response = isProductAdmin
      ? true
      : [isTechnician, isAdmin, isReviewer].some((value) => value === true);
    return response;
  }, [isAdmin, isProductAdmin, isReviewer, isTechnician, request]);

  const showCompleted = useMemo(() => {
    return (
      request &&
      [ApiRequestStatus.review, ApiRequestStatus.closed].includes(
        request.status
      ) &&
      request.scheduling.completed
    );
  }, [request]);

  return (
    <>
      <Heading size={isDetailFolded ? "md" : "sm"} mb={3} fontSize="18">
        Summary Request Timeline
      </Heading>

      {request && (
        <>
          {lastUpdated && (
            <Grid templateColumns={["repeat(2, 1fr)"]} pt={2} pb={2}>
              <GridItem colSpan={[1, null, 1]} pr={2}>
                {"Last Updated, Comment"}
              </GridItem>
              <GridItem colSpan={[1, null, 1]}>
                <DateBadge date={lastUpdated} showRelative={false} />
              </GridItem>
            </Grid>
          )}
          {request.lastUpdatedBy && (
            <Grid templateColumns={["repeat(2, 1fr)"]} pt={1} pb={1.5}>
              <GridItem colSpan={[1, null, 1]}>{"Last Updated By"}</GridItem>
              <GridItem colSpan={[1, null, 1]}>
                <UserBadge value={request.lastUpdatedBy} includeAvatar={true} />
              </GridItem>
            </Grid>
          )}
          {request.statusUpdated && (
            <Grid templateColumns={["repeat(2, 1fr)"]} pt={2} pb={2}>
              <GridItem colSpan={[1, null, 1]}>{"Status Changed"}</GridItem>
              <GridItem colSpan={[1, null, 1]}>
                <DateBadge date={request.statusUpdated} showRelative={false} />
              </GridItem>
            </Grid>
          )}
          {request.statusChangedBy && (
            <Grid templateColumns={["repeat(2, 1fr)"]} pt={1} pb={1.5}>
              <GridItem colSpan={[1, null, 1]}>{"Status Changed By"}</GridItem>
              <GridItem colSpan={[1, null, 1]}>
                <UserBadge
                  value={request.statusChangedBy}
                  includeAvatar={true}
                />
              </GridItem>
            </Grid>
          )}
          {showCompleted && request.scheduling.completed && (
            <Grid templateColumns={["repeat(2, 1fr)"]} pt={2} pb={2}>
              <GridItem colSpan={[1, null, 1]}>{"Completed"}</GridItem>
              <GridItem colSpan={[12, null, 1]} mt={[2, 2, 0, 0]}>
                {showCompletionDateField ? (
                  <StyledDatePicker
                    value={request.scheduling?.completed || null}
                    onChange={handleCompletedDateChange}
                    showTime={true}
                    name="request-start-date"
                  />
                ) : (
                  <DateBadge
                    date={request.scheduling.completed}
                    showRelative={false}
                  />
                )}
              </GridItem>
            </Grid>
          )}
          {(lastUpdated || request.statusUpdated || showCompleted) && (
            <Divider my={2} />
          )}

          <Grid templateColumns={["repeat(2, 1fr)"]} pt={2} pb={2}>
            <GridItem colSpan={[1, null, 1]}>{"Created"}</GridItem>
            <GridItem colSpan={[1, null, 1]}>
              <DateBadge date={request.created} showRelative={false} />
            </GridItem>
          </Grid>

          <Grid templateColumns={["repeat(2, 1fr)"]} pt={1} pb={1.5}>
            <GridItem colSpan={[1, null, 1]}>{"Created By"}</GridItem>
            <GridItem colSpan={[1, null, 1]}>
              <UserBadge value={request.createdBy} includeAvatar={true} />
            </GridItem>
          </Grid>

          <Grid templateColumns={["repeat(2, 1fr)"]} pt={0} pb={1}>
            <GridItem colSpan={[1, null, 1]}>{"Requested By"}</GridItem>
            <GridItem colSpan={[1, null, 1]}>
              <UserBadge value={request.requester} includeAvatar={true} />
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};
