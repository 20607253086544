import { RequestSettingsKeys } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export const useIsAllowManageAssetForTech = () => {
  const { accountSettings } = useSelector(
    (state: RootState) => state.localCache
  );

  const result = useMemo(() => {
    const isAllowManageAssetForTech =
      Boolean(
        accountSettings[RequestSettingsKeys.ALLOW_TECHNICIANS_TO_MANAGE_ASSETS]
      ) || false;

    return { isAllowManageAssetForTech };
  }, [accountSettings]);

  return result;
};
