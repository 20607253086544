import { SearchIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiVenueSupervisedByUser } from "@operations-hero/lib-api-client";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../store";
import {
  loadVenuesLevelNotification,
  unloadVenueLevelNotificationList,
  updateVenueLevelNotificationFilters,
} from "../../store/venue-level-notification.slice";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { Pager } from "../pager/Pager";
import { useProductSubscriptions } from "../shell/AppShell";
import { VenueLevelEmailSettingModal } from "./VenueLevelEmailSettingModal";

export const VenueLevelEmailSettings: FC = () => {
  const { hasEvents } = useProductSubscriptions();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { currentAccount, apiClient } = useAuthentication();

  const { venuesSupervisedByUser, filters, total } = useSelector(
    (state: RootState) => state.venueLevelNotificationList
  );
  const { page, pageSize } = filters;
  const searchColor = useColorModeValue("white", "");

  const [workingVenue, setWorkingVenue] =
    useState<ApiVenueSupervisedByUser | null>(null);

  const handleRowClick = useCallback(
    (workingVenue: ApiVenueSupervisedByUser) => {
      setWorkingVenue(workingVenue);
      onOpen();
    },
    [onOpen]
  );

  const handleOnPageChange = useCallback(
    (value: number) => {
      dispatch(updateVenueLevelNotificationFilters({ page: value }));
    },
    [dispatch]
  );

  const handleOnSearchChange = useCallback(
    (value: string) => {
      dispatch(updateVenueLevelNotificationFilters({ page: 1, search: value }));
    },
    [dispatch]
  );

  const debouncedSearchChange = debounce(handleOnSearchChange, 300);

  useEffect(() => {
    thunkDispatch(
      loadVenuesLevelNotification({ apiClient, accountId: currentAccount.id })
    );
  }, [apiClient, currentAccount.id, thunkDispatch, filters]);

  useEffect(() => {
    return () => {
      dispatch(unloadVenueLevelNotificationList());
    };
  }, [dispatch]);

  return venuesSupervisedByUser.length === 0 ? null : (
    <>
      {hasEvents && (
        <Flex flexDir="column" w="full">
          <Text fontSize="sm" fontWeight="bold">
            Venue level notification
          </Text>
          <Box my={2}>
            <InputGroup bgColor={searchColor}>
              <InputLeftElement children={<SearchIcon color="gray.300" />} />
              <Input
                type="text"
                size="md"
                placeholder="Search Venue"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  debouncedSearchChange(e.target.value)
                }
              />
            </InputGroup>
          </Box>
          {venuesSupervisedByUser.map((venue, index) => (
            <Box
              key={index}
              onClick={() => handleRowClick(venue)}
              cursor="pointer"
            >
              <Flex py={2}>
                <Box ml={6} minWidth={120}>
                  <Text>{venue.name}</Text>
                </Box>
                <Box display="flex" ml="auto" alignItems="center">
                  {venue.roles.map((role) => (
                    <Badge key={role} variant="solid" colorScheme="blue" mr={1}>
                      {role}
                    </Badge>
                  ))}
                </Box>
              </Flex>
              {index < venuesSupervisedByUser.length - 1 && <Divider />}
            </Box>
          ))}

          <Box mt="5">
            <Pager
              showDetails
              total={total}
              currentPage={page || 1}
              pageSize={pageSize || 1}
              onPageChange={handleOnPageChange}
            />
          </Box>
          {isOpen && (
            <VenueLevelEmailSettingModal
              isOpen={isOpen}
              onClose={onClose}
              workingVenue={workingVenue}
            />
          )}
        </Flex>
      )}
    </>
  );
};
