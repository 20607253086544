import { ApiRequestTaskBookDetail } from "@operations-hero/lib-api-client";
import { Document, pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { PdfViewerModal } from "../../../../../components/pdf-viewer-modal/PdfViewerModal";
import { PrintableTaskbook } from "./PrintableTaskbook";

export interface PrintTaskbookModalProps {
  taskbook: ApiRequestTaskBookDetail;
  isOpen: boolean;
  onClose: () => void;
}

export const PrintTaskbookModal = ({
  isOpen,
  onClose,
  taskbook,
}: PrintTaskbookModalProps) => {
  const [pdfDocument, setPdfDocument] = useState<React.ReactElement | null>(
    null
  );
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const generatePdf = async () => {
      const printableTaskbook = <PrintableTaskbook taskbook={taskbook} />;
      const pdfDoc = <Document>{printableTaskbook}</Document>;
      const blob = await pdf(pdfDoc).toBlob();
      const url = URL.createObjectURL(blob);

      setPdfDocument(pdfDoc);
      setPdfUrl(url);
    };

    generatePdf();
  }, [taskbook, isOpen]);

  return pdfDocument && isOpen ? (
    <PdfViewerModal
      isOpen={isOpen}
      onClose={onClose}
      pdfDocument={pdfDocument}
      downloadName={`HeroHQ_Taskbook_${taskbook.taskbook.name}.pdf`}
      pdfUrl={pdfUrl}
    />
  ) : null;
};
