import { Button } from "@chakra-ui/button";
import { GridItem } from "@chakra-ui/layout";
import { ApiInventoryItem } from "@operations-hero/lib-api-client";
import { useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { InventoryItemLocationSelectControl } from "../../../../components/form-helpers/InventoryItemLocationSelectControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { UserAutocompleteControl } from "../../../../components/form-helpers/UserAutocompleteControl";
import { FormikObserver } from "../../../../hooks/formikObserver";
import { ChangeQuantityFormValues } from "./ChangeQuantityForm";

interface MoveQuantityFormProps {
  workingItem: ApiInventoryItem;
  onClose: () => void;
}

export const MoveQuantityForm: FC<MoveQuantityFormProps> = ({
  workingItem,
  onClose,
}) => {
  const { values } = useFormikContext<ChangeQuantityFormValues>();

  const compareLocations = useCallback(
    (values: ChangeQuantityFormValues, setFieldValue: any) => {
      const locationFrom = values.locationFrom;
      const locationTo = values.locationTo;

      if (locationFrom !== null && locationTo !== null) {
        if (locationFrom.id === locationTo.id) {
          setFieldValue("locationTo", null);
        }
      }
    },
    [],
  );

  return (
    <>
      <FormikObserver cb={compareLocations} />
      <GridItem colSpan={6}>
        <UserAutocompleteControl
          value={values.requester}
          name="requester"
          label="Requester"
        />
      </GridItem>

      <GridItem colSpan={6}>
        <InventoryItemLocationSelectControl
          name="locationFrom"
          value={values.locationFrom}
          label="Storage Location From"
          inventoryItemId={workingItem?.id}
          showQuantity
        />
      </GridItem>

      <GridItem colSpan={6}>
        <InventoryItemLocationSelectControl
          name="locationTo"
          value={values.locationTo}
          label="Storage Location To"
          inventoryItemId={workingItem?.id}
          showQuantity
          selectedLocations={
            values.locationFrom ? [values.locationFrom] : undefined
          }
          catalogId={workingItem.catalog.id}
        />
      </GridItem>

      <GridItem colSpan={3}>
        <NumberInputControl
          min={0}
          name="quantity"
          label="Move Quantity"
          value={values.quantity}
        />
      </GridItem>

      <GridItem colSpan={6}>
        <TextEditorControl value={values.notes} name="notes" label="Notes" />
      </GridItem>

      <GridItem colSpan={6} display="flex" justifyContent="space-between">
        <Button
          size="sm"
          variant="outline"
          colorScheme="blue"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button size="sm" colorScheme="blue" type="submit">
          Submit Change
        </Button>
      </GridItem>
    </>
  );
};
