import {
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import FuzzySearch from "fuzzy-search";
import { useMemo } from "react";
import { MdAdd } from "react-icons/md";
import Select, { SelectRenderer } from "react-dropdown-select";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

export interface filterOptionProps {
  id: string;
  value: string;
}

export interface FiltersSelectorProps {
  value: filterOptionProps[];
  options: string[];
  onChange: (value: string[]) => void;
}

const ContentRenderer = ({ state }: SelectRenderer<filterOptionProps>) => {
  return (
    <Box p={1}>
      <Text>More </Text>
    </Box>
  );
};

const dropdownHandleRenderer = () => {
  return <Icon as={MdAdd} mt={1} />;
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<filterOptionProps>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["value"], {
        sort: true,
      }),
    [props.options]
  );

  const items = useMemo(() => {
    return !state.search ? props.options : fuzzySearch.search(state.search);
  }, [state.search, props.options, fuzzySearch]);

  return (
    <Box p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Filter"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.id}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{capitalizeFirstLetter(item.value)}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const FiltersSelector = ({
  value,
  onChange,
  options,
}: FiltersSelectorProps) => {
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const formatedOptions = useMemo(() => {
    return options.map((opt) => ({
      id: opt,
      value: opt,
    }));
  }, [options]);

  return (
    <>
      <Select
        multi
        options={formatedOptions}
        className={themeClass}
        values={value}
        searchable={true}
        valueField="id"
        labelField="value"
        dropdownGap={0}
        // clearable
        dropdownHeight="350px"
        contentRenderer={ContentRenderer}
        dropdownRenderer={DropdownRenderer}
        dropdownHandleRenderer={dropdownHandleRenderer}
        onChange={(values) => onChange(values.map((item) => item.value))}
      />
    </>
  );
};
