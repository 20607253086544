export interface HaveName {
  name: string;
}
export function sortArrayByDesc<T extends HaveName>(a: T, b: T) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA > nameB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  return 0;
}
