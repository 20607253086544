import {
  Badge,
  Box,
  Divider,
  HStack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiRequestNotification,
  EmailStatus,
} from "@operations-hero/lib-api-client";
import { UserBadge } from "../../components/badges/UserBadge";
import { DEFAULT_LOCALE_DATE_OPTS, LocaleDate } from "../dates/LocaleDate";

interface NotificationsHistoryProps {
  notifications: ApiRequestNotification[];
}

export function NotificationsHistory(props: NotificationsHistoryProps) {
  const { notifications } = props;

  const isDesktop = useBreakpointValue({
    base: false,
    sm: true,
    md: true,
    lg: true,
  });
  const textColor = useColorModeValue("500", "300");
  const colors = {
    [EmailStatus.failed]: "red",
    [EmailStatus.pending]: "yellow",
    [EmailStatus.delivered]: "green",
  };

  return (
    <VStack w="100%">
      {notifications.length > 0 && isDesktop && (
        <HStack width="100%" fontWeight="bold">
          <Text width="20%" textAlign="left">
            User
          </Text>
          <Text width="15%" textAlign="left">
            Date
          </Text>
          <Text width="25%" textAlign="left">
            Subject
          </Text>
          <Text width="10%" textAlign="center">
            Status
          </Text>
          <Text width="10%" textAlign="center">
            Opens
          </Text>
          <Text width="10%" textAlign="center">
            Clicks
          </Text>
          <Text width="10%" textAlign="center">
            Type
          </Text>
        </HStack>
      )}
      {notifications.map((notification) =>
        isDesktop ? (
          <HStack width="100%" key={`requestNotification:${notification.id}`}>
            <Box width="20%">
              <UserBadge value={notification.user} />
            </Box>
            <Text width="15%" textAlign="left">
              <LocaleDate
                date={notification.created}
                options={{ ...DEFAULT_LOCALE_DATE_OPTS, weekday: undefined }}
              />
            </Text>
            <Text width="25%" textAlign="left">
              {notification.subject}
            </Text>
            <Badge
              width="10%"
              textAlign="center"
              variant="outline"
              colorScheme={colors[notification.delivered]}
              color={`${colors[notification.delivered]}.${textColor}`}
              borderColor={`${colors[notification.delivered]}.${textColor}`}
            >
              {notification.delivered}
            </Badge>
            <Text width="10%" textAlign="center">
              {notification.opens}
            </Text>
            <Text width="10%" textAlign="center">
              {notification.clicks}
            </Text>
            <Text width="10%" textAlign="center">
              {notification.type}
            </Text>
          </HStack>
        ) : (
          <VStack
            width="100%"
            fontWeight="semibold"
            gap={2}
            key={`requestNotification:${notification.id}`}
          >
            <HStack width="100%" justifyContent="space-between">
              <Box>
                <UserBadge value={notification.user} />
              </Box>
              <LocaleDate
                date={notification.created}
                options={{ ...DEFAULT_LOCALE_DATE_OPTS, weekday: undefined }}
              />
            </HStack>
            <HStack width="100%" justifyContent="space-between">
              <Text>{notification.subject}</Text>
              <Badge
                textAlign="center"
                variant="outline"
                colorScheme={colors[notification.delivered]}
                color={`${colors[notification.delivered]}.${textColor}`}
                borderColor={`${colors[notification.delivered]}.${textColor}`}
              >
                {notification.delivered}
              </Badge>
            </HStack>
            <HStack width="100%" justifyContent="space-between">
              <Text width="30%" textAlign="left">
                {notification.opens}
              </Text>
              <Text width="30%" textAlign="right">
                {notification.clicks}
              </Text>
            </HStack>
            <HStack width="100%" justifyContent="space-between">
              <Text fontWeight="bold">Type:</Text>
              <Text width="30%" textAlign="right">
                {notification.type}
              </Text>
            </HStack>
            <Divider />
          </VStack>
        )
      )}
    </VStack>
  );
}
