import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ApiRequest, ApiRequestStatus } from "@operations-hero/lib-api-client";
import { useCallback } from "react";
import { StatusBadge } from "../../components/badges/StatusBadge";
import { RequestTransition } from "../../store/request-form/request-form.slice";

export interface StatusChangeButtonsProps {
  onStatusChange: (status: ApiRequestStatus) => void;
  request: ApiRequest | null;
  transition: RequestTransition | null;
  showHoldButton?: boolean;
  canReopen: boolean;
}

export const StatusChangeButtons = ({
  onStatusChange,
  request,
  transition,
  showHoldButton = true,
  canReopen = false,
}: StatusChangeButtonsProps) => {
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });

  const holdOnClick = useCallback(() => {
    onStatusChange(ApiRequestStatus.hold);
  }, [onStatusChange]);

  const statusClickFactory = useCallback(
    (s: ApiRequestStatus) => () => {
      onStatusChange(s);
    },
    [onStatusChange]
  );

  const handlReopen = useCallback(() => {
    if (!canReopen) {
      return;
    }

    onStatusChange(ApiRequestStatus.queued);
  }, [canReopen, onStatusChange]);

  return (
    <Box display="inline-block">
      {transition && request ? (
        <>
          <ButtonGroup
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {request.status !== ApiRequestStatus.hold &&
              request.status !== ApiRequestStatus.closed &&
              showHoldButton && (
                <Button
                  onClick={holdOnClick}
                  mr={1}
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                >
                  <Text>Hold</Text>
                </Button>
              )}

            {transition.next && (
              <Button
                onClick={statusClickFactory(transition.next)}
                colorScheme={"blue"}
                size={!showHoldButton && isTablet ? "xs" : "sm"}
              >
                <Text as={"span"} pr={2}>
                  Change to
                </Text>
                <StatusBadge status={transition.next} colorScheme="white" />
              </Button>
            )}
            {transition.forwards.length > 0 ||
            transition.backwards.length > 0 ||
            transition.canCancel ? (
              /* wrap menu in box or flex; issue: https://github.com/chakra-ui/chakra-ui/issues/3173 */
              <Box>
                <Menu closeOnSelect={true}>
                  <MenuButton
                    as={Button}
                    size={!showHoldButton && isTablet ? "xs" : "sm"}
                    border={"solid 1px"}
                    colorScheme={"blue"}
                  >
                    <ChevronDownIcon />
                  </MenuButton>
                  <MenuList>
                    {transition.forwards.length > 0 && (
                      <MenuGroup textAlign="left" title="Forwards">
                        {transition.forwards.map((s) => (
                          <MenuItem
                            key={`next-status-${s}`}
                            onClick={statusClickFactory(s)}
                          >
                            <Text as={"span"} mr={2}>
                              Change to
                            </Text>{" "}
                            <StatusBadge status={s} />
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    )}
                    {transition.forwards.length > 0 &&
                      transition.backwards.length > 0 && <MenuDivider />}
                    {transition.backwards.length > 0 && (
                      <MenuGroup textAlign="left" title="Backwards">
                        {transition.backwards.map((s) => (
                          <MenuItem
                            key={`next-status-${s}`}
                            onClick={statusClickFactory(s)}
                            py={2}
                          >
                            <Text as={"span"} mr={2}>
                              Change to
                            </Text>{" "}
                            <StatusBadge status={s} />
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    )}
                    {request.status !== ApiRequestStatus.hold &&
                      request.status !== ApiRequestStatus.closed &&
                      !showHoldButton && (
                        <>
                          <MenuDivider />
                          <MenuItem
                            key={`next-status-hold`}
                            onClick={statusClickFactory(ApiRequestStatus.hold)}
                            py={2}
                          >
                            <Text as={"span"} mr={2}>
                              Change to
                            </Text>{" "}
                            <StatusBadge status={ApiRequestStatus.hold} />
                          </MenuItem>
                        </>
                      )}
                    {transition.canCancel && (
                      <>
                        <MenuDivider />
                        <MenuItem
                          key={`next-status-canceled`}
                          onClick={statusClickFactory(
                            ApiRequestStatus.canceled
                          )}
                          py={2}
                        >
                          <Text as={"span"} mr={2}>
                            Change to
                          </Text>{" "}
                          <StatusBadge status={ApiRequestStatus.canceled} />
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </Menu>
              </Box>
            ) : null}
          </ButtonGroup>
        </>
      ) : null}
      {canReopen && <Button onClick={handlReopen}>Reopen Request</Button>}
    </Box>
  );
};
