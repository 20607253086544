import {
  ApiComment,
  ApiLaborTransaction,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import { useChangeStatus } from "../../../hooks/useChangeStatus";
import { RootState } from "../../../store/index";
import { ChangeModal } from "../../request-form/ChangeModal";

export type ChangeStatusModalProps = {
  onChangeModalSave?: () => void;
  onChangeModalCancel?: () => void;
};

export const ChangeStatusModal: FC<ChangeStatusModalProps> = ({
  onChangeModalSave,
  onChangeModalCancel,
}) => {
  const { changeModal, taskBooksCompleted, request } = useSelector(
    (state: RootState) => state.requestForm
  );

  const { handleChangeModalSave, handleChangeModalCancel } = useChangeStatus();

  const handleSave = useCallback(
    ({
      delta,
      comment,
      laborTransaction,
    }: {
      delta: Partial<ApiRequest>;
      comment?: ApiComment;
      laborTransaction?: ApiLaborTransaction;
    }) => {
      handleChangeModalSave({ delta, comment, laborTransaction })?.then(() => {
        if (onChangeModalSave) onChangeModalSave();
      });
    },
    [handleChangeModalSave, onChangeModalSave]
  );

  const handleCancel = useCallback(() => {
    handleChangeModalCancel();
    if (onChangeModalCancel) onChangeModalCancel();
  }, [handleChangeModalCancel, onChangeModalCancel]);

  return taskBooksCompleted &&
    request &&
    (changeModal.changeVerification !== null || changeModal.delta !== null) ? (
    <ChangeModal
      isOpen={
        taskBooksCompleted &&
        (changeModal.changeVerification !== null || changeModal.delta !== null)
      }
      onSave={handleSave}
      onCancel={handleCancel}
      modalProps={{ motionPreset: "none" }}
    />
  ) : null;
};
