import {
  ApiRequestReminder,
  ApiUserSummary,
  CreateApiRequestReminder,
  UpdateApiRequestReminder,
} from "@operations-hero/lib-api-client";
import * as yup from "yup";
import { getChangedPropsFromObject } from "../../../utils/compareObjects";
import { updateTimes } from "../../../utils/updateTimes";

export interface ReminderFormValues {
  title: string;
  date: Date | null;
  time: Date | null;
  notes: string | null;
  users?: ApiUserSummary[];
}

export const initialValues: ReminderFormValues = {
  date: null,
  time: null,
  title: "",
  notes: null,
  users: [],
};

export const reminderSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  date: yup.string().nullable().required("Date is required"),
  time: yup.string().nullable().required("Time is required"),
  users: yup.array().min(1, "At least one user is required"),
});

export const getReminderInitialValues = (
  users: ApiUserSummary[],
  reminder?: ApiRequestReminder | null
) => {
  if (!reminder) return { ...initialValues, users };

  const reminderDate = new Date(reminder.date);
  const values: ReminderFormValues = {
    title: reminder.title,
    date: reminderDate,
    time: reminderDate,
    notes: reminder.notes,
    users: reminder.users.length === 0 ? users : reminder.users,
  };

  return values;
};

export const mapCreateReminderValues = (
  reminder: ReminderFormValues
): CreateApiRequestReminder => {
  const date = new Date(reminder.date || "");
  const time = new Date(reminder.time || "");
  const newDate = updateTimes(date, time);

  return {
    title: reminder.title,
    users: reminder.users,
    notes: reminder.notes || "",
    date: newDate.toISOString(),
  };
};

export const mapUpdateReminderValues = (
  initialValues: ReminderFormValues,
  reminder: ReminderFormValues
): UpdateApiRequestReminder => {
  const date = new Date(reminder.date || "");
  const time = new Date(reminder.time || "");
  const newDate = updateTimes(date, time);

  const input = mapCreateReminderValues(initialValues);
  const output = {
    title: reminder.title,
    users: reminder.users,
    notes: reminder.notes || "",
    date: newDate.toISOString(),
  };

  const updatedValues = getChangedPropsFromObject(input, output);
  return updatedValues;
};
