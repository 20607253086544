import { Menu, MenuList, Portal, useDisclosure } from "@chakra-ui/react";
import { ApiUser } from "@operations-hero/lib-api-client";
import { FC, useMemo } from "react";
import { useAuthentication } from "../../auth/AuthProvider";
import { useProductSubscriptions } from "../AppShell";
import { MegaMenu } from "./MegaMenu";
import { SmallMegaMenu } from "./MegaMenu.sm";
import { SwithAccountModal } from "./SwitchAccountModal";

export interface MenuType {
  route: string;
  text: string;
}

type AccountMenuProps = {
  children: (user: ApiUser) => React.ReactNode;
  mediaQueries: boolean[];
};
export const AccountMenu: FC<AccountMenuProps> = ({
  children,
  mediaQueries,
}) => {
  const [isMobile = true, isTablet = false, isDesktop = false] = mediaQueries;
  const {
    currentUser: user,
    isProductAdmin,
    isEventAdmin,
    isInventoryAdmin,
  } = useAuthentication();
  const { hasEvents, hasRequests, hasInventory } = useProductSubscriptions();

  const {
    isOpen: isOpenSwitchModal,
    onOpen: onOpenSwitchModal,
    onClose: onCloseSwitchModal,
  } = useDisclosure();

  const count = useMemo(() => {
    let count = 0;
    if (isProductAdmin && hasRequests) count++;
    if (isEventAdmin && hasEvents) count++;
    if (isInventoryAdmin && hasInventory) count++;
    return count;
  }, [
    hasEvents,
    hasRequests,
    isEventAdmin,
    isProductAdmin,
    isInventoryAdmin,
    hasInventory,
  ]);

  const MenuWidth = useMemo(() => {
    if (!isProductAdmin && !isEventAdmin && !isInventoryAdmin) {
      return "120px";
    }
    if (count >= 2 && isTablet) return "768px";

    if (count >= 2 && isDesktop) return "798px";

    return "500px";
  }, [
    isEventAdmin,
    isProductAdmin,
    isInventoryAdmin,
    count,
    isTablet,
    isDesktop,
  ]);
  return (
    <>
      <Menu
        closeOnBlur={true}
        offset={[0, 0]}
        closeOnSelect={true}
        placement="bottom-end"
      >
        {({ onClose, isOpen }) => (
          <>
            {children(user)}
            {isOpen && (
              <Portal>
                <MenuList
                  zIndex={10}
                  style={
                    isTablet ? { width: MenuWidth } : { minWidth: MenuWidth }
                  }
                  p={2}
                >
                  {!isMobile &&
                  (isProductAdmin || isEventAdmin || isInventoryAdmin) ? (
                    <MegaMenu
                      onOpenSwitchModal={onOpenSwitchModal}
                      mediaQueries={mediaQueries}
                    />
                  ) : (
                    <SmallMegaMenu
                      onOpenSwitchModal={onOpenSwitchModal}
                      onProductItemSelected={onClose}
                    />
                  )}
                </MenuList>
              </Portal>
            )}
          </>
        )}
      </Menu>
      {isOpenSwitchModal && (
        <SwithAccountModal
          isOpen={isOpenSwitchModal}
          onClose={onCloseSwitchModal}
        />
      )}
    </>
  );
};
