import { Box } from "@chakra-ui/react";
import { FC } from "react";

export interface NumberCircleProps {
  stepNumber: number;
}

export const NumberCircle: FC<NumberCircleProps> = ({ stepNumber }) => {
  return (
    <Box
      border="1px solid"
      borderColor="blue.500"
      color="blue.500"
      borderRadius="50%"
      width="27px"
      height="25px"
      textAlign="center"
    >
      {`${stepNumber}`}
    </Box>
  );
};
