import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearFilters, updateFilters } from "./funding-source-list.slice";

type FundingSourcesPageProps = {
  queryStringFilter: string;
};

const fundingSourcesPageSlice = createSlice({
  name: "funding-sources-page",
  initialState: {
    queryStringFilter: "",
  } as FundingSourcesPageProps,
  reducers: {
    unload: (state) => {
      state.queryStringFilter = "";
    },
    updateQueryStringFilter: (
      state,
      action: PayloadAction<FundingSourcesPageProps["queryStringFilter"]>
    ) => {
      state.queryStringFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      const filters = { ...action.payload };
      const generatedString = btoa(JSON.stringify(filters));
      state.queryStringFilter = generatedString;
    });
    builder.addCase(clearFilters.fulfilled, (state, action) => {
      state.queryStringFilter = "";
    });
  },
});

export const { updateQueryStringFilter, unload } =
  fundingSourcesPageSlice.actions;
export default fundingSourcesPageSlice.reducer;
