import {
  ApiClient,
  ApiProject,
  CreateApiProject,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { clearFilters, updateFilters } from "./project-list";

type CreateProjectParams = {
  project: CreateApiProject;
  apiClient: ApiClient;
};

type DeactivateProjectParams = {
  project: ApiProject;
  apiClient: ApiClient;
};

export const createProject = createAsyncThunk<ApiProject, CreateProjectParams>(
  "project/create",
  async ({ project, apiClient }, thunkAPI) => {
    const { currentAccount } = (thunkAPI.getState() as RootState).auth;
    const newProject = await apiClient.createProject(
      currentAccount.id,
      project
    );
    return newProject;
  }
);

export const deactivateProject = createAsyncThunk<
  { project: ApiProject; deactivated: boolean },
  DeactivateProjectParams
>("project/deactivate", async ({ project, apiClient }, thunkAPI) => {
  const { currentAccount } = (thunkAPI.getState() as RootState).auth;
  const deactivated = await apiClient.deactivateProject(
    currentAccount.id,
    project.id
  );
  return { project, deactivated };
});

type ProjectsPageProps = {
  queryStringFilter: string;
};

const projectsPageSlice = createSlice({
  name: "projects-page",
  initialState: {
    queryStringFilter: "",
  } as ProjectsPageProps,
  reducers: {
    unload: (state) => {
      state.queryStringFilter = "";
    },
    updateQueryStringFilter: (
      state,
      action: PayloadAction<ProjectsPageProps["queryStringFilter"]>
    ) => {
      state.queryStringFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      const filters = action.payload;
      const generatedString = btoa(JSON.stringify(filters));
      state.queryStringFilter = generatedString;
    });
    builder.addCase(clearFilters.fulfilled, (state, action) => {
      state.queryStringFilter = "";
    });
  },
});

export const { updateQueryStringFilter, unload } = projectsPageSlice.actions;
export default projectsPageSlice.reducer;
