export function styleClicked() {
  const style = {
    bgColor: "blue.500",
    color: "white",
    borderRadius: "50",
    height: "30",
    padding: "0 12px",
    _hover: {
      background: "blue.900",
      color: "white",
    },
  };
  return style;
}

export function styleNotClicked(buttonColor: string, buttonBgColor: string) {
  const style = {
    color: buttonColor,
    bgColor: buttonBgColor,
    borderRadius: "50",
    height: "30",
    border: "1px",
    borderColor: "gray.200",
    padding: "0 12px",
  };
  return style;
}
