export const getMoneyNotation = (value: any) => {
  const valueAsNumber = Number(value);
  const isValidNumber = !isNaN(valueAsNumber);
  const valueToParse = isValidNumber ? valueAsNumber : 0;
  return valueToParse.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  });
};

export const getHourAndMinutesNotation = (value: number) => {
  if (value <= 0) return "0m";
  const valueInMinutes = Math.round(value * 60);
  const days = Math.floor(valueInMinutes / (24 * 60));
  const rest = valueInMinutes - days * 24 * 60;
  const hours = Math.floor(rest / 60) + days * 24;
  const minutes = rest % 60;
  if (!hours) {
    return `${minutes}m`;
  } else if (!minutes) {
    return `${hours}h`;
  } else {
    return `${hours}h ${minutes}m`;
  }
};
