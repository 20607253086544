import { Alert, AlertIcon, Button, Grid, GridItem } from "@chakra-ui/react";
import {
  ApiAccountUser,
  ApiUser,
  ApiUserSummary,
  CreateApiPortalUser,
  UpdateApiPortalUser,
} from "@operations-hero/lib-api-client";
import { Formik, FormikErrors } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState } from "../../../../store";
import { isObjectEmpty } from "../../../../utils/compareObjects";

interface FormValuesProps {
  firstName: string;
  lastName: string;
  email: string;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("User email must be a valid email")
    .required("Email is required"),
});

interface QuickEventUserFormProps {
  user?: ApiAccountUser | ApiUserSummary;
  onCreate?: (response: ApiUser) => void;
  onCancel?: () => void;
}

export const QuickEventUserForm: FC<QuickEventUserFormProps> = ({
  user,
  onCreate,
  onCancel,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { isEnablePublicPortal } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );
  const showToast = useShowToast();

  const initialValues = useMemo(
    () => ({
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      email: user?.email ?? "",
    }),
    [user]
  );

  const handleOnSubmitUserPortal = useCallback(
    async (
      values: FormValuesProps,
      validateForm: (values?: any) => Promise<FormikErrors<FormValuesProps>>,
      submitForm: () => Promise<void>
    ) => {
      const validationResult = await validateForm();
      if (!isObjectEmpty(validationResult)) {
        submitForm();
        return;
      }
      const payload: CreateApiPortalUser | UpdateApiPortalUser = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };

      user?.id
        ? apiClient
            .updatePortalUser(currentAccount.id, user.id, payload)
            .then((user) => {
              showToast("success", "User updated successfully");
              onCreate && onCreate(user as ApiUser);
              onCancel && onCancel();
            })
            .catch(() => {
              showToast(
                "error",
                "Something went wrong while updating the user"
              );
            })
        : apiClient
            .createPortalUser(currentAccount.id, payload as CreateApiPortalUser)
            .then((user) => {
              showToast("success", "User added successfully");
              onCreate && onCreate(user as ApiUser);
              onCancel && onCancel();
            })
            .catch(() => {
              showToast("error", "Something went wrong adding the user");
            });
    },
    [apiClient, currentAccount.id, showToast, onCreate, onCancel, user?.id]
  );

  return (
    <>
      {isEnablePublicPortal ? (
        <Alert status="info">
          <AlertIcon />
          This user will be invited to join via the Portal and will not receive
          emails until their email is verified.
        </Alert>
      ) : (
        <Alert status="warning">
          <AlertIcon />
          This user will not receive emails until they confirm their email.
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ values, validateForm, submitForm }) => (
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem colSpan={6}>
              <TextInputControl
                value={values.firstName}
                name="firstName"
                label="First Name"
              />
            </GridItem>
            <GridItem colSpan={6}>
              <TextInputControl
                value={values.lastName}
                name="lastName"
                label="Last Name"
              />
            </GridItem>
            <GridItem colSpan={6}>
              <TextInputControl
                value={values.email}
                name="email"
                label="Email"
              />
            </GridItem>
            <GridItem colSpan={6} display="flex" justifyContent="space-between">
              <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={() => {
                  onCancel && onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() =>
                  handleOnSubmitUserPortal(values, validateForm, submitForm)
                }
              >
                {user?.id ? "Update Portal User" : "Add New User"}
              </Button>
            </GridItem>
          </Grid>
        )}
      </Formik>
    </>
  );
};
