import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ApiRateGroup } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { RateGroupAutocomplete } from "../selects/RateGroupAutocomplete";

export interface RateGroupAutocompleteControlProps {
  name: string;
  label?: string;
  isDisabled?: boolean;
  cleanValueName?: string;
  value: ApiRateGroup | null;
  isClearable?: boolean;
}

export const RateGroupAutocompleteControl = ({
  name,
  value,
  label,
  isDisabled,
  cleanValueName,
  isClearable,
}: RateGroupAutocompleteControlProps) => {
  const { submitCount, setFieldValue } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (rateGroup: ApiRateGroup | null) => {
      helper.setTouched(true);
      helper.setValue(rateGroup);
      cleanValueName && setFieldValue(cleanValueName, [], false);
    },
    [cleanValueName, helper, setFieldValue]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <RateGroupAutocomplete
        {...field}
        onChange={handleOnChange}
        name={name}
        isDisabled={isDisabled}
        isClearable={isClearable}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
