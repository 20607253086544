import {
  ApiClient,
  ApiComment,
  UpdateApiComment,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";

type UpdateCommentParams = {
  apiClient: ApiClient;
  projectId: string;
  commentId: string;
  delta: UpdateApiComment;
};
export const updateComment = createAsyncThunk<ApiComment, UpdateCommentParams>(
  "project-commments/update",
  async ({ apiClient, projectId, commentId, delta }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState;
    const { currentAccount } = store.auth;
    const updatedComment = await apiClient.updateProjectComment(
      currentAccount.id,
      projectId,
      commentId,
      delta
    );

    return updatedComment;
  }
);

export type UpdateCommentsHandlerProps = {
  comments: ApiComment[];
};

export const updateCommentHandlers = <T extends UpdateCommentsHandlerProps>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(updateComment.fulfilled, (state, action) => {
    const updated = action.payload;
    const comments = [...state.comments];
    const updatedIdx = comments.findIndex((c) => c.id === updated.id);
    state.comments[updatedIdx] = updated;
  });
};
