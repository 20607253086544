import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiEventGroup,
  ApiInvoiceMetadataPropType,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../../components/badges/AdditionalNumberBadge";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  initEventGroupFilter,
  loadEventGroupFilter,
} from "../../../../store/events/thunks/filterEventList.thunk";

export interface EventGroupFilterProps {
  value: (ApiEventGroup | string)[];
  onChange: (value: ApiEventGroup[]) => void;
  includeUndefinedProp?: boolean;
  undefinedPropLabel?: string;
}

const ContentRenderer = ({ state }: SelectRenderer<ApiEventGroup>) => {
  return (
    <Box p={1} w="max-content">
      {state.values.length === 0 && "Event Group"}
      {state.values.length === 1 && state.values[0].name}
      {state.values.length > 1 && (
        <>
          <Text mr={1} as="span">
            {state.values[0].name}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiEventGroup>) => {
  const { _undefined } = ApiInvoiceMetadataPropType;
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["name"], {
        sort: true,
      }),
    [props.options]
  );
  const { eventGroups: selectedValues } = useSelector(
    (state: RootState) => state.eventList.filters
  );
  const items = useMemo(() => {
    const results = fuzzySearch.search(state.search);
    const selected = state.values;
    selected.filter((sel) =>
      results.find((res) => res.id === sel.id) ? null : results.push(sel)
    );
    return !state.search ? props.options : results;
  }, [state.search, state.values, props.options, fuzzySearch]);

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Box>Search and select:</Box>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search eventGroups"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.id}
              isChecked={
                methods.isSelected(item) ||
                selectedValues.some((sel) => sel === item.id)
              }
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
              fontWeight={item.id === _undefined ? "bold" : "normal"}
              fontStyle={item.id === _undefined ? "italic" : "normal"}
            >
              {item.name}
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const EventGroupFilter = ({
  value,
  onChange,
  includeUndefinedProp = false,
  undefinedPropLabel = ApiInvoiceMetadataPropType._undefined,
}: EventGroupFilterProps) => {
  const {
    currentAccount: account,
    apiClient,
    currentUser,
  } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const { eventGroups: selectedValues } = useSelector(
    (state: RootState) => state.eventList.filters
  );
  const [search, setSearch] = useState<string>("");
  const [filterInitCompleted, setFilterInitCompleted] = useState(false);
  const initOnce = useRef(false);

  const eventGroupFilterOptions = useSelector(
    (state: RootState) => state.eventList.eventGroups
  );

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const getEventGroupOptions = useMemo(() => {
    if (!includeUndefinedProp) return eventGroupFilterOptions;
    return [
      {
        id: ApiInvoiceMetadataPropType._undefined,
        name: undefinedPropLabel,
      } as ApiEventGroup,
      ...eventGroupFilterOptions,
    ];
  }, [eventGroupFilterOptions, includeUndefinedProp, undefinedPropLabel]);

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiEventGroup>) => {
      if (!state.search) {
        setSearch("");
      }
      setSearch(state.search);

      return props.options;
    },
    []
  );

  useEffect(() => {
    if (initOnce.current) {
      return;
    }
    initOnce.current = true;

    const promise =
      !selectedValues || selectedValues.length === 0
        ? Promise.resolve()
        : thunkDispatch(
            initEventGroupFilter({
              apiClient,
              account,
              selectedValues,
            })
          );

    promise.then(() => setFilterInitCompleted(true));
  }, [apiClient, account, thunkDispatch, selectedValues]);

  useEffect(() => {
    if (!filterInitCompleted) {
      return;
    }
    thunkDispatch(
      loadEventGroupFilter({
        apiClient,
        account,
        search,
        currentUser,
      })
    );
  }, [
    apiClient,
    account,
    thunkDispatch,
    filterInitCompleted,
    search,
    currentUser,
  ]);

  return filterInitCompleted ? (
    <Select
      multi
      className={themeClass}
      options={getEventGroupOptions}
      values={getEventGroupOptions.filter((eventGroup) =>
        value.some((val) => val === eventGroup.id)
      )}
      onChange={onChange}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      clearOnSelect={false}
      clearable={false}
      contentRenderer={ContentRenderer}
      dropdownRenderer={DropdownRenderer}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  ) : null;
};
