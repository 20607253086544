import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudget,
  ApiTransactionType,
  ApiVendor,
  ApiWorkflow,
  LaborType,
  PurchaseType,
} from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { RiCalendar2Fill, RiFilterOffLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { VendorFilter } from "../../components/filters/VendorsFilter";
import { AccountSearchBox } from "../../components/inputs/SearchBox";
import { RootState, useThunkDispatch } from "../../store";
import {
  AbsoluteDateFilter,
  cleanAllFilters,
  DateFields,
  loadTransactions,
  RelativeDateFilter,
  RelativeDateOptions,
  setDateField,
  setEnableSearch,
  setInitActive,
  setIsCustomDate,
  setSortDirection,
  setSortField,
  updateTransactionFilters,
} from "../../store/transaction-list.slice";
import { debounce } from "../../utils/debounce";
import { WorkflowFilter } from "./../requests/filters/WorkflowFilter";
import { RequestFiltersProps } from "./../requests/Requests";
import {
  PersonFilter,
  PersonFilterReferenceValue,
} from "./filters/CreatedByFilter";
import { MenuCalendar } from "./filters/RangeDates";
import { TransactionsBudgetFilter } from "./filters/TransactionsBudgetFilter";
import { TypeFilter } from "./filters/TypeFilter";
import { TypeFilterLabor } from "./filters/TypeFilterLabor";
import { TypeFilterPurchase } from "./filters/TypeFilterPurchase";

export const TransactionAdvancedFilters = ({
  showFilterAndSort,
}: RequestFiltersProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = !isMobileMode && !isTablet ? true : false;
  const { dateField, searchEnabled, initActive, initCompleted } = useSelector(
    (state: RootState) => state.transactionList
  );
  const {
    persons,
    budgets,
    workflows,
    purchaseType,
    laborType,
    type,
    date,
    vendor,
  } = useSelector((state: RootState) => state.transactionList.filters);

  const { direction: sortDirection, field: sortField } = useSelector(
    (state: RootState) => state.transactionList.sort
  );

  const bgColor = useColorModeValue("white", "whiteAlpha.300");
  const bgColorBox = useColorModeValue("blue.50", "blue.900");

  const [dateRange, setDateRange] = useState("");

  const refreshList = useCallback(() => {
    thunkDispatch(loadTransactions({ apiClient, account: currentAccount }));
  }, [thunkDispatch, apiClient, currentAccount]);

  const handlePersonFilterChange = useCallback(
    (value: PersonFilterReferenceValue[]) => {
      if ((!searchEnabled && value.length > 0) || !initActive) {
        dispatch(updateTransactionFilters({ persons: value || [] }));
      }
      dispatch(setInitActive(false));
      dispatch(setEnableSearch(false));
    },
    [dispatch, initActive, searchEnabled]
  );

  const handleSortDirectionChange = useCallback(
    (value: string | string[]) => {
      const single = Array.isArray(value) ? value[0] : value;
      const direction = single === "asc" ? "asc" : "desc";
      dispatch(setSortDirection(direction));
      refreshList();
    },
    [dispatch, refreshList]
  );

  const handleSetDateField = useCallback(
    (value: string | string[]) => {
      const dateField = Array.isArray(value) ? value[0] : value;
      dispatch(setDateField(dateField as DateFields));
      if (date) {
        const updatedDate = {
          field: dateField as DateFields,
          type: date.type,
          value: date.value,
        };
        const parsedDate =
          date.type === "relative"
            ? (updatedDate as RelativeDateFilter)
            : (updatedDate as AbsoluteDateFilter);
        dispatch(updateTransactionFilters({ date: parsedDate }));
      }
    },
    [dispatch, date]
  );

  const handleIsCustomChange = useCallback(
    (value: boolean) => {
      const custom = Array.isArray(value) ? value[0] : value;
      dispatch(setIsCustomDate(custom));
    },
    [dispatch]
  );

  const handleDateRelativeChange = useCallback(
    (value: string | string[]) => {
      const relative = Array.isArray(value) ? value[0] : value;
      setDateRange(relative);
      if (relative === "custom") {
        handleIsCustomChange(true);
      } else {
        handleIsCustomChange(false);
        if (dateField) {
          const relativeDate: RelativeDateFilter = {
            field: dateField,
            type: "relative",
            value: relative as RelativeDateOptions,
          };
          dispatch(updateTransactionFilters({ date: relativeDate }));
        }
        if (date) {
          const relativeDate: RelativeDateFilter = {
            field: date.field,
            type: "relative",
            value: relative as RelativeDateOptions,
          };
          dispatch(updateTransactionFilters({ date: relativeDate }));
        }
      }
    },
    [handleIsCustomChange, dateField, date, dispatch]
  );

  const handleSortFieldChange = useCallback(
    (value: string | string[]) => {
      const field = Array.isArray(value) ? value[0] : value;
      dispatch(setSortField(field));
      refreshList();
    },
    [dispatch, refreshList]
  );

  const handleTypeFilterChange = useCallback(
    (value: ApiTransactionType[]) => {
      if ((!searchEnabled && value.length > 0) || !initActive) {
        dispatch(updateTransactionFilters({ type: value }));
      }
      dispatch(setInitActive(false));
      dispatch(setEnableSearch(false));
    },
    [dispatch, initActive, searchEnabled]
  );

  const handleBudgetFilterChange = useCallback(
    (value: ApiBudget[]) => {
      if ((!searchEnabled && value.length > 0) || !initActive) {
        dispatch(
          updateTransactionFilters({
            budgets: value ? value.map((l) => l.id) : [],
          })
        );
      }
      dispatch(setInitActive(false));
      dispatch(setEnableSearch(false));
    },
    [dispatch, initActive, searchEnabled]
  );

  const handleWorkflowFilterChange = useCallback(
    (value: ApiWorkflow[]) => {
      if ((!searchEnabled && value.length > 0) || !initActive) {
        dispatch(
          updateTransactionFilters({
            workflows: value ? value.map((l) => l.id) : [],
          })
        );
      }
      dispatch(setInitActive(false));
      dispatch(setEnableSearch(false));
    },
    [dispatch, initActive, searchEnabled]
  );

  const handlePurchaseTypeFilterChange = useCallback(
    (value: PurchaseType[]) => {
      if ((!searchEnabled && value.length > 0) || !initActive) {
        dispatch(updateTransactionFilters({ purchaseType: value }));
      }
      dispatch(setInitActive(false));
      dispatch(setEnableSearch(false));
    },
    [dispatch, initActive, searchEnabled]
  );
  const handleLaborTypeFilterChange = useCallback(
    (value: LaborType[]) => {
      if ((!searchEnabled && value.length > 0) || !initActive) {
        dispatch(updateTransactionFilters({ laborType: value }));
      }
      dispatch(setInitActive(false));
      dispatch(setEnableSearch(false));
    },
    [dispatch, initActive, searchEnabled]
  );

  const handleVendorFilterChange = useCallback(
    (value: ApiVendor[]) => {
      if ((!searchEnabled && value.length > 0) || !initActive) {
        dispatch(
          updateTransactionFilters({
            vendor: value ? value.map((l) => l.id) : [],
          })
        );
      }
      dispatch(setInitActive(false));
      dispatch(setEnableSearch(false));
    },
    [dispatch, initActive, searchEnabled]
  );

  const setSearchValue = useCallback(
    (value: string) => {
      dispatch(updateTransactionFilters({ search: value }));
    },
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const debounceSearch = debounce(handleSearchChange, 300);

  const handleRefreshFilter = useCallback(() => {
    dispatch(cleanAllFilters());
  }, [dispatch]);

  useEffect(() => {
    if (date) {
      if (date.type === "absolute") {
        setDateRange("custom");
        dispatch(setIsCustomDate(true));
      } else {
        setDateRange(date.value);
      }
    } else {
      setDateRange("");
    }
  }, [date, dispatch]);

  const stateDateField = useMemo(
    () => (date ? date.field : dateField),
    [date, dateField]
  );
  const DateFilter = useMemo(() => {
    return (
      <Box>
        <Menu closeOnSelect={false} flip={false} strategy="fixed">
          <MenuButton
            as={IconButton}
            icon={<Icon as={RiCalendar2Fill} />}
            aria-label="Field"
            bgColor={bgColor}
            colorScheme="blue"
            variant="outline"
            size={isDesktop ? "md" : "sm"}
            id="menu-button-relative"
          />
          <MenuList zIndex={1}>
            <MenuOptionGroup
              title="Select Date Type"
              value={stateDateField}
              onChange={handleSetDateField}
              type="radio"
            >
              <MenuItemOption value="created">Created</MenuItemOption>
              <MenuItemOption value="updated">Updated</MenuItemOption>
              <MenuItemOption value="datePerformed">
                Date Performed
              </MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              title="Select Date Range"
              value={dateRange}
              onChange={handleDateRelativeChange}
              type="radio"
            >
              <MenuItemOption
                isDisabled={!stateDateField ? true : false}
                value="last7d"
                closeOnSelect
              >
                Last 7 days
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!stateDateField ? true : false}
                value="last30d"
                closeOnSelect
              >
                Last 30 days
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!stateDateField ? true : false}
                value="thisWeek"
                closeOnSelect
              >
                This week (Sun - Sat)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!stateDateField ? true : false}
                value="lastWeek"
                closeOnSelect
              >
                Last Week(Sun - Sat)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!stateDateField ? true : false}
                value="thisMonth"
                closeOnSelect
              >
                This Month (1st- EOM)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!stateDateField ? true : false}
                value="lastMonth"
                closeOnSelect
              >
                Last Month (1st- EOM)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!stateDateField ? true : false}
                value="custom"
              >
                Custom
              </MenuItemOption>

              <MenuCalendar />
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    );
  }, [
    bgColor,
    dateRange,
    handleDateRelativeChange,
    handleSetDateField,
    isDesktop,
    stateDateField,
  ]);

  const SortFilter = useMemo(
    () => (
      <Box>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={IconButton}
            icon={<Icon as={BiSortAlt2} />}
            aria-label="Sort"
            bgColor={bgColor}
            colorScheme="blue"
            variant="outline"
            size={isDesktop ? "md" : "sm"}
          />
          <MenuList>
            <MenuOptionGroup
              defaultValue="desc"
              title="Order"
              value={sortDirection}
              onChange={handleSortDirectionChange}
              type="radio"
            >
              <MenuItemOption value="asc">Ascending</MenuItemOption>
              <MenuItemOption value="desc">Descending</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              title="Field"
              value={sortField}
              onChange={handleSortFieldChange}
              type="radio"
            >
              <MenuItemOption value="created">Created</MenuItemOption>
              <MenuItemOption value="updated">Updated</MenuItemOption>
              <MenuItemOption value="datePerformed">
                Date Performed
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    ),
    [
      bgColor,
      handleSortDirectionChange,
      handleSortFieldChange,
      isDesktop,
      sortDirection,
      sortField,
    ]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return !initCompleted ? null : isDesktop ? (
    <Box bgColor={bgColorBox} p={3}>
      <HStack
        gap={2}
        width="100%"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <HStack wrap="wrap" spacing={0} gap={2} w="90%">
          <Box display="flex" minW="360px" bgColor={bgColor}>
            <AccountSearchBox
              onInputChange={debounceSearch}
              searchPlaceholder="Transaction #(Prefix/invoice)"
            />
          </Box>

          <Box bgColor={bgColor}>
            <WorkflowFilter
              value={workflows}
              onChange={handleWorkflowFilterChange}
            />
          </Box>

          <Box bgColor={bgColor}>
            <TypeFilter value={type} onChange={handleTypeFilterChange} />
          </Box>

          <Box bgColor={bgColor}>
            <TransactionsBudgetFilter
              value={budgets}
              onChange={handleBudgetFilterChange}
            />
          </Box>

          <Box bgColor={bgColor}>
            <PersonFilter value={persons} onChange={handlePersonFilterChange} />
          </Box>

          <Box bgColor={bgColor}>
            <TypeFilterPurchase
              value={purchaseType}
              onChange={handlePurchaseTypeFilterChange}
            />
          </Box>

          <Box bgColor={bgColor}>
            <TypeFilterLabor
              value={laborType}
              onChange={handleLaborTypeFilterChange}
            />
          </Box>
          <Box bgColor={bgColor}>
            <VendorFilter values={vendor} onChange={handleVendorFilterChange} />
          </Box>
        </HStack>

        <Flex flex={1} alignItems="flex-start">
          {showFilterAndSort && DateFilter}
          <Box ml={2}>{showFilterAndSort && SortFilter}</Box>
          <IconButton
            ml={2}
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Sort"
            bgColor={bgColor}
            colorScheme="blue"
            variant="outline"
            size={isDesktop ? "md" : "sm"}
            onClick={handleRefreshFilter}
          />
        </Flex>
      </HStack>
    </Box>
  ) : (
    <>
      <HStack w={"full"} justifyContent={"space-between"}>
        <Button size={"sm"} colorScheme={"blue"} onClick={onOpen}>
          Search
        </Button>
        <HStack>
          {DateFilter}
          {SortFilter}
          <IconButton
            ml={2}
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Sort"
            bgColor={bgColor}
            colorScheme="blue"
            variant="outline"
            size={isDesktop ? "md" : "sm"}
            onClick={handleRefreshFilter}
          />
        </HStack>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Select Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack divider={<StackDivider borderColor="gray.200" />}>
              <Box width={"full"}>
                <AccountSearchBox
                  onInputChange={debounceSearch}
                  searchPlaceholder="Transaction # (Prefix/invoiceNO)"
                />
              </Box>

              <HStack wrap="wrap" spacing={0} gap={2}>
                <Box bgColor={bgColor}>
                  <WorkflowFilter
                    value={workflows}
                    onChange={handleWorkflowFilterChange}
                  />
                </Box>

                <Box bgColor={bgColor} mt={4}>
                  <TypeFilter value={type} onChange={handleTypeFilterChange} />
                </Box>

                <Box bgColor={bgColor} mt={4}>
                  <TransactionsBudgetFilter
                    value={budgets}
                    onChange={handleBudgetFilterChange}
                  />
                </Box>

                <Box bgColor={bgColor} mt={4}>
                  <PersonFilter
                    value={persons}
                    onChange={handlePersonFilterChange}
                  />
                </Box>

                <Box bgColor={bgColor} mt={4}>
                  <TypeFilterPurchase
                    value={purchaseType}
                    onChange={handlePurchaseTypeFilterChange}
                  />
                </Box>

                <Box bgColor={bgColor} mt={4}>
                  <TypeFilterLabor
                    value={laborType}
                    onChange={handleLaborTypeFilterChange}
                  />
                </Box>
                <Box bgColor={bgColor} mt={4}>
                  <VendorFilter
                    values={vendor}
                    onChange={handleVendorFilterChange}
                  />
                </Box>
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
