export const compareTwoDates = (date1: Date | string, date2: Date | string) => {
  const date1Copy = (
    typeof date1 === "string" ? new Date(date1) : date1
  ).getTime();
  const date2Copy = (
    typeof date2 === "string" ? new Date(date2) : date2
  ).getTime();

  return date1Copy > date2Copy;
};
