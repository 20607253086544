import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  ApiLocationType,
  CreateApiLocation,
  NcesDistrict,
  NcesLocation,
} from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { LocationTypeSelect } from "../selects/LocationTypeSelect";
import { StateSelect } from "../selects/StateSelect";
import { WizardStepProps } from "./FirstRunWizard";

export const FirstRunWizardLocationImport = ({ onNext }: WizardStepProps) => {
  const { apiClient, currentAccount } = useAuthentication();

  const [importOrCreate, setImportOrCreate] = useState<
    "import" | "create" | ""
  >("");

  // Create state
  const [locationType, setLocationType] = useState<ApiLocationType>(
    ApiLocationType.building
  );
  const [locationName, setLocationName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  // Import state
  // district search stuff
  const [selectedState, setSelectedState] = useState<string | null>();
  const [districtName, setDistrictName] = useState("");
  const [districts, setDistricts] = useState<NcesDistrict[]>([]);
  const [activeDistrict, setActiveDistrict] = useState<NcesDistrict>();
  // locations stuff
  const [totalLocations, setTotalLocations] = useState<number>();
  const [districtLocations, setDistrictLoations] = useState<NcesLocation[]>();
  const [ignoreLocations, setIgnoreLocations] = useState<
    Record<string, boolean>
  >({});
  const [locationsImporting, setLocationsImporting] = useState(false);

  const handleLocationTypeChange = useCallback((type: ApiLocationType) => {
    setLocationType(type);
  }, []);

  const handleLocationNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocationName(e.target.value);
    },
    []
  );

  const handleSaveLocation = useCallback(() => {
    // This is not a full form, so no need to sort out types
    //@ts-ignore
    const newLocation: CreateApiLocation = {
      type: locationType,
      name: locationName,
      active: true,
      website: null,
      phone: null,
      parent: null,
    };

    setIsSaving(true);
    apiClient
      .createLocation(currentAccount.id, newLocation)
      .then(() => {
        onNext();
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [apiClient, currentAccount, locationType, locationName, onNext]);

  const handleDistrictChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDistrictName(e.target.value);
    },
    []
  );

  const handleLocationIgnore = useCallback(
    (locationId: string) => () => {
      if (!ignoreLocations[locationId]) {
        setIgnoreLocations({
          ...ignoreLocations,
          [locationId]: true,
        });
        return;
      }
      const clone = {
        ...ignoreLocations,
      };

      delete clone[locationId];
      setIgnoreLocations(clone);
    },
    [ignoreLocations]
  );

  const handleImportLocations = useCallback(() => {
    if (!activeDistrict) {
      return;
    }

    setLocationsImporting(true);

    apiClient
      .importDistrictLocations(currentAccount.id, activeDistrict.id, {
        ignoreLocations: Object.keys(ignoreLocations),
      })
      .then(() => {
        onNext();
      })
      .finally(() => {
        setLocationsImporting(false);
      });
  }, [apiClient, currentAccount, activeDistrict, ignoreLocations, onNext]);

  const handleCancelImport = useCallback(() => {
    setDistrictLoations(undefined);
    setActiveDistrict(undefined);
    setDistrictName("");
    setSelectedState(undefined);
    setImportOrCreate("");
  }, []);

  useEffect(() => {
    if (!districtName) {
      setDistricts([]);
      return;
    }

    const timeout = setTimeout(() => {
      apiClient
        .findImportableDistrict(currentAccount.id, {
          search: districtName,
          state: selectedState ? selectedState : undefined,
          pageSize: 50,
        })
        .then((pagedResult) => {
          setDistricts(pagedResult.data);
        });
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [apiClient, currentAccount, districtName, selectedState]);

  useEffect(() => {
    if (!activeDistrict) {
      setDistrictLoations([]);
      setTotalLocations(undefined);
      return;
    }

    apiClient
      .findImportableDistrictLocations(currentAccount.id, activeDistrict.id)
      .then((pagedLocations) => {
        setDistrictLoations(pagedLocations.data);
        setTotalLocations(pagedLocations.total);
      });
  }, [apiClient, currentAccount, activeDistrict]);

  return (
    <>
      {importOrCreate === "" && (
        <Stack shouldWrapChildren spacing={4}>
          <Text>Let's create a location for our operations</Text>
          <Text>
            For public or charter schools we suggest importing your district
            from NCES
          </Text>
          <HStack>
            <Button size="sm" onClick={() => setImportOrCreate("create")}>
              Manually create location
            </Button>
            <Button size="sm" onClick={() => setImportOrCreate("import")}>
              Import locations from NCES
            </Button>
          </HStack>
        </Stack>
      )}
      {importOrCreate === "create" && (
        <Stack shouldWrapChildren spacing={4}>
          <Text>Create a location</Text>
          <FormControl id="first-run-wizard-new-location-type">
            <FormLabel>Type</FormLabel>
            <LocationTypeSelect
              value={locationType}
              onChange={handleLocationTypeChange}
            />
          </FormControl>
          <FormControl id="first-run-wizard-new-location-name">
            <FormLabel>Name</FormLabel>
            <Input
              value={locationName}
              onChange={handleLocationNameChange}
              autoFocus
              autoComplete="off"
            />
          </FormControl>
          <HStack justifyContent="space-between">
            <Button
              size="sm"
              onClick={handleCancelImport}
              isDisabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={handleSaveLocation}
              isLoading={isSaving}
              isDisabled={locationName.trim().length === 0 || isSaving}
            >
              Save Location
            </Button>
          </HStack>
        </Stack>
      )}
      {importOrCreate === "import" && (
        <Stack shouldWrapChildren spacing={4}>
          <Text>Import Locations from the NCES database</Text>
          {!activeDistrict && (
            <Stack spacing={4}>
              <FormControl id="first-run-wizard-importer-state">
                <FormLabel>What state are you located in?</FormLabel>
                <StateSelect
                  value={selectedState || undefined}
                  onChange={setSelectedState}
                ></StateSelect>
              </FormControl>
              {selectedState && (
                <FormControl id="first-run-wizard-importer-district">
                  <FormLabel>District / County / NCES / State Agency</FormLabel>
                  <Input
                    value={districtName}
                    onChange={handleDistrictChange}
                    autoComplete="off"
                    autoFocus
                  />
                </FormControl>
              )}
              {!activeDistrict && districts.length > 0 && (
                <Stack>
                  <Box maxH="250px" overflowY="auto">
                    {districts.map((d) => (
                      <Flex mr={6} py={1} key={d.id} maxW="100%">
                        <Box flex={1} overflow="hidden">
                          <Text isTruncated>{d.name}</Text>
                          <Text isTruncated as="em">
                            {d.county}
                          </Text>
                        </Box>
                        <Box width="50px" textAlign="right">
                          <Button
                            size="sm"
                            onClick={() => setActiveDistrict(d)}
                          >
                            Import
                          </Button>
                        </Box>
                      </Flex>
                    ))}
                  </Box>
                </Stack>
              )}{" "}
            </Stack>
          )}
          {!!activeDistrict && (
            <Stack shouldWrapChildren spacing={4}>
              {activeDistrict && (
                <Box>
                  <Text isTruncated>{activeDistrict.name}</Text>
                  <Text isTruncated as="em">
                    {activeDistrict.county}
                  </Text>
                </Box>
              )}
              {totalLocations !== undefined ? (
                <Box>
                  Found <Text as="strong">{totalLocations}</Text> locations
                </Box>
              ) : null}
              {districtLocations && districtLocations.length > 0 ? (
                <Box mt={2} maxH="250px" overflowY="auto">
                  {districtLocations.map((l) => (
                    <Flex mr={7} pb={2} key={l.id}>
                      <Box
                        flex={1}
                        overflow="hidden"
                        textDecoration={
                          ignoreLocations[l.id] ? "line-through" : undefined
                        }
                      >
                        <Text isTruncated>{l.name}</Text>
                        <Text isTruncated fontSize="xs" fontStyle="italic">
                          {l.address}, {l.city}, {l.state} {l.postal}
                        </Text>
                      </Box>
                      <Box width="50px" textAlign="right">
                        <Button size="sm" onClick={handleLocationIgnore(l.id)}>
                          {ignoreLocations[l.id] ? "Include" : "Exclude"}
                        </Button>
                      </Box>
                    </Flex>
                  ))}
                </Box>
              ) : null}
            </Stack>
          )}
          <HStack justifyContent="space-between">
            <Button
              size="sm"
              onClick={handleCancelImport}
              isDisabled={locationsImporting}
            >
              Cancel
            </Button>
            {districtLocations && districtLocations.length > 0 ? (
              <Button
                size="sm"
                onClick={handleImportLocations}
                isLoading={locationsImporting}
                isDisabled={
                  locationsImporting ||
                  districtLocations.length -
                    Object.keys(ignoreLocations).length <=
                    0
                }
              >
                Import{" "}
                {districtLocations.length - Object.keys(ignoreLocations).length}{" "}
                locations
              </Button>
            ) : null}
          </HStack>
        </Stack>
      )}
    </>
  );
};
