import {
  Checkbox,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  Image,
  Text,
} from "@chakra-ui/react";
import { ApiAttachment, ApiLocation } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { MdOutlineCameraAlt } from "react-icons/md";

export interface ViewComponents {
  locations: ApiLocation[];
  attachments: Record<string, ApiAttachment | null>;
  selectedLocations: string[];
  handleOnChangeCheckbox: (id: string) => void;
}

export const SpaceCardView: FC<ViewComponents> = ({
  locations,
  attachments,
  selectedLocations,
  handleOnChangeCheckbox,
}) => {
  return (
    <Flex w="100%" flexWrap="wrap" justifyContent="space-between">
      {locations.map((loc) => (
        <Flex
          p={1}
          w="48%"
          minW="48%"
          flexDir="column"
          cursor="pointer"
          position="relative"
          key={`spaceForm::${loc.id}`}
          onClick={(e) => {
            e.preventDefault();
            handleOnChangeCheckbox(loc.id);
          }}
        >
          <Checkbox
            top={2}
            right={2}
            size="lg"
            bgColor="white"
            position="absolute"
            onChange={(e) => {
              e.preventDefault();
              handleOnChangeCheckbox(loc.id);
            }}
            isChecked={selectedLocations.some((l) => l === loc.id)}
          />
          {attachments[loc.id] ? (
            <Image
              src={attachments[loc.id]?.url}
              w="full"
              h="148px"
              borderRadius={6}
            />
          ) : (
            <NoAvailableImage />
          )}
          <Text isTruncated maxW="80%" fontWeight="bold">
            {loc.name}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export const SpaceListView: FC<ViewComponents> = ({
  locations,
  attachments,
  selectedLocations,
  handleOnChangeCheckbox,
}) => {
  return (
    <Flex w="100%" flexDir="column" gap={4}>
      {locations.map((loc) => (
        <Flex
          w="full"
          key={`spaceList::${loc.id}`}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            gap={2}
            cursor="pointer"
            alignItems="center"
            onClick={(e) => {
              e.preventDefault();
              handleOnChangeCheckbox(loc.id);
            }}
          >
            {attachments[loc.id] ? (
              <Image
                w="70px"
                h="50px"
                borderRadius={6}
                src={attachments[loc.id]?.url}
                alt={attachments[loc.id]?.name}
              />
            ) : (
              <NoAvailableImage
                showText={false}
                iconProps={{ boxSize: "18px" }}
                containerProps={{ w: "70px", h: "50px" }}
              />
            )}
            <Text>{loc.name}</Text>
          </Flex>
          <Checkbox
            onChange={(e) => {
              e.preventDefault();
              handleOnChangeCheckbox(loc.id);
            }}
            isChecked={selectedLocations.some((l) => l === loc.id)}
          />
        </Flex>
      ))}
    </Flex>
  );
};

interface NoAvailableImageProps {
  containerProps?: FlexProps;
  iconProps?: IconProps;
  showText?: boolean;
}

export const NoAvailableImage: FC<NoAvailableImageProps> = ({
  iconProps,
  containerProps,
  showText = true,
}) => {
  return (
    <Flex
      w="full"
      h="148px"
      color="black"
      borderRadius={8}
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="gray.200"
      {...containerProps}
    >
      <Icon as={MdOutlineCameraAlt} boxSize="48px" {...iconProps} />
      {showText && <Text>No available image</Text>}
    </Flex>
  );
};
