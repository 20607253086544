import {
  Box,
  Button,
  CheckboxGroup,
  Divider,
  HStack,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import {
  ApiEventStatus,
  ApiUpdateVenueLevelNotification,
  ApiVenueSupervisedByUser,
} from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect, useState } from "react";
import { useShowToast } from "../../hooks/showToast";
import { AccountModal } from "../../pages/account-settings/account-modal/AccountModal";
import { useThunkDispatch } from "../../store";
import { loadVenuesLevelNotification } from "../../store/venue-level-notification.slice";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useAuthentication } from "../auth/AuthProvider";
interface SwitchStatusState {
  [key: string]: boolean;
}

export interface VenueLevelEmailSettingModalProps {
  isOpen: boolean;
  onClose: () => void;
  workingVenue: ApiVenueSupervisedByUser | null;
}

export const VenueLevelEmailSettingModal: React.FC<
  VenueLevelEmailSettingModalProps
> = ({ isOpen, onClose, workingVenue }) => {
  const thunkDispatch = useThunkDispatch();

  const { currentUser, currentAccount, apiClient } = useAuthentication();
  const showToast = useShowToast();

  const [statuses, setStatuses] = useState<SwitchStatusState>({
    pending: true,
    confirmed: true,
    declined: true,
  });

  const handleStatusChange = (value: ApiEventStatus) => {
    setStatuses((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const handleSettingSave = useCallback(
    ({ venueId }: { venueId: string }) => {
      let notificationValue: boolean | ApiEventStatus[] = false;

      if (
        Object.values(statuses).every((value) => value) ||
        Object.values(statuses).every((value) => !value)
      ) {
        notificationValue = Object.values(statuses).every((value) => value);
      } else {
        const eventStatusesEnabledNotifications: ApiEventStatus[] = [];
        Object.entries(statuses).forEach(([key, value]) => {
          if (value) {
            eventStatusesEnabledNotifications.push(key as ApiEventStatus);
          }
        });
        notificationValue = eventStatusesEnabledNotifications;
      }

      const venueLevelNotification: ApiUpdateVenueLevelNotification = {
        venueId,
        userId: currentUser.id,
        notificationValue,
      };

      apiClient
        .updateVenueLevelNotificationSetting(
          currentAccount.id,
          venueLevelNotification
        )
        .then(() => {
          onClose();
          showToast("success", "Notification setting updated");

          thunkDispatch(
            loadVenuesLevelNotification({
              apiClient,
              accountId: currentAccount.id,
            })
          );
        })
        .catch(() => {
          showToast("error", "Error updating notification setting");
        });
    },
    [
      statuses,
      currentUser.id,
      apiClient,
      currentAccount.id,
      onClose,
      showToast,
      thunkDispatch,
    ]
  );

  useEffect(() => {
    const statusesEnabled: SwitchStatusState = {};
    const enabledNotifications = workingVenue?.enabledNotifications;
    if (typeof enabledNotifications === "boolean") {
      Object.values(ApiEventStatus).forEach((status) => {
        statusesEnabled[status] = enabledNotifications;
      });
    } else {
      Object.values(ApiEventStatus).forEach((status) => {
        statusesEnabled[status] = Boolean(
          enabledNotifications?.includes(status)
        );
      });
    }
    setStatuses(statusesEnabled);
  }, [workingVenue, workingVenue?.enabledNotifications]);

  return (
    <>
      {workingVenue ? (
        <AccountModal
          isOpen={isOpen}
          onClose={onClose}
          title={"Venue level notifications"}
          titleProps={{ fontSize: "lg" }}
          content={
            <Box w="100%">
              <Stack spacing={4}>
                <Text mb={5}>
                  Select which event statuses to allow when the event has the{" "}
                  {workingVenue.name} as avenue.
                </Text>
                <CheckboxGroup>
                  <Stack direction="column" spacing={6}>
                    {Object.values(ApiEventStatus).map((value) => (
                      <HStack key={value}>
                        <Switch
                          isChecked={statuses[value]}
                          id={value}
                          onChange={() => handleStatusChange(value)}
                          mr={1}
                        />
                        <Text>{capitalizeFirstLetter(value)}</Text>
                      </HStack>
                    ))}
                  </Stack>
                </CheckboxGroup>
                <Divider my={4} />
                <HStack justifyContent="flex-end" gap={1} mt={2}>
                  <Button
                    size="sm"
                    minWidth="100px"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    minWidth="100px"
                    colorScheme="blue"
                    onClick={() =>
                      handleSettingSave({ venueId: workingVenue.id })
                    }
                  >
                    Save
                  </Button>
                </HStack>
              </Stack>
            </Box>
          }
        />
      ) : null}
    </>
  );
};
