import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { convertFromRaw, EditorState } from "draft-js";
import { markdownToDraft } from "markdown-draft-js";
import { useCallback, useMemo } from "react";

export interface UseConvertMentionsParams {
  value?: string;
  mentioned?: ApiUserSummary[];
}

export function useConvertMentions({
  value,
  mentioned,
}: UseConvertMentionsParams) {
  const replaceMentions = useCallback(() => {
    let responseComment = value || "";
    mentioned?.forEach((ment) => {
      responseComment = responseComment.replaceAll(
        `<u:${ment.id}>`,
        `@${ment.firstName} ${ment.lastName}`
      );
    });
    return responseComment;
  }, [mentioned, value]);

  const replaceMentionsArrayObject = useCallback(
    (value?: string, mentioned?: ApiUserSummary[]) => {
      const responseComment = value || "";
      const regex = /(?:<u:)[\w-]*(?=>)/g;
      const matches = responseComment.match(regex);
      let arrayMentions: ApiUserSummary[] = [];
      matches?.forEach((mat) => {
        const cleaned = mat.replace("<u:", "").replace(">", "");
        const l = mentioned?.find((obj) => {
          return obj.id === cleaned;
        });
        l && arrayMentions.push(l);
      });
      return arrayMentions;
    },
    []
  );

  const createMentions = useCallback((obj: ApiUserSummary[]) => {
    const response = obj.map((val) => {
      return {
        data: {
          text: `@${val.firstName} ${val.lastName}`,
          url: `${val.id}`,
          value: `${val.firstName} ${val.lastName}`,
        },
        mutability: "IMMUTABLE",
        type: "MENTION",
      };
    });
    return response;
  }, []);

  const convertCommentDBtoEditorStateObject = useCallback(() => {
    if (!value) {
      return EditorState.createEmpty();
    }
    const stringReplaced = replaceMentions();
    const arrayMentions = replaceMentionsArrayObject();
    const mentionsToAdd = createMentions(arrayMentions);
    const response = markdownToDraft(stringReplaced);

    mentionsToAdd.forEach((element, index) => {
      response.entityMap[index] = {
        type: "MENTION",
        mutability: "IMMUTABLE",
        data: element.data,
      };
    });

    let arrEntityMap = mentionsToAdd;
    let key = 0;
    response.blocks.forEach((block) => {
      if (arrEntityMap[0]) {
        let text = block.text;
        let position = text.indexOf(arrEntityMap[0].data.text);
        let sub = 0;
        while (text.length > 0 && position !== -1 && arrEntityMap[0]) {
          block.entityRanges.push({
            key: key,
            offset: position + sub,
            length: arrEntityMap[0].data.text.length,
          });
          key++;
          sub += position + arrEntityMap[0].data.text.length;
          text = text.substring(position + arrEntityMap[0].data.text.length);
          arrEntityMap.shift();
          if (arrEntityMap[0]) {
            position = text.indexOf(arrEntityMap[0].data.text);
          }
        }
      }
    });
    const content = convertFromRaw(JSON.parse(JSON.stringify(response)));
    const state = { editorState: EditorState.createWithContent(content) };
    return state.editorState;
  }, [createMentions, replaceMentions, replaceMentionsArrayObject, value]);

  const editorState = useMemo(() => {
    return convertCommentDBtoEditorStateObject();
  }, [convertCommentDBtoEditorStateObject]);

  return {
    editorState,
    createMentions,
    replaceMentions,
    replaceMentionsArrayObject,
    convertCommentDBtoEditorStateObject,
  };
}
