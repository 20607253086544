import { CreateApiRequest } from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventFormValues } from "../pages/events/event-form/EventForm";

export interface FormikModalSliceState {
  values: CreateApiRequest | EventFormValues;
  isSubmitting: boolean;
  handleSubmit: (e?: CreateApiRequest | EventFormValues) => void;
  isUploading?: boolean;
}

export const formikModalSlice = createSlice({
  name: "formikModalSlice",
  initialState: {
    values: {},
    isSubmitting: false,
    handleSubmit: () => {},
    isUploading: undefined,
  } as FormikModalSliceState,
  reducers: {
    unloadFormikModal: (state) => {
      state.values = {} as CreateApiRequest;
      state.isSubmitting = false;
      state.handleSubmit = () => {};
      state.isUploading = undefined;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    setFormikModal: (state, action: PayloadAction<FormikModalSliceState>) => {
      const { values, isSubmitting, handleSubmit, isUploading } =
        action.payload;
      state.values = values;
      if (isSubmitting !== undefined) {
        state.isSubmitting = isSubmitting;
      }
      if (handleSubmit) {
        state.handleSubmit = handleSubmit;
      }
      state.isUploading = isUploading;
    },
  },
});

export const { unloadFormikModal, setFormikModal, setIsSubmitting } =
  formikModalSlice.actions;

export default formikModalSlice.reducer;
