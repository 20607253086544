import {
  ApiAccount,
  ApiClient,
  ApiTransactionType,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";
import { loadTransactions } from "./loadTransactions.thunk";

export interface RemoveRequestTransactionThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  idOrKey: string;
  transactionId: string;
  transactionType: "purchase" | "labor";
}

export const removeTransaction = createAsyncThunk(
  "requests/removeTransactions",
  async (params: RemoveRequestTransactionThunkParams, thunkAPI) => {
    const { apiClient, account, idOrKey, transactionId, transactionType } =
      params;

    await apiClient.deleteRequestTransaction(
      account.id,
      idOrKey,
      transactionId
    );

    const { requestForm } = thunkAPI.getState() as RootState;

    thunkAPI.dispatch(
      loadTransactions({
        apiClient,
        account,
        key: idOrKey,
        page: requestForm.commentsCurrentPage,
        transactionType,
      })
    );
    return { transactionId, transactionType };
  }
);

export const removeTransactionHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(removeTransaction.fulfilled, (state, action) => {
    if (!action.payload) return;
    const type = action.payload.transactionType;
    if (type === ApiTransactionType.labor) {
      state.laborTotal -= 1;
    } else if (type === ApiTransactionType.purchase) state.expensesTotal -= 1;
  });
};
