import {
  Box,
  Heading,
  Stack,
  StackItem,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setIsOpenInventoryOrderForm } from "../../../store/inventory/inventory-order-item-list.slice";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { SkeletonInventory } from "../SkeletonInventory";
import { InventoryOrderForm } from "./InventoryOrderForm";
import { InventoryOrderSelection } from "./InventoryOrderSection";
import { ItemsCart } from "./ItemsCart";

export const OrderItemsList: FC = () => {
  const dispatch = useDispatch();
  const { cartOrderItems, loading } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");

  const handleOnCloseCreateOrderForm = useCallback(() => {
    dispatch(
      setIsOpenInventoryOrderForm({ isFormOpen: false, supplierId: "" })
    );
  }, [dispatch]);

  return (
    <>
      <Stack
        p={[0, 0, 4]}
        w="100%"
        gap="24px"
        border="1px solid"
        flexDir={["column", "column", "row", "row"]}
        borderRadius={6}
        borderColor={["transparent", "transparent", borderColor]}
      >
        <StackItem
          w={[
            "100%",
            "100%",
            "calc(50% - 24px)",
            "calc(60% - 24px)",
            "calc(70% - 24px)",
          ]}
        >
          {loading === "idle" || loading === "pending" ? (
            <Box>
              <SkeletonInventory alwaysMobile />
            </Box>
          ) : (
            <InventoryOrderSelection />
          )}
        </StackItem>
        <StackItem w={["100%", "100%", "50%", "40%", "30%"]}>
          <ItemsCart />
        </StackItem>
      </Stack>
      {cartOrderItems.isFormOpen && cartOrderItems.supplierId && (
        <AccountModal
          title={
            <VStack alignItems="flex-start" fontWeight="bold" gap={2}>
              <Heading fontSize="2xl">
                {cartOrderItems.items.find(
                  (it) =>
                    it.item.supplier[0]?.supplier?.id ===
                    cartOrderItems.supplierId
                )?.item.supplier[0]?.supplier?.name ?? ""}{" "}
                Order
              </Heading>
            </VStack>
          }
          isOpen={cartOrderItems.isFormOpen}
          onClose={handleOnCloseCreateOrderForm}
          content={
            <InventoryOrderForm onCancel={handleOnCloseCreateOrderForm} />
          }
        />
      )}
    </>
  );
};
