import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import {
  ApiBudgetSummary,
  ApiPurchaseTransaction,
  ApiTransaction,
  ApiTransactionType,
  ApiVendor,
  ApiWorkflow,
  CreateApiPurchaseTransaction,
  PurchaseType,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import axios, { AxiosProgressEvent } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  Attachment,
  Attachments,
} from "../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../../components/form-helpers/BudgetAutocompleteControl";
import { DatePickerControl } from "../../../../components/form-helpers/DatePickerControl";
import FocusError from "../../../../components/form-helpers/FocusError";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { RadioButtonsControl } from "../../../../components/form-helpers/RadioButtonsControl";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { VendorAutocompleteControl } from "../../../../components/form-helpers/VendorsAutocompleteControl";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  addAttachment,
  AttachmentTypeEnum,
  cleanTransactionAttachements,
  removeAttachmentReducer,
  setTotalPurchaseTransactionChange,
  updateAttachment,
} from "../../../../store/request-form/request-form.slice";
import { createTransaction } from "../../../../store/request-form/thunks/createTransaction.thunk";
import { createTransactionAttachment } from "../../../../store/request-form/thunks/createTransactionAttachment.thunk";
import { deleteTransactionAttachment } from "../../../../store/request-form/thunks/deleteTransactionAttachment.thunk";
import { loadTransactionAttachments } from "../../../../store/request-form/thunks/loadTransactionAttachments.thunk";
import { updateTransaction } from "../../../../store/request-form/thunks/updateTransaction.thunk";
import { updateTransactions } from "../../../../store/transaction-list.slice";

const radioButtonOptions = [
  { label: "Vendor", schemeColor: "blue", value: PurchaseType.vendor },
  { label: "Supplies", schemeColor: "blue", value: PurchaseType.supplies },
];

const PurchaseFormSchema = (type: PurchaseType) =>
  yup.object().shape({
    type: yup.mixed().oneOf([ApiTransactionType.purchase]),
    purchaseType: yup
      .mixed()
      .oneOf([PurchaseType.vendor, PurchaseType.supplies]),
    vendor: yup.object().required("This field is required").nullable(),
    description: yup
      .string()
      .max(255, "Field is too long")
      .required("This field is required"),
    invoiceNumber: yup
      .string()
      .max(255, "Field is too long")
      .optional()
      .nullable(),
    quantity:
      type === PurchaseType.vendor
        ? yup.number().max(25000).optional().nullable()
        : yup
            .number()
            .max(25000, "Quantity must be less than 25000")
            .typeError("Must be a number")
            .positive("Must be a positive number")
            .required("This field is required"),
    unitCost: yup
      .number()
      .max(999999.99, "Field must be less than 999999.99")
      .typeError("Must be a number")
      .required("This field is required"),
    additionalFees: yup
      .number()
      .max(999999.99, "Field must be less than 999999.99")
      .min(-999999.99, "Field must be greater than 999999.99")
      .optional()
      .nullable(),
    datePerformed: yup.string().required().nullable(),
    budget: yup
      .object()
      .shape({ id: yup.string().uuid() })
      .optional()
      .nullable(),
  });

export interface ExpensesFormProps {
  transactionId: string | null;
  handleCloseModal: () => void;
  workingPurchase: UpdateApiPurchaseTransaction | null;
  canEditAndDelete?: (purchase: ApiPurchaseTransaction) => boolean;
}

export const ExpensesForm: React.FC<ExpensesFormProps> = ({
  transactionId,
  workingPurchase,
  handleCloseModal,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [purchase, setPurchase] = useState<
    CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction
  >();

  const [workflowForm, setWorkflowForm] = useState<ApiWorkflow | null>();
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const toast = useToast();
  const { request, workflow } = useSelector(
    (state: RootState) => state.requestForm
  );
  const { workflows } = useSelector((state: RootState) => state.localCache);
  const { workingTransactionAttachments } = useSelector(
    (state: RootState) => state.requestForm.transactions
  );

  const defaultPurchaseType = useMemo(() => {
    return workflow ? workflow.defaultExpenseType : PurchaseType.supplies;
  }, [workflow]);

  const [purchaseType, setPurchaseType] = useState(
    workingPurchase?.purchaseType || defaultPurchaseType
  );
  const purchaseInitValues: CreateApiPurchaseTransaction = useMemo(
    () => ({
      type: ApiTransactionType.purchase,
      purchaseType: defaultPurchaseType,
      checkNumber: 0,
      paymentDate: null,
      vendor: null,
      description: "",
      invoiceNumber: null,
      quantity: 1,
      unitCost: 0,
      additionalFees: 0,
      datePerformed: new Date().toDateString(),
      budget: request ? request.budget : null,
    }),
    [request, defaultPurchaseType]
  );

  useEffect(() => {
    if (workingPurchase !== null) {
      setPurchase(workingPurchase);
    } else {
      setPurchase(purchaseInitValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingPurchase]);

  useEffect(() => {
    if (workingPurchase !== null && transactionId && request) {
      thunkDispatch(
        loadTransactionAttachments({
          apiClient,
          accountId: currentAccount.id,
          requestIdOrKey: request?.id,
          transactionId: transactionId,
        })
      );
    }
    return () => {
      dispatch(cleanTransactionAttachements());
    };
  }, [
    thunkDispatch,
    dispatch,
    workingPurchase,
    apiClient,
    currentAccount,
    request,
    transactionId,
  ]);

  useEffect(() => {
    if (!workingPurchase) {
      setWorkflowForm(workflow);
      return;
    }
    const key = workingPurchase.requestKey?.split("-");
    if (key && key.length) {
      const slug = key[0];
      const workflowTransaction = workflows.find(
        (work) => work.requestSlug === slug
      );
      setWorkflowForm(workflow ? workflow : workflowTransaction);
      return;
    }
    setWorkflowForm(workflow ? workflow : undefined);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWorkflowForm]);

  const onSubmitForm = async (
    values: CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction,
    actions: FormikHelpers<
      CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction
    >
  ) => {
    setIsSaving(true);
    if (transactionId !== null) {
      const purchaseValues = values as UpdateApiPurchaseTransaction;
      thunkDispatch(
        updateTransaction({
          apiClient,
          account: currentAccount,
          requestKey: purchaseValues.requestKey!,
          idTransaction: transactionId,
          transactionType: ApiTransactionType.purchase,
          transaction: {
            checkNumber: purchaseValues.checkNumber,
            paymentDate: purchaseValues.paymentDate,
            purchaseType: purchaseValues.purchaseType,
            vendor: purchaseValues.vendor,
            description: purchaseValues.description,
            quantity:
              purchaseValues.quantity != null
                ? purchaseValues.purchaseType === PurchaseType.supplies
                  ? parseInt(purchaseValues.quantity.toString())
                  : 1
                : undefined,
            unitCost:
              purchaseValues.unitCost != null
                ? parseFloat(purchaseValues.unitCost.toString())
                : undefined,
            additionalFees:
              purchaseValues.additionalFees != null
                ? purchaseValues.purchaseType === PurchaseType.supplies
                  ? parseFloat(purchaseValues.additionalFees.toString())
                  : 0
                : undefined,
            datePerformed: purchaseValues.datePerformed,
            invoiceNumber: purchaseValues.invoiceNumber,
            budget: purchaseValues.budget,
          },
        })
      )
        .then(unwrapResult)
        .then((response) => {
          const totalActualPurchase =
            (purchaseValues?.quantity || 1) * (purchaseValues?.unitCost || 0) +
            (purchaseValues?.additionalFees || 0);
          const totalPreviousPurchase =
            (workingPurchase?.quantity || 1) *
              (workingPurchase?.unitCost || 0) +
            (workingPurchase?.additionalFees || 0);
          dispatch(
            setTotalPurchaseTransactionChange(
              totalActualPurchase - totalPreviousPurchase
            )
          );
          dispatch(updateTransactions(response as ApiTransaction));
        })
        .catch(() => {
          toast({
            position: "top",
            duration: 3000,
            isClosable: true,
            status: "error",
            title: "Error updating transaction",
          });
        })
        .finally(() => {
          setIsSaving(false);
          handleCloseModal();
        });
    } else {
      const purchaseValues = values as CreateApiPurchaseTransaction;
      if (!request) {
        return;
      }
      thunkDispatch(
        createTransaction({
          apiClient,
          account: currentAccount,
          request,
          transaction: {
            checkNumber: purchaseValues.checkNumber,
            paymentDate: purchaseValues.paymentDate,
            type: ApiTransactionType.purchase,
            purchaseType: purchaseValues.purchaseType,
            vendor: purchaseValues.vendor,
            description: purchaseValues.description,
            quantity: parseInt(purchaseValues.quantity.toString()),
            unitCost: parseFloat(purchaseValues.unitCost.toString()),
            additionalFees: parseFloat(
              purchaseValues.additionalFees.toString()
            ),
            datePerformed: purchaseValues.datePerformed,
            invoiceNumber: purchaseValues.invoiceNumber,
            budget: purchaseValues.budget,
          },
        })
      )
        .then(unwrapResult)
        .then((response) => {
          const createdPurchase = response as ApiPurchaseTransaction;
          const newAttachments = workingTransactionAttachments.filter(
            (x) => !!x.uploadId
          );
          dispatch(
            setTotalPurchaseTransactionChange(
              purchaseValues.quantity * purchaseValues.unitCost
            )
          );

          const promises = newAttachments.map((attachment) =>
            thunkDispatch(
              createTransactionAttachment({
                apiClient,
                accountId: currentAccount.id,
                requestIdOrKey: request.id,
                transactionId: createdPurchase.id,
                attachment: {
                  // newAttachments is filtered already, typescript doesnt follow
                  uploadId: attachment.uploadId!,
                  name: attachment.name,
                },
              })
            ).then(unwrapResult)
          );

          return Promise.all(promises);
        })
        .catch(() => {
          toast({
            position: "top",
            duration: 3000,
            isClosable: true,
            status: "error",
            title: "Error saving transaction",
          });
        })
        .finally(() => {
          setIsSaving(false);
          handleCloseModal();
        });
    }
  };

  const savePurchaseAttachment = useCallback(
    (files: Attachment[]) => {
      if (request) {
        files.forEach(async (file) => {
          if (!file.file) return;

          const loadResponse = await apiClient
            .createUpload(currentAccount.id)
            .then(async (uploadedFile) => {
              const newAttachment: Attachment = {
                ...file,
                isUploading: true,
                uploadId: uploadedFile.id,
                progress: 0,
              };

              dispatch(
                addAttachment({
                  ...newAttachment,
                  attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                })
              );
              return { newAttachment, uploadedFile };
            })
            .then(async ({ newAttachment, uploadedFile }) => {
              return axios
                .put(uploadedFile.url, newAttachment.file, {
                  headers: { "Content-type": newAttachment.file?.type },
                  onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
                    dispatch(
                      updateAttachment({
                        ...newAttachment,
                        progress: Math.round(
                          (progressEvent.loaded * 100) /
                            (progressEvent.total || 1)
                        ),
                        attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                      })
                    );
                  },
                })
                .then(() => ({ newAttachment, uploadedFile }));
            })
            .then(({ newAttachment, uploadedFile }) => {
              const updated: Attachment = {
                ...newAttachment,
                isUploading: false,
                progress: undefined,
              };

              dispatch(
                updateAttachment({
                  ...updated,
                  attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                })
              );
              return Promise.resolve({
                attachment: newAttachment,
                uploadedFile,
              });
            })
            .catch((error) => {
              toast({
                position: "top",
                duration: 3000,
                isClosable: true,
                status: "error",
                title: "Error loading files",
              });
            });

          if (
            transactionId &&
            loadResponse &&
            loadResponse.attachment.uploadId
          ) {
            thunkDispatch(
              createTransactionAttachment({
                apiClient,
                accountId: currentAccount.id,
                requestIdOrKey: request.id,
                transactionId: transactionId,
                attachment: {
                  uploadId: loadResponse.attachment.uploadId,
                  name: loadResponse.attachment.name,
                },
              })
            )
              .then(unwrapResult)
              .catch(() => {
                toast({
                  position: "top",
                  duration: 3000,
                  isClosable: true,
                  status: "error",
                  title: "Error saving a purchase attachment",
                });
              });
          }
        });
      }
    },
    [
      apiClient,
      currentAccount,
      transactionId,
      request,
      thunkDispatch,
      dispatch,
      toast,
    ]
  );

  const handleNewAttachments = useCallback(
    (files: Attachment[]) => {
      savePurchaseAttachment(files);
    },
    [savePurchaseAttachment]
  );

  const handleRemoveAttachment = useCallback(
    async (attachment: Attachment) => {
      if (request && attachment.uploadId) {
        if (transactionId) {
          await thunkDispatch(
            deleteTransactionAttachment({
              apiClient,
              accountId: currentAccount.id,
              requestIdOrKey: request.id,
              transactionId: transactionId,
              id: attachment.uploadId,
            })
          )
            .then(unwrapResult)
            .catch((error) => {
              toast({
                position: "top",
                duration: 3000,
                isClosable: true,
                status: "error",
                title: "Error deleting a purchase attachment",
              });
            });
        }

        dispatch(
          removeAttachmentReducer({
            ...attachment,
            attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
          })
        );
      }
    },
    [
      apiClient,
      currentAccount,
      transactionId,
      request,
      thunkDispatch,
      dispatch,
      toast,
    ]
  );

  return (
    <Box>
      {purchase && (
        <Formik
          onSubmit={onSubmitForm}
          initialValues={purchase}
          validationSchema={PurchaseFormSchema(purchaseType)}
        >
          {(props) => {
            return (
              <Form>
                <Grid>
                  <GridItem>
                    <RadioButtonsControl
                      label="Type of purchase"
                      value={purchaseType}
                      name="purchaseType"
                      radioOptions={radioButtonOptions}
                      setLocalState={(value) =>
                        setPurchaseType(value as PurchaseType)
                      }
                      cleanValues={[["vendor", null]]}
                    />
                  </GridItem>

                  <GridItem pt={4}>
                    <VendorAutocompleteControl
                      name="vendor"
                      label="Vendor"
                      addVendorLink
                      value={
                        purchase.vendor ? (purchase.vendor as ApiVendor) : null
                      }
                      isServiceProvider={
                        props.values.purchaseType === PurchaseType.vendor
                      }
                      isSupplier={
                        props.values.purchaseType === PurchaseType.supplies
                      }
                    />
                  </GridItem>

                  <GridItem pt={4}>
                    <TextInputControl
                      label="What was purchased?"
                      name="description"
                      value={purchase?.description || ""}
                      placeholder="ex: Light bulbs"
                    />
                  </GridItem>

                  <GridItem pt={4}>
                    <NumberInputControl
                      label="Check Number"
                      name="checkNumber"
                      value={purchase?.checkNumber || 0}
                    />
                  </GridItem>

                  {purchase && purchaseType === PurchaseType.supplies ? (
                    <Grid templateColumns={"repeat(2, 2fr)"} gap={4}>
                      <GridItem
                        colSpan={[2, null, null, 1]}
                        pt={[4, null, null, 4]}
                      >
                        <NumberInputControl
                          label="How many bought?"
                          name="quantity"
                          value={purchase.quantity || 0}
                          showStepper={true}
                          min={0}
                        />
                      </GridItem>
                      <GridItem
                        colSpan={[2, null, null, 1]}
                        pt={[0, null, null, 4]}
                      >
                        <NumberInputControl
                          label="Per unit cost"
                          name="unitCost"
                          value=""
                          prefix="$"
                          precision={2}
                        />
                      </GridItem>

                      <GridItem pt={0} colSpan={2}>
                        <NumberInputControl
                          label="Any additional Cost. Ex. shipping "
                          name="additionalFees"
                          value={purchase.additionalFees || 0}
                          min={-999999.99}
                          prefix="$"
                          precision={2}
                        />
                      </GridItem>
                    </Grid>
                  ) : (
                    <GridItem pt={4}>
                      <NumberInputControl
                        name="unitCost"
                        label="How much was spent?"
                        value={purchase.unitCost || null}
                        prefix="$"
                      />
                    </GridItem>
                  )}

                  <Divider mt={4} mb={4} />
                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4}>
                    <GridItem
                      colSpan={[2, null, null, 1]}
                      pt={[4, null, null, 4]}
                      maxW={180}
                    >
                      <DatePickerControl
                        value={purchase.datePerformed || null}
                        name="datePerformed"
                        label="Date of purchase"
                      />
                    </GridItem>
                    <GridItem
                      colSpan={[2, null, null, 1]}
                      pt={[0, null, null, 4]}
                      maxW={180}
                    >
                      <DatePickerControl
                        value={purchase?.paymentDate || null}
                        name="paymentDate"
                        label="Payment Date"
                      />
                    </GridItem>
                  </Grid>
                  <GridItem pt={4}>
                    <TextInputControl
                      label="PO / Invoice Number"
                      name="invoiceNumber"
                      value={purchase?.invoiceNumber || ""}
                      placeholder="ex: 345 67-AB"
                    />
                  </GridItem>

                  {workflowForm && workflowForm.allowBudgetsOnTransactions && (
                    <GridItem pt={4}>
                      <BudgetAutocompleteControl
                        label="Credit from budget"
                        name="budget"
                        value={(purchase?.budget as ApiBudgetSummary) || null}
                        placeholder="Search existing budgets"
                        helperText="This expense will pull from this budget."
                      />
                    </GridItem>
                  )}

                  <GridItem pt={4}>
                    <Attachments
                      attachments={workingTransactionAttachments}
                      onDeleteAttachment={handleRemoveAttachment}
                      onNewAttachments={handleNewAttachments}
                      gridColumns={2}
                    />
                  </GridItem>

                  <Divider my={4} />

                  <GridItem textAlign="center">
                    <Button
                      type="submit"
                      size="sm"
                      height="36px"
                      width="200px"
                      variant="solid"
                      colorScheme="blue"
                      isLoading={isSaving}
                    >
                      {workingPurchase ? "Save Purchase" : "Add Purchase"}
                    </Button>
                  </GridItem>
                  <FocusError />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};
