import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiVendor, ApiVendorContact } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from "react";
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdEmail,
  MdPhone,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { Pager } from "../../../../components/pager/Pager";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  deactivateVendorContact,
  loadVendorContacts,
  reactivateVendorContact,
} from "../../../../store/vendors/vendor-contacts.thunk";
import {
  cleanWorkingContacts,
  setIsOpenContactModal,
  setIsOpenFormModal,
  setWorkingContact,
  setWorkingVendor,
  updateContactFilters,
} from "../../../../store/vendors/vendors-slice";
import { debounce } from "../../../../utils/debounce";
import { ContactForm } from "./ContactForm";

interface VendorContactsProps {
  isReadOnly?: boolean;
}

export const VendorContacts: FC<VendorContactsProps> = ({ isReadOnly }) => {
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount, isProductAdmin, isEventAdmin } =
    useAuthentication();
  const { workingVendor, workingVendorContacts } = useSelector(
    (state: RootState) => state.vendorSlice,
  );

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const searchColor = useColorModeValue("white", "");
  const filtersBgColor = useColorModeValue("blue.50", "blue.900");
  const linkColor = useColorModeValue("blue.500", "blue.300");
  const contactBorderColor = useColorModeValue("gray.200", "whiteAlpha.300");

  const handleOnClickEdit = useCallback(
    (contact: ApiVendorContact) => {
      dispatch(setWorkingContact(contact));
      onOpen();
    },
    [dispatch, onOpen],
  );

  const handleOnCloseContactForm = useCallback(() => {
    workingVendorContacts.workingContact && dispatch(setWorkingContact(null));
    onClose();
  }, [dispatch, onClose, workingVendorContacts.workingContact]);

  const toggleOpenCloseContactForm = useCallback(() => {
    isOpen ? handleOnCloseContactForm() : onOpen();
  }, [handleOnCloseContactForm, isOpen, onOpen]);

  const handleDeactivateContact = useCallback(
    (contactId: string) => {
      if (!workingVendor) return;
      thunkDispatch(
        deactivateVendorContact({
          apiClient,
          accountId: currentAccount.id,
          contactId,
          vendorId: workingVendor.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Contact was deactivated successfully");
        })
        .catch(() => {
          showToast("error", "something went deactivating a contact");
        });
    },
    [apiClient, currentAccount.id, showToast, thunkDispatch, workingVendor],
  );

  const handleReactivateContact = useCallback(
    (contactId: string) => {
      if (!workingVendor) return;
      thunkDispatch(
        reactivateVendorContact({
          apiClient,
          accountId: currentAccount.id,
          contactId,
          vendorId: workingVendor.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Contact was reactivated successfully");
        })
        .catch(() => {
          showToast("error", "something went reactivating a contact");
        });
    },
    [apiClient, currentAccount.id, showToast, thunkDispatch, workingVendor],
  );

  const handleIncludeInactive = useCallback(() => {
    dispatch(
      updateContactFilters({
        includeInactive: !workingVendorContacts.filters.includeInactive,
        page: 1,
      }),
    );
  }, [dispatch, workingVendorContacts.filters.includeInactive]);

  const handleOnSearchChange = useCallback(
    (value: string) => {
      dispatch(
        updateContactFilters({
          search: value,
          page: 1,
        }),
      );
    },
    [dispatch],
  );

  const debouncedChangeSearch = debounce(handleOnSearchChange, 300);

  const handleOnPageChange = useCallback(
    (value: number) => {
      dispatch(
        updateContactFilters({
          page: value,
        }),
      );
    },
    [dispatch],
  );

  const hasDetails = useMemo(() => {
    return (
      workingVendor?.address !== null ||
      workingVendor?.city !== null ||
      workingVendor?.address2 !== null ||
      workingVendor?.state !== null
    );
  }, [
    workingVendor?.address,
    workingVendor?.address2,
    workingVendor?.city,
    workingVendor?.state,
  ]);

  const handleOnClickEditVendor = useCallback(
    (vendor: ApiVendor | null) => {
      if (!vendor) return;
      navigate("/account/vendors");
      dispatch(setIsOpenContactModal({ isOpen: false, isReadOnly: false }));
      dispatch(cleanWorkingContacts());
      dispatch(setWorkingVendor(vendor));
      dispatch(setIsOpenFormModal(true));
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    if (!workingVendor) return;
    thunkDispatch(
      loadVendorContacts({
        apiClient,
        accountId: currentAccount.id,
        vendorId: workingVendor.id,
      }),
    );
  }, [
    apiClient,
    currentAccount,
    thunkDispatch,
    workingVendor,
    workingVendorContacts.filters,
  ]);

  return (
    <Flex flexDir="column" gap={4}>
      {!isReadOnly && (
        <>
          <Flex w="100%" justifyContent="flex-end">
            <Text
              display="flex"
              cursor="pointer"
              color={linkColor}
              alignItems="center"
              onClick={toggleOpenCloseContactForm}
            >
              {isOpen ? "Close" : "Open"} create contact form
              <Icon ml={1} as={isOpen ? MdArrowDropUp : MdArrowDropDown} />
            </Text>
          </Flex>
          <Collapse in={isOpen} unmountOnExit>
            <Box
              p={2}
              borderRadius={6}
              border="1px solid"
              borderColor={contactBorderColor}
            >
              <ContactForm onClose={handleOnCloseContactForm} />
            </Box>
          </Collapse>
        </>
      )}

      {(isProductAdmin || isEventAdmin) && isReadOnly && (
        <Flex justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => handleOnClickEditVendor(workingVendor)}
          >
            Edit
          </Button>
        </Flex>
      )}

      {!hasDetails && isReadOnly && <Text>No details provided</Text>}

      {workingVendor && isReadOnly && hasDetails && (
        <Flex
          p={2}
          gap={2}
          flexDir="column"
          borderRadius={6}
          border="1px solid"
          borderColor={contactBorderColor}
        >
          <Flex
            gap={2}
            wrap="wrap"
            justifyContent="space-between"
            flexDir={["column", "column", "row"]}
          >
            {workingVendor.state && (
              <Text as="span">
                <Text as="span" fontWeight="semibold" mr={1}>
                  City:
                </Text>
                {workingVendor.state}
              </Text>
            )}
            {workingVendor.city && (
              <Text>
                <Text as="span" fontWeight="semibold" mr={1}>
                  State:
                </Text>{" "}
                {workingVendor.city}
              </Text>
            )}
          </Flex>
          <Flex
            gap={2}
            wrap="wrap"
            justifyContent="space-between"
            flexDir={["column", "column", "row"]}
          >
            {workingVendor.address && (
              <Text>
                <Text as="span" fontWeight="semibold" mr={1}>
                  Address:
                </Text>
                {workingVendor.address}
              </Text>
            )}
            {workingVendor.address2 && (
              <Text>
                <Text as="span" fontWeight="semibold" mr={1}>
                  Address 2:
                </Text>
                {workingVendor.address2}
              </Text>
            )}
          </Flex>
        </Flex>
      )}
      <Divider />

      <Flex
        bgColor={filtersBgColor}
        alignItems="center"
        p={2}
        wrap="wrap"
        gap={2}
      >
        {!isReadOnly && (
          <Box w={["100%", "100%", "46%"]}>
            <QuickFilterButton
              activeText="Include Inactive"
              onClick={handleIncludeInactive}
              isActive={workingVendorContacts.filters.includeInactive || false}
            />
          </Box>
        )}

        <Box w={["100%", "100%", "50%"]}>
          <InputGroup bgColor={searchColor}>
            <InputLeftElement children={<SearchIcon color="gray.300" />} />
            <Input
              type="text"
              placeholder="Search Contact"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                debouncedChangeSearch(e.target.value)
              }
            />
          </InputGroup>
        </Box>
      </Flex>

      {workingVendorContacts.data.length === 0 && (
        <Text>0 contacts, please add new one</Text>
      )}

      {workingVendorContacts.data.map((contact) => (
        <Flex
          py={2}
          px={4}
          gap={2}
          w="100%"
          flexDir="column"
          key={contact.id}
          borderRadius={4}
          border="1px solid"
          borderColor={contactBorderColor}
        >
          <Flex justifyContent="space-between" w="100%">
            <Text fontWeight="semibold">{contact.name}</Text>
            {!isReadOnly && (
              <Flex gap={2}>
                {contact.isActive ? (
                  <Button
                    size="xs"
                    colorScheme="red"
                    variant="outline"
                    onClick={() => handleDeactivateContact(contact.id)}
                  >
                    Deactivate
                  </Button>
                ) : (
                  <Button
                    size="xs"
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => handleReactivateContact(contact.id)}
                  >
                    Reactivate
                  </Button>
                )}
                <Button
                  size="xs"
                  colorScheme="blue"
                  onClick={() => handleOnClickEdit(contact)}
                >
                  Edit
                </Button>
              </Flex>
            )}
          </Flex>

          <Flex justifyContent="space-between" w="100%" wrap="wrap">
            {contact.email && (
              <Link
                href={`mailto::${contact.email}`}
                color={linkColor}
                display="flex"
                alignItems="center"
              >
                <Icon as={MdEmail} mr={1} />
                {contact.email}
              </Link>
            )}

            <Flex gap={2}>
              {contact.phone && (
                <Link
                  href={`tel::${contact.phone}`}
                  color={linkColor}
                  display="flex"
                  alignItems="center"
                >
                  <Icon as={MdPhone} mr={1} />
                  {contact.phone}
                </Link>
              )}

              {contact.altPhone && (
                <Link
                  href={`tel::${contact.altPhone}`}
                  color={linkColor}
                  display="flex"
                  alignItems="center"
                >
                  <Icon as={MdPhone} mr={1} />
                  {contact.altPhone}
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
      <Divider />
      <Pager
        pageSize={5}
        showDetails
        total={workingVendorContacts.total}
        currentPage={workingVendorContacts.filters.page || 1}
        onPageChange={handleOnPageChange}
      />
    </Flex>
  );
};
