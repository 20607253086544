import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import {
  GroupBase,
  OptionProps,
  Select,
  SingleValue,
  SingleValueProps,
} from "chakra-react-select";
import { Country, ICountry } from "country-state-city";
import { useField, useFormikContext } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { createOptionComponent } from "../selects/select-overrides";
import { CustomSelectComponentProp } from "../selects/select-overrides-types";

interface CountryPhoneCodeSelectControlProps {
  label: string;
  name: string;
  value: CountryPhoneCodeParams | null;
}

export type CountryPhoneCodeParams = {
  country: {
    code: string;
    name: string;
  };
  phoneCode: string;
};

export type CountryPhoneCodeOptionsProps = OptionProps<
  CountryPhoneCodeParams,
  false,
  GroupBase<CountryPhoneCodeParams>
>;

export type CountryPhoneCodeSingleValueSelect = SingleValueProps<
  CountryPhoneCodeParams,
  false,
  GroupBase<CountryPhoneCodeParams>
>;

const PhoneCodeOption = ({ value }: { value: CountryPhoneCodeParams }) => {
  return (
    <HStack gap="1" w="fit-content">
      <Image
        src={`/flag_${value.country.code}.png`}
        w="24px"
        h="24px"
        p="unset"
        margin="unset"
        loading="eager"
      />
      <Text>{value.country.name}</Text>
      <Text color="gray.700">{`+${value.phoneCode}`}</Text>
    </HStack>
  );
};

const CustomOptionComponent = createOptionComponent(PhoneCodeOption);

const CustomSingleValueComponent = (
  props: CountryPhoneCodeSingleValueSelect
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <Image
        src={`/flag_${data.country.code}.png`}
        w="24px"
        h="24px"
        loading="eager"
      />
    </Box>
  );
};

export const CountryPhoneCodeSelectControl: FC<
  CountryPhoneCodeSelectControlProps
> = ({ label, name, value }) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField<CountryPhoneCodeParams | null>({
    name,
    value: value?.country.code,
  });
  const countriesOptions: CountryPhoneCodeParams[] = useMemo(
    () =>
      (
        [
          Country.getCountryByCode("US"),
          Country.getCountryByCode("CA"),
        ] as ICountry[]
      ).map<CountryPhoneCodeParams>((country: ICountry) => ({
        country: {
          code: country.isoCode,
          name: country.name,
        },
        phoneCode: country.phonecode,
      })),
    []
  );

  const handleOnChange = useCallback(
    (value: SingleValue<CountryPhoneCodeParams>) => {
      helper.setTouched(true);
      helper.setValue(value);
    },
    [helper]
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      Option: (props: CountryPhoneCodeOptionsProps) =>
        CustomOptionComponent(props),
      SingleValue: (props: CountryPhoneCodeSingleValueSelect) =>
        CustomSingleValueComponent(props),
      IndicatorSeparator: null,
    };
  }, []);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        {...field}
        name={name}
        options={countriesOptions}
        onChange={handleOnChange}
        isClearable={false}
        isSearchable={false}
        menuPlacement="auto"
        components={components}
        getOptionValue={(item: CountryPhoneCodeParams) => item.country.code}
        chakraStyles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            px: 3,
            h: "100%",
            borderRadius: 0,
            borderWidth: 0,
            cursor: "pointer",
            bg: "transparent",
          }),
          menu: (provided) => ({ ...provided, width: "fit-content" }),
          valueContainer: (provided) => ({
            ...provided,
            p: 0,
            pl: 3,
            borderWidth: 0,
            justifyContent: "center",
          }),
        }}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
