import {
  ApiAccount,
  ApiIssuanceTransaction,
  ApiLaborTransaction,
  ApiLocation,
  ApiPurchaseTransaction,
  ApiRequest,
  ApiUserSummary,
  ApiWorkflowField,
  ApiWorkflowFieldDataType,
} from "@operations-hero/lib-api-client";
import { Document } from "@react-pdf/renderer";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { PdfViewerModal } from "../../../components/pdf-viewer-modal/PdfViewerModal";
import { useProductSubscriptions } from "../../../components/shell/AppShell";
import { RootState } from "../../../store";
import { getHourAndMinutesNotation } from "../../../utils/textNotations";
import { PrintableRequestReport } from "./PrintableRequestReport";

export interface PrintRequestModalProps {
  account: ApiAccount;
  request: ApiRequest;
  visibleFields: ApiWorkflowField[];
  isOpen: boolean;
  totalLabor: string;
  onClose: () => void;
}

export const PrintReportModal = ({
  account,
  request,
  visibleFields,
  totalLabor,
  isOpen,
  onClose,
}: PrintRequestModalProps) => {
  const { laborsData } = useSelector(
    (state: RootState) => state.requestForm.transactions.labors
  );
  const { purchasesData } = useSelector(
    (state: RootState) => state.requestForm.transactions.purchases
  );
  const { issuanceData } = useSelector(
    (state: RootState) => state.requestForm.transactions.issuance
  );
  const { locationMap } = useSelector((state: RootState) => state.localCache);
  const { transactions } = useSelector((state: RootState) => state.requestForm);

  const { hasInventory } = useProductSubscriptions();
  const { isInventoryAdmin, isInventoryStandard } = useAuthentication();

  const getUserName = useCallback(
    (user: ApiUserSummary) => `${user.firstName} ${user.lastName}`,
    []
  );

  const totalLaborHours = useMemo(
    () => getHourAndMinutesNotation(transactions.totalHours),
    [transactions.totalHours]
  );

  const assignees = useMemo(() => {
    const strings = request.assignees.map((x) =>
      x.type === "user" ? getUserName(x.assignee) : x.assignee.name
    );
    return strings.join(", ");
  }, [request, getUserName]);

  const ancestors = useMemo(() => {
    if (!request.location || !locationMap) return [] as ApiLocation[];

    const location = locationMap[request.location.id];
    const parents = location.treePath.split(".").map((x) => locationMap[x]);
    parents.splice(-2, 2); //remove the self reference in treePath and last empty value
    return parents;
  }, [request.location, locationMap]);

  const singleLineFields = useMemo(() => {
    return visibleFields.filter(
      (x) =>
        !(
          (x.dataType === ApiWorkflowFieldDataType.text &&
            x.textAllowMultiline === true) ||
          x.dataType === ApiWorkflowFieldDataType.system
        )
    );
  }, [visibleFields]);

  const multilineFields = useMemo(() => {
    return visibleFields.filter(
      (x) =>
        x.dataType === ApiWorkflowFieldDataType.text &&
        x.textAllowMultiline === true
    );
  }, [visibleFields]);

  const pdfDocument = useMemo(
    () =>
      isOpen ? (
        <Document>
          <PrintableRequestReport
            account={account}
            request={request}
            laborsData={laborsData as ApiLaborTransaction[]}
            purchasesData={purchasesData as ApiPurchaseTransaction[]}
            issuanceData={issuanceData as ApiIssuanceTransaction[]}
            assignees={assignees}
            ancestors={ancestors}
            singleLineFields={singleLineFields}
            multilineFields={multilineFields}
            totalLaborHours={totalLaborHours}
            hasInventory={
              hasInventory && (isInventoryAdmin || isInventoryStandard)
            }
          />
        </Document>
      ) : null,
    [
      isOpen,
      account,
      request,
      laborsData,
      purchasesData,
      issuanceData,
      assignees,
      ancestors,
      singleLineFields,
      multilineFields,
      totalLaborHours,
      hasInventory,
      isInventoryAdmin,
      isInventoryStandard,
    ]
  );

  return isOpen && pdfDocument ? (
    <PdfViewerModal
      isOpen={isOpen}
      onClose={onClose}
      pdfDocument={pdfDocument}
      downloadName={`HeroHQ_Request_${request.key}.pdf`}
    />
  ) : null;
};
