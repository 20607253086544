import { IconButton } from "@chakra-ui/button";
import { Box } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/menu";
import { Portal } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { RiCalendar2Fill } from "react-icons/ri";
import { useAccountDetails } from "../../../../../../hooks/useAccountDetails";
import { useScreenBreakpoints } from "../../../../../../hooks/useScreenBreakpoints";
import {
  AbsoluteDateFilter,
  DateFields,
  RelativeDateFilter,
  RelativeDateOptions,
} from "../../../../../../store/request-list.slice";
import { MenuCalendar } from "./RangeDates";

const REMOVE_TIME = /[T ]/i;

export type DateFilter = RelativeDateFilter | AbsoluteDateFilter | null;

type RequestDateFilterProps = {
  isDisabled?: boolean;
  onDateFieldChange?: (dateField: DateFields | undefined) => void;
  onDateRangeChange?: (date: DateFilter) => void;
  date?: DateFilter;
};

export const RequestDateFilter: FC<RequestDateFilterProps> = ({
  isDisabled = false,
  onDateFieldChange,
  onDateRangeChange,
  date,
}) => {
  const { isDesktop } = useScreenBreakpoints();
  const [dateRange, setDateRange] = useState("");

  const [dateValue, setDateValue] = useState<DateFilter>(null);
  const [dateFieldValue, setDateFieldValue] = useState<DateFields | null>();

  const { accountDetails } = useAccountDetails();

  const handleSetDateField = useCallback(
    (value: string | string[]) => {
      const dateField = Array.isArray(value) ? value[0] : value;
      if (dateValue) {
        const updatedDate =
          dateValue.type === "relative"
            ? ({
                field: dateField as DateFields,
                type: dateValue.type,
                value: dateValue.value,
              } as RelativeDateFilter)
            : dateValue.isFiscalYear
              ? ({
                  field: dateField as DateFields,
                  type: dateValue.type,
                  value: dateValue.value,
                  isFiscalYear: true,
                  fiscalYear: dateValue.fiscalYear,
                } as AbsoluteDateFilter)
              : ({
                  field: dateField as DateFields,
                  type: dateValue.type,
                  value: dateValue.value,
                  isFiscalYear: false,
                } as AbsoluteDateFilter);

        setDateValue(updatedDate);
      }
      setDateFieldValue(dateField as DateFields);

      onDateFieldChange && onDateFieldChange(dateField as DateFields);
    },
    [dateValue, onDateFieldChange]
  );

  const calculateFiscalYear = useCallback(
    (value: "thisfiscalyear" | "lastfiscalyear") => {
      if (!accountDetails) {
        return [];
      }

      const { fiscalStartMonth } = accountDetails;

      const currentYear = new Date().getFullYear();
      var dateStartThisFiscalYear = new Date(
        currentYear,
        fiscalStartMonth! - 1,
        1
      );
      var dateEndThisFiscalYear = new Date(
        currentYear + 1,
        fiscalStartMonth! - 1,
        0
      );

      var dateStartLastFiscalYear = new Date(
        currentYear - 1,
        fiscalStartMonth! - 1,
        1
      );
      var dateEndLastFiscalYear = new Date(
        currentYear,
        fiscalStartMonth! - 1,
        0
      );

      return value === "thisfiscalyear"
        ? [
            dateStartThisFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
            dateEndThisFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
          ]
        : [
            dateStartLastFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
            dateEndLastFiscalYear.toISOString().split(REMOVE_TIME, 1)[0],
          ];
    },
    [accountDetails]
  );

  const handleDateRelativeChange = useCallback(
    (value: string | string[]) => {
      const range = Array.isArray(value) ? value[0] : value;
      setDateRange(range);

      if (range === "custom") return;

      const field = dateFieldValue
        ? dateFieldValue
        : dateValue && dateValue.field;

      if (field) {
        if (range === "thisfiscalyear" || range === "lastfiscalyear") {
          const absoluteDate: AbsoluteDateFilter = {
            field: field,
            type: "absolute",
            value: calculateFiscalYear(range),
            isFiscalYear: true,
            fiscalYear: range,
          };

          setDateValue(absoluteDate);
          onDateRangeChange && onDateRangeChange(absoluteDate);

          return;
        }

        const relativeDate: RelativeDateFilter = {
          field: field,
          type: "relative",
          value: range as RelativeDateOptions,
        };
        setDateValue(relativeDate);
        onDateRangeChange && onDateRangeChange(relativeDate);
      }
    },
    [calculateFiscalYear, dateFieldValue, dateValue, onDateRangeChange]
  );

  const onCustomDateRangeChange = useCallback(
    (range: string[] | null[]) => {
      if (!dateFieldValue) return;

      const absoluteDate: AbsoluteDateFilter = {
        field: dateFieldValue,
        type: "absolute",
        value: range,
        isFiscalYear: false,
      };

      setDateValue(absoluteDate);
      onDateRangeChange && onDateRangeChange(absoluteDate);
    },
    [onDateRangeChange, dateFieldValue]
  );

  useEffect(() => {
    if (!dateValue) {
      setDateRange("");
      return;
    }

    if (dateValue.type === "absolute" && !dateValue.isFiscalYear) {
      setDateRange("custom");
    } else {
      dateValue.type === "absolute" &&
        dateValue.fiscalYear &&
        setDateRange(dateValue.fiscalYear);

      typeof dateValue.value === "string" && setDateRange(dateValue.value);
    }
  }, [dateValue, dateFieldValue]);

  useEffect(() => {
    setDateFieldValue(date?.field ?? null);
    setDateValue(date ?? null);
  }, [date]);

  return (
    <Box>
      <Menu
        closeOnSelect={false}
        placement={isDesktop ? "right-start" : "bottom"}
        preventOverflow={isDesktop ? false : true}
        flip={isDesktop ? false : true}
        isLazy
      >
        <MenuButton
          as={IconButton}
          icon={<RiCalendar2Fill />}
          aria-label="Field"
          colorScheme="gray"
          variant="outline"
          size={"sm"}
          id="menu-button-relative"
          disabled={isDisabled}
        />

        <Portal>
          <MenuList
            sx={{
              "[role=group]": {
                columnCount: isDesktop ? 2 : 1,
              },
            }}
            overflowY="auto"
          >
            <MenuOptionGroup
              value={dateFieldValue ?? undefined}
              onChange={handleSetDateField}
              type="radio"
            >
              <MenuItemOption value="created">Created</MenuItemOption>
              <MenuItemOption value="due">Due Date</MenuItemOption>
              <MenuItemOption value="start">Start Date</MenuItemOption>
              <MenuItemOption value="updated">Updated</MenuItemOption>
              <MenuItemOption value="completed">Completed</MenuItemOption>
              <MenuItemOption value="statusUpdated">
                Status Updated
              </MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              value={dateRange}
              onChange={handleDateRelativeChange}
              type="radio"
            >
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="last7d"
                closeOnSelect
              >
                Last 7 days
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="next7d"
                closeOnSelect
              >
                Next 7 days
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="last30d"
                closeOnSelect
              >
                Last 30 days
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="next30d"
                closeOnSelect
              >
                Next 30 days
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="thisWeek"
                closeOnSelect
              >
                This week (Sun - Sat)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="lastWeek"
                closeOnSelect
              >
                Last Week(Sun - Sat)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="nextWeek"
                closeOnSelect
              >
                Next Week (Sun - Sat)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="thisMonth"
                closeOnSelect
              >
                This Month (1st- EOM)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="lastMonth"
                closeOnSelect
              >
                Last Month (1st- EOM)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="nextMonth"
                closeOnSelect
              >
                Next month (1st- EOM)
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="thisfiscalyear"
                closeOnSelect
                display={accountDetails?.fiscalStartMonth ? "block" : "none"}
              >
                This Fiscal Year
              </MenuItemOption>
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="lastfiscalyear"
                closeOnSelect
                display={accountDetails?.fiscalStartMonth ? "block" : "none"}
              >
                Last Fiscal Year
              </MenuItemOption>
            </MenuOptionGroup>

            <MenuOptionGroup
              value={dateRange}
              onChange={handleDateRelativeChange}
              type="radio"
            >
              <MenuItemOption
                isDisabled={!dateFieldValue ? true : false}
                value="custom"
              >
                Custom
              </MenuItemOption>
            </MenuOptionGroup>

            <MenuCalendar
              isDisabled={dateRange !== "custom"}
              onCustomDateRangeChange={onCustomDateRangeChange}
              range={
                dateValue
                  ? Array.isArray(dateValue?.value)
                    ? dateValue.value
                    : []
                  : []
              }
            />
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};
