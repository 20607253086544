import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAssetCondition,
  ApiLocationSummary,
  ApiParsedAsset,
  ApiRequestPriority,
  ApiVendor,
  CreateApiAsset,
  ImageParseConfidence,
  Label,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import * as yup from "yup";

import FocusError from "../../../../components/form-helpers/FocusError";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";

import axios, { AxiosProgressEvent } from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Attachment,
  Attachments,
} from "../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { AssetConditionSelectControl } from "../../../../components/form-helpers/AssetConditionSelectControl";
import { DatePickerControl } from "../../../../components/form-helpers/DatePickerControl";
import { LocationAutocompleteControl } from "../../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { QrTextInputControl } from "../../../../components/form-helpers/QrTextInputControl";
import { ReportingCategoryAutocompleteControl } from "../../../../components/form-helpers/ReportingCategoryAutocompleteControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { VendorAutocompleteControl } from "../../../../components/form-helpers/VendorsAutocompleteControl";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState } from "../../../../store";
import { LabelsSection } from "../../location-form/LabelsSection";
import { InfoIcon } from "@chakra-ui/icons";

interface VerifyAssetModalProps {
  parsedAsset: ApiParsedAsset;
  latlong: { lat: string; long: string };
  scannedAttachments: Attachment[];
  manufacturer?: ApiVendor | null;
  onClose: () => void;
}

const CreateAssetWithAIValidationSchema = yup.object().shape({
  name: yup.string().required().max(100),
  location: yup.object().nullable().required("Location is required"),
  manufacturer: yup.object().nullable().required(),
});

const VerifyAssetModal: React.FC<VerifyAssetModalProps> = ({
  parsedAsset,
  latlong,
  scannedAttachments,
  manufacturer,
  onClose,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useShowToast();
  const [labels, setLabels] = useState<Label[] | null>(
    parsedAsset?.labels || []
  );

  const [defaultPhoto, setDefaultPhoto] = useState<Attachment>();
  const { categories } = useSelector((state: RootState) => state.localCache);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );

  const isUploading = useMemo(() => {
    return workingAttachments.some((a) => a.isUploading === true);
  }, [workingAttachments]);

  const certaintyToColor = (certainty: any) => {
    switch (certainty) {
      case ImageParseConfidence.HighlyConfident:
        return "green";
      case ImageParseConfidence.SomewhatConfident:
        return "gold";
      case ImageParseConfidence.Neutral:
        return "blue.400";
      case ImageParseConfidence.SomewhatDoubtful:
        return "blue.400";
      case ImageParseConfidence.HighlyDoubtful:
        return "blue.400";
      default:
        return "gray";
    }
  };

  const handleAddAttachment = useCallback(
    (files: Attachment[]) => {
      let allAttachments = [...workingAttachments];

      files.forEach(async (item) => {
        const response = await apiClient.createUpload(currentAccount.id);
        const newAttachment: Attachment = {
          ...item,
          isNew: true,
          isUploading: true,
          progress: 0,
          uploadId: response.id,
        };
        allAttachments.push(newAttachment);

        setWorkingAttachments(allAttachments);

        await axios.put(response.url, newAttachment.file, {
          headers: { "Content-type": newAttachment.file?.type },
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            newAttachment.progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );

            setWorkingAttachments([...allAttachments]);
          },
        });

        newAttachment.progress = undefined;
        newAttachment.isUploading = false;
        setWorkingAttachments([...allAttachments]);
      });
    },
    [apiClient, currentAccount, workingAttachments]
  );

  const handleDeleteAttachment = useCallback(
    (file: Attachment) => {
      const updatedAttachments = [...workingAttachments];
      const index = updatedAttachments.findIndex(
        (f) => f.uploadId === file.uploadId
      );
      updatedAttachments.splice(index, 1);

      setWorkingAttachments(updatedAttachments);
    },
    [workingAttachments]
  );

  const handleSetLabels = useCallback((newLabels: Label[]) => {
    setLabels(newLabels);
  }, []);

  const getDefaultPhoto = useCallback(
    (attachment: Attachment) => {
      setDefaultPhoto(attachment);
    },
    [setDefaultPhoto]
  );

  const initialValues: CreateApiAsset = useMemo(() => {
    const category = parsedAsset?.category?.value
      ? categories.find((cat) => cat.name === parsedAsset.category.value)
      : null;
    return {
      name: parsedAsset.name?.value || "",
      externalId: parsedAsset.tag?.value || "",
      manufacturer: manufacturer ?? null,
      model: parsedAsset.modelNumber?.value || "",
      serial: parsedAsset.serialNumber?.value || "",
      reportingCategory: category ?? null,
      condition: ApiAssetCondition.fair || "",
      coordinates: {
        latitude: latlong?.lat || "",
        longitude: latlong?.long || "",
      },
      purchaseCost: 0,
      purchaseOrder: "",
      invoiceNumber: "",
      purchaseDate: "",
      inServiceDate: "",
      warrantyExpiration: "",
      targetRenewalDate: "",
      notes: "",
      location: null,
      vendor: null,
      serviceProvider: null,
      defaultImage: null,
      labels: parsedAsset.labels || null,
      renewalCost: 0,
      leased: false,
      rul: 5,
      eul: 5,
      priority: ApiRequestPriority.standard,
    };
  }, [manufacturer, parsedAsset, categories, latlong]);

  const temp = useCallback(
    (isValid: boolean) => {
      if (!isValid) {
        toast("error", "Please ensure all required components are provided.");
      }
    },
    [toast]
  );

  const handleFormikSubmit = useCallback(
    async (values: CreateApiAsset) => {
      const savedAsset = await apiClient.createAsset(currentAccount.id, {
        ...values,
        poweredByAI: true,
        labels,
      });

      const allAttachments = [...workingAttachments, ...scannedAttachments];

      const defaultPicIndex = allAttachments.findIndex(
        (attachment) => attachment.uploadId === defaultPhoto?.uploadId
      );
      if (defaultPicIndex > -1) {
        allAttachments.splice(defaultPicIndex, 1);
      }

      const attachmentSaves = allAttachments.map(async (attachment) => {
        if (attachment.uploadId === undefined) {
          return Promise.resolve(null);
        }

        return await apiClient.createAssetAttachment(
          currentAccount.id,
          savedAsset.id,
          {
            uploadId: attachment.uploadId,
            name: attachment.name,
          }
        );
      });

      const savedAttachments = await Promise.all(attachmentSaves);
      if (defaultPhoto) {
        const queuedDefaultPhoto = await apiClient.createAssetAttachment(
          currentAccount.id,
          savedAsset.id,
          {
            uploadId: defaultPhoto.uploadId ?? "",
            name: defaultPhoto.name,
          }
        );

        await apiClient.updateAsset(currentAccount.id, savedAsset.id, {
          defaultImage: queuedDefaultPhoto,
        });
      } else {
        const firstImage = savedAttachments.find(
          (attachment) => attachment?.contentType.indexOf("image/") === 0
        );

        if (firstImage) {
          await apiClient.updateAsset(currentAccount.id, savedAsset.id, {
            defaultImage: firstImage.id,
          });
        }
      }

      toast("success", "Asset has been created successfully");
      onClose();
      navigate(`/account/assets/${savedAsset.id}`, { replace: true });
    },
    [
      toast,
      apiClient,
      currentAccount.id,
      workingAttachments,
      scannedAttachments,
      labels,
      navigate,
      onClose,
      defaultPhoto,
    ]
  );

  return (
    <>
      <Text fontSize="larger" fontWeight="bold">
        Asset Fields
      </Text>
      <Formik
        onSubmit={handleFormikSubmit}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={CreateAssetWithAIValidationSchema}
      >
        {({ values, isSubmitting, errors, isValid }) => {
          return (
            <Form>
              {step === 1 && (
                <>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    verticalAlign="center"
                    mb={2}
                    gap={6}
                    w="100%"
                  >
                    <GridItem colSpan={6}>
                      <Box display="flex" alignItems="center">
                        <TextInputControl
                          label="Name"
                          value={values.name || ""}
                          name="name"
                        />
                        <Tooltip label={parsedAsset.name.warning || ""}>
                          <Box display="flex" alignItems="center" p={2} mt={7}>
                            {certaintyToColor(parsedAsset.name.certainty) !==
                            "green" ? (
                              <InfoIcon
                                color={certaintyToColor(
                                  parsedAsset.name.certainty
                                )}
                                // size="24px"
                              />
                            ) : null}
                          </Box>
                        </Tooltip>
                      </Box>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Box display="flex" alignItems="center">
                        <QrTextInputControl
                          name="externalId"
                          value={values.externalId || ""}
                          placeholder={parsedAsset.tag.value}
                          label="Tag / Alternate ID"
                          helperText="Reuse existing labels, barcodes or QR codes"
                        />
                        <Tooltip label={parsedAsset.tag.warning || ""}>
                          <Box display="flex" alignItems="center" p={2} mt={7}>
                            {certaintyToColor(parsedAsset.tag.certainty) !==
                            "green" ? (
                              <Box mb={5}>
                                <InfoIcon
                                  color={certaintyToColor(
                                    parsedAsset.tag.certainty
                                  )}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        </Tooltip>
                      </Box>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Box display="flex" alignItems="center" pr={4}>
                        <LocationAutocompleteControl
                          value={values.location as ApiLocationSummary}
                          name="location"
                          label="Location"
                        />
                      </Box>
                    </GridItem>

                    <GridItem colSpan={6}>
                      <Box display="flex" alignItems="center">
                        <Box width="100%">
                          <VendorAutocompleteControl
                            addVendorLink
                            isManufacturer
                            isClearable
                            name="manufacturer"
                            label="Manufacturer"
                            value={
                              values.manufacturer
                                ? (values.manufacturer as ApiVendor)
                                : null
                            }
                            aiSearch={
                              values.manufacturer
                                ? undefined
                                : parsedAsset.manufacturer.value
                            }
                          />
                        </Box>
                        <Tooltip label={parsedAsset.manufacturer.warning || ""}>
                          <Box display="flex" alignItems="center" mt={7} p={2}>
                            {certaintyToColor(
                              parsedAsset.manufacturer.certainty
                            ) !== "green" ? (
                              <InfoIcon
                                color={certaintyToColor(
                                  parsedAsset.manufacturer.certainty
                                )}
                              />
                            ) : null}
                          </Box>
                        </Tooltip>
                      </Box>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Box display="flex" alignItems="center">
                        <TextInputControl
                          label="Serial Number"
                          value={parsedAsset.serialNumber.value}
                          name="serial"
                        />
                        <Tooltip label={parsedAsset.serialNumber.warning || ""}>
                          <Box display="flex" alignItems="center" p={2} mt={7}>
                            {certaintyToColor(
                              parsedAsset.serialNumber.certainty
                            ) !== "green" ? (
                              <InfoIcon
                                color={certaintyToColor(
                                  parsedAsset.serialNumber.certainty
                                )}
                              />
                            ) : null}
                          </Box>
                        </Tooltip>
                      </Box>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Box display="flex" alignItems="center">
                        <TextInputControl
                          label="Model Number"
                          value={parsedAsset.modelNumber.value}
                          name="model"
                        />
                        <Tooltip label={parsedAsset.modelNumber.warning || ""}>
                          <Box display="flex" alignItems="center" p={2} mt={7}>
                            {certaintyToColor(
                              parsedAsset.modelNumber.certainty
                            ) !== "green" ? (
                              <InfoIcon
                                color={certaintyToColor(
                                  parsedAsset.modelNumber.certainty
                                )}
                              />
                            ) : null}
                          </Box>
                        </Tooltip>
                      </Box>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Box display="flex" alignItems="center" width="100%">
                        <ReportingCategoryAutocompleteControl
                          label="Category"
                          name="reportingCategory"
                          value={values.reportingCategory}
                        />

                        <Tooltip label={parsedAsset.category.warning}>
                          <Box display="flex" alignItems="center" p={2} mt={7}>
                            {certaintyToColor(
                              parsedAsset.category.certainty
                            ) !== "green" ? (
                              <InfoIcon
                                color={certaintyToColor(
                                  parsedAsset.category.certainty
                                )}
                              />
                            ) : null}
                          </Box>
                        </Tooltip>
                      </Box>
                    </GridItem>
                  </Grid>

                  <LabelsSection
                    type="asset"
                    value={values.labels}
                    setValues={handleSetLabels}
                  />

                  <Box pt={3} pb={3}>
                    <Attachments
                      getDefaultPhoto={getDefaultPhoto}
                      showThumbnailEdit={true}
                      attachments={workingAttachments}
                      onNewAttachments={handleAddAttachment}
                      onDeleteAttachment={handleDeleteAttachment}
                      title="Attachments"
                    />
                  </Box>
                </>
              )}
              {step === 2 && (
                <VStack gap={4} width="full" align="stretch">
                  <Box pt={2}>
                    <TextEditorControl
                      name="notes"
                      value={""}
                      label="Notes"
                      maxCharacters="2000"
                    />
                  </Box>

                  <Box pb={1}>
                    <AssetConditionSelectControl
                      name="condition"
                      value={ApiAssetCondition.fair}
                      label="Condition"
                    />
                  </Box>
                  <Box pb={1}>
                    <DatePickerControl
                      name="inServiceDate"
                      value={values.inServiceDate}
                      label="In Service Date"
                      placeholder=""
                    />
                  </Box>
                  <Box pb={1}>
                    <DatePickerControl
                      name="warrantyExpiration"
                      value={values.warrantyExpiration}
                      label="Warranty Expires"
                      placeholder=""
                    />
                  </Box>
                  <Box pb={1}>
                    <VendorAutocompleteControl
                      name="vendor"
                      isSupplier
                      label="Supplier"
                      addVendorLink
                      value={null}
                    />
                  </Box>

                  <Box pb={1}>
                    <VendorAutocompleteControl
                      addVendorLink
                      isServiceProvider
                      name="serviceProvider"
                      label="Service Provider"
                      value={null}
                    />
                  </Box>
                  <Box pb={1}>
                    <DatePickerControl
                      name="purchaseDate"
                      value={values.purchaseDate}
                      label="Purchase Date"
                      placeholder=""
                    />
                  </Box>
                  <Box pb={1}>
                    <NumberInputControl
                      prefix="$"
                      name="purchaseCost"
                      value={values.purchaseCost}
                      label="Purchase Cost"
                      placeholder=""
                    />
                  </Box>
                  <Box pb={1}>
                    <DatePickerControl
                      name="targetRenewalDate"
                      value={values.targetRenewalDate}
                      label="Estimated Renewal Date"
                      placeholder=""
                    />
                  </Box>
                  <Box pb={1}>
                    <NumberInputControl
                      prefix="$"
                      name="renewalCost"
                      value={values.renewalCost}
                      label="Estimated Renewal Cost"
                      placeholder=""
                    />
                  </Box>
                  <Box pb={1}>
                    <TextInputControl
                      name="purchaseOrder"
                      value={values.purchaseOrder}
                      label="Purchase Order"
                      placeholder=""
                    />
                  </Box>
                  <Box pb={1}>
                    <TextInputControl
                      name="invoiceNumber"
                      value={values.invoiceNumber}
                      label="Invoice Number"
                      placeholder=""
                    />
                  </Box>
                </VStack>
              )}

              <GridItem colSpan={6}>
                <Box pt={3} pb={3}>
                  <Flex justifyContent="flex-end">
                    {step === 1 && (
                      <Button onClick={() => setStep(2)}>+ More Fields</Button>
                    )}
                    {step === 2 && (
                      <Button onClick={() => setStep(1)}> Back</Button>
                    )}
                    <Spacer />
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={isSubmitting}
                      isDisabled={isUploading || isSubmitting}
                      onClick={() => temp(isValid)}
                      spinner={<Spinner size="sm" />}
                    >
                      Create Asset
                    </Button>
                  </Flex>
                </Box>
              </GridItem>

              <FocusError />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default VerifyAssetModal;
