import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { Draggable } from "@fullcalendar/interaction";
import {
  ApiRequest,
  ApiRequestPriority,
} from "@operations-hero/lib-api-client";
import { FC, useEffect, useMemo, useRef } from "react";
import { RiBarChartHorizontalFill, RiCoinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { RequestKeyBadge } from "../../../../../components/badges/RequestKeyBadge";
import { StatusBadge } from "../../../../../components/badges/StatusBadge";
import { UserColumn } from "../../../../requests/request-row/UserColumn";

type RequestItemProps = {
  request: ApiRequest;
  draggable?: boolean;
};

const ColorMapBase = (colorMode: "light" | "dark") => {
  return {
    [ApiRequestPriority.expedite]: [
      colorMode === "light" ? "red.50" : "rgb(155, 44, 44,0.2)",
      colorMode === "light" ? "red.100" : "red.700",
      colorMode === "light" ? "red.600" : "red.600",
      colorMode === "light" ? "red.700" : "red.600",
      colorMode === "light" ? "red.700" : "red.200",
    ],
    [ApiRequestPriority.important]: [
      colorMode === "light" ? "orange.50" : "rgb(156, 66, 33,0.2)", //background
      colorMode === "light" ? "orange.100" : "orange.700", //sec background
      colorMode === "light" ? "orange.300" : "orange.300", //border
      colorMode === "light" ? "orange.700" : "orange.500", //font
      colorMode === "light" ? "orange.700" : "orange.200", //font
    ],
    [ApiRequestPriority.standard]: [
      colorMode === "light" ? "blue.50" : "rgb(44, 82, 130,0.3)", //background
      colorMode === "light" ? "blue.100" : "blue.500", //sec background
      colorMode === "light" ? "blue.500" : "blue.500", // border
      colorMode === "light" ? "blue.600" : "blue.400", //font
      colorMode === "light" ? "blue.600" : "blue.100", //sec font
    ],
    [ApiRequestPriority.low]: [
      colorMode === "light" ? "gray.200" : "rgb(74, 85, 104,0.3)",
      colorMode === "light" ? "gray.100" : "gray.600",
      colorMode === "light" ? "gray.500" : "gray.500",
      colorMode === "light" ? "gray.600" : "gray.400",
      colorMode === "light" ? "gray.600" : "gray.200",
    ],
  };
};
export const RequestItem: FC<RequestItemProps> = ({
  request,
  draggable = true,
}) => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { status } = request;
  const colorMap = useMemo(() => {
    return ColorMapBase(colorMode);
  }, [colorMode]);

  const elemRef = useRef(null);

  useEffect(() => {
    if (!elemRef.current) return;
    new Draggable(elemRef.current, {
      itemSelector: ".fc-event",
      eventData() {
        return {
          title: request.key,
          ...request,
          duration: {
            days: 3,
          },
          overlap: false,
        };
      },
    });
  }, [request]);

  return (
    <Flex
      w="full"
      border={colorMode === "light" ? "2px solid" : "1px solid"}
      borderColor="gray.300"
      borderRadius="md"
      p={2}
      gap={4}
      fontSize="smaller"
      ref={elemRef}
      className={draggable ? "fc-event" : ""}
      cursor={draggable ? "pointer" : "not-allowed"}
    >
      <Flex flexDir="column" align="stretch" gap={1} flexBasis="20%">
        <StatusBadge
          status={status}
          badgeProps={{ w: "100%", textAlign: "center", fontSize: "xs" }}
        />
        <VStack
          align="stretch"
          borderColor={colorMap[request.priority][2]}
          backgroundColor={colorMap[request.priority][0]}
          p={1}
          borderRadius="md"
          w="100%"
          alignItems="center"
          sx={{
            borderWidth: "0.1rem",
          }}
        >
          <RequestKeyBadge
            request={request}
            fontSize="xs"
            fontWeight="bold"
            color={colorMap[request.priority][3]}
          />
          <Box
            backgroundColor={colorMap[request.priority][1]}
            w="fit-content"
            p={1}
            borderRadius="sm"
          >
            <Text
              fontSize="xs"
              fontWeight="bold"
              color={colorMap[request.priority][4]}
            >
              {request.priority.toUpperCase()}
            </Text>
          </Box>
        </VStack>
      </Flex>
      <Flex
        flexDir="column"
        align="start"
        alignContent="space-around"
        flex="1 1 auto"
      >
        <Text fontWeight="bold">{request.type.toUpperCase()}</Text>
        <Text noOfLines={1}>{request.summary}</Text>
        <HStack color="gray" align="center">
          <Icon as={RiCoinLine} boxSize="5" />
          <Text>{`Cost: $${request.estimatedCost ?? 0}`}</Text>
        </HStack>
        <UserColumn assignees={request.assignees} />
      </Flex>
      <Flex alignItems="center">
        <IconButton
          isRound={true}
          variant="solid"
          colorScheme="gray"
          aria-label="char"
          fontSize="18px"
          size="sm"
          icon={<Icon as={RiBarChartHorizontalFill} color="blue.600" />}
          onClick={() => {
            navigate(`/requests/${request.key}`);
          }}
        />
      </Flex>
    </Flex>
  );
};
