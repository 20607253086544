import {
  ApiClient,
  ApiInventoryLocation,
  ApiPagingOptions,
  CreateApiInventoryLocation,
  UpdateApiInventoryLocation,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface LoadInventoryLocationParams {
  apiClient: ApiClient;
  accountId: string;
}

export const loadInventoryLocations = createAsyncThunk(
  "event/inventory-locations",
  async (params: LoadInventoryLocationParams, thunkAPI) => {
    const { apiClient, accountId } = params;
    const { filters } = (thunkAPI.getState() as RootState)
      .inventoryLocationsSlice;

    const response = await apiClient.findInventoryLocations(accountId, {
      ...filters,
    });

    return response;
  }
);

interface CreateInventoryLocationParams {
  apiClient: ApiClient;
  accountId: string;
  location: CreateApiInventoryLocation;
}

export const createInventoryLocation = createAsyncThunk(
  "event/inventory-locations/new",
  async (params: CreateInventoryLocationParams) => {
    const { apiClient, accountId, location } = params;
    const response = await apiClient.createInventoryLocation(
      accountId,
      location
    );

    return response;
  }
);

interface UpdateInventoryLocationParams {
  apiClient: ApiClient;
  accountId: string;
  inventoryLocationId: string;
  location: UpdateApiInventoryLocation;
}

export const updateInventoryLocation = createAsyncThunk(
  "event/inventory-locations/update",
  async (params: UpdateInventoryLocationParams) => {
    const { apiClient, accountId, location, inventoryLocationId } = params;
    const response = await apiClient.updateInventoryLocation(
      accountId,
      inventoryLocationId,
      location
    );

    return response;
  }
);

interface DeactivateInventoryLocationParams {
  apiClient: ApiClient;
  accountId: string;
  inventoryLocationId: string;
}

export const deactivateInventoryLocation = createAsyncThunk(
  "event/inventory-locations/deactivate",
  async (params: DeactivateInventoryLocationParams) => {
    const { apiClient, accountId, inventoryLocationId } = params;
    await apiClient.deactivateInventoryLocation(accountId, inventoryLocationId);

    return inventoryLocationId;
  }
);

export const reactivateInventoryLocation = createAsyncThunk(
  "event/inventory-locations/reactivate",
  async (params: DeactivateInventoryLocationParams) => {
    const { apiClient, accountId, inventoryLocationId } = params;
    await apiClient.reactivateInventoryLocation(accountId, inventoryLocationId);

    return inventoryLocationId;
  }
);

// INVENTORY-LOCATION-SLICE
export interface InventoryLocationsFilters extends ApiPagingOptions {
  search?: string;
  includeInactive?: boolean;
}

export interface InventoryLocationSlice {
  data: ApiInventoryLocation[];
  total: number;
  filters: InventoryLocationsFilters;
  isOpenFormModal: boolean;
  workingInventoryLocation: ApiInventoryLocation | null;
}

const DEFAULT_FILTERS: InventoryLocationsFilters = {
  page: 1,
  search: "",
  pageSize: 20,
  includeInactive: false,
};

export const inventoryLocationsSlice = createSlice({
  name: "inventoryLocations",
  initialState: {
    data: [],
    total: 1,
    filters: { ...DEFAULT_FILTERS },
    isOpenFormModal: false,
    workingInventoryLocation: null,
  } as InventoryLocationSlice,
  reducers: {
    unloadInventoryLocations: (state: InventoryLocationSlice) => {
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_FILTERS;
      state.isOpenFormModal = false;
    },
    setIsOpenFormModal: (
      state: InventoryLocationSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenFormModal = action.payload;
    },
    setWorkingLocation: (
      state: InventoryLocationSlice,
      action: PayloadAction<ApiInventoryLocation | null>
    ) => {
      state.workingInventoryLocation = action.payload;
    },
    updateFilters: (
      state: InventoryLocationSlice,
      action: PayloadAction<Partial<InventoryLocationsFilters>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadInventoryLocations.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
    });

    builder.addCase(createInventoryLocation.fulfilled, (state, action) => {
      state.data.push(action.payload);
      state.total++;
    });

    builder.addCase(updateInventoryLocation.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    });

    builder.addCase(deactivateInventoryLocation.fulfilled, (state, action) => {
      const index = state.data.findIndex((item) => item.id === action.payload);

      if (index !== -1) {
        state.filters.includeInactive
          ? (state.data[index].active = false)
          : state.data.splice(index, 1);
      }
    });

    builder.addCase(reactivateInventoryLocation.fulfilled, (state, action) => {
      const index = state.data.findIndex((item) => item.id === action.payload);

      if (index !== -1) {
        state.data[index].active = true;
      }
    });
  },
});

export const {
  setIsOpenFormModal,
  unloadInventoryLocations,
  updateFilters,
  setWorkingLocation,
} = inventoryLocationsSlice.actions;

export default inventoryLocationsSlice.reducer;
