import {
  Box,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ApiUserSummary, ApiWorkflow } from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import { MdAdd, MdClose } from "react-icons/md";
import { TbScanEye } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { UserBadge } from "../../components/badges/UserBadge";
import { UserAutocomplete } from "../../components/selects/UserAutocomplete";
import { useShowToast } from "../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../store";
import { saveFollowers } from "../../store/request-form/thunks/followers.thunk";

interface FollowersPopOverProps {
  workflow: ApiWorkflow;
}
export const FollowersPopover: FC<FollowersPopOverProps> = ({ workflow }) => {
  const [displaySearch, setDispalySearch] = useState(false);

  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();
  const { request } = useSelector((state: RootState) => state.requestForm);
  const { policyMap } = useSelector((state: RootState) => state.localCache);
  const { currentUser } = useAuthentication();

  const canAddWatchers = useMemo(() => {
    const isWorkflowAdmin = workflow && policyMap[workflow.id].admin;
    const isWorkflowReviewer = workflow && policyMap[workflow.id].reviewer;
    return isWorkflowAdmin || isWorkflowReviewer;
  }, [policyMap, workflow]);

  const isCurrentUserWatching = useMemo(() => {
    return request?.followedBy.some(
      (follower) => follower.id === currentUser.id
    );
  }, [currentUser.id, request?.followedBy]);

  const handleOnAddFollower = useCallback(
    (value: ApiUserSummary | null) => {
      if (!request || !value) {
        setDispalySearch(false);
        return;
      }

      const followers = [...request.followedBy];
      followers.push(value);
      thunkDispatch(
        saveFollowers({
          accountId: currentAccount.id,
          apiClient,
          requestId: request.id,
          followers,
        })
      )
        .then(() => {
          showToast("success", "Follower added successfully ");
        })
        .catch(() => {
          showToast("error", "Something went wrong adding a follower");
        })
        .finally(() => {
          setDispalySearch(false);
        });
    },
    [apiClient, currentAccount.id, request, showToast, thunkDispatch]
  );

  const handleOnRemoveFollower = useCallback(
    (value: ApiUserSummary | null) => {
      if (!request || !value) return;

      const followers = [...request.followedBy];
      const index = followers.findIndex((user) => user.id === value.id);
      if (index !== -1) {
        followers.splice(index, 1);

        thunkDispatch(
          saveFollowers({
            accountId: currentAccount.id,
            apiClient,
            requestId: request.id,
            followers,
          })
        )
          .then(() => {
            showToast("success", "Follower removed successfully ");
          })
          .catch(() => {
            showToast("error", "Something went wrong removing a follower");
          });
      }
    },
    [apiClient, currentAccount.id, request, showToast, thunkDispatch]
  );

  return (
    <Popover closeOnBlur closeOnEsc>
      <PopoverTrigger>
        <Button
          gap={2}
          size="sm"
          variant="outline"
          colorScheme="blue"
          display="inline-flex"
        >
          <Icon as={TbScanEye} boxSize="18px" />
          {`Watch(${request?.followedBy.length || 0})`}
        </Button>
      </PopoverTrigger>

      <PopoverContent p={2}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader
          borderBottom={
            !canAddWatchers && request && request.followedBy.length === 0
              ? "none"
              : undefined
          }
          _hover={{ cursor: !isCurrentUserWatching && "pointer" }}
          onClick={
            !isCurrentUserWatching
              ? () => handleOnAddFollower(currentUser)
              : undefined
          }
        >
          Start watching
        </PopoverHeader>
        {(canAddWatchers ||
          (request?.followedBy && request.followedBy.length > 0)) && (
          <PopoverBody>
            <Flex flexDir="column" gap={2} justifyContent="flex-start">
              {request && request.followedBy.length > 0 && (
                <Flex flexDir="column" gap={2}>
                  {request.followedBy.map((user) => (
                    <Flex
                      alignItems="center"
                      key={`follower::${user.id}`}
                      justifyContent="space-between"
                    >
                      <UserBadge value={user} />
                      {(canAddWatchers || user.id === currentUser.id) && (
                        <Icon
                          as={MdClose}
                          cursor="pointer"
                          onClick={() => handleOnRemoveFollower(user)}
                        />
                      )}
                    </Flex>
                  ))}
                </Flex>
              )}

              {canAddWatchers && (
                <>
                  {displaySearch ? (
                    <Flex w="100%">
                      <Box w="100%">
                        <UserAutocomplete
                          value={null}
                          placeholder="Search"
                          allowEmpty={false}
                          onChange={handleOnAddFollower}
                          usersToRemoveFromOptions={request?.followedBy}
                        />
                      </Box>
                    </Flex>
                  ) : (
                    <Button
                      pl={0}
                      gap={1}
                      size="sm"
                      variant="ghost"
                      alignSelf="flex-start"
                      onClick={() => setDispalySearch(true)}
                    >
                      <Icon as={MdAdd} />
                      Add Watcher
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </PopoverBody>
        )}
      </PopoverContent>
    </Popover>
  );
};
