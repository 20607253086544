import { Avatar, AvatarGroup, AvatarProps, Text } from "@chakra-ui/react";
import { ApiRequestAssignee } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { UsersBadge } from "./UsersBadge";

type AssigneesBadgeProps = {
  assignees: ApiRequestAssignee[];
  avatarProps?: AvatarProps;
};

export const AssigneesBadge: FC<AssigneesBadgeProps> = ({
  assignees,
  avatarProps,
}) => {
  return (
    <>
      {assignees.length === 0 && (
        <Text fontStyle="italic" color="gray.500" isTruncated>
          Unassigned
        </Text>
      )}

      {assignees.length === 1 && assignees[0].type === "user" && (
        <UsersBadge
          value={assignees[0].assignee}
          label={false}
          avatarProps={{ ...avatarProps, mx: 0 }}
          tagProps={{
            px: 0,
          }}
        />
      )}

      {assignees.length > 1 && (
        <AvatarGroup size="xs" max={4} justifyContent="right">
          {assignees.map((assignee) =>
            assignee.type === "group" ? (
              <Avatar
                key={assignee.assignee.id}
                name={assignee.assignee.name}
              />
            ) : (
              <Avatar
                key={assignee.assignee.id}
                src={assignee.assignee.profileImage}
                name={`${assignee.assignee.firstName} ${assignee.assignee.lastName}`}
                {...avatarProps}
              />
            )
          )}
        </AvatarGroup>
      )}
    </>
  );
};
