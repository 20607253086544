import {
  ApiClient,
  ApiFundingSource,
  ApiPagedResult,
  ApiPagingFundingSourceOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LoadingStatus } from "../types";

type LoadFundingSourcesParams = {
  apiClient: ApiClient;
  filters?: ApiPagingFundingSourceOptions & {
    status?: string | string[];
    supervisor?: string | string[];
    total: number;
  };
};
export const loadFundingSources = createAsyncThunk<
  ApiPagedResult<ApiFundingSource>,
  LoadFundingSourcesParams
>(
  "fundingSources/load",
  async ({ apiClient, filters: filtersToOverride }, thunkAPI) => {
    const { auth, fundingSourceListSlice } = thunkAPI.getState() as RootState;
    const { currentAccount } = auth;

    const { filters } = fundingSourceListSlice;
    const response = await apiClient.findFundingSources(
      currentAccount.id,
      filtersToOverride ? filtersToOverride : filters
    );
    return response;
  }
);

export const updateFilters = createAsyncThunk(
  "funding-source-filters/update",
  (filters: Partial<FundingSourceListSliceProps["filters"]>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { fundingSourceListSlice } = state;
    const updated = {
      ...fundingSourceListSlice.filters,
      ...filters,
    };
    return updated;
  }
);

type SortFilter = Pick<FundingSourceListFilters, "sort" | "direction">;
type UpdateSortFiltersParams = Partial<SortFilter>;

export const updateSortFilters = createAsyncThunk<
  SortFilter,
  UpdateSortFiltersParams
>("funding-source-sort/update", async (delta, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const {
    filters: { sort, direction },
  } = state.fundingSourceListSlice;
  const filtersToUpdate = {
    sort,
    direction,
  };
  if ("sort" in delta) {
    filtersToUpdate.sort = delta.sort;
  }

  if ("direction" in delta) {
    filtersToUpdate.direction = delta.direction;
  }

  return filtersToUpdate;
});

export const clearFilters = createAsyncThunk(
  "funding-source-filters/clear",
  (params, thunkAPI) => {
    const clearedFilters = { ...initialState.filters };
    return clearedFilters;
  }
);

export type FundingSourceListFilters = ApiPagingFundingSourceOptions;

export type FundingSourceListSliceProps = {
  fundingSources: ApiFundingSource[];
  filters: FundingSourceListFilters;
  loadingStatus: LoadingStatus;
  total: number;
};

const initialState: FundingSourceListSliceProps = {
  fundingSources: [],
  loadingStatus: "idle",
  filters: {
    page: 1,
    pageSize: 20,
    includeInactive: false,
    sort: "name",
    direction: "asc",
    search: "",
  },
  total: 0,
};

const fundingSourceListSlice = createSlice({
  name: "funding-sources-list",
  initialState,
  reducers: {
    unload: (state) => {
      state.fundingSources = [];
      state.loadingStatus = "idle";
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFundingSources.pending, (state, action) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(loadFundingSources.rejected, (state, action) => {
      state.loadingStatus = "rejected";
    });
    builder.addCase(loadFundingSources.fulfilled, (state, action) => {
      state.loadingStatus = "fulfilled";
      const { data, total } = action.payload;
      state.fundingSources = data;
      state.filters = {
        ...state.filters,
      };
      state.total = total;
    });
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
    });
    builder.addCase(clearFilters.fulfilled, (state, action) => {
      state.filters = {
        ...action.payload,
      };
    });

    builder.addCase(updateSortFilters.fulfilled, (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    });
  },
});

export const { unload } = fundingSourceListSlice.actions;
export default fundingSourceListSlice.reducer;
