import { Box, Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { FC } from "react";
import { RiFile3Line } from "react-icons/ri";
type DesktopSchedulingProps = {
  children: Array<React.ReactNode>;
  onExpandedCompleted: () => void;
};
export const DesktopScheduling: FC<DesktopSchedulingProps> = ({
  children,
  onExpandedCompleted,
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDir: "row",
          flex: "1",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Flex flexBasis="5%" flexDirection="column">
          <IconButton
            aria-label="requests"
            icon={<RiFile3Line />}
            w="fit-content"
            size="lg"
            onClick={() => {
              onToggle();
            }}
          />
        </Flex>
        <Box
          sx={{
            display: "flex",
            flexDir: "row",
            flex: "1",
            height: "100%",
            overflow: "hidden",
            gap: 2,
          }}
          flexBasis="95%"
        >
          <motion.div
            initial={false}
            animate={{
              flexBasis: isOpen ? "35%" : "0%",
              opacity: isOpen ? 1 : 0,
            }}
            style={{
              display: "flex",
              overflow: "hidden",
              top: "0",
            }}
          >
            {children[0]}
          </motion.div>
          <motion.div
            initial={false}
            animate={{
              flexBasis: isOpen ? "65%" : "100%",
            }}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              height: "100%",
              top: "0",
              flexBasis: "65%",
            }}
            onAnimationComplete={() => {
              onExpandedCompleted();
            }}
          >
            {children[1]}
          </motion.div>
        </Box>
      </Box>
    </>
  );
};
