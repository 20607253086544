import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiPurchaseTransaction } from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { Pager } from "../../../../components/pager/Pager";
import { RootState, useThunkDispatch } from "../../../../store";
import { setPurchasesTransactionsCurrentPage } from "../../../../store/request-form/request-form.slice";
import { loadTransactions } from "../../../../store/request-form/thunks/loadTransactions.thunk";
import { getVisibleFields } from "../../../../utils/getVisibleFields";
import { ExpensesData } from "./ExpensesData";
import { ExpensesModal } from "./ExpensesModal";
import { ScanReceiptModal } from "./ScanReceiptModal/ScanReceiptModal";
import { SparkleWithAnimation } from "../../../../components/icons/Sparkle";

export const PurchasesSection: React.FC = () => {
  const [workingPurchase, setWorkingPurchase] =
    useState<ApiPurchaseTransaction | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isScanReceiptOpen,
    onOpen: onOpenScanReceipt,
    onClose: onCloseScanReceipt,
  } = useDisclosure();
  const dispatch = useDispatch();

  const textColor = useColorModeValue("gradarkslategrey", "white");

  const { purchasesData, purchasesTotal, purchasesCurrentPage } = useSelector(
    (state: RootState) => state.requestForm.transactions.purchases
  );
  const { request, policy, workflow, visibleFields } = useSelector(
    (state: RootState) => state.requestForm
  );

  const { apiClient, currentAccount, isProductAdmin } = useAuthentication();

  const handleCloseModal = () => {
    setWorkingPurchase(null);
    onClose();
  };

  const thunkDispatch = useThunkDispatch();

  const { showExpenses } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const handlePageChange = useCallback(
    async (page: number) => {
      if (!request) {
        return;
      }
      await dispatch(setPurchasesTransactionsCurrentPage(page));
    },
    [dispatch, request]
  );

  const canAdd = useMemo(() => {
    if (!policy) {
      return false;
    }
    return (
      isProductAdmin ||
      policy.admin ||
      policy.reviewer ||
      policy.technician ||
      policy.contractor ||
      false
    );
  }, [policy, isProductAdmin]);

  useEffect(() => {
    if (!request?.key) {
      return;
    }
    thunkDispatch(
      loadTransactions({
        apiClient,
        account: currentAccount,
        key: request.key,
        transactionType: "purchase",
      })
    );
  }, [
    thunkDispatch,
    apiClient,
    currentAccount,
    request?.key,
    purchasesCurrentPage,
  ]);

  return workflow && (purchasesData.length > 0 || showExpenses) ? (
    <>
      <Stack>
        <Grid
          py={2}
          verticalAlign="center"
          templateColumns={["repeat(8, 1fr)"]}
        >
          <GridItem colSpan={5}>
            <Heading size="md">Expenses</Heading>
          </GridItem>
          <GridItem colSpan={3} textAlign="right">
            {isScanReceiptOpen && (
              <ScanReceiptModal
                isOpen={isScanReceiptOpen}
                onClose={onCloseScanReceipt}
              />
            )}

            {canAdd && showExpenses && (
              <HStack>
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant="outline"
                  onClick={onOpenScanReceipt}
                >
                  <SparkleWithAnimation mr={2} boxSize="1em" />
                  Scan Receipt
                </Button>
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant="outline"
                  onClick={onOpen}
                >
                  <Icon mr={2} as={IoAddOutline} />
                  Add Expenses
                </Button>
              </HStack>
            )}
          </GridItem>
        </Grid>
        {purchasesData.length === 0 && (
          <Text pb="6" color={textColor} fontSize="md">
            Add receipts, invoices, or other paid for item.
          </Text>
        )}
        {purchasesData.length > 0 && (
          <ExpensesData
            workingPurchase={workingPurchase}
            setWorkingPurchase={setWorkingPurchase}
            onOpenEditModal={onOpen}
          />
        )}

        {purchasesTotal > 20 && (
          <Box>
            <Pager
              currentPage={purchasesCurrentPage || 1}
              total={purchasesTotal}
              pageSize={20}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Stack>
      <ExpensesModal
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        workingPurchase={workingPurchase}
        purchaseId={workingPurchase?.id || null}
      />
    </>
  ) : null;
};
