import {
  Container,
  Flex,
  HStack,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { FC } from "react";
import { useNavProductItems } from "../../../../hooks/useNavProductItems";
import { useAuthentication } from "../../../auth/AuthProvider";
import { AccountMenu } from "../AccountMenu";
import Logo from "../Logo";
import LogoSmall from "../Logo.sm";
import { LargeProfileButton } from "../ProfileButton.lg";
import { SmallProfileButton } from "../ProfileButton.sm";
import { NavActionItems } from "./DesktopNavActionItems";
import { NavProductItems } from "./DesktopNavProductItems";

type DesktopNavProps = {};
export const DesktopNav: FC<DesktopNavProps> = () => {
  const { currentAccount } = useAuthentication();
  const { navItems } = useNavProductItems();

  const [isDesktopSm, isDesktopMd, isDesktopLg] = useMediaQuery([
    "(min-width: 1024px)",
    "(min-width: 1280px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  return (
    <>
      <Container maxW="8xl" px={5} py={0} minH={"60px"}>
        <HStack maxW="8xl" minH={"60px"} py={1}>
          {isDesktopSm && !isDesktopMd && !isDesktopLg && (
            <Flex flex={1}>
              {navItems.length < 4 ? (
                <Flex flexBasis="60%" alignItems="center" gap={4}>
                  <LogoSmall />
                  <NavProductItems />
                </Flex>
              ) : (
                <HStack gap={4}>
                  <LogoSmall />
                  <NavProductItems />
                </HStack>
              )}

              <Flex gap={4} flex={1} alignItems="center">
                <NavActionItems mediaQueries={[true, false]} />
                <AccountMenu mediaQueries={[false, false, true]}>
                  {(user) => <SmallProfileButton user={user} />}
                </AccountMenu>
              </Flex>
            </Flex>
          )}

          {isDesktopMd && (
            <Flex flex={1}>
              {navItems.length < 4 ? (
                <Flex flexBasis="60%" alignItems="center" gap={4}>
                  <Logo />
                  <NavProductItems />
                </Flex>
              ) : (
                <HStack gap={4}>
                  <Logo />
                  <NavProductItems />
                </HStack>
              )}
              <Flex gap={4} flex={1} alignItems="center">
                <NavActionItems mediaQueries={[false, true]} />
                {currentAccount.logo ? (
                  <Image
                    src={currentAccount.logo}
                    alt="Logo"
                    maxH="40px"
                    maxW="80px"
                  />
                ) : null}
                <AccountMenu mediaQueries={[false, false, true]}>
                  {(user) => <SmallProfileButton user={user} />}
                </AccountMenu>
              </Flex>
            </Flex>
          )}

          {isDesktopLg && (
            <Flex flex={1}>
              {navItems.length < 4 ? (
                <Flex flexBasis="50%" alignItems="center" gap={4}>
                  <Logo />
                  <NavProductItems />
                </Flex>
              ) : (
                <HStack gap={4}>
                  <Logo />
                  <NavProductItems />
                </HStack>
              )}
              <Flex gap={4} flex={1} alignItems="center">
                <NavActionItems mediaQueries={[false, false, true]} />
                {currentAccount.logo ? (
                  <Image
                    src={currentAccount.logo}
                    alt="Logo"
                    maxH="40px"
                    maxW="80px"
                    justifySelf="end"
                  />
                ) : null}
                <AccountMenu mediaQueries={[false, false, true]}>
                  {(user) => <LargeProfileButton user={user} />}
                </AccountMenu>
              </Flex>
            </Flex>
          )}
        </HStack>
      </Container>
    </>
  );
};
