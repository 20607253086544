import {
  ApiAccount,
  ApiAsset,
  ApiComment,
  ApiLocation,
  ApiRequest,
  ApiRequestTaskBookDetail,
  ApiWorkflow,
  ApiWorkflowField,
} from "@operations-hero/lib-api-client";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import {
  PdfStyles,
  PrintableRequest,
} from "../../../request-form/print/PrintableRequest";
import { PrintableRequestFields } from "../../../request-form/print/PrintableRequestFields";
import { BulkPrintFormat } from "./BulkPrintFormat";
import OpenSansBold from "./fonts/OpenSans-Bold.ttf";
import OpenSansBoldItalic from "./fonts/OpenSans-BoldItalic.ttf";
import OpenSansItalic from "./fonts/OpenSans-Italic.ttf";
import OpenSansRegular from "./fonts/OpenSans-Regular.ttf";
import {
  PrintableRequestTableRows,
  tableStyles,
} from "./PrintableRequestTableRows";

export interface RequestComments extends ApiRequest {
  comments: ApiComment[];
}

export interface BulkPrintPdfDocumentProps {
  format: BulkPrintFormat;
  requests: RequestComments[];
  assetsMap: Record<string, ApiAsset[]>;
  taskbooksMap: Record<string, ApiRequestTaskBookDetail[]>;
  ancestorsAssets: Record<string, ApiLocation[]>;
  ancestors: Record<string, ApiLocation[]>;
  account: ApiAccount;
  visibleFieldMap: Record<string, ApiWorkflowField[]>;
  workflowMap: Record<string, ApiWorkflow>;
}

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: OpenSansRegular,
    },
    {
      src: OpenSansBold,
      fontWeight: 600,
    },
    {
      src: OpenSansItalic,
      fontStyle: "italic",
    },
    {
      src: OpenSansBoldItalic,
      fontWeight: 600,
      fontStyle: "italic",
    },
    // Add more font weights and styles if necessary for other markdown styles
  ],
});
export const slimStyles = StyleSheet.create({
  footer: {
    marginBottom: 5,
    width: "100%",
    fontSize: 8,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
});
export const BulkPrintPdfDocument = ({
  format,
  requests,
  ancestors,
  account,
  visibleFieldMap,
  workflowMap,
  assetsMap,
  taskbooksMap,
  ancestorsAssets,
}: BulkPrintPdfDocumentProps) => {
  const groupedArray = requests.reduce(
    (result: RequestComments[][], currentValue, currentIndex) => {
      if (currentIndex % 2 === 0) {
        result.push([currentValue]);
      } else {
        result[result.length - 1].push(currentValue);
      }
      return result;
    },
    new Array<RequestComments[]>()
  );

  return (
    <Document>
      {format === "slim" && (
        <>
          {groupedArray.map((request, idx) => {
            return (
              <Page size="A4" style={PdfStyles.page}>
                <View
                  fixed
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    marginBottom: 5,
                    alignItems: "center",
                  }}
                >
                  <View style={{ flexGrow: 1 }}>
                    <Text
                      render={({ pageNumber, totalPages }) =>
                        `Page: ${pageNumber} / ${totalPages}`
                      }
                    />
                  </View>
                  <View style={{ maxHeight: 40, textAlign: "right" }}>
                    {!account.logo && (
                      <Text style={{ fontSize: 14, textAlign: "right" }}>
                        {account.name}
                      </Text>
                    )}
                    {account.logo && (
                      <Image
                        src={account.logo}
                        style={{
                          maxWidth: 80,
                          maxHeight: 40,
                          minHeight: 40,
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    )}
                  </View>
                </View>
                {request[0] && (
                  <View key={`akey${idx}0`}>
                    <PrintableRequestFields
                      key={request[0].id}
                      request={request[0]}
                      ancestors={ancestors[request[0].id] || []}
                      visibleFields={visibleFieldMap[request[0].id] || []}
                      format={"slim"}
                      workflow={workflowMap[request[0].workflow.id]}
                    />
                  </View>
                )}
                {request[1] && (
                  <View key={`akey${idx}1`} wrap={false}>
                    <PrintableRequestFields
                      key={request[1].id}
                      request={request[1]}
                      ancestors={ancestors[request[1].id] || []}
                      visibleFields={visibleFieldMap[request[1].id] || []}
                      format={"slim"}
                      workflow={workflowMap[request[1].workflow.id]}
                    />
                  </View>
                )}
                <View style={PdfStyles.footer} fixed>
                  <View style={{ flexDirection: "column" }}>
                    <Text
                      render={({ subPageNumber, subPageTotalPages }) => {
                        return request[0] &&
                          request[1] &&
                          subPageTotalPages === 1
                          ? `${new Date(
                              request[0].created
                            ).toLocaleString()} (${
                              request[0].key
                            }) \n ${new Date(
                              request[1].created
                            ).toLocaleString()} (${request[1].key})`
                          : `${new Date(
                              request[subPageNumber - 1]?.created
                            ).toLocaleString()} (${
                              request[subPageNumber - 1]?.key
                            })`;
                      }}
                    />
                  </View>
                  <Text>HeroHQ by OperationsHero</Text>
                  <Text>{new Date().toLocaleString()}</Text>
                </View>
              </Page>
            );
          })}
        </>
      )}

      {format === "full" && (
        <>
          {requests.map((request) => (
            <PrintableRequest
              key={request.id}
              account={account}
              request={request}
              assets={assetsMap[request.id]}
              taskbooks={taskbooksMap[request.id]}
              ancestorsAssets={ancestorsAssets}
              ancestors={ancestors[request.id] || []}
              visibleFields={visibleFieldMap[request.id] || []}
              format={"full"}
              comments={request.comments}
              workflow={workflowMap[request.workflow.id]}
            />
          ))}
        </>
      )}
      {format === "listing" && (
        <Page size="A4" style={tableStyles.page} orientation="landscape">
          <View style={PdfStyles.header}>
            <View style={PdfStyles.headerContainer}>
              <View style={PdfStyles.headerLogo}>
                {!account.logo && (
                  <Text style={{ fontSize: 14, textAlign: "right" }}>
                    {account.name}
                  </Text>
                )}
                {account.logo && (
                  <Image
                    src={account.logo}
                    style={{
                      width: "auto",
                      height: "auto",
                      alignSelf: "flex-end",
                    }}
                  />
                )}
              </View>
            </View>
          </View>

          <View style={tableStyles.mainContainer}>
            {/* table header */}
            <View style={tableStyles.tableRow} fixed>
              <Text style={tableStyles.headerCell}>Request ID</Text>
              <Text style={tableStyles.headerCell}>Requester</Text>
              <Text style={tableStyles.headerCell}>Status</Text>
              <Text style={tableStyles.headerCell}>Type</Text>
              <Text style={tableStyles.headerCell}>Category</Text>
              <Text style={tableStyles.headerCell}>Priority</Text>
              <Text
                style={{ ...tableStyles.assignedToCell, fontWeight: "bold" }}
              >
                Assigned To
              </Text>
              <Text style={{ ...tableStyles.locationCell, fontWeight: "bold" }}>
                Location
              </Text>
              <Text
                style={{ ...tableStyles.descriptionCell, fontWeight: "bold" }}
              >
                Description
              </Text>
              <Text style={tableStyles.headerCell}>Start</Text>
              <Text style={tableStyles.headerCell}>Due</Text>
            </View>

            {/* table rows */}
            <View style={tableStyles.tableContainer}>
              {requests.map((request) => (
                <PrintableRequestTableRows
                  key={`request::${request.id}`}
                  request={request}
                  ancestors={ancestors}
                />
              ))}
            </View>
          </View>
          {/*   footer*/}
          <View style={tableStyles.footer} fixed>
            <Text style={{ fontSize: 8, textAlign: "left" }}>
              HeroHQ by OperationsHero
            </Text>
            <Text
              style={{
                textAlign: "right",
                fontSize: 8,
              }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
        </Page>
      )}
    </Document>
  );
};
