import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import React, { FC } from "react";
import { CountryPhoneCodeSelectControl } from "../form-helpers/CountryPhoneCodeSelectControl";
import { PhoneInputControl } from "../form-helpers/PhoneInputControl";
import { PhoneVerificationFormParams } from "./PhoneVerificationForm";

type StepOneProps = {
  values: PhoneVerificationFormParams;
};

type StepOneSubmitButtonProps = {
  isLoading: boolean;
  onClick: () => void;
  isDisabled: boolean;
};

export const StepOne: FC<StepOneProps> = ({ values }) => {
  return (
    <VStack gap="2" w="full" align="stretch">
      <Text>
        Please verify your phone number so we can confirm and secure your
        account
      </Text>
      <HStack alignItems="start">
        <Box w="28%">
          <CountryPhoneCodeSelectControl
            label="Country"
            name="countryCode"
            value={values.countryCode}
          />
        </Box>
        <PhoneInputControl name="phone" value={values.phone} label="Phone" />
      </HStack>
      <Text>Message and data rates may apply.</Text>
    </VStack>
  );
};

export const StepOneSubmitButton: FC<StepOneSubmitButtonProps> = ({
  isLoading,
  onClick,
  isDisabled,
}) => {
  return (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      isDisabled={isDisabled}
      colorScheme="blue"
    >
      Send code
    </Button>
  );
};
