import {
  Icon,
  MenuDivider,
  MenuItem,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { BsArrowDownUp, BsClock } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { RiFlashlightLine } from "react-icons/ri";
import { VscArrowSwap } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useIsReviewerOrTechnicianOfWorkflows } from "../../../pages/account-settings/routes/routes-helpers/policies-checks";
import { useIsAllowManageAssetForTech } from "../../../pages/account-settings/routes/routes-helpers/settings-checks";
import { RootState } from "../../../store";
import { useAuthentication } from "../../auth/AuthProvider";
import { ProductMenu } from "./ProductMenu";

export interface MenuType {
  route: string;
  text: string;
}

type SmallMegaMenuProps = {
  onProductItemSelected: () => void;
  onOpenSwitchModal: () => void;
};
export const SmallMegaMenu: FC<SmallMegaMenuProps> = ({
  onProductItemSelected,
  onOpenSwitchModal,
}) => {
  const { accounts, currentAccount, isProductAdmin } = useAuthentication();
  const {
    isAdmin,
    isReviewer,
    isTechnician,
    isContractor,
    eventsHandler,
    policyAllowsDashboard,
  } = useSelector((state: RootState) => state.localCache);

  const { isVenueReadOnlyViewer } = eventsHandler.venueReadOnly;

  const { colorMode, toggleColorMode } = useColorMode();
  const iconsColor = useColorModeValue("blue.500", "blue.300");
  const schoolNameColor = useColorModeValue("gray.500", "whiteAlpha.800");

  const handleColorModeChange = () => {
    toggleColorMode();
  };

  const { isReviewerOrTech } = useIsReviewerOrTechnicianOfWorkflows();
  const { isAllowManageAssetForTech } = useIsAllowManageAssetForTech();

  const showTransactions = useMemo(
    () => policyAllowsDashboard || isProductAdmin || isAdmin || isReviewer,
    [policyAllowsDashboard, isProductAdmin, isAdmin, isReviewer]
  );

  const showTimesheet = useMemo(
    () =>
      isProductAdmin || isAdmin || isReviewer || isContractor || isTechnician,
    [isProductAdmin, isAdmin, isReviewer, isContractor, isTechnician]
  );

  const showAssets = useMemo(() => {
    return (
      isReviewerOrTech &&
      isAllowManageAssetForTech &&
      !isProductAdmin &&
      !isVenueReadOnlyViewer
    );
  }, [
    isAllowManageAssetForTech,
    isProductAdmin,
    isReviewerOrTech,
    isVenueReadOnlyViewer,
  ]);

  return (
    <>
      <Text px={4} color={schoolNameColor} fontWeight="bold">
        {currentAccount.name}
      </Text>
      <MenuDivider />
      <MenuItem onClick={handleColorModeChange}>
        {colorMode === "light" ? (
          <>
            <Icon mr={2} as={RiFlashlightLine} color={iconsColor} />
            <Text>Switch to dark mode</Text>
          </>
        ) : (
          <>
            <Icon as={RiFlashlightLine} mr={2} color={iconsColor} />
            <Text>Switch to light mode</Text>
          </>
        )}
      </MenuItem>

      <MenuItem as={RouterLink} to="/user-settings">
        <Icon as={IoSettingsOutline} mr={2} color={iconsColor} />
        <Text>User Profile</Text>
      </MenuItem>
      {accounts.length > 1 && (
        <MenuItem onClick={onOpenSwitchModal}>
          <Icon as={VscArrowSwap} mr={2} color={iconsColor} />
          Switch Account
        </MenuItem>
      )}
      {showTransactions && (
        <MenuItem as={RouterLink} to="/transactions">
          <Icon as={BsArrowDownUp} mr={1} color={iconsColor} />
          Transactions
        </MenuItem>
      )}

      {showTimesheet && (
        <MenuItem as={RouterLink} to="/timesheet">
          <Icon as={BsClock} mr={1} color={iconsColor} />
          Timesheet
        </MenuItem>
      )}
      <MenuDivider m={1} />
      <ProductMenu onClose={onProductItemSelected} />
      {showAssets && (
        <>
          <MenuItem as={RouterLink} to={"/account/assets"}>
            Assets
          </MenuItem>
          <MenuDivider m={1} />
        </>
      )}
      <MenuItem
        as="a"
        href={`${process.env.REACT_APP_AUTH_SERVER}/logout?redirectUrl=/login?accountId=${currentAccount.id}`}
      >
        <Icon as={FiLogOut} mr={2} color={iconsColor} />
        Logout
      </MenuItem>
    </>
  );
};
