import {
  ApiClient,
  ApiWorkflowAutoAssignRules,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface LoadWorkflowAutoAssignRulesThunkParams {
  apiClient: ApiClient;
  accountId: string;
  idOrSlug: string;
}

export const loadWorkflowAutoAssignRules = createAsyncThunk(
  "workflow-auto-assign-rules",
  async (params: LoadWorkflowAutoAssignRulesThunkParams) => {
    const { apiClient, accountId, idOrSlug } = params;

    const response = await apiClient.findWorkflowAutoAssignRules(
      accountId,
      idOrSlug
    );
    return response;
  }
);

export interface WorkflowAutoAssignRulesListState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: ApiWorkflowAutoAssignRules[];
}

export const workflowAutoAssignRulesList = createSlice({
  name: "workflowAutoAssignRulesList",
  initialState: {
    loading: "idle",
    data: [],
  } as WorkflowAutoAssignRulesListState,
  reducers: {
    unloadInvoices: (state: WorkflowAutoAssignRulesListState) => {
      state.loading = "idle";
      state.data = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadWorkflowAutoAssignRules.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(loadWorkflowAutoAssignRules.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadWorkflowAutoAssignRules.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const { unloadInvoices } = workflowAutoAssignRulesList.actions;

export default workflowAutoAssignRulesList.reducer;
