import { Box, Checkbox, Text, useColorModeValue } from "@chakra-ui/react";
import { LaborType } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import Select, {
  SelectItemRenderer,
  SelectRenderer,
} from "react-dropdown-select";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";

export interface TypeFilterProps {
  value: (LaborType | string)[];
  onChange: (value: LaborType[]) => void;
}

type ValueLabel = {
  value: LaborType;
  label: LaborType;
};

const ContentRenderer = ({ props, state }: SelectRenderer<ValueLabel>) => {
  const textColor = useColorModeValue("blue.500", "white");
  return (
    <Box p={1}>
      {state.values.length === 0 && "Labor Type"}
      {state.values.length === 1 && (
        <Text color={textColor} fontSize="14px" fontWeight="semibold">
          {state.values[0].value.toUpperCase()}
        </Text>
      )}
      {state.values.length > 1 && (
        <>
          <Text color={textColor} fontSize="14px" fontWeight="semibold">
            {state.values[0].value.toUpperCase()}
            <AdditionalNumberBadge
              number={state.values.length - 1}
              tagProps={{ ml: 1 }}
            />
          </Text>
        </>
      )}
    </Box>
  );
};

const ItemRenderer = ({
  item,
  itemIndex,
  props,
  state,
  methods,
}: SelectItemRenderer<ValueLabel>) => (
  <Checkbox
    key={item.value}
    isChecked={methods.isSelected(item)}
    onChange={() => methods.addItem(item)}
    w="100%"
    p={2}
  >
    <Text color="blue.500" fontSize="14px" fontWeight="semibold">
      {item.value.toUpperCase()}
    </Text>
  </Checkbox>
);

export const TypeFilterLabor = ({ value, onChange }: TypeFilterProps) => {
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const options = useMemo(
    () =>
      [
        LaborType.regular,
        LaborType.overtime,
        LaborType.holiday,
        LaborType.comp,
        LaborType.travel,
      ].map<ValueLabel>((x) => ({
        value: x,
        label: x,
      })),
    []
  );

  const internalValues = useMemo(() => {
    return value && value.length > 0
      ? options.filter((x) => value.includes(x.value))
      : [];
  }, [value, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange]
  );

  return (
    <Select
      multi
      className={themeClass}
      options={options}
      values={internalValues}
      onChange={handleChange}
      searchable={true}
      searchBy="value"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      contentRenderer={ContentRenderer}
      dropdownHeight="300"
      itemRenderer={ItemRenderer}
    />
  );
};
