import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { priorityColorMap } from "../../../components/badges/PriorityBadge";
import { RequestKeyBadge } from "../../../components/badges/RequestKeyBadge";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { UserColumn } from "./UserColumn";

export const InfoColumn = ({
  request,
  typeColor,
  isMini,
}: {
  request: ApiRequest;
  typeColor: string;
  isMini?: boolean;
}) => (
  <HStack spacing={5} width={isMini ? "240px" : "310px"}>
    <VStack width="120px">
      <StatusBadge
        status={request.status}
        badgeProps={{ w: "100%", textAlign: "center" }}
      />
      <Box
        bgColor={priorityColorMap[request.priority]}
        p={2}
        mt="1"
        borderRadius="md"
        w="100%"
      >
        <Text
          color="white"
          fontWeight="bold"
          alignItems="center"
          display="flex"
        >
          <RequestKeyBadge
            request={request}
            color="white"
            fontSize={request.key.length > 8 ? "xs" : "sm"}
          />
        </Text>
        <Box bgColor="white" mt={5} borderRadius="md">
          <Text
            color={priorityColorMap[request.priority]}
            fontSize="xs"
            fontWeight="bold"
            textAlign="center"
          >
            {request.priority.toUpperCase()}
          </Text>
        </Box>
      </Box>
    </VStack>

    <Box
      flex={1}
      maxWidth={isMini ? "120px" : "190px"}
      height="110px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box pt={0}>
        <UserColumn assignees={request.assignees} />
      </Box>
      <Text color={typeColor} fontSize="sm" fontWeight="semibold" isTruncated>
        {request.type.toUpperCase()}
      </Text>

      <Text
        fontSize="14px"
        fontWeight="semibold"
        isTruncated
        fontStyle={request.reportingCategory ? undefined : "italic"}
        color={request.reportingCategory ? undefined : "gray.500"}
      >
        {request.reportingCategory
          ? request.reportingCategory.name
          : "No category"}
      </Text>

      <UserBadge value={request.requester} />
    </Box>
  </HStack>
);
