import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import {
  ApiBillingGroupType,
  ApiServiceSumary,
  ApiSpace,
  ApiSpaceService,
  ApiVenue,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { ApiVenueServiceReference } from "@operations-hero/lib-api-client/dist/models/VenueService";
import { differenceInMinutes } from "date-fns";
import { useFormikContext } from "formik";
import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useBillingGroupRates } from "../../../../hooks/useBillingGroupRates";
import { EventFormValues } from "../EventForm";
import { ServicesMulticheckItem } from "./ServiceMulticheckItem";

export interface EventServiceData extends ApiVenueServiceReference {
  id?: string;
  spaceId?: string;
  spaceName?: string;
  isRequired: boolean;
  additionalNotes?: string;
}

interface ServicesMulticheckProps {
  venue: ApiVenue | null;
  firstOccurrence: CreateApiEventOccurrence;
  setServicesCost: Dispatch<SetStateAction<number>>;
  occurrences: CreateApiEventOccurrence[];
  setServicesSummary?: React.Dispatch<React.SetStateAction<ApiServiceSumary[]>>;
}

export const ServicesMulticheck: FC<ServicesMulticheckProps> = ({
  firstOccurrence,
  venue,
  setServicesCost,
  occurrences,
  setServicesSummary,
}) => {
  const [services, setServices] = useState<EventServiceData[]>([]);
  const { values, setFieldValue } = useFormikContext<EventFormValues>();
  const { currentAccount, apiClient } = useAuthentication();

  const rateGroupId = useMemo(() => {
    if (!values) return;
    if (!values.eventGroup || !values.eventGroup.rateGroup) return;
    return values.eventGroup.rateGroup.id;
  }, [values]);

  const { getItemRates, getAllRatesByItemId } = useBillingGroupRates({
    rateGroupId,
    type: ApiBillingGroupType.service,
  });

  const mapServices = useCallback(() => {
    if (!venue) return [] as EventServiceData[];
    return venue.services.map((service) => {
      const initialServiceChecked = values.services[service.id];
      if (!!initialServiceChecked) {
        return {
          id: initialServiceChecked.service.id,
          service: initialServiceChecked,
          assignees: [],
          isRequired: true,
          additionalNotes: initialServiceChecked.additionalNotes || undefined,
        };
      }

      return {
        ...service,
        additionalNotes: "",
      };
    });
  }, [values.services, venue]);

  const mapSpacesServices = useCallback(() => {
    if (!venue || values.spaces.length === 0) return [];

    const venueServicesIds = venue.services.map(
      (venueService) => venueService.service.id
    );

    const spacesHash = venue.spaces.reduce(
      (hash, space) => {
        hash[space.id] = space;
        return hash;
      },
      {} as Record<string, ApiSpace>
    );

    const spaceServices: ApiSpaceService[] = [];
    values.spaces.forEach((space) => {
      spaceServices.push(...space.services);
    });

    const filteredServices = spaceServices.filter((spaceService) => {
      return !venueServicesIds.some((vs) => vs === spaceService.service.id);
    }, []);

    const formatedServices = filteredServices.map((s) => ({
      id: s.service.id,
      service: s.service,
      isRequired: s.isRequired,
      spaceId: s.spaceId,
      assignees: s.assignees,
      additionalNotes: undefined,
      spaceName: spacesHash[s.spaceId].location.name,
    }));

    const servicesMap = new Map(
      formatedServices.map((service) => [service.service.id, service])
    );

    const uiqueServices: EventServiceData[] = [];
    servicesMap.forEach((item) => uiqueServices.push(item));
    return uiqueServices;
  }, [values.spaces, venue]);

  useEffect(() => {
    const services = mapServices();
    const spaceServices = mapSpacesServices();
    const newServices = [...services, ...spaceServices];
    setServices(newServices as EventServiceData[]);
  }, [mapServices, mapSpacesServices]);

  const handleOnSelectService = useCallback(
    (item: EventServiceData) => {
      const { service } = item;
      const key = `${service.id}${item.spaceId ? `:${item.spaceId}` : ""}`;

      const servicesCopy = { ...values.services };
      if (!!values.services[key]) {
        delete servicesCopy[key];
        setFieldValue("services", servicesCopy);
        return;
      }

      servicesCopy[key] = item;
      setFieldValue("services", servicesCopy);
    },
    [setFieldValue, values.services]
  );

  const handleOnChangeAdditionalNotes = useCallback(
    (value: string, item: EventServiceData) => {
      const { service } = item;
      const key = `${service.id}${item.spaceId ? `:${item.spaceId}` : ""}`;
      if (!!values.services[key]) {
        const serviceCopy = {
          ...values.services[key],
        };
        serviceCopy.additionalNotes = value;
        const newServices = { ...values.services };
        newServices[key] = { ...serviceCopy };

        setFieldValue("services", newServices);
      }
    },
    [setFieldValue, values.services]
  );

  const eventHoursDuration = useMemo(() => {
    const startDate = new Date(firstOccurrence.start);
    const endDate = new Date(firstOccurrence.end);

    const occurrenceMinutes = differenceInMinutes(endDate, startDate);
    const hours = parseFloat((occurrenceMinutes / 60).toFixed(2));

    return hours;
  }, [firstOccurrence.end, firstOccurrence.start]);

  const setServicesTotalCost = useCallback(() => {
    const total = Object.values(values.services).reduce((acc, current) => {
      const rate = getItemRates(
        current.service.id,
        true,
        occurrences,
        eventHoursDuration
      );
      const equipmentCost = Array.isArray(rate)
        ? rate.reduce((sum, r) => {
            sum = sum + r.total;
            return sum;
          }, 0)
        : 0;
      return acc + equipmentCost;
    }, 0);

    setServicesCost(total);
  }, [
    values.services,
    setServicesCost,
    getItemRates,
    occurrences,
    eventHoursDuration,
  ]);

  useEffect(() => {
    setServicesTotalCost();
  }, [setServicesTotalCost]);

  const venueServices = useMemo(
    () => services.filter((s) => !s.spaceId),
    [services]
  );

  const spaceServices = useMemo(
    () => services.filter((s) => s.spaceId),
    [services]
  );

  useEffect(() => {
    if (setServicesSummary) {
      const servicesData = Object.values(values.services).filter((s) => s.id);
      const servicesIds = servicesData.map((s) => s.service.id).filter(Boolean);
      if (!servicesIds || servicesIds.length === 0) return;
      apiClient
        .findServices(currentAccount.id, {
          ids: servicesIds,
          includeQuestions: true,
        })
        .then((response) => {
          setServicesSummary(response.data);
        });
    }
  }, [apiClient, currentAccount.id, setServicesSummary, values.services]);

  return (
    <Stack gap={2}>
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        {venueServices.map(
          (item, idx) =>
            item.service && (
              <Fragment key={item.service.id}>
                <ServicesMulticheckItem
                  item={item}
                  isSelected={!!values.services[item.service.id]}
                  eventHoursDuration={eventHoursDuration}
                  handleOnSelectService={handleOnSelectService}
                  additonalNotesChange={handleOnChangeAdditionalNotes}
                  getItemRates={getItemRates}
                  occurrences={occurrences}
                  getAllRatesByItemId={getAllRatesByItemId}
                />
              </Fragment>
            )
        )}
      </Grid>

      {spaceServices.length > 0 && (
        <Flex flexDir="column" gap={4}>
          <Text fontSize="lg">Spaces Services</Text>

          {spaceServices.map(
            (item, idx) =>
              item.service && (
                <Fragment key={item.service.id}>
                  <ServicesMulticheckItem
                    item={item}
                    eventHoursDuration={eventHoursDuration}
                    handleOnSelectService={handleOnSelectService}
                    additonalNotesChange={handleOnChangeAdditionalNotes}
                    getItemRates={getItemRates}
                    occurrences={occurrences}
                    getAllRatesByItemId={getAllRatesByItemId}
                    isSelected={
                      !!values.services[`${item.service.id}:${item.spaceId}`]
                    }
                  />
                </Fragment>
              )
          )}
        </Flex>
      )}
    </Stack>
  );
};
