import { useBreakpointValue } from "@chakra-ui/react";

export const useScreenBreakpoints = () => {
  const isMobile = useBreakpointValue(
    {
      base: true,
      xs: true,
      sm: true,
      md: false,
    },
    {
      ssr: false,
    }
  );

  const isTablet = useBreakpointValue(
    {
      base: false,
      sm: false,
      md: true,
      lg: false,
    },
    {
      ssr: false,
    }
  );

  const isDesktop = useBreakpointValue(
    {
      base: false,
      sm: false,
      md: false,
      lg: true,
    },
    {
      ssr: false,
    }
  );

  return {
    isDesktop: Boolean(isDesktop),
    isTablet: Boolean(isTablet),
    isMobile: Boolean(isMobile),
  };
};
