import { ApiClient, ApiAccount } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export interface LoadInventoryRequestsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  requestId: string;
}

export const loadInventoryRequests = createAsyncThunk(
  "requests/loadInventoryRequests",
  async (params: LoadInventoryRequestsThunkParams, thunkAPI) => {
    const { apiClient, account, requestId } = params;
    const state = thunkAPI.getState() as RootState;
    const { pageSize, page } = state.requestForm.inventoryRequests;
    const inventoryRequests = await apiClient.findInventoryRequests(
      account.id,
      { request: requestId, pageSize, page }
    );
    return inventoryRequests;
  }
);

export const loadInventoryRequestsHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadInventoryRequests.fulfilled, (state, action) => {
    state.inventoryRequests.data = action.payload.data;
    state.inventoryRequests.total = action.payload.total;
    state.inventoryRequests.page = action.payload.options.page || 1;
  });
};
