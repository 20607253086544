import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  ApiLaborTransaction,
  ApiRequest,
  ApiTransaction,
  ApiTransactionType,
  CreateApiLaborTransaction,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { DateBadge } from "../../../../components/badges/DateBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import { setTotalHoursTransactionChange } from "../../../../store/request-form/request-form.slice";
import { createTransaction } from "../../../../store/request-form/thunks/createTransaction.thunk";
import { removeTransaction } from "../../../../store/request-form/thunks/removeTransaction.thunk";
import { updateTransaction } from "../../../../store/request-form/thunks/updateTransaction.thunk";
import {
  deleteTransactions,
  updateTransactions,
} from "../../../../store/transaction-list.slice";
import { DetailField } from "../../RequestForm";
import {
  LaborTransactionForm,
  TransactionLaborerWithGroups,
} from "./LaborTransactionForm";

export interface LaborTransactionModalProps {
  isOpen: boolean;
  transaction?: ApiLaborTransaction;
  onClose: (transaction?: ApiLaborTransaction) => void;
}

export const LaborTransactionModal = ({
  isOpen,
  transaction,
  onClose,
}: LaborTransactionModalProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const request: ApiRequest | null = useSelector((state: RootState) => {
    return state.requestForm.request;
  });
  // TransactionLaborerWithGroups

  const [isSaving, setIsSaving] = useState(false);
  const [workingTransaction, setWorkingTransaction] =
    useState<TransactionLaborerWithGroups | null>();
  const [totalHoursChange, setTotalHoursChange] = useState<number>(0);
  const onCloseInternal = useCallback(() => {
    setWorkingTransaction(undefined);
    onClose();
  }, [onClose]);

  const handleLaborTransactionChange = useCallback(
    (transactionChange: ApiLaborTransaction) => {
      const totalActualHours = transactionChange?.hours || 0;
      const totalPreviousHours = transaction?.hours || 0;

      setTotalHoursChange(totalActualHours - totalPreviousHours);
      setWorkingTransaction(transactionChange);
    },
    [transaction?.hours]
  );

  const handleCreateTransactions = useCallback(() => {
    if (!workingTransaction) return;
    if (
      workingTransaction.laborer.isGroup &&
      workingTransaction.laborer.groupMembers
    ) {
      const { groupMembers } = workingTransaction.laborer;
      Promise.all(
        groupMembers.map((member) => {
          return thunkDispatch(
            createTransaction({
              apiClient,
              account: currentAccount,
              transaction: { ...workingTransaction, laborer: member },
              request,
            })
          );
        })
      )
        .then(() => {
          dispatch(setTotalHoursTransactionChange(workingTransaction.hours));
          showToast("success", "Labor transactions created successfully");
        })
        .catch((error) => {
          showToast("error", "Error Saving Labor Transactions");
        })
        .finally(() => {
          onClose();
          setWorkingTransaction(undefined);
          setTotalHoursChange(0);
        });
    }
  }, [
    apiClient,
    currentAccount,
    onClose,
    request,
    dispatch,
    showToast,
    thunkDispatch,
    workingTransaction,
  ]);

  const handleCreateTransaction = useCallback(() => {
    if (!workingTransaction) return;

    thunkDispatch(
      createTransaction({
        apiClient,
        account: currentAccount,
        request,
        transaction: workingTransaction as CreateApiLaborTransaction,
      })
    )
      .then(() => {
        dispatch(setTotalHoursTransactionChange(workingTransaction.hours));
        showToast("success", "Labor transaction created successfully");
      })
      .catch(() => {
        showToast("error", "Error saving a transaction");
      })
      .finally(() => {
        onClose();
        setWorkingTransaction(undefined);
        setTotalHoursChange(0);
      });
  }, [
    apiClient,
    currentAccount,
    onClose,
    request,
    showToast,
    thunkDispatch,
    workingTransaction,
    dispatch,
  ]);

  const handleUpdateTransaction = useCallback(() => {
    if (!workingTransaction) return;
    thunkDispatch(
      updateTransaction({
        apiClient,
        account: currentAccount,
        requestKey: workingTransaction.requestKey,
        idTransaction: workingTransaction.id,
        transactionType: ApiTransactionType.labor,
        transaction: workingTransaction,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        dispatch(setTotalHoursTransactionChange(totalHoursChange));
        dispatch(updateTransactions(response as ApiTransaction));
      })
      .catch(() => {
        showToast("error", "Error updating a transaction");
      })
      .finally(() => {
        onClose();
        setWorkingTransaction(undefined);
        setTotalHoursChange(0);
        setIsSaving(false);
      });
  }, [
    apiClient,
    currentAccount,
    dispatch,
    totalHoursChange,
    onClose,
    showToast,
    thunkDispatch,
    workingTransaction,
  ]);

  const handleRemoveLaborTransaction = useCallback(() => {
    if (!workingTransaction || !request) {
      return;
    }
    thunkDispatch(
      removeTransaction({
        apiClient,
        account: currentAccount,
        idOrKey: request.key,
        transactionId: workingTransaction.id,
        transactionType: workingTransaction.type,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        dispatch(
          setTotalHoursTransactionChange(-(workingTransaction?.hours || 0))
        );
        dispatch(deleteTransactions(response.transactionId));
      });
  }, [
    apiClient,
    currentAccount,
    dispatch,
    request,
    thunkDispatch,
    workingTransaction,
  ]);

  const handleSaveTransaction = useCallback(async () => {
    if (!transaction || !workingTransaction) return;
    setIsSaving(true);

    if (workingTransaction.id === "") {
      if (workingTransaction.laborer.isGroup) {
        handleCreateTransactions();
        return;
      }
      handleCreateTransaction();
    }

    if (workingTransaction.id !== "") {
      if (workingTransaction.laborer.isGroup) {
        await handleRemoveLaborTransaction();
        await handleCreateTransactions();
        return;
      }
      handleUpdateTransaction();
    }
  }, [
    transaction,
    workingTransaction,
    handleCreateTransaction,
    handleCreateTransactions,
    handleUpdateTransaction,
    handleRemoveLaborTransaction,
  ]);

  useEffect(() => {
    if (!transaction) {
      return;
    }
    setWorkingTransaction(transaction);
  }, [transaction]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseInternal} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {transaction && !transaction.id
            ? "New Labor Transaction"
            : "Edit Labor Transaction"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {workingTransaction && (
            <LaborTransactionForm
              transaction={workingTransaction}
              onChange={handleLaborTransactionChange}
              displayLeadGroups
            />
          )}
          {workingTransaction && workingTransaction.id && (
            <Box mt={6}>
              <Heading fontSize={"1.25em"}>Details</Heading>
              <Box pt={2}>
                <DetailField p={1} mb={2} label={"Created"}>
                  <DateBadge
                    showRelative={false}
                    date={workingTransaction.created}
                  />
                </DetailField>
                <DetailField p={1} mb={2} label={"Created By"}>
                  <UserBadge value={workingTransaction.createdBy} size="xs" />
                </DetailField>
                {workingTransaction.created !== workingTransaction.updated && (
                  <>
                    <DetailField p={1} mb={2} label={"Updated"}>
                      <DateBadge
                        showRelative={false}
                        date={workingTransaction.updated}
                      />
                    </DetailField>
                    <DetailField p={1} mb={2} label={"Updated By"}>
                      <UserBadge
                        value={workingTransaction.updatedBy}
                        size="xs"
                      />
                    </DetailField>
                  </>
                )}
              </Box>
            </Box>
          )}
        </ModalBody>
        <ModalFooter display="inline" textAlign="center">
          <Button
            width="200px"
            variant="solid"
            colorScheme="blue"
            onClick={handleSaveTransaction}
            isLoading={isSaving}
            isDisabled={
              workingTransaction == null || workingTransaction.hours <= 0
            }
          >
            {transaction && !transaction.id ? "Add Labor" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
