import { Flex } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-use";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../store";
import {
  cleanOrderItemsCart,
  loadInventoryItems,
  unloadInventoryItemsList,
} from "../../../store/inventory/inventory-order-item-list.slice";
import { InventoryOrderFilters } from "./InventoryOrderFilters";
import { OrderItemsList } from "./OrderItemsList";

export default function InventoryOrder() {
  const { filters } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();
  const location = useLocation();
  const itemId = useMemo(
    () => location.state?.state?.itemId,
    [location.state?.state?.itemId]
  );

  const loadItems = useCallback(() => {
    thunkDispatch(
      loadInventoryItems({
        apiClient,
        accountId: currentAccount.id,
        itemId: itemId ? itemId : undefined,
      })
    );
  }, [apiClient, currentAccount.id, thunkDispatch, itemId]);

  useEffect(() => {
    return () => {
      dispatch(unloadInventoryItemsList());
      dispatch(cleanOrderItemsCart());
    };
  }, [dispatch]);

  useEffect(() => {
    loadItems();
  }, [loadItems, filters, dispatch]);

  return (
    <Flex flexDir="column" gap={4} w="100%">
      <InventoryOrderFilters />

      <OrderItemsList />
    </Flex>
  );
}
