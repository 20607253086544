import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiRequest, AssociationType } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { StyledSelect } from "../../../components/selects/StyledSelect";
import { useShowToast } from "../../../hooks/showToast";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { RootState, useThunkDispatch } from "../../../store";
import { createRequestAssociation } from "../../../store/request-form/thunks/requestAssociations.thunk";
import {
  loadRequests,
  unloadRequestList,
  updateRequestFilters,
} from "../../../store/request-list.slice";
import { debounce } from "../../../utils/debounce";
import { StandardRequest } from "../../requests/containers/StandardRequest";
import { RequestPager } from "../../requests/RequestPager";
import { RequestRow } from "../../requests/RequestRow";
import { SkeletonRequests } from "../../requests/SkeletonRequests";
import { associationTypesAsText } from "./RequestAssociationsList";

interface AssociationRequestsProperties {
  isOpen: boolean;
  onClose: () => void;
}

const SELECTED_REQUEST_ID = "selected-associated-request";

export const AssociationRequestsModal: FC<AssociationRequestsProperties> = ({
  isOpen,
  onClose,
}) => {
  const [selectedRequest, setSelectedRequest] = useState<ApiRequest>();
  const [associationType, setAssociationType] = useState<string | null>(null);
  const [typeError, setTypeError] = useState(false);

  const { request } = useSelector((state: RootState) => state.requestForm);

  const { requests, filters, loading } = useSelector(
    (state: RootState) => state.requestList
  );

  const dispatch = useDispatch();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();

  const selectedRequestBg = useColorModeValue("blue.50", "blue.900");
  const errorColor = useColorModeValue("red.500", "red.300");

  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(updateRequestFilters({ search: value }));
    },

    [dispatch]
  );

  const debouncedSearchChange = debounce(handleSearchChange, 350);

  const filteredRequests = useMemo(() => {
    if (!request) return requests;

    const requestsCopy = [...requests];
    const sameRequestIndex = requestsCopy.findIndex(
      (req) => req.id === request.id
    );

    if (sameRequestIndex !== -1) {
      requestsCopy.splice(sameRequestIndex, 1);
      return requestsCopy;
    }

    return requestsCopy;
  }, [request, requests]);

  const typeOptions = useMemo(() => {
    return Object.keys(AssociationType).map((item) => {
      return { value: item, label: associationTypesAsText[item] };
    });
  }, []);

  const handleOnClickRequestRow = useCallback((value: ApiRequest) => {
    setSelectedRequest(value);
  }, []);

  const handleOnChangeType = useCallback(
    (selectValue: { value: string; label: string }) => {
      setAssociationType(selectValue.value);
      typeError && setTypeError(false);
    },
    [typeError]
  );

  const handleOnSaveRequestAssociation = useCallback(() => {
    if (!request || !selectedRequest) return;
    if (!associationType) {
      setTypeError(true);
      return;
    }

    const requestAssociation = {
      type: associationType as AssociationType,
      requestIdOrKey: selectedRequest.id,
    };

    thunkDispatch(
      createRequestAssociation({
        apiClient,
        account: currentAccount,
        requestId: request.id,
        requestAssociation,
      })
    )
      .then(unwrapResult)
      .then(() => {
        showToast("success", "Request association added succesfully");
        onClose();
      })
      .catch((error) => {
        if (error.message.includes("409")) {
          showToast("error", "This request was previously associated");
          return;
        }
        showToast(
          "error",
          "Something went wrong adding request association, please try again"
        );
        onClose();
      });
  }, [
    apiClient,
    associationType,
    currentAccount,
    onClose,
    request,
    selectedRequest,
    showToast,
    thunkDispatch,
  ]);

  const screenModes = useScreenBreakpoints();

  useEffect(() => {
    if (!selectedRequest) return;
    document
      .getElementById(SELECTED_REQUEST_ID)
      ?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [selectedRequest]);

  useEffect(() => {
    thunkDispatch(
      loadRequests({ apiClient, account: currentAccount, pageSize: 10 })
    );
  }, [apiClient, currentAccount, thunkDispatch, filters]);

  useEffect(() => {
    dispatch(unloadRequestList());
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize="3xl">Add Association to {request?.key}</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={3} wrap="wrap">
            <Flex
              w="100%"
              gap={4}
              flexDir={["column-reverse", "column-reverse", "row"]}
            >
              <Box w="100%">
                <AccountSearchBox
                  searchPlaceholder={"Search Requests"}
                  onInputChange={debouncedSearchChange}
                />
              </Box>
            </Flex>

            <Divider />

            {selectedRequest && (
              <>
                <Flex
                  p={4}
                  gap={3}
                  w="100%"
                  wrap="wrap"
                  borderRadius={6}
                  id={SELECTED_REQUEST_ID}
                  bgColor={selectedRequestBg}
                >
                  <Box
                    w="100%"
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    <Text
                      as="span"
                      fontSize="lg"
                      w="max-content"
                      fontWeight="bold"
                    >
                      Associate with
                    </Text>
                    <Box minW={["100%", "100%", "350px"]}>
                      <StyledSelect
                        value={associationType}
                        options={typeOptions}
                        placeholder="Select Association Type"
                        onChange={handleOnChangeType}
                      />
                      {typeError && (
                        <Text fontSize="sm" color={errorColor} pt={1}>
                          Association type is required
                        </Text>
                      )}
                    </Box>
                  </Box>

                  <Box w="100%">
                    <StandardRequest>
                      <RequestRow
                        request={selectedRequest}
                        selected={false}
                        screenMode={screenModes}
                      />
                    </StandardRequest>
                  </Box>

                  <Box w="100%" display="flex" justifyContent="space-between">
                    <Button
                      colorScheme="blue"
                      size="sm"
                      float="right"
                      onClick={() => setSelectedRequest(undefined)}
                    >
                      Delete
                    </Button>
                    <Button
                      size="sm"
                      float="right"
                      colorScheme="blue"
                      justifySelf="flex-end"
                      disabled={typeError}
                      onClick={handleOnSaveRequestAssociation}
                    >
                      Add Association
                    </Button>
                  </Box>
                </Flex>

                <Divider />
              </>
            )}

            {loading === "succeeded" ? (
              <>
                <Text fontSize="lg" fontWeight="bold">
                  What request do you want to associate?
                </Text>

                {filteredRequests.map((request, idx) => (
                  <Box
                    w="100%"
                    cursor="pointer"
                    key={`association::${request.id}`}
                    onClick={() => handleOnClickRequestRow(request)}
                  >
                    <StandardRequest>
                      <RequestRow
                        request={request}
                        screenMode={screenModes}
                        selected={false}
                      />
                    </StandardRequest>
                  </Box>
                ))}

                <Box w="100%" alignSelf="flex-end">
                  <RequestPager />
                </Box>
              </>
            ) : (
              <Box w="100%">
                <SkeletonRequests />
              </Box>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            justifySelf="flex-start"
            colorScheme="blue"
            variant="ghost"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
