import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { RiArrowUpDownLine } from "react-icons/ri";
import { useThunkDispatch } from "../../../../../../store";
import { updateSortFilters } from "../../../../../../store/planning-hq/requests/updateFilters.thunk";
import { useScreenBreakpoints } from "../../../../../../hooks/useScreenBreakpoints";

type SortFilterProps = {
  project: ApiProject;
};

export const SortFilter: FC<SortFilterProps> = ({ project }) => {
  const thunkDispatch = useThunkDispatch();
  const { isDesktop } = useScreenBreakpoints();

  const onDirectionChange = useCallback(
    (value: string | string[]) => {
      const [direction] = typeof value === "string" ? [value] : value;
      if (direction === "asc" || direction === "desc") {
        thunkDispatch(updateSortFilters({ direction }));
      }
    },
    [thunkDispatch]
  );

  const onFieldChange = useCallback(
    (value: string | string[]) => {
      const [field] = typeof value === "string" ? [value] : value;
      thunkDispatch(updateSortFilters({ field }));
    },
    [thunkDispatch]
  );

  return (
    <>
      <Menu
        closeOnSelect={false}
        placement={isDesktop ? "right-start" : "bottom"}
        preventOverflow={isDesktop ? false : true}
        flip={isDesktop ? false : true}
      >
        <MenuButton
          as={IconButton}
          colorScheme="gray"
          variant="outline"
          icon={<RiArrowUpDownLine />}
          size="sm"
        />

        <Portal>
          <MenuList minWidth="240px">
            <MenuOptionGroup
              defaultValue="desc"
              title="Order"
              type="radio"
              onChange={onDirectionChange}
            >
              <MenuItemOption value="asc">Ascending</MenuItemOption>
              <MenuItemOption value="desc">Descending</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              defaultValue="created"
              title="Field"
              type="radio"
              onChange={onFieldChange}
            >
              <MenuItemOption value="created">Created</MenuItemOption>
              <MenuItemOption value="updated">Updated</MenuItemOption>
              <MenuItemOption value="due">Due Date</MenuItemOption>
              <MenuItemOption value="start">Start Date</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    </>
  );
};
