import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CreateApiVendor,
  CreateApiVendorContact,
  UpdateApiVendor,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  createVendor,
  updateVendor,
} from "../../../store/vendors/vendors-slice";
import { StateSelectControl } from "../location-form/LocationForm";
import { vendorSchema } from "./VendorFormSchema";
import { VendorTypesForm } from "./VendorTypesForm";

export interface CreateApiVendorFormValues
  extends Omit<CreateApiVendor, "primaryContact"> {
  primaryContact: CreateApiVendorContact;
}

const CONTACT_INITIAL_VALUES = {
  name: "",
  email: null,
  phone: null,
  altPhone: null,
};

export const VENDOR_INITIAL_VALUES: CreateApiVendorFormValues = {
  name: "",
  address: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
  isSupplier: false,
  isManufacturer: false,
  isServiceProvider: false,
  primaryContact: CONTACT_INITIAL_VALUES,
};

interface VendorFormProps {
  onClose: () => void;
}

export const VendorForm: FC<VendorFormProps> = ({ onClose }) => {
  const [initialValues, setInitialValues] =
    useState<CreateApiVendorFormValues | null>(null);

  const { workingVendor } = useSelector(
    (state: RootState) => state.vendorSlice
  );

  const { country } = useSelector((state: RootState) => state.global);

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const { apiClient, currentAccount } = useAuthentication();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();

  const handleOnCreateVendor = useCallback(
    (values: CreateApiVendorFormValues) => {
      thunkDispatch(
        createVendor({
          apiClient,
          accountId: currentAccount.id,
          vendor: values,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Vendor added successfully");
          onClose();
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });
    },
    [apiClient, currentAccount.id, onClose, showToast, thunkDispatch]
  );

  const handleUpdateVendor = useCallback(
    (values: CreateApiVendorFormValues) => {
      if (!workingVendor) return;
      thunkDispatch(
        updateVendor({
          apiClient,
          accountId: currentAccount.id,
          vendorId: workingVendor.id,
          vendor: values as unknown as UpdateApiVendor,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Vendor was updated successfully");
          onClose();
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });
    },
    [
      apiClient,
      currentAccount.id,
      onClose,
      showToast,
      thunkDispatch,
      workingVendor,
    ]
  );

  const handleOnSubmit = useCallback(
    (values: CreateApiVendorFormValues) => {
      if (
        !values.isManufacturer &&
        !values.isServiceProvider &&
        !values.isSupplier
      )
        return;
      workingVendor ? handleUpdateVendor(values) : handleOnCreateVendor(values);
    },
    [handleOnCreateVendor, handleUpdateVendor, workingVendor]
  );

  useEffect(() => {
    if (workingVendor) {
      const vendor = {
        ...workingVendor,
        primaryContact: workingVendor.primaryContact
          ? workingVendor.primaryContact
          : CONTACT_INITIAL_VALUES,
      };
      setInitialValues(vendor as CreateApiVendorFormValues);
    } else {
      setInitialValues(VENDOR_INITIAL_VALUES);
    }
  }, [workingVendor]);

  return (
    <Flex w="100%" flexDir="column">
      {initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={vendorSchema}
        >
          {({ values }) => (
            <Form>
              <Grid templateColumns="repeat(12 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <VendorTypesForm />
                </GridItem>

                <GridItem colSpan={12}>
                  <TextInputControl
                    value={values.name}
                    name="name"
                    label="Name"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <TextInputControl
                    value={values.address}
                    name="address"
                    label="Address"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <TextInputControl
                    value={values.address2}
                    name="address2"
                    label="Address2"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <TextInputControl
                    value={values.city}
                    name="city"
                    label="City"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <StateSelectControl
                    name="state"
                    label={`State/Province (${country?.name})`}
                    isDisabled={!country}
                    value={values.state ? values.state : null}
                    country={country?.isoCode || undefined}
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <TextInputControl value={values.zip} name="zip" label="Zip" />
                </GridItem>
              </Grid>

              <Divider my={4} />

              <Grid
                p={4}
                gap={4}
                borderRadius={6}
                border="1px solid"
                borderColor={borderColor}
                templateColumns="repeat(12 1fr)"
              >
                <GridItem colSpan={12}>
                  <Heading fontSize="xl">Primary Contact</Heading>
                </GridItem>

                <GridItem colSpan={12}>
                  <TextInputControl
                    value={values.primaryContact.name}
                    name="primaryContact.name"
                    label="Name"
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <TextInputControl
                    value={values.primaryContact.email}
                    name="primaryContact.email"
                    label="Email"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <TextInputControl
                    value={values.primaryContact.phone}
                    name="primaryContact.phone"
                    label="Phone"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <TextInputControl
                    value={values.primaryContact.altPhone}
                    name="primaryContact.altPhone"
                    label="Alternative Phone"
                  />
                </GridItem>
              </Grid>

              <Flex mt={4} w="100%" justifyContent="space-between">
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="blue" type="submit">
                  Save
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Flex>
  );
};
