import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiVendor } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";

export interface SupplierFilterProps {
  values: string[];
  title?: string;
  onChange: (value: string[]) => void;
}

const ContentRenderer = ({
  props,
  state,
  title,
}: SelectRenderer<ApiVendor> & {
  title?: string;
}) => {
  return (
    <Box p={1} display="flex" alignItems="center" w="max-content">
      {state.values.length === 0 && (title || "Supplier")}
      {state.values.length === 1 && <Text>{state.values[0].name}</Text>}
      {state.values.length > 1 && (
        <Flex alignItems="center" gap={2}>
          <Text>{state.values[0].name}</Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </Flex>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiVendor>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["name"], {
        sort: true,
      }),
    [props.options]
  );

  const suppliers = useMemo(() => {
    const results = fuzzySearch.search(state.search);
    const selected = state.values;
    selected.filter((sel) =>
      results.find((res) => res.id === sel.id) ? null : results.push(sel)
    );
    return !state.search ? props.options : results;
  }, [state.search, state.values, props.options, fuzzySearch]);

  const bgColor = useColorModeValue("transparent", "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Box>Search and select:</Box>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Supplier"
        />
      </Box>
      <Box>
        {suppliers.map((item) => {
          return (
            <Checkbox
              key={item.id}
              isChecked={
                methods.isSelected(item) ||
                state.values.some((sel) => sel.id === item.id)
              }
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.name}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const VendorSupplierFilter = ({
  values,
  onChange,
  title,
}: SupplierFilterProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const [options, setOptions] = useState<ApiVendor[]>([]);
  const [search, setSearch] = useState<string>("");

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const searchFn = useCallback(
    ({ props, state }: SelectRenderer<ApiVendor>) => {
      if (!state.search) {
        setSearch("");
      }
      setSearch(state.search);

      return props.options;
    },
    []
  );

  useEffect(() => {
    apiClient
      .findVendors(currentAccount.id, { pageSize: 50, isSupplier: true })
      .then((res) => setOptions(res.data));
  }, [apiClient, currentAccount.id, search]);

  const handleOnChange = useCallback(
    (values: ApiVendor[]) => {
      const ids = values.map((v) => v.id);
      onChange(ids);
    },
    [onChange]
  );

  return (
    <Select
      multi
      options={options}
      values={options.filter((opt) => values.some((v) => v === opt.id))}
      className={themeClass}
      onChange={handleOnChange}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      clearable={false}
      keepSelectedInList
      clearOnSelect={false}
      dropdownHeight="350px"
      searchFn={searchFn}
      dropdownRenderer={DropdownRenderer}
      contentRenderer={(props) => ContentRenderer({ ...props, title })}
    />
  );
};
