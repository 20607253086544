import { Box, Flex, HStack, Text, useColorMode } from "@chakra-ui/react";
import { FC } from "react";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { TimelineItemProps } from "./types";
import { useRequestColorMap } from "./useRequestColorMap";

type SmallTimelineItemProps = TimelineItemProps;

export const SmallTimelineItem: FC<SmallTimelineItemProps> = ({ request }) => {
  const { getColor } = useRequestColorMap(request.priority);
  const { colorMode } = useColorMode();
  return (
    <Box
      borderRadius="md"
      color="black"
      position="relative"
      height={"5rem"}
      backgroundColor="gray.800"
    >
      <Flex
        w="full"
        height="full"
        gap={2}
        alignItems="center"
        backgroundColor={getColor(0)}
        borderRadius="md"
        borderWidth="thin"
        borderColor={getColor(2)}
        px={2}
      >
        <Flex
          flexDir="column"
          gap={1}
          minWidth={0}
          maxW="400px"
          position="sticky"
          left="0px"
        >
          <StatusBadge
            status={request.status}
            badgeProps={{
              w: "fit-content",
              backgroundColor: colorMode === "light" ? "white" : "transparent",
              fontSize: "small",
              borderRadius: "sm",
            }}
          />
          <HStack w="full" gap={1} fontWeight="bold">
            <Text color={colorMode === "light" ? "gray.500" : "white"}>
              {request.key}
            </Text>
          </HStack>
          <Box
            backgroundColor={getColor(2)}
            w="fit-content"
            px={1}
            borderRadius="sm"
          >
            <Text fontSize="xs" fontWeight="bold" color="white">
              {request.priority.toUpperCase()}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
