import {
  Box,
  Button,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReadOnlyTextEditor } from "../../../components/form-helpers/rich-text-editor/RichTextEditorReadOnly";
import { ModalProvider } from "../../../hooks/useIsInModal";
import { RootState } from "../../../store";
import { cleanSelectedGroup } from "../../../store/event-group-slice";
import { setIsOpenEventModal } from "../../../store/events/event-modal.slice";
import { unloadFormikModal } from "../../../store/formik-modal.slice";
import { EventEditForm } from "./EventEditForm";
import { EventForm } from "./EventForm";

export const EventFormModal: FC = () => {
  const { isModalOpen, workingEventId, modalContent } = useSelector(
    (state: RootState) => state.newEventSlice
  );
  const { event } = useSelector((state: RootState) => state.eventDetails);

  const { values, isSubmitting, handleSubmit } = useSelector(
    (state: RootState) => state.formikModalSlice
  );
  const dispatch = useDispatch();
  const headerBgColor = useColorModeValue("blue.50", "blue.900");
  const headerTextColor = useColorModeValue("blue.800", "white");

  const handleOnCLose = useCallback(() => {
    dispatch(
      setIsOpenEventModal({
        isOpen: false,
        workingEventId: "",
        modalContent: null,
      })
    );
    dispatch(cleanSelectedGroup());
  }, [dispatch]);

  const CustomModalHeader = useMemo(() => {
    if (!modalContent || !workingEventId) return null;
    if (modalContent === "form") {
      return workingEventId === "new" ? (
        <ModalHeader>
          <Heading fontSize="3xl">Create an Event</Heading>
          <Divider my={2} />
        </ModalHeader>
      ) : (
        <ModalHeader bgColor={headerBgColor} p={[4, 4, 6]}>
          {event && (
            <>
              <Text
                fontWeight="bold"
                fontSize="lg"
                textColor={headerTextColor}
                textAlign="start"
                opacity="0.7"
              >
                {event.key}
              </Text>
              <Heading fontSize="3xl" color={headerTextColor}>
                {event.name}
              </Heading>
            </>
          )}
          {event && (
            <Box fontSize="13px">
              <ReadOnlyTextEditor
                value={event.description}
                editorMaxHeight="60px"
              />
            </Box>
          )}
        </ModalHeader>
      );
    }
  }, [event, headerBgColor, headerTextColor, modalContent, workingEventId]);

  useEffect(() => {
    return () => {
      dispatch(unloadFormikModal());
    };
  }, [dispatch]);

  return isModalOpen ? (
    <ModalProvider>
      <Modal
        isOpen={isModalOpen}
        closeOnOverlayClick={false}
        preserveScrollBarGap
        scrollBehavior="inside"
        onClose={handleOnCLose}
      >
        <ModalOverlay />
        <ModalContent maxW="3xl" mt={6} pb={2}>
          {CustomModalHeader}
          <ModalCloseButton />
          {workingEventId !== "" && (
            <ModalBody p={[2, 2, 4]} overflowY="auto">
              {workingEventId === "new" && modalContent === "form" && (
                <EventForm />
              )}
              {workingEventId !== "new" && modalContent === "form" && (
                <EventEditForm onClose={handleOnCLose} />
              )}
            </ModalBody>
          )}
          {workingEventId === "new" && modalContent === "form" && (
            <ModalFooter
              justifyContent="space-between"
              position="sticky"
              bottom="0"
              zIndex="1"
              w="100%"
            >
              <Button
                mr={10}
                variant="ghost"
                colorScheme="blue"
                onClick={handleOnCLose}
              >
                Cancel
              </Button>
              <Button
                minW="200px"
                type="submit"
                colorScheme="blue"
                onClick={() => handleSubmit(values)}
                isLoading={isSubmitting === true}
                disabled={isSubmitting === true}
              >
                Submit Event
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </ModalProvider>
  ) : null;
};
