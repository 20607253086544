import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { SparkleWithAnimation } from "../../../../components/icons/Sparkle";

export interface NameplateDataModalPageProps {
  onClose: () => void;
  parsedAsset: any;
}
const findLabelQuality = (labelQuality: number) => {
  if (labelQuality >= 1 && labelQuality <= 2) {
    return "Replace ASAP";
  } else if (labelQuality >= 3 && labelQuality <= 4) {
    return "Replace Soon";
  } else if (labelQuality >= 5 && labelQuality <= 6) {
    return "Fair";
  } else if (labelQuality >= 7 && labelQuality <= 8) {
    return "Good";
  } else if (labelQuality === 9) {
    return "Great";
  } else if (labelQuality === 10) {
    return "Excellent";
  } else {
    return "Unknown";
  }
};

const labelQualityColor = (labelQuality: number) => {
  if (labelQuality >= 1 && labelQuality <= 2) {
    return "red";
  } else if (labelQuality >= 3 && labelQuality <= 4) {
    return "brown";
  } else if (labelQuality >= 5 && labelQuality <= 6) {
    return "#d4a017";
  } else if (labelQuality >= 7 && labelQuality <= 10) {
    return "green";
  } else {
    return "gray";
  }
};

const findPictureQuality = (pictureQuality: number) => {
  if (pictureQuality >= 1 && pictureQuality <= 3) {
    return "Low";
  } else if (pictureQuality >= 4 && pictureQuality <= 6) {
    return "Fair";
  } else if (pictureQuality >= 7 && pictureQuality <= 10) {
    return "High";
  } else {
    return "Unknown";
  }
};

const pictureQualityColor = (pictureQuality: number) => {
  if (pictureQuality >= 1 && pictureQuality <= 3) {
    return "red";
  } else if (pictureQuality >= 4 && pictureQuality <= 6) {
    return "o#d4a017";
  } else if (pictureQuality >= 7 && pictureQuality <= 10) {
    return "green";
  } else {
    return "gray";
  }
};

const NameplateDataModalPage: React.FC<NameplateDataModalPageProps> = ({
  onClose: closeModal,
  parsedAsset,
}) => {
  return (
    <Modal isOpen={true} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Nameplate Information <SparkleWithAnimation boxSize="1em" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="1fr 1fr" gap={4}>
            <GridItem>
              <Text fontWeight="bold">Picture Quality:</Text>
            </GridItem>
            <GridItem textAlign="left">
              <Box
                alignSelf={"right"}
                borderWidth="1px"
                borderRadius="full"
                borderColor={pictureQualityColor(
                  parsedAsset?.imageQuality.replaceRec
                )}
                p={1}
                textAlign={"center"}
              >
                <Text
                  color={pictureQualityColor(
                    parsedAsset?.imageQuality.replaceRec
                  )}
                >
                  {findPictureQuality(parsedAsset?.imageQuality.replaceRec)}
                </Text>
              </Box>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Label Quality:</Text>
            </GridItem>
            <GridItem textAlign="left">
              <Box
                borderWidth="1px"
                borderRadius="full"
                borderColor={labelQualityColor(parsedAsset?.labelQuality)}
                p={1}
                textAlign={"center"}
              >
                <Text color={labelQualityColor(parsedAsset?.labelQuality)}>
                  {findLabelQuality(parsedAsset?.labelQuality)}
                </Text>
              </Box>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Overall Confidence:</Text>
            </GridItem>
            <GridItem textAlign="right">
              <Text>{parsedAsset?.overallCertainty}%</Text>
            </GridItem>
            <GridItem colSpan={2}>
              {(parsedAsset?.labelQuality ?? 0) <= 3 ? (
                <Text textAlign="left" width="100%">
                  Your label quality was rated a {parsedAsset?.labelQuality}, it
                  is recommended that you replace it.{" "}
                  {parsedAsset?.imageQuality.helperTips}
                </Text>
              ) : (
                <Text textAlign="left" width="100%">
                  Your label quality was rated a {parsedAsset?.labelQuality}.{" "}
                  {JSON.stringify(parsedAsset?.imageQuality.helperTips)}
                </Text>
              )}
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NameplateDataModalPage;
