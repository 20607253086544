import {
  Box,
  Button,
  Checkbox,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../../components/badges/AdditionalNumberBadge";
import { FiscalYearRange } from "../../../../store/planning-hq/types";

const FISCAL_YEAR_FORMAT = "MMMM YYYY" as const;

export interface FiscalYearFilterProps {
  value: (FiscalYearRange | string)[];
  onChange?: (value: FiscalYearRange[]) => void;
  onBlur?: (value: FiscalYearRange[]) => void;
  showSelectAll?: boolean;
}

type ValueLabel = {
  value: FiscalYearRange;
  label: string;
};

const DropdownRenderer = ({
  props,
  methods,
  showSelectAll,
}: SelectRenderer<ValueLabel> & { showSelectAll?: boolean }) => {
  const bgColor = useColorModeValue(undefined, "gray.700");
  return (
    <Box p={2} backgroundColor={bgColor}>
      <Box pb={1}>
        {showSelectAll && (
          <HStack justifyContent="space-between" pb={2}>
            <div>Search and select:</div>
            {methods.areAllSelected() ? (
              <Button size="sm" variant="outline" onClick={methods.clearAll}>
                Clear all
              </Button>
            ) : (
              <Button size="sm" onClick={() => methods.selectAll()}>
                Select all
              </Button>
            )}
          </HStack>
        )}
      </Box>
      <Box>
        {props.options.map((item) => {
          return (
            <Checkbox
              key={item.label}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.label}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

const ContentRenderer = ({ state }: SelectRenderer<ValueLabel>) => {
  return (
    <Box p={1} w="max-content">
      {state.values.length === 0 && "Fiscal Year"}
      {state.values.length === 1 && <Text>{state.values[0].label}</Text>}
      {state.values.length > 1 && (
        <HStack>
          <Text>{state.values[0].label}</Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </HStack>
      )}
    </Box>
  );
};

export const FiscalYearsFilter = ({
  value,
  onChange,
  showSelectAll,
  onBlur,
}: FiscalYearFilterProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [options, setOptions] = useState<ValueLabel[]>([]);

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const internalValues = useMemo(() => {
    if (value && value.length === 0) return [];
    const first = value[0];
    if (typeof first === "string") {
      const valuesAsStringArray = value as Array<string>;
      const selected = options.filter((opt) => {
        return valuesAsStringArray.includes(opt.value.start);
      });
      return selected;
    } else {
      const valuesAsRangeArray = value as Array<FiscalYearRange>;
      const selected = options.filter((opt) => {
        return valuesAsRangeArray.includes(opt.value);
      });
      return selected;
    }
  }, [value, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      if (!onChange) return;
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange]
  );

  useEffect(() => {
    apiClient
      .findFundingSourceFiscalYears(currentAccount.id, {
        pageSize: 50,
      })
      .then((res) => {
        const mapped = res.data.map((fiscalYearRange) => {
          const start = fiscalYearRange.fiscalYearStart;
          const end = fiscalYearRange.fiscalYearEnd;
          const formattedStart = moment(start).format(FISCAL_YEAR_FORMAT);
          const formattedEnd = moment(end).format(FISCAL_YEAR_FORMAT);
          const label = `${formattedStart} - ${formattedEnd}`;
          return {
            label,
            value: {
              start,
              end,
            },
          };
        });

        setOptions(mapped);
      });
  }, [apiClient, currentAccount.id]);

  return (
    <Select
      multi
      options={options}
      values={internalValues}
      onChange={handleChange}
      className={themeClass}
      searchable={true}
      searchBy="value"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      contentRenderer={ContentRenderer}
      dropdownHeight="300"
      dropdownRenderer={(props) => (
        <DropdownRenderer {...props} showSelectAll={showSelectAll} />
      )}
    />
  );
};
