import {
  ApiAccount,
  ApiClient,
  ApiIssuanceTransaction,
  ApiItemAdjustmentType,
  ApiTransactionType,
  TransactionTotals,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export interface LoadTransactionsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  key: string;
  page?: number;
  transactionType?: "labor" | "purchase" | "issuance";
}

export interface LoadReturnTransactionsThunkParams
  extends LoadTransactionsThunkParams {
  issuanceTransactionId: string;
}

export const loadTransactions = createAsyncThunk(
  "requests/loadLaborTransactions",
  async (params: LoadTransactionsThunkParams, thunkAPI) => {
    const { apiClient, account, key, transactionType } = params;
    const state = thunkAPI.getState() as RootState;

    const page =
      transactionType === "labor"
        ? state.requestForm.transactions.labors.laborsCurrentPage
        : transactionType === "purchase"
        ? state.requestForm.transactions.purchases.purchasesCurrentPage
        : state.requestForm.transactions.issuance.issuanceCurrentPage;

    const [pagedLaborTransactions, transactionTotals] = await Promise.all([
      await apiClient.findRequestTransactions(account.id, key, {
        pageSize: 20,
        page,
        type: transactionType,
      }),
      state.requestForm.request
        ? apiClient.findRequestTransactionTotalCostHours(account.id, {
            ids: [state.requestForm.request.id],
          })
        : ({} as Record<string, TransactionTotals>),
    ]);

    return {
      ...pagedLaborTransactions,
      type: transactionType,
      transactionTotals,
    };
  }
);

export const loadReturnTransactions = createAsyncThunk(
  "requests/loadReturnTransactions",
  async (params: LoadReturnTransactionsThunkParams, thunkAPI) => {
    const { apiClient, account, key, issuanceTransactionId } = params;
    const issuanceTransactionsResponse =
      await apiClient.findRequestTransactions(account.id, key, {
        type: "issuance",
      });

    const issuanceTransactions =
      issuanceTransactionsResponse.data as ApiIssuanceTransaction[];

    const returnTransactions = issuanceTransactions.filter(
      (transaction) =>
        transaction.inventoryItemAdjustment.type ===
          ApiItemAdjustmentType.returnedToInventory &&
        transaction.inventoryItemAdjustment.issuanceTransactionId ===
          issuanceTransactionId
    );

    return { returnTransactions };
  }
);

export const loadTransactionsHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadTransactions.fulfilled, (state, action) => {
    const payload = action.payload;
    const { type } = payload;

    if (state.request) {
      state.transactions.totalHours =
        payload.transactionTotals[state.request.id]?.totalHours || 0;
      state.transactions.totalPurchases =
        payload.transactionTotals[state.request.id]?.totalPurchases || 0;
    }

    if (type === ApiTransactionType.labor) {
      state.transactions.labors = {
        laborsData: payload.data,
        laborsTotal: payload.total,
        laborsCurrentPage: payload.options.page,
      };
    }

    if (type === ApiTransactionType.purchase) {
      state.transactions.purchases = {
        purchasesData: payload.data,
        purchasesTotal: payload.total,
        purchasesCurrentPage: payload.options.page,
      };
    }

    if (type === ApiTransactionType.issuance) {
      state.transactions.issuance = {
        issuanceData: payload.data,
        issuanceTotal: payload.total,
        issuanceCurrentPage: payload.options.page,
      };
    }
  });
  builder.addCase(loadReturnTransactions.fulfilled, (state, action) => {
    const { returnTransactions } = action.payload;
    const returnsMapByItem = returnTransactions.reduce<
      Record<string, ApiIssuanceTransaction[]>
    >((recordObject, transaction) => {
      const key = transaction.inventoryItemAdjustment.item.id;
      if (!recordObject.hasOwnProperty(key)) {
        recordObject[key] = [];
      }
      recordObject[key].push(transaction);
      return recordObject;
    }, {});

    state.transactions.issuancesReturns = {
      returnsData: returnsMapByItem,
    };
  });
};
