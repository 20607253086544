import {
  ButtonProps,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { RiArrowUpDownLine } from "react-icons/ri";
import { useThunkDispatch } from "../../../../store";
import { updateSortFilters } from "../../../../store/planning-hq/funding-sources/funding-source-list.slice";

type SortFilterProps = {
  value: {
    sort: string | null;
    direction: string | null;
  };
  buttonProps?: ButtonProps;
};

export const SortFilter: FC<SortFilterProps> = ({ value, buttonProps }) => {
  const thunkDispatch = useThunkDispatch();
  const { sort, direction } = value;

  const onDirectionChange = useCallback(
    (value: string | string[]) => {
      const [direction] = typeof value === "string" ? [value] : value;
      if (direction === "asc" || direction === "desc") {
        thunkDispatch(updateSortFilters({ direction }));
      }
    },
    [thunkDispatch]
  );

  const onFieldChange = useCallback(
    (value: string | string[]) => {
      const [field] = typeof value === "string" ? [value] : value;
      thunkDispatch(updateSortFilters({ sort: field }));
    },
    [thunkDispatch]
  );

  return (
    <>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          colorScheme="blue"
          variant="outline"
          icon={<RiArrowUpDownLine />}
          {...buttonProps}
        />

        <Portal>
          <MenuList minWidth="240px">
            <MenuOptionGroup
              defaultValue="desc"
              title="Order"
              type="radio"
              onChange={onDirectionChange}
              value={direction ?? undefined}
            >
              <MenuItemOption value="asc">Ascending</MenuItemOption>
              <MenuItemOption value="desc">Descending</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              defaultValue="name"
              title="Field"
              type="radio"
              onChange={onFieldChange}
              value={sort ?? undefined}
            >
              <MenuItemOption value="name">Name</MenuItemOption>
              <MenuItemOption value="remaining">Remaining</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    </>
  );
};
