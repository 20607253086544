import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ApiInventoryItemStorageLocation } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import { InventoryItemLocationSelect } from "../selects/InventoryItemStorageLocationSelect";

export interface InventoryItemLocationSelectControlProps {
  label?: string;
  name: string;
  value: ApiInventoryItemStorageLocation | null;
  allowEmpty?: boolean;
  isDisabled?: boolean;
  inventoryItemId?: string;
  showQuantity?: boolean;
  selectedLocations?: ApiInventoryItemStorageLocation[];
  catalogId?: string;
}

export const InventoryItemLocationSelectControl = ({
  name,
  value,
  label,
  allowEmpty = false,
  isDisabled,
  inventoryItemId,
  showQuantity,
  selectedLocations,
  catalogId,
}: InventoryItemLocationSelectControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (location: ApiInventoryItemStorageLocation | null) => {
      helper.setTouched(true);
      helper.setValue(location);
    },
    [helper]
  );

  const isInvalid = useMemo(
    () => !!meta.error && (meta.touched || submitCount > 0),
    [meta, submitCount]
  );

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InventoryItemLocationSelect
        {...field}
        name={name}
        isInvalid={isInvalid}
        allowEmpty={allowEmpty}
        isDisabled={isDisabled}
        onChange={handleOnChange}
        showQuantity={showQuantity}
        inventoryItemId={inventoryItemId}
        selectedLocations={selectedLocations}
        catalogId={catalogId}
      />
      {isInvalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
};
