import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { WizardStepProps } from "./FirstRunWizard";

export const FirstRunWizardCatalog = ({ onNext }: WizardStepProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [catalogName, setCatalogName] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleClick = useCallback(async () => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    const catalog = await apiClient.createCatalog(currentAccount.id, {
      name: catalogName,
    });

    onNext(catalog);
  }, [apiClient, catalogName, currentAccount.id, isSaving, onNext]);

  useEffect(() => {
    const catalogNameIsValid = /[\d\w\s]{2,25}/.test(catalogName);
    setFormIsValid(catalogNameIsValid);
  }, [catalogName]);

  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCatalogName(e.target.value);
  };
  const handleShowMore = useCallback(() => {
    setShowInfo(!showInfo);
  }, [showInfo]);

  return (
    <Stack shouldWrapChildren>
      <Text>Different Heros, Different Needs</Text>
      <Text>Catalogs represent segments of items</Text>
      {showInfo && (
        <Box>
          <Text size="xs">
            <Text fontWeight="bold" as="span" display="inline">
              If you have a few heros
            </Text>{" "}
            who operate with a few items, we suggest naming it after your items
            type or category.
          </Text>
          <Text size="xs" mt={4}>
            <Text fontWeight="bold" as="span" display="inline">
              In larger organizations,
            </Text>{" "}
            we suggest naming it after items category, such as Plumbing,
            Custodial, etc
          </Text>
        </Box>
      )}
      <Box mb={4}>
        <Link onClick={handleShowMore}>{showInfo ? "Less" : "More"} info</Link>
      </Box>

      <FormControl id="first-run-wizard-wf-name" mb={4}>
        <FormLabel>What would you like to name your first catalog?</FormLabel>
        <Input
          value={catalogName}
          onChange={handleOnChangeName}
          placeholder="Ideas: Maintenance, Custodial, IT, Plumbing"
          maxLength={25}
          autoComplete="off"
        />
      </FormControl>

      <Button isDisabled={!formIsValid} onClick={handleClick} size="sm">
        Let's go
      </Button>
    </Stack>
  );
};
