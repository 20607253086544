import {
  ApiClient,
  ApiAccount,
  CreateApiRequestAssociation,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export interface LoadRequestAssociationsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  requestId: string;
}

export const loadRequestAssociations = createAsyncThunk(
  "requests/loadRequestAssociations",
  async (params: LoadRequestAssociationsThunkParams, thunkAPI) => {
    const { apiClient, account, requestId } = params;
    const state = thunkAPI.getState() as RootState;
    const { pageSize, page } = state.requestForm.requestAssociations;
    const requestAssociations = await apiClient.findRequestAssociations(
      account.id,
      requestId,
      { pageSize, page }
    );
    return requestAssociations;
  }
);

export const loadRequestAssociationsHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadRequestAssociations.fulfilled, (state, action) => {
    state.requestAssociations.data = action.payload.data;
    state.requestAssociations.total = action.payload.total;
    state.requestAssociations.page = action.payload.options.page || 1;
  });
};

export interface CreateRequestAssociationThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  requestId: string;
  requestAssociation: CreateApiRequestAssociation;
}

export const createRequestAssociation = createAsyncThunk(
  "requests/createRequestAssociation",
  async (params: CreateRequestAssociationThunkParams, thunkAPI) => {
    const { apiClient, account, requestId, requestAssociation } = params;
    const response = await apiClient.createRequestAssociation(
      account.id,
      requestId,
      requestAssociation
    );
    return response;
  }
);

export const createRequestAssociationHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(createRequestAssociation.fulfilled, (state, action) => {
    state.requestAssociations.data.unshift({ ...action.payload });
    state.requestAssociations.total++;
  });
};

export interface RemoveRequestAssociationThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  requestId: string;
  associationId: string;
}

export const removeRequestAssociation = createAsyncThunk(
  "requests/removeRequestAssociation",
  async (params: RemoveRequestAssociationThunkParams, thunkAPI) => {
    const { apiClient, account, requestId, associationId } = params;
    await apiClient.removeRequestAssociation(
      account.id,
      requestId,
      associationId
    );
    return { associationId };
  }
);

export const removeRequestAssociationHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(removeRequestAssociation.fulfilled, (state, action) => {
    const index = state.requestAssociations.data.findIndex(
      (item) => item.id === action.payload.associationId
    );

    if (index !== -1) {
      state.requestAssociations.data.splice(index, 1);
    }
    state.requestAssociations.total--;
  });
};
