import {
  ApiAccount,
  ApiClient,
  ApiWorkflow,
  ApiWorkflowGroupPolicy,
  ApiWorkflowPolicy,
  ApiWorkflowReason,
  ApiWorkflowReportingCategory,
  ApiWorkflowUserPolicy,
  UpdateApiWorkflow,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface InitWorkflowSettingsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  workflowIdOrSlug: string;
  search?: string;
}

export const initWorkflowSettings = createAsyncThunk(
  "workflow-settings/init",
  async (params: InitWorkflowSettingsThunkParams, thunkAPI) => {
    const { apiClient, account, workflowIdOrSlug } = params;
    const [workflow, categories, reasons] = await Promise.all([
      apiClient.getWorkflow(account.id, workflowIdOrSlug),
      apiClient.findWorkflowReportingCategories(account.id, workflowIdOrSlug, {
        excluded: true,
      }),
      apiClient.findWorkflowReasons(account.id, workflowIdOrSlug, {
        pageSize: 100,
        excluded: true,
      }),
    ]);

    return {
      workflow,
      categories,
      reasons,
    };
  }
);

export const loadWorkflowGroups = createAsyncThunk(
  "workflow-settings/load-groups",
  async (params: InitWorkflowSettingsThunkParams, thunkAPI) => {
    const { apiClient, account, workflowIdOrSlug, search } = params;
    const state = thunkAPI.getState() as RootState;
    const pagedWorkflowUsers = apiClient.findWorkflowPolicies(
      account.id,
      workflowIdOrSlug,
      {
        pageSize: 20,
        type: "group",
        page: state.workflowSettings.workflowGroupsCurrentPage,
        search,
      }
    );
    return pagedWorkflowUsers;
  }
);

export const loadWorkflowUsers = createAsyncThunk(
  "workflow-settings/load-users",
  async (params: InitWorkflowSettingsThunkParams, thunkAPI) => {
    const { apiClient, account, workflowIdOrSlug, search } = params;
    const state = thunkAPI.getState() as RootState;

    const pagedWorkflowUsers = apiClient.findWorkflowPolicies(
      account.id,
      workflowIdOrSlug,
      {
        pageSize: 20,
        type: "user",
        page: state.workflowSettings.workflowUsersCurrentPage,
        search,
      }
    );
    return pagedWorkflowUsers;
  }
);

export interface UpdateWorkflowThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  workflowIdOrSlug: string;
  patch: UpdateApiWorkflow;
}

export const updateWorkflow = createAsyncThunk(
  "workflow-settings/update-workflow",
  async (params: UpdateWorkflowThunkParams, thunkAPI) => {
    const { apiClient, account, workflowIdOrSlug, patch } = params;

    return apiClient.updateWorkflow(account.id, workflowIdOrSlug, patch);
  }
);

export interface ToggleReasonExclusionParams {
  apiClient: ApiClient;
  account: ApiAccount;
  workflowIdOrSlug: string;
  reason: ApiWorkflowReason;
}

export const toggleReasonExclusion = createAsyncThunk(
  "workflow-settings/toggle-reason-exclusion",
  async (params: ToggleReasonExclusionParams, thunkAPI) => {
    const { apiClient, account, workflowIdOrSlug, reason } = params;

    const fn = (
      reason.isExcluded
        ? apiClient.deleteWorkflowReasonExclusion
        : apiClient.createWorkflowReasonExclusion
    ).bind(apiClient);

    return fn(account.id, workflowIdOrSlug, reason.id).then(() => reason);
  }
);

export interface WorkflowSettingsSliceState {
  reasons: ApiWorkflowReason[];
  reportingCategories: ApiWorkflowReportingCategory[];
  workflow: ApiWorkflow | null;

  workflowUsers: ApiWorkflowPolicy[];
  workflowUsersTotal: number;
  workflowUsersCurrentPage: number;

  workflowGroups: ApiWorkflowPolicy[];
  workflowGroupsTotal: number;
  workflowGroupsCurrentPage: number;
  workflowPageSize: number;
  userSearch: string;
  groupSearch: string;
}

export const workflowSettingsSlice = createSlice({
  name: "workflow-settings",
  initialState: {
    reasons: [],
    reportingCategories: [],
    workflow: null,
    workflowUsers: [],
    workflowUsersTotal: 0,
    workflowPageSize: 20,
    workflowUsersCurrentPage: 1,
    workflowGroups: [],
    workflowGroupsTotal: 0,
    workflowGroupsCurrentPage: 1,
    userSearch: "",
    groupSearch: "",
  } as WorkflowSettingsSliceState,
  reducers: {
    setWorkflowGroupsCurrentPage: (state, payload: PayloadAction<number>) => {
      state.workflowGroupsCurrentPage = payload.payload;
    },
    setWorkflowUsersCurrentPage: (state, payload: PayloadAction<number>) => {
      state.workflowUsersCurrentPage = payload.payload;
    },
    addWorkflowPolicy: (state, action: PayloadAction<ApiWorkflowPolicy>) => {
      if (action.payload.type === "group") {
        const index = state.workflowGroups.findIndex(
          (workGr) =>
            (workGr as ApiWorkflowGroupPolicy).group.id ===
            (action.payload as ApiWorkflowGroupPolicy).group.id
        );
        if (index > -1) {
          state.workflowGroups.splice(index, 1);
          state.workflowGroups.unshift(action.payload);
          return;
        }
        state.workflowGroups.unshift(action.payload);
        state.workflowGroupsTotal += 1;
      } else {
        const index = state.workflowUsers.findIndex(
          (workUsr) =>
            (workUsr as ApiWorkflowUserPolicy).user.id ===
            (action.payload as ApiWorkflowUserPolicy).user.id
        );
        if (index > -1) {
          state.workflowUsers.splice(index, 1);
          state.workflowUsers.unshift(action.payload);
          return;
        }
        state.workflowUsers.unshift(action.payload);
        state.workflowUsersTotal += 1;
      }
    },

    updateWorkflowPolicy: (state, action: PayloadAction<ApiWorkflowPolicy>) => {
      const itemToUpdate =
        action.payload.type === "group"
          ? state.workflowGroups.find((x) => x.id === action.payload.id)
          : state.workflowUsers.find((x) => x.id === action.payload.id);

      if (!itemToUpdate) {
        return;
      }

      Object.assign(itemToUpdate, action.payload);
    },
    removeWorkflowPolicy: (state, action: PayloadAction<ApiWorkflowPolicy>) => {
      if (action.payload.type === "group") {
        const indexToRemove = state.workflowGroups.findIndex(
          (x) => x.id === action.payload.id
        );

        if (indexToRemove > -1) {
          state.workflowGroups.splice(indexToRemove, 1);
          state.workflowGroupsTotal -= 1;
        }
        return;
      }
      const indexToRemove = state.workflowUsers.findIndex(
        (x) => x.id === action.payload.id
      );

      if (indexToRemove > -1) {
        state.workflowUsers.splice(indexToRemove, 1);
        state.workflowUsersTotal -= 1;
      }
    },
    setUserSearch: (state, action: PayloadAction<string>) => {
      state.userSearch = action.payload;
    },
    setGroupSearch: (state, action: PayloadAction<string>) => {
      state.groupSearch = action.payload;
    },
    unload: (state) => {
      state.workflow = null;
      state.reportingCategories = [];
      state.workflowUsers = [];
      state.workflowUsersTotal = 0;
      state.reasons = [];
      state.workflowPageSize = 20;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initWorkflowSettings.fulfilled, (state, action) => {
      state.workflow = action.payload.workflow;
      state.reportingCategories = action.payload.categories;
      state.reasons = action.payload.reasons.data;
    });

    builder.addCase(loadWorkflowGroups.fulfilled, (state, action) => {
      state.workflowGroups = action.payload.data;
      state.workflowGroupsTotal = action.payload.total;
    });

    builder.addCase(loadWorkflowUsers.fulfilled, (state, action) => {
      state.workflowUsers = action.payload.data;
      state.workflowUsersTotal = action.payload.total;
    });

    builder.addCase(updateWorkflow.fulfilled, (state, action) => {
      state.workflow = action.payload;
    });

    builder.addCase(toggleReasonExclusion.fulfilled, (state, action) => {
      const updated = state.reasons.find((r) => action.payload.id === r.id);
      if (!updated) {
        return;
      }

      updated.isExcluded = !updated.isExcluded;
    });
  },
});

export const {
  unload,
  addWorkflowPolicy,
  updateWorkflowPolicy,
  removeWorkflowPolicy,
  setWorkflowGroupsCurrentPage,
  setWorkflowUsersCurrentPage,
  setUserSearch,
  setGroupSearch,
} = workflowSettingsSlice.actions;

export default workflowSettingsSlice.reducer;
