import {
  ApiAccount,
  ApiClient,
  ApiReportingCategory,
  ApiReportingCategoryType,
  CreateApiReportingCategory,
  FindReportingCategoryOptions,
  UpdateApiReportingCategory,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface LocalReportingCategorySliceState {
  allCategories: ApiReportingCategory[];
  categories: ApiReportingCategory[];
  showDisabledReportingCategories: boolean;
  type: ApiReportingCategoryType | undefined;
  parentsCategories: ApiReportingCategory[];
  categoriesCurrentPage: number;
  pageSize: number;
}
export type ReportingCategoryActionType = "deactive" | "reactive";

export interface ToggleOnOrOffReportingCategoryThunkParams {
  apiClient: ApiClient;
  accountId: string;
  categoryId: string;
  type: ReportingCategoryActionType;
}
export interface InitLocalReportingCategoryThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
  includeInactive?: boolean;
  type?: string | undefined;
}
export interface CreateReportingCategoryThunkParams {
  apiClient: ApiClient;
  accountId: string;
  category: CreateApiReportingCategory;
}
export const createReportingCategory = createAsyncThunk(
  "reportingCategory/create",
  async (params: CreateReportingCategoryThunkParams, thunkAPI) => {
    const { apiClient, accountId, category } = params;
    const result = await apiClient.createReportingCategory(accountId, category);
    return result;
  }
);
export interface UpdateReportingCategoryThunkParams {
  apiClient: ApiClient;
  accountId: string;
  category: UpdateApiReportingCategory;
  categoryId: string;
}
export const updateReportingCategory = createAsyncThunk(
  "reportingCategory/update",
  async (params: UpdateReportingCategoryThunkParams, thunkAPI) => {
    const { apiClient, accountId, category, categoryId } = params;
    const result = await apiClient.updateReportingCategory(
      accountId,
      categoryId,
      category
    );
    return result;
  }
);
export const toggleActivateReportingCategory = createAsyncThunk(
  "reportingCategory/toogleActive",
  async (params: ToggleOnOrOffReportingCategoryThunkParams, thunkAPI) => {
    const { apiClient, accountId, categoryId, type } = params;
    if (type === "deactive") {
      await apiClient.deactivateReportingCategory(accountId, categoryId);
    }
    if (type === "reactive") {
      await apiClient.reactivateReportingCategory(accountId, categoryId);
    }
    return { categoryId, type };
  }
);

export const reloadReportingCategories = createAsyncThunk(
  "reportingCategory/reload-categories",
  async (
    { apiClient, account, search }: InitLocalReportingCategoryThunkParams,
    thunkAPI
  ) => {
    const { showDisabledReportingCategories, type } = (
      thunkAPI.getState() as RootState
    ).reportingCategory;
    const options: FindReportingCategoryOptions = {
      includeInactive: showDisabledReportingCategories,
      search,
      type,
      flatten: true,
    };
    const categories = await apiClient.findReportingCategories(
      account.id,
      options
    );
    return { categories };
  }
);

export const loadParentsReportingCategoriesFromCache = createAsyncThunk(
  "reportingCategory/load-categories",
  async (options: InitLocalReportingCategoryThunkParams, thunkAPI) => {
    const { categories } = (thunkAPI.getState() as RootState).localCache;
    return { categories };
  }
);

export const localReportingCategorySlice = createSlice({
  name: "reportingCategory",
  initialState: {
    allCategories: [],
    categories: [],
    showDisabledReportingCategories: false,
    type: undefined,
    parentsCategories: [],
    categoriesCurrentPage: 1,
    pageSize: 20,
  } as LocalReportingCategorySliceState,
  reducers: {
    unloadReportingCategory: (state) => {
      state.allCategories = [];
      state.categories = [];
      state.parentsCategories = [];
      state.type = undefined;
      state.showDisabledReportingCategories = false;
      state.categoriesCurrentPage = 1;
      state.pageSize = 20;
    },
    toggleShowDisabledReportingCategorys: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showDisabledReportingCategories = action.payload;
    },
    setReportingCategoryTypeFilter: (
      state,
      payload: PayloadAction<string | undefined>
    ) => {
      state.type = payload.payload as ApiReportingCategoryType;
    },
    setCategoriesCurrentPage: (state, payload: PayloadAction<number>) => {
      state.categoriesCurrentPage = payload.payload;
      state.categories = state.allCategories.slice(
        (state.categoriesCurrentPage - 1) * state.pageSize,
        state.categoriesCurrentPage * state.pageSize
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadParentsReportingCategoriesFromCache.fulfilled,
      (state, action) => {
        state.parentsCategories = action.payload.categories.filter(
          (cat) => cat.code.split(":").length === 1
        );
      }
    );
    builder.addCase(reloadReportingCategories.fulfilled, (state, action) => {
      state.allCategories = action.payload.categories;
      state.categories = action.payload.categories.slice(
        (state.categoriesCurrentPage - 1) * state.pageSize,
        state.categoriesCurrentPage * state.pageSize
      );
    });
    builder.addCase(createReportingCategory.fulfilled, (state, action) => {
      action.payload.active = true;
      state.categories.unshift(action.payload);
    });
    builder.addCase(updateReportingCategory.fulfilled, (state, action) => {
      const index = state.categories.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index !== -1) {
        state.categories[index] = action.payload;
      }
    });
    builder.addCase(
      toggleActivateReportingCategory.fulfilled,
      (state, action) => {
        const { type, categoryId } = action.payload;
        const indexToRemove = state.allCategories.findIndex(
          (reportingCategory: ApiReportingCategory) =>
            reportingCategory.id === categoryId
        );
        if (type === "reactive") {
          state.allCategories[indexToRemove].active = true;
          if (state.allCategories[indexToRemove].children.length !== 0) {
            //reactivate all its children
            const childrenIds = state.allCategories[indexToRemove].children.map(
              (child) => child.id
            );
            for (const childId of childrenIds) {
              const indexToReactivate = state.allCategories.findIndex(
                (reportingCategory: ApiReportingCategory) =>
                  reportingCategory.id === childId
              );
              state.allCategories[indexToReactivate].active = true;
            }
          }
          state.categories = state.allCategories.slice(
            (state.categoriesCurrentPage - 1) * state.pageSize,
            state.categoriesCurrentPage * state.pageSize
          );
          return;
        }
        if (indexToRemove !== -1 && type === "deactive") {
          state.allCategories[indexToRemove].active = false;
          if (state.allCategories[indexToRemove].children.length !== 0) {
            //deactivate all its children
            const childrenIds = state.allCategories[indexToRemove].children.map(
              (child) => child.id
            );
            for (const childId of childrenIds) {
              const indexToDeactivate = state.allCategories.findIndex(
                (reportingCategory: ApiReportingCategory) =>
                  reportingCategory.id === childId
              );
              if (indexToRemove !== -1) {
                state.allCategories[indexToDeactivate].active = false;
                state.allCategories.splice(indexToDeactivate, 1);
              }
            }
          }
          if (state.showDisabledReportingCategories === false) {
            state.allCategories.splice(indexToRemove, 1);
          }
          state.categories = state.allCategories.slice(
            (state.categoriesCurrentPage - 1) * state.pageSize,
            state.categoriesCurrentPage * state.pageSize
          );
          return;
        }
      }
    );
  },
});

export const {
  unloadReportingCategory,
  toggleShowDisabledReportingCategorys,
  setReportingCategoryTypeFilter,
  setCategoriesCurrentPage,
} = localReportingCategorySlice.actions;

export default localReportingCategorySlice.reducer;
