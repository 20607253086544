import { Country, ICountry } from "country-state-city";

export const supportedCountries = ["US", "CA"];
export const defaultCountry = "US";

export const getAccountCountry = (isoCode: string) => {
  return (
    Country.getCountryByCode(isoCode) ??
    (Country.getCountryByCode(defaultCountry) as ICountry)
  );
};
