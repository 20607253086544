import {
  ApiAccount,
  ApiClient,
  ApiEventGroup,
  ApiGroupStatus,
  ApiPagingEventGroupOptions,
  ApiRateGroup,
  ApiUserReference,
  EventGroupDeclineReasonsKeys,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { uniqueMap } from "../utils/uniqueMap";

export interface InitFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  selectedValues?: string | string[];
}
export interface LoadFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
}
interface LoadEventGroupsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: ApiPagingEventGroupOptions;
}

interface UpdateEventGroupStatusParams extends LoadEventGroupsThunkParams {
  eventGroupId: string;
  status: ApiGroupStatus;
  owner: ApiUserReference;
  additionalNotes?: string;
  declineReasons?: EventGroupDeclineReasonsKeys[];
}

interface GetGroupParams extends LoadEventGroupsThunkParams {
  groupId: string;
}

export const loadEventGroups = createAsyncThunk(
  "event/groups/load",
  async (params: LoadEventGroupsThunkParams, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState).eventGroupList;
    const { apiClient, accountId } = params;

    const response = await apiClient.findEventGroups(accountId, filters);
    return response;
  }
);

export const updateEventGroupState = createAsyncThunk(
  "event/groups/update-status",
  async (params: UpdateEventGroupStatusParams, ThunkAPI) => {
    const {
      apiClient,
      accountId,
      eventGroupId,
      status,
      owner,
      additionalNotes,
      declineReasons,
    } = params;
    const response = await apiClient.updateEventGroup(accountId, eventGroupId, {
      status,
      owner,
      additionalNotes: additionalNotes,
      declineReasons,
    });
    return response;
  }
);

export const getEventGroup = createAsyncThunk(
  "event/group/load",
  async (params: GetGroupParams, ThunkAPI) => {
    const { apiClient, accountId, groupId } = params;
    const response = await apiClient.getEventGroup(accountId, groupId);
    return response;
  }
);
export const initRateGroupFilter = createAsyncThunk(
  "event/group/init-rateGroup-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = {
      ids: selectedValues
        ? Array.isArray(selectedValues)
          ? selectedValues
          : [selectedValues]
        : [],
    };
    const data = await apiClient.findRateGroups(account.id, options);
    return data.data;
  }
);
export const loadRateGroupFilter = createAsyncThunk(
  "event/group/load-rateGroup-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
    };
    const data = await apiClient.findRateGroups(account.id, options);
    return data.data;
  }
);
export interface EventGroupListState {
  data: ApiEventGroup[];
  total: number;
  filters: ApiPagingEventGroupOptions;
  selectedEventGroup: ApiEventGroup | null;
  rateGroups: ApiRateGroup[];
}

export const DEFAULT_EVENT_GROUP_FILTERS: ApiPagingEventGroupOptions = {
  page: 1,
  search: "",
  pageSize: 20,
  includeInactive: false,
  isInternalGroup: undefined,
  rateGroups: undefined,
  direction: "asc",
  sort: "name",
  created: undefined,
  updated: undefined,
  insuranceExpiration: undefined,
};

export const eventGroupList = createSlice({
  name: "eventGroupList",
  initialState: {
    data: [],
    total: 1,
    filters: { ...DEFAULT_EVENT_GROUP_FILTERS },
    selectedEventGroup: null,
    rateGroups: [],
  } as EventGroupListState,
  reducers: {
    unloadGroups: (state: EventGroupListState) => {
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_EVENT_GROUP_FILTERS;
      state.selectedEventGroup = null;
    },
    setSelectedEventGroupAdditionalNotes: (
      state: EventGroupListState,
      action: PayloadAction<string>
    ) => {
      if (state.selectedEventGroup) {
        state.selectedEventGroup.additionalNotes = action.payload;
      }
    },
    updateFilters: (
      state: EventGroupListState,
      action: PayloadAction<ApiPagingEventGroupOptions>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    cleanSelectedGroup: (state: EventGroupListState) => {
      state.selectedEventGroup = null;
    },
    updateSelectedGroupState: (
      state: EventGroupListState,
      action: PayloadAction<ApiGroupStatus>
    ) => {
      if (
        state.selectedEventGroup &&
        state.selectedEventGroup.status !== action.payload
      )
        state.selectedEventGroup.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadEventGroups.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filters.page = options.page;
      state.data = data;
      state.total = total;
    });
    builder.addCase(updateEventGroupState.fulfilled, (state, action) => {
      const { id, status } = action.payload;
      const stateToUpdate = state.data.find((s) => s.id === id);
      if (stateToUpdate) stateToUpdate.status = status;
      if (state.selectedEventGroup !== null) {
        state.selectedEventGroup.status = action.payload.status;
        state.selectedEventGroup.declineReasons = action.payload.declineReasons;
      }
    });
    builder.addCase(getEventGroup.fulfilled, (state, action) => {
      state.selectedEventGroup = action.payload;
    });
    builder.addCase(initRateGroupFilter.fulfilled, (state, action) => {
      state.rateGroups = action.payload;
    });
    builder.addCase(loadRateGroupFilter.fulfilled, (state, action) => {
      const selected = state.filters.rateGroups;
      const itemsToAdd = selected
        ? Array.isArray(selected)
          ? selected
          : [selected]
        : [];

      state.rateGroups = uniqueMap<ApiRateGroup>(
        itemsToAdd,
        state.rateGroups,
        action.payload
      );
    });
  },
});

export const {
  unloadGroups,
  updateFilters,
  cleanSelectedGroup,
  updateSelectedGroupState,
  setSelectedEventGroupAdditionalNotes,
} = eventGroupList.actions;

export default eventGroupList.reducer;
