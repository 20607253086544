import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { mapApiAttachments } from "../../../components/attachments/Attachments";
import { RequestSliceState } from "../request-form.slice";

// LOAD GALLERY
export interface LoadLocationGalleryParams {
  apiClient: ApiClient;
  accountId: string;
  locationId: string;
}

export const loadLocationsGallery = createAsyncThunk(
  "requests/location-gallery",
  async (params: LoadLocationGalleryParams, thunkAPI) => {
    const { apiClient, accountId, locationId } = params;

    try {
      return await apiClient.getGalleryByLocation(accountId, locationId);
    } catch {
      return null;
    }
  }
);

export const loadLocationsGalleryHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadLocationsGallery.fulfilled, (state, action) => {
    state.locationGallery.gallery = action.payload;
    state.locationGallery.loading = "idle";
    state.locationGallery.isOpen = false;
    state.locationGallery.images = [];
    state.locationGallery.imagesTotal = 0;
  });
};

// LOAD GALLERY IMAGES
export interface LoadGalleryImagesParams {
  apiClient: ApiClient;
  accountId: string;
  galleryId: string;
}

export const loadGalleryImages = createAsyncThunk(
  "requests/location-gallery-images",
  async (params: LoadGalleryImagesParams, thunkAPI) => {
    const { apiClient, accountId, galleryId } = params;

    const state = thunkAPI.getState() as RootState;

    try {
      const result = await apiClient.findGalleryAttachments(
        accountId,
        galleryId,
        { page: state.requestForm.locationGallery.imagesPage }
      );
      const mappedData = mapApiAttachments(result.data);
      return { ...result, data: mappedData };
    } catch {
      return null;
    }
  }
);

export const loadGalleryImagesHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadGalleryImages.fulfilled, (state, action) => {
    if (action.payload !== null) {
      state.locationGallery.images = action.payload.data;
      state.locationGallery.imagesTotal = action.payload.total;
    }
  });
};
