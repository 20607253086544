import { CheckIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { ApiRequestStatus } from "@operations-hero/lib-api-client";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getVisibleFields } from "../../utils/getVisibleFields";
import { scrollToSection } from "../../utils/scrollToSection";

export enum Section {
  taskbooks = "Taskbooks",
  assets = "Assets",
  labor = "Labor",
  expenses = "Expenses",
  inventory = "Inventory",
}

const { taskbooks, assets, labor, expenses, inventory } = Section;
interface ScrollToSectionButtonProps {
  name: Section;
  domRef: any;
  totalSectionItems?: number;
  isSectionVisible: boolean;
  updateVisibility: (isVisible: boolean) => void;
  updateSectionItemsTotal?: () => void;
  fieldDomRef?: any;
}

export const ScrollToSectionButton: FC<ScrollToSectionButtonProps> = ({
  name,
  domRef,
  isSectionVisible,
  updateVisibility,
  fieldDomRef,
}: ScrollToSectionButtonProps) => {
  const {
    taskbooksTotal,
    assetsTotal,
    expensesTotal,
    inventoryTotal,
    laborTotal,
    workflow,
    request,
    visibleFields,
  } = useSelector((state: RootState) => state.requestForm);

  const [sectionItemQty, setSectionItemQty] = useState(0);
  const [displayButton, setDisplayButton] = useState(true);

  const { showAssets, showExpenses, showLabor, showTaskbooks } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const {
    areAssetsAllowed,
    areExpensesAllowed,
    areTaskbooksAllowed,
    isLaborAllowed,
  } = useMemo(() => {
    const areTaskbooksAllowed = workflow ? showTaskbooks : true;
    const areAssetsAllowed = workflow ? showAssets : true;
    const areExpensesAllowed = workflow ? showExpenses : true;
    const isLaborAllowed = workflow ? showLabor : true;
    return {
      areAssetsAllowed,
      areTaskbooksAllowed,
      areExpensesAllowed,
      isLaborAllowed,
    };
  }, [workflow, showAssets, showExpenses, showLabor, showTaskbooks]);

  const isStatusClosedOrCanceled = useMemo(() => {
    return (
      request?.status === ApiRequestStatus.closed ||
      request?.status === ApiRequestStatus.canceled
    );
  }, [request?.status]);

  const displayTaskbookButton = useMemo(() => {
    return (
      (areTaskbooksAllowed && isStatusClosedOrCanceled && taskbooksTotal > 0) ||
      (areTaskbooksAllowed && !isStatusClosedOrCanceled) ||
      (!areTaskbooksAllowed && taskbooksTotal > 0)
    );
  }, [areTaskbooksAllowed, isStatusClosedOrCanceled, taskbooksTotal]);

  const displayAssetsButton = useMemo(() => {
    return (
      (areAssetsAllowed && isStatusClosedOrCanceled && assetsTotal > 0) ||
      (areAssetsAllowed && !isStatusClosedOrCanceled) ||
      (!areAssetsAllowed && assetsTotal > 0)
    );
  }, [areAssetsAllowed, isStatusClosedOrCanceled, assetsTotal]);

  const sectionRef = domRef;
  const sectionHiddenByDefault = sectionItemQty === 0;

  useEffect(() => {
    if (sectionRef.current && sectionHiddenByDefault) {
      if (
        (name === Section.taskbooks || name === Section.assets) &&
        sectionItemQty === 0 &&
        fieldDomRef
      ) {
        scrollToSection(sectionRef, fieldDomRef);
      } else {
        scrollToSection(sectionRef);
      }
    }
  }, [
    isSectionVisible,
    sectionHiddenByDefault,
    sectionRef,
    fieldDomRef,
    name,
    sectionItemQty,
  ]);

  useEffect(() => {
    updateVisibility(sectionItemQty > 0);
  }, [sectionItemQty, updateVisibility, request?.status]);

  useEffect(() => {
    switch (name) {
      case taskbooks:
        setSectionItemQty(taskbooksTotal);
        setDisplayButton(displayTaskbookButton);
        break;
      case assets:
        setSectionItemQty(assetsTotal);
        setDisplayButton(displayAssetsButton);
        break;
      case expenses:
        setSectionItemQty(expensesTotal);
        setDisplayButton(expensesTotal > 0 || areExpensesAllowed);
        break;
      case inventory:
        setSectionItemQty(inventoryTotal);
        break;
      case labor:
        setSectionItemQty(laborTotal);
        setDisplayButton(laborTotal > 0 || isLaborAllowed);

        break;
      default:
        setSectionItemQty(0);
        break;
    }
  }, [
    taskbooksTotal,
    assetsTotal,
    expensesTotal,
    laborTotal,
    name,
    areAssetsAllowed,
    areExpensesAllowed,
    areTaskbooksAllowed,
    isLaborAllowed,
    displayTaskbookButton,
    displayAssetsButton,
    inventoryTotal,
  ]);

  const handleClick = () => {
    if (isSectionVisible === false) {
      updateVisibility(true);
    } else {
      if (
        sectionRef.current &&
        (name === Section.taskbooks || name === Section.assets) &&
        sectionItemQty === 0 &&
        fieldDomRef
      ) {
        scrollToSection(sectionRef, fieldDomRef);
      } else if (sectionRef.current) {
        scrollToSection(sectionRef);
      }
    }
  };

  return displayButton ? (
    <Button
      fontSize="sm"
      colorScheme="blue"
      h="8"
      rounded="3xl"
      variant={isSectionVisible ? "solid" : "outline"}
      onClick={handleClick}
      leftIcon={isSectionVisible ? <CheckIcon w="3" h="3" /> : undefined}
      minWidth="max-content"
    >
      {sectionItemQty === 0 ? `${name}` : `${name} (${sectionItemQty})`}
    </Button>
  ) : null;
};
