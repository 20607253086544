import {
  ApiClient,
  CreateApiRequestReminder,
  UpdateApiRequestReminder,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export interface LoadRequestReminderParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
}

export const loadRequestReminders = createAsyncThunk(
  "request/reminders",
  async (params: LoadRequestReminderParams, thunkAPI) => {
    const { apiClient, accountId, requestId } = params;
    const { filters } = (thunkAPI.getState() as RootState).requestForm
      .reminders;
    const result = await apiClient.findRequestReminders(
      accountId,
      requestId,
      filters
    );
    return result;
  }
);

export const loadRequestRemindersHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadRequestReminders.fulfilled, (state, action) => {
    if (state.request) {
      state.reminders.loading = "succeeded";
      if (action.payload) {
        state.reminders.data = action.payload.data;
        state.reminders.total = action.payload.total;
        state.reminders.filters = action.payload.options;
      }
    }
  });
  builder.addCase(loadRequestReminders.pending, (state, action) => {
    state.reminders.loading = "pending";
  });
  builder.addCase(loadRequestReminders.rejected, (state, action) => {
    state.reminders.loading = "failed";
  });
};

export interface CreateRequestReminderParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  reminder: CreateApiRequestReminder;
}

export const createRequestReminder = createAsyncThunk(
  "requests/reminder/create",
  async (params: CreateRequestReminderParams, thunkAPI) => {
    const { apiClient, accountId, requestId, reminder } = params;

    const result = await apiClient.createRequestReminder(
      accountId,
      requestId,
      reminder
    );
    return result;
  }
);

export const createRequestReminderHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(createRequestReminder.fulfilled, (state, action) => {
    if (state.request) {
      state.reminders.data.push(action.payload);
    }
  });
};

export interface UpdateRequestReminderParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  reminder: UpdateApiRequestReminder;
  reminderId: string;
}

export const updateRequestReminder = createAsyncThunk(
  "requests/reminder/update",
  async (params: UpdateRequestReminderParams, thunkAPI) => {
    const { apiClient, accountId, requestId, reminder, reminderId } = params;

    return await apiClient.updateRequestReminder(
      accountId,
      requestId,
      reminder,
      reminderId
    );
  }
);

export const updateRequestReminderHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(updateRequestReminder.fulfilled, (state, action) => {
    if (state.request) {
      const idx = state.reminders.data.findIndex(
        (data) => data.id === action.payload.id
      );
      if (idx !== -1) {
        const newState = [...state.reminders.data];
        newState[idx] = action.payload;
        state.reminders.data = newState;
      }
    }
  });
};
