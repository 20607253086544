import { Box } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { useShowToast } from "../../hooks/showToast";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  UserOptionsProps,
  UserSingleValueSelect,
} from "./select-overrides-types";

export interface EventGroupUserAutocompleteProps {
  name?: string;
  value: ApiUserSummary | null;
  allowEmpty?: boolean;
  emptyText?: string;
  onChange?: (user: ApiUserSummary | null) => void;
  isDisabled?: boolean;
  placeholder?: string;
  eventGroupId: string | null;
}

const CustomOptionComponent = createOptionComponent(UserBadge);

const CustomSingleValueComponent = (props: UserSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
};

export const EventGroupUserAutocomplete = ({
  placeholder,
  onChange,
  name,
  value: user,
  isDisabled,
  eventGroupId,
  allowEmpty,
  emptyText,
}: EventGroupUserAutocompleteProps) => {
  const showToast = useShowToast();
  const { currentAccount, apiClient } = useAuthentication();

  const nullUser: ApiUserSummary = useMemo(
    () => ({
      firstName: emptyText || "No User",
      lastName: "",
      id: "null-user",
      email: "",
      phone: "",
      profileImage: "",
      timeZone: null,
    }),
    [emptyText],
  );
  const handleChange = useCallback(
    (value: ApiUserSummary | null) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      try {
        let users: ApiUserSummary[] = [];
        if (allowEmpty) {
          users.push(nullUser);
        }
        if (eventGroupId) {
          const response = await apiClient.findEventGroupUsers(
            currentAccount.id,
            eventGroupId,
            { search: inputValue },
          );
          const { data } = response;
          const eventGroupUsers = data.map(
            (groupUserInfo) => groupUserInfo.user,
          );
          users.push(...eventGroupUsers);
        } else {
          const res = await apiClient.findAccountUsers(currentAccount.id, {
            search: inputValue,
          });
          users.push(...res.data);
        }
        cb(users);
        return users;
      } catch (error) {
        showToast("error", "Something went wrong loading users");
        return [] as ApiUserSummary[];
      }
    },
    [
      apiClient,
      currentAccount.id,
      showToast,
      eventGroupId,
      allowEmpty,
      nullUser,
    ],
  );

  const debouncedLoadOptions = debounce(loadOptions, 250);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: UserOptionsProps) => CustomOptionComponent(props),
      SingleValue: (props: UserSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      key={`users::${user?.id}${eventGroupId}`}
      name={name}
      defaultOptions={true}
      value={user || null}
      cacheOptions={true}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      getOptionValue={(item: ApiUserSummary) => item.id}
      isDisabled={isDisabled}
      placeholder={placeholder}
      chakraStyles={commonStyles}
    />
  );
};
