import {
  ApiClient,
  ApiMergeVendors,
  ApiPagingOptions,
  ApiPagingVendorContactsOptions,
  ApiVendor,
  ApiVendorContact,
  CreateApiVendor,
  CreateApiVendorContact,
  UpdateApiVendor,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  createVendorContactHandler,
  deactivateVendorContactHandler,
  loadVendorContactsHandler,
  reactivateVendorContactHandler,
  updateVendorContactHandler,
} from "./vendor-contacts.thunk";

interface LoadVendorsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: ApiVendorFilters;
}

export const loadVendors = createAsyncThunk(
  "event/vendors/load",
  async (params: LoadVendorsThunkParams, ThunkAPI) => {
    let { filters } = (ThunkAPI.getState() as RootState).vendorSlice;
    if (params.filters) filters = params.filters;
    const { apiClient, accountId } = params;

    if (
      filters.isManufacturer &&
      filters.isServiceProvider &&
      filters.isSupplier
    ) {
      filters = {
        ...filters,
        isManufacturer: false,
        isServiceProvider: false,
        isSupplier: false,
      };
    }
    const response = await apiClient.findVendors(accountId, filters);
    return response;
  }
);

interface CreateVendorParams {
  apiClient: ApiClient;
  accountId: string;
  vendor: CreateApiVendor;
}

export const createVendor = createAsyncThunk(
  "event/vendors/new",
  async (params: CreateVendorParams) => {
    const { apiClient, accountId, vendor } = params;
    const response = apiClient.createVendor(accountId, vendor);

    return response;
  }
);

interface UpdateVendorParams {
  apiClient: ApiClient;
  accountId: string;
  vendorId: string;
  vendor: UpdateApiVendor;
}

export const updateVendor = createAsyncThunk(
  "event/vendors/update",
  async (params: UpdateVendorParams, ThunkAPI) => {
    const { workingVendor } = (ThunkAPI.getState() as RootState).vendorSlice;

    const { apiClient, accountId, vendor, vendorId } = params;

    const primaryContact =
      vendor.primaryContact as unknown as CreateApiVendorContact;

    if (
      workingVendor &&
      workingVendor.primaryContact === null &&
      primaryContact
    ) {
      const primaryContactHasChanges = primaryContact.name !== "";
      const contact = primaryContactHasChanges
        ? await apiClient.createVendorContact(
            accountId,
            vendorId,
            primaryContact
          )
        : null;
      const response = await apiClient.updateVendor(accountId, vendorId, {
        ...vendor,
        primaryContact: contact ? contact.id : null,
      });
      return { vendorId, response, contact };
    }

    const response = await apiClient.updateVendor(accountId, vendorId, vendor);
    if (!workingVendor || !workingVendor.primaryContact)
      return Promise.reject({});

    const contact = await apiClient.updateVendorContact(
      accountId,
      vendorId,
      workingVendor.primaryContact.id,
      vendor.primaryContact as unknown as CreateApiVendorContact
    );

    return { vendorId, response, contact };
  }
);

interface DeactivateVendorParams {
  apiClient: ApiClient;
  accountId: string;
  vendorId: string;
}

interface MergeVendorParams {
  apiClient: ApiClient;
  accountId: string;
  mergeVendors: ApiMergeVendors;
}

export const deactivateVendor = createAsyncThunk(
  "event/vendors/deactivate",
  async (params: DeactivateVendorParams) => {
    const { apiClient, accountId, vendorId } = params;
    await apiClient.deactivateVendor(accountId, vendorId);

    return { vendorId };
  }
);

export const reactivateVendor = createAsyncThunk(
  "event/vendors/reactivate",
  async (params: DeactivateVendorParams) => {
    const { apiClient, accountId, vendorId } = params;
    await apiClient.reactivateVendor(accountId, vendorId);

    return { vendorId };
  }
);

export const mergeVendors = createAsyncThunk(
  "event/vendors/merge",
  async (params: MergeVendorParams) => {
    const { apiClient, accountId, mergeVendors } = params;
    const updatedVendor = await apiClient.mergeVendors(accountId, mergeVendors);
    return { mergeVendors, updatedVendor };
  }
);

// VENDOR-SLICE
export interface ApiVendorFilters extends ApiPagingOptions {
  search?: string;
  includeInactive?: boolean;
  isManufacturer: boolean;
  isServiceProvider: boolean;
  isSupplier: boolean;
}

export interface VendorSlice {
  data: ApiVendor[];
  total: number;
  filters: ApiVendorFilters;
  isOpenCreateModal: boolean;
  workingVendor: ApiVendor | null;
  isOpenContactModal: boolean;
  workingVendorContacts: {
    isReadOnly: boolean;
    total: number;
    data: ApiVendorContact[];
    filters: ApiPagingVendorContactsOptions;
    workingContact: ApiVendorContact | null;
  };
}

const DEFAULT_VENDOR_FILTERS: ApiVendorFilters = {
  page: 1,
  search: "",
  pageSize: 20,
  includeInactive: false,
  isManufacturer: false,
  isServiceProvider: false,
  isSupplier: false,
};

const DEFAULT_VENDOR_CONTACT_FILTERS: ApiPagingVendorContactsOptions = {
  page: 1,
  search: "",
  pageSize: 5,
  includeInactive: false,
};

export const vendorSlice = createSlice({
  name: "serviceList",
  initialState: {
    data: [],
    total: 1,
    filters: { ...DEFAULT_VENDOR_FILTERS },
    isOpenCreateModal: false,
    workingVendor: null,
    isOpenContactModal: false,
    workingVendorContacts: {
      data: [],
      total: 0,
      isReadOnly: false,
      workingContact: null,
      filters: {
        ...DEFAULT_VENDOR_CONTACT_FILTERS,
        pageSize: 5,
      },
    },
  } as VendorSlice,
  reducers: {
    unloadVendors: (state: VendorSlice) => {
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_VENDOR_FILTERS;
      state.isOpenCreateModal = false;
    },
    setIsOpenFormModal: (
      state: VendorSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenCreateModal = action.payload;
    },
    setWorkingVendor: (
      state: VendorSlice,
      action: PayloadAction<ApiVendor | null>
    ) => {
      state.workingVendor = action.payload;
    },
    updateFilters: (
      state: VendorSlice,
      action: PayloadAction<Partial<ApiVendorFilters>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setIsOpenContactModal: (
      state: VendorSlice,
      action: PayloadAction<{ isOpen: boolean; isReadOnly?: boolean }>
    ) => {
      const { isOpen, isReadOnly } = action.payload;
      state.isOpenContactModal = isOpen;
      isReadOnly !== undefined &&
        (state.workingVendorContacts.isReadOnly = isReadOnly);
    },
    setWorkingContact: (
      state: VendorSlice,
      action: PayloadAction<ApiVendorContact | null>
    ) => {
      state.workingVendorContacts.workingContact = action.payload;
    },
    updateContactFilters: (
      state: VendorSlice,
      action: PayloadAction<Partial<ApiPagingVendorContactsOptions>>
    ) => {
      state.workingVendorContacts.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    cleanWorkingContacts: (state: VendorSlice) => {
      state.workingVendor = null;
      state.workingVendorContacts = {
        data: [],
        total: 0,
        isReadOnly: false,
        filters: { ...DEFAULT_VENDOR_CONTACT_FILTERS },
        workingContact: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadVendors.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filters.page = options.page;
      state.filters.pageSize = options.pageSize;
      state.data = data;
      state.total = total;
    });

    builder.addCase(createVendor.fulfilled, (state, action) => {
      state.data.unshift(action.payload);
      state.total++;
    });

    builder.addCase(updateVendor.fulfilled, (state, action) => {
      const { response, vendorId, contact } = action.payload;
      const index = state.data.findIndex((vendor) => vendor.id === vendorId);
      if (index !== -1) {
        state.data[index] = response;
        state.data[index].primaryContact = contact;
      }
    });

    builder.addCase(deactivateVendor.fulfilled, (state, action) => {
      const { vendorId } = action.payload;
      const index = state.data.findIndex((vendor) => vendor.id === vendorId);
      if (index !== -1) {
        state.filters.includeInactive
          ? (state.data[index].isActive = false)
          : state.data.splice(index, 1);
      }
    });

    builder.addCase(reactivateVendor.fulfilled, (state, action) => {
      const { vendorId } = action.payload;
      const index = state.data.findIndex((vendor) => vendor.id === vendorId);
      if (index !== -1) {
        state.data[index].isActive = true;
      }
    });

    builder.addCase(mergeVendors.fulfilled, (state, action) => {
      const { mergeVendors, updatedVendor } = action.payload;
      const newData = state.data
        .filter((v) => !mergeVendors.source.includes(v.id))
        .map((v) => (v.id === updatedVendor.id ? updatedVendor : v));
      state.data = newData;
    });

    // Contacts
    loadVendorContactsHandler(builder);
    createVendorContactHandler(builder);
    updateVendorContactHandler(builder);
    deactivateVendorContactHandler(builder);
    reactivateVendorContactHandler(builder);
  },
});

export const {
  setIsOpenFormModal,
  unloadVendors,
  updateFilters,
  setWorkingVendor,
  setIsOpenContactModal,
  setWorkingContact,
  updateContactFilters,
  cleanWorkingContacts,
} = vendorSlice.actions;

export default vendorSlice.reducer;
