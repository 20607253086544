import { DocumentProps, PDFViewer, usePDF } from "@react-pdf/renderer";
import { useCallback, useRef } from "react";
import { PdfViewerModalBase } from "./PdfViewerModalBase";

export interface PdfViewerModalProps {
  isOpen: boolean;
  onClose: () => void;
  pdfDocument: React.ReactElement<
    DocumentProps,
    string | React.JSXElementConstructor<any>
  >;
  downloadName: string;
  pdfUrl?: string | null;
}

export const PdfViewerModal = ({
  isOpen,
  onClose,
  pdfDocument,
  downloadName,
  pdfUrl,
}: PdfViewerModalProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [instance] = usePDF({
    document: pdfDocument,
  });

  const downloadPdf = useCallback(() => {
    if (!instance.url) {
      return;
    }
    const link = document.createElement("a");
    link.href = pdfUrl ? pdfUrl : instance.url;
    link.setAttribute("download", downloadName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [instance, downloadName, pdfUrl]);

  const printPdf = useCallback(() => {
    iframeRef.current?.contentWindow?.print();
  }, []);

  return (
    <PdfViewerModalBase
      onClose={onClose}
      isOpen={isOpen}
      showActions={!instance.error && !instance.loading}
      downloadPdf={downloadPdf}
      printPdf={printPdf}
    >
      <PDFViewer
        innerRef={iframeRef}
        width="100%"
        height="100%"
        showToolbar={false}
      >
        {pdfDocument}
      </PDFViewer>
    </PdfViewerModalBase>
  );
};
