import { FC } from "react";
import "./loading-text.css";

export interface LoadingTextProps {
  text: string;
}
export const LoadingText: FC<LoadingTextProps> = (params) => {
  const { text } = params;
  return <p className="loading">{text}</p>;
};
