import { ApiLocation, ApiLocationType } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface LocationTree {
  node: ApiLocation;
  children: LocationTree[];
  matched: boolean;
}

export interface LocalLocationSliceState {
  expandedMap: Record<string, boolean>;
  locationsProducts: Record<string, string[]>;
}

export interface InitLocalLocationThunkParams {
  initExpandedMap: boolean;
}

export const loadLocationsExpandedMap = createAsyncThunk(
  "locations/load-locations",
  async ({ initExpandedMap }: InitLocalLocationThunkParams, thunkAPI) => {
    if (!initExpandedMap) return Promise.reject();
    const { locationMap } = (thunkAPI.getState() as RootState).localCache;

    return { locationMap };
  }
);

export const loadLocationsProducts = createAsyncThunk(
  "locations/load-locations-products",
  async ({ initExpandedMap }: InitLocalLocationThunkParams, thunkAPI) => {
    const { locationMap } = (thunkAPI.getState() as RootState).localCache;

    const mapLocationsProducts = Object.keys(locationMap).reduce(
      (tree, key) => {
        tree[key] = locationMap[key].hiddenProducts || [];
        return tree;
      },
      {} as Record<string, string[]>
    );

    return { locationsProducts: mapLocationsProducts };
  }
);

export const localLocationSlice = createSlice({
  name: "locations",
  initialState: {
    expandedMap: {},
    locationsProducts: {},
  } as LocalLocationSliceState,
  reducers: {
    unloadCache: (state) => {
      state.expandedMap = {};
      state.locationsProducts = {};
    },
    expandAllLocationMap: (state, action: PayloadAction<boolean>) => {
      state.expandedMap = Object.keys(state.expandedMap).reduce<
        Record<string, boolean>
      >((result, item) => {
        result[item] = action.payload;
        return result;
      }, {});
    },
    expandLocations: (
      state,
      action: PayloadAction<{ locations: string[]; expanded: boolean }>
    ) => {
      const { locations, expanded } = action.payload;
      locations.forEach((loc) => {
        state.expandedMap[loc] = !expanded;
      });
    },
    updateLocationsProducts: (
      state,
      action: PayloadAction<{ locationsProducts: Record<string, string[]> }>
    ) => {
      Object.assign(state.locationsProducts, action.payload.locationsProducts);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLocationsExpandedMap.fulfilled, (state, action) => {
      const { locationMap } = action.payload;
      const res = Object.keys(locationMap).reduce<Record<string, boolean>>(
        (result, id) => {
          result[id] =
            locationMap[id].type === ApiLocationType.region &&
            locationMap[id].parent === null;
          return result;
        },
        {}
      );
      state.expandedMap = res;
    });
    builder.addCase(loadLocationsProducts.fulfilled, (state, action) => {
      state.locationsProducts = action.payload.locationsProducts;
    });
  },
});

export const {
  unloadCache,
  expandAllLocationMap,
  expandLocations,
  updateLocationsProducts,
} = localLocationSlice.actions;

export default localLocationSlice.reducer;
