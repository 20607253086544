import {
  Badge,
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiCategoryPrediction,
  ApiWorkflowReference,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { createFilter, MenuPlacement, Select } from "chakra-react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HiOutlineSparkles } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../store";
import { loadWorkflowReportingCategories } from "../../store/local-cache.slice";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CategoryOptionsProp,
  CategorySingleValueSelect,
  CustomSelectComponentProp,
} from "./select-overrides-types";

export interface ReportingCategoryAutocompleteProps {
  workflow?: ApiWorkflowReference;
  value: ApiWorkflowReportingCategorySummary | null;
  name?: string;
  onChange: (value: ApiWorkflowReportingCategorySummary | null) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  forceReload?: boolean;
  placeholder?: string;
  isInvalid?: boolean;
  predictedCategories?: ApiCategoryPrediction[] | null;
  menuPlacement?: MenuPlacement;
}

const groupDivider = (<hr />) as unknown as string;

export const CategorySelectBadge = ({
  value,
}: {
  value: ApiWorkflowReportingCategorySummary & { isSuggestion?: boolean };
}) => {
  const { categoriesMap } = useSelector((state: RootState) => state.localCache);
  return value ? (
    <Flex w="full" alignItems="center">
      <Flex w={value.isSuggestion ? "96%" : "full"} alignItems="center">
        <Text
          as="span"
          display="inline-block"
          fontWeight="bold"
          fontSize="sm"
          pr={2}
          minWidth="80px"
        >
          {value.code}
        </Text>
        {value.name}
        {!categoriesMap[value.id]?.active && (
          <Badge colorScheme="gray" ml={4} p={1}>
            Inactive
          </Badge>
        )}
      </Flex>
      {value.isSuggestion && (
        <Box display="flex" justifyContent="flex-end">
          <Icon as={HiOutlineSparkles} flex={1} boxSize="1.2em" />
        </Box>
      )}
    </Flex>
  ) : null;
};

const CustomOptionComponent = createOptionComponent(CategorySelectBadge);

const CustomSingleValueComponent = (props: CategorySingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <CategorySelectBadge value={data} />
    </Box>
  );
};

export const ReportingCategoryAutocomplete = ({
  workflow,
  value: category,
  onChange,
  onBlur,
  isDisabled,
  name,
  forceReload,
  placeholder,
  isInvalid,
  predictedCategories,
  menuPlacement,
}: ReportingCategoryAutocompleteProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const classTheme = useColorModeValue(
    "select-light-theme",
    "select-dark-theme"
  );

  const [options, setOptions] = useState<ApiWorkflowReportingCategorySummary[]>(
    []
  );
  const { categories, workflowCategories } = useSelector(
    (state: RootState) => state.localCache
  );
  const thunkDispatch = useThunkDispatch();

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CategoryOptionsProp) => CustomOptionComponent(props),
      SingleValue: (props: CategorySingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  const filter = useMemo(
    () =>
      createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: "any",
        stringify: (option: any) => {
          return `${option.data.code} ${option.data.name}`;
        },
      }),
    []
  );

  const handleChange = useCallback(
    (newValue: ApiWorkflowReportingCategorySummary | null) => {
      if (onChange) onChange(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (workflow) {
      const workflowId = typeof workflow === "string" ? workflow : workflow.id;
      thunkDispatch(
        loadWorkflowReportingCategories({
          apiClient,
          currentAccount,
          workflowId,
          forceReload,
        })
      );
    }
  }, [apiClient, currentAccount, thunkDispatch, workflow, forceReload]);

  useEffect(() => {
    if (!workflow) {
      setOptions(categories.filter((cat) => cat.active));
      return;
    }
    const workflowId = typeof workflow === "string" ? workflow : workflow.id;
    setOptions(workflowCategories[workflowId]);
  }, [categories, workflow, workflowCategories]);

  const mappedPredictions = useMemo(() => {
    if (!options) return [];
    if (!predictedCategories || predictedCategories.length === 0) return [];

    // ensure predictions are in the options, then sorted by probability
    // options is the larger of the 2 arrays, so we iterate over that once
    const suggestedCategories = options.reduce<
      (ApiWorkflowReportingCategorySummary & {
        isSuggestion: boolean;
        probability: number;
      })[]
    >((acc, cat) => {
      const prediction = predictedCategories.find(
        (pred) => pred.value === cat.code
      );
      if (prediction) {
        acc.push({
          ...cat,
          isSuggestion: true,
          probability: prediction.probability,
        });
      }
      return acc;
    }, []);

    // sorted by the highest probability
    const sorted = suggestedCategories.sort((a, b) => {
      return b.probability - a.probability;
    });

    return sorted;
  }, [options, predictedCategories]);

  const groupedOptions = useMemo(() => {
    if (!options) return [];
    // no predications means no grouping of options
    if (!mappedPredictions || mappedPredictions.length === 0) return options;

    // predictions first then all other options.
    let groups = [
      { label: "AI Suggestions", options: mappedPredictions },
      { label: groupDivider, options },
    ];
    return groups;
  }, [options, mappedPredictions]);

  return (
    <Select
      backspaceRemovesValue
      className={classTheme}
      isInvalid={isInvalid}
      components={components}
      filterOption={filter}
      isClearable
      isDisabled={isDisabled}
      isSearchable
      name={name}
      onChange={handleChange}
      onBlur={onBlur}
      options={groupedOptions}
      value={category}
      chakraStyles={commonStyles}
      menuPlacement={menuPlacement}
      placeholder={isDisabled ? "N/A" : placeholder || "Search Category..."}
      getOptionValue={(item: ApiWorkflowReportingCategorySummary) => item.id}
    />
  );
};
