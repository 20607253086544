import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Icon,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import {
  MdAdd,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";

interface AccountHeaderProps {
  title: string;
  counter?: number;
  buttonLabel?: string;
  moreInfo?: string;
  collapseLabel?: string;
  onClickCreateButton?: () => void;
  autoFocus?: boolean;
  buttonsTitle?: React.ReactNode;
  buttonsLeftTitle?: React.ReactNode;
  children?: React.ReactNode;
}

export const AccountPageHeader: FC<AccountHeaderProps> = ({
  title,
  children,
  buttonLabel,
  collapseLabel,
  onClickCreateButton,
  autoFocus,
  buttonsTitle,
  buttonsLeftTitle,
  moreInfo,
  counter,
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });
  const filterBarColor = useColorModeValue("blue.50", "blue.900");
  const filterLabelColor = useColorModeValue("blue.500", "white");
  const { isOpen, onClose, onOpen } = useDisclosure();

  const childrensArray = useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  // Children must be wrapped with "GridItem" with its props to use them correctly
  const filtersElements = useMemo(() => {
    if (childrensArray.length) {
      return (
        <Grid templateColumns="repeat(12, 2fr)" gap="12px" alignItems="center">
          {childrensArray.map((element) => element)}
        </Grid>
      );
    }
  }, [childrensArray]);

  return (
    <Stack
      w="100%"
      spacing={[2]}
      flexDir="row"
      flexWrap="wrap"
      alignItems="center"
    >
      <HStack width={"100%"}>
        <HStack flex={1} alignContent="baseline" alignItems="baseline">
          <Heading size="lg" display="inline-flex" gap={2} isTruncated>
            {title}{" "}
            {moreInfo && (
              <Text fontSize="lg" alignSelf="flex-end">
                {moreInfo}
              </Text>
            )}
          </Heading>
          {counter && <Text fontSize="larger">{`(${counter})`}</Text>}

          {buttonsLeftTitle}
        </HStack>

        <Box>
          {buttonLabel && onClickCreateButton && (
            <Button
              size={isMobile ? "sm" : "md"}
              onClick={onClickCreateButton}
              colorScheme="blue"
              autoFocus={autoFocus}
            >
              <Icon as={MdAdd} mr={2} />
              {buttonLabel}
            </Button>
          )}
          {buttonsTitle}
        </Box>
      </HStack>

      {childrensArray.length && !isMobile && (
        <StackItem
          display="flex"
          w="100%"
          minH="50px"
          alignItems="center"
          bgColor={filterBarColor}
          mt={2}
        >
          <Box w="100%" p={2}>
            {filtersElements}
          </Box>
        </StackItem>
      )}

      {childrensArray.length && isMobile && (
        <StackItem w="100%" bgColor={filterBarColor} mt={2}>
          <Stack p={2} w="100%">
            {isOpen && <>{filtersElements} </>}
            <StackItem
              w="max-content"
              alignSelf="flex-end"
              color={filterLabelColor}
              onClick={isOpen ? onClose : onOpen}
              mt="0 !important"
            >
              <Text as="span">
                {isOpen ? "Hide " : "Show "}
                {collapseLabel || "search and filters"}
              </Text>
              <Icon
                boxSize="18px"
                verticalAlign="middle"
                as={isOpen ? MdOutlineExpandLess : MdOutlineExpandMore}
              />
            </StackItem>
          </Stack>
        </StackItem>
      )}
    </Stack>
  );
};
