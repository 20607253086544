import {
  ApiAccount,
  ApiClient,
  ApiPagedResult,
  ApiRequest,
  ApiRequestTaskBook,
  ApiUser,
  ApiWorkflow,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  canReopen,
  generateTransition,
  isLocked,
} from "../request-form.helpers";
import { RequestSliceState } from "../request-form.slice";

export interface InitRequestFormLiteThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiUser;
  isProductAdmin: boolean;
  workflow: ApiWorkflow;
  request: ApiRequest;
}

export const initRequestFormFromList = createAsyncThunk(
  "requests/initRequestFormFromList",
  async (params: InitRequestFormLiteThunkParams, thunkAPI) => {
    const { apiClient, account, isProductAdmin, user, workflow, request } =
      params;
    const { locationMap, categoriesMap, descendantsMap, policyMap } = (
      thunkAPI.getState() as RootState
    ).localCache;

    const policy = policyMap[workflow.id] ?? null;

    const [{ data: schemaFields }, { data: taskbooks }] = await Promise.all([
      apiClient.findWorkflowSchemaFields(account.id, workflow.schema.id, {
        pageSize: 100,
      }),
      request && request.totalTaskbooks > 0
        ? apiClient.findRequestTaskbooks(account.id, request.id)
        : ({
            data: [] as ApiRequestTaskBook[],
          } as ApiPagedResult<ApiRequestTaskBook>),
    ]);

    return {
      request,
      workflow,
      schemaFields,
      isProductAdmin,
      taskbooks,
      locationMap,
      descendantsMap,
      categoriesMap,
      user,
      policy,
    };
  }
);

export const initRequestFormFromListHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(initRequestFormFromList.fulfilled, (state, action) => {
    const isCreator =
      action.payload.request?.createdBy.id === action.payload.user.id;
    const isRequester =
      action.payload.user.id === action.payload.request?.requester.id;
    const policies =
      action.payload.policy ??
      ({
        admin: false,
        approver: false,
        technician: false,
        reviewer: false,
        contractor: false,
        requester: isCreator || isRequester,
      } as WorkflowPolicy);

    const noPol =
      Object.values(policies).every((pol) => pol === false) &&
      !action.payload.isProductAdmin &&
      !action.payload.workflow.allowUsersToSubmitRequests;

    state.request = !noPol ? action.payload.request : null;
    state.schemaFields = action.payload.schemaFields;
    state.workflow = action.payload.workflow;
    state.taskbooks = action.payload.taskbooks;
    state.policy = policies;

    state.isCurrentRequester = isRequester;
    const { admin, approver, technician, reviewer, requester, contractor } =
      policies;
    state.isRequesterOnly =
      requester &&
      !approver &&
      !contractor &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;

    state.isContractor =
      contractor &&
      state.isCurrentRequester &&
      !approver &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;

    state.isContractorOnly =
      contractor &&
      !state.isCurrentRequester &&
      !approver &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;
    state.isApproverOnly =
      approver &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;

    state.canReopen =
      action.payload.request && !noPol
        ? canReopen(
            state.workflow!,
            state.policy!,
            action.payload.request,
            action.payload.isProductAdmin
          )
        : false;
    state.transition =
      action.payload.request && !noPol
        ? generateTransition(
            action.payload.workflow,
            policies,
            action.payload.request,
            action.payload.isProductAdmin,
            action.payload.descendantsMap,
            action.payload.categoriesMap
          )
        : null;
    state.formIsLocked =
      action.payload.request && !noPol
        ? isLocked(action.payload.request, state.transition)
        : true;
    state.isInit = true;
    state.taskbooksTotal = action.payload.request?.totalTaskbooks || 0;
    state.assetsTotal = action.payload.request?.totalAssets || 0;
  });
};
