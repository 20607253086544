import { Button, Icon, useColorModeValue } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { MdCheck } from "react-icons/md";

interface QuickButtonProps {
  activeText: string;
  inactiveText?: string;
  isActive: boolean;
  onClick: () => void;
}

export const QuickFilterButton: FC<QuickButtonProps> = ({
  isActive,
  activeText,
  inactiveText,
  onClick,
}) => {
  const buttonBgColor = useColorModeValue("whiteAlpha.800", "gray.700");
  const hoverActiveBgColor = useColorModeValue("blue.900", "whiteAlpha.400");
  const hoverInactiveBgColor = useColorModeValue("gray.200", "whiteAlpha.400");
  const borderColor = useColorModeValue("gray.300", "white");
  const textColor = useColorModeValue("blue.500", "white");

  const quickButtonStyles = useMemo(() => {
    return {
      borderRadius: 50,
      border: "1px solid",
      color: isActive ? "white" : textColor,
      borderColor: isActive ? "transparent" : borderColor,
      backgroundColor: isActive ? "blue.500" : buttonBgColor,
      _hover: {
        backgroundColor: isActive ? hoverActiveBgColor : hoverInactiveBgColor,
      },
    };
  }, [
    isActive,
    textColor,
    borderColor,
    buttonBgColor,
    hoverActiveBgColor,
    hoverInactiveBgColor,
  ]);

  return (
    <Button onClick={onClick} sx={quickButtonStyles} size="sm" maxH="28px">
      {isActive && <Icon as={MdCheck} mr={2} />}
      {isActive ? activeText : inactiveText ? inactiveText : activeText}
    </Button>
  );
};
