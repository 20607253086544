import { Tag, TagLabel, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { ApiRequest, ApiRequestStatus } from "@operations-hero/lib-api-client";
import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";

export const RequestSchedulingSummary = ({
  request,
}: {
  request: ApiRequest;
}) => {
  const noScheduleColor = useColorModeValue("gray.400", "white");
  const bgWhite = useColorModeValue("white", "#3F444E");

  const isOverdue = useMemo(() => {
    const now = new Date();
    return (
      ![
        ApiRequestStatus.canceled,
        ApiRequestStatus.closed,
        ApiRequestStatus.review,
      ].includes(request.status) &&
      request.scheduling.due != null &&
      new Date(request.scheduling.due).getTime() < now.getTime()
    );
  }, [request]);

  const isUpcoming = useMemo(() => {
    const now = new Date();
    if (request.scheduling.start == null) {
      return false;
    }
    return (
      [ApiRequestStatus.new, ApiRequestStatus.approved].includes(
        request.status
      ) &&
      request.scheduling.start != null &&
      new Date(request.scheduling.start).getTime() > now.getTime()
    );
  }, [request]);

  const isDue = useMemo(() => {
    const now = new Date();
    if (request.scheduling.start == null) {
      return false;
    }
    return (
      [
        ApiRequestStatus.new,
        ApiRequestStatus.approved,
        ApiRequestStatus.queued,
        ApiRequestStatus.started,
      ].includes(request.status) &&
      request.scheduling.due != null &&
      new Date(request.scheduling.due).getTime() > now.getTime()
    );
  }, [request]);

  const display = useMemo(() => {
    if (
      [ApiRequestStatus.canceled, ApiRequestStatus.closed].includes(
        request.status
      )
    ) {
      return {
        text: `Closed ${formatDistanceToNow(new Date(request.statusUpdated!))}`,
        scheme: "green",
        tooltip: `Closed ${new Date(request.statusUpdated!).toLocaleString()}`,
      };
    }

    if (isOverdue && request.scheduling.due) {
      return {
        text: `Overdue for ${formatDistanceToNow(
          new Date(request.scheduling.due)
        )}`,
        scheme: "red",
        tooltip: `Due ${new Date(request.scheduling.due).toLocaleString()}`,
      };
    }

    if (isUpcoming && request.scheduling.start) {
      return {
        text: `Starts in ${formatDistanceToNow(
          new Date(request.scheduling.start)
        )}`,
        scheme: "yellow",
        tooltip: `Starts ${new Date(
          request.scheduling.start
        ).toLocaleString()}`,
      };
    }

    if (isDue && request.scheduling.due) {
      return {
        text: `Due in ${formatDistanceToNow(new Date(request.scheduling.due))}`,
        scheme: "orange",
        tooltip: `Due ${new Date(request.scheduling.due).toLocaleString()}`,
      };
    }

    if (ApiRequestStatus.queued === request.status) {
      return {
        text: `Queued ${formatDistanceToNow(new Date(request.statusUpdated!), {
          addSuffix: true,
        })}`,
        scheme: "",
        tooltip: `Status Updated ${new Date(
          request.statusUpdated!
        ).toLocaleString()}`,
      };
    }

    if (ApiRequestStatus.started === request.status) {
      return {
        text: `Started ${formatDistanceToNow(new Date(request.statusUpdated!), {
          addSuffix: true,
        })}`,
        scheme: "",
        tooltip: `Status Updated ${new Date(
          request.statusUpdated!
        ).toLocaleString()}`,
      };
    }

    if (ApiRequestStatus.review === request.status) {
      return {
        text: `Sent for review ${formatDistanceToNow(
          new Date(request.scheduling.completed!),
          {
            addSuffix: true,
          }
        )}`,
        scheme: "",
        tooltip: `Completed ${new Date(
          request.scheduling.completed!
        ).toLocaleString()}`,
      };
    }

    const prefix = request.created === request.updated ? "Created" : "Updated";
    return {
      text: `${prefix} ${formatDistanceToNow(new Date(request.updated), {
        addSuffix: true,
      })}`,
      scheme: "",
      tooltip: `${prefix} ${new Date(request.updated).toLocaleString()}`,
    };
  }, [isOverdue, isUpcoming, isDue, request]);

  return (
    <Tooltip label={display.tooltip}>
      <Tag
        colorScheme={display.scheme}
        bgColor={display.scheme ? undefined : bgWhite}
        color={display.scheme ? undefined : noScheduleColor}
      >
        <TagLabel>{display.text}</TagLabel>
      </Tag>
    </Tooltip>
  );
};
