import { ApiQuestion, ApiSpaceSummary } from "@operations-hero/lib-api-client";

export interface SpaceQuestionResponseReducerValues {
  question: ApiQuestion;
  space: ApiSpaceSummary;
  value?: string | string[] | boolean | number;
  questionResponseId?: string;
}

export interface SpaceQuestionResponseReducerAction
  extends Partial<SpaceQuestionResponseReducerValues> {
  index: number;
}

export const initialSpaceQuestionResponseState: SpaceQuestionResponseReducerValues[] =
  [];

export const spaceQuestionsResponsesReducer = (
  values: SpaceQuestionResponseReducerValues[],
  action: SpaceQuestionResponseReducerAction
) => {
  const newValues = [...values];
  const newValue = { ...values[action.index], ...action };
  newValues[action.index] = newValue;
  return newValues;
};
