import {
  ApiAccount,
  ApiCatalog,
  ApiClient,
  CreateApiCatalog,
  UpdateApiCatalog,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface LocalCatalogSliceState {
  catalogs: ApiCatalog[];
  pageSize: number;
  includeInactive: boolean;
}
export type CatalogActionType = "deactive" | "reactive";

export interface ToggleOnOrOffCatalogThunkParams {
  apiClient: ApiClient;
  accountId: string;
  catalogId: string;
  type: CatalogActionType;
}
export interface InitLocalCacheThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
}
export interface CreateCatalogThunkParams {
  apiClient: ApiClient;
  accountId: string;
  catalog: CreateApiCatalog;
}
export interface UpdateCatalogThunkParams {
  apiClient: ApiClient;
  accountId: string;
  catalog: UpdateApiCatalog;
  catalogId: string;
}
export const createCatalog = createAsyncThunk(
  "catalog/create",
  async (params: CreateCatalogThunkParams, thunkAPI) => {
    const { apiClient, accountId, catalog } = params;
    const result = await apiClient.createCatalog(accountId, catalog);
    return result;
  }
);
export const updateCatalog = createAsyncThunk(
  "catalog/update",
  async (params: UpdateCatalogThunkParams, thunkAPI) => {
    const { apiClient, accountId, catalog, catalogId } = params;
    const result = await apiClient.updateCatalog(accountId, catalogId, catalog);
    return result;
  }
);
export const toggleActivateCatalog = createAsyncThunk(
  "catalog/toogleActive",
  async (params: ToggleOnOrOffCatalogThunkParams, thunkAPI) => {
    const { apiClient, accountId, catalogId, type } = params;
    if (type === "deactive") {
      await apiClient.deactivateCatalog(accountId, catalogId);
    }
    if (type === "reactive") {
      await apiClient.reactivateCatalog(accountId, catalogId);
    }
    return { catalogId, type };
  }
);

export const reloadCatalogs = createAsyncThunk(
  "catalog/reload-catalogs",
  async (
    { apiClient, account, search }: InitLocalCacheThunkParams,
    thunkAPI
  ) => {
    const { includeInactive, pageSize } = (thunkAPI.getState() as RootState)
      .catalog;
    const catalogs = await apiClient.findCatalogs(account.id, {
      includeInactive: includeInactive,
      pageSize: pageSize,
      search: search,
    });
    return { catalogs: catalogs.data };
  }
);

export const localCatalogSlice = createSlice({
  name: "catalog",
  initialState: {
    catalogs: [],
    pageSize: 100,
    includeInactive: false,
  } as LocalCatalogSliceState,
  reducers: {
    unloadCache: (state) => {
      state.catalogs = [];
    },
    setIncludeInactive: (state, action: PayloadAction<boolean>) => {
      state.includeInactive = action.payload;
    },
    deleteCatalog: (state, action: PayloadAction<string>) => {
      const index: string = action.payload;
      state.catalogs.forEach((catalog: ApiCatalog) => {
        if (catalog.id === index && state.includeInactive === false) {
          catalog.active = false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reloadCatalogs.fulfilled, (state, action) => {
      state.catalogs = action.payload.catalogs;
    });
    builder.addCase(createCatalog.fulfilled, (state, action) => {
      action.payload.active = true;
      state.catalogs.unshift(action.payload);
    });
    builder.addCase(updateCatalog.fulfilled, (state, action) => {
      const index = state.catalogs.findIndex((x) => x.id === action.payload.id);
      if (index !== -1) {
        state.catalogs[index] = action.payload;
      }
    });
    builder.addCase(toggleActivateCatalog.fulfilled, (state, action) => {
      const { type, catalogId } = action.payload;
      const indexToRemove = state.catalogs.findIndex(
        (catalog: ApiCatalog) => catalog.id === catalogId
      );
      state.catalogs[indexToRemove].active = true;
      if (indexToRemove !== -1 && type === "deactive") {
        state.catalogs[indexToRemove].active = false;
        if (state.includeInactive === false) {
          state.catalogs.splice(indexToRemove, 1);
        }
      }
    });
  },
});

export const { unloadCache, setIncludeInactive, deleteCatalog } =
  localCatalogSlice.actions;

export default localCatalogSlice.reducer;
