import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Spinner,
  Switch,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { RoutesProps } from "react-router-dom";

export interface AutoSavingSwitchProps extends RoutesProps {
  id: string;
  isChecked: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  label: string;
  onSave: (value: boolean) => Promise<void>;
}

export const AutoSavingSwitch = (props: AutoSavingSwitchProps) => {
  const {
    isChecked,
    isReadOnly,
    isDisabled,
    onSave,
    id,
    label,
    ...switchProps
  } = props;
  const [internalValue, setInternalValue] = useState(isChecked);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  useEffect(() => {
    setInternalValue(isChecked);
  }, [isChecked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // no change from original
    if (e.target.checked === isChecked) {
      return;
    }

    setSaving(true);
    setSaveError(false);
    setInternalValue(e.target.checked);

    onSave(e.target.checked)
      .then(() => {
        setSaveError(false);
      })
      .catch(() => {
        setSaveError(true);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <FormControl
      display="flex"
      alignItems="center"
      pt={2}
      pb={2}
      height="2.5rem"
    >
      <Switch
        id={id}
        isChecked={internalValue}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={handleChange}
        {...switchProps}
      />
      <FormLabel htmlFor={id} mt={1} ml={2}>
        {label}
      </FormLabel>
      <Box ml={2}>
        {saving && <Spinner size="md" maxH="100%" color="gray.300" />}
        {saveError && <Icon mb={1} as={BsExclamationCircle} color="tomato" />}
      </Box>
    </FormControl>
  );
};
