import { EventContentArg } from "@fullcalendar/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import moment from "moment";
import { FC, useMemo } from "react";
import { LargeTimelineItem } from "./TimelineItem.lg";
import { BaseTimelineItem } from "./TimelineItem.md";
import { SmallTimelineItem } from "./TimelineItem.sm";
import { LineTimelineItem } from "./TimelineItemLine";
import { ViewType } from "./types";
type RequestTimelineItemProps = { args: EventContentArg };

export const RequestTimelineItem: FC<RequestTimelineItemProps> = ({ args }) => {
  const request = args.event.extendedProps as ApiRequest;
  const viewType = args.view.type;

  const durationInDays = useMemo(() => {
    let diffInDays = 6;
    const { start, end } = args.event;
    if (!start || !end) return diffInDays;
    diffInDays = moment(end).diff(start, "d");
    return diffInDays;
  }, [args]);

  if (viewType === ViewType.MONTHLY) {
    if (durationInDays > 0 && durationInDays < 2) {
      return <SmallTimelineItem request={request} />;
    }
    if (durationInDays >= 2 && durationInDays < 3) {
      return <BaseTimelineItem request={request} />;
    }
    if (durationInDays >= 3) {
      return <LargeTimelineItem request={request} />;
    }
  }

  if (viewType === ViewType.YEARLY) {
    if (durationInDays < 12) {
      return <LineTimelineItem request={request} />;
    }
    if (durationInDays >= 12 && durationInDays < 30) {
      return <SmallTimelineItem request={request} />;
    }
    if (durationInDays >= 30 && durationInDays < 40) {
      return <BaseTimelineItem request={request} />;
    }
    if (durationInDays >= 40) {
      return <LargeTimelineItem request={request} />;
    }
  }

  if (viewType === ViewType.WEEKLY) {
    if (durationInDays < 3) {
      return <LineTimelineItem request={request} />;
    }
    if (durationInDays >= 3 && durationInDays < 5) {
      return <SmallTimelineItem request={request} />;
    }
    if (durationInDays >= 5 && durationInDays < 10) {
      return <BaseTimelineItem request={request} />;
    }
    if (durationInDays >= 10) {
      return <LargeTimelineItem request={request} />;
    }
  }

  return null;
};
