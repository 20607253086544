import {
  Box,
  chakra,
  HStack,
  RadioProps,
  Text,
  useColorMode,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { FC } from "react";
import {
  ViewType,
  ViewTypeKey,
  ViewTypeValue,
} from "../project/scheduling/types";

const ViewTypeRadioValue: { [key in ViewTypeKey]: string } = {
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
  YEARLY: "Yearly",
} as const;

type ViewRadioButtonProps = RadioProps & { viewType: ViewTypeKey };
export const ViewRadioButton: FC<ViewRadioButtonProps> = ({
  viewType,
  ...radioProps
}) => {
  const { colorMode } = useColorMode();
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(radioProps);

  return (
    <chakra.label {...htmlProps} cursor="pointer">
      <input {...getInputProps({})} hidden />
      <Box
        {...getRadioProps()}
        bg={
          state.isChecked
            ? colorMode === "light"
              ? "gray.200"
              : "gray.600"
            : "unset"
        }
        px={2}
        py={1}
        rounded="md"
      >
        <Text {...getLabelProps()} fontSize="smaller" fontWeight="semibold">
          {ViewTypeRadioValue[viewType]}
        </Text>
      </Box>
    </chakra.label>
  );
};

type ViewTypeRadioGroupProps = {
  onChange: (value: ViewTypeValue) => void;
};
export const ViewTypeRadioGroup: FC<ViewTypeRadioGroupProps> = ({
  onChange,
}) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: ViewType.MONTHLY,
    onChange: (value: ViewTypeValue) => {
      onChange(value);
    },
  });

  return (
    <HStack {...getRootProps()}>
      <ViewRadioButton
        key={ViewType.MONTHLY}
        viewType="MONTHLY"
        {...getRadioProps({ value: ViewType.MONTHLY })}
      />
      <ViewRadioButton
        key={ViewType.WEEKLY}
        viewType="WEEKLY"
        {...getRadioProps({ value: ViewType.WEEKLY })}
      />
      <ViewRadioButton
        key={ViewType.YEARLY}
        viewType="YEARLY"
        {...getRadioProps({ value: ViewType.YEARLY })}
      />
    </HStack>
  );
};
