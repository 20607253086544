import {
  Box,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInventoryOrderSummary,
  ApiTimelineEntry,
  ApiTimelineEntryObjectType,
} from "@operations-hero/lib-api-client";
import { format, parseISO } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import {
  DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
  LocaleDate,
} from "../../../../components/dates/LocaleDate";
import { Pager } from "../../../../components/pager/Pager";
import { useShowToast } from "../../../../hooks/showToast";
import { EntryHeaderAvatar } from "./EntryHeaderAvatar";
import { InventoryOrderTimelineBody } from "./InventoryOrderTimelineBody";

interface InventoryOrderTimelineProps {
  inventoryOrder: ApiInventoryOrderSummary;
}

const ENTRY_FORMAT = "yyyy-MM";
const MONTH_FORMAT = "MMMM yyyy";
export default function InventoryOrderTimeline({
  inventoryOrder,
}: InventoryOrderTimelineProps) {
  const { apiClient, currentAccount } = useAuthentication();
  const [timeline, setTimeline] = useState<Record<string, ApiTimelineEntry[]>>(
    {}
  );
  const [options, setOptions] = useState({ page: 1, total: 0, pageSize: 100 }); //100 or less, because of collapse months (there could be more than 100 entries) all months open as default

  const [openMonths, setOpenMonths] = useState<Record<string, boolean>>({});
  const showToast = useShowToast();

  const getInventoryOrderTimeline = useCallback(() => {
    if (!inventoryOrder) return;
    apiClient
      .findTimelineEntries(currentAccount.id, {
        objectType: ApiTimelineEntryObjectType.inventoryOrder,
        objectId: inventoryOrder.id,
        page: options.page,
        pageSize: options.pageSize,
      })
      .then((result) => {
        const groupedEntries = result.data.reduce(
          (acc, entry) => {
            const month = format(parseISO(entry.created), ENTRY_FORMAT);
            if (!acc[month]) {
              acc[month] = [];
            }
            acc[month].push(entry);
            return acc;
          },
          {} as Record<string, ApiTimelineEntry[]>
        );
        setTimeline(groupedEntries);
        setOptions({ ...options, total: result.total });
        const initialOpenState = Object.keys(groupedEntries).reduce(
          (acc, month) => {
            acc[month] = true;
            return acc;
          },
          {} as Record<string, boolean>
        );
        setOpenMonths(initialOpenState);
      })
      .catch(() => {
        showToast(
          "error",
          "Something went wrong loading Inventory Order timeline entries"
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryOrder, apiClient, currentAccount.id, options.page, showToast]);

  const handleOnChangePage = useCallback(
    (value: number) => {
      setOptions({ ...options, page: value });
    },
    [options]
  );

  const toggleMonth = useCallback((month: string) => {
    setOpenMonths((prev) => ({ ...prev, [month]: !prev[month] }));
  }, []);

  useEffect(() => {
    if (!inventoryOrder) return;
    getInventoryOrderTimeline();
  }, [getInventoryOrderTimeline, inventoryOrder]);

  return (
    <>
      <Heading
        fontSize="lg"
        display="flex"
        w="max-content"
        cursor="pointer"
        alignItems="center"
      >
        Timeline
      </Heading>
      <Box display="block" height="850px" overflowY="auto" gap={6}>
        <Flex flexDir="column">
          <Divider
            mt={4}
            left="30px"
            color="black"
            position="absolute"
            orientation="vertical"
            borderLeft="4px"
            height={options.total * 150 + "px"}
            maxHeight="850px"
          />
          {Object.entries(timeline).map(([month, entries]) => {
            const formattedMonth = format(
              parseISO(`${month}-01`),
              MONTH_FORMAT
            );
            return (
              <React.Fragment key={month}>
                <Heading
                  fontSize="lg"
                  display="flex"
                  w="max-content"
                  cursor="pointer"
                  onClick={() => toggleMonth(month)}
                  alignItems="center"
                  my={4}
                  ml={8}
                >
                  {formattedMonth}
                  <Icon
                    as={openMonths[month] ? MdArrowDropUp : MdArrowDropDown}
                  />
                </Heading>

                <Collapse key={month} in={openMonths[month]} unmountOnExit>
                  <VStack
                    alignItems="flex-start"
                    gap={4}
                    ml="30px"
                    divider={<Divider />}
                  >
                    {entries.map((entry) => (
                      <>
                        <Flex
                          mt={2}
                          key={entry.id}
                          flexDir="row"
                          gap={4}
                          w="100%"
                        >
                          <Box
                            ml="-30px"
                            zIndex={10}
                            border="2px solid black"
                            bgColor="white"
                            opacity={1}
                            borderRadius="100%"
                            height="15px"
                            width="17px"
                          />

                          <EntryHeaderAvatar entry={entry} />
                          <Flex flexDir="column" mt={-1} w="100%">
                            <InventoryOrderTimelineBody
                              entry={entry}
                              inventoryOrder={inventoryOrder}
                            />

                            <Box mt={2}>
                              <LocaleDate
                                date={entry.created}
                                options={{
                                  ...DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
                                  month: "short",
                                }}
                                textProps={{
                                  pl: 1,
                                  isTruncated: true,
                                  fontWeight: "normal",
                                }}
                              />
                            </Box>
                          </Flex>
                        </Flex>
                      </>
                    ))}
                  </VStack>
                </Collapse>
              </React.Fragment>
            );
          })}
          {options.total > options.pageSize && (
            <Flex w="100%" justifyContent="flex-end">
              <Pager
                currentPage={options.page}
                pageSize={options.pageSize}
                total={options.total}
                onPageChange={handleOnChangePage}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
}
