import {
  InputAddonProps,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputStepper,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import {
  Blue300Color,
  Blue500Color,
  Red300Color,
  Red500Color,
} from "../form-helpers/NumberInputControl";

export interface NumberValue {
  valueAsString: string;
  valueAsNumber: number;
}

interface CustomNumberInputProps {
  min?: number;
  max?: number;
  prefix?: string;
  sufix?: string;
  precision?: number;
  value?: NumberValue;
  showStepper?: boolean;
  onChange: (value: NumberValue) => void;
  onBlur?: () => void;
  isInvalid?: boolean;
  readOnly?: boolean;
  isDisabled?: boolean;
  bgColor?: string;
  placeHolder?: string;
  inputGroupProps?: InputGroupProps;
  numberInputFieldProps?: NumberInputFieldProps;
  InputLeftAddonProps?: InputAddonProps;
}

export const CustomNumberInput: FC<CustomNumberInputProps> = ({
  min,
  max,
  value,
  prefix,
  sufix,
  onChange,
  onBlur,
  precision,
  showStepper,
  isInvalid,
  isDisabled,
  readOnly = false,
  bgColor,
  placeHolder,
  inputGroupProps,
  numberInputFieldProps,
  InputLeftAddonProps,
}) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const shadowColor = useColorModeValue(Blue500Color, Blue300Color);
  const errorShadowColor = useColorModeValue(Red500Color, Red300Color);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnChange = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      onChange({ valueAsString, valueAsNumber });
    },
    [onChange]
  );

  return (
    <InputGroup
      h="40px"
      border={readOnly ? "none" : "1px solid"}
      borderRadius={6}
      borderColor={
        isInvalid ? errorShadowColor : isOpen ? "blue.500" : borderColor
      }
      boxShadow={
        isInvalid
          ? `0px 0px 0px 1px ${errorShadowColor}`
          : isOpen
          ? `0px 0px 0px 1px ${shadowColor}`
          : "none"
      }
      bgColor={bgColor}
      {...inputGroupProps}
    >
      {prefix && (
        <InputLeftAddon
          children={prefix}
          border="none"
          w="44px"
          h="38px"
          {...InputLeftAddonProps}
        />
      )}
      <NumberInput
        min={min}
        max={max}
        precision={precision}
        keepWithinRange={false}
        onChange={handleOnChange}
        onBlur={onBlur}
        isDisabled={isDisabled}
        value={
          readOnly
            ? Number(value?.valueAsString).toFixed(precision) ||
              value?.valueAsString
            : value?.valueAsString
        }
      >
        <NumberInputField
          border="none"
          shadow="none"
          boxShadow="none"
          precision={precision}
          onFocus={readOnly ? undefined : onOpen}
          padding={readOnly ? 0 : undefined}
          onBlur={onClose}
          _focus={{ shadow: "none" }}
          _invalid={{ boxShadow: "none", borderColor: "none" }}
          readOnly={readOnly}
          placeholder={placeHolder}
          {...numberInputFieldProps}
        />
        {showStepper && !readOnly && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </NumberInput>
      {sufix && (
        <InputRightAddon children={sufix} border="none" w="44px" h="38px" />
      )}
    </InputGroup>
  );
};
