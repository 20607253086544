import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Icon,
  Link,
  Stack,
  StackItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiUser, ApiUserSummary } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback, useState } from "react";
import { MdAdd } from "react-icons/md";
import { AccountModal } from "../../pages/account-settings/account-modal/AccountModal";
import { QuickEventUserForm } from "../../pages/events/event-form/form-components/QuickEventUserForm";
import { useAuthentication } from "../auth/AuthProvider";
import { EventGroupUserAutocomplete } from "../selects/EventGroupUserAutocomplete";

export interface EventGroupUserAutocompleteControlProps {
  label: string;
  name: string;
  value: ApiUserSummary | null;
  assignSelf?: string;
  emptyText?: string;
  labelProps?: FormLabelProps;
  eventGroupId?: string;
  isDisabled?: boolean;
  placeholder?: string;
}

export enum CreateUserType {
  contact = "Contact",
  owner = "Owner",
}

export type EventGroupUserAutocompleteWithCreateNewProps =
  EventGroupUserAutocompleteControlProps &
    (
      | {
          showCreate?: true;
          createOptions: CreateUserOptions;
        }
      | {
          showCreate?: false;
          createOptions?: CreateUserOptions;
        }
    );

export interface CreateUserOptions {
  isDisabled: boolean;
  userType: CreateUserType;
  formType: "inlineForm" | "modal";
  onCreate?: (user: ApiUser) => void;
}

export const EventGroupUserAutocompleteControl = ({
  name,
  value,
  label,
  assignSelf,
  emptyText,
  labelProps,
  eventGroupId,
  showCreate,
  createOptions,
  isDisabled,
  placeholder,
}: EventGroupUserAutocompleteWithCreateNewProps) => {
  const { submitCount } = useFormikContext();
  const { currentUser } = useAuthentication();

  const [openCreateForm, setOpenCreateForm] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue("gray.50", "gray.600");
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (user: ApiUserSummary | null) => {
      helper.setTouched(true);
      helper.setValue(user || null);
    },
    [helper]
  );

  const handleAssignSelf = useCallback(() => {
    handleOnChange(currentUser);
  }, [currentUser, handleOnChange]);

  const handleOnCreate = useCallback(
    (user: ApiUser) => {
      helper.setTouched(true);
      helper.setValue(user);

      if (createOptions?.onCreate) createOptions.onCreate(user);
      setOpenCreateForm(false);
    },
    [helper, createOptions]
  );

  const handleOpenCreateForm = useCallback(() => {
    if (!createOptions) return;
    if (createOptions.formType === "inlineForm") setOpenCreateForm(true);
    else onOpen();
  }, [createOptions, onOpen]);

  return (
    <Stack
      flexDir="row"
      flexWrap="wrap"
      alignItems="center"
      gridRowGap={"unset"}
      gridColumnGap={[2, 8]}
    >
      <StackItem flex={1}>
        <FormControl
          isInvalid={!!meta.error && (meta.touched || submitCount > 0)}
        >
          <Flex justifyContent="space-between" alignContent="flex-start">
            <FormLabel htmlFor={name} {...labelProps}>
              {label}
            </FormLabel>
            {assignSelf && (
              <Link color="blue.400" onClick={handleAssignSelf}>
                {assignSelf}
              </Link>
            )}
          </Flex>
          <EventGroupUserAutocomplete
            {...field}
            onChange={handleOnChange}
            name={name}
            allowEmpty={false}
            emptyText={emptyText}
            eventGroupId={eventGroupId ?? null}
            isDisabled={isDisabled}
            placeholder={placeholder}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      </StackItem>
      {showCreate && createOptions && (
        <>
          <StackItem pt={6} alignSelf="flex-end">
            <Button
              minW="136px"
              variant="outline"
              colorScheme="blue"
              onClick={handleOpenCreateForm}
              disabled={createOptions.isDisabled}
            >
              <Icon as={MdAdd} mr={2} />
              {`Create ${createOptions.userType}`}
            </Button>
          </StackItem>

          {createOptions.formType === "inlineForm" && (
            <Box w="full">
              <Collapse in={openCreateForm} unmountOnExit>
                <StackItem w="100%" borderRadius={6} bgColor={bgColor} p={4}>
                  <QuickEventUserForm
                    onCreate={(user) => handleOnCreate(user)}
                    onCancel={() => setOpenCreateForm(false)}
                  />
                </StackItem>
              </Collapse>
            </Box>
          )}

          {createOptions.formType === "modal" && isOpen && (
            <AccountModal
              isOpen={isOpen}
              onClose={onClose}
              title={"New User"}
              content={
                <QuickEventUserForm
                  onCreate={(user) => handleOnCreate(user)}
                  onCancel={onClose}
                />
              }
            />
          )}
        </>
      )}
    </Stack>
  );
};
