import { Badge, BadgeProps, useColorModeValue } from "@chakra-ui/react";
import { FC, useMemo } from "react";

export type ApprovalState =
  | "pending"
  | "approved"
  | "denied"
  | "alert"
  | "confirmed"
  | "declined";

export type InvoicesState = "draft" | "sent" | "overdue" | "paid" | "void";

interface ApprovalStateBadgeProps {
  label: string;
  rest?: BadgeProps;
  type: ApprovalState | InvoicesState;
  isInvoice?: boolean;
}

export const ApprovalStateBadge: FC<ApprovalStateBadgeProps> = ({
  label,
  type,
  rest,
  isInvoice = false,
}) => {
  const pendingTextColor = useColorModeValue("gray.600", "gray.400");
  const textColor = useColorModeValue("600", "300");

  const badgeStyle = useMemo(
    () =>
      !isInvoice
        ? {
            pending: {
              colorScheme: "gray",
              color: pendingTextColor,
              borderColor: "gray.300",
            },
            approved: {
              colorScheme: "green",
              color: `green.${textColor}`,
              borderColor: "green.300",
            },
            alert: {
              colorScheme: "yellow",
              color: `yellow.${textColor}`,
              borderColor: "yellow.300",
            },
            denied: {
              colorScheme: "red",
              color: `red.${textColor}`,
              borderColor: "red.300",
            },
            confirmed: {
              colorScheme: "green",
              color: `green.${textColor}`,
              borderColor: "green.300",
            },
            declined: {
              colorScheme: "red",
              color: `red.${textColor}`,
              borderColor: "red.300",
            },
          }
        : {
            draft: {
              colorScheme: "yellow",
              color: `yellow.${textColor}`,
              borderColor: "yellow.300",
            },
            sent: {
              colorScheme: "green",
              color: `green.${textColor}`,
              borderColor: "green.300",
            },
            overdue: {
              colorScheme: "red",
              color: `red.${textColor}`,
              borderColor: "red.300",
            },
            paid: {
              colorScheme: "green",
              color: `green.${textColor}`,
              borderColor: "green.300",
            },
            void: {
              colorScheme: "gray",
              color: pendingTextColor,
              borderColor: "gray.300",
            },
          },
    [isInvoice, pendingTextColor, textColor]
  );

  return (
    <Badge
      px={4}
      h="20px"
      w="max-content"
      display="flex"
      alignItems="center"
      textAlign="center"
      border="1px solid"
      {...badgeStyle[type]}
      {...rest}
    >
      {label}
    </Badge>
  );
};
