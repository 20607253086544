import {
  Input,
  InputGroup,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  getFormattedInputHour,
  getUnformattedInputHour,
} from "../../utils/getInputFormattedHour";
import {
  Blue300Color,
  Blue500Color,
  Red300Color,
  Red500Color,
} from "../form-helpers/NumberInputControl";

interface CustomNumberInputPropsTest {
  value: number;
  onChange: (value: number) => void;
  isInvalid?: boolean;
  readOnly?: boolean;
  isDisabled?: boolean;
}

export const TimeInput: FC<CustomNumberInputPropsTest> = ({
  value,
  onChange,
  isInvalid,
  isDisabled,
  readOnly = false,
}) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const shadowColor = useColorModeValue(Blue500Color, Blue300Color);
  const errorShadowColor = useColorModeValue(Red500Color, Red300Color);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const minuteInputRef = useRef<HTMLInputElement>(null);

  const time = getFormattedInputHour(value).split(":");

  const [hour, setHour] = useState<string>(time[0]);
  const [minute, setMinute] = useState<string>(time[1]);

  const handleMinuteOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = Number(inputValue);

    if (!(numericValue >= 0 && numericValue <= 59) && hour === "00") {
      setMinute("00");
    }
    onClose();
  };

  const moveLeft = useCallback(
    (value: string) => {
      const newHour = `${hour[1]}${value[0]}`;
      const newMinute = `${value[1]}${value[2]}`;
      return { newHour, newMinute };
    },
    [hour]
  );

  const moveRight = useCallback(() => {
    const newHour = `0${hour[0]}`;
    const newMinute = `${hour[1]}${minute[0]}`;
    return { newHour, newMinute };
  }, [hour, minute]);

  const isValidTime = (hour: number, minute: number) => {
    return (
      hour >= 0 && hour <= 24 && ((minute >= 0 && minute <= 59) || hour === 0)
    );
  };

  const getInputValue = useCallback((value: string) => {
    return value.length === 1
      ? `00${value}`
      : value.length === 2
        ? `0${value}`
        : value;
  }, []);

  const handleMinuteChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = getInputValue(e.target.value);
      const numericValue = Number(inputValue);

      if (!isNaN(numericValue)) {
        const { newHour, newMinute } =
          minute.length > e.target.value.length &&
          (minute !== "00" || hour !== "00")
            ? moveRight()
            : moveLeft(inputValue);
        if (isValidTime(Number(newHour), Number(newMinute))) {
          setHour(newHour);
          setMinute(newMinute);
        }
      }
    },
    [getInputValue, hour, minute, moveLeft, moveRight]
  );

  const handleOnFocus = useCallback(() => {
    onOpen();
    if (!minuteInputRef.current) return;
    minuteInputRef.current.focus();
  }, [onOpen]);

  useEffect(() => {
    const total = `${hour}:${minute}`;
    const input = getUnformattedInputHour(total);

    if (value !== input) {
      onChange(input);
    }
  }, [onChange, hour, minute, value]);

  return (
    <InputGroup
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={0}
      h="40px"
      border={readOnly ? "none" : "1px solid"}
      borderRadius={6}
      borderColor={
        isInvalid ? errorShadowColor : isOpen ? "blue.500" : borderColor
      }
      boxShadow={
        isInvalid
          ? `0px 0px 0px 1px ${errorShadowColor}`
          : isOpen
            ? `0px 0px 0px 1px ${shadowColor}`
            : "none"
      }
    >
      <Input
        type="text"
        placeholder="00"
        onFocus={handleOnFocus}
        p={0}
        w="20px"
        maxLength={2}
        isDisabled={isDisabled}
        value={hour === "00" && minute === "00" ? "" : hour}
        border="none"
        shadow="none"
        boxShadow="none"
        _focus={{ shadow: "none" }}
        _invalid={{ boxShadow: "none", borderColor: "none" }}
        readOnly={true}
      />
      <Text
        mx="2px"
        as="span"
        fontWeight="semibold"
        onClick={handleOnFocus}
        opacity={isDisabled ? "0.4" : "1"}
      >
        :
      </Text>
      <Input
        ref={minuteInputRef}
        placeholder="00"
        onFocus={onOpen}
        onBlur={handleMinuteOnBlur}
        p={0}
        w="20px"
        type="text"
        onChange={handleMinuteChange}
        isDisabled={isDisabled}
        value={hour === "00" && minute === "00" ? "" : minute}
        border="none"
        shadow="none"
        boxShadow="none"
        _focus={{ shadow: "none" }}
        _invalid={{ boxShadow: "none", borderColor: "none" }}
        pattern="[0-9]*"
      />
    </InputGroup>
  );
};
