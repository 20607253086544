import { Alert, AlertIcon, Text } from "@chakra-ui/react";

export const LimitVenueInfo = () => {
  return (
    <Alert status="info" borderRadius={6} color="blue.600" bgColor="blue.50">
      <AlertIcon />
      <Text fontSize="sm">Times are limited to venue hours.</Text>
    </Alert>
  );
};
