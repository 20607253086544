import {
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { MdEdit } from "react-icons/md";
import { Attachment } from "../../attachments/Attachments";
import { TextAreaControl } from "../../form-helpers/TextareaControl";
import { TextInputControl } from "../../form-helpers/TextInputControl";

interface ImageViewerFormProps {
  attachment: Attachment;
  handleOnSave?: (attachment: Attachment) => void;
}

interface FormValues {
  name: string;
  description?: string;
}

type ImageViewerDataMode = "read" | "edit";

export const ImageViewerData: FC<ImageViewerFormProps> = ({
  attachment,
  handleOnSave,
}) => {
  const [mode, setMode] = useState<ImageViewerDataMode>("read");

  const editIconColor = useColorModeValue("gray.400", "white");

  const initialValues: FormValues = useMemo(() => {
    return { name: attachment.name, description: attachment.description };
  }, [attachment.description, attachment.name]);

  const handleOnSubmit = useCallback(
    async (values: FormValues) => {
      if (handleOnSave) {
        await handleOnSave({ ...attachment, ...values });
      }
      setMode("read");
    },
    [attachment, handleOnSave]
  );

  return (
    <Flex w="100%" mt={2}>
      {mode === "read" && (
        <Flex flexDir="column" gap={2} w="100%" p={2}>
          <Text
            gap={2}
            fontWeight="bold"
            display="inline-flex"
            justifyContent="space-between"
          >
            {attachment.name}
            <Icon
              as={MdEdit}
              cursor="pointer"
              color={editIconColor}
              onClick={() => setMode("edit")}
            />
          </Text>
          <Divider />
          <Text mt={2}>{attachment.description}</Text>
        </Flex>
      )}
      {mode === "edit" && (
        <Formik onSubmit={handleOnSubmit} initialValues={initialValues}>
          {({ values }) => {
            return (
              <Form
                style={{
                  gap: 12,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextInputControl
                  value={values.name}
                  name="name"
                  placeholder="Add name"
                />

                <Divider />

                <TextAreaControl
                  value={values.description || ""}
                  name="description"
                  placeholder="Image description"
                />

                <Flex gap={6} justifyContent="flex-end">
                  <Button type="submit" colorScheme="blue" size="sm">
                    Save
                  </Button>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => setMode("read")}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      )}
    </Flex>
  );
};
