import {
  ApiAccount,
  ApiClient,
  ApiWorkflowField,
  ApiWorkflowRole,
  ApiWorkflowSchema,
  ApiWorkflowSchemaField,
  ApiWorkflowSchemaFieldFormConfig,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SchemaEditorSliceState {
  currentSchema: ApiWorkflowSchema | null;
  fieldMap: Record<string, ApiWorkflowSchemaField>;
  fieldEditingMap: Record<string, boolean>;
}

export interface LoadSchemaEditorThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  schemaId: string;
  customFields?: boolean;
  systemFields?: boolean;
}

export interface SaveSchemaFieldThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  schemaId: string;
  schemaField: ApiWorkflowSchemaField;
}

export const loadSchemaEditor = createAsyncThunk(
  "schema/loadEditor",
  async (params: LoadSchemaEditorThunkParams, thunkAPI) => {
    const { apiClient, account, schemaId, customFields, systemFields } = params;

    const schema = await apiClient.getWorkflowSchema(account.id, schemaId);

    const { data: schemaFields } = await apiClient.findWorkflowSchemaFields(
      account.id,
      schemaId,
      {
        pageSize: 50,
      },
      customFields,
      systemFields
    );

    return {
      schema,
      schemaFields,
    };
  }
);

export const saveSchemaField = createAsyncThunk(
  "schemaField/save",
  async (params: SaveSchemaFieldThunkParams) => {
    const { apiClient, account, schemaId, schemaField } = params;
    const isUpdate = schemaField.id && schemaField.id.length;
    // field is not allowed to be updated, have to delete add if you wanna change field
    const { field, ...updatePayload } = schemaField;
    const response = isUpdate
      ? await apiClient.updateWorkflowSchemaField(
          account.id,
          schemaId,
          schemaField.id,
          updatePayload
        )
      : await apiClient.createWorkflowSchemaField(
          account.id,
          schemaId,
          schemaField
        );

    return response;
  }
);

export const removeSchemaField = createAsyncThunk(
  "schemaField/delete",
  async (params: SaveSchemaFieldThunkParams) => {
    const { apiClient, account, schemaId, schemaField } = params;

    const isUpdate = schemaField.id && schemaField.id.length;

    if (!isUpdate) {
      return Promise.resolve(schemaField.field.id);
    }

    await apiClient.deleteWorkflowSchemaField(
      account.id,
      schemaId,
      schemaField.id
    );
    return Promise.resolve(schemaField.field.id);
  }
);

export const schemaSlice = createSlice({
  name: "schema",
  initialState: {
    currentSchema: null,
    fieldMap: {},
    fieldEditingMap: {},
  } as SchemaEditorSliceState,
  reducers: {
    setCurrentSchema: (
      state,
      action: PayloadAction<ApiWorkflowSchema | null>
    ) => {
      state.currentSchema = action.payload;
    },
    addSchemaField: (
      state,
      action: PayloadAction<{
        field: ApiWorkflowField;
        createForm?: ApiWorkflowSchemaFieldFormConfig;
        fullForm?: ApiWorkflowSchemaFieldFormConfig;
      }>
    ) => {
      state.fieldMap[action.payload.field.id] = {
        id: "",
        field: action.payload.field,
        createForm: action.payload.createForm
          ? action.payload.createForm
          : {
              enable: false,
              rules: [],
            },
        fullForm: action.payload.fullForm
          ? action.payload.fullForm
          : {
              enable: true,
              rules: [
                {
                  type: "visible",
                  condition: {
                    type: "compare",
                    operation: "in",
                    field: "workflowUser.role",
                    values: [
                      ApiWorkflowRole.requester,
                      ApiWorkflowRole.approver,
                      ApiWorkflowRole.technician,
                      ApiWorkflowRole.reviewer,
                    ],
                  },
                },
              ],
            },
      };

      state.fieldEditingMap[action.payload.field.id] = true;
    },
    updateSchemaField: (
      state,
      action: PayloadAction<{
        key: string;
        update: Partial<ApiWorkflowSchemaField>;
      }>
    ) => {
      Object.assign(state.fieldMap[action.payload.key], action.payload.update);
    },
    updateCurrentSchema: (
      state,
      action: PayloadAction<Partial<ApiWorkflowSchema>>
    ) => {
      Object.assign(state.currentSchema as ApiWorkflowSchema, action.payload);
    },
    openFieldEditor: (state, payload: PayloadAction<string>) => {
      state.fieldEditingMap[payload.payload] = true;
    },
    closeFieldEditor: (state, payload: PayloadAction<string>) => {
      state.fieldEditingMap[payload.payload] = false;
    },
    unloadEditor: (state) => {
      state.currentSchema = null;
      state.fieldMap = {};
      state.fieldEditingMap = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSchemaEditor.fulfilled, (state, action) => {
      state.currentSchema = action.payload.schema;

      action.payload.schemaFields.reduce((map, schemaField) => {
        map[schemaField.field.id] = schemaField;
        return map;
      }, state.fieldMap);
    });

    builder.addCase(saveSchemaField.fulfilled, (state, action) => {
      state.fieldMap[action.payload.field.id] = action.payload;
      state.fieldEditingMap[action.payload.field.id] = false;
    });

    builder.addCase(removeSchemaField.fulfilled, (state, action) => {
      delete state.fieldMap[action.payload];
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  addSchemaField,
  closeFieldEditor,
  openFieldEditor,
  setCurrentSchema,
  unloadEditor,
  updateCurrentSchema,
  updateSchemaField,
} = schemaSlice.actions;

export default schemaSlice.reducer;
