import { Icon, Text } from "@chakra-ui/react";
import { ApiTaskBook } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { FC, useCallback } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAuthentication } from "../auth/AuthProvider";
import { commonStyles } from "./select-overrides";

interface TaskbookAutocompleteProps {
  onChange: (value: ApiTaskBook | null) => void;
  value: ApiTaskBook | null;
  name: string;
  isDisabled?: boolean;
  placeholder: string;
  fieldRef?: any;
}

export const TaskbookAutocomplete: FC<TaskbookAutocompleteProps> = ({
  onChange,
  value,
  name,
  placeholder,
  fieldRef,
}) => {
  const { currentAccount, apiClient } = useAuthentication();
  const { taskbooks } = useSelector((state: RootState) => state.requestForm);

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findTaskbooks(currentAccount.id, {
          search: inputValue,
        })
        .then((results) => {
          cb(
            results.data.filter(
              (d) => !taskbooks.some((a) => a.taskbook.id === d.id),
            ),
          );
        });
    },
    [apiClient, currentAccount.id, taskbooks],
  );

  const handleChange = useCallback(
    (newValue: ApiTaskBook | null) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <AsyncSelect
      ref={fieldRef}
      key={`taksbooks-select-${taskbooks.length}`}
      name={name}
      isClearable
      cacheOptions={false}
      isMulti={false}
      defaultOptions={true}
      value={value || null}
      onChange={handleChange}
      loadOptions={loadOptions}
      chakraStyles={commonStyles}
      getOptionValue={(item: ApiTaskBook) => item.id}
      getOptionLabel={(item: ApiTaskBook) => item.name}
      placeholder={
        <Text>
          <Icon as={FaSearch} mr={4} />
          {placeholder}
        </Text>
      }
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      openMenuOnFocus={true}
      menuShouldScrollIntoView={false}
      menuShouldBlockScroll={false}
    />
  );
};
