const SEVEN_DAYS_IN_MILISECONDS = 604800000; // 7 * 24 * 60 * 60 * 1000

export const getLastDayMonth = (date: Date | string) => {
  const dateAux = typeof date === "string" ? new Date(date) : date;
  const lastDayDate = new Date(
    dateAux.getFullYear(),
    dateAux.getMonth() + 1,
    0
  );
  return lastDayDate.getDate();
};

export const getWeekOfMonth = (date: Date | string) => {
  const dateAux = typeof date === "string" ? new Date(date) : new Date(date);
  let numberOfWeek = 0;
  const timestamp = dateAux.getTime();
  const currentMonth = dateAux.getMonth();
  let monthTemp = currentMonth;

  while (monthTemp === currentMonth) {
    numberOfWeek++;
    monthTemp = new Date(
      timestamp - numberOfWeek * SEVEN_DAYS_IN_MILISECONDS
    ).getMonth();
  }

  return numberOfWeek;
};

export const getLastDateOfMonth = (date: Date | string) => {
  const DATE = 0;
  const HOURS = 23;
  const MINUES = 59;
  const dateAux = typeof date === "string" ? new Date(date) : date;
  const lastDayDate = new Date(
    dateAux.getFullYear(),
    dateAux.getMonth() + 1,
    DATE,
    HOURS,
    MINUES
  );
  return lastDayDate;
};

export const isInLastWeekOfMonth = (date: Date | string) => {
  const dateAux = typeof date === "string" ? new Date(date) : date;
  const lastDayMonth = getLastDateOfMonth(dateAux);
  const lastWeek = new Date(lastDayMonth.getTime() - SEVEN_DAYS_IN_MILISECONDS);
  return (
    dateAux.getTime() > lastWeek.getTime() &&
    dateAux.getTime() <= lastDayMonth.getTime()
  );
};

export const isInNWeekOfMonth = (numberOfWeek: number, date: Date | string) => {
  const dateAux = typeof date === "string" ? new Date(date) : new Date(date);
  return numberOfWeek === getWeekOfMonth(dateAux);
};
