import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import {
  createElement,
  FC,
  FunctionComponentElement,
  useCallback,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { LocationFilter } from "../../../components/filters/LocationFilter";
import { useAllowedLocations } from "../../../hooks/useAllowedLocation";
import { RootState, useThunkDispatch } from "../../../store";
import { updateFilters } from "../../../store/planning-hq/requests/project-add-bulk-requests";
import { RequestListFilterState } from "../../../store/request-list.slice";
import { useCategoryUtils } from "../../../utils/categoryUtils";
import { debounce } from "../../../utils/debounce";
import { CategoryFilter } from "../../requests/filters/CategoryFilter";
import { ReasonFilter } from "../../requests/filters/ReasonFilter";
import { RequestStatusFilter } from "../../requests/filters/RequestStatusFilter";
import { WorkflowFilter } from "../../requests/filters/WorkflowFilter";

type RequestListModalHeaderProps = {};

export const Filters: Array<keyof Partial<RequestListFilterState>> = [
  "workflows",
  "statuses",
  "categories",
  "locations",
  "reasons",
];

export const RequestListModalHeader: FC<RequestListModalHeaderProps> = () => {
  const { allowedLocations } = useAllowedLocations();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();
  const thunkDispatch = useThunkDispatch();
  const { colorMode } = useColorMode();

  const { filters } = useSelector(
    (state: RootState) => state.projectBulkAddRequests
  );

  const handleUpdateFilters = useCallback(
    (delta: Partial<RequestListFilterState>) => {
      thunkDispatch(updateFilters(delta));
    },
    [thunkDispatch]
  );

  const filterComponents = useMemo(() => {
    const components: {
      [key in keyof Partial<RequestListFilterState>]: FunctionComponentElement<any>;
    } = {
      workflows: createElement(WorkflowFilter, {
        key: "prj-bulk-requests:workflow-filter",
        value: filters.workflows,
        onChange: (workflows) => {
          handleUpdateFilters({ workflows: workflows.map((w) => w.id) });
        },
      }),
      locations: createElement(LocationFilter, {
        key: "prj-bulk-requests:location-filter",
        value: filters.locations,
        allowedLocations: allowedLocations,
        onChange: (locations) => {
          handleUpdateFilters({ locations: locations.value.map((l) => l.id) });
        },
      }),

      statuses: createElement(RequestStatusFilter, {
        key: "prj-bulk-requests:status-filter",
        value: filters.statuses,
        onChange: (statuses) => {
          handleUpdateFilters({ statuses });
        },
      }),

      categories: createElement(CategoryFilter, {
        key: "prj-bulk-requests:category-filter",
        value: filters.categories,
        onChange: (categories) => {
          const categoryChilds = findAllChildrenForNodesRecursive(categories);
          let newValues = Array.from(categoryChilds);
          handleUpdateFilters({
            categories: newValues ? newValues.map((l) => l.id) : [],
          });
        },
      }),

      reasons: createElement(ReasonFilter, {
        key: "prj-requests:reasons-filter",
        value: filters.reasons,
        onChange: (reasons) => {
          handleUpdateFilters({ reasons: reasons.map((r) => r.id) });
        },
      }),
    };
    return components;
  }, [
    filters.workflows,
    filters.categories,
    filters.statuses,
    filters.locations,
    filters.reasons,
    allowedLocations,
    findAllChildrenForNodesRecursive,
    handleUpdateFilters,
  ]);

  const handleUpdateSearchFilter = useCallback(
    (value: string) => {
      thunkDispatch(updateFilters({ search: value }));
    },
    [thunkDispatch]
  );
  const debouncedSearchChange = debounce(handleUpdateSearchFilter, 300);

  return (
    <VStack w="full" align="stretch">
      <Box flexBasis={["40%", "40%", "40%", "40%", "30%"]}>
        <InputGroup flexGrow={1} bg={colorMode === "light" ? "white" : "unset"}>
          <InputLeftElement children={<SearchIcon color="gray.300" />} />
          <Input
            type="text"
            placeholder="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              debouncedSearchChange(e.target.value);
            }}
          />
        </InputGroup>
      </Box>
      <HStack wrap="wrap">
        {Filters.map((f) => {
          return filterComponents[f];
        })}
      </HStack>
    </VStack>
  );
};
