import { ModalFooter } from "@chakra-ui/modal";
import { Button, HStack } from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiProject,
  CreateApiProject,
} from "@operations-hero/lib-api-client";
import { FormikProps } from "formik";
import { FC, useCallback, useRef, useState } from "react";
import { Attachment } from "../../../components/attachments/Attachments";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { NewProjectForm, NewProjectProps } from "./NewProjectForm";
export type NewProjectModalProps = {
  onClose: () => void;
  onSubmit: (
    project: CreateApiProject,
    attachments: Attachment[]
  ) => Promise<{ project: ApiProject; attachments: ApiAttachment[] } | void>;
  isOpen: boolean;
};

export const NewProjectModal: FC<NewProjectModalProps> = ({
  onClose,
  onSubmit,
  isOpen,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const newProjectFormRef = useRef<FormikProps<NewProjectProps>>(null);

  const handleSubmit = useCallback(() => {
    if (newProjectFormRef.current) {
      setIsSubmitting(true);
      newProjectFormRef.current.submitForm().then(() => {
        setIsSubmitting(false);
        if (newProjectFormRef.current?.isValid) onClose();
      });
    }
  }, [onClose]);

  return (
    <AccountModal
      size="xl"
      title="New Project"
      content={
        <NewProjectForm onSubmit={onSubmit} formRef={newProjectFormRef} />
      }
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <ModalFooter>
          <HStack w="full" justify="space-between">
            <Button
              size="md"
              variant="link"
              colorScheme="blue"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="md"
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Create
            </Button>
          </HStack>
        </ModalFooter>
      }
    />
  );
};
