import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiRequestReminder } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { updateRemindersFilters } from "../../../store/request-form/request-form.slice";
import { loadRequestReminders } from "../../../store/request-form/thunks/reminder.thunk";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { RequestReminderForm } from "./RequestReminderForm";

interface RemindersListProps {
  requestId: string;
  requestKey: string;
}
const DATE_FORMAT = "MMMM dd, yyyy h:mma";
export const RemindersList: FC<RemindersListProps> = ({
  requestId,
  requestKey,
}) => {
  const bgColor = useColorModeValue("gray.50", "gray 700");

  const { reminders } = useSelector((state: RootState) => state.requestForm);
  const { filters, total } = reminders;
  const { page, pageSize } = filters;
  const [selectedReminder, setSelectedReminder] = useState<
    string | undefined
  >();
  const [reminder, setReminder] = useState<ApiRequestReminder | undefined>();
  const { currentAccount, apiClient } = useAuthentication();
  const showToast = useShowToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const thunkDispatch = useThunkDispatch();

  const dispatch = useDispatch();

  const handleDeleteReminder = useCallback(() => {
    if (!selectedReminder) return;
    apiClient
      .deleteRequestReminder(currentAccount.id, requestId, selectedReminder)
      .then(() => {
        showToast("success", "Reminder was deleted successfully");
        thunkDispatch(
          loadRequestReminders({
            apiClient,
            accountId: currentAccount.id,
            requestId: requestId,
          })
        );
      })
      .catch(() => {
        showToast("error", "Something went wrong while deleting reminder");
      })
      .finally(() => {
        setSelectedReminder(undefined);
        onClose();
      });
  }, [
    apiClient,
    currentAccount.id,
    onClose,
    requestId,
    selectedReminder,
    showToast,
    thunkDispatch,
  ]);

  const handleOnclickDelete = useCallback(
    (reminderId: string) => {
      setSelectedReminder(reminderId);
      onOpen();
    },
    [onOpen]
  );

  const handleOnClickEdit = useCallback(
    (reminder: ApiRequestReminder) => {
      setReminder(reminder);
      onOpenEdit();
    },
    [onOpenEdit]
  );

  const handleOnPageChange = useCallback(
    (value: number) => {
      dispatch(updateRemindersFilters({ page: value }));
    },
    [dispatch]
  );

  return (
    <>
      <VStack
        bgColor={bgColor}
        padding={4}
        marginBottom={4}
        borderRadius={6}
        divider={<Divider />}
      >
        <HStack width="100%" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="large">
            Reminders
          </Text>
          <Icon
            as={IoSettingsOutline}
            width="5"
            height="5"
            color="gray.500"
            marginRight={2}
          />
        </HStack>

        {reminders.data.map((reminder) => {
          return (
            <VStack width="100%" key={`reminders::${reminder.id}`}>
              <HStack width="100%" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold" color="gray.500" fontSize="small">
                    {requestKey}
                  </Text>
                  <Text color="gray.500" fontSize="small">
                    {format(reminder.date, DATE_FORMAT)}
                  </Text>
                </HStack>
                <HStack>
                  <IconButton
                    aria-label="delete-reminder"
                    icon={<BsTrash width="5" height="5" />}
                    color="gray.500"
                    variant="ghost"
                    onClick={() => handleOnclickDelete(reminder.id)}
                  />
                  <IconButton
                    aria-label="edit-reminder"
                    icon={<MdModeEditOutline />}
                    variant="ghost"
                    color="gray.500"
                    onClick={() => handleOnClickEdit(reminder)}
                  />
                </HStack>
              </HStack>
              <Text width="100%" fontWeight="bold" fontSize="small">
                {reminder.title}
              </Text>
              <Text width="100%" fontSize="small">
                {reminder.notes}
              </Text>
            </VStack>
          );
        })}

        {isOpen && (
          <AccountModal
            isOpen={isOpen}
            onClose={onClose}
            content={
              <VStack>
                <Text width="100%">This action cannot be undone.</Text>
                <HStack width="100%" justifyContent="flex-end">
                  <Button onClick={onClose} colorScheme="gray">
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDeleteReminder}>
                    Delete
                  </Button>
                </HStack>
              </VStack>
            }
            title="Delete Reminder?"
            titleProps={{ fontSize: "medium" }}
            closeButton={false}
            isCentered={true}
            hideDivider={true}
          />
        )}

        {isOpenEdit && reminder && (
          <AccountModal
            title="Edit Reminder"
            isOpen={isOpenEdit}
            onClose={onCloseEdit}
            content={
              <RequestReminderForm reminder={reminder} onClose={onCloseEdit} />
            }
          />
        )}
      </VStack>
      {pageSize && total > pageSize && (
        <Box marginBottom={4} marginLeft={4}>
          <Pager
            showDetails
            total={total}
            currentPage={page || 1}
            pageSize={pageSize || 1}
            onPageChange={handleOnPageChange}
          />
        </Box>
      )}
    </>
  );
};
