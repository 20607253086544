import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import {
  ApiTaskBookType,
  ApiSafetyNoteType,
} from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";
import { Select, SingleValue } from "chakra-react-select";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getCustomSelectComponents } from "../selects/select-overrides";

export interface TaskBookTypeSelectControlProps {
  label: string;
  name: string;
  value: ApiTaskBookType | ApiSafetyNoteType | null;
  selectType: SelectType;
}

export interface TaskBookTypeValue {
  label: string;
  value: string;
}

export enum SelectType {
  TASK_BOOK_TYPE = "TASK_BOOK_TYPE",
  SAFETY_NOTE_TYPE = "SAFETY_NOTE_TYPE",
}

const getOptions = (type: SelectType) => {
  switch (type) {
    case SelectType.TASK_BOOK_TYPE: {
      return Object.values(ApiTaskBookType).map((item) => ({
        label:
          item === ApiTaskBookType.passfail
            ? "Pass/Fail"
            : capitalizeFirstLetter(item),
        value: item as string,
      }));
    }
    case SelectType.SAFETY_NOTE_TYPE: {
      return Object.values(ApiSafetyNoteType).map((item) => ({
        label: capitalizeFirstLetter(item),
        value: item as string,
      }));
    }
    default:
      return [];
  }
};

export const TaskBookTypeSelectControl: React.FC<
  TaskBookTypeSelectControlProps
> = ({ value, label, name, selectType }) => {
  const { submitCount } = useFormikContext();

  const [field, meta, helper] = useField({
    name: name || "",
    value: value || "",
  });

  const handleChange = useCallback(
    (newValue: SingleValue<TaskBookTypeValue> | null) => {
      if (newValue) {
        helper.setValue(newValue.value || "");
      } else {
        helper.setValue(null);
      }
      helper.setTouched(true);
    },
    [helper]
  );

  const options = useMemo(() => getOptions(selectType), [selectType]);

  const handleOnBlur = useCallback(() => {
    helper.setTouched(true);
  }, [helper]);

  const components = useMemo(getCustomSelectComponents, []);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        {...field}
        tagVariant="outline"
        value={options.find((typeItem) => typeItem.value === value)}
        defaultValue={{ label: value || "", value: value || "" }}
        onChange={handleChange}
        options={options}
        onBlur={handleOnBlur}
        components={components}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
