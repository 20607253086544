import { Box, Button, Checkbox, HStack, Input, Text } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { SelectMethods } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { GroupBadge } from "../../../components/badges/GroupBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { RootState, useThunkDispatch } from "../../../store";
import {
  loadPeopleFilterOptions,
  PersonFilterValue,
  PersonFilterValueType,
} from "../../../store/schedule-request-list.slice";

interface PersonListProps {
  values: PersonFilterValue[];
  type: PersonFilterValueType;
  methods: SelectMethods<PersonFilterValue>;
}

const PersonList = ({ values, methods, type }: PersonListProps) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();

  const { availablePersons } = useSelector(
    (state: RootState) => state.scheduleRequestList
  );

  const [search, setSearch] = useState("");

  const handleInputOnChange = useCallback(
    (e: any) => {
      const keyword = e.target.value;
      setSearch(keyword);
      thunkDispatch(
        loadPeopleFilterOptions({
          apiClient,
          account,
          type,
          search: keyword,
        })
      );
    },
    [apiClient, account, type, thunkDispatch]
  );

  const isChecked = useCallback(
    (item: PersonFilterValue) => {
      const itemKey = `${item.type}::${item.groupOrUser.assignee.id}`;
      return values.some(
        (v) =>
          `${v.type}::${
            typeof v.groupOrUser !== "string"
              ? v.groupOrUser.assignee.id
              : v.groupOrUser
          }` === itemKey
      );
    },
    [values]
  );

  const handleSelectItem = useCallback(
    (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const person = availablePersons[idx];
      methods.addItem(person);
    },
    [methods, availablePersons]
  );

  return (
    <Box p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          <Button
            size="sm"
            variant="outline"
            onClick={() => methods.clearAll()}
          >
            Clear all
          </Button>

          <Button size="sm" onClick={() => methods.selectAll()}>
            Select all
          </Button>
        </HStack>
        <Input
          type="text"
          value={search}
          onChange={handleInputOnChange}
          placeholder="Search Persons"
        />
      </Box>
      <Box>
        {availablePersons.length > 0 ? (
          availablePersons.map((item, index) => {
            return (
              <Checkbox
                key={`${item.type}::${item.groupOrUser.assignee.id}`}
                isChecked={isChecked(item)}
                onChange={handleSelectItem(index)}
                w="100%"
                p={2}
              >
                {item.groupOrUser.type === "group" ? (
                  <GroupBadge value={item.groupOrUser.assignee} />
                ) : (
                  <UserBadge value={item.groupOrUser.assignee} />
                )}
              </Checkbox>
            );
          })
        ) : (
          <Text mt={2} color={"gray"}>
            No results found
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default PersonList;
