import { ApiClient, ApiComment } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";

type DeleteCommentParams = {
  apiClient: ApiClient;
  projectId: string;
  commentId: string;
};
export const deleteComment = createAsyncThunk<
  { commentId: string; success: boolean },
  DeleteCommentParams
>(
  "project-commments/delete",
  async ({ apiClient, projectId, commentId }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState;
    const { currentAccount } = store.auth;
    const response = await apiClient.deleteProjectComment(
      currentAccount.id,
      projectId,
      commentId
    );

    return { commentId, success: response.success };
  }
);

export type DeleteCommentsHandlerProps = {
  comments: ApiComment[];
};

export const deleteCommentHandlers = <T extends DeleteCommentsHandlerProps>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(deleteComment.fulfilled, (state, action) => {
    const { commentId } = action.payload;
    const comments = [...state.comments];
    const deletedIdx = comments.findIndex((c) => c.id === commentId);
    comments.splice(deletedIdx, 1);
    state.comments = comments;
  });
};
