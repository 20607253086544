import {
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiService } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useRef, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { RootState, useThunkDispatch } from "../../../store";
import {
  initServiceFilter,
  loadServiceFilter,
} from "../../../store/request-list.slice";
import { FiltersWithCloseIcon } from "../RequestAdvancedFilters";

export interface ServiceFilterProps {
  value: (ApiService | string)[];
  onChange: (value: ApiService[]) => void;
  addCloseIcon?: FiltersWithCloseIcon;
}

const ContentRenderer = ({
  state,
  addCloseIcon,
}: SelectRenderer<ApiService> & {
  addCloseIcon?: FiltersWithCloseIcon;
}) => {
  return (
    <Box p={1} display=" flex" alignItems="center" w="max-content">
      {state.values.length === 0 && "Service"}
      {state.values.length === 1 && state.values[0].name}
      {state.values.length > 1 && (
        <>
          <Text as="span" mr={1}>
            {state.values[0].name}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
      {addCloseIcon && (
        <Icon
          as={MdClose}
          ml={2}
          onClick={(e) => addCloseIcon.handleClickCloseIcon(e, "services")}
        />
      )}
    </Box>
  );
};

const DropdownHandleRenderer = () => {
  return <Box />;
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiService>) => {
  const { services: selectedValues } = useSelector(
    (state: RootState) => state.requestList.filters
  );
  const bgColor = useColorModeValue("transparent", "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Box>Search and select:</Box>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Services"
        />
      </Box>
      <Box>
        {
          //@ts-ignore
          (state.search ? state.searchResults : props.options).map((item) => {
            return (
              <Checkbox
                key={item.id}
                isChecked={
                  methods.isSelected(item) ||
                  selectedValues.some((sel) => sel === item.id)
                }
                onChange={() => methods.addItem(item)}
                w="100%"
                p={2}
              >
                {item.name}
              </Checkbox>
            );
          })
        }
      </Box>
    </Box>
  );
};

export const ServiceFilter = ({
  value,
  onChange,
  addCloseIcon,
}: ServiceFilterProps) => {
  const { currentAccount: account, apiClient } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const { services: selectedValues } = useSelector(
    (state: RootState) => state.requestList.filters
  );
  const [search, setSearch] = useState<string>("");
  const ServiceFilterOptions = useSelector(
    (state: RootState) => state.requestList.services
  );
  const [serviceToAdd, setServiceToAdd] = useState<ApiService[]>([]);

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const searchFn = useCallback(
    ({ props, state }: SelectRenderer<ApiService>) => {
      if (!state.search) {
        setSearch("");
      }
      setSearch(state.search);

      return props.options;
    },
    []
  );

  const initOnce = useRef(false);
  const [filterInitCompleted, setFilterInitCompleted] = useState(false);

  useEffect(() => {
    // wait for request list init to finish before we run this init
    if (!initOnce) {
      return;
    }
    initOnce.current = true;
    // load only selected values to init, no restrictions

    const promise =
      !selectedValues || selectedValues.length === 0
        ? Promise.resolve()
        : thunkDispatch(
            initServiceFilter({
              apiClient,
              account,
              selectedValues,
            })
          );

    promise.then(() => setFilterInitCompleted(true));

    // on open, load the available options + add selected
  }, [apiClient, account, thunkDispatch, selectedValues]);

  useEffect(() => {
    if (!filterInitCompleted) {
      return;
    }
    thunkDispatch(
      loadServiceFilter({
        apiClient,
        account,
        search,
      })
    );
  }, [apiClient, account, thunkDispatch, filterInitCompleted, search]);

  useEffect(() => {
    const emptyService = {
      id: "null",
      name: "Missing",
    } as ApiService;
    const newServices = [emptyService].concat(ServiceFilterOptions);
    setServiceToAdd(newServices);
  }, [setServiceToAdd, ServiceFilterOptions]);

  return filterInitCompleted ? (
    <Select
      multi
      options={serviceToAdd}
      values={serviceToAdd.filter((Service) =>
        value.find((val) => val === Service.id) ? true : false
      )}
      className={themeClass}
      onChange={onChange}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      clearOnSelect={false}
      clearable={false}
      contentRenderer={(props) => ContentRenderer({ ...props, addCloseIcon })}
      dropdownRenderer={DropdownRenderer}
      dropdownHandleRenderer={addCloseIcon ? DropdownHandleRenderer : undefined}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  ) : null;
};
