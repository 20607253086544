import {
  ApiAccount,
  ApiClient,
  ApiPagingOptions,
  ApiRequest,
  ApiWorkflowSchemaField,
  FindRequestsOptions,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  getRequestFilter,
  RequestListFilterState,
} from "../../request-list.slice";
import { filtersInitialState } from "../../requests/defaults";
import { LoadingStatus } from "../project-list";

type ProjectRequestProps = {
  loadingStatus: LoadingStatus;
  options: FindRequestsOptions;
  // total: number;
  filters: RequestListFilterState;
  sort: {
    direction: "asc" | "desc";
    field: string;
  };
  pagination: ApiPagingOptions & { total: number };
  requests: ApiRequest[];
  enginesMap: { [key: string]: SchemaRulesEngine };
  schemaFieldsMap: { [key: string]: ApiWorkflowSchemaField[] };
};

type UpdateFiltersParams = Partial<RequestListFilterState>;

type SortFilter = {
  direction: "asc" | "desc";
  field: string;
};

type UpdateSortFiltersParams = Partial<SortFilter>;
export interface LoadRequestsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  page?: number;
}

export const updateFilters = createAsyncThunk<
  RequestListFilterState,
  UpdateFiltersParams
>("project-requests/bulk/filters/update", async (delta, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { filters: current } = state.projectBulkAddRequests;

  let filters: RequestListFilterState = {
    ...current,
    currentPage: 1,
    ...delta,
  };

  return filters;
});

export const updateSortFilters = createAsyncThunk<
  SortFilter,
  UpdateSortFiltersParams
>("project-requests/bulk/sort/update", async (delta, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { sort } = state.requestList;

  let filters = {
    ...sort,
    ...delta,
  } satisfies SortFilter;

  return filters;
});

export const loadBulkData = createAsyncThunk(
  "project-requests/bulk/load",
  async ({ apiClient }: LoadRequestsThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const { currentAccount, currentUser, isProductAdmin } = state.auth;
    const { workflowMap, policyMap } = state.localCache;

    const enginesMap: { [key: string]: SchemaRulesEngine } = {};
    const schemaFieldsMap: { [key: string]: ApiWorkflowSchemaField[] } = {};

    const {
      sort,
      filters,
      pagination: { page, pageSize },
    } = state.projectBulkAddRequests;

    let newFilters = { ...filters };
    const options = getRequestFilter(sort, newFilters);

    const requestsResponse = await apiClient.findRequests(currentAccount.id, {
      ...options,
      page,
      pageSize,
      includeProjectRequests: false,
    });

    const { data } = requestsResponse;

    for (const request of data) {
      const workflow = workflowMap[request.workflow.id];
      if (!enginesMap[workflow.id]) {
        if (!schemaFieldsMap[workflow.schema.id]) {
          const schemaFieldsResponse = await apiClient.findWorkflowSchemaFields(
            currentAccount.id,
            workflow.schema.id,
            { pageSize: 50 }
          );
          schemaFieldsMap[workflow.schema.id] = schemaFieldsResponse.data;
        }

        const engine = new SchemaRulesEngine({
          account: currentAccount,
          form: "full",
          schemaFields: schemaFieldsMap[workflow.schema.id],
          workflow,
          user: currentUser,
          isProductAdmin,
          policy: policyMap[workflow.id],
        });

        enginesMap[workflow.id] = engine;
      }
    }
    return {
      requests: requestsResponse,
      options,
      enginesMap,
      schemaFieldsMap,
    };
  }
);

const initialState: ProjectRequestProps = {
  loadingStatus: "idle",
  options: {},
  sort: {
    direction: "desc",
    field: "created",
  },
  filters: { ...filtersInitialState },
  pagination: {
    page: 1,
    pageSize: 30,
    total: 0,
  },
  requests: [],
  enginesMap: {},
  schemaFieldsMap: {},
};

const projectAddBulkRequestsSlice = createSlice({
  name: "project/requests/add",
  initialState: initialState,
  reducers: {
    unload: (state) => {
      state.loadingStatus = "idle";
      state.options = {};
      state.sort.direction = "desc";
      state.sort.field = "created";
      state.filters = { ...filtersInitialState };
      state.requests = [];
      state.enginesMap = {};
      state.schemaFieldsMap = {};
      state.pagination = {
        page: 1,
        pageSize: 30,
        total: 0,
      };
    },

    updatePaginationFilters: (
      state,
      action: PayloadAction<Partial<ProjectRequestProps["pagination"]>>
    ) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.pagination = {
        page: 1,
        pageSize: 30,
        total: 0,
      };
    });

    builder.addCase(updateSortFilters.fulfilled, (state, action) => {
      state.sort = action.payload;
    });

    builder.addCase(loadBulkData.fulfilled, (state, action) => {
      state.enginesMap = action.payload.enginesMap;
      state.schemaFieldsMap = action.payload.schemaFieldsMap;
      const {
        options,
        requests: { total, data },
      } = action.payload;
      state.options = options;
      state.requests = data;
      state.loadingStatus = "fulfilled";
      state.pagination.total = total;
    });

    builder.addCase(loadBulkData.pending, (state, action) => {
      state.loadingStatus = "pending";
    });

    builder.addCase(loadBulkData.rejected, (state, action) => {
      state.loadingStatus = "rejected";
    });
  },
});

export const { unload, updatePaginationFilters } =
  projectAddBulkRequestsSlice.actions;

export default projectAddBulkRequestsSlice.reducer;
