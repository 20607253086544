import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  chakra,
  CloseButton,
} from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import React from "react";

export interface NewRequestToastProps {
  onClick?: () => void;
  onClose?: () => void;
  request: ApiRequest;
}

export const NewRequestToast = ({
  onClick,
  request,
  onClose,
}: NewRequestToastProps) => {
  return (
    <Alert
      status="success"
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={8}
      textAlign="start"
      width="auto"
    >
      <AlertIcon />
      <chakra.div flex="1" onClick={onClick}>
        <AlertTitle>Request Created - {request.key}</AlertTitle>
        <AlertDescription display="block">
          Click to view {request.key}
        </AlertDescription>
      </chakra.div>
      <CloseButton
        size="sm"
        onClick={onClose}
        position="absolute"
        insetEnd={1}
        top={1}
      />
    </Alert>
  );
};
