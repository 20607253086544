import {
  ApiAttachment,
  ApiClient,
  ApiPagingVenueOptions,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface loadVenuesThunkParams {
  apiClient: ApiClient;
  accountId: string;
}

interface VenueIncludeAttachments extends ApiVenueSummary {
  attachements: ApiAttachment[];
}
export interface ApiVenueFilter extends ApiPagingVenueOptions {
  total?: number;
}

export const loadVenues = createAsyncThunk(
  "event/venues/load",
  async (params: loadVenuesThunkParams, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState).venueList;
    const { apiClient, accountId } = params;

    const response = await apiClient.findVenues(accountId, filters);
    const venuesWithAttachments = await Promise.all(
      response.data.map(async (venue) => ({
        ...venue,
        attachements: await (
          await apiClient.findVenueAttachments(accountId, venue.id)
        ).data,
      }))
    );
    return { ...response, data: venuesWithAttachments };
  }
);

interface toggleActivateThunkParams {
  apiClient: ApiClient;
  accountId: string;
  venueId: string;
  active: boolean;
}

export const toggleActivate = createAsyncThunk(
  "event/venues/activate",
  async (params: toggleActivateThunkParams, ThunkAPI) => {
    const { apiClient, accountId, venueId, active } = params;

    active && apiClient.deactivateVenue(accountId, venueId);
    !active && apiClient.reactivateVenue(accountId, venueId);

    return { venueId, active };
  }
);

export interface VenueListSliceState {
  data: VenueIncludeAttachments[];
  total: number;
  filters: ApiVenueFilter;
}

const DEFAULT_VENUE_FILTERS: ApiVenueFilter = {
  page: 1,
  search: "",
  pageSize: 20,
  type: undefined,
  includeInactive: undefined,
};

export const venueList = createSlice({
  name: "venueList",
  initialState: {
    data: [],
    total: 1,
    filters: { ...DEFAULT_VENUE_FILTERS },
  } as VenueListSliceState,
  reducers: {
    unload: (state: VenueListSliceState) => {
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_VENUE_FILTERS;
    },
    updateFilters: (
      state: VenueListSliceState,
      action: PayloadAction<ApiVenueFilter>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVenues.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filters.page = options.page;
      state.data = data;
      state.total = total;
    });

    builder.addCase(toggleActivate.fulfilled, (state, action) => {
      const { venueId, active } = action.payload;
      const { filters } = state;
      const venueIndex = state.data.findIndex((item) => item.id === venueId);

      if (!!filters.includeInactive) {
        if (venueIndex !== -1) {
          state.data[venueIndex].active = !active;
        }
      } else {
        state.data.splice(venueIndex, 1);
      }
    });
  },
});

export const { unload, updateFilters } = venueList.actions;

export default venueList.reducer;
