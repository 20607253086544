import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import {
  ApiInventoryItemAdjustment,
  ApiInventoryItemStorageLocation,
  ApiIssuanceTransaction,
  ApiItemAdjustmentType,
  ApiUserSummary,
  CreateApiInventoryItemAdjustment,
} from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { InventoryItemLocationSelectControl } from "../../../components/form-helpers/InventoryItemLocationSelectControl";
import { NumberInputControl } from "../../../components/form-helpers/NumberInputControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { UserAutocompleteControl } from "../../../components/form-helpers/UserAutocompleteControl";
import { getCustomSelectComponents } from "../../../components/selects/select-overrides";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { loadInventoryItems } from "../../../store/inventory/inventory-item-list.slice";
import { loadReturnTransactions } from "../../../store/request-form/thunks/loadTransactions.thunk";
import { changeQuantityFormSchema } from "../../inventory/inventory-items/quantity/ChangeQuantirySchema";

export interface InventoryReturnToInventoryFormProps {
  issuedItem: ApiInventoryItemAdjustment;
  issuedItemTransaction: ApiIssuanceTransaction;
  onClose: () => void;
}

export interface QuantityToReturnProps {
  formValues: InventoryReturnValues;
  max: number;
}

export interface InventoryReturnValues {
  date: Date;
  requester: ApiUserSummary;
  itemLocation: ApiInventoryItemStorageLocation;
  quantity: number;
  cost: number;
  notes: string | null;
}

const QuantityToReturn: FC<QuantityToReturnProps> = ({ formValues, max }) => {
  return (
    <Flex gap="2">
      <NumberInputControl
        name="quantity"
        label={"Quantity to return"}
        value={formValues.quantity}
        precision={2}
        max={max}
        showMaxButton={true}
      />
    </Flex>
  );
};

export const InventoryReturnToInventoryForm: FC<
  InventoryReturnToInventoryFormProps
> = ({ issuedItem, issuedItemTransaction, onClose }) => {
  const { apiClient, currentAccount } = useAuthentication();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();

  const { returnsData } = useSelector(
    (state: RootState) => state.requestForm.transactions.issuancesReturns
  );

  const initialValues: InventoryReturnValues = useMemo(() => {
    return {
      date: new Date(),
      requester: issuedItem.requester,
      itemLocation: issuedItem.itemLocation,
      quantity: 0,
      cost: issuedItem.item.cost,
      notes: null,
    };
  }, [issuedItem]);
  const components = useMemo(getCustomSelectComponents, []);

  const handleSubmit = useCallback(
    (formValues: InventoryReturnValues) => {
      const adjustment: CreateApiInventoryItemAdjustment = {
        type: ApiItemAdjustmentType.returnedToInventory,
        reason: null,
        requestId: issuedItem.requestId,
        inventoryRequestId: issuedItem.inventoryRequestId,
        item: issuedItem.item,
        unitCost: issuedItem.unitCost,
        units: issuedItem.item.units,
        purchaseOrder: issuedItem.purchaseOrder,
        invoiceNumber: issuedItem.invoiceNumber,
        serialNumber: null,
        assignedTo: issuedItem.assignedTo,
        budget: issuedItem.budget,
        itemLocation: formValues.itemLocation.id,
        date: formValues.date.toISOString(),
        returnDate: null,
        requester: formValues.requester,
        quantity: formValues.quantity,
        notes: formValues.notes,
        deliveryLocationId: issuedItem.deliveryLocationId,
        supplier: issuedItem.supplier,
        inventoryAdjustmentToCheckOut: issuedItem.inventoryAdjustmentCheckOut,
        issuanceTransactionId: issuedItemTransaction.id,
        status: null,
        associatedAdjustmentId: null,
        bulkCheckinItems: null,
      };
      apiClient
        .createInventoryItemAdjustments(currentAccount.id, adjustment)
        .then(() => {
          showToast("success", "Item was returned successfully");
          thunkDispatch(
            loadInventoryItems({ apiClient, accountId: currentAccount.id })
          );
          onClose();
        })
        .catch(() => {
          showToast("error", "An error occurred while returning the item");
        });
    },
    [
      issuedItem,
      apiClient,
      currentAccount,
      issuedItemTransaction,
      onClose,
      showToast,
      thunkDispatch,
    ]
  );

  const getMaxQuantity = useMemo(() => {
    const itemToReturn = issuedItem.item.id;

    if (!returnsData[itemToReturn]) {
      return issuedItem.quantity;
    }
    const existingReturns = returnsData[itemToReturn].reduce(
      (acc, transaction) => {
        acc += transaction.inventoryItemAdjustment.quantity;
        return acc;
      },
      0
    );
    return issuedItem.quantity - existingReturns;
  }, [issuedItem, returnsData]);

  const [formSchema] = useState(
    changeQuantityFormSchema(
      ApiItemAdjustmentType.returnedToInventory,
      undefined,
      getMaxQuantity
    )
  );

  useEffect(() => {
    if (!issuedItem || !issuedItem.requestId) return;

    thunkDispatch(
      loadReturnTransactions({
        apiClient,
        account: currentAccount,
        key: issuedItem.requestId,
        transactionType: "issuance",
        issuanceTransactionId: issuedItemTransaction.id,
      })
    );
  }, [
    issuedItem,
    issuedItemTransaction,
    apiClient,
    currentAccount,
    thunkDispatch,
  ]);

  return (
    <Box w="100%">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={formSchema}
      >
        {({ values }) => {
          return (
            <Form>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem
                  colSpan={2}
                  border="solid"
                  borderColor="whiteAlpha.200"
                  borderWidth="thin"
                  rounded="md"
                  p="3"
                >
                  <FormControl>
                    <FormLabel>Issued Item:</FormLabel>
                    <Flex
                      gap={2}
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                      key={`cart-item:${issuedItem.item.id}`}
                    >
                      <Flex w="42%" alignItems="center" gap={2}>
                        <Image
                          boxSize="40px"
                          src={
                            issuedItem.item.image
                              ? issuedItem.item.image
                              : undefined
                          }
                        />
                        <Text fontWeight="bold">{issuedItem.item.name}</Text>
                      </Flex>

                      <Flex
                        p={2}
                        gap={2}
                        w="55%"
                        flexDir="row"
                        justifyContent="flex-end"
                      >
                        <InputGroup size="sm" w="130px">
                          <Input
                            type="number"
                            min={0}
                            step={0.01}
                            value={issuedItem.quantity}
                            fontWeight="semibold"
                            isReadOnly={true}
                            rounded="sm"
                          />
                          <InputRightAddon
                            p={1}
                            w="60px"
                            maxW="60px"
                            isTruncated
                            children={issuedItem.item.units.unit}
                            rounded="sm"
                          />
                        </InputGroup>
                      </Flex>
                    </Flex>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                      value={{ value: "return", label: "Return" }}
                      isDisabled={true}
                      components={components}
                      options={[{ value: "return", label: "Return" }]}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <DatePickerControl
                    value={values.date}
                    name="date"
                    label="Date"
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <UserAutocompleteControl
                    value={values.requester}
                    name="requester"
                    label="Requester"
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <InventoryItemLocationSelectControl
                    name="itemLocation"
                    value={values.itemLocation}
                    label="Location"
                    inventoryItemId={issuedItem.item.id}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <QuantityToReturn formValues={values} max={getMaxQuantity} />
                </GridItem>
                <GridItem colSpan={2}>
                  <TextEditorControl
                    value={values.notes}
                    name="notes"
                    label="Notes"
                  />
                </GridItem>
                <GridItem
                  colSpan={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    size="sm"
                    variant="outline"
                    colorScheme="blue"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button size="sm" colorScheme="blue" type="submit">
                    Submit Change
                  </Button>
                </GridItem>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
