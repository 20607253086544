import {
  ApiRequestStatus,
  ApiWorkflow,
  BulkUpdateApiRequest,
  CreateApiComment,
  CreateApiLaborTransaction,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { FieldsToUpdate, RequestBulkState } from "./request-bulk-actions.slice";

export const generateWorkflowStatusMap = (workflow: ApiWorkflow) => {
  const result = [ApiRequestStatus.new];

  if (workflow.requireApproverForNewRequests) {
    result.push(ApiRequestStatus.approved);
  }

  result.push(ApiRequestStatus.queued);
  result.push(ApiRequestStatus.started);

  if (workflow.requireReviewerBeforeClose) {
    result.push(ApiRequestStatus.review);
  }

  result.push(ApiRequestStatus.closed);

  return result;
};

// TODO: support child locations
export const canHandleStatus = (
  workflow: ApiWorkflow,
  status: ApiRequestStatus,
  policy: WorkflowPolicy
) => {
  if (
    [
      ApiRequestStatus.queued,
      ApiRequestStatus.started,
      ApiRequestStatus.review,
      ApiRequestStatus.closed,
    ].includes(status) &&
    !!policy.contractor === true
  ) {
    return true;
  }

  if (status === ApiRequestStatus.new && policy.requester === false) {
    return false;
  }

  if (status === ApiRequestStatus.approved && policy.approver === false) {
    return false;
  }

  if (
    [
      ApiRequestStatus.queued,
      ApiRequestStatus.started,
      ApiRequestStatus.review,
    ].includes(status) &&
    policy.technician === false
  ) {
    return false;
  }

  if (
    status === ApiRequestStatus.closed &&
    workflow.requireReviewerBeforeClose &&
    policy.reviewer === false
  ) {
    return false;
  }

  if (
    status === ApiRequestStatus.closed &&
    !workflow.requireReviewerBeforeClose &&
    policy.technician === false
  ) {
    return false;
  }

  return true;
};

export const generateAllowedStatusList = (
  workflow: ApiWorkflow,
  policy: WorkflowPolicy,
  isProductAdmin: boolean
): { canCancel: boolean; statuses: ApiRequestStatus[] } | null => {
  if (!policy || !workflow) {
    return null;
  }

  const map = generateWorkflowStatusMap(workflow);

  if (policy.admin || isProductAdmin) {
    // no need to run the full cancel check
    return { statuses: map, canCancel: true };
  }

  return {
    statuses: map.filter((s) => canHandleStatus(workflow, s, policy)),
    canCancel: canCancel(workflow, policy, isProductAdmin),
  };
};

export const canCancel = (
  workflow: ApiWorkflow,
  policy: WorkflowPolicy,
  isProductAdmin: boolean
) => {
  if (!policy || !workflow) {
    return false;
  }

  if (policy.admin || isProductAdmin) {
    return true;
  }

  return !!(policy.approver || policy.technician || policy.reviewer);
};

export const canReopen = (
  workflow: ApiWorkflow,
  policy: WorkflowPolicy,
  isProductAdmin: boolean
) => {
  if (!policy || !workflow) {
    return false;
  }

  if (policy.admin || isProductAdmin) {
    return true;
  }

  if (
    workflow.requireReviewerBeforeClose === true &&
    policy.reviewer !== false
  ) {
    return true;
  }

  if (
    workflow.requireReviewerBeforeClose === false &&
    policy.technician !== false
  ) {
    return true;
  }

  return false;
};

export const generateSingleRequestPayload = (
  item: RequestBulkState,
  fieldsToUpdate: FieldsToUpdate
): BulkUpdateApiRequest => {
  let metadataPayload;
  let schedulingPayload;
  let requestPayload: FieldsToUpdate = { ...fieldsToUpdate };

  if (fieldsToUpdate.metadata)
    metadataPayload = {
      ...item.request.metadata,
      ...fieldsToUpdate.metadata,
    };

  const hasToUpdateCompletitionDate =
    fieldsToUpdate["status"] === ApiRequestStatus.closed ||
    fieldsToUpdate["status"] === ApiRequestStatus.review;

  const { completed } = item.request.scheduling;
  if (hasToUpdateCompletitionDate) {
    schedulingPayload = {
      ...item.request.scheduling,
      completed: completed ? completed : new Date().toISOString(),
    };
  }

  if (metadataPayload) {
    requestPayload = {
      ...requestPayload,
      metadata: { ...metadataPayload },
    };
  }

  if (schedulingPayload) {
    requestPayload = {
      ...requestPayload,
      scheduling: { ...schedulingPayload },
    };
  }

  const payload: BulkUpdateApiRequest = {
    idOrKey: item.request.id,
    request: { ...requestPayload },
    labor: { ...requestPayload.transaction } as CreateApiLaborTransaction,
    comment: { ...requestPayload.comment } as CreateApiComment,
  };

  return payload;
};
