import { HStack, StackProps } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
type CommentButtonProps = PropsWithChildren & StackProps;

export const CommentFormActions: FC<CommentButtonProps> = (props) => {
  const { children, ...restProps } = props;
  return (
    <HStack w="full" justifyItems="start" {...restProps}>
      {children}
    </HStack>
  );
};
