import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiBudget } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { RootState, useThunkDispatch } from "../../../store";
import {
  initBudgetFilter,
  loadBudgetFilter,
} from "../../../store/transaction-list.slice";

export interface BudgetFilterValue {
  value: ApiBudget[];
}

export interface BudgetFilterProps {
  value: (ApiBudget | string)[];
  onChange: (values: ApiBudget[]) => void;
}

const ContentRenderer = ({ props, state }: SelectRenderer<ApiBudget>) => {
  const renderMemo = useMemo(() => {
    if (state.values.length === 0) return "Budget";
    if (state.values.length === 1) return state.values[0].name;
    if (state.values.length > 1)
      return (
        <Text>
          {state.values[0].name}
          <AdditionalNumberBadge
            number={state.values.length - 1}
            tagProps={{ ml: 1 }}
          />
        </Text>
      );
  }, [state.values]);
  return <Box p={1}>{renderMemo}</Box>;
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiBudget>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["name", "externalId"], {
        sort: true,
      }),
    [props.options]
  );
  const { budgets: selectedValues } = useSelector(
    (state: RootState) => state.transactionList.filters
  );
  const items = useMemo(() => {
    const results = fuzzySearch.search(state.search);
    const selected = state.values;
    selected.filter((sel) =>
      results.find((res) => res.id === sel.id) ? null : results.push(sel)
    );
    return !state.search ? props.options : results;
  }, [state.search, state.values, props.options, fuzzySearch]);

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Assets"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.id}
              isChecked={
                methods.isSelected(item) ||
                selectedValues.some((sel) => sel === item.id)
              }
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              {item.name}
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};
export const TransactionsBudgetFilter = ({
  value,
  onChange,
}: BudgetFilterProps) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const { budgets: selectedValues } = useSelector(
    (state: RootState) => state.transactionList.filters
  );
  const [search, setSearch] = useState<string>("");
  const budgetFilterOptions = useSelector(
    (state: RootState) => state.transactionList.budgets
  );
  const { initCompleted } = useSelector(
    (state: RootState) => state.transactionList
  );

  const initOnce = useRef(false);
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const [filterInitCompleted, setFilterInitCompleted] = useState(false);
  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiBudget>) => {
      if (!state.search) {
        setSearch("");
      }
      setSearch(state.search);
      return props.options;
    },
    []
  );

  useEffect(() => {
    // wait for transaction list init to finish before we run this init
    if (!initCompleted || initOnce.current) {
      return;
    }
    initOnce.current = true;
    // load only selected values to init, no restrictions

    const promise =
      !selectedValues || selectedValues.length === 0
        ? Promise.resolve()
        : thunkDispatch(
            initBudgetFilter({
              apiClient,
              account,
              selectedValues,
            })
          );

    promise.then(() => setFilterInitCompleted(true));

    // on open, load the available options + add selected
  }, [apiClient, account, thunkDispatch, initCompleted, selectedValues]);

  useEffect(() => {
    if (!filterInitCompleted) {
      return;
    }
    thunkDispatch(
      loadBudgetFilter({
        apiClient,
        account,
        search,
      })
    );
  }, [apiClient, account, thunkDispatch, filterInitCompleted, search]);

  return filterInitCompleted ? (
    <Select
      multi
      className={themeClass}
      options={budgetFilterOptions}
      values={budgetFilterOptions.filter((budg) =>
        value.find((val) => val === budg.id) ? true : false
      )}
      onChange={onChange}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      clearOnSelect={false}
      clearable={false}
      contentRenderer={ContentRenderer}
      dropdownRenderer={DropdownRenderer}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  ) : null;
};
