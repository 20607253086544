"use-client";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";
import { AdditionalNumberBadge } from "../../../../components/badges/AdditionalNumberBadge";

interface VenueUseOptions {
  value: string;
}

export interface VenueUsesFilterProps {
  values: string[];
  addCloseIcon?: boolean;
  options: string[];
  onChange: (values: string[]) => void;
}

const ContentRenderer = ({
  state,
  methods,
  addCloseIcon,
  onChange,
}: SelectRenderer<VenueUseOptions> & {
  addCloseIcon?: boolean;
  onChange: (values: string[]) => void;
}) => {
  const borderColor = useColorModeValue(undefined, "gray.700");
  const handleClickCloseIcon = useCallback(() => {
    methods.clearAll();
    onChange([]);
  }, [methods, onChange]);

  return (
    <Box p={1} borderColor={borderColor} borderRadius="10px !important">
      {state.values.length === 0 && "Filter Uses"}
      {state.values.length === 1 && state.values[0].value}
      {state.values.length > 1 && (
        <>
          <Text as="span" mr={1}>
            {state.values[0].value}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
      {addCloseIcon && (
        <Icon as={MdClose} ml={2} onClick={(e) => handleClickCloseIcon()} />
      )}
    </Box>
  );
};

const DropdownHandleRenderer = () => {
  return <MdKeyboardArrowDown />;
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<VenueUseOptions>) => {
  const bgColor = useColorModeValue("transparent", "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Box>Search and select:</Box>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => methods.selectAll()}
            >
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Use"
        />
      </Box>
      <Box>
        {
          //@ts-ignore
          (state.search ? state.searchResults : props.options).map((item) => {
            return (
              <Checkbox
                key={item.value}
                isChecked={
                  methods.isSelected(item) ||
                  props.values.some((sel) => sel === item.value)
                }
                onChange={() => methods.addItem(item)}
                w="100%"
                p={2}
              >
                {item.value}
              </Checkbox>
            );
          })
        }
      </Box>
    </Box>
  );
};

export const VenuesUsesFilter = ({
  values,
  onChange,
  addCloseIcon,
  options,
}: VenueUsesFilterProps) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const borderColor = useColorModeValue("#E2E8F0", "#ffffff29");
  const hoverBorderColor = useColorModeValue("#CBD5E0", "#ffffff3d");
  const activeBorderColor = useColorModeValue("#007aff", "#63b3ed");
  const shadowColor = useColorModeValue("#3182ce", "#63b3ed");

  const opts = useMemo(() => options.map((opt) => ({ value: opt })), [options]);

  const promptAlert: React.CSSProperties = useMemo(() => {
    return {
      borderRadius: 6,
      borderColor: focus
        ? activeBorderColor
        : hover
        ? hoverBorderColor
        : borderColor,
      boxShadow: focus ? `0 0 0 1px ${shadowColor}` : "inherit",
    };
  }, [
    activeBorderColor,
    borderColor,
    focus,
    hover,
    hoverBorderColor,
    shadowColor,
  ]);

  const handleOnChange = useCallback(
    (params: VenueUseOptions[]) => {
      const values = params.map((v) => v.value);
      onChange(values);
    },
    [onChange]
  );

  return (
    <Box
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <Select
        multi
        options={opts}
        values={opts.filter((opt) => values.some((v) => v === opt.value))}
        onChange={(val) => handleOnChange(val)}
        searchable={true}
        searchBy="value"
        valueField="value"
        labelField="value"
        dropdownGap={0}
        keepSelectedInList
        clearOnSelect={false}
        clearable={false}
        contentRenderer={(props) =>
          ContentRenderer({ ...props, addCloseIcon, onChange })
        }
        dropdownRenderer={DropdownRenderer}
        dropdownHandleRenderer={
          addCloseIcon ? undefined : DropdownHandleRenderer
        }
        dropdownHeight="300px"
        style={promptAlert}
      />
    </Box>
  );
};
