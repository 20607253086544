import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Tooltip,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { MultiValue } from "chakra-react-select";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { UserMultipleAutocomplete } from "../selects/UserMultipleAutocomplete";

export interface UserAutocompleteControlProps {
  label: string;
  name: string;
  value: ApiUserSummary[] | null;
  assignSelf?: string;
  emptyText?: string;
  labelProps?: FormLabelProps;
  labelTooltip?: string;
}

export const UserMultipleAutocompleteControl = ({
  name,
  value,
  label,
  assignSelf,
  emptyText,
  labelProps,
  labelTooltip,
}: UserAutocompleteControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (user: MultiValue<ApiUserSummary> | null) => {
      helper.setTouched(true);
      helper.setValue(user || null);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <Flex justifyContent="space-between" alignContent="flex-start">
        <Tooltip label={labelTooltip}>
          <FormLabel htmlFor={name} {...labelProps}>
            {label}
          </FormLabel>
        </Tooltip>
      </Flex>
      <UserMultipleAutocomplete
        {...field}
        onChange={handleOnChange}
        name={name}
        allowEmpty={false}
        emptyText={emptyText}
        excludeProductsByName={["PortalHQ"]}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
