import {
  ApiItemAdjustmentReason,
  ApiItemAdjustmentType,
} from "@operations-hero/lib-api-client";
import * as yup from "yup";

function combinedSchemaValidation(
  type: ApiItemAdjustmentType,
  reason: ApiItemAdjustmentReason,
  maxQty: number
) {
  if (
    type === ApiItemAdjustmentType.restock &&
    reason === ApiItemAdjustmentReason.return
  ) {
    return yup.object().shape({
      requester: yup.object().required("Requester is required").nullable(),
      date: yup.date().required("Date is required").nullable(),
      quantity: yup
        .number()
        .typeError("Must be numeric and greater than 0")
        .required("Quantity is required")
        .positive("Quantity must be positive")
        .min(0.01)
        .max(maxQty),
      itemLocation: yup
        .object()
        .required("Item Location is required")
        .nullable(),
    });
  }
}
export const changeQuantityFormSchema = (
  type: ApiItemAdjustmentType,
  reason?: ApiItemAdjustmentReason,
  maxQuantity?: number
) => {
  if (reason && maxQuantity) {
    return combinedSchemaValidation(type, reason, maxQuantity);
  }

  switch (type) {
    case ApiItemAdjustmentType.restock:
      return yup.object().shape({
        requester: yup.object().required("Requester is required").nullable(),
        date: yup.date().required("Date is required").nullable(),
        quantity: yup
          .number()
          .typeError("Must be numeric and greater than 0")
          .required("Quantity is required")
          .positive("Quantity must be positive")
          .min(0.01),
        itemLocation: yup
          .object()
          .required("Item Location is required")
          .nullable(),
        supplier: yup.object().required("Supplier is required").nullable(),
      });
    case ApiItemAdjustmentType.issued:
      return yup.object().shape({
        requester: yup.object().required("Requester is required").nullable(),
        date: yup.date().required("Date is required").nullable(),
        quantity: yup
          .number()
          .typeError("Must be numeric and greater than 0")
          .required("Quantity is required")
          .min(0.01)
          .test({
            name: "storageLocationBalance",
            message:
              "Quantity must not be greater than storage location quantity",
            test: function (value) {
              const itemLocationQuantity = this.parent.itemLocation?.quantity;
              return (value || 0) <= itemLocationQuantity;
            },
          }),
        itemLocation: yup
          .object()
          .required("Item Location is required")
          .nullable(),
      });
    case ApiItemAdjustmentType.audit:
      return yup.object().shape({
        requester: yup.object().required("Requester is required").nullable(),
        date: yup.date().required("Date is required").nullable(),
        quantity: yup
          .number()
          .typeError("Must be numeric and equal or greater than 0")
          .required("Quantity is required")
          .positive("Quantity must be positive")
          .min(0),
        itemLocation: yup
          .object()
          .required("Item Location is required")
          .nullable(),
        reasonType: yup.string().required("Reason is required").nullable(),
      });
    case ApiItemAdjustmentType.move:
      return yup.object().shape({
        requester: yup.object().required("Requester is required").nullable(),
        date: yup.date().required("Date is required").nullable(),
        quantity: yup
          .number()
          .typeError("Must be numeric and greater than 0")
          .required("Quantity is required")
          .positive("Quantity must be positive")
          .min(0.01)
          .test({
            name: "storageLocationBalanceMove",
            message:
              "Quantity must not be greater than storage location from quantity",
            test: function (value) {
              const itemLocationQuantity = this.parent.locationFrom?.quantity;
              return (value || 0) <= itemLocationQuantity;
            },
          }),
        locationFrom: yup
          .object()
          .required("Location From is required")
          .nullable(),
        locationTo: yup.object().required("Location To is required").nullable(),
      });
    case ApiItemAdjustmentType.returnedToSupplier:
      return yup.object().shape({
        requester: yup.object().required("Requester is required").nullable(),
        date: yup.date().required("Date is required").nullable(),
        quantity: yup
          .number()
          .typeError("Must be numeric and greater than 0")
          .required("Quantity is required")
          .positive("Quantity must be positive")
          .min(0.01)
          .max(maxQuantity || 0),
        itemLocation: yup
          .object()
          .required("Item Location is required")
          .nullable(),
      });
    case ApiItemAdjustmentType.returnedToInventory:
      return yup.object().shape({
        requester: yup.object().required("Requester is required").nullable(),
        date: yup.date().required("Date is required").nullable(),
        quantity: yup
          .number()
          .typeError("Must be numeric and greater than 0")
          .required("Quantity is required")
          .positive("Quantity must be positive")
          .min(0.01)
          .max(maxQuantity || 0),
        itemLocation: yup
          .object()
          .required("Item Location is required")
          .nullable(),
      });
    default:
      return undefined;
  }
};
