import { CSSObject } from "@chakra-ui/styled-system";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { RootState } from "../../../store";
import {
  AppShellStylingContext,
  AppShellStylingContextProps,
} from "./AppShellStylingContext";

const defaultStyling: CSSObject = {
  display: "flex",
  flexDirection: "column",
  w: "100%",
  minW: "340px",
  overflowX: "hidden",
  overflowY: "hidden",
  main: {
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    w: "100%",
    minH: "calc(100vh - 85px)",
    px: "0",
    mt: "4",
    pb: "8",
  },
};

const requestsPageColumnViewStyles: CSSObject = {
  height: "100vh",
  display: "flex",
  flexDir: "column",
  overflowX: "clip",
  overflowY: "hidden",
  w: "100%",
  minW: "340px",
  main: {
    w: "100%",
    flexGrow: "1",
    overflowY: "hidden",
    px: "0",
    mt: "4",
    pb: "8",
  },
};

const projectSchedulingStyles = ({ isDesktop }: { isDesktop: boolean }) => {
  return {
    height: isDesktop ? "100vh" : undefined,
    display: "flex",
    flexDir: "column",
    overflowX: "clip",
    overflowY: "hidden",
    w: "100%",
    minW: "340px",
    main: {
      w: "100%",
      flexGrow: "1",
      overflowY: "hidden",
      px: "0",
      mt: "4",
      pb: "8",
    },
  };
};

interface AppShellStylingProviderProps {
  children: React.ReactNode;
}

export const AppShellStylingProvider: FC<AppShellStylingProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const { isDesktop } = useScreenBreakpoints();

  const [stylingMode, setStylingMode] =
    useState<AppShellStylingContextProps["stylingMode"]>("default");

  const { displayMode } = useSelector(
    (state: RootState) => state.requestsSlice
  );
  const [styling, setStyling] = useState<CSSObject>(defaultStyling);
  const value: AppShellStylingContextProps = useMemo(() => {
    return {
      stylingMode: stylingMode,
      styling: styling,
      setToDefaultStyling: () => setStylingMode("default"),
    };
  }, [styling, stylingMode]);

  useEffect(() => {
    const isRequestsPage = matchPath(location.pathname, "/requests");

    const isProjectScheduling = matchPath(
      { path: "/planning/projects/:id/scheduling", end: true },
      location.pathname
    );

    if (displayMode === "column" && isRequestsPage) {
      setStylingMode("request.column-view");
      setStyling(requestsPageColumnViewStyles);
      return;
    }
    if (isProjectScheduling) {
      setStylingMode("project.scheduling");
      setStyling(projectSchedulingStyles({ isDesktop }));
      return;
    }

    setStylingMode("default");
    setStyling(defaultStyling);
  }, [displayMode, location, isDesktop]);
  return (
    <>
      <AppShellStylingContext.Provider value={value}>
        {children}
      </AppShellStylingContext.Provider>
    </>
  );
};
