import { SearchIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChangeEvent, forwardRef } from "react";
import { debounce } from "../../utils/debounce";

interface AccountSearchBoxProps {
  defaultValue?: string;
  isUniqueElement?: boolean;
  searchPlaceholder: string;
  onInputChange: (value: string) => void;
  rest?: InputProps;
}

export const AccountSearchBox = forwardRef<
  HTMLInputElement,
  AccountSearchBoxProps
>(
  (
    {
      defaultValue,
      isUniqueElement = true,
      searchPlaceholder,
      onInputChange,
      rest,
    },
    ref
  ) => {
    const searchBackground = useColorModeValue("white", "transparent");

    const debouncedOnChangeInput = debounce(onInputChange, 300);

    return (
      <InputGroup
        w="100%"
        borderRadius={6}
        alignSelf="flex-end"
        bgColor={searchBackground}
        float={isUniqueElement ? "right" : "unset"}
        {...rest}
      >
        <InputLeftElement children={<SearchIcon color="gray.300" />} />
        <Input
          defaultValue={defaultValue}
          type="text"
          placeholder={searchPlaceholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            debouncedOnChangeInput(e.target.value)
          }
          ref={ref}
        />
      </InputGroup>
    );
  }
);
