import {
  Badge,
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { RootState, useThunkDispatch } from "../../../store";
import {
  loadPeopleFilterOptions,
  PersonFilterValue,
  PersonFilterValueType,
} from "../../../store/transaction-list.slice";
import PersonList from "./PersonList";

export interface PersonFilterReferenceValue {
  type: PersonFilterValueType;
  user: ApiUserSummary | string;
}

export interface PersonFilterProps {
  value: PersonFilterReferenceValue[];
  onChange: (value: PersonFilterReferenceValue[]) => void;
}

const ContentRenderer = ({
  props,
  state,
}: SelectRenderer<PersonFilterValue>) => {
  return (
    <Box p={1}>
      {state.values.length === 0 && "Person"}
      {state.values.length === 1 && (
        <UserBadge size="2xs" value={state.values[0].user} />
      )}
      {state.values.length > 1 && (
        <Flex alignItems="center">
          <Text as="span">Persons</Text>
          <AdditionalNumberBadge
            number={state.values.length}
            tagProps={{ ml: 1 }}
          />
        </Flex>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<PersonFilterValue>) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();

  const createdBy = useMemo(
    () => state.values.filter((x) => x.type === "createdBy"),
    [state.values]
  );

  const handleTypeChange = useCallback(
    (index: number) => {
      thunkDispatch(
        loadPeopleFilterOptions({
          apiClient,
          account,
          type: "createdBy",
        })
      );
    },
    [thunkDispatch, apiClient, account]
  );

  return (
    <Box>
      <Tabs onChange={handleTypeChange}>
        <TabList>
          <Tab>
            Created By{" "}
            {createdBy.length > 0 && (
              <Badge
                colorScheme="blue"
                size="xs"
                borderRadius="xl"
                ml={2}
                p={1}
              >
                {createdBy.length}
              </Badge>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PersonList
              values={createdBy}
              methods={methods}
              type={"createdBy"}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export const PersonFilter = ({ value, onChange }: PersonFilterProps) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const userFilterOptions = useSelector(
    (state: RootState) => state.transactionList.availablePersons
  );

  const themeClass = useColorModeValue("light-theme", "dark-theme");
  const [hydratedValues, setHydatedValues] = useState<PersonFilterValue[]>([]);

  useEffect(() => {
    if (!value || value.length === 0) {
      setHydatedValues([]);
      return;
    }
    const notHydated = value.filter((x) => typeof x.user === "string");

    if (notHydated.length === 0) {
      setHydatedValues(value as PersonFilterValue[]);
    }

    apiClient
      .findAccountUsers(account.id, {
        ids: notHydated.map((x) => x.user as string),
      })
      .then(({ data }) => {
        const alreadyHydrated = value
          .filter((x) => typeof x.user !== "string")
          .map<PersonFilterValue>(({ type, user }) => ({
            type,
            user: user as ApiUserSummary,
            key: `${type}::${(user as ApiUserSummary).id}`,
          }));

        const hydrated = notHydated.map<PersonFilterValue>(({ type, user }) => {
          const userSummary = data.find((r) => r.id === (user as string))!;
          return {
            type,
            user: userSummary,
            key: `${type}::${userSummary.id}`,
          };
        });

        setHydatedValues([...hydrated, ...alreadyHydrated]);
      });
  }, [apiClient, account, value]);

  useEffect(() => {
    thunkDispatch(
      loadPeopleFilterOptions({
        apiClient,
        account,
        type: "createdBy",
      })
    );
  }, [apiClient, account, thunkDispatch]);

  return (
    <Box>
      <Select
        multi={true}
        className={themeClass}
        options={userFilterOptions}
        values={hydratedValues}
        onChange={onChange}
        searchable={true}
        valueField="key"
        dropdownGap={0}
        keepSelectedInList
        contentRenderer={ContentRenderer}
        dropdownRenderer={DropdownRenderer}
        dropdownHeight="300"
      />
    </Box>
  );
};
