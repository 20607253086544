import {
  ApiClient,
  ApiInventoryOrderSummary,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  inventoryOrderDefaultFilters,
  InventoryOrderFiltersValues,
} from "../../pages/inventory/inventory-orders/inventory-placed-orders/inventoryOrderDefault";

interface LoadInventoryOrdersParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: Partial<InventoryOrderFiltersValues>;
}

export const loadInventoryOrders = createAsyncThunk(
  "inventory-order/load",
  async (params: LoadInventoryOrdersParams, ThunkAPI) => {
    const { apiClient, accountId } = params;
    const { filters } = (ThunkAPI.getState() as RootState).inventoryOrder;
    const newFilters = params.filters
      ? {
          ...filters,
          ...params.filters,
        }
      : filters;
    const response = await apiClient.findInventoryOrders(accountId, newFilters);
    return response;
  }
);

interface InventoryOrderListSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: ApiInventoryOrderSummary[];
  total: number;
  filters: Partial<InventoryOrderFiltersValues>;
  selectedOrders: string[];

  workingOrder: ApiInventoryOrderSummary | null;
  isOpenUpdateModal: boolean;
  isOpenTimelineModal: boolean;
  isOpenDeclineModal: boolean;
  isOpenRepeatModal: boolean;
  isOpenReviewModal: boolean;
}

const DEFAULT_INVENTORY_ORDER: InventoryOrderListSlice = {
  loading: "idle",
  data: [],
  total: 0,
  filters: { ...inventoryOrderDefaultFilters },
  selectedOrders: [],

  workingOrder: null,
  isOpenUpdateModal: false,
  isOpenTimelineModal: false,
  isOpenDeclineModal: false,
  isOpenRepeatModal: false,
  isOpenReviewModal: false,
};

export const inventoryOrderListSlice = createSlice({
  name: "inventory-order",
  initialState: { ...DEFAULT_INVENTORY_ORDER },
  reducers: {
    unloadInventoryOrder: (state: InventoryOrderListSlice) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.filters = { ...inventoryOrderDefaultFilters };
    },
    updateInventoryOrderFilters: (
      state: InventoryOrderListSlice,
      action: PayloadAction<Partial<InventoryOrderFiltersValues>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
      state.selectedOrders = [];
    },
    handleSelectAllOrders: (state: InventoryOrderListSlice) => {
      state.selectedOrders = state.data.map((order) => order.id);
    },
    handleClearSelectedOrder: (state: InventoryOrderListSlice) => {
      state.selectedOrders = [];
    },
    handleOnSelectOrder: (
      state: InventoryOrderListSlice,
      action: PayloadAction<string>
    ) => {
      const index = state.selectedOrders.findIndex(
        (id) => id === action.payload
      );
      const selectedOrdersCopy = [...state.selectedOrders];
      if (index !== -1) {
        selectedOrdersCopy.splice(index, 1);
        state.selectedOrders = selectedOrdersCopy;
        return;
      }
      selectedOrdersCopy.push(action.payload);
      state.selectedOrders = selectedOrdersCopy;
    },
    setModalState: (
      state: InventoryOrderListSlice,
      action: PayloadAction<{
        isOpenUpdateModal?: boolean;
        isOpenTimelineModal?: boolean;
        isOpenDeclineModal?: boolean;
        isOpenRepeatModal?: boolean;
        isOpenReviewModal?: boolean;
        workingOrder: ApiInventoryOrderSummary | null;
      }>
    ) => {
      const {
        isOpenUpdateModal,
        isOpenTimelineModal,
        isOpenDeclineModal,
        isOpenRepeatModal,
        isOpenReviewModal,
        workingOrder,
      } = action.payload;

      state.workingOrder = workingOrder;

      if (typeof isOpenUpdateModal === "boolean") {
        state.isOpenUpdateModal = isOpenUpdateModal;
        if (isOpenUpdateModal) {
          state.isOpenTimelineModal = false;
          state.isOpenDeclineModal = false;
          state.isOpenRepeatModal = false;
          state.isOpenReviewModal = false;
          return;
        }
      }

      if (typeof isOpenTimelineModal === "boolean") {
        state.isOpenTimelineModal = isOpenTimelineModal;
        if (isOpenTimelineModal) {
          state.isOpenUpdateModal = false;
          state.isOpenDeclineModal = false;
          state.isOpenRepeatModal = false;
          state.isOpenReviewModal = false;
          return;
        }
      }

      if (typeof isOpenDeclineModal === "boolean") {
        state.isOpenDeclineModal = isOpenDeclineModal;
        if (isOpenDeclineModal) {
          state.isOpenTimelineModal = false;
          state.isOpenUpdateModal = false;
          state.isOpenRepeatModal = false;
          state.isOpenReviewModal = false;
          return;
        }
      }

      if (typeof isOpenRepeatModal === "boolean") {
        state.isOpenRepeatModal = isOpenRepeatModal;
        if (isOpenRepeatModal) {
          state.isOpenTimelineModal = false;
          state.isOpenUpdateModal = false;
          state.isOpenDeclineModal = false;
          state.isOpenReviewModal = false;
          return;
        }
      }
      if (typeof isOpenReviewModal === "boolean") {
        state.isOpenReviewModal = isOpenReviewModal;
        if (isOpenReviewModal) {
          state.isOpenTimelineModal = false;
          state.isOpenUpdateModal = false;
          state.isOpenDeclineModal = false;
          state.isOpenRepeatModal = false;
          return;
        }
      }
      state.isOpenTimelineModal = false;
      state.isOpenDeclineModal = false;
      state.isOpenUpdateModal = false;
      state.isOpenRepeatModal = false;
      state.isOpenReviewModal = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadInventoryOrders.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(loadInventoryOrders.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadInventoryOrders.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const {
  unloadInventoryOrder,
  updateInventoryOrderFilters,
  handleOnSelectOrder,
  handleSelectAllOrders,
  handleClearSelectedOrder,
  setModalState,
} = inventoryOrderListSlice.actions;

export default inventoryOrderListSlice.reducer;
