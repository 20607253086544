import {
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiReason } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { RootState } from "../../../store";
import { FiltersWithCloseIcon } from "../RequestAdvancedFilters";

export interface ReasonFilterProps {
  value: (ApiReason | string)[];
  onChange: (value: ApiReason[]) => void;
  addCloseIcon?: FiltersWithCloseIcon;
}

const DropdownHandleRenderer = () => {
  return <Box />;
};

const ContentRenderer = ({
  state,
  addCloseIcon,
}: SelectRenderer<ApiReason> & { addCloseIcon?: FiltersWithCloseIcon }) => {
  return (
    <Box p={1} display="flex" w="max-content" alignItems="center">
      {state.values.length === 0 && "Reason"}
      {state.values.length === 1 && (
        <Box maxW={"100px"}>
          <Text isTruncated>{state.values[0].name}</Text>
        </Box>
      )}
      {state.values.length > 1 && (
        <>
          <Text as="span" mr={1}>
            {state.values[0].name}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
      {addCloseIcon && (
        <Icon
          as={MdClose}
          ml={2}
          onClick={(e) => addCloseIcon.handleClickCloseIcon(e, "reasons")}
        />
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiReason>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["name"], {
        sort: true,
      }),
    [props.options],
  );

  const items = useMemo(() => {
    return !state.search ? props.options : fuzzySearch.search(state.search);
  }, [state.search, props.options, fuzzySearch]);

  return (
    <Box p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Reasons"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={`reasons::${item.id}`}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.name}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const ReasonFilter = ({
  value,
  onChange,
  addCloseIcon,
}: ReasonFilterProps) => {
  const { reasons, reasonsMap } = useSelector(
    (state: RootState) => state.localCache,
  );
  const [reasonsToAdd, setReasonsToAdd] = useState<ApiReason[]>([]);

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  useEffect(() => {
    const newReasons = [
      {
        id: "null",
        name: "Missing",
        isSystem: true,
        active: true,
      },
    ].concat(reasons);
    setReasonsToAdd(newReasons);
  }, [setReasonsToAdd, reasons]);

  return (
    <>
      {reasons && (
        <Select
          multi
          options={reasonsToAdd.filter((res) => res.active)}
          className={themeClass}
          values={value.map((v) =>
            typeof v === "string"
              ? v !== "null"
                ? reasonsMap[v]
                : {
                    id: "null",
                    name: "Missing",
                    isSystem: true,
                    active: true,
                  }
              : v,
          )}
          onChange={onChange}
          searchable={true}
          searchBy="name"
          valueField="id"
          labelField="name"
          dropdownGap={0}
          keepSelectedInList
          contentRenderer={(props) =>
            ContentRenderer({ ...props, addCloseIcon })
          }
          dropdownRenderer={DropdownRenderer}
          dropdownHandleRenderer={
            addCloseIcon ? DropdownHandleRenderer : undefined
          }
          dropdownHeight="350px"
        />
      )}
    </>
  );
};
