import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiInventoryOrderStatus,
  ApiInventoryOrderSummary,
  CreateApiInventoryOrder,
} from "@operations-hero/lib-api-client";
import { EditorState } from "draft-js";
import { Form, Formik } from "formik";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdFiberManualRecord, MdWarning } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { RichTextEditorComments } from "../../../components/form-helpers/rich-text-editor/RichTextEditorComments";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { useShowToast } from "../../../hooks/showToast";
import { useConvertMentions } from "../../../hooks/useConvertMentions";
import { RootState, useThunkDispatch } from "../../../store";
import {
  cleanOrderSupplierItemsCart,
  InventoryOrderItem,
  loadInventoryItems,
} from "../../../store/inventory/inventory-order-item-list.slice";
import { loadInventoryOrders } from "../../../store/inventory/inventory-order.slice";
import { getId } from "../../../utils/getId";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { convertToSave } from "../../request-form/comments/Comment";
import { RecieveInventoryOrderRowItemForm } from "./inventory-order-form-item-rows/RecieveInventoryOrderRowItemForm";
import { RepeatOrCreateInventoryOrderRowItemForm } from "./inventory-order-form-item-rows/RepeatOrCreateInventoryOrderRowItemForm";
import { ReviewInventoryOrderRowItemForm } from "./inventory-order-form-item-rows/ReviewInventoryOrderRowItemForm";
import { UpdateInventoryOrderRowItemForm } from "./inventory-order-form-item-rows/UpdateInventoryOrderRowItemForm";
import { InventoryOrderFormSchema } from "./InventoryOrderFormSchema";

export interface InventoryOrderValues
  extends Omit<CreateApiInventoryOrder, "items" | "mentioned" | "totalItems"> {
  orderDate: string;
  items: InventoryOrderItem[];
}

interface CreateInventoryOrderFormProps {
  onCancel: () => void;
  onSubmit?: () => void;
  inventoryOrder?: ApiInventoryOrderSummary;
  isRepeatOrder?: boolean;
}

export const InventoryOrderForm: FC<CreateInventoryOrderFormProps> = ({
  onCancel,
  onSubmit,
  inventoryOrder,
  isRepeatOrder,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { cartOrderItems, filters } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });
  const [hydratedItems, setHydratedItems] = useState<InventoryOrderItem[]>([]);
  const [supplierItemsToDisplay, setSupplierItemsToDisplay] = useState<
    InventoryOrderItem[]
  >([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const dateNow = new Date().toISOString().split("T")[0];
  const supplierId = inventoryOrder
    ? inventoryOrder.supplier.id
    : cartOrderItems.supplierId;

  const groupedItems = useMemo(
    () =>
      cartOrderItems.items.reduce(
        (acc, cartItem) => {
          if (cartItem.supplierSelected.id === supplierId) {
            if (!acc[supplierId]) {
              acc[supplierId] = [] as InventoryOrderItem[];
            }
            acc[supplierId].push(cartItem);
          }
          return acc;
        },
        {} as Record<string, InventoryOrderItem[]>
      ),
    [cartOrderItems, supplierId]
  );

  const supplierItems = useMemo(
    () =>
      inventoryOrder ? hydratedItems : groupedItems[cartOrderItems.supplierId],
    [groupedItems, cartOrderItems.supplierId, hydratedItems, inventoryOrder]
  );

  const [notes, setNotes] = useState("");
  const [emptyItems, setEmptyItems] = useState(false);
  const color = useColorModeValue("red.500", "red.300");

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const showToast = useShowToast();

  const calculateTotalCost = useCallback((values: InventoryOrderValues) => {
    return values.items.reduce((total, item) => {
      return total + item.delivered * item.unitCost;
    }, 0);
  }, []);

  const isReviewModal = useMemo(() => {
    return (
      !isRepeatOrder &&
      inventoryOrder &&
      inventoryOrder?.status === ApiInventoryOrderStatus.delivered
    );
  }, [inventoryOrder, isRepeatOrder]);

  const initialValues: InventoryOrderValues = useMemo(() => {
    const supplier = inventoryOrder
      ? inventoryOrder.supplier
      : supplierItems[0].supplierSelected;
    return {
      inventoryOrderNumber: inventoryOrder
        ? inventoryOrder.inventoryOrderNumber
        : "",
      invoiceNumber: inventoryOrder ? inventoryOrder.invoiceNumber : "",
      purchaseOrder: inventoryOrder ? inventoryOrder.purchaseOrder : "",
      supplier,
      expectedDate:
        inventoryOrder && !isRepeatOrder ? inventoryOrder.expectedDate : "",
      note: inventoryOrder ? inventoryOrder.note : "",
      orderDate:
        inventoryOrder && !isRepeatOrder ? inventoryOrder.orderDate : dateNow,

      items: supplierItems.map((item) => {
        if (isReviewModal) {
          return item;
        }
        return {
          ...item,
          delivered: item.requested - item.delivered, //left amount to deliver as default value on recieve and update form
          restBackorder: item.requested - item.delivered,
        };
      }),
    };
  }, [inventoryOrder, supplierItems, dateNow, isReviewModal, isRepeatOrder]);

  const { editorState: notesEditorState } = useConvertMentions({
    value: inventoryOrder ? inventoryOrder.note : "",
    mentioned: inventoryOrder ? inventoryOrder.mentioned : [],
  });

  const handleSummaryBlur = useCallback(
    (summary: EditorState) => {
      const response = convertToSave(summary);
      if (!response || response === notes) return;
      setNotes(response);
    },
    [notes]
  );

  const mapInventoryOrder = useCallback(
    (values: InventoryOrderValues) => {
      return {
        note: notes,
        inventoryOrderNumber: values.inventoryOrderNumber,
        orderDate: values.orderDate,
        items: values.items,
        expectedDate: values.expectedDate,
        invoiceNumber: values.invoiceNumber,
        purchaseOrder: values.purchaseOrder,
        supplier: values.supplier,
      } as CreateApiInventoryOrder;
    },
    [notes]
  );

  const isRepeatOrCreateModal = useMemo(() => {
    return (
      (!inventoryOrder || isRepeatOrder) &&
      supplierItemsToDisplay &&
      supplierItemsToDisplay.length > 0
    );
  }, [inventoryOrder, isRepeatOrder, supplierItemsToDisplay]);

  const isRecieveOrUpdateModal = useMemo(() => {
    return (
      inventoryOrder &&
      !isRepeatOrder &&
      supplierItemsToDisplay &&
      supplierItemsToDisplay.length > 0
    );
  }, [inventoryOrder, supplierItemsToDisplay, isRepeatOrder]);

  const isRecieveModal = useMemo(() => {
    return (
      isRecieveOrUpdateModal &&
      inventoryOrder?.status === ApiInventoryOrderStatus.ordered
    );
  }, [isRecieveOrUpdateModal, inventoryOrder?.status]);

  const isUpdateModal = useMemo(() => {
    return (
      isRecieveOrUpdateModal &&
      inventoryOrder?.status === ApiInventoryOrderStatus.partialDelivery
    );
  }, [isRecieveOrUpdateModal, inventoryOrder?.status]);

  const handleOnSubmit = useCallback(
    (values: InventoryOrderValues, isSecondStep?: boolean) => {
      if (isReviewModal) {
        onCancel();
        return;
      }
      if (supplierItems.length < 1) {
        setEmptyItems(true);
        return;
      }

      if (
        !isSecondStep &&
        inventoryOrder &&
        isRecieveModal &&
        values.items.some(
          (item) => item.backOrder === false && item.requested > item.delivered
        )
      ) {
        onOpen();
        return;
      }

      const newOrder = mapInventoryOrder(values);

      if (inventoryOrder && !isRepeatOrder) {
        apiClient
          .updateInventoryOrder(currentAccount.id, inventoryOrder.id, newOrder)
          .then((res) => {
            dispatch(
              cleanOrderSupplierItemsCart(getId(newOrder.supplier || ""))
            );

            showToast(
              "success",
              `${res.key} has been changed to ${res.status}`
            );
            onCancel();
            onSubmit && onSubmit();
            if (location.pathname.includes("placed-orders")) {
              thunkDispatch(
                loadInventoryOrders({
                  apiClient,
                  accountId: currentAccount.id,
                  filters,
                })
              );
            }
          })
          .catch(() => {
            showToast(
              "error",
              "something went wrong updateing Inventory Order, please try again"
            );
          });
        return;
      }

      apiClient
        .createInventoryOrder(currentAccount.id, newOrder)
        .then((res) => {
          dispatch(cleanOrderSupplierItemsCart(getId(newOrder.supplier || "")));

          showToast("success", `${res.key} has been placed`);
          onCancel();
          onSubmit && onSubmit();
          if (location.pathname.includes("placed-orders")) {
            thunkDispatch(
              loadInventoryOrders({
                apiClient,
                accountId: currentAccount.id,
                filters,
              })
            );
          }
        })
        .catch(() => {
          showToast(
            "error",
            "something went wrong creating Inventory Order, please try again"
          );
        });
    },
    [
      apiClient,
      currentAccount.id,
      dispatch,
      mapInventoryOrder,
      onCancel,
      showToast,
      onSubmit,
      inventoryOrder,
      supplierItems.length,
      isRepeatOrder,
      onOpen,
      filters,
      location.pathname,
      thunkDispatch,
      isRecieveModal,
      isReviewModal,
    ]
  );

  useEffect(() => {
    if (!inventoryOrder) return;
    thunkDispatch(
      loadInventoryItems({
        apiClient,
        accountId: currentAccount.id,
      })
    );
  }, [
    apiClient,
    currentAccount.id,
    thunkDispatch,
    filters.search,
    inventoryOrder,
  ]);

  useEffect(() => {
    if (!inventoryOrder) return;
    apiClient
      .getInventoryOrderItems(currentAccount.id, inventoryOrder.id)
      .then((res) => {
        setHydratedItems(res as InventoryOrderItem[]);
      });
  }, [
    apiClient,
    currentAccount.id,
    thunkDispatch,
    filters.search,
    inventoryOrder,
  ]);

  useEffect(() => {
    setSupplierItemsToDisplay(supplierItems);
  }, [supplierItems]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleOnSubmit(values)}
        validationSchema={InventoryOrderFormSchema}
        enableReinitialize
      >
        {({ values, isSubmitting, setSubmitting }) => {
          return (
            <Form>
              <Grid minW="100%" gap={6} templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={12}>
                  {emptyItems && supplierItems.length < 1 && (
                    <Text fontSize="xs" color={color}>
                      You Need to select at least one item
                    </Text>
                  )}
                  <Flex flexDir="column" alignItems="center" gap={6}>
                    {isRepeatOrCreateModal && (
                      <RepeatOrCreateInventoryOrderRowItemForm
                        items={supplierItemsToDisplay}
                      />
                    )}
                    {isRecieveModal && (
                      <RecieveInventoryOrderRowItemForm
                        values={values}
                        items={supplierItemsToDisplay}
                      />
                    )}
                    {isUpdateModal && (
                      <UpdateInventoryOrderRowItemForm
                        values={values}
                        items={supplierItemsToDisplay}
                      />
                    )}

                    {isReviewModal && inventoryOrder && (
                      <ReviewInventoryOrderRowItemForm
                        values={values}
                        items={supplierItemsToDisplay}
                        inventoryOrder={inventoryOrder}
                      />
                    )}
                  </Flex>
                </GridItem>

                <GridItem colSpan={12}>
                  <Divider />
                </GridItem>

                <GridItem colSpan={isDesktop ? 6 : 12}>
                  <DatePickerControl
                    value={values.orderDate}
                    label="Order Date"
                    name="orderDate"
                    isDisabled={true}
                  />
                </GridItem>

                <GridItem colSpan={isDesktop ? 6 : 12}>
                  <DatePickerControl
                    value={values.expectedDate}
                    label="Expected Delivery Date"
                    name="expectedDate"
                    isDisabled={isReviewModal ? true : false}
                  />
                </GridItem>
                <GridItem colSpan={12}>
                  <TextInputControl
                    name="inventoryOrderNumber"
                    value={values.inventoryOrderNumber}
                    label="Order Number"
                    placeholder="Add order Number"
                    isDisabled={isReviewModal ? true : false}
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <TextInputControl
                    name="invoiceNumber"
                    value={values.invoiceNumber}
                    label="Invoice Number"
                    placeholder="Add invoice Number"
                    isDisabled={isReviewModal ? true : false}
                  />
                </GridItem>
                <GridItem colSpan={12}>
                  <TextInputControl
                    name="purchaseOrder"
                    value={values.purchaseOrder}
                    label="PO Number"
                    placeholder="Add PO Number"
                    isDisabled={isReviewModal ? true : false}
                  />
                </GridItem>
                <GridItem colSpan={12}>
                  <RichTextEditorComments
                    label="Notes (optional)"
                    value={notesEditorState}
                    onBlur={handleSummaryBlur}
                    id="order-inventory-note"
                    labelProps={{ fontWeight: "bold", fontSize: "lg" }}
                  />
                </GridItem>
                <GridItem colSpan={12}>
                  <Heading fontSize="xl" textAlign="right">
                    {`Total Cost: $${calculateTotalCost(values).toFixed(2)}`}
                  </Heading>
                </GridItem>

                <GridItem colSpan={6}>
                  <Divider />
                </GridItem>

                {values.items.some(
                  (item) =>
                    item.backOrder === false && item.requested > item.delivered
                ) &&
                  inventoryOrder &&
                  !isReviewModal &&
                  !isRepeatOrder && (
                    <>
                      <GridItem colSpan={6}>
                        <Divider />
                      </GridItem>
                      <GridItem
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        colSpan={12}
                      >
                        <Icon
                          mr={2}
                          as={RiErrorWarningLine}
                          boxSize="24px"
                          color="yellow.500"
                        />
                        <Text flexWrap="wrap">
                          Missing deliveries not marked as backorded will be
                          removed.
                        </Text>
                      </GridItem>
                    </>
                  )}
                <GridItem
                  colSpan={12}
                  display="flex"
                  justifyContent={isReviewModal ? "flex-end" : "space-between"}
                >
                  {!isReviewModal && (
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    colorScheme="blue"
                    size="sm"
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={
                      inventoryOrder && !isReviewModal && !isRepeatOrder
                        ? values.items.some((item) => !item.touched)
                        : false
                    }
                  >
                    {isReviewModal
                      ? "Close"
                      : isRecieveModal
                        ? "Recieve and ReStock"
                        : isRecieveOrUpdateModal
                          ? "Update"
                          : "Place Order"}
                  </Button>
                </GridItem>
              </Grid>
              {isOpen &&
                inventoryOrder &&
                isRecieveModal &&
                values.items.some(
                  (item) =>
                    item.backOrder === false && item.requested > item.delivered
                ) && (
                  <AccountModal
                    size="lg"
                    title={
                      <Flex alignItems="center">
                        <Icon
                          as={MdWarning}
                          boxSize="60px"
                          color="yellow.300"
                        />
                        <Heading fontSize="l">
                          Some Items will be marked as removed
                        </Heading>
                      </Flex>
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                    content={
                      <>
                        <Text>
                          Items not marked as backorder, will be removed from
                          the order, including:
                        </Text>
                        {values.items
                          .filter(
                            (item) =>
                              item.backOrder === false &&
                              item.requested > item.delivered
                          )
                          .map((item) => (
                            <Flex alignItems="center" my={2}>
                              <Icon
                                as={MdFiberManualRecord}
                                boxSize="12px"
                                color="gray.800"
                              />
                              <Text>
                                <Text as="span" fontWeight="bold">
                                  {item.item.name}
                                </Text>{" "}
                                {item.item.identifiers.externalId}{" "}
                                <Text as="span" fontWeight="bold">
                                  (x{item.requested - item.delivered})
                                </Text>
                              </Text>
                            </Flex>
                          ))}
                        <Flex justifyContent="flex-end" alignContent="flex-end">
                          <Button
                            onClick={() => {
                              setSubmitting(false);
                              onClose();
                            }}
                            mr={2}
                          >
                            Go Back
                          </Button>
                          <Button
                            onClick={() => handleOnSubmit(values, true)}
                            colorScheme="blue"
                          >
                            Continue
                          </Button>
                        </Flex>
                      </>
                    }
                  />
                )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
