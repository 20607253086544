import { Text, TextProps } from "@chakra-ui/react";
import { ApiRate } from "@operations-hero/lib-api-client";
import { FC } from "react";
import {
  formatCurrency,
  formatRateType,
} from "../../../../utils/formatCurrency";

interface ItemPriceProps {
  ratesByItem?: ApiRate[];
  addDash?: boolean;
  textProps?: TextProps;
}

export const ItemPrice: FC<ItemPriceProps> = ({
  ratesByItem,
  addDash = true,
  textProps,
}) => {
  return (
    <>
      {ratesByItem &&
        Object.values(ratesByItem).map((r, index) => (
          <Text
            as="span"
            fontWeight="normal"
            key={`itemPrice::${r.id}:${index}`}
            {...textProps}
          >
            {` ${addDash ? "- " : ""}${formatCurrency(r.pricing?.price || 0)}${
              r?.pricing ? formatRateType[r.pricing.type] : ""
            }`}
          </Text>
        ))}
    </>
  );
};
