import {
  Box,
  FormLabel,
  Grid,
  HStack,
  IconButton,
  Input,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect } from "react";
import { IoTrashOutline } from "react-icons/io5";

export interface Item {
  itemName: string;
  quantity: number;
  unitCost: number;
}

interface ItemsSectionProps {
  value: Item[];
  setValues: (value: Item[]) => void;
  setHasPurchaseItem: (hasItems: boolean) => void;
  isNameDisabled?: boolean;
}

export const ItemsSection: FC<ItemsSectionProps> = ({
  value,
  setValues,
  setHasPurchaseItem,
  isNameDisabled,
}) => {
  const errorTextColor = useColorModeValue("red.500", "red.300");
  const bgC = useColorModeValue("gray.50", "gray.600");
  const onChangeText = useCallback(
    (text: string, index: number, key: keyof Item) => {
      let newValue: Item = { ...value[index] };

      switch (key) {
        case "itemName":
          newValue.itemName = text;
          break;
        case "quantity":
          newValue.quantity = Number(text);
          break;
        case "unitCost":
          newValue.unitCost = Number(text);
          break;
      }

      if (index === value.length - 1 || value.length === 1) {
        value.splice(value.length - 1, 0, newValue);
      } else {
        const isEmpty =
          newValue.itemName.trim() === "" &&
          newValue.quantity === 0 &&
          newValue.unitCost === 0;

        if (isEmpty) {
          value.splice(index, 1);
        } else {
          value[index] = newValue;
        }
      }
      const res = [...value];
      setValues(res);

      const hasValidItem = value.some(
        (item) =>
          item.itemName.trim() !== "" && item.quantity > 0 && item.unitCost > 0
      );
      setHasPurchaseItem(hasValidItem);
    },
    [value, setValues, setHasPurchaseItem]
  );

  const handleDeleteLabel = (index: number) => {
    if (index === value.length - 1) {
      return;
    }
    value.splice(index, 1);
    setValues([...value]);
  };

  useEffect(() => {
    let newValue: Item[] = [];
    if (value && value[value.length - 1].itemName !== "") {
      newValue = [...value];
      newValue.push({ itemName: "", quantity: 0, unitCost: 0 });
      setValues(newValue);
    }
  }, [value, setValues]);

  return (
    <Box>
      <HStack>
        <Box>
          {!isNameDisabled ? (
            <>
              <Text>Items</Text>
              <Text fontSize="xs" color="gray.500">
                {`Use fields to add extra information about the Receipt.`}
              </Text>
            </>
          ) : null}
        </Box>
      </HStack>
      {value.map((label, index) => (
        <Grid
          gap={4}
          maxWidth="95%"
          justifyContent="center"
          alignItems="center"
          key={index}
        >
          <HStack pt={4}>
            <Box w="95%" backgroundColor={bgC} rounded="md" padding={2}>
              <Input
                id={`itemName${index}`}
                className="itemName"
                placeholder="Item Name"
                value={label.itemName}
                onChange={(element) => {
                  onChangeText(element.target.value, index, "itemName");
                }}
                isInvalid={label.itemName === "" && index !== value.length - 1}
              />
              {label.itemName === "" && index !== value.length - 1 && (
                <Text fontSize="sm" textColor={errorTextColor}>
                  Item Name is a required field
                </Text>
              )}
              <HStack pt={4}>
                <VStack alignItems="flex-start">
                  <FormLabel fontSize="xs" color={bgC} mb={0.5}>
                    Quantity
                  </FormLabel>
                  <Input
                    id={`quantity${index}`}
                    type="number"
                    className="quantity"
                    placeholder="Quantity"
                    value={label.quantity}
                    min={0}
                    onChange={(element) => {
                      onChangeText(element.target.value, index, "quantity");
                    }}
                    isInvalid={
                      label.quantity === 0 && index !== value.length - 1
                    }
                  />
                  {label.quantity === 0 && index !== value.length - 1 && (
                    <Text fontSize="sm" textColor={errorTextColor}>
                      Quantity is a required field
                    </Text>
                  )}
                </VStack>
                <VStack alignItems="flex-start">
                  <FormLabel fontSize="xs" color="gray.500" mb={0.5}>
                    Unit Cost
                  </FormLabel>
                  <Input
                    id={`unitCost${index}`}
                    type="number"
                    className="unitCost"
                    placeholder="Unit Cost"
                    value={label.unitCost}
                    min={0}
                    step="any"
                    onChange={(element) => {
                      onChangeText(element.target.value, index, "unitCost");
                    }}
                    isInvalid={
                      label.unitCost === 0 && index !== value.length - 1
                    }
                  />
                  {label.unitCost === 0 && index !== value.length - 1 && (
                    <Text fontSize="sm" textColor={errorTextColor}>
                      Cost is a required field
                    </Text>
                  )}
                </VStack>
              </HStack>
            </Box>
            <Box w="5%">
              <IconButton
                backgroundColor={bgC}
                variant="ghost"
                aria-label="Delete label"
                icon={<IoTrashOutline fontSize={20} />}
                color="grey.500"
                onClick={() => handleDeleteLabel(index)}
              />
            </Box>
          </HStack>
        </Grid>
      ))}
    </Box>
  );
};
