import { ApiQuestion, ApiVenueSummary } from "@operations-hero/lib-api-client";

export interface VenueQuestionResponseReducerValues {
  question: ApiQuestion;
  venue: ApiVenueSummary;
  value?: string | string[] | boolean | number | null;
  questionResponseId?: string;
}

export interface VenueQuestionResponseReducerAction
  extends Partial<VenueQuestionResponseReducerValues> {
  index: number;
}

export const venueQuestionsResponsesReducer = (
  values: VenueQuestionResponseReducerValues[],
  action: VenueQuestionResponseReducerAction
) => {
  const newValues = [...values];
  const newValue = { ...values[action.index], ...action };
  newValues[action.index] = newValue;
  return newValues;
};
