import {
  ApiClient,
  ApiTimelineEntryObjectType,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export const DEFAULT_TIMELINE = {
  loading: "idle",
  page: 1,
  total: 0,
  pageSize: 10,
  data: [],
};

export interface LoadRequestTimelineParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
}

export const loadRequestTimeline = createAsyncThunk(
  "request/timeline",
  async (params: LoadRequestTimelineParams, thunkAPI) => {
    const { apiClient, accountId, requestId } = params;

    const { pageSize, page } = (thunkAPI.getState() as RootState).requestForm
      .timeline;

    const response = await apiClient.findTimelineEntries(accountId, {
      objectType: ApiTimelineEntryObjectType.request,
      objectId: requestId,
      page,
      pageSize,
    });

    return response;
  }
);

export const requestTimelineHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadRequestTimeline.fulfilled, (state, action) => {
    const { data, options, total } = action.payload;
    state.timeline.loading = "succeeded";
    state.timeline.data = data;
    state.timeline.total = total;
    state.timeline.page = options.page || 1;
  });
  builder.addCase(loadRequestTimeline.rejected, (state) => {
    state.timeline.loading = "failed";
  });
  builder.addCase(loadRequestTimeline.pending, (state) => {
    state.timeline.loading = "pending";
  });
};
