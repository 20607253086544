import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import {
  Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { InfoOutlineIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAccountSettings,
  ApiRequest,
  ApiRequestStatus,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { RootState, useThunkDispatch } from "../../../store";
import { updateUserSettingToLocalCache } from "../../../store/local-cache.slice";
import {
  addToStatusTotal,
  setSource,
  setTarget,
  substractToStatusTotal,
  unloadChangeValues,
  updateRequestsByGroup,
} from "../../../store/request-column-view.slice";
import { unloadForm } from "../../../store/request-form/request-form.slice";
import { SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW } from "../../../utils/emailSettingUtils";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { BoardColumn } from "./BoardColumn";
import { BoardHeaders } from "./BoardHeaders";
import { ChangeStatusModal } from "./ChangeStatusModal";
import { ReOrderItems, revert } from "./ColumnView";
import { useColumnViewContext } from "./ColumnViewContext";
import { SkeletonColumn } from "./SkeletonColumn";

export type BoardProps = {};

export const Board: FC<BoardProps> = () => {
  const { apiClient, currentAccount } = useAuthentication();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const { columnViewStatuses } = useSelector(
    (state: RootState) => state.requestsSlice
  );
  const {
    loading,
    requestsByStatus,
    target,
    source,
    currentChangeType,
    transitionsByRequest,
    referencesByStatus,
  } = useSelector((root: RootState) => root.requestsColumnViewSlice);

  const {
    changeStatusValidation,
    initWorkingRequest,
    workingRequest,
    setSelectedRequest,
  } = useColumnViewContext();

  const { request, updateRequestStatus, isInit } = useSelector(
    (state: RootState) => state.requestForm
  );

  const userSettingsFromLocalCache = useSelector(
    (state: RootState) => state.localCache.userSettings
  );

  const [visibilityStatusColumn, setVisibilityStatusColumn] =
    useState<ApiRequestStatus>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isVisibilityModalOpen,
    onClose: onVisibilityModalClose,
    onOpen: onVisibilityModalOpen,
  } = useDisclosure();

  const {
    isOpen: isLoadingModalOpen,
    onClose: onLoadingModalClose,
    onOpen: onLoadingModalOpen,
  } = useDisclosure();

  const backgroundColor = useColorModeValue("gray.50", "gray.700");

  const ref = useRef<HTMLDivElement | null>(null);

  const notAllowedModalCancel = useCallback(() => {
    onClose();
    dispatch(unloadForm());
    dispatch(unloadChangeValues());
    setSelectedRequest(null);
  }, [dispatch, onClose, setSelectedRequest]);

  const requestsByStatusTotalItems = useMemo(() => {
    const total = columnViewStatuses.reduce<number>(
      (total, key) =>
        (total = total + (referencesByStatus[key]?.totalItems ?? 0)),
      0
    );
    return total;
  }, [referencesByStatus, columnViewStatuses]);

  const onChangeModalSave = useCallback(() => {
    if (source && target) {
      dispatch(substractToStatusTotal({ status: source.request.status }));
      dispatch(addToStatusTotal({ status: target.request.status }));
    }
    if (currentChangeType !== "lazy") {
      thunkDispatch(unloadForm());
      setSelectedRequest(null);
      dispatch(unloadChangeValues());
      return;
    }

    if (!target || !source) return;
    let targetColumn: ApiRequest[] = [
      ...(requestsByStatus[target.request.status] ?? []),
    ];

    let sourceColumn: ApiRequest[] = [
      ...(requestsByStatus[source.request.status] ?? []),
    ];

    const orderedItems = ReOrderItems(
      sourceColumn,
      targetColumn,
      {
        request: source.request,
        index: source.index,
        edge: null,
      },
      {
        request: target.request,
        index: 0,
        edge: null,
      },
      "lazy"
    );

    if (!orderedItems) return;
    thunkDispatch(
      updateRequestsByGroup({
        requestsMap: {
          ...requestsByStatus,
          [source.request.status]: orderedItems.orderedSource,
          [target.request.status]: orderedItems.orderedTarget,
        },
      })
    ).then(() => {
      dispatch(unloadChangeValues());
      thunkDispatch(unloadForm());
      setSelectedRequest(null);
    });
  }, [
    setSelectedRequest,
    thunkDispatch,
    currentChangeType,
    dispatch,
    requestsByStatus,
    source,
    target,
  ]);

  const onChangeModalCancel = useCallback(() => {
    if (!source || !target) return;
    if (currentChangeType === "lazy") {
      dispatch(unloadChangeValues());
      dispatch(unloadForm());
      setSelectedRequest(null);
      return;
    }

    const revertedColumns = revert(requestsByStatus, source, target);
    if (!revertedColumns) return;

    thunkDispatch(
      updateRequestsByGroup({
        requestsMap: {
          ...requestsByStatus,
          [source.request.status]: revertedColumns.orderedSource,
          [target.request.status]: revertedColumns.orderedTarget,
        },
      })
    ).then(() => {
      dispatch(unloadChangeValues());
      dispatch(unloadForm());
      setSelectedRequest(null);
    });
  }, [
    dispatch,
    requestsByStatus,
    source,
    target,
    thunkDispatch,
    currentChangeType,
    setSelectedRequest,
  ]);

  const isColumnDisabled = useCallback(
    (status: ApiRequestStatus) => {
      if (!transitionsByRequest || !workingRequest) return false;
      const isDisabled =
        !!transitionsByRequest[workingRequest.id][status] === false;
      const isSourceColumn = workingRequest.status === status;

      return isDisabled && !isSourceColumn && currentChangeType === "dnd";
    },
    [workingRequest, transitionsByRequest, currentChangeType]
  );

  const update = useCallback(
    (currentAccountId: string, setting: ApiAccountSettings) => {
      return apiClient.updateCurrentUserSettings(currentAccountId, setting);
    },
    [apiClient]
  );

  const updateVisibility = useCallback(() => {
    if (!visibilityStatusColumn) return;
    const visibilitySettings = {
      ...(userSettingsFromLocalCache[
        SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
      ] as {
        [key in ApiRequestStatus]?: boolean;
      }),
    };
    visibilitySettings[visibilityStatusColumn] = false;
    return update(currentAccount.id, {
      [SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW]:
        visibilitySettings,
    }).then((saved) => {
      dispatch(
        updateUserSettingToLocalCache({
          key: SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
          value: saved[
            SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
          ] as {
            [key in ApiRequestStatus]?: boolean;
          },
        })
      );

      onVisibilityModalClose();
    });
  }, [
    onVisibilityModalClose,
    userSettingsFromLocalCache,
    currentAccount,
    update,
    dispatch,
    visibilityStatusColumn,
  ]);

  const handleUpdateVisibilityStatus = useCallback(
    (status: ApiRequestStatus) => {
      setVisibilityStatusColumn(status);
      onVisibilityModalOpen();
    },
    [onVisibilityModalOpen]
  );

  useEffect(() => {
    return monitorForElements({
      onDrop: async (args) => {
        const { location, source } = args;
        if (location.current.dropTargets.length === 0) {
          return;
        }
        const type = location.current.dropTargets[0].data["dropElementType"];

        let requestTarget: ApiRequest = {} as ApiRequest;
        let requestSource: ApiRequest = {} as ApiRequest;
        let edge: Edge | null;

        if (type === "item") {
          requestTarget = location.current.dropTargets[0]
            .data as unknown as ApiRequest;
        }

        if (type === "column") {
          const status = location.current.dropTargets[0].data[
            "status"
          ] as ApiRequestStatus;
          requestTarget = { status } as ApiRequest;
        }

        edge = extractClosestEdge(location.current.dropTargets[0].data);
        requestSource = source.data as unknown as ApiRequest;

        if (requestSource.status === requestTarget.status) {
          dispatch(unloadChangeValues());
          dispatch(unloadForm());
          setSelectedRequest(null);
          return;
        }

        if (!isColumnDisabled(requestTarget.status)) {
          onLoadingModalOpen();
        }

        if (!requestSource.status || !requestTarget.status) return;

        if (
          transitionsByRequest &&
          workingRequest &&
          transitionsByRequest[workingRequest.id] &&
          !transitionsByRequest[workingRequest.id][requestTarget.status]
        ) {
          dispatch(
            setTarget({
              request: requestTarget,
              index: 0,
              edge,
            })
          );
          onOpen();
          return;
        }

        let targetIndex = 0;
        let sourceIndex = 0;

        let targetColumn: ApiRequest[] = [
          ...(requestsByStatus[requestTarget.status] ?? []),
        ];

        let sourceColumn: ApiRequest[] = [
          ...(requestsByStatus[requestSource.status] ?? []),
        ];

        sourceIndex = sourceColumn.findIndex(
          (request) => request.id === requestSource.id
        );

        targetIndex = targetColumn.findIndex(
          (item) => item.id === requestTarget.id
        );

        const orderedItems = ReOrderItems(
          sourceColumn,
          targetColumn,
          {
            request: requestSource,
            index: sourceIndex,
            edge: null,
          },
          {
            request: requestTarget,
            index: targetIndex,
            edge: edge,
          },
          "dnd"
        );

        if (!orderedItems) {
          dispatch(unloadChangeValues());
          dispatch(unloadForm());
          setSelectedRequest(null);
          return;
        }

        thunkDispatch(
          updateRequestsByGroup({
            requestsMap: {
              ...requestsByStatus,
              [requestSource.status]: orderedItems.orderedSource,
              [requestTarget.status]: orderedItems.orderedTarget,
            },
          })
        ).then(() => {
          dispatch(
            setSource({
              request: requestSource,
              index: sourceIndex,
              edge: null,
            })
          );

          dispatch(
            setTarget({
              request: requestTarget,
              index: targetIndex,
              edge,
            })
          );
        });
      },
      onDragStart: (args) => {
        const request: ApiRequest = {
          ...args.source.data,
        } as unknown as ApiRequest;
        setSelectedRequest(request);
      },
    });
  }, [
    isColumnDisabled,
    onOpen,
    dispatch,
    thunkDispatch,
    requestsByStatus,
    initWorkingRequest,
    changeStatusValidation,
    setSelectedRequest,
    transitionsByRequest,
    workingRequest,
    onLoadingModalOpen,
  ]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    return combine(
      autoScrollForElements({
        element,
      })
    );
  });

  useEffect(() => {
    if (isInit && isLoadingModalOpen && changeStatusValidation && target) {
      changeStatusValidation(target.request.status);
    }
  }, [isInit, isLoadingModalOpen, target, changeStatusValidation]);

  useEffect(() => {
    if (isLoadingModalOpen) {
      onLoadingModalClose();
    }
    // eslint-disable-next-line
  }, [updateRequestStatus, onLoadingModalClose]);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          flex: "1 1 auto",
          overflow: "auto",
          minH: "0px",
        }}
        backgroundColor={backgroundColor}
        px="2"
      >
        <BoardHeaders handleStatusVisibility={handleUpdateVisibilityStatus} />
        {loading === false && requestsByStatusTotalItems === 0 && (
          <HStack
            color="gray.500"
            fontSize="md"
            w="full"
            justify="center"
            my="5"
          >
            <InfoOutlineIcon />
            <Text fontStyle="italic">
              No requests found matching the criteria
            </Text>
          </HStack>
        )}
        <Grid
          width="full"
          templateColumns={`repeat(${
            Object.keys(ApiRequestStatus).length
          }, minmax(144px, 1fr))`}
          columnGap="3"
        >
          {loading &&
            columnViewStatuses.map((key) => {
              return <SkeletonColumn key={`skeleton::${key}`} />;
            })}
          {loading === false &&
            requestsByStatusTotalItems > 0 &&
            columnViewStatuses.map((statusKey) => {
              return (
                <BoardColumn
                  status={ApiRequestStatus[statusKey]}
                  key={statusKey}
                  isDisabled={isColumnDisabled(statusKey)}
                />
              );
            })}
        </Grid>
      </Box>
      {isOpen && (
        <Portal>
          <AccountModal
            isOpen={isOpen}
            onClose={notAllowedModalCancel}
            closeButton={false}
            isCentered={true}
            header={false}
            bodyProps={{
              mb: "unset",
              py: "6",
            }}
            content={
              <HStack gap="2">
                <WarningTwoIcon
                  w="8"
                  h="8"
                  color="orange"
                  alignSelf="baseline"
                />
                <VStack gap="4">
                  <VStack alignItems="left">
                    <Text fontSize="lg" fontWeight="bold">
                      Cannot move request
                    </Text>
                    <Text>
                      You are not allowed to move the request {request?.key} to
                      <Text as="span" casing="capitalize">
                        {` ${target?.request?.status.toLocaleUpperCase()} `}
                      </Text>
                      status due to a permission-related issue
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            }
            footer={
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  size="sm"
                  alignSelf="end"
                  onClick={notAllowedModalCancel}
                >
                  Got it
                </Button>
              </ModalFooter>
            }
          />
        </Portal>
      )}
      {!isLoadingModalOpen && (
        <Portal>
          <ChangeStatusModal
            onChangeModalSave={onChangeModalSave}
            onChangeModalCancel={onChangeModalCancel}
          />
        </Portal>
      )}
      {isLoadingModalOpen && target && (
        <Portal>
          <Modal
            isOpen={isLoadingModalOpen}
            onClose={onLoadingModalClose}
            isCentered={false}
          >
            <ModalOverlay />
            <ModalContent mt={0}>
              <ModalHeader h={65}>
                Change to <StatusBadge status={target?.request.status} />
              </ModalHeader>
              <ModalBody>
                <Stack gap="5">
                  <Stack>
                    <Skeleton height="20px" width="50%" />
                    <Skeleton height="40px" />
                    <Skeleton height="20px" width="50%" />
                    <Skeleton height="40px" />
                    <Skeleton height="20px" width="50%" />
                    <Skeleton height="40px" />
                    <Skeleton height="20px" width="50%" />
                    <Skeleton height="60px" />
                  </Stack>
                  <HStack justifyContent="space-between">
                    <Skeleton height="40px" w="30%" />
                    <Skeleton height="40px" w="30%" />
                  </HStack>
                </Stack>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
      )}
      {isVisibilityModalOpen && visibilityStatusColumn && (
        <Portal>
          <AccountModal
            isOpen={isVisibilityModalOpen}
            onClose={onVisibilityModalClose}
            closeButton={false}
            isCentered={true}
            header={false}
            bodyProps={{
              mb: "unset",
              py: "6",
            }}
            content={
              <HStack gap="2">
                <WarningIcon
                  w="8"
                  h="8"
                  color="blue.500"
                  alignSelf="baseline"
                />
                <VStack gap="4">
                  <VStack alignItems="left">
                    <Text fontSize="lg" fontWeight="bold">
                      Hide column in kanban view?
                    </Text>
                    <Text>
                      The {visibilityStatusColumn.toUpperCase()} status column
                      will be hidden and no longer be in the Column View.
                    </Text>
                    <Text>
                      Column visibility can be set in the{" "}
                      <Link color="blue.500" href="/user-settings">
                        User Profile page
                      </Link>
                      .
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            }
            footer={
              <ModalFooter>
                <HStack alignSelf="end">
                  <Button
                    colorScheme="gray"
                    size="sm"
                    alignSelf="end"
                    onClick={onVisibilityModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    alignSelf="end"
                    onClick={updateVisibility}
                  >
                    Hide Column
                  </Button>
                </HStack>
              </ModalFooter>
            }
          />
        </Portal>
      )}
    </>
  );
};
