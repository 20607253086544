import { ApiDay, ApiVenueSummary } from "@operations-hero/lib-api-client";
import { getDay } from "date-fns";
import { getHourAndMinutesFromString } from "../components/weekly-time-picker/WeeklyTimePicker";
import { getDayName } from "./getDayName";

export function getVenueTimes(
  selectedDate: Date | null,
  venue?: ApiVenueSummary | null
) {
  if (!venue || !selectedDate) return null;
  const dayOfWeekNumber = getDay(selectedDate);
  const dayName: ApiDay = getDayName(dayOfWeekNumber).toLowerCase() as ApiDay;
  const venueHour = venue.hours[dayName];
  const { newHour: venueStartHour, newMinutes: venueStartMinutes } =
    getHourAndMinutesFromString(venueHour.start);

  const { newHour: venueEndHour, newMinutes: venueEndMinutes } =
    getHourAndMinutesFromString(venueHour.end);

  return {
    venueStartHour: venueStartHour,
    venueStartMinutes: venueStartMinutes,
    venueEndHour: venueEndHour,
    venueEndMinutes: venueEndMinutes,
  };
}
