import { Box, Button, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FiAlertCircle, FiArrowLeft, FiArrowRight } from "react-icons/fi";

interface AICertaintyModalProps {
  displayUrl?: string | null;
  overallCertainty?: number | null;
  helperTips?: string;
  setStage: (stage: string) => void;
  setWorkingAttachments: (attachments: any[]) => void;
}

const AiConfidenceWarning: React.FC<AICertaintyModalProps> = ({
  displayUrl,
  overallCertainty,
  helperTips,
  setStage,
  setWorkingAttachments,
}) => {
  return (
    <Box>
      <Box width="full" display="flex" justifyContent="center">
        {displayUrl ? (
          <Image src={displayUrl} alt="Asset Picture" maxH="50vh" />
        ) : (
          <Text>Image not found</Text>
        )}
      </Box>

      <Box pt={1} pb={4}>
        <Flex w="100%" justifyContent="center">
          <VStack pt={1} spacing={4} align="center" mt={1}>
            <Icon as={FiAlertCircle} color="yellow.300" boxSize={8} />
            <Text fontSize="lg" fontWeight="bold">
              The AI is {overallCertainty}% confident.
            </Text>
            <Text>The AI Says: {helperTips}</Text>
            <Text>
              The AI recommends that you retake the photo. Do you want to
              proceed?
            </Text>
            <Flex w="100%" justifyContent="space-around">
              <Button
                colorScheme="blue"
                w="45%"
                leftIcon={<Icon as={FiArrowLeft} />}
                onClick={() => {
                  setStage("instructions");
                  setWorkingAttachments([]);
                }}
              >
                Retake
              </Button>
              <Button
                colorScheme="blue"
                w="45%"
                rightIcon={<Icon as={FiArrowRight} />}
                onClick={() => {
                  setStage("scanned");
                }}
              >
                Continue
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default AiConfidenceWarning;
