import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { Progressbar } from "./ProgressBar";
import { StepCircle } from "./StepCircle";

interface StepsButtonsProps {
  totalSteps: any[];
  activeStep: number;
  progressValue: number;
  getState: (index: number) => "active" | "complete" | "incomplete";
  handleSetActiveStep: (stepNumber: number) => () => void;
  saveCallback?: (currentStep: number) => void;
  disableStepButtons?: boolean;
}

export const StepsButtons: FC<StepsButtonsProps> = ({
  activeStep,
  totalSteps,
  progressValue,
  getState,
  saveCallback,
  handleSetActiveStep,
  disableStepButtons,
}) => {
  return (
    <Box mx="auto" maxW="sm" py="6" px={{ base: "2", md: "8" }}>
      <Box aria-label="Steps" position="relative">
        <Progressbar value={progressValue} />
        <Flex
          as="ol"
          align="center"
          listStyleType="none"
          justify="space-between"
        >
          {totalSteps.map((step, index) => {
            return step ? (
              <StepCircle
                activeStep={activeStep}
                key={index}
                state={getState(index)}
                onClick={handleSetActiveStep(index)}
                saveCallback={saveCallback}
                disableStepButtons={disableStepButtons}
              >
                {index + 1}
              </StepCircle>
            ) : null;
          })}
        </Flex>
      </Box>
    </Box>
  );
};
