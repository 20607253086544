import { Box, Flex, Icon, Text, TextProps } from "@chakra-ui/react";
import { ApiLocationSummary } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { LocationTypeIconMap } from "./LocationBadge";

export interface LocationTwoLineProps {
  value: ApiLocationSummary | string | null;
  boldLocation?: boolean;
  ancestorIsTruncated?: boolean;
  locationTextProps?: TextProps;
  ancestorTextProps?: TextProps;
  displayAncestors?: boolean;
  showNumberChildren?: boolean;
}

export const LocationTwoLine = ({
  value,
  boldLocation = true,
  ancestorIsTruncated = true,
  locationTextProps,
  ancestorTextProps,
  displayAncestors = true,
  showNumberChildren,
}: LocationTwoLineProps) => {
  const { locationMap, descendantsMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const currentLocation = useMemo(() => {
    if (typeof value === "string") {
      return locationMap[value];
    } else if (value === null) {
      return undefined;
    } else {
      return value;
    }
  }, [locationMap, value]);

  const ancestors = useMemo(() => {
    if (!currentLocation) return null;

    const parents = [];
    let location = currentLocation;

    while (location.parent != null) {
      const parent = locationMap[location.parent];
      parents.unshift(parent);
      location = parent;
    }

    return parents;
  }, [currentLocation, locationMap]);

  const numberChildren = useMemo(() => {
    if (!showNumberChildren || !currentLocation) return null;
    const allChildren = descendantsMap[currentLocation.id] || [];

    return allChildren.filter((child) => locationMap[child]?.active);
  }, [currentLocation, descendantsMap, locationMap, showNumberChildren]);

  return (
    <Box maxW="100%" overflow="hidden" minHeight="24px">
      {currentLocation && (
        <>
          <Flex alignItems={"center"}>
            <Icon
              as={LocationTypeIconMap[currentLocation.type]}
              mr={1}
              mt={0.25}
            />
            <Text
              isTruncated
              fontWeight={boldLocation ? "bold" : undefined}
              {...locationTextProps}
            >
              {currentLocation.name}
              {showNumberChildren &&
                numberChildren &&
                numberChildren.length - 1 > 0 &&
                `+${numberChildren.length - 1}`}
            </Text>
          </Flex>
          {ancestors && displayAncestors && (
            <Text
              isTruncated={ancestorIsTruncated}
              fontSize={"sm"}
              colorScheme={"gray"}
              {...ancestorTextProps}
            >
              {ancestors.map((l) => l.name).join(" > ")}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};
