import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CountryValues {
  name: string;
  isoCode: string;
}

const LOCALE_DATA: { [key: string]: string } = {
  US: "en-US",
  CA: "en-CA",
};

export interface GlobalSliceState {
  language: string;
  locale: string;
  country: CountryValues | null;
}

export const schemaSlice = createSlice({
  name: "global",
  initialState: {
    // ignoring some legacy IE properties which are not defined on the navigator type
    language:
      (navigator.languages || [])[0] ||
      navigator.language ||
      // @ts-ignore
      navigator.userLanguage ||
      // @ts-ignore
      navigator.browserLanguage ||
      "en",
    country: null,
    locale: LOCALE_DATA["US"],
  } as GlobalSliceState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setCountry: (state, action: PayloadAction<CountryValues | null>) => {
      state.country = action.payload;

      if (action.payload === null) {
        state.locale = LOCALE_DATA["US"];
      }

      if (action.payload !== null) {
        state.locale = LOCALE_DATA[action.payload.isoCode];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLanguage, setCountry } = schemaSlice.actions;

export default schemaSlice.reducer;
