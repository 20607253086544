import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiInventoryItem,
  ApiInventoryRequestItemStatus,
  ApiInventoryType,
  ApiLocationSummary,
  ApiRequest,
  ApiUserSummary,
  CreateApiInventoryRequest,
  CreateApiInventoryRequestItem,
  InventoryRequestSummary,
} from "@operations-hero/lib-api-client";
import { EditorState } from "draft-js";
import { Form, Formik, FormikHelpers } from "formik";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../components/form-helpers/BudgetAutocompleteControl";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { LocationAutocompleteControl } from "../../../components/form-helpers/LocationAutocompleteControl";
import { MultiUserAutocompleteControl } from "../../../components/form-helpers/MultiUserAutoCompleteControl";
import { RequestAutocompleteControl } from "../../../components/form-helpers/RequestAutocompleteControl";
import { RichTextEditorComments } from "../../../components/form-helpers/rich-text-editor/RichTextEditorComments";
import { UserAutocompleteControl } from "../../../components/form-helpers/UserAutocompleteControl";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { useShowToast } from "../../../hooks/showToast";
import { useConvertMentions } from "../../../hooks/useConvertMentions";
import { RootState, useThunkDispatch } from "../../../store";
import {
  addRequestItemToCart,
  cleanRequestItemsCart,
  InventoryItem,
  loadInventoryItems,
  setInventoryItemFilters,
  setItemQuantity,
  unloadInventoryItemsList,
} from "../../../store/inventory/inventory-item-list.slice";
import { setTotalInventoryChange } from "../../../store/request-form/request-form.slice";
import { Products } from "../../account-settings/location-list/LocationList";
import { convertToSave } from "../../request-form/comments/Comment";
import { getInventoryRequestFormSchema } from "./InventoryRequestFormSchema";

interface CreateInventoryRequestValues
  extends Omit<
    CreateApiInventoryRequest,
    | "items"
    | "requestId"
    | "mentioned"
    | "deliveryLocation"
    | "totalItems"
    | "eSign"
  > {
  requestId: ApiRequest | null;
  deliverTo: ApiUserSummary[];
  requester: ApiUserSummary;
  deliverDate: string | null;
  deliveryLocation: ApiLocationSummary | null;
}

interface CreateInventoryRequestFormProps {
  onCancel: () => void;
  request?: ApiRequest;
  onSubmit?: () => void;
  inventoryRequest?: InventoryRequestSummary;
}

export const CreateInventoryRequestForm: FC<
  CreateInventoryRequestFormProps
> = ({ onCancel, request, onSubmit, inventoryRequest }) => {
  const { currentUser, apiClient, currentAccount } = useAuthentication();
  const { locationMap } = useSelector((state: RootState) => state.localCache);
  const {
    data: dataInventoryItems,
    cartRequestItems,
    filters,
  } = useSelector((state: RootState) => state.inventoryItemsListSlice);
  const [notes, setNotes] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [emptyItems, setEmptyItems] = useState(false);
  const color = useColorModeValue("red.500", "red.300");
  const itemsBgColor = useColorModeValue("white", "transparent");

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const showToast = useShowToast();

  const isBudgetRequired = useMemo(() => {
    return cartRequestItems.items.some(
      (item) => item.item.catalog.requireBudgetForInventoryRequests
    );
  }, [cartRequestItems.items]);

  const validationSchema = useMemo(
    () => getInventoryRequestFormSchema(isBudgetRequired),
    [isBudgetRequired]
  );

  const handleOnChangeQuantity = useCallback(
    (item: InventoryItem, valueAsString: string) => {
      const valueAsNumber = parseFloat(valueAsString);
      if (valueAsString.trim() === "") {
        dispatch(setItemQuantity({ id: item.id, quantity: 0 }));
      }
      if (!isNaN(valueAsNumber)) {
        dispatch(
          setItemQuantity({
            id: item.id,
            quantity:
              item.type === ApiInventoryType.checkout
                ? 1
                : parseFloat(valueAsNumber.toFixed(2)),
          })
        );
      }
    },
    [dispatch]
  );

  const handleOnAddRequestItem = useCallback(
    (item: InventoryItem) => {
      const itemCopy: ApiInventoryItem = { ...item };
      if ("image" in itemCopy) {
        itemCopy.image = null;
      }

      const newItem: CreateApiInventoryRequestItem & {
        image: string | null;
        status: ApiInventoryRequestItemStatus;
      } = {
        item: itemCopy,
        quantity:
          item.type === ApiInventoryType.checkout ? 1 : item.quantity || 0,
        unitOfMeasure: item.units,
        image: item.image,
        status: ApiInventoryRequestItemStatus.approved,
        issueQuantity: item.quantity || 0,
        itemStorage: null,
        cost: 0,
      };

      dispatch(addRequestItemToCart(newItem));
    },
    [dispatch]
  );

  const itemsList = useMemo(() => {
    const newItems: InventoryItem[] = dataInventoryItems
      .filter(
        (item) => !cartRequestItems.items.some((i) => i.item.id === item.id)
      )
      .map((i) => ({ ...i, quantity: i.quantity }));
    const res = (
      <Box pb={4}>
        <Heading size="sm" pb={2}>
          Choose the items to add
        </Heading>
        <AccountSearchBox
          searchPlaceholder="Search for an item"
          onInputChange={(search) =>
            dispatch(setInventoryItemFilters({ search }))
          }
        />
        {newItems.map((item) => {
          const isCheckout = item.type === ApiInventoryType.checkout;
          const isBulkCheckout = item.type === ApiInventoryType.bulkCheckout;
          return (
            <Stack
              py={1}
              flexDir="row"
              p={2}
              border="1px solid"
              borderRadius={6}
              key={`item-request:"${item.id}`}
              borderColor="transparent"
            >
              <Flex w={["100%", "100%", "25%"]} alignItems="center" gap={2}>
                <Image
                  boxSize="40px"
                  src={item.image ? item.image : undefined}
                />
                <Flex flexDir="column" justifyContent="center">
                  <Text fontWeight="bold">{item.name}</Text>
                  {item.identifiers.externalId && (
                    <Text>#{item.identifiers.externalId}</Text>
                  )}
                </Flex>
              </Flex>

              <Flex flex={1}>
                <Flex
                  w="100%"
                  gap={2}
                  flexDir="row"
                  justifyContent={[
                    "space-between",
                    "space-between",
                    "flex-end",
                  ]}
                >
                  <NumberInput
                    w="85px"
                    justifyContent={[
                      "space-between",
                      "space-between",
                      "flex-end",
                    ]}
                    h="40px"
                    isDisabled={isCheckout}
                    borderRadius="6px"
                    min={0}
                    step={1}
                    precision={isBulkCheckout ? 0 : 2}
                    value={isCheckout ? 1 : item.quantity}
                    onChange={(e) => handleOnChangeQuantity(item, e)}
                    bg={itemsBgColor}
                  >
                    <NumberInputField pl={0} textAlign="center" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Button
                    h="32px"
                    p="10px 12px"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                    borderColor="blue.600"
                    border="1px solid"
                    borderRadius="4px"
                    size="sm"
                    colorScheme="blue"
                    onClick={() => handleOnAddRequestItem(item)}
                    isDisabled={!item.quantity && !isCheckout}
                  >
                    Add
                  </Button>
                </Flex>
              </Flex>
            </Stack>
          );
        })}
      </Box>
    );

    return res;
  }, [
    cartRequestItems.items,
    dataInventoryItems,
    handleOnAddRequestItem,
    handleOnChangeQuantity,
    dispatch,
    itemsBgColor,
  ]);

  const initialValues: CreateInventoryRequestValues = useMemo(() => {
    const locationId = localStorage.getItem(
      `${currentAccount.id}-request-location`
    );
    //userSettings[SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS]
    const deliveryLocation =
      locationId && locationMap[locationId] && locationMap[locationId].active
        ? locationMap[locationId]
        : null;
    return {
      requestId: request ? request : null,
      note: inventoryRequest ? inventoryRequest.note : "",
      deliverTo: inventoryRequest
        ? (inventoryRequest.deliverTo as ApiUserSummary[])
        : [currentUser as ApiUserSummary],
      requester: inventoryRequest ? inventoryRequest.requester : currentUser,
      deliverDate: inventoryRequest ? inventoryRequest.deliverDate : null,
      deliveryLocation: inventoryRequest
        ? (inventoryRequest.deliveryLocation as ApiLocationSummary)
        : deliveryLocation,
      fulfillmentReason: null,
      fulfillmentMessage: null,
      deliveryNotes: null,
      budget: inventoryRequest ? inventoryRequest.budget : null,
    };
  }, [currentUser, currentAccount.id, locationMap, request, inventoryRequest]);

  const { editorState: notesEditorState } = useConvertMentions({
    value: "",
    mentioned: undefined,
  });

  const handleSummaryBlur = useCallback(
    (summary: EditorState) => {
      const response = convertToSave(summary);
      if (!response || response === notes) return;
      setNotes(response);
    },
    [notes]
  );

  const mapInventoryRequest = useCallback(
    (values: CreateInventoryRequestValues) => {
      const deliveryLocationId =
        typeof values.deliveryLocation === "string"
          ? values.deliveryLocation
          : values.deliveryLocation?.id;
      const deliveryLocation = deliveryLocationId
        ? locationMap[deliveryLocationId]
        : null;

      return {
        note: notes,
        requester: values.requester,
        requestId: values.requestId?.id || null,
        deliverTo: values.deliverTo,
        deliverDate: values.deliverDate,
        deliveryLocation,
        items: cartRequestItems.items.map((i) => ({ ...i, item: i.item.id })),
        fulfillmentReason: null,
        fulfillmentMessage: null,
        budget: values.budget,
        eSign: null,
        deliveryNotes: null,
      } as CreateApiInventoryRequest;
    },
    [cartRequestItems.items, notes, locationMap]
  );

  const handleOnSubmit = useCallback(
    (
      values: CreateInventoryRequestValues,
      formikHelpers: FormikHelpers<CreateInventoryRequestValues>
    ) => {
      if (cartRequestItems.items.length < 1) {
        setEmptyItems(true);
        return;
      }
      setIsLoading(true);
      const newRequest = mapInventoryRequest(values);

      if (inventoryRequest) {
        apiClient
          .updateInventoryRequest(
            currentAccount.id,
            inventoryRequest.id,
            newRequest
          )
          .then((res) => {
            if (res.deliveryLocation) {
              const locationId =
                typeof res.deliveryLocation === "string"
                  ? res.deliveryLocation
                  : res.deliveryLocation.id;
              localStorage.setItem(
                `${currentAccount.id}-request-location`,
                locationId
              );
            }
            dispatch(cleanRequestItemsCart());
            formikHelpers.resetForm();
            showToast("success", "Inventory Request was updated successfully");
            onCancel();
            onSubmit && onSubmit();
          })
          .catch(() => {
            showToast(
              "error",
              "something went wrong updateing Inventory Request, please try again"
            );
          });
        setIsLoading(false);
        return;
      }

      apiClient
        .createInventoryRequest(currentAccount.id, newRequest)
        .then((resArray) => {
          if (resArray.length > 0 && resArray[0].deliveryLocation) {
            const locationId =
              typeof resArray[0].deliveryLocation === "string"
                ? resArray[0].deliveryLocation
                : resArray[0].deliveryLocation.id;
            localStorage.setItem(
              `${currentAccount.id}-request-location`,
              locationId
            );
          }

          dispatch(cleanRequestItemsCart());
          formikHelpers.resetForm();
          showToast("success", "Inventory Request was created successfully");
          request && dispatch(setTotalInventoryChange(1));
          onCancel();
          onSubmit && onSubmit();
        })
        .catch(() => {
          showToast(
            "error",
            "something went wrong creating Inventory Request, please try again"
          );
        });
      setIsLoading(false);
    },
    [
      apiClient,
      currentAccount.id,
      dispatch,
      mapInventoryRequest,
      onCancel,
      showToast,
      onSubmit,
      inventoryRequest,
      cartRequestItems.items.length,
      request,
    ]
  );

  useEffect(() => {
    if (!request?.id) return;
    thunkDispatch(
      loadInventoryItems({
        apiClient,
        accountId: currentAccount.id,
        isRequest: true,
        filters: { hasLocations: true },
      })
    );
  }, [
    apiClient,
    currentAccount.id,
    thunkDispatch,
    filters.search,
    request?.id,
  ]);

  useEffect(() => {
    if (!request?.id) return;
    dispatch(unloadInventoryItemsList());
  }, [dispatch, request?.id]);
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => {
          return (
            <Form>
              <Grid minW="100%" gap={6} templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={12}>
                  {itemsList && request && itemsList}
                  <Heading fontSize="lg" mb={4}>
                    Review Requested Items
                  </Heading>
                  {emptyItems && cartRequestItems.items.length < 1 && (
                    <Text fontSize="xs" color={color}>
                      You Need to select at least one item
                    </Text>
                  )}
                  <Flex flexDir="column" alignItems="center" gap={6}>
                    {cartRequestItems.items.map((cartItem) => (
                      <Flex
                        flexDir="row"
                        w="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                      >
                        <Flex w="42%" alignItems="center">
                          <Image
                            boxSize="40px"
                            src={cartItem.image ? cartItem.image : undefined}
                            alt="item picture"
                          />
                          <Flex flexDir="column" justifyContent="center">
                            <Text fontWeight="bold">{cartItem.item.name}</Text>
                            {cartItem.item.identifiers.externalId && (
                              <Text>
                                #{cartItem.item.identifiers.externalId}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                        <Flex
                          w="29%"
                          flexDir="column"
                          alignItems="center"
                          flexShrink={0}
                        >
                          <Text>Units</Text>
                          <Text fontWeight="bold">
                            {cartItem.item.units.name}
                          </Text>
                        </Flex>
                        <Flex
                          w="29%"
                          flexDir="column"
                          alignItems="center"
                          flexShrink={0}
                        >
                          <Text>Quantity</Text>
                          <Text fontWeight="bold">{cartItem.quantity}</Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </GridItem>

                <GridItem colSpan={12}>
                  <Divider />
                </GridItem>

                <GridItem colSpan={12}>
                  <UserAutocompleteControl
                    value={values.requester}
                    label="Requested By"
                    name="requester"
                    labelProps={{ fontWeight: "bold", fontSize: "lg" }}
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <LocationAutocompleteControl
                    value={values.deliveryLocation}
                    name="deliveryLocation"
                    label="Delivery Location"
                    productName={Products.InventoryHQ}
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <RequestAutocompleteControl
                    value={values.requestId}
                    name="requestId"
                    label="Work Order/Request Number"
                    labelProps={{ fontWeight: "bold", fontSize: "lg" }}
                    allowEmpty
                    isDisabled={request ? true : false}
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <MultiUserAutocompleteControl
                    value={values.deliverTo}
                    label="Deliver To"
                    name="deliverTo"
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <DatePickerControl
                    value={values.deliverDate}
                    label="Deliver Date"
                    name="deliverDate"
                    labelProps={{ fontWeight: "bold", fontSize: "lg" }}
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <BudgetAutocompleteControl
                    value={values.budget}
                    label="Budget"
                    name="budget"
                    allowEmpty
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <RichTextEditorComments
                    label="Add a message (optional)"
                    value={notesEditorState}
                    onBlur={handleSummaryBlur}
                    id="request-inventory-items-note"
                    labelProps={{ fontWeight: "bold", fontSize: "lg" }}
                  />
                </GridItem>

                <GridItem
                  colSpan={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    size="sm"
                    variant="outline"
                    colorScheme="blue"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    colorScheme="blue"
                    isLoading={isloading}
                  >
                    Submit Request
                  </Button>
                </GridItem>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
