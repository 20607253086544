import {
  Badge,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { GroupBadge } from "../../../components/badges/GroupBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { RootState, useThunkDispatch } from "../../../store";
import {
  initPeopleFilter,
  loadPeopleFilterOptions,
  PersonFilterReferenceValue,
  PersonFilterValue,
} from "../../../store/schedule-request-list.slice";
import PersonList from "./PersonList";

export interface PersonFilterProps {
  value: PersonFilterReferenceValue[];
  onChange: (value: PersonFilterReferenceValue[]) => void;
  filterInitialized: boolean;
}

const ContentRenderer = ({
  props,
  state,
}: SelectRenderer<PersonFilterValue>) => {
  return (
    <Box p={1}>
      {state.values.length === 0 && "Person"}
      {state.values[0] && state.values.length === 1 && (
        <>
          {state.values[0].groupOrUser.type === "group" && (
            <GroupBadge value={state.values[0].groupOrUser.assignee} />
          )}
          {state.values[0].groupOrUser.type === "user" && (
            <UserBadge value={state.values[0].groupOrUser.assignee} />
          )}
        </>
      )}
      {state.values.length > 1 && (
        <>
          Persons <AdditionalNumberBadge number={state.values.length} />
        </>
      )}
    </Box>
  );
};
const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<PersonFilterValue>) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();

  const assignee = useMemo(
    () => state.values.filter((x) => x.type === "assignee"),
    [state.values]
  );

  const handleTypeChange = useCallback(
    (index: number) => {
      thunkDispatch(
        loadPeopleFilterOptions({
          apiClient,
          account,
          type: "assignee",
        })
      );
    },
    [thunkDispatch, apiClient, account]
  );

  return (
    <Box>
      <Tabs onChange={handleTypeChange}>
        <TabList>
          <Tab>
            Assigned to{" "}
            {assignee.length > 0 && (
              <Badge
                colorScheme="blue"
                size="xs"
                borderRadius="xl"
                ml={2}
                p={1}
              >
                {assignee.length}
              </Badge>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PersonList values={assignee} methods={methods} type={"assignee"} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export const PersonFilter = ({
  value,
  onChange,
  filterInitialized,
}: PersonFilterProps) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const userFilterOptions = useSelector(
    (state: RootState) => state.scheduleRequestList.availablePersons
  );
  const { persons: selectedValues } = useSelector(
    (state: RootState) => state.scheduleRequestList.filters
  );
  const themeClass = useColorModeValue("light-theme", "dark-theme");
  //const [hydratedValues, setHydatedValues] = useState<PersonFilterValue[]>([]);

  const initOnce = useRef(false);
  const [filterInitCompleted, setFilterInitCompleted] = useState(false);

  useEffect(() => {
    // wait for scheduled list init to finish
    if (!filterInitialized || initOnce.current) {
      return;
    }
    initOnce.current = true;
    const promise =
      !selectedValues || selectedValues.length === 0
        ? Promise.resolve()
        : thunkDispatch(
            initPeopleFilter({
              apiClient,
              account,
              selectedValues,
            })
          );
    promise.then(() => setFilterInitCompleted(true));
  }, [apiClient, account, thunkDispatch, filterInitialized, selectedValues]);

  useEffect(() => {
    if (!filterInitCompleted) {
      return;
    }
    thunkDispatch(
      loadPeopleFilterOptions({
        apiClient,
        account,
        type: "assignee",
      })
    );
  }, [apiClient, account, thunkDispatch, filterInitCompleted]);

  return (
    <Box>
      <Select
        multi={true}
        className={themeClass}
        options={userFilterOptions}
        values={value as PersonFilterValue[]}
        onChange={onChange}
        searchable={true}
        valueField="key"
        dropdownGap={0}
        keepSelectedInList
        contentRenderer={ContentRenderer}
        dropdownRenderer={DropdownRenderer}
        dropdownHeight="300"
        backspaceDelete={false}
      />
    </Box>
  );
};
