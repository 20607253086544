import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { OutlinedIconButton } from "../../../components/custom-icons/OutlinedIconButton";
import { RootState } from "../../../store";
import {
  markRequestsForDeletion,
  NonCompliantRequest,
  setBulkEditStep,
  updateBulkSelection,
} from "../../../store/request-form/request-bulk-actions.slice";

interface NonCompliantRequestsModalProps {}
export const NonCompliantRequestsModal: FC<
  NonCompliantRequestsModalProps
> = () => {
  const dispatch = useDispatch();
  const {
    nonCompliantRequestsByField,
    totalSelected,
    selectionChanged,
    bulkEditStep,
  } = useSelector((store: RootState) => store.requestsBulkActionsSlice);
  const qtyColor = useColorModeValue("blackAlpha.700", "white");
  const fieldNameColor = useColorModeValue("gray.700", "gray.200");
  const requestNameColor = useColorModeValue("gray.600", "white");
  const markedForDeletionColor = useColorModeValue("gray.300", "gray.600");
  const deleteIconColor = useColorModeValue("yellow.500", "orange");
  const markedForDeletionIconColor = useColorModeValue(
    "orange.100",
    "yellow.500"
  );

  const handleDeleteRequest = useCallback(
    (request: NonCompliantRequest) => {
      dispatch(markRequestsForDeletion({ requestToMarkForDeletion: request }));
    },
    [dispatch]
  );

  const handleChangeSelection = useCallback(() => {
    dispatch(updateBulkSelection());
  }, [dispatch]);

  const handleGoBack = useCallback(() => {
    dispatch(setBulkEditStep("edit-form"));
  }, [dispatch]);

  const handleCancelSelection = useCallback(() => {
    dispatch(setBulkEditStep("edit-form"));
  }, [dispatch]);

  return (
    <>
      <Box
        style={{
          visibility:
            bulkEditStep === "change-selection" ? "visible" : "hidden",
          height: bulkEditStep === "change-selection" ? "auto" : 0,
        }}
      >
        <ModalHeader>
          <Text>
            Bulk Edits
            <Text
              as="span"
              color={qtyColor}
              fontSize="medium"
            >{` (${totalSelected})`}</Text>
          </Text>
          <Text fontSize="xs" color="gray.500" fontWeight="normal" mb="4">
            {
              "Requests with more than one required field are displayed with a ("
            }
            <Text as="span" color="yellow.500">
              {"+"}
            </Text>
            <Text as="span">{")"}</Text>
          </Text>
          <Divider />
        </ModalHeader>
        <ModalBody pt="0.5">
          {nonCompliantRequestsByField.map((entry) => (
            <Box key={`non-compliant-item::${entry[0].key}`}>
              <HStack fontSize="sm" mb="4">
                <Text color="gray.500" fontWeight="semibold">
                  Required custom field:
                </Text>
                <Text color={fieldNameColor} fontWeight="bold">
                  {entry[0].name}
                </Text>
              </HStack>
              {entry[1].map((request) => (
                <HStack
                  px="6"
                  alignContent="center"
                  h="7"
                  key={`non-compliant-item::${request.key}`}
                >
                  {request.isDuplicated ? (
                    <Text
                      w="3"
                      color={
                        request.isMarkedForDeletion
                          ? markedForDeletionIconColor
                          : deleteIconColor
                      }
                      fontWeight="bold"
                      fontSize="large"
                    >
                      +
                    </Text>
                  ) : (
                    <Box w="3"></Box>
                  )}
                  <Text
                    color={
                      request.isMarkedForDeletion
                        ? markedForDeletionColor
                        : requestNameColor
                    }
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    {request.key}
                  </Text>
                  <Spacer />
                  {!request.isMarkedForDeletion && (
                    <OutlinedIconButton
                      icon={<Icon as={BsTrash} color="gray.500" />}
                      onClick={() => handleDeleteRequest(request)}
                    ></OutlinedIconButton>
                  )}
                </HStack>
              ))}
            </Box>
          ))}
        </ModalBody>
        <ModalFooter>
          {selectionChanged ? (
            <>
              <Button colorScheme="blue" mr={3} onClick={handleCancelSelection}>
                Cancel
              </Button>
              <Button variant="ghost" onClick={handleChangeSelection}>
                Change selection
              </Button>
            </>
          ) : (
            <Button colorScheme="blue" mr={3} onClick={handleGoBack}>
              Back
            </Button>
          )}
        </ModalFooter>
      </Box>
    </>
  );
};
