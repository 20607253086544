import {
  Box,
  Button,
  Divider,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiLaborTransaction,
  ApiProject,
  ApiRequest,
  ApiRequestStatus,
  ApiTransactionType,
  ApiWorkflowFieldDataType,
  LaborType,
} from "@operations-hero/lib-api-client";
import {
  isRequestSystemFieldKey,
  isRequestSystemSectionKey,
  RequestSystemFieldKey,
  RequestSystemFieldMap,
  RequestSystemSectionKey,
} from "@operations-hero/lib-rule-engine";
import { Form, Formik } from "formik";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HiOutlineSparkles } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { StatusBadge } from "../../components/badges/StatusBadge";
import { AssigneeAutocompleteControl } from "../../components/form-helpers/AssigneeAutocompleteControl";
import { CustomFieldInputControl } from "../../components/form-helpers/CustomFieldInputControl";
import { DatePickerControl } from "../../components/form-helpers/DatePickerControl";
import FocusError from "../../components/form-helpers/FocusError";
import { ProjectAutocompleteControl } from "../../components/form-helpers/ProjectAutocompleteControl";
import { ReasonAutocompleteControl } from "../../components/form-helpers/ReasonAutocompleteControl";
import { ReportingCategoryAutocompleteControl } from "../../components/form-helpers/ReportingCategoryAutocompleteControl";
import { RequestPrioritySelectControl } from "../../components/form-helpers/RequestPrioritySelectControl";
import { RootState } from "../../store";
import { CommentForm } from "./comments/CommentForm";
import {
  Laborer,
  LaborTransactionForm,
} from "./transactions/labors/LaborTransactionForm";

export interface ChangeModalProps {
  isOpen: boolean;
  onSave: (params: {
    delta: Partial<ApiRequest>;
    comment?: ApiComment;
    project?: ApiProject | null;
  }) => void;
  onCancel: () => void;
  isSaving?: boolean;
  modalProps?: Partial<ModalProps>;
}

type ShouldDisplayField = { [key in RequestSystemFieldKey]?: boolean };
type ShouldDisplaySection = { [key in RequestSystemSectionKey]?: boolean };

export const ChangeModal: FC<ChangeModalProps> = ({
  isOpen: showModal,
  onSave,
  onCancel,
  isSaving = false,
  modalProps,
}: ChangeModalProps): JSX.Element | null => {
  const { currentUser } = useAuthentication();
  const { isOpen, onClose } = useDisclosure({ isOpen: showModal });
  const ref = useRef(null);
  const navigate = useNavigate();

  const workflow = useSelector(
    (state: RootState) => state.requestForm.workflow
  );

  const { authType } = useSelector((state: RootState) => state.auth);
  const {
    request,
    isOneClickAction,
    visibleFields,
    isApproverOnly,
    commentsTotal,
    laborTotal,
  } = useSelector((state: RootState) => state.requestForm);

  const { changeVerification, delta, requiredFields } = useSelector(
    (state: RootState) => state.requestForm.changeModal
  );

  const errorColor = useColorModeValue("red.500", "red.300");

  const [newComment, setNewComment] = useState<ApiComment>();
  const [newLaborTransaction, setNewLaborTransaction] =
    useState<ApiLaborTransaction>();

  const isUnassigned = useMemo(() => {
    const requestHasAssignees =
      request && request.assignees && request.assignees.length > 0;
    const deltaHasAssignees =
      delta && delta.assignees && delta.assignees.length > 0;

    return !(requestHasAssignees || deltaHasAssignees);
  }, [request, delta]);

  const showCompletionDate = useMemo(
    () =>
      delta &&
      delta.status != null &&
      [
        ApiRequestStatus.review,
        ApiRequestStatus.canceled,
        ApiRequestStatus.closed,
      ].includes(delta.status),
    [delta]
  );

  const [isCloseTransition, isReviewTransition, isQueueTransition]: boolean[] =
    useMemo(
      () => [
        !!delta && !!delta.status && delta.status === ApiRequestStatus.closed,
        !!delta && !!delta.status && delta.status === ApiRequestStatus.review,
        !!delta && !!delta.status && delta.status === ApiRequestStatus.queued,
      ],
      [delta]
    );

  const [requiredCustomFields, requiredSystemSections] = useMemo(() => {
    if (!changeVerification || changeVerification.requiredFields.length === 0)
      return [[], []];

    const requiredCustomFields = changeVerification.requiredFields.filter(
      (field) => field.dataType !== ApiWorkflowFieldDataType.system
    );

    const requiredSystemSections = changeVerification.requiredFields.filter(
      (field) => isRequestSystemSectionKey(field.key)
    );
    return [requiredCustomFields, requiredSystemSections];
  }, [changeVerification]);

  const displayField: ShouldDisplayField = useMemo(() => {
    const initialCondition: ShouldDisplayField = {
      "SYSTEM-CATEGORY": isCloseTransition || isQueueTransition,
      "SYSTEM-REASON": isCloseTransition || isQueueTransition,
      "SYSTEM-ASSIGN-TO":
        (isUnassigned || isQueueTransition) && !isApproverOnly,
      "SYSTEM-START-DATE": isQueueTransition,
      "SYSTEM-DUE-DATE": isQueueTransition,
      "SYSTEM-PROJECT": false,
    };

    const conditionsMet = Object.keys(
      initialCondition
    ).reduce<ShouldDisplayField>((map, key) => {
      const fieldKey = key as RequestSystemFieldKey;
      const isFieldVisible = visibleFields.some(
        (field) => field.key === fieldKey
      );
      const isFieldRequired =
        changeVerification?.requiredFields.some(
          (field) => field.key === fieldKey
        ) === true;
      map[fieldKey] = (map[fieldKey] && isFieldVisible) || isFieldRequired;
      return map;
    }, initialCondition);

    return conditionsMet;
  }, [
    isCloseTransition,
    isQueueTransition,
    isUnassigned,
    isApproverOnly,
    changeVerification,
    visibleFields,
  ]);

  const displaySection: ShouldDisplaySection = useMemo(() => {
    const isTransition = !!delta && !!delta.status === true;

    const initialCondition: ShouldDisplaySection = {
      "SYSTEM-COMMENTS": true,
      "SYSTEM-LABOR": isTransition && (isCloseTransition || isReviewTransition),
    };

    const conditionsMet = Object.keys(
      initialCondition
    ).reduce<ShouldDisplaySection>((map, key) => {
      const fieldKey = key as RequestSystemSectionKey;
      const isSectionVisible = visibleFields.some(
        (field) => field.key === fieldKey
      );
      const isSectionRequired =
        requiredSystemSections.some((field) => field.key === fieldKey) === true;

      map[fieldKey] =
        (map[fieldKey] && isSectionVisible) ||
        (isSectionRequired && isTransition);
      return map;
    }, initialCondition);

    return conditionsMet;
  }, [
    delta,
    isCloseTransition,
    isReviewTransition,
    visibleFields,
    requiredSystemSections,
  ]);

  const [requiredCustomFieldsRules, requiredSystemFieldsRules] = useMemo(() => {
    if (!requiredFields || requiredFields.length === 0) return [[], []];

    const requiredCustomFields = requiredFields.filter(
      (field) => field.dataType !== ApiWorkflowFieldDataType.system
    );

    const requiredSystemFields = requiredFields.filter((field) =>
      isRequestSystemFieldKey(field.key)
    );

    return [requiredCustomFields, requiredSystemFields];
  }, [requiredFields]);

  const initialValues = useMemo(() => {
    if (!request || !delta || !changeVerification) {
      return {} as Partial<ApiRequest>;
    }

    const scheduling = { ...request.scheduling, ...delta.scheduling };

    const workingDelta = {
      ...delta,
    };

    if (requiredCustomFieldsRules.length > 0) {
      workingDelta.metadata = { ...workingDelta.metadata, ...request.metadata };
    }

    if (isQueueTransition) {
      workingDelta.priority = delta.priority || request.priority;
    }
    if (displayField["SYSTEM-START-DATE"] || displayField["SYSTEM-DUE-DATE"]) {
      workingDelta.scheduling = workingDelta.scheduling || scheduling;
    }

    if (displayField["SYSTEM-CATEGORY"]) {
      workingDelta.reportingCategory =
        delta.reportingCategory || request.reportingCategory;
    }
    if (displayField["SYSTEM-REASON"]) {
      workingDelta.reason = delta.reason || request.reason;
    }

    if (displayField["SYSTEM-ASSIGN-TO"]) {
      workingDelta.assignees = request.assignees || delta.assignees || null;
    }

    if (displayField["SYSTEM-PROJECT"]) {
      workingDelta.projectId = delta.projectId || request.projectId;
    }

    if (showCompletionDate) {
      const schedulingInfo = workingDelta.scheduling
        ? { ...workingDelta.scheduling }
        : { ...scheduling };

      workingDelta.scheduling = {
        ...schedulingInfo,
        completed:
          workingDelta.scheduling?.completed ||
          request.scheduling?.completed ||
          new Date().toISOString(),
      };
    }
    return workingDelta;
  }, [
    displayField,
    requiredCustomFieldsRules,
    delta,
    request,
    showCompletionDate,
    isQueueTransition,
    changeVerification,
  ]);

  const validationSchema = useMemo(() => {
    const customFieldsShape = requiredCustomFieldsRules.reduce<
      Record<string, any>
    >((result, field) => {
      result[field.key] = (
        field.dataType === ApiWorkflowFieldDataType.checkbox
          ? yup.boolean()
          : field.dataType === ApiWorkflowFieldDataType.date
            ? yup.date()
            : field.dataType === ApiWorkflowFieldDataType.location
              ? yup.object()
              : field.dataType === ApiWorkflowFieldDataType.number
                ? yup.number()
                : field.dataType === ApiWorkflowFieldDataType.selection
                  ? yup.mixed()
                  : field.dataType === ApiWorkflowFieldDataType.user
                    ? yup.object()
                    : yup.string()
      )
        .nullable()
        .required(`${field.name} is required`);

      return result;
    }, {});

    const scheduling: Record<string, any> = {};

    const systemFieldsShape = requiredSystemFieldsRules.reduce<
      Record<string, any>
    >((result, field) => {
      if (!isRequestSystemFieldKey(field.key)) return result;
      if (
        field.key === "SYSTEM-START-DATE" &&
        displayField["SYSTEM-START-DATE"]
      ) {
        scheduling.start = yup
          .string()
          .nullable()
          .required("Start date is a required field");
        result["scheduling"] = yup.object().shape({
          ...scheduling,
        });
        return result;
      }

      if (field.key === "SYSTEM-DUE-DATE" && displayField["SYSTEM-DUE-DATE"]) {
        scheduling.due = yup
          .string()
          .nullable()
          .required("Due date is a required field");

        result["scheduling"] = yup.object().shape({
          ...scheduling,
        });
        return result;
      }
      if (field.key === "SYSTEM-PROJECT" && displayField["SYSTEM-PROJECT"]) {
        result["projectId"] = yup
          .object()
          .nullable()
          .required("Project is required");
        return result;
      }

      result[RequestSystemFieldMap[field.key as RequestSystemFieldKey]] =
        field.key === "SYSTEM-CATEGORY" && displayField["SYSTEM-CATEGORY"]
          ? yup.object().nullable().required(`Category is required`)
          : field.key === "SYSTEM-ASSIGN-TO" && displayField["SYSTEM-ASSIGN-TO"]
            ? yup.array().min(1, "At least one assignee is required")
            : field.key === "SYSTEM-REASON" && displayField["SYSTEM-REASON"]
              ? yup.object().nullable().required(`Reason is required`)
              : yup.string();

      return result;
    }, {});

    const schema = yup.object().shape({
      metadata: yup.object().shape(customFieldsShape),
      ...systemFieldsShape,
    });

    return schema;
  }, [requiredCustomFieldsRules, displayField, requiredSystemFieldsRules]);

  const { showCommentsError, showLaborError } = useMemo(() => {
    return {
      showCommentsError:
        commentsTotal === 0 &&
        requiredSystemSections.find(
          (field) => field.key === "SYSTEM-COMMENTS"
        ) &&
        !!delta &&
        !!delta.status === true &&
        (!newComment || (newComment && newComment.comment === "")),
      showLaborError:
        laborTotal === 0 &&
        requiredSystemSections.find((field) => field.key === "SYSTEM-LABOR") &&
        !isApproverOnly &&
        newLaborTransaction &&
        (!newLaborTransaction ||
          (newLaborTransaction && newLaborTransaction.hours === 0)),
    };
  }, [
    commentsTotal,
    laborTotal,
    isApproverOnly,
    newComment,
    newLaborTransaction,
    requiredSystemSections,
    delta,
  ]);

  const handleCloseOneClickAction = useCallback(async () => {
    if (authType === "magicToken" && request) {
      navigate(`/requests/${request.key}`, { replace: true });
    }
  }, [authType, navigate, request]);

  const handleFormikSubmit = useCallback(
    (values: Partial<ApiRequest>) => {
      if (showCommentsError || showLaborError) return;

      const result: {
        delta: Partial<ApiRequest>;
        comment?: ApiComment;
        laborTransaction?: ApiLaborTransaction;
        project?: ApiProject | null;
      } = {
        delta: values,
      };

      if (
        "projectId" in result.delta &&
        typeof result.delta.projectId !== "string" &&
        result.delta.projectId
      ) {
        result.project = { ...(result.delta.projectId as ApiProject) };
        result.delta.projectId = result.delta.projectId["id"];
      }

      if (
        newComment &&
        newComment.comment &&
        newComment.comment.trim().length > 0
      ) {
        result.comment = newComment;
      }

      if (newLaborTransaction && newLaborTransaction.hours > 0) {
        result.laborTransaction = newLaborTransaction;
      }

      onSave(result);
      isOneClickAction && handleCloseOneClickAction();
    },
    [
      newComment,
      newLaborTransaction,
      onSave,
      isOneClickAction,
      handleCloseOneClickAction,
      showCommentsError,
      showLaborError,
    ]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleLaborChange = useCallback((transaction: ApiLaborTransaction) => {
    setNewLaborTransaction(transaction);
  }, []);

  const getAssigneeAsLaborer: Laborer = useMemo(() => {
    if (!request?.assignees || request.assignees.length === 0)
      return currentUser;

    if (request.assignees[0].type === "user")
      return request.assignees[0].assignee;
    else {
      const groupAssignee: Laborer = {
        id: request.assignees[0].assignee.id,
        firstName: request.assignees[0].assignee.name,
        lastName: "",
        email: "",
        phone: "",
        profileImage: request.assignees[0].assignee.logo ?? "",
        timeZone: "",
        isGroup: true,
      };
      return groupAssignee;
    }
  }, [request, currentUser]);

  useEffect(() => {
    const now = new Date().toISOString();

    setNewComment({
      id: "",
      createdBy: currentUser,
      comment: "",
      isPublic: true,
      created: now,
      updated: now,
      updatedBy: null,
      mentioned: [],
    });
    setNewLaborTransaction({
      id: "",
      created: now,
      createdBy: currentUser,
      datePerformed: now,
      hours: 0,
      laborer: isCloseTransition ? getAssigneeAsLaborer : currentUser,
      type: ApiTransactionType.labor,
      laborType: LaborType.regular,
      updated: now,
      updatedBy: currentUser,
      attachmentCount: 0,
      budget: null,
      hourlyRate: 0,
      total: 0,
      requestId: request?.id || "",
      requestKey: request?.key || "",
    });
  }, [currentUser, request, getAssigneeAsLaborer, isCloseTransition]);

  const canShowLaborers = useMemo(() => {
    if (!delta) return false;
    return (
      delta.status === ApiRequestStatus.review ||
      delta.status === ApiRequestStatus.closed
    );
  }, [delta]);
  /**
   * IsClosing
   * =========
   * Completion Date (may already be filled in from Technician, so don't change it if it's already filled in) ✔
   * Category (may need to update the Category Field) ✔
   * Reason (may need to populate this for reporting) ✔
   *
   * IsReview
   * ============
   * Completion Date ✔
   *
   * Queue / Started
   * ================
   * Assignee (already there) ✔
   * Priority
   * Start Date
   * Due Date
   * Category ✔
   * Reason ✔
   */

  return (
    workflow &&
    changeVerification &&
    delta &&
    request && (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        {...modalProps}
      >
        <ModalOverlay />
        <ModalContent mt={0}>
          <Formik
            onSubmit={handleFormikSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={ref}
            validateOnChange={true}
          >
            {(props) => (
              <Form>
                <ModalHeader h={65}>
                  {delta.status != null ? (
                    <>
                      Change to <StatusBadge status={delta.status} />
                    </>
                  ) : (
                    "A little more data is needed"
                  )}
                </ModalHeader>
                <ModalCloseButton onClick={handleCancel} />
                <ModalBody overflowY="auto">
                  <Stack divider={<Divider />}>
                    <Stack spacing={3}>
                      {displayField["SYSTEM-ASSIGN-TO"] && (
                        <AssigneeAutocompleteControl
                          name="assignees"
                          value={props.values.assignees || []}
                          workflow={request.workflow}
                          location={request.location!}
                          reportingCategory={
                            request.reportingCategory ?? undefined
                          }
                          label="Assignees"
                        />
                      )}

                      {displayField["SYSTEM-PROJECT"] && (
                        <ProjectAutocompleteControl
                          label="Project"
                          name="projectId"
                          //@ts-ignore
                          value={props.values.projectId}
                        />
                      )}

                      {showCompletionDate && (
                        <DatePickerControl
                          label="Completion Date"
                          value={props.values.scheduling!.completed}
                          name="scheduling.completed"
                          showTime={true}
                        />
                      )}

                      {isQueueTransition && (
                        <RequestPrioritySelectControl
                          label="Priority"
                          value={props.values.priority!}
                          name="priority"
                        />
                      )}

                      {displayField["SYSTEM-START-DATE"] && (
                        <DatePickerControl
                          label="Start"
                          value={props.values.scheduling!.start}
                          name="scheduling.start"
                          showTime={true}
                          hasError={
                            typeof props.errors.scheduling === "object" &&
                            props.errors.scheduling["start"]
                          }
                        />
                      )}

                      {displayField["SYSTEM-DUE-DATE"] && (
                        <DatePickerControl
                          label="Due"
                          value={props.values.scheduling!.due}
                          name="scheduling.due"
                          showTime={true}
                          hasError={
                            typeof props.errors.scheduling === "object" &&
                            props.errors.scheduling["due"]
                          }
                        />
                      )}

                      {displayField["SYSTEM-CATEGORY"] && (
                        <ReportingCategoryAutocompleteControl
                          workflow={workflow}
                          label={
                            <>
                              Category
                              {request.reportingCategory == null &&
                                request.categoryPredictions &&
                                request.categoryPredictions.length > 0 && (
                                  <Tooltip label="Category has AI suggestions available">
                                    <Box display="inline-block">
                                      <Icon
                                        as={HiOutlineSparkles}
                                        ml={2}
                                        boxSize="1.1em"
                                        className="ai-sparkle-animation"
                                      />
                                    </Box>
                                  </Tooltip>
                                )}
                            </>
                          }
                          name="reportingCategory"
                          predictedCategories={
                            request.categoryPredictions || []
                          }
                          value={props.values.reportingCategory || null}
                        />
                      )}

                      {displayField["SYSTEM-REASON"] && (
                        <ReasonAutocompleteControl
                          workflow={workflow}
                          label="Reason"
                          name="reason"
                          // @ts-ignore
                          value={props.values.reason}
                        />
                      )}
                      {requiredCustomFields.length > 0 && (
                        <>
                          {requiredCustomFields.map((field) => {
                            return (
                              <Box key={`rf-${field.id}`} pb={[4, null, 4]}>
                                <CustomFieldInputControl
                                  value={
                                    (props.values?.metadata &&
                                      props.values.metadata[field.key]) ||
                                    null
                                  }
                                  name={`metadata.${field.key}`}
                                  field={field}
                                />
                              </Box>
                            );
                          })}
                        </>
                      )}
                    </Stack>

                    {displaySection["SYSTEM-LABOR"] && newLaborTransaction && (
                      <Box>
                        <LaborTransactionForm
                          transaction={newLaborTransaction}
                          onChange={handleLaborChange}
                          showDatePerformed={false}
                          showLaborer={canShowLaborers}
                          displayLeadGroups
                        />
                        {showLaborError && (
                          <Text
                            color={errorColor}
                            fontSize="sm"
                            textAlign="right"
                          >
                            Labor is required
                          </Text>
                        )}
                      </Box>
                    )}

                    {displaySection["SYSTEM-COMMENTS"] && (
                      <Stack spacing={2}>
                        <Heading fontSize="lg">Add a comment</Heading>
                        <CommentForm
                          comment={newComment}
                          autoFocusComment={false}
                          commentFrom="request"
                          onSave={() => {}}
                          setCurrentComment={setNewComment}
                        />
                        {showCommentsError && (
                          <Text
                            color={errorColor}
                            fontSize="sm"
                            textAlign="right"
                          >
                            Comments are required
                          </Text>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </ModalBody>
                <ModalFooter justifyContent="space-between" h={65}>
                  <Button variant="solid" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="blue"
                    isLoading={isSaving}
                  >
                    Save
                  </Button>
                </ModalFooter>
                <FocusError />
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    )
  );
};
