import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { WizardStepProps } from "./FirstRunWizard";

export const FirstRunWizardWorkflow = ({ onNext }: WizardStepProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [workflowName, setWorkflowName] = useState("");
  const [workflowKey, setWorkflowKey] = useState("");
  const [keyIsTouched, setKeyIsTouched] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [workflowDescription, setWorkflowDescription] = useState("");

  const handleClick = async () => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    const schema = await apiClient.createWorkflowSchema(currentAccount.id, {
      name: `Default Schema`,
    });
    const workflow = await apiClient.createWorkflow(currentAccount.id, {
      name: workflowName,
      requestSlug: workflowKey,
      allowUsersToSubmitRequests: true,
      schema: schema.id,
      description: workflowDescription,
    });

    onNext(workflow);
  };

  useEffect(() => {
    const keyRegExp = new RegExp("^[a-zA-Z][a-zA-Z0-9]{1,3}$");
    const workflowKeyIsValid = keyRegExp.test(workflowKey);
    const workflowNameIsValid = /[\d\w\s]{2,25}/.test(workflowName);
    setFormIsValid(workflowKeyIsValid && workflowNameIsValid);
  }, [workflowName, workflowKey]);

  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkflowName(e.target.value);
    if (!keyIsTouched) {
      setWorkflowKey(
        e.target.value.replace(/\s/g, "").substr(0, 4).toUpperCase().trim()
      );
    }
  };

  const handleOnChangeKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = e.target.value.trim().toUpperCase();
    setWorkflowKey(cleaned);
    setKeyIsTouched(cleaned.length > 0);
  };

  const handleShowMore = useCallback(() => {
    setShowInfo(!showInfo);
  }, [showInfo]);

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWorkflowDescription(e.target.value);
    },
    []
  );

  return (
    <Stack shouldWrapChildren>
      <Text>Different Heros, Different Needs</Text>
      <Text>
        Workflows represent segments of requests, with access controls,
        customization, and more.
      </Text>
      {showInfo && (
        <Box>
          <Text size="xs">
            <Text fontWeight="bold" as="span" display="inline">
              If you have a few heros
            </Text>{" "}
            who operate in a few locations, we suggest naming it after your
            department.
          </Text>
          <Text size="xs" mt={4}>
            <Text fontWeight="bold" as="span" display="inline">
              In larger organizations,
            </Text>{" "}
            we suggest naming it after organizational units, such as campus,
            district, zone.
          </Text>
        </Box>
      )}
      <Box mb={4}>
        <Link onClick={handleShowMore}>{showInfo ? "Less" : "More"} info</Link>
      </Box>

      <FormControl id="first-run-wizard-wf-name" mb={4}>
        <FormLabel>What would you like to name your first workflow?</FormLabel>
        <Input
          value={workflowName}
          onChange={handleOnChangeName}
          placeholder="Ideas: Maintenance, Custodial, Zone-1"
          maxLength={25}
          autoComplete="off"
        />
      </FormControl>

      <FormControl id="first-run-wizard-wf-key" mb={4}>
        <FormLabel>Request Prefix</FormLabel>
        <Input
          value={workflowKey}
          onChange={handleOnChangeKey}
          placeholder="Ideas: WO, CUST, ZN1"
          maxLength={4}
          autoComplete="off"
        />
        <FormHelperText>
          <Text pb={2}>2 character min, 4 character max</Text>
          <Text>
            Used as part of request numbers, Example request numbers are WO-100,
            CUST-710, ZN1-1337
          </Text>
        </FormHelperText>
      </FormControl>

      <FormControl mb={4}>
        <FormLabel>Description (Optional)</FormLabel>
        <Input
          value={workflowDescription}
          onChange={handleDescriptionChange}
          placeholder="Add a description..."
          autoComplete="off"
        />
      </FormControl>

      <Button isDisabled={!formIsValid} onClick={handleClick} size="sm">
        Let's go
      </Button>
    </Stack>
  );
};
