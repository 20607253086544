import { Editor } from "@operations-hero/react-draft-wysiwyg";
import "@operations-hero/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../auth/AuthProvider";
import "./rich-text-editor-styles.css";

export interface ReadOnlyTextEditorProps {
  value: EditorState;
  editorMaxHeight?: string;
}

interface EditorSuggestion {
  text: string;
  value: string;
  url: string;
}

export const ReadOnlyTextEditorComments: React.FC<ReadOnlyTextEditorProps> = ({
  value,
  editorMaxHeight,
}) => {
  const wrapperStyle = {
    border: "none",
  };

  const editorStyle = {
    maxHeight: editorMaxHeight || "110px",
  };

  const { currentUser } = useAuthentication();

  const [users, setSugggestions] = useState<EditorSuggestion[]>([]);

  const loadUsers = useCallback(() => {
    const currentCount = value.getCurrentContent();
    const rowObject = convertToRaw(currentCount);

    const suggestions: EditorSuggestion[] = [
      {
        text: currentUser.firstName,
        value: `${currentUser.firstName} ${currentUser.lastName}`,
        url: currentUser.id,
      },
    ];

    // unsure how this triggers, but prevents an app crash.
    if (!rowObject || !rowObject.entityMap) {
      setSugggestions(suggestions);
      return;
    }

    Object.values(rowObject.entityMap).forEach((mention) => {
      const parts = mention?.data?.value?.split(".");
      if (!parts || parts.length < 2) {
        return;
      }

      const [id, firstName, lastName] = [mention.data.url, parts[0], parts[1]];

      const objSuggestion = {
        text: firstName,
        value: `${firstName}.${lastName}`,
        url: id,
      };
      suggestions.push(objSuggestion);
    });

    setSugggestions(suggestions);
  }, [currentUser, value]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <Editor
      readOnly={true}
      toolbarHidden={true}
      editorState={value}
      wrapperStyle={wrapperStyle}
      editorStyle={editorStyle}
      mention={{
        separator: " ",
        trigger: "@",
        suggestions: users,
      }}
    />
  );
};
