import { Button, Flex, StackDivider, Text, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledDatePicker } from "../../../components/inputs/StyledDatePicker";
import { RootState } from "../../../store";
import { FC, useState } from "react";
import {
  AbsoluteDateFilter,
  updateTransactionFilters,
} from "../../../store/transaction-list.slice";

const DATE_FORMAT = "yyyy-MM-dd";

export const MenuCalendar: FC = () => {
  const [toDate, setToDate] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { transactionList } = useSelector((state: RootState) => state);
  const { dateField, filters, isCustomDate } = transactionList;
  const { date } = filters;

  const handleDateAbsoluteChange = useCallback(
    (value: string[] | null[]) => {
      if (dateField) {
        const absoluteDate: AbsoluteDateFilter = {
          field: dateField,
          type: "absolute",
          value: value,
        };
        dispatch(updateTransactionFilters({ date: absoluteDate }));
      }
      if (date) {
        const absoluteDate: AbsoluteDateFilter = {
          field: date.field,
          type: "absolute",
          value: value,
        };
        dispatch(updateTransactionFilters({ date: absoluteDate }));
      }
    },
    [dateField, date, dispatch]
  );

  const handleSetDateArray = useCallback(() => {
    const dateArray: string[] | null[] = [];
    dateArray[0] = fromDate ? format(new Date(fromDate), DATE_FORMAT) : "";
    dateArray[1] = toDate ? format(new Date(toDate), DATE_FORMAT) : "";
    handleDateAbsoluteChange(dateArray!);
  }, [fromDate, toDate, handleDateAbsoluteChange]);

  const handleDateFromChange = useCallback((value: Date | null) => {
    const date = !value ? null : value;
    const dateString = !value ? null : value.toISOString();
    const formatedDate = date !== null ? format(date, "yyyy-MM-dd") : null;
    if (formatedDate !== null && dateString !== null) {
      setFromDate(dateString);
    }
  }, []);

  const handleDateToChange = useCallback((value: Date | null) => {
    const date = !value ? null : value;
    const dateString = !value ? null : value.toISOString();
    const formatedDate = date !== null ? format(date, "yyyy-MM-dd") : null;
    if (formatedDate !== null && dateString !== null) {
      setToDate(dateString);
    }
  }, []);

  const handleCancel = useCallback(() => {
    setToDate(null);
    setFromDate(null);
  }, []);

  useEffect(() => {
    if (date && date.type === "absolute") {
      const [initDate, endDate] = date.value;
      if (initDate != null && endDate != null) {
        const formatedInitDate = initDate.replaceAll("-", "/");
        const formatedEndDate = endDate.replaceAll("-", "/");
        setFromDate(formatedInitDate);
        setToDate(formatedEndDate);
      }
    }
  }, [date]);

  return (
    <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4}>
      <Flex px="3" alignItems="center">
        <StyledDatePicker
          value={fromDate}
          onChange={handleDateFromChange}
          isDisabled={!isCustomDate}
          name="from-date-filter"
        />
        <Text mx={2} color={!isCustomDate ? "gray.400" : ""}>
          {" "}
          To{" "}
        </Text>
        <StyledDatePicker
          value={toDate}
          onChange={handleDateToChange}
          isDisabled={!isCustomDate}
          name="to-date-filter"
        />
      </Flex>
      <Flex width={"full"} justifyContent="space-evenly">
        <Button
          isDisabled={!isCustomDate}
          onClick={handleCancel}
          variant="ghost"
        >
          Cancel
        </Button>
        <Button
          isDisabled={!isCustomDate}
          onClick={handleSetDateArray}
          colorScheme="blue"
        >
          Apply
        </Button>
      </Flex>
    </VStack>
  );
};
