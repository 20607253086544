import { Badge, Box, Image, Tooltip } from "@chakra-ui/react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export default function LogoSmall() {
  const mobileLogo = "/logo192.png";

  const showDevEnvironmentWarning = useMemo(
    () => process.env.REACT_APP_NODE_ENV !== "production",
    []
  );

  return (
    <Box>
      <Link to="/">
        <Box position="relative">
          <Image src={mobileLogo} alt="Operations Hero" maxH="40px" />
          {showDevEnvironmentWarning && (
            <Tooltip label="Currently connected to a testing environment">
              <Badge
                position="absolute"
                top={"35%"}
                w={"100px"}
                left="50%"
                marginLeft="-50px"
                colorScheme="red"
                textAlign="center"
                transform="rotate(-26deg)"
              >
                Test Env
              </Badge>
            </Tooltip>
          )}
        </Box>
      </Link>
    </Box>
  );
}
