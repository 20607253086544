import { Box, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { BsPlusCircle } from "react-icons/bs";

export interface SubFormFooterProps {
  color: string;
  title: string;
  addSubFormFunction: () => void;
  isEnabled: boolean;
}

export const SubFormFooter: React.FC<SubFormFooterProps> = ({
  color,
  title,
  addSubFormFunction,
  isEnabled,
}) => {
  return isEnabled ? (
    <Box
      my={8}
      p={4}
      w="100%"
      cursor="pointer"
      borderRadius={4}
      display="inline-block"
      border="0.15rem"
      borderColor={color}
      borderStyle="dashed"
      onClick={addSubFormFunction}
    >
      <Icon
        boxSize={6}
        display="inline-block"
        as={BsPlusCircle}
        color={color}
        mr={4}
      />
      <Text
        fontSize="xl"
        display="inline-block"
        verticalAlign="5px"
        fontWeight="500"
      >
        {title}
      </Text>
    </Box>
  ) : null;
};
