import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../../components/badges/AdditionalNumberBadge";

export interface FundingSourceContactFilterProps {
  value: string | string[];
  onChange?: (value: string[]) => void;
  showSelectAll?: boolean;
  isDisabled?: boolean;
}

type ValueLabel = {
  value: string;
  label: string;
};

const DropdownRenderer = ({
  props,
  state,
  methods,
  showSelectAll,
}: SelectRenderer<ValueLabel> & { showSelectAll?: boolean }) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["value"], {
        sort: true,
      }),
    [props.options]
  );

  const items = useMemo(() => {
    const results = fuzzySearch.search(state.search);
    const selected = state.values;
    selected.filter((sel) =>
      results.find((res) => res.value === sel.value) ? null : results.push(sel)
    );
    return !state.search ? props.options : results;
  }, [state.search, state.values, props.options, fuzzySearch]);

  const bgColor = useColorModeValue(undefined, "gray.700");
  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Box>Search and select:</Box>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Users"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.value}
              isChecked={
                methods.isSelected(item) ||
                state.values.some((sel) => sel.value === item.value)
              }
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.value}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

const ContentRenderer = ({ props, state }: SelectRenderer<ValueLabel>) => {
  return (
    <Box p={1} flexDir="row" w="fit-content" alignItems="center" display="flex">
      {state.values.length === 0 && "Contact"}
      {state.values.length === 1 && <Text>{state.values[0].value}</Text>}
      {state.values.length > 1 && (
        <Box alignItems="center" display="flex" gap={2}>
          <Text>{state.values[0].value}</Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </Box>
      )}
    </Box>
  );
};

export const ContactsFilter = ({
  value,
  onChange,
  showSelectAll,
  isDisabled = false,
}: FundingSourceContactFilterProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const [options, setOptions] = useState<ValueLabel[]>([]);
  const [search, setSearch] = useState<string>("");

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ValueLabel>) => {
      if (!state.search) {
        setSearch("");
      }
      setSearch(state.search);

      return props.options;
    },
    []
  );

  const hydratedValues = useMemo(() => {
    return value && value.length > 0
      ? options.filter((x) => value.includes(x.value))
      : [];
  }, [options, value]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      if (!onChange) return;
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange]
  );

  useEffect(() => {
    apiClient
      .findFundingSourceContacts(currentAccount.id, {
        pageSize: 50,
        search,
      })
      .then((res) => {
        const loadOptions = res.data.map((contact) => ({
          value: contact.contactName,
          label: contact.contactName,
        }));
        setOptions(loadOptions);
      });
  }, [apiClient, currentAccount.id, search]);

  return (
    <Select
      key={"contact-filter-funding-source"}
      multi
      options={options}
      values={hydratedValues}
      className={themeClass}
      onChange={handleChange}
      searchable={true}
      searchBy="value"
      valueField="value"
      labelField="label"
      searchFn={searchFn}
      keepSelectedInList
      contentRenderer={(props) => ContentRenderer({ ...props })}
      dropdownRenderer={(props) => (
        <DropdownRenderer {...props} showSelectAll={showSelectAll} />
      )}
      disabled={isDisabled}
    />
  );
};
