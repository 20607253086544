import { ApiClient, ApiUserSummary } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface SaveRequestFollowersThunkParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  followers: ApiUserSummary[];
}

export const saveFollowers = createAsyncThunk(
  "requests/follow-by",
  async (params: SaveRequestFollowersThunkParams, thunkAPI) => {
    const { apiClient, accountId, requestId, followers } = params;

    return await apiClient.updateRequest(accountId, requestId, {
      followedBy: followers.map((f) => f.id),
    });
  }
);

export const saveRequestFollowersHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(saveFollowers.fulfilled, (state, action) => {
    if (state.request) {
      state.request.followedBy = action.payload.followedBy;
    }
  });
};
