import {
  Button,
  Icon,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiComment,
  ApiLocation,
  ApiRequest,
  ApiRequestTaskBookDetail,
  ApiUserSummary,
  ApiWorkflowField,
  ApiWorkflowFieldDataType,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsPrinter } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { PdfViewerModal } from "../../../components/pdf-viewer-modal/PdfViewerModal";
import { useShowToast } from "../../../hooks/showToast";
import { RootState } from "../../../store";
import { BulkPrintModal } from "./BulkPrintModal";
import { BulkPrintFormat } from "./documents/BulkPrintFormat";
import {
  BulkPrintPdfDocument,
  RequestComments,
} from "./documents/BulkPrintPdfDocument";
interface BulkPrintButtonProps {
  bulkMode?: "selection" | "all";
  isDisabled?: boolean;
}

export const BulkPrintButton: FC<BulkPrintButtonProps> = ({
  bulkMode = "all",
  isDisabled = false,
}) => {
  const { currentUser, currentAccount, apiClient, isProductAdmin } =
    useAuthentication();

  const { policyMap, workflowMap, locationMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const { requests: allRequests } = useSelector(
    (state: RootState) => state.requestList
  );
  const { requests: bulkRequests } = useSelector(
    (state: RootState) => state.requestsBulkActionsSlice
  );
  const [isLoading, setIsLoading] = useState(true);
  const [requestComments, setRequestComments] = useState<RequestComments[]>([]);
  const [requests, setRequests] = useState<ApiRequest[]>([]);
  const [assetsMap, setAssetsMap] = useState<Record<string, ApiAsset[]>>({});
  const [taskbooksMap, setTaskbooksMap] = useState<
    Record<string, ApiRequestTaskBookDetail[]>
  >({});

  const showToast = useShowToast();

  // workflow ID to rules engine;
  const rulesMap = useRef<Record<string, SchemaRulesEngine>>({});
  const visibleFieldMap = useRef<Record<string, ApiWorkflowField[]>>({});

  const {
    isOpen: bulkPrintModalIsOpen,
    onOpen: bulkPrintModalOnOpen,
    onClose: bulkPrintModalOnClose,
  } = useDisclosure();

  const {
    isOpen: pdfModalIsOpen,
    onOpen: pdfModalOnOpen,
    onClose: pdfModalOnClose,
  } = useDisclosure();

  const [selectedFormat, setSelectedFormat] = useState<BulkPrintFormat>();

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const buttonColor = useColorModeValue("white", "unset");

  const ancestors = useMemo(() => {
    if (!pdfModalIsOpen || !requests.length || !locationMap || isLoading) {
      return {} as Record<string, ApiLocation[]>;
    }
    const uniqueMap = requests.reduce<Record<string, ApiLocation[]>>(
      (result, req) => {
        if (!req.location || !req.location.active) return result;
        const location = locationMap[req.location.id];

        // safety check, this shouldn't happen but 🤷‍♂️
        if (!location) return result;

        const parents = location.treePath.split(".").map((x) => locationMap[x]);
        parents.splice(-2, 2); //remove the self reference in treePath and last empty value
        result[req.id] = parents;
        return result;
      },
      {}
    );
    return uniqueMap;
  }, [requests, locationMap, isLoading, pdfModalIsOpen]);

  const ancestorsAssets = useMemo(() => {
    return Object.values(assetsMap).reduce<Record<string, ApiLocation[]>>(
      (acc, assets) => {
        assets.forEach((asset) => {
          if (!asset.location || !locationMap)
            return {} as Record<string, ApiLocation[]>;
          const location = locationMap[asset.location.id];
          const parents = location.treePath
            .split(".")
            .map((x) => locationMap[x]);
          parents.splice(-2, 2);
          acc[asset.id] = parents;
        });
        return acc;
      },
      {}
    );
  }, [assetsMap, locationMap]);

  const pdfDocument = useMemo(() => {
    if (isLoading && !pdfModalIsOpen) return null;
    if (!selectedFormat) return null;
    return (
      <BulkPrintPdfDocument
        format={selectedFormat}
        requests={requestComments}
        ancestors={ancestors}
        visibleFieldMap={visibleFieldMap.current}
        account={currentAccount}
        workflowMap={workflowMap}
        assetsMap={assetsMap}
        taskbooksMap={taskbooksMap}
        ancestorsAssets={ancestorsAssets}
      />
    );
  }, [
    currentAccount,
    isLoading,
    pdfModalIsOpen,
    requestComments,
    selectedFormat,
    visibleFieldMap,
    ancestors,
    workflowMap,
    assetsMap,
    taskbooksMap,
    ancestorsAssets,
  ]);

  const closeOptions = useCallback(
    (format?: BulkPrintFormat) => {
      setSelectedFormat(format);
      bulkPrintModalOnClose();

      if (format) {
        pdfModalOnOpen();
      }
    },
    [bulkPrintModalOnClose, pdfModalOnOpen]
  );

  const downloadName = useCallback(
    () => `BulkPrint_${format(new Date(), "y-MM-dd")}.pdf`,
    []
  );

  const loadVisibleFields = useCallback(async () => {
    await requests.map(async (request) => {
      // create a map of each request to the visible fields
      // this should cache rules engines by schemas
      // then calc by requests
      let rulesEngine = rulesMap.current[request.workflow.id];
      if (rulesEngine) {
        const visibleFields = rulesEngine
          .getVisibleFields({
            request,
          })
          .filter(
            (field) =>
              field.dataType !== ApiWorkflowFieldDataType.system ||
              field.key === "SYSTEM-START-DATE" ||
              field.key === "SYSTEM-DUE-DATE"
          );
        visibleFieldMap.current[request.id] = visibleFields;
        return;
      }

      const workflow = workflowMap[request.workflow.id];
      const policy = policyMap[request.workflow.id];

      const fields = await apiClient.findWorkflowSchemaFields(
        currentAccount.id,
        workflow.schema.id,
        { pageSize: 100 }
      );

      rulesEngine = new SchemaRulesEngine({
        account: currentAccount,
        user: currentUser,
        form: "full",
        schemaFields: fields.data,
        workflow: workflow,
        policy: policy,
        isProductAdmin,
      });

      rulesMap.current[request.workflow.id] = rulesEngine;
      visibleFieldMap.current[request.id] = rulesEngine.getVisibleFields({
        request,
      });
    });
  }, [
    requests,
    apiClient,
    currentAccount,
    currentUser,
    isProductAdmin,
    workflowMap,
    policyMap,
    rulesMap,
    visibleFieldMap,
  ]);

  const getComments = useCallback(
    async (requestId: string) => {
      let response: ApiComment[] = [];
      await apiClient
        .findRequestComments(currentAccount.id, requestId)
        .then((res) => {
          response = res.data;
        });
      return response;
    },
    [apiClient, currentAccount.id]
  );

  const transformComment = useCallback(
    (comment: string, mentioned: ApiUserSummary[]) => {
      let resComment = comment.slice();
      mentioned.forEach((mention) => {
        resComment = resComment.replaceAll(
          `<u:${mention.id}>`,
          `@${mention.firstName} ${mention.lastName}`
        );
      });
      resComment = resComment.replaceAll(/[**_`]/g, "");
      return resComment;
    },
    []
  );

  const loadComments = useCallback(async () => {
    const response = await Promise.all(
      requests.map(async (request) => {
        const requestsWithComments = await getComments(request.id).then(
          (comments) => {
            let newComments: ApiComment[] = [];
            comments.forEach((comment) => {
              const newComment = { ...comment };
              newComment.comment = transformComment(
                comment.comment,
                comment.mentioned
              );
              newComments.push(newComment);
            });
            return { ...request, comments: newComments };
          }
        );
        return requestsWithComments;
      })
    );
    setRequestComments(response);
  }, [getComments, requests, transformComment]);

  const loadAssetsTaskbooks = useCallback(async () => {
    const ids = requests.map((req) => req.id);
    const taskbooks = await apiClient.getRequestTaskbookMap(currentAccount.id, {
      ids,
    });
    const assets = await apiClient.getRequestAssetsMap(currentAccount.id, {
      ids,
    });
    setAssetsMap(assets);
    setTaskbooksMap(taskbooks);
  }, [apiClient, currentAccount.id, requests]);

  const loadPDFData = useCallback(async () => {
    await Promise.all([
      loadComments(),
      loadVisibleFields(),
      loadAssetsTaskbooks(),
    ]);
  }, [loadComments, loadVisibleFields, loadAssetsTaskbooks]);

  useEffect(() => {
    if (!bulkPrintModalIsOpen) return;
    if (requests.length === 0) {
      showToast("info", "Nothing to print");
      bulkPrintModalOnClose();
      return;
    }
    setIsLoading(true);
    loadPDFData()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        showToast("error", "An error ocurred while loading data");
        bulkPrintModalOnClose();
        setIsLoading(false);
      });
  }, [
    bulkPrintModalIsOpen,
    loadPDFData,
    bulkPrintModalOnClose,
    requests.length,
    showToast,
  ]);

  useEffect(() => {
    if (bulkMode === "all") setRequests(allRequests);
    else {
      setRequests(
        bulkRequests
          .filter((item) => item.selected === true)
          .map((item) => item.request)
      );
    }
  }, [allRequests, bulkRequests, bulkMode]);
  return (
    <>
      {bulkMode === "all" ? (
        <IconButton
          icon={<Icon as={BsPrinter} />}
          aria-label="print"
          colorScheme="blue"
          variant="outline"
          onClick={bulkPrintModalOnOpen}
          size={isDesktop ? "md" : "sm"}
          isDisabled={isDisabled}
        />
      ) : (
        <Button
          variant="outline"
          colorScheme="gray"
          size="sm"
          bg={buttonColor}
          onClick={bulkPrintModalOnOpen}
          isDisabled={isDisabled}
        >
          Print
        </Button>
      )}
      {bulkPrintModalIsOpen && (
        <BulkPrintModal
          isOpen={bulkPrintModalIsOpen}
          loading={isLoading}
          onClose={closeOptions}
          bulkMode={bulkMode}
        />
      )}
      {pdfModalIsOpen && pdfDocument !== null && !isLoading && (
        <PdfViewerModal
          isOpen={pdfModalIsOpen}
          onClose={pdfModalOnClose}
          pdfDocument={pdfDocument}
          downloadName={downloadName()}
        />
      )}
    </>
  );
};
