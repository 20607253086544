import { ApiFrequency } from "@operations-hero/lib-api-client";
import { getDate, getDay, getHours, getMinutes, getMonth } from "date-fns";
import { getLastDayMonth } from "../../../utils/monthHelper";
import { InitialCustomCronProps } from "../CustomCron";
import {
  CRON_LIST_SEPARATOR,
  CRON_SPACE_BETWEEN_FIELDS,
  CronValuesProps,
  FIRST_DAY_MONTH,
  LAST_DAY_MONTH,
} from "./CronHelper";

const CRON_ANY = "*";

export type CronFieldKey =
  | "minute"
  | "hour"
  | "day-of-month"
  | "month"
  | "day-of-week";

export type CronBoxOption = {
  value: string;
  label: string;
  isSelected?: boolean;
};

export type CronRecurrenceOption = {
  value: ApiFrequency;
  label: string;
};

export const CRON_DAYS: CronBoxOption[] = [
  { value: "0", label: "SUN", isSelected: false },
  { value: "1", label: "MON", isSelected: false },
  { value: "2", label: "TUE", isSelected: false },
  { value: "3", label: "WED", isSelected: false },
  { value: "4", label: "THU", isSelected: false },
  { value: "5", label: "FRI", isSelected: false },
  { value: "6", label: "SAT", isSelected: false },
];

export const CRON_MONTHS: CronBoxOption[] = [
  { value: "1", label: "JAN", isSelected: false },
  { value: "2", label: "FEB", isSelected: false },
  { value: "3", label: "MAR", isSelected: false },
  { value: "4", label: "APR", isSelected: false },
  { value: "5", label: "MAY", isSelected: false },
  { value: "6", label: "JUN", isSelected: false },
  { value: "7", label: "JUL", isSelected: false },
  { value: "8", label: "AUG", isSelected: false },
  { value: "9", label: "SEP", isSelected: false },
  { value: "10", label: "OCT", isSelected: false },
  { value: "11", label: "NOV", isSelected: false },
  { value: "12", label: "DEC", isSelected: false },
];

export const RECURRENCE_OPTIONS: CronRecurrenceOption[] = [
  { value: "daily", label: "Day(s)" },
  { value: "weekly", label: "Week(s)" },
  { value: "monthly", label: "Month(s)" },
  { value: "yearly", label: "Year(s)" },
];

export const getRepeatsOnOptions = (
  selectedStartDate?: string,
  withWeklyOptions?: boolean
): CronBoxOption[] => {
  const options = [
    { label: `on the first day of the month`, value: "1" },
    { label: `on the last day of the month`, value: "L" },
  ];

  if (selectedStartDate) {
    const selectedStartDateDate = new Date(selectedStartDate);
    const dayStartDate = selectedStartDateDate.getDate();
    if (
      dayStartDate !== 1 &&
      dayStartDate !== getLastDayMonth(selectedStartDateDate)
    ) {
      options.splice(1, 0, {
        label: `on the ${dayStartDate} day of the month`,
        value: `${dayStartDate}`,
      });
    }
  }

  if (withWeklyOptions) {
    options.push(
      { label: "on the first week of the month", value: "1w" },
      { label: "on the second week of the month", value: "2w" },
      { label: "on the third week of the month", value: "3w" },
      { label: "on the last week of the month", value: "Lw" }
    );
  }

  return options;
};

export const resetCronBoxOption = (
  options: CronBoxOption[]
): CronBoxOption[] => {
  const cleanedOptions = options.map((option) => ({
    ...option,
    isSelected: false,
  }));
  return cleanedOptions;
};

const cronInitialValues: CronValuesProps = {
  days: [] as CronBoxOption[],
  months: [] as CronBoxOption[],
  repeatNumber: 1,
  recurrence: RECURRENCE_OPTIONS[0],
  repeatsOn: getRepeatsOnOptions()[0],
  isLoading: true,
  cronMap: new Map<CronFieldKey, string>([
    ["minute", CRON_ANY],
    ["hour", CRON_ANY],
    ["day-of-month", CRON_ANY],
    ["month", CRON_ANY],
    ["day-of-week", CRON_ANY],
  ]),
  cronExpression: "",
  executeEvery: 1,
  weekOfMonth: null,
};

export const getDateProps = (date: Date) => {
  const hour = getHours(date).toString();
  const minutes = getMinutes(date).toString();
  const dayOfMonth = getDate(date).toString();
  const month = (getMonth(date) + 1).toString();
  const dayOfWeek = getDay(date).toString();

  return { hour, minutes, dayOfMonth, month, dayOfWeek };
};

const getSelectedCronOptions = (
  selectedOptions: string,
  optionsType: "days" | "months"
) => {
  const cronOptionsMap =
    optionsType === "days"
      ? new Map(CRON_DAYS.map((day) => [day.value, day]))
      : new Map(CRON_MONTHS.map((day) => [day.value, day]));

  if (selectedOptions !== CRON_ANY) {
    selectedOptions.split(CRON_LIST_SEPARATOR).forEach((item) => {
      const value = cronOptionsMap.get(item);
      value && cronOptionsMap.set(item, { ...value, isSelected: true });
    });
  }

  return Array.from(cronOptionsMap.values());
};

export const getRepeatsOnOption = (
  selectedDayOfMonth: string
): CronBoxOption => {
  const [firstDayMonth, dinamicDate, lastDayMonth] = getRepeatsOnOptions();

  let selectedOption = firstDayMonth;

  if (selectedDayOfMonth === LAST_DAY_MONTH) {
    selectedOption = lastDayMonth;
  }

  if (
    selectedDayOfMonth !== FIRST_DAY_MONTH &&
    selectedDayOfMonth !== LAST_DAY_MONTH
  ) {
    selectedOption = dinamicDate;
  }

  return selectedOption;
};

export const isCronValidToSave = (cron: string) => {
  if (cron === "") return false;
  const cronArray = cron.split(CRON_SPACE_BETWEEN_FIELDS);

  if (cronArray.length !== 5) return false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minute, hour, dayOfMonth, month, dayOfWeek] = cronArray;

  if (dayOfMonth === CRON_ANY && month === CRON_ANY && dayOfWeek === CRON_ANY)
    return false;

  return true;
};

export const markAllOptionsAsSelected = (options: CronBoxOption[]) => {
  return options.map((option) => ({ ...option, isSelected: true }));
};

export const areAllOptionsMarked = (options: CronBoxOption[]) => {
  if (options.length === 0) return false;
  return options.every((option) => option.isSelected === true);
};

export const cleanCronBoxOptions = (options: CronBoxOption[]) => {
  return options.map((option) => ({ ...option, isSelected: false }));
};

export const getInitialStateFromCronString = (
  initialCronValues: InitialCustomCronProps,
  selectedDate: Date
): CronValuesProps => {
  const {
    initialCronExpression,
    initialExecuteEvery,
    initialFrecuency,
    weekOfMonth,
  } = initialCronValues;
  const newCronValues = { ...cronInitialValues };
  if (!newCronValues.cronMap) return newCronValues;

  if (initialCronExpression === "") {
    const { minutes, hour, dayOfWeek } = getDateProps(selectedDate);

    const defaultMonths = markAllOptionsAsSelected(CRON_MONTHS);

    newCronValues.days = resetCronBoxOption(CRON_DAYS);
    if (!isNaN(parseInt(dayOfWeek))) {
      newCronValues.days[parseInt(dayOfWeek)] = {
        ...newCronValues.days[parseInt(dayOfWeek)],
        isSelected: true,
      };
    }
    newCronValues.months = defaultMonths;
    const allMonths = defaultMonths.map((option) => option.value).join(",");

    const cronMapCopy = new Map(newCronValues.cronMap);
    cronMapCopy.set("minute", minutes);
    cronMapCopy.set("hour", hour);
    cronMapCopy.set("day-of-month", FIRST_DAY_MONTH);
    cronMapCopy.set("month", allMonths);

    newCronValues.cronMap = cronMapCopy;
    newCronValues.recurrence = RECURRENCE_OPTIONS[1];
    newCronValues.executeEvery = initialExecuteEvery;
    const newCronExpression = `${minutes} ${hour} ${FIRST_DAY_MONTH} ${CRON_ANY} ${CRON_ANY}`;

    return { ...newCronValues, cronExpression: newCronExpression };
  }

  const [minute, hour, dayOfMonth, month, dayOfWeek] =
    initialCronExpression.split(CRON_SPACE_BETWEEN_FIELDS);

  const cronMapCopy = new Map(newCronValues.cronMap);
  cronMapCopy.set("minute", minute);
  cronMapCopy.set("hour", hour);
  cronMapCopy.set("day-of-month", dayOfMonth);
  cronMapCopy.set("month", month);
  cronMapCopy.set("day-of-week", dayOfWeek);
  newCronValues.cronMap = cronMapCopy;

  const recurrence =
    RECURRENCE_OPTIONS.find((option) => option.value === initialFrecuency) ||
    RECURRENCE_OPTIONS[1];

  newCronValues.recurrence = recurrence;
  newCronValues.cronExpression = initialCronExpression;
  newCronValues.executeEvery = initialExecuteEvery;
  newCronValues.weekOfMonth = weekOfMonth;

  let newDays = resetCronBoxOption(CRON_DAYS);
  let newMonths = resetCronBoxOption(CRON_MONTHS);

  if (initialFrecuency === "daily") {
    newDays = getSelectedCronOptions(dayOfWeek, "days");
    newCronValues.days = newDays;
  }

  if (initialFrecuency === "weekly") {
    newDays = getSelectedCronOptions(dayOfWeek, "days");
    newMonths = getSelectedCronOptions(month, "months");

    newCronValues.days = newDays;
    newCronValues.months = newMonths;
  }

  if (initialFrecuency === "monthly") {
    newMonths = getSelectedCronOptions(month, "months");
    const newRepeatsOn = getRepeatsOnOption(dayOfMonth);

    newCronValues.days = getSelectedCronOptions(dayOfWeek, "days");
    newCronValues.months = newMonths;
    newCronValues.repeatsOn = newRepeatsOn;
  }

  return { ...newCronValues };
};
