import { Box, Flex, HStack } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useNavAllowedProductSearch } from "../../../../hooks/useNavAllowedProductSearch";
import LogoSmall from "../Logo.sm";
import { CreateMenu } from "../quick-actions/CreateMenu";
import { QRButton } from "../quick-actions/QRButton";
import { SearchAutocomplete } from "../quick-actions/SearchAutocomplete";

type NavItemsProps = {
  mediaQueries: boolean[];
};

export const StaticNavItems: FC<NavItemsProps> = ({ mediaQueries }) => {
  const [isMobileSmall = true, isMobileMedium = false, isMobileLarge = false] =
    mediaQueries;
  const [expanded, setIsExpanded] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const { allowedProductSearch } = useNavAllowedProductSearch();

  return (
    <Flex flex={1} alignItems="center" gap={2}>
      <Flex
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          overflowY: "clip",
          height: "40px",
          alignItems: "center",
          minHeight: "60px",
          width: "100%",
          flexBasis: expanded ? "0%" : "97%",
        }}
      >
        {showButtons && (
          <>
            {isMobileSmall && (
              <HStack w="full" justifyContent="space-between">
                <LogoSmall />
                <HStack>
                  <CreateMenu mediaQueries={[true]} />
                  <QRButton />
                </HStack>
              </HStack>
            )}

            {isMobileMedium && (
              <HStack w="full" justifyContent="flex-end">
                <CreateMenu mediaQueries={[false, true]} />
                <QRButton />
              </HStack>
            )}

            {isMobileLarge && (
              <HStack w="full" justifyContent="flex-end">
                <CreateMenu mediaQueries={[false, false, false]} />
                <QRButton />
              </HStack>
            )}
          </>
        )}
      </Flex>
      <Box
        style={{
          flexBasis: expanded ? "100%" : "3%",
        }}
      >
        <SearchAutocomplete
          onSearchClick={() => {
            setShowButtons(false);
            setIsExpanded(true);
          }}
          allowedProductSearch={allowedProductSearch}
          isSearchBoxExpanded={expanded}
          onBlur={() => {
            setIsExpanded(false);
            setShowButtons(true);
          }}
        />
      </Box>
    </Flex>
  );
};
