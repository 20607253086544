export interface HaveId {
  id: string;
}

export function uniqueMap<T extends HaveId>(
  itemsToAdd: string[],
  previousHydratedValues: T[],
  payload: T[]
) {
  let uniqueMap: Record<string, T> = {};
  uniqueMap = itemsToAdd.reduce<Record<string, T>>((result, id) => {
    const dehydratedValuesFound = previousHydratedValues.find(
      (val) => val.id === id
    );
    if (dehydratedValuesFound) result[id] = dehydratedValuesFound as T;
    return result;
  }, {});

  uniqueMap = payload.reduce<Record<string, T>>((result, item) => {
    result[item.id] = item;
    return result;
  }, uniqueMap);

  return Object.values(uniqueMap);
}
