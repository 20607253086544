import { Modal, ModalOverlay } from "@chakra-ui/react";
import { ApiRequestTaskBookDetail } from "@operations-hero/lib-api-client";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { RemoveTaskboookModal } from "./RemoveTaskbookModal";
import { UpdateTaskbookModal } from "./UpdateTaskbookModal";

export enum RequestTaskbookEnum {
  REMOVE = "REMOVE",
  UPDATE = "UPDATE",
}

export interface RequestTaskbookModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: RequestTaskbookEnum;
  cb?: (taskbookId: string) => void;
  workingTaskbook?: ApiRequestTaskBookDetail;
  removeItemId?: string | null;
  finishItemId?: string | null;
  requestId: string;
}

export const RequestTaskbookModal: FC<RequestTaskbookModalProps> = ({
  cb,
  isOpen,
  onClose,
  modalType,
  requestId,
  removeItemId,
  workingTaskbook,
  finishItemId,
}) => {
  const { apiClient, currentAccount } = useAuthentication();

  const [workingTaskbookData, setWorkingTaskbookData] =
    useState<ApiRequestTaskBookDetail>();
  const getData = useCallback(async () => {
    if (requestId && finishItemId) {
      const data = await apiClient.findRequestTaskbook(
        currentAccount.id,
        requestId,
        finishItemId
      );
      setWorkingTaskbookData(data);
    }
  }, [requestId, apiClient, currentAccount, finishItemId]);

  useEffect(() => {
    if (modalType === RequestTaskbookEnum.UPDATE && !workingTaskbook) {
      getData();
    } else {
      setWorkingTaskbookData(workingTaskbook);
    }
  }, [getData, workingTaskbook, modalType]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {modalType === RequestTaskbookEnum.REMOVE && removeItemId && cb && (
        <RemoveTaskboookModal
          removeItemId={removeItemId}
          onClose={onClose}
          cb={cb}
        />
      )}
      {modalType === RequestTaskbookEnum.UPDATE && workingTaskbookData && (
        <UpdateTaskbookModal
          onClose={onClose}
          workingTaskbook={workingTaskbookData}
          requestId={requestId}
          taskbookId={finishItemId}
        />
      )}
    </Modal>
  );
};
