import { Box, Text } from "@chakra-ui/react";
import { ApiRateGroup } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

const FIND_RATE_GROUPS_OPTIONS = {
  pageSize: 100,
  page: 1,
};

export interface RateGroupAutocompleteProps {
  name?: string;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: ApiRateGroup | null;
  isClearable?: boolean;
  onChange: (rateGroup: ApiRateGroup | null) => void;
}

const RateGroupBadge = ({ value }: { value: ApiRateGroup }) => {
  return <Text>{value.name}</Text>;
};

const CustomOptionComponent = createOptionComponent(RateGroupBadge);

const CustomSingleValueComponent = (props: SingleValueSelect<ApiRateGroup>) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <RateGroupBadge value={data} />
    </Box>
  );
};

export const RateGroupAutocomplete = ({
  value,
  name,
  onChange,
  allowEmpty = true,
  isInvalid,
  isDisabled,
  placeholder,
  isClearable,
}: RateGroupAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findRateGroups(currentAccount.id, {
          search: inputValue,
          ...FIND_RATE_GROUPS_OPTIONS,
        })
        .then((response) => {
          cb(response.data);
        });
    },
    [apiClient, currentAccount.id]
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const handleChange = useCallback(
    (newValue: ApiRateGroup | null) => {
      if (allowEmpty !== true && newValue == null) {
        return;
      }
      onChange(newValue);
    },
    [onChange, allowEmpty]
  );

  const getOptionValue = useCallback(
    (rateGroup: ApiRateGroup) => rateGroup.id,
    []
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiRateGroup>) =>
        CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiRateGroup>) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      name={name || "rateGroup"}
      value={value}
      components={components}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      onChange={handleChange}
      placeholder={placeholder}
      chakraStyles={commonStyles}
      getOptionValue={getOptionValue}
      isClearable={isClearable}
      defaultOptions={true}
      loadOptions={debouncedLoadOptions}
      closeMenuOnSelect
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
};
