import { WithChildren } from "../store/local-cache.slice";

export function buildFlattenedTree<T extends WithChildren<T>>(tree: T[]) {
  const flattenedTree: T[] = [];
  tree.forEach((parent: T) => {
    flattenedTree.push(parent);
    const children = parent.children;

    if (children) {
      children.forEach((child: T) => {
        flattenedTree.push(child);
      });
    }
  });
  return flattenedTree;
}
