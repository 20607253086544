import { ScheduledRequestFilters } from "../../../store/schedule-request-list.slice";

export const scheduledRequestDefaultFilters: ScheduledRequestFilters = {
  page: 1,
  search: "",
  pageSize: 50,
  asset: [],
  reason: [],
  location: [],
  category: [],
  includeInactive: false,
  frequency: [],
  executeEvery: undefined,
  persons: [],
};
