import {
  Button,
  Checkbox,
  Flex,
  List,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useCheckboxGroup,
} from "@chakra-ui/react";
import { FC } from "react";
import { RiAddLine } from "react-icons/ri";
import { AdditionalFilter, AdditionalFilters } from "./GeneralFilters";
import { FilterDisplayName } from "./defaults";

type MoreFiltersProps = {
  onFilterChange: (filters: Array<AdditionalFilter>) => void;
};

export const MoreFilters: FC<MoreFiltersProps> = ({ onFilterChange }) => {
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: [],
    onChange: (values) => {
      onFilterChange(values as Array<AdditionalFilter>);
    },
  });

  return (
    <Popover placement="right-end" preventOverflow>
      <PopoverTrigger>
        <Button size="sm" leftIcon={<RiAddLine />}>
          More filter
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <List sx={{ columnCount: 2 }} spacing={2}>
            {AdditionalFilters.map((filter) => {
              return (
                <Flex
                  key={filter}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Checkbox
                    w="max-content"
                    {...getCheckboxProps({ value: filter })}
                  >
                    <Text casing="capitalize">{FilterDisplayName[filter]}</Text>
                  </Checkbox>
                </Flex>
              );
            })}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
