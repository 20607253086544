import {
  ApiClient,
  UpdateApiScheduledRequest,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { isCronValidToSave } from "../../../components/cron/cron-helpers/CronOptions";
import {
  getChangedPropsFromObject,
  isObjectEmpty,
} from "../../../utils/compareObjects";
import { getId } from "../../../utils/getId";
import { ScheduleAssociation } from "../../schedule-request-list.slice";

interface SaveScheduledRequestParams {
  apiClient: ApiClient;
  accountId: string;
}

export const updateScheduledRequest = createAsyncThunk(
  "account/updateScheduledRequest",
  async (params: SaveScheduledRequestParams, thunkAPI) => {
    const { apiClient, accountId } = params;
    const state = thunkAPI.getState() as RootState;
    const {
      scheduledRequest,
      scheduledRequestTaskBooks,
      scheduledRequestAssets,
      schedule,
      willRequestRepeat,
      scheduledRequestCopy,
    } = state.scheduleRequestForm;

    const scheduleAssociation: ScheduleAssociation = {
      scheduledRequestId: scheduledRequest.id,
      scheduleId: schedule.id,
      schedule: undefined,
    };

    if (scheduledRequest.id) {
      const newProjectValue = scheduledRequest.project
        ? getId(scheduledRequest.project)
        : null;

      const fieldsToUpdate =
        scheduledRequestCopy !== null
          ? getChangedPropsFromObject<UpdateApiScheduledRequest>(
              scheduledRequestCopy,
              {
                ...scheduledRequest,
                projectId: newProjectValue,
              }
            )
          : null;

      const mustUpdated = !isObjectEmpty(fieldsToUpdate);
      const updatedScheduledRequest =
        mustUpdated && fieldsToUpdate
          ? await apiClient.updateScheduledRequest(
              accountId,
              scheduledRequest.id,
              fieldsToUpdate
            )
          : null;

      const newTaskbooksIds = scheduledRequestTaskBooks
        .filter((taskbook) => taskbook.isNew === true)
        .map((filteredTaskbook) => filteredTaskbook.id);

      newTaskbooksIds.length > 0 &&
        (await apiClient.createScheduledRequestTaskbook(
          accountId,
          scheduledRequest.id,
          { taskbookIds: newTaskbooksIds }
        ));

      const newAssetsIds = scheduledRequestAssets
        .filter((taskbook) => taskbook.isNew === true)
        .map((filteredAsset) => filteredAsset.id);

      newAssetsIds.length > 0 &&
        (await apiClient.createScheduledRequestAsset(
          accountId,
          scheduledRequest.id,
          { assetIds: newAssetsIds }
        ));

      const canBeSaved = isCronValidToSave(schedule.cron);
      if (!willRequestRepeat && schedule.id) {
        await apiClient.deleteSchedule(
          accountId,
          scheduledRequest.id,
          schedule.id
        );
        scheduleAssociation.schedule = false;
      }
      if (willRequestRepeat && canBeSaved) {
        const newSchedule = {
          cron: schedule.cron,
          startDate: schedule.startDate,
          endDate: schedule.endDate,
          frequency: schedule.frequency,
          executeEvery: schedule.executeEvery,
          weekOfMonth: schedule.weekOfMonth,
        };

        if (schedule.isNew) {
          scheduleAssociation.schedule = await apiClient.createSchedule(
            accountId,
            scheduledRequest.id,
            newSchedule
          );
        } else if (schedule.id) {
          scheduleAssociation.schedule = await apiClient.updateSchedule(
            accountId,
            scheduledRequest.id,
            schedule.id,
            {
              ...newSchedule,
              startDate: newSchedule.startDate
                ? new Date(newSchedule.startDate).toISOString()
                : null,
            }
          );
        }
      }

      return { updatedScheduledRequest, scheduleAssociation };
    }
  }
);
