import { Input, InputGroup } from "@chakra-ui/react";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { phoneNumberAutoFormatting } from "../../utils/phoneNumberFormatting";

export type PhoneInputProps = {
  name: string;
  placeholder?: string;
  value?: PhoneValue;
  onChange?: (phone: PhoneValue) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
};

export type PhoneValue = {
  formated: string;
  //E.164 is the general international formatting. E.g. +15123456789
  e164: string;
};

export const PhoneInput: FC<PhoneInputProps> = ({
  name,
  value,
  placeholder,
  onChange,
  onBlur,
}) => {
  const [phone, setPhone] = useState<PhoneValue>(
    value ?? {
      formated: "",
      e164: "",
    }
  );

  const onInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const formated = phoneNumberAutoFormatting(e.target.value);
      const updatedValue: PhoneValue = {
        formated,
        e164: formated.replace(/\D/g, ""),
      };
      setPhone(updatedValue);

      if (onChange) onChange(updatedValue);
    },
    [onChange]
  );

  return (
    <InputGroup>
      <Input
        name={name}
        placeholder={placeholder}
        value={phone.formated}
        onInput={onInput}
        onBlur={onBlur}
        type="tel"
        maxLength={14}
      />
    </InputGroup>
  );
};
