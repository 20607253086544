import {
  ApiClient,
  CreateApiVendorContact,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { VendorSlice } from "./vendors-slice";

// LIST
interface LoadVendorContactsParams {
  apiClient: ApiClient;
  accountId: string;
  vendorId: string;
}

export const loadVendorContacts = createAsyncThunk(
  "event/vendor-contacts/load",
  async (params: LoadVendorContactsParams, ThunkAPI) => {
    let { filters } = (ThunkAPI.getState() as RootState).vendorSlice
      .workingVendorContacts;
    const { apiClient, accountId, vendorId } = params;

    const response = await apiClient.findVendorContacts(accountId, vendorId, {
      ...filters,
      page: filters.page || 1,
      pageSize: 5,
      includeInactive: filters.includeInactive,
    });
    return response;
  }
);

export const loadVendorContactsHandler = (
  builder: ActionReducerMapBuilder<VendorSlice>
) => {
  builder.addCase(loadVendorContacts.fulfilled, (state, action) => {
    const { total, data } = action.payload;
    state.workingVendorContacts.data = data;
    state.workingVendorContacts.total = total;
  });
};

// CREATE
interface CreateVendorContactParams {
  apiClient: ApiClient;
  accountId: string;
  vendorId: string;
  contact: CreateApiVendorContact;
}

export const createVendorContact = createAsyncThunk(
  "event/vendor-contacts/new",
  async (params: CreateVendorContactParams) => {
    const { apiClient, accountId, vendorId, contact } = params;

    const response = await apiClient.createVendorContact(
      accountId,
      vendorId,
      contact
    );
    return response;
  }
);

export const createVendorContactHandler = (
  builder: ActionReducerMapBuilder<VendorSlice>
) => {
  builder.addCase(createVendorContact.fulfilled, (state, action) => {
    state.workingVendorContacts.data.unshift(action.payload);
    state.workingVendorContacts.total++;
  });
};

// UPDATE
interface UpdateVendorContactParams extends CreateVendorContactParams {
  contactId: string;
}

export const updateVendorContact = createAsyncThunk(
  "event/vendor-contacts/update",
  async (params: UpdateVendorContactParams) => {
    const { apiClient, accountId, vendorId, contactId, contact } = params;

    const response = await apiClient.updateVendorContact(
      accountId,
      vendorId,
      contactId,
      contact
    );
    return response;
  }
);

export const updateVendorContactHandler = (
  builder: ActionReducerMapBuilder<VendorSlice>
) => {
  builder.addCase(updateVendorContact.fulfilled, (state, action) => {
    const index = state.workingVendorContacts.data.findIndex(
      (contact) => contact.id === action.payload.id
    );

    if (index !== -1) {
      state.workingVendorContacts.data[index] = action.payload;
    }
  });
};

// DEACTIVATE
interface DeactivateVendorContactParams {
  apiClient: ApiClient;
  accountId: string;
  vendorId: string;
  contactId: string;
}

export const deactivateVendorContact = createAsyncThunk(
  "event/vendor-contacts/deactivate",
  async (params: DeactivateVendorContactParams) => {
    const { apiClient, accountId, vendorId, contactId } = params;

    await apiClient.deactivateVendorContact(accountId, vendorId, contactId);
    return contactId;
  }
);

export const deactivateVendorContactHandler = (
  builder: ActionReducerMapBuilder<VendorSlice>
) => {
  builder.addCase(deactivateVendorContact.fulfilled, (state, action) => {
    const index = state.workingVendorContacts.data.findIndex(
      (contact) => contact.id === action.payload
    );

    if (index !== -1) {
      state.workingVendorContacts.filters.includeInactive
        ? (state.workingVendorContacts.data[index].isActive = false)
        : state.workingVendorContacts.data.splice(index, 1);
    }
  });
};

// REACTIVATE
export const reactivateVendorContact = createAsyncThunk(
  "event/vendor-contacts/reactivate",
  async (params: DeactivateVendorContactParams) => {
    const { apiClient, accountId, vendorId, contactId } = params;

    await apiClient.reactivateVendorContact(accountId, vendorId, contactId);
    return contactId;
  }
);

export const reactivateVendorContactHandler = (
  builder: ActionReducerMapBuilder<VendorSlice>
) => {
  builder.addCase(reactivateVendorContact.fulfilled, (state, action) => {
    const index = state.workingVendorContacts.data.findIndex(
      (contact) => contact.id === action.payload
    );

    if (index !== -1) {
      state.workingVendorContacts.data[index].isActive = true;
    }
  });
};
