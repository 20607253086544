import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAccountSettings,
  EventsSettingsKeys,
  InventorySettingsKeys,
  RequestSettingsKeys,
  UpdateApiUser,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { EventHQEmailSettings } from "../../components/emailSetting/EventHQEmailSetting";
import { HeroHQEmailSettings } from "../../components/emailSetting/HeroHQEmailSetting";
import { InventoryHQEmailSettings } from "../../components/emailSetting/InventoryHQEmailSetting";
import { VenueLevelEmailSettings } from "../../components/emailSetting/VenueLevelEmailSetting";
import { FieldGroup } from "../../components/field-group/FieldGroup";
import { TextInputControl } from "../../components/form-helpers/TextInputControl";
import { TimezoneSelectControl } from "../../components/form-helpers/TimezoneSelectControl";
import { AutoSavingRadio } from "../../components/inputs/AutoSavingRadio";
import { AutoSavingSwitch } from "../../components/inputs/AutoSavingSwitch";
import { PageHeader } from "../../components/page-header/PageHeader";
import { useProductSubscriptions } from "../../components/shell/AppShell";
import { SMSNotificationSettings } from "../../components/smsNotificationSettings/SMSNotificationSettings";
import { useShowToast } from "../../hooks/showToast";
import { useAllowedDashboards } from "../../hooks/useDashboards";
import { useRequestsAccessForUser } from "../../hooks/useRequestsAccessForUser";
import { RootState } from "../../store";
import { setCurrentUser } from "../../store/auth.slice";
import { updateUserSettingToLocalCache } from "../../store/local-cache.slice";
import {
  getSettingOrDefault,
  SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS,
  SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
  SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT,
} from "../../utils/emailSettingUtils";
import { ColumnViewSettings } from "./HeroHQColumnViewSettings";

export const DEFAULT_DASHBOARD = "default-dashboard";

export function UserProfilePage() {
  const { currentUser: user, currentAccount, apiClient } = useAuthentication();
  const { hasInventory, hasHeroText } = useProductSubscriptions();
  const showToast = useShowToast();
  const dispatch = useDispatch();
  const { hasTechPlus } = useRequestsAccessForUser();

  const [accountAllowsEmails, setAccountAllowsEmails] = useState(false);
  const [accountSettings, setAccountSettings] = useState<ApiAccountSettings>(
    {}
  );
  const [userSettings, setUserSettings] = useState<ApiAccountSettings>({});
  const userSettingsFromLocalCache = useSelector(
    (state: RootState) => state.localCache.userSettings
  );

  const update = useCallback(
    (currentAccountId: string, setting: ApiAccountSettings) => {
      return apiClient.updateCurrentUserSettings(currentAccountId, setting);
    },
    [apiClient]
  );

  const initialValues = useMemo(
    () => ({
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      timeZone: user.timeZone,
    }),
    [user]
  );

  const handleFormikSubmit = useCallback(
    (values: UpdateApiUser) => {
      return apiClient
        .updateCurrentUser(values)
        .then((user) => {
          dispatch(setCurrentUser(user));
          showToast("success", "User Profile Updated");
        })
        .catch(() => {
          showToast("error", "Error updating user");
        });
    },
    [apiClient, showToast, dispatch]
  );

  const handleSettingSave = useCallback(
    (key: string) => (value: boolean) => {
      return update(currentAccount.id, { [key]: value })
        .then((saved) => {
          showToast("success", "Update user setting");
          if (key === SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS) {
            dispatch(
              updateUserSettingToLocalCache({
                key: SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS,
                value: saved[
                  SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS
                ] as boolean,
              })
            );
          }

          if (key === SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT) {
            dispatch(
              updateUserSettingToLocalCache({
                key: SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT,
                value: saved[
                  SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT
                ] as boolean,
              })
            );
          }
        })
        .catch(() => {
          showToast("error", "Error updating user setting");
        });
    },
    [currentAccount, showToast, update, dispatch]
  );

  useEffect(() => {
    const keys = [
      RequestSettingsKeys.ALLOW_REQUEST_EMAIL_NOTIFICATIONS,
      RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_EMAILS,
      RequestSettingsKeys.ALLOW_REQUEST_MENTION_EMAILS,
      EventsSettingsKeys.ALLOW_EVENT_COMMENT_MENTION_EMAILS,
      RequestSettingsKeys.ALLOW_REQUEST_APPROVER_REQUIRED_EMAILS,
      RequestSettingsKeys.ALLOW_REQUEST_REVIEWER_REQUIRED_EMAILS,
      RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_COMMENT_EMAILS,
      RequestSettingsKeys.NOTIFICATIONS_REQUEST_STATUS_UPDATED_EMAILS,
      RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS,
      EventsSettingsKeys.ALLOW_EVENT_CREATE_CONFIRMATION_EMAILS,
      EventsSettingsKeys.ALLOW_EVENT_APPROVERS_COMMENT_EMAILS,
      EventsSettingsKeys.ALLOW_EVENT_CONFIRMED_EMAILS,
      EventsSettingsKeys.ALLOW_EVENT_NEEDS_APPROVAL_EMAILS,
      EventsSettingsKeys.ALLOW_EVENT_OCCURRENCE_CANCELED_EMAILS,
      InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_CREATED_EMAILS,
      InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_FULFILLED_OR_CANCELLED_EMAILS,
      InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_NEEDS_APPROVAL_EMAILS,
      SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
      DEFAULT_DASHBOARD,
    ];

    Promise.all([
      apiClient.getAccountSettings(currentAccount.id, keys),
      apiClient.getCurrentUserSettings(currentAccount.id, keys),
    ]).then(([accountSettings, userSettings]) => {
      setAccountSettings(accountSettings);
      setUserSettings(userSettings);
      setAccountAllowsEmails(
        accountSettings &&
          getSettingOrDefault(
            accountSettings[
              RequestSettingsKeys.ALLOW_REQUEST_EMAIL_NOTIFICATIONS
            ]
          )
      );
    });
  }, [user, apiClient, currentAccount]);

  const { userDashboards } = useAllowedDashboards();

  const handleSaveDefaultDashboard = useCallback(
    (dashboard: string) => {
      return apiClient
        .updateCurrentUserSettings(currentAccount.id, {
          [DEFAULT_DASHBOARD]: dashboard,
        })
        .then(() => {
          showToast("success", "Successful default dasboard setup");
          setUserSettings({ ...userSettings, [DEFAULT_DASHBOARD]: dashboard });
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });
    },
    [apiClient, currentAccount.id, showToast, userSettings]
  );

  return (
    <Container maxWidth="8xl">
      <Stack>
        <PageHeader heading="User Profile" />
        <Formik onSubmit={handleFormikSubmit} initialValues={initialValues}>
          {({ values, isSubmitting }) => (
            <Form>
              <FieldGroup title="Personal Info">
                <VStack width="md" spacing="6" alignContent="flex-start">
                  <TextInputControl
                    label="First Name"
                    value={values.firstName || null}
                    name="firstName"
                    maxLength={255}
                  />
                  <TextInputControl
                    label="Last Name"
                    value={values.firstName || null}
                    name="lastName"
                    maxLength={255}
                  />
                  <TimezoneSelectControl
                    name="timeZone"
                    label="Time Zone"
                    placeholder="Select your User timezone"
                    value={user.timeZone || undefined}
                  />
                  <FormControl isDisabled={true} id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      isReadOnly
                      value={user.email}
                      readOnly
                    />
                  </FormControl>
                  <Box width="100%">
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </VStack>
              </FieldGroup>
            </Form>
          )}
        </Formik>
        <FieldGroup title="User Settings">
          <VStack
            width="md"
            spacing="6"
            justify="stretch"
            w="full"
            alignItems="start"
          >
            <Text w="100%" textAlign="left" size="lg" fontWeight="bold">
              HeroHQ Settings
            </Text>
            <AutoSavingSwitch
              id="allow-last-location-new-requests"
              isChecked={
                userSettingsFromLocalCache[
                  SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS
                ] as boolean
              }
              label="Use last location for new request"
              onSave={handleSettingSave(
                SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS
              )}
            />
            {hasTechPlus && (
              <ColumnViewSettings handleSettingSave={handleSettingSave} />
            )}
            {userDashboards.length > 0 && (
              <AutoSavingRadio
                options={userDashboards}
                radioGroupLabel="Default Dashboard"
                onSave={handleSaveDefaultDashboard}
                value={
                  userSettings[DEFAULT_DASHBOARD]
                    ? (userSettings[DEFAULT_DASHBOARD] as string)
                    : "requests"
                }
              />
            )}
          </VStack>
        </FieldGroup>
        <FieldGroup title="Notification Settings">
          <VStack width="md" spacing="6" alignContent="flex-start">
            {hasHeroText && (
              <SMSNotificationSettings
                accountSettings={accountSettings}
                userSettings={userSettings}
                update={update}
              />
            )}
            {accountAllowsEmails && (
              <>
                <HeroHQEmailSettings
                  accountSettings={accountSettings}
                  userSettings={userSettings}
                  update={update}
                />
                <EventHQEmailSettings
                  accountSettings={accountSettings}
                  userSettings={userSettings}
                  update={update}
                />
                <VenueLevelEmailSettings />
                {hasInventory && (
                  <InventoryHQEmailSettings
                    accountSettings={accountSettings}
                    userSettings={userSettings}
                    update={update}
                  />
                )}
              </>
            )}
          </VStack>
        </FieldGroup>
      </Stack>
    </Container>
  );
}
