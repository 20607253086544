import {
  Avatar,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import { ApiEventGroup, ApiGroupStatus } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback } from "react";
import { IoDocumentOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { TextEditorAutoSave } from "../../components/form-helpers/rich-text-editor/RichTextEditorAutoSave";
import { useShowToast } from "../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../store";
import {
  setSelectedEventGroupAdditionalNotes,
  updateEventGroupState,
  updateSelectedGroupState,
} from "../../store/event-group-slice";
import { updateEventGroupStatus } from "../../store/events/event-details.slice";
import { updateEventListGroupStatus } from "../../store/events/event-list.slice";
import { CityStateText } from "../account-settings/event-group-list/EventGroupHelpers";

interface EventGroupApprovalElements {
  eventGroupId?: string;
  eventGroupInformation?: ApiEventGroup;
  lastInsuranceAttachment: string;
  isImage: boolean;
  isEvent: boolean;
  eventId?: string;
  cancel: () => void;
  handleDecline: () => void;
  handleEdit: () => void;
}

export const EventGroupApproval: FC<EventGroupApprovalElements> = ({
  eventGroupId,
  eventGroupInformation,
  lastInsuranceAttachment,
  isImage,
  isEvent,
  eventId,
  cancel,
  handleDecline,
  handleEdit,
}) => {
  const { currentAccount, apiClient } = useAuthentication();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const { eventGroupList } = useSelector((state: RootState) => state);

  const changeGroupStatus = useCallback(() => {
    if (eventGroupInformation?.status === ApiGroupStatus.approved) {
      showToast("info", "Event Group Already Approved");
      cancel();
      return;
    }
    if (eventGroupId && eventGroupInformation) {
      thunkDispatch(
        updateEventGroupState({
          apiClient,
          accountId: currentAccount.id,
          eventGroupId,
          status: ApiGroupStatus.approved,
          owner: eventGroupInformation.owner,
          additionalNotes: eventGroupInformation.additionalNotes || undefined,
          declineReasons: [],
        })
      )
        .then(() => {
          showToast("success", "The Event Group has been approved successfuly");
          dispatch(updateSelectedGroupState(ApiGroupStatus.approved));
          dispatch(
            updateEventGroupStatus({
              eventGroupId: eventGroupId,
              eventGroupStatus: ApiGroupStatus.approved,
            })
          );
          eventId &&
            eventGroupList.selectedEventGroup &&
            dispatch(
              updateEventListGroupStatus({
                eventGroupId: eventGroupId,
                eventGroupStatus: ApiGroupStatus.approved,
              })
            );
        })
        .catch((err) => {
          showToast("error", "Error Updating Group Status");
        });
    }
    cancel();
  }, [
    apiClient,
    cancel,
    currentAccount.id,
    dispatch,
    eventGroupId,
    eventGroupInformation,
    eventGroupList.selectedEventGroup,
    eventId,
    showToast,
    thunkDispatch,
  ]);

  const handleUpdateAdditionalNotes = useCallback(
    (value: string) => {
      dispatch(setSelectedEventGroupAdditionalNotes(value));
    },
    [dispatch]
  );

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={2} p={6}>
      <GridItem colSpan={9}>
        <Text fontWeight="bold" fontSize="xl">
          Approve this group
        </Text>
      </GridItem>

      <GridItem colSpan={12}>
        <Divider border={1} color="gray.400" mt={4} mb={4} />
      </GridItem>

      <GridItem colSpan={2} rowSpan={2} pt={3}>
        <Avatar
          name={eventGroupInformation?.name}
          src={eventGroupInformation?.logo || undefined}
        />
      </GridItem>
      <GridItem colSpan={10}>
        <Text fontWeight="bold">{eventGroupInformation?.name}</Text>
        <CityStateText
          city={eventGroupInformation?.contactInfo.city}
          state={eventGroupInformation?.contactInfo.state}
        />
        <Text color="gray.600">
          {`${
            eventGroupInformation?.isInternalGroup ? "Applied on" : "Created on"
          } ${format(
            eventGroupInformation
              ? new Date(eventGroupInformation?.created)
              : new Date(),
            "MMM-dd-yyy"
          )}`}
        </Text>
      </GridItem>
      <GridItem colSpan={12}>
        <Text fontWeight="medium">{eventGroupInformation?.description}</Text>
      </GridItem>

      <GridItem colSpan={12}>
        <Divider border={1} color="gray.400" mt={4} mb={4} />
      </GridItem>

      <GridItem colSpan={12}>
        <Text fontWeight="bold" fontSize={18}>
          Group Owner
        </Text>
      </GridItem>
      <GridItem colSpan={2} rowSpan={2} pt={3}>
        <Avatar
          name={eventGroupInformation?.owner.firstName}
          src={eventGroupInformation?.owner.profileImage || undefined}
        />
      </GridItem>
      <GridItem colSpan={10}>
        <Text fontWeight="bold">
          {`${eventGroupInformation?.owner.firstName} ${eventGroupInformation?.owner.lastName} `}
        </Text>
        <Text color="blue.600">{eventGroupInformation?.owner.email}</Text>
        <Text>
          {eventGroupInformation?.contactInfo.phoneNumber ||
            eventGroupInformation?.owner.phone}
        </Text>
      </GridItem>

      <GridItem colSpan={12}>
        <Divider border={1} color="gray.400" mt={4} mb={4} />
      </GridItem>

      <GridItem colSpan={9}>
        <Text fontWeight="bold" fontSize={18}>
          Proof of Insurance
        </Text>
        <Text color="gray.600">
          {`${
            eventGroupInformation?.insuranceExpiration
              ? "Insurance expires"
              : "No insurance expires information"
          } ${
            eventGroupInformation?.insuranceExpiration
              ? format(
                  new Date(eventGroupInformation?.insuranceExpiration),
                  "MMM-dd-yyy"
                )
              : ""
          }`}
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        {isImage || lastInsuranceAttachment === "" ? (
          <Image src={lastInsuranceAttachment} minH="100%" minW="100%" />
        ) : (
          <Icon
            as={IoDocumentOutline}
            width={10}
            height={10}
            color="gray.400"
            minH="100%"
            minW="100%"
          />
        )}
      </GridItem>

      <GridItem colSpan={12}>
        <Divider border={1} color="gray.400" mt={4} mb={4} />
      </GridItem>

      <GridItem colSpan={12}>
        <Text fontWeight="bold" fontSize={18} pb={3}>
          Group Additional Notes
        </Text>
        <TextEditorAutoSave
          id="event-group-notes"
          value={eventGroupInformation?.additionalNotes || ""}
          initialValue={eventGroupInformation?.additionalNotes}
          onChange={handleUpdateAdditionalNotes}
        />
      </GridItem>

      <GridItem colSpan={12}>
        <Divider border={1} color="gray.400" mt={4} mb={4} />
      </GridItem>

      <GridItem colSpan={12}>
        <Flex justifyContent="space-between" gap={2}>
          <Button
            size="sm"
            onClick={cancel}
            variant="outline"
            borderColor="blue.500"
            borderRadius={5}
          >
            Cancel
          </Button>
          <Flex gap={3}>
            <Button
              size="sm"
              variant="outline"
              colorScheme="red"
              onClick={handleDecline}
            >
              Decline
            </Button>
            <Button
              colorScheme="blue"
              float="right"
              onClick={changeGroupStatus}
              size="sm"
            >
              Approve
            </Button>
          </Flex>
        </Flex>
      </GridItem>
    </Grid>
  );
};
