import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  GridItem,
  Icon,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiVenueSummary } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { StyledDatePicker } from "../../../../components/inputs/StyledDatePicker";
import { EventOccurrenceUpdateProps } from "../../../../store/events/event-details.slice";
import { filterAllowedTime } from "../../../../utils/filterAllowedTime";
import { getVenueTimesRanges } from "../../../../utils/getVenueTimesRange";
import {
  addHoursToEndDate,
  customRound,
  subsTwoHoursToStartDate,
} from "../../../../utils/updateTimes";
import { ConflictAlert } from "../form-components/ConflictsAlert";

const DATETIME_FORMAT = "h:mm aa";

type DateTimeElements = {
  selectedOccurrences: string[];
  occurrences: EventOccurrenceUpdateProps[];
  updateSelectedOccurrencesDates: (start: Date, end: Date) => void;
  venueSelectAll?: ApiVenueSummary | null;
  limitByVenue?: boolean;
  allOccurrences?: Date[];
  setDateStartSelect?: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setDateEndSelect?: React.Dispatch<React.SetStateAction<Date | undefined>>;
};

export const ChangeSelectedTimes: FC<DateTimeElements> = ({
  occurrences,
  selectedOccurrences,
  updateSelectedOccurrencesDates,
  venueSelectAll,
  limitByVenue = false,
  setDateStartSelect,
  setDateEndSelect,
}) => {
  const [startHour, setStartHour] = useState<Date | null>(null);
  const [endHour, setEndHour] = useState<Date | null>(null);
  const [totalConflicts, setTotalConflicts] = useState<number>();
  const [venueStart, setVenueStart] = useState<Date | null>(null);
  const [venueEnd, setVenueEnd] = useState<Date | null>(null);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const linkColor = useColorModeValue("blue.500", "whiteAlpha.900");

  const { apiClient, currentAccount } = useAuthentication();

  const handleOnChangeStartHour = useCallback(
    (value: Date | null) => {
      setStartHour(value);
      if (value) {
        const diffInHrs =
          endHour && startHour
            ? Math.abs(startHour.getTime() - endHour.getTime()) /
              (1000 * 60 * 60)
            : 2;
        const newEndDate = addHoursToEndDate(value, customRound(diffInHrs));
        if (
          limitByVenue &&
          venueEnd &&
          newEndDate.getTime() > venueEnd.getTime()
        ) {
          setEndHour(venueEnd);
          return;
        }
        setEndHour(newEndDate);
      }
    },
    [endHour, limitByVenue, startHour, venueEnd]
  );

  const handleOnChangeEndHour = useCallback(
    (date: Date | null) => {
      if (!date) return;
      setEndHour(date);
      if (startHour) {
        const newStart = subsTwoHoursToStartDate(date);
        if (
          limitByVenue &&
          venueStart &&
          newStart.getTime() < venueStart.getTime()
        ) {
          setStartHour(venueStart);
          return;
        }
        if (date.getTime() < startHour.getTime()) setStartHour(newStart);
      }
    },
    [limitByVenue, startHour, venueStart]
  );

  const handleOnSave = useCallback(() => {
    if (startHour && endHour) {
      updateSelectedOccurrencesDates(startHour, endHour);
      limitByVenue && setDateStartSelect && setDateStartSelect(startHour);
      limitByVenue && setDateEndSelect && setDateEndSelect(endHour);
    }
    onClose();
  }, [
    endHour,
    onClose,
    startHour,
    updateSelectedOccurrencesDates,
    setDateEndSelect,
    setDateStartSelect,
    limitByVenue,
  ]);

  const checkSelectedOccurrencesConflicts = useCallback(async () => {
    if (selectedOccurrences.length === 0 || !isOpen) return;

    const occurrencesToCheck = occurrences
      .filter((occ) => selectedOccurrences.some((item) => item === occ.id))
      .map((occurrence) => ({
        id: occurrence.id,
        venue: occurrence.venue.id,
        spaces: occurrence.spaces.map((space) => space.id),
        start: startHour ? startHour.toISOString() : occurrence.start,
        end: endHour ? endHour.toISOString() : occurrence.end,
      }));

    const response = await apiClient.checkEventConflicts(
      currentAccount.id,
      occurrencesToCheck
    );
    const occurrencesWithConflicts = response.filter(
      (item) => item.hasConflict
    );
    setTotalConflicts(occurrencesWithConflicts.length);
  }, [
    apiClient,
    currentAccount.id,
    endHour,
    isOpen,
    occurrences,
    selectedOccurrences,
    startHour,
  ]);

  const setAllowedTime = useCallback(() => {
    if (!venueSelectAll) return;
    const venueRangeTimes = getVenueTimesRanges(venueSelectAll.hours);
    if (!venueRangeTimes) return;
    const { minStartHour, minStartMinute, maxEndHour, maxEndMinute } =
      venueRangeTimes;

    const dateStart = new Date();
    dateStart.setHours(minStartHour);
    dateStart.setMinutes(minStartMinute);
    setVenueStart(dateStart);
    setStartHour(dateStart);

    const dateEnd = new Date(dateStart);
    dateEnd.setHours(maxEndHour);
    dateEnd.setMinutes(maxEndMinute);
    setVenueEnd(dateEnd);
    setEndHour(dateEnd);
  }, [venueSelectAll]);

  const filterTime = useCallback(
    (time: Date) => {
      return filterAllowedTime(time, venueStart, venueEnd);
    },
    [venueStart, venueEnd]
  );

  useEffect(() => {
    limitByVenue && setAllowedTime();
  }, [setAllowedTime, limitByVenue]);

  useEffect(() => {
    checkSelectedOccurrencesConflicts();
  }, [checkSelectedOccurrencesConflicts]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box w="38%" fontWeight="bold" color={linkColor}>
          <Text
            as="span"
            display={["none", "none", "inherit"]}
            cursor="pointer"
            _hover={{ textDecoration: "underline" }}
          >
            Change Times <Icon as={MdModeEditOutline} ml={2} />
          </Text>
          <Text
            as="span"
            display={["inherit", "inherit", "none"]}
            cursor="pointer"
            _hover={{ textDecoration: "underline" }}
          >
            Times <Icon as={MdModeEditOutline} ml={2} />
          </Text>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader>
          <Box w="100%">
            <Collapse in={Boolean(totalConflicts)} unmountOnExit>
              <ConflictAlert
                includeDescription={false}
                title={`There are ${totalConflicts} occurrence(s) with conflicts`}
              />
            </Collapse>

            <Box>
              <Text p={5} pl={1} fontSize="sm">
                All selected dates will be updated to reflect the time selected
                below.
              </Text>
            </Box>
            <Divider border={1} />
            <Box>
              <Text fontWeight="bold" p={5} pl={1}>
                Change selected times
              </Text>
            </Box>

            <Grid templateColumns="repeat(12, 1fr)" gap={2}>
              <GridItem colSpan={6}>
                <StyledDatePicker
                  placeholder="Start Time"
                  showTime
                  showTimeOnly
                  name="startHour"
                  value={startHour}
                  format={DATETIME_FORMAT}
                  onChange={handleOnChangeStartHour}
                  filterTime={limitByVenue ? filterTime : undefined}
                />
              </GridItem>

              <GridItem colSpan={6}>
                <StyledDatePicker
                  placeholder="End Time"
                  name="endHour"
                  value={endHour}
                  showTime={true}
                  showTimeOnly={true}
                  format={DATETIME_FORMAT}
                  onChange={handleOnChangeEndHour}
                  filterTime={limitByVenue ? filterTime : undefined}
                />
              </GridItem>
              <GridItem colSpan={12}>
                <Divider border={1} />
              </GridItem>

              <GridItem colSpan={6}>
                <Button
                  float="left"
                  variant="outline"
                  borderColor="blue.500"
                  borderRadius={5}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </GridItem>

              <GridItem colSpan={6}>
                <Button float="right" colorScheme="blue" onClick={handleOnSave}>
                  Save
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </PopoverHeader>
      </PopoverContent>
    </Popover>
  );
};
