import {
  ApiClient,
  ApiInventoryItemAdjustment,
  ApiInventoryItemAdjustmentUserIdentifier,
  ApiItemAdjustmentType,
  ApiPagingInventoryItemAdjustment,
  ApiPagingInventoryItemAdjustmentFilters,
  ApiRequest,
  ApiUserSummary,
  ReturnTotal,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { buildMap } from "../../utils/buildMap";
import getRangeRelativeDate from "../../utils/getRangeRelativeDate";

export interface InventoryItemAdjusmentsFilters
  extends ApiPagingInventoryItemAdjustment {
  dateRange?: RelativeDateFilter | AbsoluteDateFilter;
  dateRelative?: RelativeDateFilter;
}
const DEFAULT_FILTERS: InventoryItemAdjusmentsFilters = {
  page: 1,
  pageSize: 20,
};
export interface InventoryItemAdjustmentsSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: ApiInventoryItemAdjustment[];
  total: number;
  filters: InventoryItemAdjusmentsFilters;
  adjusters: ApiUserSummary[];
  dateField?: DateFields;
  isCustomDate: boolean;
  requestsMap: Record<string, ApiRequest>;
  returnTotalsMap: Record<string, ReturnTotal>;
}
interface LoadInventoryItemAdjustmentsParams {
  apiClient: ApiClient;
  accountId: string;
  itemId: string;
}

interface LoadInventoryItemAdjustersParams {
  apiClient: ApiClient;
  accountId: string;
  inventoryItemId: string;
}

export const loadInventoryItemAdjusters = createAsyncThunk(
  "inventory/item-adjusters",
  async (params: LoadInventoryItemAdjustersParams, thunkAPI) => {
    const { apiClient, accountId, inventoryItemId } = params;
    const options: ApiPagingInventoryItemAdjustmentFilters = {
      inventoryItemId,
      userIdentifier: ApiInventoryItemAdjustmentUserIdentifier.adjusterId,
    };
    const response = await apiClient.findInventoryItemAdjustmentPersons(
      accountId,
      options
    );
    return response.data;
  }
);

export const loadInventoryItemAdjustments = createAsyncThunk(
  "inventory/item-adjustments",
  async (params: LoadInventoryItemAdjustmentsParams, thunkAPI) => {
    const { apiClient, accountId, itemId } = params;
    const { filters } = (thunkAPI.getState() as RootState)
      .inventoryItemAdjustmentsSlice;

    const mappedFilters: InventoryItemAdjusmentsFilters = {
      inventoryLocation: Array.isArray(filters.inventoryLocation)
        ? filters.inventoryLocation.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,

      adjuster: Array.isArray(filters.adjuster)
        ? filters.adjuster.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,

      request: filters.request,

      date: filters.dateRange
        ? filters.dateRange.type === "relative"
          ? getRangeRelativeDate(filters.dateRange.value)
          : filters.dateRange.value
        : undefined,
      type: filters.type,
      serialNumber: filters.serialNumber,
      reason: filters.reason,
      isCheckedOutItem: filters.isCheckedOutItem,
      search: filters.search,
      status: filters.status,
      page: filters.page,
      pageSize: filters.pageSize,
      item: itemId,
      direction: "desc",
    };

    const inventoryItemAdjustments =
      await apiClient.findInventoryItemAdjustments(accountId, mappedFilters);

    const requestIds = inventoryItemAdjustments.data
      .filter((adj) => adj.requestId)
      .map((adj) => adj.requestId) as string[];

    const requests =
      requestIds.length > 0
        ? (await apiClient.findRequests(accountId, { idsOrKeys: requestIds }))
            .data
        : [];

    const returnTotalsMap = await apiClient.findReturnAdjustmentsTotals(
      accountId,
      {
        ids: inventoryItemAdjustments.data
          .filter((iia) => iia.type === ApiItemAdjustmentType.restock)
          .map((iia) => iia.id),
      }
    );

    return { inventoryItemAdjustments, requests, returnTotalsMap };
  }
);

export const inventoryItemAdjustmentsSlice = createSlice({
  name: "inventoryItemAdjustments",
  initialState: {
    data: [],
    total: 0,
    filters: { ...DEFAULT_FILTERS },
    adjusters: [],
    dateField: undefined,
    isCustomDate: false,
    loading: "pending",
    requestsMap: {},
    returnTotalsMap: {},
  } as InventoryItemAdjustmentsSlice,
  reducers: {
    unloadInventoryAdjustmentList: (state: InventoryItemAdjustmentsSlice) => {
      state.data = [];
      state.total = 0;
      state.requestsMap = {};
      state.filters = { ...DEFAULT_FILTERS };
    },
    setInventoryItemAdjustmentsFilters: (
      state: InventoryItemAdjustmentsSlice,
      action: PayloadAction<Partial<InventoryItemAdjusmentsFilters>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    setDateField: (state, action: PayloadAction<DateFields>) => {
      state.dateField = action.payload;
    },
    setRelativeDate: (state, action: PayloadAction<RelativeDateFilter>) => {
      state.filters.dateRange = action.payload;
    },
    setAbsoluteDate: (state, action: PayloadAction<AbsoluteDateFilter>) => {
      state.filters.dateRange = action.payload;
    },
    setIsCustomDate: (state, action: PayloadAction<boolean>) => {
      state.isCustomDate = action.payload;
    },
    setCurrentPage: (state, payload: PayloadAction<number>) => {
      state.filters.page = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadInventoryItemAdjustments.fulfilled, (state, action) => {
      const { inventoryItemAdjustments, requests, returnTotalsMap } =
        action.payload;

      state.loading = "succeeded";
      state.data = inventoryItemAdjustments.data;
      state.total = inventoryItemAdjustments.total;
      state.requestsMap = buildMap(requests);
      state.returnTotalsMap = returnTotalsMap;
    });

    builder.addCase(loadInventoryItemAdjustments.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(loadInventoryItemAdjustments.rejected, (state, action) => {
      state.loading = "failed";
      state.data = [];
    });

    builder.addCase(loadInventoryItemAdjusters.fulfilled, (state, action) => {
      state.adjusters = action.payload;
    });
  },
});

export type DateFields = "date";

export type RelativeDateOptions =
  | "last7d"
  | "last30d"
  | "thisWeek"
  | "lastWeek"
  | "thisMonth"
  | "lastMonth";

export type AbsoluteDateFilter = {
  field: DateFields;
  type: "absolute";
  value: string[];
};

export type RelativeDateFilter = {
  field: DateFields;
  type: "relative";
  value: RelativeDateOptions;
};

export type DateFilter = {
  field: DateFields;
  type: "relative" | "absolute";
  value: string[];
};

export const {
  setInventoryItemAdjustmentsFilters,
  unloadInventoryAdjustmentList,
  setDateField,
  setRelativeDate,
  setAbsoluteDate,
  setIsCustomDate,
  setCurrentPage,
} = inventoryItemAdjustmentsSlice.actions;

export default inventoryItemAdjustmentsSlice.reducer;
