import { Badge, Box, Text } from "@chakra-ui/react";
import {
  ApiWorkflow,
  ApiWorkflowReference,
} from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  WorkflowOptionsProps,
  WorkflowSingleValueSelect,
} from "./select-overrides-types";

export interface WorkflowAutocompleteProps {
  workflow: ApiWorkflow | ApiWorkflowReference | null;
  onChange: (workflow: ApiWorkflow | null) => void;
  allowEmpty?: boolean;
  placeholder?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  name?: string;
  options?: ApiWorkflow[];
}

const WorkflowBadge = ({ value }: { value: ApiWorkflow }) => {
  return (
    <Box width="100%" gap={-2}>
      <Text width="100%" pb={0}>
        <Text
          as="span"
          display="inline-block"
          fontWeight="bold"
          pr={1}
          minWidth="50px"
        >
          {value.requestSlug}: {value.name}
        </Text>
        {!value.active && (
          <Badge colorScheme="gray" ml={4} p={1}>
            Inactive
          </Badge>
        )}
      </Text>
      <Text width="100%">{value.description}</Text>
    </Box>
  );
};

const CustomOptionComponent = createOptionComponent(WorkflowBadge);

const CustomSingleValueComponent = (props: WorkflowSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <WorkflowBadge value={data} />
    </Box>
  );
};

export const WorkflowAutocomplete = ({
  workflow,
  onChange,
  allowEmpty = true,
  isInvalid,
  isDisabled,
  placeholder,
  options,
}: WorkflowAutocompleteProps) => {
  const workflows = useSelector(
    (state: RootState) => state.localCache.workflows,
  );

  const handleChange = useCallback(
    (newValue: ApiWorkflow | null) => {
      if (allowEmpty !== true && newValue == null) {
        return;
      }
      onChange(newValue);
    },
    [onChange, allowEmpty],
  );

  const getOptionValue = useCallback(
    (workflow: ApiWorkflow) => workflow.name,
    [],
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: WorkflowOptionsProps) => CustomOptionComponent(props),
      SingleValue: (props: WorkflowSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <Select
      components={components}
      getOptionValue={getOptionValue}
      isClearable={allowEmpty === true}
      onChange={handleChange}
      chakraStyles={commonStyles}
      options={options ? options : workflows}
      value={workflow as ApiWorkflow}
      isInvalid={isInvalid}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};
