import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  usePrevious,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiLocation,
  ApiLocationReference,
  ApiLocationSummary,
  ApiReportingCategory,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { Pager } from "../../../components/pager/Pager";
import { LocationAutocomplete } from "../../../components/selects/LocationAutocomplete";
import { ReportingCategoryAutocomplete } from "../../../components/selects/ReportingCategoryAutocomplete";
import { RootState, useThunkDispatch } from "../../../store";
import {
  addScheduledRequestAssets,
  removeNewScheduledRequestAsset,
  setGenerateRequestPerAsset,
} from "../../../store/scheduled-request-form/schedule-request-form.slice";
import { removeScheduledRequestProp } from "../../../store/scheduled-request-form/thunks";
import { useCategoryUtils } from "../../../utils/categoryUtils";
import { useLocationUtils } from "../../../utils/locationUtils";
import { AssetCard } from "../asset-list/AssetCard";
import { Products } from "../location-list/LocationList";

interface ScheduledRequestAssetsProps {
  editStatus: string;
  activeStep: number;
  totalSteps: number;
}

export const ScheduleAssetsForm: FC<ScheduledRequestAssetsProps> = ({
  editStatus,
  activeStep,
  totalSteps,
}) => {
  const firstStackColor = useColorModeValue("gray.50", "whiteAlpha.200");
  const buttonBgColor = useColorModeValue("blue.500", "whiteAlpha.300");
  const { scheduledRequest, scheduledRequestAssets, isAddingAsset } =
    useSelector((state: RootState) => state.scheduleRequestForm);
  const [filteredAssets, setFilteredAssets] = useState<ApiAsset[]>([]);
  const [location, setLocation] = useState<ApiLocationSummary | null>(
    scheduledRequest.location || null
  );
  const { getChildrenId } = useLocationUtils();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();
  const [locationIds, setLocationIds] = useState<string[] | undefined>(
    scheduledRequest.location
      ? getChildrenId([scheduledRequest.location as ApiLocation])
      : undefined
  );
  const [category, setCategory] = useState(scheduledRequest.reportingCategory);
  const [categoriesIds, setCategoriesIds] = useState<string[] | undefined>(
    scheduledRequest.reportingCategory
      ? Array.from(
          findAllChildrenForNodesRecursive([
            scheduledRequest.reportingCategory.id,
          ])
        ).map((cat) => cat.id)
      : undefined
  );
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PAGE_SIZE = 20;
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);
  const previousPage = usePrevious(page);
  const findAssets = useCallback(async () => {
    const response = await apiClient.findAssets(currentAccount.id, {
      page: previousPage === page ? 1 : page,
      pageSize: PAGE_SIZE + scheduledRequestAssets.length,
      search: searchValue,
      locations: locationIds ? locationIds : [],
      categories: categoriesIds ? categoriesIds : [],
    });
    setTotal(
      response.total - parseInt(scheduledRequestAssets.length.toString(), 10)
    );
    const assetsMap = new Map(response.data.map((asset) => [asset.id, asset]));
    scheduledRequestAssets.forEach(
      (item) => assetsMap.has(item.id) && assetsMap.delete(item.id)
    );
    setFilteredAssets(Array.from(assetsMap.values()));
  }, [
    apiClient,
    currentAccount.id,
    searchValue,
    scheduledRequestAssets,
    page,
    previousPage,
    locationIds,
    categoriesIds,
  ]);

  const handleOnChangeLocation = useCallback(
    (newLocation: ApiLocationSummary | null) => {
      setLocation(newLocation);
      if (newLocation) {
        const childrens = getChildrenId([newLocation as ApiLocation]);
        setLocationIds(childrens);
        return;
      }
      setLocationIds(undefined);
    },
    [getChildrenId]
  );

  const handleOnChangeCategory = useCallback(
    (value: ApiWorkflowReportingCategorySummary | null) => {
      setCategory(value);
      if (value) {
        const categoryChilds = value
          ? findAllChildrenForNodesRecursive([value.id])
          : new Set<ApiReportingCategory>();
        const categories = Array.from(categoryChilds);
        setCategoriesIds(categories ? categories.map((cat) => cat.id) : []);
        return;
      }
      setCategoriesIds(undefined);
    },
    [findAllChildrenForNodesRecursive]
  );

  const handleOnChangeSearch = useCallback((event: any) => {
    const { value } = event.target;
    setSearchValue(value as string);
  }, []);

  const handleAddAsset = useCallback(
    (asset: ApiAsset) => {
      dispatch(addScheduledRequestAssets(asset));
    },
    [dispatch]
  );

  const onChangeGenerateRequestPerAsset = useCallback(() => {
    dispatch(
      setGenerateRequestPerAsset(!scheduledRequest.generateRequestPerAsset)
    );
  }, [dispatch, scheduledRequest.generateRequestPerAsset]);

  const handleOnClickRemoveAsset = useCallback(
    async (assetId: string) => {
      if (scheduledRequest.id) {
        await thunkDispatch(
          removeScheduledRequestProp({
            accountId: currentAccount.id,
            apiClient,
            prop: "asset",
            scheduledRequestId: scheduledRequest.id,
            id: assetId,
          })
        );
      }
      dispatch(removeNewScheduledRequestAsset(assetId));
    },
    [apiClient, currentAccount.id, dispatch, scheduledRequest.id, thunkDispatch]
  );

  useEffect(() => {
    findAssets();
  }, [findAssets, category, searchValue, location]);

  return (
    <>
      <Heading my={4} fontSize={["2xl", "3xl"]}>
        {`${editStatus} Schedule Request (${activeStep} of ${totalSteps})`}
      </Heading>
      <Text fontSize="lg">
        <strong>Add Assets</strong> (Optional)
      </Text>

      <Stack
        p={4}
        my={4}
        spacing={4}
        flexDir="row"
        flexWrap="wrap"
        borderRadius={6}
        alignItems="center"
        bgColor={firstStackColor}
      >
        <StackItem w="100%">
          <FormLabel>Location</FormLabel>
          <LocationAutocomplete
            value={(location as ApiLocationReference) || null}
            onChange={handleOnChangeLocation}
            productName={Products.HeroHQ}
          />
        </StackItem>

        <StackItem w={["100%", "100%", "50%"]}>
          <ReportingCategoryAutocomplete
            value={category}
            onChange={handleOnChangeCategory}
          />
        </StackItem>

        <StackItem
          pl={[0, 0, 2, 2]}
          display="inline-flex"
          flexDir="column"
          flex={1}
        >
          <InputGroup>
            <Input
              placeholder="Search assets"
              onChange={handleOnChangeSearch}
            />
            <InputLeftElement
              zIndex={0}
              pointerEvents="none"
              children={<Icon as={FaSearch} color="gray.300" />}
            />
          </InputGroup>
        </StackItem>

        {filteredAssets.length > 0 && (
          <Stack gap={2} w="100%" divider={<Divider />}>
            {filteredAssets.map((asset) => (
              <Flex
                w="100%"
                key={`sr-search::${asset.id}`}
                justifyContent="space-between"
              >
                <Box flex="1" maxW={["220px", "max-content", "max-content"]}>
                  <Text pb={1} isTruncated>
                    {asset.name}
                    {asset.externalId ? `, ${asset.externalId}` : null}
                  </Text>
                  {asset.location ? (
                    <LocationTwoLine
                      boldLocation={false}
                      value={asset.location}
                      locationTextProps={{ fontSize: "sm" }}
                      ancestorTextProps={{ fontSize: "xs" }}
                    />
                  ) : (
                    <Text fontSize="sm" fontStyle="italic">
                      No Location / Mobile Asset
                    </Text>
                  )}
                </Box>
                <Box>
                  <Button
                    size="xs"
                    color="white"
                    ml={[0, 0, "10px"]}
                    bgColor={buttonBgColor}
                    _hover={{ backgroundColor: "none" }}
                    isDisabled={isAddingAsset}
                    isLoading={isAddingAsset}
                    onClick={() => handleAddAsset(asset)}
                  >
                    <Icon as={FaPlus} mr={2} />
                    Add Asset
                  </Button>
                </Box>
              </Flex>
            ))}
          </Stack>
        )}

        <Divider />
        <StackItem flex={1} w="94%" flexDirection={isMobile ? "column" : "row"}>
          <Checkbox
            onChange={onChangeGenerateRequestPerAsset}
            isChecked={scheduledRequest.generateRequestPerAsset}
            minW="100%"
          >
            Generate one request for each asset
          </Checkbox>
          <Pager
            currentPage={page}
            total={total}
            pageSize={PAGE_SIZE}
            onPageChange={onPageChange}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          />
        </StackItem>
      </Stack>

      <Divider />

      <Stack flexDir="row" alignItems="center" flexWrap="wrap">
        <StackItem>
          <Text color="gray.500">
            {scheduledRequestAssets.length} assets added...
          </Text>
        </StackItem>
        {scheduledRequestAssets.map((asset, index) => (
          <Fragment key={index + asset.id}>
            <StackItem py={[2]} w={["100%", "100%"]}>
              <AssetCard asset={asset}>
                <Button
                  size="xs"
                  variant="outline"
                  ml={[0, 0, "10px"]}
                  borderColor={buttonBgColor}
                  onClick={() => handleOnClickRemoveAsset(asset.id)}
                >
                  Remove
                </Button>
              </AssetCard>
            </StackItem>
          </Fragment>
        ))}
      </Stack>
    </>
  );
};
