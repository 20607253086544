import { Badge, HStack, Text } from "@chakra-ui/react";
import { ApiAccountDetail, ApiReport } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

export interface ReportAutocompleteProps {
  isInvalid?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: ApiReport | null;
  onChange: (venue: ApiReport | null) => void;
  product?: string;
  accountDetail?: ApiAccountDetail;
}

const ReportItem = ({ value }: { value: ApiReport }) => {
  return (
    <HStack gap={2}>
      <Text noOfLines={1}>{value.dashboard.name}</Text>
      {!value.isSystem && <Badge>Account</Badge>}
    </HStack>
  );
};

const CustomOptionComponent = createOptionComponent(ReportItem);

const CustomSingleValueComponent = (props: SingleValueSelect<ApiReport>) => {
  const { data, innerProps } = props;
  return <ReportItem value={data} {...innerProps} />;
};

export const ReportsAutocomplete = ({
  value,
  onChange,
  isInvalid,
  isDisabled,
  placeholder,
  product = undefined,
  accountDetail = undefined,
}: ReportAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const handleChange = useCallback(
    (newValue: ApiReport | null) => {
      if (newValue == null) {
        return;
      }
      onChange(newValue);
    },
    [onChange]
  );

  const shouldRemoveM1Report = useMemo(() => {
    if (!accountDetail || !product) {
      return true;
    }

    if (
      product.toLowerCase() === "herohq" &&
      accountDetail.address?.state?.trim().toLowerCase() === "nj"
    ) {
      return false;
    }

    return true;
  }, [product, accountDetail]);

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      if (product) {
        return apiClient
          .findAccountReports(currentAccount.id, {
            search: inputValue,
            product: product,
          })
          .then((response) => {
            let data = response.data;

            if (shouldRemoveM1Report) {
              data = data.filter(
                (x) => x.dashboardId !== "b711328b-67ff-4474-917a-1ff32bfb77af"
              );
            }

            cb(data);
            // set default value if there is more than one result
            if (data.length >= 1 && !value) {
              handleChange(data[0]);
            }
            return data;
          });
      } else {
        cb([]);
        return [] as ApiReport[];
      }
    },
    [
      apiClient,
      currentAccount,
      product,
      shouldRemoveM1Report,
      value,
      handleChange,
    ]
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const getOptionValue = useCallback((report: ApiReport) => report.id, []);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiReport>) =>
        CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiReport>) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      key={`report${product}`}
      name="report"
      defaultOptions={true}
      chakraStyles={commonStyles}
      value={value}
      cacheOptions={true}
      getOptionValue={getOptionValue}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      isClearable={false}
      isInvalid={isInvalid}
      isMulti={false}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};
