import { Box, Heading, useBreakpointValue } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export type PageHeaderProps = PropsWithChildren<{
  heading: string;
}>;

export const PageHeader = ({ heading, children }: PageHeaderProps) => {
  const size = useBreakpointValue({ base: "lg", md: "xl" });
  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        py={1}
      >
        <Heading as="h1" size={size}>
          {heading}
        </Heading>
        {children && <Box maxW="md">{children}</Box>}
      </Box>
    </>
  );
};
