import { Flex, Tag, TagLabel, TagLeftIcon, Tooltip } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { BiBuildings, BiTask } from "react-icons/bi";
import { FaRegCalendarCheck } from "react-icons/fa";
import { MdOutlineAttachFile } from "react-icons/md";
import { TbScanEye } from "react-icons/tb";
import { createPath, useNavigate } from "react-router";

export const RequestRowIcons = ({ request }: { request: ApiRequest }) => {
  const navigate = useNavigate();
  const assetsLink = createPath({
    pathname: `/requests/${request.key}`,
    hash: "#assets",
  });
  const taskbooksLink = createPath({
    pathname: `/requests/${request.key}`,
    hash: "#taskbooks",
  });

  return (
    <Flex gap={2} w="100%" justifyContent="right">
      {request.followedBy.length > 0 && (
        <Tooltip label={`${request.followedBy.length} follower(s)`}>
          <Tag variant="subtle">
            <TagLeftIcon as={TbScanEye} boxSize="16px" />
            <TagLabel>{request.followedBy.length}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {request.totalReminders > 0 && (
        <Tooltip label={`${request.totalReminders} reminder(s)`}>
          <Tag variant="subtle">
            <TagLeftIcon as={FaRegCalendarCheck} boxSize="16px" />
            <TagLabel>{request.totalReminders}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {request.totalAttachments > 0 && (
        <Tooltip label={`${request.totalAttachments} attachment(s)`}>
          <Tag variant="subtle">
            <TagLeftIcon as={MdOutlineAttachFile} />
            <TagLabel>{request.totalAttachments}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {request.totalAssets > 0 && (
        <Tooltip label={`${request.totalAssets} asset(s)`}>
          <Tag
            variant="subtle"
            onClick={(e) => {
              e.preventDefault();
              navigate(assetsLink);
            }}
          >
            <TagLeftIcon as={BiBuildings} />
            <TagLabel>{request.totalAssets}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {request.totalTaskbooks > 0 && (
        <Tooltip label={`${request.totalTaskbooks} taskbook(s)`}>
          <Tag
            variant="subtle"
            onClick={(e) => {
              e.preventDefault();
              navigate(taskbooksLink);
            }}
          >
            <TagLeftIcon as={BiTask} />
            <TagLabel>{request.totalTaskbooks}</TagLabel>
          </Tag>
        </Tooltip>
      )}
    </Flex>
  );
};
