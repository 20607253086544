import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { MultiValue } from "chakra-react-select";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import {
  MultiUserAutocomplete,
  MultiUserAutocompleteProps,
} from "../selects/MultiUserAutocomplete";

export interface MultiUserAutocompleteControlProps {
  label: string;
  name: string;
  value: ApiUserSummary[] | null;
  emptyText?: string;
  labelProps?: FormLabelProps;
  fieldProps?: Pick<MultiUserAutocompleteProps, "filterOptions">;
}

export const MultiUserAutocompleteControl = ({
  name,
  value,
  label,
  emptyText,
  labelProps,
  fieldProps,
}: MultiUserAutocompleteControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (user: MultiValue<ApiUserSummary> | null) => {
      helper.setTouched(true);
      helper.setValue(user || null);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <Flex justifyContent="space-between" alignContent="flex-start">
        <FormLabel htmlFor={name} {...labelProps}>
          {label}
        </FormLabel>
      </Flex>
      <MultiUserAutocomplete
        {...field}
        onChange={handleOnChange}
        name={name}
        allowEmpty={false}
        emptyText={emptyText}
        {...fieldProps}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
