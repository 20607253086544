import { IconButton, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import { RiQrCodeLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { setQRLookupModalIsOpen } from "../../../../store/qr-lookup.slice";
type QRButtonProps = {};

export const QRButton: FC<QRButtonProps> = () => {
  const dispatch = useDispatch();
  const handleOpenQRLookup = () => {
    dispatch(setQRLookupModalIsOpen(true));
  };
  return (
    <Tooltip label="Lookup QR Code or Barcode" hasArrow gutter={12}>
      <IconButton
        aria-label="Scan QR Code"
        onClick={handleOpenQRLookup}
        icon={<RiQrCodeLine />}
        size="sm"
        fontSize="22px"
        variant="outline"
        colorScheme="blue"
      />
    </Tooltip>
  );
};
