import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  NumberInput,
  NumberInputField,
  Switch,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiInventoryItemStorageLocation } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CiCircleCheck } from "react-icons/ci";
import { MdWarning } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { CheckboxControl } from "../../../../components/form-helpers/CheckboxControl";
import { InventoryOrderItem } from "../../../../store/inventory/inventory-order-item-list.slice";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";

export interface VerifyOrderItemControlProps {
  name: string;
  value: InventoryOrderItem;
  isUpdateModal?: boolean;
}
export interface StorageLocationsWithQuantity {
  storageLocation: ApiInventoryItemStorageLocation;
  restockQuantity: number;
}
export const VerifyOrderItemControl = ({
  name,
  value,
  isUpdateModal,
}: VerifyOrderItemControlProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const { submitCount, setFieldValue } = useFormikContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });
  const [, meta] = useField({ name });
  const [touched, setTouched] = useState<boolean | undefined>(undefined);
  const [storageLocations, setStorageLocations] = useState<
    StorageLocationsWithQuantity[]
  >([]);
  const [showLowStock, setShowLowStock] = useState<boolean>(true);

  useEffect(() => {
    apiClient
      .findInventoryItemStorageLocations(currentAccount.id, value.item.id, {
        showLowStock,
        addPolicy: true,
      })
      .then((res) => {
        const locations = res.data.sort((a, b) => {
          const aLowStock = a.levels?.low && a.quantity < a.levels.low;
          const bLowStock = b.levels?.low && b.quantity < b.levels.low;

          if (aLowStock && !bLowStock) return -1;
          if (!aLowStock && bLowStock) return 1;
          return 0;
        });
        let remainingDelivered = Number(value.delivered);
        const updatedLocations = locations.map((loc) => {
          const fulfillQuantity = (loc.levels?.idealStock || 0) - loc.quantity;

          const restockQuantity =
            fulfillQuantity > 0 && fulfillQuantity <= remainingDelivered
              ? fulfillQuantity
              : remainingDelivered;

          remainingDelivered -= restockQuantity;
          return {
            storageLocation: loc,
            restockQuantity: restockQuantity,
          };
        });
        setStorageLocations(updatedLocations);
        setFieldValue(`${name}.storageLocationRestock`, updatedLocations);
      });
  }, [
    apiClient,
    currentAccount.id,
    value.item.id,
    showLowStock,
    setFieldValue,
    name,
    value.delivered,
  ]);

  const handleRestockQuantityChange = useCallback(
    (value: number, locIndex: number) => {
      const updatedStorageLocations = storageLocations.map((loc, i) => {
        if (i === locIndex) {
          return {
            storageLocation: loc.storageLocation,
            restockQuantity: value,
          };
        }
        return loc;
      });
      setStorageLocations(updatedStorageLocations);
      setFieldValue(`${name}.storageLocationRestock`, updatedStorageLocations);
    },
    [storageLocations, setFieldValue, name]
  );
  const totalRestockItems = useMemo(() => {
    return storageLocations.reduce((sum, loc) => {
      return sum + Number(loc.restockQuantity);
    }, 0);
  }, [storageLocations]);
  const isDisabledItem = useMemo(() => {
    return isUpdateModal ? value.backOrder === false : false;
  }, [isUpdateModal, value.backOrder]);
  console.log({ isUpdateModal, isDisabledItem });
  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <Flex
        w="100%"
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex justifyContent="center" alignItems="center">
          {touched &&
            !isDisabledItem &&
            value.backOrder === false &&
            value.requested > Number(value.delivered) && (
              <Icon as={RiErrorWarningLine} boxSize="24px" color="yellow.500" />
            )}
          <Flex gap={2} flexDir="column" alignItems="flex-start">
            {isUpdateModal && !isDisabledItem && (
              <Button
                size="xs"
                colorScheme="red"
                variant="outline"
                onClick={onOpen}
              >
                <Text>Cancel Backorder</Text>
              </Button>
            )}
            {isDisabledItem && <Text textColor="red">REMOVED</Text>}

            <CheckboxControl
              name={`${name}.backOrder`}
              label="BackOrder"
              disabled={isUpdateModal}
              formControlProps={{ mt: 0 }}
            />
          </Flex>
        </Flex>
        {touched === undefined && (
          <FormLabel alignSelf="flex-end" htmlFor={name}>
            <Flex pt={2} alignItems="flex-end" justifyItems="flex-end">
              <IconButton
                px={2}
                aria-label="edit-comment"
                onClick={() => {
                  setTouched(false);
                  setFieldValue(`${name}.touched`, false);
                }}
                icon={
                  <Box gap={1} display="flex" alignItems="center">
                    <Icon as={RiErrorWarningLine} color="yellow.300" />
                    <Text whiteSpace="nowrap" color="inherit">
                      Unverified Item
                    </Text>
                  </Box>
                }
                variant="ghost"
              />
            </Flex>
          </FormLabel>
        )}
        {touched === false && (
          <FormLabel alignSelf="flex-end" htmlFor={name}>
            <Flex alignItems="flex-end" justifyItems="flex-end">
              <Button
                colorScheme="blue"
                variant="solid"
                onClick={() => {
                  setTouched(true);
                  setFieldValue(`${name}.touched`, true);
                }}
                height="24px"
                width="138px"
                alignSelf="flex-end"
                isDisabled={totalRestockItems !== Number(value.delivered)}
              >
                <Text>Done</Text>
              </Button>
            </Flex>
          </FormLabel>
        )}
        {touched && (
          <FormLabel alignSelf="flex-end" htmlFor={name}>
            <Flex pt={2} alignItems="flex-end" justifyItems="flex-end">
              <IconButton
                px={2}
                isDisabled={isDisabledItem}
                aria-label="edit-comment"
                onClick={() => {
                  setTouched(false);
                  setFieldValue(`${name}.touched`, false);
                }}
                icon={
                  <Box gap={1} display="flex" alignItems="center">
                    <Icon as={CiCircleCheck} color="green.300" />
                    <Text whiteSpace="nowrap" color="inherit">
                      Verified Item
                    </Text>
                  </Box>
                }
                variant="ghost"
              />
            </Flex>
          </FormLabel>
        )}
      </Flex>
      {touched === false && (
        <VStack bgColor={bgColor} w="100%">
          <HStack w="100%">
            <Box w="70%">
              <Text fontSize={isDesktop ? "m" : "xs"} fontWeight="bold">
                Storage Locations ({value.item.totalStorageLocations})
              </Text>
            </Box>
            <Box w="15%">
              <Text fontWeight="bold" fontSize={isDesktop ? "m" : "xs"}>
                Stock
              </Text>
            </Box>
            <Box w="15%">
              <Text mt={4} fontWeight="bold" fontSize={isDesktop ? "m" : "xs"}>
                Restock
              </Text>
              <Text
                fontSize={isDesktop ? "sm" : "xs"}
                textColor={
                  totalRestockItems < Number(value.delivered)
                    ? "yellow.600"
                    : undefined
                }
                fontWeight="bold"
              >
                ({totalRestockItems} of {Number(value.delivered)})
              </Text>
            </Box>
          </HStack>

          {storageLocations.map((loc, locIndex) => (
            <HStack key={loc.storageLocation.id} w="100%">
              <Box w="70%">
                <Flex>
                  {loc.storageLocation.levels?.low &&
                    loc.storageLocation.quantity <
                      loc.storageLocation.levels.low && (
                      <Icon
                        onClick={() => setTouched(false)}
                        as={MdWarning}
                        boxSize="24px"
                        color="yellow.300"
                      />
                    )}
                  <Text>{loc.storageLocation.storageLocation.name}</Text>
                </Flex>
                <Text fontWeight="300">
                  Ideal Stock: {loc.storageLocation.levels?.idealStock}
                </Text>
              </Box>
              <Box w="15%">
                <Text>{loc.storageLocation.quantity}</Text>
              </Box>
              <Box w="15%">
                <NumberInput
                  name={`${name}.storageLocationRestock[${locIndex}].restockQuantity`}
                  value={
                    (storageLocations &&
                      storageLocations[locIndex]?.restockQuantity) ||
                    0
                  }
                  onChange={(_, value) =>
                    handleRestockQuantityChange(value, locIndex)
                  }
                >
                  <NumberInputField paddingRight="1rem" />
                </NumberInput>
              </Box>
            </HStack>
          ))}
          {storageLocations.length === 0 && showLowStock && (
            <Flex w="100%" flexDir="column" gap={4}>
              <Alert status="warning">
                <AlertIcon />
                No storage locations for restocking found
              </Alert>
            </Flex>
          )}
          <Flex justifyContent="flex-start" alignItems="center" w="100%">
            <Box mt={2} flex="1" textAlign="left">
              Show All Storage Locations
              <Switch
                ml={2}
                isChecked={!showLowStock}
                onChange={() => setShowLowStock(!showLowStock)}
                aria-label="toggle disabled workflows"
              />
            </Box>
          </Flex>
        </VStack>
      )}

      {isOpen && (
        <AccountModal
          size="lg"
          title={<Heading fontSize="l">Cancel backorder?</Heading>}
          isOpen={isOpen}
          onClose={onClose}
          content={
            <>
              <Text>
                If you cancel a backorder, the item will be removed from the
                order
              </Text>
              <Text>This action cannot be undone</Text>
              <Flex justifyContent="flex-end" alignContent="flex-end">
                <Button onClick={onClose} mr={2}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setFieldValue(`${name}.backOrder`, false);
                    setTouched(true);
                    setFieldValue(`${name}.touched`, true);
                    onClose();
                  }}
                  colorScheme="red"
                >
                  Cancel Backorder
                </Button>
              </Flex>
            </>
          }
        />
      )}
    </FormControl>
  );
};
