import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { Pager } from "../../components/pager/Pager";
import { useQueryString } from "../../hooks/useQueryString";
import { RootState, useThunkDispatch } from "../../store";
import {
  DateFilterValues,
  initTransactionList,
  loadTransactions,
  RelativeDateOptions,
  setCurrentPage,
  setInitCompleted,
  setShowLaborCosts,
  unloadTransactionList,
  updateTransactionFilters,
} from "../../store/transaction-list.slice";
import { SkeletonTransactions } from "./SkeletonTransactions";
import { TransactionActionFilters } from "./TransactionActionFilters";
import { TransactionAdvancedFilters } from "./TransactionAdvancedFilters";
import { TransactionDisplayCount } from "./TransactionDisplayCount";
import { TransactionList } from "./TransactionList";

export interface TransactionFiltersProps {
  showFilterAndSort: boolean;
}
export const Transactions = () => {
  const { currentAccount, apiClient, isProductAdmin } = useAuthentication();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { query } = useQueryString({});

  const {
    loading,
    transactions,
    totalTransactions,
    queryStringFilter,
    filters,
    showLaborCosts,
    initCompleted,
  } = useSelector((state: RootState) => state.transactionList);
  const { currentPage, pageSize } = filters;
  const { isReviewer } = useSelector((state: RootState) => state.localCache);

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const filterTextColor = useColorModeValue("gray.700", "whiteAlpha.900");

  const showFilterAndSort = useMemo(() => {
    return !isReviewer || isProductAdmin;
  }, [isProductAdmin, isReviewer]);

  const dateRangeSelection = useMemo(() => {
    if (!filters.date) return "No Date Filters Applied";
    const field = DateFilterValues[filters.date.field];
    let value = "";
    if (filters.date.type === "relative") {
      value = DateFilterValues[filters.date.value as RelativeDateOptions];
    } else {
      value = `Custom from ${filters.date.value[0]} to ${filters.date.value[1]}`;
    }
    const res = `${field}:  ${value}`;
    return res;
  }, [filters.date]);

  const initOnce = useRef(false);
  useEffect(() => {
    if (initOnce.current) {
      return;
    }
    initOnce.current = true;
    thunkDispatch(
      initTransactionList({
        account: currentAccount,
        apiClient,
      }),
    ).then(() => {
      let decodedFilter = null;
      try {
        decodedFilter = JSON.parse(atob(query.get("filter") || ""));
      } catch (e) {
        decodedFilter = null;
      }

      if (decodedFilter) {
        dispatch(updateTransactionFilters(decodedFilter));
      }
      dispatch(setInitCompleted(true));
    });
  }, [dispatch, thunkDispatch, apiClient, currentAccount, query]);
  useEffect(() => {
    return () => {
      dispatch(unloadTransactionList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!initCompleted) {
      return;
    }
    thunkDispatch(
      loadTransactions({
        apiClient,
        account: currentAccount,
      }),
    );
  }, [apiClient, currentAccount, thunkDispatch, initCompleted, filters]);

  useEffect(() => {
    if (!queryStringFilter || !initCompleted) {
      return;
    }

    const currentValue = query.get("filter");
    const mustUpdate = currentValue !== queryStringFilter;

    if (mustUpdate) {
      navigate(`/transactions?filter=${queryStringFilter}`, { replace: true });
    }
  }, [queryStringFilter, navigate, initCompleted, filters, query]);

  const handleTransactionPageChange = useCallback(
    (page: number) => {
      dispatch(setCurrentPage(page));
      thunkDispatch(loadTransactions({ apiClient, account: currentAccount }));
    },
    [dispatch, thunkDispatch, apiClient, currentAccount],
  );

  const handleShowLaborCosts = useCallback(() => {
    dispatch(setShowLaborCosts());
  }, [dispatch]);

  return (
    <Container maxWidth="8xl" minH="6xl">
      <Flex w="100%" justifyContent="space-between" gap={2} alignItems="center">
        <Heading size="lg" alignItems="center" py={2} mb={3}>
          Transactions
        </Heading>

        <Flex gap={2}>
          <Button
            variant="outline"
            colorScheme={!showLaborCosts ? "blue" : "red"}
            onClick={handleShowLaborCosts}
          >
            {!showLaborCosts ? "Show Labor Costs" : "Hide Labor Costs"}
          </Button>
          <TransactionActionFilters />
        </Flex>
      </Flex>
      <Stack>
        {!isMobileMode && !isTablet ? (
          <>
            <Box>
              {showFilterAndSort && (
                <>
                  <TransactionAdvancedFilters
                    showFilterAndSort={showFilterAndSort}
                  />
                  <Text textAlign="right" color={filterTextColor}>
                    {dateRangeSelection}
                  </Text>
                </>
              )}
            </Box>
          </>
        ) : (
          <Box alignItems={"center"} py={1} mb={3}>
            <Box my={5}>
              <Divider />
            </Box>
            <VStack width={"full"}>
              <Flex width={"full"} justifyContent="space-between">
                <TransactionDisplayCount />
              </Flex>
              {showFilterAndSort && (
                <>
                  <Flex width={"full"} justifyContent="space-between">
                    <TransactionAdvancedFilters
                      showFilterAndSort={showFilterAndSort}
                    />
                  </Flex>
                  <Text
                    alignSelf="flex-end"
                    textAlign="right"
                    fontSize="xs"
                    color={filterTextColor}
                  >
                    {dateRangeSelection}
                  </Text>
                </>
              )}
            </VStack>
          </Box>
        )}

        {loading === "idle" || loading === "pending" ? (
          <Box>
            <SkeletonTransactions />
          </Box>
        ) : transactions.length > 0 ? (
          <Stack gap={4}>
            <TransactionList />
          </Stack>
        ) : (
          <Box pt={8}>
            {isReviewer && !isProductAdmin ? (
              <Center pt={8}>
                <Box textAlign="center">
                  <Text m={4}>You currently have no active Transactions.</Text>
                  <Text>
                    Create a new Transaction after creating a request.
                  </Text>
                </Box>
              </Center>
            ) : (
              <Text>No transactions found matching the criteria</Text>
            )}
          </Box>
        )}

        <SimpleGrid columns={isMobileMode ? 1 : 2} pt={6}>
          {!isMobileMode && (
            <Box pt={2}>
              <TransactionDisplayCount />
            </Box>
          )}
          <Flex justifyContent={{ base: "center", md: "end", lg: "end" }}>
            <Pager
              currentPage={currentPage}
              pageSize={pageSize}
              total={totalTransactions}
              onPageChange={handleTransactionPageChange}
            />
          </Flex>
        </SimpleGrid>
      </Stack>
    </Container>
  );
};
