import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import SchedulesList from "../scheduled-request-list/ScheduleRequestList";
import { AccountWrapper } from "./AccountRoutesWrapper";

const FieldForm = lazy(() => import("../field-form/FieldForm"));
const FieldList = lazy(() => import("../field-list/FieldList"));
const SchemaForm = lazy(() => import("../schema-form/SchemaForm"));
const SchemaList = lazy(() => import("../schema-list/SchemaList"));
const TaskBooksForm = lazy(() => import("../taskbook-form/TaskBookForm"));
const Taskbook = lazy(() => import("../taskbook-view/Taskbook"));
const TaskbookList = lazy(() => import("../taskbook-list/TaskbookList"));
const WorkflowList = lazy(() => import("../workflow-list/WorkflowList"));
const ReasonList = lazy(() => import("../reason-list/ReasonList"));
const HeroHQSettings = lazy(() => import("../herohq-settings/HeroHQSettings"));
const ReportingCategoryList = lazy(
  () => import("../reporting-category-list/ReportingCategoryList"),
);
const WorkflowSettings = lazy(
  () => import("../workflow-settings/WorkflowSettings"),
);

export const useRequestRoutes = (hasRequests: boolean) => {
  const { isProductAdmin } = useAuthentication();

  if (!hasRequests || !isProductAdmin) return { routes: [] };

  const routes: React.ReactNode[] = [
    <Route
      path="workflows"
      key="routes::request/worflows"
      element={<WorkflowList />}
    />,
    <Route
      path="workflows/:idOrSlug"
      key="routes::request/worflows/idOrSlug"
      element={<WorkflowSettings />}
    />,
    <Route
      path="reporting-categories"
      key="routes::request/reporting-categories"
      element={<ReportingCategoryList />}
    />,
    <Route
      path="reasons"
      key="routes::request/reasons"
      element={<AccountWrapper Element={ReasonList} />}
    />,
    <Route
      path="schemas"
      key="routes::request/schemas"
      element={<AccountWrapper Element={SchemaList} />}
    />,
    <Route
      path="schemas/:schemaId"
      key="routes::request/schemas/schemaId"
      element={<AccountWrapper Element={SchemaForm} />}
    />,
    <Route
      path="fields"
      key="routes::request/fields"
      element={<AccountWrapper Element={FieldList} />}
    />,
    <Route
      path="fields/:fieldId"
      key="routes::request/fields/fieldId"
      element={<AccountWrapper Element={FieldForm} />}
    />,
    <Route
      path="taskbooks"
      key="routes::request/taskbooks"
      element={<AccountWrapper Element={TaskbookList} />}
    />,
    <Route
      path="taskbooks/new"
      key="routes::request/taskbooks/new"
      element={<AccountWrapper Element={TaskBooksForm} />}
    />,
    <Route
      path="taskbooks/:id"
      key="routes::request/taskbooks/id"
      element={<AccountWrapper Element={Taskbook} />}
    />,
    <Route
      path="taskbooks/edit/:id"
      key="routes::request/taskbooks/edit/id"
      element={<AccountWrapper Element={TaskBooksForm} />}
    />,
    <Route
      path="scheduled-requests"
      key="routes::request/scheduled-requests"
      element={<AccountWrapper Element={SchedulesList} />}
    />,
    <Route
      path="herohq-settings"
      key="routes::request/herohq-settings"
      element={<AccountWrapper Element={HeroHQSettings} />}
    />,
  ];

  return { routes };
};
