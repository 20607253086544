import { Box } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import {
  AsyncSelect,
  MultiValue,
  MultiValueGenericProps,
} from "chakra-react-select";
import { FC, FocusEventHandler, useCallback, useMemo } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  UserOptionsProps,
} from "./select-overrides-types";

export interface UserAutocompleteProps {
  name?: string;
  value: ApiUserSummary[] | null;
  allowEmpty?: boolean;
  emptyText?: string;
  onChange: (value: MultiValue<ApiUserSummary>) => void;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  isInvalid?: boolean;
  excludeProductsByName?: string[];
}

const CustomOptionComponent = createOptionComponent(UserBadge);

export function CustomMultiValueLabelComponent(
  props: MultiValueGenericProps<ApiUserSummary>,
) {
  const { data, innerProps } = props;

  if (data.id && data.id === "null-user") {
    return <>{data.firstName}</>;
  }
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
}

export const UserMultipleAutocomplete: FC<UserAutocompleteProps> = ({
  allowEmpty,
  emptyText,
  onChange,
  onBlur,
  name,
  value: user,
  isDisabled,
  isInvalid,
  excludeProductsByName,
}) => {
  const { currentAccount, apiClient } = useAuthentication();

  const handleChange = useCallback(
    (newValue: MultiValue<ApiUserSummary>) => {
      onChange(newValue);
    },
    [onChange],
  );

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      const productRoles = await apiClient.findProductRoles(currentAccount.id);

      const roles = productRoles.filter((pr) => {
        if (!excludeProductsByName) return true;
        return !excludeProductsByName.some(
          (productName) => productName === pr.product.name,
        );
      });

      const rolesIds = roles.map((r) => r.id);
      let response: ApiUserSummary[];
      const res = await apiClient.findAccountUsers(currentAccount.id, {
        search: inputValue,
        productRoles: rolesIds,
        pageSize: 100,
      });
      response = res.data;
      cb(response);
      return response;
    },
    [apiClient, currentAccount.id, excludeProductsByName],
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: UserOptionsProps) => CustomOptionComponent(props),
      MultiValueLabel: (props: any) => CustomMultiValueLabelComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      name={name}
      onBlur={onBlur}
      defaultOptions={true}
      value={user || null}
      cacheOptions={true}
      loadOptions={loadOptions}
      onChange={handleChange}
      isMulti={true}
      chakraStyles={commonStyles}
      components={components}
      getOptionValue={(item: ApiUserSummary) => item.id}
      isDisabled={isDisabled}
    />
  );
};
