import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { useProductSubscriptions } from "../components/shell/AppShell";
import { AllowedProductSearch } from "../components/shell/nav/types";
import { RootState } from "../store";

export const useNavAllowedProductSearch = () => {
  const { isApprover, isTechnician, isReviewer, isAdmin, eventsHandler } =
    useSelector((root: RootState) => root.localCache);

  const { isVenueManager } = eventsHandler.venueManagers;

  const { enableInvoicesForEvents } = useSelector(
    (root: RootState) => root.eventSettingsSlice
  );
  const { isEventAdmin, isInventoryAdmin, isProductAdmin } =
    useAuthentication();

  const { hasRequests, hasEvents, hasInventory } = useProductSubscriptions();

  const allowedProductSearch: AllowedProductSearch = useMemo(() => {
    const hasWorkflowAccess =
      isApprover || isTechnician || isReviewer || isAdmin;
    return {
      canSearchRequests: hasRequests && (isProductAdmin || hasWorkflowAccess),
      canSearchEvents: hasEvents && (isEventAdmin || isVenueManager),
      canSearchInvRequests: hasInventory && isInventoryAdmin,
      canSearchInvoices: hasEvents && !!enableInvoicesForEvents && isEventAdmin,
      canSearchOrders: hasInventory && isInventoryAdmin,
    };
  }, [
    hasRequests,
    isProductAdmin,
    isApprover,
    isTechnician,
    isReviewer,
    isAdmin,
    hasEvents,
    isEventAdmin,
    isVenueManager,
    hasInventory,
    isInventoryAdmin,
    enableInvoicesForEvents,
  ]);

  return { allowedProductSearch };
};
