import { Button, HStack, Icon, useBreakpointValue } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setCurrentPage,
  updateRequestFilters,
} from "../../store/request-list.slice";
import { debounce } from "../../utils/debounce";

const BLOCK_SIZE = 3;

export interface RequestPageProps {
  disabled?: boolean;
}

export const RequestPager: FC<RequestPageProps> = ({ disabled }) => {
  const dispatch = useDispatch();

  const showFirstLastPageArrows = useBreakpointValue({ base: false, md: true });

  const totalRequests = useSelector(
    (state: RootState) => state.requestList.totalRequests
  );
  const pageSize = useSelector(
    (state: RootState) => state.requestList.filters.pageSize
  );
  const currentPage = useSelector(
    (state: RootState) => state.requestList.filters.currentPage
  );

  const numberOfPages = useMemo(() => {
    if (totalRequests === 0) return 0;

    return Math.ceil(totalRequests / pageSize);
  }, [totalRequests, pageSize]);

  const pages = useMemo(() => {
    const currBlock = Math.ceil(currentPage / BLOCK_SIZE);

    const result: number[] = [];

    const startingPageIndex = (currBlock - 1) * BLOCK_SIZE;
    for (let i = 1; i <= BLOCK_SIZE; i++) {
      if (startingPageIndex + i > numberOfPages) {
        return result;
      }

      result.push(startingPageIndex + i);
    }

    return result;
  }, [numberOfPages, currentPage]);

  const isPagerDisabled = useCallback(
    (pageNumberToCompare?: number) => {
      return (
        numberOfPages === 1 || currentPage === pageNumberToCompare || disabled
      );
    },
    [currentPage, disabled, numberOfPages]
  );

  const gotoPage = useCallback(
    (targetPage: number) => {
      const page =
        targetPage > numberOfPages
          ? numberOfPages
          : targetPage < 1
            ? 1
            : targetPage;
      dispatch(setCurrentPage(page));
      dispatch(updateRequestFilters({ currentPage: page }));
      sessionStorage.setItem("scroll", "0");
      sessionStorage.setItem("from", "request");
    },
    [dispatch, numberOfPages]
  );

  const prevPage = useCallback(() => {
    gotoPage(currentPage - 1);
  }, [gotoPage, currentPage]);

  const nextPage = useCallback(() => {
    gotoPage(currentPage + 1);
  }, [gotoPage, currentPage]);

  const debouncedNextPage = debounce(nextPage, 200);
  const debouncedPrevPage = debounce(prevPage, 200);
  const debouncedGoToPage = debounce(gotoPage, 200);

  return numberOfPages > 0 ? (
    <HStack spacing={1} justifyContent="flex-end">
      {showFirstLastPageArrows && (
        <Button
          isDisabled={isPagerDisabled(1)}
          onClick={() => debouncedGoToPage(1)}
        >
          <Icon as={HiChevronDoubleLeft} />
        </Button>
      )}
      <Button
        isDisabled={isPagerDisabled(1)}
        onClick={() => debouncedPrevPage()}
      >
        <Icon as={HiChevronLeft} />
      </Button>
      {pages[0] > 1 && (
        <Button
          onClick={() => debouncedGoToPage(currentPage - BLOCK_SIZE)}
          isDisabled={disabled}
        >
          ...
        </Button>
      )}
      {pages.map((page) => (
        <Button
          key={`pager-${page}`}
          colorScheme={page === currentPage ? "blue" : undefined}
          onClick={() => debouncedGoToPage(page)}
          isDisabled={disabled}
        >
          {page}
        </Button>
      ))}
      {pages[pages.length - 1] < numberOfPages && (
        <Button
          onClick={() => debouncedGoToPage(currentPage + BLOCK_SIZE)}
          isDisabled={disabled}
        >
          ...
        </Button>
      )}
      <Button
        isDisabled={isPagerDisabled(numberOfPages)}
        onClick={() => debouncedNextPage()}
      >
        <Icon as={HiChevronRight} />
      </Button>
      {showFirstLastPageArrows && (
        <Button
          isDisabled={isPagerDisabled(numberOfPages)}
          onClick={() => debouncedGoToPage(numberOfPages)}
        >
          <Icon as={HiChevronDoubleRight} />
        </Button>
      )}
    </HStack>
  ) : null;
};
