import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiUser } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useMemo } from "react";
import { MdAdd, MdExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  addTransactionToMap,
  DATE_FORMAT_TIMESHEET,
  unloadNewTransactions,
} from "../../store/timesheet.slice";
import { getFormatedHour } from "../request-form/transactions/transactions-helper";
import { PopoverTransactionRow } from "./PopoverTransactionRow";

interface HourLaborTimesheetProps {
  date: Date;
  reqKey: string;
  user: ApiUser;
}

export const HourLaborTimesheet: FC<HourLaborTimesheetProps> = ({
  date,
  reqKey,
  user,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const dispatch = useDispatch();
  const { transactionsMap, isWorkingTransaction } = useSelector(
    (state: RootState) => state.timesheet,
  );

  const key = useMemo(
    () => `${reqKey}::${format(date, DATE_FORMAT_TIMESHEET)}`,
    [date, reqKey],
  );

  const addTransactionRow = useCallback(() => {
    dispatch(addTransactionToMap({ key, user }));
  }, [dispatch, user, key]);

  const validationMap = useMemo(() => {
    return Boolean(transactionsMap[key] && transactionsMap[key].length);
  }, [transactionsMap, key]);

  const handleOnOpen = useCallback(() => {
    if (!validationMap) {
      dispatch(addTransactionToMap({ key, user }));
    }
    onOpen();
  }, [dispatch, onOpen, user, key, validationMap]);

  useEffect(() => {
    return () => {
      dispatch(unloadNewTransactions(key));
    };
  }, [dispatch, key]);

  return (
    <Menu isOpen={isOpen} onOpen={handleOnOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        rightIcon={
          validationMap && transactionsMap[key].length > 1 ? (
            <MdExpandMore />
          ) : undefined
        }
        variant="ghost"
        _hover={{ background: "none" }}
        _active={{ background: "none" }}
        shadow="none"
        boxShadow="none"
        _focus={{ shadow: "none" }}
      >
        <Flex cursor="pointer" justifyContent="center">
          {validationMap ? (
            getFormatedHour(
              transactionsMap[key].reduce(
                (acc, current) => acc + current.hours,
                0,
              ),
            )
          ) : (
            <Text color="gray.500">0 hrs</Text>
          )}
        </Flex>
      </MenuButton>
      {isOpen && (
        <MenuList minWidth={["sm", "sm", "md"]}>
          <MenuGroup>
            {transactionsMap[key].map((tr) => (
              <PopoverTransactionRow
                key={`${tr.id}`}
                transaction={tr}
                keyMap={key}
              />
            ))}
            <Flex w="100%">
              <Button
                justifySelf="flex-start"
                size={"sm"}
                onClick={() => addTransactionRow()}
                variant="ghost"
                colorScheme="blue"
                isDisabled={Boolean(isWorkingTransaction)}
              >
                <Icon as={MdAdd} mr={2} />
                Add labor
              </Button>
            </Flex>
          </MenuGroup>
        </MenuList>
      )}
    </Menu>
  );
};
