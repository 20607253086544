import {
  Box,
  Button,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";

interface RemoveModalProps {
  removeItemId: string | null | undefined;
  onClose: () => void;
  cb: (taskbookId: string) => void;
}

export const RemoveTaskboookModal: FC<RemoveModalProps> = ({
  removeItemId,
  onClose,
  cb,
}) => {
  const handleExecuteCallBack = useCallback(async () => {
    if (removeItemId) cb(removeItemId);
  }, [cb, removeItemId]);

  return (
    <ModalContent>
      <ModalHeader>
        <Text fontWeight="700" fontSize="lg">
          Are you sure you want to delete this task book?
        </Text>
      </ModalHeader>
      <ModalFooter>
        <Box width="60%">
          <Text
            maxW="58px"
            color="blue.500"
            fontWeight="500"
            cursor="pointer"
            onClick={onClose}
          >
            Cancel
          </Text>
        </Box>
        <Button colorScheme="red" onClick={handleExecuteCallBack} size="sm">
          Yes, Delete Task Book
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
