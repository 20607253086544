import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiDelta,
  ApiInventoryOrderItem,
  ApiInventoryOrderSummary,
  ApiTimelineEntry,
  ApiTimelineEntryObjectRelation,
  TimelineEntryAction,
} from "@operations-hero/lib-api-client";
import { FC, Fragment } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { DateBadge } from "../../../../components/badges/DateBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { TimeLineEntryFields } from "../../../../utils/timelineHelper";
import { AdjustmentEntry } from "./AdjustmentEntry";
import { ItemEntry } from "./ItemEntry";

export interface DeltaElementProps {
  delta: ApiDelta;
  relationObject: ApiTimelineEntryObjectRelation | null;
  inventoryOrder: ApiInventoryOrderSummary;
}

const DeltaElement: FC<DeltaElementProps> = ({
  delta,
  relationObject,
  inventoryOrder,
}) => {
  const { field } = delta;
  const textColorStatus = useColorModeValue("blue.600", "blue.200");

  switch (field) {
    case "status":
      return (
        <Flex gap={2}>
          <Text fontWeight="bold">{inventoryOrder.supplier?.name} Order</Text>
          <Text>
            {`${inventoryOrder.supplier?.name.replace(/\s/g, "").substring(0, 4).toUpperCase().trim() || ""}-${inventoryOrder.key} status was change to`}
          </Text>
          <Text textColor={textColorStatus} fontWeight="semibold">
            {capitalizeFirstLetter((delta.newValue as string) || "")}
          </Text>
        </Flex>
      );
    case "inventoryOrderNumber":
    case "invoiceNumber":
    case "purchaseOrder":
    case "note": {
      const newValue = delta.newValue ? (delta.newValue as string) : null;
      const oldValue = delta.oldValue ? (delta.oldValue as string) : null;

      return (
        <VStack gap={1} p={2} alignItems="flex-start">
          <Flex alignItems="center" gap={1}>
            <Text fontWeight="semibold" as="span">
              {TimeLineEntryFields[field]}:
            </Text>
            {oldValue && <Text as="span">{oldValue}</Text>}
            {newValue && (
              <>
                <Icon as={MdArrowRightAlt} />
                <Text as="span">{newValue}</Text>
              </>
            )}
          </Flex>
        </VStack>
      );
    }
    case "expectedDate": {
      const { oldValue, newValue } = delta;
      const formatedOldValue = oldValue ? (oldValue as string) : null;
      const formatedNewValue = newValue ? (newValue as string) : null;

      return (
        <>
          <Box ml={2} w="full" display="flex">
            <Text display="inline" fontWeight="semibold">
              Date:
            </Text>
            {formatedNewValue && (
              <Box
                display="flex"
                alignItems="center"
                gap="2"
                pl="2"
                flexWrap="wrap"
                w="max-content"
                flexBasis="full"
              >
                <DateBadge date={formatedOldValue} showRelative={false} />

                <Icon as={MdArrowRightAlt} />
                <DateBadge date={formatedNewValue} showRelative={false} />
              </Box>
            )}
          </Box>
        </>
      );
    }
    default:
      return null;
  }
};

export interface EntryBodyProps {
  entry: ApiTimelineEntry;
  inventoryOrder: ApiInventoryOrderSummary;
}

export const InventoryOrderTimelineBody: FC<EntryBodyProps> = ({
  entry,
  inventoryOrder,
}) => {
  const { action, delta, relationObject, relation } = entry;
  const textColorItems = useColorModeValue("gray.600", "gray.200");
  const sortedDelta = delta
    ? delta.sort((a, b) => {
        if (a.field === "status") return -1;
        if (b.field === "status") return 1;
        return 0;
      })
    : null;

  if (action === TimelineEntryAction.create && relation === null) {
    return (
      <>
        <Flex gap={2}>
          <UserBadge
            textStyle={{
              fontSize: "m",
              marginBottom: "8px",
            }}
            value={entry.createdBy}
            textFontWeight="bold"
            includeAvatar={false}
          />
          <Text>placed</Text>
          <Text fontWeight="bold">{inventoryOrder.supplier?.name} Order</Text>
          <Text>
            {`${inventoryOrder.supplier?.name.replace(/\s/g, "").substring(0, 4).toUpperCase().trim() || ""}-${inventoryOrder.key}`}
          </Text>
        </Flex>
        <Flex gap={2}>
          <Text
            textColor={textColorItems}
          >{`Items[${inventoryOrder.totalItems}]:`}</Text>
          {sortedDelta?.map((delta) => (
            <Text textColor={textColorItems} fontWeight="bold">
              {`${(delta.newValue && (delta.newValue as ApiInventoryOrderItem)?.item?.name) || ""}`}
              {`(x${(delta.newValue && (delta.newValue as ApiInventoryOrderItem)?.requested) || ""}) `}
            </Text>
          ))}
        </Flex>
      </>
    );
  }

  if (relation === "item")
    return <ItemEntry entry={entry} inventoryOrder={inventoryOrder} />;

  if (relation === "adjustment")
    return <AdjustmentEntry entry={entry} inventoryOrder={inventoryOrder} />;

  if (!sortedDelta) return null;
  return (
    <>
      {sortedDelta.map((deltaItem, idx) => (
        <Fragment key={`${deltaItem.field}::${idx}`}>
          <DeltaElement
            delta={deltaItem}
            relationObject={relationObject}
            inventoryOrder={inventoryOrder}
          />
        </Fragment>
      ))}
    </>
  );
};
