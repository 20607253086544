import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LocalEventDetailsSliceState } from "../event-details.slice";

export const DEFAULT_EVENT_NOTIFICATION = {
  loading: "idle",
  page: 1,
  total: 0,
  pageSize: 10,
  data: [],
};

export interface LoadEventNotificationParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  user?: string | string[];
}

export const loadEventNotification = createAsyncThunk(
  "event/notification",
  async (params: LoadEventNotificationParams, thunkAPI) => {
    const { apiClient, accountId, eventId, user } = params;

    const { pageSize, page } = (thunkAPI.getState() as RootState).eventDetails
      .notifications;

    const response = await apiClient.findEventNotificationHistory(
      accountId,
      eventId,
      {
        user: user || [],
        page,
        pageSize,
      }
    );

    return response;
  }
);

export const eventNotificationHandler = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(loadEventNotification.fulfilled, (state, action) => {
    const { data, options, total } = action.payload;
    state.notifications.loading = "succeeded";
    state.notifications.data = data;
    state.notifications.total = total;
    state.notifications.page = options.page || 1;
  });
  builder.addCase(loadEventNotification.rejected, (state) => {
    state.notifications.loading = "failed";
  });
  builder.addCase(loadEventNotification.pending, (state) => {
    state.notifications.loading = "pending";
  });
};
