import { BoxProps, Button, FormLabel, Wrap, WrapItem } from "@chakra-ui/react";
import { FC, useCallback, useEffect } from "react";
import { CronBoxOption } from "./cron-helpers/CronOptions";

interface CronDaysProps {
  label?: string;
  boxProps: BoxProps;
  options: CronBoxOption[];
  onChangeOption: (option: CronBoxOption) => void;
  enabledDay?: string;
  allowedDays?: number[];
}

export const CronOptions: FC<CronDaysProps> = ({
  label,
  options,
  boxProps,
  onChangeOption,
  enabledDay,
  allowedDays,
}) => {
  // useMemo did not work here
  // if none are selected the cron parser assumes *
  const allSelected = options.every((x) => x.isSelected === false);

  const handleChangeMiddleware = useCallback(
    (option: CronBoxOption) => {
      if (allSelected) {
        // turn them all on if its using * cron,
        // before toggling it off.
        options.forEach((o) => {
          if (o.value !== option.value) onChangeOption(o);
        });
      } else {
        // toggle the one off
        onChangeOption(option);
      }
    },
    [allSelected, options, onChangeOption]
  );

  const handleDisabledFields = useCallback(() => {
    if (enabledDay) {
      options.forEach((option) => {
        if (enabledDay !== option.value) {
          if (option.isSelected) {
            onChangeOption(option);
          }
        } else {
          if (!option.isSelected) {
            onChangeOption(option);
          }
        }
      });
    }
  }, [options, enabledDay, onChangeOption]);

  useEffect(() => {
    enabledDay && handleDisabledFields();
  }, [handleDisabledFields, enabledDay]);

  return (
    <>
      <FormLabel mt={4}>{label}</FormLabel>
      <Wrap flexWrap="wrap" alignItems="flex-end" maxW={boxProps.maxW} mt={2}>
        {options.map((option) => (
          <WrapItem key={option.value}>
            <Button
              _hover={{ backgroundColor: "none" }}
              minW="40px"
              maxW="40px"
              fontSize="sm"
              key={option.label}
              borderRadius={8}
              variant={allSelected || option.isSelected ? "solid" : "outline"}
              colorScheme={allSelected || option.isSelected ? "blue" : "gray"}
              onClick={() => handleChangeMiddleware(option)}
              isDisabled={
                allowedDays && allowedDays.length > 0
                  ? !allowedDays.some((ad) => ad.toString() === option.value)
                  : false
              }
            >
              {option.label}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
    </>
  );
};
