import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ApiAsset, ApiRequest } from "@operations-hero/lib-api-client";
import { useCallback, useMemo, useState } from "react";

export interface DeleteAssetConfirmModalProps {
  asset: ApiAsset;
  request: ApiRequest;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

export const DeleteAssetConfirmModal = ({
  asset,
  request,
  onConfirm,
  onCancel,
}: DeleteAssetConfirmModalProps) => {
  const isOpen = useMemo(() => !!asset, [asset]);
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    onConfirm().finally(() => {
      setLoading(false);
    });
  }, [onConfirm]);

  return asset && request && isOpen ? (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove Asset?</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <Text>
            Remove <strong>{asset.name}</strong> from request{" "}
            <strong>{request.key}</strong>?
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            onClick={handleConfirm}
            colorScheme="red"
            isDisabled={loading}
            isLoading={loading}
          >
            Remove
          </Button>
          <Button onClick={onCancel} isDisabled={loading}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
};
