import { Badge, Box, Icon } from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  GroupBase,
  OptionProps,
  Select,
  SingleValueProps,
} from "chakra-react-select";
import React, { FC, useCallback, useMemo } from "react";
import { IoCaretDownOutline } from "react-icons/io5";
import { BADGE_LABEL_COLOR } from "./RequestTypeSelect";
import { createOptionComponent, CustomSelectStyles } from "./select-overrides";
import { CustomSelectComponentProp } from "./select-overrides-types";

export type CommonValuesType = {
  value: string;
  label: string;
};

export type CustomStyles = ChakraStylesConfig<
  { value: string; label: string },
  false,
  GroupBase<{ value: string; label: string }>
>;

export type StringSingleValueProps = SingleValueProps<
  CommonValuesType,
  false,
  GroupBase<CommonValuesType>
>;

const CommonOptionBadge: React.FC<{
  value: CommonValuesType;
  isSelected: boolean;
}> = ({ value, isSelected }) => (
  <Box cursor="pointer">
    <Badge
      variant="none"
      fontSize="sm"
      color={isSelected ? "white" : BADGE_LABEL_COLOR}
    >
      {value.value}
    </Badge>
  </Box>
);

const CustomOptionComponent = createOptionComponent(CommonOptionBadge);

const CustomSingleValueComponent = (
  props: SingleValueProps<CommonValuesType, false, GroupBase<CommonValuesType>>
) => {
  const { innerProps } = props;
  return (
    <Box {...innerProps} cursor="pointer">
      <Badge variant="none" fontSize="sm" color={BADGE_LABEL_COLOR}>
        Action <Icon as={IoCaretDownOutline} boxSize={3} />
      </Badge>
    </Box>
  );
};

export interface CommonSelectProps {
  value: string;
  name?: string;
  options: string[];
  isDisabled?: boolean;
  defaultValue?: CommonValuesType;
  onChange?: (status: string) => void;
}

export const CommonStringSelect: FC<CommonSelectProps> = ({
  name,
  value,
  onChange,
  options,
  isDisabled,
  defaultValue,
}: CommonSelectProps) => {
  const selectOptions: CommonValuesType[] = useMemo(
    () => options.map((item) => ({ value: item, label: item })),
    [options]
  );

  const handleChange = useCallback(
    (item: CommonValuesType | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange]
  );

  const components = useMemo(
    (): CustomSelectComponentProp => ({
      SingleValue: (props: StringSingleValueProps) =>
        CustomSingleValueComponent(props),
      Option: (
        props: OptionProps<CommonValuesType, false, GroupBase<CommonValuesType>>
      ) => CustomOptionComponent(props),
      IndicatorSeparator: null,
      DropdownIndicator: null,
    }),
    []
  );

  return (
    <Select
      name={name}
      options={selectOptions}
      isClearable={false}
      isSearchable={false}
      menuPlacement="auto"
      components={components}
      isDisabled={isDisabled}
      defaultValue={defaultValue || null}
      onChange={handleChange}
      chakraStyles={CustomSelectStyles() as CustomStyles}
      getOptionLabel={(entry) => entry.label}
      value={selectOptions.find((x) => x.value === value)}
    />
  );
};
