export const getTimeZoneIanaFormat = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

export const getDateFromISOString = (date: string | Date) => {
  const workingDate = typeof date === "string" ? new Date(date) : date;
  const isoDateTime = new Date(
    workingDate.getTime() - workingDate.getTimezoneOffset() * 60000
  );
  const response = new Date(isoDateTime);
  return response;
};
