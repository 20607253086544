import {
  Box,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { useScreenBreakpoints } from "../../hooks/useScreenBreakpoints";

const SkeletonMobile = () => (
  <Box as="li" p={6} display="flex" flexDir="column" gap={4}>
    <Skeleton height={7} />
    <Box display="flex" justifyContent="space-between">
      <Box w={40}>
        <SkeletonText noOfLines={2} spacing="6" maxWidth={70} />
      </Box>
      <Box w={40}>
        <Skeleton height={2} mb="6" />
        <Skeleton height={2} ml="9" />
      </Box>
    </Box>
    <Box>
      <SkeletonText noOfLines={2} spacing="3" maxW="150" />
      <SkeletonText noOfLines={2} spacing="4" maxW="80%" mt="6" />
    </Box>
    <Box display="flex" gap={3} justifyContent="center" mt="6">
      <Skeleton height={6} w={150} bg="green" />
      <Skeleton height={6} w={8} bg="red" />
    </Box>
  </Box>
);

const SkeletonDektop: FC<SkeletonRequestsProps> = ({ includeBorder }) => {
  const unselectedBorderColor = useColorModeValue("gray.200", "gray.600");
  return (
    <Box
      as="li"
      p={3}
      display="flex"
      gap={4}
      borderRadius={4}
      border={includeBorder ? "1px solid" : "unset"}
      borderColor={unselectedBorderColor}
    >
      <Box display="flex" flexDirection="column" w={110} gap={3}>
        <Skeleton height={4} />
        <Skeleton height={77} />
      </Box>
      <Box w={110} gap={4}>
        <SkeletonCircle size="6" display="inline-block" />
        <SkeletonText mt="6" noOfLines={2} spacing="6" />
      </Box>
      <Box flex={1}>
        <SkeletonText ml={8} noOfLines={2} spacing="3" maxW="150" />
        <SkeletonText mt="8" ml={8} noOfLines={2} spacing="4" maxW="80%" />
      </Box>
      <Box display="flex" w={209} gap={3} alignItems="flex-end">
        <Skeleton height={6} flex={1} />
        <Skeleton height={6} w="8" />
      </Box>
    </Box>
  );
};

interface SkeletonRequestsProps {
  includeBorder?: boolean;
}

export const SkeletonRequests: FC<SkeletonRequestsProps> = ({
  includeBorder,
}) => {
  const { isMobile } = useScreenBreakpoints();
  const fakeRows = useMemo(() => [1, 2, 3, 4, 5], []);

  return (
    <Box as="ul" w="100%" display="flex" gap={2} flexDir="column">
      {isMobile
        ? fakeRows.map((item) => (
            <SkeletonMobile key={`request-skeleton::${item}`} />
          ))
        : fakeRows.map((item) => (
            <SkeletonDektop
              key={`request-skeleton::${item}`}
              includeBorder={includeBorder}
            />
          ))}
    </Box>
  );
};
