import { LaborType } from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { SingleValue } from "react-select";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";
interface LaborTypeAutocompleteProps {
  value: LaborType;
  onChange: (value: LaborType) => void;
}

const LaborTypeAutocomplete = ({
  value,
  onChange,
}: LaborTypeAutocompleteProps) => {
  const options = Object.entries(LaborType).map(([key, value]) => ({
    value: value,
    label: capitalizeFirstLetter(key),
  }));

  const handleChange = useCallback(
    (newValue: SingleValue<{ value: LaborType; label: string }>) => {
      if (newValue) {
        onChange(newValue.value);
      }
    },
    [onChange],
  );

  const components = useMemo(getCustomSelectComponents, []);

  return (
    <Select
      closeMenuOnSelect={true}
      options={options}
      onChange={handleChange}
      components={components}
      chakraStyles={commonStyles}
      value={options.find((x) => x.value === value)}
    />
  );
};

export default LaborTypeAutocomplete;
