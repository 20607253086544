import {
  ApiQuestion,
  ApiServiceSumary,
  ApiSpaceSummary,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import { ApiQuestionResponse } from "@operations-hero/lib-api-client/dist/models/QuestionResponse";

export interface QuestionWithResponses {
  question: ApiQuestion;
  questionResponse: ApiQuestionResponse;
}

export interface QuestionsResponses {
  eventId: string;
  object: ApiVenueSummary | ApiSpaceSummary | ApiServiceSumary;
  questionsResponses: QuestionWithResponses[];
}

export const questionsResponsesReducer = (
  values: QuestionsResponses[],
  action: QuestionsResponses[]
) => {
  return action;
};
