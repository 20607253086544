import { ApiWeeksOfMonth } from "@operations-hero/lib-api-client";
import { SingleValue } from "chakra-react-select";
import { getLastDayMonth } from "../../../utils/monthHelper";
import {
  areAllOptionsMarked,
  cleanCronBoxOptions,
  CRON_DAYS,
  CRON_MONTHS,
  CronBoxOption,
  CronFieldKey,
  getDateProps,
  getRepeatsOnOptions,
  markAllOptionsAsSelected,
  resetCronBoxOption,
} from "./CronOptions";

export const CRON_ANY = "*";
export const LAST_DAY_MONTH = "L";
export const FIRST_DAY_MONTH = "1";
export const CRON_SPACE_BETWEEN_FIELDS = " ";
export const CRON_LIST_SEPARATOR = ",";

const DEFAULT_CRON = "* * * * *";

export enum ActionTypeEnum {
  InitCronFields = "INIT_CRON_FIELDS",
  GetCronFields = "GET_CRON_VALUES",
  SetRepeat = "SET_REPEAT",
  SetRepeatsOn = "SET_REPEATS_ON",
  ToggleDay = "TOGGLE_DAY",
  ToggleMonth = "TOOGLE_MONTH",
  SetRecurrence = "SET_RECURRENCE",
  SetExecuteEvery = "SET_EXECUTE_NUMBER",
  UnloadCron = "UNLOAD_CRON",
  SetCronHourMinuteAndWeekOfMonth = "SET_CRON_HOUR_MINUTE_WEEK_MONTH",
}

export interface CronValuesProps {
  repeatNumber: number;
  recurrence: CronBoxOption | null;
  repeatsOn: CronBoxOption | null;
  days: CronBoxOption[];
  months: CronBoxOption[];
  cronMap: Map<CronFieldKey, string> | undefined;
  cronExpression: string;
  isLoading: boolean;
  executeEvery: number;
  weekOfMonth: ApiWeeksOfMonth;
}

export type CronAction =
  | { type: ActionTypeEnum.UnloadCron }
  | { type: ActionTypeEnum.InitCronFields }
  | { type: ActionTypeEnum.ToggleDay; payload: CronBoxOption }
  | { type: ActionTypeEnum.ToggleMonth; payload: CronBoxOption }
  | {
      type: ActionTypeEnum.SetRepeatsOn;
      payload: {
        option: SingleValue<CronBoxOption>;
        selectedDateStr: string | null;
      };
    }
  | { type: ActionTypeEnum.SetExecuteEvery; payload: number }
  | {
      type: ActionTypeEnum.SetCronHourMinuteAndWeekOfMonth;
      payload: {
        weekOfMonth: ApiWeeksOfMonth;
        selectedDateStr: string | null;
      };
    }
  | {
      type: ActionTypeEnum.SetRecurrence;
      payload: {
        option: SingleValue<CronBoxOption>;
        selectedDateStr: string | null;
      };
    };

export const CronReducer = (
  state: CronValuesProps,
  action: CronAction
): CronValuesProps => {
  if (!state.cronMap) return state;

  const getExpressionFromBoxOptions = (options: CronBoxOption[]) => {
    const areAllMarked = areAllOptionsMarked(options);
    if (areAllMarked) return CRON_ANY;

    const selectedOptions: string =
      options
        .filter((item) => item.isSelected)
        .map((item) => item.value)
        .join(`${CRON_LIST_SEPARATOR}`) || CRON_ANY;
    return selectedOptions;
  };

  const getUpdatedCronExpression = () => {
    if (!state.cronMap) return DEFAULT_CRON;
    const cronMap = new Map(state.cronMap);
    if (state.weekOfMonth) {
      cronMap.set("day-of-month", "*");
    }
    const newExpression = Array.from(cronMap.values()).join(
      CRON_SPACE_BETWEEN_FIELDS
    );
    return newExpression;
  };

  switch (action.type) {
    case ActionTypeEnum.SetRecurrence: {
      if (action.payload.option === null) return state;
      const selectedDate = action.payload.selectedDateStr
        ? new Date(action.payload.selectedDateStr)
        : new Date();

      const { minutes, hour, dayOfMonth, month, dayOfWeek } =
        getDateProps(selectedDate);

      let newDaysOptions = resetCronBoxOption(
        state.days.length ? state.days : CRON_DAYS
      );
      let newMonthOptions = resetCronBoxOption(
        state.months.length ? state.months : CRON_MONTHS
      );
      const cronMapCopy = new Map(state.cronMap);

      cronMapCopy.set("minute", minutes);
      cronMapCopy.set("hour", hour);

      if (action.payload.option.value === "daily") {
        cronMapCopy.set("day-of-month", CRON_ANY);
        cronMapCopy.set("month", CRON_ANY);
        cronMapCopy.set("day-of-week", CRON_ANY);

        newDaysOptions = newDaysOptions.map((day) => ({
          ...day,
          isSelected: true,
        }));

        newMonthOptions = markAllOptionsAsSelected(newMonthOptions);
      }

      if (action.payload.option.value === "weekly") {
        cronMapCopy.set("day-of-month", CRON_ANY);
        cronMapCopy.set("month", CRON_ANY);
        cronMapCopy.set("day-of-week", dayOfWeek);

        newDaysOptions = newDaysOptions.map((day) =>
          day.value === dayOfWeek ? { ...day, isSelected: true } : day
        );

        newMonthOptions = markAllOptionsAsSelected(newMonthOptions);
      }

      if (action.payload.option.value === "monthly") {
        cronMapCopy.set("day-of-month", "1");
        cronMapCopy.set("month", CRON_ANY);
        cronMapCopy.set("day-of-week", CRON_ANY);

        state.repeatsOn = getRepeatsOnOptions()[0];
        newMonthOptions = resetCronBoxOption(
          state.months.length ? state.months : CRON_MONTHS
        );
      }

      if (action.payload.option.value === "yearly") {
        cronMapCopy.set("day-of-month", `${dayOfMonth}`);
        cronMapCopy.set("month", `${month}`);
        cronMapCopy.set("day-of-week", CRON_ANY);
      }

      state.cronMap = cronMapCopy;
      const newCronExpression = getUpdatedCronExpression();

      return {
        ...state,
        days: newDaysOptions,
        months: newMonthOptions,
        cronMap: cronMapCopy,
        cronExpression: newCronExpression,
        recurrence: action.payload.option,
      };
    }

    case ActionTypeEnum.ToggleDay: {
      const index = state.days.findIndex(
        (day) => day.value === action.payload.value
      );
      const updatedDays = state.days;
      updatedDays[index] = {
        ...action.payload,
        isSelected: !action.payload.isSelected,
      };
      const selectedDays = getExpressionFromBoxOptions(updatedDays);
      const cronMapCopy = new Map(state.cronMap);
      cronMapCopy && cronMapCopy.set("day-of-week", selectedDays);

      state.cronMap = cronMapCopy;
      const newCronExpression = getUpdatedCronExpression();
      return {
        ...state,
        days: updatedDays,
        cronMap: cronMapCopy,
        cronExpression: newCronExpression,
      };
    }

    case ActionTypeEnum.ToggleMonth: {
      const index = state.months.findIndex(
        (month) => month.value === action.payload.value
      );

      const updatedMonths = state.months;
      updatedMonths[index] = {
        ...action.payload,
        isSelected: !action.payload.isSelected,
      };
      const selectedMonths = getExpressionFromBoxOptions(updatedMonths);
      const cronMapCopy = new Map(state.cronMap);
      cronMapCopy && cronMapCopy.set("month", selectedMonths);

      state.cronMap = cronMapCopy;
      const newCronExpression = getUpdatedCronExpression();
      return {
        ...state,
        months: updatedMonths,
        cronMap: cronMapCopy,
        cronExpression: newCronExpression,
      };
    }

    case ActionTypeEnum.SetCronHourMinuteAndWeekOfMonth: {
      const { weekOfMonth, selectedDateStr } = action.payload;
      const selectedDate = selectedDateStr
        ? new Date(selectedDateStr)
        : new Date();
      const { minutes, hour, dayOfMonth, month, dayOfWeek } =
        getDateProps(selectedDate);

      const cronMapCopy = new Map(state.cronMap);
      cronMapCopy.set("minute", minutes);
      cronMapCopy.set("hour", hour);

      if (state.recurrence?.value === "daily") {
        cronMapCopy.set("day-of-month", dayOfMonth);
      }

      if (state.recurrence?.value === "weekly") {
        cronMapCopy.set("day-of-month", CRON_ANY);
        cronMapCopy.set("month", CRON_ANY);
        cronMapCopy.set("day-of-week", dayOfWeek);
      }

      if (state.recurrence?.value === "yearly") {
        cronMapCopy.set("month", month);
        cronMapCopy.set("day-of-month", dayOfMonth);
      }

      if (state.recurrence?.value === "monthly") {
        cronMapCopy.set("day-of-month", dayOfMonth);
        if (weekOfMonth === null) {
          /* updates reccurrence dropdown selected value */
          const lastDayMonth = getLastDayMonth(selectedDate).toString();
          switch (dayOfMonth) {
            case FIRST_DAY_MONTH:
              state.repeatsOn = {
                label: "on the first day of the month",
                value: FIRST_DAY_MONTH,
              };
              break;
            case lastDayMonth:
              state.repeatsOn = {
                label: "on the last day of the month",
                value: LAST_DAY_MONTH,
              };
              break;
            default:
              state.repeatsOn = {
                label: `on the ${dayOfMonth} day of the month`,
                value: dayOfMonth.toString(),
              };
              break;
          }
          const cleanDays = cleanCronBoxOptions(state.days);
          state.days = cleanDays;
          state.weekOfMonth = null;
          cronMapCopy.set("day-of-week", CRON_ANY);
        } else {
          switch (weekOfMonth) {
            case "1":
              state.repeatsOn = {
                label: "on the first week of the month",
                value: "1w",
              };
              break;
            case "2":
              state.repeatsOn = {
                label: "on the second week of the month",
                value: "2w",
              };
              break;
            case "3":
              state.repeatsOn = {
                label: "on the third week of the month",
                value: "3w",
              };
              break;
            case "L":
              state.repeatsOn = {
                label: "on the last week of the month",
                value: "Lw",
              };
              break;
          }
        }
      }

      state.cronMap = cronMapCopy;
      const newCronExpression = getUpdatedCronExpression();
      return {
        ...state,
        cronMap: cronMapCopy,
        cronExpression: newCronExpression,
      };
    }

    case ActionTypeEnum.SetRepeatsOn: {
      const { option, selectedDateStr } = action.payload;
      if (option === null) return state;
      const dayOfMonth = option.value;
      const minute = state.cronMap.get("minute");
      const hour = state.cronMap.get("hour");

      const cronMapCopy = new Map(state.cronMap);

      if (dayOfMonth.includes("w")) {
        const selectedDate = selectedDateStr
          ? new Date(selectedDateStr)
          : new Date();
        const { dayOfWeek } = getDateProps(selectedDate);
        state.weekOfMonth = dayOfMonth.charAt(0) as ApiWeeksOfMonth;
        if (!state.days.some((d) => d.isSelected)) {
          let newDaysOptions = resetCronBoxOption(
            state.days.length ? state.days : CRON_DAYS
          );
          newDaysOptions = newDaysOptions.map((day) =>
            day.value === dayOfWeek ? { ...day, isSelected: true } : day
          );
          state.days = newDaysOptions;
          const selectedDays = getExpressionFromBoxOptions(newDaysOptions);
          cronMapCopy.set("day-of-week", selectedDays);
        }
      } else {
        const cleanDays = cleanCronBoxOptions(state.days);
        state.days = cleanDays;
        state.weekOfMonth = null;
        cronMapCopy.set("day-of-week", CRON_ANY);
      }
      cronMapCopy.set("minute", `${minute}`);
      cronMapCopy.set("hour", `${hour}`);
      cronMapCopy.set("day-of-month", `${dayOfMonth}`);
      state.cronMap = cronMapCopy;

      return {
        ...state,
        repeatsOn: option,
        cronMap: cronMapCopy,
        cronExpression: getUpdatedCronExpression(),
      };
    }

    case ActionTypeEnum.SetExecuteEvery: {
      return { ...state, executeEvery: action.payload };
    }

    case ActionTypeEnum.UnloadCron: {
      state.cronMap.clear();
      state.cronExpression = "";
      state.days = [];
      state.months = [];
      state.repeatNumber = 0;
      state.recurrence = null;
      state.repeatsOn = null;
      state.isLoading = true;
      state.weekOfMonth = null;

      return state;
    }

    default:
      return state;
  }
};
