import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import moment from "moment-timezone";
import { FC, useCallback, useMemo } from "react";
import { StyledSelect } from "../selects/StyledSelect";

interface TimezoneSelectControlProps {
  label: string;
  name: string;
  value: string | undefined;
  placeholder?: string;
}
const UTC_FORMAT = "Z";
export const TimezoneSelectControl: FC<TimezoneSelectControlProps> = ({
  label,
  name,
  value,
  placeholder,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({ name, value });

  var timeZones = moment.tz.names();
  var offsetTmz = timeZones.map((timeZone) => {
    return {
      label: `(GMT ${moment.tz(timeZone).format(UTC_FORMAT)}) ${timeZone}`,
      value: timeZone,
    };
  });

  const timeZonesOptions = useMemo(
    () =>
      Object.values(offsetTmz).map((tmz) => ({
        label: tmz.label,
        value: tmz.value,
      })),
    [offsetTmz]
  );

  const handleOnChange = useCallback(
    (value: { label: string; value: string } | null) => {
      helper.setTouched(true);
      helper.setValue(value?.value);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <StyledSelect
        {...field}
        onChange={handleOnChange}
        options={timeZonesOptions}
        placeholder={placeholder}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
