import { Flex, useColorModeValue } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateFilters } from "../../../store/inventory/inventory-order-item-list.slice";

import { checkArray } from "../../../utils/compareObjects";
import { getId } from "../../../utils/getId";
import { VendorSupplierFilter } from "../filters/SupplierFilter";
import LowStockFilter from "./filters/LowStockFilter";

export const InventoryOrderFilters: FC = () => {
  const bgColor = useColorModeValue("blue.50", "blue.900");
  const dispatch = useDispatch();
  const { filters } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );

  const handleOnChangeSupplier = useCallback(
    (values: string[]) => {
      const newValues = checkArray<string[]>(values);
      dispatch(updateFilters({ supplier: newValues }));
    },
    [dispatch]
  );

  const handleOnChangeLowStock = useCallback(
    (showLowStock: boolean) => {
      dispatch(updateFilters({ showLowStock }));
    },
    [dispatch]
  );
  return (
    <Flex
      py={2}
      px={4}
      gap={4}
      w="100%"
      minH="20px"
      bgColor={bgColor}
      alignItems="center"
    >
      <Flex
        w="100%"
        display={["none", "none", "flex"]}
        justifyContent="space-between"
      >
        <Flex gap={4}>
          <VendorSupplierFilter
            values={
              filters.supplier
                ? Array.isArray(filters.supplier)
                  ? filters.supplier.map((sup) => getId(sup))
                  : [getId(filters.supplier)]
                : []
            }
            onChange={handleOnChangeSupplier}
            title="All Suppliers"
          />
          <LowStockFilter
            showLowStock={filters.showLowStock || true}
            onChange={handleOnChangeLowStock}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
