import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../../store";
import { loadProjectTimeline } from "../../../../store/planning-hq/timeline/project-timeline";
import { EntryHeader } from "../../../request-form/timeline/EntryHeader";
import { ProjectTimelineBody } from "./ProjectTimelineBody";

type ProjectTimelineProps = {
  project: ApiProject;
};

export const ProjectTimeline: FC<ProjectTimelineProps> = ({ project }) => {
  const thunkDispatch = useThunkDispatch();
  const { apiClient } = useAuthentication();

  const { entries, loadingStatus } = useSelector(
    (state: RootState) => state.projectTimelineSlice
  );

  useEffect(() => {
    thunkDispatch(loadProjectTimeline({ apiClient, projectId: project.id }));
  }, [thunkDispatch, apiClient, project]);

  return (
    <>
      {loadingStatus === "pending" && (
        <Center w="100%">
          <Spinner color="blue.500" />
        </Center>
      )}
      {loadingStatus === "rejected" && <Text>Could not load information</Text>}
      {loadingStatus === "fulfilled" ? (
        <VStack w="full" alignItems="flex-start" gap={8} divider={<Divider />}>
          {entries.map((entry) => {
            if (entry.delta && !entry.delta.length) return null;
            return (
              <Flex key={entry.id} flexDir="row" gap={4} w="100%">
                <Avatar src={entry.createdBy.profileImage} size="sm" />
                <Flex flexDir="column" mt={-1} w="100%">
                  <EntryHeader entry={entry} />
                  <Box>
                    <ProjectTimelineBody entry={entry} />
                  </Box>
                </Flex>
              </Flex>
            );
          })}
        </VStack>
      ) : null}
    </>
  );
};
