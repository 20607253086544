import { Box } from "@chakra-ui/react";
import {
  ApiLocationSummary,
  ApiUserGroup,
  ApiUserSummary,
  ApiWorkflowReference,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { FocusEventHandler, useCallback, useMemo } from "react";
import { useShowToast } from "../../hooks/showToast";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  UserOptionsProps,
  UserSingleValueSelect,
} from "./select-overrides-types";
import { UserAutocompleteGroup } from "./UserAutocomplete";

export type SingleAssigneeAutocompleteValue = ApiUserSummary & {
  isGroup?: boolean;
  groupMembers?: ApiUserSummary[];
};

export interface SingleAssigneeAutocompleteProps {
  name?: string;
  value: SingleAssigneeAutocompleteValue | null;
  onChange?: (user: SingleAssigneeAutocompleteValue | null) => void;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  workflow: ApiWorkflowReference | null;
  location?: ApiLocationSummary;
  reportingCategory?: ApiWorkflowReportingCategorySummary;
  displayLeadGroups?: boolean;
}

const CustomOptionComponent = createOptionComponent(UserBadge);

const CustomSingleValueComponent = (props: UserSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
};

export const SingleAssigneeAutocomplete = ({
  onChange,
  onBlur,
  name,
  value: user,
  isDisabled,
  workflow,
  location,
  reportingCategory,
  displayLeadGroups,
}: SingleAssigneeAutocompleteProps) => {
  const showToast = useShowToast();
  const { currentAccount, apiClient } = useAuthentication();

  const handleChange = useCallback(
    (value: SingleAssigneeAutocompleteValue | null) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const workflowId = useMemo(() => {
    if (workflow === null) return undefined;
    return typeof workflow === "string" ? workflow : workflow.id;
  }, [workflow]);

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      try {
        const users: any = [];
        if (!workflowId) {
          cb(users);
          return;
        }

        const response = await apiClient.findWorkflowAssignees(
          currentAccount.id,
          workflowId,
          {
            search: inputValue,
            pageSize: 100,
            location: location ? location.id : undefined,
            category: reportingCategory ? reportingCategory.id : undefined,
          },
        );

        const userToPush = response.data
          .filter((assignee) => assignee.type === "user")
          .map((assignee) => assignee.assignee as ApiUserSummary);
        users.push(...userToPush);

        if (displayLeadGroups) {
          const userGroups = response.data
            .filter((assignee) => assignee.type === "group")
            .map((assignee) => assignee.assignee as ApiUserGroup);

          const groups = await Promise.all(
            userGroups.map(async (group) => {
              return {
                ...group,
                members: await apiClient.findUserGroupMembers(
                  currentAccount.id,
                  group.id,
                  {
                    search: inputValue,
                  },
                ),
              };
            }),
          );

          const mapValues: UserAutocompleteGroup[] = groups.map((group) => ({
            id: group.id,
            firstName: group.name,
            lastName: "",
            email: "",
            phone: "",
            profileImage: "",
            isGroup: true,
            groupMembers: group.members.data.map((item) => item.user),
          }));
          users.push(...mapValues);
        }

        cb(users);
        return users;
      } catch (error) {
        showToast("error", "Something went wrong loading users");
      }
    },
    [
      apiClient,
      currentAccount.id,
      showToast,
      workflowId,
      location,
      displayLeadGroups,
      reportingCategory,
    ],
  );

  const debouncedLoadOptions = debounce(loadOptions, 250);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: UserOptionsProps) => CustomOptionComponent(props),
      SingleValue: (props: UserSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      name={name}
      onBlur={onBlur}
      defaultOptions={true}
      value={user}
      cacheOptions={true}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      chakraStyles={commonStyles}
      getOptionValue={(item: SingleAssigneeAutocompleteValue) => item.id}
      isDisabled={isDisabled}
    />
  );
};
