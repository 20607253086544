import { Avatar, BoxProps, HStack, Icon, Text } from "@chakra-ui/react";
import { ApiLaborTransaction } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { IoTrashSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { OutlinedIconButton } from "../../../../components/custom-icons/OutlinedIconButton";
import {
  DataTable,
  DataTableColumn,
} from "../../../../components/data-table/DataTable";
import { RootState } from "../../../../store";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import {
  getFormatedHour,
  TRANSACTION_DATE_FORMAT,
} from "../../../request-form/transactions/transactions-helper";

enum TransactionEnum {
  EDIT = "EDIT",
  REMOVE = "REMOVE",
}

export interface LaborTableButtonsProps {
  transaction: ApiLaborTransaction;
  handleClickButtons: (
    transaction: ApiLaborTransaction,
    action: TransactionEnum
  ) => void;
  canEdit: (transaction: ApiLaborTransaction) => boolean;
  canDelete: (transaction: ApiLaborTransaction) => boolean;
}

export const LaborTableButtons: React.FC<LaborTableButtonsProps> = ({
  transaction,
  handleClickButtons,
  canEdit,
  canDelete,
}) => {
  return (
    <HStack justifyContent="right">
      {canDelete(transaction) && (
        <OutlinedIconButton
          icon={<Icon as={IoTrashSharp} boxSize={5} />}
          onClick={() =>
            handleClickButtons(transaction, TransactionEnum.REMOVE)
          }
        />
      )}
      {canEdit(transaction) && (
        <OutlinedIconButton
          icon={<Icon as={MdEdit} boxSize={5} />}
          onClick={() => handleClickButtons(transaction, TransactionEnum.EDIT)}
        />
      )}
    </HStack>
  );
};

export interface LaborTransactionsTableProps {
  canEdit: (transaction: ApiLaborTransaction) => boolean;
  canDelete: (transaction: ApiLaborTransaction) => boolean;
  onEdit: (transaction: ApiLaborTransaction) => void;
  onDelete: (transaction: ApiLaborTransaction) => void;
}

const LaborTransactionTable: React.FC<LaborTransactionsTableProps> = ({
  canEdit,
  onEdit,
  onDelete,
  canDelete,
}) => {
  const { laborsData } = useSelector(
    (state: RootState) => state.requestForm.transactions.labors
  );
  let total: number = 0;
  laborsData.map((laborItem: any) => {
    return (total += laborItem.hours);
  });
  const getRowData = (transaction: ApiLaborTransaction) => {
    const { laborer, datePerformed, hours } = transaction;
    return {
      laborerName: `${laborer.firstName} ${laborer.lastName}`,
      laborerPicture: laborer.profileImage,
      performedDate: format(new Date(datePerformed), TRANSACTION_DATE_FORMAT),
      hours: hours,
    };
  };

  const handleClickButtons = useCallback(
    (transaction: ApiLaborTransaction, action: TransactionEnum) => {
      if (action === TransactionEnum.EDIT) {
        onEdit(transaction);
      }

      if (action === TransactionEnum.REMOVE) {
        onDelete(transaction);
      }
    },
    [onEdit, onDelete]
  );

  const columns = useMemo<DataTableColumn<ApiLaborTransaction>[]>(() => {
    const cellProps: BoxProps = {
      py: "4",
    };
    return [
      {
        Header: "Who",
        Cell: (cell: any) => {
          const { laborerName, laborerPicture } = getRowData(cell.row.original);
          return (
            <>
              <Avatar mt={1} mr={2} size="2xs" src={laborerPicture} />
              <Text as="span" whiteSpace="break-spaces" isTruncated>
                {laborerName}
              </Text>
            </>
          );
        },
        maxWidth: 300,
        minWidth: 150,
        boxProps: cellProps,
      },
      {
        Header: "Type",
        accessor: (row) => capitalizeFirstLetter(row.laborType),
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        boxProps: cellProps,
      },
      {
        Header: "Time",
        Cell: (cell: any) => (
          <Text>{getFormatedHour(cell.row.original.hours)}</Text>
        ),
        maxWidth: 50,
        boxProps: cellProps,
      },
      {
        Header: "Date",
        Cell: (cell: any) => {
          const { performedDate } = getRowData(cell.row.original);
          return (
            <>
              <Text>{performedDate}</Text>
            </>
          );
        },
        maxWidth: 70,
        boxProps: cellProps,
      },
      {
        Header: " ",
        Cell: (cell: any) => (
          <LaborTableButtons
            transaction={cell.row.original}
            handleClickButtons={handleClickButtons}
            canEdit={canEdit}
            canDelete={canDelete}
          />
        ),
        maxWidth: 50,
        boxProps: cellProps,
      },
    ];
  }, [handleClickButtons, canEdit, canDelete]);

  return <DataTable columns={columns} data={laborsData} />;
};

export default LaborTransactionTable;
