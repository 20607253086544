import {
  Divider,
  Heading,
  HeadingProps,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface AccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string | React.ReactNode;
  content: React.ReactNode;
  contentProps?: ModalContentProps;
  titleProps?: HeadingProps;
  bodyProps?: ModalBodyProps;
  size?: string;
  footer?: ReactElement<ModalFooterProps>;
  closeButton?: boolean;
  isCentered?: boolean;
  header?: boolean;
  closeOnOverlayClick?: boolean;
  hideDivider?: boolean;
}

export function AccountModal({
  isOpen,
  onClose,
  title,
  content,
  contentProps,
  titleProps,
  bodyProps,
  size,
  footer,
  closeButton = true,
  isCentered = false,
  header = true,
  closeOnOverlayClick = true,
  hideDivider,
}: AccountModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      size={size}
      isCentered={isCentered}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent {...contentProps}>
        {header && (
          <ModalHeader display="flex" flexDir="column" gap={4}>
            {typeof title === "string" ? (
              <Heading size="lg" {...titleProps}>
                {title}
              </Heading>
            ) : (
              title
            )}
            {!hideDivider && <Divider />}
          </ModalHeader>
        )}

        {closeButton && <ModalCloseButton />}

        <ModalBody mb={2} w="100%" {...bodyProps}>
          {content}
        </ModalBody>
        {footer}
      </ModalContent>
    </Modal>
  );
}
