import { MutableRefObject } from "react";
import { debounce } from "./debounce";

const debounceFocus = debounce(
  (element: HTMLSelectElement) => element.focus(),
  300
);

const debounceScroll = debounce((sectionElementRef: any) => {
  if (sectionElementRef && sectionElementRef.current)
    window.scrollTo({
      top: sectionElementRef.current.offsetTop,
      behavior: "smooth",
    });
}, 400);

export function scrollToSection(
  sectionElementRef: any,
  fieldFocusElementRef?: MutableRefObject<HTMLSelectElement | null>
) {
  if (sectionElementRef && sectionElementRef.current)
    debounceScroll(sectionElementRef);

  if (fieldFocusElementRef && fieldFocusElementRef.current) {
    debounceFocus(fieldFocusElementRef.current);
  }
}
