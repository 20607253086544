import { ApiEvent, ApiEventStatus } from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventOccurrenceUpdateProps } from "./event-details.slice";

export type ApproveOrUpdateStatus = "approve" | "update" | null;
export type ModalContentStatus = "form" | "conflicts" | null;

interface NewEventSliceState {
  isModalOpen: boolean;
  workingEventId: string;
  workingOccurrence?: EventOccurrenceUpdateProps;
  isEventListOpen: boolean;
  isApproveModalOpen: boolean;
  modalContent: ModalContentStatus;
  eventStatus: ApiEventStatus | null;
  approveOrUpdateStatus: ApproveOrUpdateStatus;
  event: ApiEvent | null;
  isConflictsModalOpen: boolean;
}

const initialState: NewEventSliceState = {
  isModalOpen: false,
  workingEventId: "",
  workingOccurrence: undefined,
  modalContent: null,
  isEventListOpen: false,
  isApproveModalOpen: false,
  eventStatus: null,
  approveOrUpdateStatus: null,
  isConflictsModalOpen: false,
  event: null,
};

interface SetOpenModalProps {
  isOpen: boolean;
  workingEventId: string;
  workingOccurrence?: EventOccurrenceUpdateProps;
  modalContent: ModalContentStatus;
}

interface ToogleApproveModalProps {
  workingEventId: string;
  isApproveModalOpen: boolean;
  eventStatus: ApiEventStatus;
  approveOrUpdateStatus: ApproveOrUpdateStatus;
}

export const newEventSlice = createSlice({
  name: "event-form-modal",
  initialState: { ...initialState },
  reducers: {
    unloadNewEvent: (state: NewEventSliceState) => {
      state.isModalOpen = false;
      state.workingEventId = "";
      state.workingOccurrence = undefined;
    },
    setIsOpenEventModal: (
      state: NewEventSliceState,
      action: PayloadAction<SetOpenModalProps>
    ) => {
      state.isModalOpen = action.payload.isOpen;
      state.workingEventId = action.payload.workingEventId;
      state.workingOccurrence = action.payload.workingOccurrence;
      state.modalContent = action.payload.modalContent;
    },
    setIsConflictsModalOpen: (
      state: NewEventSliceState,
      action: PayloadAction<
        Omit<SetOpenModalProps, "modalContent"> & { event?: ApiEvent }
      >
    ) => {
      state.isConflictsModalOpen = action.payload.isOpen;
      state.workingEventId = action.payload.workingEventId;
      state.workingOccurrence = action.payload.workingOccurrence;

      state.event = action.payload.event || null;
    },
    setIsEventListOpen: (
      state: NewEventSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.isEventListOpen = action.payload;
    },
    toogleApproveModal: (
      state: NewEventSliceState,
      action: PayloadAction<ToogleApproveModalProps>
    ) => {
      const {
        workingEventId,
        isApproveModalOpen,
        eventStatus,
        approveOrUpdateStatus,
      } = action.payload;
      state.workingEventId = workingEventId;
      state.isApproveModalOpen = isApproveModalOpen;
      state.eventStatus = eventStatus;
      state.approveOrUpdateStatus = approveOrUpdateStatus;
    },
    unloadApproveModal: (
      state: NewEventSliceState,
      action: PayloadAction<{ cleanWorkingId: boolean }>
    ) => {
      state.isApproveModalOpen = false;
      state.eventStatus = null;
      state.approveOrUpdateStatus = null;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setIsOpenEventModal,
  unloadNewEvent,
  setIsEventListOpen,
  toogleApproveModal,
  unloadApproveModal,
  setIsConflictsModalOpen,
} = newEventSlice.actions;

export default newEventSlice.reducer;
