import {
  ApiAccount,
  ApiClient,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import getValueFromObjectPathFn from "lodash/get";
import { RootState } from "../..";
import {
  canReopen,
  generateTransition,
  isLocked,
} from "../request-form.helpers";
import { ChangeModalState, RequestSliceState } from "../request-form.slice";

export interface UpdateRequestThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  idOrKey: string;
  delta: Partial<ApiRequest>;
  request: ApiRequest;
  engine: SchemaRulesEngine;
  isChangeModal: boolean;
  isProductAdmin: boolean;
}

export const updateRequest = createAsyncThunk(
  "requests/update",
  async (params: UpdateRequestThunkParams, thunkAPI) => {
    const {
      apiClient,
      account,
      idOrKey,
      request,
      delta,
      engine,
      isChangeModal,
      isProductAdmin,
    } = params;
    const { locationMap, categoriesMap, descendantsMap } = (
      thunkAPI.getState() as RootState
    ).localCache;

    const changeVerification = engine.verifyChange(
      {
        delta,
        request,
      },
      // sending the lodash _get function
      getValueFromObjectPathFn
    );

    const requiredFields = engine.getRequiredFields({ request, delta });

    if (
      changeVerification.result === "failed" ||
      (delta.status !== undefined && !isChangeModal)
    ) {
      const failure: ChangeModalState = {
        changeVerification,
        delta,
        requiredFields,
      };
      return thunkAPI.rejectWithValue(failure);
    }

    const response = await apiClient.updateRequest(account.id, idOrKey, delta);
    return {
      isProductAdmin,
      request: response,
      locationMap,
      descendantsMap,
      categoriesMap,
    };
  }
);

export const updateRequestHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(updateRequest.fulfilled, (state, action) => {
    state.changeModal = {
      changeVerification: null,
      delta: null,
      requiredFields: null,
    };
    state.request && Object.assign(state.request, action.payload.request);

    state.canReopen = canReopen(
      state.workflow!,
      state.policy!,
      action.payload.request,
      action.payload.isProductAdmin
    );
    state.transition = generateTransition(
      state.workflow!,
      state.policy!,
      action.payload.request,
      action.payload.isProductAdmin,
      action.payload.descendantsMap,
      action.payload.categoriesMap
    );
    state.formIsLocked = isLocked(action.payload.request, state.transition);
    state.schemaVerificationFailed = false;
    state.updateRequestStatus = "success";
  });

  builder.addCase(
    updateRequest.rejected,
    (state, action: PayloadAction<any>) => {
      const { changeVerification, delta, requiredFields } =
        action.payload as ChangeModalState;
      const { isAutomaticTransition } = state;
      // TODO: handle server error rejection.
      if (!changeVerification && !delta) {
        return;
      }
      if (
        changeVerification &&
        changeVerification.result === "failed" &&
        isAutomaticTransition
      ) {
        state.schemaVerificationFailed = true;
        state.changeModal = {
          changeVerification: null,
          delta: null,
          requiredFields: null,
        };
      } else {
        state.changeModal.changeVerification = changeVerification;
        state.changeModal.delta = delta;
        state.changeModal.requiredFields = requiredFields;
      }
    }
  );
};
