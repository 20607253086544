import { ApiDay, ApiVenueSummary } from "@operations-hero/lib-api-client";
import { getDay } from "date-fns";
import { getDayName } from "./getDayName";

export const getNearestVenueDate = (venue: ApiVenueSummary) => {
  let date = new Date();
  let cont = 0;
  do {
    const day = getDay(date);
    const dayName: ApiDay = getDayName(day).toLowerCase() as ApiDay;

    if (venue.hours[dayName].isOpen) {
      return date;
    }
    date = new Date(date.setDate(date.getDate() + 1));
    cont++;
  } while (cont < 7);

  return new Date();
};
