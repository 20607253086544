import {
  ApiClient,
  ApiAccount,
  ApiUser,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export interface LoadCommentsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiUser;
  key: string;
  page?: number;
}

export const loadComments = createAsyncThunk(
  "requests/loadComments",
  async (params: LoadCommentsThunkParams, thunkAPI) => {
    const { apiClient, account, key } = params;
    const state = thunkAPI.getState() as RootState;
    const { commentsCurrentPage: page } = state.requestForm;
    const pagedComments = await apiClient.findRequestComments(account.id, key, {
      pageSize: 20,
      page,
    });
    return pagedComments;
  }
);

export const loadCommentsHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadComments.fulfilled, (state, action) => {
    state.comments = action.payload.data;
    state.commentsTotal = action.payload.total;
    state.commentsCurrentPage = action.payload.options.page;
  });
};
