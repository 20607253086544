import { createSlice } from "@reduxjs/toolkit";

export const SYSTEM_ANNOUNCEMENT = "system-announcement";

export const ANNOUNCEMENT_STYLE: Record<string, string> = {
  red: "error",
  yellow: "warning",
  blue: "info",
};

export interface AnnouncementSetting {
  message: string;
  expires: string | null;
  color?: string;
}

export interface AnnouncementSlice {
  announcement: AnnouncementSetting | undefined;
  isAnnouncementExpired: boolean;
}

export const announcementSlice = createSlice({
  name: "auth",
  initialState: {
    announcement: undefined,
    isAnnouncementExpired: false,
  } as AnnouncementSlice,
  reducers: {
    setAnnouncement: (state, action) => {
      state.announcement = action.payload;
    },
    setIsAnnouncementExpired: (state, action) => {
      state.isAnnouncementExpired = action.payload;
    },
  },
});

export const { setAnnouncement, setIsAnnouncementExpired } =
  announcementSlice.actions;

export default announcementSlice.reducer;
