import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LocalEventDetailsSliceState } from "../event-details.slice";

export interface LoadEventCommentsParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
}

export const loadEventComments = createAsyncThunk(
  "events/load-comments",
  async (params: LoadEventCommentsParams, thunkAPI) => {
    const { apiClient, accountId, eventId } = params;

    const { commentsCurrentPage } = (thunkAPI.getState() as RootState)
      .eventDetails;

    const result = await apiClient.findEventComments(accountId, eventId, {
      page: commentsCurrentPage || 1,
    });
    return result;
  }
);

export interface CreateUpateCommentsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  commentId?: string;
  isPublic: boolean;
  commentText: string;
}

export const createUpdateEventComment = createAsyncThunk(
  "events/create-update-comment",
  async (params: CreateUpateCommentsThunkParams, thunkAPI) => {
    const { apiClient, accountId, eventId, commentId, isPublic, commentText } =
      params;

    if (commentId) {
      const comment = await apiClient.updateEventComment(
        accountId,
        eventId,
        commentId,
        { comment: commentText, isPublic }
      );

      return { commentId, comment };
    } else {
      const comment = await apiClient.createEventComment(accountId, eventId, {
        comment: commentText,
        isPublic,
      });
      return { commentId, comment };
    }
  }
);

interface DeleteEventCommentParams extends LoadEventCommentsParams {
  commentId: string;
}

export const deleteEventComment = createAsyncThunk(
  "events/delete-comment",
  async (params: DeleteEventCommentParams, thunkAPI) => {
    const { apiClient, accountId, eventId, commentId } = params;

    await apiClient.deleteEventComment(accountId, eventId, commentId);

    return commentId;
  }
);

export const eventCommentsHandlers = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(loadEventComments.fulfilled, (state, action) => {
    state.comments = action.payload.data;
    state.commentsTotal = action.payload.total;
    state.commentsCurrentPage = action.payload.options.page;
  });

  builder.addCase(createUpdateEventComment.fulfilled, (state, action) => {
    const { commentId, comment } = action.payload;
    if (!state.isInitialized) {
      return;
    }

    if (commentId) {
      const index = state.comments.findIndex(
        (item) => item.id === commentId,
        []
      );
      if (index !== -1) {
        state.comments[index] = comment;
      }
    } else {
      state.comments.unshift(comment);
    }
  });

  builder.addCase(deleteEventComment.fulfilled, (state, action) => {
    const index = state.comments.findIndex(
      (item) => item.id === action.payload
    );

    if (index !== -1) {
      state.comments.splice(index, 1);
    }
  });
};
