import { ApiAsset } from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AiAssetParams = {
  apiAsset: ApiAsset | null;
};

const AiAssetSlice = createSlice({
  name: "aiAsset",
  initialState: {} as AiAssetParams,
  reducers: {
    setAiAsset(state, action: PayloadAction<AiAssetParams>) {
      state.apiAsset = action.payload.apiAsset;
    },
  },
});

export const { setAiAsset } = AiAssetSlice.actions;

export default AiAssetSlice.reducer;
