import { Box } from "@chakra-ui/react";
import {
  ApiUserSummary,
  FindUsersOptions,
} from "@operations-hero/lib-api-client";
import {
  AsyncSelect,
  MultiValue,
  MultiValueGenericProps,
} from "chakra-react-select";
import { FocusEventHandler, useCallback, useMemo } from "react";
import { useShowToast } from "../../hooks/showToast";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  UserMultiValueSelect,
  UserOptionsProps,
} from "./select-overrides-types";

export interface MultiUserAutocompleteProps {
  name?: string;
  value: ApiUserSummary | null | ApiUserSummary[];
  allowEmpty?: boolean;
  emptyText?: string;
  onChange?: (user: MultiValue<ApiUserSummary> | null) => void;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  filterOptions?: FindUsersOptions;
}

export interface UserAutocompleteGroup
  extends Omit<ApiUserSummary, "timeZone"> {
  isGroup?: boolean;
  groupMembers?: ApiUserSummary[];
}

const CustomOptionComponent = createOptionComponent(UserBadge);

function CustomMultiValueLabelComponent(
  props: MultiValueGenericProps<ApiUserSummary>
) {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
}

export const MultiUserAutocomplete = ({
  allowEmpty,
  emptyText,
  onChange,
  onBlur,
  name,
  value: user,
  isDisabled,
  filterOptions,
}: MultiUserAutocompleteProps) => {
  const showToast = useShowToast();
  const { currentAccount, apiClient } = useAuthentication();

  const nullUser: ApiUserSummary = useMemo(
    () => ({
      firstName: emptyText || "No User",
      lastName: "",
      id: "null-user",
      email: "",
      phone: "",
      profileImage: "",
      timeZone: null,
    }),
    [emptyText]
  );

  const handleChange = useCallback(
    (value: MultiValue<ApiUserSummary> | null) => {
      const newValue = value;
      if (onChange) onChange(newValue);
    },
    [onChange]
  );

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      try {
        const users: any = [];
        if (allowEmpty) {
          users.push(nullUser);
        }

        const response = await apiClient.findAccountUsers(currentAccount.id, {
          search: inputValue,
          ...(filterOptions ?? {}),
        });
        users.push(...response.data);
        cb(users);
        return users;
      } catch (error) {
        showToast("error", "Something went wrong loading users");
      }
    },
    [
      allowEmpty,
      apiClient,
      currentAccount.id,
      nullUser,
      showToast,
      filterOptions,
    ]
  );

  const debouncedLoadOptions = debounce(loadOptions, 250);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: UserOptionsProps) => CustomOptionComponent(props),
      MultiValueLabel: (props: UserMultiValueSelect) =>
        CustomMultiValueLabelComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      name={name}
      onBlur={onBlur}
      defaultOptions={true}
      value={user || nullUser}
      cacheOptions={true}
      chakraStyles={commonStyles}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      getOptionValue={(item: ApiUserSummary) => item.id}
      isDisabled={isDisabled}
      isMulti={true}
    />
  );
};
