import { Box, Circle, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { useCallback, ReactNode } from "react";

interface StepProps {
  activeStep: number;
  onClick: () => void;
  children: ReactNode;
  disableStepButtons?: boolean;
  state: "active" | "complete" | "incomplete";
  saveCallback?: (currentStep: number) => void;
}

export const StepCircle = (props: StepProps) => {
  const { activeStep, children, state, saveCallback, onClick } = props;

  const isCompleted = state === "complete";
  const isIncompleted = state === "incomplete";

  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  const incompleteTextColor = useColorModeValue("gray.700", "white");
  const completeTextColor = useColorModeValue("white", "gray.900");

  const completeButtonColor = useColorModeValue(
    "blue.500",
    "ScheduledRequestColors.stepCircleActive"
  );
  const incompleteButtonColor = useColorModeValue(
    "gray.200",
    "ScheduledRequestColors.stepCircleInactive"
  );

  const handleOnClickStep = useCallback(() => {
    saveCallback && saveCallback(activeStep);
    onClick();
  }, [onClick, saveCallback, activeStep]);

  return (
    <Box as="li" display="inline-flex">
      <button
        onClick={handleOnClickStep}
        disabled={props.disableStepButtons || false}
        style={{ cursor: props.disableStepButtons ? "not-allowed" : "pointer" }}
      >
        <Circle
          size="8"
          aria-hidden
          position="relative"
          bg={isCompleted ? completeButtonColor : incompleteButtonColor}
        >
          <Box
            as="span"
            color={
              isDarkMode
                ? "white"
                : isIncompleted
                ? incompleteTextColor
                : completeTextColor
            }
            fontWeight="bold"
          >
            {children}
          </Box>
        </Circle>
      </button>
    </Box>
  );
};
