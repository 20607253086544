import { ApiClient } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { PhoneValue } from "../components/inputs/PhoneInput";

type PhoneVerificationStateParams = {
  verificationId?: string;
  phone?: PhoneValue;
  verificationStatus?: boolean;
  resendStatus: "started" | "finish" | "idle";
};

type CodeVerificationParams = {
  apiClient: ApiClient;
  code: number;
};

type InitVerificationParams = {
  apiClient: ApiClient;
  phone: string;
};

type ResendParams = {
  apiClient: ApiClient;
};

type DeletePhoneParams = {
  apiClient: ApiClient;
};

export const initVerification = createAsyncThunk(
  "verify/init",
  async ({ apiClient, phone }: InitVerificationParams, thunkAPI) => {
    const response = await apiClient.initPhoneVerification({ phone });
    return {
      verificationId: response.verificationId,
      phone,
    };
  }
);

export const verifyUserPhoneNumber = createAsyncThunk(
  "verify/verify-code",
  async ({ apiClient, code }: CodeVerificationParams, thunkAPI) => {
    const { verificationId, phone } = (thunkAPI.getState() as RootState)
      .phoneVerificationSlice;
    const response = await apiClient.verifyUserPhone({
      phone: phone?.e164 || "",
      options: {
        verificationId: verificationId ?? "",
        verificationCode: code,
      },
    });

    // Adds padding to prevent an abrupt end to the animated loading button
    await new Promise((res) => setTimeout(res, 1000));

    return {
      phone: response.phone,
      phoneVerified: response.phoneVerified,
    };
  }
);

export const reSendCode = createAsyncThunk(
  "verify/resend",
  async ({ apiClient }: ResendParams, thunkAPI) => {
    const { phone } = (thunkAPI.getState() as RootState).phoneVerificationSlice;
    const response = await apiClient.initPhoneVerification({
      phone: phone?.e164,
    });
    return {
      verificationId: response.verificationId,
      phone,
    };
  }
);

export const deletePhone = createAsyncThunk(
  "verify/delete",
  async ({ apiClient }: DeletePhoneParams, thunkAPI) => {
    const response = await apiClient.updateCurrentUser({
      phone: null,
      phoneVerified: null,
    });
    return response;
  }
);

const initialState: PhoneVerificationStateParams = {
  verificationId: "",
  phone: {
    formated: "",
    e164: "",
  },
  verificationStatus: undefined,
  resendStatus: "idle",
};
export const phoneVerificationSlice = createSlice({
  name: "phoneVerification",
  initialState,
  reducers: {
    setVerificationId: (
      state,
      action: PayloadAction<PhoneVerificationStateParams["verificationId"]>
    ) => {
      state.verificationId = action.payload;
    },

    setPhone(
      state,
      action: PayloadAction<PhoneVerificationStateParams["phone"]>
    ) {
      state.phone = action.payload;
    },

    setResendStatus(
      state,
      action: PayloadAction<PhoneVerificationStateParams["resendStatus"]>
    ) {
      state.resendStatus = action.payload;
    },

    setVerificationStatus(
      state,
      action: PayloadAction<PhoneVerificationStateParams["verificationStatus"]>
    ) {
      state.verificationStatus = action.payload;
    },

    unloadPhoneVerificationState(state) {
      state.phone = initialState.phone;
      state.resendStatus = initialState.resendStatus;
      state.verificationId = initialState.verificationId;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(initVerification.fulfilled, (state, action) => {
      const { verificationId } = action.payload;
      state.verificationId = verificationId;
      state.verificationStatus = undefined;
    });

    builder.addCase(verifyUserPhoneNumber.fulfilled, (state, action) => {
      state.verificationStatus = !!action.payload.phoneVerified;
    });

    builder.addCase(reSendCode.pending, (state) => {
      state.resendStatus = "started";
      state.verificationStatus = undefined;
    });
    builder.addCase(reSendCode.fulfilled, (state, action) => {
      const { verificationId } = action.payload;
      state.verificationId = verificationId;
    });
  },
});

export const {
  setVerificationId,
  setPhone,
  setResendStatus,
  setVerificationStatus,
  unloadPhoneVerificationState,
} = phoneVerificationSlice.actions;
export default phoneVerificationSlice.reducer;
