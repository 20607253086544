import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ApiLocationSummary } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import { MenuPlacement } from "react-select";
import { LocationAutocomplete } from "../selects/LocationAutocomplete";

export type CleanValues = [string, number | string | null | never[]];
export interface LocationAutocompleteControlProps {
  label: string;
  name: string;
  value: ApiLocationSummary | null;
  allowedLocations?: string[];
  allowEmpty?: boolean;
  isDisabled?: boolean;
  cleanValues?: CleanValues[];
  productName?: string;
  menuPlacement?: MenuPlacement;
}

export const LocationAutocompleteControl = ({
  name,
  value,
  label,
  allowedLocations,
  allowEmpty = false,
  isDisabled,
  cleanValues,
  productName,
  menuPlacement,
}: LocationAutocompleteControlProps) => {
  const { submitCount, setFieldValue } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (location: ApiLocationSummary | null) => {
      helper.setValue(location);

      cleanValues &&
        cleanValues.forEach((item) => {
          const [fieldName, fieldValue] = item;
          setFieldValue(fieldName, fieldValue, false);
        });

      // See this issue with running setTouched and setValue in the same hook,
      // https://github.com/jaredpalmer/formik/issues/2083
      // This fixes a bug where the value is set, yet validation runs on the old value.
      setImmediate(() => helper.setTouched(true));
    },
    [cleanValues, helper, setFieldValue]
  );

  const handleBlur = useCallback(() => {
    helper.setTouched(true);
  }, [helper]);

  const isInvalid = useMemo(
    () => !!meta.error && (meta.touched || submitCount > 0),
    [meta, submitCount]
  );

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <LocationAutocomplete
        {...field}
        onChange={handleOnChange}
        onBlur={handleBlur}
        name={name}
        allowEmpty={allowEmpty}
        autoSelectWhenOneOption={true}
        isInvalid={isInvalid}
        allowedLocations={allowedLocations}
        isDisabled={isDisabled}
        productName={productName}
        menuPlacement={menuPlacement}
      />
      {isInvalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
};
