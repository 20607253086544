import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiEventGroupPortalUser } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CellProps } from "react-table";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { MethodIcon } from "../../../components/custom-icons/MethodIcon";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/data-table/DataTable";
import {
  DEFAULT_LOCALE_DATE_OPTS,
  LocaleDate,
} from "../../../components/dates/LocaleDate";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  deactivateUserRole,
  reactivateUserRole,
} from "../../../store/portal-users.slice";
import { AccountModal } from "../account-modal/AccountModal";

export const PortalUserList: FC = () => {
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();
  const { portalUsers } = useSelector(
    (state: RootState) => state.eventGroupPortalUsersSlice,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deactivateColor = useColorModeValue("red.500", "red.300");
  const groupColor = useColorModeValue("blue.500", "blue.300");
  const [workingPortalUser, setWorkingPortalUser] =
    useState<ApiEventGroupPortalUser | null>(null);

  const handleEventGroupClick = useCallback(
    (portalUser: ApiEventGroupPortalUser) => {
      setWorkingPortalUser(portalUser);
      onOpen();
    },
    [onOpen],
  );

  const handleOnDeactivate = useCallback(
    (portalUser: ApiEventGroupPortalUser) => {
      thunkDispatch(
        deactivateUserRole({
          apiClient,
          accountId: currentAccount.id,
          user: portalUser.user,
        }),
      )
        .then(unwrapResult)
        .then((res) => {
          showToast("success", "Portal User was deactivated successfully");
        })
        .catch((res) => {
          showToast("error", "something went wrong deactivating a Portal User");
        });
    },
    [apiClient, currentAccount.id, showToast, thunkDispatch],
  );

  const handleOnReactivate = useCallback(
    (portalUser: ApiEventGroupPortalUser) => {
      thunkDispatch(
        reactivateUserRole({
          apiClient,
          accountId: currentAccount.id,
          user: portalUser.user,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Portal User was reactivated successfully");
        })
        .catch(() => {
          showToast("error", "something went wrong reactivating a Portal User");
        });
    },
    [apiClient, currentAccount.id, showToast, thunkDispatch],
  );

  const columns = useMemo<DataTableColumn<ApiEventGroupPortalUser>[]>(
    () => [
      {
        Header: "Name",
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => {
          return (
            <UserBadge value={data.row.original.user} includeAvatar={false} />
          );
        },
      },
      {
        Header: "Email",
        disableSortBy: true,
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => (
          <HStack alignItems="center" spacing={1}>
            {!data.row.original.user.emailVerified && (
              <Tooltip hasArrow label="The email is not verified">
                <Icon as={WarningTwoIcon} color="orange.300" boxSize="18px" />
              </Tooltip>
            )}

            <Link href={`mailto:${data.row.original.user.email}`}>
              {data.row.original.user.email}
            </Link>
          </HStack>
        ),
      },
      {
        Header: "Phone",
        disableSortBy: true,
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => (
          <HStack alignItems="center" spacing={1}>
            {!data.row.original.user.phoneVerified &&
              data.row.original.user.phone && (
                <Tooltip hasArrow label="The phone is not verified">
                  <Icon as={WarningTwoIcon} color="orange.300" boxSize="18px" />
                </Tooltip>
              )}

            <Text>{data.row.original.user.phone || "no phone"}</Text>
          </HStack>
        ),
      },
      {
        Header: "Group(s)",
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => {
          const groupNames = data.row.original.eventGroups.map((eg) => eg.name);
          return (
            <Box
              cursor="pointer"
              onClick={() => handleEventGroupClick(data.row.original)}
            >
              <Text textColor={groupColor} textDecoration="underline">
                {groupNames[0]}

                {groupNames.length > 1 && (
                  <AdditionalNumberBadge number={groupNames.length - 1} />
                )}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "Method",
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => (
          <Center>
            <MethodIcon methodLogin={data.row.original.user.method} />
          </Center>
        ),
        maxWidth: 90,
        minWidth: 65,
      },
      {
        Header: "Last Login",
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => (
          <Tooltip
            label={new Date(
              data.row.original.user.dateTimeLogin as string,
            ).toLocaleString()}
          >
            <Text>
              <LocaleDate
                date={data.row.original.user.dateTimeLogin as string}
                options={{
                  ...DEFAULT_LOCALE_DATE_OPTS,
                  weekday: undefined,
                }}
              />
            </Text>
          </Tooltip>
        ),
        maxWidth: 100,
        minWidth: 75,
      },
      {
        Header: "Created",
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => (
          <Tooltip
            label={new Date(
              data.row.original.user.created as string,
            ).toLocaleString()}
          >
            <Text>
              <LocaleDate
                date={data.row.original.user.created as string}
                options={{
                  ...DEFAULT_LOCALE_DATE_OPTS,
                  weekday: undefined,
                }}
              />
            </Text>
          </Tooltip>
        ),
        maxWidth: 100,
        minWidth: 75,
      },
      {
        Header: "Actions",
        maxWidth: 100,
        Cell: (data: CellProps<ApiEventGroupPortalUser>) => (
          <Box w="100%" display="flex" justifyContent="flex-end" gap={2}>
            {data.row.original.user.products.find((p) => p.name === "PortalHQ")
              ?.enabled ? (
              <Button
                size="xs"
                variant="ghost"
                border="1px solid"
                colorScheme="red"
                borderColor={deactivateColor}
                onClick={() => handleOnDeactivate(data.row.original)}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                size="xs"
                onClick={() => handleOnReactivate(data.row.original)}
              >
                Reactivate
              </Button>
            )}
          </Box>
        ),
      },
    ],
    [
      deactivateColor,
      handleOnDeactivate,
      handleOnReactivate,
      handleEventGroupClick,
      groupColor,
    ],
  );

  return (
    <Flex display="flex" w="100%">
      <DataTable columns={columns} data={portalUsers} rest={{ w: "100%" }} />
      {isOpen && workingPortalUser && (
        <AccountModal
          content={
            <>
              <Text mt={-2} mb={2}>
                {`Event Groups for user: ${workingPortalUser.user.firstName} ${workingPortalUser.user.lastName}`}
              </Text>
              {workingPortalUser.eventGroups.map((eventGroup) => (
                <Box key={eventGroup.id} flex={1} w="100%">
                  {eventGroup.name}
                </Box>
              ))}
            </>
          }
          title={`Event Groups`}
          titleProps={{ fontSize: "md" }}
          onClose={onClose}
          isOpen={isOpen}
        />
      )}
    </Flex>
  );
};
