import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { ApiComment } from "@operations-hero/lib-api-client";
import { FC, useCallback, useState } from "react";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { CommentForm } from "../../../request-form/comments/CommentForm";
import { OccurrenceModalProps } from "./OcurrencesModal";

interface EditOccurrencesProps
  extends Omit<OccurrenceModalProps, "isOpen" | "occurrence" | "modalType"> {
  handleOnSaveOccurrences?: () => void;
}

export const EditOccurrences: FC<EditOccurrencesProps> = ({
  eventId,
  onClose,
  handleOnSaveOccurrences,
}) => {
  const toast = useToast();
  const { apiClient, currentAccount } = useAuthentication();

  const [currentComment, setCurrentComment] = useState<ApiComment>();
  const saveComment = useCallback(
    async (comment: string) => {
      try {
        await apiClient.createEventComment(currentAccount.id, eventId, {
          comment,
        });
      } catch (error) {
        toast({
          status: "error",
          title: "Something went wrong creating a comment, please try again!",
          isClosable: true,
          duration: 2000,
          position: "top",
        });
      }
    },
    [apiClient, currentAccount.id, eventId, toast],
  );

  const handleOnSave = useCallback(
    async (comment?: string) => {
      comment && saveComment(comment);
      handleOnSaveOccurrences && handleOnSaveOccurrences();
      onClose();
    },
    [handleOnSaveOccurrences, onClose, saveComment],
  );

  return (
    <Box>
      <Box>
        <Box>
          <Grid templateColumns="repeat(6 ,1fr)" gap={4}>
            <GridItem colSpan={6}>
              <Divider />
            </GridItem>

            <GridItem colSpan={6}>
              <Heading fontSize="xl">Add a comment</Heading>
            </GridItem>

            <GridItem colSpan={6}>
              <CommentForm
                commentFrom="event"
                onSave={() => {}}
                comment={currentComment}
                autoFocusComment={false}
                showCommentButtons={false}
                setCurrentComment={setCurrentComment}
              />
            </GridItem>

            <GridItem colSpan={6}>
              <Divider />
            </GridItem>
            <GridItem display="flex" justifyContent="space-between" colSpan={6}>
              <Button
                variant="outline"
                borderColor="blue.500"
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                onClick={() => handleOnSave(currentComment?.comment)}
              >
                Save and Close
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
