export const getPaginationDetails = (
  total: number,
  pageSize: number,
  currentPage: number
) => {
  const start = currentPage * pageSize - (pageSize - 1);
  const end = Math.min(start + pageSize - 1, total);

  return `Showing ${start}-${end} of ${total}`;
};
