import { Heading, Stack, Text } from "@chakra-ui/react";
import {
  ApiBillingGroupType,
  ApiRentableEquipment,
  ApiRentableEquipmentReference,
  ApiSpaceEquipment,
  ApiVenue,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { differenceInMinutes } from "date-fns";
import { useFormikContext } from "formik";
import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useBillingGroupRates } from "../../../../hooks/useBillingGroupRates";
import { EventFormValues } from "../EventForm";
import { RentableEquipmentItem } from "./RentableEquipmentItem";

interface EventEquipmentSectionProps {
  venue: ApiVenue;
  firstOccurrence: CreateApiEventOccurrence;
  setEquipmentCost: Dispatch<SetStateAction<number>>;
  occurrences: CreateApiEventOccurrence[];
}

export interface SpaceBasicData {
  id: string;
  name: string;
  rentableEquipmentId: string;
}

export interface RentableEquipmentRender extends ApiRentableEquipment {
  space?: SpaceBasicData;
}

export const EventEquipmentSection: FC<EventEquipmentSectionProps> = ({
  venue,
  firstOccurrence,
  setEquipmentCost,
  occurrences,
}) => {
  const { setFieldValue, values } = useFormikContext<EventFormValues>();

  const rateGroupId = useMemo(() => {
    if (!values) return;
    if (!values.eventGroup || !values.eventGroup.rateGroup) return;
    return values.eventGroup.rateGroup.id;
  }, [values]);

  const { getItemRates, getAllRatesByItemId } = useBillingGroupRates({
    rateGroupId,
    type: ApiBillingGroupType.equipment,
  });

  const eventHoursDuration = useMemo(() => {
    const startDate = new Date(firstOccurrence.start);
    const endDate = new Date(firstOccurrence.end);
    const occurrenceMinutes = differenceInMinutes(endDate, startDate);
    const hours = parseFloat((occurrenceMinutes / 60).toFixed(2));
    return hours;
  }, [firstOccurrence.end, firstOccurrence.start]);

  const addEquipment = useCallback(
    (equipment: ApiRentableEquipment) => {
      const newEquipmentValue = [
        ...values.rentableEquipment,
        { rentableEquipment: equipment, quantity: equipment.minRentable },
      ];
      setFieldValue("rentableEquipment", newEquipmentValue);
    },
    [setFieldValue, values.rentableEquipment]
  );

  const removeEquipment = useCallback(
    (equipment: ApiRentableEquipment) => {
      const index = values.rentableEquipment.findIndex((item) => {
        return typeof item.rentableEquipment === "string"
          ? item.rentableEquipment === equipment.id
          : item.rentableEquipment.id === equipment.id;
      });
      if (index !== -1) {
        const equipmentCopy = [...values.rentableEquipment];
        equipmentCopy.splice(index, 1);
        setFieldValue("rentableEquipment", equipmentCopy);
      }
    },
    [setFieldValue, values.rentableEquipment]
  );

  const getEquipmentId = useCallback(
    (equipment: ApiRentableEquipmentReference) => {
      return typeof equipment === "string" ? equipment : equipment.id;
    },
    []
  );

  const handleOnChangeEquipment = useCallback(
    (equipment: RentableEquipmentRender) => {
      const id = typeof equipment === "string" ? equipment : equipment.id;

      const isAdded = values.rentableEquipment.some((item) => {
        return typeof item.rentableEquipment === "string"
          ? item.rentableEquipment === id
          : item.rentableEquipment.id === id;
      });

      isAdded ? removeEquipment(equipment) : addEquipment(equipment);
    },
    [addEquipment, removeEquipment, values.rentableEquipment]
  );

  const getHasError = useCallback(
    (equipment: ApiRentableEquipment, quantity?: number) => {
      const { maxRentable, minRentable } = equipment;
      const hasError = {
        error: false,
        errorMessage: "",
      };

      if (quantity === undefined) return { error: false, errorMessage: "" };

      if (quantity > maxRentable) {
        hasError.error = true;
        hasError.errorMessage = `Maximum rentable is ${maxRentable}`;
      }
      if (quantity < minRentable) {
        hasError.error = true;
        hasError.errorMessage = `Minimum rentable is ${minRentable}`;
      }

      return { ...hasError };
    },
    []
  );

  const getQuantity = useCallback(
    (equipment: ApiRentableEquipment) => {
      let quantity;
      let error;
      let errorMessage;
      const element = values.rentableEquipment.find(
        (item) => getEquipmentId(item.rentableEquipment) === equipment.id
      );

      if (element) {
        quantity = element.quantity;
        error = element.error;
        errorMessage = element.errorMessage;
      }

      return { quantity, error, errorMessage };
    },
    [getEquipmentId, values.rentableEquipment]
  );

  const handleOnChangeQuantity = useCallback(
    (value: number, equipment: ApiRentableEquipment) => {
      const workingValue = isNaN(value) ? 0 : value;
      const index = values.rentableEquipment.findIndex(
        (item) => getEquipmentId(item.rentableEquipment) === equipment.id
      );
      if (index !== -1) {
        const { error, errorMessage } = getHasError(equipment, workingValue);
        const equipmentCopy = [...values.rentableEquipment];
        const newEquipment = {
          ...equipmentCopy[index],
          quantity: workingValue,
          error,
          errorMessage,
        };
        equipmentCopy[index] = newEquipment;
        setFieldValue("rentableEquipment", equipmentCopy);
      }
    },
    [getEquipmentId, getHasError, setFieldValue, values.rentableEquipment]
  );

  const setEquipmentTotalCost = useCallback(() => {
    const total = values.rentableEquipment.reduce((acc, current) => {
      const equipmentId =
        typeof current.rentableEquipment === "string"
          ? current.rentableEquipment
          : current.rentableEquipment.id;
      const rates = getItemRates(
        equipmentId,
        true,
        occurrences,
        eventHoursDuration,
        current.quantity
      );
      const equipmentCost = rates
        ? rates.reduce((sum, rate) => {
            return sum + rate.total;
          }, 0)
        : 0;
      return acc + equipmentCost;
    }, 0);
    setEquipmentCost(total);
  }, [
    eventHoursDuration,
    setEquipmentCost,
    values.rentableEquipment,
    getItemRates,
    occurrences,
  ]);

  useEffect(() => {
    setEquipmentTotalCost();
  }, [setEquipmentTotalCost]);

  const venueEquipment = useMemo(() => {
    return venue
      ? venue.rentableEquipment.map((e) => {
          return {
            active: e.equipment.active,
            created: e.equipment.created,
            description: e.equipment.description,
            id: e.equipment.id,
            maxRentable: e.maxRentable,
            minRentable: e.minRentable,
            name: e.equipment.name,
            updated: e.equipment.updated,
          };
        })
      : [];
  }, [venue]);

  const spacesEquipment: RentableEquipmentRender[] = useMemo(() => {
    const equipment: (ApiSpaceEquipment & {
      space: SpaceBasicData;
    })[] = [];

    values.spaces.forEach((space) => {
      const spaceEquipment = space.equipment.map((e) => ({
        ...e,
        space: {
          id: space.id,
          name: space.location.name,
          rentableEquipmentId: e.equipment.id,
        },
      }));
      equipment.push(...spaceEquipment);
    });

    const formatedSpaceEquipment: RentableEquipmentRender[] = [];

    equipment.forEach((e) => {
      const isVenueEquipment = venueEquipment.some(
        (ve) => ve.id === e.equipment.id
      );
      if (!isVenueEquipment) {
        formatedSpaceEquipment.push({
          ...e.equipment,
          space: e.space,
          minRentable: e.minRentable,
          maxRentable: e.maxRentable,
          id: `${e.space.name}:${e.equipment.id}`,
        });
      }
    });

    return formatedSpaceEquipment;
  }, [values.spaces, venueEquipment]);

  return (
    <Stack gap={4} w="100%">
      <Heading fontSize="lg">Will you need any equipment?</Heading>
      {venueEquipment.map((equipment) => (
        <Fragment key={equipment.id}>
          <RentableEquipmentItem
            equipment={equipment}
            getQuantity={getQuantity}
            eventHoursDuration={eventHoursDuration}
            handleOnChangeQuantity={handleOnChangeQuantity}
            handleOnChangeEquipment={handleOnChangeEquipment}
            getAllRatesByItemId={getAllRatesByItemId}
            getItemRate={getItemRates}
            occurrences={occurrences}
          />
        </Fragment>
      ))}
      {}
      <Text fontSize="lg">Spaces Equipment</Text>
      {spacesEquipment.map((e) => (
        <Fragment key={e.id}>
          <RentableEquipmentItem
            equipment={e}
            getQuantity={getQuantity}
            eventHoursDuration={eventHoursDuration}
            handleOnChangeQuantity={handleOnChangeQuantity}
            handleOnChangeEquipment={handleOnChangeEquipment}
            getAllRatesByItemId={getAllRatesByItemId}
            getItemRate={getItemRates}
            occurrences={occurrences}
            space={e.space}
          />
        </Fragment>
      ))}
    </Stack>
  );
};
