import { useEffect, useState } from "react";

const US_LOCALE = "en-US";
const CANADA_LOCALE = "en-CA";
const UNITED_KINGDOM_LOCALE = "en-GB";

export function useDateTimeFormatter(locale: string = US_LOCALE) {
  const [formatter, setFormatter] = useState<Intl.DateTimeFormat>();
  // This is to achieve the expected format of dates for Canadian users.
  // We will use United Kingdom locale format only for dates
  const customLocale =
    locale === CANADA_LOCALE ? UNITED_KINGDOM_LOCALE : locale;

  useEffect(() => {
    setFormatter(new Intl.DateTimeFormat(customLocale));
  }, [customLocale, locale]);

  const formatDateTime = (
    date: Date,
    options?: Intl.DateTimeFormatOptions
  ): string => {
    if (!formatter) return "";

    if (options) {
      return new Intl.DateTimeFormat(customLocale, options).format(date);
    }

    return formatter.format(date);
  };

  return { formatDateTime };
}
