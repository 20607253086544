import {
  ApiRequest,
  FindRequestsOptions,
} from "@operations-hero/lib-api-client";
import { createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../project-list";
import { updateProjectSchedule } from "../scheduling/project-scheduling.slice";
import { findProjectRequests } from "./findRequests.thunk";

type ProjectRequestProps = {
  loadingStatus: LoadingStatus;
  options: FindRequestsOptions;
  total: number;
  requestsCache: { [key: string]: ApiRequest };
};

const initialState: ProjectRequestProps = {
  loadingStatus: "idle",
  options: {},
  total: 0,
  requestsCache: {},
};

const projectRequestsSlice = createSlice({
  name: "project/requests",
  initialState: initialState,
  reducers: {
    unloadProjectRequests: (state) => {
      state.loadingStatus = "idle";
      state.options = {};
      state.total = 0;
      state.requestsCache = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findProjectRequests.fulfilled, (state, action) => {
      const {
        options,
        requests: { total, data },
      } = action.payload;
      state.loadingStatus = "fulfilled";
      state.options = options;
      state.total = total;

      const cache = data.reduce<{ [key: string]: ApiRequest }>(
        (map, request) => {
          map[request.id] = request;
          return map;
        },
        {}
      );
      state.requestsCache = cache;
    });
    builder.addCase(findProjectRequests.pending, (state, action) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(findProjectRequests.rejected, (state, action) => {
      state.loadingStatus = "rejected";
    });
    builder.addCase(updateProjectSchedule.fulfilled, (state, action) => {
      const updatedRequest = action.payload.updatedRequest;
      if (updatedRequest) {
        state.requestsCache[updatedRequest.id] = updatedRequest;
      }
    });
  },
});

export const { unloadProjectRequests } = projectRequestsSlice.actions;
export default projectRequestsSlice.reducer;
