import { extendTheme } from "@chakra-ui/react";
import { FormLabelTheme } from "./FormTheme";

const appTheme = extendTheme({
  colors: {
    ScheduledRequestColors: {
      // Dark
      stepCircleActive: "#6d7f97",
      stepCircleInactive: "#2c4669",
    },
  },
  components: {
    FormLabel: FormLabelTheme,
  },
});

export default appTheme;
