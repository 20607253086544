import {
  Button,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../store";
import { setCurrentUser } from "../../store/auth.slice";
import { initLocalCache } from "../../store/local-cache.slice";
import { setNewRequestDialogIsOpen } from "../../store/new-request-form.slice";
import { useAuthentication } from "../auth/AuthProvider";
import { useProductSubscriptions } from "../shell/AppShell";
import { Step } from "../vertical-steps/Step";
import { StepContent } from "../vertical-steps/StepContent";
import { Steps } from "../vertical-steps/Steps";
import { useSteps } from "../vertical-steps/useSteps";
import { FirstRunWizardCatalog } from "./FirstRunWizardCatalog";
import { FirstRunWizardLocationImport } from "./FirstRunWizardLocationImport";
import { FirstRunWizardWorkflow } from "./FirstRunWizardWorkflow";
export interface WizardStepProps {
  onNext: (data?: any) => void;
}

export const FirstRunWizard = () => {
  const {
    apiClient,
    currentAccount,
    currentUser,
    isProductAdmin,
    isInventoryAdmin,
  } = useAuthentication();
  const { hasInventory } = useProductSubscriptions();
  const thunkDispath = useThunkDispatch();
  const dispatch = useDispatch();
  const [showWizard, setShowWizard] = useState<boolean>();

  const { activeStep, gotoStep, nextStep } = useSteps({
    initialStep: 0,
  });

  const { locations, workflows, catalogs } = useSelector(
    (state: RootState) => state.localCache
  );

  useEffect(() => {
    if (!isProductAdmin || showWizard === true) {
      return;
    }

    const needsWorkflow = workflows.length === 0;
    const needsLocation = locations.length === 0;
    const needsCatalogs =
      hasInventory && isInventoryAdmin && catalogs.length === 0;

    setShowWizard(needsWorkflow || needsLocation || needsCatalogs);

    if (needsWorkflow && !needsLocation && !needsCatalogs) {
      gotoStep(1);
    }

    if (needsLocation && !needsWorkflow && !needsCatalogs) {
      gotoStep(2);
    }

    if (needsCatalogs && !needsWorkflow && !needsLocation) {
      gotoStep(3);
    }
  }, [
    gotoStep,
    locations,
    workflows,
    catalogs,
    showWizard,
    hasInventory,
    isProductAdmin,
    isInventoryAdmin,
  ]);

  // reload local cache once the user has reached the last step
  useEffect(() => {
    // 0 indexed
    const initStep = hasInventory ? 4 : 3;
    if (activeStep === initStep) {
      thunkDispath(
        initLocalCache({
          apiClient,
          account: currentAccount,
          user: currentUser,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          if (res.userUpdated) {
            dispatch(setCurrentUser(res.userUpdated));
          }
        });
    }
  }, [
    activeStep,
    thunkDispath,
    apiClient,
    currentAccount,
    currentUser,
    dispatch,
    hasInventory,
  ]);

  const handleClose = useCallback(() => {
    setShowWizard(false);
  }, []);

  const handleWorkflowCreate = useCallback(() => {
    nextStep();
  }, [nextStep]);

  const handleLocationCreate = useCallback(() => {
    nextStep();
  }, [nextStep]);

  const handleCatalogCreate = useCallback(() => {
    nextStep();
  }, [nextStep]);

  const handleCreateRequest = useCallback(() => {
    setShowWizard(false);
    dispatch(setNewRequestDialogIsOpen(true));
  }, [dispatch]);

  return (
    <Modal
      isOpen={showWizard || false}
      onClose={handleClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent p={6}>
        <Steps activeStep={activeStep}>
          <Step title="Getting Started">
            <StepContent>
              <Stack shouldWrapChildren spacing={4}>
                <Text fontSize="lg">
                  Thanks for joining Operations Hero! 🛠️❤️
                </Text>
                <Text>
                  Our heroic journey begins together with a few small steps.
                  Luckily it only takes a couple minutes to get through.
                </Text>
                <HStack>
                  <Button size="sm" onClick={nextStep}>
                    Onward!
                  </Button>
                </HStack>
              </Stack>
            </StepContent>
          </Step>
          <Step title="Create your first workflow">
            <StepContent>
              <FirstRunWizardWorkflow onNext={handleWorkflowCreate} />
            </StepContent>
          </Step>
          <Step title="Import / Add Locations">
            <StepContent>
              <FirstRunWizardLocationImport onNext={handleLocationCreate} />
            </StepContent>
          </Step>
          {hasInventory && isInventoryAdmin && (
            <Step title="Create your first catalog">
              <StepContent>
                <FirstRunWizardCatalog onNext={handleCatalogCreate} />
              </StepContent>
            </Step>
          )}

          <Step title="Next Steps">
            <StepContent>
              <Stack shouldWrapChildren spacing={4}>
                <Text fontSize="lg">🎉 All done! 🎉</Text>
                <Text>
                  That was enough information to create your first request.
                </Text>
                <Text>
                  If you're feeling adventurous, try setting up a custom field,
                  schema rules, customize your workflow, or invite a user{" "}
                </Text>
                <HStack>
                  <Button size="sm" onClick={handleClose}>
                    Let me explore
                  </Button>
                  <Button size="sm" onClick={handleCreateRequest}>
                    Create a request
                  </Button>
                </HStack>
              </Stack>
            </StepContent>
          </Step>
        </Steps>
      </ModalContent>
    </Modal>
  );
};
