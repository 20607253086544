//@ts-nocheck
import {
  BaseComponent,
  createRef,
  CssDimValue,
  DateMarker,
  DateProfile,
  DateRange,
  findElements,
  greatestDurationDenominator,
  NowIndicatorRoot,
  NowTimer,
  RefObject,
  VNode,
} from "@fullcalendar/common";
import {
  coordToCss,
  TimelineCoords,
  TimelineHeaderRows,
} from "@fullcalendar/timeline";
import { TimelineDateProfile } from "./timeline-date-profile";

export interface TimelineHeaderProps {
  dateProfile: DateProfile;
  tDateProfile: TimelineDateProfile;
  clientWidth: number | null;
  clientHeight: number | null;
  tableMinWidth: CssDimValue;
  tableColGroupNode: VNode;
  slatCoords: TimelineCoords;
  rowInnerHeights?: number[];
  onMaxCushionWidth?: (number) => void;
  project: Project | null;
}

export class TimelineHeader extends BaseComponent<TimelineHeaderProps> {
  rootElRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  render() {
    let { props, context } = this;
    const { project, rowInnerHeights } = props;

    // TODO: very repetitive
    // TODO: make part of tDateProfile?
    let timerUnit = greatestDurationDenominator(
      props.tDateProfile.slotDuration
    ).unit;

    // WORKAROUND: make ignore slatCoords when out of sync with dateProfile
    let slatCoords =
      props.slatCoords && props.slatCoords.dateProfile === props.dateProfile
        ? props.slatCoords
        : null;

    const projectStart = project ? new Date(project.start) : new Date();

    const projectEnd = project ? new Date(project.end) : new Date();

    const startOffset = (projectStart.getTimezoneOffset() / 60) * -1;
    projectStart.setHours(projectStart.getHours() + startOffset);

    const endOffset = (projectEnd.getTimezoneOffset() / 60) * -1;
    projectEnd.setHours(projectEnd.getHours() + endOffset);

    let updatedRowInnerHeights = [];
    if (rowInnerHeights) {
      updatedRowInnerHeights = [...rowInnerHeights];
      const lastRowIndex = updatedRowInnerHeights.length - 1;
      updatedRowInnerHeights[lastRowIndex] = 34;
    }

    return (
      <NowTimer unit={timerUnit}>
        {(nowDate: DateMarker, todayRange: DateRange) => (
          <div className="fc-timeline-header" ref={this.rootElRef}>
            <table
              aria-hidden
              className="fc-scrollgrid-sync-table"
              style={{
                minWidth: props.tableMinWidth,
                width: props.clientWidth,
              }}
            >
              {props.tableColGroupNode}
              <tbody>
                <TimelineHeaderRows
                  dateProfile={props.dateProfile}
                  tDateProfile={props.tDateProfile}
                  nowDate={nowDate}
                  todayRange={todayRange}
                  rowInnerHeights={updatedRowInnerHeights}
                />
              </tbody>
            </table>
            <div className="fc-timeline-now-indicator-container">
              {slatCoords && slatCoords.isDateInRange(projectStart) && (
                <NowIndicatorRoot isAxis date={projectStart}>
                  {(rootElRef, classNames, innerElRef, innerContent) => {
                    return (
                      <>
                        <div
                          ref={rootElRef}
                          className={[
                            "fc-timeline-now-indicator-arrow",
                            "project-start",
                          ]
                            .concat(classNames)
                            .join(" ")}
                          style={coordToCss(
                            slatCoords.dateToCoord(projectStart),
                            context.isRtl
                          )}
                        ></div>
                        <div
                          ref={rootElRef}
                          className={[
                            "fc-timeline-now-indicator-line",
                            "project-start",
                          ]
                            .concat(classNames)
                            .join(" ")}
                          style={coordToCss(
                            slatCoords.dateToCoord(projectStart),
                            context.isRtl
                          )}
                        ></div>
                      </>
                    );
                  }}
                </NowIndicatorRoot>
              )}
            </div>
            <div className="fc-timeline-now-indicator-container">
              {slatCoords && slatCoords.isDateInRange(projectEnd) && (
                <NowIndicatorRoot isAxis date={projectEnd}>
                  {(rootElRef, classNames, innerElRef, innerContent) => (
                    <>
                      <div
                        ref={rootElRef}
                        className={[
                          "fc-timeline-now-indicator-arrow",
                          "project-end",
                        ]
                          .concat(classNames)
                          .join(" ")}
                        style={coordToCss(
                          slatCoords.dateToCoord(projectEnd),
                          context.isRtl
                        )}
                      >
                        {innerContent}
                      </div>
                      <div
                        ref={rootElRef}
                        className={[
                          "fc-timeline-now-indicator-line",
                          "project-end",
                        ]
                          .concat(classNames)
                          .join(" ")}
                        style={coordToCss(
                          slatCoords.dateToCoord(projectEnd),
                          context.isRtl
                        )}
                      ></div>
                    </>
                  )}
                </NowIndicatorRoot>
              )}
            </div>
          </div>
        )}
      </NowTimer>
    );
  }

  componentDidMount() {
    this.updateSize();
  }

  componentDidUpdate() {
    this.updateSize();
  }

  updateSize() {
    if (this.props.onMaxCushionWidth) {
      this.props.onMaxCushionWidth(this.computeMaxCushionWidth());
    }
  }

  computeMaxCushionWidth() {
    // TODO: called way too often
    return Math.max(
      ...findElements(
        this.rootElRef.current,
        ".fc-timeline-header-row:last-child .fc-timeline-slot-cushion"
      ).map((el) => el.getBoundingClientRect().width)
    );
  }
}
