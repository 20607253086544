export const filterAllowedTime = (
  time: Date,
  venueStartHour: Date | null | undefined,
  venueEndHour: Date | null | undefined
) => {
  if (!venueStartHour && !venueEndHour) return true;
  const selectedDate = new Date(time);
  const hours = selectedDate.getHours();
  const minutes = selectedDate.getMinutes();

  const sHours = venueStartHour ? venueStartHour.getHours() : 25;
  const sMinutes = venueStartHour ? venueStartHour.getMinutes() : 61;

  const eHours = venueEndHour ? venueEndHour.getHours() : 25;
  const eMinutes = venueEndHour ? venueEndHour.getMinutes() : 61;

  const validStartTime =
    hours > sHours || (hours === sHours && minutes >= sMinutes);

  const validEndTime =
    hours < eHours || (hours === eHours && minutes <= eMinutes);
  return validStartTime && validEndTime;
};
