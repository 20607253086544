import { Avatar, Tag, TagLabel } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import React from "react";

interface UserBadgeProps {
  value: ApiUserSummary;
}

export const UserMentionBadge: React.FC<UserBadgeProps> = ({ value }) => {
  return (
    <Tag background="inherit" pl={2} w="80" textColor="blackAlpha.900">
      <Avatar
        size={"sm"}
        name={`${value.firstName} ${value.lastName}`}
        src={value.profileImage}
        visibility={value.id === "null-user" ? "hidden" : "initial"}
        mr={2}
      />
      <TagLabel fontWeight="normal" fontStyle="initial" fontSize={[16, 16, 14]}>
        {value.firstName} {value.lastName}
      </TagLabel>
    </Tag>
  );
};
