import { Grid, GridItem, Heading } from "@chakra-ui/react";
import { ApiRequestTaskBookDetail } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { DateBadge } from "../../../../components/badges/DateBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";

interface RequesTaskbookHistoryProps {
  requestTaskbook: ApiRequestTaskBookDetail;
  userReadSafetyNotes: boolean;
}

export const RequestTaskbookHistory: FC<RequesTaskbookHistoryProps> = ({
  requestTaskbook,
  userReadSafetyNotes,
}) => {
  return (
    <Grid
      templateColumns="repeat(12, 2fr)"
      gap={3}
      opacity={userReadSafetyNotes ? 1 : 0.5}
    >
      <GridItem colSpan={12}>
        <Heading minW="100%" size="md">
          History
        </Heading>
      </GridItem>
      <GridItem colSpan={[6, 4]}>Last Performed On</GridItem>
      <GridItem colSpan={[6, 8]}>
        <DateBadge date={requestTaskbook?.updated} showRelative={false} />
      </GridItem>
      <GridItem colSpan={[6, 4]}>Created On</GridItem>
      <GridItem colSpan={[6, 8]}>
        <DateBadge date={requestTaskbook?.created} showRelative={false} />
      </GridItem>
      {requestTaskbook?.taskbook.createdBy && (
        <>
          <GridItem colSpan={[6, 4]}>Created By</GridItem>
          <GridItem colSpan={[6, 8]}>
            <UserBadge value={requestTaskbook?.taskbook.createdBy} />
          </GridItem>
        </>
      )}
      {requestTaskbook?.taskbook.updatedBy && (
        <>
          <GridItem colSpan={[6, 4]}>Requested By</GridItem>
          <GridItem colSpan={[6, 8]}>
            <UserBadge value={requestTaskbook?.taskbook.updatedBy} />
          </GridItem>
        </>
      )}
    </Grid>
  );
};
