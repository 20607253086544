import { Box, Button, Checkbox, Divider, Text, VStack } from "@chakra-ui/react";
import {
  ApiEventGroup,
  ApiGroupStatus,
  EventGroupDeclineReasons,
  EventGroupDeclineReasonsKeys,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import Select, {
  SelectItemRenderer,
  SelectRenderer,
} from "react-dropdown-select";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { TextEditorAutoSave } from "../../components/form-helpers/rich-text-editor/RichTextEditorAutoSave";
import { useShowToast } from "../../hooks/showToast";
import { useThunkDispatch } from "../../store";
import {
  setSelectedEventGroupAdditionalNotes,
  updateEventGroupState,
  updateSelectedGroupState,
} from "../../store/event-group-slice";

interface EventGroupDeclineElements {
  eventGroupId: string | undefined;
  eventGroupInformation: ApiEventGroup | undefined;
  onClose: () => void;
  cancel: () => void;
}

type ValueLabel = {
  value: EventGroupDeclineReasonsKeys;
  label: EventGroupDeclineReasons;
};

export const EventGroupDecline: FC<EventGroupDeclineElements> = ({
  eventGroupId,
  eventGroupInformation,
  onClose,
  cancel,
}) => {
  const [reasons, setReasons] = useState<ValueLabel[]>([]);

  const options = useMemo(
    () =>
      Object.entries(EventGroupDeclineReasons).map<ValueLabel>(
        ([key, label]) => ({
          value: key as EventGroupDeclineReasonsKeys,
          label,
        })
      ),
    []
  );
  const showToast = useShowToast();
  const { currentAccount, apiClient } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const ItemRenderer = useCallback(
    ({ item, methods }: SelectItemRenderer<ValueLabel>) => {
      return (
        <Checkbox
          key={item.value}
          isChecked={methods.isSelected(item)}
          onChange={() => methods.addItem(item)}
          w="100%"
          p={2}
        >
          {item.value}
        </Checkbox>
      );
    },
    []
  );
  const ContentRenderer = useCallback(
    ({ props, state }: SelectRenderer<ValueLabel>) => {
      return (
        <Box p={1}>
          {state.values.length === 0 && "Reason"}
          {state.values.length === 1 && <Text>{state.values[0].value}</Text>}
          {state.values.length > 1 && (
            <Text>
              {state.values[0].value} {` and ${state.values.length - 1} more`}
            </Text>
          )}
        </Box>
      );
    },
    []
  );

  const changeGroupStatus = useCallback(async () => {
    if (eventGroupInformation?.status === ApiGroupStatus.denied) {
      showToast("info", "Event Group Already Declined");
      cancel();
      onClose();
      return;
    }
    if (eventGroupId && eventGroupInformation) {
      thunkDispatch(
        updateEventGroupState({
          apiClient,
          accountId: currentAccount.id,
          eventGroupId,
          status: ApiGroupStatus.denied,
          owner: eventGroupInformation.owner,
          declineReasons:
            reasons.length !== 0
              ? reasons.map((reason) => reason.value)
              : ["other"],
          additionalNotes: eventGroupInformation.additionalNotes || "",
        })
      )
        .then(() => {
          showToast("success", "The Event Group has been declined successfuly");
          dispatch(updateSelectedGroupState(ApiGroupStatus.denied));
        })
        .catch((err) => {
          showToast("error", "Error Updating Group Status");
        });
    }
    cancel();
    onClose();
  }, [
    apiClient,
    cancel,
    currentAccount.id,
    dispatch,
    eventGroupId,
    eventGroupInformation,
    onClose,
    reasons,
    showToast,
    thunkDispatch,
  ]);

  const handleUpdateAdditionalNotes = useCallback(
    (value: string) => {
      dispatch(setSelectedEventGroupAdditionalNotes(value));
    },
    [dispatch]
  );

  const handleOnChangeReasons = useCallback((values: ValueLabel[]) => {
    setReasons(values);
  }, []);

  return (
    <VStack
      divider={<Divider border={1} color="gray.400" mt={6} mb={6} />}
      spacing={4}
      p={6}
      align="stretch"
    >
      <Box>
        <Text fontWeight="bold" fontSize={18}>
          Decline this group
        </Text>
      </Box>
      <Box mb={3} mt={3}>
        <Text fontWeight="bold" fontSize={15} pb={2}>
          Please provide a reason for the decline.
        </Text>
        <Text fontWeight="normal" color="gray.700" fontSize={12}>
          Decline reason
        </Text>
        <Select
          multi
          options={options}
          searchable={true}
          searchBy="value"
          valueField="value"
          labelField="label"
          dropdownGap={0}
          keepSelectedInList
          contentRenderer={ContentRenderer}
          dropdownHeight="300"
          values={reasons}
          onChange={handleOnChangeReasons}
          itemRenderer={ItemRenderer}
          style={{ borderRadius: "6px" }}
        />
      </Box>
      <Box>
        <Text fontWeight="bold" fontSize={18} pb={3}>
          Group Additional Notes
        </Text>
        <TextEditorAutoSave
          id="event-group-decline-notes"
          value={eventGroupInformation?.additionalNotes || ""}
          initialValue={eventGroupInformation?.additionalNotes}
          onChange={handleUpdateAdditionalNotes}
        />
      </Box>
      <Box mt={3}>
        <Button variant="outline" borderColor="blue.500" onClick={cancel}>
          Cancel
        </Button>
        <Button colorScheme="blue" float="right" onClick={changeGroupStatus}>
          Decline and Close
        </Button>
      </Box>
    </VStack>
  );
};
