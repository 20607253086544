import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Switch,
} from "@chakra-ui/react";
import { ApiComment } from "@operations-hero/lib-api-client";
import { EditorState } from "draft-js";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ImEyeBlocked } from "react-icons/im";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { UserBadge } from "../../../components/badges/UserBadge";
import {
  getEditorValueAsString,
  RichTextEditorComments,
} from "../../../components/form-helpers/rich-text-editor/RichTextEditorComments";
import { convertToSave } from "./Comment";
import { EventCommentAlert, RequestCommentAlert } from "./CommentAlertMessage";

export interface CommentFormProps {
  comment?: ApiComment;
  onChange?: (comment: ApiComment) => void;
  autoFocusComment?: boolean;
  showCommentButtons?: boolean;
  setCurrentComment?: Dispatch<SetStateAction<ApiComment | undefined>>;
  onSave: (
    newComment: ApiComment,
    isPublic: boolean,
  ) => void | ((newComment: string) => void);
  commentFrom: "request" | "event";
}

export const CommentForm = ({
  comment,
  autoFocusComment,
  setCurrentComment,
  showCommentButtons,
  onChange,
  onSave,
  commentFrom,
}: CommentFormProps) => {
  const { currentUser } = useAuthentication();

  const [isPublic, setIsPublic] = useState(true);
  const [canPostPrivate, setCanPostPrivate] = useState(false);
  const [commentState, setCommentState] = useState<EditorState>();

  const [workingComment, setWorkingComment] = useState<ApiComment>();
  const [isSaving, setIsSaving] = useState(false);

  const setNewComment = useCallback(
    (newComment?: EditorState) => {
      const response = newComment && convertToSave(newComment);

      if (response) {
        const commentObject = {
          id: "",
          createdBy: currentUser,
          comment: response || "",
          isPublic: isPublic,
          created: new Date().toISOString(),
          updated: new Date().toISOString(),
          updatedBy: null,
          mentioned: [],
        };
        setWorkingComment(commentObject);

        setCurrentComment && setCurrentComment(commentObject);
      }
    },
    [currentUser, setCurrentComment, isPublic],
  );

  const handleCommentChange = useCallback((newComment: ApiComment) => {
    setWorkingComment(newComment);
  }, []);

  const saveComment = useCallback(async () => {
    if (workingComment === undefined) return;
    setIsSaving(true);
    try {
      await onSave(workingComment, isPublic);
      setWorkingComment(undefined);
      setCommentState(undefined);
      setNewComment();
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  }, [workingComment, onSave, isPublic, setNewComment]);

  const cancelComment = () => {
    setCommentState(undefined);
    setWorkingComment(undefined);
  };

  const handlePublicSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsPublic(!e.target.checked);
    },
    [],
  );

  useEffect(() => {
    if (!comment || !handleCommentChange) {
      return;
    }

    if (
      (commentState &&
        comment.comment !== getEditorValueAsString(commentState)) ||
      comment.isPublic !== isPublic
    ) {
      handleCommentChange({
        ...comment,
        comment: (commentState && convertToSave(commentState)) || "",
        isPublic,
      });
    }
  }, [comment, commentState, isPublic, handleCommentChange]);

  useEffect(() => {
    if (!comment || !onChange) {
      return;
    }

    if (
      (commentState &&
        comment.comment !== getEditorValueAsString(commentState)) ||
      comment.isPublic !== isPublic
    ) {
      onChange({
        ...comment,
        comment: (commentState && getEditorValueAsString(commentState)) || "",
        isPublic,
      });
    }
  }, [comment, commentState, isPublic, onChange]);

  const handleOnChangeText = useCallback(
    (value: EditorState) => {
      setCommentState(value);
      setNewComment(value);
    },
    [setNewComment],
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          {
            <UserBadge
              value={comment ? comment.createdBy : currentUser}
              textFontWeight="bold"
            />
          }
        </Box>
        {canPostPrivate && (
          <Box>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isPublic" mb="0">
                <Icon
                  verticalAlign="-2px"
                  as={ImEyeBlocked}
                  display="inline"
                  mr={2}
                />
                {"Private"}
              </FormLabel>

              <Switch
                id={`cm-from-${comment?.id}-isPublic`}
                isChecked={!isPublic}
                onChange={handlePublicSwitchToggle}
              />
            </FormControl>
          </Box>
        )}
      </Flex>

      {commentFrom === "request" && (
        <RequestCommentAlert
          isPublic={isPublic}
          setCanPostPrivate={setCanPostPrivate}
        />
      )}

      {commentFrom === "event" && (
        <EventCommentAlert
          isPublic={isPublic}
          setCanPostPrivate={setCanPostPrivate}
        />
      )}

      <RichTextEditorComments
        value={commentState || undefined}
        id="comment"
        setParentState={handleOnChangeText}
      />
      {showCommentButtons && (
        <Box display="flex" alignItems="center" justifyContent="end">
          <Button
            variant="outline"
            size="sm"
            m={2}
            colorScheme="blue"
            onClick={cancelComment}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            size="sm"
            onClick={saveComment}
            p={2}
            colorScheme="blue"
            isLoading={isSaving}
          >
            Save
          </Button>
        </Box>
      )}
    </>
  );
};
