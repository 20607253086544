import {
  ApiAccount,
  ApiClient,
  ApiUser,
  ApiUserAccountLink,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deletePhone, verifyUserPhoneNumber } from "./user-phone-verifications";

export type AuthType = "idle" | "common" | "magicToken";
export interface AuthSliceState {
  authType: AuthType;
  currentUser: ApiUser;
  currentAccount: ApiAccount;
  accounts: ApiUserAccountLink[];
  isProductStandard: boolean;
  isProductAdmin: boolean;
  isEventAdmin: boolean;
  isEventStandard: boolean;
  isInternal: boolean;
  isInventoryStandard: boolean;
  isInventoryAdmin: boolean;
  isEnergyUser: boolean;
  isPlanningAdmin: boolean;
}

export interface InitAuthThunkParams {
  apiClient: ApiClient;
  returnPath: string | null;
  accountId: string | null;
}

export const initAuth = createAsyncThunk(
  "auth/init",
  async (
    { apiClient, returnPath, accountId }: InitAuthThunkParams,
    thunkAPI
  ) => {
    const [currentUser, accounts] = await Promise.all([
      apiClient.getCurrentUser(),
      apiClient.getCurrentUserAccounts(),
    ]);

    const isInternal = accounts.some(
      (x) => x.id === "00000000-0000-0000-0000-000000000000"
    );
    const queryString = !returnPath
      ? ""
      : returnPath.substring(returnPath.indexOf("?"));
    const params = new URLSearchParams(queryString || "");

    const accountIdFromUrl = accountId || params.get("accountId");

    const autoLoginAccount =
      accountIdFromUrl && isInternal
        ? await apiClient.getAccountDetail(accountIdFromUrl)
        : null;

    return {
      currentUser,
      accounts,
      autoLoginAccount,
      accountIdFromUrl,
      isInternal,
    };
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    currentUser: {} as any as ApiUser,
    currentAccount: {} as any as ApiUserAccountLink,
    accounts: [],
    isProductStandard: false,
    isProductAdmin: false,
    isEventAdmin: false,
    isEventStandard: false,
    isInternal: false,
    isInventoryStandard: false,
    isInventoryAdmin: false,
    isEnergyUser: false,
    isPlanningAdmin: false,
    authType: "idle",
  } as AuthSliceState,
  reducers: {
    setCurrentAccount: (state, action: PayloadAction<ApiUserAccountLink>) => {
      const acct = action.payload;
      state.currentAccount = acct;
      state.isProductAdmin = isProductAdmin(
        state.isInternal,
        acct,
        "herohq",
        "administrator"
      );
      state.isEventAdmin = isProductAdmin(
        state.isInternal,
        acct,
        "events",
        "event administrator"
      );

      localStorage.setItem(`${state.currentUser.id}:acct`, acct.id);
    },
    setCurrentUser: (state, action: PayloadAction<ApiUser>) => {
      state.currentUser = action.payload;
    },
    updateAccount: (
      state,
      action: PayloadAction<{ id: string; name: string; logo: string }>
    ) => {
      const found = state.accounts.find((x) => x.id === action.payload.id);
      if (found) {
        found.name = action.payload.name;
        found.logo = action.payload.logo;
      }

      if (state.currentAccount.id === action.payload.id) {
        state.currentAccount.name = action.payload.name;
        state.currentAccount.logo = action.payload.logo;
      }
    },
    setAuthType: (state: AuthSliceState, action: PayloadAction<AuthType>) => {
      state.authType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initAuth.fulfilled, (state, action) => {
      const {
        currentUser,
        accounts,
        accountIdFromUrl,
        autoLoginAccount,
        isInternal,
      } = action.payload;
      state.currentUser = currentUser;
      state.accounts = autoLoginAccount
        ? [...accounts, autoLoginAccount as unknown as ApiUserAccountLink]
        : accounts;

      const lastAccountId =
        accountIdFromUrl || localStorage.getItem(`${currentUser.id}:acct`);
      let account = lastAccountId
        ? accounts.find((x) => x.id === lastAccountId)
        : null;
      account = account || accounts[0];

      // the user has no active accounts. likely a disabled user
      if (!account && !autoLoginAccount) {
        state.isProductAdmin = false;
        return;
      }
      state.isInternal = isInternal;
      state.currentAccount = autoLoginAccount || account;
      state.isProductAdmin = autoLoginAccount
        ? true
        : isProductAdmin(isInternal, account, "herohq", "administrator");

      state.isEventAdmin = autoLoginAccount
        ? true
        : isProductAdmin(isInternal, account, "events", "event administrator");

      state.isInventoryAdmin = autoLoginAccount
        ? true
        : isProductAdmin(
            isInternal,
            account,
            "inventoryhq",
            "inventory administrator"
          );

      state.isProductStandard = isProductAdmin(
        isInternal,
        account,
        "herohq",
        "standard"
      );

      state.isEventStandard = isProductAdmin(
        isInternal,
        account,
        "events",
        "event standard"
      );

      state.isInventoryStandard = isProductAdmin(
        isInternal,
        account,
        "inventoryhq",
        "inventory standard"
      );

      state.isEnergyUser = autoLoginAccount
        ? true
        : isProductAdmin(isInternal, account, "energyhq", "energyhq user");

      state.isPlanningAdmin = autoLoginAccount
        ? true
        : isProductAdmin(
            isInternal,
            account,
            "planninghq",
            "planning administrator"
          );

      localStorage.setItem(`${currentUser.id}:acct`, account.id);
    });

    builder.addCase(verifyUserPhoneNumber.fulfilled, (state, action) => {
      const { phone, phoneVerified } = action.payload;
      if (phoneVerified) {
        state.currentUser = {
          ...state.currentUser,
          phone,
          phoneVerified,
        };
      }
    });

    builder.addCase(deletePhone.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
  },
});

const isProductAdmin = (
  isInternal: boolean,
  account: ApiUserAccountLink,
  product: string,
  role: string
) => {
  return (
    isInternal ||
    account.products
      .find((x) => x.name.toLowerCase() === product.toLowerCase())
      ?.role.toLowerCase() === role.toLowerCase() ||
    false
  );
};

// Action creators are generated for each case reducer function
export const { setCurrentAccount, setAuthType, updateAccount, setCurrentUser } =
  authSlice.actions;

export default authSlice.reducer;
