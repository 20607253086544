import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  ApiRequestTaskBookDetail,
  ApiRequestTaskBookTask,
  ApiSafetyNote,
  ApiSafetyNoteType,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { ReadOnlyTextEditor } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorReadOnly";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  setVisibleSchemaFields,
  unloadForm,
} from "../../../../store/request-form/request-form.slice";
import { initRequestForm } from "../../../../store/request-form/thunks/initRequestForm.thunk";
import { updateTaskbook } from "../../../../store/request-form/thunks/updateTaskbook.thunk";
import {
  RequestTaskbookEnum,
  RequestTaskbookModal,
} from "../taskbook-modals/TaskbookModal";
import { RequestTaskbookHistory } from "./History";
import { PrintTaskbookModal } from "./print/PrintTaskbookModal";
import { RequestSafetyNotes } from "./RequestSafetyNotes";
import { RequestTasks, RequestWithAttachments } from "./RequestTasks";

const SAFETY_NOTES_ID = "safety-notes-checkbox";

export type TaskbookStatus = "started" | "finished";

export const TaskBookWorkView: FC = () => {
  const { apiClient, currentAccount, currentUser, isProductAdmin } =
    useAuthentication();

  const { key, requestTaskbookId, readOnly } = useParams<{
    key?: string;
    requestTaskbookId: string;
    readOnly?: string;
  }>();

  const thunkDispatch = useThunkDispatch();
  const { request, workflow, policy, schemaFields } = useSelector(
    (state: RootState) => state.requestForm,
  );
  const tasksRef = useRef<RequestWithAttachments[] | null>(null);

  const [workingTaskbook, setWorkingTaskbook] =
    useState<ApiRequestTaskBookDetail | null>(null);
  const [engine, setEngine] = useState<SchemaRulesEngine>();
  const [userReadSafetyNotes, setUserReadSafetyNotes] = useState(false);
  const [showSafetyError, setShowSafetyError] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const badgeBgColor = useColorModeValue("gray.50", "none");
  const toast = useToast();
  const dispatch = useDispatch();

  const compareTasksOrder = (
    source: ApiRequestTaskBookTask,
    target: ApiRequestTaskBookTask,
  ) => {
    return source.task.order < target.task.order ? -1 : 1;
  };

  const {
    isOpen: printIsOpen,
    onOpen: printOnOpen,
    onClose: printOnClose,
  } = useDisclosure();

  const getData = useCallback(async () => {
    if (requestTaskbookId && key) {
      const data = await apiClient.findRequestTaskbook(
        currentAccount.id,
        key,
        requestTaskbookId,
      );
      setWorkingTaskbook(data);
    }
  }, [requestTaskbookId, key, apiClient, currentAccount]);

  const getFilteredSafetyNotes = useCallback(() => {
    const warnings: ApiSafetyNote[] = [];
    const risks: ApiSafetyNote[] = [];

    workingTaskbook?.safetyNotes.forEach((safetyNote) => {
      safetyNote.type === ApiSafetyNoteType.warning
        ? warnings.push(safetyNote)
        : risks.push(safetyNote);
    });

    return { warnings, risks };
  }, [workingTaskbook]);

  const getSortTasks = useMemo(() => {
    if (workingTaskbook) {
      return workingTaskbook?.tasks.sort(compareTasksOrder);
    }
  }, [workingTaskbook]);

  const verifySafetyNotesRead = useCallback(() => {
    if (!userReadSafetyNotes) {
      !showSafetyError && setShowSafetyError(true);
      const safetyNotesNode = document.getElementById(SAFETY_NOTES_ID);
      if (safetyNotesNode) {
        safetyNotesNode.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        safetyNotesNode.focus();
      }
    }
  }, [showSafetyError, userReadSafetyNotes]);

  const handleOnClickFinish = useCallback(() => {
    if (!userReadSafetyNotes) {
      verifySafetyNotesRead();
      return;
    }
    onOpen();
  }, [verifySafetyNotesRead, onOpen, userReadSafetyNotes]);

  const startTaskbook = useCallback(async () => {
    if (workingTaskbook && key) {
      await thunkDispatch(
        updateTaskbook({
          apiClient,
          accountId: currentAccount.id,
          taskbookId: workingTaskbook?.id,
          requestId: key,
          requestTaskbook: {
            started: new Date().toString(),
          },
        }),
      ).then(() => {
        setHasStarted(true);
        toast({
          duration: 2000,
          isClosable: true,
          position: "top",
          status: "success",
          title: "Taskbook status was marked as 'Started'",
        });
      });
    }
  }, [apiClient, currentAccount, thunkDispatch, toast, workingTaskbook, key]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const safetyNotesRead = workingTaskbook?.safetyNotesAcceptedBy.some(
      ({ user }) => user.id === currentUser.id,
    );
    if (workingTaskbook?.safetyNotes.length === 0) {
      setUserReadSafetyNotes(true);
    } else {
      setUserReadSafetyNotes(safetyNotesRead || false);
    }
  }, [currentUser, workingTaskbook]);

  useEffect(() => {
    setHasStarted(!!workingTaskbook?.started);
  }, [workingTaskbook]);

  useEffect(() => {
    key &&
      thunkDispatch(
        initRequestForm({
          apiClient,
          account: currentAccount,
          key,
          user: currentUser,
          isProductAdmin,
        }),
      );
    return () => {
      dispatch(unloadForm());
    };
  }, [
    apiClient,
    currentAccount,
    currentUser,
    isProductAdmin,
    key,
    thunkDispatch,
    dispatch,
  ]);

  useEffect(() => {
    if (!request || !workflow || !policy) {
      return;
    }
    const rulesEngine =
      engine ||
      new SchemaRulesEngine({
        account: currentAccount,
        user: currentUser,
        form: "full",
        schemaFields: schemaFields,
        workflow: workflow,
        policy: policy,
        isProductAdmin,
      });

    if (!engine) {
      setEngine(rulesEngine);
    }
    const fields = rulesEngine.getVisibleFields({
      request,
      includeDeleted: false,
    });

    dispatch(setVisibleSchemaFields(fields));
  }, [
    currentAccount,
    currentUser,
    engine,
    isProductAdmin,
    policy,
    request,
    schemaFields,
    workflow,
    dispatch,
  ]);

  return (
    <Container maxW="8xl">
      <Grid templateColumns="repeat(12, 2fr)" gap={2}>
        <GridItem
          pb={2}
          display="flex"
          colSpan={12}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <Text as="span" fontWeight="700" fontSize={["xl", "2xl"]} w="70%">
            <Link
              mr={2}
              pr={2}
              borderRight="1px solid"
              borderRightColor="gray.200"
              as={RouterLink}
              color="blue.500"
              to={`/requests/${key}`}
            >
              {key}
            </Link>
            {workingTaskbook?.taskbook.name}
            {(hasStarted || workingTaskbook?.completed) && (
              <StatusBadge
                status={
                  hasStarted && !workingTaskbook?.completed
                    ? "started"
                    : "finished"
                }
                badgeProps={{
                  ml: "2",
                  rounded: "sm",
                  bg: badgeBgColor,
                  fontSize: "sm",
                  fontWeight: "semibold",
                  px: "2",
                }}
              ></StatusBadge>
            )}
          </Text>

          {!readOnly && workingTaskbook && (
            <HStack w="30%" mt={1} justifyContent="flex-end">
              {!hasStarted && (
                <Button
                  size={isMobile ? "xs" : "sm"}
                  colorScheme="blue"
                  onClick={() => startTaskbook()}
                >
                  Start
                </Button>
              )}
              {workingTaskbook.tasks.length > 4 && (
                <Button
                  size={isMobile ? "xs" : "sm"}
                  colorScheme="blue"
                  onClick={handleOnClickFinish}
                >
                  Finish
                </Button>
              )}
              {!isMobile && (
                <Button
                  size={isMobile ? "xs" : "sm"}
                  colorScheme="blue"
                  onClick={printOnOpen}
                >
                  Print
                </Button>
              )}
            </HStack>
          )}
        </GridItem>

        <GridItem colSpan={12}>
          <Text fontWeight="700">
            {workingTaskbook?.taskbook.category?.name}
          </Text>
        </GridItem>

        <GridItem colSpan={12}>
          <Box mt={2}>
            <ReadOnlyTextEditor value={workingTaskbook?.taskbook.description} />
          </Box>
        </GridItem>

        {workingTaskbook && workingTaskbook.safetyNotes.length > 0 && (
          <GridItem colSpan={12} rowStart={userReadSafetyNotes ? 4 : [1, 4]}>
            <RequestSafetyNotes
              id={SAFETY_NOTES_ID}
              showSafetyError={showSafetyError}
              setShowSafetyError={setShowSafetyError}
              {...getFilteredSafetyNotes()}
              safetyNotesAcceptedBy={workingTaskbook.safetyNotesAcceptedBy}
              userReadSafetyNotes={userReadSafetyNotes}
              setUserReadSafetyNotes={setUserReadSafetyNotes}
              taskBookName={workingTaskbook.taskbook.name}
              hasStarted={hasStarted}
              startTaskbook={startTaskbook}
            />
          </GridItem>
        )}

        {workingTaskbook && getSortTasks && (
          <GridItem colSpan={12}>
            <RequestTasks
              tasksRef={tasksRef}
              tasks={getSortTasks}
              verifySafetyNotesRead={verifySafetyNotesRead}
              taskbookId={workingTaskbook.taskbook.id}
              userReadSafetyNotes={userReadSafetyNotes}
              taskBookType={workingTaskbook.taskbook.type}
            />
          </GridItem>
        )}

        {!readOnly && (
          <GridItem
            pt={2}
            colSpan={12}
            alignItems="center"
            flexDirection="column"
            display={isMobile ? "flex" : "block"}
          >
            <Button
              mr={4}
              size="sm"
              colorScheme="blue"
              w={isMobile ? "80%" : "max-content"}
              onClick={handleOnClickFinish}
              disabled={!userReadSafetyNotes}
            >
              Finish
            </Button>
            <Divider pb={4} />
          </GridItem>
        )}

        {workingTaskbook && (
          <GridItem colSpan={12}>
            <RequestTaskbookHistory
              requestTaskbook={workingTaskbook}
              userReadSafetyNotes={userReadSafetyNotes}
            />
          </GridItem>
        )}
      </Grid>
      {workingTaskbook && tasksRef.current && key && (
        <RequestTaskbookModal
          isOpen={isOpen}
          onClose={onClose}
          modalType={RequestTaskbookEnum.UPDATE}
          requestId={key}
          workingTaskbook={{ ...workingTaskbook, tasks: tasksRef.current }}
        />
      )}
      {workingTaskbook && printIsOpen && (
        <PrintTaskbookModal
          isOpen={printIsOpen}
          onClose={printOnClose}
          taskbook={workingTaskbook}
        />
      )}
    </Container>
  );
};
