import { Flex } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

type CommentsProps = {};
export const CommentsRoot: FC<PropsWithChildren<CommentsProps>> = ({
  children,
}) => {
  return (
    <Flex direction="column" gap={10}>
      {children}
    </Flex>
  );
};
