import { useRef, useState } from "react";

export const useLongPress = ({ onLongPress }: { onLongPress: () => void }) => {
  const [longPressDetected, setLongPressDetected] = useState<string>();

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const isLongPress = useRef<boolean>();
  const isScrolling = useRef<boolean>();

  function handleOnTouchStart() {
    timerRef.current = setTimeout(() => {
      if (!isScrolling.current) {
        isLongPress.current = true;
        setLongPressDetected("longpress");
        onLongPress();
      }
    }, 500);
  }

  function handleOnTouchEnd() {
    isScrolling.current = false;
    if (timerRef && timerRef.current) clearTimeout(timerRef.current);
  }

  function handleOnTouchMove() {
    isScrolling.current = true;
  }

  return {
    longPressDetected,
    handlers: {
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
      onTouchMove: handleOnTouchMove,
    },
  };
};
