import {
  ApiAccount,
  ApiClient,
  ApiUnitOfMeasure,
  ApiUnitOfMeasureType,
  CreateApiUnitOfMeasure,
  FindApiUnitOfMeasureOptions,
  UpdateApiUnitOfMeasure,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface LocalUnitOfMeasureSliceState {
  unitOfMeasures: ApiUnitOfMeasure[];
  unitOfMeasuresByCategory: Record<string, ApiUnitOfMeasure[]>;
  showDisabledUnitOfMeasures: boolean;
  unitOfMeasureType: ApiUnitOfMeasureType | undefined;
}
export type UnitOfMeasureActionType = "deactive" | "reactive";

export interface ToggleOnOrOffUnitOfMeasureThunkParams {
  apiClient: ApiClient;
  accountId: string;
  unitOfMeasureId: string;
  type: UnitOfMeasureActionType;
}
export interface InitLocalUnitOfMeasuresThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
  includeInactive?: boolean;
  type?: ApiUnitOfMeasureType | undefined;
}

export type InitLocalUnitOfMeasuresByCategoryThunkParams =
  InitLocalUnitOfMeasuresThunkParams;

export interface CreateUnitOfMeasureThunkParams {
  apiClient: ApiClient;
  accountId: string;
  unitOfMeasure: CreateApiUnitOfMeasure;
}

export const createUnitOfMeasure = createAsyncThunk(
  "unitOfMeasure/create",
  async (params: CreateUnitOfMeasureThunkParams, thunkAPI) => {
    const { apiClient, accountId, unitOfMeasure } = params;
    const result = await apiClient.createUnitOfMeasure(
      accountId,
      unitOfMeasure
    );
    return result;
  }
);
export interface UpdateUnitOfMeasureThunkParams {
  apiClient: ApiClient;
  accountId: string;
  unitOfMeasure: UpdateApiUnitOfMeasure;
  unitOfMeasureId: string;
}
export const updateUnitOfMeasure = createAsyncThunk(
  "unitOfMeasure/update",
  async (params: UpdateUnitOfMeasureThunkParams, thunkAPI) => {
    const { apiClient, accountId, unitOfMeasure, unitOfMeasureId } = params;
    const result = await apiClient.updateUnitOfMeasure(
      accountId,
      unitOfMeasureId,
      unitOfMeasure
    );
    return result;
  }
);
export const toggleActivateUnitOfMeasure = createAsyncThunk(
  "unitOfMeasure/toggleActive",
  async (params: ToggleOnOrOffUnitOfMeasureThunkParams, thunkAPI) => {
    const { apiClient, accountId, unitOfMeasureId, type } = params;
    if (type === "deactive") {
      await apiClient.deactivateUnitOfMeasure(accountId, unitOfMeasureId);
    }
    if (type === "reactive") {
      await apiClient.reactivateUnitOfMeasure(accountId, unitOfMeasureId);
    }
    return { unitOfMeasureId, type };
  }
);

export const reloadUnitOfMeasures = createAsyncThunk(
  "unitOfMeasure/reload-unit-of-measures",
  async (
    { apiClient, account, search, type }: InitLocalUnitOfMeasuresThunkParams,
    thunkAPI
  ) => {
    const { showDisabledReportingCategories } = (
      thunkAPI.getState() as RootState
    ).reportingCategory;
    const options: FindApiUnitOfMeasureOptions = {
      includeInactive: showDisabledReportingCategories,
      search,
      type,
    };
    const unitOfMeasures = await apiClient.findUnitOfMeasures(
      account.id,
      options
    );
    return { unitOfMeasures };
  }
);

interface LoadUnitOfMeasureByCategoriesParams {
  apiClient: ApiClient;
  accountId: string;
  options?: FindApiUnitOfMeasureOptions;
}

export const loadUnitOfMeasuresByCategory = createAsyncThunk(
  "unitOfMeasure/load-unit-of-measures-by-category",
  async (params: LoadUnitOfMeasureByCategoriesParams, ThunkAPI) => {
    const { apiClient, accountId, options } = params;
    const response = await apiClient.findUnitOfMeasuresByCategory(
      accountId,
      options ? options : { includeInactive: true }
    );
    return response;
  }
);

export const reloadUnitOfMeasuresByCategory = createAsyncThunk(
  "unitOfMeasure/reload-unit-of-measures-by-category",
  async (
    {
      apiClient,
      account,
      search,
    }: InitLocalUnitOfMeasuresByCategoryThunkParams,
    thunkAPI
  ) => {
    const { showDisabledUnitOfMeasures, unitOfMeasureType } = (
      thunkAPI.getState() as RootState
    ).unitOfMeasure;
    const options: FindApiUnitOfMeasureOptions = {
      includeInactive: showDisabledUnitOfMeasures,
      search,
      type: unitOfMeasureType,
    };
    const unitOfMeasuresByCategory =
      await apiClient.findUnitOfMeasuresByCategory(account.id, options);
    return { unitOfMeasuresByCategory };
  }
);

export const localUnitOfMeasuresSlice = createSlice({
  name: "unitOfMeasures",
  initialState: {
    unitOfMeasures: [],
    unitOfMeasuresByCategory: {},
    showDisabledUnitOfMeasures: false,
    unitOfMeasureType: undefined,
  } as LocalUnitOfMeasureSliceState,
  reducers: {
    unloadUnitOfMeasures: (state) => {
      state.unitOfMeasures = [];
      state.unitOfMeasuresByCategory = {};
      state.unitOfMeasureType = undefined;
      state.showDisabledUnitOfMeasures = false;
    },
    toggleShowDisabledUnitOfMeasures: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showDisabledUnitOfMeasures = action.payload;
    },
    setUnitOfMeasuresTypeFilter: (
      state,
      payload: PayloadAction<string | undefined>
    ) => {
      state.unitOfMeasureType = payload.payload as ApiUnitOfMeasureType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reloadUnitOfMeasures.fulfilled, (state, action) => {
      state.unitOfMeasures = action.payload.unitOfMeasures;
    });
    builder.addCase(
      reloadUnitOfMeasuresByCategory.fulfilled,
      (state, action) => {
        state.unitOfMeasuresByCategory =
          action.payload.unitOfMeasuresByCategory;
      }
    );
  },
});

export const {
  unloadUnitOfMeasures,
  toggleShowDisabledUnitOfMeasures,
  setUnitOfMeasuresTypeFilter,
} = localUnitOfMeasuresSlice.actions;

export default localUnitOfMeasuresSlice.reducer;
