import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiEventOccurrence,
  ApiLocation,
  ApiSpace,
  ApiSpaceSummary,
  ApiVenueSummary,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik, useFormikContext } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { VenueAutocompleteControl } from "../../../components/form-helpers/VenueAutocompleteControl";
import { useShowToast } from "../../../hooks/showToast";
import { useThunkDispatch } from "../../../store";
import { createEventOccurrences } from "../../../store/events/event-details.slice";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { SpacesForm } from "../spaces/SpaceForm";
import { NoAvailableImage } from "../spaces/SpaceFormViews";
import { ConflictAlert } from "./form-components/ConflictsAlert";
import ScheduledEvents from "./form-components/ScheduleEvent";

export const eventOccurrenceSchema = yup.object().shape({
  venue: yup.object().required("Venue is required").nullable(),
  spaces: yup
    .array()
    .min(1, "You must select at least 1 space")
    .required("Spaces is required")
    .nullable(),
});

export interface EventOccurrenceFormValues {
  venue: ApiVenueSummary | null;
  spaces: ApiSpaceSummary[];
}

interface CreateEventOccurrencesFormProps {
  eventId: string;
  venue?: ApiVenueSummary;
  spaces?: ApiSpaceSummary[];
  firstOccurrence?: ApiEventOccurrence;
  onClose: () => void;
}

export const CreateEventOccurrencesForm: FC<
  CreateEventOccurrencesFormProps
> = ({ eventId, onClose, venue, spaces, firstOccurrence }) => {
  const [occurrences, setOccurrences] = useState<CreateApiEventOccurrence[]>(
    []
  );
  const [hasConflicts, setHasConflicts] = useState(false);

  const isDesktop = useBreakpointValue({
    base: false,
    sm: true,
    md: true,
    lg: true,
  });
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const {
    isOpen,
    onOpen: openSpacesModal,
    onClose: closeSpaceModal,
  } = useDisclosure();
  const onOpen = useCallback(() => {
    openSpacesModal();
  }, [openSpacesModal]);
  const { apiClient, currentAccount } = useAuthentication();

  const initialValues: EventOccurrenceFormValues = useMemo(
    () => ({
      spaces: [],
      venue: venue || null,
    }),
    [venue]
  );

  const checkConflicts = useCallback(
    async (occurrences: CreateApiEventOccurrence[]) => {
      const response = await apiClient.checkEventConflicts(
        currentAccount.id,
        occurrences.map((x) => {
          return {
            start: x.start,
            end: x.end,
            venue: x.venue,
            spaces: x.spaces.map((s) => (typeof s === "string" ? s : s.id)),
          };
        })
      );
      const result = response.some((item) => item.hasConflict);
      setHasConflicts(result);
    },
    [apiClient, currentAccount.id]
  );

  const handleOnSubmit = useCallback(
    (values: EventOccurrenceFormValues) => {
      const { venue, spaces } = values;
      if (!venue || !occurrences.length) return;
      const occurrencesToSave = occurrences.map((occurrence) => ({
        ...occurrence,
        venue: venue.id,
        spaces,
      }));
      thunkDispatch(
        createEventOccurrences({
          apiClient,
          eventId: eventId,
          accountId: currentAccount.id,
          occurrences: occurrencesToSave,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Event occurrence(s) created successfully");
          onClose();
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });
    },
    [
      apiClient,
      currentAccount.id,
      eventId,
      occurrences,
      onClose,
      showToast,
      thunkDispatch,
    ]
  );

  return (
    <>
      <Formik
        w="100%"
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validationSchema={eventOccurrenceSchema}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          const handleOnAddSpaces = (values: ApiSpace[]) => {
            setFieldValue("spaces", values);
          };

          return (
            <Form>
              <Grid templateColumns="repeat(12, 1fr)" gap={5}>
                <GridItem colSpan={12}>
                  <Heading fontSize="lg">Choose a venue</Heading>
                </GridItem>

                <GridItem colSpan={[12, 12, 10]}>
                  <VenueAutocompleteControl
                    name="venue"
                    value={values.venue}
                    cleanValuesNames={["spaces"]}
                  />
                </GridItem>

                <GridItem
                  colSpan={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Heading fontSize="lg">{`Spaces (${values.spaces.length})`}</Heading>
                  <Button colorScheme="blue" size="sm" onClick={onOpen}>
                    <Icon as={MdAdd} mr={2} /> Add Spaces
                  </Button>
                </GridItem>

                <GridItem colSpan={12}>
                  <EventOccurrenceSpacesSection />
                </GridItem>

                <GridItem colSpan={12}>
                  <Divider />
                </GridItem>

                <GridItem colSpan={12}>
                  <ScheduledEvents
                    occurrences={occurrences}
                    setOccurrences={setOccurrences}
                    selectedVenue={values.venue}
                    selectedSpaces={values.spaces}
                    checkConflicts={checkConflicts}
                    multipleOccurrencesText="Use advanced scheduling"
                    firstOccurrence={firstOccurrence}
                  />
                  {hasConflicts && occurrences.length > 0 && (
                    <ConflictAlert boxProps={{ mt: 6 }} />
                  )}
                </GridItem>

                <GridItem colSpan={12}>
                  <Divider />
                </GridItem>

                <GridItem mt={2} colSpan={12} textAlign="center">
                  <Button
                    mr={10}
                    variant="ghost"
                    colorScheme="blue"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    minW="200px"
                    type="submit"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </GridItem>
              </Grid>

              {isOpen && values.venue && (
                <AccountModal
                  isOpen={isOpen}
                  onClose={closeSpaceModal}
                  contentProps={{ minW: isDesktop ? "xl" : undefined }}
                  title={`Spaces (${spaces?.length})`}
                  content={
                    <SpacesForm
                      viewMode="list"
                      includeCarousel
                      onClose={closeSpaceModal}
                      values={values.spaces}
                      cb={handleOnAddSpaces}
                      venue={values.venue}
                      parent={
                        values.venue
                          ? (values.venue.location as ApiLocation)
                          : null
                      }
                      action="get"
                    />
                  }
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const EventOccurrenceSpacesSection: FC = () => {
  const { values, setFieldValue } =
    useFormikContext<EventOccurrenceFormValues>();
  const iconColor = useColorModeValue("gray.500", "white");

  const handleRemoveSpace = useCallback(
    (spaceId: String) => {
      const index = values.spaces.findIndex((space) => space.id === spaceId);
      if (index === -1) return;
      const spacesCopy = [...values.spaces];
      spacesCopy.splice(index, 1);
      setFieldValue("spaces", spacesCopy);
    },
    [setFieldValue, values.spaces]
  );

  return (
    <Flex flexDir="column" gap={4}>
      {values.spaces.map((space) => (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          key={`eventOccurrence::${space.id}`}
        >
          <Flex gap={2} alignItems="center">
            {space.defaultAttachment !== null ? (
              <Image
                w="50px"
                h="48px"
                borderRadius={6}
                src={space.defaultAttachment.url}
              />
            ) : (
              <NoAvailableImage
                showText={false}
                iconProps={{ boxSize: "14px" }}
                containerProps={{ w: "50px", h: "48px" }}
              />
            )}
            <Text fontWeight="semibold">{space.location.name}</Text>
          </Flex>

          <Icon
            cursor="pointer"
            color={iconColor}
            as={IoTrashOutline}
            onClick={() => handleRemoveSpace(space.id)}
          />
        </Flex>
      ))}
    </Flex>
  );
};
