import {
  ApiClient,
  ApiInvoicePayment,
  FindPaymentsOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface LoadPaymentsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: FindPaymentsOptions;
}

export const loadPayments = createAsyncThunk(
  "payments",
  async (params: LoadPaymentsThunkParams, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState).paymentsList;
    const newFilters = params.filters
      ? { ...filters, ...params.filters }
      : filters;
    const { apiClient, accountId } = params;

    const response = await apiClient.findAllPayments(accountId, newFilters);
    return response;
  }
);

export interface PaymentsListState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: ApiInvoicePayment[];
  total: number;
  filters: FindPaymentsOptions;
}

const DEFAULT_PAYMENTS_FILTERS: FindPaymentsOptions = {
  page: 1,
  pageSize: 20,
  direction: "desc",
  metadata: {},
  dateFilter: [],
};

export const paymentsList = createSlice({
  name: "paymentsList",
  initialState: {
    loading: "idle",
    data: [],
    total: 1,
    filters: { ...DEFAULT_PAYMENTS_FILTERS },
  } as PaymentsListState,
  reducers: {
    unloadPayments: (state: PaymentsListState) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_PAYMENTS_FILTERS;
    },
    updateFilters: (
      state: PaymentsListState,
      action: PayloadAction<FindPaymentsOptions>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    updatePayment: (
      state: PaymentsListState,
      action: PayloadAction<{
        value: Partial<ApiInvoicePayment>;
        paymentId: string;
      }>
    ) => {
      const { value, paymentId } = action.payload;
      const index = state.data.findIndex((payment) => payment.id === paymentId);
      if (index !== -1) {
        const paymentCopy = { ...state.data[index], ...value };
        state.data[index] = paymentCopy;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadPayments.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.loading = "succeeded";
      state.filters.page = options.page;
      state.data = data;
      state.total = total;
    });
    builder.addCase(loadPayments.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadPayments.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const { unloadPayments, updateFilters, updatePayment } =
  paymentsList.actions;

export default paymentsList.reducer;
