import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useProductSubscriptions } from "../components/shell/AppShell";
import { RootState } from "../store";

export const useNavActionItems = () => {
  const { hasRequests, hasEvents, hasInventory } = useProductSubscriptions();

  const { isProductAdmin, isEventAdmin, isEventStandard, isInventoryAdmin } =
    useSelector((state: RootState) => state.auth);

  const { isAdmin, isReviewer, isTechnician, reportsSummary, eventsHandler } =
    useSelector((state: RootState) => state.localCache);

  const { isVenueReadOnlyViewer } = eventsHandler.venueReadOnly;

  const { allowInternalUsersViewCalendar } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const hasCalendar = useMemo(() => {
    if (isVenueReadOnlyViewer) {
      return false;
    }

    if (
      isProductAdmin ||
      isAdmin ||
      isReviewer ||
      isEventAdmin ||
      isEventStandard ||
      isTechnician ||
      allowInternalUsersViewCalendar
    ) {
      return true;
    }
    return false;
  }, [
    isVenueReadOnlyViewer,
    isProductAdmin,
    isAdmin,
    isReviewer,
    isEventAdmin,
    isEventStandard,
    isTechnician,
    allowInternalUsersViewCalendar,
  ]);

  const hasReports = useMemo(() => {
    if (isVenueReadOnlyViewer) {
      return false;
    }

    if (
      reportsSummary["Total"] &&
      reportsSummary["Total"] > 0 &&
      (isAdmin ||
        (hasInventory && isInventoryAdmin) ||
        (hasEvents && isEventAdmin) ||
        (hasRequests && isProductAdmin))
    ) {
      return true;
    }
    return false;
  }, [
    isVenueReadOnlyViewer,
    reportsSummary,
    isAdmin,
    hasInventory,
    isInventoryAdmin,
    hasEvents,
    isEventAdmin,
    hasRequests,
    isProductAdmin,
  ]);

  const showQRReaderButton = useMemo(() => {
    if (isVenueReadOnlyViewer) {
      return false;
    }

    return true;
  }, [isVenueReadOnlyViewer]);

  const showGlobalSearch = useMemo(() => {
    return !isVenueReadOnlyViewer;
  }, [isVenueReadOnlyViewer]);

  return {
    hasCalendar,
    hasReports,
    showGlobalSearch,
    showQRReaderButton,
  };
};
