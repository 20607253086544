import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ApiRequestStatus, ApiWorkflow } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { RequestStatusSelect } from "../selects/RequestStatusSelect";

export interface RequestStatusSelectControlProps {
  label?: string;
  name: string;
  value: ApiRequestStatus | null;
  workflow?: ApiWorkflow | null;
  statuses?: ApiRequestStatus[];
}

export const RequestStatusSelectControl = ({
  name,
  value,
  label,
  statuses,
  workflow,
}: RequestStatusSelectControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (status: ApiRequestStatus | null) => {
      helper.setTouched(true);
      helper.setValue(status || null);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <RequestStatusSelect
        {...field}
        workflow={workflow}
        status={field.value}
        statuses={statuses}
        onChange={handleOnChange}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
