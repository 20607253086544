import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FC, useMemo } from "react";
import { CheckboxControl } from "../../../components/form-helpers/CheckboxControl";
import { CreateApiVendorFormValues } from "./VendorsForm";

export const VendorTypesForm: FC = () => {
  const { values, submitCount, errors, setErrors } =
    useFormikContext<CreateApiVendorFormValues>();

  const errorColor = useColorModeValue("red.500", "red.300");

  const error = useMemo(() => {
    if (submitCount === 0) return false;
    const result =
      !values.isManufacturer && !values.isServiceProvider && !values.isSupplier;

    result &&
      setErrors({
        ...errors,
        isManufacturer: " ",
        isServiceProvider: " ",
        isSupplier: " ",
      });
    return result;
  }, [
    errors,
    setErrors,
    submitCount,
    values.isManufacturer,
    values.isServiceProvider,
    values.isSupplier,
  ]);

  return (
    <FormControl>
      <FormLabel w="100%">Vendor Type</FormLabel>
      <Flex w="100%" flexDir="row" wrap="wrap" justifyContent="space-between">
        <Box>
          <CheckboxControl
            name="isManufacturer"
            label="Manufacturer"
            value={values.isManufacturer}
          />
        </Box>
        <Box>
          <CheckboxControl
            name="isServiceProvider"
            label="Service Provider"
            value={values.isManufacturer}
          />
        </Box>
        <Box>
          <CheckboxControl
            name="isSupplier"
            label="Supplier"
            value={values.isSupplier}
          />
        </Box>
      </Flex>
      {error && (
        <Text color={errorColor} fontSize="sm" mt={2}>
          At least one type must be selected
        </Text>
      )}
    </FormControl>
  );
};
