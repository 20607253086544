import { ApiAccount, ApiClient } from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosProgressEvent } from "axios";
import { Attachment } from "../../../components/attachments/Attachments";
import {
  addAttachment,
  AttachmentTypeEnum,
  updateAttachment,
} from "../request-form.slice";

export interface AddRequestAttachmentThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  idOrKey: string;
  attachment: Attachment;
}

export const uploadAttachment = createAsyncThunk(
  "requests/uploadAttachment",
  async (params: AddRequestAttachmentThunkParams, { dispatch }) => {
    const { apiClient, account, idOrKey, attachment } = params;

    const upload = await apiClient.createUpload(account.id);

    const newAttachment = {
      ...attachment,
      isUploading: true,
      uploadId: upload.id,
      progress: 0,
    };

    dispatch(
      addAttachment({
        ...newAttachment,
        attachmentType: AttachmentTypeEnum.REQUEST,
      })
    );

    await axios.put(upload.url, attachment.file, {
      headers: { "Content-Type": attachment.file!.type },
      onUploadProgress: (progressEvent: AxiosProgressEvent) =>
        dispatch(
          updateAttachment({
            ...newAttachment,
            attachmentType: AttachmentTypeEnum.REQUEST,
            progress: Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            ),
          })
        ),
    });

    dispatch(
      updateAttachment({
        ...newAttachment,
        attachmentType: AttachmentTypeEnum.REQUEST,
        progress: 100,
      })
    );

    const apiAttachment = await apiClient.createRequestAttachment(
      account.id,
      idOrKey,
      {
        uploadId: upload.id,
        name: newAttachment.name,
      }
    );

    dispatch(
      updateAttachment({
        ...newAttachment,
        file: undefined,
        isNew: false,
        progress: undefined,
        uploadId: apiAttachment.id,
        url: apiAttachment.url,
        attachmentType: AttachmentTypeEnum.REQUEST,
      })
    );
  }
);
