import { Box, Button, Icon, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

export interface SubFormHeaderProps {
  buttonLabel: string;
  textColor: string;
  buttonColor: string;
  title: string;
  addSubFormFunction: () => void;
}

export const SubFormHeader: React.FC<SubFormHeaderProps> = ({
  title,
  buttonLabel,
  buttonColor,
  textColor,
  addSubFormFunction,
}) => {
  const variant = useBreakpointValue({ base: "xl", md: "3xl" });
  return (
    <Box display="inline-block" mt={6} w="100%">
      <Text
        fontSize={variant}
        display="inline-block"
        color={textColor}
        fontWeight="700"
      >
        {title}
      </Text>
      <Button
        float="right"
        variant="outline"
        color={buttonColor}
        borderColor={buttonColor}
        onClick={addSubFormFunction}
      >
        <Icon as={AiOutlinePlus} mr={2} />
        {buttonLabel}
      </Button>
    </Box>
  );
};
