import { Badge, BadgeProps, Icon } from "@chakra-ui/react";
import { ApiRequestPriority } from "@operations-hero/lib-api-client";
import { IoCaretDownOutline } from "react-icons/io5";

export const priorityColorMap = {
  [ApiRequestPriority.expedite]: "red.600",
  [ApiRequestPriority.important]: "orange.300",
  [ApiRequestPriority.standard]: "blue.600",
  [ApiRequestPriority.low]: "gray",
};

export interface PriorityBadgeProps {
  priority: ApiRequestPriority;
  isControlValue?: Boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  rest?: BadgeProps;
}

export const PriorityBadge = ({
  priority,
  isControlValue,
  isDisabled,
  isSelected,
  rest,
}: PriorityBadgeProps) => {
  return (
    <Badge
      variant="none"
      fontSize="sm"
      color={isSelected ? "white" : priorityColorMap[priority]}
      borderColor={priorityColorMap[priority]}
      colorScheme="gray"
      {...rest}
    >
      {priority}
      {isDisabled && isControlValue && ","}
      {!isDisabled && isControlValue && (
        <Icon as={IoCaretDownOutline} boxSize={3} />
      )}
    </Badge>
  );
};
