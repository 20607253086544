import {
  Button,
  Heading,
  HStack,
  ModalFooter,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";
import { AccountModal } from "../../pages/account-settings/account-modal/AccountModal";
import { useThunkDispatch } from "../../store";
import { deletePhone } from "../../store/user-phone-verifications";
import { useAuthentication } from "../auth/AuthProvider";
export type DeletePhoneNumberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDeletePhone: () => Promise<void>;
};
export const DeletePhoneNumberModal: FC<DeletePhoneNumberModalProps> = ({
  isOpen,
  onClose,
  onDeletePhone,
}) => {
  const { apiClient } = useAuthentication();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const thunkDispatch = useThunkDispatch();

  const handleOnDeletePhoneNumber = useCallback(async () => {
    setIsDeleting(true);
    await thunkDispatch(deletePhone({ apiClient }));
    setIsDeleting(false);
    onClose();
    await onDeletePhone();
  }, [onClose, thunkDispatch, onDeletePhone, apiClient]);

  return (
    <AccountModal
      title={
        <HStack alignItems="center">
          <Heading size="md">Delete phone number?</Heading>
        </HStack>
      }
      content={
        <VStack justify="stretch" gap="2" align="start">
          <Text>
            The verified phone number will be removed and you will no longer be
            available to receive text messages.
          </Text>
          <Text>This action cannot be undone.</Text>
        </VStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      footer={
        <ModalFooter gap="2">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={handleOnDeletePhoneNumber}
            isLoading={isDeleting}
          >
            Delete
          </Button>
        </ModalFooter>
      }
      closeButton={false}
    />
  );
};
