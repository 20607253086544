import {
  ApiAttachment,
  ApiClient,
  ApiPagedResult,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { Attachment } from "../../../components/attachments/Attachments";
import { LoadingStatus } from "../project-list";

type GetAttachmentsParams = {
  apiClient: ApiClient;
  projectId: string;
};

export const findAttachments = createAsyncThunk<
  ApiPagedResult<ApiAttachment>,
  GetAttachmentsParams
>("project-attachments/find", async ({ apiClient, projectId }, thunkAPI) => {
  const store = thunkAPI.getState() as RootState;
  const { currentAccount } = store.auth;
  const response = await apiClient.findProjectAttachments(
    currentAccount.id,
    projectId,
    { page: 1, pageSize: 50 }
  );

  return response;
});

export type AttachmentHandlerProps = {
  attachments: Attachment[];
  attachmentsLoadingStatus: LoadingStatus;
};

export const findAttachmentsHandlers = <T extends AttachmentHandlerProps>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(findAttachments.pending, (state, action) => {
    state.attachmentsLoadingStatus = "pending";
  });
  builder.addCase(findAttachments.rejected, (state, action) => {
    state.attachmentsLoadingStatus = "rejected";
  });
  builder.addCase(findAttachments.fulfilled, (state, action) => {
    const { data } = action.payload;
    state.attachments = data.map((attachment) => ({
      created: attachment.created,
      name: attachment.name,
      url: attachment.url,
      uploadId: attachment.id,
      type: attachment.contentType,
      isUploading: false,
      isNew: false,
    }));
    state.attachmentsLoadingStatus = "fulfilled";
  });
};
