import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiEventOccurrenceConflict,
  ApiSpaceSummary,
  ApiVenue,
  ApiVenueSummary,
  CheckApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { Pager } from "../../../../components/pager/Pager";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  addEditedDate,
  addEditedDateForAllSelected,
  addEditedSpaceForAllSelected,
  addSelectedDate,
  EventOccurrenceUpdateProps,
  loadEventDates,
  setAllOccurencesCheck,
  setCurrentPage,
  setIncludeInactive,
  setOnlyUpdateEvent,
  toggleActivateEventOccurrence,
  unloadDatesModal,
  updateAllEventOccurrences,
  updateEventDetailLastUpdated,
  updateMultipleEventOccurrences,
} from "../../../../store/events/event-details.slice";
import { updateSingleEventData } from "../../../../store/events/event-list.slice";
import { setIsConflictsModalOpen } from "../../../../store/events/event-modal.slice";
import { getVenueTimes } from "../../../../utils/getVenueTimes";
import { isValidOccurrenceVenue } from "../../../../utils/isValidOccurrenceVenue";
import { updateTimes } from "../../../../utils/updateTimes";
import { ChangeDateTime } from "../../event-form/edit-form-components/ChangeDateTime";
import { toastStatus } from "../EventEditForm";
import { ConflictAlert } from "../form-components/ConflictsAlert";
import { ChangeSelectedTimes } from "./ChangeSelectedTimes";
import { ChooseVenue } from "./ChooseVenue";
import { OccurrenceModal, OccurrenceModalType } from "./OcurrencesModal";

const VENUES_PAGE_SIZE = 50;

interface EventDateProps {
  onCancel: () => void;
  showToast: (status: toastStatus, title: string) => void;
  isOnList?: boolean;
}

export interface UpdateLocalSpaces {
  venue: ApiVenueSummary;
  spaces: ApiSpaceSummary[];
  conflicts: ApiEventOccurrenceConflict;
  occurrenceId?: string;
}

export interface UpdateLocalTimes {
  selectedDate: Date;
  startHour: Date;
  endHour: Date;
  conflicts: ApiEventOccurrenceConflict;
  occurrenceId: string;
}

export const EventDate: FC<EventDateProps> = ({
  onCancel,
  showToast,
  isOnList,
}) => {
  const {
    event,
    eventDates,
    allOccurrencesCheck,
    eventDatesEditedModal,
    eventDateCheckAll,
    eventDatesSelectedModal,
    eventDatesFilters,
  } = useSelector((state: RootState) => state.eventDetails);
  const {
    eventDatesCurrentPage,
    eventDatesTotalPages,
    pageSize,
    includeInactive,
  } = eventDatesFilters;

  const { loading } = useSelector((state: RootState) => state.eventList);
  const { limitAllEventsByVenueHours } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const [allVenues, setAllVenues] = useState<ApiVenueSummary[]>([]);
  const [localOccurrences, setLocalOccurrences] = useState<
    EventOccurrenceUpdateProps[]
  >([]);
  const [selectedOccurrence, setSelectedOccurrence] = useState<
    string | undefined
  >();
  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const headerBgColor = useColorModeValue("blue.50", "whiteAlpha.300");
  const textColor = useColorModeValue("black", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.200");
  const conflictBgColor = useColorModeValue("orange.50", "transparent");
  const conflictCheckBoxColor = useColorModeValue("orange.500", "white");

  const inactiveBgColor = useColorModeValue("gray.50", undefined);
  const inactiveWarning = useColorModeValue("gray.500", undefined);
  const inactiveTitle = useColorModeValue("gray.200", undefined);

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const [modalType, setModalType] = useState<OccurrenceModalType>("cancel");
  const [venueSelected, setVenueSelected] = useState<ApiVenueSummary | null>(
    event ? event.venue : null
  );
  const [dateStartSelect, setDateStartSelect] = useState<Date>();
  const [dateEndSelect, setDateEndSelect] = useState<Date>();

  const getAllVenues = useCallback(async () => {
    const venues = await apiClient.findVenues(currentAccount.id, {
      pageSize: VENUES_PAGE_SIZE,
    });

    setAllVenues(venues.data);
  }, [apiClient, currentAccount.id]);

  const handleOnChangeCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      const occurrence = localOccurrences.find((oc) => oc.id === id);
      if (!occurrence) return;
      dispatch(addSelectedDate({ occurrence, isChecked: checked }));
    },
    [dispatch, localOccurrences]
  );

  const handleSelectAll = useCallback(() => {
    dispatch(setAllOccurencesCheck(!allOccurrencesCheck));
    includeInactive && dispatch(setIncludeInactive(!includeInactive));
  }, [dispatch, allOccurrencesCheck, includeInactive]);

  const cleanIsCheckandCheckAll = useCallback((cleanCheckAll?: boolean) => {
    cleanCheckAll && setAllOccurencesCheck(false);
  }, []);

  const handleOnCancelEvent = useCallback(
    (occurrenceId: string) => {
      cleanIsCheckandCheckAll();
      setSelectedOccurrence(occurrenceId);
      setModalType("cancel");
      onOpen();
    },
    [cleanIsCheckandCheckAll, onOpen]
  );

  const handleOnReactivateEvent = useCallback(
    (occurrenceId: string) => {
      if (!event) return;
      thunkDispatch(
        toggleActivateEventOccurrence({
          apiClient,
          type: "reactivate",
          eventId: event.id,
          accountId: currentAccount.id,
          occurrencesIds: [occurrenceId],
        })
      );
    },
    [apiClient, currentAccount.id, event, thunkDispatch]
  );

  const handleOnCloseModal = useCallback(() => {
    cleanIsCheckandCheckAll();
    setSelectedOccurrence(undefined);
    thunkDispatch(loadEventDates({ apiClient, account: currentAccount })).then(
      () => {
        const eventDatesCopy = [...eventDates];
        setLocalOccurrences(eventDatesCopy);
        onClose();
      }
    );
  }, [
    eventDates,
    cleanIsCheckandCheckAll,
    onClose,
    apiClient,
    currentAccount,
    thunkDispatch,
  ]);

  const checkConflicts = useCallback(
    async (occurrencesToCheck: EventOccurrenceUpdateProps[]) => {
      const occurrencesRequiredData =
        occurrencesToCheck.map<CheckApiEventOccurrence>((ocurrence) => {
          return {
            start: ocurrence.start,
            end: ocurrence.end,
            venue: ocurrence.venue.id,
            spaces: ocurrence.spaces.map((space) => space.id),
            id: ocurrence.id,
          };
        });
      const conflicts = await apiClient.checkEventConflicts(
        currentAccount.id,
        occurrencesRequiredData
      );
      const newOccurrences = occurrencesToCheck.map((item, idx) => ({
        ...item,
        conflicts: conflicts[idx],
      }));

      return newOccurrences;
    },
    [apiClient, currentAccount.id]
  );

  const updateSingleOccurrenceDates = useCallback(
    (params: UpdateLocalTimes) => {
      const { selectedDate, startHour, endHour, conflicts, occurrenceId } =
        params;
      const occurrencesCopy = [...localOccurrences];
      const index = occurrencesCopy.findIndex(
        (item) => item.id === occurrenceId
      );

      if (index !== -1) {
        const occurrenceToUpdate = occurrencesCopy[index];
        const updatedStart = updateTimes(selectedDate, startHour).toISOString();
        const updatedEnd = updateTimes(selectedDate, endHour).toISOString();
        occurrencesCopy[index] = {
          ...occurrenceToUpdate,
          end: updatedEnd,
          mustUpdate: true,
          start: updatedStart,
          conflicts: conflicts,
        };
        dispatch(addEditedDate(occurrencesCopy[index]));
        setLocalOccurrences(occurrencesCopy);
      }
    },
    [localOccurrences, dispatch]
  );

  const updateSelectedOccurrencesDates = useCallback(
    async (start: Date, end: Date) => {
      const updatedLocalOccurrences = localOccurrences.map((occurrence) => {
        const isSelected =
          eventDatesSelectedModal[occurrence.id] || allOccurrencesCheck;
        const updateStartDate = updateTimes(occurrence.start, start);
        const updateEndDate = updateTimes(occurrence.end, end);
        const isValid = limitAllEventsByVenueHours
          ? isValidOccurrenceVenue(
              venueSelected ? venueSelected : (occurrence.venue as ApiVenue),
              new Date(updateStartDate),
              new Date(updateStartDate),
              new Date(updateEndDate)
            )
          : true;

        if (isSelected && isValid) {
          return {
            ...occurrence,
            start: updateStartDate.toISOString(),
            end: updateEndDate.toISOString(),
            mustUpdate: true,
          };
        }

        if (isSelected && !isValid) {
          return {
            ...occurrence,
            start: updateStartDate.toISOString(),
            end: updateEndDate.toISOString(),
            mustUpdate: false,
          };
        }
        return occurrence;
      });
      const newOccurrences = await checkConflicts(updatedLocalOccurrences);
      dispatch(
        addEditedDateForAllSelected({
          start: start.toISOString(),
          end: end.toISOString(),
        })
      );
      setLocalOccurrences(newOccurrences);
      cleanIsCheckandCheckAll();
    },
    [
      checkConflicts,
      cleanIsCheckandCheckAll,
      localOccurrences,
      eventDatesSelectedModal,
      dispatch,
      allOccurrencesCheck,
      limitAllEventsByVenueHours,
      venueSelected,
    ]
  );

  const updateSingleVenueAndSpace = useCallback(
    (
      occurrenceId: string,
      venue: ApiVenueSummary,
      conflicts: ApiEventOccurrenceConflict,
      spaces: ApiSpaceSummary[]
    ) => {
      const occurrencesCopy = [...localOccurrences];
      const index = occurrencesCopy.findIndex(
        (item) => item.id === occurrenceId
      );
      if (index !== -1) {
        const prevOccurrence = occurrencesCopy[index];
        occurrencesCopy[index] = {
          ...prevOccurrence,
          venue: venue,
          spaces: spaces,
          mustUpdate: true,
          conflicts: conflicts,
        };
        dispatch(addEditedDate(occurrencesCopy[index]));
        setLocalOccurrences(occurrencesCopy);
      }
    },
    [localOccurrences, dispatch]
  );

  const updateSelectedVenuesAndSpaces = useCallback(
    async (venue: ApiVenueSummary, spaces: ApiSpaceSummary[]) => {
      if (allOccurrencesCheck || Object.keys(eventDatesSelectedModal).length) {
        const updatedLocalOccurrences = localOccurrences.map((occurrence) => {
          const isValid = limitAllEventsByVenueHours
            ? isValidOccurrenceVenue(
                venue,
                new Date(occurrence.start),
                new Date(occurrence.start),
                new Date(occurrence.end)
              )
            : true;
          const isSelected =
            eventDatesSelectedModal[occurrence.id] || allOccurrencesCheck;

          if (isSelected && isValid) {
            return {
              ...occurrence,
              venue: venue,
              spaces: spaces,
              mustUpdate: true,
            };
          }

          if (isSelected && !isValid) {
            return {
              ...occurrence,
              venue: venue,
              spaces: spaces,
              mustUpdate: false,
            };
          }
          return occurrence;
        });
        const newOccurrences = await checkConflicts(updatedLocalOccurrences);
        dispatch(addEditedSpaceForAllSelected({ venue, spaces }));
        setLocalOccurrences([...newOccurrences]);
        cleanIsCheckandCheckAll();
      }
    },
    [
      checkConflicts,
      cleanIsCheckandCheckAll,
      allOccurrencesCheck,
      localOccurrences,
      dispatch,
      eventDatesSelectedModal,
      limitAllEventsByVenueHours,
    ]
  );

  const updateVenuesAndSpaces = useCallback(
    (params: UpdateLocalSpaces) => {
      const { venue, spaces, occurrenceId, conflicts } = params;
      if (
        allOccurrencesCheck ||
        Object.keys(eventDatesSelectedModal).length > 0
      ) {
        updateSelectedVenuesAndSpaces(venue, spaces);
        return;
      }
      if (occurrenceId) {
        updateSingleVenueAndSpace(occurrenceId, venue, conflicts, spaces);
      }
    },
    [
      allOccurrencesCheck,
      updateSelectedVenuesAndSpaces,
      updateSingleVenueAndSpace,
      eventDatesSelectedModal,
    ]
  );

  const handleOnSaveOccurrences = useCallback(async () => {
    if (!event) return;

    if (allOccurrencesCheck) {
      const [occurrence] = localOccurrences;
      const newOccurrence = {
        start: occurrence.start,
        end: occurrence.end,
        venue: occurrence.venue.id,
        spaces: occurrence.spaces.map((item) => item.id),
      };
      thunkDispatch(
        updateAllEventOccurrences({
          apiClient,
          accountId: currentAccount.id,
          eventId: event.id,
          occurrence: newOccurrence,
          selectedOccurrences: localOccurrences,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          if (res.length > 0 && res[0].updated) {
            dispatch(
              updateEventDetailLastUpdated({
                lastUpdated: res[0].updated,
                updatedBy: currentUser,
              })
            );
          }
          showToast(
            "success",
            "All event occurrences were updated successfully"
          );
        })
        .catch(() => {
          showToast("error", "Something happened while updating occurrences");
        });
      const newEvent = await apiClient.getEvent(currentAccount.id, event.id);
      dispatch(setOnlyUpdateEvent(newEvent));
      dispatch(setAllOccurencesCheck(false));
      cleanIsCheckandCheckAll();
      dispatch(unloadDatesModal());
      onCancel();
      return;
    }

    const occurrencesToUpdate = Object.values(eventDatesEditedModal).map(
      (item) => ({
        ...item,
        spaces: item.spaces.map((space) => space.id),
        venue: item.venue.id,
        conflicts: item.conflicts,
      })
    );

    thunkDispatch(
      updateMultipleEventOccurrences({
        apiClient,
        accountId: currentAccount.id,
        eventId: event?.id,
        occurrences: occurrencesToUpdate,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        showToast("success", "Occurrence(s) updated successfully");
        dispatch(unloadDatesModal());
        onCancel();
      })
      .catch(() => {
        showToast("error", "someething went wrong, please try again");
      });
  }, [
    apiClient,
    cleanIsCheckandCheckAll,
    currentAccount.id,
    currentUser,
    dispatch,
    event,
    allOccurrencesCheck,
    localOccurrences,
    onCancel,
    showToast,
    thunkDispatch,
    eventDatesEditedModal,
  ]);

  const handleOnSaveChanges = useCallback(() => {
    setModalType("save");
    onOpen();
  }, [onOpen]);

  const handleOnChangePage = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
    },
    [dispatch]
  );

  const getVenueHours = useCallback(
    (selectedDate: Date | null, venue?: ApiVenueSummary | null) => {
      return getVenueTimes(selectedDate, venue);
    },
    []
  );

  const handleShowConflicts = useCallback(
    (occurrence: EventOccurrenceUpdateProps) => {
      if (!event) return;
      dispatch(
        setIsConflictsModalOpen({
          isOpen: true,
          workingEventId: event.id,
          workingOccurrence: occurrence,
        })
      );
    },
    [dispatch, event]
  );

  useEffect(() => {
    if (!isOnList) return;
    thunkDispatch(loadEventDates({ apiClient, account: currentAccount }));
  }, [
    apiClient,
    currentAccount,
    thunkDispatch,
    isOnList,
    eventDatesCurrentPage,
    eventDatesFilters.includeInactive,
  ]);

  useEffect(() => {
    if (Object.values(eventDateCheckAll).length) {
      const eventDateCopy = eventDates.map((occurrence) => {
        return {
          ...occurrence,
          start: eventDateCheckAll.start
            ? updateTimes(
                occurrence.start,
                eventDateCheckAll.start
              ).toISOString()
            : occurrence.start,
          end: eventDateCheckAll.end
            ? updateTimes(occurrence.end, eventDateCheckAll.end).toISOString()
            : occurrence.end,
          venue: eventDateCheckAll.venue
            ? eventDateCheckAll.venue
            : occurrence.venue,
          spaces: eventDateCheckAll.spaces
            ? eventDateCheckAll.spaces
            : occurrence.spaces,
          mustUpdate: true,
        };
      });
      setLocalOccurrences([...eventDateCopy]);
      return;
    }
    setLocalOccurrences([...eventDates]);
  }, [eventDates, eventDateCheckAll]);

  useEffect(() => {
    getAllVenues();
  }, [getAllVenues]);

  useEffect(() => {
    if (loading === "succeeded" && event) {
      dispatch(
        updateSingleEventData({
          eventId: event.id,
          values: { occurrences: eventDates },
        })
      );
    }
  }, [dispatch, event, eventDates, loading]);

  useEffect(() => {
    return () => {
      dispatch(unloadDatesModal());
    };
  }, [dispatch]);

  return (
    <Box alignItems="center" w="100%">
      <Flex
        alignItems="center"
        display="flex"
        flexDir="row"
        flexWrap="wrap"
        borderRadius={6}
        border={["none", "none", "1px solid #E2E8F0"]}
      >
        <Box
          display={["none", "none", "flex"]}
          w="100%"
          py={5}
          px={4}
          bgColor={["transparent", "transparent", headerBgColor]}
        >
          <Checkbox
            w="9%"
            onChange={handleSelectAll}
            isChecked={allOccurrencesCheck}
          />
          {!allOccurrencesCheck &&
          Object.keys(eventDatesSelectedModal).length === 0 ? (
            <>
              <Box w="38%" fontWeight="bold" alignItems="left" px={2}>
                <Text textColor={textColor} as="span">
                  Date/Time
                </Text>
              </Box>

              <Box w="38%" fontWeight="bold" as="span" px={2}>
                <Text textColor={textColor}>Venue/Space</Text>
              </Box>

              <QuickFilterButton
                activeText="Include Inactive"
                isActive={includeInactive}
                onClick={() => dispatch(setIncludeInactive(!includeInactive))}
              />
            </>
          ) : (
            <>
              <ChangeSelectedTimes
                selectedOccurrences={Object.keys(eventDatesSelectedModal)}
                occurrences={localOccurrences}
                updateSelectedOccurrencesDates={updateSelectedOccurrencesDates}
                venueSelectAll={venueSelected}
                limitByVenue={limitAllEventsByVenueHours}
                setDateStartSelect={setDateStartSelect}
                setDateEndSelect={setDateEndSelect}
              />
              <ChooseVenue
                venue={venueSelected || null}
                spaces={[]}
                hasMultipleSelections={
                  allOccurrencesCheck ||
                  Object.keys(eventDatesSelectedModal).length > 0
                }
                selectedOccurrences={Object.keys(eventDatesSelectedModal)}
                occurrences={localOccurrences}
                isSelectedAll={allOccurrencesCheck}
                venuesOptions={allVenues}
                limitByVenue={limitAllEventsByVenueHours}
                updateVenuesAndSpaces={updateVenuesAndSpaces}
                cleanIsCheckandCheckAll={cleanIsCheckandCheckAll}
                setVenueChangeSelected={setVenueSelected}
              />
            </>
          )}
        </Box>
        <Box display={["flex", "flex", "none"]} w="100%" p={4}>
          <Checkbox
            w="9%"
            onChange={handleSelectAll}
            isChecked={allOccurrencesCheck}
          />
          {!allOccurrencesCheck &&
          Object.keys(eventDatesSelectedModal).length === 0 ? (
            <>
              <Text
                w="40%"
                alignItems="left"
                color={textColor}
                fontWeight="bold"
              >
                Select All
              </Text>
            </>
          ) : (
            <>
              <ChangeSelectedTimes
                selectedOccurrences={Object.keys(eventDatesSelectedModal)}
                occurrences={localOccurrences}
                updateSelectedOccurrencesDates={updateSelectedOccurrencesDates}
                limitByVenue={limitAllEventsByVenueHours}
                setDateStartSelect={setDateStartSelect}
                setDateEndSelect={setDateEndSelect}
              />
              <ChooseVenue
                hasMultipleSelections={
                  allOccurrencesCheck ||
                  Object.keys(eventDatesSelectedModal).length > 0
                }
                venue={null}
                spaces={null}
                selectedOccurrences={Object.keys(eventDatesSelectedModal)}
                isSelectedAll={allOccurrencesCheck}
                venuesOptions={allVenues}
                occurrences={localOccurrences}
                updateVenuesAndSpaces={updateVenuesAndSpaces}
                cleanIsCheckandCheckAll={cleanIsCheckandCheckAll}
              />
            </>
          )}
        </Box>

        {localOccurrences.map(
          (occurrence: EventOccurrenceUpdateProps, index) => {
            return (
              <Box
                w="100%"
                key={occurrence.id}
                mt="4px"
                border={["solid 1px", "solid 1px", "none"]}
                borderColor={borderColor}
                bgColor={
                  !occurrence.active
                    ? inactiveBgColor
                    : occurrence.conflicts.hasConflict ||
                        (limitAllEventsByVenueHours &&
                          !occurrence.mustUpdate &&
                          (eventDatesSelectedModal[occurrence.id] ||
                            allOccurrencesCheck) &&
                          (venueSelected || (dateStartSelect && dateEndSelect)))
                      ? conflictBgColor
                      : "transparent"
                }
                marginBottom={["10px", "10px", "inherit"]}
              >
                {occurrence.conflicts.hasConflict && (
                  <ConflictAlert
                    includeDescription={false}
                    boxProps={{ minW: "100%" }}
                    alertProps={{
                      py: 1,
                      px: 3,
                      borderRadius: 0,
                      bgColor: !occurrence.active ? inactiveTitle : undefined,
                    }}
                    alertTitleProps={{
                      fontWeight: "normal",
                      color: !occurrence.active ? inactiveWarning : undefined,
                    }}
                    iconProps={{
                      color: !occurrence.active ? inactiveWarning : undefined,
                    }}
                    title="Please choose a different date/time or venue/spaces"
                    showConflictsLink
                    onClickShowConflicts={() => handleShowConflicts(occurrence)}
                  />
                )}
                <Flex
                  alignItems="center"
                  px={4}
                  py={2}
                  marginLeft={!occurrence.active && !isMobile ? 14 : undefined}
                  flexWrap={["wrap", "wrap", "nowrap"]}
                >
                  <Box
                    display={["flex", "flex", "none"]}
                    flexDir="row"
                    w="100%"
                    marginBottom={["10px", "10px", "inherit"]}
                  >
                    <Checkbox
                      w="max-content"
                      id={occurrence.id}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleOnChangeCheckbox(e)
                      }
                      isChecked={!!eventDatesSelectedModal[occurrence.id]}
                    >
                      <Text fontWeight="bold" as="span">
                        Event {++index}
                      </Text>
                    </Checkbox>
                  </Box>

                  {occurrence.active && (
                    <CheckboxGroup>
                      <Checkbox
                        id={occurrence.id}
                        w="10%"
                        borderColor={
                          occurrence.conflicts.hasConflict
                            ? conflictCheckBoxColor
                            : ""
                        }
                        display={["none", "none", "block"]}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeCheckbox(e)
                        }
                        isChecked={
                          allOccurrencesCheck ||
                          !!eventDatesSelectedModal[occurrence.id]
                        }
                        isDisabled={allOccurrencesCheck}
                      />
                    </CheckboxGroup>
                  )}

                  <ChangeDateTime
                    isSingle
                    occurrence={
                      eventDatesEditedModal[occurrence.id] || occurrence
                    }
                    cleanIsCheckandCheckAll={cleanIsCheckandCheckAll}
                    updateSingleOccurrenceDates={updateSingleOccurrenceDates}
                    limitByVenue={limitAllEventsByVenueHours}
                    originalVenue={eventDatesEditedModal[occurrence.id]?.venue}
                    isInactive={!occurrence.active}
                  />
                  <ChooseVenue
                    isSingle
                    venue={
                      eventDatesEditedModal[occurrence.id]?.venue ||
                      occurrence.venue
                    }
                    spaces={
                      eventDatesEditedModal[occurrence.id]?.spaces ||
                      occurrence.spaces
                    }
                    isSelectedAll={false}
                    occurrenceId={occurrence.id}
                    venuesOptions={allVenues}
                    selectedOccurrences={Object.keys(eventDatesSelectedModal)}
                    start={
                      eventDatesEditedModal[occurrence.id]?.start ||
                      occurrence.start
                    }
                    end={
                      eventDatesEditedModal[occurrence.id]?.end ||
                      occurrence.end
                    }
                    conflictProp={occurrence.conflicts}
                    occurrences={localOccurrences}
                    updateVenuesAndSpaces={updateVenuesAndSpaces}
                    cleanIsCheckandCheckAll={cleanIsCheckandCheckAll}
                    setSelectedVenueHours={() =>
                      getVenueHours(
                        eventDatesEditedModal[occurrence.id]?.start
                          ? new Date(
                              eventDatesEditedModal[occurrence.id]?.start
                            )
                          : new Date(occurrence.start),
                        eventDatesEditedModal[occurrence.id]?.venue
                          ? eventDatesEditedModal[occurrence.id]?.venue
                          : occurrence.venue
                      )
                    }
                    limitByVenue={limitAllEventsByVenueHours}
                    isInactive={!occurrence.active}
                  />

                  {occurrence.active ? (
                    <Button
                      w="110px"
                      size="sm"
                      colorScheme="red"
                      variant="outline"
                      _hover={{ bgColor: "none" }}
                      onClick={() => handleOnCancelEvent(occurrence.id)}
                    >
                      <Text>Cancel Event</Text>
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      w="110px"
                      colorScheme="blue"
                      variant="outline"
                      onClick={() => handleOnReactivateEvent(occurrence.id)}
                    >
                      Reactivate
                    </Button>
                  )}
                </Flex>
                <Divider
                  borderColor={borderColor}
                  display={["none", "none", "block"]}
                />
              </Box>
            );
          }
        )}
        <Box px={4} py={2} w="100%">
          <Pager
            showDetails
            pageSize={pageSize}
            total={eventDatesTotalPages}
            onPageChange={handleOnChangePage}
            currentPage={eventDatesCurrentPage}
          />
        </Box>
      </Flex>

      <Box w="100%" display="flex" justifyContent="space-between" py={4}>
        <Button
          size="sm"
          variant="outline"
          onClick={onCancel}
          borderColor="blue.500"
        >
          Cancel
        </Button>
        <Button
          colorScheme="blue"
          size="sm"
          onClick={handleOnSaveChanges}
          isDisabled={
            allOccurrencesCheck
              ? false
              : Object.keys(eventDatesEditedModal).length === 0
          }
        >
          {allOccurrencesCheck
            ? `Save ${eventDatesTotalPages} Occurrences`
            : `Save ${Object.keys(eventDatesEditedModal).length} Occurrences`}
        </Button>
      </Box>

      {event &&
        isOpen &&
        (selectedOccurrence ||
          Object.keys(eventDatesSelectedModal).length > 0 ||
          modalType === "save") && (
          <OccurrenceModal
            isOpen={isOpen}
            modalType={modalType}
            onClose={handleOnCloseModal}
            eventId={event.id}
            occurrencesIds={
              selectedOccurrence
                ? [selectedOccurrence]
                : Object.keys(eventDatesSelectedModal)
            }
            occurrences={localOccurrences}
            setLocalOccurrences={setLocalOccurrences}
            handleOnSaveOccurrences={handleOnSaveOccurrences}
            venue={venueSelected || undefined}
          />
        )}
    </Box>
  );
};
