import {
  ApiReportingCategory,
  ApiRequest,
  ApiRequestStatus,
  ApiWorkflow,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { findCategoryChildrenFunction } from "../../utils/categoryUtils";
import { findLocationChildrenFunction } from "../../utils/locationUtils";
import { RequestTransition } from "./request-form.slice";
/** Helpers */
export const isLocked = (
  request: ApiRequest,
  transition: RequestTransition | null
) => {
  if (request.status === ApiRequestStatus.new) {
    return false;
  }

  return (
    transition === null ||
    request.status === ApiRequestStatus.closed ||
    request.status === ApiRequestStatus.canceled
  );
};

export const generateWorkflowStatusMap = (workflow: ApiWorkflow) => {
  const result = [ApiRequestStatus.new];

  if (workflow.requireApproverForNewRequests) {
    result.push(ApiRequestStatus.approved);
  }

  result.push(ApiRequestStatus.queued);
  result.push(ApiRequestStatus.started);

  if (workflow.requireReviewerBeforeClose) {
    result.push(ApiRequestStatus.review);
  }

  result.push(ApiRequestStatus.closed);

  return result;
};

// TODO: support child locations
export const canHandleStatus = (
  workflow: ApiWorkflow,
  status: ApiRequestStatus,
  policy: WorkflowPolicy,
  request: ApiRequest,
  descendantsMap: { [key: string]: string[] },
  categoriesMap: { [key: string]: ApiReportingCategory }
) => {
  // Contractor special logic
  if (policy.contractor !== false) {
    const canHandleLocation =
      policy.contractor === true || policy.contractor.locations.length === 0
        ? true
        : request.location &&
          findLocationChildrenFunction(
            descendantsMap,
            policy.contractor.locations,
            new Set<string>()
          ).has(request.location.id);

    const canHandleCategory =
      policy.contractor === true || policy.contractor.categories.length === 0
        ? true
        : request.reportingCategory &&
          findCategoryChildrenFunction(
            categoriesMap,
            policy.contractor.categories,
            new Set<string>()
          ).has(request.reportingCategory.id);

    const closingStatus = workflow.requireReviewerBeforeClose
      ? ApiRequestStatus.review
      : ApiRequestStatus.closed;

    const allowedStatuses = [
      ApiRequestStatus.queued,
      ApiRequestStatus.started,
      closingStatus,
    ];

    return (
      allowedStatuses.includes(status) && canHandleLocation && canHandleCategory
    );
  }

  if (
    status === ApiRequestStatus.new &&
    (policy.requester === false ||
      (typeof policy.requester !== "boolean" &&
        request.location &&
        !findLocationChildrenFunction(
          descendantsMap,
          policy.requester.locations,
          new Set<string>()
        ).has(request.location.id) &&
        request.reportingCategory &&
        !findCategoryChildrenFunction(
          categoriesMap,
          policy.requester.categories,
          new Set<string>()
        ).has(request.reportingCategory.id)))
  ) {
    return false;
  }

  if (status === ApiRequestStatus.approved && policy.approver === false) {
    return false;
  }
  if (
    [
      ApiRequestStatus.queued,
      ApiRequestStatus.started,
      ApiRequestStatus.review,
    ].includes(status) &&
    (policy.technician === false ||
      (typeof policy.technician !== "boolean" &&
        request.location &&
        !findLocationChildrenFunction(
          descendantsMap,
          policy.technician.locations,
          new Set<string>()
        ).has(request.location.id) &&
        request.reportingCategory &&
        !findCategoryChildrenFunction(
          categoriesMap,
          policy.technician.categories,
          new Set<string>()
        ).has(request.reportingCategory.id)))
  ) {
    return false;
  }

  if (
    status === ApiRequestStatus.closed &&
    workflow.requireReviewerBeforeClose &&
    (policy.reviewer === false ||
      (typeof policy.reviewer !== "boolean" &&
        request.location &&
        !findLocationChildrenFunction(
          descendantsMap,
          policy.reviewer.locations,
          new Set<string>()
        ).has(request.location.id) &&
        request.reportingCategory &&
        !findCategoryChildrenFunction(
          categoriesMap,
          policy.reviewer.categories,
          new Set<string>()
        ).has(request.reportingCategory.id)))
  ) {
    return false;
  }

  if (
    status === ApiRequestStatus.closed &&
    !workflow.requireReviewerBeforeClose &&
    (policy.technician === false ||
      (typeof policy.technician !== "boolean" &&
        request.location &&
        !findLocationChildrenFunction(
          descendantsMap,
          policy.technician.locations,
          new Set<string>()
        ).has(request.location.id) &&
        request.reportingCategory &&
        !findCategoryChildrenFunction(
          categoriesMap,
          policy.technician.categories,
          new Set<string>()
        ).has(request.reportingCategory.id)))
  ) {
    return false;
  }

  return true;
};

export const generateTransition = (
  workflow: ApiWorkflow,
  policy: WorkflowPolicy,
  request: ApiRequest,
  isProductAdmin: boolean,
  descendantsMap: { [key: string]: string[] },
  categoriesMap: { [key: string]: ApiReportingCategory }
): RequestTransition | null => {
  if (!policy || !request || !workflow) {
    return null;
  }

  if (
    request.status === ApiRequestStatus.closed ||
    request.status === ApiRequestStatus.canceled
  ) {
    return null;
  }

  //Generates an order list of status changes for the primary path
  // ignores canceled and held
  let map = generateWorkflowStatusMap(workflow);
  if (request.status === ApiRequestStatus.hold) {
    map = map.filter(
      (s) =>
        ![
          ApiRequestStatus.new,
          ApiRequestStatus.approved,
          ApiRequestStatus.queued,
        ].includes(s)
    );
  }
  const indexOf = map.indexOf(request.status);
  const backwards = indexOf === 0 ? [] : map.splice(0, indexOf).reverse();
  // Hold and canceled are not in the array
  const next = indexOf === -1 ? map[0] : map[1];
  const forwards = indexOf === -1 ? map.splice(1) : map.splice(2) || [];

  if (policy.admin || isProductAdmin) {
    // no need to run the full cancel check
    return { next, backwards, forwards, canCancel: true };
  }

  if (
    !canHandleStatus(
      workflow,
      next,
      policy,
      request,
      descendantsMap,
      categoriesMap
    )
  ) {
    return null;
  }

  return {
    next,
    backwards: backwards.filter((s) =>
      canHandleStatus(
        workflow,
        s,
        policy,
        request,
        descendantsMap,
        categoriesMap
      )
    ),
    forwards: forwards.filter((s) =>
      canHandleStatus(
        workflow,
        s,
        policy,
        request,
        descendantsMap,
        categoriesMap
      )
    ),
    canCancel: canCancel(workflow, policy, request, isProductAdmin),
  };
};

export const canCancel = (
  workflow: ApiWorkflow,
  policy: WorkflowPolicy,
  request: ApiRequest,
  isProductAdmin: boolean
) => {
  if (!request || !policy || !workflow) {
    return false;
  }

  if (
    request.status === ApiRequestStatus.closed ||
    request.status === ApiRequestStatus.canceled
  ) {
    return false;
  }

  if (policy.admin || isProductAdmin) {
    return true;
  }

  return !!(policy.approver || policy.technician || policy.reviewer);
};

export const canReopen = (
  workflow: ApiWorkflow,
  policy: WorkflowPolicy,
  request: ApiRequest,
  isProductAdmin: boolean
) => {
  if (!request || !policy || !workflow) {
    return false;
  }

  if (
    request.status !== ApiRequestStatus.closed &&
    request.status !== ApiRequestStatus.canceled
  ) {
    return false;
  }

  if (policy.admin || isProductAdmin) {
    return true;
  }

  if (
    workflow.requireReviewerBeforeClose === true &&
    policy.reviewer !== false
  ) {
    return true;
  }

  if (
    workflow.requireReviewerBeforeClose === false &&
    policy.technician !== false
  ) {
    return true;
  }

  return false;
};
