import {
  ApiAccount,
  ApiClient,
  ApiLaborTransaction,
  ApiPurchaseTransaction,
  ApiTransactionType,
  UpdateApiTransaction,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface UpdateTransactionsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  requestKey: string;
  transaction: UpdateApiTransaction;
  idTransaction: string;
  transactionType: ApiTransactionType;
}

export const updateTransaction = createAsyncThunk(
  "request/updateTransaction",
  async (params: UpdateTransactionsThunkParams, thunkAPI) => {
    const {
      apiClient,
      account,
      requestKey,
      transaction,
      idTransaction,
      transactionType,
    } = params;
    switch (transactionType) {
      case ApiTransactionType.labor: {
        const laborTransaction = transaction as ApiLaborTransaction;
        const result = apiClient.updateRequestTransaction(
          account.id,
          requestKey,
          idTransaction,
          {
            laborer: laborTransaction.laborer.id,
            datePerformed: laborTransaction.datePerformed,
            hours: laborTransaction.hours,
            laborType: laborTransaction.laborType,
            budget: laborTransaction.budget,
          }
        );
        return result;
      }
      case ApiTransactionType.purchase: {
        const purchaseTransaction = transaction as ApiPurchaseTransaction;
        const result = apiClient.updateRequestTransaction(
          account.id,
          requestKey,
          idTransaction,
          {
            ...purchaseTransaction,
          }
        );
        return result;
      }
      default:
        return Promise.resolve();
    }
  }
);

export const updateTransactionHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(updateTransaction.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }
    const type = action.payload.type;
    const id = action.payload.id;

    if (type === ApiTransactionType.labor) {
      const registeredIndex = state.transactions.labors.laborsData.findIndex(
        (item) => item.id === id
      );
      if (registeredIndex !== -1) {
        state.transactions.labors.laborsData[registeredIndex] = action.payload;
      }
    } else if (type === ApiTransactionType.purchase) {
      const registeredIndex =
        state.transactions.purchases.purchasesData.findIndex(
          (item) => item.id === id
        );
      if (registeredIndex !== -1) {
        state.transactions.purchases.purchasesData[registeredIndex] =
          action.payload;
      }
    }
  });
};
