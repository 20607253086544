import { Box, Text } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { priorityColorMap } from "../../../components/badges/PriorityBadge";

interface RequestModalHeaderProps {
  request: ApiRequest;
}

export const RequestModalHeader: FC<RequestModalHeaderProps> = ({
  request,
}) => {
  return request ? (
    <Box
      p={2}
      display="flex"
      w="100%"
      color="blue.600"
      borderRadius={6}
      alignItems="center"
      justifyContent="space-between"
      bgColor={priorityColorMap[request.priority]}
    >
      <Text color="white" w="max-content" fontWeight="500">
        {request.key}
      </Text>
      <Box bgColor="white" w="max-content" maxH="20px">
        <Text
          px={2}
          fontSize="sm"
          fontWeight="bold"
          textAlign="center"
          color={priorityColorMap[request.priority]}
        >
          {request.priority.toUpperCase()}
        </Text>
      </Box>
    </Box>
  ) : null;
};
