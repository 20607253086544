import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { useCallback, useState } from "react";
import { formatCurrency } from "../../../utils/formatCurrency";

export interface CurrencyInputControlProps {
  label?: string;
  name: string;
  value: number;
  isDisabled?: boolean;
  inputProps?: InputProps;
}

export const CurrencyInputControl = ({
  name,
  value: inputValue,
  label,
  isDisabled,
  inputProps,
}: CurrencyInputControlProps) => {
  const format = (val: any) => `$ ` + val;

  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({
    name,
    value: inputValue === null ? "0.0" : format(inputValue),
  });

  const [localValue, setLocalValue] = useState<{
    valueAsString?: string;
    valueAsNumber?: number;
  }>({
    valueAsNumber: inputValue,
    valueAsString: `$ ${formatCurrency(inputValue).substring(1)}`,
  });

  const handleOnBlur = useCallback(() => {
    setLocalValue({
      ...localValue,
      valueAsString: `$ ${formatCurrency(localValue.valueAsNumber).substring(1)}`,
    });
  }, [localValue]);

  const handleOnChange = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      if (
        valueAsNumber === localValue.valueAsNumber &&
        valueAsString.slice(-1) !== "."
      ) {
        return;
      }

      setLocalValue({
        valueAsNumber,
        valueAsString: `$ ${valueAsString}`,
      });
      helper.setTouched(true);
      helper.setValue(valueAsNumber || null);
    },
    [helper, localValue.valueAsNumber]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <NumberInput
        {...field}
        w="100%"
        min={0}
        onChange={handleOnChange}
        alignItems="left"
        inputMode="numeric"
        value={localValue.valueAsString}
        onBlur={() => {
          handleOnBlur();
        }}
      >
        <NumberInputField textAlign="left" pr="3" />
      </NumberInput>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
