import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

export const BulkEditFormSkeleton = () => {
  return (
    <>
      <ModalHeader>
        <Text>Bulk Edits</Text>
      </ModalHeader>
      <ModalBody>
        <Stack>
          <Skeleton height="80px" />
          <Skeleton height="80px" />
          <Skeleton height="80px" />
          <Skeleton height="80px" />
          <Skeleton height="80px" />
        </Stack>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </>
  );
};
