import {
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsExclamationCircle } from "react-icons/bs";

export interface AutoSavingFormControlProps extends InputProps {
  value: string;
  id?: string;
  onSave: (value: string, elementId?: string) => Promise<void>;
}

export const AutoSavingInput = (props: AutoSavingFormControlProps) => {
  const {
    value,
    onSave,
    isDisabled,
    isRequired = false,
    ...inputProps
  } = props;
  const [internalValue, setInternalValue] = useState(value || "");
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const trimmed = e.target.value.trim();
    // no change from original
    if (trimmed === value) {
      return;
    }

    setSaving(true);
    setSaveError(false);

    if (isRequired && trimmed === "") {
      setInternalValue(value);
    } else {
      setInternalValue(trimmed);
    }

    onSave(trimmed, props.id)
      .then(() => {
        setSaveError(false);
      })
      .catch(() => {
        setSaveError(true);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <InputGroup>
      <Input
        {...inputProps}
        value={internalValue}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={isDisabled || saving}
      />
      {saving && <InputRightElement children={<Spinner color="gray.300" />} />}

      {saveError && (
        <InputRightElement
          children={<Icon as={BsExclamationCircle} color="tomato" />}
        />
      )}
    </InputGroup>
  );
};
