import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { RequestSettingsKeys } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useShowToast } from "../../hooks/showToast";
import { RootState } from "../../store";
import {
  getUserOrAccountSettingDefault,
  SettingProps,
} from "../../utils/emailSettingUtils";
import { phoneNumberFormatting } from "../../utils/phoneNumberFormatting";
import { useAuthentication } from "../auth/AuthProvider";
import { CountryPhoneCodeParams } from "../form-helpers/CountryPhoneCodeSelectControl";
import { useProductSubscriptions } from "../shell/AppShell";
import { DeletePhoneNumberModal } from "./DeletePhoneNumberModal";
import { PhoneVerificationModal } from "./PhoneVerificationModal";

export type PhoneParams = {
  phone: string;
  countryCode: CountryPhoneCodeParams;
};

export type VerificationParams = {
  code: string | null;
};

export const SMSNotificationSettings = ({
  update,
  accountSettings,
  userSettings,
  label,
}: SettingProps) => {
  const { hasRequests } = useProductSubscriptions();
  const showToast = useShowToast();
  const { currentUser: user, currentAccount } = useAuthentication();
  const [allowSendSmsToAssignee, setAllowSendSmsToAssignee] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const { verificationStatus } = useSelector(
    (root: RootState) => root.phoneVerificationSlice,
  );

  const handleSettingSave = useCallback(
    async (key: string, value: boolean) => {
      try {
        const saved = await update(currentAccount.id, { [key]: value });
        showToast("success", `Update notification setting`);
        if (key === RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS) {
          setAllowSendSmsToAssignee(
            saved[RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS] as boolean,
          );
        }
      } catch (error) {
        showToast("error", "Error updating notification setting");
      }
    },
    [currentAccount, showToast, update],
  );

  const onPhoneVerificationCompleted = useCallback(async () => {
    if (verificationStatus !== undefined) {
      await handleSettingSave(
        RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS,
        verificationStatus,
      );
    }
  }, [verificationStatus, handleSettingSave]);

  const onDeletePhoneConfirmed = useCallback(async () => {
    await handleSettingSave(
      RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS,
      false,
    );
  }, [handleSettingSave]);

  const onSettingSwitch = useCallback(() => {
    if (allowSendSmsToAssignee) {
      handleSettingSave(
        RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS,
        false,
      );
      return;
    }

    if (user.phoneVerified && !allowSendSmsToAssignee) {
      handleSettingSave(
        RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS,
        true,
      );
    } else {
      onOpen();
    }
  }, [onOpen, handleSettingSave, allowSendSmsToAssignee, user]);

  useEffect(() => {
    setAllowSendSmsToAssignee(
      getUserOrAccountSettingDefault(
        accountSettings,
        RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_SMS,
        userSettings,
        false,
      ),
    );
  }, [user, currentAccount, accountSettings, userSettings]);

  return (
    <>
      {hasRequests && (
        <>
          <Text w="100%" textAlign="left" size="lg" fontWeight="bold">
            {label ? label : "SMS Settings"}
          </Text>

          <Box mt={7} width="100%">
            <FormControl
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap="2"
              flexDir="row"
            >
              <HStack w="full" alignItems="start">
                <Switch
                  id="allow-request-assigned-to-sms"
                  onChange={onSettingSwitch}
                  isChecked={allowSendSmsToAssignee}
                  pt="1"
                />
                <VStack w="full" justify="stretch" alignItems="start">
                  <FormLabel htmlFor="email-alerts" lineHeight="unset" mb={0}>
                    Text me when a request has been assigned
                  </FormLabel>
                  {user.phoneVerified && user.phone && (
                    <HStack>
                      <Text>
                        Verified number: {phoneNumberFormatting(user.phone)}
                      </Text>
                      <Button
                        variant="link"
                        colorScheme="blue"
                        onClick={onDeleteModalOpen}
                      >
                        (Delete)
                      </Button>
                    </HStack>
                  )}
                </VStack>
              </HStack>
            </FormControl>
          </Box>
        </>
      )}

      <PhoneVerificationModal
        isOpen={isOpen}
        onClose={onClose}
        onPhoneVerified={onPhoneVerificationCompleted}
      />

      <DeletePhoneNumberModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onDeletePhone={onDeletePhoneConfirmed}
      />
    </>
  );
};
