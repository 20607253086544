import { Grid, GridItem, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";

export const RequestFormSkeleton = () => {
  return (
    <Grid templateColumns={["repeat(2, 1fr)", null, "repeat(12, 1fr)"]} gap={6}>
      <GridItem colSpan={[1, null, 3]} pb={[6, null, 3]} mb={4} pr={6}>
        <Skeleton height=".5em" maxW="25%" />
        <Skeleton height=".5em" mt={4} />
      </GridItem>
      <GridItem colSpan={[1, null, 3]} pb={[6, null, 3]} mb={4} pr={6}>
        <Skeleton height=".5em" maxW="25%" />
        <Skeleton height=".5em" mt={4} />
      </GridItem>
      <GridItem colSpan={[1, null, 3]} pb={[6, null, 3]} pr={6}>
        <Skeleton height=".5em" maxW="25%" />
        <Skeleton height=".5em" mt={4} />
      </GridItem>
      <GridItem colSpan={[1, null, 3]} pb={[6, null, 3]} pr={6}>
        <Skeleton height=".5em" maxW="25%" />
        <Skeleton height=".5em" mt={4} />
      </GridItem>
      <GridItem colSpan={[2, null, 12]} pb={[6, null, 3]} mb={4}>
        <Skeleton height=".5em" maxW={["12.5%", null, "6.25%"]} mb={4} />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </GridItem>
      <GridItem colSpan={[2, null, 12]} pb={[6, null, 3]} pr={6} mb={4}>
        <Skeleton height=".5em" maxW={["12.5%", null, "6.25%"]} />
        <Skeleton height=".5em" maxW={["75%", null, "50%"]} mt={4} />
      </GridItem>
    </Grid>
  );
};
