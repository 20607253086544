import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  StackItem,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiBillingGroupType,
  ApiEventEquipment,
  ApiRate,
  ApiSpace,
  ApiSpaceEquipment,
  ApiSpaceSummary,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { differenceInMinutes } from "date-fns";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import {
  RateProps,
  useBillingGroupRates,
} from "../../../../hooks/useBillingGroupRates";
import { RootState, useThunkDispatch } from "../../../../store";
import { updateEvent } from "../../../../store/events/event-details.slice";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { toastStatus } from "../EventEditForm";
import { ItemPrice } from "../form-components/ItemPrice";

interface EventEquipmentsProps {
  onCancel: () => void;
  setEquipmentCost: Dispatch<SetStateAction<number>>;
  showToast: (status: toastStatus, title: string) => void;
  equipmentToShow: ApiEventEquipment[] | undefined;
  occurrences: CreateApiEventOccurrence[];
}

export interface EventEquipmentData extends Omit<ApiSpaceEquipment, "spaceId"> {
  quantity: number;
  isSelected: boolean;
  spaceId: string | null;
  additionalNotes: string | null;
  spaceName?: string;
  error?: string;
}

export const EditEquipment: FC<EventEquipmentsProps> = ({
  onCancel,
  showToast,
  setEquipmentCost,
  equipmentToShow,
  occurrences,
}) => {
  const [equipments, setEquipments] = useState<EventEquipmentData[]>([]);
  const [firstRender, setFirstRender] = useState(false);
  const { apiClient, currentAccount } = useAuthentication();

  const thunkDispatch = useThunkDispatch();
  const { event, eventDates, workingVenue } = useSelector(
    (state: RootState) => state.eventDetails
  );

  const rateGroupId = event?.eventGroup.rateGroupId || undefined;
  const { rates, getItemRates } = useBillingGroupRates({
    rateGroupId: rateGroupId,
    type: ApiBillingGroupType.equipment,
  });

  useEffect(() => {
    if (!event || !workingVenue) return;

    const venueOptions: EventEquipmentData[] =
      workingVenue.rentableEquipment.map((vre) => {
        const eventEquipment = event.rentableEquipment.find(
          (ere) => ere.id === vre.equipment.id && ere.spaceId === null
        );
        const item: EventEquipmentData = {
          ...vre,
          spaceId: null,
          additionalNotes: null,
          isSelected: eventEquipment ? true : false,
          quantity: eventEquipment?.quantity || vre.minRentable,
        };

        return item;
      });

    const spaceMap = workingVenue.spaces.reduce(
      (map, space) => {
        map[space.id] = space;
        return map;
      },
      {} as Record<string, ApiSpace>
    );

    const spaceOptions: EventEquipmentData[] = [];
    const allSpacesEquipment = event.spaces.reduce((array, space) => {
      const spaceDetail = spaceMap[space.id];
      if (!spaceDetail) return array;
      array.push(...spaceDetail.equipment);
      return array;
    }, [] as ApiSpaceEquipment[]);

    allSpacesEquipment.forEach((se) => {
      const eventEquipment = event.rentableEquipment.find(
        (ere) => ere.id === se.equipment.id && ere.spaceId === se.spaceId
      );
      const isAddedInVenues = venueOptions.some(
        (vo) => vo.equipment.id === se.equipment.id
      );

      const item: EventEquipmentData = {
        ...se,
        additionalNotes: null,
        isSelected: eventEquipment ? true : false,
        quantity: eventEquipment?.quantity || se.minRentable,
      };
      !isAddedInVenues && spaceOptions.push(item);
    });

    const allEquipment = [...venueOptions, ...spaceOptions];
    setEquipments(allEquipment);
  }, [apiClient, currentAccount.id, equipmentToShow, event, workingVenue]);

  const handleOnSelectEquipment = useCallback(
    (equip: EventEquipmentData) => {
      const spaceId = equip.spaceId ? equip.spaceId : null;

      const index = equipments.findIndex(
        (eq) => eq.equipment.id === equip.equipment.id && eq.spaceId === spaceId
      );

      if (index !== -1) {
        const equipCopy = [...equipments];
        equipCopy[index].isSelected = !equipCopy[index].isSelected;
        setEquipments(equipCopy);
      }
    },
    [equipments]
  );

  const checkQuantityError = useCallback(
    (equip: EventEquipmentData, quantity: number) => {
      if (quantity < equip.minRentable) {
        return `Minimum rentable is ${equip.minRentable}`;
      }

      if (quantity > equip.maxRentable) {
        return `Minimum rentable is ${equip.maxRentable}`;
      }

      return;
    },
    []
  );

  const handleOnChangeQuantity = useCallback(
    (equip: EventEquipmentData, value: number) => {
      const spaceId = equip.spaceId ? equip.spaceId : null;

      const index = equipments.findIndex(
        (eq) => eq.equipment.id === equip.equipment.id && eq.spaceId === spaceId
      );

      if (index !== -1) {
        const hasError = checkQuantityError(equip, value);
        const equipCopy = [...equipments];
        equipCopy[index].quantity = value;
        equipCopy[index].error = hasError;

        setEquipments(equipCopy);
      }
    },
    [checkQuantityError, equipments]
  );

  const selectedEquipments = useMemo(
    () => equipments.filter((equip) => equip.isSelected),
    [equipments]
  );

  const eventHoursDuration = useMemo(() => {
    const firstOccurrence = eventDates[0];
    if (!firstOccurrence) return 1;
    const { start, end } = firstOccurrence;
    if (!start || !end) return 1;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const occurrenceMinutes = differenceInMinutes(endDate, startDate);
    const hours = parseFloat((occurrenceMinutes / 60).toFixed(2));
    return hours;
  }, [eventDates]);

  const setEquipmentTotalCost = useCallback(() => {
    if (!rates) return;
    const total = equipments.reduce((acc, current) => {
      const rate = getItemRates(
        current.equipment.id,
        current.isSelected,
        occurrences,
        eventHoursDuration,
        current.quantity
      );
      const equipmentCost = rate
        ? rate.reduce((sum, r) => {
            sum = sum + r.total;
            return sum;
          }, 0)
        : 0;
      return acc + equipmentCost;
    }, 0);
    setEquipmentCost(total);
  }, [
    equipments,
    setEquipmentCost,
    getItemRates,
    eventHoursDuration,
    rates,
    occurrences,
  ]);

  const handleOnSave = useCallback(() => {
    if (event === null || !event.id) return;

    const equipmentToSave = selectedEquipments.map((selected) => {
      const spaceId = selected.spaceId || undefined;
      return {
        spaceId,
        quantity: selected.quantity,
        rentableEquipment: selected.equipment.id,
      };
    });

    const eventToUpdate = { rentableEquipment: equipmentToSave };

    thunkDispatch(
      updateEvent({
        apiClient,
        eventId: event.id,
        event: eventToUpdate,
        accountId: currentAccount.id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        showToast("success", "Event was updated successfully");
        setEquipmentTotalCost();
        onCancel();
      })
      .catch(() => {
        showToast("error", "Something went wrong, please try again");
      });
  }, [
    apiClient,
    currentAccount.id,
    event,
    onCancel,
    selectedEquipments,
    setEquipmentTotalCost,
    showToast,
    thunkDispatch,
  ]);

  const hasChanges = useMemo(() => {
    if (!event) return false;

    const reduxEventEquipment = event.rentableEquipment
      .map((ere) => `${ere.quantity}`)
      .sort()
      .join(",");

    const selectedItems = selectedEquipments
      .map((se) => `${se.quantity}`)
      .sort()
      .join(",");

    return reduxEventEquipment !== selectedItems;
  }, [event, selectedEquipments]);

  const hasErrors = useMemo(() => {
    if (!selectedEquipments.length) {
      return true;
    }

    return selectedEquipments.some((equip) => equip.error);
  }, [selectedEquipments]);

  useEffect(() => {
    if (!firstRender && rates && equipments.length > 0 && rates && rates.size) {
      setEquipmentTotalCost();
      setFirstRender(true);
    }
  }, [firstRender, rates, equipments, setEquipmentTotalCost]);

  const { venueEquipment, spaceEquipment } = useMemo(() => {
    const venueEquipment: EventEquipmentData[] = [];
    const spaceEquipment: EventEquipmentData[] = [];
    if (!event) return { venueEquipment, spaceEquipment };

    const spacesHash = event.spaces.reduce(
      (map, space) => {
        map[space.id] = space;
        return map;
      },
      {} as Record<string, ApiSpaceSummary>
    );

    equipments.forEach((item) => {
      item.spaceId === null
        ? venueEquipment.push(item)
        : spaceEquipment.push({
            ...item,
            spaceName: spacesHash[item.spaceId]?.location.name || "",
          });
    });

    return { venueEquipment, spaceEquipment };
  }, [equipments, event]);

  return (
    <>
      {event ? (
        <Stack gap={2} pl={4}>
          {venueEquipment.length > 0 &&
            venueEquipment.map((equip, index) => {
              const rate = getItemRates(
                equip.equipment.id,
                equip.isSelected,
                occurrences,
                eventHoursDuration,
                equip.quantity
              );
              return (
                <EventEquipmentItem
                  rate={rate}
                  equip={equip}
                  key={equip.equipment.id}
                  equipmentRate={
                    rates ? rates.get(equip.equipment.id) : undefined
                  }
                  handleOnChangeQuantity={handleOnChangeQuantity}
                  handleOnSelectEquipment={handleOnSelectEquipment}
                />
              );
            })}

          {spaceEquipment.length > 0 && (
            <Flex w="full" flexDir="column" gap={4}>
              <Heading fontSize="lg" fontWeight="semibold">
                Spaces Equipment
              </Heading>
              {spaceEquipment.map((equip, index) => {
                const rate = getItemRates(
                  equip.equipment.id,
                  equip.isSelected,
                  occurrences,
                  eventHoursDuration,
                  equip.quantity
                );
                return (
                  <EventEquipmentItem
                    rate={rate}
                    equip={equip}
                    equipmentRate={
                      rates ? rates.get(equip.equipment.id) : undefined
                    }
                    key={`${equip.equipment.id}:${equip.spaceId}`}
                    handleOnChangeQuantity={handleOnChangeQuantity}
                    handleOnSelectEquipment={handleOnSelectEquipment}
                  />
                );
              })}
            </Flex>
          )}

          <StackItem w="100%" display="flex" justifyContent="space-between">
            <Button
              size="sm"
              variant="outline"
              borderColor="blue.500"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="blue"
              onClick={handleOnSave}
              disabled={hasErrors || !hasChanges}
            >
              Save Changes
            </Button>
          </StackItem>
        </Stack>
      ) : (
        <Spinner />
      )}
    </>
  );
};

interface EventEquipmentItemProps {
  rate?: RateProps[];
  equipmentRate?: ApiRate[];
  equip: EventEquipmentData;
  handleOnSelectEquipment: (equip: EventEquipmentData) => void;
  handleOnChangeQuantity: (equip: EventEquipmentData, value: number) => void;
}

const EventEquipmentItem: FC<EventEquipmentItemProps> = ({
  rate,
  equip,
  equipmentRate,
  handleOnSelectEquipment,
  handleOnChangeQuantity,
}) => {
  const tooltipColor = useColorModeValue("gray.500", "white");
  const errorColor = useColorModeValue("red.500", "red.300");

  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  return (
    <Flex flexDir="column">
      <Flex
        w="full"
        justifyContent="space-between"
        flexDir={["column", "column", "row"]}
      >
        <Checkbox
          fontSize="md"
          fontWeight="bold"
          isChecked={equip.isSelected}
          onChange={() => handleOnSelectEquipment(equip)}
        >
          <Flex gap={1} alignItems="center">
            {equip.equipment.name}
            {equipmentRate && enableInvoicesForEvents && (
              <ItemPrice ratesByItem={equipmentRate} />
            )}
            {equip.spaceName && (
              <Tooltip label={equip.spaceName} fontSize="md">
                <Box color={tooltipColor}>
                  <IoMdInformationCircle />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Checkbox>

        <Flex gap={4} alignItems="center">
          <Text as="span" fontSize="15px" w={["max-content"]}>
            How many do you need?
          </Text>
          <Flex
            flex={1}
            gap={[2, 2, 4]}
            alignItems="center"
            justifyContent="flex-end"
          >
            <NumberInput
              size="md"
              maxW="80px"
              value={equip.isSelected ? equip.quantity : undefined}
              onChange={(_, value) => handleOnChangeQuantity(equip, value)}
              isDisabled={!equip.isSelected}
            >
              <NumberInputField borderRadius={6} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {enableInvoicesForEvents && rate && (
              <Text as="span" w="70px" textAlign="right">
                {formatCurrency(
                  rate.reduce((sum, r) => {
                    sum += r.total;
                    return sum;
                  }, 0)
                )}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      {equip.error && (
        <Text mt={1} color={errorColor} fontSize="sm" textAlign="right">
          {equip.error}
        </Text>
      )}
    </Flex>
  );
};
