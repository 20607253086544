import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { BiListPlus } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import ImageViewerModal from "../../../components/images/ImageViewerModal";
import { RootState } from "../../../store";
import {
  cleanOrderItemsCart,
  cleanOrderSupplierItemsCart,
  InventoryOrderItem,
  removeOrderItemFromCart,
  setIsOpenInventoryOrderForm,
} from "../../../store/inventory/inventory-order-item-list.slice";

export const ItemsCart: FC = () => {
  const textColor = useColorModeValue("gray.400", "white");
  const mainGgColor = useColorModeValue("blue.50", "gray.600");
  const itemsBgColor = useColorModeValue("white", "transparent");
  const dispatch = useDispatch();
  const { cartOrderItems } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );

  const handleOnClearAll = useCallback(() => {
    dispatch(cleanOrderItemsCart());
  }, [dispatch]);

  return (
    <Stack
      gap={2}
      w="100%"
      p={[2, 2, 4]}
      borderRadius={8}
      bgColor={mainGgColor}
    >
      {cartOrderItems.items.length === 0 && (
        <Flex justifyContent="space-between">
          <Heading fontSize="xl">Your Order</Heading>
          <Link
            color="blue.500"
            onClick={handleOnClearAll}
            _hover={{ textDecoration: "none" }}
          >
            Clear all
          </Link>
        </Flex>
      )}
      <Flex bgColor={itemsBgColor} gap={4} flexDir="column" p={2}>
        {cartOrderItems.items.length === 0 && (
          <Text color={textColor}>No items added to cart</Text>
        )}

        <CartItemsList />
      </Flex>
      {cartOrderItems.items.length === 0 && (
        <Button
          w="max-content"
          colorScheme="blue"
          alignSelf="center"
          onClick={() => {}}
          isDisabled={cartOrderItems.items.length === 0}
        >
          <Icon as={BiListPlus} mr={1} /> Place Order
        </Button>
      )}
    </Stack>
  );
};

export const CartItemsList: FC = () => {
  const buttonColor = useColorModeValue("gray.500", "whiteAlpha.500");
  const errorColor = useColorModeValue("red.500", "red.300");

  const dispatch = useDispatch();
  const { cartOrderItems } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );

  const handleOnClearSupplier = useCallback(
    (supplierId: string) => {
      dispatch(cleanOrderSupplierItemsCart(supplierId));
    },
    [dispatch]
  );

  const handleOnRemoveItem = useCallback(
    (itemId: string) => {
      dispatch(removeOrderItemFromCart({ id: itemId }));
    },
    [dispatch]
  );

  const handleOnOpenCreateOrderForm = useCallback(
    (supplierId: string) => {
      dispatch(setIsOpenInventoryOrderForm({ supplierId, isFormOpen: true }));
    },
    [dispatch]
  );

  const groupedItems = cartOrderItems.items.reduce(
    (acc, cartItem) => {
      const supplierId: string = cartItem.supplierSelected.id || "";
      if (!acc[supplierId]) {
        acc[supplierId] = [] as InventoryOrderItem[];
      }
      acc[supplierId].push(cartItem);
      return acc;
    },
    {} as Record<string, InventoryOrderItem[]>
  );

  return (
    <>
      {Object.keys(groupedItems).map((supplierId) => {
        const supplierItems = groupedItems[supplierId];
        const supplierName = supplierItems[0].item.supplier[0]?.supplier.name;

        return (
          <Box key={`supplier-${supplierId}`}>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Heading fontSize="xl">
                {supplierName} Order ({groupedItems[supplierId].length})
              </Heading>
              <Link
                color="blue.500"
                onClick={() => handleOnClearSupplier(supplierId)}
                _hover={{ textDecoration: "none" }}
              >
                Clear all
              </Link>
            </Flex>
            {supplierItems.map((cartItem) => (
              <React.Fragment key={`cart-item:${cartItem.item.id}`}>
                <Flex
                  gap={2}
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex w={"42%"} mt={1} alignItems="center" gap={2}>
                    <ImageViewerModal
                      image={{ src: cartItem.image || undefined, alt: "" }}
                      imageProps={{ boxSize: "40px" }}
                    />
                    <Flex flexDir="column" justifyContent="center">
                      <Text fontWeight="bold">{cartItem.item.name}</Text>
                      {cartItem.item.identifiers.externalId && (
                        <Text>#{cartItem.item.identifiers.externalId}</Text>
                      )}
                    </Flex>
                  </Flex>
                  <Flex
                    p={2}
                    gap={2}
                    w="55%"
                    flexDir="column"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Text size="sm" fontWeight="bold">
                      Quantity
                    </Text>
                    <Text>{cartItem.requested}</Text>
                  </Flex>
                  <IconButton
                    size="sm"
                    fontSize="18px"
                    color={buttonColor}
                    variant="outline"
                    aria-label="Remove item"
                    icon={<IoTrashOutline />}
                    onClick={() => handleOnRemoveItem(cartItem.item.id)}
                  />
                </Flex>
                {cartItem.requested <= 0 && (
                  <Text
                    display="flex"
                    mt={1}
                    color={errorColor}
                    fontSize="sm"
                    textAlign="right"
                  >
                    Quantity needs to be more than 0
                  </Text>
                )}
              </React.Fragment>
            ))}
            <Flex mt={2} justifyContent="center">
              <Button
                w="max-content"
                colorScheme="blue"
                onClick={() => handleOnOpenCreateOrderForm(supplierId)}
                isDisabled={
                  supplierItems.length === 0 ||
                  supplierItems.some((item) => !item.requested)
                }
              >
                <Icon as={BiListPlus} mr={1} /> Place Order
              </Button>
            </Flex>
          </Box>
        );
      })}
    </>
  );
};
