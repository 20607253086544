import * as yup from "yup";

const FORMATED_PHONE_NUMBER_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/;
export const INVALID_PHONE_ERROR = "Please add a valid phone number";
export const REQUIRED_PHONE_ERROR = "Phone is required";

export const phoneVerificationFormSchema = (
  verificationId?: string,
  verificationStatus?: boolean
) =>
  yup.object().shape({
    phone: yup
      .object()
      .test("required", REQUIRED_PHONE_ERROR, (value: any) => {
        return !!value.formated === true;
      })
      .test("valid-phone", INVALID_PHONE_ERROR, (value: any) => {
        const validated = FORMATED_PHONE_NUMBER_REGEX.test(value.formated);
        return validated === true;
      }),
    code: yup
      .number()
      .test("code-is-required", "Code is required", (value) => {
        return (
          !!verificationId === false ||
          (!!verificationId === true && !!value !== false)
        );
      })
      .test("code-is-invalid", "Invalid code", (value) => {
        return verificationStatus === true || verificationStatus === undefined;
      })
      .nullable(),
  });
