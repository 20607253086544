import { HStack, Tag, TagCloseButton, TagLabel, Text } from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../../../components/auth/AuthProvider";
import { useDateTimeFormatter } from "../../../../../../hooks/useIntlFormatter";
import { RootState, useThunkDispatch } from "../../../../../../store";
import { findProjectRequests } from "../../../../../../store/planning-hq/requests/findRequests.thunk";
import { updateFilters } from "../../../../../../store/planning-hq/requests/updateFilters.thunk";
import { RequestListFilterState } from "../../../../../../store/request-list.slice";
import { filtersInitialState } from "../../../../../../store/requests/defaults";
import { RequestDateFilter, RequestDateFilters } from "./defaults";

type FilterTagsProps = { project: ApiProject };
export const FilterTags: FC<FilterTagsProps> = ({ project }) => {
  const { apiClient } = useAuthentication();
  const { filters } = useSelector((state: RootState) => state.requestList);
  const { locale } = useSelector((state: RootState) => state.global);
  const { formatDateTime } = useDateTimeFormatter(locale);

  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: any;
  }>({});

  const {
    date,
    currentPage,
    pageSize,
    search,
    quickFilter,
    moreFilters,
    displayModeFilter,
    ...restSelectedFilters
  } = selectedFilters;

  const thunkDispatch = useThunkDispatch();

  const getSelectedFilters = useCallback((filters: RequestListFilterState) => {
    const selectedFilters = { ...filters };
    Object.entries(selectedFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        // @ts-ignore
        delete selectedFilters[key];
        return;
      }

      if (value == null) {
        // @ts-ignore
        delete selectedFilters[key];
      }
    });
    return selectedFilters;
  }, []);

  const tagFilters = useMemo(() => {
    const filterKeys = Object.keys(restSelectedFilters);
    let tags = [];
    if (filterKeys.length > 3) {
      tags.push({
        key: "multi",
        value: `${filterKeys.length} filters applied`,
      });
      return tags;
    }

    for (const key in restSelectedFilters) {
      tags.push({ key: key, value: undefined });
    }

    if (date) {
      let type = date.type;
      let field = date.field;
      let value = "";
      if (
        type === "absolute" &&
        (date.value as Array<string>)[0] &&
        (date.value as Array<string>)[1]
      ) {
        const dates = [
          formatDateTime(new Date(date.value[0])).replace(/\//g, "-"),
          formatDateTime(new Date(date.value[1])).replace(/\//g, "-"),
        ];
        value = dates.join(" to ");
      } else {
        value = date.value;
      }

      tags.push({
        key: field,
        value: value,
      });
    }

    return tags;
  }, [restSelectedFilters, date, formatDateTime]);

  const handleRemoveFilter = useCallback(
    (filterKey: string) => {
      let filtersToReset: { [key: string]: any } = {};
      const isDateFilter = RequestDateFilters.includes(
        filterKey as RequestDateFilter
      );

      if (isDateFilter) {
        filtersToReset["date"] = null;
        filtersToReset["dateRelative"] = undefined;
        filtersToReset[filterKey] = [];
      }

      const isMultiFilter = filterKey === "multi";

      if (isMultiFilter) {
        filtersToReset = { ...filtersInitialState };
      } else {
        filtersToReset[filterKey] = [];
      }

      thunkDispatch(updateFilters(filtersToReset))
        .unwrap()
        .then(() => {
          if (isDateFilter) return;

          thunkDispatch(
            findProjectRequests({ apiClient, projectId: project.id })
          );
        });
    },
    [thunkDispatch, apiClient, project]
  );

  useEffect(() => {
    const selected = getSelectedFilters(filters);
    setSelectedFilters(selected);
  }, [filters, getSelectedFilters]);

  return tagFilters.length > 0 ? (
    <HStack>
      {tagFilters.map((tag) => {
        return (
          <Tag
            size="md"
            key={tag.key}
            borderRadius="full"
            variant="solid"
            borderColor="gray.400"
            borderWidth="thin"
            bg="gray.100"
            color="gray.700"
            w="fit-content"
          >
            <TagLabel fontSize="small">
              <HStack gap={1}>
                {tag.value ? (
                  tag.key === "multi" ? (
                    <Text as="span" casing="capitalize">
                      {`${tag.value}`}
                    </Text>
                  ) : (
                    <Text as="span" casing="capitalize">
                      {`${tag.key}: `}
                      <Text as="span">{`${tag.value}`}</Text>
                    </Text>
                  )
                ) : (
                  <Text as="span" casing="capitalize">
                    {`${tag.key}`}
                  </Text>
                )}
              </HStack>
            </TagLabel>
            <TagCloseButton
              onClick={() => {
                handleRemoveFilter(tag.key);
              }}
            />
          </Tag>
        );
      })}
    </HStack>
  ) : (
    <Text fontSize="smaller">No filters applied</Text>
  );
};
