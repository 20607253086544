import { Box, Flex, HStack, Text, useColorMode } from "@chakra-ui/react";
import { FC } from "react";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { AssigneesBadge } from "../../components/AssigneeBadge";
import { TimelineItemProps } from "./types";
import { useRequestColorMap } from "./useRequestColorMap";

type LargeTimelineItemProps = TimelineItemProps;

export const LargeTimelineItem: FC<LargeTimelineItemProps> = ({ request }) => {
  const { getColor } = useRequestColorMap(request.priority);
  const { colorMode } = useColorMode();

  return (
    <Box
      borderRadius="md"
      color="black"
      position="relative"
      height={"5rem"}
      backgroundColor="gray.800"
    >
      <Flex
        w="full"
        height="full"
        gap={2}
        alignItems="center"
        backgroundColor={getColor(0)}
        borderRadius="md"
        borderWidth="thin"
        borderColor={getColor(2)}
        px={2}
      >
        <Flex
          justifyContent="center"
          justifyItems="center"
          alignContent="center"
          alignItems="center"
          textAlign={"center"}
        >
          <AssigneesBadge
            assignees={request.assignees}
            avatarProps={{ borderColor: getColor(2), borderWidth: "2px" }}
          />
        </Flex>
        <Flex
          flexDir="column"
          minWidth={0}
          maxW="400px"
          position="sticky"
          left="0px"
          gap={1}
        >
          <StatusBadge
            status={request.status}
            badgeProps={{
              w: "fit-content",
              backgroundColor: colorMode === "light" ? "white" : "transparent",
              fontSize: "small",
              borderRadius: "sm",
            }}
          />
          <HStack w="full" gap={1} fontWeight="bold">
            <Text color={colorMode === "light" ? "gray.500" : "white"}>
              {request.key}
            </Text>
            <Text
              color={colorMode === "light" ? "gray.700" : "white"}
            >{`|`}</Text>
            <Text
              isTruncated
              color={colorMode === "light" ? "gray.700" : "gray.50"}
            >{`${request.summary}`}</Text>
          </HStack>
          <Box
            backgroundColor={getColor(2)}
            w="fit-content"
            px={1}
            borderRadius="sm"
          >
            <Text fontSize="xs" fontWeight="bold" color="white">
              {request.priority.toUpperCase()}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
