import { useColorModeValue } from "@chakra-ui/react";
import { ApiItemAdjustmentType } from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { SingleValue } from "react-select";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";
interface InventoryTypeAutocompleteProps {
  value: ApiItemAdjustmentType | null;
  onChange: (value: ApiItemAdjustmentType) => void;
  isInvalid?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

export const InventoryTypeAutocomplete = ({
  value,
  onChange,
  isInvalid,
  placeholder,
  isDisabled,
}: InventoryTypeAutocompleteProps) => {
  const classTheme = useColorModeValue("light-theme", "dark-theme");

  const options = Object.values(ApiItemAdjustmentType)
    .filter(
      (item) => item !== "returnedToSupplier" && item !== "returnedToInventory",
    )
    .map((item) => ({
      value: item,
      label: capitalizeFirstLetter(item),
    }));

  const handleChange = useCallback(
    (
      newValue: SingleValue<{
        value: ApiItemAdjustmentType;
        label: string;
      }>,
    ) => {
      if (newValue) {
        onChange(newValue.value);
      }
    },
    [onChange],
  );

  const components = useMemo(getCustomSelectComponents, []);

  return (
    <Select
      className={classTheme}
      isDisabled={isDisabled}
      closeMenuOnSelect={true}
      placeholder={placeholder}
      isInvalid={isInvalid}
      options={options}
      onChange={handleChange}
      components={components}
      chakraStyles={commonStyles}
      value={value ? options.find((x) => x.value === value) : null}
    />
  );
};
