import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiComment } from "@operations-hero/lib-api-client";
import { convertToRaw, EditorState } from "draft-js";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { ImEyeBlocked } from "react-icons/im";
import { MdModeEditOutline } from "react-icons/md";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { UserBadge } from "../../../components/badges/UserBadge";
import {
  DEFAULT_LOCALE_DATE_OPTS,
  DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
  LocaleDate,
} from "../../../components/dates/LocaleDate";
import { ReadOnlyTextEditorComments } from "../../../components/form-helpers/rich-text-editor/CommentsTextEditorReadOnly";
import {
  getEditorValueAsString,
  RichTextEditorComments,
} from "../../../components/form-helpers/rich-text-editor/RichTextEditorComments";
import { useConvertMentions } from "../../../hooks/useConvertMentions";

export interface CommentProps {
  comment: ApiComment;
  onDelete: (comment: ApiComment) => void;
  hasPermisionToEdit: boolean;
  onSave: (newComment: ApiComment, isPublic: boolean) => void;
}

export const convertToSave = (comment: EditorState) => {
  const entityMap = convertToRaw(comment.getCurrentContent()).entityMap;
  let commentString = getEditorValueAsString(comment);
  Object.values(entityMap).forEach((entity) => {
    if (entity.type === "MENTION") {
      commentString = commentString?.replace(
        entity.data.text,
        `<u:${entity.data.url}>`,
      );
    }
  });
  return commentString;
};

export const Comment = ({
  comment,
  onDelete,
  onSave,
  hasPermisionToEdit,
}: CommentProps) => {
  const { currentUser } = useAuthentication();
  const textColor = useColorModeValue("blue.500", "white");

  const [commentState, setCommentState] = useState<EditorState>();
  const [isPublic, setIsPublic] = useState(comment.isPublic);
  const [workingComment, setWorkingComment] = useState<ApiComment>();

  const { editorState: commentToEdit } = useConvertMentions({
    value: comment.comment,
    mentioned: comment?.mentioned,
  });

  const saveComment = useCallback(() => {
    if (workingComment === undefined) return;
    onSave(workingComment, isPublic);
    setWorkingComment(undefined);
  }, [isPublic, onSave, workingComment]);

  const handlePublicSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsPublic(!e.target.checked);
    },
    [],
  );

  const handleEdit = useCallback((newComment: ApiComment) => {
    setWorkingComment(newComment);
  }, []);

  const handleCommentChange = useCallback((newComment: ApiComment) => {
    setWorkingComment(newComment);
  }, []);

  useEffect(() => {
    if (!comment || !handleCommentChange) {
      return;
    }

    if (
      (commentState && comment.comment !== convertToSave(commentState)) ||
      comment.isPublic !== isPublic
    ) {
      handleCommentChange({
        ...comment,
        comment: (commentState && convertToSave(commentState)) || "",
        isPublic,
      });
    }
  }, [comment, commentState, handleCommentChange, isPublic]);

  const editSection = useMemo(
    () => (
      <Box>
        <RichTextEditorComments
          id="comment-edit"
          value={commentToEdit}
          onBlur={setCommentState}
        />
        <Box pt={2}>
          <Button
            mr={1}
            size="xs"
            variant="outline"
            colorScheme="blue"
            onClick={saveComment}
          >
            Save
          </Button>
          <Button
            size="xs"
            variant="outline"
            colorScheme="blue"
            mr={0}
            onClick={() => setWorkingComment(undefined)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    ),
    [saveComment, commentToEdit],
  );

  const canEdit = useMemo(() => {
    return comment.createdBy.id === currentUser.id || hasPermisionToEdit;
  }, [comment.createdBy.id, currentUser.id, hasPermisionToEdit]);

  return (
    <Box py={2}>
      <Grid templateColumns={["repeat(6, 1fr)"]} alignItems="center">
        <GridItem display="flex" flexDirection="column" colSpan={3}>
          <UserBadge value={comment.createdBy} />
          <Text color="gray.500" fontSize="xs" as="span">
            <LocaleDate
              date={comment.created}
              options={DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS}
            />
          </Text>
        </GridItem>

        {canEdit && !workingComment && (
          <GridItem
            display="flex"
            justifyContent="flex-end"
            colSpan={3}
            alignItems="center"
            gap={4}
          >
            {!isPublic && <Icon as={ImEyeBlocked} />}
            <Icon
              size="sm"
              color={textColor}
              onClick={() => onDelete(comment)}
              as={BsTrash}
              cursor="pointer"
            />
            <Icon
              size="sm"
              color={textColor}
              onClick={() => handleEdit(comment)}
              as={MdModeEditOutline}
              cursor="pointer"
            />
          </GridItem>
        )}
        {workingComment && (
          <GridItem colSpan={3}>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <FormLabel htmlFor="isPublic" mb="0">
                <Icon
                  verticalAlign="-2px"
                  mr={2}
                  as={ImEyeBlocked}
                  display="inline"
                />
                {"Private"}
              </FormLabel>

              <Switch
                id={`cm-from-${comment?.id}-isPublic`}
                isChecked={!isPublic}
                onChange={handlePublicSwitchToggle}
              />
            </FormControl>
          </GridItem>
        )}

        {/*Edited section */}

        <GridItem colSpan={6} py={3}>
          {workingComment
            ? editSection
            : commentToEdit && (
                <ReadOnlyTextEditorComments value={commentToEdit} />
              )}
        </GridItem>
        {!workingComment && (
          <GridItem colSpan={6}>
            {comment.updated > comment.created ? (
              <Text
                mt={1}
                as="span"
                fontSize="xs"
                display="flex"
                color="gray.500"
              >
                (edited
                <LocaleDate
                  date={comment.created}
                  textProps={{ ml: 1, fontWeight: "normal" }}
                  options={{
                    ...DEFAULT_LOCALE_DATE_OPTS,
                    hour: "2-digit",
                    minute: "2-digit",
                  }}
                />
                )
              </Text>
            ) : null}
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};
