import { Box, Text } from "@chakra-ui/react";
import { FC } from "react";

interface NumberCirclerProps {
  step: number | string;
}

export const NumberCircle: FC<NumberCirclerProps> = ({ step }) => {
  return (
    <Box
      ml={1}
      mr={2}
      minW="22px"
      width="22px"
      height="22px"
      color="blue.500"
      borderRadius="50%"
      textAlign="center"
      border="2px solid"
      borderColor="blue.500"
    >
      <Text mt="-3px" textDecoration="none">{`${step}`}</Text>
    </Box>
  );
};
