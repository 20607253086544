import * as yup from "yup";

export const EventFormSchema = yup.object().shape({
  name: yup.string().required("Name is required").nullable(),
  eventGroup: yup.object().required("Group is required").nullable(),
  venue: yup.object().required("Venue is required").nullable(),
  spaces: yup
    .array()
    .min(1, "You must select at least 1 space")
    .required("Spaces is required")
    .nullable(),
  eventContact: yup.object().nullable().required("Event Contact is required"),
});
