import {
  Box,
  Button,
  Flex,
  StackDivider,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useState } from "react";
import { StyledDatePicker } from "../../../../../../components/inputs/StyledDatePicker";

type MenuCalendarProps = {
  isDisabled?: boolean;
  onCustomDateRangeChange?: (date: string[] | null[]) => void;
  range: (string | null)[];
};
export const MenuCalendar: FC<MenuCalendarProps> = ({
  isDisabled = false,
  range,
  onCustomDateRangeChange,
}) => {
  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const [toDate, setToDate] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<string | null>(null);

  const handleSetDateArray = useCallback(() => {
    const dateArray: string[] | null[] = [];
    dateArray[0] = fromDate ? new Date(fromDate).toISOString() : "";
    dateArray[1] = toDate ? new Date(toDate).toISOString() : "";

    onCustomDateRangeChange && onCustomDateRangeChange(dateArray);
  }, [fromDate, toDate, onCustomDateRangeChange]);

  const handleDateFromChange = useCallback((value: Date | null) => {
    const date = !value ? null : value;
    const dateString = !value ? null : value.toISOString();
    const formatedDate = date !== null ? format(date, "yyyy-MM-dd") : null;
    if (formatedDate !== null && dateString !== null) {
      setFromDate(dateString);
    }
  }, []);

  const handleDateToChange = useCallback((value: Date | null) => {
    const date = !value ? null : value;
    const dateString = !value ? null : value.toISOString();
    const formatedDate = date !== null ? format(date, "yyyy-MM-dd") : null;
    if (formatedDate !== null && dateString !== null) {
      setToDate(dateString);
    }
  }, []);

  const handleCancel = useCallback(() => {
    setToDate(null);
    setFromDate(null);
  }, []);

  useEffect(() => {
    if (!range) return;
    const [initDate, endDate] = range;
    if (initDate != null) {
      setFromDate(initDate);
    }
    if (endDate != null) {
      setToDate(endDate);
    }
  }, [range]);

  return (
    <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4}>
      {!isMobileMode ? (
        <Flex px="3" alignItems="center" pr={6}>
          <StyledDatePicker
            value={fromDate}
            onChange={handleDateFromChange}
            isDisabled={isDisabled}
            name="from-date-filter"
          />
          <Text mx={2} color={isDisabled ? "gray.400" : ""}>
            To
          </Text>
          <StyledDatePicker
            value={toDate}
            onChange={handleDateToChange}
            isDisabled={isDisabled}
            name="to-date-filter"
          />
        </Flex>
      ) : (
        <Box ml={5} mr={8} alignItems="center">
          <StyledDatePicker
            value={fromDate}
            onChange={handleDateFromChange}
            isDisabled={isDisabled}
            name="from-date-filter"
          />
          <Text color={isDisabled ? "gray.400" : ""}>To</Text>
          <StyledDatePicker
            value={toDate}
            onChange={handleDateToChange}
            isDisabled={isDisabled}
            name="to-date-filter"
          />
        </Box>
      )}
      <Flex width={"full"} justifyContent="space-evenly">
        <Button isDisabled={isDisabled} onClick={handleCancel} variant="ghost">
          Cancel
        </Button>
        <Button
          isDisabled={isDisabled}
          onClick={handleSetDateArray}
          colorScheme="blue"
        >
          Apply
        </Button>
      </Flex>
    </VStack>
  );
};
