import { Box, HStack, VStack } from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiProject,
  ApiProjectStatus,
  ApiUserReference,
  ApiUserSummary,
  CreateApiProject,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import { FC, MutableRefObject, useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import {
  Attachment,
  Attachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { MultiUserAutocompleteControl } from "../../../components/form-helpers/MultiUserAutoCompleteControl";
import { ProjectStatusSelectControl } from "../../../components/form-helpers/ProjectStatusSelectControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { MultiUserAutocompleteProps } from "../../../components/selects/MultiUserAutocomplete";
import { useShowToast } from "../../../hooks/showToast";

export type NewProjectProps = {
  name: string;
  assignee: ApiUserSummary[] | null;
  description: string;
  budgetAllocations: [];
  status: ApiProjectStatus | null;
  start: string | null;
  end: string | null;
  estimatedCost: number;
};
export type NewProjectFormProps = {
  onSubmit: (
    project: CreateApiProject,
    attachments: Attachment[]
  ) => Promise<{ project: ApiProject; attachments: ApiAttachment[] } | void>;
  formRef: MutableRefObject<FormikProps<NewProjectProps> | null>;
};

const multiUserFieldOptions: Partial<MultiUserAutocompleteProps> = {
  filterOptions: {
    excludeRole: "Portal User",
  },
};

export const NewProjectForm: FC<NewProjectFormProps> = ({
  onSubmit,
  formRef,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useShowToast();

  const initialValues: NewProjectProps = {
    name: "",
    assignee: [],
    description: "",
    budgetAllocations: [],
    status: null,
    start: null,
    end: null,
    estimatedCost: 0,
  };

  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );

  const createProjectAttachments = useCallback(() => {
    const attachmentsToCreate = workingAttachments.filter(
      (attachment) => attachment.isNew === true
    );
    return attachmentsToCreate;
  }, [workingAttachments]);

  const handleOnSubmit = useCallback(
    (values: NewProjectProps) => {
      const { assignee, name, description, start, end, status } = values;
      let supervisors: ApiUserReference[] = [];
      if (assignee) {
        supervisors = assignee.map<ApiUserReference>((x) => x.id);
      }
      const project: CreateApiProject = {
        supervisors,
        name,
        description,
        start: start ? new Date(start).toISOString() : null,
        end: end ? new Date(end).toISOString() : null,
        status: status || ApiProjectStatus.pending,
        estimatedCost: 0,
      };
      return onSubmit(project, createProjectAttachments());
    },
    [onSubmit, createProjectAttachments]
  );

  const handleNewAttachments = useCallback(
    async (attachments: Attachment[]) => {
      Promise.all(
        attachments.map(async (item) => {
          const uploadFile = await apiClient.createUploadAndMoveAttachment(
            currentAccount.id,
            item
          );

          return { ...item, uploadId: uploadFile.id };
        })
      )
        .then((formatedAttachments) => {
          setWorkingAttachments([
            ...workingAttachments,
            ...formatedAttachments,
          ]);
        })
        .catch(() => {
          toast(
            "error",
            "Cannot submit attachments at the moment. Try again later."
          );
        });
    },
    [apiClient, currentAccount.id, workingAttachments, toast]
  );

  const handleOnRemoveAttachment = useCallback(
    async (attachment: Attachment) => {
      const index = workingAttachments.findIndex(
        (item) => item.uploadId === attachment.uploadId
      );
      if (index !== -1) {
        const attachmentsCopy = workingAttachments;
        await attachmentsCopy.splice(index, 1);
        setWorkingAttachments([...attachmentsCopy]);
      }
    },
    [workingAttachments]
  );

  const validationSchema = useMemo(() => {
    const schema = yup.object().shape({
      name: yup.string().required("Name is a required field"),
      status: yup.string().nullable().required("Status is a required field"),
      start: yup
        .string()
        .nullable()
        .when("status", {
          is: (status: ApiProjectStatus) =>
            status !== ApiProjectStatus.pending && status !== null,
          then: yup.string().nullable().required("Start date is required"),
        }),
      end: yup
        .string()
        .nullable()
        .when("status", {
          is: (status: ApiProjectStatus) =>
            status !== ApiProjectStatus.pending && status !== null,
          then: yup.string().nullable().required("Start date is required"),
        }),
    });
    return schema;
  }, []);

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({ values, errors, submitCount }) => {
        return (
          <Form>
            <VStack w="full" justify="stretch" gap={4} alignItems="start">
              <TextInputControl
                name="name"
                value={values.name}
                label="Project Name"
              />
              <ProjectStatusSelectControl
                name="status"
                label="Status"
                value={values.status}
              />
              <MultiUserAutocompleteControl
                label="Assignee"
                name="assignee"
                value={values.assignee}
                fieldProps={multiUserFieldOptions}
              />
              <TextEditorControl
                name="description"
                label="Description"
                value={values.description}
              />
              <HStack w="full" alignItems="start">
                <DatePickerControl
                  name="start"
                  label="Start Date"
                  value={values.start}
                  hasError={!!errors.start && submitCount > 0}
                />
                <DatePickerControl
                  name="end"
                  label="End Date"
                  value={values.end}
                  hasError={!!errors.end && submitCount > 0}
                />
              </HStack>
              <Box w="full">
                <Attachments
                  attachments={workingAttachments}
                  onNewAttachments={handleNewAttachments}
                  onDeleteAttachment={handleOnRemoveAttachment}
                />
              </Box>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
