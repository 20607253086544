import { Flex } from "@chakra-ui/react";
import { FC, Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { FallbackSpinner } from "../../../components/code-splitting/FallbackSpinner";
import { RootState } from "../../../store";

interface InventoryRouteWrapperProps {
  Element: React.FC | React.LazyExoticComponent<() => JSX.Element>;
}

export const InventoryWrapper: FC<InventoryRouteWrapperProps> = ({
  Element,
}) => {
  const { inventoryView } = useSelector((state: RootState) => state.localCache);

  const mainComponenetWidth = useMemo(() => {
    return inventoryView === "half" ? "80%" : "100%";
  }, [inventoryView]);

  return (
    <Flex flex={1} width={["100%", null, mainComponenetWidth]}>
      <Suspense fallback={<FallbackSpinner />}>
        <Element />
      </Suspense>
    </Flex>
  );
};
