import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
  Box,
  Button,
  GridItem,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ApiRequestStatus } from "@operations-hero/lib-api-client";
import { motion } from "framer-motion";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import {
  TimePeriod,
  TimePeriodKey,
} from "../../../components/selects/ColumnViewRequestPeriodSelect";
import { RootState, useThunkDispatch } from "../../../store";
import { loadMoreItems } from "../../../store/request-column-view.slice";
import { SETTING_CLOSED_REQUEST_TIME_PERIOD } from "../../../utils/emailSettingUtils";
import { DraggableRequest } from "./DraggableRequest";

export type BoardColumnProps = {
  status: ApiRequestStatus;
  isDisabled: boolean;
};

export const BoardColumn: FC<BoardColumnProps> = ({ status, isDisabled }) => {
  const { requestsByStatus, referencesByStatus } = useSelector(
    (root: RootState) => root.requestsColumnViewSlice
  );

  const thunkDispatch = useThunkDispatch();

  const { apiClient, currentAccount } = useAuthentication();

  const [dragEntered, setDragEntered] = useState(false);

  const disabledBgColor = useColorModeValue("gray.300", "gray.800");
  const ref = useRef(null);
  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    return combine(
      draggable({
        element: el,
        getInitialData: () => ({
          dropElementType: "column",
          status: status,
        }),
        canDrag: () => false,
      }),
      dropTargetForElements({
        element: el,
        getData: (args) => {
          return {
            dropElementType: "column",
            status: status,
          };
        },
        onDragEnter: (args) => {
          setDragEntered(true);
        },
        onDragLeave: () => setDragEntered(false),
        onDrop: () => setDragEntered(false),
      })
    );
  }, [status, requestsByStatus]);

  const { userSettings } = useSelector((state: RootState) => state.localCache);

  const handleLoadMoreItems = useCallback(() => {
    thunkDispatch(
      loadMoreItems({
        apiClient,
        accountId: currentAccount.id,
        status,
      })
    );
  }, [thunkDispatch, apiClient, currentAccount, status]);

  const closeColumnTimePeriod = useMemo(() => {
    const setting = userSettings[
      SETTING_CLOSED_REQUEST_TIME_PERIOD
    ].toString() as TimePeriodKey;
    return TimePeriod[setting];
  }, [userSettings]);

  return (
    <>
      <GridItem ref={ref}>
        <VStack
          justify="stretch"
          spacing="unset"
          outline={dragEntered ? "3px solid #3182CE" : ""}
          position="relative"
          mt="1"
          height="full"
        >
          {isDisabled && (
            <Box
              height="full"
              width="full"
              backgroundColor={disabledBgColor}
              opacity="0.7"
              position="absolute"
              zIndex="2"
            ></Box>
          )}
          {requestsByStatus[status]?.length === 0 && dragEntered && (
            <hr
              style={{
                height: 5,
                backgroundColor: "#90CDF4",
                width: "95%",
                borderRadius: 10,
                marginTop: 5,
                marginBottom: 5,
              }}
            />
          )}
          {requestsByStatus[status]?.map((request, index) => {
            return (
              <Box
                key={`request::${request.id}`}
                as={motion.div}
                layout
                w="full"
              >
                <Link to={`/requests/${request.key}`}>
                  <DraggableRequest request={request} index={index} />
                </Link>
              </Box>
            );
          })}
          {(referencesByStatus[status]?.totalItems ?? 0) >
            (requestsByStatus[status]?.length ?? 0) && (
            <Button onClick={handleLoadMoreItems} w="full" size="sm" top="1">
              Load more
            </Button>
          )}
          {status === ApiRequestStatus.closed && (
            <Box
              as={motion.div}
              layout
              animate={{
                y: 0,
                x: 0,
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <Text
                py="2"
                color="gray.400"
                fontStyle="italic"
                fontSize="medium"
              >{`*(Last ${closeColumnTimePeriod} view )`}</Text>
            </Box>
          )}
        </VStack>
      </GridItem>
    </>
  );
};
