import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Button, HStack, IconButton, Text } from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import moment from "moment-timezone";
import { FC, useCallback } from "react";
import { ViewTypeRadioGroup } from "../../components/ViewRadioButton";
import { ViewType } from "./types";

type TimelineHeaderProps = {
  calendar: FullCalendar;
  project: ApiProject;
  container: HTMLDivElement;
};

export const TimelineHeader: FC<TimelineHeaderProps> = ({
  calendar,
  project,
  container,
}) => {
  const prev = useCallback(() => {
    const viewType = calendar.getApi().view.type;
    let slotWidth = 100;
    if (viewType === ViewType.WEEKLY) {
      slotWidth = 110;
    }
    if (viewType === ViewType.YEARLY) {
      slotWidth = 110;
    }

    const slots = document.querySelectorAll(
      "td.fc-timeline-slot.fc-timeline-slot"
    );

    if (slots.length > 0) {
      slotWidth = (slots[0] as HTMLDivElement).offsetWidth;
    }
    const timelineContainerWidth = container.offsetWidth;
    const visibleSlots = Math.round(timelineContainerWidth / slotWidth) - 1;
    const scrollerElems = document.querySelectorAll(
      ".fc-scroller.fc-scroller-liquid-absolute"
    );

    if (scrollerElems.length > 0) {
      const scrollContainer = scrollerElems[1];
      scrollContainer.scrollLeft -= visibleSlots * slotWidth;
    }
  }, [calendar, container]);

  const next = useCallback(() => {
    const viewType = calendar.getApi().view.type;

    let slotWidth = 100;
    if (viewType === ViewType.WEEKLY) {
      slotWidth = 110;
    }
    if (viewType === ViewType.YEARLY) {
      slotWidth = 110;
    }

    const slots = document.querySelectorAll(
      "td.fc-timeline-slot.fc-timeline-slot"
    );
    if (slots.length > 0) {
      slotWidth = (slots[0] as HTMLDivElement).offsetWidth;
    }
    const timelineContainerWidth = container.offsetWidth;
    const visibleSlots = Math.round(timelineContainerWidth / slotWidth) - 1;
    const scrollerElems = document.querySelectorAll(
      ".fc-scroller.fc-scroller-liquid-absolute"
    );

    if (scrollerElems.length > 0) {
      const scrollContainer = scrollerElems[1];
      scrollContainer.scrollLeft += visibleSlots * slotWidth;
    }
  }, [calendar, container]);

  const today = useCallback(() => {
    const { start, end } = project;
    if (moment(new Date(Date.now())).isBetween(moment(start), moment(end))) {
      const diffInDays = moment(new Date(Date.now())).diff(start, "d");
      calendar.getApi().scrollToTime({
        days: diffInDays - 2,
      });
    }
  }, [project, calendar]);

  const projectStart = useCallback(() => {
    const viewType = calendar.getApi().view.type;
    if (viewType === ViewType.WEEKLY || viewType === ViewType.MONTHLY) {
      calendar.getApi().scrollToTime({
        days: -2,
      });
    }

    if (viewType === ViewType.YEARLY) {
      calendar.getApi().scrollToTime({
        days: -15,
      });
    }
    return;
  }, [calendar]);

  const projectEnd = useCallback(() => {
    const diffInDays = moment(project.end).diff(project.start, "d");
    calendar.getApi().scrollToTime({
      days: diffInDays,
    });
  }, [calendar, project]);

  return !project ? null : (
    <>
      <HStack gap={4} justifyContent="flex-end" wrap="wrap">
        <HStack gap={2}>
          <Text fontSize="sm">From:</Text>
          <Button
            leftIcon={<ArrowLeftIcon w={2} h={2} />}
            size="sm"
            px={2}
            py={1}
            variant="outline"
            colorScheme="blue"
            onClick={projectStart}
          >
            {moment(project.start).format("MMM DD, YYYY")}
          </Button>
          <Text fontSize="sm">To:</Text>
          <Button
            rightIcon={<ArrowRightIcon w={2} h={2} />}
            size="sm"
            px={2}
            py={1}
            variant="outline"
            colorScheme="blue"
            onClick={projectEnd}
          >
            {moment(project.end).format("MMM DD, YYYY")}
          </Button>
        </HStack>
        <ViewTypeRadioGroup
          onChange={(viewType) => {
            calendar.getApi().changeView(viewType);
          }}
        />
        <HStack>
          <IconButton
            aria-label="timeline-previous"
            icon={<ChevronLeftIcon />}
            size="sm"
            variant="outline"
            colorScheme="gray"
            onClick={prev}
          />
          <Button
            size="sm"
            py={1}
            variant="outline"
            colorScheme="gray"
            onClick={today}
          >
            Today
          </Button>
          <IconButton
            aria-label="timeline-next"
            icon={<ChevronRightIcon />}
            size="sm"
            variant="outline"
            colorScheme="gray"
            onClick={next}
          />
        </HStack>
      </HStack>
    </>
  );
};
