import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import {
  ApiBudgetSummary,
  ApiLaborTransaction,
  ApiRequest,
  ApiUserSummary,
  ApiWorkflow,
  LaborType,
} from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import HoursSpinner from "../../../../components/inputs/HoursSpinner";
import { StyledDatePicker } from "../../../../components/inputs/StyledDatePicker";
import { BudgetAutocomplete } from "../../../../components/selects/BudgetAutocomplete";
import LaborTypeAutocomplete from "../../../../components/selects/LaborTypeAutocomplete";
import { SingleAssigneeAutocomplete } from "../../../../components/selects/SingleAssigneeAutocomplete";
import { RootState } from "../../../../store";

export enum HoursOperation {
  ADDITION = "ADDITION",
  SUBTRACTION = "SUBTRACTION",
  REPLACE = "REPLACE",
}

const MIN_HOUR = 0;
const MAX_HOUR = 23.99;
const HOUR_STEP = 0.25;

export interface LaborTransactionFormProps {
  transaction: ApiLaborTransaction;
  showLaborer?: boolean;
  showDatePerformed?: boolean;
  onChange: (value: ApiLaborTransaction) => void;
  displayLeadGroups?: boolean;
}

export type Laborer = ApiUserSummary & {
  isGroup?: boolean;
  groupMembers?: ApiUserSummary[];
};

export type TransactionLaborerWithGroups = ApiLaborTransaction & {
  laborer: Laborer;
};

export const LaborTransactionForm = ({
  showLaborer = true,
  showDatePerformed = true,
  transaction,
  onChange,
  displayLeadGroups,
}: LaborTransactionFormProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [workflowForm, setWorkflowForm] = useState<ApiWorkflow>();
  const [requestForm, setRequestForm] = useState<ApiRequest>();
  const { request, workflow } = useSelector(
    (state: RootState) => state.requestForm
  );
  const { workflows } = useSelector((state: RootState) => state.localCache);
  const handleUserOnChange = useCallback(
    (value: Laborer | null) => {
      if (value?.isGroup && value.groupMembers?.length === 0) {
        apiClient
          .findUserGroupMembers(currentAccount.id, value.id)
          .then((response) => {
            const newValue = {
              ...value,
              groupMembers: response.data.map((member) => member.user),
            };
            onChange({ ...transaction, laborer: newValue });
          });
        return;
      }
      // yes this is weird, allowEmpty is false on the control, but the types do not flow
      onChange({ ...transaction, laborer: value! });
    },
    [transaction, onChange, apiClient, currentAccount.id]
  );

  const handleLaborTypeOnChange = useCallback(
    (value: LaborType) => {
      onChange({ ...transaction, laborType: value });
    },
    [transaction, onChange]
  );

  const handleDateChanged = useCallback(
    (date: Date | string | null) => {
      const datePerformed: Date =
        typeof date === "string" || date === null ? new Date(date || "") : date;
      datePerformed.setHours(0);
      datePerformed.setMinutes(0);
      datePerformed.setSeconds(0);
      onChange({
        ...transaction,
        datePerformed: datePerformed
          ? new Date(datePerformed).toISOString()
          : "",
      });
    },
    [transaction, onChange]
  );

  const handleHoursChange = useCallback(
    (value: number) => {
      onChange({ ...transaction, hours: value });
    },
    [transaction, onChange]
  );

  const handleBudgetChange = useCallback(
    (budget: ApiBudgetSummary | null) => {
      onChange({ ...transaction, budget });
    },
    [transaction, onChange]
  );

  const loadRequest = useCallback(
    async (requestKey: string) => {
      return await apiClient.getRequest(currentAccount.id, requestKey);
    },
    [apiClient, currentAccount.id]
  );

  useEffect(() => {
    if (transaction.hours > MAX_HOUR) {
      onChange({ ...transaction, hours: MAX_HOUR });
    }
    if (transaction.hours < MIN_HOUR) {
      onChange({ ...transaction, hours: MIN_HOUR });
    }
  }, [transaction, onChange]);

  useEffect(() => {
    if (!transaction.id) {
      onChange({ ...transaction, budget: request?.budget || null });
    }
    const key = transaction.requestKey.split("-");
    if (key.length && !workflow) {
      const slug = key[0];
      const workflowTransaction = workflows.find(
        (work) => work.requestSlug === slug
      );
      setWorkflowForm(workflowTransaction);
      return;
    }
    setWorkflowForm(workflow ? workflow : undefined);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWorkflowForm]);

  useEffect(() => {
    const key = transaction.requestKey;
    if (key.length && !request) {
      loadRequest(key).then((res) => {
        setRequestForm(res);
        return;
      });
    }
    setRequestForm(request ? request : undefined);
    return;
  }, [setRequestForm, loadRequest, request, transaction.requestKey]);

  return (
    <Box display="flex" flexDir="column" gap={2}>
      {showLaborer && (
        <FormControl id="laborer">
          <FormLabel htmlFor="laborer" mb="0">
            Laborer
          </FormLabel>
          {workflowForm && requestForm && (
            <SingleAssigneeAutocomplete
              value={transaction.laborer}
              onChange={handleUserOnChange}
              workflow={workflowForm}
              location={requestForm.location ?? undefined}
              reportingCategory={requestForm.reportingCategory ?? undefined}
              displayLeadGroups={displayLeadGroups}
            />
          )}
        </FormControl>
      )}

      <FormControl id="labor-type">
        <FormLabel htmlFor="labor-type" mb="0" mt="4">
          Labor Type
        </FormLabel>
        <LaborTypeAutocomplete
          onChange={handleLaborTypeOnChange}
          value={transaction.laborType}
        />
      </FormControl>

      {showDatePerformed && (
        <FormControl mt={4} id="datePerformed">
          <FormLabel htmlFor="datePerformed" mb="0">
            Date Performed
          </FormLabel>
          <StyledDatePicker
            value={transaction.datePerformed}
            onChange={handleDateChanged}
            name="labor-date-performed"
          />
        </FormControl>
      )}
      {workflowForm && workflowForm.allowBudgetsOnTransactions && (
        <FormControl id="labor-budget">
          <FormLabel>Budgets</FormLabel>
          <BudgetAutocomplete
            name="budget"
            value={(transaction.budget as ApiBudgetSummary) || null}
            placeholder="Search existing budgets"
            onChange={handleBudgetChange}
          />
        </FormControl>
      )}
      <FormControl id="hours" mt={7}>
        <FormLabel htmlFor="hours" mb="0">
          Time spent
        </FormLabel>
        <HoursSpinner
          value={transaction.hours}
          onChange={handleHoursChange}
          includeStepButtons
          includeQuickButtons
          min={MIN_HOUR}
          max={MAX_HOUR}
          step={HOUR_STEP}
        />
      </FormControl>
    </Box>
  );
};
