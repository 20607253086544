import { Portal, useDisclosure } from "@chakra-ui/react";
import { ApiFundingSource } from "@operations-hero/lib-api-client";
import React, { FC, useCallback, useState } from "react";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../hooks/showToast";
import { DeactivateActivateModal } from "./DeactivateActivateModal";

type InjectedDeactivateActivateProps = {
  onOpen: () => void;
  active: boolean;
};

type DeactivateActivateProps = {
  fundingSource: ApiFundingSource;
  onActivateDeactivate: (active: boolean) => void;
  children: (props: InjectedDeactivateActivateProps) => React.ReactNode;
};

export const DeactivateActivate: FC<DeactivateActivateProps> = ({
  children,
  fundingSource,
  onActivateDeactivate,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useShowToast();
  const [active, setActive] = useState<boolean>(fundingSource.active);
  const handleAction = useCallback(() => {
    if (active) {
      return apiClient
        .deactivateFundingSource(currentAccount.id, fundingSource.id)
        .then(() => {
          setActive(false);
          onActivateDeactivate(false);
        })
        .catch(() => {
          toast("error", "An error occurred");
        })
        .finally(() => {
          onClose();
        });
    } else {
      return apiClient
        .reactivateFundingSource(currentAccount.id, fundingSource.id)
        .then(() => {
          setActive(true);
          onActivateDeactivate(true);
        })
        .catch(() => {
          toast("error", "An error occurred");
        })
        .finally(() => {
          onClose();
        });
    }
  }, [
    fundingSource.id,
    currentAccount.id,
    apiClient,
    onClose,
    active,
    onActivateDeactivate,
    toast,
  ]);
  return (
    <>
      {children({ onOpen, active })}
      {isOpen && (
        <Portal>
          <DeactivateActivateModal
            isOpen={isOpen}
            onClose={onClose}
            onActivateDeactivate={handleAction}
            fundingSource={fundingSource}
            active={active}
          />
        </Portal>
      )}
    </>
  );
};
