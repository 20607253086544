import { InfoIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { useScreenBreakpoints } from "../../../../hooks/useScreenBreakpoints";
import { RootState } from "../../../../store";
import { Header } from "./Header";
import { Requests } from "./request-list/Requests";
import { DesktopScheduling } from "./Scheduling.desktop";
import { TabletScheduling } from "./Scheduling.tablet";
import { Timeline } from "./Timeline";

type SchedulingProps = {};
export const Scheduling: FC<SchedulingProps> = () => {
  const { colorMode } = useColorMode();
  const { isDesktop } = useScreenBreakpoints();

  const { project } = useSelector((state: RootState) => state.projectPageSlice);
  const { isInternal } = useSelector((state: RootState) => state.auth);
  const schedulingTimelineRef = useRef<FullCalendar | null>(null);

  return (
    <>
      {project && (
        <>
          <Header project={project} />
          <Divider my={3} />
          {isDesktop ? (
            <DesktopScheduling
              onExpandedCompleted={() => {
                if (!schedulingTimelineRef.current) return;
                schedulingTimelineRef.current.getApi().updateSize();
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  gap: 4,
                  w: "100%",
                }}
              >
                <Requests project={project} />
              </Box>
              {isInternal ? (
                <>
                  {!project.start || !project.end ? (
                    <Flex
                      gap={2}
                      h="100%"
                      flexDirection="column"
                      bg={colorMode === "light" ? "gray.50" : "gray.900"}
                    >
                      <VStack color="gray" mt={6}>
                        <InfoIcon />
                        <Text>
                          Project start and end dates required to display the
                          timeline
                        </Text>
                      </VStack>
                    </Flex>
                  ) : (
                    <Flex
                      gap={2}
                      h="100%"
                      flexDirection="column"
                      className="oh-planning-scheduling"
                    >
                      <Timeline
                        project={project}
                        timelineRef={schedulingTimelineRef}
                      />
                    </Flex>
                  )}
                </>
              ) : (
                <Flex
                  gap={2}
                  pt={20}
                  color="gray"
                  fontStyle="italic"
                  alignContent="center"
                  alignItems="center"
                  h="100%"
                  flexDirection="column"
                  bg={colorMode === "light" ? "gray.50" : "gray.900"}
                  opacity={0.5}
                >
                  <LockIcon />
                  Timeline
                </Flex>
              )}
            </DesktopScheduling>
          ) : (
            <TabletScheduling>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  gap: 4,
                  w: "100%",
                }}
              >
                <Requests project={project} />
              </Box>
              {isInternal ? (
                <>
                  {!project.start || !project.end ? (
                    <Flex
                      gap={2}
                      h="100%"
                      flexDirection="column"
                      bg={colorMode === "light" ? "gray.50" : "gray.900"}
                    >
                      <VStack
                        color="gray"
                        mt={6}
                        w="full"
                        align={"stretch"}
                        h="full"
                        alignItems={"center"}
                      >
                        <InfoIcon />
                        <Text>
                          Project start and end dates required to display the
                          timeline
                        </Text>
                      </VStack>
                    </Flex>
                  ) : (
                    <Flex gap={2} h="100%" flexDirection="column">
                      <Box
                        gap={2}
                        h="100%"
                        pt="10"
                        className="oh-planning-scheduling"
                      >
                        <Timeline
                          project={project}
                          timelineRef={schedulingTimelineRef}
                        />
                      </Box>
                    </Flex>
                  )}
                </>
              ) : (
                <Box
                  gap={2}
                  fontStyle="italic"
                  bg={colorMode === "light" ? "gray.50" : "gray.900"}
                  h="100%"
                  pt="10"
                >
                  <VStack color="gray">
                    <LockIcon />
                    <Text>Timeline</Text>
                  </VStack>
                </Box>
              )}
            </TabletScheduling>
          )}
        </>
      )}
    </>
  );
};
