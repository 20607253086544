import { Badge, Flex, Text } from "@chakra-ui/react";
import { ApiVendor } from "@operations-hero/lib-api-client";
import {
  AsyncCreatableSelect,
  DropdownIndicatorProps,
} from "chakra-react-select";
import { useCallback, useMemo, useState } from "react";
import { useShowToast } from "../../hooks/showToast";
import { VENDOR_INITIAL_VALUES } from "../../pages/account-settings/vendors/VendorsForm";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  DropdownIndicator,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

export interface VendorAutocompleteProps {
  name?: string;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: ApiVendor | null;
  isClearable?: boolean;
  onChange: (vendor: ApiVendor | null) => void;
  isSupplier?: boolean;
  isManufacturer?: boolean;
  isServiceProvider?: boolean;
  isSupplierOnly?: boolean;
  isManufacturerOnly?: boolean;
  aiSearch?: string;
}

const VendorBadge = ({
  value,
}: {
  value: ApiVendor | { label: string; value: string };
}) => {
  const isNew = "label" in value;

  const vendorTypes = useMemo(() => {
    let result: string[] = [];
    if (isNew) return result;
    const valueCopy = { ...value } as ApiVendor;

    valueCopy.isManufacturer && result.push("Manufacturer");
    valueCopy.isServiceProvider && result.push("Service Provider");
    valueCopy.isSupplier && result.push("Supplier");

    return result;
  }, [isNew, value]);

  return (
    <Flex flexDir="column">
      <Text fontWeight={isNew ? "semibold" : "normal"} pl={1}>
        {"label" in value ? value.label : value.name}
      </Text>
      {vendorTypes.length > 0 && (
        <Badge colorScheme="white" isTruncated>
          {vendorTypes.join(", ")}
        </Badge>
      )}
    </Flex>
  );
};
const CustomOptionComponent = createOptionComponent(VendorBadge);

const CustomSingleValueComponent = (props: SingleValueSelect<ApiVendor>) => {
  const { data } = props;
  return <VendorBadge value={data} />;
};

export const VendorAutocomplete = ({
  value,
  onChange,
  allowEmpty = true,
  isInvalid,
  isDisabled,
  placeholder,
  isClearable,
  isSupplier,
  isManufacturer,
  isServiceProvider,
  isSupplierOnly,
  isManufacturerOnly,
  aiSearch,
}: VendorAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<string | undefined>(aiSearch);
  const showToast = useShowToast();

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      inputValue && setSearch(inputValue);
      apiClient
        .findVendors(currentAccount.id, {
          search,
          pageSize: 50,
          isSupplier: isSupplierOnly,
          isManufacturer: isManufacturerOnly,
        })
        .then((response) => {
          return cb(response.data);
        });
    },
    [apiClient, currentAccount.id, isManufacturerOnly, isSupplierOnly, search]
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const handleChange = useCallback(
    (newValue: ApiVendor | null) => {
      if (allowEmpty !== true && newValue == null) {
        return;
      }
      onChange(newValue);
    },
    [onChange, allowEmpty]
  );

  const getOptionValue = useCallback((vendor: ApiVendor) => vendor.id, []);

  const handleOnCreateOption = useCallback(
    async (inputValue: string) => {
      try {
        setIsLoading(true);
        const response = await apiClient.createVendor(currentAccount.id, {
          ...VENDOR_INITIAL_VALUES,
          name: inputValue,
          primaryContact: null,
          isManufacturer: isManufacturer || false,
          isServiceProvider: isServiceProvider || false,
          isSupplier: isSupplier || false,
        });
        onChange(response);
        showToast("success", "Vendor was created successfully");
        setIsLoading(false);
      } catch {
        showToast("error", "something went wrong creating a vendor");
      }
    },
    [
      apiClient,
      currentAccount.id,
      isManufacturer,
      isServiceProvider,
      isSupplier,
      onChange,
      showToast,
    ]
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (
        props: CustomOptionsProps<ApiVendor | { label: string; value: string }>
      ) => CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiVendor>) =>
        CustomSingleValueComponent(props),
      DropdownIndicator: (props: DropdownIndicatorProps) => {
        return DropdownIndicator(props);
      },
    };
  }, []);

  const vendorTypeValues = useMemo(() => {
    if (isManufacturer && isServiceProvider) {
      return "Manufacturer&ServiceProvider";
    }
    if (isSupplier) {
      return "Supplier";
    }
    if (isManufacturer) {
      return "Manufacturer";
    }
    return "ServiceProvider";
  }, [isManufacturer, isServiceProvider, isSupplier]);

  return (
    <AsyncCreatableSelect
      value={value}
      key={`${value?.name}::${vendorTypeValues}`}
      isLoading={isLoading}
      cacheOptions={true}
      defaultOptions={true}
      isInvalid={isInvalid}
      onChange={handleChange}
      components={components}
      isDisabled={isDisabled}
      isClearable={isClearable}
      name="vendorAutocomplete"
      placeholder={placeholder}
      chakraStyles={commonStyles}
      getOptionValue={getOptionValue}
      loadOptions={debouncedLoadOptions}
      onCreateOption={handleOnCreateOption}
      defaultInputValue={aiSearch}
      allowCreateWhileLoading={false}
    />
  );
};
