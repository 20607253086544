import {
  Button,
  Divider,
  Heading,
  HStack,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProps } from "formik";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useShowToast } from "../../hooks/showToast";
import { useAccountDetails } from "../../hooks/useAccountDetails";
import { useMultiStepForm } from "../../hooks/useMultiStepForm";
import { AccountModal } from "../../pages/account-settings/account-modal/AccountModal";
import { RootState, useThunkDispatch } from "../../store";
import {
  initVerification,
  setPhone,
  setVerificationId,
  setVerificationStatus,
  unloadPhoneVerificationState,
  verifyUserPhoneNumber,
} from "../../store/user-phone-verifications";
import {
  defaultCountry,
  getAccountCountry,
} from "../../utils/getSupportedCountries";
import { useAuthentication } from "../auth/AuthProvider";
import {
  PhoneVerificationForm,
  PhoneVerificationFormParams,
} from "./PhoneVerificationForm";
import { StepFinal, StepFinalSubmitButton } from "./StepFinal";
import { StepOne, StepOneSubmitButton } from "./StepOne";
import { StepTwo, StepTwoSubmitButton } from "./StepTwo";

export type PhoneVerificationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onPhoneVerified: () => Promise<void> | void;
};

export const PhoneVerificationModal: FC<PhoneVerificationModalProps> = ({
  isOpen,
  onClose,
  onPhoneVerified,
}) => {
  const { apiClient } = useAuthentication();
  const { accountDetails } = useAccountDetails();

  const accountCountry = getAccountCountry(
    accountDetails?.address.country || defaultCountry
  );

  const initialValues: PhoneVerificationFormParams = useMemo(
    () => ({
      phone: {
        formated: "",
        e164: "",
      },
      countryCode: {
        country: {
          name: accountCountry.name,
          code: accountCountry.isoCode,
        },
        phoneCode: accountCountry.phonecode,
      },
      code: "",
    }),
    [accountCountry]
  );
  const formRef = useRef<FormikProps<PhoneVerificationFormParams>>(null);

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const showToast = useShowToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const { verificationStatus, resendStatus } = useSelector(
    (root: RootState) => root.phoneVerificationSlice
  );
  const validationStatusRef = useRef<boolean>();
  validationStatusRef.current = verificationStatus;

  const steps = useMemo(() => {
    return [
      <StepOne values={formRef.current?.values || initialValues} />,
      <StepTwo
        values={formRef.current?.values || initialValues}
        isResendDisabled={isLoading}
      />,
      <StepFinal />,
    ];
  }, [isLoading, initialValues]);

  const { currentStepIndex, step, next, back, isFirstStep, isLastStep, goTo } =
    useMultiStepForm(steps);

  const onFormClose = useCallback(async () => {
    dispatch(unloadPhoneVerificationState());
    onClose();
    dispatch(setVerificationStatus(undefined));
    goTo(0);
    setIsLoading(false);
    await onPhoneVerified();
  }, [onClose, goTo, dispatch, onPhoneVerified]);

  const onFirstSubmit = useCallback(() => {
    if (!formRef.current?.values.phone || !formRef.current.values.countryCode)
      return;

    setIsLoading(true);

    const { phone, countryCode } = formRef.current.values;
    const fullE164FormatPhone = `+${countryCode.phoneCode}${phone.e164}`;
    dispatch(
      setPhone({
        formated: phone.formated,
        e164: fullE164FormatPhone,
      })
    );
    thunkDispatch(
      initVerification({
        apiClient,
        phone: fullE164FormatPhone,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setIsLoading(false);
        next();
        setIsValid(false);
      })
      .catch(() => {
        showToast(
          "error",
          "An error happened during verification. Please try again."
        );
        onFormClose();
      });
  }, [next, apiClient, thunkDispatch, dispatch, onFormClose, showToast]);

  const onSecondSubmit = useCallback(async () => {
    if (!formRef.current?.values.code) return;
    setIsLoading(true);

    //clearing old verification status, form errors and re-validating
    dispatch(setVerificationStatus(undefined));
    await formRef.current.validateForm();

    thunkDispatch(
      verifyUserPhoneNumber({
        apiClient,
        code: Number.parseInt(formRef.current.values.code),
      })
    )
      .then(unwrapResult)
      .then(async () => {
        setIsLoading(false);

        if (validationStatusRef.current === true) {
          next();
        } else if (formRef.current) {
          //validation failed, trigger validation to display error message
          await formRef.current.validateForm();
          //validation failed, clearing status for the next verification attempt
          dispatch(setVerificationStatus(undefined));
        }
      })
      .catch(() => {
        showToast(
          "error",
          "An error happened during verification. Please try again."
        );
        onFormClose();
      });
  }, [next, dispatch, apiClient, thunkDispatch, onFormClose, showToast]);

  const submitButtons = useMemo(
    () => [
      <StepOneSubmitButton
        isLoading={isLoading}
        onClick={onFirstSubmit}
        isDisabled={!isValid}
      />,
      <StepTwoSubmitButton
        isLoading={isLoading}
        onClick={onSecondSubmit}
        isDisabled={!isValid}
      />,
      <StepFinalSubmitButton onClick={onFormClose} />,
    ],
    [isLoading, isValid, onFirstSubmit, onSecondSubmit, onFormClose]
  );

  const backActions = useMemo(() => {
    return [
      () => {},
      () => {
        // only the second step has a Back button
        //clearing the verificationId for the next attempt
        dispatch(setVerificationId(""));
        //clearing the code field
        formRef.current?.setFieldValue("code", "", false);
        formRef.current?.setTouched({ code: false });
        back();
        setIsValid(true);
      },
    ];
  }, [back, dispatch]);

  const modalTitle = useMemo(() => {
    return (
      <HStack alignItems="center">
        <Heading size="md">Phone verification</Heading>
        <Text
          fontWeight="normal"
          sx={{
            fontSize: "md",
          }}
        >{`(Step ${isLastStep ? currentStepIndex : currentStepIndex + 1} of ${
          steps.length - 1
        })`}</Text>
      </HStack>
    );
  }, [currentStepIndex, isLastStep, steps]);

  useEffect(() => {
    if (resendStatus === "started") {
      formRef.current?.setFieldValue("code", "", false);
      formRef.current?.setTouched({ code: false });
      formRef.current?.validateForm();
    }
  }, [resendStatus]);

  return (
    <AccountModal
      title={modalTitle}
      content={
        <PhoneVerificationForm
          formRef={formRef}
          step={step}
          initialValues={initialValues}
          onChange={(isValid) => {
            setIsValid(isValid);
          }}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
      closeButton={false}
      isCentered={true}
      footer={
        <>
          <Divider />
          <ModalFooter
            justifyContent={
              !isFirstStep && !isLastStep ? "space-between" : "flex-end"
            }
          >
            {!isFirstStep && !isLastStep && (
              <Button
                onClick={backActions[currentStepIndex]}
                variant="link"
                colorScheme="blue"
                isDisabled={isLoading || resendStatus === "started"}
              >
                Back
              </Button>
            )}

            <HStack>
              {!isLastStep && (
                <Button onClick={onFormClose} isDisabled={isLoading}>
                  Cancel
                </Button>
              )}
              {submitButtons[currentStepIndex]}
            </HStack>
          </ModalFooter>
        </>
      }
    />
  );
};
