import { Text, TextProps } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useMemo } from "react";

const HOUR_FORMAT = "h:mmaaa";

type DateRange = Date | string;

interface HoursRangeProps {
  start: DateRange;
  end: DateRange;
  textProps?: TextProps;
  textBefore?: string;
}

export const HoursRange: FC<HoursRangeProps> = ({
  start,
  end,
  textProps,
  textBefore,
}) => {
  const range = useMemo(() => {
    const formattedStart = format(new Date(start), HOUR_FORMAT);
    const formattedEnd = format(new Date(end), HOUR_FORMAT);

    return `${formattedStart} - ${formattedEnd}`;
  }, [end, start]);

  return (
    <Text as="span" {...textProps}>
      {textBefore} {range}
    </Text>
  );
};
