import { Box, Icon, Image } from "@chakra-ui/react";
import { ApiAttachment } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { FaDotCircle } from "react-icons/fa";
import { VscCircleLargeFilled } from "react-icons/vsc";

interface ImageSelectionProps {
  attachment: ApiAttachment;
  isChecked: boolean;
  onChangeAttachment: (id: string) => void;
}

export const ImageSelection: FC<ImageSelectionProps> = ({
  attachment,
  isChecked,
  onChangeAttachment,
}) => {
  const previewImage = useCallback((attachment: ApiAttachment) => {
    const imageSrc = attachment.url;
    window.open(
      imageSrc!,
      "_blank",
      // eslint-disable-next-line no-restricted-globals
      `width=${screen.width},height=${screen.height}`
    );
  }, []);

  return (
    <Box
      borderRadius={6}
      position="relative"
      border={isChecked ? "solid 3px" : "none"}
      borderColor={isChecked ? "blue.500" : "none"}
    >
      <Icon
        as={isChecked ? FaDotCircle : VscCircleLargeFilled}
        position="absolute"
        marginLeft="140px"
        marginTop="10px"
        cursor="pointer"
        fontSize="larger"
        onClick={() => {
          onChangeAttachment(attachment.id);
        }}
        color={isChecked ? "blue.600" : "gray.100"}
      />
      <Image
        w={"170px"}
        h={"170px"}
        alt={attachment.name}
        // @ts-ignore
        src={attachment.url || attachment?.readUrl}
        onClick={() => previewImage(attachment)}
      />
    </Box>
  );
};
