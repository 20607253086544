import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useRequestsAccessForUser = () => {
  const { workflowMap, policyMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const { isProductAdmin } = useSelector((state: RootState) => state.auth);

  const hasTechPlus = useMemo(() => {
    let hasTechPlus = false;
    for (const key in workflowMap) {
      const policy = policyMap[workflowMap[key].id];
      const { admin, technician, reviewer } = policy;
      if (isProductAdmin || admin || technician || reviewer) {
        hasTechPlus = true;
        break;
      }
    }
    return hasTechPlus;
  }, [isProductAdmin, workflowMap, policyMap]);
  return {
    hasTechPlus,
  };
};
