import {
  Box,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAllowedDashboards } from "../../../../hooks/useDashboards";
import { useNavProductItems } from "../../../../hooks/useNavProductItems";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";

export const NavProductItems = () => {
  const location = useLocation();
  const { userDashboards } = useAllowedDashboards();

  const linkHoverColor = useColorModeValue("gray.800", "white");
  const itemColor = useColorModeValue("gray.400", "whiteAlpha.700");
  const selectedItemColor = useColorModeValue("gray.600", "white");

  const selectedItem = useMemo(() => {
    const path = location.pathname.slice(1);
    if (path === "") return "Dashboard";
    return capitalizeFirstLetter(path);
  }, [location.pathname]);

  const { navItems, energyCallback } = useNavProductItems();

  return (
    <Flex alignContent="center" gap={2.5} w="fit-content">
      {userDashboards.length > 1 && (
        <Menu gutter={4}>
          <MenuButton
            p={3}
            color={itemColor}
            fontWeight="semibold"
            _hover={{ fontWeight: "semibold", color: linkHoverColor }}
          >
            <Flex alignItems="center" gap={1}>
              <Text as="span">Dashboards</Text>
              <MdKeyboardArrowDown />
            </Flex>
          </MenuButton>
          <MenuList>
            {userDashboards.map((item) => (
              <MenuItem key={`dashboardMenu::${item.label}`}>
                <Link
                  as={RouterLink}
                  to={item.path}
                  _hover={{
                    color: linkHoverColor,
                    textDecoration: "none",
                    fontWeight: "semibold",
                  }}
                >
                  {item.label}
                </Link>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}

      {userDashboards.length === 1 && (
        <Box p={3}>
          <Link
            as={RouterLink}
            to={userDashboards[0].path}
            fontWeight="500"
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
            color={
              selectedItem === userDashboards[0].label
                ? selectedItemColor
                : itemColor
            }
          >
            Dashboard
          </Link>
        </Box>
      )}

      {navItems.map((navItem) => (
        <Box key={navItem.label} alignSelf="center" p={3}>
          {navItem.Element ? (
            <navItem.Element onClick={() => {}} />
          ) : (
            <Link
              as={navItem.to ? RouterLink : undefined}
              href={navItem.href ?? undefined}
              to={navItem.to ? navItem.to : ""}
              fontWeight="500"
              _hover={{
                textDecoration: "none",
                color: linkHoverColor,
              }}
              color={
                selectedItem === navItem.label ? selectedItemColor : itemColor
              }
              onClick={navItem.label === "Energy" ? energyCallback : () => {}}
            >
              {navItem.label}
            </Link>
          )}
        </Box>
      ))}
    </Flex>
  );
};
