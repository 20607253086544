import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
} from "@chakra-ui/react";
import FullCalendar, { EventInput } from "@fullcalendar/react";
import { endOfWeek, format, startOfWeek } from "date-fns";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { DATE_FORMAT_TIMESHEET } from "../../store/timesheet.slice";
import { MiniCalendar } from "../calendar/mini-calendar/MiniCalendar";
import { TimesheetFiltersValues } from "./TimesheetFilters";
interface CalendarDatePickerProps {
  values: EventInput[];
  setValues: (values: Partial<TimesheetFiltersValues>) => void; //Dispatch<React.SetStateAction<EventInput[]>>;
}

const DATE_FORMAT_ELEMENT = "yyyy-MM-dd";
const DATE_FORMAT_CALENDAR_BUTTON = "MMM dd";

export const CalendarWeekPicker: FC<CalendarDatePickerProps> = ({
  values,
  setValues,
}) => {
  const bgColor = useColorModeValue("white", "whiteAlpha.300");

  const miniCalendarRef = useRef<FullCalendar | null>(null);
  const [previouslySelectedRow, setPreviouslySelectedRow] =
    useState<Element | null>(null);

  const handlePrevious = useCallback(() => {
    const date = new Date(values[0]?.start?.toLocaleString() ?? "");
    date.setDate(date.getDate() - 7);

    setValues({
      values: [
        {
          id: format(new Date(date), DATE_FORMAT_TIMESHEET),
          title: "",
          start: startOfWeek(new Date(date)),
          end: startOfWeek(new Date(date)),
        },
        {
          id: format(new Date(date), DATE_FORMAT_TIMESHEET),
          title: "",
          start: endOfWeek(new Date(date)),
          end: endOfWeek(new Date(date)),
        },
      ],
    });
    if (!miniCalendarRef.current) return;

    const miniCalendarApi = miniCalendarRef.current.getApi();
    miniCalendarApi.gotoDate(date);
  }, [values, setValues]);

  const handleNext = useCallback(() => {
    const date = new Date(values[0]?.start?.toLocaleString() ?? "");
    date.setDate(date.getDate() + 7);
    setValues({
      values: [
        {
          id: format(new Date(date), DATE_FORMAT_TIMESHEET),
          title: "",
          start: startOfWeek(new Date(date)),
          end: startOfWeek(new Date(date)),
        },
        {
          id: format(new Date(date), DATE_FORMAT_TIMESHEET),
          title: "",
          start: endOfWeek(new Date(date)),
          end: endOfWeek(new Date(date)),
        },
      ],
    });

    if (!miniCalendarRef.current) return;

    const miniCalendarApi = miniCalendarRef.current.getApi();
    miniCalendarApi.gotoDate(date);
  }, [values, setValues]);

  const navigateToDate = useCallback(
    async ({ date }: { date: Date }) => {
      if (!miniCalendarRef.current) return;

      const miniCalendarApi = miniCalendarRef.current.getApi();

      const miniCalendarCurrentData =
        miniCalendarApi.currentDataManager?.getCurrentData();

      if (
        !miniCalendarCurrentData ||
        !miniCalendarCurrentData.dateProfile.activeRange
      )
        return;

      setValues({
        values: [
          {
            id: format(new Date(date), DATE_FORMAT_TIMESHEET),
            title: "",
            start: startOfWeek(new Date(date)),
            end: startOfWeek(new Date(date)),
          },
          {
            id: format(new Date(date), DATE_FORMAT_TIMESHEET),
            title: "",
            start: endOfWeek(new Date(date)),
            end: endOfWeek(new Date(date)),
          },
        ],
      });

      miniCalendarApi.gotoDate(date);
    },
    [setValues]
  );

  const buttonLabel = useMemo(() => {
    const startDate = new Date(values[0]?.start?.toLocaleString() ?? "");
    const endDate = new Date(values[1]?.start?.toLocaleString() ?? "");
    if (startDate.getMonth() === endDate.getMonth()) {
      return `${format(
        startDate,
        DATE_FORMAT_CALENDAR_BUTTON
      )} - ${endDate.getDate()}`;
    }
    return `${format(startDate, DATE_FORMAT_CALENDAR_BUTTON)} -
    ${format(endDate, DATE_FORMAT_CALENDAR_BUTTON)}`;
  }, [values]);

  useEffect(() => {
    const startDate = new Date(values[0]?.start?.toLocaleString() ?? "");
    const dateFormat = format(startDate, DATE_FORMAT_ELEMENT);
    const el = document.getElementsByClassName("fc-daygrid-day");

    for (let i = 0; i < el.length; i++) {
      const row = el[i].closest("tr[role='row']");
      const rowDate = el[i].getAttribute("data-date");

      if (row && rowDate === dateFormat) {
        row.setAttribute("id", "selectedWeek");

        if (previouslySelectedRow && previouslySelectedRow !== row) {
          previouslySelectedRow.removeAttribute("id");
        }

        setPreviouslySelectedRow(row);
        break;
      }
    }
  }, [values, previouslySelectedRow]);

  return (
    <Flex mt={[2, 2, 0]} flexWrap="wrap">
      <Button
        bgColor={bgColor}
        borderColor="blue.500"
        variant="outline"
        size="sm"
        mr={2}
        onClick={handlePrevious}
      >
        <Icon as={MdKeyboardArrowLeft} cursor="pointer" />
      </Button>
      <Popover>
        <PopoverTrigger>
          <Button
            aria-label="Field"
            bgColor={bgColor}
            borderColor="blue.500"
            variant="outline"
            size="sm"
            id="menu-button-relative"
          >
            {buttonLabel}
          </Button>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <MiniCalendar
              onDateClick={navigateToDate}
              miniCalendarRef={miniCalendarRef}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Button
        bgColor={bgColor}
        borderColor="blue.500"
        variant="outline"
        size="sm"
        ml={2}
        onClick={handleNext}
      >
        <Icon as={MdKeyboardArrowRight} cursor="pointer" />
      </Button>
    </Flex>
  );
};
