import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/menu";
import { useCallback } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../store";
import { loadRequestsByStatus } from "../../../store/request-column-view.slice";
import {
  loadRequests,
  setSortDirection,
  setSortField,
} from "../../../store/request-list.slice";

export const RequestSortFilter = () => {
  const bgColor = useColorModeValue("white", "whiteAlpha.300");
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = !isMobileMode && !isTablet ? true : false;
  const { direction: sortDirection, field: sortField } = useSelector(
    (state: RootState) => state.requestList.sort,
  );

  const { displayMode } = useSelector(
    (state: RootState) => state.requestsSlice
  );

  const refreshList = useCallback(() => {
    if (displayMode === "row")
      thunkDispatch(loadRequests({ apiClient, account: currentAccount }));
    if (displayMode === "column")
      thunkDispatch(
        loadRequestsByStatus({
          apiClient,
          accountId: currentAccount.id,
        })
      );
  }, [thunkDispatch, apiClient, currentAccount, displayMode]);

  const handleSortDirectionChange = useCallback(
    (value: string | string[]) => {
      const single = Array.isArray(value) ? value[0] : value;
      const direction = single === "asc" ? "asc" : "desc";
      dispatch(setSortDirection(direction));
      refreshList();
    },
    [dispatch, refreshList],
  );

  const handleSortFieldChange = useCallback(
    (value: string | string[]) => {
      const field = Array.isArray(value) ? value[0] : value;
      dispatch(setSortField(field));
      refreshList();
    },
    [dispatch, refreshList],
  );

  return (
    <Box>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          icon={<BiSortAlt2 />}
          aria-label="Sort"
          bgColor={bgColor}
          colorScheme="blue"
          variant="outline"
          size={isDesktop ? "md" : "sm"}
        />
        <MenuList zIndex="3">
          <MenuOptionGroup
            defaultValue="desc"
            title="Order"
            value={sortDirection}
            onChange={handleSortDirectionChange}
            type="radio"
          >
            <MenuItemOption value="asc">Ascending</MenuItemOption>
            <MenuItemOption value="desc">Descending</MenuItemOption>
          </MenuOptionGroup>
          <MenuDivider />
          <MenuOptionGroup
            title="Field"
            value={sortField}
            onChange={handleSortFieldChange}
            type="radio"
          >
            <MenuItemOption value="created">Created</MenuItemOption>
            <MenuItemOption value="updated">Updated</MenuItemOption>
            <MenuItemOption value="due">Due Date</MenuItemOption>
            <MenuItemOption value="start">Start Date</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
