import { Box, Stack } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { SummarySection } from "./SummarySection";

export const ContentColumn = ({
  request,
  isTablet,
}: {
  request: ApiRequest;
  isTablet?: boolean;
}) => (
  <Stack spacing={2}>
    <Box flex="1">
      <Box w="100%">
        <LocationTwoLine value={request.location} />
      </Box>
    </Box>
    <Box flex="1">
      <SummarySection request={request} />
    </Box>
  </Stack>
);
