import {
  ApiAccountUser,
  ApiClient,
  ApiEventGroupPortalUser,
  ApiPagingEventGroupPortalUserOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface LoadEventGroupPortalUserParams {
  apiClient: ApiClient;
  accountId: string;
}

export const loadEventGroupPortalUsers = createAsyncThunk(
  "portal-user/load-users",
  async (params: LoadEventGroupPortalUserParams, ThunkAPI) => {
    const { apiClient, accountId } = params;
    const { filters } = (ThunkAPI.getState() as RootState)
      .eventGroupPortalUsersSlice;
    const response = apiClient.findPortalUsers(accountId, { ...filters });
    return response;
  }
);

export interface EventGroupPortalUsersSliceProps {
  total: number;
  portalUsers: ApiEventGroupPortalUser[];
  filters: ApiPagingEventGroupPortalUserOptions;
}

export interface UpdateUserRoleThunkParams {
  apiClient: ApiClient;
  accountId: string;
  user: ApiAccountUser;
}
export interface DeactivateUserRoleThunkParams {
  apiClient: ApiClient;
  accountId: string;
  user: ApiAccountUser;
}
export const reactivateUserRole = createAsyncThunk(
  "portal-user/update-user-role",
  async (
    { apiClient, accountId, user }: UpdateUserRoleThunkParams,
    thunkAPI
  ) => {
    const products = [{ name: "PortalHQ", role: "Portal User", enabled: true }];

    const result = await apiClient.updateAccountUser(accountId, user.id, {
      products,
    });
    return { user: result };
  }
);

export const deactivateUserRole = createAsyncThunk(
  "portal-user/deactivate-user-role",
  async (
    { apiClient, accountId, user }: DeactivateUserRoleThunkParams,
    thunkAPI
  ) => {
    await apiClient.deactivateRole(accountId, user.id, "PortalHQ");
    return { user };
  }
);

export const INITIAL_PORTAL_FILTERS: ApiPagingEventGroupPortalUserOptions = {
  page: 1,
  search: "",
  pageSize: 20,
  includeInactive: false,
  eventGroups: [],
  direction: "desc",
};

const INITIAL_VALUES: EventGroupPortalUsersSliceProps = {
  total: 0,
  filters: INITIAL_PORTAL_FILTERS,
  portalUsers: [] as ApiEventGroupPortalUser[],
};

export const eventGroupPortalUsersSlice = createSlice({
  name: "portal-user",
  initialState: INITIAL_VALUES,
  reducers: {
    unloadEventGroupPortalUsersSlice: (state) => {
      state.total = INITIAL_VALUES.total;
      state.filters = INITIAL_VALUES.filters;
      state.portalUsers = INITIAL_VALUES.portalUsers;
    },
    setPortalUsersFilter: (
      state: EventGroupPortalUsersSliceProps,
      action: PayloadAction<ApiPagingEventGroupPortalUserOptions>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadEventGroupPortalUsers.fulfilled, (state, action) => {
      state.portalUsers = action.payload.data;
      state.total = action.payload.total;
    });
    builder.addCase(deactivateUserRole.fulfilled, (state, action) => {
      const { user } = action.payload;
      const index = state.portalUsers.findIndex(
        (portalUs) => portalUs.user.id === user.id
      );
      if (index === -1) return;
      if (!state.filters.includeInactive) {
        state.portalUsers.splice(index, 1);
      } else {
        const productIndex = state.portalUsers[index].user.products.findIndex(
          (x) => x.name === "PortalHQ"
        );
        if (productIndex === -1) return;
        state.portalUsers[index].user.products[productIndex].enabled = false;
      }
    });
    builder.addCase(reactivateUserRole.fulfilled, (state, action) => {
      const { user } = action.payload;
      const index = state.portalUsers.findIndex(
        (portalUs) => portalUs.user.id === user.id
      );
      if (index === -1) return;
      const productIndex = state.portalUsers[index].user.products.findIndex(
        (x) => x.name === "PortalHQ"
      );
      if (productIndex === -1) return;
      state.portalUsers[index].user.products[productIndex].enabled = true;
    });
  },
});

export const { setPortalUsersFilter, unloadEventGroupPortalUsersSlice } =
  eventGroupPortalUsersSlice.actions;

export default eventGroupPortalUsersSlice.reducer;
