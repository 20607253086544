import { Box, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
export const StandardRequest: FC<{
  isMini?: boolean;
  children: React.ReactNode;
  onLoad?: () => void;
}> = ({ children, isMini, onLoad }) => {
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const bgColor = useColorModeValue("white", "gray.600");

  const isCondensed = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isSm = useBreakpointValue({
    base: false,
    sm: true,
    md: false,
  });
  const isLg = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: false,
  });
  const isXl = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: true,
  });

  useEffect(() => {
    onLoad && onLoad();
  }, [onLoad]);

  return (
    <>
      {((isCondensed && !(isMini && isSm)) || (isLg && isMini)) && (
        <Box
          borderColor={borderColor}
          borderWidth={1}
          bg="white"
          borderRadius="md"
        >
          {children}
        </Box>
      )}
      {(isTablet || (isMini && isXl) || (isMini && isSm)) && (
        <Box
          borderColor={borderColor}
          borderWidth={1}
          borderRadius="md"
          p="4"
          bgColor="white"
          py="2"
          backgroundColor={bgColor}
        >
          {children}
        </Box>
      )}
      {!isCondensed && !isTablet && !isMini && (
        <Box
          borderColor={borderColor}
          borderWidth={1}
          borderRadius="md"
          p="4"
          bgColor="white"
          py="2"
          backgroundColor={bgColor}
        >
          {children}
        </Box>
      )}
    </>
  );
};
