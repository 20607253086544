export const DEFAULT_COLOR_CODES = [
  "#F6AD55",
  "#ECC94B",
  "#4FD1C5",
  "#68D391",
  "#63B3ED",
  "#4299E1",
  "#A0AEC0",
  "#E53E3E",
  "#FC8181",
  "#805AD5",
];
