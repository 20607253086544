import { Flex, Text } from "@chakra-ui/react";
import { ApiInventoryItemAdjustment } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { EntryBodyProps } from "./InventoryOrderTimelineBody";

export const AdjustmentEntry: FC<EntryBodyProps> = ({
  entry,
  inventoryOrder,
}) => {
  return (
    <>
      {entry ? (
        <>
          <Flex gap={2}>
            <UserBadge
              textStyle={{
                fontSize: "m",
                marginBottom: "8px",
              }}
              value={entry.createdBy}
              textFontWeight="bold"
              includeAvatar={false}
            />
            <Text>Recieved</Text>
            <Text fontWeight="bold">{inventoryOrder.supplier?.name}</Text>
            <Text>
              {`${inventoryOrder.supplier?.name.replace(/\s/g, "").substring(0, 4).toUpperCase().trim() || ""}-${inventoryOrder.key}`}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <Flex flexDir="column" justifyContent="center">
              <Text fontWeight="bold">
                {
                  (entry.relationObject as ApiInventoryItemAdjustment)?.item
                    ?.name
                }
                {`(x${
                  (entry.relationObject as ApiInventoryItemAdjustment)?.quantity
                }) was Received & Restocked`}
              </Text>
            </Flex>
          </Flex>
          <Text>
            {`Storage Location: ${
              (entry.relationObject as ApiInventoryItemAdjustment).itemLocation
                ?.storageLocation?.name
            }`}
          </Text>
        </>
      ) : null}
    </>
  );
};
