import {
  Divider,
  Flex,
  GridItem,
  Heading,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { RootState, useThunkDispatch } from "../../../store";
import { setRequestAssociationsPage } from "../../../store/request-form/request-form.slice";
import {
  loadRequestAssociations,
  removeRequestAssociation,
} from "../../../store/request-form/thunks/requestAssociations.thunk";
import { StandardRequest } from "../../requests/containers/StandardRequest";
import { RequestRow } from "../../requests/RequestRow";
import { RemoveAssociationButton } from "./RemoveAssociationButton";

type AssociationObject = { [key: string]: string };

export const associationTypesAsText: AssociationObject = {
  clones: "Clones",
  requires: "Requires",
  duplicates: "Duplicates",
  clonedBy: "Cloned By",
  requiredBy: "Required By",
  duplicatedBy: "Duplicated By",
};

export const RequestAssociationList: FC = () => {
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();
  const breakPoints = useBreakpointValue({
    base: true,
    xs: true,
    sm: false,
    md: false,
    lg: true,
    xl: false,
  });

  const { requestAssociations, request } = useSelector(
    (state: RootState) => state.requestForm
  );

  const { data, page, pageSize, total } = requestAssociations;

  const screenModes = useScreenBreakpoints();

  const dispatch = useDispatch();
  const showToast = useShowToast();

  const handleOnPageChange = useCallback(
    (page: number) => {
      dispatch(setRequestAssociationsPage(page));
    },
    [dispatch]
  );

  const handleDeleteAssociation = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, associationId: string) => {
      if (!request) return;
      e.preventDefault();
      e.stopPropagation();
      thunkDispatch(
        removeRequestAssociation({
          apiClient,
          account: currentAccount,
          requestId: request.id,
          associationId,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Association was removed successfully");
        });
    },
    [apiClient, currentAccount, request, showToast, thunkDispatch]
  );

  useEffect(() => {
    if (!request) return;
    thunkDispatch(
      loadRequestAssociations({
        apiClient,
        account: currentAccount,
        requestId: request.id,
      })
    );
  }, [apiClient, currentAccount, request, thunkDispatch, page]);

  return data.length > 0 ? (
    <GridItem colSpan={12}>
      <Flex flexDir="column" gap={4}>
        <Divider />
        <Heading size="md">Associations</Heading>
        {data.map((association) => (
          <Link
            w="100%"
            as={RouterLink}
            key={association.id}
            _hover={{ textDecoration: "none" }}
            to={`/requests/${association.request.key}`}
          >
            <Text fontWeight="bold" pb={1.5}>
              {associationTypesAsText[association.type]}
            </Text>
            <StandardRequest>
              <RequestRow
                isMini={breakPoints}
                request={association.request}
                screenMode={screenModes}
                CustomActionElement={
                  <RemoveAssociationButton
                    associationId={association.id}
                    handleDeleteAssociation={handleDeleteAssociation}
                  />
                }
              />
            </StandardRequest>
          </Link>
        ))}
        {total > pageSize && page && pageSize && (
          <Pager
            total={total}
            currentPage={page}
            pageSize={pageSize}
            onPageChange={handleOnPageChange}
          />
        )}
        <Divider />
      </Flex>
    </GridItem>
  ) : null;
};
