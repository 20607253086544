import {
  Box,
  FormControl,
  FormLabel,
  FormLabelProps,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Editor } from "@operations-hero/react-draft-wysiwyg";
import "@operations-hero/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import { draftToMarkdown } from "markdown-draft-js";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../../store";
import { loadUsers, unload } from "../../../store/account-users.slice";
import { debounce } from "../../../utils/debounce";
import { useAuthentication } from "../../auth/AuthProvider";
import { UserMentionBadge } from "../../badges/UserMentionBadge";
import "./rich-text-editor-styles.css";
import { editorToolBar } from "./RichTextEditorControl";

interface RichTextEditorCommentsProps {
  id: string;
  label?: string;
  value: EditorState | undefined;
  name?: string;
  initialValue?: string | null;
  maxCharacters?: string;
  isInvalid?: boolean;
  invalidMessage?: string;
  setParentState?: (value: EditorState) => void;
  onBlur?: (value: EditorState) => void;
  labelProps?: FormLabelProps;
  autosize?: boolean;
}

export const getEditorValueAsString = (newContent: EditorState) => {
  let valueAsMarkDownFormat = "";
  if (newContent) {
    valueAsMarkDownFormat = draftToMarkdown(
      convertToRaw(newContent?.getCurrentContent())
    );
    return valueAsMarkDownFormat;
  }
};

export const RichTextEditorComments: React.FC<RichTextEditorCommentsProps> = ({
  id,
  label,
  value,
  setParentState,
  onBlur,
  isInvalid,
  maxCharacters,
  invalidMessage,
  labelProps,
  autosize = false,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const themeClass = useColorModeValue(
    "light-theme-rich-text-editor",
    "dark-theme-rich-text-editor"
  );
  const errorColor = useColorModeValue("#E53E3E", "#FC8181");
  const borderColor = useColorModeValue("#E2E8F0", "#4A5568");
  const focusColor = useColorModeValue("#3182CE", "#63B3ED");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { users: userList } = useSelector(
    (state: RootState) => state.accountUsers
  );
  const [content, setContent] = useState<EditorState>();
  const [search, setSearch] = useState("");
  const [users, setSuggestions] = useState<object[]>([]);
  const [textVerification, setTextVerification] = useState(false);
  const thunkDispatch = useThunkDispatch();

  const wrapperStyle = useCallback(
    () => ({
      borderColor: isOpen ? "transparent" : borderColor,
      boxShadow: isOpen
        ? `0px 0px 0px 2px ${focusColor}`
        : isInvalid
        ? `0px 0px 0px 2px ${errorColor}`
        : "0px 0px 0px 2px transparent",
    }),
    [isOpen, borderColor, focusColor, isInvalid, errorColor]
  );

  const editorStyle: CSSProperties = useMemo(() => {
    return {
      /**110px to keep the same value as the css file */
      maxHeight: autosize ? "max-content" : "110px",
    };
  }, [autosize]);

  const findSearch = useCallback((text: string, position: number) => {
    const splitText = text.split("", position).reverse().join("");
    const result = splitText
      .slice(0, splitText.indexOf("@"))
      .split("")
      .reverse()
      .join("")
      .trim();
    if (
      !/\s/.test(result) &&
      splitText.includes("@") &&
      ((splitText.slice(-1) === "@" && splitText.slice(-2) !== "@@") ||
        splitText.slice(0, splitText.indexOf("@") + 2).slice(-1) === " ")
    ) {
      setSearch(result);
      setTextVerification(true);
      return;
    }
    setTextVerification(false);
  }, []);

  const debounceSearch = debounce(findSearch, 300);

  const handleOnChange = useCallback(
    (newContent: EditorState) => {
      if (content !== newContent) {
        setContent(newContent);
      }
      var selectionState = newContent.getSelection();
      var anchorKey = selectionState.getAnchorKey();
      var currentContent = newContent.getCurrentContent();
      var currentContentBlock = currentContent.getBlockForKey(anchorKey);
      var end = selectionState.getEndOffset();
      setParentState && setParentState(newContent);
      debounceSearch(currentContentBlock.getText(), end);
    },
    [content, debounceSearch, setParentState]
  );

  const handleOnBlur = useCallback(() => {
    onClose();
    if (content) {
      onBlur && onBlur(content);
    }
    setSearch("");
  }, [content, onBlur, onClose]);

  const loadUsersStore = useCallback(() => {
    thunkDispatch(
      loadUsers({
        apiClient,
        account: currentAccount,
        includeInactive: false,
        search: search === "" ? undefined : search,
        noIncludeEmail: true,
      })
    );
  }, [apiClient, currentAccount, thunkDispatch, search]);

  /* It forces the dropdown to render since there is a sync issue 
    with the trigger on the mention wysiwyg editor*/
  const forceRender = useCallback(() => {
    if (content === undefined) return;
    var editorState = content;
    var currentContent = editorState.getCurrentContent();
    var newEditorState = EditorState.createWithContent(currentContent);
    const newEditorStateWithSelection = EditorState.forceSelection(
      newEditorState,
      content.getSelection()
    );
    setContent(newEditorStateWithSelection);
  }, [content]);

  const loadUsersList = useCallback(() => {
    const newUsers: any[] = [];
    userList.forEach((res) => {
      const objSuggestion = {
        text: <UserMentionBadge value={res} />,
        value: `${res.firstName} ${res.lastName}`,
        url: res.id,
      };
      newUsers.push(objSuggestion);
    });
    setSuggestions(newUsers);
    if (textVerification) forceRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textVerification, userList]);

  useEffect(() => {
    setContent(value);
  }, [value]);

  useEffect(() => {
    loadUsersStore();
  }, [loadUsersStore]);

  useEffect(() => {
    loadUsersList();
  }, [loadUsersList]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch]);

  return (
    <FormControl>
      {label && (
        <FormLabel id={id} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <Box className={themeClass}>
        <Editor
          onFocus={onOpen}
          editorState={content}
          onBlur={handleOnBlur}
          toolbar={editorToolBar}
          wrapperStyle={wrapperStyle()}
          editorStyle={editorStyle}
          onEditorStateChange={handleOnChange}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: users,
          }}
          spellCheck={true}
        />
      </Box>
      {invalidMessage && (
        <Text mt={1} fontSize="sm" color={errorColor}>
          {invalidMessage}
        </Text>
      )}
      {maxCharacters && (
        <Text fontSize="sm" color="gray.500">
          {maxCharacters} character max
        </Text>
      )}
    </FormControl>
  );
};
