import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { IconType } from "react-icons";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { RootState } from "../../../store";
import {
  MenuSection,
  setCurrentMenuSection,
} from "../../../store/local-cache.slice";
import { useAuthentication } from "../../auth/AuthProvider";
import { useProductSubscriptions } from "../AppShell";
import {
  accountsMenu,
  eventsHQMenu,
  inventoryHQMenu,
  requestHQMenu,
} from "./menuOptions";

export interface ProductMenuProps {
  openExpanded?: boolean;
  onClose?: () => void;
}

export interface MenuType {
  route: string;
  text: string;
  icon: IconType;
}

export const ProductMenu: React.FC<ProductMenuProps> = ({
  openExpanded,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { isProductAdmin, isEventAdmin, isInventoryAdmin } =
    useAuthentication();
  const { hasEvents, hasRequests, hasInventory, hasPlanning } =
    useProductSubscriptions();
  const bgColor = useColorModeValue("#edf2f7", "#ffffff1a");

  const { enableInvoicesForEvents, isEnablePublicPortal } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const { currentMenuSection } = useSelector(
    (state: RootState) => state.localCache
  );

  const handleSetCurrentSection = useCallback(
    (section: MenuSection, isSubmenu: boolean = false) => {
      const value =
        section === currentMenuSection && !isSubmenu ? undefined : section;
      dispatch(setCurrentMenuSection({ section: value }));
      isSubmenu && onClose && onClose();
    },
    [currentMenuSection, dispatch, onClose]
  );

  const memoizedEventsMenu = useMemo(() => {
    const eventsCopy = [...eventsHQMenu];
    if (enableInvoicesForEvents) {
      eventsCopy.push(
        {
          route: "/account/rate-sheet",
          text: "Rate Sheet",
        },
        {
          route: "/account/invoice-list",
          text: "Invoices",
        },
        {
          route: "/account/payments-list",
          text: "Payments",
        }
      );
    }
    if (isEnablePublicPortal) {
      eventsCopy.push({
        route: "/account/portal-users",
        text: "Portal Users",
      });
    }
    return eventsCopy;
  }, [enableInvoicesForEvents, isEnablePublicPortal]);

  const memoizedHeroHQMenu = useMemo(() => {
    const menuOptions = [...requestHQMenu];
    if (!hasPlanning) {
      menuOptions.splice(4, 0, {
        route: generatePath("/planning/:section", { section: "projects" }),
        text: "Projects",
      });
    }

    return menuOptions;
  }, [hasPlanning]);

  const { indexRequests, indexEvents, indexInventory } = useMemo(() => {
    let indexRequests = 0;
    let indexEvents = 0;
    let indexInventory = 0;

    if (hasRequests && isProductAdmin) {
      indexRequests++;
      indexEvents++;
      indexInventory++;
    }

    if (hasEvents && isEventAdmin) {
      indexEvents++;
      indexInventory++;
    }

    if (hasInventory && isInventoryAdmin) {
      indexInventory++;
    }
    return {
      indexRequests,
      indexEvents,
      indexInventory,
    };
  }, [
    hasEvents,
    hasInventory,
    hasRequests,
    isEventAdmin,
    isInventoryAdmin,
    isProductAdmin,
  ]);

  const accordionIndex = useMemo(() => {
    if (!currentMenuSection) return -1;

    switch (currentMenuSection) {
      case "account":
        return 0;
      case "requests":
        return indexRequests;
      case "events":
        return indexEvents;
      case "inventory":
        return indexInventory;
      default:
        return -1;
    }
  }, [currentMenuSection, indexRequests, indexEvents, indexInventory]);

  return (
    <>
      <Accordion
        allowToggle
        allowMultiple={false}
        index={accordionIndex}
        border="0px solid transparent"
      >
        {(isProductAdmin || isEventAdmin || isInventoryAdmin) && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display={"flex"}
                  fontWeight="bold"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ bgColor: bgColor }}
                  onClick={() => handleSetCurrentSection("account")}
                >
                  <Text>Account</Text>
                  {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
                </AccordionButton>

                {isExpanded && (
                  <AccordionPanel p="6px 0px">
                    <List
                      w={"100%"}
                      borderRadius={"0"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      gap={0}
                    >
                      {accountsMenu.map((item) => (
                        <ListItem
                          key={item.route}
                          p="8px 16px"
                          _hover={{ bgColor: bgColor }}
                          as={RouterLink}
                          to={item.route}
                          onClick={() =>
                            handleSetCurrentSection("account", true)
                          }
                        >
                          {item.text}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        )}
        {hasRequests && isProductAdmin && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display={"flex"}
                  fontWeight="bold"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ bgColor: bgColor }}
                  onClick={() => handleSetCurrentSection("requests")}
                >
                  <Text>HeroHQ</Text>
                  {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
                </AccordionButton>

                {isExpanded && (
                  <AccordionPanel p="6px 0px">
                    <List
                      w={"full"}
                      borderRadius={"0"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      gap={0}
                    >
                      {memoizedHeroHQMenu.map((item) => (
                        <ListItem
                          key={item.route}
                          p="8px 16px"
                          _hover={{ bgColor: bgColor }}
                          as={RouterLink}
                          to={item.route}
                          onClick={() =>
                            handleSetCurrentSection("requests", true)
                          }
                        >
                          <Box>{item.text}</Box>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        )}
        {hasEvents && isEventAdmin && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display={"flex"}
                  fontWeight="bold"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ bgColor: bgColor }}
                  onClick={() => handleSetCurrentSection("events")}
                >
                  <Text>EventsHQ</Text>
                  {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
                </AccordionButton>
                {isExpanded && (
                  <AccordionPanel p="6px 0px">
                    <List
                      w={"full"}
                      borderRadius={"0"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      gap={0}
                    >
                      {memoizedEventsMenu.map((item) => (
                        <ListItem
                          key={item.route}
                          p="8px 16px"
                          _hover={{ bgColor: bgColor }}
                          as={RouterLink}
                          to={item.route}
                          onClick={() =>
                            handleSetCurrentSection("events", true)
                          }
                        >
                          {item.text}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        )}

        {hasInventory && isInventoryAdmin && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display="flex"
                  fontWeight="bold"
                  justifyContent="space-between"
                  alignItems="center"
                  _hover={{ bgColor: bgColor }}
                  onClick={() => handleSetCurrentSection("inventory")}
                >
                  <Text>InventoryHQ</Text>
                  {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
                </AccordionButton>
                {isExpanded && (
                  <AccordionPanel p="6px 0px">
                    <List
                      w="full"
                      borderRadius={0}
                      display="flex"
                      flexDirection="column"
                      justifyContent="start"
                      gap={0}
                    >
                      {inventoryHQMenu.map((item) => (
                        <ListItem
                          key={item.route}
                          p="8px 16px"
                          _hover={{ bgColor: bgColor }}
                          as={RouterLink}
                          to={item.route}
                          onClick={() =>
                            handleSetCurrentSection("inventory", true)
                          }
                        >
                          {item.text}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        )}
      </Accordion>
    </>
  );
};
