import {
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { FC } from "react";

interface CronNumberInputProps {
  name: string | undefined;
  min: number;
  max?: number;
  label: string;
  maxW?: string;
  value: number | null;
  step?: number;
  defaultValue?: number;
  onChange: (value: number, name?: string | undefined) => void;
  mr?: number;
  children: React.ReactNode;
}

export const CronNumberInput: FC<CronNumberInputProps> = ({
  min,
  max,
  name,
  label,
  step = 1,
  onChange,
  maxW,
  value,
  children,
  mr = {},
}) => {
  return (
    <>
      <FormLabel minW="99%">{label}</FormLabel>
      <NumberInput
        min={min}
        max={max}
        step={step}
        onChange={(value) => onChange(+value, name)}
        value={value || 0}
        maxW={maxW ? maxW : "auto"}
        mr={mr || 0}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {children}
    </>
  );
};
