import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { FC } from "react";
import { BaseTimelineItem } from "./TimelineItem.md";
import { TimelineItemProps } from "./types";
import { useRequestColorMap } from "./useRequestColorMap";

type LineTimelineItemProps = TimelineItemProps;

export const LineTimelineItem: FC<LineTimelineItemProps> = ({ request }) => {
  const { getColor } = useRequestColorMap(request.priority);
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Box
          width="full"
          backgroundColor={getColor(2)}
          w="full"
          height={"5rem"}
          borderRadius={"md"}
        ></Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <BaseTimelineItem request={request} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
