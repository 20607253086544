import FullCalendar, {
  DayHeaderContentArg,
  EventContentArg,
  EventInput,
  ToolbarInput,
} from "@fullcalendar/react"; // isort: skip
import {
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { addMonths, format } from "date-fns";
import { Dispatch, FC, useCallback, useEffect, useMemo, useRef } from "react";
import {
  MdBlockFlipped,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import "./calendar.datepicker.style.css";

const DATE_FORMAT = "yyyy-MMM-dd";
const today = new Date();
const todayCopy = new Date(today).setDate(1);
const nextMonth = addMonths(todayCopy, 1);

interface CalendarDatePickerProps {
  values: EventInput[];
  setValues: Dispatch<React.SetStateAction<EventInput[]>>;
  blockedDates: string[];
}

export const CalendarDatePicker: FC<CalendarDatePickerProps> = ({
  values,
  setValues,
  blockedDates,
}) => {
  const firstCalendarRef = useRef<FullCalendar | null>(null);
  const secondCalendarRef = useRef<FullCalendar | null>(null);

  const calendarButtonBgColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
  });

  const miniCalenderToolbar = useMemo<ToolbarInput>(
    () => ({
      start: "title",
      center: "",
      end: "",
    }),
    []
  );

  const miniCalendarDayHeaderContent = useCallback(
    (hookProps: DayHeaderContentArg) => {
      const { dow } = hookProps;
      if (dow === 0) return "S";
      if (dow === 1) return "M";
      if (dow === 2) return "T";
      if (dow === 3) return "W";
      if (dow === 4) return "T";
      if (dow === 5) return "F";
      if (dow === 6) return "S";
      return hookProps.dow;
    },
    []
  );

  const handleAddDate = useCallback(
    (arg: DateClickArg) => {
      const { date } = arg;
      const id = format(date, DATE_FORMAT);

      const selectedDatesCopy = [...values];
      const index = values.findIndex((e) => e.id === id);
      if (index !== -1) return;

      const newEvent = {
        id: id,
        title: "",
        start: date,
        end: date,
      };
      selectedDatesCopy.push(newEvent);
      setValues(selectedDatesCopy);
    },
    [setValues, values]
  );

  const handlePrevious = useCallback(() => {
    if (!firstCalendarRef.current || !secondCalendarRef.current) return;

    const firstCalendarApi = firstCalendarRef.current.getApi();
    const secondCalendarApi = secondCalendarRef.current.getApi();

    // 2 times because we have to navigate every two months
    firstCalendarApi.prev();
    firstCalendarApi.prev();

    secondCalendarApi.prev();
    secondCalendarApi.prev();
  }, []);

  const handleOnNext = useCallback(() => {
    if (!firstCalendarRef.current || !secondCalendarRef.current) return;

    const firstCalendarApi = firstCalendarRef.current.getApi();
    const secondCalendarApi = secondCalendarRef.current.getApi();

    firstCalendarApi.next();
    firstCalendarApi.next();

    secondCalendarApi.next();
    secondCalendarApi.next();
  }, []);

  useEffect(() => {
    if (blockedDates.length === 0) return;
    let items: EventInput[] = [];
    blockedDates.forEach((d, index) => {
      const newEvent = {
        id: `blockedDate::${index}`,
        title: "",
        start: d,
        end: d,
        isBlocked: true,
      };
      items.push(newEvent as unknown as EventInput);
    });
    setValues(items);
  }, [blockedDates, setValues]);

  return (
    <Flex flexDir="column" gap={2}>
      <Flex justifyContent="space-between">
        <Text>Pick your dates</Text>
        <Flex
          gap={2}
          minW="60px"
          height="30px"
          borderRadius={4}
          bgColor={calendarButtonBgColor}
          alignItems="center"
          justifyContent="space-between"
        >
          <Icon
            as={MdKeyboardArrowLeft}
            ml={1}
            boxSize="20px"
            cursor="pointer"
            onClick={handlePrevious}
          />
          <Icon
            as={MdKeyboardArrowRight}
            mr={1}
            boxSize="20px"
            cursor="pointer"
            onClick={handleOnNext}
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex wrap="wrap" flexDir={["column", "row", "row"]}>
        <Box
          minW="280px"
          className="calendar-datepicker"
          w={["100%", "calc(50% - 2%)", "calc(50% - 2%)"]}
        >
          <FullCalendar
            plugins={[interactionPlugin, dayGridPlugin]}
            initialView="dayGridMonth"
            events={values}
            ref={firstCalendarRef}
            headerToolbar={miniCalenderToolbar}
            dayHeaderContent={miniCalendarDayHeaderContent}
            height="300px"
            dateClick={handleAddDate}
            eventContent={(args: EventContentArg) => (
              <EventContent
                args={args}
                blockedDates={blockedDates}
                selectedDates={values}
                setSelectedDates={setValues}
              />
            )}
            showNonCurrentDates={false}
            schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE}
          />
        </Box>

        <Divider
          w={["100%", "2%", "2%"]}
          h={isMobile ? "auto" : "300px"}
          orientation={isMobile ? "horizontal" : "vertical"}
        />

        <Box
          mt={[2, 2, 0]}
          className="calendar-datepicker"
          minW="280px"
          w={["100%", "calc(50% - 2%)", "calc(50% - 2%)"]}
        >
          <FullCalendar
            plugins={[interactionPlugin, dayGridPlugin]}
            initialView="dayGridMonth"
            initialDate={nextMonth}
            events={values}
            ref={secondCalendarRef}
            headerToolbar={miniCalenderToolbar}
            dayHeaderContent={miniCalendarDayHeaderContent}
            height="300px"
            dateClick={handleAddDate}
            eventContent={(args: EventContentArg) => (
              <EventContent
                args={args}
                blockedDates={blockedDates}
                selectedDates={values}
                setSelectedDates={setValues}
              />
            )}
            showNonCurrentDates={false}
            schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE}
          />
        </Box>
      </Flex>
      <Divider />
    </Flex>
  );
};

interface EventContentProps {
  args: EventContentArg;
  selectedDates: EventInput[];
  blockedDates: string[];
  setSelectedDates: Dispatch<React.SetStateAction<EventInput[]>>;
}
const EventContent: FC<EventContentProps> = ({
  args,
  selectedDates,
  setSelectedDates,
  blockedDates,
}) => {
  const dateBgColor = useColorModeValue("blue.500", "blue.300");

  const day = args.event.start?.getDate();
  const id = args.event.start ? format(args.event.start, DATE_FORMAT) : "";
  const isBlocked = "isBlocked" in args.event._def.extendedProps;

  const handleRemoveDate = useCallback(() => {
    const selectedDatesCopy = [...selectedDates];
    const index = selectedDates.findIndex((e) => e.id === id);
    if (index !== -1) {
      selectedDatesCopy.splice(index, 1);
      setSelectedDates(selectedDatesCopy);
    }
  }, [id, selectedDates, setSelectedDates]);

  return (
    <>
      {isBlocked ? (
        <Flex
          w="30px"
          h="30px"
          top="-29px"
          left="7px"
          borderRadius="50%"
          position="absolute"
          bgColor="white"
          color="red.500"
          justifyContent="center"
          onClick={handleRemoveDate}
        >
          <Icon as={MdBlockFlipped} boxSize="24px" />
        </Flex>
      ) : (
        <Flex
          w="28px"
          h="28px"
          top="-30px"
          left="7px"
          cursor="pointer"
          borderRadius="50%"
          position="absolute"
          bgColor={dateBgColor}
          justifyContent="center"
          onClick={handleRemoveDate}
        >
          <Text color="white" mt="4px">
            {day}
          </Text>
        </Flex>
      )}
    </>
  );
};
