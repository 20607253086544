import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiWorkflow } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useMemo } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { RootState } from "../../../store";

export interface WorkflowFilterProps {
  value: (ApiWorkflow | string)[];
  onChange: (value: ApiWorkflow[]) => void;
  optionsIds?: string[];
}

const WorkflowBadge = ({ workflow }: { workflow: ApiWorkflow }) => {
  return (
    <Text fontWeight="extrabold" as="span" display="inline-block" mr={2}>
      {workflow.requestSlug}
    </Text>
  );
};

const ContentRenderer = ({ props, state }: SelectRenderer<ApiWorkflow>) => {
  return (
    <Box p={1}>
      {state.values.length === 0 && "Workflow"}
      {state.values.length === 1 && (
        <WorkflowBadge workflow={state.values[0]} />
      )}
      {state.values.length > 1 && (
        <>
          <WorkflowBadge workflow={state.values[0]} />
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiWorkflow>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["name", "requestSlug"], {
        sort: true,
      }),
    [props.options],
  );

  const items = useMemo(() => {
    return !state.search ? props.options : fuzzySearch.search(state.search);
  }, [state.search, props.options, fuzzySearch]);

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button size="sm" variant="outline" onClick={methods.clearAll}>
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Workflows"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.id}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <WorkflowBadge workflow={item} />
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const WorkflowFilter = ({
  value,
  onChange,
  optionsIds,
}: WorkflowFilterProps) => {
  const { workflows, workflowMap } = useSelector(
    (state: RootState) => state.localCache,
  );
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const options = useMemo(() => {
    if (!optionsIds) return workflows;
    return workflows.filter((wf) => optionsIds.some((item) => item === wf.id));
  }, [optionsIds, workflows]);

  return (
    <Select
      multi
      className={themeClass}
      options={options}
      values={value.map((v) => (typeof v === "string" ? workflowMap[v] : v))}
      onChange={(values) => onChange(values)}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      //clearable
      contentRenderer={ContentRenderer}
      dropdownRenderer={DropdownRenderer}
      dropdownHeight="300"
    />
  );
};
