import { ApiRequest } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { ReadOnlyTextEditorComments } from "../../../components/form-helpers/rich-text-editor/CommentsTextEditorReadOnly";
import { useConvertMentions } from "../../../hooks/useConvertMentions";

export const SummarySection = ({ request }: { request: ApiRequest }) => {
  const { editorState: summary } = useConvertMentions({
    value: request?.summary,
    mentioned: request?.mentioned,
  });

  const memoizedTextEditor = useMemo(() => {
    if (!summary) return null;
    return <ReadOnlyTextEditorComments value={summary} />;
  }, [summary]);

  return <>{memoizedTextEditor}</>;
};
