import {
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Square,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
  formatToFullHexColorCode,
  formatToShortHexColorCode,
  isHexColorCodeFull,
  isHexColorCodeShort,
} from "./color-code-utils";
import { DEFAULT_COLOR_CODES } from "./defaults";
import { ColorSelection } from "./types";

const fallbackPaletteColor: ColorSelection = {
  from: "color-palette",
  value: "#FFFFFF",
} as const;

const getOptions = (options?: string[]) => {
  if (!options || options.length === 0) return DEFAULT_COLOR_CODES;
  return options;
};

const getDefaultColorSelection = (options: string[]): ColorSelection => {
  if (options.length > 0) return { from: "color-palette", value: options[0] };
  return fallbackPaletteColor;
};

const getValueAsColorSelection = (
  value: string,
  options: string[]
): ColorSelection => {
  if (options.includes(value)) {
    return { from: "color-palette", value: value };
  } else {
    return { from: "input", value: value };
  }
};

type ColorPickerInputArgs = {
  value?: string;
  formatted?: string;
  isValid?: boolean;
};

export type ColorPickerEventArgs = {
  selectedColor: string;
  paletteValue: string;
  inputValue: ColorPickerInputArgs;
};

type ColorPickerProps = {
  value?: string | null;
  options?: string[];
  onColorSelected?: (args: ColorPickerEventArgs) => void;
  isDisabled?: boolean;
};

export const ColorPicker: FC<ColorPickerProps> = ({
  value,
  options: customOptions,
  onColorSelected,
  isDisabled = false,
}) => {
  const [options] = useState(getOptions(customOptions));

  const [inputValue, setInputValue] = useState<null | string>(null);
  const [paletteValue, setPaletteValue] = useState<string | null>(null);

  const [selectedColor, setSelectedColor] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);
  const popoverContentRef = useRef<HTMLDivElement | null>(null);

  const defaultOrFallbackColorSelection = getDefaultColorSelection(options);

  const handleOnInputChange = useCallback(
    (input: string) => {
      setInputValue(input);

      if (!input) {
        setSelectedColor(defaultOrFallbackColorSelection.value);
        setPaletteValue(defaultOrFallbackColorSelection.value);
      }
      setPaletteValue(null);

      if (input[0] === "#") {
        //TODO: check if input value is in the palette
        const isInputValid = isHexColorCodeFull(input);
        if (isInputValid) {
          setSelectedColor(input);
        } else {
          setSelectedColor(defaultOrFallbackColorSelection.value);
        }
      } else {
        const isInputValid = isHexColorCodeShort(input);
        if (isInputValid) {
          setSelectedColor(formatToFullHexColorCode(input));
        } else {
          setSelectedColor(defaultOrFallbackColorSelection.value);
        }
      }
    },
    [defaultOrFallbackColorSelection.value]
  );

  const handleColorPaletteSelectionChange = useCallback(
    (colorSelection: string) => {
      setSelectedColor(colorSelection);

      setPaletteValue(colorSelection);
      setInputValue("");
    },
    []
  );

  const getSelectedStyles = useCallback(
    (option: string) => {
      if (option === selectedColor) {
        return {
          boxShadow: `0 0 7px ${option}`,
        };
      } else return {};
    },
    [selectedColor]
  );

  const initialize = useCallback(() => {
    if (!value) {
      const defaultColor = getDefaultColorSelection(options);
      setSelectedColor(defaultColor.value);
      return;
    }

    const isValueFullHexCode = isHexColorCodeFull(value);
    const isValueShortHexCode = isHexColorCodeShort(value);

    let valueToCheck = "";
    if (isValueFullHexCode) {
      valueToCheck = value;
    }

    if (isValueShortHexCode) {
      valueToCheck = formatToFullHexColorCode(value);
    }

    const valueAsInitialSelection = getValueAsColorSelection(value, options);
    if (valueAsInitialSelection.from === "input") {
      setInputValue(formatToShortHexColorCode(valueToCheck));
      setPaletteValue(null);
    } else {
      setInputValue("");
      setPaletteValue(valueAsInitialSelection.value);
    }

    setSelectedColor(valueAsInitialSelection.value);
  }, [value, options]);

  const handleOnBlur = useCallback(() => {
    const inputFullFormatted = formatToFullHexColorCode(inputValue ?? "");
    if (onColorSelected) {
      onColorSelected({
        selectedColor,
        inputValue: {
          value: inputValue ?? "",
          isValid: isHexColorCodeFull(inputFullFormatted),
          formatted: inputFullFormatted,
        },
        paletteValue: paletteValue ?? "",
      });
    }
  }, [inputValue, selectedColor, paletteValue, onColorSelected]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Popover placement="bottom-end" initialFocusRef={inputRef}>
      {({ isOpen, onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <Button
                rightIcon={<MdOutlineKeyboardArrowDown />}
                variant="outline"
                px={1}
                w="full"
                isDisabled={isDisabled}
              >
                <Square
                  size="30px"
                  bg={selectedColor}
                  rounded="md"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="blue.800"
                ></Square>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              ref={popoverContentRef}
              className="color-picker-popover-content"
              w={"18rem"}
              onBlur={(e) => {
                if (e.relatedTarget === popoverContentRef.current) return;
                handleOnBlur();
              }}
              // This styling is required due to a bug related to framer-motion versions < 11.7
              // Check the closed issue: https://github.com/chakra-ui/chakra-ui/issues/8555
              variants={{
                enter: {
                  transition: {
                    visibility: {
                      duration: 0,
                    },
                  },
                },
              }}
            >
              <PopoverArrow />
              <PopoverBody>
                <Grid templateColumns={"repeat(7, 1fr)"} gap={2}>
                  {options &&
                    options.map((opt) => {
                      return (
                        <GridItem
                          justifyItems={"center"}
                          key={`color-code::${opt}`}
                        >
                          <Square
                            rounded="md"
                            size="30px"
                            bg={`${opt}`}
                            color="white"
                            cursor={"pointer"}
                            onClick={() => {
                              handleColorPaletteSelectionChange(opt);
                              onClose();
                            }}
                            {...getSelectedStyles(opt)}
                          ></Square>
                        </GridItem>
                      );
                    })}
                  <GridItem colSpan={4}>
                    <InputGroup size={"sm"}>
                      <InputLeftAddon children="#" />
                      <Input
                        ref={inputRef}
                        placeholder="FFFFFF"
                        onChange={(e) => handleOnInputChange(e.target.value)}
                        value={inputValue ?? ""}
                      />
                    </InputGroup>
                  </GridItem>
                </Grid>
              </PopoverBody>
            </PopoverContent>
          </>
        );
      }}
    </Popover>
  );
};
