import {
  Box,
  Button,
  Checkbox,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiProjectStatus } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { AdditionalNumberBadge } from "../../../../components/badges/AdditionalNumberBadge";
import { StatusBadge } from "../../../../components/badges/StatusBadge";

export interface ProjectStatusFilterProps {
  value: (ApiProjectStatus | string)[];
  onChange?: (value: ApiProjectStatus[]) => void;
  showSelectAll?: boolean;
  status: ApiProjectStatus[];
  isDisabled?: boolean;
}

type ValueLabel = {
  value: ApiProjectStatus;
  label: ApiProjectStatus;
};

const DropdownRenderer = ({
  props,
  methods,
  showSelectAll,
}: SelectRenderer<ValueLabel> & { showSelectAll?: boolean }) => {
  const bgColor = useColorModeValue(undefined, "gray.700");
  return (
    <Box p={2} backgroundColor={bgColor}>
      <Box pb={1}>
        {showSelectAll && (
          <HStack justifyContent="space-between" pb={2}>
            <Box>Select:</Box>
            {methods.areAllSelected() ? (
              <Button size="sm" variant="outline" onClick={methods.clearAll}>
                Clear all
              </Button>
            ) : (
              <Button size="sm" onClick={() => methods.selectAll()}>
                Select all
              </Button>
            )}
          </HStack>
        )}
      </Box>
      <Box>
        {props.options.map((item) => {
          return (
            <Checkbox
              key={item.label}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <StatusBadge status={item.value} />
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

const ContentRenderer = ({ props, state }: SelectRenderer<ValueLabel>) => {
  return (
    <Box p={1} flexDir="row" w="fit-content" alignItems="center" display="flex">
      {state.values.length === 0 && "Status"}
      {state.values.length === 1 && (
        <StatusBadge status={state.values[0].value} />
      )}
      {state.values.length > 1 && (
        <Box alignItems="center" display="flex" gap={2}>
          <StatusBadge status={state.values[0].value} />
          <AdditionalNumberBadge number={state.values.length - 1} />
        </Box>
      )}
    </Box>
  );
};

export const ProjectStatusFilter = ({
  value,
  onChange,
  showSelectAll,
  status,
  isDisabled = false,
}: ProjectStatusFilterProps) => {
  const options = useMemo(
    () =>
      status.map<ValueLabel>((x) => ({
        value: x,
        label: x,
      })),

    [status]
  );
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const internalValues = useMemo(() => {
    return value && value.length > 0
      ? options.filter((x) => value.includes(x.value))
      : [];
  }, [value, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      if (!onChange) return;
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange]
  );

  return (
    <Select
      multi
      options={options}
      values={internalValues}
      onChange={handleChange}
      className={themeClass}
      searchable={true}
      searchBy="value"
      valueField="value"
      labelField="label"
      keepSelectedInList
      contentRenderer={ContentRenderer}
      dropdownRenderer={(props) => (
        <DropdownRenderer {...props} showSelectAll={showSelectAll} />
      )}
      disabled={isDisabled}
    />
  );
};
