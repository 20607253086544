import { ApiWorkflowField } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";
import { CustomFieldInput } from "../custom-field-input/CustomFieldInput";

export interface CustomFieldInputControlProps {
  field: ApiWorkflowField;
  name: string;
  value?: any;
  placeholder?: string;
  isDisabled?: boolean;
  showErrorOnValidation?: boolean;
}

export const CustomFieldInputControl = ({
  field: apiField,
  name,
  value,
  placeholder,
  showErrorOnValidation = false,
}: CustomFieldInputControlProps) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({
    name,
    value: value === null ? undefined : value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (newValue: any) => {
      helper.setTouched(true);
      helper.setValue(newValue || null);
    },
    [helper]
  );

  const isInvalid = useMemo(() => {
    if (showErrorOnValidation) return !!meta.error;
    return !!meta.error && (meta.touched || submitCount > 0);
  }, [meta.error, meta.touched, showErrorOnValidation, submitCount]);

  return (
    <CustomFieldInput
      {...field}
      field={apiField}
      onChange={handleOnChange}
      isInvalid={isInvalid}
      errorMessage={meta.error}
      name={name}
    />
  );
};
