import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ScheduleRequestSliceState } from "../schedule-request-form.slice";

interface RemoveScheduledRequestPropProps {
  apiClient: ApiClient;
  accountId: string;
  scheduledRequestId: string;
  id: string;
  prop: "taskbook" | "asset";
}

export const removeScheduledRequestProp = createAsyncThunk(
  "account/RemoveScheduleRequestProp",
  async (params: RemoveScheduledRequestPropProps, thunkAPI) => {
    const { accountId, apiClient, scheduledRequestId, prop, id } = params;

    prop === "taskbook"
      ? await apiClient.deleteScheduledRequestTaskbook(
          accountId,
          scheduledRequestId,
          id
        )
      : await apiClient.deleteScheduledRequestAsset(
          accountId,
          scheduledRequestId,
          id
        );

    return { id: id, prop: prop };
  }
);

export const removeTaskBookAssetHandler = (
  builder: ActionReducerMapBuilder<ScheduleRequestSliceState>
) => {
  builder.addCase(removeScheduledRequestProp.fulfilled, (state, action) => {
    const { id, prop } = action.payload;
    if (prop === "taskbook") {
      const index = state.scheduledRequestTaskBooks.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        state.scheduledRequestTaskBooks.splice(index, 1);
      }
    }

    if (prop === "asset") {
      const index = state.scheduledRequestAssets.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        state.scheduledRequestAssets.splice(index, 1);
      }
    }
  });
};
