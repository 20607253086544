import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ExportType } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import {
  IoCaretDownOutline,
  IoCaretUpOutline,
  IoDownloadOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { QuickFilterButton } from "../../../components/buttons/QuickFilterButton";
import { ExportModal } from "../../../components/export/ExportModal";
import { useQueryStringFilter } from "../../../hooks/useQueryStringFilter";
import { RootState, useThunkDispatch } from "../../../store";
import {
  loadScheduledRequests,
  ScheduledRequestFilters,
  unloadScheduledRequestList,
  updateScheduleRequestFilters,
} from "../../../store/schedule-request-list.slice";
import { getPaginationDetails } from "../../../utils/getPaginationDetails";
import { AccountModal } from "../account-modal/AccountModal";
import { ScheduleForms } from "../scheduled-request-form/ScheduleForms";
import { SchedulesData } from "./ScheduleData";
import { ScheduleFilters } from "./ScheduleFilters";
import { scheduledRequestDefaultFilters } from "./ScheduleFiltersDefault";

export default function SchedulesList() {
  const { scheduleRequestList } = useSelector((state: RootState) => state);
  const fixedContainerBgColor = useColorModeValue("blue.50", "gray.700");
  const ButtonBgColor = useColorModeValue("blue.500", "blue.900");
  const isDesktop = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  });
  const [workingScheduledRequestId, SetWorkingScheduledRequestId] = useState<
    string | undefined
  >();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentAccount, apiClient } = useAuthentication();
  const [showPills, setShowPills] = useState(false);
  const [cloneScheduledRequest, setCloneScheduledRequest] =
    useState<boolean>(false);
  const [isFiltersInit, setIsFiltersInit] = useState(false);
  const filtersBackground = useColorModeValue("blue.50", "blue.900");

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const openScheduledRequestForm = useCallback(
    (scheduledRequestId: string, cloneScheduledRequest?: boolean) => {
      SetWorkingScheduledRequestId(scheduledRequestId);
      if (cloneScheduledRequest) {
        setCloneScheduledRequest(cloneScheduledRequest);
      }
    },
    []
  );

  const {
    isOpen: isFiltersOpen,
    onOpen: openFilters,
    onClose: closeFilters,
  } = useDisclosure();

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();

  const { total } = useSelector(
    (state: RootState) => state.scheduleRequestList
  );
  const { filter, filterInitialized, updateQueryString } = useQueryStringFilter(
    {
      defaultValue: scheduledRequestDefaultFilters,
    }
  );

  const updateFilters = useCallback(
    (values: Partial<ScheduledRequestFilters>) => {
      const updatedFilters: ScheduledRequestFilters = { ...filter, ...values };
      // Removing the persons field from the query string
      const { persons, ...newFilters } = updatedFilters;
      updateQueryString({ ...newFilters });
      dispatch(updateScheduleRequestFilters(updatedFilters));
    },
    [filter, dispatch, updateQueryString]
  );

  const handleOnChangePage = useCallback(
    (page: number) => {
      updateFilters({ page });
    },
    [updateFilters]
  );

  const handleOnChangeToogle = useCallback(() => {
    const includeInactive = filter.includeInactive === true ? false : true;
    updateFilters({ includeInactive });
  }, [filter.includeInactive, updateFilters]);

  useEffect(() => {
    if (!isFiltersInit && filterInitialized) {
      updateFilters(filter);
      setIsFiltersInit(true);
    }
  }, [filterInitialized, filter, isFiltersInit, updateFilters]);

  useEffect(() => {
    if (!filterInitialized) return;
    thunkDispatch(
      loadScheduledRequests({
        accountId: currentAccount.id,
        apiClient: apiClient,
      })
    );
  }, [
    apiClient,
    thunkDispatch,
    currentAccount.id,
    scheduleRequestList.filters,
    scheduleRequestList.toggleUpdate,
    filterInitialized,
  ]);

  useEffect(() => {
    if (workingScheduledRequestId) {
      onOpen();
    }
  }, [onOpen, workingScheduledRequestId]);

  useEffect(() => {
    return () => {
      dispatch(unloadScheduledRequestList());
    };
  }, [dispatch]);

  return (
    <>
      <Stack mb={10}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="flex-end" gap={4}>
            <Heading size="lg">Scheduled Requests</Heading>

            <Box display={["none", "none", "block"]}>
              <QuickFilterButton
                activeText="Include Inactive"
                isActive={filter.includeInactive || false}
                onClick={handleOnChangeToogle}
              />
            </Box>
          </Flex>
          <Flex gap={2} display={["none", "none", "flex"]}>
            <Button
              float="right"
              color="white"
              onClick={onOpen}
              _hover={{ opacity: "1" }}
              size={isDesktop ? "md" : "sm"}
              bgColor={useColorModeValue("blue.500", "whiteAlpha.300")}
            >
              <Icon as={BiPlus} mr={2} />
              Add Schedule
            </Button>
          </Flex>
        </Flex>

        <StackItem
          p={2}
          my={4}
          display="flex"
          flexWrap="wrap"
          borderRadius={8}
          bgColor={filtersBackground}
        >
          <Flex w="100%" gap={4}>
            {isDesktop && (
              <Flex flex={1}>
                <ScheduleFilters
                  filter={filter}
                  updateFilters={updateFilters}
                  filterInitialized={filterInitialized}
                />
              </Flex>
            )}

            {!isDesktop && (
              <Flex flex={1} gap={4} justifyContent="space-between">
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => setShowPills(!showPills)}
                  rightIcon={
                    <Icon
                      as={showPills ? IoCaretUpOutline : IoCaretDownOutline}
                    />
                  }
                >
                  Quick Filters
                </Button>

                <Button size="sm" colorScheme="blue" onClick={openFilters}>
                  Filters
                </Button>
              </Flex>
            )}

            <Box w="40px">
              <IconButton
                display="flex"
                colorScheme="blue"
                aria-label="Export"
                variant="outline"
                icon={<IoDownloadOutline />}
                onClick={onExportModalOpen}
                size={isDesktop ? "md" : "sm"}
              />
            </Box>
          </Flex>
        </StackItem>

        {!isDesktop && (
          <StackItem display="flex" alignItems="center" flexWrap="wrap" gap={4}>
            {showPills && (
              <Flex w="100%">
                <QuickFilterButton
                  activeText="Include Inactive"
                  isActive={filter.includeInactive || false}
                  onClick={handleOnChangeToogle}
                />
              </Flex>
            )}
            <Text w="100%" fontSize="sm">
              {getPaginationDetails(total, filter.pageSize, filter.page)}
            </Text>
          </StackItem>
        )}

        <StackItem>
          <SchedulesData
            handleOnChangePage={handleOnChangePage}
            openScheduledRequestForm={openScheduledRequestForm}
          />
        </StackItem>
      </Stack>

      {!isDesktop && (
        <Box
          w="100%"
          ml={-4}
          bottom={0}
          minH="60px"
          position="fixed"
          alignItems="center"
          justifyContent="center"
          display={["flex", "flex", "none"]}
          bgColor={fixedContainerBgColor}
        >
          <Button
            size="sm"
            float="right"
            color="white"
            bgColor={ButtonBgColor}
            onClick={onOpen}
          >
            <Icon as={BiPlus} mr={2} />
            Add Schedule
          </Button>
        </Box>
      )}
      <AccountModal
        title="Scheduled Request Filters"
        isOpen={isFiltersOpen}
        onClose={closeFilters}
        content={
          <ScheduleFilters
            filter={filter}
            updateFilters={updateFilters}
            filterInitialized={filterInitialized}
          />
        }
      />
      <ScheduleForms
        isOpen={isOpen}
        onClose={onClose}
        workingScheduledRequestId={workingScheduledRequestId}
        cloneScheduledRequest={cloneScheduledRequest}
        setCloneScheduledRequest={setCloneScheduledRequest}
        setWorkingScheduledRequestId={SetWorkingScheduledRequestId}
      />
      {isExportModalOpen && (
        <ExportModal
          total={total}
          options={filter}
          isOpen={isExportModalOpen}
          onClose={onExportModalClose}
          type={ExportType.scheduledRequest}
        />
      )}
    </>
  );
}
