import { Badge } from "@chakra-ui/react";
import { ApiAsset } from "@operations-hero/lib-api-client";
import { useMemo } from "react";

export interface AssetWarrantyBadgeProps {
  asset: ApiAsset;
}

export const AssetWarrantyBadge = ({ asset }: AssetWarrantyBadgeProps) => {
  const underWarranty = useMemo(
    () =>
      asset.warrantyExpiration != null &&
      new Date(asset.warrantyExpiration).getTime() > new Date().getTime(),
    [asset.warrantyExpiration]
  );
  const expired = useMemo(
    () =>
      asset.warrantyExpiration != null &&
      new Date(asset.warrantyExpiration).getTime() < new Date().getTime(),
    [asset.warrantyExpiration]
  );

  return (
    <>
      {asset.active && underWarranty && (
        <Badge colorScheme="green" p={1}>
          Under Warranty
        </Badge>
      )}
      {asset.active && expired && (
        <Badge colorScheme="orange" p={1}>
          Warranty Expired
        </Badge>
      )}
      {!asset.active && (
        <Badge colorScheme="gray" p={1}>
          Inactive
        </Badge>
      )}
    </>
  );
};
