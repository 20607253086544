import { BoxProps, GridItem, HStack, Icon, Text } from "@chakra-ui/react";
import {
  ApiPurchaseTransaction,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { IoMdAttach } from "react-icons/io";
import { IoTrashSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { OutlinedIconButton } from "../../../../components/custom-icons/OutlinedIconButton";
import {
  DataTable,
  DataTableColumn,
} from "../../../../components/data-table/DataTable";
import { RootState } from "../../../../store";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { getVisibleFields } from "../../../../utils/getVisibleFields";
import { TRANSACTION_DATE_FORMAT } from "../transactions-helper";
import { ExpensesDataProps } from "./ExpensesData";

export enum TransactionEnum {
  EDIT = "EDIT",
  REMOVE = "REMOVE",
}

export const ExpensesTable: React.FC<ExpensesDataProps> = ({
  setWorkingPurchase,
  onOpenEditModal,
  openRemoveModal,
  canEditAndDelete,
}) => {
  const { purchasesData } = useSelector(
    (state: RootState) => state.requestForm.transactions.purchases
  );
  let total: number = 0;
  purchasesData.map((expenseItem: any) => {
    return (total += expenseItem.total);
  });

  const visibleFields = useSelector(
    (state: RootState) => state.requestForm.visibleFields
  );

  const { showExpenses } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const handleClickButtons = useCallback(
    (purchase: UpdateApiPurchaseTransaction, action: TransactionEnum) => {
      if (!showExpenses) {
        return;
      }
      setWorkingPurchase(purchase as ApiPurchaseTransaction);
      TransactionEnum.EDIT === action && onOpenEditModal();
      TransactionEnum.REMOVE === action && openRemoveModal && openRemoveModal();
    },
    [setWorkingPurchase, onOpenEditModal, openRemoveModal, showExpenses]
  );

  const columns = useMemo<DataTableColumn<ApiPurchaseTransaction>[]>(() => {
    const cellProps: BoxProps = {
      py: "4",
    };
    return [
      {
        Header: "Where",
        Cell: (cell: any) => (
          <Text whiteSpace="break-spaces" justifyContent="center">
            {cell.row.original.vendor?.name}
          </Text>
        ),
        maxWidth: 90,
        boxProps: cellProps,
      },
      {
        Header: "What",
        Cell: (cell: any) => (
          <Text whiteSpace="break-spaces">{cell.row.original.description}</Text>
        ),
        maxWidth: 90,
        boxProps: cellProps,
      },
      {
        Header: "Total Cost",
        Cell: (cell: any) => (
          <Text textAlign="left">
            {cell.row.original.total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "USD",
            })}
          </Text>
        ),

        maxWidth: 90,
        boxProps: cellProps,
      },
      {
        Header: "Type",
        Cell: (cell: any) => (
          <Text>{capitalizeFirstLetter(cell.row.original.purchaseType)}</Text>
        ),
        maxWidth: 80,
        boxProps: cellProps,
      },
      {
        Header: "Date",
        Cell: (cell: any) => (
          <Text>
            {format(
              new Date(cell.row.original.datePerformed),
              TRANSACTION_DATE_FORMAT
            )}
          </Text>
        ),
        maxWidth: 90,
        boxProps: cellProps,
      },
      {
        Header: " ",
        Cell: (cell: any) => (
          <HStack spacing="1.5" alignItems="center" justifyContent="right">
            {canEditAndDelete && canEditAndDelete(cell.row.original) && (
              <>
                {cell.row.original.attachmentCount > 0 ? (
                  <OutlinedIconButton
                    icon={<Icon as={IoMdAttach} boxSize={5} />}
                  />
                ) : null}
                <OutlinedIconButton
                  icon={<Icon as={IoTrashSharp} boxSize={5} />}
                  onClick={() =>
                    handleClickButtons(
                      cell.row.original,
                      TransactionEnum.REMOVE
                    )
                  }
                />
                <OutlinedIconButton
                  icon={<Icon as={MdEdit} boxSize={5} />}
                  onClick={() =>
                    handleClickButtons(cell.row.original, TransactionEnum.EDIT)
                  }
                />
              </>
            )}
          </HStack>
        ),
        maxWidth: 110,
        boxProps: cellProps,
      },
    ];
  }, [handleClickButtons, canEditAndDelete]);
  return (
    <GridItem colSpan={8} pt={4}>
      <DataTable columns={columns} data={purchasesData} />
    </GridItem>
  );
};
