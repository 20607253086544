import { Button, Divider, Flex, Grid, GridItem } from "@chakra-ui/react";
import { ApiRequestReminder } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { UserMultipleAutocompleteControl } from "../../../components/form-helpers/UserMultipleAutocompleteControl";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  loadRequestReminders,
  updateRequestReminder,
} from "../../../store/request-form/thunks/reminder.thunk";
import {
  getReminderInitialValues,
  mapUpdateReminderValues,
  ReminderFormValues,
  reminderSchema,
} from "./reminderFormHelper";

interface RequestReminderFormProps {
  reminder: ApiRequestReminder;
  onClose: () => void;
}

export const RequestReminderForm: FC<RequestReminderFormProps> = ({
  reminder,
  onClose,
}) => {
  const { request } = useSelector((state: RootState) => state.requestForm);
  const [isLoading, setIsLoading] = useState(false);

  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount, currentUser } = useAuthentication();

  const initialValues = useMemo(
    () => getReminderInitialValues([currentUser], reminder),
    [currentUser, reminder]
  );

  const handleOnSubmit = useCallback(
    (values: ReminderFormValues) => {
      if (!request) return;
      setIsLoading(true);
      const mappedValues = mapUpdateReminderValues(initialValues, values);
      thunkDispatch(
        updateRequestReminder({
          apiClient,
          accountId: currentAccount.id,
          reminder: mappedValues,
          requestId: request.id,
          reminderId: reminder.id,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Reminder updated successfully");
          thunkDispatch(
            loadRequestReminders({
              apiClient,
              accountId: currentAccount.id,
              requestId: request.id,
            })
          );
        })
        .catch(() => {
          showToast("error", "Something went wrong while updating reminder");
        })
        .finally(() => setIsLoading(false));
      onClose();
    },
    [
      apiClient,
      currentAccount.id,
      initialValues,
      onClose,
      reminder.id,
      request,
      showToast,
      thunkDispatch,
    ]
  );

  return (
    <Flex w="100%">
      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validationSchema={reminderSchema}
      >
        {({ values }) => {
          return (
            <Form
              style={{
                gap: 12,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <TextInputControl
                    name="title"
                    value={values.title}
                    label="Title"
                    placeholder="Add title"
                  />
                </GridItem>

                <GridItem colSpan={[12, 12, 7]}>
                  <DatePickerControl
                    name="date"
                    label="Date"
                    value={values.date}
                    placeholder="Select date"
                  />
                </GridItem>

                <GridItem colSpan={[12, 12, 5]}>
                  <DatePickerControl
                    showTime
                    format="hh:mmaa"
                    name="time"
                    showTimeOnly
                    label="Time"
                    value={values.time}
                    className="timePicker"
                    placeholder="Select time"
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <UserMultipleAutocompleteControl
                    name="users"
                    value={values.users || []}
                    label="Notify user(s)"
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <TextEditorControl
                    name="notes"
                    value={values.notes}
                    label="Notes (optional)"
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <Divider />
                </GridItem>

                <GridItem
                  colSpan={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button size="sm" variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    size="sm"
                    isLoading={isLoading}
                  >
                    Save
                  </Button>
                </GridItem>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};
