import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Spacer,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiAssetCondition,
  ApiAttachment,
  ApiLocationSummary,
  ApiParsedAsset,
  ApiReportingCategory,
  ApiRequestPriority,
  ApiVendor,
  CreateApiAsset,
  CreateApiAttachment,
  ImageParseConfidence,
  Label,
  UpdateApiAsset,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as yup from "yup";

import FocusError from "../../../../components/form-helpers/FocusError";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";

import axios, { AxiosProgressEvent } from "axios";
import { MdWarning } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Attachment,
  Attachments,
  mapApiAttachments,
} from "../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { AssetConditionSelectControl } from "../../../../components/form-helpers/AssetConditionSelectControl";
import { DatePickerControl } from "../../../../components/form-helpers/DatePickerControl";
import { LocationAutocompleteControl } from "../../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { QrTextInputControl } from "../../../../components/form-helpers/QrTextInputControl";
import { ReportingCategoryAutocompleteControl } from "../../../../components/form-helpers/ReportingCategoryAutocompleteControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { VendorAutocompleteControl } from "../../../../components/form-helpers/VendorsAutocompleteControl";
import { SparkleWithAnimation } from "../../../../components/icons/Sparkle";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState } from "../../../../store";
import { LabelsSection } from "../../location-form/LabelsSection";
import { setAiAsset } from "../../../../store/ai-assets/aiAsset.slice";

interface VerifyAssetModalProps {
  parsedAsset: ApiParsedAsset;
  latlong: { lat: string; long: string };
  scannedAttachments: Attachment[];
  manufacturer?: ApiVendor | null;
  onClose: () => void;
}

const CreateAssetWithAIValidationSchema = yup.object().shape({
  name: yup.string().required().max(100),
  location: yup.object().nullable().required("Location is required"),
  manufacturer: yup.object().nullable().required(),
});

const EditAiAssetModal: React.FC<VerifyAssetModalProps> = ({
  parsedAsset,
  latlong,
  scannedAttachments,
  manufacturer,

  onClose,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { assetId } = useParams<{ assetId: string }>();
  const bgColor = useColorModeValue("blue.50", "gray.700");
  const radioBgColor = useColorModeValue("white", "gray.700");
  const dispatch = useDispatch();
  const [currentAsset, setCurrentAsset] = useState<ApiAsset>();
  const ref = useRef<FormikProps<CreateApiAsset>>(null);
  const toast = useShowToast();

  const [labels, setLabels] = useState<Label[] | null>(
    parsedAsset?.labels || []
  );
  const { apiAsset } = useSelector((state: RootState) => state.aiAssetSlice);
  const { categories } = useSelector((state: RootState) => state.localCache);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );
  const [attachments, setAttachments] = useState<ApiAttachment[]>([]);
  const [defaultPhoto, setDefaultPhoto] = useState<ApiAttachment>();
  const [newAttachments, setNewAttachments] = useState<Attachment[]>([]);
  const [nameState, setNameState] = useState(parsedAsset.name.value);
  const [tagState, setTagState] = useState(parsedAsset.tag.value);
  const [manufacturerState, setManufacturer] = useState<ApiVendor | null>(
    manufacturer || null
  );
  const [serialState, setSerialState] = useState(
    parsedAsset.serialNumber.value
  );
  const [modelState, setModelState] = useState(parsedAsset.modelNumber.value);
  const [categoriesState, setCategoriesState] =
    useState<ApiReportingCategory[]>(categories);

  const isUploading = useMemo(() => {
    return workingAttachments.some((a) => a.isUploading === true);
  }, [workingAttachments]);

  const certaintyToColor = (certainty: any) => {
    switch (certainty) {
      case ImageParseConfidence.HighlyConfident:
        return "green";
      case ImageParseConfidence.SomewhatConfident:
        return "gold";
      case ImageParseConfidence.Neutral:
        return "yellow";
      case ImageParseConfidence.SomewhatDoubtful:
        return "orange";
      case ImageParseConfidence.HighlyDoubtful:
        return "red";
      default:
        return "gray";
    }
  };

  const handleAddAttachment = useCallback(
    (files: Attachment[]) => {
      let allAttachments = [...newAttachments];

      files.forEach(async (item) => {
        const response = await apiClient.createUpload(currentAccount.id);
        const newAttachment: Attachment = {
          ...item,
          isNew: true,
          isUploading: true,
          progress: 0,
          uploadId: response.id,
        };
        allAttachments.push(newAttachment);

        setNewAttachments(allAttachments);

        await axios.put(response.url, newAttachment.file, {
          headers: { "Content-type": newAttachment.file?.type },
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            newAttachment.progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );

            setNewAttachments([...allAttachments]);
          },
        });

        newAttachment.progress = undefined;
        newAttachment.isUploading = false;
        setNewAttachments([...allAttachments]);
      });
    },
    [apiClient, currentAccount, newAttachments]
  );

  const handleDeleteAttachment = useCallback(
    (file: Attachment) => {
      const updatedAttachments = [...workingAttachments];
      const index = updatedAttachments.findIndex(
        (f) => f.uploadId === file.uploadId
      );
      updatedAttachments.splice(index, 1);

      setWorkingAttachments(updatedAttachments);
    },
    [workingAttachments]
  );

  const handleSetLabels = useCallback((newLabels: Label[]) => {
    setLabels(newLabels);
  }, []);

  const getDefaultPhoto = useCallback(
    (attachment: Attachment) => {
      const foundAttachment = attachments.find((a) => a.id === attachment.id);
      if (!foundAttachment) {
        return;
      }
      setDefaultPhoto(foundAttachment);
    },
    [attachments]
  );

  const initialValues: CreateApiAsset = useMemo(() => {
    const category = parsedAsset?.category?.value
      ? categories.find((cat: any) => cat.name === parsedAsset.category.value)
      : null;

    const combinedLabels = [
      ...(currentAsset?.labels || []),
      ...(parsedAsset.labels || []).filter(
        (label) => !(currentAsset?.labels || []).includes(label)
      ),
    ];

    return {
      name: parsedAsset.name?.value ?? (currentAsset?.name || ""),
      externalId: parsedAsset.tag?.value ?? (currentAsset?.externalId || ""),
      manufacturer: manufacturer ?? (currentAsset?.manufacturer || null),
      model: parsedAsset.modelNumber?.value ?? (currentAsset?.model || ""),
      serial: parsedAsset.serialNumber?.value ?? (currentAsset?.serial || ""),
      reportingCategory: category ?? (currentAsset?.reportingCategory || null),
      condition: ApiAssetCondition.fair || "",
      coordinates: {
        latitude: latlong?.lat || "",
        longitude: latlong?.long || "",
      },
      purchaseCost: currentAsset?.purchaseCost || 0,
      purchaseOrder: currentAsset?.purchaseOrder || "",
      invoiceNumber: currentAsset?.invoiceNumber || "",
      purchaseDate: currentAsset?.purchaseDate || "",
      inServiceDate: currentAsset?.inServiceDate || "",
      warrantyExpiration: currentAsset?.warrantyExpiration || "",
      targetRenewalDate: currentAsset?.targetRenewalDate || "",
      notes: currentAsset?.notes || "",
      location: currentAsset?.location || null,
      vendor: currentAsset?.vendor || null,
      serviceProvider: currentAsset?.serviceProvider || null,
      defaultImage: currentAsset?.defaultImage || null,
      labels: combinedLabels || null,
      renewalCost: currentAsset?.renewalCost || 0,
      leased: currentAsset?.leased || false,
      attachments: workingAttachments,
      poweredByAi: true,
      eul: currentAsset?.eul || 5,
      rul: currentAsset?.rul || 5,
      priority: currentAsset?.priority || ApiRequestPriority.standard,
    };
  }, [
    manufacturer,
    parsedAsset,
    categories,
    latlong,
    currentAsset,
    workingAttachments,
  ]);

  const validator = useCallback(
    (isValid: boolean) => {
      if (!isValid) {
        toast("error", "Please ensure all required components are provided.");
      }
    },
    [toast]
  );

  const handleNameToggle = useCallback(
    (value: any) => {
      if (value === "0") {
        setNameState(parsedAsset.name.value || "");
      } else if (value === "1") {
        setNameState(currentAsset?.name || "");
      }
    },
    [setNameState, currentAsset, parsedAsset]
  );

  const handleTagToggle = useCallback(
    (value: any) => {
      if (value === "0") {
        setTagState(parsedAsset.tag.value || "");
      } else if (value === "1") {
        setTagState(currentAsset?.externalId || "");
      }
    },
    [setTagState, currentAsset, parsedAsset]
  );
  const handleManufacturerToggle = useCallback(
    (value: any) => {
      if (value === "0") {
        setManufacturer(manufacturer || null);
      } else if (value === "1") {
        setManufacturer(currentAsset?.manufacturer || null);
      }
    },
    [setManufacturer, currentAsset, manufacturer]
  );

  const handleSerialToggle = useCallback(
    (value: any) => {
      if (value === "0") {
        setSerialState(parsedAsset.serialNumber.value || "");
      } else if (value === "1") {
        setSerialState(currentAsset?.serial || "");
      }
    },
    [setSerialState, currentAsset, parsedAsset]
  );

  const handleModelToggle = useCallback(
    (value: any) => {
      if (value === "0") {
        setModelState(parsedAsset.modelNumber.value || "");
      } else if (value === "1") {
        setModelState(currentAsset?.model || "");
      }
    },
    [setModelState, currentAsset, parsedAsset]
  );
  const handleCategoryToggle = useCallback(
    (value: any) => {
      if (!categories) return;
      if (value === "0") {
        setCategoriesState(categories);
      } else if (value === "1") {
        setCategoriesState(
          currentAsset?.reportingCategory as unknown as ApiReportingCategory[]
        );
      }
    },
    [setCategoriesState, currentAsset, categories]
  );

  const handleFormikSubmit = useCallback(
    async (values: CreateApiAsset) => {
      if (!assetId && !apiAsset) return;
      const savedAsset = await apiClient.updateAsset(
        currentAccount.id,
        assetId ?? (apiAsset ? apiAsset.id : ""),
        {
          ...values,
          poweredByAI: true,
          labels,
        } as UpdateApiAsset
      );

      const allAttachments = [...newAttachments, ...scannedAttachments];
      const attachmentSaves = allAttachments.map(async (attachment) => {
        if (attachment.uploadId === undefined) {
          return Promise.resolve(null);
        }

        return await apiClient.createAssetAttachment(
          currentAccount.id,
          savedAsset.id,
          {
            uploadId: attachment.uploadId,
            name: attachment.name,
          } as CreateApiAttachment
        );
      });

      const savedAttachments = await Promise.all(attachmentSaves);
      if (!savedAsset.defaultImage || defaultPhoto) {
        const defaultImage =
          defaultPhoto ||
          savedAttachments.find(
            (a) => a && a.contentType.indexOf("image/") > -1
          );

        if (defaultImage) {
          await apiClient.updateAsset(currentAccount.id, savedAsset.id, {
            defaultImage,
          });
        }
      }
      toast("success", "Asset has been updated successfully");
      onClose();
      dispatch(setAiAsset({ apiAsset: null }));
      navigate(0);
    },
    [
      toast,
      apiClient,
      currentAccount.id,
      newAttachments,
      scannedAttachments,
      labels,
      navigate,
      onClose,
      assetId,
      defaultPhoto,
      apiAsset,
      dispatch,
    ]
  );

  useEffect(() => {
    if (!assetId && !apiAsset) return;

    Promise.all([
      apiClient.getAsset(
        currentAccount.id,
        assetId ?? (apiAsset ? apiAsset.id : "")
      ),
      apiClient.findAssetAttachments(
        currentAccount.id,
        assetId ?? (apiAsset ? apiAsset.id : ""),
        {
          pageSize: 20,
        }
      ),
    ])
      .then(([remoteAsset, remoteAttachments]) => {
        setCurrentAsset(remoteAsset);
        setLabels(remoteAsset.labels);
        setAttachments(remoteAttachments.data);
        setWorkingAttachments([
          ...mapApiAttachments(remoteAttachments.data),
          ...newAttachments,
        ]);
      })
      .catch(() => {
        toast("error", "Error loading asset");
      });
  }, [assetId, apiClient, currentAccount, toast, newAttachments, apiAsset]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.setFieldValue("name", nameState);
  }, [nameState]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.setFieldValue("externalId", tagState);
  }, [tagState]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.setFieldValue("manufacturer", manufacturerState);
  }, [manufacturerState]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.setFieldValue("serial", serialState);
  }, [serialState]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.setFieldValue("model", modelState);
  }, [modelState]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.setFieldValue("reportingCategory", categoriesState);
  }, [categoriesState]);

  return (
    <>
      <Text fontSize="larger" fontWeight="bold">
        Asset Fields
      </Text>
      {currentAsset ? (
        <Formik
          onSubmit={handleFormikSubmit}
          initialValues={initialValues}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={CreateAssetWithAIValidationSchema}
          innerRef={ref}
          enableReinitialize={true}
        >
          {({ values, isSubmitting, isValid }) => {
            return (
              <Form>
                {step === 1 && (
                  <>
                    <Grid
                      templateColumns="repeat(6, 1fr)"
                      verticalAlign="center"
                      mb={2}
                      gap={6}
                      w="100%"
                    >
                      <GridItem colSpan={6}>
                        {parsedAsset.name.value === null ||
                        parsedAsset.name?.value === currentAsset?.name ? (
                          <Box display="flex" alignItems="center">
                            <TextInputControl
                              label="Name"
                              value={currentAsset?.name || ""}
                              name="name"
                            />
                            <Tooltip label={parsedAsset.name?.warning || ""}>
                              <Box
                                display="flex"
                                alignItems="center"
                                p={2}
                                mt={7}
                              >
                                {certaintyToColor(
                                  parsedAsset.name.certainty
                                ) !== "green" ? (
                                  <MdWarning
                                    color={certaintyToColor(
                                      parsedAsset.name.certainty
                                    )}
                                    size="24px"
                                  />
                                ) : null}
                              </Box>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            backgroundColor={bgColor}
                            border="2px solid"
                            borderColor="blue.500"
                            p={2}
                            rounded={5}
                          >
                            <Box
                              position="absolute"
                              top="-10px"
                              right="10px"
                              backgroundColor={radioBgColor}
                              px={2}
                              py={1}
                              borderRadius="md"
                              boxShadow="lg"
                            >
                              Review
                            </Box>
                            <Grid
                              templateColumns="repeat(6, 1fr)"
                              verticalAlign="center"
                              mb={2}
                              gap={6}
                              w="100%"
                            >
                              <GridItem colSpan={6}>
                                <Box display="flex" alignItems="center">
                                  <TextInputControl
                                    label="Name *"
                                    value={nameState || null}
                                    name="name"
                                  />
                                  <Tooltip
                                    label={parsedAsset.name.warning || ""}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={8}
                                      ml={2}
                                    >
                                      {certaintyToColor(
                                        parsedAsset.name.certainty
                                      ) !== "green" ? (
                                        <MdWarning
                                          color={certaintyToColor(
                                            parsedAsset.name.certainty
                                          )}
                                          size="24px"
                                        />
                                      ) : null}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <RadioGroup
                                  defaultValue="0"
                                  onChange={handleNameToggle}
                                >
                                  <Stack direction="row" spacing={5}>
                                    <Radio
                                      value="0"
                                      backgroundColor={radioBgColor}
                                    >
                                      <SparkleWithAnimation
                                        boxSize="1em"
                                        mr={1}
                                      />
                                      New Data
                                    </Radio>
                                    <Radio
                                      value="1"
                                      backgroundColor={radioBgColor}
                                    >
                                      Previous Data
                                    </Radio>
                                  </Stack>
                                </RadioGroup>
                              </GridItem>
                            </Grid>
                          </Box>
                        )}
                      </GridItem>
                      <GridItem colSpan={6}>
                        {parsedAsset.tag.value === null ||
                        parsedAsset.tag?.value === currentAsset?.externalId ? (
                          <Box display="flex" alignItems="center">
                            <QrTextInputControl
                              name="externalId"
                              value={currentAsset?.externalId || null}
                              label="Tag / Alternate ID"
                              helperText="Reuse existing labels, barcodes or QR codes"
                            />
                            <Tooltip label={parsedAsset.tag.warning || ""}>
                              <Box
                                display="flex"
                                alignItems="center"
                                p={2}
                                mt={7}
                              >
                                {certaintyToColor(parsedAsset.tag.certainty) !==
                                "green" ? (
                                  <Box mb={5}>
                                    <MdWarning
                                      color={certaintyToColor(
                                        parsedAsset.tag.certainty
                                      )}
                                      size="24px"
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            backgroundColor={bgColor}
                            border="2px solid"
                            borderColor="blue.500"
                            p={2}
                            rounded={5}
                          >
                            <Box
                              position="absolute"
                              top="-10px"
                              right="10px"
                              backgroundColor={radioBgColor}
                              px={2}
                              py={1}
                              borderRadius="md"
                              boxShadow="lg"
                            >
                              Review
                            </Box>
                            <Grid
                              templateColumns="repeat(6, 1fr)"
                              verticalAlign="center"
                              mb={2}
                              gap={6}
                              w="100%"
                            >
                              <GridItem colSpan={6}>
                                <Box display="flex" alignItems="center">
                                  <QrTextInputControl
                                    name="externalId"
                                    value={tagState || null}
                                    placeholder={parsedAsset.tag.value}
                                    label="Tag / Alternate ID"
                                    helperText="Reuse existing labels, barcodes or QR codes"
                                  />
                                  <Tooltip
                                    label={parsedAsset.tag.warning || ""}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={8}
                                      ml={2}
                                    >
                                      {certaintyToColor(
                                        parsedAsset.tag.certainty
                                      ) !== "green" ? (
                                        <MdWarning
                                          color={certaintyToColor(
                                            parsedAsset.tag.certainty
                                          )}
                                          size="24px"
                                        />
                                      ) : null}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <RadioGroup
                                  defaultValue="0"
                                  onChange={handleTagToggle}
                                >
                                  <Stack direction="row" spacing={5}>
                                    <Radio
                                      value="0"
                                      backgroundColor={radioBgColor}
                                    >
                                      <SparkleWithAnimation
                                        boxSize="1em"
                                        mr={1}
                                      />
                                      New Data
                                    </Radio>
                                    <Radio
                                      value="1"
                                      backgroundColor={radioBgColor}
                                    >
                                      Previous Data
                                    </Radio>
                                  </Stack>
                                </RadioGroup>
                              </GridItem>
                            </Grid>
                          </Box>
                        )}
                      </GridItem>

                      <GridItem colSpan={6}>
                        <Box display="flex" alignItems="center" pr={4}>
                          <LocationAutocompleteControl
                            value={values.location as ApiLocationSummary | null}
                            name="location"
                            label="Location"
                          />
                        </Box>
                      </GridItem>

                      <GridItem colSpan={6}>
                        {manufacturer === null ||
                        manufacturer === currentAsset?.manufacturer ? (
                          <Box display="flex" alignItems="center">
                            <Box width="100%">
                              <VendorAutocompleteControl
                                addVendorLink
                                isManufacturer
                                name="manufacturer"
                                label="Manufacturer"
                                value={
                                  currentAsset?.manufacturer
                                    ? (currentAsset?.manufacturer as ApiVendor)
                                    : null
                                }
                              />
                            </Box>
                            <Tooltip
                              label={parsedAsset.manufacturer.warning || ""}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                mt={7}
                                p={2}
                              >
                                {certaintyToColor(
                                  parsedAsset.manufacturer.certainty
                                ) !== "green" ? (
                                  <MdWarning
                                    color={certaintyToColor(
                                      parsedAsset.manufacturer.certainty
                                    )}
                                    size="24px"
                                  />
                                ) : null}
                              </Box>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            backgroundColor={bgColor}
                            border="2px solid"
                            borderColor="blue.500"
                            p={2}
                            rounded={5}
                          >
                            <Box
                              position="absolute"
                              top="-10px"
                              right="10px"
                              backgroundColor={radioBgColor}
                              px={2}
                              py={1}
                              borderRadius="md"
                              boxShadow="lg"
                            >
                              Review
                            </Box>
                            <Grid
                              templateColumns="repeat(6, 1fr)"
                              verticalAlign="center"
                              mb={2}
                              gap={6}
                              w="100%"
                            >
                              <GridItem colSpan={6}>
                                <Box display="flex" alignItems="center">
                                  <VendorAutocompleteControl
                                    addVendorLink
                                    isManufacturer
                                    name="manufacturer"
                                    label="Manufacturer"
                                    value={
                                      values.manufacturer
                                        ? (values.manufacturer as ApiVendor)
                                        : null
                                    }
                                    aiSearch={
                                      values.manufacturer
                                        ? undefined
                                        : parsedAsset.manufacturer.value
                                    }
                                  />

                                  <Tooltip
                                    label={
                                      parsedAsset.manufacturer.warning || ""
                                    }
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={8}
                                      ml={2}
                                    >
                                      {certaintyToColor(
                                        parsedAsset.manufacturer.certainty
                                      ) !== "green" ? (
                                        <MdWarning
                                          color={certaintyToColor(
                                            parsedAsset.manufacturer.certainty
                                          )}
                                          size="24px"
                                        />
                                      ) : null}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <RadioGroup
                                  defaultValue="0"
                                  onChange={handleManufacturerToggle}
                                >
                                  <Stack direction="row" spacing={5}>
                                    <Radio
                                      value="0"
                                      backgroundColor={radioBgColor}
                                    >
                                      <SparkleWithAnimation
                                        boxSize="1em"
                                        mr={1}
                                      />
                                      New Data
                                    </Radio>
                                    <Radio
                                      value="1"
                                      backgroundColor={radioBgColor}
                                    >
                                      Previous Data
                                    </Radio>
                                  </Stack>
                                </RadioGroup>
                              </GridItem>
                            </Grid>
                          </Box>
                        )}
                      </GridItem>
                      <GridItem colSpan={6}>
                        {parsedAsset.serialNumber.value === null ||
                        parsedAsset.serialNumber?.value ===
                          currentAsset?.serial ? (
                          <Box display="flex" alignItems="center">
                            <Box width="100%">
                              <TextInputControl
                                label="Serial Number"
                                value={currentAsset?.serial}
                                name="serial"
                              />
                            </Box>
                            <Tooltip
                              label={parsedAsset.serialNumber.warning || ""}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                mt={7}
                                p={2}
                              >
                                {certaintyToColor(
                                  parsedAsset.serialNumber.certainty
                                ) !== "green" ? (
                                  <MdWarning
                                    color={certaintyToColor(
                                      parsedAsset.serialNumber.certainty
                                    )}
                                    size="24px"
                                  />
                                ) : null}
                              </Box>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            backgroundColor={bgColor}
                            border="2px solid"
                            borderColor="blue.500"
                            p={2}
                            rounded={5}
                          >
                            <Box
                              position="absolute"
                              top="-10px"
                              right="10px"
                              backgroundColor={radioBgColor}
                              px={2}
                              py={1}
                              borderRadius="md"
                              boxShadow="lg"
                            >
                              Review
                            </Box>
                            <Grid
                              templateColumns="repeat(6, 1fr)"
                              verticalAlign="center"
                              mb={2}
                              gap={6}
                              w="100%"
                            >
                              <GridItem colSpan={6}>
                                <Box display="flex" alignItems="center">
                                  <TextInputControl
                                    label="Serial Number"
                                    value={parsedAsset.serialNumber.value}
                                    name="serial"
                                  />

                                  <Tooltip
                                    label={
                                      parsedAsset.serialNumber.warning || ""
                                    }
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={8}
                                      ml={2}
                                    >
                                      {certaintyToColor(
                                        parsedAsset.serialNumber.certainty
                                      ) !== "green" ? (
                                        <MdWarning
                                          color={certaintyToColor(
                                            parsedAsset.serialNumber.certainty
                                          )}
                                          size="24px"
                                        />
                                      ) : null}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <RadioGroup
                                  defaultValue="0"
                                  onChange={handleSerialToggle}
                                >
                                  <Stack direction="row" spacing={5}>
                                    <Radio
                                      value="0"
                                      backgroundColor={radioBgColor}
                                    >
                                      <SparkleWithAnimation
                                        boxSize="1em"
                                        mr={1}
                                      />
                                      New Data
                                    </Radio>
                                    <Radio
                                      value="1"
                                      backgroundColor={radioBgColor}
                                    >
                                      Previous Data
                                    </Radio>
                                  </Stack>
                                </RadioGroup>
                              </GridItem>
                            </Grid>
                          </Box>
                        )}
                      </GridItem>
                      <GridItem colSpan={6}>
                        {parsedAsset.modelNumber.value === null ||
                        parsedAsset.modelNumber?.value ===
                          currentAsset?.model ? (
                          <Box display="flex" alignItems="center">
                            <Box width="100%">
                              <TextInputControl
                                label="Model Number"
                                value={currentAsset?.model}
                                name="model"
                              />
                            </Box>
                            <Tooltip
                              label={parsedAsset.modelNumber.warning || ""}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                mt={7}
                                p={2}
                              >
                                {certaintyToColor(
                                  parsedAsset.modelNumber.certainty
                                ) !== "green" ? (
                                  <MdWarning
                                    color={certaintyToColor(
                                      parsedAsset.modelNumber.certainty
                                    )}
                                    size="24px"
                                  />
                                ) : null}
                              </Box>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            backgroundColor={bgColor}
                            border="2px solid"
                            borderColor="blue.500"
                            p={2}
                            rounded={5}
                          >
                            <Box
                              position="absolute"
                              top="-10px"
                              right="10px"
                              backgroundColor={radioBgColor}
                              px={2}
                              py={1}
                              borderRadius="md"
                              boxShadow="lg"
                            >
                              Review
                            </Box>
                            <Grid
                              templateColumns="repeat(6, 1fr)"
                              verticalAlign="center"
                              mb={2}
                              gap={6}
                              w="100%"
                            >
                              <GridItem colSpan={6}>
                                <Box display="flex" alignItems="center">
                                  <TextInputControl
                                    label="Modal Number"
                                    value={parsedAsset.modelNumber.value}
                                    name="model"
                                  />

                                  <Tooltip
                                    label={
                                      parsedAsset.modelNumber.warning || ""
                                    }
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={8}
                                      ml={2}
                                    >
                                      {certaintyToColor(
                                        parsedAsset.modelNumber.certainty
                                      ) !== "green" ? (
                                        <MdWarning
                                          color={certaintyToColor(
                                            parsedAsset.modelNumber.certainty
                                          )}
                                          size="24px"
                                        />
                                      ) : null}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <RadioGroup
                                  defaultValue="0"
                                  onChange={handleModelToggle}
                                >
                                  <Stack direction="row" spacing={5}>
                                    <Radio
                                      value="0"
                                      backgroundColor={radioBgColor}
                                    >
                                      <SparkleWithAnimation
                                        boxSize="1em"
                                        mr={1}
                                      />
                                      New Data
                                    </Radio>
                                    <Radio
                                      value="1"
                                      backgroundColor={radioBgColor}
                                    >
                                      Previous Data
                                    </Radio>
                                  </Stack>
                                </RadioGroup>
                              </GridItem>
                            </Grid>
                          </Box>
                        )}
                      </GridItem>
                      <GridItem colSpan={6}>
                        {parsedAsset.modelNumber.value === null ||
                        categories ===
                          (currentAsset?.reportingCategory as unknown as ApiReportingCategory[]) ? (
                          <Box display="flex" alignItems="center">
                            <Box width="100%">
                              <ReportingCategoryAutocompleteControl
                                label="Category"
                                name="reportingCategory"
                                value={currentAsset?.reportingCategory}
                              />
                            </Box>
                            <Tooltip label={parsedAsset.category.warning || ""}>
                              <Box
                                display="flex"
                                alignItems="center"
                                mt={7}
                                p={2}
                              >
                                {certaintyToColor(
                                  parsedAsset.category.certainty
                                ) !== "green" ? (
                                  <MdWarning
                                    color={certaintyToColor(
                                      parsedAsset.category.certainty
                                    )}
                                    size="24px"
                                  />
                                ) : null}
                              </Box>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            backgroundColor={bgColor}
                            border="2px solid"
                            borderColor="blue.500"
                            p={2}
                            rounded={5}
                          >
                            <Box
                              position="absolute"
                              top="-10px"
                              right="10px"
                              backgroundColor={radioBgColor}
                              px={2}
                              py={1}
                              borderRadius="md"
                              boxShadow="lg"
                            >
                              Review
                            </Box>
                            <Grid
                              templateColumns="repeat(6, 1fr)"
                              verticalAlign="center"
                              mb={2}
                              gap={6}
                              w="100%"
                            >
                              <GridItem colSpan={6}>
                                <Box display="flex" alignItems="center">
                                  <ReportingCategoryAutocompleteControl
                                    label="Category"
                                    name="reportingCategory"
                                    value={values.reportingCategory}
                                  />

                                  <Tooltip
                                    label={parsedAsset.category.warning || ""}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={8}
                                      ml={2}
                                    >
                                      {certaintyToColor(
                                        parsedAsset.category.certainty
                                      ) !== "green" ? (
                                        <MdWarning
                                          color={certaintyToColor(
                                            parsedAsset.category.certainty
                                          )}
                                          size="24px"
                                        />
                                      ) : null}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <RadioGroup
                                  defaultValue="0"
                                  onChange={handleCategoryToggle}
                                >
                                  <Stack direction="row" spacing={5}>
                                    <Radio
                                      value="0"
                                      backgroundColor={radioBgColor}
                                    >
                                      <SparkleWithAnimation
                                        boxSize="1em"
                                        mr={1}
                                      />
                                      New Data
                                    </Radio>
                                    <Radio
                                      value="1"
                                      backgroundColor={radioBgColor}
                                    >
                                      Previous Data
                                    </Radio>
                                  </Stack>
                                </RadioGroup>
                              </GridItem>
                            </Grid>
                          </Box>
                        )}
                      </GridItem>
                    </Grid>

                    <LabelsSection
                      type="asset"
                      value={values.labels}
                      setValues={handleSetLabels}
                    />

                    <Box pt={3} pb={3}>
                      <Attachments
                        getDefaultPhoto={getDefaultPhoto}
                        showThumbnailEdit={true}
                        attachments={[...workingAttachments]}
                        onNewAttachments={handleAddAttachment}
                        onDeleteAttachment={handleDeleteAttachment}
                        title="Attachments"
                      />
                    </Box>
                  </>
                )}
                {step === 2 && (
                  <VStack gap={4} width="full" align="stretch">
                    <Box pt={2}>
                      <TextEditorControl
                        name="notes"
                        value={values.notes}
                        label="Notes"
                        maxCharacters="2000"
                      />
                    </Box>

                    <Box pb={1}>
                      <AssetConditionSelectControl
                        name="condition"
                        value={ApiAssetCondition.fair}
                        label="Condition"
                      />
                    </Box>
                    <Box pb={1}>
                      <DatePickerControl
                        name="inServiceDate"
                        value={values.inServiceDate}
                        label="In Service Date"
                        placeholder=""
                      />
                    </Box>
                    <Box pb={1}>
                      <DatePickerControl
                        name="warrantyExpiration"
                        value={values.warrantyExpiration}
                        label="Warranty Expires"
                        placeholder=""
                      />
                    </Box>
                    <Box pb={1}>
                      <VendorAutocompleteControl
                        name="vendor"
                        isSupplier
                        label="Supplier"
                        addVendorLink
                        value={null}
                      />
                    </Box>

                    <Box pb={1}>
                      <VendorAutocompleteControl
                        addVendorLink
                        isServiceProvider
                        name="serviceProvider"
                        label="Service Provider"
                        value={null}
                      />
                    </Box>
                    <Box pb={1}>
                      <DatePickerControl
                        name="purchaseDate"
                        value={values.purchaseDate}
                        label="Purchase Date"
                        placeholder=""
                      />
                    </Box>
                    <Box pb={1}>
                      <NumberInputControl
                        prefix="$"
                        name="purchaseCost"
                        value={values.purchaseCost}
                        label="Purchase Cost"
                        placeholder=""
                      />
                    </Box>
                    <Box pb={1}>
                      <DatePickerControl
                        name="targetRenewalDate"
                        value={values.targetRenewalDate}
                        label="Estimated Renewal Date"
                        placeholder=""
                      />
                    </Box>
                    <Box pb={1}>
                      <NumberInputControl
                        prefix="$"
                        name="renewalCost"
                        value={values.renewalCost}
                        label="Estimated Renewal Cost"
                        placeholder=""
                      />
                    </Box>
                    <Box pb={1}>
                      <TextInputControl
                        name="purchaseOrder"
                        value={values.purchaseOrder}
                        label="Purchase Order"
                        placeholder=""
                      />
                    </Box>
                    <Box pb={1}>
                      <TextInputControl
                        name="invoiceNumber"
                        value={values.invoiceNumber}
                        label="Invoice Number"
                        placeholder=""
                      />
                    </Box>
                  </VStack>
                )}

                <GridItem colSpan={6}>
                  <Box pt={3} pb={3}>
                    <Flex justifyContent="flex-end">
                      {step === 1 && (
                        <Button onClick={() => setStep(2)}>
                          + More Fields
                        </Button>
                      )}
                      {step === 2 && (
                        <Button onClick={() => setStep(1)}> Back</Button>
                      )}
                      <Spacer />
                      <Button
                        type="submit"
                        colorScheme="blue"
                        isLoading={isSubmitting}
                        isDisabled={isUploading || isSubmitting}
                        onClick={() => validator(isValid)}
                        spinner={<Spinner size="sm" />}
                      >
                        Update Asset
                      </Button>
                    </Flex>
                  </Box>
                </GridItem>

                <FocusError />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default EditAiAssetModal;
