import { ModalFooter } from "@chakra-ui/modal";
import { Button, HStack } from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiFundingSource,
  CreateApiFundingSource,
} from "@operations-hero/lib-api-client";
import { FormikProps } from "formik";
import { FC, useCallback, useRef, useState } from "react";
import { Attachment } from "../../../components/attachments/Attachments";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import {
  NewFundingSourceForm,
  NewFundingSourceProps,
} from "./NewFundingSourceForm";

export type NewFundingSourceModalProps = {
  onClose: () => void;
  onSubmit: (
    fundingSource: CreateApiFundingSource,
    attachments: Attachment[]
  ) => Promise<{
    fundingSource: ApiFundingSource;
    attachments: ApiAttachment[];
  } | void>;
  isOpen: boolean;
};

export const NewFundingSourceModal: FC<NewFundingSourceModalProps> = ({
  onClose,
  onSubmit,
  isOpen,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const newFundingSourceFormRef =
    useRef<FormikProps<NewFundingSourceProps>>(null);

  const handleSubmit = useCallback(() => {
    if (newFundingSourceFormRef.current) {
      setIsSubmitting(true);
      newFundingSourceFormRef.current.submitForm().then(() => {
        setIsSubmitting(false);
        if (newFundingSourceFormRef.current?.isValid) onClose();
      });
    }
  }, [onClose]);

  return (
    <AccountModal
      size="xl"
      title="New Funding Source"
      content={
        <NewFundingSourceForm
          onSubmit={onSubmit}
          formRef={newFundingSourceFormRef}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <ModalFooter>
          <HStack w="full" justify="space-between">
            <Button
              size="md"
              variant="link"
              colorScheme="blue"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="md"
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Create
            </Button>
          </HStack>
        </ModalFooter>
      }
    />
  );
};
