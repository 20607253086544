import { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/closest-edge";
import {
  ApiClient,
  ApiRequest,
  ApiRequestStatus,
  ApiWorkflow,
  ApiWorkflowPolicy,
  FindRequestsOptions,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { SETTING_CLOSED_REQUEST_TIME_PERIOD } from "../utils/emailSettingUtils";
import getRangeRelativeDate from "../utils/getRangeRelativeDate";
import { generateTransition } from "./request-form/request-form.helpers";
import { getRequestFilter, RequestListFilterState } from "./request-list.slice";
import { filtersInitialState } from "./requests/defaults";

export const MAX_ITEMS_PER_LOAD = 20;

export const columnViewFiltersOverride: Partial<
  Omit<RequestListFilterState, "quickFilter">
> = {
  //status filter override
  statuses: filtersInitialState.statuses,
  // dates filters override
  completed: filtersInitialState.completed,
  created: filtersInitialState.created,
  updated: filtersInitialState.updated,
  due: filtersInitialState.due,
  start: filtersInitialState.start,
  statusUpdated: filtersInitialState.statusUpdated,
  date: filtersInitialState.date,
  dateRelative: filtersInitialState.dateRelative,
};

export type StatusColumnRef = {
  maxItems: number;
  loadTimes: number;
  totalItems: number;
};
type StatusMap<T> = { [key in ApiRequestStatus]?: T };

export type ReferencesByStatus = StatusMap<StatusColumnRef>;

export type RequestsByStatusMap = StatusMap<ApiRequest[]>;

export type CurrentRequestRef = {
  policy: ApiWorkflowPolicy | null;
  workflow: ApiWorkflow | null;
  engine: SchemaRulesEngine | null;
};

export type RequestCard = {
  request: ApiRequest;
  index: number;
  edge: Edge | null;
};

export type ChangeType = "unset" | "dnd" | "lazy";

export type TransitionMap = {
  [key in ApiRequestStatus]?: boolean;
};

export type RequestsColumnViewSliceProps = {
  requestsByStatus: RequestsByStatusMap;
  referencesByStatus: ReferencesByStatus;
  currentChangeType?: ChangeType;
  source?: RequestCard;
  target?: RequestCard;
  loading: boolean;
  transitionsByRequest?: { [key: string]: TransitionMap };
};

const initialRequestsByStatus = (
  Object.keys(ApiRequestStatus) as ApiRequestStatus[]
).reduce<RequestsByStatusMap>((map, key) => {
  map[key] = [];
  return map;
}, {} as RequestsByStatusMap);

export type LoadRequestsByStatusProps = {
  apiClient: ApiClient;
  accountId: string;
};

type LoadMoreItemsProps = {
  status: ApiRequestStatus;
  apiClient: ApiClient;
  accountId: string;
};

type UpdateRequestTransition = {
  request: ApiRequest;
};

type UpdateRequestsByGroupParams = {
  requestsMap: RequestsColumnViewSliceProps["requestsByStatus"];
};

const initialReferencesByStatus = (
  Object.keys(ApiRequestStatus) as ApiRequestStatus[]
).reduce<ReferencesByStatus>((map, key) => {
  map[key] = {
    maxItems: 0,
    loadTimes: 1,
    totalItems: 0,
  };
  return map;
}, {} as ReferencesByStatus);

const initialState: RequestsColumnViewSliceProps = {
  requestsByStatus: initialRequestsByStatus,
  referencesByStatus: initialReferencesByStatus,
  currentChangeType: undefined,
  loading: false,
};

export const updateRequestTransition = createAsyncThunk(
  "requests-column-view/update-request-transition",
  async ({ request }: UpdateRequestTransition, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { isProductAdmin } = state.auth;

    const { workflowMap, policyMap, descendantsMap, categoriesMap } =
      state.localCache;
    const w = workflowMap[request.workflow.id];
    const p = policyMap[request.workflow.id];
    if (!w || !p)
      return {
        requestId: null,
        transition: {},
      };

    const transition = generateTransition(
      w,
      p,
      request,
      isProductAdmin,
      descendantsMap,
      categoriesMap
    );
    const requestTransitions: TransitionMap = {};
    if (transition?.next) requestTransitions[transition.next] = true;
    if (transition?.backwards && transition.backwards.length > 0) {
      transition.backwards.forEach((status) => {
        requestTransitions[status] = true;
      });
    }

    if (transition?.forwards && transition.forwards.length > 0) {
      transition.forwards.forEach((status) => {
        requestTransitions[status] = true;
      });
    }
    if (
      request?.status &&
      request.status !== ApiRequestStatus.hold &&
      request.status !== ApiRequestStatus.canceled
    ) {
      requestTransitions[ApiRequestStatus.hold] = true;
    }

    if (transition?.canCancel) {
      requestTransitions[ApiRequestStatus.canceled] = true;
    }

    return {
      requestId: request.id,
      transition: requestTransitions,
    };
  }
);

export const loadMoreItems = createAsyncThunk(
  "requests-column-view/load-more-items",
  async ({ apiClient, status, accountId }: LoadMoreItemsProps, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { isProductAdmin } = state.auth;

    const { workflowMap, policyMap, descendantsMap, categoriesMap } =
      state.localCache;

    const { userSettings } = state.localCache;
    const { referencesByStatus, requestsByStatus, transitionsByRequest } =
      state.requestsColumnViewSlice;

    const { sort, filters } = state.requestList;
    let newFilters = { ...filters };
    newFilters = {
      ...newFilters,
      ...columnViewFiltersOverride,
      pageSize: MAX_ITEMS_PER_LOAD,
    };
    const options = getRequestFilter(sort, newFilters);

    let loadTimes = referencesByStatus[status]?.loadTimes ?? 1;
    if (
      (requestsByStatus[status]?.length ?? 0) >=
      MAX_ITEMS_PER_LOAD * loadTimes
    )
      loadTimes = loadTimes + 1;
    const statusOptions: FindRequestsOptions = {
      ...options,
      status,
      page: 1,
      pageSize: loadTimes * MAX_ITEMS_PER_LOAD,
      sort: "created",
      direction: "asc",
    };

    if (
      status === ApiRequestStatus.closed &&
      userSettings[SETTING_CLOSED_REQUEST_TIME_PERIOD]
    ) {
      const closePeriodString =
        userSettings[SETTING_CLOSED_REQUEST_TIME_PERIOD].toString();
      const relativeDate = getRangeRelativeDate(closePeriodString);
      statusOptions.statusUpdated = relativeDate;
    }

    const itemsToAppendResponse = await apiClient.findRequests(
      accountId,
      statusOptions
    );

    const itemsToAppend = itemsToAppendResponse.data;

    const requestsTransitions = itemsToAppend.reduce<
      Record<string, TransitionMap>
    >((map, request) => {
      const w = workflowMap[request.workflow.id];
      const p = policyMap[request.workflow.id];
      if (!w || !p) return map;
      if (transitionsByRequest && transitionsByRequest[request.id]) return map;
      const transition = generateTransition(
        w,
        p,
        request,
        isProductAdmin,
        descendantsMap,
        categoriesMap
      );
      const requestTransitions: TransitionMap = {};
      if (transition?.next) requestTransitions[transition.next] = true;
      if (transition?.backwards && transition.backwards.length > 0) {
        transition.backwards.forEach((status) => {
          requestTransitions[status] = true;
        });
      }

      if (transition?.forwards && transition.forwards.length > 0) {
        transition.forwards.forEach((status) => {
          requestTransitions[status] = true;
        });
      }
      if (
        request?.status &&
        request.status !== ApiRequestStatus.hold &&
        request.status !== ApiRequestStatus.canceled
      ) {
        requestTransitions[ApiRequestStatus.hold] = true;
      }

      if (transition?.canCancel) {
        requestTransitions[ApiRequestStatus.canceled] = true;
      }

      map[request.id] = requestTransitions;
      return map;
    }, {});

    return {
      itemsToAppend: itemsToAppendResponse.data,
      status,
      requestsByStatus,
      requestsTransitions,
      statusOptions,
    };
  }
);

export const loadRequestsByStatus = createAsyncThunk(
  "requests-column-view/load-by-status",
  async ({ apiClient, accountId }: LoadRequestsByStatusProps, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const { sort, filters } = state.requestList;
    const { columnViewStatuses } = state.requestsSlice;
    const {
      userSettings,
      workflowMap,
      policyMap,
      descendantsMap,
      categoriesMap,
    } = state.localCache;
    const { isProductAdmin } = state.auth;
    let newFilters = { ...filters };
    newFilters = {
      ...newFilters,
      ...columnViewFiltersOverride,
      pageSize: MAX_ITEMS_PER_LOAD,
    };
    const options = getRequestFilter(sort, newFilters);

    const groupedByStatus = {} as RequestsByStatusMap;
    const referencesByStatus = {} as ReferencesByStatus;
    const allRequests: ApiRequest[] = [];
    await Promise.all(
      columnViewStatuses.map(async (status) => {
        const statusOptions: FindRequestsOptions = {
          ...options,
          status,
          pageSize: MAX_ITEMS_PER_LOAD,
          sort: "created",
          direction: "asc",
        };
        if (
          status === ApiRequestStatus.closed &&
          userSettings[SETTING_CLOSED_REQUEST_TIME_PERIOD]
        ) {
          const closePeriodString =
            userSettings[SETTING_CLOSED_REQUEST_TIME_PERIOD].toString();
          const relativeDate = getRangeRelativeDate(closePeriodString);
          statusOptions.statusUpdated = relativeDate;
        }
        const response = await apiClient.findRequests(accountId, statusOptions);
        groupedByStatus[status] = response.data;
        allRequests.push(...response.data);
        referencesByStatus[status] = {
          maxItems: MAX_ITEMS_PER_LOAD,
          loadTimes: 1,
          totalItems: response.total,
        };
      })
    );

    const transitionMap = allRequests.reduce<Record<string, TransitionMap>>(
      (map, request) => {
        const w = workflowMap[request.workflow.id];
        const p = policyMap[request.workflow.id];
        if (!w || !p) return map;
        const transition = generateTransition(
          w,
          p,
          request,
          isProductAdmin,
          descendantsMap,
          categoriesMap
        );
        const requestTransitions: TransitionMap = {};
        if (transition?.next) requestTransitions[transition.next] = true;
        if (transition?.backwards && transition.backwards.length > 0) {
          transition.backwards.forEach((status) => {
            requestTransitions[status] = true;
          });
        }

        if (transition?.forwards && transition.forwards.length > 0) {
          transition.forwards.forEach((status) => {
            requestTransitions[status] = true;
          });
        }
        if (
          request?.status &&
          request.status !== ApiRequestStatus.hold &&
          request.status !== ApiRequestStatus.canceled
        ) {
          requestTransitions[ApiRequestStatus.hold] = true;
        }

        if (transition?.canCancel) {
          requestTransitions[ApiRequestStatus.canceled] = true;
        }

        map[request.id] = requestTransitions;
        return map;
      },
      {}
    );

    return { groupedByStatus, referencesByStatus, transitionMap };
  }
);

export const updateRequestsByGroup = createAsyncThunk(
  "requests-column-view/update-requests-map",
  async (params: UpdateRequestsByGroupParams, thunkAPI) => {
    return {
      map: params.requestsMap,
    };
  }
);
export const requestsColumnViewSlice = createSlice({
  name: "request-column-view",
  initialState,
  reducers: {
    setSource: (state, action: PayloadAction<RequestCard>) => {
      if (!state.source) {
        state.source = action.payload;
      } else {
        state.source = {
          ...state.source,
          ...action.payload,
        };
      }
    },

    setTarget: (state, action: PayloadAction<RequestCard>) => {
      if (!state.target) {
        state.target = action.payload;
      } else {
        state.target = {
          ...state.source,
          ...action.payload,
        };
      }
    },
    setChangeType: (
      state,
      action: PayloadAction<RequestsColumnViewSliceProps["currentChangeType"]>
    ) => {
      state.currentChangeType = action.payload;
    },

    unloadChangeValues: (state) => {
      state.currentChangeType = "unset";
      state.source = undefined;
      state.target = undefined;
    },
    addToStatusTotal: (
      state,
      action: PayloadAction<{
        status: ApiRequestStatus;
      }>
    ) => {
      const { status } = action.payload;
      const current = state.referencesByStatus[status] ?? {
        loadTimes: 1,
        maxItems: 0,
        totalItems: 0,
      };

      const updated = { ...current, totalItems: current.totalItems + 1 };
      state.referencesByStatus[status] = updated;
    },

    substractToStatusTotal: (
      state,
      action: PayloadAction<{
        status: ApiRequestStatus;
      }>
    ) => {
      const { status } = action.payload;

      const current = state.referencesByStatus[status] ?? {
        loadTimes: 1,
        maxItems: 0,
        totalItems: 0,
      };
      const updated = { ...current, totalItems: current.totalItems - 1 };

      state.referencesByStatus[status] = updated;
    },

    unloadColumnView: (state) => {
      state.currentChangeType = "unset";
      state.source = undefined;
      state.target = undefined;
      state.requestsByStatus = initialRequestsByStatus;
      state.referencesByStatus = initialReferencesByStatus;
      state.loading = false;
      state.transitionsByRequest = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRequestsByStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadRequestsByStatus.fulfilled, (state, action) => {
      const { referencesByStatus, groupedByStatus, transitionMap } =
        action.payload;
      state.referencesByStatus = referencesByStatus;
      state.requestsByStatus = groupedByStatus;
      state.transitionsByRequest = transitionMap;
      state.loading = false;
    });
    builder.addCase(updateRequestsByGroup.fulfilled, (state, action) => {
      state.requestsByStatus = action.payload.map;
    });
    builder.addCase(loadMoreItems.fulfilled, (state, action) => {
      const { itemsToAppend, status, requestsTransitions, statusOptions } =
        action.payload;

      state.requestsByStatus[status] = [...itemsToAppend];

      const current = state.referencesByStatus[status] ?? {
        loadTimes: 1,
        maxItems: 0,
        totalItems: 0,
      };

      state.referencesByStatus[status] = {
        ...current,
        loadTimes: statusOptions.pageSize
          ? statusOptions.pageSize / MAX_ITEMS_PER_LOAD
          : 1,
      };
      state.transitionsByRequest = {
        ...state.transitionsByRequest,
        ...requestsTransitions,
      };
    });
    builder.addCase(updateRequestTransition.fulfilled, (state, action) => {
      const { requestId, transition } = action.payload;
      if (!requestId || !transition) return;
      if (!state.transitionsByRequest) state.transitionsByRequest = {};
      state.transitionsByRequest[requestId] = transition;
    });
  },
});

export const {
  setChangeType,
  setSource,
  setTarget,
  unloadChangeValues,
  addToStatusTotal,
  substractToStatusTotal,
  unloadColumnView,
} = requestsColumnViewSlice.actions;
export default requestsColumnViewSlice.reducer;
