import {
  ApiAccount,
  ApiClient,
  ApiLocationSummary,
  ApiUser,
  ApiUserSummary,
  ApiWorkflow,
  ApiWorkflowReasonSummary,
  CreateApiScheduledRequest,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getVisibleFields } from "../../../utils/getVisibleFields";
import { getTimeZoneIanaFormat } from "../../../utils/timezone";
import {
  ScheduledRequestFormExtraProps,
  ScheduledRequestProps,
  ScheduleRequestSliceState,
} from "../schedule-request-form.slice";
import { getNextMonthFirstDay } from "../scheduled-request-helpers";

interface InitScheduledRequestFormProps {
  apiClient: ApiClient;
  currentUser: ApiUser;
  currentAccount: ApiAccount;
  isProductAdmin: boolean;
}

export const initScheduleRequestFormValues = createAsyncThunk(
  "account/initScheduleRequestValues",
  async (params: InitScheduledRequestFormProps, thunkAPI) => {
    const { currentAccount, currentUser, apiClient, isProductAdmin } = params;
    const globalState = thunkAPI.getState() as RootState;

    const { workflows, policyMap } = globalState.localCache;
    const { scheduledRequest } = globalState.scheduleRequestForm;

    const workflowReasons = await apiClient.findWorkflowReasons(
      currentAccount.id,
      workflows[0].requestSlug,
      { pageSize: 100 }
    );

    const initReason = workflowReasons.data.find(
      (reason) => reason.name === "Preventive"
    );

    const firstWorkflow = workflows[0];
    const policy = policyMap[firstWorkflow.id];

    const extraProps = await getWorkflowSchemaFieldsForSchedule({
      apiClient,
      currentAccount,
      currentUser,
      policy,
      workflow: firstWorkflow,
      isProductAdmin,
      scheduledRequest,
    });

    return {
      workflow: workflows[0],
      location: undefined as unknown as ApiLocationSummary,
      requester: currentUser as ApiUserSummary,
      reason: initReason as ApiWorkflowReasonSummary,
      extraProps,
    } as Partial<CreateApiScheduledRequest> & {
      extraProps: ScheduledRequestFormExtraProps;
    };
  }
);

export const initScheduledRequestHandler = (
  builder: ActionReducerMapBuilder<ScheduleRequestSliceState>
) => {
  builder.addCase(initScheduleRequestFormValues.fulfilled, (state, action) => {
    const scheduleDefualtStartDate = getNextMonthFirstDay();

    state.scheduledRequest = {
      ...state.scheduledRequest,
      location: (action.payload.location as ApiLocationSummary) || undefined,
      workflow: (action.payload.workflow as ApiWorkflow) || undefined,
      requester: action.payload.requester as ApiUserSummary,
      reason: action.payload.reason as ApiWorkflowReasonSummary,
    };

    state.schedule.isNew = true;
    state.schedule.timeZone = getTimeZoneIanaFormat();
    state.schedule.startDate = scheduleDefualtStartDate;
    state.schedule.executeEvery = 1;
    state.schedule.frequency = "monthly";
    state.isLoading = false;
    state.extraProps = { ...action.payload.extraProps };
  });
};

interface getWorkflowSchemaFieldsForScheduleParams
  extends InitScheduledRequestFormProps {
  workflow: ApiWorkflow;
  policy: WorkflowPolicy;
  scheduledRequest: ScheduledRequestProps;
}

export const getWorkflowSchemaFieldsForSchedule = async ({
  apiClient,
  currentAccount,
  currentUser,
  policy,
  workflow,
  isProductAdmin,
  scheduledRequest,
}: getWorkflowSchemaFieldsForScheduleParams) => {
  const schemaFields = await apiClient.findWorkflowSchemaFields(
    currentAccount.id,
    workflow.schema.id,
    { pageSize: 50 }
  );

  const rulesEngine = new SchemaRulesEngine({
    account: currentAccount,
    form: "full",
    schemaFields: schemaFields.data,
    user: currentUser,
    workflow: workflow,
    policy,
    isProductAdmin,
  });

  const visibleFields = rulesEngine.getVisibleFields({
    //@ts-ignore
    request: scheduledRequest,
    includeDeleted: false,
  });

  const requiredWorkflowFields = rulesEngine.getRequiredFields({
    //@ts-ignore
    request: scheduledRequest,
  });

  const { showProject, showTaskbooks } = getVisibleFields(visibleFields);

  const isProjectRequired = requiredWorkflowFields.some(
    (field) => field.key === "SYSTEM-PROJECT"
  );

  const result = {
    showProject,
    showTaskbooks,
    visibleFields,
    isProjectRequired,
    schemaFields: schemaFields.data,
  } as ScheduledRequestFormExtraProps;

  return result;
};
