/** Support for US and CA phone numbers only */

export const phoneNumberFormatting = (phoneE164format: string) => {
  const cleaned = ("" + phoneE164format).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const countryCode = match[1] ? "+1 " : "";
    return [countryCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const phoneNumberAutoFormatting = (input: string) => {
  const numbers = input.replace(/\D/g, "");

  let formattedNumber = "";
  if (numbers.length <= 3) {
    formattedNumber = numbers;
  } else if (numbers.length <= 6) {
    formattedNumber = `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
  } else {
    formattedNumber = `(${numbers.slice(0, 3)}) ${numbers.slice(
      3,
      6
    )}-${numbers.slice(6)}`;
  }

  return formattedNumber;
};
