import { Avatar, Tag, TagLabel } from "@chakra-ui/react";
import { ApiUserGroup } from "@operations-hero/lib-api-client";

export interface GroupBadgeProps {
  value: ApiUserGroup;
  size?: string;
  hideName?: boolean;
}

export const GroupBadge = ({
  value,
  size = "xs",
  hideName,
}: GroupBadgeProps) => {
  return (
    <Tag size="lg" background="inherit" pl={1} pr={1}>
      <Avatar
        size={size}
        name={`${value.name}`}
        src={value.logo ? value.logo : ""}
        visibility={value.id === "null-user" ? "hidden" : "initial"}
        ml={-1}
        mr={2}
      />
      {!hideName ? (
        <TagLabel
          fontWeight="normal"
          fontStyle={value.id === "null-user" ? "italic" : "initial"}
          fontSize={[16, 16, 14]}
        >
          {value.name}
        </TagLabel>
      ) : null}
    </Tag>
  );
};
