import { Box, Button, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { RiArrowUpDownFill } from "react-icons/ri";
import { NumberCircle } from "../common-components/NumberCircle";
import { TaskFormProps } from "./TaskForm";

export interface CollapseTaskProps extends TaskFormProps {
  index: number;
  onClose: () => void;
}

export const CollapseTask: FC<CollapseTaskProps> = ({
  task,
  index,
  provided,
  onClose,
  deleteTaskStep,
}) => {
  const { getFieldMeta } = useFormikContext();
  const textColor = useColorModeValue("black", "white");

  const hasError = useCallback(() => {
    const { error, touched } = getFieldMeta(`tasks[${index}].name`);
    if (error && touched) return true;
    return false;
  }, [getFieldMeta, index]);

  const deleteStep = useCallback(
    (index: number) => {
      deleteTaskStep(index);
    },
    [deleteTaskStep],
  );

  return (
    <Box>
      <Box display="inline-flex" width="100%" my={4}>
        <Box pt={hasError() ? 2 : 0.5}>
          <NumberCircle stepNumber={task.order} />
        </Box>
        <Box marginX={4} w="100%">
          <Text
            border="2px solid"
            fontWeight={600}
            borderColor={hasError() ? "red.500" : "transparent"}
            minHeight={10}
            borderRadius={6}
          >
            {task.name}
          </Text>
        </Box>
        <Box {...provided.dragHandleProps} pt={2}>
          <Icon as={RiArrowUpDownFill} w={6} h={5} color={textColor} />
        </Box>
      </Box>
      <Button
        variant="outline"
        borderColor="blue.500"
        color={textColor}
        size="sm"
        mr={4}
        onClick={onClose}
      >
        Edit this Step
      </Button>
      <Button
        variant="outline"
        borderColor="blue.500"
        size="sm"
        color={textColor}
        onClick={() => deleteStep(index)}
      >
        Delete
      </Button>
    </Box>
  );
};
