import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { FC, RefObject, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AddTaskbookComponent } from "./AddTaskBook";
import { TaskbooksData } from "./TaskbooksData";

export interface TaskBookProps {
  requestEngine: SchemaRulesEngine | undefined;
  fieldRef?: RefObject<HTMLSelectElement>;
  isTaskbooksRendered?: boolean;
  updateTaskbooksRenderState?: (value: boolean) => void;
}

export const TaskbooksSection: FC<TaskBookProps> = ({
  requestEngine,
  fieldRef,
  updateTaskbooksRenderState,
}) => {
  const { taskbooks, isApproverOnly, isRequesterOnly } = useSelector(
    (state: RootState) => state.requestForm
  );

  useEffect(() => {
    if (updateTaskbooksRenderState) updateTaskbooksRenderState(true);
  }, [updateTaskbooksRenderState]);
  return taskbooks.length === 0 &&
    (isApproverOnly || isRequesterOnly) ? null : (
    <>
      <AddTaskbookComponent fieldRef={fieldRef} />
      <TaskbooksData requestEngine={requestEngine} />
    </>
  );
};
