import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, MenuButton, useColorModeValue } from "@chakra-ui/react";
import { ApiUser } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { UserBadge } from "../../badges/UserBadge";
type LargeProfileButtonProps = {
  user: ApiUser;
};
export const LargeProfileButton: FC<LargeProfileButtonProps> = ({ user }) => {
  const userBadgeBg = useColorModeValue("gray.200", "whiteAlpha.200");

  return (
    <MenuButton
      as={Button}
      bgColor={userBadgeBg}
      rightIcon={<ChevronDownIcon />}
      _active={{ bgColor: userBadgeBg }}
      border="none"
      display="flex"
      alignItems="center"
    >
      <UserBadge value={user} hideName={false} backgroundColor={userBadgeBg} />
    </MenuButton>
  );
};
