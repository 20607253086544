export class GameOfLife {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D | null;
  fps: number;
  interval: number;
  cellSize: number;
  cols: number;
  rows: number;
  aliveCells: Set<any>;
  animationFrameId: any;
  lastTime: any;

  constructor(canvas: HTMLCanvasElement, fps = 20) {
    this.canvas = canvas;
    this.context = this.canvas.getContext("2d");
    this.fps = fps;
    this.interval = 10000 / this.fps;
    this.cellSize = 10;
    this.cols = Math.floor(this.canvas.width / this.cellSize);
    this.rows = Math.floor(this.canvas.height / this.cellSize);
    this.aliveCells = new Set();
    this.animationFrameId = null;
  }

  start() {
    if (!this.animationFrameId) {
      this.lastTime = 0;
      this.animationFrameId = requestAnimationFrame(this.update.bind(this));
    }
  }

  stop() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  clear() {
    this.stop();
    this.aliveCells.clear();
    this.render();
  }

  random() {
    this.aliveCells.clear();

    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.cols; col++) {
        if (Math.random() < 0.5) {
          this.aliveCells.add(this.getCellKey(row, col));
        }
      }
    }
    this.render();
  }

  update(timestamp: number) {
    const deltaTime = timestamp - this.lastTime;
    if (deltaTime > this.interval) {
      this.lastTime = timestamp - (deltaTime % this.interval);
      this.render();
      this.computeNextGeneration();
    }
    this.animationFrameId = requestAnimationFrame(this.update.bind(this));
  }

  computeNextGeneration() {
    const newAliveCells = new Set();
    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.cols; col++) {
        const neighbors = this.getAliveNeighbors(row, col);
        const cellKey = this.getCellKey(row, col);
        if (
          this.aliveCells.has(cellKey) &&
          (neighbors === 2 || neighbors === 3)
        ) {
          newAliveCells.add(cellKey);
        } else if (!this.aliveCells.has(cellKey) && neighbors === 3) {
          newAliveCells.add(cellKey);
        }
      }
    }
    this.aliveCells = newAliveCells;
  }

  getAliveNeighbors(row: number, col: number) {
    let count = 0;
    for (let i = -1; i <= 1; i++) {
      for (let j = -1; j <= 1; j++) {
        if (i === 0 && j === 0) continue;
        const newRow = row + i;
        const newCol = col + j;
        if (
          newRow >= 0 &&
          newRow < this.rows &&
          newCol >= 0 &&
          newCol < this.cols
        ) {
          if (this.aliveCells.has(this.getCellKey(newRow, newCol))) {
            count++;
          }
        }
      }
    }
    return count;
  }

  getCellKey(row: number, col: number) {
    return `${row},${col}`;
  }

  render() {
    const { context } = this;
    if (!context) return;
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.aliveCells.forEach((cellKey) => {
      const [row, col] = cellKey.split(",").map(Number);
      context.fillStyle = this.getRandomColor();
      context.fillRect(
        col * this.cellSize,
        row * this.cellSize,
        this.cellSize,
        this.cellSize
      );
    });
  }

  getRandomColor() {
    const colors = ["#2B6CB0", "#2C5282", "#98C0E6"];
    return colors[Math.floor(Math.random() * colors.length)];
  }
}
