import * as yup from "yup";
import { ScheduledRequestProps } from "../../../store/scheduled-request-form/schedule-request-form.slice";

export const ScheduleRequestFormSchema = {
  name: yup.string().max(255, "Field is too long").required("Name is required"),
  summary: yup.string().nullable(),
  location: yup.object().required("Location is required"),
  assignees: yup.object().nullable(),
  reportingCategory: yup.object().nullable(),
  reason: yup.object().nullable(),
  workflow: yup.object().required("Workflow is required"),
  estimatedHours: yup.string().nullable(),
  estimatedCost: yup.number().typeError("Must be a number").nullable(),
  requester: yup.object().required("Requester is required"),
};

export const getScheduleRequestFormSchema = (
  showProject: boolean,
  isProjectRequired: boolean
) => {
  if (!showProject || !isProjectRequired) {
    return yup.object().shape(ScheduleRequestFormSchema);
  }

  const newValidationSchema = {
    ...ScheduleRequestFormSchema,
    project: yup
      .object()
      .nullable(false)
      .typeError("Project is required")
      .required("Project is required"),
  };

  return yup.object().shape(newValidationSchema);
};

export const validateScheduleRequestForm = (
  scheduleRequest: ScheduledRequestProps,
  yupSchema?: yup.ObjectSchema<any>
): boolean => {
  const schema = yupSchema
    ? yupSchema
    : yup.object().shape(ScheduleRequestFormSchema);
  const result = schema.isValidSync(scheduleRequest);
  return result;
};
