import { useColorModeValue } from "@chakra-ui/react";

const DarkModeWidgetTheme = {
  type: "custom",
  background: "#1A202C",
  itemsBackground: "#2D3748",
  boxShadow: {
    size: "none",
    color: "rgb(0,0,0)",
  },
  title: {
    align: "center",
    bold: false,
    italic: false,
    underline: false,
    border: false,
  },
  borders: {
    "border-top-width": "0px",
    "border-right-width": "0px",
    "border-bottom-width": "0px",
    "border-left-width": "0px",
    "border-radius": "8px",
    "border-style": "solid",
    "border-color": "#000000",
  },
  // margins: [0, 0],
  mainColor: "#0E89E0",
  axis: {},
  legend: {
    type: "line",
  },
  tooltip: {},
  colors: [
    "#0E89E0",
    "#52B6F0",
    "#8FD0F1",
    "#BDDCF9",
    "#F45000",
    "#FF8E3B",
    "#FFB069",
    "#FFCFA1",
    "#15BF49",
    "#60D863",
    "#95E88C",
    "#C1F3B7",
    "#685AC2",
    "#958FD3",
    "#B4B6E4",
    "#D7D7EF",
    "#636363",
    "#969696",
    "#BDBDBD",
    "#D9D9D9",
  ],
  font: {
    fontFamily: "Open Sans",
    fontSize: 13,
  },
  editBackground: "#1A202C",
  editDarkOrLight: "dark-edit",
  darkOrLight: "dark",
};

const LightModeWidgetTheme = {
  type: "custom",
  background: "#F2F2F2",
  itemsBackground: "#ffffff",
  boxShadow: {
    size: "none",
    color: "rgb(0,0,0)",
  },
  title: {
    align: "center",
    bold: false,
    italic: false,
    underline: false,
    border: false,
  },
  editModeBackground: "#ececec",
  borders: {
    "border-color": "rgba(0, 0, 0, .1)",
    "border-radius": "8px",
    "border-style": "solid",
    "border-top-width": "1px",
    "border-right-width": "1px",
    "border-bottom-width": "1px",
    "border-left-width": "1px",
  },
  // margins: [0, 0],
  mainColor: "#009dff",
  axis: {},
  legend: {
    type: "normal",
  },
  tooltip: {},
  colors: [
    "#0E89E0",
    "#52B6F0",
    "#8FD0F1",
    "#BDDCF9",
    "#F45000",
    "#FF8E3B",
    "#FFB069",
    "#FFCFA1",
    "#15BF49",
    "#60D863",
    "#95E88C",
    "#C1F3B7",
    "#685AC2",
    "#958FD3",
    "#B4B6E4",
    "#D7D7EF",
    "#636363",
    "#969696",
    "#BDBDBD",
    "#D9D9D9",
  ],
  font: {
    fontFamily: "Open Sans",
    fontSize: 13,
  },
  editBackground: "#ececec",
  editDarkOrLight: "light-edit",
  darkOrLight: "light",
};

function useCumulioTheme() {
  const widgetTheme = useColorModeValue(
    LightModeWidgetTheme,
    DarkModeWidgetTheme
  );

  const loader = useColorModeValue(
    { bg: "#FFFFFF", spinner: "" },
    { bg: "#2D3748", spinner: "" }
  );

  return { widgetTheme, loader };
}

export default useCumulioTheme;
