import { Alert, useColorModeValue } from "@chakra-ui/react";
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";

interface CommentAlertMessageProps {
  isPublic: boolean;
  setCanPostPrivate: Dispatch<SetStateAction<boolean>>;
}

const DEFAULT_MESSAGE = "This comment will be visible to everybody";

export const RequestCommentAlert: FC<CommentAlertMessageProps> = ({
  isPublic,
  setCanPostPrivate,
}) => {
  const alertBgColor = useColorModeValue("orange.200", "orange.600");

  const { isProductAdmin } = useAuthentication();
  const { workflow, policy } = useSelector(
    (state: RootState) => state.requestForm
  );

  const canPostPrivate = useMemo(() => {
    if (isProductAdmin) return true;
    if (!policy) {
      return false;
    }
    return !!(policy.admin || policy.reviewer || policy.technician);
  }, [policy, isProductAdmin]);

  useEffect(() => {
    setCanPostPrivate(canPostPrivate);
  }, [canPostPrivate, setCanPostPrivate]);

  const messageFromWorkflow = useMemo(() => {
    return `This comment will be visible to Administrators, Technicians
    ${workflow?.requireReviewerBeforeClose ? " and Reviewers" : ""}`;
  }, [workflow]);

  const message = useMemo(() => {
    if (!isPublic && canPostPrivate) return messageFromWorkflow;
    return DEFAULT_MESSAGE;
  }, [canPostPrivate, isPublic, messageFromWorkflow]);

  return (
    <Alert
      my={2}
      py={1}
      status="warning"
      borderRadius={"md"}
      bgColor={alertBgColor}
      justifyContent="center"
    >
      {message}
    </Alert>
  );
};

export const EventCommentAlert: FC<CommentAlertMessageProps> = ({
  setCanPostPrivate,
}) => {
  const alertBgColor = useColorModeValue("orange.200", "orange.600");
  useEffect(() => {
    setCanPostPrivate(true);
  }, [setCanPostPrivate]);

  return (
    <Alert
      my={2}
      py={1}
      status="warning"
      borderRadius={"md"}
      bgColor={alertBgColor}
      justifyContent="center"
    >
      {DEFAULT_MESSAGE}
    </Alert>
  );
};
