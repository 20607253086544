import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiEventGroup,
  ApiEventGroupSumary,
  UpdateApiEvent,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { MdGroups } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { AccountSearchBox } from "../../components/inputs/SearchBox";
import { useShowToast } from "../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../store";
import { loadEventGroups, updateFilters } from "../../store/event-group-slice";
import { updateEvent } from "../../store/events/event-details.slice";
import { updateEventList } from "../../store/events/event-list.slice";
import { CityStateText } from "../account-settings/event-group-list/EventGroupHelpers";

interface ChangeGroupElements {
  eventGroupInformation?: ApiEventGroup;
  eventId: string | undefined;
  cancel: () => void;
  onClose: () => void;
}

export const ChangeGroup: FC<ChangeGroupElements> = ({
  eventId,
  eventGroupInformation,
  cancel,
  onClose,
}) => {
  const { eventGroupList } = useSelector((state: RootState) => state);
  const { apiClient, currentAccount } = useAuthentication();
  const dispatch = useDispatch();
  const [selectedGroup, setSelectGroup] = useState(
    `${eventGroupInformation?.id ? eventGroupInformation?.id : ""}`,
  );
  const [isInternal, setIsInternal] = useState(
    `${eventGroupInformation?.isInternalGroup ? "internal" : "external"}`,
  );
  const [loaded, setLoaded] = useState(false);
  const thunkDispatch = useThunkDispatch();
  const showToast = useShowToast();
  const bgColorCheckbox = useColorModeValue("white.Alpha.900", "gray.700");
  const colorCheckbox = useColorModeValue("gray.50", "gray.800");

  useEffect(() => {
    thunkDispatch(
      loadEventGroups({
        apiClient,
        accountId: currentAccount.id,
      }),
    );
  }, [
    apiClient,
    currentAccount.id,
    eventGroupList.filters.isInternalGroup,
    eventGroupList.filters.search,
    thunkDispatch,
  ]);

  useEffect(() => {
    if (
      eventGroupList.filters.isInternalGroup !== undefined &&
      loaded === false
    ) {
      setLoaded(true);
    }
    dispatch(updateFilters({ isInternalGroup: isInternal === "internal" }));
  }, [isInternal, dispatch, eventGroupList.filters.isInternalGroup, loaded]);

  const onSearchGroups = useCallback(
    (searchValue: string) => {
      dispatch(updateFilters({ search: searchValue }));
    },
    [dispatch],
  );

  const handleChangeGroup = useCallback(
    (value: string) => {
      const eventGroup = eventGroupList.data.find((e) => e.id === value);
      if (!eventGroup) return;
      const event: UpdateApiEvent = {
        eventGroup: {
          ...eventGroup,
          rateGroupId: eventGroup?.rateGroup?.id || null,
        } as ApiEventGroupSumary,
      };
      if (eventId && eventGroup) {
        thunkDispatch(
          updateEvent({
            eventId,
            accountId: currentAccount.id,
            apiClient,
            event,
          }),
        )
          .then(() => {
            dispatch(
              updateEventList({
                eventId,
                eventGroup: { ...eventGroup, rateGroupId: null },
              }),
            );
            showToast("success", "The Group has been changed successfuly");
          })
          .catch((err) => {
            showToast("error", "Error changing Group");
          });
      }
      dispatch(updateFilters({ isInternalGroup: undefined }));
      onClose();
      cancel();
    },
    [
      apiClient,
      cancel,
      currentAccount,
      dispatch,
      eventGroupList,
      eventId,
      onClose,
      thunkDispatch,
      showToast,
    ],
  );

  const handleOnchangeGroup = useCallback((groupId: string) => {
    setSelectGroup(groupId);
  }, []);

  const handleIsInternal = useCallback((value: "internal" | "external") => {
    setIsInternal(value);
    setSelectGroup("");
  }, []);

  const handleCancel = useCallback(() => {
    setSelectGroup(
      `${eventGroupInformation?.id ? eventGroupInformation?.id : ""}`,
    );
    cancel();
  }, [cancel, eventGroupInformation?.id]);

  return (
    <VStack m={6} align="stretch" key={eventGroupInformation?.id}>
      <Box>
        <Text fontWeight="bold" fontSize={18} mb={3}>
          Change group
        </Text>
      </Box>
      <Box>
        <RadioGroup onChange={handleIsInternal} value={isInternal}>
          <Stack direction="row">
            <Radio value="internal">Internal</Radio>
            <Radio value="external">External</Radio>
          </Stack>
        </RadioGroup>
        <Text fontWeight="normal" color="gray.700" fontSize={12} mt={3}>
          Group Name
        </Text>
        <AccountSearchBox
          searchPlaceholder="Search groups"
          onInputChange={(e) => onSearchGroups(e)}
        />
      </Box>

      <Divider border={1} color="gray.500" />

      <Box>
        <VStack
          divider={<Divider border={1} color="gray.500" />}
          align="stretch"
        >
          {loaded ? (
            eventGroupList.data.map((group) => {
              return (
                <Box
                  key={group.id}
                  bgColor={
                    selectedGroup !== group.id ? bgColorCheckbox : colorCheckbox
                  }
                  p={3}
                >
                  <Checkbox
                    id={group.id}
                    name={group.name}
                    value={group.id}
                    mr={5}
                    onChange={() => handleOnchangeGroup(group.id)}
                    isChecked={selectedGroup === group.id}
                  >
                    <Flex>
                      <Icon as={MdGroups} boxSize={6} mr={2} />
                      <Text fontWeight="bold">{group.name}</Text>
                    </Flex>
                    <CityStateText
                      city={group.contactInfo.city}
                      state={group.contactInfo.state}
                    />
                  </Checkbox>
                </Box>
              );
            })
          ) : (
            <Spinner />
          )}
        </VStack>
      </Box>

      <Divider border={1} color="gray.500" />

      <Box>
        <Button
          variant="outline"
          borderColor="blue.500"
          onClick={handleCancel}
          mt={5}
        >
          Cancel
        </Button>
        <Button
          colorScheme="blue"
          float="right"
          disabled={
            selectedGroup === "" || selectedGroup === eventGroupInformation?.id
          }
          onClick={() => handleChangeGroup(selectedGroup)}
          mt={5}
        >
          Change Group
        </Button>
      </Box>
    </VStack>
  );
};
