import { Box, Text } from "@chakra-ui/react";
import { ApiVenueSummary } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo, useState } from "react";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { LocationTwoLine } from "../badges/LocationTwoLine";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

export interface VenueAutocompleteProps {
  name?: string;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: ApiVenueSummary | null;
  options?: ApiVenueSummary[];
  selectedValues?: ApiVenueSummary[];
  showAsLocationTwoLine?: boolean;
  onChange: (venue: ApiVenueSummary | null) => void;
  uses?: string[];
}

const VenueBadge = ({ value }: { value: ApiVenueSummary }) => {
  return <Text>{value.name}</Text>;
};

const CustomOptionComponent = createOptionComponent(VenueBadge);

const CustomSingleValueComponent = (
  props: SingleValueSelect<ApiVenueSummary>,
  showAsLocationTwoLine?: boolean,
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      {showAsLocationTwoLine ? (
        <LocationTwoLine value={data.location} />
      ) : (
        <VenueBadge value={data} />
      )}
    </Box>
  );
};

export const VenueAutocomplete = ({
  value,
  onChange,
  allowEmpty = true,
  isInvalid,
  isDisabled,
  placeholder,
  options,
  selectedValues,
  showAsLocationTwoLine,
  uses,
}: VenueAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [input, setInput] = useState("");

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      if (options !== undefined && inputValue === input) {
        cb(options);
        return;
      }
      setInput(inputValue);
      apiClient
        .findVenues(currentAccount.id, {
          search: inputValue,
          pageSize: 50,
          uses,
        })
        .then((response) => {
          let data = response.data;
          if (selectedValues) {
            data = data.filter(
              (item) => !selectedValues.some((sv) => sv.id === item.id),
            );
          }
          cb(data);
        });
    },
    [apiClient, currentAccount.id, input, options, selectedValues, uses],
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const handleChange = useCallback(
    (newValue: ApiVenueSummary | null) => {
      if (allowEmpty !== true && newValue == null) {
        return;
      }
      onChange(newValue);
    },
    [onChange, allowEmpty],
  );

  const getOptionValue = useCallback((venue: ApiVenueSummary) => venue.id, []);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiVenueSummary>) =>
        CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiVenueSummary>) =>
        CustomSingleValueComponent(props, showAsLocationTwoLine),
    };
  }, [showAsLocationTwoLine]);

  return (
    <AsyncSelect
      key={selectedValues?.length}
      name="venueAutocomplete"
      defaultOptions={true}
      chakraStyles={commonStyles}
      value={value}
      cacheOptions={true}
      getOptionValue={getOptionValue}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      isClearable={false}
      isInvalid={isInvalid}
      isMulti={false}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};
