import {
  Box,
  HStack,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ExportType } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { IoDownloadOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { ExportModal } from "../../components/export/ExportModal";
import { RootState } from "../../store";
import { getTransactionFilter } from "../../store/transaction-list.slice";

export const TransactionActionFilters = () => {
  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();
  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = useMemo(
    () => (!isMobileMode && !isTablet ? true : false),
    [isMobileMode, isTablet]
  );
  const { totalTransactions, filters, sort } = useSelector(
    (state: RootState) => state.transactionList
  );

  const { workflows } = useSelector((state: RootState) => state.localCache);
  const options = getTransactionFilter(sort, filters, workflows);
  return (
    <Box>
      <HStack>
        <IconButton
          colorScheme="blue"
          aria-label="Export"
          variant="outline"
          icon={<IoDownloadOutline />}
          onClick={onExportModalOpen}
          size={isDesktop ? "md" : "sm"}
        />
      </HStack>
      {/* export modal, this modal require remount to reset state */}
      {isExportModalOpen && (
        <ExportModal
          isOpen={isExportModalOpen}
          onClose={onExportModalClose}
          total={totalTransactions}
          options={options}
          type={ExportType.transaction}
        />
      )}
    </Box>
  );
};
