import {
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiUser } from "@operations-hero/lib-api-client";
import { eachDayOfInterval, format } from "date-fns";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { StatusBadge } from "../../components/badges/StatusBadge";
import { UserTimeSheetsAutocomplete } from "../../components/selects/UserTimeSheetsAutocomplete";
import { useQueryStringFilter } from "../../hooks/useQueryStringFilter";
import { RootState, useThunkDispatch } from "../../store";
import {
  DATE_FORMAT_TIMESHEET,
  loadTimesheet,
} from "../../store/timesheet.slice";
import { AccountModal } from "../account-settings/account-modal/AccountModal";
import { RequestInfo } from "../calendar/requests/RequestInfo";
import { RequestModalHeader } from "../calendar/requests/RequestInfoHeader";
import { getFormatedHour } from "../request-form/transactions/transactions-helper";
import { CalendarWeekPicker } from "./CalendarWeekTimesheet";
import { HourLaborTimesheet } from "./HourLaborTimesheet";
import "./Timesheet.css";
import {
  timesheetDefaultFilters,
  TimesheetFiltersValues,
} from "./TimesheetFilters";

const DATE_FORMAT_DAY = "EEE dd";
const DATE_FORMAT_ELEMENT = "yyyy-MM-dd";

export const Timesheet = () => {
  const { currentAccount, apiClient, currentUser } = useAuthentication();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const thunkDispatch = useThunkDispatch();
  const { filter: queryFilters, updateQueryString } = useQueryStringFilter({
    defaultValue: timesheetDefaultFilters,
  });

  const [user, setUser] = useState<ApiUser | null>(currentUser);
  const [reqKey, setReqKey] = useState<string>("");
  const policyMap = useSelector(
    (state: RootState) => state.localCache.policyMap
  );

  const workflows = useMemo(() => {
    const values: string[] = [];
    Object.entries(policyMap).forEach((data) => {
      if (data[1].admin) values.push(data[0]);
    });
    return values;
  }, [policyMap]);

  const { requestsMap, transactionsMap } = useSelector(
    (state: RootState) => state.timesheet
  );

  const updateFilters = useCallback(
    (values: Partial<TimesheetFiltersValues>) => {
      const updatedFilters: TimesheetFiltersValues = {
        ...queryFilters,
        ...values,
      };
      updateQueryString({ ...updatedFilters });
    },
    [queryFilters, updateQueryString]
  );

  const { values } = queryFilters;

  const dateRange = useMemo(
    () =>
      eachDayOfInterval({
        start: new Date(values[0]?.start?.toLocaleString() ?? ""),
        end: new Date(values[1]?.start?.toLocaleString() ?? ""),
      }),
    [values]
  );

  const handleOnOpenModal = useCallback(
    (reqKey: string) => {
      setReqKey(reqKey);
      onOpen();
    },
    [setReqKey, onOpen]
  );

  const calculateTotalColumn = useCallback(
    (reqKey: string) => {
      let total = 0;
      dateRange.forEach((date) => {
        const key = `${reqKey}::${format(date, DATE_FORMAT_TIMESHEET)}`;
        total += transactionsMap[key]
          ? transactionsMap[key].reduce(
              (acc, current) => acc + current.hours,
              0
            )
          : 0;
      });
      return getFormatedHour(total || 0);
    },
    [transactionsMap, dateRange]
  );

  const calculateTotalColumnDay = useCallback(
    (date: Date) => {
      let total = 0;
      const dateKey = format(date, DATE_FORMAT_TIMESHEET);

      const trKeys = Object.keys(transactionsMap).filter((trKey) =>
        trKey.includes(dateKey)
      );

      trKeys.forEach((trKey) => {
        total += transactionsMap[trKey]
          ? transactionsMap[trKey].reduce(
              (acc, current) => acc + current.hours,
              0
            )
          : 0;
      });
      return getFormatedHour(total || 0);
    },
    [transactionsMap]
  );

  const calculateTotalLabel = useMemo(() => {
    const total = transactionsMap ? Object.values(transactionsMap).flat() : [];
    return getFormatedHour(
      total.reduce((acc, current) => acc + current.hours, 0) || 0
    );
  }, [transactionsMap]);

  useEffect(() => {
    thunkDispatch(
      loadTimesheet({
        apiClient,
        accountId: currentAccount.id,
        userId: user ? user.id : currentUser.id,
        weekSelected: [
          format(
            new Date(values[0]?.start?.toLocaleString() ?? ""),
            DATE_FORMAT_ELEMENT
          ),
          format(
            new Date(values[1]?.start?.toLocaleString() ?? ""),
            DATE_FORMAT_ELEMENT
          ),
        ],
      })
    );
  }, [apiClient, currentAccount, thunkDispatch, values, user, currentUser.id]);

  return (
    <Container maxW="8xl" mt={2}>
      {requestsMap && transactionsMap && (
        <Fragment>
          <Flex
            flexDirection={["column", "column", "row"]}
            w="100%"
            justifyContent="space-between"
          >
            <Flex>
              <Heading size="lg">Timesheet</Heading>
              {workflows.length > 0 && (
                <Stack minW="300px" marginLeft={6}>
                  <UserTimeSheetsAutocomplete
                    value={user}
                    onChange={setUser}
                    workflowIdsOrSlugs={workflows}
                  />
                </Stack>
              )}
            </Flex>
            <CalendarWeekPicker values={values} setValues={updateFilters} />
          </Flex>
          <Text my={4}>Add your labor hours of the week to your Requests</Text>

          <TableContainer>
            <Table>
              <Tbody>
                <Tr w="100%" border="none">
                  <Td border="none" fontWeight="bold">
                    Requests ({Object.keys(requestsMap).length})
                  </Td>
                  {dateRange.map((date) => (
                    <Td
                      key={`${date.getDate()}`}
                      fontWeight="bold"
                      textAlign="center"
                      alignItems="center"
                      border="none"
                    >
                      <Text>{format(date, DATE_FORMAT_DAY)}</Text>
                      <Text>({calculateTotalColumnDay(date)})</Text>
                    </Td>
                  ))}
                  <Td
                    border="none"
                    fontWeight="bold"
                    textAlign="center"
                    alignItems="center"
                  >
                    <Text>Total</Text>
                    <Text>({calculateTotalLabel})</Text>
                  </Td>
                </Tr>
                {Object.keys(requestsMap).map((reqKey) => (
                  <Tr key={`${reqKey}`} w="100%">
                    <Td fontWeight="bold" p={4}>
                      <VStack width="100%">
                        <HStack width="100%" justifyContent="space-between">
                          <Link
                            onClick={() => handleOnOpenModal(reqKey)}
                            paddingRight={4}
                          >
                            {requestsMap[reqKey].key}
                          </Link>
                          <StatusBadge status={requestsMap[reqKey].status} />
                        </HStack>
                        <HStack width="100%" justifyContent="space-between">
                          <Text fontWeight="normal">
                            {requestsMap[reqKey].reportingCategory?.name}
                          </Text>
                          <Text fontWeight="normal">
                            {requestsMap[reqKey].location?.name}
                          </Text>
                        </HStack>
                      </VStack>
                    </Td>
                    {dateRange.map((date) => (
                      <Td
                        key={`${date.getDate()}`}
                        p={4}
                        fontWeight="bold"
                        verticalAlign="middle"
                        textAlign="center"
                      >
                        <HourLaborTimesheet
                          date={date}
                          reqKey={reqKey}
                          user={user || currentUser}
                        />
                      </Td>
                    ))}
                    <Td
                      p={4}
                      fontWeight="bold"
                      verticalAlign="middle"
                      textAlign="center"
                    >
                      {calculateTotalColumn(reqKey)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Text mt={8} textAlign="left" fontWeight="bold">
            {`Total week hours: ${calculateTotalLabel}`}
          </Text>
        </Fragment>
      )}
      {isOpen && requestsMap[reqKey] && (
        <AccountModal
          content={
            <RequestInfo request={requestsMap[reqKey]} onCloseModal={onClose} />
          }
          title={<RequestModalHeader request={requestsMap[reqKey]} />}
          contentProps={{ maxW: "xl" }}
          onClose={onClose}
          isOpen={isOpen}
          closeButton={false}
        />
      )}
    </Container>
  );
};
