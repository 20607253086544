import { IconButton, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";

type CalendarButtonProps = {};

export const CalendarButton: FC<CalendarButtonProps> = () => {
  return (
    <Tooltip label="Calendar" hasArrow gutter={12}>
      <IconButton
        aria-label="Calendar"
        icon={<RiCalendarScheduleLine />}
        size="sm"
        fontSize="18px"
        variant="outline"
        colorScheme="blue"
        as={RouterLink}
        to={"/calendar"}
      />
    </Tooltip>
  );
};
