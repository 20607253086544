import {
  ApiAccount,
  ApiClient,
  ApiReason,
  ApiReasonType,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface LocalReasonSliceState {
  reasons: ApiReason[];
  reasonsPageSize: number;
  showDisabledReasons: boolean;
  type: string | undefined;
}
export type ReasonActionType = "deactive" | "reactive";

export interface ToggleOnOrOffReasonThunkParams {
  apiClient: ApiClient;
  accountId: string;
  reasonId: string;
  type: ReasonActionType;
}
export interface InitLocalReasonThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
  includeInactive?: boolean;
  type?: string | undefined;
}

export const toggleActivateReason = createAsyncThunk(
  "reason/toogleActive",
  async (params: ToggleOnOrOffReasonThunkParams, thunkAPI) => {
    const { apiClient, accountId, reasonId, type } = params;
    if (type === "deactive") {
      await apiClient.deactivateReason(accountId, reasonId);
    }
    if (type === "reactive") {
      await apiClient.reactivateReason(accountId, reasonId);
    }
    return { reasonId, type };
  }
);

export const reloadReasons = createAsyncThunk(
  "reason/reload-reasons",
  async (
    { apiClient, account, search }: InitLocalReasonThunkParams,
    thunkAPI
  ) => {
    const { showDisabledReasons, reasonsPageSize, type } = (
      thunkAPI.getState() as RootState
    ).reason;
    const reasonsSearch = await apiClient.findReasons(account.id, {
      includeInactive: showDisabledReasons,
      pageSize: reasonsPageSize,
      search: search,
      type: type as ApiReasonType,
    });
    return { reasonsSearch };
  }
);

export const loadReasonsFromCache = createAsyncThunk(
  "reason/load-reasons",
  async (
    { apiClient, account, search }: InitLocalReasonThunkParams,
    thunkAPI
  ) => {
    const { reasons } = (thunkAPI.getState() as RootState).localCache;
    return { reasons: reasons };
  }
);

export const localReasonSlice = createSlice({
  name: "reason",
  initialState: {
    reasons: [],
    type: "",
    reasonsPageSize: 100,
    showDisabledReasons: false,
  } as LocalReasonSliceState,
  reducers: {
    unloadCache: (state) => {
      state.reasons = [];
    },
    addReason: (state, action: PayloadAction<ApiReason>) => {
      if (state.type === ApiReasonType.system) {
        return;
      }
      action.payload.active = true;
      state.reasons.unshift(action.payload);
    },
    updateReason: (state, action: PayloadAction<ApiReason>) => {
      const index = state.reasons.findIndex((x) => x.id === action.payload.id);
      if (index !== -1) {
        state.reasons[index] = action.payload;
      }
    },
    toggleShowDisabledReasons: (state, action: PayloadAction<boolean>) => {
      state.showDisabledReasons = action.payload;
    },
    setReasonTypeFilter: (
      state,
      payload: PayloadAction<string | undefined>
    ) => {
      state.type = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReasonsFromCache.fulfilled, (state, action) => {
      state.reasons = action.payload.reasons;
    });
    builder.addCase(reloadReasons.fulfilled, (state, action) => {
      state.reasons = action.payload.reasonsSearch.data;
    });
    builder.addCase(toggleActivateReason.fulfilled, (state, action) => {
      const { type, reasonId } = action.payload;
      const indexToRemove = state.reasons.findIndex(
        (reason: ApiReason) => reason.id === reasonId
      );
      state.reasons[indexToRemove].active = type === "reactive" ? true : false;
      if (
        indexToRemove !== -1 &&
        state.showDisabledReasons === false &&
        type === "deactive"
      ) {
        state.reasons[indexToRemove].active = false;
        state.reasons.splice(indexToRemove, 1);
      }
    });
  },
});

export const {
  unloadCache,
  addReason,
  updateReason,
  toggleShowDisabledReasons,
  setReasonTypeFilter,
} = localReasonSlice.actions;

export default localReasonSlice.reducer;
