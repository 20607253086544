import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../store";
import {
  reSendCode,
  setResendStatus,
} from "../../store/user-phone-verifications";
import { useAuthentication } from "../auth/AuthProvider";
import { NumberInputControl } from "../form-helpers/NumberInputControl";
import { PhoneVerificationFormParams } from "./PhoneVerificationForm";
import ResendCountdown from "./ResendCountdown";

export type StepTwoProps = {
  values: PhoneVerificationFormParams;
  isResendDisabled?: boolean;
};

type StepTwoSubmitButtonProps = {
  isLoading: boolean;
  onClick: () => void;
  isDisabled: boolean;
};

export const StepTwo: FC<StepTwoProps> = ({
  values,
  isResendDisabled = false,
}) => {
  const { apiClient } = useAuthentication();
  const { resendStatus, phone } = useSelector(
    (root: RootState) => root.phoneVerificationSlice
  );

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const onCountDownFinish = useCallback(() => {
    dispatch(setResendStatus("finish"));
  }, [dispatch]);

  const handleOnClick = useCallback(() => {
    thunkDispatch(reSendCode({ apiClient }));
  }, [apiClient, thunkDispatch]);

  const maskedPhone = useMemo(() => {
    if (!phone || phone.e164.length < 2) {
      return "";
    }
    const phoneNumber = phone.e164.slice(1, phone.e164.length);
    const numDigitsToMask = phoneNumber.length - 2;
    const mask = "*".repeat(numDigitsToMask);
    return mask + phoneNumber.slice(-2);
  }, [phone]);

  return (
    <VStack gap="2" w="full" align="stretch">
      <Text>
        A text message with verification code has been sent to your phone{" "}
        {maskedPhone}
      </Text>
      <NumberInputControl name="code" value={values.code} label="Enter code" />
      {resendStatus === "started" ? (
        <ResendCountdown duration={60} onFinish={onCountDownFinish} />
      ) : (
        <HStack gap="unset" alignContent="center">
          <Text lineHeight="unset">Didn't get the code?</Text>
          <Button
            variant="link"
            onClick={handleOnClick}
            colorScheme="blue"
            lineHeight="unset"
            isDisabled={isResendDisabled}
          >
            Resend
          </Button>
        </HStack>
      )}
    </VStack>
  );
};

export const StepTwoSubmitButton: FC<StepTwoSubmitButtonProps> = ({
  isLoading,
  onClick,
  isDisabled,
}) => {
  return (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      isDisabled={isDisabled}
      colorScheme="blue"
    >
      Verify
    </Button>
  );
};
