import { Heading, VStack } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  GridItem,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useSelector } from "react-redux";
import { useShowToast } from "../../../hooks/showToast";
import { RootState } from "../../../store";
import { DeactivateActivate } from "../projects/actions/DeactivateActivate";
import { EditProjectForm } from "./EditProjectForm";
import { ProjectComments } from "./ProjectComments";
import ProjectDetailsSkeleton from "./ProjectDetailsSkeleton";
import { ProjectTimeline } from "./timeline/ProjectTimeline";
import { ProjectTimelineSummary } from "./timeline/ProjectTimelineSummary";

export const ProjectDetails = () => {
  const { project, projectLoadingStatus } = useSelector(
    (state: RootState) => state.projectPageSlice
  );
  const { workingProject } = useSelector(
    (state: RootState) => state.projectFormSlice
  );

  const [active, setActive] = useState<boolean>(true);

  const { isOpen, onToggle } = useDisclosure();
  const toast = useShowToast();

  const onActivateDeactivate = useCallback(
    (active: boolean) => {
      setActive(active);

      if (active) {
        toast("success", "Project reactivated");
      } else {
        toast("success", "Project deactivated");
      }
    },
    [toast]
  );

  useEffect(() => {
    if (projectLoadingStatus === "fulfilled") {
      setActive(!!project?.active);
    }
  }, [projectLoadingStatus, project]);

  const commentsBgColor = useColorModeValue("blue.50", "blue.900");

  return (
    <Grid
      gridTemplateColumns="repeat(5, 1fr)"
      gridTemplateRows="repeat(4, min-content)"
      columnGap={10}
      rowGap={4}
    >
      <GridItem rowStart={1} colSpan={5}>
        <VStack w="full" align="stretch" gap="3">
          {active === false && (
            <Alert status="info">
              <AlertIcon />
              This project has been deactivated and can no longer be edited.
            </Alert>
          )}
        </VStack>
      </GridItem>
      <GridItem rowStart={2} colSpan={3}>
        <Heading as="h3" size="md">
          About the project
        </Heading>
      </GridItem>
      <GridItem colSpan={[5, 5, 3]} rowSpan={[1, 1, 1]} rowStart={[3, 3, 3]}>
        {projectLoadingStatus === "pending" && <ProjectDetailsSkeleton />}
        <Stack flexDir="column" gap={7} w="full" align="stretch">
          {projectLoadingStatus === "rejected" && (
            <Text>Could not load information</Text>
          )}
          {projectLoadingStatus === "fulfilled" && project && (
            <VStack w="full" align="stretch" gap="3">
              <EditProjectForm
                project={project}
                isDisabled={active === false}
              />
            </VStack>
          )}
        </Stack>
      </GridItem>
      <GridItem rowStart={[5, 5, 4]} colSpan={[5, 5, 3]}>
        <Stack flexDir="column" gap={7} w="full" align="stretch" my={4}>
          {workingProject && (
            <>
              <VStack w="full" align="stretch" gap="3">
                <Heading as="h3" size="md">
                  Summary Projects Timeline
                </Heading>
                <Box w="full">
                  <ProjectTimelineSummary project={workingProject} />
                </Box>
              </VStack>

              <VStack w="full" align="stretch" gap="3">
                <Heading
                  as="h3"
                  size="md"
                  onClick={onToggle}
                  display="flex"
                  w="max-content"
                  cursor="pointer"
                  alignItems="center"
                >
                  Project Timeline
                  <Icon as={isOpen ? MdArrowDropUp : MdArrowDropDown} />
                </Heading>
                <Collapse in={isOpen} unmountOnExit>
                  <ProjectTimeline project={workingProject} />
                </Collapse>
              </VStack>
            </>
          )}
        </Stack>
      </GridItem>

      <GridItem
        colSpan={[5, 5, 2]}
        colStart={[1, 1, 4]}
        rowSpan={[1, 1, 2]}
        rowStart={[4, 4, 3]}
      >
        {workingProject && (
          <VStack
            w="full"
            align="stretch"
            gap="3"
            bg={commentsBgColor}
            borderRadius="md"
            p={5}
          >
            <Heading as="h3" size="md">
              Comments
            </Heading>
            <Box w="full">
              <ProjectComments project={workingProject} />
            </Box>
          </VStack>
        )}
      </GridItem>

      <GridItem colStart={4} rowStart={2} colSpan={2}>
        <Flex w="full" justifyContent="end">
          {project && (
            <DeactivateActivate
              project={project}
              onActivateDeactivate={onActivateDeactivate}
            >
              {({ onOpen, active }) => (
                <Button
                  colorScheme={active ? "red" : "blue"}
                  size="sm"
                  onClick={onOpen}
                >
                  {active ? "Deactivate Project" : "Reactivate Project"}
                </Button>
              )}
            </DeactivateActivate>
          )}
        </Flex>
      </GridItem>
    </Grid>
  );
};
