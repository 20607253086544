import { ApiWorkflowField } from "@operations-hero/lib-api-client";

export function getVisibleFields(visibleFields: ApiWorkflowField[]) {
  return {
    showCategory: visibleFields.some(
      (field) => field.key === "SYSTEM-CATEGORY"
    ),
    showReason: visibleFields.some((field) => field.key === "SYSTEM-REASON"),
    showComments: visibleFields.some(
      (field) => field.key === "SYSTEM-COMMENTS"
    ),
    showStartDate: visibleFields.some(
      (field) => field.key === "SYSTEM-START-DATE"
    ),
    showDueDate: visibleFields.some((field) => field.key === "SYSTEM-DUE-DATE"),
    showBudgetOnRequests: visibleFields.some(
      (field) => field.key === "SYSTEM-BUDGET-ON-REQUESTS"
    ),
    showExpenses: visibleFields.some(
      (field) => field.key === "SYSTEM-EXPENSES"
    ),
    showEstimatedCost: visibleFields.some(
      (field) => field.key === "SYSTEM-ESTIMATED-COST"
    ),
    showLabor: visibleFields.some((field) => field.key === "SYSTEM-LABOR"),
    showEstimatedLabor: visibleFields.some(
      (field) => field.key === "SYSTEM-ESTIMATED-LABOR"
    ),
    showTaskbooks: visibleFields.some(
      (field) => field.key === "SYSTEM-TASKBOOKS"
    ),
    showAssets: visibleFields.some((field) => field.key === "SYSTEM-ASSETS"),
    showAssignTo: visibleFields.some(
      (field) => field.key === "SYSTEM-ASSIGN-TO"
    ),
    showProject: visibleFields.some((field) => field.key === "SYSTEM-PROJECT"),
  };
}
