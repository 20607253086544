import { RequestListFilterState } from "../../../../../../store/request-list.slice";

export const FilterDisplayName: {
  [key in keyof Partial<RequestListFilterState>]: string;
} = {
  workflows: "workflow",
  statuses: "status",
  locations: "location",
  persons: "person",
  categories: "category",
  assets: "asset",
  reasons: "reasons",
  types: "types",
  scheduledRequest: "schedules",
  events: "events",
  services: "services",
};

export const RequestDateFilters = [
  "start",
  "due",
  "completed",
  "statusUpdated",
  "created",
  "updated",
] as const;

export type RequestDateFilter = (typeof RequestDateFilters)[number];
