import {
  Alert,
  AlertIcon,
  Divider,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiEventBlock } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { DateBadge } from "../../../../components/badges/DateBadge";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";

interface BlockedVenueOrSpacesAlertProps {
  data: ApiEventBlock[];
}

export const BlockedVenueOrSpacesAlert: FC<BlockedVenueOrSpacesAlertProps> = ({
  data,
}) => {
  const conflictBgColor = useColorModeValue("orange.50", "orange.600");
  const textColor = useColorModeValue("orange.500", "white");

  return (
    <Alert
      status="warning"
      color={textColor}
      borderRadius={6}
      bgColor={conflictBgColor}
    >
      <AlertIcon />
      <Flex flexDir="column" alignItems="flex-start" gap={2} w="100%">
        <Heading fontSize="md">
          Your selected venues or spaces are blocked for the following dates:
        </Heading>
        <Flex w="100%" wrap="wrap">
          {data.map((item) => {
            return (
              <>
                <Flex w="100%" gap={4} flexDir={["column", "column", "row"]}>
                  <Flex
                    gap={2}
                    // alignItems="center"
                    justifyContent="space-between"
                    w={["100%", "100%", "25%"]}
                  >
                    <Text as="span">{capitalizeFirstLetter(item.type)}</Text>
                    {"venue" in item && (
                      <Text as="span">{item.venue.name}</Text>
                    )}
                    {"space" in item && (
                      <Text as="span">{item.space.location.name}</Text>
                    )}
                  </Flex>

                  <Flex
                    gap={[2, 2, 0]}
                    flex={1}
                    wrap="wrap"
                    flexDir={["column", "column", "row"]}
                  >
                    {item.datesToBlock.map(({ start, end }) => (
                      <Flex
                        w="100%"
                        justifyContent="space-between"
                        flexDir={["column", "column", "row"]}
                      >
                        <DateBadge
                          date={new Date(start)}
                          showRelative={false}
                        />

                        <Text display={["none", "none", "block"]}>-</Text>
                        <DateBadge date={new Date(end)} showRelative={false} />
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
                <Divider my={2} />
              </>
            );
          })}
          <Text w="100%" textAlign="right" mt={1}>
            Please, try changing dates or venue/spaces!
          </Text>
        </Flex>
      </Flex>
    </Alert>
  );
};
