import {
  Box,
  Button,
  Fade,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ApiRequestStatus } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoWarning } from "react-icons/io5";
import { useSelector } from "react-redux";
import { BulkValues } from "../../../pages/requests/bulk-edit/BulkEditModal";
import { RootState, useThunkDispatch } from "../../../store";
import {
  runSchemaValidationAsync,
  setBulkEditStep,
  setValidationStatus,
} from "../../../store/request-form/request-bulk-actions.slice";
import { isObjectEmpty } from "../../../utils/compareObjects";
import { LoadingText } from "../../loading-text/LoadingText";
import { RequestStatusSelect } from "../../selects/RequestStatusSelect";

export interface RequestStatusSelectControlProps {
  label?: string;
  name: string;
  value: ApiRequestStatus | null;
  showError?: boolean;
}

export const RequestStatusSelectControl = ({
  name,
  value,
  label,
  showError,
}: RequestStatusSelectControlProps) => {
  const { submitCount, errors } = useFormikContext<BulkValues>();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const {
    workflow,
    nonCompliantRequests,
    enabledStatusList,
    validationStatus,
    nonCompliantRequestsByField,
  } = useSelector((root: RootState) => root.requestsBulkActionsSlice);

  const [showVerifyingMessage, setShowVerifyingMessage] = useState(false);

  const dispatch = useThunkDispatch();

  const handleOnChange = useCallback(
    (status: ApiRequestStatus | null) => {
      helper.setTouched(true);
      helper.setValue(status || null);
      dispatch(setValidationStatus("started"));
    },
    [helper, dispatch]
  );

  const handleViewDetails = useCallback(() => {
    dispatch(setBulkEditStep("change-selection"));
  }, [dispatch]);

  const enabledStatuses = useMemo(() => {
    if (!enabledStatusList) return [];

    const statusList: Array<ApiRequestStatus> = [ApiRequestStatus.hold];

    const { canCancel, statuses } = enabledStatusList;

    if (canCancel) statusList.push(ApiRequestStatus.canceled);

    statusList.push(...statuses);

    return statusList;
  }, [enabledStatusList]);

  useEffect(() => {
    if (validationStatus === "started") {
      if (value) dispatch(runSchemaValidationAsync({ status: value }));
    }

    if (validationStatus === "in-progress" || validationStatus === "started") {
      setShowVerifyingMessage(true);
    }

    if (validationStatus === "success" || validationStatus === "failed") {
      setShowVerifyingMessage(false);
    }
  }, [validationStatus, dispatch, value]);

  return (
    <>
      <FormControl
        isInvalid={!!meta.error && (meta.touched || submitCount > 0)}
      >
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <RequestStatusSelect
          {...field}
          workflow={workflow}
          status={field.value}
          statuses={enabledStatuses || []}
          onChange={handleOnChange}
        />
        {showVerifyingMessage && (
          <Box fontSize="sm" color="gray.500" mt="4">
            <Fade in={showVerifyingMessage}>
              <LoadingText text="Verifying" />
            </Fade>
          </Box>
        )}
        {validationStatus === "success" &&
          nonCompliantRequestsByField.length === 0 && (
            <Fade in={validationStatus === "success"}>
              <Text fontSize="sm" color="gray.500" mt="4">
                All requests passed validation
              </Text>
            </Fade>
          )}
        {nonCompliantRequestsByField.length > 0 &&
          nonCompliantRequestsByField.some(
            (field) => field[0].key.substring(0, 7) !== "SYSTEM"
          ) && (
            <VStack color="gray.500" alignItems="stretch" fontSize="sm" mt="4">
              {(validationStatus === "failed" ||
                validationStatus === "success") && (
                <HStack alignItems="start">
                  <Icon
                    as={IoWarning}
                    color="orange.300"
                    boxSize="5"
                    mt="0.5"
                  />
                  <VStack alignItems="start">
                    <Text>
                      {isObjectEmpty(errors) && !showError
                        ? "Status will be updated alongside required fields on all selected items. Check the non-compliant Requests:"
                        : "Can't change Status due to Required Rules from Schemas on these Requests:"}
                    </Text>
                    <HStack fontWeight="bold">
                      {Object.keys(nonCompliantRequests).length <= 2 ? (
                        Object.keys(nonCompliantRequests).map((requestKey) => (
                          <Text key={`non-compliant::${requestKey}`}>
                            {requestKey}
                          </Text>
                        ))
                      ) : (
                        <>
                          <Text
                            key={`non-compliant::${
                              Object.keys(nonCompliantRequests)[0]
                            }`}
                          >
                            {`${Object.keys(nonCompliantRequests)[0]},`}
                          </Text>
                          <Text
                            key={`non-compliant::${
                              Object.keys(nonCompliantRequests)[1]
                            }`}
                          >
                            {Object.keys(nonCompliantRequests)[1]}
                          </Text>
                          <Text fontWeight="normal">{`+${
                            Object.keys(nonCompliantRequests).length - 2
                          } more`}</Text>
                        </>
                      )}
                      <Button
                        size="xs"
                        onClick={handleViewDetails}
                        variant="link"
                        colorScheme="blue"
                      >
                        View Details
                      </Button>
                    </HStack>
                  </VStack>
                </HStack>
              )}
            </VStack>
          )}

        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </>
  );
};
