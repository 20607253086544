import {
  ApiClient,
  ApiInventoryItemAdjustment,
  ApiItemAdjustmentReason,
  ApiItemAdjustmentType,
  ApiPagedResult,
  ApiPagingInventoryItemAdjustment,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { buildMap } from "../../utils/buildMap";

const DEFAULT_FILTERS: ApiPagingInventoryItemAdjustment = {
  page: 1,
  pageSize: 20,
  direction: "desc",
  type: ApiItemAdjustmentType.issued,
  reason: ApiItemAdjustmentReason.checkout,
  isCheckedOutItem: true,
};
export interface InventoryItemCheckoutCheckinSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: ApiInventoryItemAdjustment[];
  total: number;
  filters: ApiPagingInventoryItemAdjustment;
  requestsMap: Record<string, ApiRequest>;
  dateRangeSelection: string;

  loadingModal: "idle" | "pending" | "succeeded" | "failed";
  dataModal: ApiInventoryItemAdjustment[];
  totalModal: number;
  requestsMapModal: Record<string, ApiRequest>;
  filtersModal: ApiPagingInventoryItemAdjustment;
}
interface LoadInventoryItemCheckoutCheckinParams {
  apiClient: ApiClient;
  accountId: string;
  itemId?: string;
}
export interface InventoryItemCheckoutResponseThunk {
  inventoryItemCheckoutCheckin: ApiPagedResult<ApiInventoryItemAdjustment>;
  requests: ApiRequest[];
}
export const loadInventoryItemCheckoutCheckin = createAsyncThunk(
  "inventory/item-checkout-checkin",
  async (params: LoadInventoryItemCheckoutCheckinParams, thunkAPI) => {
    const { apiClient, accountId, itemId } = params;
    const { filters } = (thunkAPI.getState() as RootState)
      .inventoryItemCheckoutCheckinSlice;

    const mappedFilters: ApiPagingInventoryItemAdjustment = {
      ...filters,
      catalog: Array.isArray(filters.catalog)
        ? filters.catalog.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      item: Array.isArray(filters.item)
        ? filters.item.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : itemId,
      adjuster: Array.isArray(filters.adjuster)
        ? filters.adjuster.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      requester: Array.isArray(filters.requester)
        ? filters.requester.map((requester) =>
            typeof requester === "string" ? requester : requester.id
          )
        : undefined,
      assignedTo: Array.isArray(filters.assignedTo)
        ? filters.assignedTo.map((assignedTo) =>
            typeof assignedTo === "string" ? assignedTo : assignedTo.id
          )
        : undefined,
    };

    const inventoryItemCheckoutCheckin =
      await apiClient.findInventoryItemAdjustments(accountId, mappedFilters);

    const requestIds = inventoryItemCheckoutCheckin.data
      .filter((adj) => adj.requestId)
      .map((adj) => adj.requestId) as string[];

    const requests =
      requestIds.length > 0
        ? (await apiClient.findRequests(accountId, { idsOrKeys: requestIds }))
            .data
        : [];

    return { inventoryItemCheckoutCheckin, requests };
  }
);

export const loadInventoryItemCheckoutCheckinModal = createAsyncThunk(
  "inventory/item-checkout-checkin-modal",
  async (params: LoadInventoryItemCheckoutCheckinParams, thunkAPI) => {
    const { apiClient, accountId, itemId } = params;
    const filters = (thunkAPI.getState() as RootState)
      .inventoryItemCheckoutCheckinSlice.filtersModal;

    const mappedFilters: ApiPagingInventoryItemAdjustment = {
      ...filters,
      catalog: Array.isArray(filters.catalog)
        ? filters.catalog.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      item: Array.isArray(filters.item)
        ? filters.item.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : itemId,
      adjuster: Array.isArray(filters.adjuster)
        ? filters.adjuster.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      requester: Array.isArray(filters.requester)
        ? filters.requester.map((requester) =>
            typeof requester === "string" ? requester : requester.id
          )
        : undefined,
      assignedTo: Array.isArray(filters.assignedTo)
        ? filters.assignedTo.map((assignedTo) =>
            typeof assignedTo === "string" ? assignedTo : assignedTo.id
          )
        : undefined,
    };

    const inventoryItemCheckoutCheckin =
      await apiClient.findInventoryItemAdjustments(accountId, mappedFilters);

    const requestIds = inventoryItemCheckoutCheckin.data
      .filter((adj) => adj.requestId)
      .map((adj) => adj.requestId) as string[];

    const requests =
      requestIds.length > 0
        ? (await apiClient.findRequests(accountId, { idsOrKeys: requestIds }))
            .data
        : [];

    return { inventoryItemCheckoutCheckin, requests };
  }
);

export const inventoryItemCheckoutCheckinSlice = createSlice({
  name: "inventoryItemCheckoutCheckin",
  initialState: {
    data: [],
    total: 0,
    filters: { ...DEFAULT_FILTERS },
    loading: "pending",
    requestsMap: {},
    dateRangeSelection: "",
    dataModal: [],
    totalModal: 0,
    filtersModal: { ...DEFAULT_FILTERS, pageSize: 10 },
    loadingModal: "pending",
    requestsMapModal: {},
  } as InventoryItemCheckoutCheckinSlice,
  reducers: {
    unloadInventoryCheckoutCheckinList: (
      state: InventoryItemCheckoutCheckinSlice
    ) => {
      state.data = [];
      state.total = 0;
      state.requestsMap = {};
      state.filters = { ...DEFAULT_FILTERS };
      state.dateRangeSelection = "";
    },
    unloadInventoryCheckoutCheckinModal: (
      state: InventoryItemCheckoutCheckinSlice
    ) => {
      state.dataModal = [];
      state.totalModal = 0;
      state.requestsMapModal = {};
      state.filtersModal = { ...DEFAULT_FILTERS, pageSize: 10 };
    },
    setCheckoutCheckinFilters: (
      state: InventoryItemCheckoutCheckinSlice,
      action: PayloadAction<Partial<ApiPagingInventoryItemAdjustment>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setCheckoutCheckinFiltersModal: (
      state: InventoryItemCheckoutCheckinSlice,
      action: PayloadAction<Partial<ApiPagingInventoryItemAdjustment>>
    ) => {
      state.filtersModal = { ...state.filtersModal, ...action.payload };
    },
    setDateRange: (
      state: InventoryItemCheckoutCheckinSlice,
      action: PayloadAction<string>
    ) => {
      state.dateRangeSelection = action.payload;
    },
    removeCheckout: (state, action: PayloadAction<string>) => {
      const checkoutIndex = state.data.findIndex(
        (x) => x.id === action.payload
      );
      if (checkoutIndex !== -1) {
        state.data.splice(checkoutIndex, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadInventoryItemCheckoutCheckin.fulfilled,
      (state, action) => {
        const { inventoryItemCheckoutCheckin, requests } = action.payload;

        state.loading = "succeeded";
        state.data = inventoryItemCheckoutCheckin.data;
        state.total = inventoryItemCheckoutCheckin.total;
        state.requestsMap = buildMap(requests);
      }
    );

    builder.addCase(loadInventoryItemCheckoutCheckin.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(loadInventoryItemCheckoutCheckin.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });

    builder.addCase(
      loadInventoryItemCheckoutCheckinModal.fulfilled,
      (state, action) => {
        const { inventoryItemCheckoutCheckin, requests } = action.payload;

        state.loadingModal = "succeeded";
        state.dataModal = inventoryItemCheckoutCheckin.data;
        state.totalModal = inventoryItemCheckoutCheckin.total;
        state.requestsMapModal = buildMap(requests);
      }
    );

    builder.addCase(loadInventoryItemCheckoutCheckinModal.pending, (state) => {
      state.loadingModal = "pending";
    });

    builder.addCase(
      loadInventoryItemCheckoutCheckinModal.rejected,
      (state, action) => {
        state.loadingModal = "failed";
        state.dataModal = [];
      }
    );
  },
});

export const {
  setCheckoutCheckinFilters,
  unloadInventoryCheckoutCheckinList,
  setDateRange,
  setCheckoutCheckinFiltersModal,
  unloadInventoryCheckoutCheckinModal,
  removeCheckout,
} = inventoryItemCheckoutCheckinSlice.actions;

export default inventoryItemCheckoutCheckinSlice.reducer;
