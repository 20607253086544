import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateRequestFilters } from "../../../store/request-list.slice";
import { RequestAdvancedFilters } from "../RequestAdvancedFilters";
import { ViewMode } from "../Requests";
import { BulkHeaderOptions } from "./BulkHeaderOptions";

export interface RequestAdvancedFiltersContainerProps {
  mode?: ViewMode;
  handleSelectAll?: () => void;
}

export const RequestAdvancedFiltersContainer: FC<
  RequestAdvancedFiltersContainerProps
> = ({ mode = "normal", handleSelectAll }) => {
  const bgColorBox = useColorModeValue("blue.50", "blue.900");
  const bgColor = useColorModeValue("white", "whiteAlpha.300");

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = !isMobileMode && !isTablet ? true : false;

  const dispatch = useDispatch();

  const { search } = useSelector(
    (state: RootState) => state.requestList.filters
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateRequestFilters({ search: e.target.value }));
    },

    [dispatch]
  );

  return isDesktop ? (
    <Box bgColor={bgColorBox} p={3} display="flex">
      <Flex width="100%" flexDir="row" gap={2}>
        <Box minW={["100%", "250px"]} display="flex">
          <InputGroup flexGrow={1}>
            <InputLeftElement children={<SearchIcon color="gray.300" />} />
            <Input
              type="text"
              maxH="38px"
              value={search}
              bgColor={bgColor}
              placeholder="Request #/Summary"
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Box>
        {mode === "normal" ? (
          <RequestAdvancedFilters />
        ) : (
          <BulkHeaderOptions handleSelectAll={handleSelectAll} />
        )}
      </Flex>
    </Box>
  ) : (
    <Box p={3} display="flex">
      <Flex width="100%" flexDir="row" gap={2}>
        {mode === "normal" ? (
          <RequestAdvancedFilters />
        ) : (
          <BulkHeaderOptions handleSelectAll={handleSelectAll} />
        )}
      </Flex>
    </Box>
  );
};
