import {
  Box,
  Collapse,
  Divider,
  Flex,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiEvent, ApiEventOccurrence } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ApprovalStateBadge } from "../../../components/badges/event-badges/ApprovalStatesBadges";
import { EventGroupBadge } from "../../../components/badges/event-badges/GroupBadge";
import { EventOwnerBadge } from "../../../components/badges/event-badges/OwnerBadge";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { HoursRange } from "../../../components/dates/HoursRage";
import {
  DEFAULT_LOCALE_DATE_OPTS,
  LocaleDate,
} from "../../../components/dates/LocaleDate";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";

interface EventBasicDetailsProps {
  event: ApiEvent | string;
}

const OCC_PAGE_SIZE = 10;

export const EventBasicDetails: FC<EventBasicDetailsProps> = ({ event }) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const { isOpen, onToggle } = useDisclosure();
  const [workingEvent, setWorkingEvent] = useState<ApiEvent | null>(null);
  const [occurrences, setOccurrences] = useState<ApiEventOccurrence[] | null>(
    null
  );

  const showToast = useShowToast();
  const { apiClient, currentAccount } = useAuthentication();

  const fetchEvent = useCallback(async () => {
    try {
      if (typeof event === "string") {
        const response = await apiClient.getEvent(currentAccount.id, event);
        setWorkingEvent(response);
        return;
      }
      setWorkingEvent(event);
    } catch (error) {
      showToast(
        "error",
        "Something went wrong loading event data, please try again"
      );
    }
  }, [apiClient, currentAccount.id, event, showToast]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  useEffect(() => {
    if (!isOpen || workingEvent === null) return;

    apiClient
      .findEventOccurrences(currentAccount.id, workingEvent.id, {
        page,
        pageSize: OCC_PAGE_SIZE,
      })
      .then((response) => {
        setOccurrences(response.data);
        setTotal(response.total);
      })
      .catch(() => {
        setOccurrences([]);
        showToast("error", "Something went wrong loading event occurrences");
      });
  }, [apiClient, currentAccount.id, isOpen, page, showToast, workingEvent]);

  return workingEvent ? (
    <Flex
      w="100%"
      gap={4}
      flexDir="row"
      flexWrap="wrap"
      key={`${workingEvent.id}`}
      justifyContent="space-between"
    >
      <Box
        display="flex"
        w={["100%", "100%", "30%"]}
        flexDir={["row", "row", "column"]}
        justifyContent="space-between"
      >
        <Text fontWeight="bold" fontSize="lg">
          {workingEvent.name}
        </Text>
        <Box>
          <ApprovalStateBadge
            label={workingEvent.status}
            type={workingEvent.status}
          />
        </Box>
      </Box>

      <EventGroupBadge
        group={workingEvent.eventGroup}
        boxProps={{ py: [2, 2, 0], w: ["100%", "50%", "30%"] }}
      />

      <EventOwnerBadge
        owner={workingEvent.eventGroup.owner}
        boxProps={{ py: [2, 2, 0], w: ["100%", "50%", "30%"] }}
      />

      <Divider />

      <Flex w="100%" flexDir="column" gap={4}>
        <Text
          gap={1}
          cursor="pointer"
          fontWeight="bold"
          onClick={onToggle}
          alignItems="center"
          display="inline-flex"
        >
          Occurrences
          <Icon as={isOpen ? MdKeyboardArrowUp : MdKeyboardArrowDown} />
        </Text>

        <Collapse in={isOpen}>
          <VStack
            gap={1}
            divider={<Divider />}
            mb={total > OCC_PAGE_SIZE ? 4 : 0}
          >
            {occurrences?.map((occ) => (
              <Flex w="100%">
                <Flex w="48%" flexDir="column">
                  <LocaleDate
                    date={occ.start}
                    options={DEFAULT_LOCALE_DATE_OPTS}
                  />
                  <HoursRange start={occ.start} end={occ.end} />
                </Flex>
                <Flex w="48%">
                  <LocationTwoLine value={workingEvent.venue.location} />
                </Flex>
              </Flex>
            ))}
          </VStack>
          {total > OCC_PAGE_SIZE && (
            <Pager
              total={total}
              currentPage={page}
              pageSize={OCC_PAGE_SIZE}
              onPageChange={(value: number) => setPage(value)}
            />
          )}
        </Collapse>
      </Flex>
    </Flex>
  ) : null;
};
