import { ApiVenueSummary } from "@operations-hero/lib-api-client";
import { getVenueTimes } from "./getVenueTimes";

export function isValidOccurrenceVenue(
  venue: ApiVenueSummary,
  start: Date,
  startHour: Date,
  endHour: Date
) {
  const availableVenueTimes = getVenueTimes(start, venue);
  if (!availableVenueTimes) return false;

  const startToCommpare = new Date(start);
  startToCommpare.setHours(availableVenueTimes.venueStartHour);
  startToCommpare.setMinutes(availableVenueTimes.venueStartMinutes);

  const endToCommpare = new Date(start);
  endToCommpare.setHours(availableVenueTimes.venueEndHour);
  endToCommpare.setMinutes(availableVenueTimes.venueEndMinutes);

  return (
    startHour.getTime() >= startToCommpare.getTime() &&
    startHour.getTime() <= endToCommpare.getTime() &&
    endHour.getTime() >= startToCommpare.getTime() &&
    endHour.getTime() <= endToCommpare.getTime()
  );
}
