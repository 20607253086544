import React, { FC, useCallback, useEffect, useState } from "react";
import {
  ApiAttachment,
  ApiInventoryItem,
} from "@operations-hero/lib-api-client";
import {
  useColorModeValue,
  Flex,
  Image,
  Text,
  Box,
  Center,
  Stack,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { useAuthentication } from "../../../components/auth/AuthProvider";

interface ItemCartProps {
  item: ApiInventoryItem;
  children: JSX.Element;
}

const ItemCart: FC<ItemCartProps> = ({ item, children }) => {
  const { apiClient, currentAccount } = useAuthentication();

  const [itemAttachments, setItemAttachments] = useState<ApiAttachment[]>();

  const getItemAttachemnts = useCallback(() => {
    apiClient
      .findInventoryItemAttachments(currentAccount.id, item.id)
      .then((response) => setItemAttachments(response.data));
  }, [apiClient, item.id, currentAccount.id]);

  useEffect(() => {
    getItemAttachemnts();
  }, [getItemAttachemnts]);

  return (
    <Flex
      direction="column"
      pl={0}
      borderColor={useColorModeValue("gray.200", "gray.600")}
      borderWidth={1}
      borderRadius="md"
    >
      <Box maxW={"100%"} maxH={"100%"} w="100%" h="100px" position="relative">
        <Box
          bgColor={useColorModeValue("gray.200", "gray.600")}
          borderBottomLeftRadius="md"
          borderTopLeftRadius="md"
          borderTopRightRadius="md"
          color={useColorModeValue("gray.500", "gray.100")}
          display="flex"
          height="100%"
          maxH="100%"
          maxW="100%"
          overflow="hidden"
          position="relative"
          width="100%"
        >
          {itemAttachments && itemAttachments.length > 0 ? (
            <Image
              src={itemAttachments[0].url}
              transform="translate(-50%, -50%)"
              minH="100%"
              minW="100%"
              position="absolute"
              boxSizing="border-box"
              left="50%"
              top="50%"
              objectFit="cover"
              alt="Item Attachment"
            />
          ) : (
            <Center w="100%">
              <Text>No Image</Text>
            </Center>
          )}
        </Box>
      </Box>

      <Box flex="1" px={4} py={2}>
        <Stack spacing={3}>
          <Text fontSize="md" isTruncated={true}>
            {item.name}
          </Text>
          <HStack spacing={2}>
            <Text fontSize="small" colorScheme="gray" isTruncated>
              {item.identifiers.externalId}
            </Text>
          </HStack>
        </Stack>
      </Box>
      <Flex direction="column" p={2}>
        <Spacer />
        <Box textAlign="right">{children}</Box>
      </Flex>
    </Flex>
  );
};

export default ItemCart;
