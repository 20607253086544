import { SimpleGrid, Text } from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { UserBadge } from "../../../../components/badges/UserBadge";
import {
  DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
  LocaleDate,
} from "../../../../components/dates/LocaleDate";

type ProjectTimelineSummaryProps = {
  project: ApiProject;
};
export const ProjectTimelineSummary: FC<ProjectTimelineSummaryProps> = ({
  project,
}) => {
  const { created, createdBy, updated, lastStatusChanged } = project;
  return (
    <SimpleGrid columns={2} spacingY={4}>
      <Text>Created</Text>
      <LocaleDate
        date={created}
        options={{
          ...DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
          month: "short",
        }}
      />
      <Text>Created By</Text>
      <UserBadge value={createdBy} includeAvatar={true} />

      <Text>Last Status Changed</Text>
      {lastStatusChanged ? (
        <LocaleDate
          date={lastStatusChanged}
          options={{
            ...DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
            month: "short",
          }}
        />
      ) : (
        <Text>-</Text>
      )}

      <Text>Last updated</Text>
      <LocaleDate
        date={updated}
        options={{
          ...DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
          month: "short",
        }}
      />
    </SimpleGrid>
  );
};
