import { ApiAccount, ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface RemoveRequestAssetThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  idOrKey: string;
  assetId: string;
}

export const removeAsset = createAsyncThunk(
  "requests/removeAsset",
  async (params: RemoveRequestAssetThunkParams, thunkAPI) => {
    const { apiClient, account, idOrKey, assetId } = params;

    await apiClient.deleteRequestAsset(account.id, idOrKey, assetId);

    return assetId;
  }
);
export const removeAssetHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(removeAsset.fulfilled, (state, action) => {
    const idx = state.assets.data.findIndex((x) => x.id === action.payload);

    state.assets.data.splice(idx, 1);
    state.assetsTotal -= 1;
  });
};
