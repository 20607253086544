import { Avatar, AvatarGroup, Box, GridItem, Text } from "@chakra-ui/react";
import { ApiAssignee } from "@operations-hero/lib-api-client";
import { UserBadge } from "../../../components/badges/UserBadge";

export const UserColumn = ({ assignees }: { assignees: ApiAssignee[] }) => (
  <GridItem
    colSpan={[6, null, 4, 2]}
    isTruncated
    justifyContent={["right", "right", "left"]}
  >
    {assignees && assignees.length > 0 ? (
      assignees.length === 1 ? (
        assignees[0].type === "user" ? (
          <UserBadge value={assignees[0].assignee} />
        ) : (
          <Box display="inline-flex" maxW="100%">
            <Avatar
              size="xs"
              src={assignees[0].assignee.logo ? assignees[0].assignee.logo : ""}
            />
            <Text pl={2} isTruncated>
              {assignees[0].assignee.name}
            </Text>
          </Box>
        )
      ) : (
        <AvatarGroup
          size="xs"
          max={4}
          justifyContent={["right", "right", "left"]}
        >
          {assignees.map((assignee) =>
            assignee.type === "group" ? (
              <Avatar
                key={assignee.assignee.id}
                name={assignee.assignee.name}
                src={assignee.assignee.logo ? assignee.assignee.logo : ""}
              />
            ) : (
              <Avatar
                key={assignee.assignee.id}
                src={assignee.assignee.profileImage}
                name={`${assignee.assignee.firstName} ${assignee.assignee.lastName}`}
              />
            )
          )}
        </AvatarGroup>
      )
    ) : (
      <Text fontStyle={"italic"} color="gray.500" isTruncated>
        Unassigned
      </Text>
    )}
  </GridItem>
);
