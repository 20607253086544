import {
  ApiClient,
  ApiPagingVenueOptions,
  ApiVenueLevelNotificationOptions,
  ApiVenueSupervisedByUser,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export const DEFAULT_VENUE_LEVEL_NOTIFICATION_PAGE_SIZE = 5;

interface loadVenueLevelNotificationThunkParams {
  apiClient: ApiClient;
  accountId: string;
}

export type ApiLevelNotificationFilter = ApiPagingVenueOptions;

export const loadVenuesLevelNotification = createAsyncThunk(
  "event/venue-level-notification/load",
  async (params: loadVenueLevelNotificationThunkParams, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState)
      .venueLevelNotificationList;
    const { apiClient, accountId } = params;

    const response = await apiClient.findVenuesLevelNotificationSetting(
      accountId,
      filters
    );
    const { data } = response;
    if (data.length === DEFAULT_VENUE_LEVEL_NOTIFICATION_PAGE_SIZE) {
      return { ...response };
    } else {
      const dataWithEmptyValues = [...data];
      for (
        let index = 0;
        index < DEFAULT_VENUE_LEVEL_NOTIFICATION_PAGE_SIZE - data.length;
        index++
      ) {
        dataWithEmptyValues.push({
          id: "",
          name: "-",
          enabledNotifications: true,
          roles: [],
        });
      }
      return {
        ...response,
        data: dataWithEmptyValues,
      };
    }
  }
);

export interface VenueLevelNotificationSliceState {
  venuesSupervisedByUser: ApiVenueSupervisedByUser[];
  total: number;
  filters: ApiVenueLevelNotificationOptions;
}

const DEFAULT_VENUE_LEVEL_NOTIFICATION_FILTERS: ApiVenueLevelNotificationOptions =
  {
    page: 1,
    search: "",
    pageSize: DEFAULT_VENUE_LEVEL_NOTIFICATION_PAGE_SIZE,
  };

export const venueLevelNotificationList = createSlice({
  name: "venueLevelNotificationList",
  initialState: {
    venuesSupervisedByUser: [],
    total: 1,
    filters: { ...DEFAULT_VENUE_LEVEL_NOTIFICATION_FILTERS },
  } as VenueLevelNotificationSliceState,
  reducers: {
    unloadVenueLevelNotificationList: (
      state: VenueLevelNotificationSliceState
    ) => {
      state.venuesSupervisedByUser = [];
      state.total = 0;
      state.filters = DEFAULT_VENUE_LEVEL_NOTIFICATION_FILTERS;
    },
    updateVenueLevelNotificationFilters: (
      state: VenueLevelNotificationSliceState,
      action: PayloadAction<ApiVenueLevelNotificationOptions>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVenuesLevelNotification.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filters.page = options.page;
      state.venuesSupervisedByUser = data;
      state.total = total;
    });
  },
});

export const {
  unloadVenueLevelNotificationList,
  updateVenueLevelNotificationFilters,
} = venueLevelNotificationList.actions;

export default venueLevelNotificationList.reducer;
