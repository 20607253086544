import { Button, Grid, GridItem } from "@chakra-ui/react";
import { CreateApiVendorContact } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  createVendorContact,
  updateVendorContact,
} from "../../../../store/vendors/vendor-contacts.thunk";
import { setWorkingContact } from "../../../../store/vendors/vendors-slice";

const INITIAL_VALUES: CreateApiVendorContact = {
  name: "",
  email: null,
  phone: null,
  altPhone: null,
};

const constactSchema = yup.object().shape({
  name: yup.string().required("Name is required").max(100),
});

interface ContactFormProps {
  onClose?: () => void;
}

export const ContactForm: FC<ContactFormProps> = ({ onClose }) => {
  const [initialValues, setInitialValues] =
    useState<CreateApiVendorContact | null>(null);

  const contactFormRef = useRef<FormikProps<CreateApiVendorContact> | null>(
    null
  );
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { workingVendorContacts } = useSelector(
    (state: RootState) => state.vendorSlice
  );
  const { apiClient, currentAccount } = useAuthentication();
  const showToast = useShowToast();
  const { workingVendor } = useSelector(
    (state: RootState) => state.vendorSlice
  );

  const handleAddNewContact = useCallback(
    (values: CreateApiVendorContact) => {
      if (!workingVendor) return;
      thunkDispatch(
        createVendorContact({
          apiClient,
          accountId: currentAccount.id,
          vendorId: workingVendor.id,
          contact: values,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Contact was created successfully");
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });
    },
    [apiClient, currentAccount.id, showToast, thunkDispatch, workingVendor]
  );

  const handleUpdateContact = useCallback(
    (values: CreateApiVendorContact) => {
      if (!workingVendor || !workingVendorContacts.workingContact) return;
      thunkDispatch(
        updateVendorContact({
          apiClient,
          accountId: currentAccount.id,
          vendorId: workingVendor.id,
          contactId: workingVendorContacts.workingContact.id,
          contact: values,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Contact was updated successfully");
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });
    },
    [
      apiClient,
      currentAccount.id,
      showToast,
      thunkDispatch,
      workingVendor,
      workingVendorContacts.workingContact,
    ]
  );

  const handleOnSubmit = useCallback(
    (
      values: CreateApiVendorContact,
      formikHelpers: FormikHelpers<CreateApiVendorContact>
    ) => {
      if (workingVendorContacts.workingContact) {
        handleUpdateContact(values);
        dispatch(setWorkingContact(null));
      } else {
        handleAddNewContact(values);
      }

      formikHelpers.resetForm();
    },
    [
      dispatch,
      handleAddNewContact,
      handleUpdateContact,
      workingVendorContacts.workingContact,
    ]
  );

  const handleClickOnCancel = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (workingVendorContacts.workingContact) {
      setInitialValues(
        workingVendorContacts.workingContact as CreateApiVendorContact
      );
      contactFormRef.current && contactFormRef.current.resetForm();
    } else {
      setInitialValues(INITIAL_VALUES);
    }
  }, [workingVendorContacts.workingContact]);

  return (
    <>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={constactSchema}
          innerRef={contactFormRef}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Grid gap={4} borderRadius={6} templateColumns="repeat(12 1fr)">
                <GridItem colSpan={12}>
                  <TextInputControl
                    value={values.name}
                    name="name"
                    label="Name"
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <TextInputControl
                    name="email"
                    label="Email"
                    value={values.email}
                  />
                </GridItem>

                <GridItem colSpan={[12, 12, 6]}>
                  <TextInputControl
                    name="phone"
                    label="Phone"
                    value={values.phone}
                  />
                </GridItem>

                <GridItem colSpan={[12, 12, 6]}>
                  <TextInputControl
                    name="altPhone"
                    value={values.altPhone}
                    label="Alternative Phone"
                  />
                </GridItem>

                <GridItem
                  colSpan={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={handleClickOnCancel}
                  >
                    Cancel
                  </Button>
                  <Button size="sm" type="submit" colorScheme="blue">
                    Save
                  </Button>
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
