import { Box, useColorModeValue } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import Select, {
  SelectItemRenderer,
  SelectRenderer,
} from "react-dropdown-select";

interface ShowLowStockFilterProps {
  showLowStock: boolean;
  onChange: (value: boolean) => void;
}

type ValueLabel = {
  value: string;
  label: string;
};
const ShowLowStockFilter: FC<ShowLowStockFilterProps> = ({
  showLowStock,
  onChange,
}) => {
  const disabledBgColor = useColorModeValue("gray.200", "gray.600");
  const options: ValueLabel[] = useMemo(
    () => [
      { value: "Low Quantity Items", label: "Low Quantity Items" },
      { value: "All Items", label: "All Items" },
    ],
    []
  );

  const handleSelectChange = useCallback(
    (selected: ValueLabel[]) => {
      const value = selected[0]?.value;
      onChange(value === "Low Quantity Items");
    },
    [onChange]
  );

  const bgColor = useColorModeValue("white", "gray.700");

  const ContentRenderer = ({ state }: SelectRenderer<ValueLabel>) => (
    <Box p={1}>{state.values.length === 1 && state.values[0].label}</Box>
  );

  const ItemRenderer = ({
    item,
    methods,
    state,
  }: SelectItemRenderer<ValueLabel>) => (
    <Box
      p={2}
      bg={
        state.values.find((val) => val.value === item.value)
          ? disabledBgColor
          : bgColor
      }
      cursor="pointer"
      onClick={() => {
        if (state.values.find((val) => val.value !== item.value)) {
          methods.addItem(item);
        }
      }}
      opacity={state.values.find((val) => val.value === item.value) ? 0.5 : 1}
    >
      {item.label}
    </Box>
  );

  return (
    <Select
      options={options}
      values={options.filter(
        (option) =>
          option.value === (showLowStock ? "Low Quantity Items" : "All Items")
      )}
      onChange={handleSelectChange}
      multi={false}
      contentRenderer={ContentRenderer}
      itemRenderer={ItemRenderer}
      dropdownHeight="300px"
      style={{
        gap: "12px",
        borderRadius: "6px 0px 0px 0px",
        backgroundColor: bgColor,
      }}
    />
  );
};

export default ShowLowStockFilter;
