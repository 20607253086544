import {
  ApiClient,
  ApiAccount,
  ApiUser,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { loadComments } from "./loadComments.thunk";

export interface DeleteCommentThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiUser;
  key: string;
  id: string;
}

export const deleteComment = createAsyncThunk(
  "requests/deleteComment",
  async (params: DeleteCommentThunkParams, thunkAPI) => {
    const { user, apiClient, account, key, id } = params;

    await apiClient.deleteRequestComment(account.id, key, id);

    const { requestForm } = thunkAPI.getState() as RootState;

    thunkAPI.dispatch(
      loadComments({
        apiClient,
        account,
        key,
        user,
        page: requestForm.commentsCurrentPage,
      })
    );
  }
);
