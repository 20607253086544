import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export const useIsReviewerOrTechnicianOfWorkflows = () => {
  const { policyMap } = useSelector((state: RootState) => state.localCache);

  const result = useMemo(() => {
    const isReviewerOrTech = Object.values(policyMap).some((policy) => {
      return policy["reviewer"] || policy["technician"] || false;
    });

    return { isReviewerOrTech };
  }, [policyMap]);

  return result;
};
