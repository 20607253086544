import {
  Icon,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { BsPrinter } from "react-icons/bs";
import { MdDownload } from "react-icons/md";

export interface PdfViewerModalBaseProps {
  isOpen: boolean;
  onClose: () => void;
  showActions: boolean;
  printPdf: () => void;
  downloadPdf: () => void;
  children: React.ReactElement;
}

export const PdfViewerModalBase = (props: PdfViewerModalBaseProps) => {
  const { isOpen, onClose, showActions, printPdf, downloadPdf, children } =
    props;
  return (
    <Modal onClose={onClose} isOpen={isOpen} autoFocus={false}>
      <ModalOverlay />
      <ModalContent m={0} minW="100%" h="100vh">
        <ModalHeader>
          {showActions && (
            <>
              <IconButton
                icon={<Icon as={BsPrinter} />}
                mr={2}
                aria-label="print"
                onClick={printPdf}
              >
                Print
              </IconButton>
              <IconButton
                icon={<Icon as={MdDownload} />}
                aria-label="download"
                onClick={downloadPdf}
              >
                Download
              </IconButton>
            </>
          )}

          <ModalCloseButton />
        </ModalHeader>

        {children}
      </ModalContent>
    </Modal>
  );
};
