import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Spacer,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ApiRequest,
  ApiRequestStatus,
  ApiRequestTaskBook,
  ApiTaskBookType,
} from "@operations-hero/lib-api-client";
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IoCheckmarkCircleOutline,
  IoPlayCircleOutline,
  IoTrashSharp,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { UserBadge } from "../../../components/badges/UserBadge";
import { OutlinedIconButton } from "../../../components/custom-icons/OutlinedIconButton";
import { RootState, useThunkDispatch } from "../../../store";
import { setIsAutomaticTransition } from "../../../store/request-form/request-form.slice";
import { loadTaskbooks } from "../../../store/request-form/thunks/loadTaskbooks.thunk";
import { removeTaskbook } from "../../../store/request-form/thunks/removeTaskbook.thunk";
import { updateRequest } from "../../../store/request-form/thunks/updateRequest.thunk";
import { updateTaskbook } from "../../../store/request-form/thunks/updateTaskbook.thunk";
import { getVisibleFields } from "../../../utils/getVisibleFields";
import {
  RequestTaskbookEnum,
  RequestTaskbookModal,
} from "./taskbook-modals/TaskbookModal";
import { TaskBookProps } from "./TaskbooksSection";

export type TaskbookStatus = "complete" | "incomplete" | null;
export type ChangeStatus = "completed" | "idle" | "in-progress";
export const RATING_SCALE = 5;

export interface CustomElement {
  [key: string]: ReactElement<Element> | string;
}

export const TaskbooksData: FC<TaskBookProps> = ({ requestEngine }) => {
  const location = useLocation();
  const [workingTaskbook, setWorkingTaskBook] =
    useState<ApiRequestTaskBook | null>(null);
  const [changeStatus, setChangeStatus] = useState<ChangeStatus>("idle");
  const [lastTransition, setLastTransition] = useState<ApiRequestStatus>();
  const { key } = useParams();

  const {
    taskbooks,
    request,
    workflow,
    policy,
    transition,
    schemaVerificationFailed,
    isAutomaticTransition,
    visibleFields,
  } = useSelector((state: RootState) => state.requestForm);

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: updateModalIsOpen,
    onOpen: onOpenUpdateModal,
    onClose: onCloseUpdateModal,
  } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });
  const textColor = useColorModeValue("gradarkslategrey", "white");

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const { currentAccount, apiClient, isProductAdmin, currentUser } =
    useAuthentication();

  const { showTaskbooks } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const canViewAndStartTaskbooks = useMemo(() => {
    if (isProductAdmin) return true;
    if (
      policy &&
      (policy.reviewer ||
        policy.technician ||
        policy.admin ||
        policy.contractor)
    ) {
      return true;
    }
    return false;
  }, [isProductAdmin, policy]);

  const canDeleteTaskbook = useCallback(
    (taskbookCreatorId: string) => {
      if (currentUser.id === taskbookCreatorId) return true;
      if (isProductAdmin) return true;
      if (policy && (policy.reviewer || policy.admin)) return true;
      return false;
    },
    [currentUser.id, isProductAdmin, policy]
  );

  const handleRemoveTaskbook = useCallback(
    (taskbookId: string) => {
      if (request) {
        thunkDispatch(
          removeTaskbook({
            apiClient,
            accountId: currentAccount.id,
            requestId: request?.id,
            requestTaskbookId: taskbookId,
          })
        );
        setWorkingTaskBook(null);
        onClose();
      }
    },
    [apiClient, currentAccount, request, thunkDispatch, onClose]
  );

  const handleOnClickDelete = useCallback(
    (taskbookId: ApiRequestTaskBook) => {
      setWorkingTaskBook(taskbookId);
      onOpen();
    },
    [onOpen]
  );

  const handleOnClickFinish = useCallback(
    (taskbookId: ApiRequestTaskBook) => {
      setWorkingTaskBook(taskbookId);
      onOpenUpdateModal();
    },
    [onOpenUpdateModal]
  );

  const startTaskbook = useCallback(
    (taskbook: ApiRequestTaskBook) => {
      if (request) {
        thunkDispatch(
          updateTaskbook({
            apiClient,
            accountId: currentAccount.id,
            taskbookId: taskbook.id,
            requestId: request?.id,
            requestTaskbook: {
              status: taskbook.status,
              started: new Date().toString(),
            },
          })
        );
      }
    },
    [apiClient, currentAccount, thunkDispatch, request]
  );

  const openTaskbookWorkView = useCallback(
    (id: string, readonly = false) => {
      readonly
        ? navigate(`/requests/${request?.key}/books/${id}/mode/read-only`)
        : navigate(`/requests/${request?.key}/books/${id}`);
    },
    [navigate, request]
  );

  const getStatusText = useCallback((requestTaskbook: ApiRequestTaskBook) => {
    const {
      percentComplete,
      status,
      taskbook,
      totalScore,
      taskCount,
      averageScore,
    } = requestTaskbook;

    const text = {
      finished: "Finished -",
      didNot: "Did not finish -",
      secondary: ` ${taskCount !== 0 ? percentComplete : "100"}% complete`,
    };

    if (taskbook.type === ApiTaskBookType.scoring) {
      text.finished = "Scoring -";
      text.didNot = "Scoring -";
      text.secondary = ` ${totalScore} of ${
        taskCount * RATING_SCALE
      } and Average: ${averageScore}`;
    }

    if (taskbook.type === ApiTaskBookType.passfail) {
      text.finished = "Finished -";
      text.didNot = "Did not finished -";
      text.secondary = ` ${taskCount !== 0 ? percentComplete : "100"}% passed`;
    }

    const statusText: CustomElement = {
      complete: (
        <Text as="span" fontWeight="bold">
          {text.finished}
          <Text as="span" color="green.500">
            {text.secondary}
          </Text>
        </Text>
      ),
      incomplete: (
        <Text as="span" fontWeight="bold">
          {text.didNot}
          <Text as="span" color="orange.500">
            {text.secondary}
          </Text>
        </Text>
      ),
    };

    return status ? statusText[status] : null;
  }, []);

  const handleChangeRequestStatus = useCallback(
    async (status: ApiRequestStatus, taskbook?: ApiRequestTaskBook) => {
      if (request && requestEngine) {
        const delta: Partial<ApiRequest> = { status };
        if (
          (status === ApiRequestStatus.review ||
            status === ApiRequestStatus.closed) &&
          !request.scheduling.completed
        ) {
          delta.scheduling = {
            ...request.scheduling,
            completed: new Date().toISOString(),
          };
        }
        await thunkDispatch(
          updateRequest({
            apiClient,
            account: currentAccount,
            idOrKey: request.key,
            delta,
            isChangeModal: true,
            isProductAdmin: isProductAdmin,
            request,
            engine: requestEngine,
          })
        )
          .then(() => {
            setLastTransition(status);
            setChangeStatus("completed");
          })
          .catch(() => {
            toast({
              duration: 1500,
              isClosable: true,
              position: "top",
              status: "error",
              title: "Something went wrong updating the request",
            });
          });
      }
      if (status === ApiRequestStatus.started && taskbook) {
        startTaskbook(taskbook);
        navigate(`/requests/${request?.key}/books/${taskbook.id}`);
      }
    },
    [
      apiClient,
      currentAccount,
      isProductAdmin,
      request,
      requestEngine,
      startTaskbook,
      thunkDispatch,
      toast,
      navigate,
    ]
  );

  const isAllTaskbooksCompleted = useMemo(
    () => taskbooks.length > 0 && taskbooks.every((x) => x.completed !== null),
    [taskbooks]
  );

  useEffect(() => {
    // this is triggered when a taskbook is completed
    if (!location.state?.checkTaskbookComplete) {
      return;
    }

    if (!request || !transition) {
      return;
    }

    // load the taskbooks if they are missing
    if (request.totalTaskbooks > 0 && taskbooks.length === 0) {
      //reloading the taskbooks list after the status of any of them has change
      thunkDispatch(
        loadTaskbooks({
          apiClient,
          account: currentAccount,
          key: request.key,
        })
      );
      // data not loaded yet, so we need to wait
      return;
    }

    if (
      isAllTaskbooksCompleted &&
      ApiRequestStatus.started === request.status
    ) {
      handleChangeRequestStatus(transition.next);
    }

    //clearing the location state to get ready for any next action
    navigate(`/requests/${key}`, {
      state: { checkTaskbookComplete: false },
      replace: true,
    });
  }, [
    handleChangeRequestStatus,
    isAllTaskbooksCompleted,
    request,
    taskbooks.length,
    transition,
    location.state?.checkTaskbookComplete,
    apiClient,
    currentAccount,
    navigate,
    thunkDispatch,
    key,
  ]);

  useEffect(() => {
    if (changeStatus !== "completed" || !lastTransition) return;

    const status = lastTransition;
    const isAutoTransitionAndFailed =
      schemaVerificationFailed && isAutomaticTransition;

    const updateStatusMessage = isAutoTransitionAndFailed
      ? "Taksbooks completed"
      : status === ApiRequestStatus.started
        ? "Request status was marked as 'Started'"
        : "Request status was change";

    toast({
      duration: 1500,
      isClosable: true,
      position: "top",
      status: "success",
      title: updateStatusMessage,
    });

    //re-setting values
    setChangeStatus("idle");
    if (isAutomaticTransition) dispatch(setIsAutomaticTransition(false));
  }, [
    changeStatus,
    isAutomaticTransition,
    schemaVerificationFailed,
    toast,
    transition,
    lastTransition,
    dispatch,
  ]);
  return (
    <>
      {taskbooks.length === 0 && (
        <Text pt={2} color={textColor} fontSize="md">
          Add task books, use the search box to add a item
        </Text>
      )}
      {workflow && taskbooks.length > 0 && (
        <>
          <Text fontWeight="bold" pb={5} fontSize="md" pt={2}>
            Description
          </Text>
          {taskbooks.map((item: ApiRequestTaskBook) => (
            <Box id={item.id} key={`taskbooks::${item.id}`}>
              <Divider />
              <HStack alignContent="space-between" alignItems="start" py={5}>
                {isMobile ? (
                  <Button
                    variant="link"
                    colorScheme="blue"
                    fontWeight="normal"
                    _focus={{
                      border: "none",
                    }}
                    onClick={() => openTaskbookWorkView(item.id)}
                  >{`${item.taskbook.name}`}</Button>
                ) : (
                  <VStack alignItems="flex-start">
                    <HStack>
                      <Button
                        variant="link"
                        colorScheme="blue"
                        fontWeight="normal"
                        _focus={{
                          border: "none",
                        }}
                        onClick={() => openTaskbookWorkView(item.id)}
                      >{`${item.taskbook.name}`}</Button>
                      <Text>{getStatusText(item)}</Text>
                    </HStack>
                    {item.updatedBy !== null && (
                      <HStack spacing="unset">
                        <UserBadge
                          value={item.updatedBy}
                          textStyle={{
                            fontSize: "md",
                            fontWeight: "bold",
                            color: textColor,
                          }}
                        />
                        <Text
                          fontSize="md"
                          color={textColor}
                        >{`updated this task book on ${new Date(
                          item.updated as string
                        ).toLocaleDateString()}`}</Text>
                      </HStack>
                    )}
                  </VStack>
                )}
                <Spacer />
                <HStack spacing="1">
                  {showTaskbooks && canDeleteTaskbook(item.createdBy.id) && (
                    <OutlinedIconButton
                      icon={<Icon as={IoTrashSharp} boxSize={5} />}
                      onClick={() => handleOnClickDelete(item)}
                    />
                  )}

                  {canViewAndStartTaskbooks && !item.started ? (
                    <OutlinedIconButton
                      icon={<Icon as={IoPlayCircleOutline} boxSize={6} />}
                      onClick={() =>
                        handleChangeRequestStatus(
                          ApiRequestStatus.started,
                          item
                        )
                      }
                    />
                  ) : (
                    (item.status === "incomplete" || item.status === null) && (
                      <OutlinedIconButton
                        icon={
                          <Icon as={IoCheckmarkCircleOutline} boxSize={6} />
                        }
                        onClick={() => handleOnClickFinish(item)}
                      />
                    )
                  )}
                </HStack>
              </HStack>
            </Box>
          ))}
        </>
      )}
      {request && (
        <RequestTaskbookModal
          isOpen={isOpen}
          onClose={onClose}
          cb={handleRemoveTaskbook}
          requestId={request.id}
          removeItemId={workingTaskbook?.id}
          modalType={RequestTaskbookEnum.REMOVE}
        />
      )}
      {request && (
        <RequestTaskbookModal
          isOpen={updateModalIsOpen}
          onClose={onCloseUpdateModal}
          requestId={request.id}
          finishItemId={workingTaskbook?.id}
          modalType={RequestTaskbookEnum.UPDATE}
        />
      )}
    </>
  );
};
