import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiComment } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { setCommentsCurrentPage } from "../../../store/request-form/request-form.slice";
import { createUpdateRequestComment } from "../../../store/request-form/thunks/createEditCommentThunk";
import { deleteComment } from "../../../store/request-form/thunks/deleteComment.thunk";
import { loadComments } from "../../../store/request-form/thunks/loadComments.thunk";
import { getVisibleFields } from "../../../utils/getVisibleFields";
import { Comment } from "./Comment";
import { CommentForm } from "./CommentForm";

export const CommentsSection = () => {
  const { currentUser, currentAccount, apiClient, isProductAdmin } =
    useAuthentication();
  const showToast = useShowToast();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const workflow = useSelector(
    (state: RootState) => state.requestForm.workflow
  );
  const { request, policy } = useSelector(
    (state: RootState) => state.requestForm
  );
  const { comments, commentsCurrentPage, commentsTotal, visibleFields } =
    useSelector((state: RootState) => state.requestForm);

  const [currentComment, setCurrentComment] = useState<ApiComment>();
  const bgColor = useColorModeValue("blue.50", "blue.900");

  const handlePageChange = useCallback(
    (page: number) => {
      if (!request) {
        return;
      }

      dispatch(setCommentsCurrentPage(page));
      thunkDispatch(
        loadComments({
          apiClient,
          account: currentAccount,
          key: request!.key,
          user: currentUser,
        })
      );
    },
    [dispatch, thunkDispatch, apiClient, currentAccount, request, currentUser]
  );

  const handleDeleteComment = useCallback(
    (comment: ApiComment) => {
      thunkDispatch(
        deleteComment({
          apiClient,
          account: currentAccount,
          key: request!.key,
          user: currentUser,
          id: comment.id,
        })
      );
    },
    [thunkDispatch, apiClient, currentAccount, request, currentUser]
  );

  const { showComments } = useMemo(() => {
    return workflow ? getVisibleFields(visibleFields) : { showComments: false };
  }, [visibleFields, workflow]);

  useEffect(() => {
    setCurrentComment({
      id: "",
      createdBy: currentUser,
      comment: "",
      isPublic: true,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      updatedBy: null,
      mentioned: [],
    });
  }, [currentUser]);

  useEffect(() => {
    if (!request?.key) {
      return;
    }
    thunkDispatch(
      loadComments({
        apiClient,
        account: currentAccount,
        key: request.key,
        user: currentUser,
      })
    );
  }, [thunkDispatch, apiClient, currentAccount, currentUser, request?.key]);

  const hasPermisionToEdit = useMemo(() => {
    if (!policy) return false;

    return isProductAdmin || policy.admin;
  }, [isProductAdmin, policy]);

  const saveComment = useCallback(
    (workingComment: ApiComment, isPublic: boolean) => {
      if (!request) return;
      thunkDispatch(
        createUpdateRequestComment({
          apiClient,
          accountId: currentAccount.id,
          requestIdOrKey: request.id,
          commentId: workingComment.id,
          commentText: workingComment.comment,
          isPublic,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Comment was saved successfully");
        })
        .catch(() => {
          showToast(
            "error",
            "Something went wrong saving comment, please try again"
          );
        });
    },
    [apiClient, currentAccount.id, request, showToast, thunkDispatch]
  );

  return request && workflow && (showComments || comments.length > 0) ? (
    <Box bg={bgColor} p={4} borderRadius="md">
      <Grid
        templateColumns={["repeat(2, 1fr)"]}
        pt={2}
        mb={1}
        verticalAlign="center"
      >
        <GridItem colSpan={1} pb={3}>
          <Heading size="md">Comments</Heading>
        </GridItem>
      </Grid>
      {showComments && (
        <>
          <CommentForm
            comment={currentComment}
            autoFocusComment={false}
            showCommentButtons={true}
            setCurrentComment={setCurrentComment}
            onSave={saveComment}
            commentFrom="request"
          />
        </>
      )}
      <Stack spacing={2}>
        {comments ? null : (
          <Box>
            <Box p={3}>
              <SkeletonCircle size="7" />
              <SkeletonText mt="4" ml={8} noOfLines={3} spacing="4" />
            </Box>

            <Box p={3}>
              <SkeletonCircle size="7" />
              <SkeletonText mt="4" ml={8} noOfLines={3} spacing="4" />
            </Box>
          </Box>
        )}

        {comments &&
          comments.length > 0 &&
          comments.map((comment) => (
            <Box key={comment.id}>
              <Divider my={2} />
              <Comment
                comment={comment}
                onDelete={handleDeleteComment}
                onSave={saveComment}
                hasPermisionToEdit={hasPermisionToEdit}
              />
            </Box>
          ))}
        {comments && comments.length === 0 && (
          <>
            <Divider mb={4} />
            <Text p={4}>No Comments</Text>
          </>
        )}
      </Stack>
      {comments && commentsTotal > 20 && (
        <Box mt={2}>
          <Pager
            onPageChange={handlePageChange}
            total={commentsTotal}
            currentPage={commentsCurrentPage || 1}
            pageSize={20}
          />
        </Box>
      )}
    </Box>
  ) : null;
};
