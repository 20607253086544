import { ApiQuestion, ApiServiceSumary } from "@operations-hero/lib-api-client";

export interface ServiceQuestionResponseReducerValues {
  question: ApiQuestion;
  service: ApiServiceSumary;
  value?: string | string[] | boolean | number | null;
  questionResponseId?: string;
}

export interface ServiceQuestionResponseReducerAction
  extends Partial<ServiceQuestionResponseReducerValues> {
  index: number;
}

export const serviceQuestionsResponsesReducer = (
  values: ServiceQuestionResponseReducerValues[],
  action: ServiceQuestionResponseReducerAction
) => {
  const newValues = [...values];
  const newValue = { ...values[action.index], ...action };
  newValues[action.index] = newValue;
  return newValues;
};
