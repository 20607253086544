import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { FiscalYearSelect } from "../selects/FiscalYearSelect";

type FiscalYearRange = {
  start: string;
  end: string;
};

interface FiscalYearsAutocompleteControlProps {
  label: string;
  name: string;
  value: FiscalYearRange | null;
  isClearable?: boolean;
  placeholder?: string;
}

export const FiscalYearsControl: FC<FiscalYearsAutocompleteControlProps> = ({
  label,
  name,
  value,
  placeholder,
  isClearable,
}) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({ name, value: value || undefined });

  const handleOnChange = useCallback(
    (value: FiscalYearRange | null) => {
      helper.setTouched(true);
      helper.setValue(value);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <FiscalYearSelect
        fiscalYear={field.value ?? undefined}
        onChange={(fiscalYear) => handleOnChange(fiscalYear)}
        isInvalid={!!meta.error && (meta.touched || submitCount > 0)}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
