import {
  Box,
  Checkbox,
  Flex,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiRate,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { useFormikContext } from "formik";
import { ChangeEvent, FC, Fragment, useMemo } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { GetItemRateType } from "../edit-form-components/EventServiceItem";
import { EventFormValues } from "../EventForm";
import { ItemPrice } from "./ItemPrice";
import { EventServiceData } from "./ServicesMulticheck";

interface ServicesMulticheckItemProps {
  isSelected: boolean;
  item: EventServiceData;
  eventHoursDuration: number;
  handleOnSelectService: (item: EventServiceData) => void;
  additonalNotesChange: (values: string, item: EventServiceData) => void;
  getItemRates: GetItemRateType;
  occurrences: CreateApiEventOccurrence[];
  getAllRatesByItemId: (itemId: string) => ApiRate[] | undefined;
}

export const ServicesMulticheckItem: FC<ServicesMulticheckItemProps> = ({
  item,
  isSelected,
  eventHoursDuration,
  handleOnSelectService,
  additonalNotesChange,
  getItemRates,
  occurrences,
  getAllRatesByItemId,
}) => {
  const tooltipColor = useColorModeValue("gray.500", "white");
  const labelColor = useColorModeValue("gray.700", "white");
  const { values } = useFormikContext<EventFormValues>();

  const rate = getItemRates(
    item.service.id,
    isSelected,
    occurrences,
    eventHoursDuration
  );

  const ratesByItem = getAllRatesByItemId(item.service.id);

  const formikValue = useMemo(() => {
    const key = `${item.service.id}${item.spaceId ? `:${item.spaceId}` : ""}`;
    return values.services[key];
  }, [item.service.id, item.spaceId, values.services]);

  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  return (
    <Fragment>
      <Box w="100%" p={0}>
        <Flex justifyContent="space-between">
          {item.isRequired ? (
            <Popover placement="top-start" trigger="hover">
              <PopoverTrigger>
                <Checkbox
                  mr={2}
                  mt={1}
                  fontWeight={700}
                  isReadOnly={item.isRequired}
                  isChecked={item.isRequired || !!formikValue}
                  onChange={() => {
                    handleOnSelectService(item);
                  }}
                >
                  <Flex alignItems="center" gap={1}>
                    {item.service.name}
                    {rate && enableInvoicesForEvents && (
                      <ItemPrice ratesByItem={ratesByItem} />
                    )}
                    {item.spaceName && (
                      <Tooltip label={item.spaceName} fontSize="md">
                        <Box color={tooltipColor}>
                          <IoMdInformationCircle />
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>
                </Checkbox>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  This service is required for this
                  {item.spaceName ? " space" : " venue"}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Checkbox
              mr={2}
              mt={1}
              fontWeight={700}
              isReadOnly={item.isRequired}
              isChecked={item.isRequired || !!formikValue}
              onChange={() => {
                handleOnSelectService(item);
              }}
            >
              <Flex alignItems="center" gap={1}>
                {item.service.name}
                {rate && enableInvoicesForEvents && (
                  <ItemPrice ratesByItem={ratesByItem} />
                )}
                {item.spaceName && (
                  <Tooltip label={item.spaceName} fontSize="md">
                    <Box color={tooltipColor}>
                      <IoMdInformationCircle />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </Checkbox>
          )}

          {rate && enableInvoicesForEvents && (
            <Text w="max-content" pt={1}>
              {formatCurrency(
                rate.reduce((sum, r) => {
                  sum = sum + r.total;
                  return sum;
                }, 0) || 0
              )}
            </Text>
          )}
        </Flex>

        {(isSelected || item.isRequired) && (
          <Box w="100%" pt={2}>
            <Text color={labelColor} fontSize="14px">
              Additional notes
            </Text>
            <Input
              placeholder="Ex: We’d like 8 tables and 38 chairs"
              onBlur={(e: ChangeEvent<HTMLInputElement>) =>
                additonalNotesChange(e.target.value, item)
              }
              defaultValue={formikValue ? formikValue.additionalNotes : ""}
            />
          </Box>
        )}
      </Box>
    </Fragment>
  );
};
