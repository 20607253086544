import { ApiRequest } from "@operations-hero/lib-api-client";
import { useCallback, useState } from "react";

type SelectItem<T extends { id: string }> = T & {
  canBeSelected: boolean;
  selected: boolean;
};

export const useMultiSelect = <T extends ApiRequest>(
  cbAllowed?: (item: T) => boolean
) => {
  const [selectItems, setSelectItems] = useState<{
    [key: string]: SelectItem<T>;
  }>({});
  const [totalSelected, setTotalSelected] = useState<number>(0);
  const [totalToSelect, setTotalToSelect] = useState<number>(0);

  const resetMultiSelect = useCallback(() => {
    setSelectItems({});
    setTotalSelected(0);
    setTotalToSelect(0);
  }, []);

  const initialize = useCallback(
    (items: T[]) => {
      let toSelectItemsTotal = 0;
      const itemsWithSelectStatus = items.reduce<{
        [key: string]: SelectItem<T>;
      }>((map, item) => {
        if (!cbAllowed) {
          toSelectItemsTotal++;
          map[item.id] = {
            ...item,
            canBeSelected: true,
            selected: false,
          };

          return map;
        } else if (cbAllowed && cbAllowed(item)) {
          toSelectItemsTotal++;
          map[item.id] = {
            ...item,
            canBeSelected: true,
            selected: false,
          };
          return map;
        } else {
          map[item.id] = {
            ...item,
            canBeSelected: false,
            selected: false,
          };
          return map;
        }
      }, {});
      setSelectItems(itemsWithSelectStatus);
      setTotalToSelect(toSelectItemsTotal);
      setTotalSelected(0);
    },
    [cbAllowed]
  );

  const handleSingleItemSelect = useCallback(
    (itemId: string) => {
      const itemsToUpdate = { ...selectItems };
      const itemToUpdate = itemsToUpdate[itemId];
      if (itemToUpdate.selected) {
        itemsToUpdate[itemId] = {
          ...itemsToUpdate[itemId],
          selected: false,
        };
        setTotalSelected(totalSelected - 1);
      } else {
        itemsToUpdate[itemId] = {
          ...itemsToUpdate[itemId],
          selected: true,
        };
        setTotalSelected(totalSelected + 1);
      }
      setSelectItems(itemsToUpdate);
    },
    [selectItems, totalSelected]
  );

  return {
    selectItems,
    totalSelected,
    totalToSelect,
    initialize,
    handleSingleItemSelect,
    resetMultiSelect,
  };
};
