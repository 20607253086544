import { Heading, HeadingProps } from "@chakra-ui/layout";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const RequestPageHeading = (props: HeadingProps) => {
  const { listTitle } = useSelector((state: RootState) => state.requestList);

  return (
    <Heading size={"lg"} {...props}>
      {listTitle}
    </Heading>
  );
};
