type imageFormat = "png" | "jpg" | "jpeg";

export const donwloadQRCodeAsImage = (
  canvaElementId: string,
  fileName?: string,
  imageFormat?: imageFormat
) => {
  const QrCode = document.getElementById(canvaElementId) as HTMLCanvasElement;
  if (!QrCode) return;

  const image = QrCode.toDataURL(imageFormat || "image/jpg");
  const link = document.createElement("a");
  link.href = image;
  link.download = fileName || canvaElementId;
  link.click();
};
