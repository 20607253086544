import { IconButton, MenuButton } from "@chakra-ui/react";
import { ApiUser } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { UserBadge } from "../../badges/UserBadge";

type IconProfileButtonProps = {
  user: ApiUser;
};
export const SmallProfileButton: FC<IconProfileButtonProps> = ({ user }) => {
  return (
    <MenuButton
      as={IconButton}
      bgColor="transparent"
      _active={{ bgColor: "transparent" }}
      border="none"
      aria-label="profile-menu"
      isRound
    >
      <UserBadge
        value={user}
        hideName={true}
        backgroundColor="transparent"
        avatarProps={{
          w: 7,
          h: 7,
          mr: 0,
        }}
        tagProps={{
          px: 0,
          justifyContent: "center",
          w: "fit-content",
        }}
      />
    </MenuButton>
  );
};
