import { Box, Icon } from "@chakra-ui/react";
import { FC } from "react";
import {
  IoArrowRedoCircleSharp,
  IoCheckmarkCircleSharp,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { NumberCircle } from "./NumberCircle";

interface StepIconProps {
  type: "complete" | "incomplete" | "skipped" | "number" | "failed" | null;
  step: number | string;
}

export const StepIcon: FC<StepIconProps> = ({ type, step }) => {
  return type === "number" ? (
    <NumberCircle step={step} />
  ) : (
    <Box h="25px" w="25px" mr={2} color="blue.500" mt={-0.5}>
      {type === "complete" && (
        <Icon as={IoCheckmarkCircleSharp} boxSize="28px" />
      )}
      {type === "incomplete" && (
        <Icon as={IoCloseCircleOutline} boxSize="28px" color="red.500" />
      )}
      {type === "skipped" && (
        <Icon as={IoArrowRedoCircleSharp} boxSize="28px" />
      )}
      {type === "failed" && <Icon boxSize="28px" as={IoCloseCircleOutline} />}
    </Box>
  );
};
