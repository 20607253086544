import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { Link, Text } from "@react-pdf/renderer";
import React from "react";

export const transformMentions = (
  summary: string,
  mentioned: ApiUserSummary[]
) => {
  let resSummary = summary.slice();
  mentioned.forEach((mention) => {
    resSummary = resSummary.replaceAll(
      `<u:${mention.id}>`,
      `@${mention.firstName} ${mention.lastName}`
    );
  });

  return resSummary;
};

export const renderMarkdownToPDF = (markdownText: string) => {
  const textStyle = { fontFamily: "Open Sans", fontSize: 8 };
  const markdownOptions = [
    {
      regex: /\*\*(.*?)\*\*/g, // Bold **text**
      style: { fontWeight: "bold" },
    },
    {
      regex: /__(.*?)__/g, // Underline __text__
      style: { textDecoration: "underline" },
    },
    {
      regex: /_(.*?)_/g, // Italic _text_
      style: { fontStyle: "italic" },
    },
    {
      regex: /~~(.*?)~~/g, // Strikethrough ~~text~~
      style: { textDecoration: "line-through" },
    },
    {
      regex: /`([^`]+)`/g, // Inline `code`
      style: {
        backgroundColor: "#f1f1f1",
        padding: "2px 4px",
        borderRadius: "4px",
      },
    },
    {
      regex: /\[([^\]]+)\]\(([^)]+)\)/g, // Link [text](url)
      style: { link: "$2", color: "blue", textDecoration: "underline" },
    },
  ];

  const applyStyles = (text: string, styles: any) => {
    if (styles?.link) {
      return (
        <Link key={`styled_${text}`} src={styles.link} style={styles}>
          {text}
        </Link>
      );
    }
    return (
      <Text key={`styled_${text}`} style={{ ...textStyle, ...styles }}>
        {text}
      </Text>
    );
  };

  let result: React.ReactNode[] = [];
  let lastIndex = 0;

  markdownOptions.forEach((option) => {
    markdownText.replace(option.regex, (match, text, offset) => {
      const normalText = markdownText.slice(lastIndex, offset);
      const styledText = markdownText.slice(offset, offset + match.length);

      if (normalText) {
        result.push(normalText);
      }
      //apply markdown style from markdownOptions
      if (styledText) {
        result.push(applyStyles(text, option.style));
      }

      lastIndex = offset + match.length;
      return match;
    });
  });

  // Add any remaining normal text
  const remainingText = markdownText.slice(lastIndex);
  if (
    remainingText &&
    lastIndex !== markdownText.length &&
    //this is a fix to insert whole text when there is no markDown applied and to prevent re-inserting text if its the same as original and with styles applied
    (remainingText !== markdownText || lastIndex === 0)
  ) {
    result.push(remainingText);
  }

  // Use Fragment to preserve line breaks as in the original text
  return (
    <Text style={textStyle}>
      {result.map((item, index) => (
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </Text>
  );
};
