import {
  ApiClient,
  ApiCreateEventBlock,
  ApiEventBlock,
  ApiEventBlockSpace,
  ApiEventBlockType,
  ApiEventBlockVenue,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { LoadingStatus } from "../../types/redux-slices-types";
/* Load venue and spaces blocks */
interface LoadBlocksProps {
  apiClient: ApiClient;
  accountId: string;
}

export const loadVenueSpacesBlock = createAsyncThunk(
  "events/block",
  async (params: LoadBlocksProps, thunkAPI) => {
    const { apiClient, accountId } = params;
    const { page, pageSize } = (thunkAPI.getState() as RootState)
      .eventBlockSlice;

    const response = await apiClient.findVenuesOrSpacesBlocked(accountId, {
      page,
      pageSize,
    });

    return response;
  }
);

export const loadAllVenueSpacesBlock = createAsyncThunk(
  "events/all-block",
  async (params: LoadBlocksProps, thunkAPI) => {
    const { apiClient, accountId } = params;

    let page = 1;
    const pageSize = 100;
    let allBlocks: ApiEventBlock[] = [];
    let total = 0;
    do {
      const response = await apiClient.findVenuesOrSpacesBlocked(accountId, {
        page,
        pageSize,
      });
      allBlocks = [...allBlocks, ...response.data];
    } while (page <= Math.ceil(total / pageSize));
    return allBlocks;
  }
);
interface UpdateVenueSpacesBlockParams {
  apiClient: ApiClient;
  accountId: string;
  apiEventBlock: ApiCreateEventBlock;
  workingVenueSpaceIdx: number;
}

export const updateVenueSpaceBlock = createAsyncThunk(
  "events/update-block",
  async (params: UpdateVenueSpacesBlockParams) => {
    const { apiClient, accountId, apiEventBlock, workingVenueSpaceIdx } =
      params;

    const response = await apiClient.updateBlockedVenuesOrSpaces(
      accountId,
      apiEventBlock
    );
    return { response, workingVenueSpaceIdx };
  }
);

interface UnlockVenueSpaceParams {
  itemIdx: number;
  accountId: string;
  apiClient: ApiClient;
  workingItem: ApiEventBlock;
}

export const unlockVenueSpaces = createAsyncThunk(
  "events/unblock",
  async (params: UnlockVenueSpaceParams) => {
    const { apiClient, accountId, workingItem, itemIdx } = params;

    const itemId =
      "venue" in workingItem ? workingItem.venue.id : workingItem.space.id;

    const venueOrSpacesToUnblock = { ids: [itemId] };
    await apiClient.unblockBlockedVenuesOrSpaces(
      accountId,
      venueOrSpacesToUnblock
    );
    return itemIdx;
  }
);

/* Block slice */
export interface EventBlockSlice {
  loading: LoadingStatus;
  total: number;
  page: number;
  pageSize: number;
  data: ApiEventBlock[];
  allUsedSpaces: string[];
  allUsedVenues: string[];
}

const INITIAL_STATE: EventBlockSlice = {
  loading: "idle",
  total: 0,
  page: 1,
  pageSize: 20,
  data: [],
  allUsedSpaces: [],
  allUsedVenues: [],
};

export const eventBlockSlice = createSlice({
  name: "events-block",
  initialState: { ...INITIAL_STATE },
  reducers: {
    unloadEventBlockList: (state: EventBlockSlice) => {
      state.data = [];
      state.loading = "idle";
      state.total = 0;
      state.page = 1;
      state.pageSize = 20;
    },
    addBlockedItems: (
      state: EventBlockSlice,
      action: PayloadAction<ApiEventBlock[]>
    ) => {
      const allBlocked = action.payload;
      state.data.unshift(...allBlocked);
      state.total += allBlocked.length;
      const blockedVenues = allBlocked.filter(
        (block) => block.type === ApiEventBlockType.venue
      ) as ApiEventBlockVenue[];
      const blockedSpaces = allBlocked.filter(
        (block) => block.type === ApiEventBlockType.space
      ) as ApiEventBlockSpace[];
      state.allUsedVenues = Array.from(
        new Set([
          ...state.allUsedVenues,
          ...blockedVenues.map((block) => block.venue.id),
        ])
      );
      state.allUsedSpaces = Array.from(
        new Set([
          ...state.allUsedSpaces,
          ...blockedSpaces.map((block) => block.space.id),
        ])
      );
    },
    setEventBlockCurrentPage: (
      state: EventBlockSlice,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVenueSpacesBlock.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.total = action.payload.total;
      state.data = action.payload.data;
    });

    builder.addCase(loadAllVenueSpacesBlock.fulfilled, (state, action) => {
      const allBlocked = action.payload;
      const blockedVenues = allBlocked.filter(
        (block) => block.type === ApiEventBlockType.venue
      ) as ApiEventBlockVenue[];
      const blockedSpaces = allBlocked.filter(
        (block) => block.type === ApiEventBlockType.space
      ) as ApiEventBlockSpace[];
      state.allUsedVenues = blockedVenues.map((block) => block.venue.id);
      state.allUsedSpaces = blockedSpaces.map((block) => block.space.id);
    });

    builder.addCase(unlockVenueSpaces.fulfilled, (state, action) => {
      state.data.splice(action.payload, 1);
    });

    builder.addCase(updateVenueSpaceBlock.fulfilled, (state, action) => {
      const { response, workingVenueSpaceIdx } = action.payload;
      if (response.length) {
        const firstItem = response[0];
        state.data[workingVenueSpaceIdx] = firstItem;
      }
    });
  },
});

export const {
  addBlockedItems,
  unloadEventBlockList,
  setEventBlockCurrentPage,
} = eventBlockSlice.actions;

export default eventBlockSlice.reducer;
