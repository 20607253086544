import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  StackDivider,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInventoryOrderStatus,
  ApiInventoryOrderSummary,
  ApiPagingInventoryOrderOptions,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { DateBadge } from "../../../../components/badges/DateBadge";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { Pager } from "../../../../components/pager/Pager";
import { useShowToast } from "../../../../hooks/showToast";
import { useQueryStringFilter } from "../../../../hooks/useQueryStringFilter";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  loadInventoryOrders,
  setModalState,
  unloadInventoryOrder,
  updateInventoryOrderFilters,
} from "../../../../store/inventory/inventory-order.slice";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { SkeletonInventory } from "../../SkeletonInventory";
import InventoryOrderTimeline from "../inventory-order-timeline/InventoryOrderTimeline";
import { InventoryOrderForm } from "../InventoryOrderForm";
import {
  inventoryOrderDefaultFilters,
  InventoryOrderFiltersValues,
} from "./inventoryOrderDefault";
import { InventoryOrderMenu } from "./InventoryOrderMenu";
import { InventoryPlacedOrdersFilters } from "./InventoryPlacedOrdersFilters";

const DATE_FORMAT = "MM/dd/yyyy";

export interface InventoryListProps {
  filter: ApiPagingInventoryOrderOptions;
  updateFilters: (value: ApiPagingInventoryOrderOptions) => void;
}

const statusColorMap: { [key in ApiInventoryOrderStatus]?: string } = {
  [ApiInventoryOrderStatus.declined]: "red",
  [ApiInventoryOrderStatus.delivered]: "green",
};

export const InventoryPlacedOrders: FC = () => {
  const thunkDispatch = useThunkDispatch();

  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const dispatch = useDispatch();
  const {
    data,
    total,
    loading,
    workingOrder,
    isOpenDeclineModal,
    isOpenUpdateModal,
    isOpenRepeatModal,
    isOpenReviewModal,
    isOpenTimelineModal,
  } = useSelector((state: RootState) => state.inventoryOrder);

  const {
    filter: queryFilters,
    updateQueryString,
    filterInitialized,
  } = useQueryStringFilter({
    defaultValue: inventoryOrderDefaultFilters,
  });

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });

  const bgColor = useColorModeValue("blue.50", "blue.900");
  const borderColor = useColorModeValue("gray.300", "gray.700");

  const showToast = useShowToast();
  const firstUpdate = useRef(true);

  const updateFilters = useCallback(
    (values: Partial<InventoryOrderFiltersValues>) => {
      const updatedFilters: Partial<InventoryOrderFiltersValues> = {
        ...queryFilters,
        ...values,
      };
      updateQueryString({ ...updatedFilters });
      dispatch(updateInventoryOrderFilters(updatedFilters));
    },
    [dispatch, queryFilters, updateQueryString]
  );

  const handleOnPageChange = useCallback(
    (value: number) => {
      updateFilters({ page: value });
    },
    [updateFilters]
  );

  const handleOnCancelOrder = useCallback(
    (orderId: string) => {
      apiClient
        .updateInventoryOrder(currentAccount.id, orderId, {
          status: ApiInventoryOrderStatus.declined,
        })
        .then((res) => {
          showToast("success", `${res.key} status has been ${res.status}`);

          thunkDispatch(
            loadInventoryOrders({
              apiClient,
              accountId: currentAccount.id,
              filters: queryFilters,
            })
          );
        })
        .catch(() =>
          showToast("error", "There was an error canceling the inventory order")
        )
        .finally(() => dispatch(setModalState({ workingOrder: null })));
    },
    [
      apiClient,
      currentAccount.id,
      showToast,
      thunkDispatch,
      queryFilters,
      dispatch,
    ]
  );
  //dispatch(setModalState({ workingOrder: order, isOpenUpdateModal: true }));

  const handleStatus = useCallback(
    (order: ApiInventoryOrderSummary) => {
      if (
        order.status === ApiInventoryOrderStatus.ordered ||
        order.status === ApiInventoryOrderStatus.partialDelivery
      ) {
        dispatch(
          setModalState({ workingOrder: order, isOpenUpdateModal: true })
        );
        return;
      }
      if (order.status === ApiInventoryOrderStatus.delivered) {
        dispatch(
          setModalState({ workingOrder: order, isOpenReviewModal: true })
        );
        return;
      }
      if (order.status === ApiInventoryOrderStatus.declined) {
        dispatch(
          setModalState({ workingOrder: order, isOpenRepeatModal: true })
        );
        return;
      }
    },
    [dispatch]
  );
  useEffect(() => {
    if (!filterInitialized) {
      return;
    }
    if (firstUpdate.current) {
      thunkDispatch(
        loadInventoryOrders({
          apiClient,
          accountId: currentAccount.id,
          filters: queryFilters,
        })
      );
      firstUpdate.current = false;
      updateFilters({ ...queryFilters });
      return;
    }
    thunkDispatch(
      loadInventoryOrders({
        apiClient,
        accountId: currentAccount.id,
        filters: queryFilters,
      })
    );
  }, [
    apiClient,
    currentAccount.id,
    thunkDispatch,
    currentUser.id,
    updateFilters,
    filterInitialized,
    queryFilters,
  ]);

  useEffect(
    () => () => {
      dispatch(unloadInventoryOrder());
    },
    [dispatch]
  );

  return (
    <Flex minW="100%" flexDir="column" gap={4}>
      {filterInitialized && (
        <InventoryPlacedOrdersFilters
          filters={queryFilters}
          updateFilters={updateFilters}
        />
      )}

      {loading === "idle" || loading === "pending" ? (
        <Box>
          <SkeletonInventory />
        </Box>
      ) : data.length > 0 ? (
        <>
          <Box minW="100%">
            <VStack
              p={2}
              width="100%"
              borderRadius={4}
              border={"1px solid"}
              borderColor={borderColor}
              gap={isMobile ? 4 : undefined}
              divider={isMobile ? <StackDivider /> : undefined}
            >
              {!isMobile && (
                <>
                  <HStack width="100%" p={2}>
                    <Heading size="sm" width="11.11%">
                      Order
                    </Heading>
                    <Heading size="sm" width="11.11%">
                      Supplier
                    </Heading>
                    <Heading size="sm" width="11.11%">
                      Order Date
                    </Heading>
                    <Heading textAlign="center" size="sm" width="11.11%">
                      Expected Date
                    </Heading>
                    <Heading size="sm" width="11.11%" textAlign="center">
                      PO
                    </Heading>
                    <Heading size="sm" width="11.11%" textAlign="center">
                      Invoice #
                    </Heading>
                    <Heading size="sm" width="11.11%">
                      Status
                    </Heading>
                    <Heading textAlign="right" size="sm" width="11.11%">
                      Total Amount
                    </Heading>
                    <Heading size="sm" width="11.11%"></Heading>
                  </HStack>
                  <Divider />
                </>
              )}
              {data.map((inventory, index) =>
                !isMobile ? (
                  <HStack
                    p={2}
                    alignItems="center"
                    width="100%"
                    cursor="pointer"
                    onClick={() => handleStatus(inventory)}
                    borderRadius={4}
                    bgColor={index % 2 === 0 ? bgColor : undefined}
                    key={`inventoryPlacedOrders::${inventory.id}`}
                  >
                    <Box width="11.11%">
                      <Text>{inventory.key}</Text>
                      <Text>{inventory.supplier?.name || ""} Order</Text>
                    </Box>
                    <Box w="11.11%">
                      <Text>{inventory.supplier?.name || ""}</Text>
                    </Box>
                    <Text w="11.11%" justifySelf="flex-start">
                      {format(new Date(inventory.orderDate), DATE_FORMAT)}
                    </Text>
                    <Text
                      w="11.11%"
                      justifySelf="flex-start"
                      textAlign="center"
                    >
                      {inventory.expectedDate
                        ? format(new Date(inventory.expectedDate), DATE_FORMAT)
                        : "-"}
                    </Text>
                    <Text w="11.11%" textAlign="center">
                      {inventory.purchaseOrder ? inventory.purchaseOrder : "-"}
                    </Text>
                    <Text w="11.11%" textAlign="center">
                      {inventory.invoiceNumber ? inventory.invoiceNumber : "-"}
                    </Text>
                    <Text w="11.11%" justifySelf="flex-start">
                      <StatusBadge
                        status={inventory.status}
                        colorScheme={statusColorMap[inventory.status]}
                      />
                    </Text>
                    <Box w="11.11%">
                      <Text textAlign="right">${inventory.totalCost}</Text>
                    </Box>
                    <HStack
                      w="11.11%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <InventoryOrderMenu order={inventory} />
                    </HStack>
                  </HStack>
                ) : (
                  <VStack
                    gap={2}
                    width="100%"
                    key={`inventoryPlacedOrders::${inventory.id}`}
                  >
                    <Flex
                      w="100%"
                      alignSelf="flex-start"
                      justifyContent="space-between"
                    >
                      <Text fontWeight="bold">{inventory.key}</Text>

                      <StatusBadge
                        status={inventory.status}
                        colorScheme={statusColorMap[inventory.status]}
                      />
                    </Flex>
                    <Flex wrap="wrap" gap={1} width="100%">
                      <Text>Ordered on </Text>
                      <DateBadge
                        date={inventory.orderDate}
                        showRelative={false}
                      />
                      <Text>by</Text>
                      <UserBadge value={inventory.createdBy} />
                    </Flex>
                    <HStack width="100%" justifyContent="space-between">
                      <Text>Expected date:</Text>
                      {inventory.expectedDate ? (
                        <DateBadge
                          date={inventory.expectedDate}
                          showRelative={false}
                          rest={{ textAlign: "right" }}
                        />
                      ) : (
                        <Text>-</Text>
                      )}
                    </HStack>
                    <HStack width="100%" justifyContent="space-between">
                      <Text>PO</Text>
                      <Text>
                        {inventory.purchaseOrder
                          ? inventory.purchaseOrder
                          : "-"}
                      </Text>
                    </HStack>
                    <HStack width="100%" justifyContent="space-between">
                      <Text>Invoice #</Text>
                      <Text>
                        {inventory.invoiceNumber
                          ? inventory.invoiceNumber
                          : "-"}
                      </Text>
                    </HStack>
                    <HStack width="100%" justifyContent="space-between">
                      <Text>Total Amount: ${inventory.totalCost}</Text>
                    </HStack>
                    <HStack w="100%" justifyContent="flex-end">
                      <InventoryOrderMenu order={inventory} />
                    </HStack>
                  </VStack>
                )
              )}
              {isOpenDeclineModal && workingOrder && (
                <AccountModal
                  size="lg"
                  title={
                    <VStack alignItems="flex-start">
                      <Heading fontSize="2xl">
                        Decline Order {workingOrder.key}?
                      </Heading>
                    </VStack>
                  }
                  isOpen={isOpenDeclineModal}
                  onClose={() =>
                    dispatch(setModalState({ workingOrder: null }))
                  }
                  content={
                    <Box>
                      <Text>All items will be removed from the order</Text>
                      <Text>This action cannot be undone</Text>

                      <Flex mt={2} justifyContent="flex-end">
                        <HStack justifyContent="flex-end" gap={1} mt={2}>
                          <Button
                            size="sm"
                            minWidth="100px"
                            variant="outline"
                            onClick={() =>
                              dispatch(setModalState({ workingOrder: null }))
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            size="sm"
                            minWidth="100px"
                            colorScheme="red"
                            onClick={() => handleOnCancelOrder(workingOrder.id)}
                          >
                            Decline Order
                          </Button>
                        </HStack>
                      </Flex>
                    </Box>
                  }
                />
              )}
              {isOpenUpdateModal && workingOrder && (
                <AccountModal
                  size={
                    workingOrder.status ===
                    ApiInventoryOrderStatus.partialDelivery
                      ? "3xl"
                      : "lg"
                  }
                  title={
                    <VStack alignItems="flex-start">
                      <Text fontSize="sm">{workingOrder.key}</Text>
                      <Heading fontSize="2xl">
                        {workingOrder.supplier?.name} Order{" "}
                        {workingOrder.status ===
                        ApiInventoryOrderStatus.partialDelivery
                          ? "Update"
                          : "Reception"}{" "}
                      </Heading>
                      <Text fontSize="sm">Status: {workingOrder.status}</Text>
                    </VStack>
                  }
                  isOpen={isOpenUpdateModal}
                  onClose={() =>
                    dispatch(setModalState({ workingOrder: null }))
                  }
                  content={
                    <InventoryOrderForm
                      inventoryOrder={workingOrder}
                      onCancel={() =>
                        dispatch(setModalState({ workingOrder: null }))
                      }
                    />
                  }
                />
              )}
              {isOpenRepeatModal && workingOrder && (
                <AccountModal
                  size="lg"
                  title={
                    <VStack alignItems="flex-start">
                      <Heading fontSize="2xl">
                        {workingOrder.supplier?.name} Order
                      </Heading>
                    </VStack>
                  }
                  isOpen={isOpenRepeatModal}
                  onClose={() =>
                    dispatch(setModalState({ workingOrder: null }))
                  }
                  content={
                    <InventoryOrderForm
                      inventoryOrder={workingOrder}
                      isRepeatOrder
                      onCancel={() =>
                        dispatch(setModalState({ workingOrder: null }))
                      }
                    />
                  }
                />
              )}
              {isOpenReviewModal && workingOrder && (
                <AccountModal
                  size="3xl"
                  title={
                    <VStack alignItems="flex-start">
                      <Text fontSize="sm">{workingOrder.key}</Text>
                      <Heading fontSize="2xl">
                        {workingOrder.supplier?.name} Order Review
                      </Heading>
                      <Text fontSize="sm">Status: {workingOrder.status}</Text>
                    </VStack>
                  }
                  isOpen={isOpenReviewModal}
                  onClose={() =>
                    dispatch(setModalState({ workingOrder: null }))
                  }
                  content={
                    <InventoryOrderForm
                      inventoryOrder={workingOrder}
                      onCancel={() =>
                        dispatch(setModalState({ workingOrder: null }))
                      }
                    />
                  }
                />
              )}
              {isOpenTimelineModal && workingOrder && (
                <AccountModal
                  size="3xl"
                  title={
                    <VStack alignItems="flex-start">
                      <Text fontSize="sm">{workingOrder.key}</Text>
                      <Heading fontSize="2xl">
                        {workingOrder.supplier?.name} Order History
                      </Heading>
                      <Text fontSize="sm">Status: {workingOrder.status}</Text>
                    </VStack>
                  }
                  isOpen={isOpenTimelineModal}
                  onClose={() =>
                    dispatch(setModalState({ workingOrder: null }))
                  }
                  content={
                    <InventoryOrderTimeline inventoryOrder={workingOrder} />
                  }
                  footer={
                    <Flex w="100%">
                      <Button
                        w="120px"
                        ml="auto"
                        mr={8}
                        mb={4}
                        p={2}
                        colorScheme="blue"
                        onClick={() =>
                          dispatch(setModalState({ workingOrder: null }))
                        }
                      >
                        Close
                      </Button>
                    </Flex>
                  }
                />
              )}
            </VStack>
          </Box>
          <Pager
            showDetails
            total={total}
            currentPage={queryFilters.page || 1}
            pageSize={queryFilters.pageSize || 20}
            onPageChange={handleOnPageChange}
          />
        </>
      ) : (
        <Text>Inventory orders not found!</Text>
      )}
    </Flex>
  );
};
