import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { RequestAutocomplete } from "../selects/RequestAutocomplete";

export interface RequestAutocompleteProps {
  label?: string;
  name: string;
  value: ApiRequest | null;
  isDisabled?: boolean;
  labelProps?: FormLabelProps;
  allowEmpty?: boolean;
}

export const RequestAutocompleteControl = ({
  name,
  value,
  label,
  isDisabled,
  labelProps,
  allowEmpty,
}: RequestAutocompleteProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (venue: ApiRequest | null) => {
      helper.setTouched(true);
      helper.setValue(venue || "");
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && (
        <FormLabel htmlFor={name} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <RequestAutocomplete
        {...field}
        onChange={handleOnChange}
        name={name}
        isDisabled={isDisabled}
        isClearable={allowEmpty}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
