import { ApiFrequency } from "@operations-hero/lib-api-client";
import { parseExpression } from "cron-parser";
import {
  isInLastWeekOfMonth,
  isInNWeekOfMonth,
} from "../../../utils/monthHelper";
import { getTimeZoneIanaFormat } from "../../../utils/timezone";
import { CronValues } from "../CustomCron";

const MINUTES_TO_REST = 2;
const COUNT_INITIAL_VALUE = -1;
const timeZone = getTimeZoneIanaFormat();

function cronToObject(cron: string) {
  const [min, hour, dayMonth, month, dayWeekI] = cron.split(" ");

  const dayWeek = dayWeekI.length > 1 ? dayWeekI.split(",") : [dayWeekI];

  return {
    min,
    hour,
    dayMonth,
    month,
    dayWeek,
  };
}

const calculateCurrentCount = (
  cron: string,
  frequency: ApiFrequency,
  today: Date,
  count: number
) => {
  let currentScheduledCount = count;

  const { dayWeek } = cronToObject(cron);
  switch (frequency) {
    case "daily":
      currentScheduledCount += 1;
      return currentScheduledCount;
    case "weekly":
      {
        const day = today.getDay();
        if (dayWeek.length === 1) {
          currentScheduledCount += 1;
        } else {
          const [firstDayOfWeek] = dayWeek;
          if (currentScheduledCount === COUNT_INITIAL_VALUE) {
            currentScheduledCount += 1;
          } else if (day === parseInt(firstDayOfWeek)) {
            currentScheduledCount += 1;
          }
        }
      }
      return currentScheduledCount;
    case "monthly":
      currentScheduledCount += 1;

      return currentScheduledCount;
    case "yearly":
      currentScheduledCount += 1;

      return currentScheduledCount;
  }
};

interface CronOccurrence {
  start: string;
  end: string;
  limitByVenue?: boolean;
}

export const getCronOcurrences = (
  cron: CronValues,
  startDate: Date,
  endDate: Date,
  limitByVenue = false,
  allowedDays?: number[]
) => {
  const currentDate = new Date(startDate);
  const startDateCopy = new Date(startDate);
  const currentDateMinutes = currentDate.getMinutes();

  currentDate.setMinutes(currentDateMinutes - MINUTES_TO_REST);
  const options = {
    currentDate,
    startDate: startDateCopy || undefined,
    endDate: endDate || undefined,
    tz: timeZone,
  };

  let allOcurrences: CronOccurrence[] = [];
  const endDateAsNumber = endDate.getTime();

  const startHours = startDate.getHours();
  const startMinutes = startDate.getMinutes();
  const endHours = endDate.getHours();
  const endMinutes = endDate.getMinutes();

  const { cronExpression, frequency, executeEvery, weekOfMonth } = cron;

  const interval = parseExpression(cronExpression, options);
  let breaker = startDate.getTime();

  // This should be -1, to have the first occurrence considering the current date
  let executeEveryCount = -1;

  while (breaker < endDateAsNumber) {
    try {
      const occurrence = new Date(interval.next().toString());

      if (limitByVenue && allowedDays) {
        const occurrenceDay = occurrence.getDay();
        if (allowedDays.every((ad) => ad !== occurrenceDay)) continue;
      }

      executeEveryCount = calculateCurrentCount(
        cronExpression,
        frequency,
        occurrence,
        executeEveryCount
      );

      let isMonthWeek = false;

      if (weekOfMonth) {
        if (weekOfMonth === "L") {
          isMonthWeek = isInLastWeekOfMonth(occurrence);
        } else {
          const numberOfweek = parseInt(weekOfMonth);
          isMonthWeek = isInNWeekOfMonth(numberOfweek, occurrence);
        }
      }

      if (executeEveryCount % executeEvery === 0) {
        const newStartDate = new Date(occurrence);
        newStartDate.setHours(startHours);
        newStartDate.setMinutes(startMinutes);

        const newEndDate = new Date(occurrence);
        newEndDate.setHours(endHours);
        newEndDate.setMinutes(endMinutes);

        const newOccurrence: CronOccurrence = {
          start: newStartDate.toISOString(),
          end: newEndDate.toISOString(),
        };

        if (weekOfMonth && isMonthWeek) {
          allOcurrences.push(newOccurrence);
        }
        if (!weekOfMonth) {
          allOcurrences.push(newOccurrence);
        }
      }

      breaker = occurrence.getTime();
    } catch (error) {
      break;
    }
  }
  return allOcurrences;
};
