import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface StringParamValue {
  [key: string]: string;
}
interface UseQueryStringParams {
  defaultValues?: StringParamValue;
  shouldInit?: boolean;
}

export function useQueryString({
  defaultValues,
  shouldInit,
}: UseQueryStringParams) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const [isInitialized, setIsInitialized] = useState(false);
  const [queryParams, setQueryParams] = useState<StringParamValue>(
    defaultValues || {},
  );

  const updateQueryValues = useCallback(
    (value: StringParamValue) => {
      setQueryParams({ ...queryParams, ...value });
    },
    [queryParams],
  );

  useEffect(() => {
    if (!shouldInit) return;
    const queryString = query.toString();

    if (queryString.length !== 0 && !isInitialized) {
      let newValues: StringParamValue = {};
      const defaultValuesKeys = defaultValues ? Object.keys(defaultValues) : [];
      query.forEach((value, key) => {
        if (defaultValuesKeys.some((item) => item === key)) {
          newValues[key] = value;
        }
      });
      setQueryParams(newValues);
    }
    setIsInitialized(true);
  }, [defaultValues, isInitialized, query, shouldInit]);

  useEffect(() => {
    if (!isInitialized || !shouldInit) return;

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value === "") {
        return;
      }
      query.set(key, value);
    });
    navigate({ pathname, search: query.toString() });
  }, [navigate, isInitialized, pathname, query, shouldInit, queryParams]);

  return { query, queryParams, updateQueryValues, isInitialized };
}
