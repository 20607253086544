import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import {
  ApiClient,
  ApiComment,
  ApiPagingOptions,
  ApiProject,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Attachment } from "../../components/attachments/Attachments";
import {
  AttachmentHandlerProps,
  findAttachmentsHandlers,
} from "./attachments/findAttachments.thunk";
import { removeAttachmentHandlers } from "./attachments/removeAttachments.thunk";
import {
  createCommentHandlers,
  CreateCommentsHandlerProps,
} from "./comments/createComment.thunk";
import {
  deleteCommentHandlers,
  DeleteCommentsHandlerProps,
} from "./comments/deleteComment.thunk";
import {
  CommentsHandlerProps,
  findCommentsHandlers,
} from "./comments/findComments.thunk";
import {
  DEFAULT_COMMENTS_PAGE_SIZE,
  DEFAULT_COMMENTS_PAGE_START,
} from "./defaults";
import { updateProject } from "./project-form";
import { LoadingStatus } from "./project-list";

type GetProjectParams = {
  apiClient: ApiClient;
  id: string;
};

export const getProject = createAsyncThunk<ApiProject, GetProjectParams>(
  "project/get",
  async ({ apiClient, id }, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const { currentAccount } = rootState.auth;
    const project = await apiClient.getProject(currentAccount.id, id);
    return project;
  }
);

type ProjectPageSliceProps = {
  project: ApiProject | null;
  projectLoadingStatus: LoadingStatus;
  attachments: Attachment[];
  attachmentsLoadingStatus: LoadingStatus;
  comments: ApiComment[];
  commentsOptions: ApiPagingOptions;
  commentsTotal: number;
  commentsLoadingStatus: LoadingStatus;
};

const initialState: ProjectPageSliceProps = {
  project: null,
  attachments: [],
  comments: [],

  commentsOptions: {
    page: DEFAULT_COMMENTS_PAGE_START,
    pageSize: DEFAULT_COMMENTS_PAGE_SIZE,
  },
  commentsTotal: 0,

  projectLoadingStatus: "idle",
  attachmentsLoadingStatus: "idle",
  commentsLoadingStatus: "idle",
};

const projectPageSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    unload: (state) => {
      state.project = initialState.project;
      state.attachments = [];
      state.comments = [];
      state.commentsTotal = 0;
      state.projectLoadingStatus = initialState.projectLoadingStatus;
      state.attachmentsLoadingStatus = initialState.attachmentsLoadingStatus;
      state.commentsLoadingStatus = initialState.commentsLoadingStatus;
    },
    addAttachment: (state, action: PayloadAction<Attachment>) => {
      const { attachments } = state;

      if (
        attachments.some(
          (attachement) =>
            attachement.isNew === true &&
            attachement.name === action.payload.name
        )
      ) {
        return;
      }
      state.attachments.push(action.payload);
    },
    updateAttachment: (state, action: PayloadAction<Attachment>) => {
      const index = state.attachments.findIndex(
        (x) => x.isNew === true && x.name === action.payload.name
      );

      state.attachments[index] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProject.pending, (state) => {
      state.projectLoadingStatus = "pending";
    });
    builder.addCase(getProject.rejected, (state) => {
      state.projectLoadingStatus = "rejected";
      state.attachmentsLoadingStatus = "rejected";
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.project = action.payload;
      state.projectLoadingStatus = "fulfilled";
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.project = action.payload;
    });

    removeAttachmentHandlers(builder);
    findAttachmentsHandlers(
      builder as ActionReducerMapBuilder<AttachmentHandlerProps>
    );

    createCommentHandlers(
      builder as ActionReducerMapBuilder<CreateCommentsHandlerProps>
    );
    findCommentsHandlers(
      builder as ActionReducerMapBuilder<CommentsHandlerProps>
    );
    deleteCommentHandlers(
      builder as ActionReducerMapBuilder<DeleteCommentsHandlerProps>
    );
  },
});

export const { unload, addAttachment, updateAttachment } =
  projectPageSlice.actions;
export default projectPageSlice.reducer;
