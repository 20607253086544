import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface AddTaskbookParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  ids: string[];
}

export const addTaskbook = createAsyncThunk(
  "requests/addTaskbook",
  async (params: AddTaskbookParams, thunkAPI) => {
    const { apiClient, accountId, requestId, ids } = params;

    return await apiClient.createRequestTaskbook(accountId, requestId, { ids });
  }
);

export const addTaskbookHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(addTaskbook.fulfilled, (state, action) => {
    state.taskbooks.push(action.payload[0]);
    state.taskbooksTotal += 1;
  });
};
