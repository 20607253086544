import {
  ApiInventoryItemReference,
  ApiInventoryOrderStatus,
  ApiPagingInventoryOrderOptions,
  ApiVendorReference,
} from "@operations-hero/lib-api-client";

export interface InventoryOrderFiltersValues
  extends ApiPagingInventoryOrderOptions {
  search: string;
  page: number;
  pageSize: number;
  created: string[];
  updated: string[];
  sort?: string;
  direction?: "asc" | "desc";
  item: ApiInventoryItemReference | ApiInventoryItemReference[];
  status: ApiInventoryOrderStatus | ApiInventoryOrderStatus[];
  supplier: ApiVendorReference | ApiVendorReference[];
}

export const inventoryOrderDefaultFilters: Partial<InventoryOrderFiltersValues> =
  {
    page: 1,
    search: "",
    pageSize: 50,
    direction: "desc",
    sort: "key",
    created: [],
    updated: [],
    status: [],
    item: [],
    supplier: [],
  };
