import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiFrequency } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";

export interface ScheduledFrequencyFilterProps {
  frequency: ApiFrequency[];
  onFrequencyChange: (value: ApiFrequency[]) => void;
  showSelectAll?: boolean;
  executeEvery: number | undefined;
  onEveryChange: (value: number | undefined) => void;
}

type ValueLabel = {
  value: ApiFrequency;
  label: string;
};

const DropdownRenderer = ({
  props,
  methods,
  showSelectAll,
  executeEvery,
  onEveryChange,
}: SelectRenderer<ValueLabel> & {
  showSelectAll?: boolean;
  executeEvery: number | undefined;
  onEveryChange: (value: number) => void;
}) => {
  const bgColor = useColorModeValue(undefined, "gray.700");
  return (
    <Box p={2} backgroundColor={bgColor}>
      <Box pb={1}>
        {showSelectAll && (
          <HStack justifyContent="space-between" pb={2}>
            <Box>Search and select:</Box>
            {methods.areAllSelected() ? (
              <Button size="sm" variant="outline" onClick={methods.clearAll}>
                Clear all
              </Button>
            ) : (
              <Button size="sm" onClick={() => methods.selectAll()}>
                Select all
              </Button>
            )}
          </HStack>
        )}
      </Box>
      <Flex>
        <FormControl>
          <FormLabel>Execute Every:</FormLabel>
          <NumberInput
            min={1}
            value={executeEvery}
            onChange={(_, ValueAsNumber) => onEveryChange(ValueAsNumber)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </Flex>
      <Box>
        {props.options.map((item) => {
          return (
            <Checkbox
              key={item.label}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.label}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

const ContentRenderer = ({
  props,
  state,
  executeEvery,
}: SelectRenderer<ValueLabel> & {
  showSelectAll?: boolean;
  executeEvery: number | undefined;
}) => {
  const executeEveryLabel = executeEvery ? `Every: ${executeEvery} ` : "";
  const frequencyLabel =
    state.values.length > 0
      ? executeEvery && executeEvery > 1
        ? `${state.values[0].label}s`
        : state.values[0].label
      : "";

  return (
    <Flex p={1} alignItems="center">
      {state.values.length === 0 && "Frequency"}
      {state.values.length === 1 && (
        <Text>{`${executeEveryLabel} ${frequencyLabel}`}</Text>
      )}
      {state.values.length > 1 && (
        <>
          <Text mr={1}>{`${executeEveryLabel}${frequencyLabel}`}</Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Flex>
  );
};

export const ScheduledRequestFrequencyFilter = ({
  frequency,
  onFrequencyChange,
  showSelectAll,
  executeEvery,
  onEveryChange,
}: ScheduledFrequencyFilterProps) => {
  const options: ValueLabel[] = useMemo(
    () => [
      { value: "daily", label: "Day" },
      { value: "weekly", label: "Week" },
      { value: "monthly", label: "Month" },
      { value: "yearly", label: "Year" },
    ],
    []
  );
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const internalValues = useMemo(() => {
    return frequency.length > 0
      ? options.filter((x) => frequency.includes(x.value))
      : [];
  }, [frequency, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      onFrequencyChange(
        values && values.length ? values.map((x) => x.value) : []
      );
    },
    [onFrequencyChange]
  );

  return (
    <Select
      multi
      options={options}
      values={internalValues}
      onChange={handleChange}
      className={themeClass}
      searchable={true}
      searchBy="value"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      dropdownHeight="300"
      contentRenderer={(props) => (
        <ContentRenderer {...props} executeEvery={executeEvery} />
      )}
      dropdownRenderer={(props) => (
        <DropdownRenderer
          {...props}
          showSelectAll={showSelectAll}
          executeEvery={executeEvery}
          onEveryChange={onEveryChange}
        />
      )}
    />
  );
};
