import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { UpdateApiPurchaseTransaction } from "@operations-hero/lib-api-client";
import React from "react";
import { ExpensesForm } from "./ExpensesForm";

export interface ExpensesModalProps {
  isOpen: boolean;
  workingPurchase?: UpdateApiPurchaseTransaction | null;
  handleCloseModal: () => void;
  purchaseId: string | null;
}

export const ExpensesModal: React.FC<ExpensesModalProps> = ({
  isOpen,
  purchaseId,
  workingPurchase,
  handleCloseModal,
}) => (
  <Modal isOpen={isOpen} onClose={handleCloseModal} closeOnEsc size="md">
    <ModalOverlay />
    <ModalContent mb={10}>
      <ModalHeader fontWeight="700">
        {workingPurchase ? "Edit Expense" : "Add Expense"}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={8}>
        <ExpensesForm
          handleCloseModal={handleCloseModal}
          workingPurchase={workingPurchase || null}
          transactionId={purchaseId}
        />
      </ModalBody>
    </ModalContent>
  </Modal>
);
