import { Avatar, Flex, Image } from "@chakra-ui/react";
import {
  ApiInventoryItemAdjustment,
  ApiInventoryOrderItem,
  ApiTimelineEntry,
} from "@operations-hero/lib-api-client";
import { FC } from "react";

export const EntryHeaderAvatar: FC<{ entry: ApiTimelineEntry }> = ({
  entry,
}) => {
  if (entry.relation === "item") {
    return (
      <Image
        boxSize="40px"
        src={
          (entry.relationObject as ApiInventoryOrderItem).item.image ||
          undefined
        }
      />
    );
  }

  if (entry.relation === "adjustment") {
    return (
      <Flex flexDir="column" gap={2}>
        <Avatar src={entry.createdBy.profileImage} size="sm" />
        <Image
          boxSize="40px"
          src={
            (entry.relationObject as ApiInventoryItemAdjustment).item.image ||
            undefined
          }
        />
      </Flex>
    );
  }

  return <Avatar src={entry.createdBy.profileImage} size="sm" />;
};
