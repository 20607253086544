import { ApiEventGroupSumary } from "@operations-hero/lib-api-client";
import { Avatar, Box, Text, BoxProps } from "@chakra-ui/react";
import { FC } from "react";

interface GroupBadgeProps {
  group: ApiEventGroupSumary;
  boxProps?: BoxProps;
}

export const EventGroupBadge: FC<GroupBadgeProps> = ({ group, boxProps }) => {
  return (
    <Box gap={2} flexDir="row" display="flex" {...boxProps}>
      <Avatar src={group.logo || ""} name={group.name} />
      <Box>
        <Text fontSize="sm" color="gray.600">
          Group
        </Text>
        <Text fontWeight="bold">{group.name}</Text>
      </Box>
    </Box>
  );
};
