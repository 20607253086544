import { createPlugin } from "@fullcalendar/common";

import premiumCommonPlugin from "@fullcalendar/premium-common"; // eslint-disable-line import/no-duplicates
// ensure ambient declarations
import "@fullcalendar/premium-common"; // eslint-disable-line import/no-duplicates

import resourceCommonPlugin from "@fullcalendar/resource-common";
import timelinePlugin from "@fullcalendar/timeline";

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./main.css";

import moment from "moment-timezone";
import CustomResourceTimelineView from "./CustomTimelineView";

export const useCustomTimelinePlugin = () => {
  const { project } = useSelector((state: RootState) => state.projectPageSlice);

  const customTimelinePlugin = useMemo(() => {
    if (!project) return;

    return createPlugin({
      deps: [premiumCommonPlugin, resourceCommonPlugin, timelinePlugin],
      initialView: "resourceTimelineDay",
      views: {
        resourceTimeline: {
          type: "timeline",
          component: CustomResourceTimelineView,
          needsResourceData: true,
          resourceAreaWidth: "30%",
          resourcesInitiallyExpanded: true,
          eventResizableFromStart: true,
        },
        resourceTimelineWeek: {
          type: "resourceTimeline",
          slotDuration: { days: 1 },
          slotLabelFormat: [
            { year: "numeric", month: "long" },
            {
              week: "narrow",
            },
            { weekday: "narrow" },
          ],
          slotLabelContent(value) {
            if (value.level === 1) {
              const first = moment(value.date);
              let last = moment(value.date).add({ days: 7 });
              if (first.weekday() !== 0) {
                last = moment(value.date).add({ days: 7 - first.weekday() });
              }
              const formatted = `${first.format("D")} - ${last.format("D")}`;
              return formatted;
            }
            if (value.level === 2) {
            } else {
              return value.text;
            }
          },

          slotMinWidth: 30,
          visibleRange: {
            start: moment(project.start).subtract({ days: 3 }).toDate(),
            end: moment(project.end).add({ days: 3 }).toDate(),
          },
          slotLaneClassNames: ["custom-week-lane"],
          viewClassNames: ["custom-week-view"],
        },

        resourceTimelineMonth: {
          type: "resourceTimeline",
          slotDuration: { days: 1 },
          slotLabelFormat: [
            { year: "numeric", month: "short" },
            { day: "numeric" },
          ],

          slotMinWidth: 100,
          visibleRange: {
            start: moment(project.start).subtract({ days: 2 }).toISOString(),
            end: moment(project.end).add({ days: 2 }).toISOString(),
          },
          slotLabelContent(value) {
            if (value.level === 1) {
              const formatted = moment(value.date).format("ddd D");
              return formatted;
            } else {
              return value.text;
            }
          },
          viewClassNames: ["custom-month-view"],
        },

        resourceTimelineYear: {
          type: "resourceTimeline",
          slotDuration: { days: 1 },
          slotLabelFormat: [
            { year: "numeric" },
            { month: "short" },
            { day: "numeric" },
          ],
          slotMinWidth: 6,
          visibleRange: {
            start: moment(project.start).subtract({ days: 15 }).toDate(),
            end: moment(project.end).add({ days: 15 }).toDate(),
          },
          slotLabelContent(value) {
            if (value.level === 2) {
              return "";
            }
            return value.text;
          },
          slotLaneClassNames: ["custom-year-lane"],
          eventMinWidth: 2,
          viewClassNames: ["custom-year-view"],
        },
      },
    });
  }, [project]);

  return { customTimelinePlugin };
};
