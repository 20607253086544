import { Box, Flex, Icon, Text, TextProps } from "@chakra-ui/react";
import { ApiLocationSummary } from "@operations-hero/lib-api-client";
import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { LocationTypeIconMap } from "../../../components/badges/LocationBadge";
import { RootState } from "../../../store";

export interface SpaceTwoLineProps {
  value: ApiLocationSummary[];
  boldLocation?: boolean;
  ancestorIsTruncated?: boolean;
  locationTextProps?: TextProps;
  ancestorTextProps?: TextProps;
  maxItemsToShow?: number;
  showRestNumber?: boolean;
  showAncestor?: boolean;
}

export const SpaceTwoLine = ({
  value,
  boldLocation = true,
  ancestorIsTruncated = true,
  locationTextProps,
  ancestorTextProps,
  maxItemsToShow,
  showRestNumber,
  showAncestor = true,
}: SpaceTwoLineProps) => {
  const locationMap = useSelector(
    (state: RootState) => state.localCache.locationMap
  );

  const ancestors = useMemo(() => {
    if (value.length === 0) return null;

    const parents = [];
    let location = value[0];

    while (location.parent != null) {
      const parent = locationMap[location.parent];
      parents.unshift(parent);
      location = parent;
    }

    return parents;
  }, [locationMap, value]);

  const valuesToShow = useMemo(
    () => (maxItemsToShow ? value.slice(0, maxItemsToShow) : value),
    [maxItemsToShow, value]
  );

  const showRestlocationsNumber = useMemo(() => {
    if (!maxItemsToShow || !showRestNumber || maxItemsToShow >= value.length)
      return 0;
    if (value.length > maxItemsToShow) {
      return value.length - maxItemsToShow;
    }
    return 0;
  }, [maxItemsToShow, showRestNumber, value.length]);

  return value.length > 0 ? (
    <Box maxW="100%" overflow="hidden">
      <Flex flexDir="row" flexWrap="wrap">
        {valuesToShow.map((location, idx) => {
          return (
            <Fragment key={location.id}>
              <Text
                fontWeight={boldLocation ? "bold" : undefined}
                {...locationTextProps}
                mr={[8, 8, 0, 0, 8]}
              >
                <Icon
                  as={LocationTypeIconMap[location.type]}
                  mr={1}
                  mt={0.25}
                />
                {location.name}
                {valuesToShow.length - 1 === idx ? (
                  showRestlocationsNumber > 0 ? (
                    <Text as="span" fontWeight="normal">
                      {` and ${showRestlocationsNumber} more`}
                    </Text>
                  ) : (
                    ""
                  )
                ) : (
                  ","
                )}
              </Text>
            </Fragment>
          );
        })}
      </Flex>
      {showAncestor && ancestors && (
        <Text
          whiteSpace={!ancestorIsTruncated ? "normal" : "unset"}
          isTruncated={ancestorIsTruncated}
          fontSize={"sm"}
          colorScheme={"gray"}
          {...ancestorTextProps}
          mr={[8, 8, 0, 0, 8]}
        >
          {ancestors.map((l) => l.name).join(" > ")}
        </Text>
      )}
    </Box>
  ) : null;
};
