import { Button } from "@chakra-ui/react";
import {
  ApiComment,
  ApiProject,
  CreateApiComment,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import {
  CommentContainer,
  CommentForm,
  CommentFormActions,
  CommentHeader,
  CommentItem,
  CommentList,
  CommentsRoot,
} from "../../../components/comments";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { createComment } from "../../../store/planning-hq/comments/createComment.thunk";
import { deleteComment } from "../../../store/planning-hq/comments/deleteComment.thunk";
import {
  findComments,
  updateOptions,
} from "../../../store/planning-hq/comments/findComments.thunk";
import { updateComment } from "../../../store/planning-hq/comments/updateComment.thunk";
import {
  DEFAULT_COMMENTS_PAGE_SIZE,
  DEFAULT_COMMENTS_PAGE_START,
} from "../../../store/planning-hq/defaults";

type ProjectCommentsProps = {
  project: ApiProject;
};

export const ProjectComments: FC<ProjectCommentsProps> = ({ project }) => {
  const { apiClient, currentUser } = useAuthentication();
  const defaultValue = useMemo(
    () => ({
      comment: "",
      createdBy: currentUser,
      isPublic: true,
      mentioned: [],
    }),
    [currentUser]
  );
  const [newComment, setNewComment] = useState<CreateApiComment | undefined>(
    defaultValue
  );

  const { comments, commentsTotal, commentsOptions } = useSelector(
    (state: RootState) => state.projectPageSlice
  );

  const init = useRef(false);
  const thunkDispatch = useThunkDispatch();
  const toast = useShowToast();

  const handleSaveNewComment = useCallback(() => {
    if (!newComment) return;

    thunkDispatch(
      createComment({
        apiClient,
        projectId: project.id,
        comment: newComment as ApiComment,
      })
    )
      .then(() => {
        setNewComment(undefined);
        toast("success", "Comment created successfully");
      })
      .catch(() => {
        toast("error", "Could not create new comment");
      });
  }, [apiClient, project.id, newComment, thunkDispatch, toast]);

  const handleNewCommentChange = useCallback(
    (delta: Partial<CreateApiComment>) => {
      if (!newComment) {
        setNewComment({ ...defaultValue, ...delta });
      } else {
        setNewComment({
          ...newComment,
          ...delta,
        });
      }
    },
    [newComment, defaultValue]
  );

  const handleUpdateComment = useCallback(
    (commentToUpdate: ApiComment) => {
      const { id, isPublic, comment } = commentToUpdate;
      return thunkDispatch(
        updateComment({
          apiClient,
          projectId: project.id,
          commentId: id,
          delta: { isPublic, comment },
        })
      )
        .unwrap()
        .then((updatedComment) => {
          toast("success", "Comment updated successfully");
          return updatedComment;
        })
        .catch(() => {
          toast("error", "Could not update comment");
        });
    },
    [toast, project.id, apiClient, thunkDispatch]
  );

  const handleDeleteComment = useCallback(
    (commentToDelete: ApiComment) => {
      return thunkDispatch(
        deleteComment({
          apiClient,
          projectId: project.id,
          commentId: commentToDelete.id,
        })
      )
        .unwrap()
        .then(({ success }) => {
          toast("success", "Comment deleted");
          if (success) {
            return true;
          } else {
            throw new Error("Could not delete comment");
          }
        })
        .catch(() => {
          toast("error", "Could not delete comment");
        });
    },
    [apiClient, project.id, toast, thunkDispatch]
  );

  const onPageChange = useCallback(
    (page: number) => {
      thunkDispatch(
        updateOptions({ current: commentsOptions, delta: { page: page } })
      );
    },
    [thunkDispatch, commentsOptions]
  );

  useEffect(() => {
    if (!init.current) {
      thunkDispatch(
        findComments({
          apiClient,
          projectId: project.id,
          options: commentsOptions,
        })
      ).then(() => {
        init.current = true;
      });
    }
  }, [thunkDispatch, apiClient, project, commentsOptions]);

  useEffect(() => {
    if (init.current) {
      thunkDispatch(
        findComments({
          apiClient,
          projectId: project.id,
          options: commentsOptions,
        })
      );
    }
  }, [thunkDispatch, apiClient, commentsOptions, project]);

  return (
    <CommentsRoot>
      <CommentContainer>
        {/* New comment section */}
        <CommentHeader
          onVisibilityChange={(value) =>
            handleNewCommentChange({
              isPublic: value === "public" ? true : false,
            })
          }
        />
        <CommentForm
          onCommentChange={(value) =>
            handleNewCommentChange({ comment: value })
          }
          comment={newComment as ApiComment}
        />
        <CommentFormActions justifyItems="end" justifyContent="end">
          <Button
            size="sm"
            aria-label="cancel"
            variant="outline"
            colorScheme="blue"
            onClick={() => setNewComment(undefined)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            aria-label="save"
            colorScheme="blue"
            onClick={handleSaveNewComment}
          >
            Save
          </Button>
        </CommentFormActions>
      </CommentContainer>

      {comments.length > 0 ? (
        <CommentList>
          {comments.map((comment) => (
            <CommentItem
              comment={comment}
              key={`comment::${comment.id}`}
              onSaveChanges={handleUpdateComment}
              onDelete={handleDeleteComment}
            />
          ))}
        </CommentList>
      ) : null}

      {commentsTotal > DEFAULT_COMMENTS_PAGE_SIZE && commentsTotal > 0 ? (
        <Pager
          onPageChange={onPageChange}
          total={commentsTotal}
          currentPage={commentsOptions.page || DEFAULT_COMMENTS_PAGE_START}
          pageSize={commentsOptions.pageSize || DEFAULT_COMMENTS_PAGE_SIZE}
        />
      ) : null}
    </CommentsRoot>
  );
};
