import {
  Card,
  CardBody,
  CardProps,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { Attachment } from "../../components/attachments/Attachments";
import { ImageViewer } from "../../components/images/imageViewer/ImageViewer";

interface LocationImageCardProps {
  attachment: Attachment;
  cardProps?: CardProps;
}

export const GalleryImageCard: FC<LocationImageCardProps> = ({
  attachment,
  cardProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const imageBorderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  return (
    <>
      <Card
        overflow="hidden"
        cursor="pointer"
        variant="outline"
        minH="140px"
        onClick={onOpen}
        direction={{ base: "column", sm: "row" }}
        boxShadow="0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A"
      >
        <Flex p={2}>
          <Image
            h="140px"
            minW="180px"
            objectFit="cover"
            border="1px solid"
            borderRadius={6}
            alt={attachment.name}
            src={attachment.url || ""}
            borderColor={imageBorderColor}
            maxW={{ base: "100%", sm: "180px" }}
          />
        </Flex>

        <Stack>
          <CardBody p={2} display="flex" flexDir="column" gap={2} w="100%">
            <Heading size="md" isTruncated maxW="180px">
              {attachment.name}
            </Heading>

            <Text
              opacity={attachment.description ? 1 : 0.5}
              maxHeight="4.5em"
              overflowY="hidden"
              textOverflow="ellipsis"
            >
              {attachment.description || "No description"}
            </Text>
          </CardBody>
        </Stack>
      </Card>

      {isOpen && (
        <ImageViewer
          isOpen={isOpen}
          onClose={onClose}
          showOnlyImageViewer
          attachment={attachment}
        />
      )}
    </>
  );
};
