import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface LoadTransactionAttachmentsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  requestIdOrKey: string;
  transactionId: string;
}

export const loadTransactionAttachments = createAsyncThunk(
  "request/loadTransactionAttachments",
  async (params: LoadTransactionAttachmentsThunkParams, thunkAPI) => {
    const { apiClient, accountId, requestIdOrKey, transactionId } = params;
    const promise = apiClient.findRequestTransactionAttachments(
      accountId,
      requestIdOrKey,
      transactionId
    );

    return promise;
  }
);

export const loadTransactionAttachmentsHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadTransactionAttachments.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }
    const attachments = action.payload.data.map((attachment) => ({
      created: attachment.created,
      name: attachment.name,
      url: attachment.url,
      isNew: false,
      isUploading: false,
      uploadId: attachment.id,
      type: attachment.contentType,
    }));

    state.transactions.workingTransactionAttachments = attachments;
  });
};
