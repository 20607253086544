import {
  ApiLocation,
  ApiLocationSummary,
  ApiRequest,
  ApiUserSummary,
  ApiWorkflow,
  ApiWorkflowField,
  ApiWorkflowFieldDataType,
  SelectionField,
} from "@operations-hero/lib-api-client";
import { Text, View } from "@react-pdf/renderer";
import { useCallback, useMemo } from "react";
import { getVisibleFields } from "../../../utils/getVisibleFields";
import { BulkPrintFormat } from "../../requests/print/documents/BulkPrintFormat";
import { PdfStyles } from "./PrintableRequest";

export interface PrintableRequestFieldsProps {
  request: ApiRequest;
  ancestors?: ApiLocation[];
  visibleFields: ApiWorkflowField[];
  workflow: ApiWorkflow;
  format: BulkPrintFormat;
}

export const PrintableRequestFields = ({
  request,
  ancestors,
  visibleFields,
  format,
  workflow,
}: PrintableRequestFieldsProps) => {
  const getUserName = useCallback(
    (user: ApiUserSummary) => `${user.firstName} ${user.lastName}`,
    []
  );

  const assignees = useMemo(() => {
    const strings = request.assignees.map((x) =>
      x.type === "user" ? getUserName(x.assignee) : x.assignee.name
    );
    return strings.join(", ");
  }, [request, getUserName]);

  const singleLineFields = useMemo(() => {
    return visibleFields.filter(
      (x) =>
        !(
          (x.dataType === ApiWorkflowFieldDataType.text &&
            x.textAllowMultiline === true) ||
          x.dataType === ApiWorkflowFieldDataType.system
        )
    );
  }, [visibleFields]);

  const multilineFields = useMemo(() => {
    return visibleFields.filter(
      (x) =>
        x.dataType === ApiWorkflowFieldDataType.text &&
        x.textAllowMultiline === true
    );
  }, [visibleFields]);

  const { showStartDate, showDueDate } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const transformSummary = useCallback(
    (summary: string, mentioned: ApiUserSummary[]) => {
      let resSummary = summary.slice();
      mentioned.forEach((mention) => {
        resSummary = resSummary.replaceAll(
          `<u:${mention.id}>`,
          `@${mention.firstName} ${mention.lastName}`
        );
      });
      resSummary = resSummary.replaceAll(/[**_`]/g, "");
      return resSummary;
    },
    []
  );

  const getSingleFieldValue = useCallback(
    (field: ApiWorkflowField) => {
      const { checkbox, date, location, number, selection, text, user } =
        ApiWorkflowFieldDataType;
      const fieldValue = request.metadata[field.key];
      if (!fieldValue) return "";
      switch (field.dataType) {
        case checkbox:
          return "Checked";
        case date:
          return new Date(fieldValue as string).toLocaleString();
        case location:
          return (fieldValue as ApiLocationSummary).name;
        case number:
          return fieldValue.toString();
        case selection:
          return (fieldValue as SelectionField).value;
        case text:
          return fieldValue.toString();
        case user:
          return `${(fieldValue as ApiUserSummary).firstName} ${
            (fieldValue as ApiUserSummary).lastName
          }`;
      }
    },
    [request.metadata]
  );

  return (
    <View>
      <View style={PdfStyles.fields}>
        {format === "slim" && (
          <View style={PdfStyles.row}>
            <View style={{ flexGrow: 1, flexDirection: "row" }}>
              <Text style={PdfStyles.fieldLabel}>ID:</Text>
              <Text style={PdfStyles.fieldValue}>{request.key}</Text>
            </View>
            <View style={{ flexGrow: 1, flexDirection: "row" }}>
              <Text style={PdfStyles.fieldLabel}>Priority:</Text>
              <Text style={PdfStyles.fieldValue}>
                {request.priority.toUpperCase()}
              </Text>
            </View>
          </View>
        )}
        <View style={PdfStyles.row}>
          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Requested By:</Text>
            <Text style={PdfStyles.fieldValue}>
              {getUserName(request.requester)}
            </Text>
          </View>
          {format === "full" ? (
            <View style={{ flexGrow: 1, flexDirection: "row" }}>
              <Text style={PdfStyles.fieldLabel}>Created By:</Text>
              <Text style={PdfStyles.fieldValue}>
                {getUserName(request.createdBy)}
              </Text>
            </View>
          ) : (
            <View style={{ flexGrow: 1, flexDirection: "row" }}>
              <Text style={PdfStyles.fieldLabel}>Type:</Text>
              <Text style={PdfStyles.fieldValue}>
                {request.type.toUpperCase()}
              </Text>
            </View>
          )}
        </View>

        <View style={PdfStyles.row}>
          <Text style={PdfStyles.longFieldLabel}>Assigned To:</Text>
          <Text style={PdfStyles.longFieldValue}>{assignees}</Text>
        </View>
        <View style={PdfStyles.row}>
          <Text style={PdfStyles.longFieldLabel}>Location:</Text>
          <Text style={PdfStyles.longFieldValue}>
            {`${request.location?.name || "N/A"}`}
            {ancestors &&
              ancestors.length > 0 &&
              ` (${ancestors.map((l) => l.name).join(" > ")})`}
            {request.location?.active === false && ` (inactive)`}
          </Text>
        </View>
        <View style={PdfStyles.row}>
          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Category:</Text>
            <Text style={PdfStyles.fieldValue}>
              {request.reportingCategory?.name}
            </Text>
          </View>
          <View
            style={{
              flexGrow: 1,
              flexDirection: "row",
              padding: 0,
              margin: 0,
            }}
          >
            <Text style={PdfStyles.fieldLabel}>Reason:</Text>
            <Text style={PdfStyles.fieldValue}>{request.reason?.name}</Text>
          </View>
        </View>
        {(showStartDate || showDueDate) && (
          <View style={PdfStyles.row}>
            {showStartDate && (
              <View style={{ flexGrow: 1, flexDirection: "row" }}>
                <Text style={PdfStyles.fieldLabel}>Start:</Text>
                <Text style={PdfStyles.fieldValue}>
                  {request.scheduling.start
                    ? new Date(request.scheduling.start).toLocaleString()
                    : ""}
                </Text>
              </View>
            )}
            {showDueDate && (
              <View style={{ flexGrow: 1, flexDirection: "row" }}>
                <Text style={PdfStyles.fieldLabel}>Due:</Text>
                <Text style={PdfStyles.fieldValue}>
                  {request.scheduling.due
                    ? new Date(request.scheduling.due).toLocaleString()
                    : ""}
                </Text>
              </View>
            )}
          </View>
        )}
        {singleLineFields.length > 0 &&
          singleLineFields.map((field) => (
            <View style={PdfStyles.row} key={field.id}>
              <Text style={PdfStyles.longFieldLabel}>{field.name}:</Text>
              <Text style={PdfStyles.longFieldValue}>
                {getSingleFieldValue(field)}
              </Text>
            </View>
          ))}
        {multilineFields &&
          multilineFields.map((x) => (
            <View style={{ width: "100%", marginTop: 20 }}>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={PdfStyles.fieldLabel}>{x.name}</Text>
                </View>
              </View>
              <View
                style={{
                  minHeight: 60,
                  border: 1,
                  borderColor: "#ccc",
                  padding: 5,
                }}
              >
                <Text>{request.metadata[x.key]?.toString()}</Text>
              </View>
            </View>
          ))}
        <View style={{ marginTop: 20 }}>
          <View style={{ flexDirection: "column" }}>
            <View style={{ flexDirection: "row" }}>
              <Text style={PdfStyles.fieldLabel}>Summary or description</Text>
            </View>
          </View>
          <View
            style={{
              minHeight: 60,
              border: 1,
              borderColor: "#ccc",
              padding: 5,
            }}
          >
            <Text>{transformSummary(request.summary, request.mentioned)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
