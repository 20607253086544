import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiInventoryItem,
  ApiInventoryItemSupplier,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import { IoCaretDownOutline } from "react-icons/io5";
import { MdWarning } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ImageViewerModal from "../../../components/images/ImageViewerModal";
import { Pager } from "../../../components/pager/Pager";
import { RootState } from "../../../store";
import {
  addOrderItemToCart,
  InventoryItem,
  InventoryOrderItem,
  setItemQuantity,
  setItemSupplier,
  updateFilters,
} from "../../../store/inventory/inventory-order-item-list.slice";
import { PopoverUnitCostItemOrder } from "./PopoverUnitCostItemOrder";
export const InventoryOrderSelection: FC = () => {
  const { data, cartOrderItems, filters, total } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );
  const dispatch = useDispatch();
  const inputBgColor = useColorModeValue("white", "transparent");
  const boderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openMenu, setOpenMenu] = useState<{
    id: string | null;
    type: "stock" | "orderQty" | "unitCost" | "supplier";
  }>({ id: null, type: "stock" });

  const handleQuantityChange = useCallback(
    (id: string, value: string) => {
      dispatch(setItemQuantity({ id, requested: Number(value) }));
    },
    [dispatch]
  );

  const handleSupplierChange = useCallback(
    (id: string, supplier: ApiInventoryItemSupplier) => {
      dispatch(setItemSupplier({ id, supplier }));
    },
    [dispatch]
  );
  const handleToggleMenu = useCallback(
    (itemId: string, type: "stock" | "orderQty" | "unitCost" | "supplier") => {
      if (openMenu.id === itemId) {
        onClose();
        setOpenMenu({ id: null, type });
      } else {
        onOpen();
        setOpenMenu({ id: itemId, type });
      }
    },
    [openMenu, onClose, onOpen]
  );
  const handleOnAddOrderItem = useCallback(
    (item: InventoryItem) => {
      const itemCopy: ApiInventoryItem = { ...item };
      const newItem: InventoryOrderItem = {
        item: itemCopy,
        requested: item.requested || 0,
        idealStock: item.idealStock || 0,
        stock: item.stock || 0,
        unitOfMeasure: item.units,
        image: item.image,
        backOrder: false,
        delivered: 0,
        unitCost: item.cost,
        supplierSelected: item.supplierSelected,
      };

      dispatch(addOrderItemToCart(newItem));
    },
    [dispatch]
  );

  const handleOnChangePage = useCallback(
    (value: number) => {
      dispatch(updateFilters({ page: value }));
    },
    [dispatch]
  );

  const itemsList = useMemo(() => {
    const newItems: InventoryItem[] = data.filter(
      (item) => !cartOrderItems.items.some((i) => i.item.id === item.id)
    );

    return newItems;
  }, [cartOrderItems.items, data]);

  return (
    <Stack w="100%" gap={2}>
      <Flex fontWeight="bold" display={["none", "none", "none", "flex"]}>
        <Box minW="20%">Name</Box>
        <Box minW="14%">Supplier</Box>
        <Box minW="12%">Stock</Box>
        <Box minW="10%">Ideal Stock</Box>
        <Box minW="10%">Order Qty</Box>
        <Box minW="8%">Unit Cost</Box>
        <Box minW="12%" textAlign="right">
          Ordered
        </Box>
        <Box minW="13%"></Box>
      </Flex>

      <Divider my={2} display={["none", "none", "flex"]} />

      {itemsList.map((item) => {
        return (
          <Box key={`item-order:"${item.id}`} display="flex" flexDir="column">
            <Stack
              display="flex"
              py={1}
              flexDir={isDesktop ? "row" : "column"}
              p={[3, 3, 0]}
              border="1px solid"
              borderRadius={6}
              key={`item-order:"${item.id}`}
              borderColor={[boderColor, boderColor, "transparent"]}
              alignItems="center"
              alignSelf="stretch"
            >
              <Flex
                w={["100%", "100%", "100%", "100%", "20%"]}
                mt={1}
                alignItems="center"
                gap={2}
              >
                <ImageViewerModal
                  image={{ src: item.image || undefined, alt: "" }}
                  imageProps={{ boxSize: "40px" }}
                />
                <Flex flexDir="column" justifyContent="center">
                  <Text fontWeight="bold">{item.name}</Text>
                  {item.identifiers.externalId && (
                    <Text>#{item.identifiers.externalId}</Text>
                  )}
                </Flex>
              </Flex>
              <Flex
                w={["100%", "100%", "100%", "100%", "14%"]}
                display="flex"
                alignItems="center"
                justifyContent={isDesktop ? "center" : "flex-end"}
                gap={isDesktop ? 0 : 2}
              >
                {!isDesktop && <Text fontWeight="bold">Supplier:</Text>}
                <Text>{item.supplierSelected.name}</Text>
                <Menu
                  isOpen={
                    isOpen &&
                    openMenu.id === item.id &&
                    openMenu.type === "supplier"
                  }
                  onClose={onClose}
                  placement="left-start"
                >
                  <MenuButton
                    as={IconButton}
                    onClick={() => handleToggleMenu(item.id, "supplier")}
                    icon={<IoCaretDownOutline />}
                    aria-label="Suppliers"
                    variant="none"
                    size={isDesktop ? "md" : "sm"}
                  />
                  <MenuList>
                    {item.supplier.map((supp, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleSupplierChange(item.id, supp);
                        }}
                      >
                        {supp.supplier.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>

              <Flex
                w="12%"
                display={["none", "none", "none", "none", "flex"]}
                justifyContent="center"
                alignItems="center"
              >
                {item.storageLocations.some(
                  (loc) => loc.levels?.low && loc.quantity < loc.levels.low
                ) && (
                  <Icon
                    mr={2}
                    as={MdWarning}
                    color="yellow.500"
                    boxSize="18px"
                  />
                )}
                <Text>{item.stock}</Text>

                <Menu
                  isOpen={
                    isOpen &&
                    openMenu.id === item.id &&
                    openMenu.type === "stock"
                  }
                  onClose={onClose}
                  placement="left-start"
                >
                  <MenuButton
                    as={IconButton}
                    onClick={() => handleToggleMenu(item.id, "stock")}
                    icon={<IoCaretDownOutline />}
                    aria-label="Storage Locations"
                    variant="none"
                    size={isDesktop ? "md" : "sm"}
                  />
                  <MenuList>
                    <Text ml={3} color="gray.500">
                      Low stock locations{" "}
                    </Text>
                    {item.storageLocations.map((location, index) => (
                      <MenuItem key={index} pointerEvents="none">
                        {location.storageLocation.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex
                w="10%"
                display={["none", "none", "none", "none", "flex"]}
                justifyContent="center"
                alignItems="center"
              >
                <Text>{item.idealStock}</Text>
              </Flex>
              <Flex
                w={["100%", "100%", "100%", "100%", "10%"]}
                display="flex"
                justifyContent={isDesktop ? "center" : "flex-end"}
                alignItems="center"
                gap={isDesktop ? 0 : 2}
              >
                {!isDesktop && <Text fontWeight="bold">Order Qty:</Text>}
                <Text>{item.requested}</Text>
                <Menu
                  isOpen={
                    isOpen &&
                    openMenu.id === item.id &&
                    openMenu.type === "orderQty"
                  }
                  onClose={onClose}
                  placement="left-start"
                >
                  <MenuButton
                    as={IconButton}
                    onClick={() => handleToggleMenu(item.id, "orderQty")}
                    icon={<IoCaretDownOutline />}
                    aria-label="Requester"
                    size={isDesktop ? "md" : "sm"}
                    variant="ghost"
                    _hover={{ background: "none" }}
                    _active={{ background: "none" }}
                    shadow="none"
                    boxShadow="none"
                    _focus={{ shadow: "none" }}
                  />
                  <MenuList>
                    <MenuGroup>
                      <Flex gap={2} justifyContent="center" alignItems="center">
                        <Text fontWeight="bold">Order Qty.</Text>
                        <NumberInput
                          w="80px"
                          min={0}
                          value={item.requested}
                          bgColor={inputBgColor}
                          onChange={(value) =>
                            handleQuantityChange(item.id, value)
                          }
                        >
                          <NumberInputField paddingRight="1rem" />
                        </NumberInput>
                      </Flex>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
              <Flex
                w={["100%", "100%", "100%", "100%", "9%"]}
                display="flex"
                justifyContent={isDesktop ? "center" : "flex-end"}
                alignItems="center"
                gap={isDesktop ? 0 : 2}
              >
                {!isDesktop && <Text fontWeight="bold">Unit Cost:</Text>}
                <Text>${item.cost.toFixed(4)}</Text>
                <Menu
                  isOpen={
                    isOpen &&
                    openMenu.id === item.id &&
                    openMenu.type === "unitCost"
                  }
                  onClose={onClose}
                  placement="left-start"
                >
                  <MenuButton
                    as={IconButton}
                    onClick={() => handleToggleMenu(item.id, "unitCost")}
                    icon={<IoCaretDownOutline />}
                    aria-label="Unit Cost"
                    variant="none"
                    size={isDesktop ? "md" : "sm"}
                  />
                  <MenuList>
                    <PopoverUnitCostItemOrder item={item} />
                  </MenuList>
                </Menu>
              </Flex>
              <Flex
                w="12%"
                display={["none", "none", "none", "none", "flex"]}
                justifyContent="center"
                alignItems="center"
              >
                <Text>{item.totalOrdered}</Text>
              </Flex>
              <Flex
                w={["100%", "100%", "100%", "100%", "13%"]}
                gap={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  h="32px"
                  px={2}
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  borderColor="blue.600"
                  border="1px solid"
                  borderRadius="4px"
                  size="xs"
                  colorScheme="blue"
                  onClick={() => handleOnAddOrderItem(item)}
                >
                  Add
                </Button>
              </Flex>
            </Stack>
          </Box>
        );
      })}

      {filters.pageSize && total > filters.pageSize && (
        <Pager
          showDetails
          total={total}
          currentPage={filters.page || 1}
          pageSize={filters.pageSize || 20}
          onPageChange={handleOnChangePage}
        />
      )}
    </Stack>
  );
};
