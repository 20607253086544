import { Tag, TagLabel } from "@chakra-ui/react";
import { ApiVendor } from "@operations-hero/lib-api-client";
import React from "react";

export interface VendorBadgeProps {
  value: ApiVendor;
  size?: string;
  hideName?: boolean;
  backgroundColor?: string;
  textFontWeight?: string;
  fontSize?: number | number[];
}

export const VendorBadge: React.FC<VendorBadgeProps> = ({
  value,
  hideName,
  textFontWeight,
  fontSize,
}) => {
  return (
    <Tag size="lg" background="inherit" pl={0} pr={1}>
      {value.id === "null-vendor" || !hideName ? (
        <TagLabel
          fontWeight={textFontWeight || "normal"}
          fontStyle={value.id === "null-vendor" ? "italic" : "initial"}
          fontSize={fontSize ? fontSize : [16, 16, 14]}
        >
          {value.name}
        </TagLabel>
      ) : null}
    </Tag>
  );
};
