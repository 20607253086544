import {
  Box,
  Checkbox,
  Flex,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiDays,
  ApiPricing,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { ChangeEvent, FC } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { EventServiceDataEdit } from "./EventUpdateServices";

export type GetItemRateType = (
  itemId: string,
  isSelected: boolean,
  occurrences: CreateApiEventOccurrence[],
  eventDuration: number,
  quantity?: number
) =>
  | {
      total: number;
      pricing?: ApiPricing;
      generateInvoices?: boolean;
      days?: ApiDays[];
      rateId?: string;
      id?: string;
      name?: string;
    }[]
  | undefined;
interface EventServiceItemProps {
  index: number;
  service: EventServiceDataEdit;
  eventHoursDuration: number;
  handleOnSelectService: (service: EventServiceDataEdit) => void;
  handleOnChangeAdditionalNote: (
    service: EventServiceDataEdit,
    value: string
  ) => void;
  getItemRate: GetItemRateType;
  occurrences: CreateApiEventOccurrence[];
}

export const EventServiceItem: FC<EventServiceItemProps> = ({
  index,
  service,
  getItemRate,
  eventHoursDuration,
  handleOnSelectService,
  handleOnChangeAdditionalNote,
  occurrences,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const tooltipColor = useColorModeValue("gray.500", "white");

  const rate = getItemRate(
    service.service.id,
    service.isSelected,
    occurrences,
    eventHoursDuration
  );
  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  return (
    <Box key={service.service.id} w="100%" py={2}>
      <Flex justifyContent="space-between">
        {service.isRequired ? (
          <Popover placement="top-start" trigger="hover">
            <PopoverTrigger>
              <Checkbox
                readOnly
                maxW="max-content"
                isChecked={service.isRequired || service.isSelected}
                onChange={() => handleOnSelectService(service)}
              >
                <Flex alignItems="center" gap={1}>
                  {service.service.name}
                  {service.spaceName && (
                    <Tooltip label={service.spaceName} fontSize="md">
                      <Box color={tooltipColor}>
                        <IoMdInformationCircle />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              </Checkbox>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody>
                This service is required for this
                {service.spaceId ? " space" : " venue"}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Checkbox
            maxW="max-content"
            isChecked={service.isSelected}
            onChange={() => handleOnSelectService(service)}
          >
            <Flex alignItems="center" gap={1}>
              {service.service.name}
              {service.spaceName && (
                <Tooltip label={service.spaceName} fontSize="md">
                  <Box color={tooltipColor}>
                    <IoMdInformationCircle />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Checkbox>
        )}
        {enableInvoicesForEvents && rate && (
          <Text w="max-content" pt={1}>
            {formatCurrency(
              rate.reduce((sum, r) => {
                sum += r.total;
                return sum;
              }, 0)
            )}
          </Text>
        )}
      </Flex>
      {service.isSelected && (
        <>
          <Text fontSize="14px" pb={1} color={textColor}>
            Additional notes
          </Text>
          <Input
            value={service.additionalNotes}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnChangeAdditionalNote(service, e.target.value)
            }
          />
        </>
      )}
    </Box>
  );
};
