import { ApiAccount, ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { Attachment } from "../../../../components/attachments/Attachments";

export interface RemoveProjectAttachmentThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  fundingSourceId: string;
  attachmentId: string;
}

export const removeAttachment = createAsyncThunk(
  "funding-source-attachment/remove",
  async (params: RemoveProjectAttachmentThunkParams, thunkAPI) => {
    const { apiClient, account, fundingSourceId, attachmentId } = params;

    await apiClient.deleteProjectAttachment(
      account.id,
      fundingSourceId,
      attachmentId
    );

    return attachmentId;
  }
);

export const removeAttachmentHandlers = <
  T extends { attachments: Attachment[] },
>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(removeAttachment.fulfilled, (state, action) => {
    const idx = state.attachments.findIndex(
      (x) => x.uploadId === action.payload
    );

    state.attachments.splice(idx, 1);
  });
};
