import {
  ApiAccount,
  ApiClient,
  ApiWorkflow,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface LocalWorkflowSliceState {
  workflows: ApiWorkflow[];
  workflowsPageSize: number;
  showDisabledWorkflows: boolean;
}
export type WorkflowActionType = "deactive" | "reactive";

export interface ToggleOnOrOffWorkflowThunkParams {
  apiClient: ApiClient;
  accountId: string;
  workflowIdOrSlug: string;
  type: WorkflowActionType;
}
export interface InitLocalCacheThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
}

export const toggleActivateWorkflow = createAsyncThunk(
  "workflow/toogleActive",
  async (params: ToggleOnOrOffWorkflowThunkParams, thunkAPI) => {
    const { apiClient, accountId, workflowIdOrSlug, type } = params;
    if (type === "deactive") {
      await apiClient.deactivateWorkflow(accountId, workflowIdOrSlug);
    }
    if (type === "reactive") {
      await apiClient.reactivateWorkflow(accountId, workflowIdOrSlug);
    }
    return { workflowIdOrSlug, type };
  }
);

export const reloadWorkflows = createAsyncThunk(
  "workflow/reload-workflows",
  async (
    { apiClient, account, search }: InitLocalCacheThunkParams,
    thunkAPI
  ) => {
    const { showDisabledWorkflows, workflowsPageSize } = (
      thunkAPI.getState() as RootState
    ).workflow;
    const workflows = await apiClient.findWorkflows(account.id, {
      includeInactive: `${showDisabledWorkflows}`,
      pageSize: workflowsPageSize,
      search: search,
    });
    return { workflows: workflows.data };
  }
);

export const localWorkflowSlice = createSlice({
  name: "workflow",
  initialState: {
    workflows: [],
    workflowsPageSize: 100,
    showDisabledWorkflows: false,
  } as LocalWorkflowSliceState,
  reducers: {
    unloadCache: (state) => {
      state.workflows = [];
    },
    toggleShowDisabledWorkflows: (state, action: PayloadAction<boolean>) => {
      state.showDisabledWorkflows = action.payload;
    },
    deleteWorkflow: (state, action: PayloadAction<string>) => {
      const index: string = action.payload;
      state.workflows.forEach((workflow: ApiWorkflow) => {
        if (workflow.id === index && state.showDisabledWorkflows === false) {
          workflow.active = false;
        }
      });
    },
    updateWorkflow: (state, action: PayloadAction<ApiWorkflow>) => {
      const index: string = action.payload.id;
      state.workflows.forEach((workflow: ApiWorkflow) => {
        if (workflow.id === index) {
          workflow.active = true;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reloadWorkflows.fulfilled, (state, action) => {
      state.workflows = action.payload.workflows;
    });

    builder.addCase(toggleActivateWorkflow.fulfilled, (state, action) => {
      const { type, workflowIdOrSlug } = action.payload;
      const indexToRemove = state.workflows.findIndex(
        (workflow: ApiWorkflow) => workflow.id === workflowIdOrSlug
      );
      state.workflows[indexToRemove].active = true;
      if (indexToRemove !== -1 && type === "deactive") {
        state.workflows[indexToRemove].active = false;
        if (state.showDisabledWorkflows === false) {
          state.workflows.splice(indexToRemove, 1);
        }
      }
    });
  },
});

export const {
  unloadCache,
  toggleShowDisabledWorkflows,
  deleteWorkflow,
  updateWorkflow,
} = localWorkflowSlice.actions;

export default localWorkflowSlice.reducer;
