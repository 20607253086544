import { Text } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

interface ResendCountdownProps {
  duration: number;
  onFinish?: () => void;
}

const ResendCountdown: React.FC<ResendCountdownProps> = ({
  duration,
  onFinish,
}) => {
  const [timer, setTimer] = React.useState(duration);
  const intervalId = useRef<any>(null);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clearInterval(intervalId.current);
  }, []);

  useEffect(() => {
    if (timer === 0 && intervalId.current) {
      clearInterval(intervalId.current);
      onFinish?.();
    }
  }, [timer, onFinish]);
  return (
    <Text>{`Code was sent. You can request resend in ${timer} seconds`}</Text>
  );
};

export default ResendCountdown;
