import {
  Alert,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { lazy, Suspense, useCallback, useMemo, useState } from "react";
import { FallbackSpinner } from "../code-splitting/FallbackSpinner";
import { OnResultFunction } from "../qr-reader/qrReaderTypes";

const QrReader = lazy(() => import("../qr-reader/QrReader"));
const QrViewFinder = lazy(() => import("../qr-quick-scan/QrViewFinder"));

export interface QrQuickScanModalProps {
  isOpen: boolean;
  onClose: () => void;
  onData: (data: string) => void;
}

export const QrQuickScanModal = ({
  isOpen,
  onClose,
  onData,
}: QrQuickScanModalProps) => {
  const [error, setError] = useState("");

  const videoContraints = useMemo(
    () => ({ facingMode: { ideal: "environment" } }),
    [],
  );

  const handleResult = useCallback<OnResultFunction>(
    (result, error) => {
      if (error) {
        setError(error.message || error.name);
        return;
      }
      if (!result) {
        return;
      }

      onData(result.decode());
    },
    [onData],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent pb={6}>
        <ModalHeader>Lookup QR code or Barcode</ModalHeader>
        <ModalCloseButton />
        <Suspense fallback={<FallbackSpinner />}>
          <ModalBody>
            {error && <Alert>{error}</Alert>}
            {isOpen && (
              <QrReader
                ViewFinder={QrViewFinder}
                onResult={handleResult}
                constraints={videoContraints}
                scanDelay={250}
                videoId={"qr-quick-scan-modal-video"}
              />
            )}
          </ModalBody>
        </Suspense>
      </ModalContent>
    </Modal>
  );
};
