import {
  Alert,
  AlertIcon,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Stack,
  StackItem,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiBillingGroupType,
  ApiEventGroup,
  ApiGroupStatus,
  EventGroupDeclineReasons,
} from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdAdd } from "react-icons/md";
import { CleanValues } from "../../../../components/form-helpers/RadioButtonsControl";
import { EventGroupAutocomplete } from "../../../../components/selects/EventGroupAutocomplete";
import { useBillingGroupRates } from "../../../../hooks/useBillingGroupRates";
import { isInsuranceExpired } from "../../../../utils/getInsuranceExpired";
import { QuickEventGroupForm } from "./QuickEventGroupForm";

interface EventGroupSelectorProps {
  name: string;
  label: string;
  isDisabled?: boolean;
  value: ApiEventGroup | null;
  isEventInternal?: boolean;
  showExpiredInsurance?: boolean;
  setEventCost?: React.Dispatch<React.SetStateAction<number>>;
  fieldsToClean?: CleanValues[];
}

export const EventGroupSelector: FC<EventGroupSelectorProps> = ({
  name,
  label,
  value,
  isDisabled,
  isEventInternal,
  showExpiredInsurance = false,
  setEventCost,
  fieldsToClean,
}) => {
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const [expired, setExpired] = useState(false);
  const bgColor = useColorModeValue("gray.50", "gray.600");
  const { submitCount, setFieldValue } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField<ApiEventGroup | null>({
    name,
    value,
  });

  const rateGroupId = useMemo(() => {
    return value?.rateGroup?.id;
  }, [value]);

  const { getItemRatePerEvent } = useBillingGroupRates({
    rateGroupId,
    type: ApiBillingGroupType.event,
  });

  const handleItemPerEvent = useCallback(
    (rgId: string) => {
      const itemRate = getItemRatePerEvent(rgId);
      if (setEventCost && itemRate) {
        let total = 0;
        itemRate.forEach((i) => {
          if (i.rate?.pricing.price) {
            total += i.rate.pricing.price;
          }
        });
        setEventCost(total);
      }
    },
    [setEventCost, getItemRatePerEvent],
  );

  const handleOnChange = useCallback(
    (group: ApiEventGroup | null) => {
      if (group?.rateGroup?.showInPublicCalendar) {
        setFieldValue("isPublic", group.rateGroup.showInPublicCalendar);
      }
      helper.setTouched(true);
      helper.setValue(group || null);
      if (
        group &&
        group.insuranceExpiration &&
        !group.isInternalGroup &&
        isInsuranceExpired(group.insuranceExpiration)
      ) {
        setExpired(true);
        return;
      }
      setExpired(false);
      group && group.rateGroup && handleItemPerEvent(group.rateGroup.id);

      if (fieldsToClean) {
        fieldsToClean.forEach((item) => {
          const [fieldName, fieldValue] = item;
          setFieldValue(fieldName, fieldValue, false);
        });
      }
    },
    [helper, handleItemPerEvent, fieldsToClean, setFieldValue],
  );

  const handleOnCreate = useCallback(
    (group: ApiEventGroup) => {
      helper.setTouched(true);
      helper.setValue(group);
      const { isInternalGroup } = group;
      const isInternalEvent = isInternalGroup ? "internal" : "external";
      setFieldValue("isInternal", isInternalEvent);
      setOpenCreateForm(false);
    },
    [helper, setFieldValue],
  );

  useEffect(() => {
    meta.error && setExpired(false);
  }, [setExpired, meta.error]);

  useEffect(() => {
    isEventInternal && setExpired(false);
  }, [isEventInternal, setExpired]);

  const deniedReasons = useMemo(() => {
    if (!field.value || field.value.declineReasons.length === 0) return [];
    return field.value?.declineReasons.map(
      (key) => EventGroupDeclineReasons[key],
    );
  }, [field]);

  return (
    <Stack
      flexDir="row"
      flexWrap="wrap"
      alignItems="center"
      gridRowGap={"unset"}
      gridColumnGap={[2, 8]}
    >
      <StackItem flex={1}>
        <FormControl
          isInvalid={!!meta.error && (meta.touched || submitCount > 0)}
        >
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <EventGroupAutocomplete
            {...field}
            placeholder="Search groups"
            onChange={handleOnChange}
            name={name}
            isDisabled={isDisabled}
            isInternalGroup={isEventInternal}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      </StackItem>

      <StackItem alignSelf="flex-end">
        <Button
          minW="136px"
          variant="outline"
          colorScheme="blue"
          onClick={() => setOpenCreateForm(true)}
        >
          <Icon as={MdAdd} mr={2} />
          Create Group
        </Button>
      </StackItem>

      {openCreateForm && (
        <StackItem w="100%" borderRadius={6} bgColor={bgColor} p={4}>
          <Collapse in={openCreateForm} unmountOnExit>
            <QuickEventGroupForm
              onCreate={(group) => handleOnCreate(group)}
              onCancel={() => setOpenCreateForm(false)}
            />
          </Collapse>
        </StackItem>
      )}
      {showExpiredInsurance && expired && (
        <Alert status="error">
          <AlertIcon />
          This group's insurance has expired.
        </Alert>
      )}
      {field.value && field.value.status === ApiGroupStatus.denied && (
        <Alert status="error" display="flex" gap={1}>
          <AlertIcon />
          This group is denied for the following reason(s):{" "}
          {deniedReasons.join(", ")}.
        </Alert>
      )}
    </Stack>
  );
};
