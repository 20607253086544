import {
  Box,
  Checkbox,
  Divider,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  StackItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CronNumberInput } from "../../../components/cron/CronNumberInput";
import {
  CronValues,
  CustomCronGenerator,
} from "../../../components/cron/CustomCron";
import { StyledDatePicker } from "../../../components/inputs/StyledDatePicker";
import { RootState } from "../../../store";
import {
  setDueDaysAfterStart,
  setGenerateByCompletationDate,
  setGenerateOnlyWhenRequestsAreClosed,
  setScheduleCronExpression,
  setScheduleEndDate,
  setScheduleStartDate,
  setStartDaysAfterCreate,
} from "../../../store/scheduled-request-form/schedule-request-form.slice";

interface ScheduleFormProps {
  editStatus: string;
  activeStep: number;
  totalSteps: number;
}

export const ScheduleForm: FC<ScheduleFormProps> = ({
  editStatus,
  activeStep,
  totalSteps,
}) => {
  const { schedule, scheduledRequest, isLoading } = useSelector(
    (state: RootState) => state.scheduleRequestForm
  );
  const [endRadioValue, setEndRadioValue] = useState(
    schedule.endDate === null ? 0 : 1
  );
  const dispatch = useDispatch();
  const cronGeneratorBgColor = useColorModeValue("gray.50", "whiteAlpha.200");

  const handleOnChangeEndRadio = useCallback((value: string) => {
    setEndRadioValue(+value);
  }, []);

  const handleOnChangeGenerateNewOnlyIsClosed = useCallback(
    (value: string) => {
      dispatch(setGenerateOnlyWhenRequestsAreClosed(value.toString() === "1"));
    },
    [dispatch]
  );

  const handleOnChangeStartOrDueDays = useCallback(
    (value: number, name?: string) => {
      name === "beginDate" && dispatch(setStartDaysAfterCreate(value));
      name === "dueDate" && dispatch(setDueDaysAfterStart(value));
    },
    [dispatch]
  );

  const handleOnChangeStartDate = useCallback(
    (date: Date | null) => {
      if (date !== null) {
        dispatch(setScheduleStartDate(date.toISOString()));
      }
    },
    [dispatch]
  );

  const handleOnChangeEndDate = useCallback(
    (date: Date | null) => {
      dispatch(setScheduleEndDate(date ? date.toISOString() : null));
    },
    [dispatch]
  );

  const handleGenerateByCompletationDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setGenerateByCompletationDate(e.target.checked));
    },
    [dispatch]
  );
  const getCronExpression = useCallback(
    ({ cronExpression, executeEvery, frequency, weekOfMonth }: CronValues) => {
      dispatch(
        setScheduleCronExpression({
          cron: cronExpression,
          executeEvery,
          frequency,
          weekOfMonth,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <Heading my={4} fontSize={["2xl", "3xl"]}>
        {`${editStatus} Schedule Request (${activeStep} of ${totalSteps})`}
      </Heading>

      <Stack flexDir="row" flexWrap="wrap" spacing={6}>
        <StackItem w="100%">
          <Box w={["100%", "100%", "50%"]}>
            <FormLabel>Begin schedule on</FormLabel>
            <StyledDatePicker
              value={schedule?.startDate || null}
              name="startDate"
              showTime={true}
              format="EEEE, MMM-dd-yyyy, 'at' h:mm aa"
              onChange={handleOnChangeStartDate}
            />
          </Box>
        </StackItem>
        <Stack
          spacing={2}
          direction={["column", "column", "column", "row"]}
          maxW="max-content"
        >
          <StackItem
            w={["100%", "100%", "100%", "50%"]}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            <CronNumberInput
              name="beginDate"
              min={0}
              maxW="74px"
              label="When will work start?"
              onChange={handleOnChangeStartOrDueDays}
              value={scheduledRequest.startDaysAfterCreate}
              defaultValue={scheduledRequest.startDaysAfterCreate}
            >
              <Text fontWeight="bold" ml={2}>
                Days after request is created
              </Text>
            </CronNumberInput>
          </StackItem>

          <StackItem
            w={["100%", "100%", "100%", "50%"]}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            <CronNumberInput
              name="dueDate"
              min={0}
              maxW="74px"
              label="Due Date"
              onChange={handleOnChangeStartOrDueDays}
              value={scheduledRequest.dueDaysAfterStart}
              defaultValue={scheduledRequest.dueDaysAfterStart}
            >
              <Text fontWeight="bold" ml={2}>
                Days after start date
              </Text>
            </CronNumberInput>
          </StackItem>
        </Stack>
      </Stack>

      {!isLoading && (
        <StackItem
          w="100%"
          p={4}
          mt={4}
          borderRadius={4}
          bgColor={cronGeneratorBgColor}
        >
          <CustomCronGenerator
            InitialCustomCronValues={{
              initialCronExpression: schedule.cron,
              initialExecuteEvery: schedule.executeEvery,
              initialFrecuency: schedule.frequency,
              weekOfMonth: schedule.weekOfMonth,
            }}
            withWeeklyOptions={true}
            selectedStartDate={new Date(schedule.startDate || "")}
            getCronExpression={getCronExpression}
          />
        </StackItem>
      )}

      <StackItem w="100%">
        <FormLabel mt={4}>Ends</FormLabel>
        <RadioGroup
          value={`${endRadioValue}`}
          onChange={handleOnChangeEndRadio}
        >
          <Stack spacing={2} direction="row" maxW="max-content">
            <StackItem
              w={
                scheduledRequest.generateOnlyWhenRequestsAreClosed
                  ? "50%"
                  : "100%"
              }
            >
              <Stack direction="column">
                <Radio value={"0"} my={2} borderColor="gray.400">
                  <Text fontWeight={endRadioValue === 0 ? 700 : 500}>
                    Never, does not end
                  </Text>
                </Radio>
                <Radio value={"1"} borderColor="gray.400">
                  <Box display="flex" flexDir="row" alignItems="center">
                    <Text
                      as="span"
                      mr={4}
                      fontWeight={endRadioValue === 1 ? 700 : 500}
                    >
                      On
                    </Text>
                    {endRadioValue === 1 && (
                      <StyledDatePicker
                        name="endDate"
                        value={schedule?.endDate || null}
                        onChange={handleOnChangeEndDate}
                      />
                    )}
                  </Box>
                </Radio>
              </Stack>
            </StackItem>
            {scheduledRequest.generateOnlyWhenRequestsAreClosed && (
              <StackItem w="50%">
                <Checkbox
                  isChecked={scheduledRequest.generateByCompletionDate === true}
                  onChange={handleGenerateByCompletationDateChange}
                >
                  Generate next Schedule based on Completion Date
                </Checkbox>
              </StackItem>
            )}
          </Stack>
        </RadioGroup>
      </StackItem>

      <Divider my={2} />
      {!scheduledRequest.generateByCompletionDate && (
        <StackItem>
          <Text fontWeight={700} my={2}>
            Should a request be generated if the previous schedule hasn't been
            completed?
          </Text>
          <RadioGroup
            value={
              scheduledRequest.generateOnlyWhenRequestsAreClosed ? "1" : "0"
            }
            onChange={handleOnChangeGenerateNewOnlyIsClosed}
          >
            <Radio value="0" mr={4} borderColor="gray.400">
              yes
            </Radio>
            <Radio value="1" borderColor="gray.400">
              no
            </Radio>
          </RadioGroup>
        </StackItem>
      )}
    </>
  );
};
