import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ApiUserAccountLink } from "@operations-hero/lib-api-client";
import React, { useCallback, useState } from "react";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../auth/AuthProvider";
import { Pager } from "../../pager/Pager";

export interface SwitchAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SwithAccountModal({
  isOpen,
  onClose,
}: SwitchAccountModalProps) {
  const { accounts, currentAccount, setCurrentAccount } = useAuthentication();
  const navigate = useNavigate();

  const [accountList, setAccountList] = useState([...accounts]);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const PAGE_SIZE: number = 10;
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setSearch(newValue);
      setAccountList(
        accounts.filter(
          (acct) =>
            acct.name.toLowerCase().indexOf(newValue.toLowerCase()) >= 0,
        ),
      );
      setPage(1);
    },
    [accounts],
  );

  const handleSwitchAccount = useCallback(
    (acct: ApiUserAccountLink) => () => {
      setCurrentAccount(acct);
      onClose();
      navigate("/");
    },
    [navigate, setCurrentAccount, onClose],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Choose Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {accounts.length > PAGE_SIZE && (
            <FormControl mb={8}>
              <FormLabel>Search {accounts.length} available accounts</FormLabel>
              <InputGroup>
                <InputLeftAddon>
                  <SearchIcon />
                </InputLeftAddon>
                <Input
                  placeholder="Search Accounts"
                  value={search}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </FormControl>
          )}
          {accountList
            .slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page)
            .map((acct) => {
              return (
                <Button
                  key={acct.id}
                  w="100%"
                  p={4}
                  mb={4}
                  minH="60px"
                  isDisabled={currentAccount.id === acct.id}
                  onClick={handleSwitchAccount(acct)}
                >
                  <Flex alignItems="center" maxW="100%">
                    {acct.logo && (
                      <Box p={0} m={0} mr={4}>
                        <Img
                          src={acct.logo}
                          mr={2}
                          alt="Logo"
                          maxH="50px"
                          maxW="100px"
                        />
                      </Box>
                    )}
                    <Box maxW="100%">
                      <Text isTruncated={true} wordBreak="break-word">
                        {acct.name}
                        {currentAccount.id === acct.id && (
                          <Box ml={2} as="span">
                            <Icon as={RiCheckboxCircleLine} color="green.400" />
                          </Box>
                        )}
                      </Text>
                    </Box>
                  </Flex>
                </Button>
              );
            })}
          {accountList.length > PAGE_SIZE && (
            <Pager
              currentPage={page}
              pageSize={PAGE_SIZE}
              total={accountList.length}
              onPageChange={(page) => setPage(page)}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
