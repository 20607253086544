import { Box, useColorModeValue } from "@chakra-ui/react";
import { ApiRequestStatus, ApiWorkflow } from "@operations-hero/lib-api-client";
import {
  GroupBase,
  OptionProps,
  Select,
  SingleValueProps,
} from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { StatusBadge } from "../badges/StatusBadge";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";

export interface RequestStatusSelectProps {
  workflow?: ApiWorkflow | null;
  status: ApiRequestStatus | undefined;
  onChange?: (status: ApiRequestStatus) => void;
  statuses?: ApiRequestStatus[];
}

type ValueLabel = {
  value: ApiRequestStatus;
  label: string;
};

type ValueAndLabel = {
  value: ApiRequestStatus;
  label: ApiRequestStatus;
};

const CustomOptionComponent = (
  props: OptionProps<ValueAndLabel, false, GroupBase<ValueAndLabel>>,
) => {
  const { data, innerRef, innerProps } = props;
  const hoverBgColor = useColorModeValue("gray.100", "whiteAlpha.100");
  return (
    <Box
      {...innerProps}
      p={2}
      ref={innerRef}
      _hover={{ backgroundColor: hoverBgColor }}
      cursor="pointer"
    >
      <StatusBadge status={data.value} />
    </Box>
  );
};

const CustomSingleValueComponent = (
  props: SingleValueProps<ValueAndLabel, false, GroupBase<ValueAndLabel>>,
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <StatusBadge status={data.value} />
    </Box>
  );
};

export const RequestStatusSelect = ({
  status,
  workflow,
  statuses,
  onChange,
}: RequestStatusSelectProps) => {
  const statusOptionsFromWorkflow = useCallback(() => {
    let options = statuses
      ? statuses
      : [
          ApiRequestStatus.new,
          ApiRequestStatus.approved,
          ApiRequestStatus.queued,
          ApiRequestStatus.started,
          ApiRequestStatus.review,
          ApiRequestStatus.hold,
          ApiRequestStatus.closed,
          ApiRequestStatus.canceled,
        ];

    if (workflow) {
      if (!workflow.requireApproverForNewRequests) {
        options = options.filter((opt) => opt !== ApiRequestStatus.approved);
      }

      if (!workflow.requireReviewerBeforeClose) {
        options = options.filter((opt) => opt !== ApiRequestStatus.review);
      }
    }

    return options;
  }, [workflow, statuses]);

  const components = useMemo(
    () => ({
      SingleValue: CustomSingleValueComponent,
      Option: CustomOptionComponent,
      ...getCustomSelectComponents(),
    }),
    [],
  );

  const options = useMemo(() => {
    const options = statusOptionsFromWorkflow();

    return options.map((x) => ({
      value: x,
      label: x,
    }));
  }, [statusOptionsFromWorkflow]);

  const handleChange = useCallback(
    (item: ValueLabel | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange],
  );

  return (
    <Select
      options={options}
      components={components}
      onChange={handleChange}
      chakraStyles={commonStyles}
      value={options.find((x) => x.value === status) || null}
    />
  );
};
