import { EventInput } from "@fullcalendar/react";
import { endOfWeek, format, startOfWeek } from "date-fns";
import { DATE_FORMAT_TIMESHEET } from "../../store/timesheet.slice";

export interface TimesheetFiltersValues {
  values: EventInput[];
}
export const timesheetDefaultFilters: TimesheetFiltersValues = {
  values: [
    {
      id: format(new Date(), DATE_FORMAT_TIMESHEET),
      title: "",
      start: startOfWeek(new Date()),
      end: startOfWeek(new Date()),
    },
    {
      id: format(new Date(), DATE_FORMAT_TIMESHEET),
      title: "",
      start: endOfWeek(new Date()),
      end: endOfWeek(new Date()),
    },
  ],
};
