import { ApiAccount, ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface LoadTaskbooksThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  key: string;
  page?: number;
}

export const loadTaskbooks = createAsyncThunk(
  "requests/loadTaskbooks",
  async (params: LoadTaskbooksThunkParams, thunkAPI) => {
    const { apiClient, account, key } = params;

    const pagedTaskbooks = await apiClient.findRequestTaskbooks(
      account.id,
      key
    );
    return pagedTaskbooks;
  }
);

export const loadTaskbooksHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadTaskbooks.fulfilled, (state, action) => {
    const { data, total } = action.payload;
    state.taskbooks = data;
    state.taskbooksTotal = total;
  });
};
