import { Box, VStack } from "@chakra-ui/react";
import { ApiVenueSummary } from "@operations-hero/lib-api-client";
import { useFormikContext } from "formik";
import React, { FC, useEffect, useMemo, useReducer } from "react";
import { QuestionResponseFields } from "./QuestionReponseFields";
import {
  VenueQuestionResponseReducerValues,
  venueQuestionsResponsesReducer,
} from "./VenueQuestionsResponsesReducer";

interface VenueQuestionsSectionProps {
  venue: ApiVenueSummary;
  setResponses: React.Dispatch<
    React.SetStateAction<VenueQuestionResponseReducerValues[]>
  >;
  setQuestionsFieldsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VenueQuestionsSection: FC<VenueQuestionsSectionProps> = ({
  venue,
  setResponses,
  setQuestionsFieldsValid,
}) => {
  const { submitCount } = useFormikContext();

  const questionResponses: VenueQuestionResponseReducerValues[] =
    useMemo(() => {
      return venue.questions.map((question) => {
        return {
          question,
          venue,
        };
      });
    }, [venue]);

  const [questionResponseValues, dispatchQuestionResponseValues] = useReducer(
    venueQuestionsResponsesReducer,
    questionResponses
  );

  useEffect(() => {
    setResponses(
      questionResponseValues.filter((response) => {
        return response.value;
      })
    );
  }, [questionResponseValues, setResponses]);

  useEffect(() => {
    const invalid = questionResponseValues.some((response) => {
      if (response.question.required) {
        return !response.value;
      }
      return false;
    });
    setQuestionsFieldsValid(!invalid);
  }, [questionResponseValues, setQuestionsFieldsValid]);

  return (
    <VStack gap={4} width="100%">
      {questionResponseValues.map((questionResponse, index) => {
        return (
          <Box
            gap={4}
            width="100%"
            key={`questionsResponses::${questionResponse.question.id}::`}
            position="relative"
          >
            <QuestionResponseFields
              question={questionResponse.question}
              value={questionResponse.value}
              index={index + 1}
              onChange={(
                value: string | number | boolean | string[] | null
              ) => {
                dispatchQuestionResponseValues({
                  value,
                  index,
                });
              }}
              isInvalid={
                questionResponse.question.required &&
                !questionResponse.value &&
                submitCount > 0
              }
            />
          </Box>
        );
      })}
    </VStack>
  );
};
