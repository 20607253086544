import { Tag, TagLabel, TagLeftIcon, TagProps } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { MdAlarmAdd, MdMeetingRoom } from "react-icons/md";

type RequestKeyBadgeProps = TagProps & {
  request: ApiRequest;
};

export const RequestKeyBadge = ({ request, ...rest }: RequestKeyBadgeProps) => {
  const icon = useMemo(() => {
    if (!request) return undefined;

    if (request.scheduledRequestId) return MdAlarmAdd;
    if (request.eventId) return MdMeetingRoom;

    return undefined;
  }, [request]);

  return (
    <Tag
      variant="outline"
      borderWidth={0}
      p={0}
      boxShadow={0}
      color="inherit"
      {...rest}
    >
      {icon && <TagLeftIcon as={icon} mr={1} />}
      <TagLabel>{request.key}</TagLabel>
    </Tag>
  );
};
