import {
  ApiAsset,
  ApiLocation,
  ApiRequestTaskBookDetail,
} from "@operations-hero/lib-api-client";
import { Document } from "@react-pdf/renderer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { PdfViewerModal } from "../../../components/pdf-viewer-modal/PdfViewerModal";
import { RootState } from "../../../store";
import { PrintableRequest, PrintableRequestProps } from "./PrintableRequest";

export interface PrintRequestModalProps extends PrintableRequestProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PrintRequestModal = ({
  isOpen,
  onClose,
  request,
  visibleFields,
  account,
  comments,
}: PrintRequestModalProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [initCompleted, setInitCompleted] = useState<boolean>(false);
  const [assetsMap, setAssetsMap] = useState<Record<string, ApiAsset[]>>();
  const [taskbooksMap, setTaskbooksMap] =
    useState<Record<string, ApiRequestTaskBookDetail[]>>();
  const { locationMap, workflowMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const ancestors = useMemo(() => {
    if (!request.location || !locationMap) return [] as ApiLocation[];

    const location = locationMap[request.location.id];
    const parents = location.treePath.split(".").map((x) => locationMap[x]);
    parents.splice(-2, 2); //remove the self reference in treePath and last empty value
    return parents;
  }, [request.location, locationMap]);

  const ancestorsAssets = useMemo(() => {
    if (!assetsMap || !assetsMap[request.id])
      return {} as Record<string, ApiLocation[]>;
    return assetsMap[request.id].reduce<Record<string, ApiLocation[]>>(
      (acc, asset) => {
        if (!asset.location || !locationMap)
          return {} as Record<string, ApiLocation[]>;
        const location = locationMap[asset.location.id];
        const parents = location.treePath.split(".").map((x) => locationMap[x]);
        parents.splice(-2, 2);
        acc[asset.id] = parents;
        return acc;
      },
      {}
    );
  }, [assetsMap, locationMap, request.id]);

  const pdfDocument = useMemo(
    () =>
      isOpen && initCompleted ? (
        <Document>
          <PrintableRequest
            request={request}
            assets={assetsMap ? assetsMap[request.id] : undefined}
            ancestorsAssets={ancestorsAssets}
            taskbooks={taskbooksMap ? taskbooksMap[request.id] : undefined}
            ancestors={ancestors}
            visibleFields={visibleFields}
            account={account}
            format="full"
            comments={comments}
            workflow={workflowMap[request.workflow.id]}
          />
        </Document>
      ) : null,
    [
      isOpen,
      initCompleted,
      request,
      assetsMap,
      ancestorsAssets,
      taskbooksMap,
      ancestors,
      visibleFields,
      account,
      comments,
      workflowMap,
    ]
  );

  const loadAssetsTaskbooks = useCallback(async () => {
    const ids = [request.id];
    const taskbooks = await apiClient.getRequestTaskbookMap(currentAccount.id, {
      ids,
    });
    const assets = await apiClient.getRequestAssetsMap(currentAccount.id, {
      ids,
    });
    setAssetsMap(assets);
    setTaskbooksMap(taskbooks);
    setInitCompleted(true);
  }, [apiClient, currentAccount.id, request]);

  useEffect(() => {
    loadAssetsTaskbooks();
  }, [loadAssetsTaskbooks]);

  return isOpen && initCompleted && pdfDocument ? (
    <PdfViewerModal
      isOpen={isOpen}
      onClose={onClose}
      pdfDocument={pdfDocument}
      downloadName={`HeroHQ_Request_${request.key}.pdf`}
    />
  ) : null;
};
