import { Box, Flex, Icon, IconButton } from "@chakra-ui/react";
import {
  ApiLaborTransaction,
  LaborType,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { MdCheck } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { TimeInput } from "../../components/inputs/TimeInput";
import { StyledSelect } from "../../components/selects/StyledSelect";
import { useShowToast } from "../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../store";
import {
  createTransactionTimesheet,
  deleteTransactionTimesheet,
  setIsWorkingTransaction,
  unloadNewTransactions,
  updateTransactionTimesheet,
} from "../../store/timesheet.slice";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

interface PopoverTransactionRowProps {
  transaction: ApiLaborTransaction;
  keyMap: string;
}
type ValueLabel = {
  value: LaborType;
  label: string;
};
export const PopoverTransactionRow: FC<PopoverTransactionRowProps> = ({
  transaction,
  keyMap,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { isWorkingTransaction } = useSelector(
    (state: RootState) => state.timesheet
  );
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const showToast = useShowToast();

  const [workingTransaction, setWorkingTransaction] =
    useState<ApiLaborTransaction>(transaction);

  const onChangeLaborType = useCallback(
    (labor: ValueLabel) => {
      const laborType = labor.value;
      setWorkingTransaction({ ...workingTransaction, laborType });
      dispatch(setIsWorkingTransaction(workingTransaction.id));
    },
    [dispatch, workingTransaction]
  );

  const onChangeHours = useCallback(
    (hours: number) => {
      setWorkingTransaction({ ...workingTransaction, hours });
      dispatch(setIsWorkingTransaction(workingTransaction.id));
    },
    [dispatch, workingTransaction]
  );

  const onDeleteTransaction = useCallback(() => {
    thunkDispatch(
      deleteTransactionTimesheet({
        apiClient,
        accountId: currentAccount.id,
        workingTransaction,
        key: keyMap,
      })
    )
      .then(unwrapResult)
      .then(() => {
        if (workingTransaction.id !== "new") {
          showToast("success", "Transaction deleted successfully");
        }
        dispatch(setIsWorkingTransaction(undefined));
      })
      .catch(() => {
        showToast("error", "Something went wrong, please try again");
      });
  }, [
    thunkDispatch,
    dispatch,
    apiClient,
    currentAccount.id,
    keyMap,
    showToast,
    workingTransaction,
  ]);

  const onSaveTransaction = useCallback(() => {
    if (workingTransaction.id === "new") {
      thunkDispatch(
        createTransactionTimesheet({
          apiClient,
          accountId: currentAccount.id,
          workingTransaction,
          key: keyMap,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Transaction created successfully");
          dispatch(setIsWorkingTransaction(undefined));
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });
      return;
    }
    thunkDispatch(
      updateTransactionTimesheet({
        apiClient,
        accountId: currentAccount.id,
        workingTransaction,
        key: keyMap,
      })
    )
      .then(unwrapResult)
      .then(() => {
        showToast("success", "Transaction updated successfully");
        dispatch(setIsWorkingTransaction(undefined));
      })
      .catch(() => {
        showToast("error", "Something went wrong, please try again");
      });
  }, [
    thunkDispatch,
    dispatch,
    apiClient,
    currentAccount.id,
    keyMap,
    showToast,
    workingTransaction,
  ]);

  const isDisabled = useMemo(() => {
    if (isWorkingTransaction) {
      return isWorkingTransaction !== workingTransaction.id;
    }
    return false;
  }, [isWorkingTransaction, workingTransaction.id]);

  useEffect(() => {
    return () => {
      dispatch(unloadNewTransactions(keyMap));
    };
  }, [dispatch, keyMap]);

  return (
    <Flex gap={2} justifyContent="center" w="100%" alignItems="center">
      <Box w="50%" ml={2}>
        <StyledSelect
          isDisabled={isDisabled}
          options={Object.keys(LaborType).map((type) => ({
            value: type,
            label: capitalizeFirstLetter(type),
          }))}
          value={workingTransaction.laborType}
          onChange={onChangeLaborType}
        />
      </Box>
      <Box w="20%">
        <TimeInput
          value={workingTransaction.hours}
          onChange={onChangeHours}
          isDisabled={isDisabled}
        />
      </Box>
      <Box alignContent="center" w="20%">
        <IconButton
          aria-label="Delete"
          icon={<Icon as={MdCheck} />}
          disabled={isDisabled}
          variant="unstyled"
          minWidth="5"
          minHeight="5"
          lineHeight="3"
          color="gray"
          size="lg"
          onClick={onSaveTransaction}
          _focus={{ border: "none" }}
          ml={-6}
        />

        <IconButton
          aria-label="Delete"
          icon={<Icon as={BsTrash} />}
          variant="unstyled"
          ml={2}
          minWidth="5"
          minHeight="5"
          lineHeight="3"
          color="gray"
          size="lg"
          onClick={onDeleteTransaction}
          _focus={{ border: "none" }}
        />
      </Box>
    </Flex>
  );
};
