import {
  Button,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Stack,
  StackItem,
} from "@chakra-ui/react";
import {
  ApiBillingGroupType,
  ApiEventStatus,
  ApiSpace,
  ApiSpaceService,
  ApiSpaceSummary,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { differenceInMinutes } from "date-fns";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useBillingGroupRates } from "../../../../hooks/useBillingGroupRates";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  removeQuestionResponse,
  updateEvent,
} from "../../../../store/events/event-details.slice";
import { toastStatus } from "../EventEditForm";
import { EventServiceData } from "../form-components/ServicesMulticheck";
import { EventServiceItem } from "./EventServiceItem";

interface EventServicesProps {
  onCancel: () => void;
  showToast: (status: toastStatus, title: string) => void;
  setServicesCost: Dispatch<SetStateAction<number>>;
}

export interface EventServiceDataEdit extends EventServiceData {
  isSelected: boolean;
}

export const EventServices: FC<EventServicesProps> = ({
  onCancel,
  showToast,
  setServicesCost,
}) => {
  const [services, setServices] = useState<EventServiceDataEdit[]>([]);
  const [firstRender, setFirstRender] = useState(false);
  const { apiClient, currentAccount } = useAuthentication();

  const thunkDispatch = useThunkDispatch();
  const { event, eventDates, workingVenue } = useSelector(
    (state: RootState) => state.eventDetails
  );
  const dispatch = useDispatch();
  const rateGroupId = event?.eventGroup.rateGroupId || undefined;
  const { rates, getItemRates } = useBillingGroupRates({
    rateGroupId: rateGroupId,
    type: ApiBillingGroupType.service,
  });

  const handleOnSelectService = useCallback(
    (service: EventServiceDataEdit) => {
      const index = services.findIndex(
        (s) =>
          s.service.id === service.service.id && s.spaceId === service.spaceId
      );

      if (index !== -1) {
        const servicesCopy = [...services];
        servicesCopy[index].isSelected = !servicesCopy[index].isSelected;
        setServices(servicesCopy);
      }
    },
    [services]
  );

  const selectedServices = useMemo(
    () => services.filter((service) => service.isSelected),
    [services]
  );

  const handleOnChangeAdditionalNote = useCallback(
    (service: EventServiceDataEdit, value: string) => {
      const servicesCopy = [...services];
      const index = servicesCopy.findIndex(
        (s) =>
          service.service.id === s.service.id && service.spaceId === s.spaceId
      );
      if (index !== -1) {
        servicesCopy[index].additionalNotes = value;
        setServices(servicesCopy);
      }
    },
    [services]
  );

  const eventHoursDuration = useMemo(() => {
    const firstOccurrence = eventDates[0];
    if (!firstOccurrence) return 1;
    const { start, end } = firstOccurrence;
    if (!start || !end) return 1;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const occurrenceMinutes = differenceInMinutes(endDate, startDate);
    const hours = parseFloat((occurrenceMinutes / 60).toFixed(2));
    return hours;
  }, [eventDates]);

  const setServicesTotalCost = useCallback(() => {
    if (!rates) return;
    const total = services.reduce((acc, current) => {
      const rate = getItemRates(
        current.service.id,
        current.isSelected,
        eventDates,
        eventHoursDuration
      );
      const equipmentCost = rate
        ? rate.reduce((sum, r) => {
            sum = sum + r.total;
            return sum;
          }, 0)
        : 0;
      return acc + equipmentCost;
    }, 0);
    setServicesCost(total);
  }, [
    services,
    setServicesCost,
    getItemRates,
    eventHoursDuration,
    rates,
    eventDates,
  ]);

  const handleOnSave = useCallback(() => {
    if (event === null || !event.id) return;

    const servicesToSave = selectedServices.map((selected) => ({
      service: selected.service.id,
      spaceId: selected.spaceId,
      additionalNotes: selected.additionalNotes,
    }));

    // If a service is removed from the event, also the responses for it need to be removed
    const oldServices = event.services.map((s) => s.id);
    const newServices = selectedServices.map((s) => s.id);
    const servicesResponsesToDelete = oldServices
      .filter((id) => !newServices.includes(id))
      .filter(Boolean);
    if (servicesResponsesToDelete.length > 0) {
      apiClient
        .bulkDeleteQuestionResponse(currentAccount.id, {
          eventId: event.id,
          serviceIds: servicesResponsesToDelete,
        })
        .then((response) => {
          dispatch(removeQuestionResponse(response));
        });
    }

    const isEventConfirmed = event && event.status === ApiEventStatus.confirmed;

    const eventToUpdate = {
      services: servicesToSave,
      status: isEventConfirmed ? ApiEventStatus.confirmed : undefined,
    };

    thunkDispatch(
      updateEvent({
        apiClient,
        eventId: event.id,
        event: eventToUpdate,
        accountId: currentAccount.id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        showToast("success", "Event was updated successfully");
        setServicesTotalCost();
        onCancel();
      })
      .catch(() => {
        showToast("error", "Something went wrong, please try again");
      });
  }, [
    apiClient,
    currentAccount.id,
    dispatch,
    event,
    onCancel,
    selectedServices,
    setServicesTotalCost,
    showToast,
    thunkDispatch,
  ]);

  const hasChanges = useMemo(() => {
    if (event) {
      const localServices = selectedServices.map(
        (service) => `${service.service.id}${service.additionalNotes}`
      );
      const reduxServices = event.services.map(
        (service) => `${service.id}${service.additionalNotes}`
      );

      if (
        localServices.length > reduxServices?.length ||
        localServices.length < reduxServices?.length
      ) {
        return true;
      }

      if (localServices.sort().join("") !== reduxServices.sort().join("")) {
        return true;
      }
    }
    return false;
  }, [event, selectedServices]);

  const setAllServices = useCallback(async () => {
    if (!event || !workingVenue) return;
    const allServices: EventServiceDataEdit[] = workingVenue.services.map(
      (vs) => {
        const service = event.services.find(
          (es) => es.id === vs.service.id && es.spaceId === null
        );
        return {
          ...vs,
          isSelected: service ? true : false,
          additionalNotes: service?.additionalNotes || "",
        };
      }
    );

    const eventOccurrencesSpaces: ApiSpaceSummary[] = [];
    eventDates.forEach((eventOccurrence) =>
      eventOccurrencesSpaces.push(...eventOccurrence.spaces)
    );
    const uniqueSpacesIds = Array.from(
      new Set(eventOccurrencesSpaces.map((s) => s.id))
    );

    const allSpaces = (
      await apiClient.findSpaces(currentAccount.id, {
        ids: uniqueSpacesIds,
      })
    ).data;

    const spacesServices: ApiSpaceService[] = [];
    const spacesHash = allSpaces.reduce(
      (hash, space) => {
        spacesServices.push(...space.services);
        hash[space.id] = space;
        return hash;
      },
      {} as Record<string, ApiSpace>
    );

    const allSpaceServices = spacesServices.map((ss) => {
      const service = event.services.find(
        (es) => es.id === ss.service.id && es.spaceId === ss.spaceId
      );

      return {
        ...ss,
        isRequired: ss.isRequired,
        isSelected: !!service,
        additionalNotes: service?.additionalNotes || "",
        spaceName: spacesHash[ss.spaceId].location.name || "",
      };
    });
    allServices.push(...allSpaceServices);

    setServices(allServices);
  }, [apiClient, currentAccount.id, event, eventDates, workingVenue]);

  useEffect(() => {
    setAllServices();
  }, [setAllServices]);

  useEffect(() => {
    if (!firstRender && rates && services.length > 0 && rates && rates.size) {
      setServicesTotalCost();
      setFirstRender(true);
    }
  }, [firstRender, rates, services, setServicesTotalCost]);

  const { venueServices, spacesServices } = useMemo(() => {
    const venueServices: EventServiceDataEdit[] = [];
    const spacesServices: EventServiceDataEdit[] = [];
    services.forEach((s) => {
      s.spaceId ? spacesServices.push(s) : venueServices.push(s);
    });
    return { venueServices, spacesServices };
  }, [services]);

  return (
    <>
      {event ? (
        <Stack gap={2} pl={4}>
          <Grid templateColumns="repeat(1, 1fr)" gap={3}>
            {venueServices.length > 0 &&
              venueServices.map((service, index) => (
                <GridItem key={`${service.service.id}:${index}`}>
                  <EventServiceItem
                    index={index}
                    service={service}
                    getItemRate={getItemRates}
                    eventHoursDuration={eventHoursDuration}
                    handleOnSelectService={handleOnSelectService}
                    handleOnChangeAdditionalNote={handleOnChangeAdditionalNote}
                    occurrences={eventDates}
                  />
                </GridItem>
              ))}
            {spacesServices.length > 0 && (
              <>
                <Heading fontSize="lg" fontWeight="semibold">
                  Spaces Services
                </Heading>
                {spacesServices.map((service, index) => (
                  <GridItem key={`${service.service.id}:${service.spaceId}`}>
                    <EventServiceItem
                      index={index}
                      service={service}
                      getItemRate={getItemRates}
                      eventHoursDuration={eventHoursDuration}
                      handleOnSelectService={handleOnSelectService}
                      handleOnChangeAdditionalNote={
                        handleOnChangeAdditionalNote
                      }
                      occurrences={eventDates}
                    />
                  </GridItem>
                ))}
              </>
            )}
          </Grid>

          <StackItem w="100%" display="flex" justifyContent="space-between">
            <Button
              size="sm"
              variant="outline"
              borderColor="blue.500"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              size="sm"
              onClick={handleOnSave}
              disabled={!hasChanges}
            >
              Save Changes
            </Button>
          </StackItem>
        </Stack>
      ) : (
        <Spinner />
      )}
    </>
  );
};
