import {
  ApiAccount,
  ApiCatalog,
  ApiCatalogGroupPolicy,
  ApiCatalogPolicy,
  ApiCatalogUserPolicy,
  ApiClient,
  UpdateApiCatalog,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface InitCatalogSettingsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  catalogId: string;
  search?: string;
}

export const initCatalogSettings = createAsyncThunk(
  "catalog-settings/init",
  async (params: InitCatalogSettingsThunkParams, thunkAPI) => {
    const { apiClient, account, catalogId } = params;
    const catalog = await apiClient.getCatalog(account.id, catalogId);

    return {
      catalog,
    };
  }
);

export const loadCatalogGroups = createAsyncThunk(
  "catalog-settings/load-groups",
  async (params: InitCatalogSettingsThunkParams, thunkAPI) => {
    const { apiClient, account, catalogId, search } = params;
    const state = thunkAPI.getState() as RootState;
    const pagedCatalogUsers = apiClient.findCatalogPolicies(
      account.id,
      catalogId,
      {
        pageSize: 20,
        type: "group",
        page: state.catalogSettings.catalogGroupsCurrentPage,
        search,
      }
    );
    return pagedCatalogUsers;
  }
);

export const loadCatalogUsers = createAsyncThunk(
  "catalog-settings/load-users",
  async (params: InitCatalogSettingsThunkParams, thunkAPI) => {
    const { apiClient, account, catalogId, search } = params;
    const state = thunkAPI.getState() as RootState;

    const pagedCatalogUsers = apiClient.findCatalogPolicies(
      account.id,
      catalogId,
      {
        pageSize: 20,
        type: "user",
        page: state.catalogSettings.catalogUsersCurrentPage,
        search,
      }
    );
    return pagedCatalogUsers;
  }
);

export interface UpdateCatalogThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  catalogId: string;
  patch: UpdateApiCatalog;
}

export const updateCatalog = createAsyncThunk(
  "catalog-settings/update-catalog",
  async (params: UpdateCatalogThunkParams, thunkAPI) => {
    const { apiClient, account, catalogId, patch } = params;

    return apiClient.updateCatalog(account.id, catalogId, patch);
  }
);

export interface CatalogSettingsSliceState {
  catalog: ApiCatalog | null;

  catalogUsers: ApiCatalogPolicy[];
  catalogUsersTotal: number;
  catalogUsersCurrentPage: number;

  catalogGroups: ApiCatalogPolicy[];
  catalogGroupsTotal: number;
  catalogGroupsCurrentPage: number;
  catalogPageSize: number;
  userSearch: string;
  groupSearch: string;
}

export const catalogSettingsSlice = createSlice({
  name: "catalog-settings",
  initialState: {
    catalog: null,
    catalogUsers: [],
    catalogUsersTotal: 0,
    catalogPageSize: 20,
    catalogUsersCurrentPage: 1,
    catalogGroups: [],
    catalogGroupsTotal: 0,
    catalogGroupsCurrentPage: 1,
    userSearch: "",
    groupSearch: "",
  } as CatalogSettingsSliceState,
  reducers: {
    setCatalogGroupsCurrentPage: (state, payload: PayloadAction<number>) => {
      state.catalogGroupsCurrentPage = payload.payload;
    },
    setCatalogUsersCurrentPage: (state, payload: PayloadAction<number>) => {
      state.catalogUsersCurrentPage = payload.payload;
    },
    addCatalogPolicy: (state, action: PayloadAction<ApiCatalogPolicy>) => {
      if (action.payload.type === "group") {
        const index = state.catalogGroups.findIndex(
          (workGr) =>
            (workGr as ApiCatalogGroupPolicy).group.id ===
            (action.payload as ApiCatalogGroupPolicy).group.id
        );
        if (index > -1) {
          state.catalogGroups.splice(index, 1);
          state.catalogGroups.unshift(action.payload);
          return;
        }
        state.catalogGroups.unshift(action.payload);

        state.catalogGroupsTotal += 1;
      } else {
        const index = state.catalogUsers.findIndex(
          (workUsr) =>
            (workUsr as ApiCatalogUserPolicy).user.id ===
            (action.payload as ApiCatalogUserPolicy).user.id
        );
        if (index > -1) {
          state.catalogUsers.splice(index, 1);
          state.catalogUsers.unshift(action.payload);
          return;
        }
        state.catalogUsers.unshift(action.payload);
        state.catalogUsersTotal += 1;
      }
    },

    updateCatalogPolicy: (state, action: PayloadAction<ApiCatalogPolicy>) => {
      const itemToUpdate =
        action.payload.type === "group"
          ? state.catalogGroups.find((x) => x.id === action.payload.id)
          : state.catalogUsers.find((x) => x.id === action.payload.id);

      if (!itemToUpdate) {
        return;
      }

      Object.assign(itemToUpdate, action.payload);
    },
    removeCatalogPolicy: (state, action: PayloadAction<ApiCatalogPolicy>) => {
      if (action.payload.type === "group") {
        const indexToRemove = state.catalogGroups.findIndex(
          (x) => x.id === action.payload.id
        );

        if (indexToRemove > -1) {
          state.catalogGroups.splice(indexToRemove, 1);
          state.catalogGroupsTotal -= 1;
        }
        return;
      }
      const indexToRemove = state.catalogUsers.findIndex(
        (x) => x.id === action.payload.id
      );

      if (indexToRemove > -1) {
        state.catalogUsers.splice(indexToRemove, 1);
        state.catalogUsersTotal -= 1;
      }
    },
    setUserSearch: (state, action: PayloadAction<string>) => {
      state.userSearch = action.payload;
    },
    setGroupSearch: (state, action: PayloadAction<string>) => {
      state.groupSearch = action.payload;
    },
    unload: (state) => {
      state.catalog = null;
      state.catalogUsers = [];
      state.catalogUsersTotal = 0;
      state.catalogPageSize = 20;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initCatalogSettings.fulfilled, (state, action) => {
      state.catalog = action.payload.catalog;
    });

    builder.addCase(loadCatalogGroups.fulfilled, (state, action) => {
      state.catalogGroups = action.payload.data;
      state.catalogGroupsTotal = action.payload.total;
    });

    builder.addCase(loadCatalogUsers.fulfilled, (state, action) => {
      state.catalogUsers = action.payload.data;
      state.catalogUsersTotal = action.payload.total;
    });

    builder.addCase(updateCatalog.fulfilled, (state, action) => {
      state.catalog = action.payload;
    });
  },
});

export const {
  unload,
  addCatalogPolicy,
  updateCatalogPolicy,
  removeCatalogPolicy,
  setCatalogGroupsCurrentPage,
  setCatalogUsersCurrentPage,
  setUserSearch,
  setGroupSearch,
} = catalogSettingsSlice.actions;

export default catalogSettingsSlice.reducer;
