import {
  Modal,
  ModalContent,
  ModalOverlay,
  UseModalProps,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiLaborTransaction,
  ApiReportingCategoryReference,
  ApiRequestStatus,
  ApiWorkflowReasonSummary,
} from "@operations-hero/lib-api-client";
import { ApiAssignee } from "@operations-hero/lib-api-client/dist/models/Assignees";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { clearBulkEditDataAsync } from "../../../store/request-form/request-bulk-actions.slice";
import { BulkEditForm } from "./BulkEditForm";
import { BulkEditFormSkeleton } from "./BulkEditFormSkeleton";
import { NonCompliantRequestsModal } from "./NonCompliantRequestsModal";
export interface BulkValues {
  status: ApiRequestStatus | null;
  assignTo: ApiAssignee[];
  category: ApiReportingCategoryReference | null;
  reason: ApiWorkflowReasonSummary | null;
  transaction: ApiLaborTransaction;
  comment: ApiComment;
  metadata: any;
}
interface BulkEditModalProps extends UseModalProps {}

export const BulkEditModal: FC<BulkEditModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const { loadingEditData } = useSelector(
    (state: RootState) => state.requestsBulkActionsSlice
  );

  useEffect(() => {
    dispatch(clearBulkEditDataAsync());
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        {loadingEditData === "pending" && <BulkEditFormSkeleton />}
        {loadingEditData === "succeeded" && (
          <>
            <BulkEditForm />
            <NonCompliantRequestsModal />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
