import { Text, TextProps } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const RequestDisplayCount = (props: TextProps) => {
  const totalRequests = useSelector(
    (state: RootState) => state.requestList.totalRequests
  );

  const requests = useSelector(
    (state: RootState) => state.requestList.requests
  );

  const currentPage = useSelector(
    (state: RootState) => state.requestList.filters.currentPage
  );
  const pageSize = useSelector(
    (state: RootState) => state.requestList.filters.pageSize
  );

  const pageStart = useMemo(() => {
    return (currentPage - 1) * pageSize;
  }, [currentPage, pageSize]);

  const showingTotal = useMemo(() => {
    return pageStart + requests.length;
  }, [pageStart, requests]);

  return totalRequests > 0 ? (
    <Text {...props}>
      Showing {pageStart + 1}-{showingTotal} of {totalRequests}
    </Text>
  ) : null;
};
