import * as yup from "yup";

export const getInventoryRequestFormSchema = (isBudgetRequired: boolean) => {
  const schema = yup.object().shape({
    requester: yup.object().nullable().required("Requester is required"),
    deliveryLocation: yup
      .object()
      .nullable()
      .required("Delivery Location is required"),
    requestId: yup.object().nullable().optional(),
    deliverTo: yup
      .array()
      .required("Deliver To is required")
      .min(1, "Deliver To is required"),
    deliverDate: yup.object().nullable().optional(),
    budget: isBudgetRequired
      ? yup
          .object()
          .nullable()
          .typeError("Budget is required")
          .required("Budget is required")
      : yup.object().nullable(),
  });

  return schema;
};
