import { Box, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { priorityColorMap } from "../../../components/badges/PriorityBadge";
import { RequestKeyBadge } from "../../../components/badges/RequestKeyBadge";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { RequestRowIcons } from "./RequestRowIcons";
import { UserColumn } from "./UserColumn";

export const InfoColumnMobile = ({
  request,
  typeColor,
}: {
  request: ApiRequest;
  typeColor: string;
}) => (
  <Box>
    <HStack
      bgColor={priorityColorMap[request.priority]}
      px={3}
      py={1}
      borderTopRadius="md"
      justifyContent="space-between"
      isTruncated
      mb={3}
      outline="solid 1px"
      outlineColor={priorityColorMap[request.priority]}
    >
      <RequestKeyBadge request={request} fontSize="lg" p={1} color="white" />
      <Box
        bgColor="white"
        borderRadius="md"
        px={2}
        py={0.5}
        justifyContent="center"
      >
        <Text
          color={priorityColorMap[request.priority]}
          fontSize={"sm"}
          fontWeight="bold"
          textAlign="center"
        >
          {request.priority.toUpperCase()}
        </Text>
      </Box>
    </HStack>
    <SimpleGrid columns={2} spacing={2} px="4">
      <Box>
        <StatusBadge status={request.status} />
      </Box>

      <Box textAlign={"right"}>
        <UserColumn assignees={request.assignees} />
      </Box>
      <Box>
        <Text color={typeColor} fontSize="14px" fontWeight="semibold">
          {request.type.toUpperCase()}
        </Text>
      </Box>
      <Box textAlign={"right"}>
        {request.reportingCategory ? (
          <Text fontSize="14px" fontWeight="semibold" isTruncated>
            {request.reportingCategory.name}
          </Text>
        ) : (
          <Text fontStyle={"italic"} color="gray.500">
            No category
          </Text>
        )}
      </Box>
      <Box>
        <UserBadge value={request.requester} />
      </Box>
      <Box>
        <RequestRowIcons request={request} />
      </Box>
    </SimpleGrid>
  </Box>
);
