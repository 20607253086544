import { Badge, Box, Text } from "@chakra-ui/react";
import {
  ApiWorkflowReason,
  ApiWorkflowReasonSummary,
  ApiWorkflowReference,
} from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createFilter } from "react-select";
import { RootState, useThunkDispatch } from "../../store";
import { loadWorkflowReasons } from "../../store/local-cache.slice";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  ReasonOptionsProp,
  ReasonSingleValueSelect,
} from "./select-overrides-types";

export interface ReasonAutocompleteProps {
  value: ApiWorkflowReasonSummary | null;
  workflow: ApiWorkflowReference;
  onChange: (reason: ApiWorkflowReasonSummary | null) => void;
  isDisabled?: boolean;
  name?: string;
  filterOptions?: string[];
  defaultValue?: string;
  isClearable?: boolean;
}
export const ReasonSelectBadge = ({
  value,
}: {
  value: ApiWorkflowReasonSummary;
}) => {
  const { reasonsMap } = useSelector((state: RootState) => state.localCache);
  return value ? (
    <Text
      as="span"
      display="inline-block"
      fontWeight="bold"
      fontSize="sm"
      pr={2}
      minWidth="80px"
    >
      {value.name}
      {!reasonsMap[value.id]?.active && (
        <Badge colorScheme="gray" ml={4} p={1}>
          Inactive
        </Badge>
      )}
    </Text>
  ) : null;
};

const CustomOptionComponent = createOptionComponent(ReasonSelectBadge);

const CustomSingleValueComponent = (props: ReasonSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <ReasonSelectBadge value={data} />
    </Box>
  );
};
export const ReasonAutocomplete = ({
  value,
  workflow,
  onChange,
  isDisabled,
  name,
  filterOptions,
  defaultValue,
  isClearable = true,
}: ReasonAutocompleteProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [options, setOptions] = useState<ApiWorkflowReason[]>([]);
  const [reason, setReason] = useState<ApiWorkflowReason | null>();

  const workflowId = useMemo(
    () => (typeof workflow === "string" ? workflow : workflow.id),
    [workflow],
  );

  const reasons = useSelector(
    (state: RootState) => state.localCache.workflowReasons[workflowId],
  );

  const thunkDispatch = useThunkDispatch();

  const filter = useMemo(
    () =>
      createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: "any",
        stringify: (option: any) => {
          return `${option.data.name}`;
        },
      }),
    [],
  );

  const handleChange = useCallback(
    (item: ApiWorkflowReasonSummary | null) => {
      const newValue = item ? item : null;
      if (newValue === value) {
        return;
      }
      onChange(item ? item : null);
    },
    [onChange, value],
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: ReasonOptionsProp) => CustomOptionComponent(props),
      SingleValue: (props: ReasonSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  useEffect(() => {
    thunkDispatch(
      loadWorkflowReasons({
        apiClient,
        currentAccount,
        workflowId,
      }),
    );
  }, [apiClient, currentAccount, thunkDispatch, workflowId]);

  useEffect(() => {
    if (!reasons) {
      return;
    }

    if (!filterOptions || filterOptions.length === 0) {
      setOptions(reasons.filter((reason) => reason.active));
      return;
    }

    const filteredReasons = reasons.filter((reason) =>
      filterOptions.some((item) => item === reason.name),
    );
    setOptions(filteredReasons);
  }, [apiClient, currentAccount, filterOptions, workflow, reasons]);

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find(
        (option) => option.name === defaultValue,
      );
      setReason(defaultOption);
    }
  }, [defaultValue, options]);

  useEffect(() => {
    if (!value) {
      setReason(null);
      return;
    }
    const updatedValue = options.find((option) => option.name === value?.name);
    setReason(updatedValue);
  }, [value, options]);
  return (
    <Select
      backspaceRemovesValue
      components={components}
      filterOption={filter}
      getOptionLabel={(item: ApiWorkflowReasonSummary) => item.name}
      getOptionValue={(item: ApiWorkflowReasonSummary) => item.id}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isSearchable
      name={name}
      onChange={handleChange}
      options={options}
      placeholder={isDisabled ? "N/A" : "Search Reasons..."}
      value={value || reason}
      chakraStyles={commonStyles}
    />
  );
};
