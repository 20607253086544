import { Box, Checkbox, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { ApiRequestType } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import Select, {
  SelectItemRenderer,
  SelectRenderer,
} from "react-dropdown-select";
import { MdClose } from "react-icons/md";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { FiltersWithCloseIcon } from "../RequestAdvancedFilters";

export interface TypeFilterProps {
  value: (ApiRequestType | string)[];
  onChange: (value: ApiRequestType[]) => void;
  addCloseIcon?: FiltersWithCloseIcon;
}

type ValueLabel = {
  value: ApiRequestType;
  label: ApiRequestType;
};

const DropdownHandleRenderer = () => {
  return <Box />;
};

const ContentRenderer = ({
  props,
  state,
  addCloseIcon,
}: SelectRenderer<ValueLabel> & { addCloseIcon?: FiltersWithCloseIcon }) => {
  const textColor = useColorModeValue("blue.500", "white");
  return (
    <Box p={1} w="max-content" display="flex" alignItems="center">
      {state.values.length === 0 && "Type"}
      {state.values.length === 1 && (
        <Text color={textColor} fontSize="14px" fontWeight="semibold">
          {state.values[0].value.toUpperCase()}
        </Text>
      )}
      {state.values.length > 1 && (
        <>
          <Text
            mr={1}
            as="span"
            color={textColor}
            fontSize="14px"
            fontWeight="semibold"
          >
            {state.values[0].value.toUpperCase()}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
      {addCloseIcon && (
        <Icon
          as={MdClose}
          ml={2}
          onClick={(e) => addCloseIcon.handleClickCloseIcon(e, "types")}
        />
      )}
    </Box>
  );
};

const ItemRenderer = ({
  item,
  itemIndex,
  props,
  state,
  methods,
}: SelectItemRenderer<ValueLabel>) => (
  <Checkbox
    key={item.value}
    isChecked={methods.isSelected(item)}
    onChange={() => methods.addItem(item)}
    w="100%"
    p={2}
  >
    <Text color="blue.500" fontSize="14px" fontWeight="semibold">
      {item.value.toUpperCase()}
    </Text>
  </Checkbox>
);

export const TypeFilter = ({
  value,
  onChange,
  addCloseIcon,
}: TypeFilterProps) => {
  const themeClass = useColorModeValue("light-theme", "dark-theme");
  const options = useMemo(
    () =>
      [
        ApiRequestType.corrective,
        ApiRequestType.predetermined,
        ApiRequestType.predictive,
        ApiRequestType.scheduled,
        ApiRequestType.triggered,
      ].map<ValueLabel>((x) => ({
        value: x,
        label: x,
      })),
    [],
  );

  const internalValues = useMemo(() => {
    return value && value.length > 0
      ? options.filter((x) => value.includes(x.value))
      : [];
  }, [value, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange],
  );

  return (
    <Select
      multi
      options={options}
      className={themeClass}
      values={internalValues}
      onChange={handleChange}
      searchable={true}
      searchBy="value"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      //clearable
      contentRenderer={(props) => ContentRenderer({ ...props, addCloseIcon })}
      dropdownHandleRenderer={addCloseIcon ? DropdownHandleRenderer : undefined}
      dropdownHeight="300"
      itemRenderer={ItemRenderer}
    />
  );
};
