import { ApiComment } from "@operations-hero/lib-api-client";
import { ContentState, EditorState } from "draft-js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useConvertMentions } from "../../hooks/useConvertMentions";
import { convertToSave } from "../../pages/request-form/comments/Comment";
import { useAuthentication } from "../auth/AuthProvider";
import { RichTextEditorComments } from "../form-helpers/rich-text-editor/RichTextEditorComments";

type CommentFormProps = {
  comment?: ApiComment;
  onCommentChange: (value: string) => void;
};

type WorkingComment = Pick<
  ApiComment,
  "comment" | "createdBy" | "isPublic" | "mentioned"
> & {
  created?: string;
};
export const CommentForm: FC<CommentFormProps> = ({
  comment,
  onCommentChange,
}) => {
  const { currentUser } = useAuthentication();

  const newComment: WorkingComment = useMemo(
    () => ({
      comment: "",
      createdBy: currentUser,
      isPublic: true,
      mentioned: [],
    }),
    [currentUser]
  );

  const [workingComment, setWorkingComment] = useState<
    WorkingComment | undefined
  >(comment ?? newComment);

  const { editorState: commentBodyState } = useConvertMentions({
    value: workingComment?.comment || "",
    mentioned: workingComment?.mentioned || [],
  });

  const [commentState, setCommentState] = useState<EditorState | undefined>(
    commentBodyState
  );

  const handleBodyStateChange = useCallback(
    (value: EditorState) => {
      if (!workingComment) return;
      setCommentState(value);
      setWorkingComment({
        ...workingComment,
        comment: convertToSave(value) ?? "",
      });
      onCommentChange(convertToSave(value) ?? "");
    },
    [workingComment, onCommentChange]
  );

  useEffect(() => {
    // clearing the editor box
    if (comment === undefined) {
      setWorkingComment(newComment);
      setCommentState(
        EditorState.createWithContent(ContentState.createFromText(""))
      );
    }
  }, [comment, newComment]);

  return (
    <RichTextEditorComments
      value={commentState || undefined}
      id="comment"
      setParentState={handleBodyStateChange}
    />
  );
};
