interface ObjectParams {
  id: string;
  [key: string]: any;
}

export function getAllUniqueChildObjects<T extends ObjectParams>(
  parentArray: T[],
  childName: string
) {
  const allChildObjects: T[] = [];

  // Extract all child objects
  parentArray.forEach((parent) => {
    allChildObjects.push(...parent[childName]);
  });

  // Create a map to track unique child objects by their id
  const uniqueChildObjectsMap = new Map();

  allChildObjects.forEach((child) => {
    if (!uniqueChildObjectsMap.has(child.id)) {
      uniqueChildObjectsMap.set(child.id, child);
    }
  });

  // Convert the map values back to an array
  return Array.from(uniqueChildObjectsMap.values());
}
