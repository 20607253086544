import {
  Box,
  Button,
  Checkbox,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { SparkleWithAnimation } from "../../../../components/icons/Sparkle";
import { updateUserSettingToLocalCache } from "../../../../store/local-cache.slice";
import { HIDE_AI_ASSET_MODAL } from "../../../../utils/emailSettingUtils";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../store";
interface AIAssetInstructionsProps {
  setStage: (stage: string) => void;
}

const AiCreateAssetIntro: React.FC<AIAssetInstructionsProps> = ({
  setStage,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { assetId } = useParams<{ assetId?: string }>();
  const { apiAsset } = useSelector((state: RootState) => state.aiAssetSlice);

  const [hideModal, setHideModal] = useState(false);
  const dispatch = useDispatch();
  const updateSetting = useCallback(async () => {
    const setting = { [HIDE_AI_ASSET_MODAL]: hideModal };
    apiClient.updateCurrentUserSettings(currentAccount.id, setting).then(() => {
      dispatch(
        updateUserSettingToLocalCache({
          key: HIDE_AI_ASSET_MODAL,
          value: hideModal,
        })
      );
    });
  }, [apiClient, currentAccount.id, hideModal, dispatch]);

  const continueToNextStage = () => {
    setStage("initial");
    updateSetting();
  };

  return (
    <VStack width={"100%"} gap={4} pb={4}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <SparkleWithAnimation boxSize="6em" />
      </Box>
      <Box pb={4}>
        <Text fontSize="2xl" textAlign="center" fontWeight="bold">
          {assetId || apiAsset ? "Edit" : "Create"} an Asset with the assistance
          of AI.
        </Text>
      </Box>
      <Box width="100%">
        <Text fontWeight="bold">Instructions:</Text>
        <UnorderedList mb={2}>
          <ListItem>Take/upload a picture of an asset nameplate</ListItem>
          <ListItem>Verify AI generated data from the image</ListItem>
          <ListItem>Take additional photos of the asset</ListItem>
          <ListItem>Optionally add more asset lifecycle data</ListItem>
        </UnorderedList>
      </Box>
      <Box width="100%">
        <Text fontWeight="bold">
          Tips for taking pictures of asset nameplates:
        </Text>
        <UnorderedList mb={2} width="100%">
          <ListItem>
            <Text>Good lighting, avoid reflections, glare and shadows.</Text>
          </ListItem>
          <ListItem>
            <Text>High resolution, use a smartphone or camera</Text>
          </ListItem>
          <ListItem>
            <Text>Straight text, try be close to level with the device</Text>
          </ListItem>
          <ListItem>
            <Text>Reduce blur, try stabilizing the device.</Text>
          </ListItem>
        </UnorderedList>
      </Box>
      <Box>
        <Checkbox size="lg" onChange={() => setHideModal(!hideModal)}>
          <Text fontWeight="bold" fontSize="md">
            Don't show this again
          </Text>
        </Checkbox>
      </Box>
      <Button
        width="100%"
        colorScheme="blue"
        mt={4}
        onClick={continueToNextStage}
      >
        Continue
      </Button>
    </VStack>
  );
};

export default AiCreateAssetIntro;
