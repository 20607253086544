import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QRLookupSliceState {
  qrLookupModalIsOpen: boolean;
}

export const qrLookupSlice = createSlice({
  name: "qrLookup",
  initialState: {
    qrLookupModalIsOpen: false,
  } as QRLookupSliceState,
  reducers: {
    setQRLookupModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.qrLookupModalIsOpen = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setQRLookupModalIsOpen } = qrLookupSlice.actions;

export default qrLookupSlice.reducer;
