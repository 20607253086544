import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  StackDirection,
  Text,
} from "@chakra-ui/react";
import { ApiDiscountType, PurchaseType } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";

interface RadioOption {
  label?: string;
  schemeColor: string;
  value: string | number;
}

// This is a tuple for clean several formik field values if is needed
export type CleanValues = [string, number | string | null | never[]];

export interface RadioButtonsControlProps {
  label?: string;
  name: string;
  value: string | number;
  helperText?: string;
  radioOptions: RadioOption[];
  setLocalState?: (value: PurchaseType | ApiDiscountType) => void;
  cleanValues?: CleanValues[];
  direction?: StackDirection;
}

export const RadioButtonsControl: React.FC<RadioButtonsControlProps> = ({
  label,
  name,
  value,
  helperText,
  radioOptions,
  setLocalState,
  cleanValues,
  direction,
}) => {
  const { submitCount, setFieldValue } = useFormikContext();
  const [field, meta] = useField({
    name,
    value,
  });

  const handleOnchange = useCallback(
    (value: PurchaseType | ApiDiscountType) => {
      if (setLocalState && value) {
        setLocalState(value);
      }
      cleanValues &&
        cleanValues.forEach((item) => {
          const [fieldName, fieldValue] = item;
          setFieldValue(fieldName, fieldValue, false);
        });
    },
    [cleanValues, setFieldValue, setLocalState],
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && (
        <FormLabel id={name} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        {...field}
        name={name}
        value={value ? `${value}` : undefined}
        onChange={handleOnchange}
      >
        <Stack direction={direction || "row"} gap={4}>
          {radioOptions.map((option) => (
            <Radio
              {...field}
              size="md"
              name={name}
              cursor="pointer"
              key={option?.label || option.value}
              value={option.value ? `${option.value}` : undefined}
              colorScheme={option.schemeColor}
            >
              <Text fontWeight="400" fontSize="lg" cursor="pointer">
                {option.label}
              </Text>
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
