import {
  Box,
  BoxProps,
  Icon,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";
import { IoMdCalendar, IoMdStopwatch } from "react-icons/io";
import {
  MdBusiness,
  MdConstruction,
  MdGroups,
  MdLocationOn,
  MdOutlineQuestionMark,
} from "react-icons/md";
import { RiAlertFill, RiGroup2Fill } from "react-icons/ri";

type IconType =
  | "alert"
  | "calendar"
  | "group"
  | "watch"
  | "users"
  | "build"
  | "equipment"
  | "location"
  | "questions";

const Icons = {
  users: MdGroups,
  build: MdBusiness,
  alert: RiAlertFill,
  group: RiGroup2Fill,
  watch: IoMdStopwatch,
  calendar: IoMdCalendar,
  equipment: MdConstruction,
  location: MdLocationOn,
  questions: MdOutlineQuestionMark,
};

interface CustomIconProps {
  type: IconType;
  boxProps?: BoxProps;
  iconProps?: IconProps;
}

export const BigCircleIcon: FC<CustomIconProps> = ({
  type,
  boxProps,
  iconProps,
}) => {
  const defaultBoxColor = useColorModeValue("blue.50", "gray.700");
  const defaultIconColor = useColorModeValue("blue.500", "white");

  return (
    <Box
      h="50px"
      minW="50px"
      display="flex"
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
      bgColor={defaultBoxColor}
      {...boxProps}
    >
      <Icon
        as={Icons[type]}
        boxSize="24px"
        color={defaultIconColor}
        {...iconProps}
      />
    </Box>
  );
};
