import { ApiClient, ApiProject } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { LoadingStatus } from "./project-list";

type ProjectFormSliceProps = {
  workingProject: ApiProject | null;
  updateLoadingStatus: LoadingStatus;
};
type UpdateProjectParams = {
  id: string;
  apiClient: ApiClient;
  delta: Partial<ApiProject>;
};

export const updateProject = createAsyncThunk<ApiProject, UpdateProjectParams>(
  "projects/update",
  async ({ id, apiClient, delta }, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const { currentAccount } = rootState.auth;
    const updatedProject = await apiClient.updateProject(
      currentAccount.id,
      id,
      delta
    );

    return updatedProject;
  }
);

const initialState: ProjectFormSliceProps = {
  workingProject: null,
  updateLoadingStatus: "idle",
};

const projectFormSlice = createSlice({
  name: "project/details",
  initialState,
  reducers: {
    setUpdateStatus: (
      state,
      action: PayloadAction<ProjectFormSliceProps["updateLoadingStatus"]>
    ) => {
      state.updateLoadingStatus = action.payload;
    },
    setWorkingProject: (
      state,
      action: PayloadAction<ProjectFormSliceProps["workingProject"]>
    ) => {
      state.workingProject = action.payload;
    },
    unload: (state) => {
      state.workingProject = null;
      state.updateLoadingStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProject.pending, (state) => {
      state.updateLoadingStatus = "pending";
    });

    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.updateLoadingStatus = "fulfilled";
      state.workingProject = action.payload;
    });
  },
});

export const { unload, setWorkingProject, setUpdateStatus } =
  projectFormSlice.actions;
export default projectFormSlice.reducer;
