import { CheckCircleIcon } from "@chakra-ui/icons";
import { Button, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";

type StepFinalSubmitButtonProps = {
  onClick: () => void;
};

export const StepFinal = () => {
  return (
    <VStack gap="10" justify="stretch" mb="8" spacing="unset ">
      <Text alignSelf="start">
        Your phone number was successfully verified!
      </Text>
      <CheckCircleIcon w="16" h="16" color="teal.400" />
    </VStack>
  );
};

export const StepFinalSubmitButton: FC<StepFinalSubmitButtonProps> = ({
  onClick,
}) => {
  return (
    <Button onClick={onClick} colorScheme="blue">
      Close
    </Button>
  );
};
