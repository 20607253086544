import { Grid, GridItem } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { AccountModal } from "../../../pages/account-settings/account-modal/AccountModal";
import { Attachment } from "../../attachments/Attachments";
import { ImageViewerData } from "./ImageViewerData";
import { ZoomableImage } from "./ZoomableImage";

interface ImageViewerProps {
  isOpen: boolean;
  onClose: () => void;
  attachment: Attachment;
  showOnlyImageViewer?: boolean;
  handleOnSaveAttachment?: (attachment: Attachment) => void;
}

export const ImageViewer: FC<ImageViewerProps> = ({
  isOpen,
  onClose,
  attachment,
  showOnlyImageViewer,
  handleOnSaveAttachment,
}) => {
  const zoomableImageColSpan = useMemo(() => {
    if (showOnlyImageViewer) return 12;
    return [12, 12, 8, 9];
  }, [showOnlyImageViewer]);

  return (
    <>
      {isOpen && (
        <AccountModal
          header={false}
          isOpen={isOpen}
          onClose={onClose}
          bodyProps={{ p: 0 }}
          contentProps={{ maxW: "8xl" }}
          content={
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mt={[8, 8, 0]}>
              {!showOnlyImageViewer && (
                <GridItem
                  p={2}
                  display="flex"
                  bgColor="gray.50"
                  colSpan={[12, 12, 4, 3]}
                >
                  <ImageViewerData
                    attachment={attachment}
                    handleOnSave={handleOnSaveAttachment}
                  />
                </GridItem>
              )}

              <GridItem colSpan={zoomableImageColSpan} px={4} pt={12} pb={4}>
                <ZoomableImage attachment={attachment} />
              </GridItem>
            </Grid>
          }
        />
      )}
    </>
  );
};
