import { Text, TextProps } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { RelativeDateOptions } from "../../../store/transaction-list.slice";
import { DateFilter } from "../Requests";

export const RequestDateSelectionText = (props: TextProps) => {
  const { filters } = useSelector((state: RootState) => state.requestList);

  const dateRangeSelection = useMemo(() => {
    if (filters.date === null) return "No Date Filters Applied";
    const field = DateFilter[filters.date.field];
    let value = "";
    if (filters.date.type === "relative") {
      value = DateFilter[filters.date.value as RelativeDateOptions];
    } else {
      if (filters.date.isFiscalYear) {
        value = `${
          filters.date.fiscalYear && DateFilter[filters.date.fiscalYear]
        } from ${filters.date.value[0]} to ${filters.date.value[1]}`;
      } else
        value = `Custom from ${filters.date.value[0]} to ${filters.date.value[1]}`;
    }
    const res = `${field}:  ${value}`;
    return res;
  }, [filters.date]);

  return (
    <Text size="sm" {...props}>
      {dateRangeSelection}
    </Text>
  );
};
