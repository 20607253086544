import { Box } from "@chakra-ui/react";
import { Select, SingleValue } from "chakra-react-select";
import { FC, useMemo } from "react";
import { getCustomSelectComponents } from "../../selects/select-overrides";
import { CronBoxOption, RECURRENCE_OPTIONS } from "../cron-helpers/CronOptions";

interface RecurrenceSelectProps {
  onRecurrenceChange: (recurrence: SingleValue<CronBoxOption>) => void;
  value: CronBoxOption | null;
}

export const RecurrenceSelect: FC<RecurrenceSelectProps> = ({
  value,
  onRecurrenceChange,
}) => {
  const components = useMemo(getCustomSelectComponents, []);

  return (
    <Box minW="150px">
      <Select
        options={RECURRENCE_OPTIONS}
        value={value}
        onChange={onRecurrenceChange}
        components={components}
      />
    </Box>
  );
};
