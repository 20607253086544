import {
  ApiClient,
  ApiRequest,
  ApiRequestStatus,
  ApiWorkflow,
  ApiWorkflowField,
  ApiWorkflowSchemaField,
  ApiWorkflowSummary,
  BulkUpdateApiRequest,
  CreateApiComment,
  CreateApiLaborTransaction,
  TransactionTotals,
  UpdateApiRequest,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { LoadingStatus } from "../../types/redux-slices-types";
import {
  loadRequests,
  unloadRequestList,
  updateRequestFilters,
} from "../request-list.slice";
import {
  generateAllowedStatusList,
  generateSingleRequestPayload,
} from "./request-bulk-action.helpers";

export interface RequestBulkState {
  request: ApiRequest;
  selected: boolean;
  canBeSelected: boolean;
}

export interface NonCompliantRequest {
  isDuplicated: boolean;
  key: string;
  isMarkedForDeletion: boolean;
}
export interface LoadWorkflowByIdParams {
  apiClient: ApiClient;
  accountId: string;
  workflowRef: ApiWorkflowSummary;
  firstSelectedItemIndex: number;
}

export interface UpdateRequestsBulkParams {
  apiClient: ApiClient;
  accountId: string;
}

export enum FieldsToUpdateKeys {
  "metadata" = "Other fields",
  "reportingCategory" = "Category",
  "assignees" = "Assignee",
  "reason" = "Reason",
  "status" = "Status",
}

type UpdateStatus =
  | "success"
  | "in-progress"
  | "idle"
  | "failed"
  | "successWithErrors";

type ValidationStatus =
  | "success"
  | "in-progress"
  | "idle"
  | "canceled"
  | "started"
  | "failed";

export type SelectionMode = "normal" | "bulk";

type BulkEditStep = "edit-form" | "change-selection" | "none";

export const loadBulkModeDataAsync = createAsyncThunk(
  "bulk-actions/loadInitialBulkDataAsync",
  async (params: LoadWorkflowByIdParams, thunkAPI) => {
    const { workflowRef, firstSelectedItemIndex } = params;
    const store = thunkAPI.getState() as RootState;
    const { requests } = store.requestsBulkActionsSlice;
    let totalAllowedForSelection = 0;

    const requestsWithSelectStatusUpdated = requests.map((bulkItem) => {
      if (bulkItem.request.workflow.id === workflowRef.id) {
        totalAllowedForSelection++;
        return { ...bulkItem, canBeSelected: true };
      } else {
        return { ...bulkItem, canBeSelected: false };
      }
    });
    requestsWithSelectStatusUpdated[firstSelectedItemIndex] = {
      ...requestsWithSelectStatusUpdated[firstSelectedItemIndex],
      canBeSelected: true,
      selected: true,
    };

    return {
      workflow: workflowRef,
      bulkRequests: requestsWithSelectStatusUpdated,
      totalAllowedForSelection,
    };
  }
);

export const loadRequestsTotalsAsync = createAsyncThunk(
  "bulk-actions/loadRequestsTotalsAsync",
  async (params: { apiClient: ApiClient; accountId: string }, thunkAPI) => {
    const { apiClient, accountId } = params;
    const store = thunkAPI.getState() as RootState;

    const { requests } = store.requestsBulkActionsSlice;

    const requestsIds = requests.map(
      (requestWithStatus) => requestWithStatus.request.id
    );
    const transactionsSummary =
      await apiClient.findRequestTransactionTotalCostHours(accountId, {
        ids: requestsIds,
      });

    return {
      transactionsSummary,
    };
  }
);

export const loadBulkEditDataAsync = createAsyncThunk(
  "bulk-actions/loadBulkEditDataAsync",
  async (params: { apiClient: ApiClient; accountId: string }, thunkAPI) => {
    const { apiClient, accountId } = params;
    const state = thunkAPI.getState() as RootState;
    const { rejectWithValue } = thunkAPI;
    const { isProductAdmin } = state.auth;
    try {
      const { workflowRef } = state.requestsBulkActionsSlice;
      if (!workflowRef) throw new Error("Workflow is not defined yet");

      const workflow = await apiClient.getWorkflow(accountId, workflowRef.id);

      const context = await apiClient.getCurrentUserWorkflowContext(
        accountId,
        workflow.id
      );

      const schemaFields = await apiClient.findWorkflowSchemaFields(
        accountId,
        workflow.schema.id,
        {
          pageSize: 50,
        }
      );

      const requestStatusList = generateAllowedStatusList(
        workflow,
        context.policy,
        isProductAdmin
      );

      return {
        policy: context.policy,
        schemaFields: schemaFields.data,
        statuses: requestStatusList,
        workflow: workflow,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const runSchemaValidationAsync = createAsyncThunk(
  "bulk-actions/runValidationAsync",
  async (params: { status: ApiRequestStatus }, { getState }) => {
    const { status } = params;
    const store = getState() as RootState;
    const { currentAccount, currentUser, isProductAdmin } = store.auth;
    const { requests, workflow, policy, schemaFields } =
      store.requestsBulkActionsSlice;
    const selectedRequests = requests
      .filter((bulkItem) => bulkItem.selected === true)
      .map((bulkItem) => bulkItem.request);

    if (!workflow || !policy)
      throw new Error("Workflow or policy not defined yet");

    const schemaEngine = new SchemaRulesEngine({
      account: currentAccount,
      form: "full",
      schemaFields: schemaFields,
      user: currentUser,
      workflow: workflow,
      policy: policy,
      isProductAdmin,
    });
    const nonCompliantGroupByRequest =
      await schemaEngine.getMissingFieldsByRequest(selectedRequests, {
        status,
      });

    const nonCompliantGroupedByField = Array.from(
      nonCompliantGroupByRequest.entries()
    ).reduce((map, [requestKey, fields]) => {
      fields.forEach((field) => {
        map.set(
          field,
          (map.get(field) || []).concat({
            key: requestKey,
            isMarkedForDeletion: false,
            isDuplicated: false,
          })
        );
      });
      return map;
    }, new Map<ApiWorkflowField, NonCompliantRequest[]>());
    const checkIfKeyAlreadyExists = (requestKey: string) => {
      const allValues = Array.from(nonCompliantGroupedByField.values()).flat();
      const count = allValues.filter(
        (value) => value.key === requestKey
      ).length;
      return count > 1;
    };

    nonCompliantGroupedByField.forEach((requests, fieldKey) => {
      const updatedRequests = requests.map((requestToUpdate) => {
        const foundRequest = checkIfKeyAlreadyExists(requestToUpdate.key);
        return foundRequest
          ? { ...requestToUpdate, isDuplicated: true }
          : requestToUpdate;
      });
      nonCompliantGroupedByField.set(fieldKey, updatedRequests);
    });

    return {
      nonCompliantGroupByRequest: Object.fromEntries(
        nonCompliantGroupByRequest
      ),
      nonCompliantGroupByField: Array.from(
        nonCompliantGroupedByField.entries()
      ),
    };
  }
);

type RequestsBulkUpdateResponse = {
  updatedFields: string[];
  totalUpdated: number;
  totalWithErrors: number;
  successType: "success" | "successWithErrors" | "failed";
};
export const updateRequestsBulkAsync = createAsyncThunk(
  "bullk-actions/update",
  async (params: UpdateRequestsBulkParams, thunkAPI) => {
    const { apiClient, accountId } = params;
    const state = thunkAPI.getState() as RootState;
    const { rejectWithValue } = thunkAPI;
    const { fieldsToUpdate, requests } = state.requestsBulkActionsSlice;
    const { transitionMap } = state.requestList;
    const requestsToUpdate = requests.filter((item) => item.selected === true);

    try {
      if (!fieldsToUpdate)
        return {
          updatedFields: [],
          totalUpdated: 0,
          totalWithErrors: 0,
          successType: "failed" as UpdateStatus,
        };
      const nonCompliantRequests: Array<ApiRequest> = [];
      const requestsAsPayload = requestsToUpdate.reduce<
        Array<BulkUpdateApiRequest>
      >((list, item) => {
        const { allowedStatuses } = transitionMap[item.request.id];
        //nothing to do here, the verification for other fields will be handle through the API request
        if (!fieldsToUpdate.status) {
          list.push(generateSingleRequestPayload(item, fieldsToUpdate));
          return list;
        }

        // the status field needs to be updated but
        // the request does not have any available status in its transition
        if (allowedStatuses.length === 0) {
          nonCompliantRequests.push(item.request);
          return list;
        }

        // here we verify if the status to update to is allowed
        if (!allowedStatuses.includes(fieldsToUpdate.status)) {
          nonCompliantRequests.push(item.request);
          return list;
        }

        // in any other case we generate the request payload
        list.push(generateSingleRequestPayload(item, fieldsToUpdate));
        return list;
      }, []);

      const updatedFields = Object.keys(fieldsToUpdate).reduce(
        (array, key: string) => {
          array.push(
            FieldsToUpdateKeys[key as keyof typeof FieldsToUpdateKeys]
          );
          return array;
        },
        new Array<string>()
      );

      const response: RequestsBulkUpdateResponse = {
        updatedFields: updatedFields,
        totalUpdated: 0,
        totalWithErrors: 0,
        successType: "success",
      };

      if (requestsAsPayload.length > 0) {
        const result = await apiClient.bulkUpdateRequest(
          accountId,
          requestsAsPayload
        );
        response.successType = result.error ? "successWithErrors" : "success";
        response.totalWithErrors = result.error
          ? result.errors?.length ?? 0
          : 0;
        response.totalUpdated = result.data.length;
      }

      if (nonCompliantRequests.length > 0) {
        response.successType = "successWithErrors";
        response.totalWithErrors =
          response.totalWithErrors + nonCompliantRequests.length;
      }

      return response;
    } catch (error) {
      return rejectWithValue("Something went wrong updating the requests");
    }
  }
);

export interface FieldsToUpdate extends Partial<UpdateApiRequest> {
  transaction?: CreateApiLaborTransaction;
  comment?: CreateApiComment;
}

export interface RequestsBulkActionsState {
  workflowRef: ApiWorkflowSummary | null;
  workflow: ApiWorkflow | null;
  schemaFields: ApiWorkflowSchemaField[];
  policy: WorkflowPolicy | null;
  requests: RequestBulkState[];
  totalSelected: number;
  nonCompliantRequests: Record<string, ApiWorkflowField[]>;
  nonCompliantRequestsByField: Array<
    [key: ApiWorkflowField, value: NonCompliantRequest[]]
  >;
  transactionsSummary: Record<string, TransactionTotals>;
  isTotalDataLoading: boolean;
  fieldsToUpdate?: FieldsToUpdate;
  enabledStatusList: {
    canCancel: boolean;
    statuses: ApiRequestStatus[];
  } | null;
  selectionChanged: boolean;
  loadingEditData: LoadingStatus;
  successfulUpdateMessage: string;
  partialUpdateMessage: string;
  failedUpdateMessage: string;
  totalAllowedForSelection: number;
  updateStatus: UpdateStatus;
  validationStatus: ValidationStatus;
  selectMode: SelectionMode;
  bulkEditStep: BulkEditStep;
}

export const requestsBulkActionsSlice = createSlice({
  name: "bulk-actions",
  initialState: {
    workflow: null,
    workflowRef: null,
    schemaFields: [],
    policy: null,
    requests: [],
    totalSelected: 0,
    nonCompliantRequests: {},
    nonCompliantRequestsByField: [],
    updateStatus: "idle",
    enabledStatusList: null,
    transactionsSummary: {},
    isTotalDataLoading: false,
    validationStatus: "idle",
    selectMode: "normal",
    selectionChanged: false,
    bulkEditStep: "none",
    loadingEditData: "idle",
    successfulUpdateMessage: "",
    partialUpdateMessage: "",
    failedUpdateMessage: "",
    totalAllowedForSelection: 0,
  } as RequestsBulkActionsState,
  reducers: {
    setValidationStatus: (state, action: PayloadAction<ValidationStatus>) => {
      state.validationStatus = action.payload;
    },
    setBulkEditStep: (state, action: PayloadAction<BulkEditStep>) => {
      state.bulkEditStep = action.payload;
    },
    setSelectMode: (state, action: PayloadAction<SelectionMode>) => {
      state.selectMode = action.payload;
    },
    setBulkRequests: (
      state: RequestsBulkActionsState,
      action: PayloadAction<RequestBulkState[]>
    ) => {
      state.requests = action.payload;
      const totalSelected = action.payload.filter(
        (bulkItem) => bulkItem.selected === true
      ).length;
      if (totalSelected === 0) state.selectMode = "normal";

      state.totalSelected = totalSelected;
    },
    setTotalSelected: (
      state: RequestsBulkActionsState,
      action: PayloadAction<number>
    ) => {
      state.totalSelected = action.payload;
    },

    setFieldsToUpdate: (
      state: RequestsBulkActionsState,
      action: PayloadAction<Partial<UpdateApiRequest>>
    ) => {
      state.fieldsToUpdate = action.payload;
      state.updateStatus = "in-progress";
    },

    setUpdateStatus: (
      state: RequestsBulkActionsState,
      action: PayloadAction<UpdateStatus>
    ) => {
      state.updateStatus = action.payload;
    },

    markRequestsForDeletion: (
      state: RequestsBulkActionsState,
      action: PayloadAction<{ requestToMarkForDeletion: NonCompliantRequest }>
    ) => {
      const { nonCompliantRequestsByField } = state;
      const { requestToMarkForDeletion } = action.payload;
      const nonCompliantRequestMap = new Map(nonCompliantRequestsByField);
      nonCompliantRequestMap.forEach((value, key) => {
        const requests = [...value];
        const requestToUpdateIndex = requests.findIndex(
          (request) => request.key === requestToMarkForDeletion.key
        );
        if (requestToUpdateIndex !== -1)
          requests[requestToUpdateIndex] = {
            ...requestToMarkForDeletion,
            isMarkedForDeletion: true,
          };

        nonCompliantRequestMap.set(key, requests);
      });
      state.nonCompliantRequestsByField = Array.from(
        nonCompliantRequestMap.entries()
      );
      state.selectionChanged = true;
    },

    updateBulkSelection: (state: RequestsBulkActionsState) => {
      const { nonCompliantRequestsByField, requests } = state;
      const allRequests = nonCompliantRequestsByField.flatMap(
        (reportByField) => reportByField[1]
      );

      const uniqueValues = [
        ...Array.from(
          new Map<string, NonCompliantRequest>(
            allRequests.map((request) => [request.key, request])
          ).values()
        ),
      ];

      const requestListUpdated = [...requests];
      uniqueValues.forEach((nonCompliantRequest) => {
        const requestToUpdateIndex = requests.findIndex(
          (item) =>
            item.request.key === nonCompliantRequest.key &&
            nonCompliantRequest.isMarkedForDeletion
        );
        requestListUpdated[requestToUpdateIndex] = {
          ...requestListUpdated[requestToUpdateIndex],
          selected: false,
        };
      });
      const totalSelected = requestListUpdated.filter(
        (bulkItem) => bulkItem.selected === true
      ).length;
      state.requests = [...requestListUpdated];
      state.selectionChanged = false;
      if (totalSelected === 0) {
        state.validationStatus = "idle";
        state.totalSelected = 0;
        state.bulkEditStep = "none";
        state.selectMode = "normal";
      } else {
        state.validationStatus = "started";
        state.totalSelected = totalSelected;
        state.bulkEditStep = "edit-form";
      }
    },

    clearBulkEditDataAsync: (state) => {
      state.nonCompliantRequests = {};
      state.nonCompliantRequestsByField = [];
      state.validationStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateRequestsBulkAsync.fulfilled, (state, action) => {
      state.updateStatus = "success";
      state.totalSelected = 0;
      state.policy = null;
      state.schemaFields = [];
      state.enabledStatusList = null;
      state.workflow = null;
      state.nonCompliantRequests = {};
      state.nonCompliantRequestsByField = [];
      state.validationStatus = "idle";

      state.successfulUpdateMessage = `${
        action.payload.updatedFields.toString() || ""
      } changed for ${action.payload.totalUpdated} requests`;

      state.partialUpdateMessage = `${action.payload?.totalWithErrors} requests could not be updated`;
      state.updateStatus = action.payload.successType;
    });
    builder.addCase(
      updateRequestsBulkAsync.rejected,
      (state: RequestsBulkActionsState, action) => {
        state.updateStatus = "failed";
        state.failedUpdateMessage =
          "Something went wrong updating the requests";
      }
    );
    /**Setting up states when the requestList slice has completed loading successfully */
    builder.addCase(loadRequests.fulfilled, (state, action) => {
      const requestsWithStates: Array<{
        request: ApiRequest;
        selected: boolean;
        canBeSelected: boolean;
        bulkAvailable: boolean;
      }> = action.payload.requests.data.map((request) => {
        return {
          request,
          selected: false,
          canBeSelected: true,
          bulkAvailable: true,
        };
      });
      state.requests = requestsWithStates;
      state.updateStatus = "idle";
      state.totalSelected = 0;
      state.selectMode = "normal";
      state.bulkEditStep = "none";
      state.successfulUpdateMessage = "";
    });

    builder.addCase(loadBulkModeDataAsync.fulfilled, (state, action) => {
      state.workflowRef = action.payload.workflow;
      state.requests = action.payload.bulkRequests;
      state.totalSelected = 1;
      state.selectMode = "bulk";
      state.totalAllowedForSelection = action.payload.totalAllowedForSelection;
    });

    builder.addCase(loadRequestsTotalsAsync.pending, (state, action) => {
      state.isTotalDataLoading = true;
    });

    builder.addCase(loadRequestsTotalsAsync.fulfilled, (state, action) => {
      state.isTotalDataLoading = false;
      state.transactionsSummary = action.payload.transactionsSummary;
    });

    builder.addCase(loadBulkEditDataAsync.fulfilled, (state, { payload }) => {
      state.policy = payload.policy;
      state.schemaFields = payload.schemaFields;
      state.enabledStatusList = payload.statuses;
      state.workflow = payload.workflow;
      state.bulkEditStep = "edit-form";
      state.loadingEditData = "succeeded";
    });
    builder.addCase(loadBulkEditDataAsync.pending, (state) => {
      state.loadingEditData = "pending";
    });
    builder.addCase(loadBulkEditDataAsync.rejected, (state) => {
      state.loadingEditData = "failed";
    });
    builder.addCase(runSchemaValidationAsync.pending, (state) => {
      state.validationStatus = "in-progress";
    });
    builder.addCase(
      runSchemaValidationAsync.fulfilled,
      (state, { payload }) => {
        state.nonCompliantRequests = payload.nonCompliantGroupByRequest;
        state.nonCompliantRequestsByField = payload.nonCompliantGroupByField;
        if (payload.nonCompliantGroupByField.length === 0)
          state.validationStatus = "success";
        else state.validationStatus = "failed";
      }
    );
    builder.addCase(updateRequestFilters, (state) => {
      state.policy = null;
      state.schemaFields = [];
      state.enabledStatusList = null;
      state.workflow = null;
      state.nonCompliantRequests = {};
      state.nonCompliantRequestsByField = [];
      state.validationStatus = "idle";
      state.totalSelected = 0;
      state.selectMode = "normal";
    });
    /**Unloading data when the requestList slice unloads */
    builder.addCase(unloadRequestList, (state) => {
      state.workflow = null;
      state.workflowRef = null;
      state.schemaFields = [];
      state.policy = null;
      state.requests = [];
      state.totalSelected = 0;
      state.nonCompliantRequests = {};
      state.nonCompliantRequestsByField = [];
      state.updateStatus = "idle";
      state.enabledStatusList = null;
      state.transactionsSummary = {};
      state.validationStatus = "idle";
      state.selectMode = "normal";
      state.selectionChanged = false;
      state.bulkEditStep = "none";
      state.loadingEditData = "idle";
      state.successfulUpdateMessage = "";
      state.partialUpdateMessage = "";
      state.failedUpdateMessage = "";
      state.totalAllowedForSelection = 0;
    });
  },
});
export const {
  setBulkRequests,
  setTotalSelected,
  setFieldsToUpdate,
  setUpdateStatus,
  updateBulkSelection,
  markRequestsForDeletion,
  clearBulkEditDataAsync,
  setSelectMode,
  setBulkEditStep,
  setValidationStatus,
} = requestsBulkActionsSlice.actions;
export default requestsBulkActionsSlice.reducer;
