import {
  ApiAccount,
  ApiAccountUser,
  ApiCatalog,
  ApiClient,
  ApiRole,
  ApiUser,
  ApiUserInvite,
  ApiWorkflow,
  CatalogPolicy,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

const PAGE_SIZE = 20;

export interface InitAccountUsersThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
}

export const initAccountUsers = createAsyncThunk(
  "account-users/init",
  async ({ apiClient, account }: InitAccountUsersThunkParams, thunkAPI) => {
    const [
      pagedUsers,
      pagedInvites,
      roles,
      eventRoles,
      inventoryRoles,
      portalRoles,
      energyRoles,
      planningRoles,
    ] = await Promise.all([
      apiClient.findAccountUsers(account.id, {
        pageSize: PAGE_SIZE,
      }),
      apiClient.findAccountUserInvites(account.id, { pageSize: PAGE_SIZE }),
      apiClient.findProductRoles(account.id, {
        name: "HeroHQ",
      }),
      apiClient.findProductRoles(account.id, {
        name: "Events",
      }),
      apiClient.findProductRoles(account.id, {
        name: "InventoryHQ",
      }),
      apiClient.findProductRoles(account.id, {
        name: "PortalHQ",
      }),
      apiClient.findProductRoles(account.id, {
        name: "EnergyHQ",
      }),
      apiClient.findProductRoles(account.id, {
        name: "PlanningHQ",
      }),
    ]);

    return {
      pagedUsers,
      pagedInvites,
      roles,
      eventRoles,
      inventoryRoles,
      portalRoles,
      energyRoles,
      planningRoles,
    };
  }
);

export interface LoadAccountUsersThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  includeInactive: boolean;
  search?: string;
  noIncludeEmail?: boolean;
  productRoles?: string[];
}

export const loadUsers = createAsyncThunk(
  "account-users/load-users",
  async (
    {
      apiClient,
      account,
      includeInactive,
      search,
      noIncludeEmail,
      productRoles,
    }: LoadAccountUsersThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const pagedUsers = await apiClient.findAccountUsers(account.id, {
      pageSize: PAGE_SIZE,
      page: state.accountUsers.usersCurrentPage,
      includeInactive,
      search,
      noIncludeEmail,
      productRoles,
    });
    return { pagedUsers, includeInactive };
  }
);

export interface LoadAccountInviteThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
}

export const loadInvites = createAsyncThunk(
  "account-users/load-invites",
  async ({ apiClient, account }: LoadAccountInviteThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const pagedUsers = await apiClient.findAccountUserInvites(account.id, {
      pageSize: PAGE_SIZE,
      page: state.accountUsers.invitesCurrentPage,
    });

    return pagedUsers;
  }
);

export interface DisableUserThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiAccountUser;
}

export const disableUser = createAsyncThunk(
  "account-users/disable-user",
  async ({ apiClient, account, user }: DisableUserThunkParams, thunkAPI) => {
    await apiClient.deactivateAccountUser(account.id, user.id);
    return user;
  }
);

export interface ReactivateUserThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiAccountUser;
}

export const reactivateUser = createAsyncThunk(
  "account-users/reactivate-user",
  async ({ apiClient, account, user }: ReactivateUserThunkParams, thunkAPI) => {
    const result = await apiClient.reactivateAccountUser(account.id, user.id);
    return result;
  }
);

export interface UpdateUserRoleThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiAccountUser;
  productName:
    | "HeroHQ"
    | "Events"
    | "InventoryHQ"
    | "PortalHQ"
    | "EnergyHQ"
    | "PlanningHQ";
  role: string;
}
export interface DeactivateUserRoleThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiAccountUser;
  productName:
    | "HeroHQ"
    | "Events"
    | "InventoryHQ"
    | "PortalHQ"
    | "EnergyHQ"
    | "PlanningHQ";
}
export const updateUserRole = createAsyncThunk(
  "account-users/update-user-role",
  async (
    { apiClient, account, user, role, productName }: UpdateUserRoleThunkParams,
    thunkAPI
  ) => {
    const products = [{ name: productName, role, enabled: true }];

    const result = await apiClient.updateAccountUser(account.id, user.id, {
      products,
    });
    return result;
  }
);

export const deactivateUserRole = createAsyncThunk(
  "account-users/deactivate-user-role",
  async (
    { apiClient, account, user, productName }: DeactivateUserRoleThunkParams,
    thunkAPI
  ) => {
    await apiClient.deactivateRole(account.id, user.id, productName);
    return { user, productName };
  }
);
export interface AccountUsersSliceState {
  users: ApiAccountUser[];
  usersTotal: number;
  usersCurrentPage: number;
  includeInactive: boolean;
  invites: ApiUserInvite[];
  invitesTotal: number;
  invitesCurrentPage: number;

  roles: ApiRole[];
  eventRoles: ApiRole[];
  inventoryRoles: ApiRole[];
  portalRoles: ApiRole[];
  energyRoles: ApiRole[];
  planningRoles: ApiRole[];

  roleSelected?: ApiRole;
  eventRoleSelected?: ApiRole;
  planningRoleSelected?: ApiRole;
  workflowList: ApiWorkflow[];
  selectedWorkflow: ApiWorkflow | null;
  workflowPolicies: (ApiWorkflow & WorkflowPolicy)[];

  catalogRoleSelected?: ApiRole;
  catalogList: ApiCatalog[];
  selectedCatalog: ApiCatalog | null;
  catalogPolicies: (ApiCatalog & CatalogPolicy)[];
}

export const accountUsersSlice = createSlice({
  name: "account-users",
  initialState: {
    users: [],
    usersTotal: 0,
    usersCurrentPage: 1,
    includeInactive: false,
    invites: [],
    invitesTotal: 0,
    invitesCurrentPage: 1,
    roles: [],
    eventRoles: [],
    inventoryRoles: [],
    portalRoles: [],
    energyRoles: [],
    planningRoles: [],
    roleSelected: undefined,
    eventRoleSelected: undefined,
    planningRoleSelected: undefined,
    workflowList: [],
    selectedWorkflow: null,
    workflowPolicies: [],

    catalogRoleSelected: undefined,
    catalogList: [],
    selectedCatalog: null,
    catalogPolicies: [],
  } as AccountUsersSliceState,
  reducers: {
    unload: (state) => {
      state.users = [];
      state.usersTotal = 0;
      state.usersCurrentPage = 1;
      state.includeInactive = false;
      state.invites = [];
      state.invitesTotal = 0;
      state.invitesCurrentPage = 1;
    },
    unloadInviteModal: (state) => {
      state.roleSelected = undefined;
      state.eventRoleSelected = undefined;
      state.catalogRoleSelected = undefined;
      state.planningRoleSelected = undefined;
      state.workflowList = [];
      state.selectedWorkflow = null;
      state.workflowPolicies = [];
      state.catalogList = [];
      state.selectedCatalog = null;
      state.catalogPolicies = [];
    },
    setUsersCurrentPage: (state, payload: PayloadAction<number>) => {
      state.usersCurrentPage = payload.payload;
    },
    setInvitesCurrentPage: (state, payload: PayloadAction<number>) => {
      state.invitesCurrentPage = payload.payload;
    },
    setIncludeInactiveState: (state, payload: PayloadAction<boolean>) => {
      state.includeInactive = payload.payload;
    },
    setRole: (state, payload: PayloadAction<ApiRole | undefined>) => {
      state.roleSelected = payload.payload;
      if (payload.payload === undefined) {
        state.selectedWorkflow = null;
        state.workflowPolicies = [];
      }
    },
    setEventRole: (state, payload: PayloadAction<ApiRole | undefined>) => {
      state.eventRoleSelected = payload.payload;
    },
    setPlanningRole: (state, payload: PayloadAction<ApiRole | undefined>) => {
      state.planningRoleSelected = payload.payload;
    },
    setWorkflows: (state, payload: PayloadAction<ApiWorkflow[]>) => {
      state.workflowList = payload.payload;
    },
    addInvites: (state, payload: PayloadAction<ApiUserInvite[]>) => {
      state.invites = state.invites.concat(payload.payload);
      state.invitesTotal += payload.payload.length;
    },
    setSelectedWorkflow: (
      state,
      payload: PayloadAction<ApiWorkflow | null>
    ) => {
      state.selectedWorkflow = payload.payload;
    },
    updateUser: (state, payload: PayloadAction<ApiUser>) => {
      const user = payload.payload;
      const userIndex = state.users.findIndex((u) => u.id === user.id);

      if (userIndex < 0) return;

      state.users[userIndex] = { ...state.users[userIndex], ...user };
    },
    setWorkflowPolicyTable: (
      state,
      payload: PayloadAction<{ id: string; policy: WorkflowPolicy }>
    ) => {
      const { id, policy } = payload.payload;
      state.selectedWorkflow = null;
      const workflow = state.workflowList.find((x) => x.id === id);
      if (workflow) {
        state.workflowPolicies.push({
          ...workflow,
          ...policy,
        });
        state.workflowList = (state.workflowList as ApiWorkflow[]).filter(
          (work) => work.id !== workflow.id
        );
      }
    },
    removeWorkflowPolicyTable: (state, payload: PayloadAction<string>) => {
      const id = payload.payload;
      const workflow = state.workflowPolicies.find((work) => work.id === id);
      if (workflow) {
        state.workflowList.push(workflow);
        state.workflowPolicies = (
          state.workflowPolicies as (ApiWorkflow & WorkflowPolicy)[]
        ).filter((work) => work.id !== workflow.id);
      }
    },
    setSelectedCatalog: (state, payload: PayloadAction<ApiCatalog | null>) => {
      state.selectedCatalog = payload.payload;
    },
    setCatalogPolicyTable: (
      state,
      payload: PayloadAction<{ id: string; policy: CatalogPolicy }>
    ) => {
      const { id, policy } = payload.payload;
      state.selectedCatalog = null;
      const catalog = state.catalogList.find((x) => x.id === id);
      if (catalog) {
        state.catalogPolicies.push({
          ...catalog,
          ...policy,
        });
        state.catalogList = (state.catalogList as ApiCatalog[]).filter(
          (cat) => cat.id !== catalog.id
        );
      }
    },
    removeCatalogPolicyTable: (state, payload: PayloadAction<string>) => {
      const id = payload.payload;
      const catalog = state.catalogPolicies.find((cat) => cat.id === id);
      if (catalog) {
        state.catalogList.push(catalog);
        state.catalogPolicies = (
          state.catalogPolicies as (ApiCatalog & CatalogPolicy)[]
        ).filter((cat) => cat.id !== catalog.id);
      }
    },
    setCatalogRole: (state, payload: PayloadAction<ApiRole | undefined>) => {
      state.catalogRoleSelected = payload.payload;
    },
    setCatalogs: (state, payload: PayloadAction<ApiCatalog[]>) => {
      state.catalogList = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initAccountUsers.fulfilled, (state, action) => {
      state.usersTotal = action.payload.pagedUsers.total;
      state.usersCurrentPage =
        action.payload.pagedUsers.options.page || state.usersCurrentPage;
      state.users = action.payload.pagedUsers.data;

      state.invitesTotal = action.payload.pagedInvites.total;
      state.invitesCurrentPage =
        action.payload.pagedInvites.options.page || state.invitesCurrentPage;
      state.invites = action.payload.pagedInvites.data;
      state.roles = action.payload.roles.map((rol) => rol);
      state.eventRoles = action.payload.eventRoles.map((rol) => rol);
      state.inventoryRoles = action.payload.inventoryRoles.map((rol) => rol);
      state.portalRoles = action.payload.portalRoles.map((rol) => rol);
      state.energyRoles = action.payload.energyRoles.map((rol) => rol);
      state.planningRoles = action.payload.planningRoles.map((rol) => rol);
    });

    builder.addCase(loadUsers.fulfilled, (state, action) => {
      const { pagedUsers } = action.payload;
      state.usersTotal = pagedUsers.total;
      state.usersCurrentPage =
        pagedUsers.options.page || state.usersCurrentPage;
      state.users = pagedUsers.data;
    });

    builder.addCase(loadInvites.fulfilled, (state, action) => {
      state.invitesTotal = action.payload.total;
      state.invitesCurrentPage =
        action.payload.options.page || state.invitesCurrentPage;
      state.invites = action.payload.data;
    });

    builder.addCase(disableUser.fulfilled, (state, action) => {
      const user = action.payload;
      const userIndex = state.users.findIndex((u) => u.id === user.id);

      if (userIndex < 0) return;
      const { products, ...rest } = user;
      state.users[userIndex] = {
        ...rest,
        products: products.map((x) => ({ ...x, enabled: false })),
      };
      if (!state.includeInactive) {
        state.users.splice(userIndex, 1);
      }
    });

    builder.addCase(reactivateUser.fulfilled, (state, action) => {
      const { id } = action.payload;
      const userIndex = state.users.findIndex((u) => u.id === id);
      if (userIndex < 0) return;

      state.users[userIndex] = action.payload;
    });

    builder.addCase(updateUserRole.fulfilled, (state, action) => {
      const user = action.payload;
      const userIndex = state.users.findIndex((u) => u.id === user.id);

      if (userIndex < 0) return;

      state.users[userIndex] = action.payload;
    });
    builder.addCase(deactivateUserRole.fulfilled, (state, action) => {
      const user = action.payload.user;
      const userIndex = state.users.findIndex((u) => u.id === user.id);

      if (userIndex < 0) return;

      state.users[userIndex].products.find(
        (x) => x.name === action.payload.productName
      )!.enabled = false;
    });
  },
});

export const {
  unload,
  addInvites,
  setUsersCurrentPage,
  setInvitesCurrentPage,
  setIncludeInactiveState,
  setRole,
  setEventRole,
  setPlanningRole,
  setWorkflows,
  setSelectedWorkflow,
  setWorkflowPolicyTable,
  removeWorkflowPolicyTable,
  unloadInviteModal,
  setSelectedCatalog,
  setCatalogPolicyTable,
  removeCatalogPolicyTable,
  setCatalogRole,
  setCatalogs,
  updateUser,
} = accountUsersSlice.actions;

export default accountUsersSlice.reducer;
