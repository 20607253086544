import { Flex, Text } from "@chakra-ui/react";
import adaptivePlugin from "@fullcalendar/adaptive";
import interactionPlugin, {
  EventReceiveArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import FullCalendar, { EventDropArg, EventInput } from "@fullcalendar/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import moment from "moment";
import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../../store";
import { updateProjectSchedule } from "../../../../store/planning-hq/scheduling/project-scheduling.slice";
import { RequestTimelineItem } from "./RequestTimelineItem";
import { TimelineHeader } from "./TimelineHeader";
import { ScheduleItem } from "./types";
import { useCustomTimelinePlugin } from "./useCustomTimelinePlugin";

type TimelineProps = {
  project: ApiProject;
  timelineRef: MutableRefObject<FullCalendar | null>;
};

export const Timeline: FC<TimelineProps> = ({ project, timelineRef }) => {
  const { apiClient } = useAuthentication();
  const calendarRef = useRef<FullCalendar>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [headerCalendarRef, setHeaderCalendarRef] =
    useState<FullCalendar | null>(null);

  const { customTimelinePlugin } = useCustomTimelinePlugin();
  const { requestsCache } = useSelector(
    (state: RootState) => state.projectRequests
  );

  const { loadingStatus, total } = useSelector(
    (state: RootState) => state.projectRequests
  );

  const thunkDispatch = useThunkDispatch();

  const rowResources = useMemo(() => {
    const rows = "ABCDEFGHIJKLMNOPQRST".split("");

    return rows.map((r, index) => ({
      id: r,
      title: index + "",
    }));
  }, []);

  const eventAddedToTimeline = useCallback(
    (args: EventReceiveArg) => {
      const event = args.event;
      const resources = args.event.getResources();
      const resource = resources[0];

      const updatedItem: ScheduleItem = {
        id: event.id,
        rowId: resource.id,
        start: new Date(event.start ?? "").toISOString(),
        end: new Date(event.end ?? "").toISOString(),
      };

      thunkDispatch(
        updateProjectSchedule({
          projectId: project.id,
          apiClient,
          item: updatedItem,
        })
      );
    },
    [apiClient, project.id, thunkDispatch]
  );

  const eventChange = useCallback(
    async (args: EventResizeDoneArg) => {
      const oldValue = args.oldEvent;
      const event = args.event;

      if (oldValue.start !== event.start || oldValue.start !== event.start) {
        const event = args.event;
        const resources = args.event.getResources();
        const resource = resources[0];

        const updatedItem: ScheduleItem = {
          id: event.id,
          rowId: resource.id,
          start: new Date(event.start ?? "").toISOString(),
          end: new Date(event.end ?? "").toISOString(),
        };

        thunkDispatch(
          updateProjectSchedule({
            projectId: project.id,
            apiClient,
            item: updatedItem,
          })
        );
      }
    },
    [apiClient, project.id, thunkDispatch]
  );

  const eventUpdated = useCallback(
    (args: EventDropArg) => {
      const event = args.event;
      const resources = args.event.getResources();
      const resource = resources[0];

      const updatedItem: ScheduleItem = {
        id: event.id,
        rowId: resource.id,
        start: new Date(event.start ?? "").toISOString(),
        end: new Date(event.end ?? "").toISOString(),
      };

      thunkDispatch(
        updateProjectSchedule({
          projectId: project.id,
          apiClient,
          item: updatedItem,
        })
      );
    },
    [apiClient, project.id, thunkDispatch]
  );

  const events = useMemo(() => {
    const scheduling = project.scheduling;
    const items = scheduling["items"] ? scheduling.items : {};

    if (Object.keys(requestsCache).length === 0) {
      return [];
    }
    return Object.keys(items).map((key) => {
      const item = items[key];
      const eventItem: EventInput = {
        start: moment(item.start).toDate(),
        end: moment(item.end).toDate(),
        title: "",
        resourceId: item.rowId,
        ...requestsCache[key],
      };
      return eventItem;
    });
  }, [project, requestsCache]);

  if (calendarRef.current && !timelineRef.current) {
    timelineRef.current = calendarRef.current;
  }

  useEffect(() => {
    if (!calendarRef.current) return;

    setHeaderCalendarRef(calendarRef.current);

    if (!timelineRef.current) {
      timelineRef.current = calendarRef.current;
    }
  }, [requestsCache, loadingStatus, timelineRef, project]);

  if (!customTimelinePlugin) return null;
  if (!project.start || !project.end) return null;

  return (
    <>
      {loadingStatus === "fulfilled" && (
        <>
          {total === 0 ? (
            <Flex direction="column" height="full" pt={10}>
              <Text color="gray.500" textAlign="center">
                There are no requests to add to the timeline
              </Text>
            </Flex>
          ) : (
            <>
              {!project ? null : (
                <Flex ref={containerRef} height="full" flexDir="column" gap={2}>
                  {headerCalendarRef && (
                    <TimelineHeader
                      calendar={headerCalendarRef}
                      project={project}
                      container={containerRef.current as HTMLDivElement}
                    />
                  )}
                  <FullCalendar
                    key={"requests-scheduling-timeline"}
                    ref={calendarRef}
                    plugins={[
                      adaptivePlugin,
                      interactionPlugin,
                      customTimelinePlugin,
                    ]}
                    initialView="resourceTimelineMonth"
                    headerToolbar={false}
                    editable={true}
                    droppable={true}
                    schedulerLicenseKey={
                      process.env.REACT_APP_FULLCALENDAR_LICENSE
                    }
                    height="100%"
                    resourceAreaWidth={0}
                    resourcesInitiallyExpanded={false}
                    resources={rowResources}
                    expandRows={true}
                    resourceLaneClassNames={["custom-row"]}
                    eventContent={(eventArgs) => (
                      <RequestTimelineItem args={eventArgs} />
                    )}
                    eventClassNames={["project-request"]}
                    eventBorderColor="none"
                    validRange={{
                      start: project.start,
                      end: project.end,
                    }}
                    eventResize={eventChange}
                    eventDrop={eventUpdated}
                    eventReceive={eventAddedToTimeline}
                    initialEvents={events}
                    eventOverlap={false}
                    datesSet={(args) => {
                      args.view.calendar.scrollToTime({ days: -100 });
                    }}
                  />
                </Flex>
              )}
            </>
          )}
        </>
      )}

      {loadingStatus === "pending" && <Text color="gray.500">Loading...</Text>}

      {loadingStatus === "rejected" && (
        <Text color="gray.500">An error ocurred while loading requests</Text>
      )}
    </>
  );
};
