import { Box, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { FC } from "react";
import { ProjectListFilters } from "../../../../store/planning-hq/project-list";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

export type HeaderProps = {
  filters: ProjectListFilters;
  updateFilters: (filter: Partial<ProjectListFilters>) => void;
  clearFilters: () => void;
  onNewProjectClick: () => any;
};

export const Header: FC<HeaderProps> = ({
  filters,
  updateFilters,
  clearFilters,
  onNewProjectClick,
}) => {
  const isDesktop = useBreakpointValue({ base: false, md: true, lg: true });
  const bgColorBox = useColorModeValue("blue.50", "blue.900");

  return (
    <>
      {isDesktop ? (
        <Box w="full" p={3} bgColor={bgColorBox}>
          <DesktopHeader
            filters={filters}
            updateFilters={updateFilters}
            clearFilters={clearFilters}
            onNewProjectClick={onNewProjectClick}
          />
        </Box>
      ) : (
        <Box w="full" p={3}>
          <MobileHeader
            filters={filters}
            updateFilters={updateFilters}
            clearFilters={clearFilters}
            onNewProjectClick={onNewProjectClick}
          />
        </Box>
      )}
    </>
  );
};
