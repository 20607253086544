export function localMidnightToUTC(date: Date) {
  date.setHours(0, 0, 0, 0);
  return date.toISOString(); //.split("T")[0]; -> no splitting to send time included to BE
}

export function calculateDateToUTC(days: number) {
  const today = new Date();
  const date = new Date(today);
  date.setDate(today.getDate() + days);
  return localMidnightToUTC(date);
}

export function formatUTCToText(date?: string | null) {
  if (!date) return "";
  return date.replaceAll("-", "/")?.split("T")[0];
}
