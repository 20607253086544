import {
  ApiClient,
  ApiComment,
  CreateApiComment,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";

type CreateCommentParams = {
  apiClient: ApiClient;
  projectId: string;
  comment: CreateApiComment;
};
export const createComment = createAsyncThunk<ApiComment, CreateCommentParams>(
  "project-commments/create",
  async ({ apiClient, projectId, comment }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState;
    const { currentAccount } = store.auth;
    const response = await apiClient.createProjectComment(
      currentAccount.id,
      projectId,
      comment
    );
    return response;
  }
);

export type CreateCommentsHandlerProps = {
  comments: ApiComment[];
};

export const createCommentHandlers = <T extends CreateCommentsHandlerProps>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(createComment.fulfilled, (state, action) => {
    const newComment = action.payload;
    state.comments = [newComment, ...state.comments];
  });
};
