import { ApiClient, ApiAccount } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export interface LoadAssetsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  key: string;
  page?: number;
}

export const loadAssets = createAsyncThunk(
  "requests/loadAssets",
  async (params: LoadAssetsThunkParams, thunkAPI) => {
    const { apiClient, account, key } = params;
    const state = thunkAPI.getState() as RootState;
    const {
      assets: { page, pageSize },
    } = state.requestForm;
    const pagedComments = await apiClient.findRequestAssets(account.id, key, {
      pageSize,
      page,
    });
    return pagedComments;
  }
);

export const loadAssetsHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadAssets.fulfilled, (state, action) => {
    const { data, options, total } = action.payload;
    state.assets.page = options.page != null ? options.page : state.assets.page;
    state.assets.total = total;
    state.assets.data = data;
  });
};
