import {
  ApiBillingGroup,
  ApiBillingGroupBase,
  ApiBillingGroupType,
  ApiDays,
  ApiHoursType,
  ApiPricing,
  ApiRate,
  ApiRateType,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../components/auth/AuthProvider";
import { daysRelation } from "../utils/daysRelation";

interface BillingGroupData extends ApiBillingGroupBase {
  items: string[];
}

interface useBillingGroupRatesParams {
  rateGroupId?: string;
  type: ApiBillingGroupType;
}

export interface ItemRateProps {
  total: number;
  pricing: ApiPricing;
  id: string;
  name: string;
  generateInvoices: boolean;
}

export function useBillingGroupRates({
  rateGroupId,
  type,
}: useBillingGroupRatesParams) {
  const [rates, setRates] = useState<Map<string, ApiRate[]>>();
  const [billingGroups, setBillingGroups] = useState<BillingGroupData[]>([]);
  const [eventBillingGroups, setEventBillingGroups] = useState<
    ApiBillingGroup[]
  >([]);
  const { apiClient, currentAccount } = useAuthentication();

  const findBillingGroupWithItems = useCallback(
    async (billingGroups: ApiBillingGroupBase[]) => {
      const result = await Promise.all(
        billingGroups.map(async (billingGroup) => {
          const getBillingGroupServices = () =>
            apiClient
              .getBillingGroupServices(currentAccount.id, billingGroup.id)
              .then((services) => services.data.map((serv) => serv.id));

          const getBillingGroupEquipment = () =>
            apiClient
              .getBillingGroupEquipment(currentAccount.id, billingGroup.id)
              .then((equipment) => equipment.data.map((e) => e.id));

          const getBillingGroupSpaces = () =>
            apiClient
              .getBillingGroupSpaces(currentAccount.id, billingGroup.id)
              .then((spaces) => {
                return spaces.data.map((s) => s.id);
              });

          return {
            ...billingGroup,
            // Items will be array of equipment ids or services ids, this will be used for assign to each item the respective rate
            items:
              type === ApiBillingGroupType.service
                ? await getBillingGroupServices()
                : type === ApiBillingGroupType.equipment
                  ? await getBillingGroupEquipment()
                  : await getBillingGroupSpaces(),
          };
        })
      );

      return result;
    },
    [apiClient, currentAccount.id, type]
  );

  const findBillingGroups = useCallback(async () => {
    const response = await apiClient.findBillingGroup(currentAccount.id, {
      pageSize: 100,
      type: type,
    });
    if (type === ApiBillingGroupType.event) {
      setEventBillingGroups(response.data);
    } else {
      const billingGroups = await findBillingGroupWithItems(response.data);

      setBillingGroups(billingGroups);
    }
  }, [apiClient, currentAccount.id, findBillingGroupWithItems, type]);

  const findRates = useCallback(() => {
    if (!rateGroupId) return;
    const ratesMap = new Map<string, ApiRate[]>();
    billingGroups.forEach((billingGroup) => {
      const { items } = billingGroup;
      // We will get the rate from billingGroup by rateGroupId

      const rate = billingGroup.rateGroups.filter(
        (rg) => rg.id === rateGroupId
      );

      if (rate && rate.length > 0) {
        items.forEach((item) => {
          if (!ratesMap.has(item)) {
            // Each (serviceId | equipmentId) will have assigned a rate
            ratesMap.set(item, { ...rate });
          }
        });
      }
    });
    setRates(ratesMap);
  }, [rateGroupId, billingGroups]);

  // This callback is a helper to get rate including the total, using the item(service | equipment) id.
  const getItemRates = useCallback(
    (
      itemId: string,
      isSelected: boolean,
      occurrences: CreateApiEventOccurrence[],
      eventDuration: number,
      quantity?: number
    ) => {
      if (!rateGroupId || !rates) return;
      const availableRates = rates.get(itemId);
      if (!availableRates || availableRates.length < 1) return;

      let total = 0;

      if (!isSelected) {
        return [{ ...availableRates, total: 0 }];
      }

      const totalEachOccurrence = occurrences.map((occurrence) => {
        const day = new Date(occurrence.start).getDay();
        const hours = eventDuration;
        const availableRate = Object.values(availableRates).find((r) =>
          r.days?.includes(daysRelation[day] as ApiDays)
        );
        const rateToUse = availableRate
          ? availableRate
          : Object.values(availableRates).find((r) => r.days?.length === 0);

        if (!rateToUse) return { ...availableRate, total };
        const { pricing } = rateToUse;

        if (type === ApiBillingGroupType.equipment) {
          switch (pricing.type) {
            case ApiRateType.hourly:
              if (pricing.minHoursType === ApiHoursType.minHours) {
                if (
                  hours <= pricing.minimumHours ||
                  pricing.minimumHours === 0
                ) {
                  total = pricing.price;
                } else {
                  total =
                    (hours - pricing.minimumHours) *
                      (pricing.additionalHour ? pricing.additionalHour : 1) +
                    pricing.price;
                }
              }
              break;
            case ApiRateType.flat:
              total = pricing.price;
              quantity = 1; // quantity doesn't matter when is flat
              break;
            case ApiRateType.each: {
              if (!pricing.fullDayRate) {
                total = pricing.price * hours;
              } else {
                if (pricing.greaterThan && hours >= pricing.greaterThan) {
                  total = pricing.fullDayPrice ? pricing.fullDayPrice : 0;
                } else {
                  total = hours * pricing.price;
                }
              }
            }
          }
        } else {
          if (
            pricing.type === ApiRateType.hourly &&
            pricing.minimumHours !== 0
          ) {
            if (pricing.minHoursType === ApiHoursType.minHours) {
              if (hours <= pricing.minimumHours) {
                total = pricing.price;
              } else {
                total =
                  (hours - pricing.minimumHours) *
                    (pricing.additionalHour ? pricing.additionalHour : 1) +
                  pricing.price;
              }
            } else {
              total = Math.ceil(hours / pricing.minimumHours) * pricing.price;
            }
          } else {
            total = pricing.price;
          }
        }
        if (quantity !== undefined) {
          total = total * quantity;
        }
        return { ...availableRate, total };
      });
      return totalEachOccurrence;
    },
    [rateGroupId, type, rates]
  );

  const getEventRates = useCallback(
    (rateGroupId: string) => {
      const rates = eventBillingGroups.map((bGroup) => {
        return {
          billingGroup: bGroup.name,
          rate: bGroup.rateGroups.find((rg) => rg.id === rateGroupId),
        };
      });
      return rates;
    },
    [eventBillingGroups]
  );

  const getItemRatePerEvent = useCallback(
    (rgId: string) => {
      const eventRates = getEventRates(rgId);
      return eventRates;
    },
    [getEventRates]
  );

  const getAllRatesByItemId = useCallback(
    (itemId: string) => {
      if (!rates) return;
      return rates.get(itemId);
    },
    [rates]
  );

  useEffect(() => {
    findBillingGroups();
  }, [findBillingGroups]);

  useEffect(() => {
    findRates();
  }, [findRates]);

  return {
    rates,
    billingGroups,
    getItemRatePerEvent,
    getItemRates,
    getAllRatesByItemId,
  };
}

export interface RateProps {
  total: number;
  pricing?: ApiPricing;
  generateInvoices?: boolean;
  days?: ApiDays[];
  rateId?: string;
  id?: string;
  name?: string;
}
