import { Box, Button } from "@chakra-ui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";

interface RemoveAssociationButtonProps {
  associationId: string;
  handleDeleteAssociation: (
    e: React.MouseEvent<HTMLButtonElement>,
    associationId: string,
  ) => void;
}

export const RemoveAssociationButton: FC<RemoveAssociationButtonProps> = ({
  associationId,
  handleDeleteAssociation,
}) => {
  const { isProductAdmin } = useAuthentication();
  const { policy } = useSelector((state: RootState) => state.requestForm);

  if (
    policy &&
    (policy.reviewer || policy.technician || policy.admin || isProductAdmin)
  ) {
    return (
      <Box
        w="100%"
        display="flex"
        justifyContent={["center", "center", "flex-end", "center", "flex-end"]}
      >
        <Button
          size="xs"
          variant="ghost"
          border="1px solid"
          colorScheme="blue"
          borderColor="blue.500"
          onClick={(e) => handleDeleteAssociation(e, associationId)}
        >
          Remove Association
        </Button>
      </Box>
    );
  }
  return null;
};
