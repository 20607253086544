import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Textarea,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import {
  ApiQuestion,
  ApiQuestionDataType,
} from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { ImageSelection } from "../../../../../components/images/ImageSelection";
import { StyledSelect } from "../../../../../components/selects/StyledSelect";

interface QuestionResponseFieldsProps {
  question: ApiQuestion;
  index: number;
  value?: string | number | boolean | string[] | null;
  onChange: (value: string | number | boolean | string[]) => void;
  isInvalid?: boolean;
  onBlur?: (values?: string | string[] | boolean | number) => void;
}

export const QuestionResponseFields: FC<QuestionResponseFieldsProps> = ({
  question,
  value,
  index,
  onChange,
  isInvalid,
  onBlur,
}) => {
  const handleOnChangeText = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  const handleOnChangeNumber = useCallback(
    (value: number, min: number, max: number) => {
      // Returns the number inside the range
      const newValue = Math.max(min, Math.min(value, max));
      onChange(newValue);
    },
    [onChange]
  );

  const handleOnChangeMultiSelection = useCallback(
    (id: string) => {
      const newValues = value ? [...(value as string[])] : [];
      const index = newValues.findIndex((v) => v === id);
      if (index !== -1) {
        newValues.splice(index, 1);
        if (question.required && newValues.length === 0) return;
        onChange(newValues);
        onBlur && onBlur(newValues);
        return;
      }
      newValues.push(id);
      onChange(newValues);
      onBlur && onBlur(newValues);
    },
    [onBlur, onChange, question.required, value]
  );

  const handleOnChangeAttachment = useCallback(
    (id: string) => {
      const newValues = value ? [...(value as string[])] : [];
      const index = newValues.findIndex((v) => v === id);
      if (index !== -1) {
        newValues.splice(index, 1);
        onChange(newValues);
        onBlur && onBlur(newValues);
        return;
      }
      newValues.push(id);
      onChange(newValues);
      onBlur && onBlur(newValues);
    },
    [onBlur, onChange, value]
  );

  return (
    <VStack width="100%" justifyContent="flex-start">
      {question.type !== ApiQuestionDataType.checkbox && (
        <Text width="100%" fontSize="sm">
          {index}. {question.textQuestion} {question.required && "*"}
        </Text>
      )}
      {question.type === ApiQuestionDataType.text && (
        <Box width="100%">
          {question.allowMultiline && (
            <FormControl isInvalid={isInvalid}>
              <Textarea
                value={value as string}
                onChange={(e) => handleOnChangeText(e.target.value)}
                onBlur={() => onBlur && onBlur()}
              />
              {isInvalid && (
                <FormErrorMessage>
                  This question field is required.
                </FormErrorMessage>
              )}
            </FormControl>
          )}
          {!question.allowMultiline && (
            <FormControl isInvalid={isInvalid}>
              <Input
                value={value as string}
                onChange={(e) => handleOnChangeText(e.target.value)}
                onBlur={() => onBlur && onBlur()}
              />
              {isInvalid && (
                <FormErrorMessage>
                  This question field is required.
                </FormErrorMessage>
              )}
            </FormControl>
          )}
        </Box>
      )}

      {question.type === ApiQuestionDataType.number && (
        <Box width="100%">
          <FormControl isInvalid={isInvalid}>
            <NumberInput
              min={question.min}
              max={question.max}
              precision={question.decimalPlace}
              value={value ? (value as number) : undefined}
              onChange={(e) => {
                handleOnChangeNumber(parseFloat(e), question.min, question.max);
              }}
            >
              <NumberInputField
                onChange={(e) => {
                  handleOnChangeNumber(
                    parseFloat(e.target.value),
                    question.min,
                    question.max
                  );
                }}
                onBlur={(e) => onBlur && onBlur(parseFloat(e.target.value))}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {isInvalid && (
              <FormErrorMessage>
                This question field is required.
              </FormErrorMessage>
            )}
          </FormControl>
        </Box>
      )}

      {question.type === ApiQuestionDataType.checkbox && (
        <FormControl isInvalid={isInvalid}>
          <Checkbox
            size="lg"
            width="100%"
            isChecked={value as boolean}
            onChange={() => {
              onChange(!(value as boolean));
              onBlur && onBlur(!(value as boolean));
            }}
          >
            <Text fontSize="sm">
              {question.textQuestion} {question.required && "*"}
            </Text>
          </Checkbox>
          {isInvalid && (
            <FormErrorMessage>
              This question field is required.
            </FormErrorMessage>
          )}
        </FormControl>
      )}

      {question.type === ApiQuestionDataType.multi_selection && (
        <VStack width="100%">
          <FormControl isInvalid={isInvalid}>
            {question.values?.map((val) => (
              <Checkbox
                size="lg"
                width="100%"
                value={val.id}
                isChecked={(value ? (value as string[]) : []).some(
                  (v) => v === val.id
                )}
                onChange={() => {
                  handleOnChangeMultiSelection(val.id);
                }}
                key={`checkboxValues::${val.id}`}
              >
                <Text fontSize="sm">{val.value}</Text>
              </Checkbox>
            ))}
            {isInvalid && (
              <FormErrorMessage>
                This question field is required.
              </FormErrorMessage>
            )}
          </FormControl>
        </VStack>
      )}
      {question.type === ApiQuestionDataType.selection && (
        <FormControl isInvalid={isInvalid}>
          <StyledSelect
            options={question.options}
            onChange={(e) => {
              onChange(e ? e.id : null);
              onBlur && onBlur(e ? e.id : null);
            }}
            value={value ? (value as string) : undefined}
            isClearable={true}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />

          {isInvalid && (
            <FormErrorMessage>
              This question field is required.
            </FormErrorMessage>
          )}
        </FormControl>
      )}

      {question.type === ApiQuestionDataType.picture && (
        <FormControl isInvalid={isInvalid}>
          <Wrap width="100%">
            {question.attachments.map((att) => (
              <ImageSelection
                attachment={att}
                isChecked={(value ? (value as string[]) : []).some(
                  (v) => v === att.id
                )}
                onChangeAttachment={handleOnChangeAttachment}
                key={`imageSelection::${att.id}`}
              />
            ))}
          </Wrap>
          {isInvalid && (
            <FormErrorMessage>
              This question field is required.
            </FormErrorMessage>
          )}
        </FormControl>
      )}
    </VStack>
  );
};
