import { Divider, Grid, GridItem } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import { ApiLaborTransaction } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import React from "react";
import { IoTrashSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { OutlinedIconButton } from "../../../../components/custom-icons/OutlinedIconButton";
import { RootState } from "../../../../store";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import {
  getFormatedHour,
  TRANSACTION_DATE_FORMAT,
} from "../transactions-helper";
import { LaborTransactionsTableProps } from "./LaborTransactionsTable";

export const MobileLaborTransactionTable = ({
  canEdit,
  canDelete,
  onDelete,
  onEdit,
}: LaborTransactionsTableProps) => {
  const laborsData = useSelector(
    (state: RootState) =>
      state.requestForm.transactions.labors.laborsData as ApiLaborTransaction[]
  );

  return (
    <Grid templateColumns={["repeat(2, 2fr)"]} gap={2}>
      {laborsData.map((transaction) => (
        <React.Fragment key={transaction.id}>
          <GridItem colSpan={1}>
            <UserBadge value={transaction.laborer} />
          </GridItem>
          <GridItem colSpan={1} fontWeight="700" textAlign="right">
            {format(
              new Date(transaction.datePerformed),
              TRANSACTION_DATE_FORMAT
            )}
          </GridItem>
          <GridItem colSpan={1}>
            {capitalizeFirstLetter(transaction.laborType)}
          </GridItem>
          <GridItem colSpan={1} textAlign="right">
            {getFormatedHour(transaction.hours)}
          </GridItem>
          {(canEdit(transaction) || canDelete(transaction)) && (
            <GridItem
              colSpan={2}
              textAlign="right"
              pt={2}
              display="flex"
              flexDirection="row"
              justifyContent="right"
              gap="2"
            >
              {canDelete(transaction) && (
                <OutlinedIconButton
                  icon={<Icon as={IoTrashSharp} boxSize={5} />}
                  onClick={() => onDelete(transaction)}
                />
              )}
              {canEdit(transaction) && (
                <OutlinedIconButton
                  icon={<Icon as={MdEdit} boxSize={5} />}
                  onClick={() => onEdit(transaction)}
                />
              )}
            </GridItem>
          )}
          <GridItem colSpan={2} mt={4} mb={4}>
            <Divider />
          </GridItem>
        </React.Fragment>
      ))}
    </Grid>
  );
};
