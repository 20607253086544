import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ApiScheduledRequest } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { DateBadge } from "../../../components/badges/DateBadge";

interface ScheduleModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  scheduledRequest: ApiScheduledRequest | null;
}

export const ModalScheduleDate: FC<ScheduleModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  scheduledRequest,
}) => {
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Next Runs</Text>
        </ModalHeader>
        <ModalBody>
          <Text mt={-2} mb={2}>
            Future requests for the next 6 months
          </Text>
          {scheduledRequest?.schedules[0].nextRuns.map((runDate, key) => (
            <Box key={runDate + key} flex={1} w="100%">
              {new Date(runDate) > new Date() && <DateBadge date={runDate} />}
            </Box>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
