import {
  ApiClient,
  ApiReport,
  FindReportsOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoadReportsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters: FindReportsOptions;
}

export interface GetReportThunkParams {
  apiClient: ApiClient;
  accountId: string;
  reportId: string;
}

export const loadProductReports = createAsyncThunk(
  "reports/load",
  async (params: LoadReportsThunkParams, thunkAPI) => {
    const { apiClient, accountId, filters } = params;
    const response = await apiClient.findAccountReports(accountId, filters);
    return response;
  }
);

export const getProductReport = createAsyncThunk(
  "reports/loadOne",
  async (params: GetReportThunkParams, thunkAPI) => {
    const { apiClient, accountId, reportId } = params;
    const response = await apiClient.getAccountReport(accountId, reportId);
    return response;
  }
);

export type ReportsSliceState = {
  currentProduct: string;
  currentProductReports: ApiReport[];
  currentReport?: ApiReport;
};
export const reportsSlice = createSlice({
  name: "reportsSlice",
  initialState: {
    currentProduct: "HeroHQ",
    currentProductReports: [],
    currentReport: undefined,
  } as ReportsSliceState,
  reducers: {
    setCurrentProduct: (state, action: PayloadAction<string>) => {
      state.currentProduct = action.payload;
    },
    unloadReports: (state) => {
      state.currentProductReports = [];
    },

    setCurrentReport: (state, action: PayloadAction<ApiReport | undefined>) => {
      state.currentReport = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadProductReports.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.currentProductReports = data;
    });
    builder.addCase(getProductReport.fulfilled, (state, action) => {
      const report = action.payload;
      state.currentReport = report;
    });
  },
});

export const { setCurrentProduct, unloadReports, setCurrentReport } =
  reportsSlice.actions;
export default reportsSlice.reducer;
