import { ApiRequest, ApiRequestStatus } from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { generateTransition } from "../request-form/request-form.helpers";
import { TransitionMap } from "./types";
type LoadTransitionMapParams = {
  requests: ApiRequest[];
};
export const loadTransitionMap = createAsyncThunk<
  {
    transitionMap: TransitionMap;
  },
  LoadTransitionMapParams
>("requests/transitions/load", ({ requests }, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { workflowMap, policyMap, descendantsMap, categoriesMap } =
    state.localCache;
  const { isProductAdmin } = state.auth;
  const transitionMap = requests.reduce<TransitionMap>((map, request) => {
    const w = workflowMap[request.workflow.id];
    const p = policyMap[request.workflow.id];
    if (!w || !p) return map;
    const transition = generateTransition(
      w,
      p,
      request,
      isProductAdmin,
      descendantsMap,
      categoriesMap
    );
    const requestTransitions: ApiRequestStatus[] = [];
    if (transition?.next) requestTransitions.push(transition.next);
    if (transition?.backwards && transition.backwards.length > 0) {
      transition.backwards.forEach((status) => {
        requestTransitions.push(status);
      });
    }

    if (transition?.forwards && transition.forwards.length > 0) {
      transition.forwards.forEach((status) => {
        requestTransitions.push(status);
      });
    }
    if (
      request?.status &&
      request.status !== ApiRequestStatus.hold &&
      request.status !== ApiRequestStatus.canceled
    ) {
      requestTransitions.push(ApiRequestStatus.hold);
    }

    if (transition?.canCancel) {
      requestTransitions.push(ApiRequestStatus.canceled);
    }
    map[request.id] = {
      allowedStatuses: requestTransitions,
      transition,
    };
    return map;
  }, {});
  return { transitionMap };
});
