import { Box, Text } from "@chakra-ui/react";
import {
  ApiRequestStatus,
  RequestSettingsKeys,
} from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { AutoSavingSwitch } from "../../components/inputs/AutoSavingSwitch";
import { useProductSubscriptions } from "../../components/shell/AppShell";
import { useShowToast } from "../../hooks/showToast";
import { RequestStatusFilter } from "../../pages/requests/filters/RequestStatusFilter";
import {
  getUserOrAccountSettingDefault,
  SettingProps,
  Statuses,
  StatusRequestEmail,
} from "../../utils/emailSettingUtils";

export const HeroHQEmailSettings = ({
  update,
  accountSettings,
  userSettings,
  label,
}: SettingProps) => {
  const { hasRequests } = useProductSubscriptions();
  const showToast = useShowToast();
  const { currentUser: user, currentAccount } = useAuthentication();
  const [assigneeEmails, setAssigneeEmails] = useState(false);
  const [mentionRequestEmails, setMentionRequestEmails] = useState(false);
  const [allowRequestApprover, setAllowRequestApprover] = useState(false);
  const [allowRequestReviewer, setAllowRequestReviewer] = useState(false);
  const [allowAssignedComments, setAllowAssignedComments] = useState(false);

  const [requestStatusUpdated, setRequestStatusUpdated] = useState<
    StatusRequestEmail[]
  >([]);

  const handleSettingSaveSelect = useCallback(
    (value: ApiRequestStatus[]) => {
      const values = value as unknown as Statuses[];
      const statusToChange = [...requestStatusUpdated];

      statusToChange.map((selected) =>
        values.some((val) => val === selected.status)
          ? (selected.isChecked = true)
          : (selected.isChecked = false),
      );
      setRequestStatusUpdated(statusToChange);

      let uniqueMap: Record<string, boolean> = {};
      uniqueMap = statusToChange.reduce<Record<string, boolean>>(
        (result, id) => {
          result[id.status] = id.isChecked;
          return result;
        },
        {},
      );

      update(currentAccount.id, {
        [RequestSettingsKeys.NOTIFICATIONS_REQUEST_STATUS_UPDATED_EMAILS]:
          uniqueMap,
      })
        .then((saved) => {
          showToast("success", `Update notification setting`);
          const statusUpdated =
            saved[
              RequestSettingsKeys.NOTIFICATIONS_REQUEST_STATUS_UPDATED_EMAILS
            ];
          const res = Object.keys(statusUpdated).map((status) => {
            return {
              status,
              isChecked:
                //@ts-ignore
                statusUpdated[status],
            } as StatusRequestEmail;
          });
          setRequestStatusUpdated(res);
        })
        .catch(() => showToast("error", "Error updating notification setting"));
    },
    [requestStatusUpdated, currentAccount.id, showToast, update],
  );

  const handleSettingSave = useCallback(
    (key: string) => (value: boolean) => {
      return update(currentAccount.id, { [key]: value })
        .then((saved) => {
          showToast("success", `Update notification setting`);

          if (key === RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_EMAILS) {
            setAssigneeEmails(
              saved[
                RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_EMAILS
              ] as boolean,
            );
          }

          if (key === RequestSettingsKeys.ALLOW_REQUEST_MENTION_EMAILS) {
            setMentionRequestEmails(
              saved[
                RequestSettingsKeys.ALLOW_REQUEST_MENTION_EMAILS
              ] as boolean,
            );
          }

          if (
            key === RequestSettingsKeys.ALLOW_REQUEST_APPROVER_REQUIRED_EMAILS
          ) {
            setAllowRequestApprover(
              saved[
                RequestSettingsKeys.ALLOW_REQUEST_APPROVER_REQUIRED_EMAILS
              ] as boolean,
            );
          }

          if (
            key === RequestSettingsKeys.ALLOW_REQUEST_REVIEWER_REQUIRED_EMAILS
          ) {
            setAllowRequestReviewer(
              saved[
                RequestSettingsKeys.ALLOW_REQUEST_REVIEWER_REQUIRED_EMAILS
              ] as boolean,
            );
          }
          if (
            key === RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_COMMENT_EMAILS
          ) {
            setAllowAssignedComments(
              saved[
                RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_COMMENT_EMAILS
              ] as boolean,
            );
          }
        })
        .catch(() => {
          showToast("error", "Error updating notification setting");
        });
    },
    [currentAccount, showToast, update],
  );
  useEffect(() => {
    setAssigneeEmails(
      getUserOrAccountSettingDefault(
        accountSettings,
        RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_EMAILS,
        userSettings,
      ),
    );
    setMentionRequestEmails(
      getUserOrAccountSettingDefault(
        accountSettings,
        RequestSettingsKeys.ALLOW_REQUEST_MENTION_EMAILS,
        userSettings,
      ),
    );

    setAllowRequestApprover(
      getUserOrAccountSettingDefault(
        accountSettings,
        RequestSettingsKeys.ALLOW_REQUEST_APPROVER_REQUIRED_EMAILS,
        userSettings,
      ),
    );

    setAllowRequestReviewer(
      getUserOrAccountSettingDefault(
        accountSettings,
        RequestSettingsKeys.ALLOW_REQUEST_REVIEWER_REQUIRED_EMAILS,
        userSettings,
      ),
    );
    setAllowAssignedComments(
      getUserOrAccountSettingDefault(
        accountSettings,
        RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_COMMENT_EMAILS,
        userSettings,
      ),
    );

    let settingStatus = getUserOrAccountSettingDefault(
      accountSettings,
      RequestSettingsKeys.NOTIFICATIONS_REQUEST_STATUS_UPDATED_EMAILS,
      userSettings,
    );
    settingStatus = settingStatus === true ? false : settingStatus;

    const allStatus = !settingStatus
      ? Object.keys(Statuses).map((status) => {
          return {
            status,
            isChecked: true,
          } as StatusRequestEmail;
        })
      : Object.keys(Statuses).map((status) => {
          return {
            status,
            isChecked:
              //@ts-ignore
              settingStatus[status] === true,
          } as StatusRequestEmail;
        });
    setRequestStatusUpdated(allStatus);
  }, [user, currentAccount, accountSettings, userSettings]);
  return (
    <>
      {hasRequests && (
        <>
          <Text w="100%" textAlign="left" size="lg" fontWeight="bold">
            {label ? label : "HeroHQ Settings"}
          </Text>
          <Box mt={7} width="100%">
            <AutoSavingSwitch
              id="allow-request-assgined-to-emails"
              isChecked={assigneeEmails}
              label="Email me when a request has been assigned"
              onSave={handleSettingSave(
                RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_EMAILS,
              )}
            />
          </Box>
          <Box mt={7} width="100%">
            <AutoSavingSwitch
              id="allow-request-mention-emails"
              isChecked={mentionRequestEmails}
              label="Email me when I am mentioned in a request"
              onSave={handleSettingSave(
                RequestSettingsKeys.ALLOW_REQUEST_MENTION_EMAILS,
              )}
            />
          </Box>

          <Box mt={7} width="100%">
            <AutoSavingSwitch
              id="allow-request-approver-required-emails"
              isChecked={allowRequestApprover}
              label="Email me when a request needs my approval"
              onSave={handleSettingSave(
                RequestSettingsKeys.ALLOW_REQUEST_APPROVER_REQUIRED_EMAILS,
              )}
            />
          </Box>

          <Box mt={7} width="100%">
            <AutoSavingSwitch
              id="allow-request-reviewer-required-emails"
              isChecked={allowRequestReviewer}
              label="Email me when an issue is ready for me to review"
              onSave={handleSettingSave(
                RequestSettingsKeys.ALLOW_REQUEST_REVIEWER_REQUIRED_EMAILS,
              )}
            />
          </Box>

          <Box mt={7} width="100%">
            <AutoSavingSwitch
              id="allow-request-assgined-to-comment-emails"
              isChecked={allowAssignedComments}
              label="Email me comments when I'm assigned"
              onSave={handleSettingSave(
                RequestSettingsKeys.ALLOW_REQUEST_ASSIGNED_TO_COMMENT_EMAILS,
              )}
            />
          </Box>
          <Box display="flex" width="100%" alignItems={"center"} pt={4}>
            <Text as="span" w="100%">
              Email me when my requests change to one of these statuses:
              <Box maxW="500px">
                <RequestStatusFilter
                  value={requestStatusUpdated
                    .filter((val) => val.isChecked)
                    .map((status) => status.status)}
                  onBlur={handleSettingSaveSelect}
                  omitNewStatus={true}
                  showSelectAll={true}
                />
              </Box>
            </Text>
          </Box>
        </>
      )}
    </>
  );
};
