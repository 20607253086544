export type ObjectWithId = {
  id: string;
};

export function buildMap<T extends ObjectWithId>(objects: T[]) {
  return objects.reduce<{
    [key: string]: T;
  }>((result, object) => {
    result[object.id] = object;
    return result;
  }, {});
}

export function uniqueValues<T extends ObjectWithId>(objects: T[]) {
  return Object.values(buildMap(objects));
}
