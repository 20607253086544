import { Flex, Text } from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomNumberInput } from "../../../components/inputs/CustomNumberInput";
import {
  InventoryItem,
  setItemPrice,
} from "../../../store/inventory/inventory-order-item-list.slice";

interface PopoverUnitCostItemOrderProps {
  item: InventoryItem;
}

export const PopoverUnitCostItemOrder: FC<PopoverUnitCostItemOrderProps> = ({
  item,
}) => {
  const dispatch = useDispatch();
  const [workingItem, setWorkingItem] = useState<
    InventoryItem & { costAsString: string }
  >({ ...item, costAsString: item.cost.toString() });

  const handlePriceChange = useCallback(
    (id: string, value: string) => {
      setWorkingItem({
        ...workingItem,
        cost: Number(value),
        costAsString: value,
      });
      dispatch(setItemPrice({ id, unitCost: Number(value) }));
    },
    [dispatch, workingItem]
  );

  return (
    <Flex gap={2} justifyContent="center" alignItems="center">
      <Text fontWeight="bold">Unit Cost</Text>
      <CustomNumberInput
        min={0}
        prefix="$"
        precision={4}
        onChange={(value) => handlePriceChange(item.id, value.valueAsString)}
        value={{
          valueAsNumber: workingItem.cost,
          valueAsString: workingItem.costAsString,
        }}
        numberInputFieldProps={{
          width: "100px",
          paddingRight: "1rem",
        }}
        inputGroupProps={{ width: "fit-content" }}
      />
    </Flex>
  );
};
