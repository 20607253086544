import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./components/auth/AuthProvider";
import AppShell from "./components/shell/AppShell";
import { AppShellStylingProvider } from "./pages/requests/column-view/AppShellStylingProvider";
import "./pages/requests/filters/filters.css";
import store from "./store";
import appTheme from "./theme/AppTheme";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <ChakraProvider theme={appTheme}>
            <AppShellStylingProvider>
              <AppShell />
            </AppShellStylingProvider>
          </ChakraProvider>
        </AuthProvider>
      </Router>
    </Provider>
  );
}

export default App;
