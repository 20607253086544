import {
  ApiCatalogReference,
  ApiInventoryRequestsQueryFilter,
  ApiInventoryRequestStatus,
} from "@operations-hero/lib-api-client";

export interface InventoryRequestFiltersValues {
  quickFilter?: ApiInventoryRequestsQueryFilter;
  search: string;
  page: number;
  pageSize: number;
  requester: string[];
  created: string[];
  status: ApiInventoryRequestStatus[];
  deliveryLocation: string[];
  request: string | string[];
  catalog: ApiCatalogReference | ApiCatalogReference[];
  sort?: string;
  direction?: "asc" | "desc";
}

export const inventoryRequestDefaultFilters: Partial<InventoryRequestFiltersValues> =
  {
    quickFilter: ApiInventoryRequestsQueryFilter.MY_REQUESTS,
    page: 1,
    search: "",
    pageSize: 50,
    created: [],
    status: [],
    requester: [],
    deliveryLocation: [],
    catalog: [],
    direction: "desc",
    sort: "number",
  };
