import { ApiAccount, ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface AddRequestAssetThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  idOrKey: string;
  assetId: string;
}

export const addAsset = createAsyncThunk(
  "requests/addAsset",
  async (params: AddRequestAssetThunkParams, thunkAPI) => {
    const { apiClient, account, idOrKey, assetId } = params;

    return await apiClient.createRequestAsset(account.id, idOrKey, { assetId });
  }
);
export const addAssetHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(addAsset.fulfilled, (state, action) => {
    state.assets.data.unshift(action.payload);
    state.assets.total += 1;
    state.assetsTotal = state.assets.total;
  });
};
