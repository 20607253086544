import { Flex, useColorModeValue } from "@chakra-ui/react";
import { ApiInventoryOrderStatus } from "@operations-hero/lib-api-client";
import { FC, useCallback, useRef } from "react";
import { AccountSearchBox } from "../../../../components/inputs/SearchBox";
import { checkArray } from "../../../../utils/compareObjects";
import { getId } from "../../../../utils/getId";
import { VendorSupplierFilter } from "../../filters/SupplierFilter";

import { InventoryOrderStatusFilter } from "../filters/InventoryOrderStatusFilter";
import { InventoryOrderFiltersValues } from "./inventoryOrderDefault";

interface InventoryPlacedOrdersFiltersProps {
  filters: Partial<InventoryOrderFiltersValues>;
  updateFilters: (values: Partial<InventoryOrderFiltersValues>) => void;
}

export const InventoryPlacedOrdersFilters: FC<
  InventoryPlacedOrdersFiltersProps
> = ({ filters, updateFilters }) => {
  const bgColor = useColorModeValue("blue.50", "blue.900");

  const searchRef = useRef<HTMLInputElement | null>(null);

  const handleOnChangeStatus = useCallback(
    (values: ApiInventoryOrderStatus[]) => {
      updateFilters({ page: 1, status: values });
    },
    [updateFilters]
  );

  const handleOnChangeSupplier = useCallback(
    (values: string[]) => {
      const newValues = checkArray<string[]>(values);
      updateFilters({ supplier: newValues });
    },
    [updateFilters]
  );

  const handleOnChangeSearch = useCallback(
    (value: string) => {
      updateFilters({ page: 1, search: value });
    },
    [updateFilters]
  );

  return (
    <Flex
      bgColor={bgColor}
      minH="20px"
      alignItems="center"
      py={2}
      px={4}
      gap={4}
    >
      <Flex
        w="100%"
        display={["none", "none", "flex"]}
        justifyContent="space-between"
      >
        <Flex gap={4}>
          <VendorSupplierFilter
            values={
              filters.supplier
                ? Array.isArray(filters.supplier)
                  ? filters.supplier.map((sup) => getId(sup))
                  : [getId(filters.supplier)]
                : []
            }
            onChange={handleOnChangeSupplier}
            title="All Suppliers"
          />
          <InventoryOrderStatusFilter
            onChange={handleOnChangeStatus}
            value={
              Array.isArray(filters.status)
                ? filters.status
                : filters.status
                  ? [filters.status]
                  : []
            }
          />
        </Flex>

        <Flex gap={2}>
          <AccountSearchBox
            defaultValue={filters.search}
            onInputChange={handleOnChangeSearch}
            rest={{ minW: "320px" }}
            searchPlaceholder="Search by key, PO # or Invoice #"
            ref={searchRef}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
