import {
  ApiClient,
  ApiComment,
  ApiPagedResult,
  ApiPagingOptions,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  DEFAULT_COMMENTS_PAGE_SIZE,
  DEFAULT_COMMENTS_PAGE_START,
} from "../defaults";
import { LoadingStatus } from "../project-list";

type GetCommentsParams = {
  apiClient: ApiClient;
  projectId: string;
  options?: ApiPagingOptions;
};

const defaultOptions: ApiPagingOptions = {
  page: DEFAULT_COMMENTS_PAGE_START,
  pageSize: DEFAULT_COMMENTS_PAGE_SIZE,
};

export const findComments = createAsyncThunk<
  ApiPagedResult<ApiComment>,
  GetCommentsParams
>(
  "project-comments/find",
  async ({ apiClient, projectId, options }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState;
    const { currentAccount } = store.auth;
    const response = await apiClient.findProjectComments(
      currentAccount.id,
      projectId,
      options ?? defaultOptions
    );

    return response;
  }
);

export const updateOptions = createAsyncThunk<
  ApiPagingOptions,
  {
    current: Partial<ApiPagingOptions>;
    delta: Partial<ApiPagingOptions>;
  }
>("project-comments/filters/update", async ({ current, delta }, thunkAPI) => {
  return {
    ...current,
    ...delta,
  };
});

export type CommentsHandlerProps = {
  comments: ApiComment[];
  commentsLoadingStatus: LoadingStatus;
  commentsOptions: ApiPagingOptions;
  commentsTotal: number;
};

export const findCommentsHandlers = <T extends CommentsHandlerProps>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(findComments.pending, (state, action) => {
    state.commentsLoadingStatus = "pending";
  });
  builder.addCase(findComments.rejected, (state, action) => {
    state.commentsLoadingStatus = "rejected";
  });
  builder.addCase(findComments.fulfilled, (state, action) => {
    const { data, total } = action.payload;
    state.comments = data;
    state.commentsTotal = total;
    state.commentsLoadingStatus = "fulfilled";
  });
  builder.addCase(updateOptions.fulfilled, (state, action) => {
    state.commentsOptions = action.payload;
  });
};
