import { Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const TransactionDisplayCount = () => {
  const { totalTransactions, transactions, filters } = useSelector(
    (state: RootState) => state.transactionList
  );
  const { currentPage, pageSize } = filters;
  const pageStart = useMemo(() => {
    return (currentPage - 1) * pageSize;
  }, [currentPage, pageSize]);

  const showingTotal = useMemo(() => {
    return pageStart + transactions.length;
  }, [pageStart, transactions]);

  return totalTransactions > 0 ? (
    <Text>
      Showing {pageStart + 1}-{showingTotal} of {totalTransactions}
    </Text>
  ) : null;
};
