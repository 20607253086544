import {
  Box,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

export const FallbackSpinner = () => {
  const textColor = useColorModeValue("gray.600", "white.600");
  return (
    <Box justifyContent="center" display="flex" pt={6} w="100%">
      <VStack>
        <Spinner
          size="xl"
          thickness="4px"
          emptyColor="gray.200"
          color="blue.600"
        />
        <Text color={textColor}>Loading...</Text>
      </VStack>
    </Box>
  );
};
