import {
  ApiAccount,
  ApiClient,
  ApiUserGroup,
  ApiUserGroupOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface LoadUserGroupsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
}

export const loadUserGroups = createAsyncThunk(
  "user-groups/load-groups",
  async ({ apiClient, account }: LoadUserGroupsThunkParams, thunkAPI) => {
    const { filters } = (thunkAPI.getState() as RootState).userGroups;
    const pagedUsers = await apiClient.findUserGroups(account.id, filters);

    return pagedUsers;
  }
);

export interface RequiredUserGroupOptions
  extends Required<Omit<ApiUserGroupOptions, "direction" | "sort">> {}

export interface UserGroupSliceState {
  groups: ApiUserGroup[];
  groupsTotal: number;
  filters: RequiredUserGroupOptions;
}
const DEFAULT_USER_GROUPS_FILTERS: RequiredUserGroupOptions = {
  page: 1,
  search: "",
  pageSize: 20,
  includeInactive: false,
};

export const userGroupsSlice = createSlice({
  name: "user-groups",
  initialState: {
    groups: [],
    groupsTotal: 0,
    filters: DEFAULT_USER_GROUPS_FILTERS,
  } as UserGroupSliceState,
  reducers: {
    unload: (state) => {
      state.groups = [];
      state.groupsTotal = 0;
      state.filters = {
        ...DEFAULT_USER_GROUPS_FILTERS,
        page: state.filters.page,
      };
    },
    updateFilters: (
      state: UserGroupSliceState,
      action: PayloadAction<ApiUserGroupOptions>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserGroups.fulfilled, (state, action) => {
      const { total, data, options } = action.payload;
      state.groupsTotal = total;
      state.filters.page = options.page || 20;
      state.groups = data;
    });
  },
});

export const { unload, updateFilters } = userGroupsSlice.actions;

export default userGroupsSlice.reducer;
