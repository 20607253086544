import { Box, Icon, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoStar, IoStarOutline } from "react-icons/io5";

export interface RatingProps {
  scale: number;
  score: number;
  onClick: (score: number) => void;
  disabled?: boolean;
  size?: number;
  fillColor?: string;
  strokeColor?: string;
  hoverFillColor?: string;
  hoverStrokeColor?: string;
  blockClickAction?: boolean;
}

export const Rating: React.FC<RatingProps> = (props) => {
  const {
    scale,
    score,
    onClick,
    disabled,
    size,
    fillColor,
    strokeColor,
    hoverFillColor,
    hoverStrokeColor,
    blockClickAction,
  } = props;
  const [rating, setRating] = useState(score);

  useEffect(() => {
    setRating(score);
  }, [score]);

  const handleOnClick = (idx: number) => {
    if (!isNaN(idx)) {
      // allow to click first icon and set rating to zero if rating is already 1
      if (rating === 1 && idx === 1) {
        !blockClickAction && setRating(0);
        onClick(0);
      } else {
        !blockClickAction && setRating(idx);
        onClick(idx);
      }
    }
  };

  const RatingIcon = ({ fill }: { fill: boolean }) => {
    return (
      <Icon
        as={fill ? IoStar : IoStarOutline}
        boxSize={`${size}px`}
        color={fillColor}
        stroke={strokeColor}
        _hover={{
          color: hoverFillColor,
          stroke: hoverStrokeColor,
        }}
      />
    );
  };

  const RatingButton = ({ idx, fill }: { idx: number; fill: boolean }) => {
    return (
      <Box
        as="button"
        aria-label={`Rate ${idx}`}
        height={`${size}px`}
        width={`${size}px`}
        onClick={() => handleOnClick(idx)}
        disabled={disabled}
      >
        <RatingIcon fill={fill} />
      </Box>
    );
  };

  return (
    <Stack isInline justify="center">
      {new Array(scale).fill(1).map((_, idx) => (
        <RatingButton key={idx + 1} idx={idx + 1} fill={idx + 1 <= rating} />
      ))}
    </Stack>
  );
};

Rating.displayName = "Rating";
Rating.defaultProps = {
  size: 32,
  fillColor: "orange.400",
  strokeColor: "grey",
  hoverFillColor: "orange.500",
  hoverStrokeColor: "#707070",
};
