import {
  ApiClient,
  ApiRateGroup,
  CreateApiRateGroup,
  FindRateGroupOptions,
  UpdateApiRateGroup,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface CreateGroupRateParams {
  apiClient: ApiClient;
  accountId: string;
  groupRate: CreateApiRateGroup;
}

export const createGroupRate = createAsyncThunk(
  "group/new/group-rate",
  async (params: CreateGroupRateParams, ThunkAPI) => {
    const { apiClient, accountId, groupRate } = params;
    const response = apiClient.createRateGroup(accountId, groupRate);
    return response;
  }
);

interface UpdateGroupRateParams {
  apiClient: ApiClient;
  accountId: string;
  groupRateId: string;
  groupRate: UpdateApiRateGroup;
}

export const updateGroupRate = createAsyncThunk(
  "group/edit/group-rate",
  async (params: UpdateGroupRateParams, ThunkAPI) => {
    const { apiClient, accountId, groupRateId, groupRate } = params;
    const response = apiClient.updateRateGroup(
      accountId,
      groupRateId,
      groupRate
    );
    return response;
  }
);

interface LoadGroupRateParams {
  apiClient: ApiClient;
  accountId: string;
}

export const loadGroupRates = createAsyncThunk(
  "group/groups-rate",
  async (params: LoadGroupRateParams, ThunkAPI) => {
    const { apiClient, accountId } = params;
    const filters = (ThunkAPI.getState() as RootState).groupRatesSlice.filters;
    const response = apiClient.findRateGroups(accountId, { ...filters });
    return response;
  }
);

export interface GroupRatesSliceProps {
  total: number;
  groupRates: ApiRateGroup[];
  filters: FindRateGroupOptions;
  workingGroupRate?: ApiRateGroup;
}

interface DeactivateReactivateParams {
  accountId: string;
  apiClient: ApiClient;
  groupRate: ApiRateGroup;
  type: "deactivate" | "reactivate";
}

export const deactivateReactivateGroupRate = createAsyncThunk(
  "group/toggle-group-rate",
  async (params: DeactivateReactivateParams, ThunkAPI) => {
    const { type, apiClient, accountId, groupRate } = params;
    if (type === "deactivate") {
      apiClient.deactivateRateGroup(accountId, groupRate.id);
    } else {
      apiClient.reactivateRateGroup(accountId, groupRate.id);
    }

    return { groupRate, type };
  }
);

const INITIAL_FILTERS: FindRateGroupOptions = {
  page: 1,
  search: "",
  pageSize: 20,
  isPublic: undefined,
  isTaxable: undefined,
  includeInactive: undefined,
};

const INITIAL_VALUES: GroupRatesSliceProps = {
  total: 0,
  filters: INITIAL_FILTERS,
  workingGroupRate: undefined,
  groupRates: [] as ApiRateGroup[],
};

export const groupRatesSlice = createSlice({
  name: "group-rate",
  initialState: INITIAL_VALUES,
  reducers: {
    unloadGroupRatesSlice: (state) => {
      state = { ...INITIAL_VALUES };
    },
    setWorkingGroupRate: (
      state: GroupRatesSliceProps,
      action: PayloadAction<ApiRateGroup | undefined>
    ) => {
      state.workingGroupRate = action.payload;
    },
    setRateGroupFilter: (
      state: GroupRatesSliceProps,
      action: PayloadAction<FindRateGroupOptions>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createGroupRate.fulfilled, (state, action) => {
      state.groupRates.unshift(action.payload);
      state.total = state.total + 1;
    });

    builder.addCase(updateGroupRate.fulfilled, (state, action) => {
      const index = state.groupRates.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.groupRates[index] = action.payload;
      }
    });

    builder.addCase(loadGroupRates.fulfilled, (state, action) => {
      state.groupRates = action.payload.data;
      state.total = action.payload.total;
    });

    builder.addCase(
      deactivateReactivateGroupRate.fulfilled,
      (state, action) => {
        const index = state.groupRates.findIndex(
          (item) => item.id === action.payload.groupRate.id
        );

        if (index === -1) return;

        if (action.payload.type === "deactivate") {
          if (!state.filters.includeInactive) {
            state.groupRates.splice(index, 1);
          } else {
            state.groupRates[index] = {
              ...action.payload.groupRate,
              active: false,
            };
          }
        }

        if (action.payload.type === "reactivate") {
          state.groupRates[index] = {
            ...action.payload.groupRate,
            active: true,
          };
        }
      }
    );
  },
});

export const {
  setRateGroupFilter,
  setWorkingGroupRate,
  unloadGroupRatesSlice,
} = groupRatesSlice.actions;

export default groupRatesSlice.reducer;
