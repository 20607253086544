import {
  Box,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  ApiParsedAsset,
  ApiVendor,
  ImageParseConfidence,
} from "@operations-hero/lib-api-client";
import axios, { AxiosProgressEvent } from "axios";
import ExifReader from "exifreader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FiInfo } from "react-icons/fi";
import { Attachment } from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { SparkleWithAnimation } from "../../../components/icons/Sparkle";
import { useShowToast } from "../../../hooks/showToast";

import { MdPushPin } from "react-icons/md";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { HIDE_AI_ASSET_MODAL } from "../../../utils/emailSettingUtils";
import AiCreateAssetIntro from "./ai-create-modal-steps/AiCreateAssetIntro";
import AiLowConfidenceWarning from "./ai-create-modal-steps/AiLowConfidenceWarning";
import { AiNampleplatePhotoCapture } from "./ai-create-modal-steps/AiNampleplatePhotoCapture";
import AiProcessingAnimation from "./ai-create-modal-steps/AiProcessingAnimation";
import VerifyAssetSection from "./ai-create-modal-steps/VerifyAssetSection";
import { useParams } from "react-router-dom";

export interface CreateByPictureModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreatePictureModal = ({
  isOpen,
  onClose,
}: CreateByPictureModalProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { assetId } = useParams<{ assetId?: string }>();
  const showToast = useShowToast();

  const [displayUrl, setDisplayUrl] = useState("");
  const [stage, setStage] = useState("instructions");

  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");
  const webcamRef = React.useRef(null);
  const [isNameplateModalOpen, setIsNameplateDataModalOpen] = useState(false);
  const openNameplateDataModal = () => setIsNameplateDataModalOpen(true);
  const closeNameplateDataModal = () => setIsNameplateDataModalOpen(false);
  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );
  const [isSticky, setIsSticky] = useState(false);
  const { apiAsset } = useSelector((state: RootState) => state.aiAssetSlice);
  const { userSettings } = useSelector((state: RootState) => state.localCache);
  const aiSetting = userSettings[HIDE_AI_ASSET_MODAL] ?? false;
  const [apiParsedAsset, setApiParsedAsset] = useState<ApiParsedAsset>();

  const [manufacturer, setManufacturer] = useState<ApiVendor | null>(null);
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
  });

  const handleAddAttachment = useCallback(
    (files: Attachment[]) => {
      setStage("loading");
      let allAttachments = [...workingAttachments];

      // todo: only handle 1 file at a time
      files.forEach(async (item) => {
        const response = await apiClient.createUpload(currentAccount.id);
        const newAttachment: Attachment = {
          ...item,
          isNew: true,
          isUploading: true,
          progress: 0,
          uploadId: response.id,
          scannedByAi: true,
        };
        allAttachments.push(newAttachment);

        setWorkingAttachments(allAttachments);

        await axios.put(response.url, newAttachment.file, {
          headers: { "Content-type": newAttachment.file?.type },
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            newAttachment.progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );

            setWorkingAttachments([...allAttachments]);
          },
        });

        newAttachment.progress = undefined;
        newAttachment.isUploading = false;
        setWorkingAttachments([...allAttachments]);
      });
    },
    [apiClient, currentAccount, workingAttachments]
  );

  const handlePinClick = useCallback(() => {
    setIsSticky(!isSticky);
  }, [isSticky]);

  const videoConstraints = useMemo(
    () => ({
      facingMode: "environment",
    }),
    []
  );

  const captureNameplate = useCallback(() => {
    const imageSrc = (webcamRef.current as any)?.getScreenshot();
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "captured-image.jpg", {
            type: "image/jpeg",
          });
          const attachment: Attachment = {
            file: file,
            created: new Date().toISOString(),
            url: null,
            name: "",
            type: "",
          };
          handleAddAttachment([attachment]);
        });
    }
  }, [webcamRef, handleAddAttachment]);

  const isImageBlob = useMemo(
    () =>
      workingAttachments.length > 0 &&
      workingAttachments[0].file &&
      workingAttachments[0].url == null,
    [workingAttachments]
  );

  // this has to be an effect so it can revoke the object url
  useEffect(() => {
    const file = workingAttachments[0]?.file;
    if (!file || !isImageBlob) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setDisplayUrl(objectUrl);
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [isImageBlob, workingAttachments]);

  // Parse the uploaded image and set the metadata for confirmation
  useEffect(() => {
    const image = workingAttachments[0];

    if (!image || !image.uploadId || image.isUploading || !image.file) {
      return;
    }

    new Promise<void>(async (resolve) => {
      if (!image || !image.file || !image.uploadId) {
        return resolve();
      }

      const latlong = { longitude: "", latitude: "" };
      try {
        const tags = await ExifReader.load(image.file);
        latlong.longitude = tags["GPSLongitude"]
          ? tags["GPSLongitude"].description + ""
          : "";
        latlong.latitude = tags["GPSLatitude"]
          ? tags["GPSLatitude"].description + ""
          : "";
        setLong(latlong.longitude);
        setLat(latlong.latitude);
      } catch (error: any) {
        console.error(
          "Error with EXIF data: " + (error?.message || "Unknown error")
        );
      }

      try {
        const parsed = await apiClient.parseAssetNameplate(currentAccount.id, {
          uploadId: image.uploadId!,
          location: latlong,
        });
        const asset = JSON.parse(
          //@ts-ignore
          parsed?.response?.candidates[0]?.content?.parts[0]?.text || "{}"
        ) as ApiParsedAsset;

        if (asset?.manufacturer?.value) {
          const searchResults = await apiClient.findVendors(currentAccount.id, {
            isManufacturer: true,
            search: asset.manufacturer.value,
          });
          if (searchResults.data.length > 0) {
            setManufacturer(searchResults.data[0]);
          } else {
            asset.manufacturer.warning = "Please confirm the manufacturer";
            asset.manufacturer.certainty =
              ImageParseConfidence.SomewhatDoubtful;
          }
        }
        setApiParsedAsset(asset);
        if (asset?.imageQuality?.retakePic) {
          setStage("continue");
          return;
        }
        setStage("parsed");
      } catch (error: any) {
        showToast(
          "error",
          "Error with parsing image: " + (error?.message || "Unknown error")
        );
        onClose();
      }
    });
  }, [workingAttachments, apiClient, currentAccount, showToast, onClose]);

  useEffect(() => {
    if (aiSetting) {
      setStage("initial");
    }
  }, [aiSetting]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered={true}
      closeOnOverlayClick={false}
      closeOnEsc={true}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        minH={isMobile ? "100%" : "50%"}
        minW={isMobile ? "100%" : "4xl"}
      >
        <ModalHeader fontSize={"1em"} pb={1}>
          <HStack alignItems="center">
            {stage !== "instructions" && (
              <SparkleWithAnimation boxSize={"1.1em"} />
            )}
            {/* {stage === "instructions" && <Text>Instructions</Text>} */}
            {stage === "initial" && (
              <Text>Take a picture of the nameplate</Text>
            )}
            {stage === "loading" && <Text>Processing Image</Text>}
            {stage === "parsed" && <Text>Verify Information</Text>}
            {stage === "continue" && <Text>Proceed with image</Text>}
          </HStack>

          {stage === "parsed" && isSticky && (
            <>
              <HStack mb={2}>
                <Text fontSize="x-large" fontWeight="bold">
                  {assetId || apiAsset ? "Edit" : "New"} Asset
                </Text>
                <Spacer />
                <IconButton
                  onClick={openNameplateDataModal}
                  aria-label="Show Details"
                  icon={<FiInfo size={35} />}
                  variant="ghost"
                />
                <IconButton
                  bg={isSticky ? "blue.500" : "gray.500"}
                  color="white"
                  _hover={{ bg: "blue.700", color: "white" }}
                  aria-label="Pin Image"
                  icon={<MdPushPin />}
                  variant={isSticky ? "solid" : "outline"}
                  onClick={handlePinClick}
                />
              </HStack>
              <Box width="100%">
                <Image
                  src={displayUrl}
                  alt="Asset Picture"
                  borderRadius="md"
                  width="100%"
                />
              </Box>
            </>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={2} overflowY="auto">
          {stage === "instructions" && !aiSetting && (
            <AiCreateAssetIntro setStage={setStage} />
          )}
          {stage === "initial" && (
            <AiNampleplatePhotoCapture
              webcamRef={webcamRef}
              videoConstraints={videoConstraints}
              captureNameplate={captureNameplate}
              workingAttachments={workingAttachments}
              handleAddAttachment={handleAddAttachment}
            />
          )}
          {stage === "loading" && (
            <Box>
              <AiProcessingAnimation />
            </Box>
          )}
          {stage === "continue" && (
            <Box>
              <AiLowConfidenceWarning
                displayUrl={displayUrl}
                apiParsedAsset={apiParsedAsset}
                setStage={setStage}
                setWorkingAttachments={setWorkingAttachments}
              />
            </Box>
          )}
          {stage === "parsed" && apiParsedAsset && (
            <Box>
              {!isSticky && (
                <>
                  <HStack mb={2}>
                    <Text fontSize="x-large" fontWeight="bold">
                      {assetId || apiAsset ? "Edit" : "Create"} Asset
                    </Text>
                    <Spacer />
                    <IconButton
                      onClick={openNameplateDataModal}
                      aria-label="Show Details"
                      icon={<FiInfo size={35} />}
                      variant="ghost"
                    />
                    <IconButton
                      bg={isSticky ? "blue.500" : "gray.500"}
                      color="white"
                      _hover={{ bg: "blue.700", color: "white" }}
                      aria-label="Pin Image"
                      icon={<MdPushPin />}
                      variant={isSticky ? "solid" : "outline"}
                      onClick={handlePinClick}
                    />
                  </HStack>
                  <Box position="relative">
                    <Box justifyContent="center" display="flex" width="full">
                      <Image
                        src={displayUrl}
                        alt="Asset Picture"
                        borderRadius="md"
                        className={isSticky ? "sticky" : ""}
                        sx={
                          isSticky
                            ? { position: "sticky", top: "0" }
                            : undefined
                        }
                      />
                    </Box>
                  </Box>
                </>
              )}
              <VerifyAssetSection
                isNameplateModalOpen={isNameplateModalOpen}
                apiParsedAsset={apiParsedAsset}
                latlong={{ long, lat }}
                closeNameplateDataModal={closeNameplateDataModal}
                scannedAttachments={workingAttachments}
                onClose={onClose}
                manufacturer={manufacturer}
              />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
