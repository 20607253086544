import { Box, VStack } from "@chakra-ui/react";
import { ApiServiceSumary } from "@operations-hero/lib-api-client";
import { useFormikContext } from "formik";
import React, { FC, useEffect, useMemo, useReducer } from "react";
import { QuestionResponseFields } from "./QuestionReponseFields";
import {
  ServiceQuestionResponseReducerValues,
  serviceQuestionsResponsesReducer,
} from "./ServiceQuestionsResponsesReducer";

interface ServiceQuestionsSectionProps {
  service: ApiServiceSumary;
  setResponses: React.Dispatch<
    React.SetStateAction<ServiceQuestionResponseReducerValues[]>
  >;
  setQuestionsFieldsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ServiceQuestionsSection: FC<ServiceQuestionsSectionProps> = ({
  service,
  setResponses,
  setQuestionsFieldsValid,
}) => {
  const { submitCount } = useFormikContext();

  const questionResponses: ServiceQuestionResponseReducerValues[] =
    useMemo(() => {
      if (!service.questions) return [];
      return service.questions.map((question) => {
        return {
          question,
          service,
        };
      });
    }, [service]);

  const [questionResponseValues, dispatchQuestionResponseValues] = useReducer(
    serviceQuestionsResponsesReducer,
    questionResponses
  );

  useEffect(() => {
    setResponses(
      questionResponseValues.filter((response) => {
        return response.value;
      })
    );
  }, [questionResponseValues, setResponses]);

  useEffect(() => {
    const invalid = questionResponseValues.some((response) => {
      if (response.question.required) {
        return !response.value;
      }
      return false;
    });
    setQuestionsFieldsValid(!invalid);
  }, [questionResponseValues, setQuestionsFieldsValid]);

  return (
    <VStack gap={4} width="100%">
      {questionResponseValues.map((questionResponse, index) => {
        return (
          <Box
            gap={4}
            width="100%"
            key={`questionsResponses::${questionResponse.question.id}::`}
            position="relative"
          >
            <QuestionResponseFields
              question={questionResponse.question}
              value={questionResponse.value}
              index={index + 1}
              onChange={(
                value: string | number | boolean | string[] | null
              ) => {
                dispatchQuestionResponseValues({
                  value,
                  index,
                });
              }}
              isInvalid={
                questionResponse.question.required &&
                !questionResponse.value &&
                submitCount > 0
              }
            />
          </Box>
        );
      })}
    </VStack>
  );
};
