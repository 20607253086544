import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiUserSummary,
  FindUsersOptions,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { MdClose } from "react-icons/md";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../components/badges/AdditionalNumberBadge";
import { FiltersWithCloseIcon } from "../../pages/requests/RequestAdvancedFilters";
import { UserBadge } from "../badges/UserBadge";

export interface UserFilterProps {
  value: (ApiUserSummary | string)[];
  onChange: (value: ApiUserSummary[]) => void;
  addCloseIcon?: FiltersWithCloseIcon;
  title?: string;
  filterOptions?: FindUsersOptions & { role?: string };
}

const ContentRenderer = ({
  props,
  state,
  title,
  addCloseIcon,
}: SelectRenderer<ApiUserSummary> & {
  addCloseIcon?: FiltersWithCloseIcon;
  title?: string;
}) => {
  return (
    <Box p={1} display=" flex" alignItems="center" w="max-content">
      {state.values.length === 0 && (title || "Users")}
      {state.values.length === 1 && (
        <UserBadge value={state.values[0]} tagSize="md" />
      )}
      {state.values.length > 1 && (
        <Flex alignItems="center" gap={2}>
          <UserBadge value={state.values[0]} tagSize="md" />
          <AdditionalNumberBadge number={state.values.length - 1} />
        </Flex>
      )}
      {addCloseIcon && (
        <Icon
          as={MdClose}
          ml={2}
          onClick={(e) => addCloseIcon.handleClickCloseIcon(e, "users")}
        />
      )}
    </Box>
  );
};

const DropdownHandleRenderer = () => {
  return <Box />;
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiUserSummary>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["firstName", "lastName"], {
        sort: true,
      }),
    [props.options]
  );

  const items = useMemo(() => {
    const results = fuzzySearch.search(state.search);
    const selected = state.values;
    selected.filter((sel) =>
      results.find((res) => res.id === sel.id) ? null : results.push(sel)
    );
    return !state.search ? props.options : results;
  }, [state.search, state.values, props.options, fuzzySearch]);

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Box>Search and select:</Box>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Users"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.id}
              isChecked={
                methods.isSelected(item) ||
                state.values.some((sel) => sel.id === item.id)
              }
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <UserBadge value={item} />
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const UsersFilter = ({
  value,
  onChange,
  addCloseIcon,
  title,
  filterOptions,
}: UserFilterProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const [options, setOptions] = useState<ApiUserSummary[]>([]);
  const [search, setSearch] = useState<string>("");

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiUserSummary>) => {
      if (!state.search) {
        setSearch("");
      }
      setSearch(state.search);

      return props.options;
    },
    []
  );

  useEffect(() => {
    apiClient
      .findAccountUsers(currentAccount.id, {
        pageSize: 50,
        search,
        ...(filterOptions ?? {}),
      })
      .then((res) => setOptions(res.data));
  }, [apiClient, currentAccount.id, search, filterOptions]);

  const hydratedValues = useMemo(() => {
    const allValues: ApiUserSummary[] = [];
    if (!options.length) return allValues;

    for (const val of value) {
      if (typeof val === "string") {
        const valueFound = options.find(
          (opt) => opt.id === val
        ) as ApiUserSummary;
        if (valueFound) {
          allValues.push(valueFound);
        }
      } else {
        allValues.push(val);
      }
    }

    return allValues;
  }, [options, value]);

  return (
    <Select
      multi
      options={options}
      values={hydratedValues}
      className={themeClass}
      onChange={onChange}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      clearable={false}
      keepSelectedInList
      clearOnSelect={false}
      dropdownHeight="350px"
      searchFn={searchFn}
      dropdownRenderer={DropdownRenderer}
      contentRenderer={(props) =>
        ContentRenderer({ ...props, addCloseIcon, title })
      }
      dropdownHandleRenderer={addCloseIcon ? DropdownHandleRenderer : undefined}
    />
  );
};
