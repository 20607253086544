interface weekOfMonthType {
  [key: string]: string;
}

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthWeeks: weekOfMonthType = {
  0: "zero",
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth",
  6: "sixth",
};

export const getDayName = (dayOfTheWeek: number) => {
  return days[dayOfTheWeek];
};
