import { Flex, Grid, GridItem, Switch, Text } from "@chakra-ui/react";
import {
  ApiDay,
  ApiHours,
  ApiVenueTime,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback, useMemo, useReducer } from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { isObjectEmpty } from "../../utils/compareObjects";
import { StyledDatePicker } from "../inputs/StyledDatePicker";

export type WeeklyVenueTime = ApiVenueTime & { isInvalid?: boolean };
export type DayHours = Record<ApiDay, WeeklyVenueTime>;

const defaultDayHours: WeeklyVenueTime = {
  end: "",
  start: "",
  isOpen: false,
  isInvalid: false,
};

const HOUR_MINUTES = 60;

const getHourState = (day: ApiDay, hourData?: Record<ApiDay, ApiVenueTime>) => {
  if (!hourData) return defaultDayHours;
  return { ...hourData[day], isInvalid: false };
};

export const getInitialState = (initialData?: Record<ApiDay, ApiVenueTime>) => {
  const initialState: Map<ApiDay, WeeklyVenueTime> = new Map([
    ["monday", { ...getHourState("monday", initialData) }],
    ["tuesday", { ...getHourState("tuesday", initialData) }],
    ["wednesday", { ...getHourState("wednesday", initialData) }],
    ["thursday", { ...getHourState("thursday", initialData) }],
    ["friday", { ...getHourState("friday", initialData) }],
    ["saturday", { ...getHourState("saturday", initialData) }],
    ["sunday", { ...getHourState("sunday", initialData) }],
  ]);

  return initialState;
};

export const getHourAndMinutesFromString = (timeParam: string) => {
  if (timeParam === "") return { newHour: NaN, newMinutes: NaN };
  var timePeriod = timeParam.match(/AM|PM/gm);
  const slicedTime = timeParam.split(/\s(PM|AM)/gm)[0];
  const [hours, minutes] = slicedTime.split(":");

  let newHour = +hours;
  let newMinutes = +minutes;

  if (timePeriod) {
    const AMPM = timePeriod[0];
    AMPM === "PM" && newHour < 12 && (newHour = newHour + 12);
    AMPM === "AM" && newHour === 12 && (newHour = newHour - 12);
  }

  return { newHour, newMinutes };
};

const hasError = (venueHour?: ApiVenueTime) => {
  if (!venueHour || (venueHour.start !== "" && venueHour.end === "")) {
    return false;
  }
  const { start, end } = venueHour;

  if (start === "" && end !== "") {
    return true;
  }

  const { newHour: newStartHour, newMinutes: newStartMinutes } =
    getHourAndMinutesFromString(start);
  const { newHour: newEndHour, newMinutes: newEndMinutes } =
    getHourAndMinutesFromString(end);

  const startTotalMinutes = newStartHour * HOUR_MINUTES + newStartMinutes;
  const endTotalMinutes = newEndHour * HOUR_MINUTES + newEndMinutes;

  const result = startTotalMinutes > endTotalMinutes;

  return result;
};

const dayHoursReducer = (
  state: Map<ApiDay, WeeklyVenueTime>,
  action: {
    day: ApiDay;
    payload: Partial<WeeklyVenueTime>;
  }
) => {
  const { day, payload } = action;
  const venueHour = state.get(day);

  const newValue = {
    ...venueHour,
    ...payload,
    isInvalid: hasError({ ...venueHour, ...payload } as ApiVenueTime & {
      isInvalid: boolean;
    }),
  };

  state.set(day, newValue as WeeklyVenueTime);
  return state;
};

interface WeeklyTimePickerProps {
  initialData?: Record<ApiDay, ApiVenueTime>;
  defaultVenueHours?: ApiHours;
  getHoursCallback: (daysMap: DayHours) => void;
  getErrorsCallback?: (hasErrors: boolean) => void;
}

export const WeeklyTimePicker: FC<WeeklyTimePickerProps> = ({
  initialData,
  defaultVenueHours,
  getHoursCallback,
  getErrorsCallback,
}) => {
  const [daysMap, reducerDispatch] = useReducer(
    dayHoursReducer,
    getInitialState(initialData)
  );

  const executeCallback = useCallback(
    (newDaysMap?: Map<ApiDay, WeeklyVenueTime>) => {
      const entries = newDaysMap || daysMap;
      const hours = Object.fromEntries(entries) as DayHours;

      getHoursCallback(hours);

      if (getErrorsCallback) {
        const hasErrors = Array.from(daysMap.values())
          .map((item) => item.isInvalid)
          .some((item) => item === true);

        getErrorsCallback(hasErrors);
      }
    },
    [daysMap, getErrorsCallback, getHoursCallback]
  );

  const setNewDaysMap = useCallback(
    (
      day: ApiDay,
      payload: {
        isOpen?: boolean;
        start?: string;
        end?: string;
        isInvalid?: boolean;
      }
    ) => {
      const newDaysMap = new Map(daysMap);
      const currentMap = newDaysMap.get(day as ApiDay);
      if (currentMap) {
        newDaysMap.set(day as ApiDay, { ...currentMap, ...payload });
        executeCallback(newDaysMap);
        return;
      }
      executeCallback();
    },
    [daysMap, executeCallback]
  );

  const handleOnChangeOpen = useCallback(
    ({ day, isOpen }: { day: ApiDay; isOpen: boolean }) => {
      let isOpenPayload: Partial<WeeklyVenueTime> = { isOpen: true };
      if (defaultVenueHours && !isObjectEmpty(defaultVenueHours)) {
        const defaultStartHour = defaultVenueHours[day as ApiDay].start;
        const defaultEndHour = defaultVenueHours[day as ApiDay].end;
        isOpenPayload["start"] = defaultStartHour;
        isOpenPayload["end"] = defaultEndHour;
      }

      const payload = isOpen
        ? { ...isOpenPayload }
        : { isOpen, start: "", end: "" };

      reducerDispatch({ day, payload });

      setNewDaysMap(day, payload);
    },
    [defaultVenueHours, setNewDaysMap]
  );

  const handleTimePickerChange = useCallback(
    (value: Date | null, name?: string) => {
      if (name) {
        const [day, field] = name.split("-");
        const payload =
          field === "start"
            ? { start: value ? format(value, "hh:mm aa") : "" }
            : { end: value ? format(value, "hh:mm aa") : "" };
        reducerDispatch({ day: day as ApiDay, payload });

        setNewDaysMap(day as ApiDay, payload);
      }
    },
    [setNewDaysMap]
  );
  const newDateFromTime = useCallback((time: string) => {
    if (time === "") return null;

    const now = new Date();
    const { newHour, newMinutes } = getHourAndMinutesFromString(time);
    now.setHours(newHour);
    now.setMinutes(newMinutes);

    return now;
  }, []);

  const daysArray = useMemo(() => Array.from(daysMap.keys()), [daysMap]);

  return (
    <Flex minW="100%" flexDir="row" wrap="wrap" gap={2}>
      {Array.from(daysMap.values()).map((day, idx) => {
        return (
          <Grid
            py={2}
            gap={3}
            w="100%"
            minH="48px"
            alignItems="center"
            key={daysArray[idx]}
            templateColumns="repeat(12, 1fr)"
          >
            <GridItem colSpan={[6, 6, 3]} fontWeight="700">
              {capitalizeFirstLetter(daysArray[idx])}
            </GridItem>

            <GridItem
              colSpan={[6, 6, 2]}
              display="flex"
              justifyContent={[
                "flex-end",
                "flex-end",
                "flex-end",
                "flex-start",
              ]}
            >
              <Switch
                mr={2}
                z-index={10}
                isChecked={day.isOpen}
                onChange={() =>
                  handleOnChangeOpen({
                    day: daysArray[idx],
                    isOpen: !day.isOpen,
                  })
                }
              />
              <Text
                as="span"
                maxW="max-content"
                textAlign={["right", "right", "left"]}
              >
                Open
              </Text>
            </GridItem>

            <GridItem colSpan={[5, 5, 3]}>
              {day.isOpen && (
                <StyledDatePicker
                  showTime
                  showTimeOnly
                  format="h:mm aa"
                  className="timePicker"
                  placeholder="08:00 AM"
                  hasError={day.isInvalid}
                  name={`${daysArray[idx]}-start`}
                  onChange={handleTimePickerChange}
                  value={newDateFromTime(day.start)}
                />
              )}
            </GridItem>

            <GridItem textAlign="center" colSpan={[2, 2, 1]}>
              {day.isOpen && <Text>To</Text>}
            </GridItem>

            <GridItem colSpan={[5, 5, 3]}>
              {day.isOpen && (
                <StyledDatePicker
                  showTime
                  showTimeOnly
                  format="h:mm aa"
                  className="timePicker"
                  placeholder="06:00 PM"
                  hasError={day.isInvalid}
                  name={`${daysArray[idx]}-end`}
                  value={newDateFromTime(day.end)}
                  onChange={handleTimePickerChange}
                />
              )}
            </GridItem>
          </Grid>
        );
      })}
    </Flex>
  );
};
