import {
  FormControl,
  Input,
  InputGroup,
  Stack,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  getFormatedRequestHour,
  requestHoursFormatRegex,
} from "../../pages/request-form/transactions/transactions-helper";
import { debounce } from "../../utils/debounce";

export enum HoursOperation {
  ADDITION = "ADDITION",
  SUBTRACTION = "SUBTRACTION",
  REPLACE = "REPLACE",
}

enum TimeEnum {
  DAYS,
  HOURS,
  MINUTES,
}

const getTimeNumber = (time: string | null, type: TimeEnum): number => {
  if (time) {
    switch (type) {
      case TimeEnum.DAYS: {
        const days = time.replace("d", "");
        const daysDec = +days * 24;
        return daysDec;
      }
      case TimeEnum.HOURS: {
        const hour = time.replace("h", "");
        return +hour;
      }
      case TimeEnum.MINUTES: {
        const minutes = time.replace("m", "");
        const minutesDec = +minutes / 60;
        return minutesDec;
      }
      default:
        return 0;
    }
  }
  return 0;
};

const convertAndSave = (
  value: string,
  callbackToExecute: (value: number) => void
) => {
  const cleanValue = value.trim();
  const isValid = requestHoursFormatRegex.test(cleanValue);
  if (isValid) {
    let days: number = 0;

    const hourStr = cleanValue.match(/(\d+)h/g);
    const minutesStr = cleanValue.match(/([0-5]?[0-9]m)/g);
    const hours: number = hourStr?.length
      ? getTimeNumber(hourStr[0], TimeEnum.HOURS)
      : 0;
    const minutes: number = minutesStr?.length
      ? getTimeNumber(minutesStr[0], TimeEnum.MINUTES)
      : 0;

    const decimalNotationHour = days + hours + minutes;
    callbackToExecute(decimalNotationHour);
    return;
  }
  callbackToExecute(0);
};

const debouncedSave = debounce(convertAndSave, 250);

interface RequestHoursSpinnerProps {
  value: number;
  isDisabled?: boolean;
  placeholder?: string;
  onBlur?: (value: number) => void;
  getIsValid?: (value: boolean) => void;
  onChange?: (value: number, isValid?: boolean) => void;
}

const RequestHoursSpinner: React.FC<RequestHoursSpinnerProps> = ({
  value = 0,
  onChange,
  getIsValid,
  onBlur,
  isDisabled,
  placeholder,
}) => {
  const [formatedHours, setFormatedHours] = useState(
    getFormatedRequestHour(value)
  );

  const [isValid, setIsValid] = useState(true);

  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // The following variables are used because the chakra-ui colors not works for shadow css
  const redErrorColor = colorMode === "dark" ? "#FC8181" : "#E53E3E";
  const blueFocusColor = colorMode === "dark" ? "#63B3ED" : "#3182CE";
  const grayColor = "#D0D4D9";

  const handleOnInputChange = useCallback(
    (value: string) => {
      setFormatedHours(value);

      if (value && onChange) {
        debouncedSave(value, onChange);
      }
    },
    [onChange]
  );

  const handleOnBlur = useCallback(
    (value: string) => {
      if (value && onBlur) {
        debouncedSave(value, onBlur);
      }
    },
    [onBlur]
  );

  useEffect(() => {
    if (!formatedHours && value) {
      setFormatedHours(getFormatedRequestHour(value));
    }
  }, [formatedHours, value]);

  useEffect(() => {
    const result = requestHoursFormatRegex.test(formatedHours);
    setIsValid(result);
    getIsValid && getIsValid(result);
  }, [formatedHours, getIsValid]);

  return (
    <Stack gap={2}>
      <FormControl isInvalid={!isValid}>
        <InputGroup
          size="sm"
          onFocus={onOpen}
          onBlur={onClose}
          maxW={"100%"}
          shadow={
            isOpen
              ? isValid
                ? `0px 0px 0px 2px ${blueFocusColor}`
                : `0px 0px 0px 2px ${redErrorColor}`
              : isValid
                ? `0px 0px 0px 0.5px ${grayColor}`
                : `0px 0px 0px 2px ${redErrorColor}`
          }
          borderRadius={4}
        >
          <Input
            pr={6}
            size="md"
            border="none"
            borderRadius={6}
            value={formatedHours}
            textAlign="right"
            isDisabled={isDisabled}
            placeholder={placeholder}
            maxLength={20}
            _focus={{ shadow: "none" }}
            pl={7}
            onBlur={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnBlur(e.target.value)
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnInputChange(e.target.value)
            }
          />
        </InputGroup>
      </FormControl>
    </Stack>
  );
};

export default RequestHoursSpinner;
