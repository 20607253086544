import { ApiLocation } from "@operations-hero/lib-api-client";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { format as formatDate } from "date-fns";
import React from "react";
import {
  renderMarkdownToPDF,
  transformMentions,
} from "../../../../utils/bulkPrintUtils";
import { RequestComments } from "./BulkPrintPdfDocument";

export interface PrintableRequestTableRowsProps {
  request: RequestComments;
  ancestors: Record<string, ApiLocation[]>;
}
export const tableStyles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 8,
    fontFamily: "Open Sans",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
  },
  tableCell: {
    padding: 5,
    flexGrow: 1,
    width: "6%",
    overflow: "hidden",
    fontSize: 8,
  },
  headerCell: {
    padding: 5,
    flexGrow: 1,
    width: "6%",
    overflow: "hidden",
    fontWeight: "bold",
    fontSize: 8,
  },
  assignedToCell: {
    padding: 5,
    flexGrow: 1,
    width: "8%",
    overflow: "hidden",
    fontSize: 8,
  },
  locationCell: {
    padding: 5,
    flexGrow: 1,
    width: "18%",
    overflow: "hidden",
    fontSize: 8,
  },
  descriptionCell: {
    padding: 5,
    flexGrow: 1,
    width: "20%",
    overflow: "hidden",
    fontSize: 8,
  },
  mainContainer: {
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableContainer: {
    flex: 1,
  },
  footer: {
    position: "relative",
    paddingLeft: 30,
    paddingRight: 30,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
});

export const PrintableRequestTableRows = ({
  request,
  ancestors,
}: PrintableRequestTableRowsProps) => {
  const shortDateFormat = "M-d-y";

  return (
    <View key={request.id} style={tableStyles.tableRow}>
      <Text style={tableStyles.tableCell}>{request.key}</Text>
      <Text style={tableStyles.tableCell}>
        {`${request.requester.firstName} ${request.requester.lastName}`}
      </Text>
      <Text style={tableStyles.tableCell}>{request.status}</Text>
      <Text style={tableStyles.tableCell}>{request.type}</Text>
      <Text style={tableStyles.tableCell}>
        {request.reportingCategory?.code}
      </Text>
      <Text style={tableStyles.tableCell}>{request.priority}</Text>
      <Text style={tableStyles.assignedToCell}>
        {request.assignees
          .map((assignee) =>
            assignee.type === "group"
              ? `${assignee.assignee.name}`
              : `${assignee.assignee.firstName} ${assignee.assignee.lastName}`
          )
          .join(", ")}
      </Text>

      <Text style={tableStyles.locationCell}>
        {`${request.location?.name || "N/A"}`}
        {ancestors[request.id] &&
          ancestors[request.id].length > 0 &&
          ` (${ancestors[request.id].map((l) => l.name).join(" > ")})`}
        {request.location?.active === false && ` (inactive)`}
      </Text>

      <View style={tableStyles.descriptionCell}>
        {renderMarkdownToPDF(
          transformMentions(request.summary, request.mentioned)
        )}
      </View>

      <Text style={tableStyles.tableCell}>
        {request.scheduling.start
          ? formatDate(new Date(request.scheduling.start), shortDateFormat)
          : ""}
      </Text>
      <Text style={tableStyles.tableCell}>
        {request.scheduling.due
          ? formatDate(new Date(request.scheduling.due), shortDateFormat)
          : ""}
      </Text>
    </View>
  );
};
