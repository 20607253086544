import { useMemo } from "react";
import { SubscriptionContextInterface } from "../../../components/shell/AppShell";
import { useAdminRoutes } from "./AccountRoutes";
import { useEventRoutes } from "./EventsRoutes";
import { useInvoiceRoutes } from "./InvoicesRoutes";
import { useRequestRoutes } from "./RequestRoutes";

export const useMainAccountRoutes = (
  subscriptions: SubscriptionContextInterface,
) => {
  const { hasRequests, hasEvents } = subscriptions;
  const { routes: adminRoutes } = useAdminRoutes();
  const { routes: requestsRoutes } = useRequestRoutes(hasRequests);
  const { routes: eventRoutes } = useEventRoutes(hasEvents);
  const { routes: invoiceRoutes } = useInvoiceRoutes(hasEvents);

  const accountRoutes = useMemo(
    () => [...adminRoutes, ...requestsRoutes, ...eventRoutes, ...invoiceRoutes],
    [adminRoutes, eventRoutes, invoiceRoutes, requestsRoutes],
  );

  return { accountRoutes };
};
