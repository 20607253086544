import {
  ApiAccount,
  ApiClient,
  ApiUserGroup,
  ApiUserGroupMember,
  UpdateApiUserGroup,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

const PAGE_SIZE = 20;

export interface LoadUserGroupFormThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  groupId: string;
}
interface DeactivateReactivateParams {
  accountId: string;
  apiClient: ApiClient;
  groupId: string;
}
export const initUserGroupForm = createAsyncThunk(
  "user-group-form/init-form",
  async (
    { apiClient, account, groupId }: LoadUserGroupFormThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;

    const [group, pagedUsers] = await Promise.all([
      apiClient.getUserGroup(account.id, groupId),
      apiClient.findUserGroupMembers(account.id, groupId, {
        pageSize: PAGE_SIZE,
        page: state.userGroupForm.membersCurrentPage,
      }),
    ]);

    return { group, pagedUsers };
  }
);

export const loadUserGroupMembers = createAsyncThunk(
  "user-group-form/load-members",
  async (
    { apiClient, account, groupId }: LoadUserGroupFormThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;

    const pagedUsers = await apiClient.findUserGroupMembers(
      account.id,
      groupId,
      {
        pageSize: PAGE_SIZE,
        page: state.userGroupForm.membersCurrentPage,
        search: state.userGroupForm.search,
      }
    );

    return pagedUsers;
  }
);

export const updateGroup = createAsyncThunk(
  "user-group-form/update-group",
  async (
    {
      apiClient,
      account,
      groupId,
      group,
    }: LoadUserGroupFormThunkParams & { group: UpdateApiUserGroup },
    thunkAPI
  ) => {
    const userGroup = await apiClient.updateUserGroup(
      account.id,
      groupId,
      group
    );

    return userGroup;
  }
);

export const deactivateUserGroup = createAsyncThunk(
  "user-group-form/deactivate-group",
  async (params: DeactivateReactivateParams, ThunkAPI) => {
    const { apiClient, accountId, groupId } = params;
    await apiClient.deactivateUserGroup(accountId, groupId);
    return { groupId };
  }
);

export const reactivateUserGroup = createAsyncThunk(
  "user-group-form/reactivate-group",
  async (params: DeactivateReactivateParams, ThunkAPI) => {
    const { apiClient, accountId, groupId } = params;

    const group = await apiClient.reactivateUserGroup(accountId, groupId);

    return { group };
  }
);
export interface UserGroupFormSliceState {
  group: ApiUserGroup | null;
  members: ApiUserGroupMember[];
  membersTotal: number;
  membersCurrentPage: number;
  search: string;
}

export const userGroupFormSlice = createSlice({
  name: "user-group-form",
  initialState: {
    group: null,
    members: [],
    membersTotal: 0,
    membersCurrentPage: 1,
    search: "",
  } as UserGroupFormSliceState,
  reducers: {
    unload: (state) => {
      state.group = null;
      state.members = [];
      state.membersTotal = 0;
      state.membersCurrentPage = 1;
      state.search = "";
    },
    setMembersCurrentPage: (state, payload: PayloadAction<number>) => {
      state.membersCurrentPage = payload.payload;
    },
    setMembersSearch: (state, payload: PayloadAction<string>) => {
      state.search = payload.payload;
    },
    updateMember: (state, payload: PayloadAction<ApiUserGroupMember>) => {
      const user = state.members.find(
        (x) => x.user.id === payload.payload.user.id
      );
      if (!user) {
        return;
      }

      user.memberType = payload.payload.memberType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initUserGroupForm.fulfilled, (state, action) => {
      state.group = action.payload.group;
      state.membersTotal = action.payload.pagedUsers.total;
      state.membersCurrentPage =
        action.payload.pagedUsers.options.page || state.membersCurrentPage;
      state.members = action.payload.pagedUsers.data;
    });

    builder.addCase(loadUserGroupMembers.fulfilled, (state, action) => {
      state.membersTotal = action.payload.total;
      state.membersCurrentPage =
        action.payload.options.page || state.membersCurrentPage;
      state.members = action.payload.data;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.group = action.payload;
    });
    builder.addCase(deactivateUserGroup.fulfilled, (state, action) => {
      if (state.group !== null) {
        state.group!.active = false;
      }
    });

    builder.addCase(reactivateUserGroup.fulfilled, (state, action) => {
      const { group } = action.payload;
      state.group = group;
    });
  },
});

export const { unload, setMembersCurrentPage, setMembersSearch, updateMember } =
  userGroupFormSlice.actions;

export default userGroupFormSlice.reducer;
