import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";
import { updateRequestFilters } from "../../../store/request-list.slice";
import { RequestDisplayCount } from "../RequestDisplayCount";
import { ViewMode } from "../Requests";
import { RequestAdvancedFiltersContainer } from "./RequestAdvancedFiltersContainer";
import { RequestDateSelectionText } from "./RequestDateSelectionText";
import RequestListActions from "./RequestListActions";
import { RequestListViewSelector } from "./RequestListViewSelector";
import { RequestPageHeading } from "./RequestPageHeading";

export interface RequestListHeaderProps {
  mode: ViewMode;
  totalSelected?: number;
  handleSelectAll?: () => void;
}

export const RequestListHeader: FC<RequestListHeaderProps> = ({
  mode = "normal",
  handleSelectAll,
}) => {
  const { isProductAdmin } = useAuthentication();
  const dispatch = useDispatch();
  const { isRequesterOnly } = useSelector(
    (state: RootState) => state.localCache
  );
  const { filters } = useSelector((state: RootState) => state.requestList);
  const { displayMode } = useSelector(
    (state: RootState) => state.requestsSlice
  );

  const [showPills, setShowPills] = useState(false);

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });

  const filterTextColor = useColorModeValue("gray.700", "whiteAlpha.900");
  const bgColor = useColorModeValue("white", "whiteAlpha.300");

  const showFilterAndSort = useMemo(() => {
    return !isRequesterOnly || isProductAdmin;
  }, [isProductAdmin, isRequesterOnly]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateRequestFilters({ search: e.target.value }));
    },
    [dispatch]
  );

  return !isMobileMode && !isTablet ? (
    <Stack gap={2}>
      {/* Desktop View */}
      <HStack justifyContent="space-between">
        <RequestPageHeading size={"lg"} maxW={250} isTruncated />
        {!showFilterAndSort ? (
          <InputGroup maxW={350} bgColor={bgColor}>
            <InputLeftElement children={<SearchIcon color="gray.300" />} />
            <Input
              type="text"
              placeholder="Request #/Summary"
              value={filters.search}
              onChange={handleSearchChange}
            />
          </InputGroup>
        ) : (
          <>
            <Box ml={5}>
              <RequestListViewSelector />
            </Box>
            <RequestListActions />
          </>
        )}
      </HStack>
      {showFilterAndSort && (
        <RequestAdvancedFiltersContainer
          mode={mode}
          handleSelectAll={handleSelectAll}
        />
      )}
      {displayMode === "row" && (
        <HStack justifyContent="space-between">
          <Box>
            <RequestDisplayCount />
          </Box>
          {showFilterAndSort ? (
            <RequestDateSelectionText
              textAlign={"right"}
              color={filterTextColor}
            />
          ) : null}
        </HStack>
      )}
    </Stack>
  ) : (
    <Stack gap={2}>
      {/* Mobile and Tablet View */}
      <HStack justifyContent="space-between">
        <RequestPageHeading size={isMobileMode ? "md" : "lg"} isTruncated />
        {showFilterAndSort ? (
          <RequestListActions />
        ) : (
          <Box maxW={210}>
            <InputGroup bgColor={bgColor}>
              <InputLeftElement children={<SearchIcon color="gray.300" />} />
              <Input
                type="text"
                placeholder="Request #/Summary"
                value={filters.search}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </Box>
        )}
      </HStack>

      {showFilterAndSort && (
        <HStack justifyContent="space-between">
          <Box>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => setShowPills(!showPills)}
              rightIcon={
                <Icon as={showPills ? IoCaretUpOutline : IoCaretDownOutline} />
              }
            >
              Change View
            </Button>
          </Box>
          <Box>
            <RequestAdvancedFiltersContainer
              mode={mode}
              handleSelectAll={handleSelectAll}
            />
          </Box>
        </HStack>
      )}
      {showFilterAndSort && showPills && (
        <Box py={2}>
          <RequestListViewSelector />
        </Box>
      )}

      {displayMode === "row" && (
        <HStack justifyContent="space-between">
          <Box>
            <RequestDisplayCount fontSize="xs" />
          </Box>

          {showFilterAndSort ? (
            <RequestDateSelectionText
              textAlign={"right"}
              fontSize="xs"
              color={filterTextColor}
            />
          ) : null}
        </HStack>
      )}
    </Stack>
  );
};
