import { Flex, Icon, Image, Text, useColorModeValue } from "@chakra-ui/react";
import {
  ApiRate,
  ApiSpaceSummary,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { FC, useMemo } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { NoAvailableImage } from "../../spaces/SpaceFormViews";
import { GetItemRateType } from "../edit-form-components/EventServiceItem";
import { ItemPrice } from "./ItemPrice";

interface SpaceItemProps {
  space: ApiSpaceSummary;
  eventHoursDuration: number;
  getItemRates: GetItemRateType;
  occurrences: CreateApiEventOccurrence[];
  handleRemoveSpace: (spaceId: string) => void;
  getAllRatesByItemId: (itemId: string) => ApiRate[] | undefined;
}

export const SpaceItem: FC<SpaceItemProps> = ({
  space,
  getItemRates,
  occurrences,
  handleRemoveSpace,
  eventHoursDuration,
  getAllRatesByItemId,
}) => {
  const rates = getItemRates(space.id, true, occurrences, eventHoursDuration);
  const ratesByItem = getAllRatesByItemId(space.id);

  const textColor = useColorModeValue("gray.500", "whiteAlpha.600");

  const sumTotal = useMemo(() => {
    let total = 0;
    rates?.forEach((r) => {
      total += r.total;
    });
    return total;
  }, [rates]);

  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex gap={2} alignItems="center">
        {space.defaultAttachment ? (
          <Image
            w="50px"
            h="48px"
            borderRadius={6}
            src={space.defaultAttachment.url}
          />
        ) : (
          <NoAvailableImage
            showText={false}
            iconProps={{ boxSize: "14px" }}
            containerProps={{ w: "50px", h: "48px" }}
          />
        )}
        <Flex flexDir="column">
          <Text fontWeight="semibold">{space.location.name}</Text>
          {rates && enableInvoicesForEvents && (
            <ItemPrice
              ratesByItem={ratesByItem}
              addDash={false}
              textProps={{ fontSize: "xs" }}
            />
          )}
          {rates && enableInvoicesForEvents && (
            <Text fontSize="xs" color={textColor}>{`Subtotal: ${formatCurrency(
              sumTotal || 0
            )}`}</Text>
          )}
        </Flex>
      </Flex>

      <Icon
        cursor="pointer"
        as={IoTrashOutline}
        onClick={() => handleRemoveSpace(space.id)}
      />
    </Flex>
  );
};
