import { Box } from "@chakra-ui/react";
import { ApiRequestPriority } from "@operations-hero/lib-api-client";
import {
  ChakraStylesConfig,
  GroupBase,
  OptionProps,
  Select,
  SingleValueProps,
} from "chakra-react-select";
import React, { useCallback, useMemo } from "react";
import { PriorityBadge } from "../badges/PriorityBadge";
import { createOptionComponent, CustomSelectStyles } from "./select-overrides";
import { CustomSelectComponentProp } from "./select-overrides-types";

export interface RequestPrioritySelectProps {
  value: ApiRequestPriority;
  onChange?: (status: ApiRequestPriority) => void;
  isDisabled?: boolean;
  name?: string;
}

type ValueLabel = {
  value: ApiRequestPriority;
  label: string;
};

type PriorityStyles = ChakraStylesConfig<
  { value: ApiRequestPriority; label: ApiRequestPriority },
  false,
  GroupBase<{ value: ApiRequestPriority; label: ApiRequestPriority }>
>;

type PrioritySingleValueProps = SingleValueProps<
  ValueLabel,
  false,
  GroupBase<ValueLabel>
>;

export interface PriorityBadgeLabel {
  value: ValueLabel;
  isSelected: boolean;
}

const PriorityValueBadge: React.FC<PriorityBadgeLabel> = ({
  value,
  isSelected,
}) => <PriorityBadge priority={value.value} isSelected={isSelected} />;

const CustomOptionComponent = createOptionComponent(PriorityValueBadge);

const CustomSingleValueComponent = (
  props: SingleValueProps<ValueLabel, false, GroupBase<ValueLabel>>,
) => {
  const { data, innerProps, isDisabled } = props;
  return (
    <Box {...innerProps} cursor="pointer">
      <PriorityBadge
        priority={data.value}
        isControlValue
        isDisabled={isDisabled}
      />
    </Box>
  );
};

export const RequestPrioritySelect = ({
  value,
  onChange,
  isDisabled,
  name,
}: RequestPrioritySelectProps) => {
  const options = useMemo(
    () =>
      [
        ApiRequestPriority.expedite,
        ApiRequestPriority.important,
        ApiRequestPriority.standard,
        ApiRequestPriority.low,
      ].map((x) => ({
        value: x,
        label: x,
      })),
    [],
  );

  const customStyles = useMemo(
    () => CustomSelectStyles() as PriorityStyles,
    [],
  );

  const handleChange = useCallback(
    (item: ValueLabel | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange],
  );

  const components = useMemo(
    (): CustomSelectComponentProp => ({
      SingleValue: (props: PrioritySingleValueProps) =>
        CustomSingleValueComponent(props),
      Option: (props: OptionProps<ValueLabel, false, GroupBase<ValueLabel>>) =>
        CustomOptionComponent(props),
      IndicatorSeparator: null,
      DropdownIndicator: null,
    }),
    [],
  );

  return (
    <Select
      name={name}
      value={options.find((x) => x.value === value)}
      options={options}
      chakraStyles={customStyles}
      onChange={handleChange}
      isClearable={false}
      getOptionLabel={(entry) => entry.label}
      isSearchable={false}
      menuPlacement="auto"
      components={components}
      isDisabled={isDisabled}
    />
  );
};
