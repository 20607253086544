import { Avatar, Box, HStack, Text } from "@chakra-ui/react";
import { ApiEventGroup } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

export interface EventGroupAutocompleteProps {
  name?: string;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: ApiEventGroup | null;
  isInternalGroup?: boolean;
  onChange: (group: ApiEventGroup | null) => void;
  excludedGroup?: string;
  includeAvatar?: boolean;
}

const EventGroupBadge = ({
  value,
  includeAvatar,
}: {
  value: ApiEventGroup;
  includeAvatar?: boolean;
}) => {
  return includeAvatar ? (
    <HStack>
      <Avatar src={value.logo || ""} name={value.name} size="xs" />
      <Box>
        <Text>{value.name}</Text>
      </Box>
    </HStack>
  ) : (
    <Box>
      <Text>{value.name}</Text>
    </Box>
  );
};

const CustomOptionComponent = createOptionComponent(EventGroupBadge);

const CustomSingleValueComponent = (
  props: SingleValueSelect<ApiEventGroup>,
  includeAvatar?: boolean,
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <EventGroupBadge value={data} includeAvatar={includeAvatar} />
    </Box>
  );
};

export const EventGroupAutocomplete = ({
  value,
  onChange,
  allowEmpty = true,
  isInvalid,
  isDisabled,
  placeholder,
  isInternalGroup,
  excludedGroup,
  includeAvatar,
}: EventGroupAutocompleteProps) => {
  const { currentAccount, currentUser, apiClient, isEventAdmin } =
    useAuthentication();

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findEventGroups(currentAccount.id, {
          search: inputValue,
          isInternalGroup: isInternalGroup,
          users: !isEventAdmin ? currentUser.id : undefined,
        })
        .then((response) =>
          excludedGroup
            ? cb(response.data.filter((resp) => resp.id !== excludedGroup))
            : cb(response.data),
        );
    },
    [
      apiClient,
      currentAccount.id,
      currentUser.id,
      excludedGroup,
      isEventAdmin,
      isInternalGroup,
    ],
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const handleChange = useCallback(
    (newValue: ApiEventGroup | null) => {
      if (allowEmpty !== true && newValue == null) {
        return;
      }
      onChange(newValue);
    },
    [onChange, allowEmpty],
  );

  const getOptionValue = useCallback((group: ApiEventGroup) => group.id, []);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiEventGroup>) =>
        CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiEventGroup>) =>
        CustomSingleValueComponent(props, includeAvatar),
      DropdownIndicator: () => null,
    };
  }, [includeAvatar]);

  const memorizedKey = useMemo(() => {
    if (!isInternalGroup) return "eventGroup-all";
    return isInternalGroup ? "eventGroup-internal" : " eventGroup-external";
  }, [isInternalGroup]);

  return (
    <AsyncSelect
      key={memorizedKey}
      defaultOptions={true}
      value={value}
      cacheOptions={true}
      getOptionValue={getOptionValue}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      isClearable={true}
      isInvalid={isInvalid}
      isMulti={false}
      chakraStyles={commonStyles}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};
