import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestListFilterState } from "../../request-list.slice";

type UpdateFiltersParams = Partial<RequestListFilterState>;

type SortFilter = {
  direction: "asc" | "desc";
  field: string;
};

type UpdateSortFiltersParams = Partial<SortFilter>;

export const updateFilters = createAsyncThunk<
  RequestListFilterState,
  UpdateFiltersParams
>("project-requests/filters/update", async (delta, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { filters: current } = state.requestList;

  let filters: RequestListFilterState = {
    ...current,
    currentPage: 1,
    ...delta,
  };

  return filters;
});

export const updateSortFilters = createAsyncThunk<
  SortFilter,
  UpdateSortFiltersParams
>("project-requests/sort/update", async (delta, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { sort } = state.requestList;

  let filters = {
    ...sort,
    ...delta,
  } satisfies SortFilter;

  return filters;
});

type RequestFiltersHandlers = {
  filters: RequestListFilterState;
  sort: {
    direction: "asc" | "desc";
    field: string;
  };
};

export const projectRequestsFiltersHandlers = <
  T extends RequestFiltersHandlers,
>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(updateFilters.fulfilled, (state, action) => {
    state.filters = action.payload;
  });

  builder.addCase(updateSortFilters.fulfilled, (state, action) => {
    state.sort = action.payload;
  });
};
