import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import React from "react";

const ProjectAttachmentsSkeleton = () => {
  return (
    <SimpleGrid columns={5} spacingX="10px" width="fit-content">
      {[...Array(5)].map((_, i) => {
        return <Skeleton w="170px" h="170px" key={i} />;
      })}
    </SimpleGrid>
  );
};

export default React.memo(ProjectAttachmentsSkeleton);
