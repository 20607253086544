import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { ApiEventGroup } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapApiAttachments } from "../../components/attachments/Attachments";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { RootState } from "../../store";
import { unloadGroups } from "../../store/event-group-slice";
import { ChangeGroup } from "./ChangeGroup";
import { EventGroupApproval } from "./EventGroupApproval";
import { EventGroupDecline } from "./EventGroupDecline";

enum GroupModal {
  approve = "approve",
  decline = "decline",
  change = "change",
}

interface GroupApprovalModalElements {
  isOpen: boolean;
  eventGroupId: string | undefined;
  eventId?: string;
  handleOnCLose: () => void;
}

export const GroupApprovalModal: FC<GroupApprovalModalElements> = ({
  isOpen,
  eventGroupId,
  eventId,
  handleOnCLose,
}) => {
  const { currentAccount, apiClient } = useAuthentication();
  const dispatch = useDispatch();
  const [option, setOption] = useState<GroupModal>(GroupModal.approve);
  const [isImage, setIsImage] = useState(false);
  const [lastInsuranceAttachment, setLastInsuranceAttachment] = useState("");
  const [eventGroupInformation, setEventGroupInformation] =
    useState<ApiEventGroup>();
  const { eventGroupList } = useSelector((state: RootState) => state);

  const handleOnCloseModal = useCallback(() => {
    dispatch(unloadGroups());
    handleOnCLose();
  }, [dispatch, handleOnCLose]);

  const handleOpenDecline = useCallback(() => {
    setOption(GroupModal.decline);
  }, []);

  const handleOpenEdit = useCallback(() => {
    setOption(GroupModal.change);
  }, []);

  const handleOpenApproved = useCallback(() => {
    setOption(GroupModal.approve);
  }, []);

  const handleCloseApproved = useCallback(() => {
    setOption(GroupModal.approve);
    handleOnCLose();
  }, [handleOnCLose]);

  const getEventGroupInformation = useCallback(async () => {
    setEventGroupInformation(
      eventGroupList.selectedEventGroup
        ? eventGroupList.selectedEventGroup
        : eventGroupList.data.find(
            (eventGroup) => eventGroup.id === eventGroupId
          )
    );

    const { data } = await apiClient.findEventGroupAttachments(
      currentAccount.id,
      eventGroupId!
    );

    const eventGroupAttachments = mapApiAttachments(data);

    if (
      eventGroupAttachments &&
      eventGroupAttachments.length > 0 &&
      eventGroupAttachments[eventGroupAttachments.length - 1].url !== null
    ) {
      var image = eventGroupAttachments[eventGroupAttachments.length - 1].url;

      (
        eventGroupAttachments[eventGroupAttachments.length - 1].type || ""
      ).indexOf("image/") > -1
        ? setIsImage(true)
        : setIsImage(false);

      image
        ? setLastInsuranceAttachment(image)
        : setLastInsuranceAttachment("");
      return;
    }
    setLastInsuranceAttachment("");
  }, [
    apiClient,
    currentAccount.id,
    eventGroupId,
    eventGroupList.selectedEventGroup,
    eventGroupList.data,
  ]);

  useEffect(() => {
    getEventGroupInformation();
  }, [getEventGroupInformation]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnCloseModal} size="sm">
      <ModalOverlay />
      <ModalContent mt={6}>
        {option === GroupModal.approve ? (
          <EventGroupApproval
            eventGroupId={eventGroupId}
            cancel={handleCloseApproved}
            handleDecline={handleOpenDecline}
            handleEdit={handleOpenEdit}
            eventGroupInformation={eventGroupInformation}
            lastInsuranceAttachment={lastInsuranceAttachment}
            isImage={isImage}
            isEvent={eventId ? true : false}
            eventId={eventId}
          />
        ) : option === GroupModal.decline ? (
          <EventGroupDecline
            eventGroupId={eventGroupId}
            eventGroupInformation={eventGroupInformation}
            cancel={handleOpenApproved}
            onClose={handleOnCLose}
          />
        ) : (
          <ChangeGroup
            eventGroupInformation={eventGroupInformation}
            cancel={handleOpenApproved}
            onClose={handleOnCLose}
            eventId={eventId}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
