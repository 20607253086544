import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from "@chakra-ui/react";
import { FC, useCallback, useRef, useState } from "react";
import { formatCurrency } from "../../../utils/formatCurrency";

type AutoSavingCurrencyInputProps = Omit<
  NumberInputProps,
  "onBlur" | "value"
> & {
  onBlur: (valueAsString?: string, valueAsNumber?: number) => Promise<void>;
  value?: number;
  isRequired?: boolean;
};

export const AutoSavingCurrencyInput: FC<AutoSavingCurrencyInputProps> = ({
  value,
  onBlur,
  isRequired = false,
}) => {
  const saving = useRef<boolean>(false);

  const [localValue, setLocalValue] = useState<{
    valueAsString?: string;
    valueAsNumber?: number;
  }>({
    valueAsNumber: value,
    valueAsString: `$ ${formatCurrency(value).substring(1)}`,
  });

  const handleOnChange = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      if (saving.current) return;
      if (
        valueAsNumber === localValue.valueAsNumber &&
        valueAsString.slice(-1) !== "."
      )
        return;
      setLocalValue({
        valueAsNumber,
        valueAsString: `$ ${valueAsString}`,
      });
    },
    [localValue, saving]
  );

  const handleOnBlur = useCallback(() => {
    saving.current = true;
    if (isRequired && !localValue.valueAsNumber) {
      setLocalValue({
        valueAsNumber: value,
        valueAsString: `$ ${formatCurrency(value).substring(1)}`,
      });
    } else {
      setLocalValue({
        ...localValue,
        valueAsString: `$ ${formatCurrency(localValue.valueAsNumber).substring(1)}`,
      });
    }
    onBlur(localValue.valueAsString, localValue.valueAsNumber).finally(() => {
      saving.current = false;
    });
  }, [localValue, onBlur, isRequired, value]);

  return (
    <NumberInput
      w="100%"
      min={0}
      alignItems="left"
      inputMode="numeric"
      value={localValue.valueAsString}
      onChange={handleOnChange}
      onBlur={() => {
        handleOnBlur();
      }}
    >
      <NumberInputField
        textAlign="left"
        _focus={{ shadow: "none" }}
        _invalid={{
          boxShadow: "none",
          borderColor: "none",
        }}
        pr="3"
      />
    </NumberInput>
  );
};
