import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  ModalFooter,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { FiscalYearRange } from "../../../../store/planning-hq/types";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { ContactsFilter } from "../filters/ContactsFilter";
import { FiscalYearsFilter } from "../filters/FiscalYearsFilter";
import { SortFilter } from "../filters/SortFilter";
import { HeaderProps } from "./Header";

type MobileHeaderProps = HeaderProps;

export const MobileHeader: FC<MobileHeaderProps> = ({
  filters,
  updateFilters,
  clearFilters,
  onNewFundingSourceClick,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleOnChange = useCallback(
    (fiscalYearRanges: FiscalYearRange[]) => {
      const { fiscalYearStart } = fiscalYearRanges.reduce<{
        fiscalYearStart: string[];
        fiscalYearEnd: string[];
      }>(
        (map, item) => {
          map.fiscalYearStart.push(item.start);
          map.fiscalYearEnd.push(item.end);
          return map;
        },
        {
          fiscalYearStart: [],
          fiscalYearEnd: [],
        }
      );

      updateFilters({ fiscalYearStart: fiscalYearStart });
    },
    [updateFilters]
  );

  return (
    <>
      <HStack justify="space-between" wrap="wrap">
        <QuickFilterButton
          activeText="Include Inactive"
          isActive={filters.includeInactive || false}
          onClick={() =>
            updateFilters({
              includeInactive: !filters.includeInactive,
            })
          }
        />
        <HStack wrap="wrap">
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
            onClick={onNewFundingSourceClick}
            size="sm"
          >
            Add funding source
          </Button>
          <Button
            leftIcon={<Icon as={RiFilterLine} />}
            size="sm"
            colorScheme="blue"
            onClick={onOpen}
            aria-label="Filters"
          >
            Filters
          </Button>
          <IconButton
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Clear"
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={clearFilters}
          />
          <SortFilter
            value={{
              sort: filters.sort ?? null,
              direction: filters.direction ?? null,
            }}
            buttonProps={{
              size: "sm",
            }}
          />
        </HStack>
      </HStack>
      {isOpen && (
        <AccountModal
          isOpen={isOpen}
          onClose={onClose}
          title={<Text>Select filters</Text>}
          bodyProps={{ m: 0 }}
          content={
            <VStack align="stretch" w="full" gap={6}>
              <InputGroup flexGrow={1}>
                <InputLeftElement children={<SearchIcon color="gray.300" />} />
                <Input
                  type="text"
                  placeholder="Search"
                  value={filters.search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateFilters({ search: e.target.value });
                  }}
                />
              </InputGroup>
              <HStack>
                <ContactsFilter
                  value={filters.contactName ?? []}
                  onChange={(value) => {
                    updateFilters({
                      contactName: value,
                    });
                  }}
                />

                <FiscalYearsFilter
                  value={filters.fiscalYearStart ?? []}
                  onChange={handleOnChange}
                />
              </HStack>
            </VStack>
          }
          footer={
            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          }
        />
      )}
    </>
  );
};
