import {
  Box,
  Image,
  ImageProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { ZoomableImageAttachment } from "./ZoomableImageAttachment";

interface ImageViewerModalProps {
  image: { src?: string; alt: string };
  imageProps?: ImageProps;
}
const GCP_STORAGE_PREFIX = "https://storage.googleapis.com";

const ImageViewerModal: FC<ImageViewerModalProps> = ({ image, imageProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const previewUrl = useMemo(() => {
    const src = image?.src;
    const previewServer = process.env.REACT_APP_IMAGE_RESIZER_SERVER;

    // preview server does not have to be configured
    if (!previewServer || !src || typeof src !== "string") {
      return src;
    }

    if (!src.startsWith(GCP_STORAGE_PREFIX)) {
      return image.src;
    }

    // remove the env prefix as well
    let path = src
      .replace(GCP_STORAGE_PREFIX, "")
      .replace(/\/oh-(dev|prod)-/, "/");

    return previewServer + "/preview" + path;
  }, [image.src]);

  return (
    <Box>
      <Image
        src={previewUrl}
        alt={image.alt}
        cursor="pointer"
        onClick={
          image.src
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                onOpen();
              }
            : undefined
        }
        {...imageProps}
      />

      {isOpen && image.src && (
        <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
          <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />{" "}
          <ModalContent
            w="full"
            h="full"
            m={0}
            p={0}
            position="relative"
            bg="transparent"
          >
            <ModalCloseButton zIndex="overlay" color="white" />
            <ModalBody
              p={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="full"
              overflow="hidden"
            >
              <ZoomableImageAttachment name={image.alt} url={image.src} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default ImageViewerModal;
