import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ApiAsset } from "@operations-hero/lib-api-client";
import { AsyncSelect, MenuPlacement } from "chakra-react-select";
import { Fragment, useCallback } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";

export interface AssetSearchAutocompleteProps {
  value: ApiAsset | null;
  onChange: (value: ApiAsset | null) => void;
  fieldRef?: any;
  locations?: string[];
  categories?: string[];
  menuPlacement?: MenuPlacement;
}

export const AssetSearchAutocomplete = ({
  onChange,
  value,
  locations,
  categories,
  fieldRef,
  menuPlacement,
}: AssetSearchAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const assets = useSelector(
    (state: RootState) => state.requestForm.assets.data,
  );

  const handleChange = useCallback(
    (newValue: ApiAsset | null) => {
      onChange(newValue);
    },
    [onChange],
  );

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findAssets(currentAccount.id, {
          search: inputValue,
          pageSize: 10,
          locations,
          categories,
        })
        .then((results) => {
          cb(results.data.filter((d) => !assets.some((a) => a.id === d.id)));
        });
    },
    [apiClient, currentAccount, assets, categories, locations],
  );

  return (
    <Fragment>
      <HStack>
        <Box flex={1}>
          <AsyncSelect
            ref={fieldRef}
            key={`taksbooks-select-${assets.length}`}
            isClearable={false}
            cacheOptions={false}
            isMulti={false}
            defaultOptions={true}
            value={value || null}
            onChange={handleChange}
            loadOptions={loadOptions}
            menuPlacement={menuPlacement}
            getOptionValue={(item: ApiAsset) => item.id}
            getOptionLabel={(item: ApiAsset) => item.name}
            placeholder={
              <Text>
                <Icon as={FaSearch} mr={4} />
                {"Search assets to add"}
              </Text>
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            openMenuOnFocus={true}
            menuShouldScrollIntoView={false}
            menuShouldBlockScroll={false}
          />
        </Box>
      </HStack>
    </Fragment>
  );
};
