import {
  ApiClient,
  ApiInventoryCostType,
  ApiInventoryItem,
  ApiInventoryRequestItem,
  ApiInventoryType,
  ApiPagingInventoryItem,
  CreateApiInventoryRequestItem,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export const itemType: Record<ApiInventoryType, string> = {
  stock: "Stock",
  checkout: "Check In/Out",
  bulkCheckout: "Bulk Check Out",
};

export const itemCostType: Record<ApiInventoryCostType, string> = {
  averageUnitCost: "Average Unit Cost",
  fixedUnitCost: "Fixed Unit Cost",
  lastPurchasePrice: "Last Purchase Price",
};

interface LoadInventoryItems {
  apiClient: ApiClient;
  accountId: string;
  isRequest?: boolean;
  filters?: Partial<ApiPagingInventoryItem>;
  itemId?: string;
}

export interface InventoryItem extends ApiInventoryItem {
  quantity?: number;
}

export interface InventoryRequestItem extends ApiInventoryRequestItem {
  image?: string | null;
}

export const loadInventoryItems = createAsyncThunk(
  "inventory-items/load",
  async (params: LoadInventoryItems, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState)
      .inventoryItemsListSlice;
    const { apiClient, accountId } = params;
    const newFilters = params.filters
      ? { ...filters, ...params.filters }
      : filters;

    const mapFilters: ApiPagingInventoryItem = {
      ...newFilters,
      catalog: Array.isArray(newFilters.catalog)
        ? newFilters.catalog.map((cat) =>
            typeof cat === "string" ? cat : cat.id
          )
        : undefined,
      storageLocation: Array.isArray(newFilters.storageLocation)
        ? newFilters.storageLocation.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      category: Array.isArray(newFilters.category)
        ? newFilters.category.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      search: params.itemId ? params.itemId : newFilters.search,
    };

    if (params.isRequest === true) {
      mapFilters.allowRequesting = true;
    }

    const response = await apiClient.findInventoryItem(accountId, mapFilters);

    return { ...response };
  }
);

export const DEFAULT_FILTERS_INVENTORY: ApiPagingInventoryItem = {
  page: 1,
  pageSize: 20,
  search: "",
  type: undefined,
  category: undefined,
  includeInactive: false,
  storageLocation: undefined,
  allowRequesting: false,
  catalog: undefined,
  showLowStock: undefined,
};

export interface InventoryItemListSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: InventoryItem[];
  total: number;
  filters: ApiPagingInventoryItem;
  selectedItemId: string | null;
  isOpenChangeQuantityModal: boolean;
  isOpenCheckoutModal: boolean;
  isOpenCheckoutTableModal: boolean;
  isOpenCheckinModal: boolean;
  isOpenStoragesInfoModal: boolean;
  isFromQR: boolean;
  workingItem: ApiInventoryItem | null;
  cartRequestItems: {
    isFormOpen: boolean;
    items: InventoryRequestItem[];
  };
}

const DEFAULT_INVENTORY_LIST: InventoryItemListSlice = {
  loading: "idle",
  data: [],
  total: 0,
  selectedItemId: null,
  filters: { ...DEFAULT_FILTERS_INVENTORY },
  isOpenChangeQuantityModal: false,
  isOpenCheckoutModal: false,
  isOpenCheckoutTableModal: false,
  isOpenCheckinModal: false,
  isOpenStoragesInfoModal: false,
  isFromQR: false,
  workingItem: null,
  cartRequestItems: {
    isFormOpen: false,
    items: [],
  },
};

export const inventoryItemsListSlice = createSlice({
  name: "inventory-item-list",
  initialState: { ...DEFAULT_INVENTORY_LIST } as InventoryItemListSlice,
  reducers: {
    unloadInventoryItemsList: (state: InventoryItemListSlice) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.workingItem = null;
      state.selectedItemId = null;
      state.filters = { ...DEFAULT_FILTERS_INVENTORY };
    },
    setSelectedItemId: (
      state: InventoryItemListSlice,
      action: PayloadAction<string>
    ) => {
      const value =
        state.selectedItemId === action.payload ? null : action.payload;
      state.selectedItemId = value;
    },
    setInventoryItemFilters: (
      state: InventoryItemListSlice,
      action: PayloadAction<Partial<ApiPagingInventoryItem>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setWorkingItem: (
      state: InventoryItemListSlice,
      action: PayloadAction<ApiInventoryItem | null>
    ) => {
      state.workingItem = action.payload;
    },
    setIsOpenChangeQuantityModal: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenChangeQuantityModal = action.payload;
    },
    setIsOpenCheckoutModal: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenCheckoutModal = action.payload;
      if (action.payload === true) {
        state.isOpenCheckoutTableModal = false;
        state.isOpenCheckinModal = false;
      }
    },
    setIsOpenCheckoutTableModal: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenCheckoutTableModal = action.payload;
      if (action.payload === true) {
        state.isOpenCheckinModal = false;
        state.isOpenCheckoutModal = false;
      }
    },
    setIsOpenCheckinModal: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenCheckinModal = action.payload;
      if (action.payload === true) {
        state.isOpenCheckoutTableModal = false;
        state.isOpenCheckoutModal = false;
      }
    },
    setIsOpenStoragesInfoModal: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenStoragesInfoModal = action.payload;
    },
    setIsFromQRModal: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.isFromQR = action.payload;
    },

    setIncludeInactiveItems: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.filters.includeInactive = action.payload;
    },
    setAllowRequestingItems: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.filters.allowRequesting = action.payload;
    },
    // Request Items
    setIsOpenInventoryRequestForm: (
      state: InventoryItemListSlice,
      action: PayloadAction<boolean>
    ) => {
      state.cartRequestItems.isFormOpen = action.payload;
    },
    addRequestItemToCart: (
      state: InventoryItemListSlice,
      action: PayloadAction<CreateApiInventoryRequestItem>
    ) => {
      const isAlreadyAdded = state.cartRequestItems.items.find(
        (item) =>
          item.item.id ===
          (typeof action.payload.item === "string"
            ? action.payload.item
            : action.payload.item.id)
      );

      if (isAlreadyAdded) return;
      state.cartRequestItems.items.unshift({
        ...action.payload,
        itemStorage: null,
        issueQuantity: 0,
      } as InventoryRequestItem);
    },
    removeRequestItemFromCart: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string }>
    ) => {
      const index = state.cartRequestItems.items.findIndex(
        (item) => item.item.id === action.payload.id
      );

      if (index !== -1) {
        state.cartRequestItems.items.splice(index, 1);
      }
      const itemIdx = state.data.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1 && state.data[itemIdx]?.quantity) {
        state.data[itemIdx].quantity = undefined;
      }
    },
    setItemQuantity: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string; quantity?: number }>
    ) => {
      const { id, quantity } = action.payload;
      const index = state.data.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.data[index].quantity = quantity;
      }
    },
    updateRequestItemQuantityCartItem: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string; quantity: number }>
    ) => {
      const { id, quantity } = action.payload;
      const index = state.cartRequestItems.items.findIndex(
        (cartItem) => cartItem.item.id === id
      );

      if (index !== -1) {
        state.cartRequestItems.items[index].quantity = quantity;
      }
    },
    updateInventoryItemFilters: (
      state: InventoryItemListSlice,
      action: PayloadAction<Partial<ApiPagingInventoryItem>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    cleanRequestItemsCart: (state: InventoryItemListSlice) => {
      state.cartRequestItems.items = [];
      state.data = state.data.map((i) => ({ ...i, quantity: undefined }));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadInventoryItems.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(loadInventoryItems.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadInventoryItems.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const {
  unloadInventoryItemsList,
  setSelectedItemId,
  setWorkingItem,
  setAllowRequestingItems,
  setInventoryItemFilters,
  setIsOpenChangeQuantityModal,
  setIsOpenCheckoutModal,
  setIsOpenCheckoutTableModal,
  setIsOpenCheckinModal,
  setIsFromQRModal,
  setIncludeInactiveItems,
  setItemQuantity,
  setIsOpenInventoryRequestForm,
  addRequestItemToCart,
  removeRequestItemFromCart,
  cleanRequestItemsCart,
  updateRequestItemQuantityCartItem,
  updateInventoryItemFilters,
  setIsOpenStoragesInfoModal,
} = inventoryItemsListSlice.actions;

export default inventoryItemsListSlice.reducer;
