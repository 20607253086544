import { SearchIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  IconButton,
  SystemStyleObject,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  AsyncSelect,
  components,
  ControlProps,
  GroupHeadingProps,
  GroupProps,
  SelectInstance,
  SingleValue,
} from "chakra-react-select";
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../../../store";
import { debounce } from "../../../../utils/debounce";
import { useAuthentication } from "../../../auth/AuthProvider";
import { createOptionComponent } from "../../../selects/select-overrides";
import { AllowedProductSearch } from "../types";
import {
  GroupOptions,
  isEventItem,
  isInventoryRequestItem,
  isInvoiceItem,
  isOrderItem,
  isRequestItem,
  SearchItem,
  SearchOptionsProps,
} from "./types";

export type SearchAutocompleteProps = {
  isSearchBoxExpanded: boolean;
  onSearchClick: (value: boolean) => void;
  onBlur: () => void;
  allowedProductSearch: AllowedProductSearch;
};

const REQUEST_REGEX = /[A-Z][A-Z0-9]{1,3}-\d*/i;
const EVENT_REGEX = /E-\d*/i;
const ORDER_REGEX = /O-\d*/i;
const NUMBER_REGEX = /^\d+$/;

export const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  onSearchClick,
  isSearchBoxExpanded = false,
  allowedProductSearch,
  onBlur,
}) => {
  const navigate = useNavigate();
  const { workflowMap } = useSelector((root: RootState) => root.localCache);

  const { apiClient, currentAccount } = useAuthentication();
  const {
    canSearchEvents,
    canSearchInvRequests,
    canSearchInvoices,
    canSearchRequests,
    canSearchOrders,
  } = allowedProductSearch;

  const slugs = Object.keys(workflowMap).map(
    (key) => workflowMap[key].requestSlug
  );

  const ref = useRef<SelectInstance<SearchItem, false>>(null);
  const selectBackground = useColorModeValue("white", "transparent");
  const { colorMode } = useColorMode();

  const GroupHeading = (props: GroupHeadingProps<SearchItem>) => {
    return (
      <components.GroupHeading {...props}>
        <Text
          fontSize="sm"
          color="gray.400"
          casing="capitalize"
          fontWeight="normal"
        >
          {`${props.data.label} (${props.data.options.length})`}
        </Text>
      </components.GroupHeading>
    );
  };

  const Group = (props: GroupProps<SearchItem, false>) => (
    <components.Group {...props}>
      <VStack
        divider={<Divider mx="6" />}
        rowGap={0}
        gap={0}
        spacing={0}
        align="stretch"
      >
        {props.children}
      </VStack>
    </components.Group>
  );

  const getSearchHighlight = useCallback(
    (searchValue: string, optionValue: string) => {
      const searchValueIdx = optionValue.indexOf(searchValue);
      return (
        <Text>
          {searchValueIdx === -1 ? (
            <>
              <Text as="span" fontWeight="bold">
                {optionValue.slice(0, searchValue.length)}
              </Text>
              <Text as="span">
                {optionValue.slice(searchValue.length, optionValue.length)}
              </Text>
            </>
          ) : (
            <>
              <Text as="span">{optionValue.slice(0, searchValueIdx)}</Text>
              <Text as="span" fontWeight="bold">
                {searchValue}
              </Text>
              <Text as="span">
                {optionValue.slice(
                  searchValueIdx + searchValue.length,
                  optionValue.length
                )}
              </Text>
            </>
          )}
        </Text>
      );
    },
    []
  );

  const SearchItemOption = ({ value: data }: { value: SearchItem }) => {
    let currentInputValue = "";
    if (ref.current) currentInputValue = ref.current.props.inputValue;

    return (
      <Box my={0}>
        {isRequestItem(data) && (
          <>
            {getSearchHighlight(currentInputValue, data.key)}
            <Text noOfLines={1}>{`${data.status.toLocaleUpperCase()} - ${
              data.summary
            }`}</Text>
          </>
        )}
        {isEventItem(data) && (
          <>
            {getSearchHighlight(currentInputValue, data.key)}
            <Text noOfLines={1}>{`${data.status.toLocaleUpperCase()} - ${
              data.name
            }`}</Text>
          </>
        )}
        {isInventoryRequestItem(data) && (
          <>
            {getSearchHighlight(
              currentInputValue,
              data.inventoryRequestNumber + ""
            )}
            <Text noOfLines={1}>{`${data.status.toLocaleUpperCase()} - ${
              data.requester.firstName
            } ${data.requester.lastName} ${
              typeof data.deliveryLocation === "string"
                ? null
                : " - " + data.deliveryLocation.name
            }`}</Text>
          </>
        )}
        {isInvoiceItem(data) && (
          <>
            {getSearchHighlight(currentInputValue, data.serialNumber + "")}
            <Text noOfLines={1}>{`${data.status.toLocaleUpperCase()} - $${
              data.total
            } - ${data.billingContact.firstName} ${
              data.billingContact.lastName
            }`}</Text>
          </>
        )}
        {isOrderItem(data) && (
          <>
            {getSearchHighlight(currentInputValue, data.key)}
            <Text noOfLines={1}>{`${data.status.toLocaleUpperCase()} - ${
              data.supplier.name
            }`}</Text>
          </>
        )}
      </Box>
    );
  };

  const CustomOptionComponent =
    createOptionComponent<SearchItem>(SearchItemOption);

  const Control = ({ children, ...props }: ControlProps<SearchItem, false>) => {
    //@ts-ignore
    const { onSearchClick } = props.selectProps;

    return isSearchBoxExpanded ? (
      <components.Control {...props}>
        <Box mx="2" as="span" mt="-0.5">
          <SearchIcon
            w={5}
            h={5}
            color={colorMode === "light" ? "gray.500" : "blue.300"}
          />
        </Box>

        {children}
      </components.Control>
    ) : (
      <Box
        color={colorMode === "light" ? "gray.500" : "#3182ce"}
        style={{
          ...(props.getStyles("control", {
            children,
            ...props,
          }) as CSSProperties),
          borderColor: colorMode === "light" ? "gray.300" : "#3182ce",
          width: "100%",
        }}
      >
        <IconButton
          zIndex="5"
          aria-label="search-icon"
          onClick={onSearchClick}
          variant="unstyled"
          icon={<SearchIcon w={5} h={5} />}
          height="full"
          mt="-0.5"
          colorScheme="blue"
        />
      </Box>
    );
  };

  const noOptionsFound = useCallback(() => {
    return (
      <Box
        h="150"
        alignContent="center"
        justifyContent="center"
        color={colorMode === "light" ? "gray.400" : "white"}
      >
        <WarningIcon color="blue.400" w="4" h="4" />
        <Text>Sorry, no matches found</Text>
        <Text>
          Try searching by ID (e.g
          <Text as="span" fontWeight="bold">
            {" "}
            WO-123
          </Text>
          )
        </Text>
      </Box>
    );
  }, [colorMode]);

  const loadOptions = useCallback(
    async (searchString: string, cb: any) => {
      const results: GroupOptions[] = [];

      const isRequest = REQUEST_REGEX.test(searchString);
      const isEvent = EVENT_REGEX.test(searchString);
      const isOrder = ORDER_REGEX.test(searchString);
      const isNumberOnly = NUMBER_REGEX.test(searchString);

      if (isRequest && canSearchRequests) {
        const requests = await apiClient.findRequests(currentAccount.id, {
          partialSearch: searchString,
        });
        results.push({
          label: "Requests",
          options: requests.data,
        });

        cb(results);
        return;
      }

      if (isEvent && canSearchEvents) {
        const eventNumber =
          searchString.split("-").length === 2
            ? searchString.toUpperCase().replace("E-", "")
            : "";

        if (!eventNumber) return;
        const events = await apiClient.findEvents(currentAccount.id, {
          partialSearch: eventNumber,
        });
        results.push({
          label: "Events",
          options: events.data,
        });
        cb(results);
        return;
      }

      if (isOrder && canSearchOrders) {
        const orderNumber =
          searchString.split("-").length === 2
            ? searchString.toUpperCase().replace("O-", "")
            : "";

        if (!orderNumber) return;
        const orders = await apiClient.findInventoryOrders(currentAccount.id, {
          partialSearch: orderNumber,
        });
        results.push({
          label: "Orders",
          options: orders.data,
        });
        cb(results);
        return;
      }
      if (isNumberOnly && canSearchRequests) {
        const requestsKeys = slugs.map((slug) => `${slug}-${searchString}`);
        const requests = await apiClient.findRequests(currentAccount.id, {
          partialSearch: requestsKeys,
        });

        results.push({
          label: "Requests",
          options: requests.data,
        });
      }

      if (isNumberOnly && canSearchEvents) {
        const events = await apiClient.findEvents(currentAccount.id, {
          partialSearch: searchString,
        });

        results.push({
          label: "Events",
          options: events.data,
        });
      }

      if (isNumberOnly && canSearchInvRequests) {
        const invRequests = await apiClient.findInventoryRequests(
          currentAccount.id,
          {
            partialSearch: searchString,
          }
        );
        results.push({
          label: "Inventory Requests",
          options: invRequests.data,
        });
      }

      if (isNumberOnly && canSearchInvoices) {
        const invoices = await apiClient.findInvoices(currentAccount.id, {
          partialSearch: searchString,
        });

        results.push({
          label: "Invoices",
          options: invoices.data,
        });
      }

      if (isNumberOnly && canSearchOrders) {
        const orders = await apiClient.findInventoryOrders(currentAccount.id, {
          partialSearch: searchString,
        });

        results.push({
          label: "Orders",
          options: orders.data,
        });
      }

      cb(results);
    },
    [
      apiClient,
      canSearchEvents,
      canSearchInvoices,
      canSearchRequests,
      canSearchInvRequests,
      canSearchOrders,
      currentAccount,
      slugs,
    ]
  );
  const debouncedLoadOptions = debounce(loadOptions, 300);

  const menuStyles = useMemo(() => {
    if (isSearchBoxExpanded)
      return {
        menu: (provided: SystemStyleObject) => ({
          ...provided,
          zIndex: 3,
          paddingY: 0,
          shadow: "base",
          rounded: "md",
        }),
        menuList: (provided: SystemStyleObject) => ({
          ...provided,
          marginY: 0,
          paddingY: 0,
        }),
      };
    else {
      return {
        menu: () => ({
          display: "hidden",
        }),
      };
    }
  }, [isSearchBoxExpanded]);

  const getUrlAsValue = (item: SearchItem) => {
    if (isRequestItem(item)) return `/requests/${item.key}`;
    if (isEventItem(item)) return `/events/${item.key}`;
    if (isInvoiceItem(item)) return `/account/invoice-list/${item.id}`;
    if (isInventoryRequestItem(item))
      return `/inventory/requests?requestInventoryId=${item.id}`;
    if (isOrderItem(item))
      return `/inventory/placed-orders?filter=${btoa(encodeURIComponent(JSON.stringify({ search: item.key })))}`;
    return "";
  };

  const onChange = useCallback(
    async (value: SingleValue<SearchItem>) => {
      if (!value) return;
      const navigateTo = getUrlAsValue(value);
      // delay added to let the search control's animation to finish smoothly before redirecting
      await new Promise((resolve) => setTimeout(resolve, 350));
      navigate(navigateTo);
    },
    [navigate]
  );

  const handleOnBlur = useCallback(() => {
    onBlur();
    if (ref.current) {
      ref.current.clearValue();
    }
  }, [onBlur]);

  useEffect(() => {
    if (isSearchBoxExpanded && ref.current) {
      ref.current?.focus();
    }
  }, [isSearchBoxExpanded]);

  return (
    <>
      <AsyncSelect<SearchItem>
        //@ts-ignore
        onSearchClick={onSearchClick}
        ref={ref}
        size="sm"
        components={{
          Option: (props: SearchOptionsProps) => CustomOptionComponent(props),
          GroupHeading,
          Group,
          DropdownIndicator: () => null,
          Control,
        }}
        loadOptions={debouncedLoadOptions as any}
        chakraStyles={{
          ...menuStyles,

          placeholder: (provided) => ({
            ...provided,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
            fontSize: "0.875rem",
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingLeft: "0px",
          }),
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            background: selectBackground,
            height: "2.097rem",
            minHeight: "2.097rem",
            borderWidth: "1px",
          }),
        }}
        onBlur={handleOnBlur}
        onChange={onChange}
        blurInputOnSelect={true}
        openMenuOnClick={false}
        placeholder={
          "Search by ID for Requests, Events, Invoices, Order & Inventory"
        }
        noOptionsMessage={noOptionsFound}
      />
    </>
  );
};
