import {
  ApiClient,
  ApiPagedResult,
  ApiPagingOptions,
  ApiTimelineEntry,
  ApiTimelineEntryObjectType,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LoadingStatus } from "../project-list";

type LoadProjectTimelineParams = {
  apiClient: ApiClient;
  projectId: string;
};
export const loadProjectTimeline = createAsyncThunk<
  ApiPagedResult<ApiTimelineEntry>,
  LoadProjectTimelineParams
>("project-timeline/load", async ({ apiClient, projectId }, thunkAPI) => {
  const { auth, projectTimelineSlice } = thunkAPI.getState() as RootState;
  const { currentAccount } = auth;
  const { filters } = projectTimelineSlice;

  const response = await apiClient.findTimelineEntries(currentAccount.id, {
    objectType: ApiTimelineEntryObjectType.project,
    objectId: projectId,
    page: filters.page || 1,
    pageSize: filters.pageSize || 10,
  });

  return response;
});

type ProjectTimelineProps = {
  filters: ApiPagingOptions;
  entries: ApiTimelineEntry[];
  loadingStatus: LoadingStatus;
};
const initialState: ProjectTimelineProps = {
  filters: { page: 1, pageSize: 10 },
  entries: [],
  loadingStatus: "idle",
};

const projectTimelineSlice = createSlice({
  name: "project-timeline",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadProjectTimeline.pending, (state, action) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(loadProjectTimeline.rejected, (state, action) => {
      state.loadingStatus = "rejected";
    });
    builder.addCase(loadProjectTimeline.fulfilled, (state, action) => {
      const { data, options } = action.payload;
      state.entries = data;
      state.filters = options;
      state.loadingStatus = "fulfilled";
    });
  },
});

export default projectTimelineSlice.reducer;
