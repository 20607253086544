import {
  ApiClient,
  ApiAccount,
  ApiComment,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface SaveCommentThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  comment: ApiComment;
  request: ApiRequest;
}

export const saveComment = createAsyncThunk(
  "request/saveComment",
  async (params: SaveCommentThunkParams, thunkAPI) => {
    const { apiClient, account, comment, request } = params;
    const newComment = await apiClient.createRequestComment(
      account.id,
      request.id,
      {
        comment: comment?.comment,
        isPublic: comment?.isPublic,
      }
    );

    return newComment;
  }
);

export const saveCommentHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(saveComment.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }
    state.comments.unshift(action.payload);
  });
};
