import {
  Button,
  Divider,
  Grid,
  GridItem,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  ApiTaskbookDetail,
  ApiTaskBookType,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  Attachment,
  mapApiAttachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ReportingCategoryAutocompleteControl } from "../../../components/form-helpers/ReportingCategoryAutocompleteControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import {
  SelectType,
  TaskBookTypeSelectControl,
} from "../../../components/form-helpers/TaskBookTypeSelectControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { useThunkDispatch } from "../../../store";
import {
  ApiTaskbookTask,
  saveTaskBook,
  TaskBookFormValues,
} from "../../../store/taskbook.slice";
import { areObjectsEquals } from "../../../utils/compareObjects";
import { SafetyNotesForm } from "./safety-notes/SafetyNotesForm";
import { TaskFormSection } from "./tasks/TaskSection";

export const newTaskPrefix = "NEW::";

const taskBookSchema = yup.object().shape({
  taskbook: yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .max(1000, "Field is too long"),
    category: yup.object().required("Field is required").nullable(),
    description: yup.string().max(1000, "Field is too long"),
  }),
  safetyNotes: yup.array().of(
    yup.object().shape({
      type: yup.string().required(),
      description: yup
        .string()
        .required("Field is required")
        .max(1000, "Field is too long"),
    })
  ),
  tasks: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Field is required"),
      description: yup.string().max(1000, "Field is too long"),
      order: yup.number(),
    })
  ),
});

const formInitialValues: TaskBookFormValues = {
  taskbook: {
    name: "",
    type: ApiTaskBookType.checklist,
    category: null,
    description: "",
    active: true,
  },
  safetyNotes: [],
  tasks: [],
  safetyNotesToDelete: [],
};

export default function TaskBooksForm() {
  const [initialValues, setInitialValues] = useState<TaskBookFormValues>();

  const [isSaving, setIsSaving] = useState(false);
  const fixedButtonsBackground = useColorModeValue("blue.50", "blue.500");

  const toast = useToast();
  const device = useBreakpointValue({
    base: "small-mobile",
    sm: "mobile",
    md: "tablet",
    lg: "desktop",
  });

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const dispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();

  const getValuesToSave = useCallback(
    async (updatedValues: TaskBookFormValues) => {
      if (initialValues) {
        const safetyNotesToUpdate =
          updatedValues.safetyNotes.length !== initialValues.safetyNotes.length
            ? updatedValues.safetyNotes
            : updatedValues.safetyNotes.filter((safetyNote) => {
                if (!safetyNote.id) {
                  return safetyNote;
                }
                const safetyNoteInitialValue = initialValues.safetyNotes.find(
                  (sn) => sn.id === safetyNote.id
                );
                return !areObjectsEquals(safetyNote, safetyNoteInitialValue);
              });
        const tasksToUpdate = await updatedValues.tasks.filter(
          (task, index) => {
            if (task.id.includes(newTaskPrefix)) {
              return task;
            } else {
              const initValueIndex = initialValues.tasks.findIndex(
                (t) => t.id === task.id
              );

              const hasNewAttachments = task.taskAttachments.some(
                (attachment: Attachment) => attachment.isNew === true
              );
              const hasSameIndex: boolean = initValueIndex === index;
              const isLastIndex = index === updatedValues.tasks.length;

              return (
                !areObjectsEquals(task, initialValues.tasks[initValueIndex]) ||
                !hasSameIndex ||
                hasNewAttachments ||
                !isLastIndex
              );
            }
          }
        );

        return { safetyNotes: safetyNotesToUpdate, tasks: tasksToUpdate };
      }
    },
    [initialValues]
  );

  const saveWorkingTaskBook = useCallback(
    async (values: TaskBookFormValues) => {
      let valuesToSave = { ...values };
      let saveTaskbookData;

      if (id) {
        const filteredValues = await getValuesToSave(values);
        if (!initialValues || !filteredValues) return;

        saveTaskbookData = !areObjectsEquals(
          values.taskbook,
          initialValues.taskbook
        );

        valuesToSave = {
          ...valuesToSave,
          safetyNotes: filteredValues?.safetyNotes,
          tasks: filteredValues?.tasks,
        };
      }

      dispatch(
        saveTaskBook({
          apiClient,
          accountId: currentAccount.id,
          taskbookFormValues: valuesToSave,
          taskBookId: id,
          saveTaskbookData: saveTaskbookData,
        })
      )
        .then(unwrapResult)
        .then((taskbookId) => {
          toast({
            position: "top",
            duration: 3000,
            isClosable: true,
            status: "success",
            title: "Task book saved",
          });
          navigate(`/account/taskbooks/${taskbookId}`);
        })
        .catch(() => {
          toast({
            position: "top",
            duration: 3000,
            isClosable: true,
            status: "error",
            title: "Error saving a task book",
          });
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [
      apiClient,
      currentAccount,
      dispatch,
      toast,
      navigate,
      id,
      initialValues,
      getValuesToSave,
    ]
  );

  const onSubmitForm = useCallback(
    (
      values: TaskBookFormValues,
      formikHelpers: FormikHelpers<TaskBookFormValues>
    ) => {
      setIsSaving(true);
      saveWorkingTaskBook(values);
      values.safetyNotesToDelete &&
        values.safetyNotesToDelete.length > 0 &&
        formikHelpers.setFieldValue("safetyNotestoDelete", []);
      setIsSaving(false);
    },
    [setIsSaving, saveWorkingTaskBook]
  );

  const getTaskbookData = useCallback(async () => {
    if (id) {
      try {
        const taskbookData: ApiTaskbookDetail = await apiClient.findTaskbook(
          currentAccount.id,
          id
        );
        const {
          name,
          description,
          type,
          category,
          safetyNotes,
          tasks,
          active,
        } = taskbookData;

        const newTasks = await Promise.all(
          tasks.map(async (task) => {
            const { data: attachments } =
              await apiClient.findTaskbookTaskAttachments(
                currentAccount.id,
                id,
                task.id
              );
            const formatedAtachment = mapApiAttachments(attachments);

            const taskWithAttachments = {
              ...task,
              taskAttachments: formatedAtachment,
            };
            return taskWithAttachments;
          })
        );

        const newFormValues = {
          taskbook: { id, name, type, description, category, active },
          safetyNotes: safetyNotes,
          tasks: newTasks.sort(compareTasksOrder),
        };

        setInitialValues(newFormValues);
      } catch (error) {
        toast({
          position: "top",
          duration: 3000,
          isClosable: true,
          status: "error",
          title: "Error loading task book data",
        });
      }
    } else {
      setInitialValues(formInitialValues);
    }
  }, [apiClient, id, currentAccount, toast]);

  const compareTasksOrder = (
    source: ApiTaskbookTask,
    target: ApiTaskbookTask
  ) => {
    return source.order < target.order ? -1 : 1;
  };

  const toogleActivateDeactivate = useCallback(
    async (
      values: TaskBookFormValues,
      setValues: (values: React.SetStateAction<TaskBookFormValues>) => void
    ) => {
      if (id) {
        try {
          const result = values.taskbook.active
            ? await apiClient.deactivateTaskbook(currentAccount.id, id)
            : await apiClient.reactivateTaskbook(currentAccount.id, id);

          const newTaskbook: TaskBookFormValues = result
            ? {
                taskbook: {
                  name: result.name,
                  description: result.description,
                  type: result.type,
                  category: result.category,
                  active: result.active,
                },
                safetyNotes: values.safetyNotes,
                tasks: values.tasks,
              }
            : { ...values, taskbook: { ...values.taskbook, active: false } };
          setValues(newTaskbook);
        } catch (error) {
          toast({
            position: "top",
            duration: 3000,
            isClosable: true,
            status: "error",
            title: "Error activating/deactivating a taskbook",
          });
        }
      }
    },
    [currentAccount, apiClient, id, toast]
  );

  useEffect(() => {
    if (!initialValues) {
      getTaskbookData();
    }
  }, [getTaskbookData, initialValues]);

  return (
    <>
      {initialValues && (
        <Formik
          onSubmit={onSubmitForm}
          initialValues={initialValues}
          validationSchema={taskBookSchema}
        >
          {({ values, setValues }) => {
            return (
              <Form>
                <Grid templateColumns="repeat(12, 2fr)" gap={4}>
                  <GridItem colSpan={[12, null, null, 8]}>
                    <TextInputControl
                      label="Name"
                      name="taskbook.name"
                      value={values.taskbook.name || null}
                    />
                  </GridItem>

                  {device === "desktop" && (
                    <GridItem
                      colSpan={4}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        mr={2}
                        size="sm"
                        type="submit"
                        height="34px"
                        fontSize="sm"
                        variant="solid"
                        colorScheme="blue"
                        isLoading={isSaving}
                      >
                        Save Changes
                      </Button>
                      {id && (
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme={values.taskbook.active ? "red" : "blue"}
                          onClick={() =>
                            toogleActivateDeactivate(values, setValues)
                          }
                        >
                          {values.taskbook.active ? "Deactive " : "Reactive "}
                          Task Book
                        </Button>
                      )}
                    </GridItem>
                  )}

                  <GridItem colSpan={12}>
                    <Divider />
                  </GridItem>

                  <GridItem colSpan={[12, null, null, 8]}>
                    <TaskBookTypeSelectControl
                      value={values.taskbook.type || null}
                      label="Type"
                      name="taskbook.type"
                      selectType={SelectType.TASK_BOOK_TYPE}
                    />
                  </GridItem>

                  <GridItem colSpan={[12, null, null, 8]}>
                    <ReportingCategoryAutocompleteControl
                      label="Category"
                      name="taskbook.category"
                      value={values.taskbook.category || null}
                    />
                  </GridItem>

                  <GridItem colSpan={[12, null, null, 8]}>
                    <TextEditorControl
                      label="Description"
                      name="taskbook.description"
                      value={values.taskbook.description || null}
                    />
                  </GridItem>

                  <GridItem colSpan={[12, null, null, 8]}>
                    <SafetyNotesForm />
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Divider />
                  </GridItem>

                  <GridItem colSpan={[12, null, null, 8]}>
                    <TaskFormSection compareTasksOrder={compareTasksOrder} />
                  </GridItem>

                  {device === "desktop" && (
                    <GridItem colSpan={[12, null, null, 8]} pt={4}>
                      <Button
                        mr={2}
                        size="md"
                        type="submit"
                        height="34px"
                        fontSize="sm"
                        variant="solid"
                        colorScheme="blue"
                        isLoading={isSaving}
                      >
                        Save Changes
                      </Button>
                    </GridItem>
                  )}

                  {device !== "desktop" && (
                    <GridItem
                      h="50px"
                      bottom={0}
                      ml={[-4, -4, 0, 0]}
                      bgColor={fixedButtonsBackground}
                      position="fixed"
                      width={["100%", "100%", "calc(100% - 270px)", null]}
                    >
                      {id && (
                        <Button
                          mt={2}
                          ml={2}
                          size="sm"
                          variant="outline"
                          colorScheme={values.taskbook.active ? "red" : "blue"}
                          onClick={() =>
                            toogleActivateDeactivate(values, setValues)
                          }
                        >
                          {values.taskbook.active ? "Deactive " : "Reactive "}
                          Task Book
                        </Button>
                      )}
                      <Button
                        mt={2}
                        mr={2}
                        size="sm"
                        type="submit"
                        float="right"
                        variant="solid"
                        colorScheme="blue"
                        isLoading={isSaving}
                      >
                        Save Changes
                      </Button>
                    </GridItem>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
