export const addTwoHours = (hours: number, minutes: number) => {
  const MAX_HOUR = 23;
  const MAX_MINUTES = 59;
  if (hours >= 22) return { newHours: MAX_HOUR, newMinutes: MAX_MINUTES };
  return { newHours: hours + 2, newMinutes: minutes };
};

export const restTwoHours = (hours: number, minutes: number) => {
  const MIN_HOUR = 0;
  const MIN_MUNTES = 0;
  if (hours <= 2) return { newHours: MIN_HOUR, newMinutes: MIN_MUNTES };
  return { newHours: hours - 2, newMinutes: minutes };
};
