import { ApiClient, ApiAccount } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface RemoveRequestAttachmentThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  idOrKey: string;
  attachmentId: string;
}

export const removeAttachment = createAsyncThunk(
  "requests/removeAttachment",
  async (params: RemoveRequestAttachmentThunkParams, thunkAPI) => {
    const { apiClient, account, idOrKey, attachmentId } = params;

    await apiClient.deleteRequestAttachment(account.id, idOrKey, attachmentId);

    return attachmentId;
  }
);
export const removeAttachmentHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(removeAttachment.fulfilled, (state, action) => {
    const idx = state.attachments.findIndex(
      (x) => x.uploadId === action.meta.arg.attachmentId
    );

    state.attachments.splice(idx, 1);
  });
};
