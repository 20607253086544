import { useState } from "react";

const determineState = (activeIndex: number, index: number) => {
  if (activeIndex > index) return "complete";
  if (activeIndex === index + 1) return "active";
  return "incomplete";
};

export const useHorizontalStep = (stepsNumber?: number) => {
  const [activeStep, setActiveStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState<number>(stepsNumber || 0);
  const [isLastStep, setIsLastStep] = useState(false);
  let factor = totalSteps - 1;

  return {
    activeStep,
    totalSteps,
    isLastStep,
    progress: (100 / factor) * (activeStep - 1),
    getStepState: (index: number) => determineState(activeStep, index),
    gotoStep: (index: number) => () => {
      setActiveStep(index + 1);
      index === totalSteps - 1 ? setIsLastStep(true) : setIsLastStep(false);
    },
    resetSteps: () => {
      factor = -1;
      setActiveStep(1);
      setTotalSteps(0);
      setIsLastStep(false);
    },
    setTotalSteps,
  };
};
