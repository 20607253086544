import { Editor } from "@operations-hero/react-draft-wysiwyg";
import "@operations-hero/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, EditorState } from "draft-js";
import { markdownToDraft } from "markdown-draft-js";
import React, { useEffect, useState } from "react";
import "./rich-text-editor-styles.css";

export interface ReadOnlyTextEditorProps {
  value: string | null | undefined;
  editorMaxHeight?: string;
}

export const ReadOnlyTextEditor: React.FC<ReadOnlyTextEditorProps> = ({
  value,
  editorMaxHeight,
}) => {
  const [content, setContent] = useState<EditorState>();
  useEffect(() => {
    if (value) {
      const convertedValue = EditorState.createWithContent(
        convertFromRaw(markdownToDraft(value))
      );
      setContent(convertedValue);
    }
  }, [value]);

  const wrapperStyle = {
    border: "none",
  };

  const editorStyle = {
    maxHeight: editorMaxHeight || "110px",
  };

  return (
    <Editor
      readOnly={true}
      toolbarHidden={true}
      editorState={content}
      wrapperStyle={wrapperStyle}
      editorStyle={editorStyle}
    />
  );
};
