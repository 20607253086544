import { Flex, Icon, Text, VStack } from "@chakra-ui/react";
import {
  ApiInventoryItemAdjustment,
  ApiInventoryOrderItem,
} from "@operations-hero/lib-api-client";
import { FC } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { TimeLineEntryFields } from "../../../../utils/timelineHelper";
import { EntryBodyProps } from "./InventoryOrderTimelineBody";

export const ItemEntry: FC<EntryBodyProps> = ({ entry }) => {
  const { delta } = entry;

  return (
    <>
      {delta
        ? delta.map(({ field, oldValue, newValue }, idx) => (
            <Flex flexDir="column">
              {field === "unitCost" && (
                <VStack gap={1} p={2} alignItems="flex-start">
                  <Text fontWeight="bold">
                    {
                      (entry.relationObject as ApiInventoryItemAdjustment)?.item
                        ?.name
                    }
                    was updated
                  </Text>
                  <Flex alignItems="center" gap={1}>
                    <Text fontWeight="semibold" as="span">
                      {TimeLineEntryFields[field]}:
                    </Text>
                    {oldValue && <Text as="span">{oldValue as number}</Text>}
                    {newValue && (
                      <>
                        <Icon as={MdArrowRightAlt} />
                        <Text as="span">{newValue as number}</Text>
                      </>
                    )}
                  </Flex>
                </VStack>
              )}
              {field === "backOrder" &&
                oldValue === false &&
                newValue === true && (
                  <Flex flexDir="column" justifyContent="center" ml={2}>
                    <Text fontWeight="bold">
                      {
                        (entry.relationObject as ApiInventoryOrderItem)?.item
                          ?.name
                      }
                      {`(x${
                        (entry.relationObject as ApiInventoryOrderItem)
                          ?.requested -
                        (entry.relationObject as ApiInventoryOrderItem)
                          ?.delivered
                      }) backOrder was Updated`}
                    </Text>
                  </Flex>
                )}
              {field === "backOrder" &&
                oldValue === true &&
                newValue === false && (
                  <Flex gap={2}>
                    <Text fontWeight="bold">
                      {
                        (entry.relationObject as ApiInventoryOrderItem)?.item
                          ?.name
                      }
                    </Text>
                    <Text>was</Text>
                    <Text fontWeight="bold" textColor="red">
                      REMOVED
                    </Text>
                    <Text> from reorder</Text>
                  </Flex>
                )}
            </Flex>
          ))
        : null}
    </>
  );
};
