import { WarningIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  ModalFooter,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAccountSettings,
  ApiRequestStatus,
} from "@operations-hero/lib-api-client";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { AutoSavingSwitch } from "../../components/inputs/AutoSavingSwitch";
import {
  ColumnViewRequestPeriodSelect,
  TimePeriodKey,
} from "../../components/selects/ColumnViewRequestPeriodSelect";
import { useShowToast } from "../../hooks/showToast";
import { RootState } from "../../store";
import { updateUserSettingToLocalCache } from "../../store/local-cache.slice";
import { isObjectEmpty } from "../../utils/compareObjects";
import {
  SETTING_CLOSED_REQUEST_TIME_PERIOD,
  SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
  SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT,
} from "../../utils/emailSettingUtils";
import { AccountModal } from "../account-settings/account-modal/AccountModal";

type ColumnViewSettingsProps = {
  handleSettingSave: (key: any) => (value: boolean) => Promise<void>;
};
export const ColumnViewSettings: FC<ColumnViewSettingsProps> = ({
  handleSettingSave,
}) => {
  const { currentAccount, apiClient } = useAuthentication();

  const userSettingsFromLocalCache = useSelector(
    (state: RootState) => state.localCache.userSettings,
  );
  const dispatch = useDispatch();
  const showToast = useShowToast();

  const update = useCallback(
    (currentAccountId: string, setting: ApiAccountSettings) => {
      return apiClient.updateCurrentUserSettings(currentAccountId, setting);
    },
    [apiClient],
  );

  const [checkedStatus, setCheckedStatus] = useState<{
    [key in ApiRequestStatus]?: boolean;
  }>(
    userSettingsFromLocalCache[
      SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
    ] as {
      [key in ApiRequestStatus]?: boolean;
    },
  );

  const [selectedStatus, setSelectedStatus] = useState<{
    status: ApiRequestStatus;
    checked: boolean;
  }>();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const onOpenChangeStatusModal = useCallback(
    (e: ChangeEvent<HTMLInputElement>, status: ApiRequestStatus) => {
      setSelectedStatus({
        status,
        checked: e.target.checked,
      });
      onOpen();
    },
    [onOpen],
  );

  const handleColumnVisibilitySetting = useCallback(
    (key: string) => (value: boolean) => {
      let enabledStatus: {
        [key in ApiRequestStatus]?: boolean;
      } = {};

      if (value) {
        enabledStatus = (
          Object.keys(ApiRequestStatus) as ApiRequestStatus[]
        ).reduce<{
          [key in ApiRequestStatus]?: boolean;
        }>((map, item) => {
          map[item] = true;
          return map;
        }, {});
      }

      return update(currentAccount.id, { [key]: enabledStatus }).then(
        (saved) => {
          dispatch(
            updateUserSettingToLocalCache({
              key: SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
              value: saved[
                SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
              ] as {
                [key in ApiRequestStatus]?: boolean;
              },
            }),
          );

          setCheckedStatus(
            saved[SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW] as {
              [key in ApiRequestStatus]?: boolean;
            },
          );
        },
      );
    },
    [currentAccount, update, dispatch],
  );

  const handleRequestClosedTimePeriod = useCallback(
    (value: TimePeriodKey) => {
      return update(currentAccount.id, {
        [SETTING_CLOSED_REQUEST_TIME_PERIOD]: value,
      })
        .then((saved) => {
          showToast("success", "Update user setting");
          dispatch(
            updateUserSettingToLocalCache({
              key: SETTING_CLOSED_REQUEST_TIME_PERIOD,
              value: saved[SETTING_CLOSED_REQUEST_TIME_PERIOD] as TimePeriodKey,
            }),
          );
        })
        .catch(() => {
          showToast("error", "Error updating user setting");
        });
    },
    [currentAccount, dispatch, update, showToast],
  );

  const updateVisibility = useCallback(() => {
    const visibilitySettings = {
      ...(userSettingsFromLocalCache[
        SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
      ] as {
        [key in ApiRequestStatus]?: boolean;
      }),
    };
    if (!selectedStatus) return;
    visibilitySettings[selectedStatus.status] = selectedStatus.checked;

    return update(currentAccount.id, {
      [SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW]:
        visibilitySettings,
    }).then((saved) => {
      dispatch(
        updateUserSettingToLocalCache({
          key: SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
          value: saved[
            SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
          ] as {
            [key in ApiRequestStatus]?: boolean;
          },
        }),
      );
      setCheckedStatus(
        saved[SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW] as {
          [key in ApiRequestStatus]?: boolean;
        },
      );
      onClose();
      showToast("success", "Update user setting");
    });
  }, [
    currentAccount,
    update,
    onClose,
    selectedStatus,
    dispatch,
    userSettingsFromLocalCache,
    showToast,
  ]);

  return (
    <>
      <AutoSavingSwitch
        id={SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT}
        isChecked={
          userSettingsFromLocalCache[
            SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT
          ] as boolean
        }
        label="Set column view as default"
        onSave={handleSettingSave(SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT)}
      />
      <AutoSavingSwitch
        id={SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW}
        isChecked={
          typeof userSettingsFromLocalCache[
            SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
          ] === "object" &&
          isObjectEmpty(
            userSettingsFromLocalCache[
              SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
            ],
          )
            ? false
            : true
        }
        label="Enable status visibility in column view"
        onSave={handleColumnVisibilitySetting(
          SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
        )}
      />
      {typeof userSettingsFromLocalCache[
        SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
      ] === "object" &&
        !isObjectEmpty(
          userSettingsFromLocalCache[
            SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW
          ],
        ) && (
          <HStack gap="3" wrap="wrap" spacing="unset">
            {(Object.keys(ApiRequestStatus) as ApiRequestStatus[]).map(
              (status) => {
                return (
                  <Checkbox
                    key={status}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onOpenChangeStatusModal(e, status)
                    }
                    isChecked={!!checkedStatus[status]}
                  >
                    {status.toUpperCase()}
                  </Checkbox>
                );
              },
            )}
          </HStack>
        )}
      <FormControl w="50%">
        <FormLabel>CLOSED Request time period</FormLabel>
        <ColumnViewRequestPeriodSelect
          value={
            userSettingsFromLocalCache[
              SETTING_CLOSED_REQUEST_TIME_PERIOD
            ] as TimePeriodKey
          }
          onChange={handleRequestClosedTimePeriod}
          timePeriodValues={["last1d", "last7d", "last14d", "last30d"]}
        />
      </FormControl>

      {isOpen && selectedStatus && (
        <AccountModal
          isOpen={isOpen}
          onClose={onClose}
          closeButton={false}
          isCentered={true}
          header={false}
          bodyProps={{
            mb: "unset",
            py: "6",
          }}
          content={
            <HStack gap="2">
              <WarningIcon w="8" h="8" color="blue.500" alignSelf="baseline" />
              <VStack justify="stretch" alignItems="left">
                <Text fontSize="lg" fontWeight="bold">
                  {selectedStatus.checked ? (
                    <Text>Show column in kanban view?</Text>
                  ) : (
                    <Text>Hide column in kanban view?</Text>
                  )}
                </Text>
                {selectedStatus.checked ? (
                  <Text>
                    The {selectedStatus.status.toUpperCase()} will be visible in
                    Column View.
                  </Text>
                ) : (
                  <>
                    <Text>
                      The {selectedStatus.status.toUpperCase()} status column
                      will be hidden and no longer be in the Column View.
                    </Text>
                  </>
                )}
              </VStack>
            </HStack>
          }
          footer={
            <ModalFooter>
              <HStack>
                <Button
                  colorScheme="gray"
                  size="sm"
                  alignSelf="end"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  size="sm"
                  alignSelf="end"
                  onClick={updateVisibility}
                >
                  {selectedStatus.checked ? "Show column " : "Hide column "}
                </Button>
              </HStack>
            </ModalFooter>
          }
        />
      )}
    </>
  );
};
