import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Spinner,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiBillingGroupType,
  ApiEventApprover,
  ApiEventGroup,
  ApiEventStatus,
  ApiGroupStatus,
} from "@operations-hero/lib-api-client";
import { differenceInMinutes } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdExpandLess, MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { UserAvatar } from "../../../components/badges/ApproverBadge";
import { ApprovalStateBadge } from "../../../components/badges/event-badges/ApprovalStatesBadges";
import { LocationTypeIconMap } from "../../../components/badges/LocationBadge";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { BigCircleIcon } from "../../../components/custom-icons/BigCircleIcons";
import {
  DEFAULT_LOCALE_DATE_OPTS,
  LocaleDate,
} from "../../../components/dates/LocaleDate";
import { useShowToast } from "../../../hooks/showToast";
import { useBillingGroupRates } from "../../../hooks/useBillingGroupRates";
import { RootState, useThunkDispatch } from "../../../store";
import {
  initLoadEventDetails,
  loadVenue,
  unloadDatesModal,
  unloadEventDetails,
} from "../../../store/events/event-details.slice";
import { toogleApproveModal } from "../../../store/events/event-modal.slice";
import { formatCurrency } from "../../../utils/formatCurrency";
import { EventGroupApprovalButton } from "../../event-group/EventGroupApprovalButton";
import { NoAvailableImage } from "../spaces/SpaceFormViews";
import { EditEquipment } from "./edit-form-components/EditEquipment";
import { EventDate } from "./edit-form-components/EventDate";
import { EventSummaryForm } from "./edit-form-components/EventSummary";
import { EventServices } from "./edit-form-components/EventUpdateServices";
import { ConflictAlert } from "./form-components/ConflictsAlert";
import { EditQuestionsResponses } from "./form-components/questions/EditQuestionsResponses";

export type toastStatus = "success" | "error";
interface EventEditProps {
  onClose: () => void;
}
export const EventEditForm: FC<EventEditProps> = ({ onClose }) => {
  const firstSectionBgColor = useColorModeValue("gray.50", "whiteAlpha.200");
  const [firstRender, setFirstRender] = useState(false);
  const [equipmentCost, setEquipmentCost] = useState(0);
  const [servicesCost, setServicesCost] = useState(0);
  const [spacesCost, setSpacesCost] = useState(0);
  const [currentEventGroup, setCurrentEventGroup] = useState<ApiEventGroup>();

  const aproveButtonText = useBreakpointValue({
    base: "Approve Event",
    xs: "Approve Event",
    sm: "Approve This Event",
  });

  const { eventDetails, newEventSlice } = useSelector(
    (state: RootState) => state
  );
  const { isInitialized, event, eventDates, eventDatesFilters, workingVenue } =
    eventDetails;
  const { eventDatesTotalPages } = eventDatesFilters;
  const { workingEventId } = newEventSlice;
  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const groupIcon =
    event?.eventGroup.status === ApiGroupStatus.approved ? "users" : "alert";
  const [groupIconColor, setGroupIconColor] = useState(
    event?.eventGroup.status === ApiGroupStatus.approved
      ? "blue.500"
      : "orange.500"
  );
  const [groupIconBgColor, setGroupIconBgColor] = useState(
    event?.eventGroup.status === ApiGroupStatus.approved
      ? "blue.50"
      : "orange.50"
  );

  useEffect(() => {
    setGroupIconColor(
      event?.eventGroup.status === ApiGroupStatus.approved
        ? "blue.500"
        : "orange.500"
    );
    setGroupIconBgColor(
      event?.eventGroup.status === ApiGroupStatus.approved
        ? "blue.50"
        : "orange.50"
    );
  }, [event?.eventGroup.status]);

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const showToast = useShowToast();
  const { apiClient, currentAccount, isEventAdmin, currentUser } =
    useAuthentication();

  useEffect(() => {
    if (!isInitialized) {
      thunkDispatch(
        initLoadEventDetails({
          apiClient,
          account: currentAccount,
          eventId: workingEventId,
          initFrom: "modal",
        })
      );
    }
  }, [apiClient, currentAccount, isInitialized, thunkDispatch, workingEventId]);

  useEffect(() => {
    if (isInitialized && event && !workingVenue) {
      thunkDispatch(
        loadVenue({
          apiClient,
          accountId: currentAccount.id,
          venueId: event.venue.id,
        })
      );
    }
  }, [
    apiClient,
    currentAccount.id,
    event,
    isInitialized,
    thunkDispatch,
    workingVenue,
  ]);

  useEffect(() => {
    if (isInitialized && isInitialized === "modal")
      return () => {
        dispatch(unloadEventDetails());
      };
  }, [dispatch, isInitialized]);

  const {
    isOpen: isOpenSummary,
    onOpen: openSummary,
    onClose: closeSummary,
  } = useDisclosure();

  const {
    isOpen: isOpenOccurrences,
    onOpen: openOcurrences,
    onClose: closeOccurrences,
  } = useDisclosure();

  const { isOpen: isOpenLocation, onToggle: toggleIsOpenLocation } =
    useDisclosure();

  const {
    isOpen: isOpenServices,
    onOpen: openServices,
    onClose: closeServices,
  } = useDisclosure();

  const {
    isOpen: isOpenEquipment,
    onOpen: openEquipment,
    onClose: closeEquipment,
  } = useDisclosure();

  const {
    isOpen: isOpenQuestions,
    onOpen: openQuestions,
    onClose: closeQuestions,
  } = useDisclosure();

  const handleOnClickSummary = useCallback(() => {
    isOpenSummary ? closeSummary() : openSummary();
  }, [closeSummary, isOpenSummary, openSummary]);

  const handleOnClickOcurrences = useCallback(() => {
    if (isOpenOccurrences) {
      dispatch(unloadDatesModal());
      closeOccurrences();
      return;
    }
    openOcurrences();
  }, [closeOccurrences, isOpenOccurrences, openOcurrences, dispatch]);

  const handleOnClickServices = useCallback(() => {
    isOpenServices ? closeServices() : openServices();
  }, [closeServices, isOpenServices, openServices]);

  const handleOnClickEquipment = useCallback(() => {
    isOpenEquipment ? closeEquipment() : openEquipment();
  }, [closeEquipment, isOpenEquipment, openEquipment]);

  const handleOnClickQuestions = useCallback(() => {
    isOpenQuestions ? closeQuestions() : openQuestions();
  }, [closeQuestions, isOpenQuestions, openQuestions]);

  const rateGroupId = useMemo(() => {
    if (!event) return;
    if (!event.eventGroup || !event.eventGroup.rateGroupId) return;
    return event.eventGroup.rateGroupId;
  }, [event]);

  const { rates, getItemRates } = useBillingGroupRates({
    rateGroupId,
    type: ApiBillingGroupType.space,
  });

  const firstThreeServices = useMemo(() => {
    const maxServicesToDisplay = 3;

    if (!event || event.services.length === 0) {
      return "No services selected";
    }

    if (event.services.length <= maxServicesToDisplay) {
      return event.services.map((item) => item.name).join(", ");
    }

    const servicesCopy = [...event.services];
    const servicesToShow = servicesCopy
      .splice(0, maxServicesToDisplay)
      .map((service) => service.name)
      .join(", ");

    const remainingNumber = event.services.length - maxServicesToDisplay;

    return `${servicesToShow} and ${remainingNumber} more`;
  }, [event]);

  const firstThreeEquipment = useMemo(() => {
    const maxEquipmentToDisplay = 3;

    if (!event || event.rentableEquipment.length === 0) {
      return "No equipment selected";
    }

    if (event.rentableEquipment.length <= maxEquipmentToDisplay) {
      return event.rentableEquipment.map((equip) => equip.name).join(", ");
    }

    const equipmentCopy = [...event.rentableEquipment];
    const equipmentToShow = equipmentCopy
      .splice(0, maxEquipmentToDisplay)
      .map((equip) => equip.name)
      .join(", ");

    const remainingNumber =
      event.rentableEquipment.length - maxEquipmentToDisplay;

    return `${equipmentToShow} and ${remainingNumber} more`;
  }, [event]);

  const handleOnApproveDeclineEvent = useCallback(
    (eventId: string, eventStatus: ApiEventStatus) => {
      dispatch(
        toogleApproveModal({
          workingEventId: eventId,
          isApproveModalOpen: true,
          eventStatus: eventStatus,
          approveOrUpdateStatus: "approve",
        })
      );
    },
    [dispatch]
  );

  const getEventApprovers = useCallback(
    (eventApprovers: ApiEventApprover[]) => {
      return eventApprovers.filter(
        (item) => item.status === ApiEventStatus.confirmed
      );
    },
    []
  );

  const isApprover = useMemo(() => {
    if (!workingVenue || !event) return false;
    const approver = workingVenue?.approvalRequiredBy.find(
      (approver) => approver.id === currentUser.id
    );

    return approver ? approver : false;
  }, [currentUser.id, event, workingVenue]);

  const canApproveOrDecline = useMemo(() => {
    if (!isApprover) return { canApprove: false, canDecline: false };

    const isRegistered = event?.eventApprovers.find(
      (item) => item.id === isApprover.id
    );

    if (!isRegistered) return { canApprove: true, canDecline: true };

    const canApprove =
      isRegistered.status === ApiEventStatus.pending ||
      isRegistered.status === ApiEventStatus.declined
        ? true
        : false;
    const canDecline =
      isRegistered.status === ApiEventStatus.pending ||
      isRegistered.status === ApiEventStatus.confirmed
        ? true
        : false;
    return { canApprove, canDecline };
  }, [isApprover, event?.eventApprovers]);

  const totalEstimatedCost = useMemo(
    () => servicesCost + equipmentCost + spacesCost,
    [equipmentCost, servicesCost, spacesCost]
  );

  const eventHoursDuration = useMemo(() => {
    const firstOccurrence = eventDates[0];
    if (!firstOccurrence) return 1;
    const { start, end } = firstOccurrence;
    if (!start || !end) return 1;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const occurrenceMinutes = differenceInMinutes(endDate, startDate);
    const hours = parseFloat((occurrenceMinutes / 60).toFixed(2));
    return hours;
  }, [eventDates]);

  const setSpacesTotalCost = useCallback(() => {
    if (!rates || !event) return;
    const total = event.spaces.reduce((acc, current) => {
      const rate = getItemRates(
        current.id,
        true,
        eventDates,
        eventHoursDuration
      );
      const equipmentCost = rate
        ? rate.reduce((sum, r) => {
            sum = sum + r.total;
            return sum;
          }, 0)
        : 0;
      return acc + equipmentCost;
    }, 0);
    setSpacesCost(total);
  }, [
    event,
    setSpacesCost,
    getItemRates,
    eventHoursDuration,
    rates,
    eventDates,
  ]);

  const getEventGroupData = useCallback(async () => {
    if (event) {
      const response = await apiClient.getEventGroup(
        currentAccount.id,
        event?.eventGroup.id
      );
      setCurrentEventGroup(response);
    }
  }, [apiClient, currentAccount.id, event]);

  useEffect(() => {
    if (!firstRender && rates && rates.size) {
      setSpacesTotalCost();
      setFirstRender(true);
    }
  }, [firstRender, rates, setSpacesTotalCost]);

  useEffect(() => {
    getEventGroupData();
  }, [getEventGroupData]);

  return (
    <>
      {isInitialized ? (
        <Stack p={4} gap={5}>
          <StackItem
            display="flex"
            gap={2}
            p={[2, 2, 4]}
            flexWrap="wrap"
            bgColor={firstSectionBgColor}
            justifyContent="space-between"
            flexDir={["column", "column", "row"]}
          >
            {event?.eventApprovers.length === 0 ? (
              <Text fontSize="sm" as="span" flex={1} fontWeight="500">
                No approvers yet
              </Text>
            ) : (
              <Box
                mr={2}
                gap={2}
                w="max-content"
                display="inline-flex"
                alignItems="center"
              >
                {event && (
                  <>
                    <Text fontWeight="bold">{`Approved by ${
                      getEventApprovers(event.eventApprovers).length
                    } of ${workingVenue?.approvalRequiredBy.length}`}</Text>
                    <UserAvatar users={event.eventApprovers} />
                  </>
                )}
              </Box>
            )}

            {(isEventAdmin || isApprover) && (
              <Box justifySelf="flex-end">
                {canApproveOrDecline.canDecline && (
                  <Button
                    variant="outline"
                    colorScheme="red"
                    size="sm"
                    w={["64px", "auto", "auto"]}
                    mr={2}
                    onClick={() =>
                      handleOnApproveDeclineEvent(
                        workingEventId,
                        ApiEventStatus.declined
                      )
                    }
                  >
                    Decline
                  </Button>
                )}

                {canApproveOrDecline.canApprove && (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    w={["108px", "auto", "auto"]}
                    onClick={() =>
                      handleOnApproveDeclineEvent(
                        workingEventId,
                        ApiEventStatus.confirmed
                      )
                    }
                  >
                    {aproveButtonText}
                  </Button>
                )}
              </Box>
            )}
          </StackItem>

          <StackItem
            display="flex"
            alignItems="center"
            flexDir="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Heading fontSize="xl">Event Summary</Heading>
            <Box display="flex" alignItems={["center", "center", "flex-end"]}>
              <Text mt={1} fontSize="sm" display="inline-flex" gap={1}>
                Created by
                <Text
                  as="span"
                  color="blue.500"
                  cursor="pointer"
                >{` ${event?.createdBy.firstName} ${event?.createdBy.lastName}`}</Text>
                on
                <LocaleDate
                  date={event?.created || ""}
                  options={{ ...DEFAULT_LOCALE_DATE_OPTS, weekday: undefined }}
                />
              </Text>
              <ApprovalStateBadge
                rest={{ ml: 2, maxH: "20px" }}
                type={event?.status || "pending"}
                label={event?.status || ""}
              />
            </Box>
          </StackItem>
          <Divider />

          <StackItem
            display="flex"
            flexDir="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box flex={1} display="inline-flex" alignItems="center">
              <BigCircleIcon
                type={groupIcon}
                iconProps={{ color: groupIconColor }}
                boxProps={{ bgColor: groupIconBgColor, mr: 4 }}
              />
              <Box>
                <Text fontSize="12px">{event?.eventGroup.name}</Text>
                <Text fontWeight="bold">{event?.name}</Text>
                {isEventAdmin && (
                  <EventGroupApprovalButton
                    groupId={event?.eventGroup.id}
                    eventId={event?.id}
                    hideButton={true}
                  />
                )}
              </Box>
            </Box>
            <Box w="30px" textAlign="right">
              <Icon
                color="blue.500"
                cursor="pointer"
                onClick={handleOnClickSummary}
                boxSize={isOpenSummary ? "24px" : "18px"}
                as={isOpenSummary ? MdExpandLess : MdModeEdit}
              />
            </Box>
            <Box w="100%" mt={isOpenSummary ? 4 : 0}>
              <Collapse in={isOpenSummary} unmountOnExit>
                <EventSummaryForm
                  onCancel={closeSummary}
                  showToast={showToast}
                />
              </Collapse>
            </Box>
          </StackItem>

          <Divider />

          <StackItem
            display="flex"
            flexDir="row"
            alignItems="center"
            flexWrap="wrap"
          >
            {eventDetails.eventDates.some(
              (item) => item.conflicts.hasConflict
            ) && (
              <ConflictAlert
                boxProps={{ mb: 4, w: "100%" }}
                includeDescription={false}
              />
            )}
            <Box
              w="calc(100% - 30px)"
              gap={6}
              display="flex"
              flexDir="row"
              flexWrap="wrap"
              alignItems="center"
            >
              <Box w={["max-content"]} display="flex" flexDir="row">
                <BigCircleIcon
                  type="calendar"
                  iconProps={{ color: "blue.500" }}
                  boxProps={{ mr: 4 }}
                />

                <Box>
                  <Text fontSize="12px">Date/Time</Text>
                  {eventDates.length > 0 ? (
                    <>
                      <Text fontWeight="bold">
                        {eventDatesTotalPages} event occurrences scheduled
                      </Text>
                      <Flex gap={1} alignItems="center" fontSize="0.9em">
                        <LocaleDate
                          date={eventDates[0].start}
                          options={{
                            ...DEFAULT_LOCALE_DATE_OPTS,
                            month: "long",
                            weekday: undefined,
                          }}
                        />
                        -
                        <LocaleDate
                          date={eventDates[eventDates.length - 1].start}
                          options={{
                            ...DEFAULT_LOCALE_DATE_OPTS,
                            month: "long",
                            weekday: undefined,
                          }}
                        />
                      </Flex>
                    </>
                  ) : (
                    <Text fontWeight="bold">0 event occurrences scheduled</Text>
                  )}
                </Box>
              </Box>

              <Box w={["max-content"]} display="flex" flexDir="row">
                <BigCircleIcon
                  type="build"
                  boxProps={{
                    mr: 4,
                    display: ["flex", "flex", "none"],
                  }}
                  iconProps={{ color: "blue.500" }}
                />
              </Box>
            </Box>

            <Box minW="30px" textAlign="right">
              <Icon
                color="blue.500"
                cursor="pointer"
                onClick={handleOnClickOcurrences}
                boxSize={isOpenOccurrences ? "24px" : "18px"}
                as={isOpenOccurrences ? MdExpandLess : MdModeEdit}
              />
            </Box>

            <Box w="100%" mt={isOpenOccurrences ? 4 : 0}>
              <Collapse in={isOpenOccurrences} unmountOnExit>
                <EventDate
                  onCancel={closeOccurrences}
                  showToast={showToast}
                  isOnList
                />
              </Collapse>
            </Box>
          </StackItem>

          <Divider />

          <StackItem
            display="flex"
            flexDir="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box flex={1} display="inline-flex" alignItems="center">
              <BigCircleIcon
                type="location"
                boxProps={{ mr: 4 }}
                iconProps={{ color: "blue.500" }}
              />
              <Box>
                <Text fontSize="12px">
                  Location{enableInvoicesForEvents && "/Prices"}
                </Text>
                {event?.spaces[0] && (
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    display="flex"
                    alignItems="center"
                  >
                    <Icon
                      mr={1}
                      as={LocationTypeIconMap[event.spaces[0].location.type]}
                    />
                    <Text as="span" fontSize="md" fontWeight="bold">
                      {event.spaces[0].location.name}
                    </Text>
                  </Text>
                )}
                <Text fontSize="12px">
                  {event?.spaces.length} spaces booked
                </Text>
              </Box>
            </Box>
            <Box w="30px" textAlign="right">
              <Icon
                color="blue.500"
                cursor="pointer"
                onClick={toggleIsOpenLocation}
                boxSize={isOpenLocation ? "24px" : "18px"}
                as={isOpenLocation ? MdExpandLess : MdModeEdit}
              />
            </Box>
            <Box w="100%" mt={isOpenLocation ? 4 : 0}>
              <Collapse in={isOpenLocation}>
                <Flex gap={5} flexDir="column" pl={4}>
                  {event && (
                    <LocationTwoLine
                      value={{
                        ...event.venue.location,
                        name: event.venue.name,
                      }}
                    />
                  )}
                  {event?.spaces.map((space) => {
                    const rate = getItemRates(
                      space.id,
                      true,
                      eventDates,
                      eventHoursDuration
                    );
                    return (
                      <Flex
                        alignItems="center"
                        key={`EditEvent:${space.id}`}
                        justifyContent="space-between"
                      >
                        <Flex gap={2} alignItems="center">
                          {space.defaultAttachment ? (
                            <Image
                              w="50px"
                              h="48px"
                              borderRadius={6}
                              src={space.defaultAttachment.url}
                            />
                          ) : (
                            <NoAvailableImage
                              showText={false}
                              iconProps={{ boxSize: "14px" }}
                              containerProps={{ w: "50px", h: "48px" }}
                            />
                          )}
                          <Text fontWeight="bold">{space.location.name}</Text>
                        </Flex>
                        {rate && enableInvoicesForEvents && (
                          <Text>
                            {formatCurrency(
                              rate.reduce((sum, r) => {
                                sum += r.total;
                                return sum;
                              }, 0)
                            )}
                          </Text>
                        )}
                      </Flex>
                    );
                  })}
                </Flex>
              </Collapse>
            </Box>
          </StackItem>

          <Divider />

          <StackItem
            display="flex"
            flexDir="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box flex={1} display="inline-flex" alignItems="center">
              <BigCircleIcon
                type="group"
                boxProps={{ mr: 4 }}
                iconProps={{ color: "blue.500" }}
              />
              <Box>
                <Text fontSize="12px">Services</Text>
                <Text fontWeight="bold">{firstThreeServices}</Text>
              </Box>
            </Box>
            <Box w="30px" textAlign="right">
              <Icon
                color="blue.500"
                cursor="pointer"
                onClick={handleOnClickServices}
                boxSize={isOpenServices ? "24px" : "18px"}
                as={isOpenServices ? MdExpandLess : MdModeEdit}
              />
            </Box>
            <Box w="100%" mt={isOpenServices ? 4 : 0}>
              <Collapse in={isOpenServices}>
                <EventServices
                  showToast={showToast}
                  onCancel={closeServices}
                  setServicesCost={setServicesCost}
                />
              </Collapse>
            </Box>
          </StackItem>

          <Divider />

          <StackItem
            display="flex"
            flexDir="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box flex={1} display="inline-flex" alignItems="center">
              <BigCircleIcon
                type="equipment"
                boxProps={{ mr: 4 }}
                iconProps={{ color: "blue.500" }}
              />
              <Box>
                <Text fontSize="12px">Equipment</Text>
                <Text fontWeight="bold">{firstThreeEquipment}</Text>
              </Box>
            </Box>
            <Box w="30px" textAlign="right">
              <Icon
                color="blue.500"
                cursor="pointer"
                onClick={handleOnClickEquipment}
                boxSize={isOpenEquipment ? "24px" : "18px"}
                as={isOpenEquipment ? MdExpandLess : MdModeEdit}
              />
            </Box>
            <Box w="100%" mt={isOpenEquipment ? 4 : 0}>
              <Collapse in={isOpenEquipment}>
                <EditEquipment
                  onCancel={closeEquipment}
                  showToast={showToast}
                  setEquipmentCost={setEquipmentCost}
                  equipmentToShow={event?.rentableEquipment}
                  occurrences={eventDates}
                />
              </Collapse>
            </Box>
          </StackItem>

          <Divider />

          <StackItem
            display="flex"
            flexDir="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box flex={1} display="inline-flex" alignItems="center">
              <BigCircleIcon
                type="questions"
                boxProps={{ mr: 4 }}
                iconProps={{ color: "blue.500" }}
              />
              <Box>
                <Text fontSize="12px">Questions</Text>
                <Text fontWeight="bold">Edit Questions</Text>
              </Box>
            </Box>
            <Box w="30px" textAlign="right">
              <Icon
                color="blue.500"
                cursor="pointer"
                onClick={handleOnClickQuestions}
                boxSize={isOpenQuestions ? "24px" : "18px"}
                as={isOpenQuestions ? MdExpandLess : MdModeEdit}
              />
            </Box>
            <Box w="100%" mt={isOpenQuestions ? 4 : 0}>
              <Collapse in={isOpenQuestions}>
                {event && (
                  <EditQuestionsResponses
                    eventId={event.id}
                    closeQuestions={closeQuestions}
                    hideDivider
                  />
                )}
              </Collapse>
            </Box>
          </StackItem>

          {totalEstimatedCost &&
            enableInvoicesForEvents &&
            currentEventGroup?.rateGroup?.generateInvoices && (
              <StackItem>
                <Heading fontSize="xl" textAlign="right">
                  Estimated Total Cost: {formatCurrency(totalEstimatedCost)}
                </Heading>
              </StackItem>
            )}

          <Divider />

          {/*TODO: Enable the following block when insurance for events functionality will be ready */}

          {/* <StackItem d="flex" flexDir="row" alignItems="center" flexWrap="wrap">
            <Box flex={1} d="inline-flex" alignItems="center">
              <BigCircleIcon
                type="watch"
                boxProps={{ mr: 4 }}
                iconProps={{ color: "blue.500" }}
              />
              <Box>
                <Text fontSize="12px">Proof of insurance</Text>
                <Text fontWeight="bold">Insurance comming soon</Text>
              </Box>
            </Box>
            <Box w="30px" textAlign="right">
              <Icon
                color="blue.500"
                cursor="pointer"
                onClick={handleOnClickInsurance}
                boxSize={isOpenInsurance ? "24px" : "18px"}
                as={isOpenInsurance ? MdExpandLess : MdModeEdit}
              />
            </Box>
            {isOpenInsurance && (
              <Box w="100%" mt={4}>
                Insurances are comming
              </Box>
            )}
          </StackItem> */}
        </Stack>
      ) : (
        <Spinner />
      )}
    </>
  );
};
