import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface CreateUpateCommentsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  requestIdOrKey: string;
  commentId?: string;
  isPublic: boolean;
  commentText: string;
}

export const createUpdateRequestComment = createAsyncThunk(
  "requests/create-update-comment",
  async (params: CreateUpateCommentsThunkParams, thunkAPI) => {
    const {
      apiClient,
      accountId,
      requestIdOrKey,
      commentId,
      isPublic,
      commentText,
    } = params;

    if (commentId) {
      const comment = await apiClient.updateRequestComment(
        accountId,
        requestIdOrKey,
        commentId,
        { comment: commentText, isPublic }
      );

      return { commentId, comment };
    } else {
      const comment = await apiClient.createRequestComment(
        accountId,
        requestIdOrKey,
        {
          comment: commentText,
          isPublic,
        }
      );
      return { commentId, comment };
    }
  }
);

export const createUpadeCommentsHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(createUpdateRequestComment.fulfilled, (state, action) => {
    const { commentId, comment } = action.payload;
    if (!state.isInit) {
      return;
    }

    if (commentId) {
      const index = state.comments.findIndex(
        (item) => item.id === commentId,
        []
      );
      if (index !== -1) {
        state.comments[index] = comment;
      }
    } else {
      state.comments.unshift(comment);
    }
  });
};
