import { Container, useDisclosure } from "@chakra-ui/react";
import { CreateApiProject } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Attachment } from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { useQueryString } from "../../../hooks/useQueryString";
import { RootState, useThunkDispatch } from "../../../store";
import {
  clearFilters,
  loadProjects,
  ProjectListFilters,
  unload as unloadProjectsList,
  updateFilters,
} from "../../../store/planning-hq/project-list";
import { unload as unloadProjectsPage } from "../../../store/planning-hq/projects-page";
import { NewProjectModal } from "../project/NewProjectModal";
import { Header } from "./header/Header";
import ProjectsSkeleton from "./ProjectsSkeleton";
import { ProjectTable } from "./ProjectsTable";

export type ProjectsProps = {};
export const Projects: FC<ProjectsProps> = () => {
  const { apiClient, currentAccount } = useAuthentication();

  const { loadingStatus, projects, filters } = useSelector(
    (state: RootState) => state.projectListSlice
  );

  const { queryStringFilter } = useSelector(
    (state: RootState) => state.projectsPageSlice
  );

  const { query } = useQueryString({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const toast = useShowToast();

  const init = useRef<{
    triggered: boolean;
    status: "idle" | "pending" | "fulfilled";
  }>({ triggered: false, status: "idle" });

  const {
    isOpen: isNewProjectModalOpen,
    onClose: onNewProjectModalClose,
    onOpen: onNewProjectModalOpen,
  } = useDisclosure();

  const handleNewProjectSubmit = useCallback(
    async (project: CreateApiProject, attachments: Attachment[]) => {
      const newProject = await apiClient
        .createProject(currentAccount.id, project)
        .then((project) => {
          return project;
        })
        .then(async (project) => {
          if (attachments.length === 0) {
            toast("success", "Project created successfully");
            return;
          }

          if (attachments.length > 0) {
            await Promise.all(
              attachments.map(async ({ uploadId, name }) =>
                apiClient.createProjectAttachment(
                  currentAccount.id,
                  project.id,
                  {
                    uploadId: uploadId || "",
                    name,
                  }
                )
              )
            )
              .catch(() => {
                toast("error", "An error ocurred while submitting attachments");
              })
              .finally(() => {
                toast("success", "Project created successfully");
              });
          }
        })
        .catch(() => {
          toast("error", "An error ocurred while creating a new project");
        })
        .finally(() => {
          thunkDispatch(loadProjects({ apiClient }));
          onNewProjectModalClose();
        });

      return newProject;
    },
    [apiClient, currentAccount.id, thunkDispatch, onNewProjectModalClose, toast]
  );

  useEffect(() => {
    if (init.current["triggered"]) return;
    init.current = {
      triggered: true,
      status: "pending",
    };

    let filterObj;
    try {
      filterObj = JSON.parse(atob(query.get("filter") || ""));
    } catch (e) {
      filterObj = null;
    }

    if (filterObj) {
      thunkDispatch(updateFilters(filterObj)).then(() => {
        thunkDispatch(loadProjects({ apiClient })).then(() => {
          init.current["status"] = "fulfilled";
        });
      });
    } else {
      thunkDispatch(
        loadProjects({ apiClient, filters: filterObj ? filterObj : undefined })
      ).then(() => {
        init.current = {
          triggered: true,
          status: "fulfilled",
        };
      });
    }
  }, [thunkDispatch, apiClient, query, navigate, dispatch]);

  useEffect(() => {
    if (init.current["triggered"] && init.current["status"] !== "fulfilled")
      return;
    thunkDispatch(loadProjects({ apiClient }));
  }, [
    thunkDispatch,
    apiClient,
    filters.page,
    filters.status,
    filters.supervisor,
    filters.search,
    filters.includeInactive,
  ]);

  useEffect(() => {
    if (!queryStringFilter) return;
    navigate(`${pathname}?filter=${queryStringFilter}`, {
      replace: true,
    });
  }, [queryStringFilter, navigate, pathname]);

  useEffect(() => {
    return () => {
      dispatch(unloadProjectsList());
      dispatch(unloadProjectsPage());
    };
  }, [dispatch]);

  const handleUpdateFilters = useCallback(
    (value: Partial<ProjectListFilters>) => {
      thunkDispatch(updateFilters(value));
    },
    [thunkDispatch]
  );

  const handleClearFilters = useCallback(() => {
    thunkDispatch(clearFilters());
  }, [thunkDispatch]);

  return (
    <Container
      maxWidth="8xl"
      display="flex"
      gap={4}
      flexDirection="column"
      p={0}
    >
      <Header
        filters={filters}
        updateFilters={handleUpdateFilters}
        clearFilters={handleClearFilters}
        onNewProjectClick={onNewProjectModalOpen}
      />
      {loadingStatus === "pending" && <ProjectsSkeleton />}

      {loadingStatus === "fulfilled" && (
        <>
          <ProjectTable projects={projects} />

          {filters.page && filters.pageSize && (
            <Pager
              showDetails
              total={filters.total}
              currentPage={filters.page}
              pageSize={filters.pageSize}
              onPageChange={(page) => {
                thunkDispatch(
                  updateFilters({
                    page,
                  })
                );
              }}
            />
          )}
        </>
      )}

      {isNewProjectModalOpen && (
        <NewProjectModal
          onSubmit={handleNewProjectSubmit}
          onClose={onNewProjectModalClose}
          isOpen={isNewProjectModalOpen}
        />
      )}
    </Container>
  );
};
