import { ApiDay, ApiHours } from "@operations-hero/lib-api-client";
import { getHourAndMinutesFromString } from "../components/weekly-time-picker/WeeklyTimePicker";

const DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export function getVenueTimesRanges(venueHours: ApiHours | null) {
  if (!venueHours) return;

  let minStartHour = 23;
  let minStartMinute = 45;

  let maxEndHour = 0;
  let maxEndMinute = 0;

  DAYS.forEach((day) => {
    if (venueHours[day as ApiDay].isOpen) {
      const { newHour: venueStartHour, newMinutes: venueStartMinutes } =
        getHourAndMinutesFromString(venueHours[day as ApiDay].start);

      const { newHour: venueEndHour, newMinutes: venueEndMinutes } =
        getHourAndMinutesFromString(venueHours[day as ApiDay].end);

      minStartHour =
        venueStartHour < minStartHour
          ? venueStartHour
          : venueStartHour === minStartHour
          ? venueStartMinutes < minStartMinute
            ? venueStartHour
            : minStartHour
          : minStartHour;

      minStartMinute =
        venueStartHour < minStartHour
          ? venueStartMinutes
          : venueStartHour === minStartHour
          ? venueStartMinutes < minStartMinute
            ? venueStartMinutes
            : minStartMinute
          : minStartMinute;

      maxEndHour =
        venueEndHour > maxEndHour
          ? venueEndHour
          : venueEndHour === maxEndHour
          ? venueEndMinutes > maxEndMinute
            ? venueEndHour
            : maxEndHour
          : maxEndHour;

      maxEndMinute =
        venueEndHour > maxEndHour
          ? venueEndMinutes
          : venueEndHour === maxEndHour
          ? venueEndMinutes > maxEndMinute
            ? venueEndMinutes
            : maxEndMinute
          : maxEndMinute;
    }
  });
  return {
    minStartHour,
    minStartMinute,
    maxEndHour,
    maxEndMinute,
  };
}
