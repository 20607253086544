import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { ApiBudget, ApiBudgetSummary } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { BudgetAutocomplete } from "../selects/BudgetAutocomplete";

export interface BudgetAutocompleteControlProps {
  label: string;
  name: string;
  value: ApiBudgetSummary | ApiBudget | null;
  placeholder?: string;
  helperText?: string;
  allowEmpty?: boolean;
}

export const BudgetAutocompleteControl = ({
  name,
  value,
  label,
  placeholder,
  helperText,
  allowEmpty,
}: BudgetAutocompleteControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (budget: ApiBudgetSummary | ApiBudget | null) => {
      helper.setTouched(true);
      helper.setValue(budget || null);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <BudgetAutocomplete
        {...field}
        name={name}
        onChange={handleOnChange}
        isClearable={allowEmpty}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
