import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import {
  ApiCategoryPrediction,
  ApiWorkflowReference,
  ApiWorkflowReportingCategoryReference,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { ReportingCategoryAutocomplete } from "../selects/ReportingCategoryAutocomplete";

export interface ReportingCategoryAutocompleteControlProps {
  label?: string | JSX.Element;
  name: string;
  value:
    | ApiWorkflowReportingCategorySummary
    | ApiWorkflowReportingCategoryReference
    | null;
  workflow?: ApiWorkflowReference;
  isDisabled?: boolean;
  predictedCategories?: ApiCategoryPrediction[] | null;
}

export const ReportingCategoryAutocompleteControl = ({
  name,
  value,
  label,
  workflow,
  isDisabled,
  predictedCategories,
}: ReportingCategoryAutocompleteControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (category: ApiWorkflowReportingCategorySummary | null) => {
      helper.setTouched(true);
      helper.setValue(category || null);
    },
    [helper]
  );

  const handleOnBlur = useCallback(() => {
    helper.setTouched(true);
  }, [helper]);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <ReportingCategoryAutocomplete
        {...field}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        name={name}
        workflow={workflow}
        isDisabled={isDisabled}
        predictedCategories={predictedCategories}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
