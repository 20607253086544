import { ApiTaskBookType } from "@operations-hero/lib-api-client";
import { Page, Text, View } from "@react-pdf/renderer";
import { PdfStyles } from "../../../print/PrintableRequest";
import { PrintTaskbookModalProps } from "./PrintTaskbookModal";

export const PrintableTaskbook = ({
  taskbook,
}: Omit<PrintTaskbookModalProps, "isOpen" | "onClose">) => {
  const allUsers = taskbook.users
    .map((user) => `${user.firstName} ${user.lastName}`)
    .join(", ");

  return (
    <Page size="A4" style={PdfStyles.page}>
      <View style={PdfStyles.header}>
        <View style={PdfStyles.headerText}>
          <Text style={{ fontSize: 20 }}>{taskbook.taskbook.name}</Text>
        </View>
      </View>

      <View wrap={true}>
        <View style={PdfStyles.fields}>
          <View style={{ marginTop: 20 }}>
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={PdfStyles.fieldLabel}>Summary or description</Text>
              </View>
            </View>
            <View
              style={{
                minHeight: 60,
                border: 1,
                borderColor: "#ccc",
                padding: 5,
              }}
            >
              <Text>{taskbook.taskbook.description}</Text>
            </View>
          </View>
          <View style={{ marginTop: 20 }}>
            <View style={PdfStyles.row}>
              <Text style={PdfStyles.fieldLabel}>Taskbook Type:</Text>
              <Text style={PdfStyles.fieldValue}>{taskbook.taskbook.type}</Text>
              <Text style={PdfStyles.fieldLabel}>Taskbook Status:</Text>
              <Text style={PdfStyles.fieldValue}>{taskbook.status}</Text>
            </View>
            <View style={PdfStyles.row}>
              <Text style={PdfStyles.fieldLabel}>Taskbook Average Score:</Text>
              <Text style={PdfStyles.fieldValue}>{taskbook.averageScore}</Text>
            </View>
            <View style={PdfStyles.row}>
              <Text style={PdfStyles.fieldLabel}>Taskbook Total Score:</Text>
              <Text style={PdfStyles.fieldValue}>{taskbook.totalScore}</Text>
            </View>
            <View style={PdfStyles.row}>
              <Text style={PdfStyles.fieldLabel}>
                Taskbook Percent Complete:
              </Text>
              <Text style={PdfStyles.fieldValue}>
                {taskbook.percentComplete}%
              </Text>
            </View>
            <View style={PdfStyles.row}>
              <Text style={PdfStyles.fieldLabel}>Taskbook Task Count:</Text>
              <Text style={PdfStyles.fieldValue}>{taskbook.taskCount}</Text>
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            <View style={PdfStyles.row}>
              <Text style={PdfStyles.fieldLabel}>Taskbook Users:</Text>
              <Text style={PdfStyles.fieldValue}>{allUsers}</Text>
            </View>
          </View>

          <View
            style={{
              flexGrow: 1,
              minHeight: 60,
              border: 1,
              borderColor: "#ccc",
            }}
          >
            <View style={PdfStyles.row}>
              <Text style={PdfStyles.fieldLabel}>Taskbook Tasks:</Text>
            </View>

            {taskbook.tasks
              .sort((a, b) => a.task.order - b.task.order)
              .map((task) => (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 2,
                    alignItems: "center",
                  }}
                  key={`taskbooKTask::${task.id}`}
                >
                  <View style={{ flex: 1, marginRight: 10 }}>
                    <Text
                      style={{
                        flexShrink: 1,
                        textAlign: "left",
                      }}
                    >
                      {task.task.name}:
                    </Text>
                  </View>
                  {taskbook.taskbook.type === ApiTaskBookType.scoring ? (
                    <View
                      style={{
                        //flexGrow: 1,
                        flexDirection: "row",
                      }}
                    >
                      {/* Scoring Table 1-5 */}
                      {[1, 2, 3, 4, 5].map((value) => (
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            border: "1px solid black",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              task.score >= value ? "gray" : "transparent",
                          }}
                          key={`taskbooKTask::${task.id}::${value}`}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {value}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ) : (
                    //Empty Box
                    <View
                      style={{
                        width: 20,
                        height: 20,
                        border: "1px solid black",
                        justifyContent: "center",
                        backgroundColor:
                          task.status === "complete" ? "gray" : "white",
                      }}
                    ></View>
                  )}
                </View>
              ))}
          </View>

          <View style={{ flexGrow: 1, marginTop: 20 }}>
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={PdfStyles.fieldLabel}>Notes</Text>
              </View>
            </View>
            <View
              style={{
                flexGrow: 1,
                minHeight: 120,
                border: 1,
                borderColor: "#ccc",
              }}
            ></View>
          </View>
        </View>
      </View>
    </Page>
  );
};
