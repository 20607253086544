import { Box, Text } from "@chakra-ui/layout";
import {
  ApiTimelineEntry,
  ApiWorkflowPolicy,
  TimelineEntryAction,
} from "@operations-hero/lib-api-client";
import { FC, useMemo } from "react";
import { UserBadge } from "../../../components/badges/UserBadge";
import {
  DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
  LocaleDate,
} from "../../../components/dates/LocaleDate";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { getPastTense } from "../../../utils/getPastTense";
import { getUserOrGroupName } from "../../../utils/getUserOrGroupName";
import { TimeLineEntryFields } from "../../../utils/timelineHelper";
import { SparkleWithAnimation } from "../../../components/icons/Sparkle";
interface CreatedEntryProps {
  entry: ApiTimelineEntry;
  field?: string;
}
export const EntryHeader: FC<CreatedEntryProps> = ({ entry, field }) => {
  const entryDeltaText = useMemo(() => {
    if (
      entry.action === TimelineEntryAction.create ||
      entry.action === TimelineEntryAction.clone ||
      entry.action === TimelineEntryAction.move ||
      entry.action === TimelineEntryAction.send ||
      entry.action === TimelineEntryAction.resend ||
      entry.action === TimelineEntryAction.cancel
    )
      return `${capitalizeFirstLetter(entry.objectType)}`;
    let result: string[] = [];
    entry.delta?.forEach(({ field }) => {
      const fieldName = TimeLineEntryFields[field];
      const isAdded = result.some((element) => element === fieldName);
      if (!isAdded) {
        result.push(fieldName);
      }
    });
    return result.join(", ");
  }, [entry]);
  const getAction = useMemo(() => {
    let actionContent;
    switch (entry.relation) {
      case "policy":
        {
          const userOrGroupPolicy = entry.relationObject
            ? (entry.relationObject as ApiWorkflowPolicy)
            : null;
          actionContent =
            entry.delta && userOrGroupPolicy && entry.delta[0]
              ? `${entry.delta[0].action} ${userOrGroupPolicy.type}`
              : "";
        }
        break;
      default:
        actionContent =
          entry.delta && entry.delta.length && entry.delta[0]
            ? entry.delta[0].action
            : getPastTense(entry.action);
        break;
    }
    return actionContent;
  }, [entry]);
  const getRelation = useMemo(() => {
    let relationContent;
    switch (entry.relation) {
      case "policy":
        const userOrGroupPolicy = entry.relationObject
          ? (entry.relationObject as ApiWorkflowPolicy)
          : null;
        relationContent =
          entry.delta &&
          userOrGroupPolicy &&
          getUserOrGroupName(userOrGroupPolicy);
        break;
      case "eventOccurrence":
        relationContent = entryDeltaText?.includes("Start")
          ? `${TimeLineEntryFields[entry.relation]} ${entryDeltaText}`
          : TimeLineEntryFields[entry.relation];
        break;
      default:
        relationContent = field
          ? TimeLineEntryFields[field]
          : entryDeltaText
            ? entryDeltaText
            : TimeLineEntryFields[`${entry.relation}`] || "";
        break;
    }
    return relationContent;
  }, [
    entryDeltaText,
    field,
    entry.relation,
    entry.delta,
    entry.relationObject,
  ]);

  return (
    <Box
      as="span"
      display="flex"
      flexDir="row"
      flexWrap="wrap"
      alignItems="center"
      pb={1}
    >
      <UserBadge
        value={entry.createdBy}
        textFontWeight="bold"
        includeAvatar={false}
      />
      <Text
        as="span"
        mt="-1px"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        {getAction}
        <Text as="span" pl={1} fontWeight="bolder">
          {`${getRelation}`}
        </Text>
        {entry.poweredByAI && (
          <Text as="span" pl={1}>
            using
            <SparkleWithAnimation />
            AI,{" "}
          </Text>
        )}
        <LocaleDate
          date={entry.created}
          options={{ ...DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS, month: "short" }}
          textProps={{ pl: 1, isTruncated: true, fontWeight: "normal" }}
        />
      </Text>
    </Box>
  );
};
