import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Stack,
  StackItem,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiLocation,
  ApiLocationSummary,
  ApiLocationType,
  CreateApiLocation,
  Label,
  UpdateApiLocation,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik, FormikHelpers, useField } from "formik";
import QRCode from "qrcode.react";
import React, { useCallback, useEffect, useState } from "react";
import { MdDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import FocusError from "../../../components/form-helpers/FocusError";
import { NumberInputControl } from "../../../components/form-helpers/NumberInputControl";
import { QrTextInputControl } from "../../../components/form-helpers/QrTextInputControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { LocationAutocomplete } from "../../../components/selects/LocationAutocomplete";
import { LocationTypeSelect } from "../../../components/selects/LocationTypeSelect";
import { StateSelect } from "../../../components/selects/StateSelect";
import { FormikObserver } from "../../../hooks/formikObserver";
import { useShowToast } from "../../../hooks/showToast";
import { useAccountDetails } from "../../../hooks/useAccountDetails";
import { RootState, useThunkDispatch } from "../../../store";
import { reloadLocations } from "../../../store/local-cache.slice";
import { updateLocationsProducts } from "../../../store/locations.slice";
import { donwloadQRCodeAsImage } from "../../../utils/downloadQR";
import { useLocationUtils } from "../../../utils/locationUtils";
import { LabelsSection } from "./LabelsSection";

const LocationFormSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required().oneOf(Object.values(ApiLocationType)),
  parent: yup.string().optional().uuid().nullable(),
  externalId: yup.string().optional().nullable(),
  phone: yup.string().optional().nullable(),
  website: yup.string().optional().url().nullable(),

  built: yup.date().optional().nullable(),
  lastRenovated: yup.date().optional().nullable(),
  squareFeet: yup.number().optional().nullable(),
  capacity: yup.number().optional().nullable(),
  enrollment: yup.number().optional().nullable(),

  address1: yup.string().optional().nullable(),
  address2: yup.string().optional().nullable(),
  city: yup.string().optional().nullable(),
  state: yup.string().optional().nullable(),
  postalCode: yup.string().optional().nullable(),
  county: yup.string().optional().nullable(),
});

interface LocationStateParent {
  id: string;
  type: ApiLocationType;
}

export default function LocationForm() {
  const { apiClient, currentAccount } = useAuthentication();
  const { accountDetails } = useAccountDetails();
  const { findFirstLevelChildren } = useLocationUtils();
  const { locationId } = useParams<{ locationId: string }>();
  const QRColorBg = useColorModeValue("white", "transparent");
  const errorColor = useColorModeValue("red.500", "red.300");
  const navigate = useNavigate();
  const locationHook = useLocation();
  const {
    isOpen: delConfirmIsOpen,
    onOpen: delConfirmOnOpen,
    onClose: delConfirmOnClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { locationMap } = useSelector((state: RootState) => state.localCache);

  const { locationsProducts } = useSelector(
    (state: RootState) => state.locations
  );

  const locationStateParent = locationHook.state as
    | LocationStateParent
    | undefined;

  const [notFound, setNotFound] = useState(false);
  const [location, setLocation] = useState<ApiLocation>();
  const [active, setActive] = useState(locationMap[locationId || ""]?.active);
  const [labels, setLabels] = useState<Label[] | null>(
    locationMap[locationId || ""]?.labels
  );
  const [repeatedNameError, setRepeatedNameError] = useState(false);
  const showToast = useShowToast();

  const saveLocation = useCallback(
    (
      values: CreateApiLocation | UpdateApiLocation,
      actions: FormikHelpers<ApiLocation>
    ) => {
      let emptyLabels = undefined;
      if (labels) {
        for (let i = 0; i < labels.length; i++) {
          if (labels[i].key === "") {
            emptyLabels = `key${i}`;
            break;
          }
          if (labels[i].value === "") {
            emptyLabels = `value${i}`;
            break;
          }
        }
      }
      const newValues = { ...values };
      newValues.labels = labels;

      if (!location) {
        return Promise.resolve();
      }
      const children = values.parent
        ? findFirstLevelChildren(values.parent, location.id)
        : [];
      if (children.some((child) => child.name === values.name)) {
        setRepeatedNameError(true);
        return Promise.resolve();
      }
      if (!emptyLabels) {
        const promise =
          location.id.length > 0
            ? apiClient.updateLocation(
                currentAccount.id,
                location.id,
                newValues as UpdateApiLocation
              )
            : apiClient.createLocation(
                currentAccount.id,
                newValues as CreateApiLocation
              );

        return promise
          .then(async (savedLocation) => {
            let newLocationProducts = { ...locationsProducts };
            if (
              Object.keys(newLocationProducts).some((key) =>
                key.includes(savedLocation.id)
              )
            ) {
              newLocationProducts[savedLocation.id] =
                savedLocation.hiddenProducts;
            } else {
              const products: Record<string, string[]> = {
                [savedLocation.id]: savedLocation.hiddenProducts,
              };
              newLocationProducts = { ...newLocationProducts, ...products };
            }

            dispatch(
              updateLocationsProducts({
                locationsProducts: newLocationProducts,
              })
            );
            setLocation(savedLocation);
            await thunkDispatch(
              reloadLocations({ apiClient, account: currentAccount })
            ).then(unwrapResult);
            if (locationStateParent) {
              const { id } = locationMap[locationStateParent.id];
              const { type } = values;
              navigate("/account/locations/new", {
                state: { id, type },
              });
              showToast(
                "success",
                `Location ${values.name} was created succesfully`
              );
              return Promise.resolve();
            }
            const updatedOrCreated =
              location.id.length > 0 ? "updated" : "created";
            showToast(
              "success",
              `Location ${values.name} was ${updatedOrCreated} succesfully`
            );
            navigate(`/account/locations`, {
              state: {
                autoFocus: location.id.length === 0,
              },
            });
          })
          .catch(() => {
            showToast("error", "Error Saving Location");
          });
      }
      document.getElementById(emptyLabels)?.focus();
      return Promise.resolve();
    },
    [
      labels,
      location,
      apiClient,
      currentAccount,
      dispatch,
      locationsProducts,
      thunkDispatch,
      locationStateParent,
      showToast,
      navigate,
      locationMap,
      findFirstLevelChildren,
    ]
  );

  const handleDownloadQRCode = useCallback((location: ApiLocation) => {
    const elementId = `location_${location.id}`;
    donwloadQRCodeAsImage(elementId);
  }, []);

  const handleDisableLocationToggle = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!locationId) return;
      active
        ? await apiClient.deactivateLocation(currentAccount.id, locationId)
        : await apiClient.reactivateLocation(currentAccount.id, locationId);

      thunkDispatch(reloadLocations({ apiClient, account: currentAccount }))
        .then(unwrapResult)
        .then(() => {
          showToast(
            "success",
            active ? "Location deactivated" : "Location activated"
          );
          delConfirmOnClose();
        })
        .catch(() => {
          showToast(
            "error",
            active ? "Error disabling Location" : "Error enabling Location"
          );
          delConfirmOnClose();
        })
        .then(() => {
          navigate(`/account/locations`);
          setActive(!active);
        });
    },
    [
      active,
      apiClient,
      currentAccount,
      thunkDispatch,
      locationId,
      showToast,
      delConfirmOnClose,
      navigate,
    ]
  );

  const handleSetLabels = useCallback((labels: Label[]) => {
    setLabels(labels);
  }, []);

  const redirectWithParent = useCallback(
    (id?: string) => () => {
      if (!id) return;
      const { type } = locationMap[id];
      navigate(`/account/locations/new`, {
        state: {
          id,
          type,
        },
      });
    },
    [navigate, locationMap]
  );

  const clearErrorName = useCallback(() => {
    setRepeatedNameError(false);
  }, []);
  useEffect(() => {
    if (!locationId) return;
    if (locationId === "new") {
      setLocation({
        id: "",
        type: locationStateParent
          ? locationStateParent.type
          : ApiLocationType.building,
        name: "",
        externalId: null,
        active: true,
        parent: locationStateParent ? locationStateParent.id : null,
        phone: null,
        website: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        county: null,
        latitude: null,
        longitude: null,
        built: null,
        lastRenovated: null,
        squareFeet: null,
        capacity: null,
        enrollment: null,
        labels: labels,
        treePath: "",
        hiddenProducts: [],
      });
      return;
    }

    const foundLocation = locationMap[locationId];

    if (!foundLocation) {
      setNotFound(true);
      return;
    }

    setLocation(foundLocation);
  }, [labels, locationMap, locationId, locationStateParent]);

  return (
    <Stack flexDir="row" flexWrap="wrap" spacing={6}>
      <StackItem w="100%">
        <Flex
          justifyContent="space-between"
          flexDirection={["column", "column", "row"]}
        >
          {locationId !== "new" ? (
            <>
              <Box display="flex" alignItems="center">
                <PageHeader heading={`Editing Location`} />
                <Button
                  size="md"
                  ml={4}
                  onClick={redirectWithParent(locationId)}
                >
                  Add Child Location
                </Button>
              </Box>

              <Button
                size="md"
                alignSelf={["flex-end", "flex-end", "center"]}
                colorScheme={
                  locationMap[locationId || ""].active ? "red" : "blue"
                }
                onClick={() => {
                  delConfirmOnOpen();
                }}
              >
                {locationMap[locationId || ""].active
                  ? "Deactivate "
                  : "Reactivate "}
                Location
              </Button>
            </>
          ) : (
            <PageHeader heading={`New Location`} />
          )}
        </Flex>
        <Modal isOpen={delConfirmIsOpen} onClose={delConfirmOnClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{active ? "Disable" : "Enable"} Location?</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={4}>
              {active ? (
                <Text>
                  The selected location and its children will be disabled. You
                  will not be able to create a new Request with inactive
                  locations. Would you like to proceed?
                </Text>
              ) : (
                <Text>
                  The selected location and its children will be enable again.
                  Would you like to proceed?
                </Text>
              )}
            </ModalBody>
            <ModalFooter justifyContent="space-between">
              <Button
                onClick={(e) => handleDisableLocationToggle(e)}
                colorScheme={active ? "red" : "green"}
              >
                {active ? "Disable" : "Enable"}
              </Button>
              <Button onClick={delConfirmOnClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {notFound === true && (
          <Box pt={8}>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>Error loading location.</AlertTitle>
              <AlertDescription>
                The location{" "}
                <Text display="inline" as="pre">
                  {locationId}
                </Text>{" "}
                was not found in this account
              </AlertDescription>
            </Alert>
          </Box>
        )}
      </StackItem>

      <StackItem w={["100%", "100%", "100%", "70%"]}>
        {!location ? (
          <LocationSkeleton />
        ) : (
          <Formik
            initialValues={location}
            validationSchema={LocationFormSchema}
            onSubmit={saveLocation}
            enableReinitialize
          >
            {(props) => (
              <Form>
                <FormikObserver cb={clearErrorName} />
                <Stack spacing={6} w={["100%", "100%", "100%", "94%"]}>
                  <TextInputControl
                    name="name"
                    value={location.name}
                    label="Name"
                    placeholder="Location Name"
                  />
                  {repeatedNameError && (
                    <Text mt={1} color={errorColor}>
                      A child location with the same name already exists
                    </Text>
                  )}
                  <LocationTypeSelectControl
                    name="type"
                    value={location.type}
                  />

                  <ParentSelectControl
                    name="parent"
                    value={location.parent}
                    locationId={locationId}
                  />

                  <QrTextInputControl
                    name="externalId"
                    value={location.externalId}
                    label="Tag / Alternate ID"
                    placeholder="000001"
                    helperText="Reuse existing labels, barcodes or QR codes"
                  />

                  <TextInputControl
                    name="phone"
                    value={location.phone}
                    label="Phone / Extension"
                    placeholder="555-555-5555 or x123"
                  />

                  <TextInputControl
                    name="website"
                    value={location.website}
                    label="Website"
                    placeholder="https://district.k12.state.gov/location"
                  />

                  {(props.values.type || location.type) === "building" ||
                  (props.values.type || location.type) === "field" ||
                  (props.values.type || location.type) === "room" ? (
                    <>
                      <DatePickerControl
                        // @ts-ignore
                        value={location.built}
                        name="built"
                        label="Built"
                        showTime={false}
                      />
                      <DatePickerControl
                        // @ts-ignore
                        value={location.lastRenovated}
                        name="lastRenovated"
                        label="Last Renovated"
                        showTime={false}
                      />
                      <NumberInputControl
                        // @ts-ignore
                        value={location.squareFeet}
                        name="squareFeet"
                        label="Square Feet"
                        placeholder="Square Feet"
                      />
                      <NumberInputControl
                        // @ts-ignore
                        value={location.capacity}
                        name="capacity"
                        label="Capacity"
                        placeholder="Capacity"
                      />
                      {(props.values.type || location.type) === "building" && (
                        <NumberInputControl
                          // @ts-ignore
                          value={location.enrollment}
                          name="enrollment"
                          label="Enrollment"
                          placeholder="Enrollment"
                        />
                      )}
                    </>
                  ) : null}

                  {(props.values.type || location.type) === "building" ||
                  (props.values.type || location.type) === "field" ||
                  (props.values.type || location.type) === "region" ? (
                    <>
                      <TextInputControl
                        // @ts-ignore
                        value={location.address1}
                        name="address1"
                        label="Address Line 1"
                        placeholder="Address"
                      />
                      <TextInputControl
                        // @ts-ignore
                        value={location.address2}
                        name="address2"
                        label="Address Line 2"
                        placeholder="Line 2"
                      />
                      <TextInputControl
                        // @ts-ignore
                        value={location.city}
                        name="city"
                        label="City"
                        placeholder="City"
                      />
                      <StateSelectControl
                        name="state"
                        /* @ts-ignore */
                        value={location.state}
                        country={accountDetails?.address.country || undefined}
                      />
                      <TextInputControl
                        // @ts-ignore
                        value={location.postalCode}
                        name="postalCode"
                        label="Postal Code"
                        placeholder="Postal Code"
                      />
                    </>
                  ) : null}
                  <LabelsSection
                    type="location"
                    value={location.labels}
                    setValues={handleSetLabels}
                  />
                  <Box textAlign={["center", "center", "left"]} pt={2}>
                    <Button
                      size="sm"
                      minW="80px"
                      type="submit"
                      colorScheme={"blue"}
                      isLoading={props.isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                  <FocusError />
                </Stack>
              </Form>
            )}
          </Formik>
        )}
      </StackItem>
      <StackItem w={["100%", "100%", "100%", "30%"]} pt={[2, 2, 2, 6]}>
        {location && location.id && (
          <>
            <Box
              p={1}
              display="flex"
              bgColor={QRColorBg}
              minW={["100%", "100%", "250px"]}
              justifyContent={["center", "center", "left"]}
            >
              <QRCode
                size={250}
                id={`location_${location.id}`}
                includeMargin={true}
                value={
                  location.externalId
                    ? `${location.externalId}`
                    : `accounts/${currentAccount.id}/locations/${location.id}`
                }
              />
            </Box>
            <Flex
              pt={6}
              maxW={260}
              justifyContent="center"
              minW={["100%", "100%", "250px"]}
            >
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => handleDownloadQRCode(location)}
              >
                <Icon as={MdDownload} mr={2} mt={0.5} /> Download QR
              </Button>
            </Flex>
          </>
        )}
      </StackItem>
    </Stack>
  );
}

const LocationSkeleton = () => <SkeletonText />;

const LocationTypeSelectControl = ({
  name,
  value,
}: {
  name: string;
  value: ApiLocationType;
}) => {
  const [field, meta, helper] = useField<ApiLocationType>({
    name,
    value,
    defaultValue: ApiLocationType.building,
  });

  const handleOnChange = useCallback(
    (newValue: ApiLocationType) => {
      helper.setTouched(true);
      helper.setValue(newValue);
    },
    [helper]
  );
  return (
    <FormControl id={name}>
      <FormLabel htmlFor={name}>Type</FormLabel>
      <LocationTypeSelect value={field.value} onChange={handleOnChange} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

const ParentSelectControl = ({
  name,
  value,
  locationId,
}: {
  name: string;
  value: string | null;
  locationId?: string;
}) => {
  const { locations, locationMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const locationsToHide =
    locationId && locationId !== "new"
      ? locations
          .filter(
            (l) => l.treePath.indexOf(locationMap[locationId].treePath) >= 0
          )
          .map((loc) => loc.id)
      : [];
  const [field, meta, helper] = useField({
    name,
    value: value || "",
  });

  const handleOnChange = useCallback(
    (location: ApiLocationSummary | null) => {
      helper.setTouched(true);
      helper.setValue(location ? location.id : null);
    },
    [helper]
  );

  return (
    <FormControl id={name} mb={7}>
      <FormLabel htmlFor={name}>Parent</FormLabel>
      <LocationAutocomplete
        value={field.value}
        onChange={handleOnChange}
        hideLocations={locationsToHide}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const StateSelectControl = ({
  name,
  value,
  label,
  country,
  isDisabled,
}: {
  name: string;
  value: string | null;
  country?: string;
  isDisabled?: boolean;
  label?: string;
}) => {
  const [field, meta, helper] = useField({
    name,
    value: value || "",
  });

  const handleOnChange = useCallback(
    (state: string | null) => {
      helper.setTouched(true);
      helper.setValue(state || undefined);
    },
    [helper]
  );

  return (
    <FormControl id={name} mb={7}>
      <FormLabel htmlFor={name}>{label || "State/Province"}</FormLabel>
      <StateSelect
        key={`stateSelect::${name}`}
        value={field.value}
        onChange={handleOnChange}
        allowEmpty={true}
        country={country}
        isDisabled={isDisabled}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
