import { Avatar, AvatarGroup, Box, Tooltip } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { UserBadge } from "./UserBadge";

interface UserAvatarProps {
  users: ApiUserSummary[];
}

export const UserAvatar: FC<UserAvatarProps> = ({ users }) => {
  return users.length === 0 ? null : (
    <Box display="flex" alignItems="center">
      {users.length === 1 ? (
        <>
          <UserBadge value={users[0]} />
        </>
      ) : (
        <AvatarGroup
          size="sm"
          max={4}
          justifyContent={["left", "left", "right"]}
        >
          {users.map((user) => (
            <Tooltip
              key={`userAvatar::${user.id}`}
              label={`${user.firstName} ${user.lastName} - ${user.email}`}
            >
              <Avatar
                key={user.id}
                name={user.firstName}
                src={user.profileImage}
              />
            </Tooltip>
          ))}
        </AvatarGroup>
      )}
    </Box>
  );
};
