import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Box,
  CloseButton,
  Container,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiAccountSubscription } from "@operations-hero/lib-api-client";
import { subDays } from "date-fns";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAuthentication } from "../auth/AuthProvider";

interface customMessages {
  [key: string]: {
    title: string;
    description: string;
  };
}

const generalMessages: customMessages = {
  errorLoading: {
    title: "Error loading required data.",
    description:
      "Please refresh your browser. If the problem persists, please contact support",
  },
  noAccountWarning: {
    title: "Your user account has been disabled.",
    description: "Please contact your site administrator.",
  },
  noProducts: {
    title: "Your account has no active products",
    description: "Please contact your site administrator.",
  },
  noEmailVerified: {
    title: "Your email is not verified yet",
    description: "Please check your email inbox to verify your email",
  },
  notAllowed: {
    title: "Your user account does not have access to this page",
    description: "Please contact your site administrator.",
  },
};

export type AppAlertType =
  | "errorLoading"
  | "noAccountWarning"
  | "noProducts"
  | "noEmailVerified"
  | "notAllowed";

interface AppAlertProps {
  alertType: AppAlertType;
  status?: AlertStatus;
}

export const AppAlert: FC<AppAlertProps> = ({
  alertType,
  status = "error",
}) => {
  return (
    <Flex w="100%" pb={4} pt={6}>
      <Container maxWidth="8xl">
        <Alert status={status}>
          <AlertIcon />
          <Box w="100%">
            <AlertTitle>{generalMessages[alertType].title}</AlertTitle>
            <AlertDescription>
              {generalMessages[alertType].description}
            </AlertDescription>
          </Box>
        </Alert>
      </Container>
    </Flex>
  );
};

const getSubscriptionAlertMessage = (
  subscription?: ApiAccountSubscription,
  name?: string
): customMessages => ({
  expired: {
    title: `Your ${name} account expired ${
      subscription && new Date(subscription.expires).toLocaleString()
    }`,
    description: "Please contact sales or support to renew your subscription.",
  },
  notSubscribed: {
    title: `Your user account does not have a subscription to ${name}.`,
    description: "Please contact sales or support subscribe your account.",
  },
});

interface SubscriptionAlertProps {
  name: string;
  subscription?: ApiAccountSubscription;
}
export const SubscriptionAlert: FC<SubscriptionAlertProps> = ({
  subscription,
  name,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: true });
  const [alertStatus, setAlertStatus] = useState<AlertStatus>("error");
  const [displaycloseButton, setDisplayCloseButton] = useState(false);

  const alertMessage = useMemo(
    () => getSubscriptionAlertMessage(subscription, name),
    [name, subscription]
  );

  const alertType = useMemo(() => {
    if (!subscription) {
      setAlertStatus("error");
      setDisplayCloseButton(true);
      onOpen();
      return "notSubscribed";
    }

    const now = new Date().getTime();
    const expires = new Date(subscription.expires).getTime();

    if (now > expires) {
      setAlertStatus("error");
      setDisplayCloseButton(true);
      onOpen();
      return "expired";
    }

    return null;
  }, [onOpen, subscription]);

  return alertType && alertType !== "notSubscribed" && isOpen ? (
    <Flex w="100%" pb={0} pt={4}>
      <Container maxWidth="8xl">
        <Alert status={alertStatus}>
          <AlertIcon />
          <Box w="100%">
            <AlertTitle>{alertMessage[alertType].title}</AlertTitle>
            <AlertDescription>
              {alertMessage[alertType].description}
            </AlertDescription>
          </Box>
          {displaycloseButton && (
            <CloseButton
              top={-1}
              right={-1}
              onClick={onClose}
              position="relative"
              alignSelf="flex-start"
            />
          )}
        </Alert>
      </Container>
    </Flex>
  ) : null;
};

const EXPIRE_WARNING_DAYS = 30;

export const WillExpireSoonAlert: FC<{
  subscriptions: ApiAccountSubscription[] | undefined;
}> = ({ subscriptions }) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: true });
  const { isProductAdmin } = useAuthentication();
  const { policyAllowsDashboard } = useSelector(
    (state: RootState) => state.localCache
  );
  const emailColor = useColorModeValue("blue.500", "blue.300");

  const sortedSubscriptions = useMemo(() => {
    if (!subscriptions) return [];

    return subscriptions.sort((a, b) => {
      const firstTime = new Date(a.expires).getTime();
      const secondTime = new Date(b.expires).getTime();
      return firstTime - secondTime;
    });
  }, [subscriptions]);

  const firstExpiring = useMemo(() => {
    const now = new Date().getTime();
    const isAboutToExpire = sortedSubscriptions.find((sub) => {
      const time = subDays(
        new Date(sub.expires),
        EXPIRE_WARNING_DAYS
      ).getTime();
      if (now > time) return sub;
      return undefined;
    });
    if (isAboutToExpire) {
      if (policyAllowsDashboard || isProductAdmin) {
        onOpen();
        return isAboutToExpire;
      }
    }
  }, [isProductAdmin, onOpen, policyAllowsDashboard, sortedSubscriptions]);

  const alertMessage = useMemo(() => {
    if (!firstExpiring) return;

    return {
      title: `Your ${
        firstExpiring.isTrial ? "trial" : "subscription"
      } is expiring on ${new Date(firstExpiring.expires).toLocaleString()}`,

      description: (
        <>
          {firstExpiring.isTrial ? (
            <Text as="span" display="flex" flexWrap="wrap">
              Please contact
              <Text
                as="a"
                mx={1}
                w="max-content"
                color={emailColor}
                wordBreak="keep-all"
                href="mailto:suport@operationshero.com"
              >
                support@operationshero.com
              </Text>
              for support with your trial and
              <Text
                as="a"
                mx={1}
                w="max-content"
                color={emailColor}
                wordBreak="keep-all"
                href="mailto:sales@operationshero.com"
              >
                sales@operationshero.com
              </Text>
              to receive pricing/references
            </Text>
          ) : (
            <Text as="span" display="flex" flexWrap="wrap">
              Please contact
              <Text
                as="a"
                mx={1}
                w="max-content"
                color={emailColor}
                wordBreak="keep-all"
                href="mailto:sales@operationshero.com"
              >
                sales@operationshero.com
              </Text>
              to receive your renewal invoice
            </Text>
          )}
        </>
      ),
    };
  }, [emailColor, firstExpiring]);

  return alertMessage && isOpen ? (
    <Flex w="100%" pb={0} pt={4}>
      <Container maxWidth="8xl" display="flex" gap={2} flexDir="column">
        <Alert status="info">
          <AlertIcon />
          <Flex w="100%" flexDir="column">
            <AlertTitle>{alertMessage.title}</AlertTitle>
            <AlertDescription>{alertMessage.description}</AlertDescription>
          </Flex>
          <CloseButton
            top={-1}
            right={-1}
            onClick={onClose}
            position="relative"
            alignSelf="flex-start"
          />
        </Alert>
      </Container>
    </Flex>
  ) : null;
};
