import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";
import { BiSortAlt2 } from "react-icons/bi";

interface SortDirectionFilterProps {
  handleSortDirectionChange: (value: string | string[]) => void;
  handleSortFieldChange: (value: string | string[]) => void;
  direction?: "asc" | "desc";
  sort?: string;
  sortOptions: Record<string, string>;
}
export const SortDirectionFilter: FC<SortDirectionFilterProps> = ({
  handleSortDirectionChange,
  handleSortFieldChange,
  direction,
  sort,
  sortOptions,
}) => {
  const menuBg = useColorModeValue("white", "transparent");

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={BiSortAlt2} />}
        aria-label="Sort"
        colorScheme="blue"
        variant="outline"
        bgColor={menuBg}
      />
      <MenuList>
        <MenuOptionGroup
          defaultValue="desc"
          title="Order"
          value={direction}
          onChange={handleSortDirectionChange}
          type="radio"
        >
          <MenuItemOption value="asc">Ascending</MenuItemOption>
          <MenuItemOption value="desc">Descending</MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuOptionGroup
          title="Field"
          value={sort}
          onChange={handleSortFieldChange}
          type="radio"
        >
          {Object.keys(sortOptions).map((key) => (
            <MenuItemOption value={key} key={`sortOption::${key}`}>
              {sortOptions[key]}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
