import { Box, Flex, FormLabel, Heading, Stack, Text } from "@chakra-ui/react";
import { ApiBudgetSummary, ApiRequest } from "@operations-hero/lib-api-client";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { BudgetAutocomplete } from "../../../../components/selects/BudgetAutocomplete";
import { RootState } from "../../../../store";
import { getVisibleFields } from "../../../../utils/getVisibleFields";
import { EstimatedHours } from "../../EstimateHours";
import { EstimatedExpenses } from "./EstimateExpenses";

interface CostSectionProps {
  handlePropertyChange: (
    delta: Partial<ApiRequest>,
    isChangeModal?: any
  ) => void;
}
export const CostSection: React.FC<CostSectionProps> = ({
  handlePropertyChange,
}) => {
  const {
    isRequesterOnly,
    isApproverOnly,
    request,
    workflow,
    isContractorOnly,
    isContractor,
    visibleFields,
  } = useSelector((state: RootState) => state.requestForm);

  const {
    showBudgetOnRequests,
    showExpenses,
    showEstimatedCost,
    showLabor,
    showEstimatedLabor,
  } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const handleBudgetChange = useCallback(
    (budget: ApiBudgetSummary | null) => {
      handlePropertyChange({ budget });
    },
    [handlePropertyChange]
  );

  const hasCostPolicy = useMemo(() => {
    return (
      !isRequesterOnly && !isApproverOnly && !isContractorOnly && !isContractor
    );
  }, [isRequesterOnly, isApproverOnly, isContractorOnly, isContractor]);
  return workflow && request ? (
    <Stack>
      <Heading size="md">Costs</Heading>
      <Text py={4}>
        Here you will find the assigned Budget and estimates for this Request
      </Text>
      <Flex
        w="100%"
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        alignItems="center"
      >
        {showBudgetOnRequests && hasCostPolicy && (
          <Box w={["100%", "100%", "50%"]}>
            <FormLabel>Budget</FormLabel>
            <BudgetAutocomplete
              name="budget"
              value={(request.budget as ApiBudgetSummary) || null}
              placeholder="Budget"
              onChange={handleBudgetChange}
              isClearable
            />
          </Box>
        )}
        {showExpenses && showEstimatedCost && hasCostPolicy && (
          <Box w={["100%", "100%", "160px"]}>
            <EstimatedExpenses
              handlePropertyChange={handlePropertyChange}
              isCostSection
            />
          </Box>
        )}
        {showLabor && showEstimatedLabor && hasCostPolicy && (
          <Box w={["100%", "100%", "160px"]}>
            <EstimatedHours
              handlePropertyChange={handlePropertyChange}
              isCostSection
            />
          </Box>
        )}
      </Flex>
    </Stack>
  ) : null;
};
