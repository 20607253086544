import { Box, Heading, useBreakpointValue, useToast } from "@chakra-ui/react";
import { ApiTaskBook } from "@operations-hero/lib-api-client";
import React, { RefObject, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { TaskbookAutocomplete } from "../../../components/selects/TaskbookAutocomplete";
import { RootState, useThunkDispatch } from "../../../store";
import { addTaskbook } from "../../../store/request-form/thunks/addTaskbook.thunk";
import { getVisibleFields } from "../../../utils/getVisibleFields";
interface AddTaskbookComponentProps {
  fieldRef?: RefObject<HTMLSelectElement>;
}

export const AddTaskbookComponent: React.FC<AddTaskbookComponentProps> = ({
  fieldRef,
}) => {
  const [taskbook, setTaskbook] = useState<ApiTaskBook | null>(null);
  const displayValue = useBreakpointValue({
    base: "inline-block",
    sm: "inline-flex",
  });
  const toast = useToast();

  const thunkDispatch = useThunkDispatch();
  const { currentAccount, apiClient, isProductAdmin } = useAuthentication();
  const { request, policy, visibleFields } = useSelector(
    (state: RootState) => state.requestForm
  );

  const { showTaskbooks } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const onChangeAutoComplete = useCallback(
    (value: ApiTaskBook | null) => {
      setTaskbook(value);

      if (request && value) {
        thunkDispatch(
          addTaskbook({
            apiClient: apiClient,
            accountId: currentAccount.id,
            requestId: request?.id,
            ids: [value.id],
          })
        )
          .then(() => {
            setTaskbook(null);
          })
          .catch((error) => {
            toast({
              duration: 3000,
              isClosable: true,
              position: "top",
              status: "error",
              title: "Error adding a taskbook",
            });
          });
      }
    },
    [setTaskbook, apiClient, currentAccount, request, thunkDispatch, toast]
  );

  const canAddTaskbooks = useMemo(() => {
    if (isProductAdmin) return true;
    if (policy && (policy.reviewer || policy.technician)) {
      return true;
    }
    return false;
  }, [isProductAdmin, policy]);

  return (
    <Box
      mb={2}
      w="100%"
      position="relative"
      alignItems="center"
      display={displayValue}
    >
      <Heading size="md" w="40%" mb={displayValue === "inline-block" ? 4 : 0}>
        Task Books
      </Heading>
      {showTaskbooks && canAddTaskbooks && (
        <Box w={["100%", "60%"]} float="right">
          <TaskbookAutocomplete
            onChange={onChangeAutoComplete}
            value={taskbook}
            name="taskbook"
            placeholder="Search to add a task book"
            fieldRef={fieldRef}
          />
        </Box>
      )}
    </Box>
  );
};
