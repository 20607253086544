import { useColorMode } from "@chakra-ui/react";
import { ApiRequestPriority } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { ColorMapBase } from "./types";

export const useRequestColorMap = (priority: ApiRequestPriority) => {
  const { colorMode } = useColorMode();

  const colorMap = useMemo(() => {
    return ColorMapBase(colorMode);
  }, [colorMode]);

  const getColor = useCallback(
    (grade: number) => {
      return colorMap[priority][grade];
    },
    [priority, colorMap]
  );
  return {
    getColor,
    colorMap,
  };
};
