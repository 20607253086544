import {
  Icon,
  MenuItem,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { BsArrowDownUp, BsClock } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { RiFlashlightLine } from "react-icons/ri";
import { VscArrowSwap } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { RootState } from "../../../../store";
import { useAuthentication } from "../../../auth/AuthProvider";

type QuickAccessMenuItemsProps = {
  onOpenSwitchModal: () => void;
};
export const QuickAccessMenuItems: FC<QuickAccessMenuItemsProps> = ({
  onOpenSwitchModal,
}) => {
  const { toggleColorMode } = useColorMode();
  const iconsColor = useColorModeValue("blue.500", "blue.300");
  const isLightMode = useColorModeValue(true, false);
  const { accounts } = useAuthentication();

  const { isProductAdmin } = useAuthentication();
  const {
    isAdmin,
    isReviewer,
    isTechnician,
    isContractor,
    policyAllowsDashboard,
  } = useSelector((state: RootState) => state.localCache);

  const showTransactions = useMemo(
    () => policyAllowsDashboard || isProductAdmin || isAdmin || isReviewer,
    [policyAllowsDashboard, isProductAdmin, isAdmin, isReviewer]
  );

  const showTimesheet = useMemo(
    () =>
      isProductAdmin || isAdmin || isReviewer || isContractor || isTechnician,
    [isProductAdmin, isAdmin, isReviewer, isContractor, isTechnician]
  );

  const handleColorModeChange = useCallback(() => {
    toggleColorMode();
  }, [toggleColorMode]);

  return (
    <>
      <MenuItem
        onClick={handleColorModeChange}
        _hover={{ background: "none" }}
        w="unset"
      >
        <Icon as={RiFlashlightLine} mr={1} color={iconsColor} />
        Switch to {isLightMode ? "dark" : "light"} mode
      </MenuItem>
      {accounts.length > 1 && (
        <MenuItem
          w="unset"
          onClick={onOpenSwitchModal}
          _hover={{ background: "none" }}
        >
          <Icon as={VscArrowSwap} mr={1} color={iconsColor} />
          Switch Account
        </MenuItem>
      )}
      <MenuItem
        w="unset"
        as={RouterLink}
        to="/user-settings"
        _hover={{ background: "none" }}
      >
        <Icon as={IoSettingsOutline} mr={1} color={iconsColor} />
        User Profile
      </MenuItem>

      {showTransactions && (
        <MenuItem
          w="unset"
          as={RouterLink}
          to="/transactions"
          _hover={{ background: "none" }}
        >
          <Icon as={BsArrowDownUp} mr={1} color={iconsColor} />
          Transactions
        </MenuItem>
      )}

      {showTimesheet && (
        <MenuItem
          w="unset"
          as={RouterLink}
          to="/timesheet"
          _hover={{ background: "none" }}
        >
          <Icon as={BsClock} mr={1} color={iconsColor} />
          Timesheet
        </MenuItem>
      )}
    </>
  );
};
