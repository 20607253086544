import {
  ApiClient,
  ApiTimelineEntryObjectType,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LocalEventDetailsSliceState } from "../event-details.slice";

export const DEFAULT_EVENT_TIMELINE = {
  loading: "idle",
  page: 1,
  total: 0,
  pageSize: 10,
  data: [],
};

export interface loadEventTimelineParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
}

export const loadEventTimeline = createAsyncThunk(
  "event/timeline",
  async (params: loadEventTimelineParams, thunkAPI) => {
    const { apiClient, accountId, eventId } = params;

    const { pageSize, page } = (thunkAPI.getState() as RootState).eventDetails
      .timeline;

    const response = await apiClient.findTimelineEntries(accountId, {
      objectType: ApiTimelineEntryObjectType.event,
      objectId: eventId,
      page,
      pageSize,
    });

    return response;
  }
);

export const eventTimelineHandler = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(loadEventTimeline.fulfilled, (state, action) => {
    const { data, options, total } = action.payload;
    state.timeline.loading = "succeeded";
    state.timeline.data = data;
    state.timeline.total = total;
    state.timeline.page = options.page || 1;
  });
  builder.addCase(loadEventTimeline.rejected, (state) => {
    state.timeline.loading = "failed";
  });
  builder.addCase(loadEventTimeline.pending, (state) => {
    state.timeline.loading = "pending";
  });
};
