import { Box, Flex, HStack } from "@chakra-ui/layout";
import {
  Button,
  Icon,
  IconButton,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect } from "react";
import { IoWarning } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";

const sections: Record<SectionType, FormSection> = {
  status: {
    title: "Change Status",
    placeholder: "Change the status of selected Requests",
  },
  assignTo: {
    title: "Assign To",
    placeholder:
      "All assigned users if any, will be replaced with the new assignees",
  },
  category: {
    title: "Category",
    placeholder: "Select a Category to add to the Request",
  },
  reason: {
    title: "Reason",
    placeholder: "Add a Reason to the Request",
  },
  customField: {
    title: "Other fields",
    placeholder: "Select an option to add to the Request",
  },
  labor: {
    title: "Labor",
    placeholder: "Add a labor to the Request",
  },
  comment: {
    title: "Comment",
    placeholder: "Add a comment to the Request",
  },
};

export type SectionType =
  | "status"
  | "assignTo"
  | "category"
  | "reason"
  | "customField"
  | "labor"
  | "comment";

export interface FormSection {
  title: string;
  placeholder: string;
  infoMessage?: string;
  onCancel?: () => void;
}
export interface EditFormSectionProps {
  type: SectionType;
  requiresValidation?: boolean;
  isNotCompliantToValidation?: boolean;
  isOpenCondition?: boolean;
  onCancel?: () => void;
  children: React.ReactNode;
}
export const EditFormSection: FC<EditFormSectionProps> = ({
  type,
  children,
  requiresValidation = false,
  isNotCompliantToValidation,
  isOpenCondition,
  onCancel,
}) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const handleCancel = useCallback(() => {
    onToggle();
    if (onCancel) onCancel();
  }, [onCancel, onToggle]);

  useEffect(() => {
    if (isOpenCondition != null && isOpenCondition) onOpen();
    if (isOpenCondition != null && !isOpenCondition) onClose();
  }, [isOpenCondition, onClose, onOpen]);

  return (
    <Box w="100%">
      <Flex w="100%" alignItems="center" py="1">
        {requiresValidation && isNotCompliantToValidation ? (
          <HStack alignContent="center">
            <Text fontWeight="bold">{sections[type].title}</Text>
            <Icon as={IoWarning} color="orange.300" boxSize="5" />
          </HStack>
        ) : (
          <Text fontWeight="bold">{sections[type].title}</Text>
        )}
        <Spacer />
        {isOpen ? (
          <Button
            size="sm"
            variant="link"
            colorScheme="blue"
            onClick={handleCancel}
            _hover={{
              border: "none",
            }}
            fontWeight="medium"
          >
            Cancel
          </Button>
        ) : (
          <IconButton
            aria-label="Edit"
            icon={<Icon as={MdModeEditOutline} />}
            variant="ghost"
            colorScheme="blue"
            onClick={onToggle}
          />
        )}
      </Flex>
      <Text fontSize="sm" color="gray.500">
        {sections[type].placeholder}
      </Text>
      {isOpen && <Box mt="4">{children}</Box>}
    </Box>
  );
};
