import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  ApiRequest,
  ApiRequestAssignee,
  ApiRequestStatus,
  ApiWorkflow,
  ApiWorkflowField,
  ApiWorkflowFieldDataType,
  ApiWorkflowReference,
  MetadataTypes,
} from "@operations-hero/lib-api-client";
import axios, { AxiosResponse } from "axios";
import { EditorState } from "draft-js";
import { Form, Formik, FormikProps } from "formik";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { StatusBadge } from "../../components/badges/StatusBadge";
import { CustomFieldInput } from "../../components/custom-field-input/CustomFieldInput";
import { AssigneeAutocompleteControl } from "../../components/form-helpers/AssigneeAutocompleteControl";
import { RequestStatusSelectControl } from "../../components/form-helpers/RequestStatusSelectControl";
import { RichTextEditorComments } from "../../components/form-helpers/rich-text-editor/RichTextEditorComments";
import { WorkflowAutocompleteControl } from "../../components/form-helpers/WorkflowAutocompleteControl";
import { FormikObserver } from "../../hooks/formikObserver";
import { useShowToast } from "../../hooks/showToast";
import { useConvertMentions } from "../../hooks/useConvertMentions";
import { RootState } from "../../store";
import { updateRequestSliceData } from "../../store/request-form/request-form.slice";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { convertToSave } from "./comments/Comment";

export type ActionMenuType = "move" | "copy" | "print" | "clone" | "associate";

type ExpectedMoveErrors = {
  meta: boolean;
  status: boolean;
};

const EXPECTED_ERROR_STATUS = 400;

const MoveSchema = yup.object().shape({
  target: yup
    .object()
    .typeError("target workflow is required")
    .required("workflow is required"),
});

interface MoveRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  menuActionValue: ActionMenuType;
}

interface MoveFormProps {
  hasSameSchema: boolean;
  targetPrevId: string;
  source: ApiWorkflow | null;
  target: ApiWorkflow | null;
  targetFields: ApiWorkflowField[];
  status: ApiRequestStatus | null;
  assignees: ApiRequestAssignee[];
}

export const MoveRequestModal: FC<MoveRequestModalProps> = ({
  isOpen,
  menuActionValue,
  onClose,
}) => {
  const [expectedErrors, setExpectedErrors] = useState<ExpectedMoveErrors>({
    meta: false,
    status: false,
  });

  const [tempRequest, setTempRequest] = useState<ApiRequest | null>(null);
  const [sourceWorkflow, setSourceWorkflow] = useState<ApiWorkflow | null>(
    null
  );

  const [incompatibleFields, setIncompatibleFields] =
    useState<ApiWorkflowField[]>();
  const formRef = useRef<FormikProps<MoveFormProps>>(null);
  const showToast = useShowToast();
  const navigate = useNavigate();
  const { apiClient, currentAccount } = useAuthentication();
  const { request } = useSelector((state: RootState) => state.requestForm);

  const dispatch = useDispatch();
  const { editorState: summary } = useConvertMentions({
    value: request?.summary,
    mentioned: request?.mentioned,
  });

  const isClone = useMemo(() => menuActionValue === "clone", [menuActionValue]);
  const formInitialValues = useMemo(() => {
    return {
      status: request ? request.status : null,
      target: null,
      targetPrevId: "",
      hasSameSchema: true,
      source: request ? (request.workflow as ApiWorkflow) : "",
      targetFields: [] as ApiWorkflowField[],
      assignees: request ? request.assignees : null,
    };
  }, [request]);

  const handleErrors = useCallback(
    (response: AxiosResponse) => {
      if (!response) return;
      const { data } = response;

      if (data.status === EXPECTED_ERROR_STATUS) {
        data.message === "incompatible status" &&
          setExpectedErrors({ ...expectedErrors, status: true });
      }
    },
    [expectedErrors]
  );

  const handleOnSubmit = useCallback(
    async (values: MoveFormProps) => {
      if (!values.source || !values.target || !values.status) return;
      if (request) {
        try {
          const { hasSameSchema, status, assignees } = values;
          const newMetadata = tempRequest?.metadata;
          const keyDat = Object.keys(request.metadata).filter(
            (oldData) =>
              !incompatibleFields?.some((incompt) => incompt.key === oldData)
          );

          let validData = {} as MetadataTypes;
          keyDat.forEach((key) => (validData[key] = request.metadata[key]));
          if (isClone) {
            await apiClient.cloneWorkflowRequest(
              currentAccount.id,
              request.id,
              {
                workflow: values.target,
                status,
                metadata: hasSameSchema
                  ? { ...validData }
                  : { ...newMetadata, ...validData },
                summary: summary ? convertToSave(summary) || "" : "",
                assignees,
              }
            );
            showToast(
              "success",
              "Request was cloned successfully",
              `${request.id}`
            );
            onClose();
            navigate("/requests");
            return;
          }
          await apiClient.moveWorkflowRequest(currentAccount.id, request.id, {
            workflow: values.target,
            status: expectedErrors.status ? status : undefined,
            metadata: hasSameSchema
              ? { ...validData }
              : { ...newMetadata, ...validData },
          });
          showToast(
            "success",
            "Request was moved successfully",
            `${request.id}`
          );
          onClose();
          navigate("/requests");
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            handleErrors(error.response);
            return;
          }
          showToast(
            "success",
            "something went wrong, please try again",
            `${request.id}`
          );
        }
      }
    },
    [
      showToast,
      onClose,
      navigate,
      request,
      apiClient,
      handleErrors,
      currentAccount.id,
      expectedErrors.status,
      incompatibleFields,
      isClone,
      summary,
      tempRequest,
    ]
  );

  const getCustomFieldDefaultValue = useCallback((fieldName: string) => {
    const defaultValues = {
      text: "",
      number: 0,
      checkbox: false,
    };
    return (defaultValues as MetadataTypes)[fieldName] || "";
  }, []);

  const handleOnChangeForm = useCallback(
    async (values: MoveFormProps, setFieldValue: any) => {
      const { target, targetPrevId, assignees } = values;

      if (target && target.id !== targetPrevId) {
        if (sourceWorkflow?.schema && target.schema) {
          const hasSameSchema = sourceWorkflow.schema.id === target.schema.id;
          setFieldValue("hasSameSchema", hasSameSchema);
        }
        const { schema } = target;
        const response = await apiClient.findWorkflowSchemaFields(
          currentAccount.id,
          schema.id,
          {
            pageSize: 50,
          }
        );
        const schemaFieldsSource = sourceWorkflow?.schema.id
          ? await apiClient.findWorkflowSchemaFields(
              currentAccount.id,
              sourceWorkflow?.schema.id,
              {
                pageSize: 50,
              }
            )
          : null;

        const targetCustomFields = response.data.map((item) => item.field);

        const sourceCustomFields = schemaFieldsSource?.data.map(
          (item) => item.field
        );
        setFieldValue(
          "targetFields",
          targetCustomFields.filter(
            (target) =>
              !sourceCustomFields?.some(
                (source) =>
                  (source.dataType === target.dataType &&
                    source.key === target.key) ||
                  target.dataType === ApiWorkflowFieldDataType.system
              )
          )
        );

        setIncompatibleFields(
          sourceCustomFields!.filter(
            (target) =>
              !targetCustomFields?.some(
                (source) =>
                  (source.dataType === target.dataType &&
                    source.key === target.key) ||
                  target.dataType === ApiWorkflowFieldDataType.system
                //when sourceField is the same as target don't set as incompatible
              )
          )
        );
        const metadataFromSchemaFields = response.data.map((item) => {
          const defaultValue = getCustomFieldDefaultValue(item.field.dataType);
          return {
            [item.field.key]: defaultValue,
          };
        });

        const newMetadata = Object.assign({}, ...metadataFromSchemaFields);
        if (request) {
          const newRequest: ApiRequest = {
            ...request,
            metadata: { ...newMetadata },
          };
          if (assignees) newRequest.assignees = assignees;
          setTempRequest(newRequest);
        }
        setFieldValue("targetPrevId", target.id);
      }
    },
    [
      apiClient,
      request,
      sourceWorkflow,
      currentAccount.id,
      getCustomFieldDefaultValue,
    ]
  );

  const getSourceWorkflow = useCallback(async () => {
    if (request) {
      const workflow = await apiClient.getWorkflow(
        currentAccount.id,
        request.workflow.requestSlug
      );
      setSourceWorkflow(workflow);
    }
  }, [apiClient, currentAccount.id, request]);

  const handleOnChangeCustomField = useCallback(
    (field: ApiWorkflowField) => {
      return (newValue: any) => {
        const metadata = {
          ...(tempRequest?.metadata || {}),
          [field.key]: newValue,
        };
        if (tempRequest) {
          const newRequestAux: ApiRequest = {
            ...tempRequest,
            metadata: metadata,
          };
          setTempRequest(newRequestAux);
        }
      };
    },
    [tempRequest]
  );

  const handleOnCloseModal = useCallback(() => {
    setTempRequest(null);
    setExpectedErrors({ meta: false, status: false });
    // using ref only to clean the formik values when modal close
    formRef.current?.setValues({ ...(formInitialValues as MoveFormProps) });
    onClose();
  }, [formInitialValues, onClose]);

  const handleSummaryBlur = useCallback(
    (summary: EditorState) => {
      const response = convertToSave(summary);
      if (response && response.trim() === request?.summary.trim()) return;
      dispatch(updateRequestSliceData({ summary: response }));
    },
    [dispatch, request?.summary]
  );

  useEffect(() => {
    getSourceWorkflow();
  }, [getSourceWorkflow]);

  return request ? (
    <>
      <Modal isOpen={isOpen} onClose={handleOnCloseModal}>
        <ModalOverlay />
        <ModalContent maxWidth={isClone ? "4xl" : "md"}>
          <ModalHeader fontSize="xl">
            {capitalizeFirstLetter(menuActionValue)} {request.key}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              innerRef={formRef}
              onSubmit={handleOnSubmit}
              validationSchema={MoveSchema}
              initialValues={{ ...(formInitialValues as MoveFormProps) }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <FormikObserver cb={handleOnChangeForm} />
                    <Grid gap={4} mb={4}>
                      <GridItem>
                        <WorkflowAutocompleteControl
                          isDisabled
                          label="Source"
                          name="source"
                          value={values.source as ApiWorkflowReference}
                        />
                      </GridItem>

                      <GridItem>
                        <WorkflowAutocompleteControl
                          name="target"
                          label="Target"
                          value={values.target || ""}
                        />
                      </GridItem>

                      {expectedErrors.status && (
                        <GridItem>
                          <Alert borderRadius={6} status="warning">
                            <AlertIcon />
                            <Text as="span">
                              The status <StatusBadge status={request.status} />{" "}
                              its not available in the target workflow. Please
                              select a new status
                            </Text>
                          </Alert>
                        </GridItem>
                      )}
                      {(expectedErrors.status || isClone) && (
                        <GridItem>
                          <RequestStatusSelectControl
                            name="status"
                            label="Request status"
                            workflow={values.target}
                            value={values.status}
                          />
                        </GridItem>
                      )}
                      {isClone && (
                        <>
                          <GridItem>
                            <AssigneeAutocompleteControl
                              value={values.assignees}
                              workflow={values.target}
                              location={
                                tempRequest?.location
                                  ? tempRequest.location
                                  : undefined
                              }
                              reportingCategory={
                                tempRequest?.reportingCategory ?? undefined
                              }
                              label="Assigned To"
                              name="assignees"
                            />
                          </GridItem>
                          <GridItem>
                            <RichTextEditorComments
                              id="comment-edit"
                              value={summary}
                              onBlur={handleSummaryBlur}
                            />
                          </GridItem>
                        </>
                      )}

                      {!values.hasSameSchema && (
                        <>
                          {values.targetFields.length > 0 && (
                            <GridItem>
                              <Divider my={2} />
                              <Heading size="sm" pb={2} color="blue.300">
                                Target workflow custom fields
                              </Heading>
                              <Flex w="100%" flexDir="row" wrap="wrap">
                                {values.targetFields.map((targetField, idx) => {
                                  return (
                                    <Box
                                      py={1}
                                      minW="100%"
                                      key={targetField.id + idx}
                                    >
                                      <CustomFieldInput
                                        field={targetField}
                                        name="request-custom-field-target"
                                        onChange={handleOnChangeCustomField(
                                          targetField
                                        )}
                                        value={
                                          tempRequest?.metadata[targetField.key]
                                        }
                                      />
                                    </Box>
                                  );
                                })}
                              </Flex>
                            </GridItem>
                          )}

                          {incompatibleFields &&
                            incompatibleFields?.length > 0 && (
                              <GridItem>
                                <Divider my={2} />
                                <Alert borderRadius={6} status="error" my={3}>
                                  <AlertIcon />
                                  Some fields are incompatible with the target
                                  workflow. These fields will be removed after
                                  the request is {`${menuActionValue}d`}
                                </Alert>
                                {incompatibleFields.map((field) => (
                                  <CustomFieldInput
                                    key={field.id}
                                    field={field}
                                    isInvalid={true}
                                    isDisabled={true}
                                    name="request-custom-source"
                                    value={request.metadata[field.key]}
                                  />
                                ))}
                              </GridItem>
                            )}
                        </>
                      )}

                      <GridItem>
                        <Button type="submit" float="right" colorScheme="blue">
                          {isClone ? "Clone" : "Move"}
                        </Button>
                      </GridItem>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};
