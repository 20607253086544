import { Flex } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
type CommentContainerProps = PropsWithChildren;

export const CommentContainer: FC<CommentContainerProps> = ({ children }) => {
  return (
    <Flex flexDir="column" gap={2}>
      {children}
    </Flex>
  );
};
