import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Flex,
  SystemStyleObject,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import {
  Column,
  Row,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";

export type DataTableColumn<T extends object> = Column<T> & {
  boxProps?: BoxProps;
};

export interface DataTableProps<T extends object> {
  columns: DataTableColumn<T>[];
  data: T[];
  hiddenColumns?: string[];
  hiddenHeader?: boolean;
  displayBorderBottom?: boolean;
  onRowClick?: (item: T) => void;
  rest?: BoxProps;
  headerRest?: BoxProps;
  markOddRow?: boolean;
  headersToAlign?: Record<string, "right" | "center" | "left">;
  rowProps?: BoxProps;
}

export const DataTable = ({
  columns,
  data,
  hiddenColumns,
  displayBorderBottom = true,
  hiddenHeader = false,
  onRowClick,
  rest,
  headerRest,
  markOddRow,
  headersToAlign,
  rowProps,
}: DataTableProps<any>) => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const oddRowBgColor = useColorModeValue("gray.50", "gray.700");
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { hiddenColumns: hiddenColumns || [] },
      },
      useFlexLayout,
      useResizeColumns,
      useSortBy
    );

  const borderColor = useColorModeValue("gray.200", "gray.700");

  const handleRowClick = useCallback(
    (row: Row<object>) => {
      return () => {
        if (onRowClick) {
          onRowClick(row.original);
        }
      };
    },
    [onRowClick]
  );

  const headerHAlignment = useCallback(
    (header?: string | null): SystemStyleObject => {
      if (headersToAlign && header && headersToAlign[header]) {
        return {
          justifyContent: headersToAlign[header],
          marginRight: headersToAlign[header] === "right" ? 4 : 0,
        };
      }

      return { justifyContent: "left" };
    },
    [headersToAlign]
  );

  const rowHAlignment = useCallback(
    (header?: string | null): SystemStyleObject => {
      if (headersToAlign && header && headersToAlign[header]) {
        return {
          marginRight: headersToAlign[header] === "right" ? 4 : 0,
        };
      }

      return {};
    },
    [headersToAlign]
  );

  return (
    <Box overflowX="auto" pb={4} {...rest}>
      <Box {...getTableProps()}>
        <Box>
          {!hiddenHeader &&
            headerGroups.map((headerGroup, idx) => {
              const { id, ...rest } = headerGroup;
              return (
                <Box
                  {...rest.getHeaderGroupProps()}
                  {...headerRest}
                  key={`tableHeader::${id}${idx}`}
                >
                  {headerGroup.headers.map((column, idx) => {
                    const { id, ...rest } = column;
                    return (
                      <Box
                        {...rest.getHeaderProps(rest.getSortByToggleProps())}
                        borderBottom="1px"
                        display="inline-block"
                        borderColor={borderColor}
                        p={1}
                        fontWeight="bold"
                        {...headerRest}
                        key={`tableSubHeader::${id}${idx}`}
                      >
                        <Flex
                          justify="space-between"
                          sx={headerHAlignment(
                            column.render("Header")?.toString()
                          )}
                        >
                          <Box w={column.canSort ? "full" : "fit-content"}>
                            {column.render("Header")}
                          </Box>
                          <Box
                            color={column.isSorted ? "inherit" : borderColor}
                          >
                            {column.canSort ? (
                              column.isSortedDesc ? (
                                <TriangleDownIcon aria-label="sorted descending" />
                              ) : (
                                <TriangleUpIcon aria-label="sorted ascending" />
                              )
                            ) : null}
                          </Box>
                        </Flex>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
        </Box>
        <Box {...getTableBodyProps()} mt={2}>
          {rows.map((row, idx) => {
            const { id } = row;
            prepareRow(row);
            return (
              <Box
                {...row.getRowProps()}
                onClick={handleRowClick(row)}
                cursor={onRowClick ? "pointer" : ""}
                my={markOddRow ? 2 : 0}
                {...rowProps}
                bgColor={markOddRow && idx % 2 !== 0 ? oddRowBgColor : "unset"}
                key={`tableRow::${id}${idx}`}
              >
                {row.cells.map((cell, idx) => (
                  <Box
                    display="inline-block"
                    borderBottom={displayBorderBottom ? "1px" : "none"}
                    borderColor={borderColor}
                    p={2}
                    {...cell.getCellProps()}
                    isTruncated={true}
                    {...(cell.column as DataTableColumn<any>).boxProps}
                    sx={rowHAlignment(cell.column.Header?.toString())}
                    key={`react-table-row::${idx}`}
                  >
                    {cell.render("Cell")}
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
