import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiReportingCategory,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { RootState } from "../../../store";
import { useCategoryUtils } from "../../../utils/categoryUtils";

export const CategoryFilterBadge = ({
  value,
}: {
  value: ApiWorkflowReportingCategorySummary;
}) => {
  return (
    <HStack>
      {value && (
        <>
          <Box
            as="span"
            display="inline-block"
            fontWeight="bold"
            fontSize="sm"
            pr={2}
            minWidth="80px"
          >
            {value.code}
          </Box>
          <Box>{value.name}</Box>
        </>
      )}
    </HStack>
  );
};

export interface CategoryFilterProps {
  value: (string | ApiReportingCategory)[];
  onChange: (value: ApiReportingCategory[]) => void;
  isRequestList?: boolean;
}

const ContentRenderer = ({
  props,
  state,
}: SelectRenderer<ApiReportingCategory>) => {
  const { values } = props;
  return (
    <Box p={1}>
      {values.length === 0 && "Category"}
      {values.length === 1 &&
        (values[0].id === "null" ? (
          values[0].code
        ) : (
          <CategoryFilterBadge value={state.values[0]} />
        ))}
      {values.length > 1 && (
        <>
          <Text as="span" mr={1}>
            {values[0].name === "" ? values[0].code : values[0].name}
          </Text>
          <AdditionalNumberBadge number={values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiReportingCategory>) => {
  const isDisabled = useMemo(
    () => (item: ApiReportingCategory) =>
      props.values.includes(item) && !methods.isSelected(item),
    [props.values, methods]
  );

  const bgColor = useColorModeValue(undefined, "gray.700");

  const handleChecked = useCallback(
    (item: ApiReportingCategory) => {
      return (
        props.values.includes(item) ||
        (item.id === "null" &&
          props.values.some((value) => value.id === "null"))
      );
    },
    [props.values]
  );

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Categories"
        />
      </Box>
      <Box>
        {
          //@ts-ignore
          (state.search ? state.searchResults : props.options).map((item) => {
            return (
              <Checkbox
                key={item.id}
                isChecked={(() => handleChecked(item))()}
                onChange={
                  isDisabled(item) ? undefined : () => methods.addItem(item)
                }
                w="100%"
                p={2}
                disabled={isDisabled(item)}
              >
                <CategoryFilterBadge value={item} />
              </Checkbox>
            );
          })
        }
      </Box>
    </Box>
  );
};

export const CategoryFilter = ({
  value,
  onChange,
  isRequestList,
}: CategoryFilterProps) => {
  const { categories, categoriesMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const [categoriesToAdd, setCategoriesToAdd] = useState<
    ApiReportingCategory[]
  >([]);

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const { findAllChildrenForNodesRecursive } = useCategoryUtils();
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(categoriesToAdd, ["code", "name"], {
        sort: true,
      }),
    [categoriesToAdd]
  );

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiReportingCategory>) => {
      if (!state.search) {
        return props.options;
      }
      const found = fuzzySearch.search(state.search);
      const categoryChilds = findAllChildrenForNodesRecursive(found);
      //@ts-ignore
      return [...categoryChilds];
    },
    [fuzzySearch, findAllChildrenForNodesRecursive]
  );

  useEffect(() => {
    if (!isRequestList) {
      setCategoriesToAdd(categories);
      return;
    }
    const emptyCategory = {
      active: true,
      children: [],
      code: "Missing",
      created: "",
      id: "null",
      isSystem: true,
      name: "",
      updated: "",
    } as ApiReportingCategory;

    const newCategories = [emptyCategory].concat(categories);
    setCategoriesToAdd(newCategories);
  }, [setCategoriesToAdd, categories, isRequestList]);

  return (
    <Select
      multi
      options={categoriesToAdd.filter((cat) => cat.active)}
      className={themeClass}
      values={value.map((c) =>
        typeof c === "string"
          ? c !== "null"
            ? categoriesMap[c]
            : {
                active: true,
                children: [],
                code: "Missing",
                created: "",
                id: "null",
                isSystem: true,
                name: "",
                updated: "",
              }
          : c
      )}
      onChange={(value) => onChange(value)}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      clearOnSelect={false}
      clearable={false}
      contentRenderer={ContentRenderer}
      dropdownRenderer={DropdownRenderer}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  );
};
