import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LocalEventDetailsSliceState } from "../event-details.slice";

interface LoadEventDatesScheduleModalProps {
  apiClient: ApiClient;
  accountId: string;
  serviceId: string;
  eventId: string;
}

export const loadEventDatesScheduleModal = createAsyncThunk(
  "event-occurrences/load-event-dates-schedule-modal",
  async (params: LoadEventDatesScheduleModalProps, thunkAPI) => {
    const { apiClient, accountId, serviceId, eventId } = params;
    const state = thunkAPI.getState() as RootState;
    const { page, pageSize } = state.eventDetails.eventDatesFiltersModal;

    const occurrences = await apiClient.findScheduledJobs(accountId, {
      page,
      pageSize,
      eventId,
      serviceId,
    });
    return occurrences;
  }
);

export const loadEventDatesScheduleModalHandler = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(loadEventDatesScheduleModal.fulfilled, (state, action) => {
    state.loadingModal = "succeeded";
    state.eventDatesScheduleModal = action.payload.data;
    state.eventDatesFiltersModal.total = action.payload.total;
  });
  builder.addCase(loadEventDatesScheduleModal.pending, (state) => {
    state.loadingModal = "pending";
  });
  builder.addCase(loadEventDatesScheduleModal.rejected, (state) => {
    state.loadingModal = "failed";
    state.eventDatesScheduleModal = [];
  });
};
