import { IconButton } from "@chakra-ui/react";
import React, { FC, MouseEventHandler, ReactElement } from "react";

interface OutlinedIconButtonProps {
  icon: ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
}

export const OutlinedIconButton: FC<OutlinedIconButtonProps> = ({
  icon,
  onClick,
  isDisabled,
}) => {
  return (
    <IconButton
      aria-label={`icon-button-${icon.key}`}
      icon={icon}
      variant="unstyled"
      boxSize="unset"
      boxSizing="unset"
      minWidth="unset"
      minHeight="unset"
      lineHeight="3"
      color="gray"
      size="lg"
      onClick={onClick}
      _focus={{ border: "none" }}
      isDisabled={isDisabled}
    />
  );
};
