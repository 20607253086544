import {
  Box,
  Button,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiInventoryItem } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import React, { Fragment, useCallback } from "react";
import { FaSearch } from "react-icons/fa";
import { MdQrCode2 } from "react-icons/md";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { QrQuickScanModal } from "../../../components/qr-quick-scan/QrQuickScanModal";

export interface ItemSearchAutocompleteProps {
  value: ApiInventoryItem | null;
  onChange: (value: ApiInventoryItem | null) => void;
  fieldRef?: any;
}

export const ItemSearchAutocomplete = ({
  onChange,
  value,
  fieldRef,
}: ItemSearchAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const qrColor = useColorModeValue("black", "white");

  const handleChange = useCallback(
    (newValue: ApiInventoryItem | null) => {
      onChange(newValue);
    },
    [onChange]
  );

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findInventoryItem(currentAccount.id, {
          search: inputValue,
          pageSize: 50,
        })
        .then((results) => {
          cb(results.data);
        });
    },
    [apiClient, currentAccount]
  );

  const handleQrData = useCallback(
    (externalId: string) => {
      apiClient
        .findInventoryItem(currentAccount.id, {
          search: externalId,
          pageSize: 1,
        })
        .then((result) => {
          onChange(result.data[0]);
        });
      onClose();
    },
    [onClose, apiClient, currentAccount.id, onChange]
  );

  return (
    <Fragment>
      <HStack>
        <Box flex={1}>
          <AsyncSelect
            ref={fieldRef}
            isClearable={false}
            cacheOptions={false}
            isMulti={false}
            defaultOptions={true}
            value={value || null}
            onChange={handleChange}
            loadOptions={loadOptions}
            getOptionValue={(item: ApiInventoryItem) => item.id}
            getOptionLabel={(item: ApiInventoryItem) =>
              `${item.identifiers.externalId}  ${item.name}`
            }
            placeholder={
              <Text>
                <Icon as={FaSearch} mr={4} />
                {"Search to add an Item"}
              </Text>
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            openMenuOnFocus={true}
            menuShouldScrollIntoView={false}
            menuShouldBlockScroll={false}
          />
        </Box>
        <Button
          onClick={onOpen}
          color={qrColor}
          onTouchEnd={onOpen}
          zIndex={100}
          w={7}
        >
          <Icon h={7} w={7} as={MdQrCode2} />
        </Button>
      </HStack>
      <QrQuickScanModal
        isOpen={isOpen}
        onClose={onClose}
        onData={handleQrData}
      />
    </Fragment>
  );
};
