import { ApiClient, CreateApiSchedule } from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { isCronValidToSave } from "../../../components/cron/cron-helpers/CronOptions";
import { mapSaveScheduledRequest } from "../scheduled-request-helpers";

interface SaveScheduledRequestParams {
  apiClient: ApiClient;
  accountId: string;
}

export const createScheduledRequest = createAsyncThunk(
  "account/createScheduledRequest",
  async (params: SaveScheduledRequestParams, thunkAPI) => {
    const { apiClient, accountId } = params;
    const state = thunkAPI.getState() as RootState;
    const {
      scheduledRequest,
      scheduledRequestTaskBooks,
      scheduledRequestAssets,
      schedule,
      willRequestRepeat,
    } = state.scheduleRequestForm;

    const newScheduleRequest = mapSaveScheduledRequest(scheduledRequest);
    if (!newScheduleRequest) return;

    const createdScheduledRequest = await apiClient.createScheduledRequest(
      accountId,
      newScheduleRequest
    );
    if (scheduledRequestTaskBooks.length) {
      const newTaskbooksIds = scheduledRequestTaskBooks.map(
        (taskbook) => taskbook.id
      );
      await apiClient.createScheduledRequestTaskbook(
        accountId,
        createdScheduledRequest.id,
        { taskbookIds: newTaskbooksIds }
      );
    }

    if (scheduledRequestAssets.length) {
      const newAssetsIds = scheduledRequestAssets.map((asset) => asset.id);

      await apiClient.createScheduledRequestAsset(
        accountId,
        createdScheduledRequest.id,
        { assetIds: newAssetsIds }
      );
    }
    const validDailyFrequency =
      schedule.frequency === "daily" && schedule.executeEvery > 0;
    const canBeSaved = isCronValidToSave(schedule.cron) || validDailyFrequency;
    if (willRequestRepeat && canBeSaved) {
      const newSchedule: CreateApiSchedule = {
        cron: schedule.cron,
        timeZone: schedule.timeZone,
        startDate: schedule.startDate,
        endDate: schedule.endDate,
        frequency: schedule.frequency,
        executeEvery: schedule.executeEvery,
        weekOfMonth: schedule.weekOfMonth,
      };

      const schedules = await apiClient.createSchedule(
        accountId,
        createdScheduledRequest.id,
        {
          ...newSchedule,
          startDate: newSchedule.startDate
            ? new Date(newSchedule.startDate).toISOString()
            : null,
        }
      );
      createdScheduledRequest.schedules.push(schedules);
    }
    return createdScheduledRequest;
  }
);
