import {
  lastDayOfMonth,
  lastDayOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { calculateDateToUTC, localMidnightToUTC } from "./localMidnightToUTC";

const getRangeRelativeDate = (dateRelative: string | null) => {
  const today = new Date();
  const todayFormated = localMidnightToUTC(today);

  const last1DayFormated = calculateDateToUTC(-1);
  const last7DaysFormated = calculateDateToUTC(-7);
  const next7DaysFormated = calculateDateToUTC(7);
  const last14DaysFormated = calculateDateToUTC(-14);
  const last30DaysFormated = calculateDateToUTC(-30);
  const next30DaysFormated = calculateDateToUTC(30);
  const last35DaysFormated = calculateDateToUTC(-35);
  const next35DaysFormated = calculateDateToUTC(35);

  const date = [];

  if (dateRelative === "last1d") {
    date[0] = dateRelative === "last1d" ? last1DayFormated : todayFormated;
    date[1] = "";
  }

  if (dateRelative === "last7d" || dateRelative === "next7d") {
    date[0] = dateRelative === "last7d" ? last7DaysFormated : todayFormated;
    date[1] = dateRelative === "last7d" ? "" : next7DaysFormated;
  }

  if (dateRelative === "last14d") {
    date[0] = dateRelative === "last14d" ? last14DaysFormated : todayFormated;
    date[1] = "";
  }

  if (dateRelative === "last30d" || dateRelative === "next30d") {
    date[0] = dateRelative === "last30d" ? last30DaysFormated : todayFormated;
    date[1] = dateRelative === "last30d" ? "" : next30DaysFormated;
  }

  if (
    dateRelative === "lastWeek" ||
    dateRelative === "thisWeek" ||
    dateRelative === "nextWeek"
  ) {
    const year =
      dateRelative === "lastWeek"
        ? last7DaysFormated.substring(0, 4)
        : dateRelative === "thisWeek"
        ? todayFormated.substring(0, 4)
        : next7DaysFormated.substring(0, 4);
    const month =
      dateRelative === "lastWeek"
        ? last7DaysFormated.substring(5, 7)
        : dateRelative === "thisWeek"
        ? todayFormated.substring(5, 7)
        : next7DaysFormated.substring(5, 7);
    const day =
      dateRelative === "lastWeek"
        ? last7DaysFormated.substring(8, 10)
        : dateRelative === "thisWeek"
        ? todayFormated.substring(8, 10)
        : next7DaysFormated.substring(8, 10);

    const firstDayOfLastWeek = localMidnightToUTC(
      startOfWeek(
        new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 11, 55, 0)
      )
    );
    const LastDayOfLastWeek = localMidnightToUTC(
      lastDayOfWeek(
        new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 11, 55, 0)
      )
    );
    date[0] = firstDayOfLastWeek;
    date[1] = LastDayOfLastWeek;
  }

  if (
    dateRelative === "lastMonth" ||
    dateRelative === "thisMonth" ||
    dateRelative === "nextMonth"
  ) {
    const year =
      dateRelative === "lastMonth"
        ? last35DaysFormated.substring(0, 4)
        : dateRelative === "thisMonth"
        ? todayFormated.substring(0, 4)
        : next35DaysFormated.substring(0, 4);
    const month =
      dateRelative === "lastMonth"
        ? last35DaysFormated.substring(5, 7)
        : dateRelative === "thisMonth"
        ? todayFormated.substring(5, 7)
        : next35DaysFormated.substring(5, 7);
    const day =
      dateRelative === "lastMonth"
        ? last35DaysFormated.substring(8, 10)
        : dateRelative === "thisMonth"
        ? todayFormated.substring(8, 10)
        : next35DaysFormated.substring(8, 10);

    const firstDayOfLastMonth = localMidnightToUTC(
      startOfMonth(
        new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 11, 55, 0)
      )
    );
    const LastDayOfLastMonth = localMidnightToUTC(
      lastDayOfMonth(
        new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 11, 55, 0)
      )
    );
    date[0] = firstDayOfLastMonth;
    date[1] = LastDayOfLastMonth;
  }

  return date;
};

export default getRangeRelativeDate;
