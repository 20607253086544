import { Box } from "@chakra-ui/react";
import { ApiUser, ApiUserSummary } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { useShowToast } from "../../hooks/showToast";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  UserOptionsProps,
  UserSingleValueSelect,
} from "./select-overrides-types";

export interface UserAutocompleteProps {
  name?: string;
  value: ApiUser | null;
  onChange?: (user: ApiUser | null) => void;
  workflowIdsOrSlugs?: string | string[];
}

export interface UserAutocompleteGroup
  extends Omit<ApiUserSummary, "timeZone"> {
  isGroup?: boolean;
  groupMembers?: ApiUserSummary[];
}

const CustomOptionComponent = createOptionComponent(UserBadge);

const CustomSingleValueComponent = (props: UserSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
};

export const UserTimeSheetsAutocomplete = ({
  onChange,
  name,
  value: user,
  workflowIdsOrSlugs,
}: UserAutocompleteProps) => {
  const showToast = useShowToast();
  const { currentAccount, apiClient } = useAuthentication();

  const nullUser: ApiUser = useMemo(
    () =>
      ({
        firstName: "No User",
        lastName: "",
        id: "null-user",
        email: "",
        profileImage: "",
        timeZone: null,
      }) as ApiUser,
    [],
  );

  const handleChange = useCallback(
    (value: ApiUser | null) => {
      const newValue =
        value === null || value.id === nullUser.id ? null : value;
      if (onChange) onChange(newValue);
    },
    [onChange, nullUser],
  );

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      try {
        const users: any = [];

        const response = await apiClient.findUserAndGroupsRequestFilter(
          currentAccount.id,
          {
            typeToSearch: "assignee",
            isForTimeSheet: true,
            search: inputValue,
            workflowIdsOrSlugs,
          },
        );
        const r = response.map((r) => r.assignee as ApiUserSummary);
        users.push(...r);
        cb(users);
        return users;
      } catch (error) {
        showToast("error", "Something went wrong loading users");
      }
    },
    [apiClient, currentAccount.id, showToast, workflowIdsOrSlugs],
  );

  const debouncedLoadOptions = debounce(loadOptions, 250);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: UserOptionsProps) => CustomOptionComponent(props),
      SingleValue: (props: UserSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      name={name}
      defaultOptions={true}
      value={user || nullUser}
      cacheOptions={true}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      chakraStyles={commonStyles}
      components={components}
      getOptionValue={(item: ApiUser) => item.id}
    />
  );
};
