import {
  Box,
  Container,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { ProductMenu } from "../../components/shell/nav/ProductMenu";

export const AccountSettings = () => {
  const headerColor = useColorModeValue("gray.300", "gray.700");
  const { isProductAdmin, isEventAdmin, isInventoryAdmin } =
    useAuthentication();

  return (
    <Container maxWidth="8xl">
      <Flex direction={["column", null, "row"]}>
        {(isProductAdmin || isEventAdmin || isInventoryAdmin) && (
          <Box
            w={["100%", null, "200px"]}
            mr={[0, null, 10]}
            mb={[10, null, 0]}
            minWidth={["100%", null, "200px"]}
            display={["none", "none", "block"]}
          >
            <Box
              w="100%"
              borderColor={headerColor}
              borderWidth={1}
              borderRadius="md"
              fontSize="md"
            >
              <Box bgColor={headerColor} p={2} borderTopRadius="md">
                <Text>Account Settings</Text>
              </Box>
              <Box p={2}>
                <ProductMenu openExpanded={true} />
              </Box>
            </Box>
          </Box>
        )}
        <Outlet />
      </Flex>
    </Container>
  );
};
