import {
  ApiAttachment,
  ApiClient,
  ApiInventoryCostType,
  ApiInventoryItem,
  ApiInventoryType,
  ApiReportingCategory,
  ApiUnitOfMeasureSummary,
  CreateApiInventoryItemStorageLocation,
  CreateApiInventoryItemSupplier,
  UpdateApiInventoryItem,
  UpdateApiInventoryItemStorageLocation,
  UpdateApiInventoryItemSupplier,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getChangedPropsFromObject } from "../../utils/compareObjects";

interface InventoryFormErrors {
  name: boolean;
  category: boolean;
  units: boolean;
  catalog: boolean;
}

export interface InventoryItemStorageLocation
  extends Omit<CreateApiInventoryItemStorageLocation, "units"> {
  id: string;
  isNew?: boolean;
  active?: boolean;
}

export interface InventoryItemSupplier extends CreateApiInventoryItemSupplier {
  id: string;
  isNew?: boolean;
  active?: boolean;
}
export interface InventoryFormItemValues
  extends Omit<
    ApiInventoryItem,
    "category" | "supplier" | "units" | "storageLocations" | "image"
  > {
  category: ApiReportingCategory | null;
  supplier: InventoryItemSupplier[];
  units: ApiUnitOfMeasureSummary | null;
  storageLocations: InventoryItemStorageLocation[];
}

// Inventory Item Slice
export interface InventoryItemFormSlice {
  item: InventoryFormItemValues;
  itemCopy: InventoryFormItemValues | null;
  submitCount: number;
  errors: InventoryFormErrors;
  workingLocation: InventoryItemStorageLocation | null;
  workingSupplier: InventoryItemSupplier | null;
  includeInactiveLocations: boolean;
  itemAttachment: ApiAttachment | null;
}

const mapInventoryItem = (values: InventoryFormItemValues) => {
  if (!values.category) return null;
  const { storageLocations, supplier } = values;
  const newLocations: CreateApiInventoryItemStorageLocation[] | null =
    storageLocations.length > 0
      ? storageLocations.map((sl) => ({
          ...sl,
          units: values.units as ApiUnitOfMeasureSummary,
        }))
      : null;

  const suppliers: CreateApiInventoryItemSupplier[] | null =
    supplier.length > 0
      ? supplier.map((supp) => ({
          ...supp,
        }))
      : null;
  return {
    ...values,
    category: values.category.id,
    supplier: suppliers,
    units: values.units as ApiUnitOfMeasureSummary,
    identifiers: {
      ...values.identifiers,
      upc: values.identifiers.upc || values.name,
    },
    storageLocations: newLocations,
  };
};

interface SaveInventoryItem {
  apiClient: ApiClient;
  accountId: string;
}

export const createInventoryItem = createAsyncThunk(
  "inventory-item/create",
  async (params: SaveInventoryItem, ThunkAPI) => {
    const { apiClient, accountId } = params;
    const { item } = (ThunkAPI.getState() as RootState).inventoryItemSlice;
    const inventoryItem = mapInventoryItem(item);
    if (!inventoryItem) return Promise.reject({});

    const response = await apiClient.createInventoryItem(
      accountId,
      inventoryItem
    );

    return response;
  }
);

export const updateInventoryItem = createAsyncThunk(
  "inventory-item/update",
  async (params: SaveInventoryItem, ThunkAPI) => {
    const { apiClient, accountId } = params;
    const { item, itemCopy } = (ThunkAPI.getState() as RootState)
      .inventoryItemSlice;

    if (!item.id || !item.category) return Promise.reject({});

    const valuesToUpdate = getChangedPropsFromObject<UpdateApiInventoryItem>(
      itemCopy,
      item
    );

    const newStorageLocations = item.storageLocations.filter((sl) => sl.isNew);
    if (newStorageLocations.length > 0) {
      await Promise.all(
        newStorageLocations.map((sl) =>
          apiClient.createInventoryItemStorageLocation(accountId, item.id, sl)
        )
      );
    }

    const newSuppliers = item.supplier.filter((sup) => sup.isNew);
    if (newSuppliers.length > 0) {
      await Promise.all(
        newSuppliers.map((sup) =>
          apiClient.createInventoryItemSupplier(accountId, item.id, sup)
        )
      );
    }

    const response = await apiClient.updateInventoryItem(
      accountId,
      item.id,
      valuesToUpdate
    );

    return response;
  }
);

interface RemoveStorageLocationParams {
  apiClient: ApiClient;
  accountId: string;
  inventoryItemId: string;
  inventoryItemStorageLocationId: string;
}

export const removeStorageLocation = createAsyncThunk(
  "inventory-item/remove-location",
  async (params: RemoveStorageLocationParams, ThunkAPI) => {
    const {
      apiClient,
      accountId,
      inventoryItemId,
      inventoryItemStorageLocationId,
    } = params;

    await apiClient.deactivateInventoryItemStorageLocation(
      accountId,
      inventoryItemId,
      inventoryItemStorageLocationId
    );

    return inventoryItemStorageLocationId;
  }
);

interface UpdateStorageLocationParams {
  apiClient: ApiClient;
  accountId: string;
  inventoryItemId: string;
  inventoryItemStorageLocationId: string;
  inventoryItemStorageLocation: UpdateApiInventoryItemStorageLocation;
}

export const updateStorageLocation = createAsyncThunk(
  "inventory-item/update-location",
  async (params: UpdateStorageLocationParams, ThunkAPI) => {
    const {
      apiClient,
      accountId,
      inventoryItemId,
      inventoryItemStorageLocationId,
      inventoryItemStorageLocation,
    } = params;

    const response = await apiClient.updateInventoryItemStorageLocation(
      accountId,
      inventoryItemId,
      inventoryItemStorageLocationId,
      inventoryItemStorageLocation
    );

    return response;
  }
);

interface LoadInventoryItemParams {
  apiClient: ApiClient;
  accountId: string;
  itemId: string;
}

export const initInventoryItemForm = createAsyncThunk(
  "inventory-item/init",
  async (params: LoadInventoryItemParams, ThunkAPI) => {
    const { apiClient, accountId, itemId } = params;

    if (itemId !== "new") {
      const response = await apiClient.getInventoryItem(accountId, itemId);
      const { data } = await apiClient.findInventoryItemAttachments(
        accountId,
        itemId
      );
      return { response, attachment: data[0] };
    }

    return { response: ITEM_DEFAULT_VALUES, attachment: null };
  }
);

export const deactivateInventoryItem = createAsyncThunk(
  "inventory-item/deactivate",
  async (params: LoadInventoryItemParams) => {
    const { apiClient, accountId, itemId } = params;

    if (itemId === "new") return Promise.reject({});

    const response = await apiClient.deactivateInventoryItem(accountId, itemId);

    return response;
  }
);

export const reactivateInventoryItem = createAsyncThunk(
  "inventory-item/reactivate",
  async (params: LoadInventoryItemParams) => {
    const { apiClient, accountId, itemId } = params;

    const response = await apiClient.reactivateInventoryItem(accountId, itemId);
    return response;
  }
);

interface InventoryItemLocationParams extends LoadInventoryItemParams {
  locationId: string;
}

export const deactivateInventoryItemLocation = createAsyncThunk(
  "inventory-item/deactivate/location",
  async (params: InventoryItemLocationParams) => {
    const { apiClient, accountId, itemId, locationId } = params;
    await apiClient.deactivateInventoryItemStorageLocation(
      accountId,
      itemId,
      locationId
    );

    return locationId;
  }
);

export const reactivateInventoryItemLocation = createAsyncThunk(
  "inventory-item/reactivate/location",
  async (params: InventoryItemLocationParams) => {
    const { apiClient, accountId, itemId, locationId } = params;
    await apiClient.reactivateInventoryItemStorageLocation(
      accountId,
      itemId,
      locationId
    );

    return locationId;
  }
);

interface ReloadInventoryItemLocations extends LoadInventoryItemParams {
  includeInactive: boolean;
}

export const reloadItemStorageLocations = createAsyncThunk(
  "inventory-item/reload/locations",
  async (params: ReloadInventoryItemLocations) => {
    const { apiClient, accountId, itemId, includeInactive } = params;

    const response = await apiClient.findInventoryItemStorageLocations(
      accountId,
      itemId,
      { includeInactive }
    );

    return response;
  }
);

interface InventoryItemSupplierParams extends LoadInventoryItemParams {
  inventoryItemSupplierId: string;
}

export const removeInventoryItemSupplier = createAsyncThunk(
  "inventory-item/remove/supplier",
  async (params: InventoryItemSupplierParams) => {
    const { apiClient, accountId, itemId, inventoryItemSupplierId } = params;
    await apiClient.removeInventoryItemSupplier(
      accountId,
      itemId,
      inventoryItemSupplierId
    );

    return inventoryItemSupplierId;
  }
);
interface UpdateSupplierParams {
  apiClient: ApiClient;
  accountId: string;
  inventoryItemId: string;
  inventoryItemSupplierId: string;
  inventoryItemSupplier: UpdateApiInventoryItemSupplier;
}

export const updateSupplier = createAsyncThunk(
  "inventory-item/update-supplier",
  async (params: UpdateSupplierParams, ThunkAPI) => {
    const {
      apiClient,
      accountId,
      inventoryItemId,
      inventoryItemSupplierId,
      inventoryItemSupplier,
    } = params;

    const response = await apiClient.updateInventoryItemSupplier(
      accountId,
      inventoryItemId,
      inventoryItemSupplierId,
      inventoryItemSupplier
    );

    return response;
  }
);

export const reloadItemSuppliers = createAsyncThunk(
  "inventory-item/reload/supplier",
  async (params: LoadInventoryItemParams) => {
    const { apiClient, accountId, itemId } = params;

    const response = await apiClient.findInventoryItemSuppliers(
      accountId,
      itemId
    );

    return response;
  }
);

const ITEM_DEFAULT_VALUES: InventoryFormItemValues = {
  id: "",
  catalog: {
    id: "",
    name: "",
    active: true,
    requireBudgetForInventoryRequests: false,
  },
  name: "",
  type: ApiInventoryType.stock,
  typeCost: ApiInventoryCostType.lastPurchasePrice,
  allowRequesting: false,
  cost: 0,
  summary: "",
  units: null,
  category: null,
  supplier: [],
  totalSuppliers: 0,
  manufacturer: null,
  storageLocations: [],
  identifiers: {
    mpn: "",
    upc: "",
    externalId: "",
  },
  // next are not used in create, but are used en update or details views
  created: "",
  updated: "",
  active: true,
  totalStorageLocations: 0,
};

const ERROR_DEFAULT_VALUES: InventoryFormErrors = {
  name: false,
  category: false,
  units: false,
  catalog: false,
};

const DEFAULT_VALUES: InventoryItemFormSlice = {
  item: ITEM_DEFAULT_VALUES,
  itemCopy: null,
  submitCount: 0,
  workingLocation: null,
  workingSupplier: null,
  errors: { ...ERROR_DEFAULT_VALUES },
  includeInactiveLocations: false,
  itemAttachment: null,
};

const inventoryItemSlice = createSlice({
  name: "inventoryItem",
  initialState: {
    ...DEFAULT_VALUES,
  } as InventoryItemFormSlice,
  reducers: {
    unloadInventoryItem: (state: InventoryItemFormSlice) => {
      state.item = { ...ITEM_DEFAULT_VALUES };
      state.itemCopy = null;
      state.errors = { ...ERROR_DEFAULT_VALUES };
      state.submitCount = 0;
      state.includeInactiveLocations = false;
      state.itemAttachment = null;
    },
    addStorageLocation: (
      state,
      action: PayloadAction<InventoryItemStorageLocation>
    ) => {
      state.item.storageLocations?.unshift(action.payload);
    },
    addSupplier: (state, action: PayloadAction<InventoryItemSupplier>) => {
      state.item.supplier?.unshift(action.payload);
    },
    setWorkingLocation: (
      state,
      action: PayloadAction<InventoryItemStorageLocation | null>
    ) => {
      state.workingLocation = action.payload;
    },
    setWorkingSupplier: (
      state,
      action: PayloadAction<InventoryItemSupplier | null>
    ) => {
      state.workingSupplier = action.payload;
    },
    removeStorageLocationFromRedux: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      const { id } = action.payload;
      const index = state.item.storageLocations.findIndex((sl) => {
        const locationId =
          typeof sl.storageLocation === "string"
            ? sl.storageLocation
            : sl.storageLocation.id;
        return locationId === id;
      });

      if (index !== -1) {
        state.item.storageLocations.splice(index, 1);
      }
    },
    removeSupplierFromRedux: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const index = state.item.supplier.findIndex((sup) => {
        const supplierId =
          typeof sup.supplier === "string" ? sup.supplier : sup.supplier.id;
        return supplierId === id;
      });

      if (index !== -1) {
        state.item.supplier.splice(index, 1);
      }
    },
    updateInventoryItemState: (
      state: InventoryItemFormSlice,
      action: PayloadAction<Partial<InventoryFormItemValues>>
    ) => {
      state.item = { ...state.item, ...action.payload };
      const updatedValueKeys = Object.keys(action.payload);
      const errorsKeys = Object.keys(state.errors);

      state.submitCount > 0 &&
        updatedValueKeys.forEach((key) => {
          if (errorsKeys.some((item) => item === key)) {
            const parsedKey = key as keyof InventoryFormItemValues;
            const hasValue =
              action.payload[parsedKey] === "" &&
              action.payload[parsedKey] !== null;
            state.errors[key as keyof InventoryFormErrors] = hasValue;
          }
        });
    },
    setInventoryFormErrors: (
      state: InventoryItemFormSlice,
      action: PayloadAction<Partial<InventoryFormErrors>>
    ) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    increaseSubmitCount: (state: InventoryItemFormSlice) => {
      state.submitCount++;
    },
    setIncludeInactiveLocations: (
      state: InventoryItemFormSlice,
      action: PayloadAction<boolean>
    ) => {
      state.includeInactiveLocations = action.payload;
    },
    setInventoryItemAttachment: (
      state: InventoryItemFormSlice,
      action: PayloadAction<ApiAttachment | null>
    ) => {
      state.itemAttachment = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createInventoryItem.fulfilled, (state, action) => {
      state.submitCount = 0;
      state.item = action.payload;
    });

    builder.addCase(initInventoryItemForm.fulfilled, (state, action) => {
      state.item = action.payload.response;
      state.itemCopy = action.payload.response;
      state.itemAttachment = action.payload.attachment;
    });

    builder.addCase(removeStorageLocation.fulfilled, (state, action) => {
      const index = state.item.storageLocations.findIndex(
        (sl) => sl.id === action.payload
      );
      if (index !== -1) {
        state.item.storageLocations.splice(index, 1);
      }
    });

    builder.addCase(updateStorageLocation.fulfilled, (state, action) => {
      const index = state.item.storageLocations.findIndex(
        (sl) => sl.id === action.payload.id
      );
      if (index !== -1) {
        state.item.storageLocations[index] = action.payload;
      }
    });

    builder.addCase(deactivateInventoryItem.fulfilled, (state) => {
      state.item.active = false;
    });

    builder.addCase(reactivateInventoryItem.fulfilled, (state, action) => {
      state.item.active = action.payload.active;
    });

    builder.addCase(
      deactivateInventoryItemLocation.fulfilled,
      (state, action) => {
        const locationIdx = state.item.storageLocations.findIndex(
          (sl) => sl.id === action.payload
        );
        if (locationIdx === -1) return;

        state.workingLocation && (state.workingLocation.active = false);
        if (state.includeInactiveLocations) {
          state.item.storageLocations[locationIdx].active = false;
        } else {
          state.item.storageLocations.splice(locationIdx, 1);
        }
      }
    );

    builder.addCase(
      reactivateInventoryItemLocation.fulfilled,
      (state, action) => {
        const locationIdx = state.item.storageLocations.findIndex(
          (sl) => sl.id === action.payload
        );

        if (locationIdx === -1) return;

        state.workingLocation && (state.workingLocation.active = true);
        state.item.storageLocations[locationIdx].active = true;
      }
    );

    builder.addCase(reloadItemStorageLocations.fulfilled, (state, action) => {
      state.item.storageLocations = action.payload.data;
    });
    builder.addCase(reloadItemSuppliers.fulfilled, (state, action) => {
      state.item.supplier = action.payload.data;
    });
    builder.addCase(removeInventoryItemSupplier.fulfilled, (state, action) => {
      const id = action.payload;
      const index = state.item.supplier.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.item.supplier.splice(index, 1);
      }
    });
    builder.addCase(updateSupplier.fulfilled, (state, action) => {
      const index = state.item.supplier.findIndex(
        (sup) => sup.id === action.payload.id
      );
      if (index !== -1) {
        state.item.supplier[index] = action.payload;
      }
    });
  },
});

export const {
  setWorkingSupplier,
  addStorageLocation,
  setWorkingLocation,
  removeStorageLocationFromRedux,
  updateInventoryItemState,
  unloadInventoryItem,
  increaseSubmitCount,
  setInventoryFormErrors,
  setIncludeInactiveLocations,
  setInventoryItemAttachment,
  addSupplier,
  removeSupplierFromRedux,
} = inventoryItemSlice.actions;

export default inventoryItemSlice.reducer;
