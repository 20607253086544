import {
  Box,
  Flex,
  HStack,
  StackDivider,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiRequest,
  ApiRequestStatus,
  ApiWorkflow,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { ActionsColumn } from "../../requests/request-row/ActionsColumn";
import { ContentColumn } from "../../requests/request-row/ContentColumn";
import { InfoColumn } from "../../requests/request-row/InfoColumn";
import { InfoColumnMobile } from "../../requests/request-row/InfoColumnMobile";

export interface RequestRowProps {
  request: ApiRequest;
  onStatusChange?: (params: {
    request: ApiRequest;
    status: ApiRequestStatus;
    workflow: ApiWorkflow;
    policy: WorkflowPolicy;
  }) => void;
  isMini?: boolean;
  selected?: boolean;
  screenMode: {
    isMobile?: boolean;
    isTablet?: boolean;
    isDesktop?: boolean;
  };
}

export const RequestBaseItem = ({
  request,
  onStatusChange,
  isMini,
  selected = true,
  screenMode: { isDesktop, isTablet, isMobile },
}: RequestRowProps) => {
  const bgColorMini = useColorModeValue("white", "gray.600");
  const typeColor = useColorModeValue("blue.500", "whiteAlpha");
  const dividerColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Flex w="100%">
      {/* MOBILE */}
      {isMobile && (
        <VStack
          minW="full"
          display="flex"
          verticalAlign="center"
          bgColor={isMini ? bgColorMini : "unset"}
          borderRadius="md"
          divider={
            <StackDivider borderColor={dividerColor} overflow="hidden" />
          }
        >
          <Box width="full">
            <InfoColumnMobile request={request} typeColor={typeColor} />
          </Box>
          {!isMini && (
            <Flex
              px="4"
              width="full"
              minWidth={0}
              alignSelf="flex-start"
              flexDirection="column"
            >
              <ContentColumn request={request} />
            </Flex>
          )}
          <Box my={2}>
            <ActionsColumn
              request={request}
              onStatusChange={onStatusChange}
              showIcons={false}
            />
          </Box>
        </VStack>
      )}

      {/* TABLET */}
      {isTablet && (
        <VStack
          w="100%"
          spacing={3}
          display="flex"
          verticalAlign="center"
          justifyContent="space-between"
          bgColor={isMini ? bgColorMini : "unset"}
          divider={<StackDivider borderColor={dividerColor} />}
        >
          <HStack
            spacing={5}
            divider={<StackDivider borderColor={dividerColor} />}
            width="full"
          >
            <Flex flexDirection="column" alignSelf="baseline" flex={1}>
              <InfoColumn
                request={request}
                typeColor={typeColor}
                isMini={isMini}
              />
            </Flex>
            <Flex flexDirection="column" alignSelf="baseline">
              <ActionsColumn
                request={request}
                onStatusChange={onStatusChange}
                showIcons={true}
              />
            </Flex>
          </HStack>
          {!isMini && (
            <Flex
              alignSelf="flex-start"
              width={"full"}
              minWidth={0}
              flexDirection={"column"}
            >
              <ContentColumn request={request} isTablet={isTablet} />
            </Flex>
          )}
        </VStack>
      )}

      {/* DESKTOP */}
      {isDesktop && (
        <HStack
          w="100%"
          display="flex"
          spacing={!onStatusChange ? 2 : 6}
          divider={<StackDivider borderColor={dividerColor} />}
        >
          <Flex flexDirection="column">
            <InfoColumn
              request={request}
              typeColor={typeColor}
              isMini={isMini}
            />
          </Flex>

          {!isMini && (
            <Flex flexDirection="column" alignSelf="baseline" flex={1}>
              <ContentColumn request={request} />
            </Flex>
          )}

          <Flex w="216px" alignSelf="baseline" flexDirection="column">
            <ActionsColumn
              showIcons={true}
              request={request}
              onStatusChange={onStatusChange}
            />
          </Flex>
        </HStack>
      )}
    </Flex>
  );
};
