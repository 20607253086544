import { createContext, ReactNode, useContext } from "react";

const ModalContext = createContext<boolean | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  return <ModalContext.Provider value={true}>{children}</ModalContext.Provider>;
};

export const useIsInModal = () => {
  return useContext(ModalContext);
};
