import { ApiClient } from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface DeleteTransactionAttachmentThunkParams {
  apiClient: ApiClient;
  accountId: string;
  requestIdOrKey: string;
  transactionId: string;
  id: string;
}

export const deleteTransactionAttachment = createAsyncThunk(
  "request/deleteTransactionAttachment",
  async (params: DeleteTransactionAttachmentThunkParams) => {
    const { apiClient, accountId, requestIdOrKey, transactionId, id } = params;
    const promise = apiClient.deleteRequestTransactionAttachment(
      accountId,
      requestIdOrKey,
      transactionId,
      id
    );
    return promise;
  }
);
