import { ApiAccountDetail } from "@operations-hero/lib-api-client";
import { useEffect, useState } from "react";
import { useAuthentication } from "../components/auth/AuthProvider";

export const useAccountDetails = () => {
  const { apiClient, currentAccount } = useAuthentication();
  const [accountDetails, setAccountDetails] = useState<ApiAccountDetail>();
  useEffect(() => {
    apiClient.getAccountDetail(currentAccount.id).then((details) => {
      setAccountDetails(details);
    });
  }, [apiClient, currentAccount]);

  return { accountDetails };
};
