import { ViewOffIcon } from "@chakra-ui/icons";
import { Box, Grid, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { ApiRequestStatus } from "@operations-hero/lib-api-client";
import { motion } from "framer-motion";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useColumnViewContext } from "./ColumnViewContext";
export type BoardHeadersProps = {
  handleStatusVisibility: (status: ApiRequestStatus) => void;
};

export const BoardHeaders: FC<BoardHeadersProps> = ({
  handleStatusVisibility,
}) => {
  const { enabledStatuses } = useColumnViewContext();
  const { referencesByStatus } = useSelector(
    (state: RootState) => state.requestsColumnViewSlice
  );

  const backgroundColor = useColorModeValue("gray.50", "gray.700");
  const headerBgColor = useColorModeValue("gray.100", "gray.600");
  const headerColor = useColorModeValue("gray.600", "gray.50");

  return (
    <Box
      w="full"
      position="sticky"
      top="0"
      zIndex="2"
      backgroundColor={backgroundColor}
      pt="2"
    >
      <Grid
        width="full"
        templateColumns={`repeat(${
          Object.keys(ApiRequestStatus).length
        }, minmax(144px, 1fr))`}
        columnGap="3"
      >
        {enabledStatuses.map((statusKey) => {
          return (
            <Box
              key={`columnHeader::${statusKey}`}
              as={motion.div}
              layout
              initial={{
                opacity: 1,
              }}
              w="full"
            >
              <Box
                fontSize="sm"
                w="full"
                fontWeight="semibold"
                color={headerColor}
                backgroundColor={headerBgColor}
                rounded="sm"
                border="solid"
                borderColor="gray.500"
                px="1"
                borderWidth="1px"
              >
                <HStack w="full" justifyContent="space-between">
                  <Text>{`${statusKey.toUpperCase()} (${
                    referencesByStatus[statusKey]?.totalItems ?? 0
                  })`}</Text>
                  <ViewOffIcon
                    onClick={() => handleStatusVisibility(statusKey)}
                    cursor="pointer"
                  />
                </HStack>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};
