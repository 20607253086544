import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import {
  ApiRequest,
  ApiRequestPriority,
  ApiWorkflow,
} from "@operations-hero/lib-api-client";
import { getIn, useField, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { RequestPrioritySelect } from "../selects/RequestPrioritySelect";

export interface RequestPrioritySelectControlProps {
  label: string;
  name: string;
  value: ApiRequestPriority;
}

export const RequestPrioritySelectControl = ({
  name,
  value,
  label,
}: RequestPrioritySelectControlProps) => {
  const { submitCount } = useFormikContext();
  useAutomaticDueDates();

  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (priority: ApiRequestPriority | null) => {
      helper.setTouched(true);
      helper.setValue(priority || null);
    },
    [helper]
  );

  const isInvalid = useMemo(
    () => !!meta.error && (meta.touched || submitCount > 0),
    [meta, submitCount]
  );

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <RequestPrioritySelect {...field} onChange={handleOnChange} name={name} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const useAutomaticDueDates = () => {
  const workflow = useSelector(
    (state: RootState) => state.requestForm.workflow
  );
  const request = useSelector((state: RootState) => state.requestForm.request);
  const delta = useSelector(
    (state: RootState) => state.requestForm.changeModal.delta
  );

  const { values, setFieldValue, touched } =
    useFormikContext<Partial<ApiRequest>>();

  const priority = getIn(values, "priority");

  const isAutomaticDue = useMemo(() => {
    if (!workflow || !request || !delta) {
      return false;
    }

    return (
      workflow.allowAutomaticDueDate &&
      !request.scheduling.due &&
      !delta.scheduling?.due
    );
  }, [delta, request, workflow]);

  useEffect(() => {
    if (!priority || !isAutomaticDue || !workflow) {
      return;
    }

    // @ts-ignore
    if (touched.scheduling?.due) {
      return;
    }

    const priorityKey = `priority${capitalizeFirstLetter(
      priority
    )}` as keyof ApiWorkflow;

    const hoursToAdd =
      ((workflow as any)[`${priorityKey}`] as number) * (60 * 60 * 1000);

    const dueDate = new Date(new Date().getTime() + hoursToAdd).toISOString();

    setFieldValue("scheduling.due", dueDate);
  }, [
    priority,
    isAutomaticDue,
    workflow,
    setFieldValue,
    //@ts-ignore
    touched.scheduling?.due,
  ]);
};
