import {
  ApiClient,
  UpdateApiRequestTaskBook,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface UpdateTaskbookParams {
  apiClient: ApiClient;
  accountId: string;
  taskbookId: string;
  requestId: string;
  requestTaskbook: UpdateApiRequestTaskBook;
}

export const updateTaskbook = createAsyncThunk(
  "requests/updateTaskbook",
  async (params: UpdateTaskbookParams, thunkAPI) => {
    const { apiClient, accountId, taskbookId, requestId, requestTaskbook } =
      params;

    return await apiClient.updateRequestTaskbook(
      accountId,
      requestId,
      taskbookId,
      requestTaskbook
    );
  }
);

export const updateTaskbookHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(updateTaskbook.fulfilled, (state, action) => {
    const index = state.taskbooks.findIndex(
      (taskbook) => taskbook.id === action.payload.id
    );
    if (index !== -1) {
      state.taskbooks[index] = action.payload;
    }
  });
};
