import { CSSObject } from "@chakra-ui/styled-system";
import { createContext, useContext } from "react";

export type StylingType =
  | "default"
  | "request.column-view"
  | "project.scheduling";

export type AppShellStylingContextProps = {
  stylingMode: StylingType;
  setToDefaultStyling: () => void;
  styling: CSSObject;
};

export const AppShellStylingContext =
  createContext<AppShellStylingContextProps | null>(null);

export function useAppShellStylingContext() {
  const value = useContext(AppShellStylingContext);
  if (!value) throw new Error("App shell styling context provider");
  return value;
}
