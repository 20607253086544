import { Divider, VStack } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

type CommentListProps = PropsWithChildren & {};

export const CommentList: FC<CommentListProps> = ({ children }) => {
  return (
    <VStack w="full" align="stretch" divider={<Divider />} gap={5}>
      {children}
    </VStack>
  );
};
