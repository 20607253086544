import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  ResponsiveValue,
  Text,
  useColorModeValue,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ApiAttachment } from "@operations-hero/lib-api-client";
import { useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { HiDocumentAdd } from "react-icons/hi";
import { MdUpload } from "react-icons/md";
import { AttachmentFileCard } from "./AttachmentFileCard";
import { AttachmentsAccordion } from "./AttachmentsAccordion";

export interface AttachmentsProps {
  attachments: Attachment[];
  onNewAttachments: (files: Attachment[]) => void;
  onDeleteAttachment: (file: Attachment) => void;
  onUpdateAttachment?: (attachment: Attachment, label: string) => void;
  isDisabled?: boolean;
  gridColumns?: ResponsiveValue<number>;
  title?: string;
  hideDraggable?: boolean;
  displayAccordion?: boolean;
  displayActions?: boolean;
  addButtonLabel?: string;
  canEditLabel?: boolean;
  showData?: boolean;
  cardBoxProps?: BoxProps;
  allowedTypes?: string[];
  heading?: boolean;
  thumbnail?: Attachment;
  getDefaultPhoto?: (attachment: Attachment) => void;
  showThumbnailEdit?: boolean;
  display?: "normal" | "grid";
}

export interface Attachment {
  created: string;
  file?: File;
  name: string;
  type: string;
  url: string | null;
  isNew?: boolean;
  isUploading?: boolean;
  uploadId?: string;
  progress?: number;
  label?: string;
  id?: string;
  active?: boolean;
  scannedByAi?: boolean;
  description?: string;
}

export const Attachments = ({
  attachments,
  onDeleteAttachment,
  onNewAttachments,
  isDisabled,
  gridColumns,
  title,
  hideDraggable = false,
  displayAccordion = false,
  displayActions,
  addButtonLabel,
  canEditLabel,
  onUpdateAttachment,
  showData,
  cardBoxProps,
  allowedTypes,
  heading = true,
  thumbnail,
  getDefaultPhoto,
  showThumbnailEdit,
  display = "normal",
}: AttachmentsProps) => {
  const [rejections, setRejections] = useState<FileRejection[]>([]);
  const [accordionItem, setAccordionItem] = useState<number[] | undefined>(
    undefined
  );

  const [defaultPhoto, setDefaultPhoto] = useState<Attachment | undefined>(
    thumbnail
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (onNewAttachments && acceptedFiles.length > 0) {
        const newAttachments = acceptedFiles.map<Attachment>((file) => ({
          created: new Date().toISOString(),
          file,
          name: file.name,
          type: file.type,
          url: null,
          isNew: true,
        }));
        onNewAttachments(newAttachments);
        displayAccordion && setAccordionItem([0]);
      }

      setRejections(fileRejections);
    },
    [onNewAttachments, displayAccordion]
  );

  const handleThumbnail = useCallback(
    (attachment: Attachment) => {
      if (!getDefaultPhoto) return;
      setDefaultPhoto(attachment);
      getDefaultPhoto(attachment);
    },
    [setDefaultPhoto, getDefaultPhoto]
  );

  const handleDeleteAttachment = useCallback(
    (attachment: Attachment) => () => {
      onDeleteAttachment(attachment);
    },
    [onDeleteAttachment]
  );

  // https://react-dropzone.js.org/#src
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } =
    useDropzone({
      accept: allowedTypes
        ? allowedTypes.reduce(
            (acc, type) => {
              acc[type] = [];
              return acc;
            },
            {} as Record<string, string[]>
          )
        : allowedMimeTypes.reduce(
            (acc, mimeType) => {
              acc[mimeType] = [];
              return acc;
            },
            {} as Record<string, string[]>
          ),
      maxFiles: 20,
      maxSize: 52428800, // 1024 * 1024 * 50 = 50MB
      multiple: true,
      noClick: isDisabled || (attachments.length > 0 && display === "normal"),
      noDrag: isDisabled,
      onDrop,
    });

  const sectionBgColor = useColorModeValue("white", "gray.700");
  const draggingBgColor = useColorModeValue("blue.50", "blue.700");
  const borderColor = useColorModeValue("blue.500", "gray.600");
  const draggingBorderColor = useColorModeValue("blue.100", "blue.300");
  const buttonColor = useColorModeValue("blue.500", "blue.300");
  const primaryTextColor = useColorModeValue("black", "white");
  const secondaryTextColor = useColorModeValue("gray.500", "white");

  const memoizedAccordion = useMemo(
    () => (
      <AttachmentsAccordion
        defaultIndex={accordionItem}
        setAccordionItem={setAccordionItem}
      >
        <Wrap
          spacing={4}
          border={isDragActive ? "2px" : undefined}
          padding={isDragActive ? 0 : "2px"}
          borderColor={isDragActive ? draggingBorderColor : undefined}
        >
          {attachments.map((attachment, idx) => (
            <WrapItem key={attachment.created + "_idx_" + idx.toString()}>
              <AttachmentFileCard
                defaultPhoto={defaultPhoto}
                showThumbnailEdit={showThumbnailEdit}
                setDefaultPhoto={handleThumbnail}
                attachment={attachment}
                progress={attachment.progress}
                isDisabled={isDisabled}
                onDelete={handleDeleteAttachment(attachment)}
                canEditLabel={canEditLabel}
              />
            </WrapItem>
          ))}
        </Wrap>
      </AttachmentsAccordion>
    ),
    [
      accordionItem,
      isDragActive,
      draggingBorderColor,
      attachments,
      isDisabled,
      handleDeleteAttachment,
      canEditLabel,
      defaultPhoto,
      handleThumbnail,
      showThumbnailEdit,
    ]
  );

  const gridDroppableZone = useMemo(() => {
    return (
      <VStack align="stretch" alignItems="center" p={3}>
        <Icon as={HiDocumentAdd} boxSize={10} color={buttonColor} />
        {!isDragActive ? (
          <>
            <HStack wrap="wrap" justifyItems="center">
              <Text
                fontSize="md"
                fontWeight="bold"
                color={primaryTextColor}
                lineHeight="5"
                justifyContent="center"
                textAlign="center"
              >
                Drag and drop here or{" "}
                <Text
                  as="span"
                  fontSize="md"
                  color={buttonColor}
                  fontWeight="bold"
                >
                  Select Files
                </Text>
              </Text>
            </HStack>
            <Text
              fontSize="sm"
              color={secondaryTextColor}
              justifyContent="center"
              textAlign="center"
            >
              You can add images, pdfs, or docx
            </Text>
          </>
        ) : (
          <Text fontWeight="semibold">Drop file(s) to upload.</Text>
        )}
      </VStack>
    );
  }, [isDragActive, secondaryTextColor, primaryTextColor, buttonColor]);

  return (
    <Box>
      <input {...getInputProps()} />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        py={1}
      >
        <Flex alignItems="flex-start">
          {heading && (
            <>
              <MdUpload size="25px" />
              <Heading size="md" mb={3}>
                {title ? title : "Upload Attachments"}
              </Heading>
            </>
          )}
        </Flex>
        {display === "normal" &&
        !isDisabled &&
        (hideDraggable || attachments.length > 0) ? (
          <Box maxW="md">
            <Button
              onClick={open}
              size="sm"
              variant="outline"
              colorScheme="blue"
            >
              {addButtonLabel ? addButtonLabel : "Upload Images"}
            </Button>
          </Box>
        ) : null}
      </Box>
      {attachments.length > 0 ? (
        displayAccordion ? (
          { ...memoizedAccordion }
        ) : (
          <Wrap
            spacing={4}
            border={isDragActive ? "2px" : undefined}
            padding={isDragActive ? 0 : "2px"}
            borderColor={isDragActive ? draggingBorderColor : undefined}
          >
            {attachments.map((attachment, idx) => (
              <WrapItem key={attachment.created + "_idx_" + idx.toString()}>
                <AttachmentFileCard
                  showData={showData}
                  attachment={attachment}
                  isDisabled={isDisabled}
                  boxProps={cardBoxProps}
                  defaultPhoto={defaultPhoto}
                  setDefaultPhoto={handleThumbnail}
                  canEditLabel={canEditLabel}
                  showThumbnailEdit={showThumbnailEdit}
                  progress={attachment.progress}
                  displayActions={displayActions}
                  onUpdateAttachment={onUpdateAttachment}
                  onDelete={handleDeleteAttachment(attachment)}
                />
              </WrapItem>
            ))}
            {!hideDraggable && display === "grid" && (
              <Center
                {...getRootProps()}
                cursor="pointer"
                borderColor={isDragActive ? draggingBorderColor : borderColor}
                borderStyle="dashed"
                borderWidth="thin"
                borderRadius="md"
                background={isDragActive ? draggingBgColor : sectionBgColor}
                w="170px"
                h="170px"
              >
                {gridDroppableZone}
              </Center>
            )}
          </Wrap>
        )
      ) : null}
      {!isDisabled && attachments.length === 0
        ? !hideDraggable && (
            <Center
              {...getRootProps()}
              cursor="pointer"
              borderColor={isDragActive ? draggingBorderColor : borderColor}
              borderStyle="dashed"
              borderWidth="thin"
              borderRadius="md"
              background={isDragActive ? draggingBgColor : sectionBgColor}
              w={display === "grid" ? "170px" : "auto"}
            >
              {display === "normal" && (
                <HStack py={7} px="2" wrap={["wrap", "nowrap"]}>
                  <Box
                    w={["full", "max-content"]}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <Icon as={HiDocumentAdd} boxSize={10} color={buttonColor} />
                  </Box>
                  <VStack spacing="unset" alignItems={["center", "start"]}>
                    {acceptedFiles.length === 0 ? (
                      <>
                        <HStack>
                          <Text
                            fontSize="md"
                            fontWeight="bold"
                            color={primaryTextColor}
                            lineHeight="5"
                          >
                            Drag and drop here or
                          </Text>
                          <Text
                            fontSize="md"
                            color={buttonColor}
                            fontWeight="bold"
                          >
                            Select Files
                          </Text>
                        </HStack>
                        <Text fontSize="sm" color={secondaryTextColor}>
                          You can add images, pdfs, or docx
                        </Text>
                      </>
                    ) : (
                      <Text fontWeight="semibold">
                        Drop {acceptedFiles.length} file(s) to upload.
                      </Text>
                    )}
                  </VStack>
                </HStack>
              )}
              {display === "grid" && gridDroppableZone}
            </Center>
          )
        : null}
      {isDisabled && attachments.length === 0 ? (
        <Center background={sectionBgColor} p={4}>
          <Text>No attachments were provided</Text>
        </Center>
      ) : null}
      {rejections.length > 0 ? (
        <Box mt={2} color="tomato">
          File count should be 20 or less and not greater than 50MB in size.
          Allowed formats are .jpg .png .doc .docx .xls .xlsx .pdf .txt
        </Box>
      ) : null}
    </Box>
  );
};

export const allowedMimeTypes = [
  // Word
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // Excel
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // PDF
  "application/pdf",
  // text
  "text/plain",
  // images
  "image/jpeg",
  "image/png",
];

export const mapApiAttachments = (
  attachments: LocalApiAttachment[]
): Attachment[] => {
  return attachments.map((attachment) => ({
    created: attachment.created,
    name: attachment.name,
    url: attachment.url,
    isNew: false,
    isUploading: false,
    uploadId: attachment.id,
    type: attachment.contentType,
    label: attachment.label || undefined,
    id: attachment.id,
    description: attachment.description || "",
    active: attachment.active,
  }));
};

export type LocalApiAttachment = ApiAttachment & {
  description?: string;
  active?: boolean;
};
