import {
  Badge,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiRequestAssignee } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { GroupBadge } from "../../../components/badges/GroupBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { RootState, useThunkDispatch } from "../../../store";
import {
  initPeopleFilter,
  loadPeopleFilterOptions,
  PersonFilterValue,
  PersonFilterValueType,
} from "../../../store/request-list.slice";
import PersonList from "./PersonList";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";

export interface PersonFilterReferenceValue {
  type: PersonFilterValueType;
  groupOrUser: ApiRequestAssignee | string;
}

export interface PersonFilterProps {
  value: PersonFilterReferenceValue[];
  onChange: (value: PersonFilterReferenceValue[]) => void;
}

const ContentRenderer = ({
  props,
  state,
}: SelectRenderer<PersonFilterValue>) => {
  return (
    <Box p={1}>
      {state.values.length === 0 && "Person"}
      {state.values[0] && state.values.length === 1 && (
        <>
          {state.values[0].groupOrUser.type === "group" && (
            <GroupBadge value={state.values[0].groupOrUser.assignee} />
          )}
          {state.values[0].groupOrUser.type === "user" && (
            <UserBadge value={state.values[0].groupOrUser.assignee} />
          )}
        </>
      )}
      {state.values.length > 1 && (
        <>
          Persons <AdditionalNumberBadge number={state.values.length} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<PersonFilterValue>) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();

  const assignees = useMemo(
    () => state.values.filter((x) => x.type === "assignee"),
    [state.values]
  );

  const requesters = useMemo(
    () => state.values.filter((x) => x.type === "requester"),
    [state.values]
  );

  const handleTypeChange = useCallback(
    (index: number) => {
      thunkDispatch(
        loadPeopleFilterOptions({
          apiClient,
          account,
          type: index === 0 ? "assignee" : "requester",
        })
      );
    },
    [thunkDispatch, apiClient, account]
  );

  return (
    <Box>
      <Tabs onChange={handleTypeChange}>
        <TabList>
          <Tab>
            Assignees{" "}
            {assignees.length > 0 && (
              <Badge
                colorScheme="blue"
                size="xs"
                borderRadius="xl"
                ml={2}
                p={1}
              >
                {assignees.length}
              </Badge>
            )}
          </Tab>
          <Tab>
            Requesters{" "}
            {requesters.length > 0 && (
              <Badge
                colorScheme="blue"
                size="xs"
                borderRadius="xl"
                ml={2}
                p={1}
              >
                {requesters.length}
              </Badge>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PersonList
              values={assignees}
              methods={methods}
              type={"assignee"}
            />
          </TabPanel>
          <TabPanel>
            <PersonList
              values={requesters}
              methods={methods}
              type={"requester"}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export const PersonFilter = ({ value, onChange }: PersonFilterProps) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const userFilterOptions = useSelector(
    (state: RootState) => state.requestList.availablePersons
  );
  const { workflows, persons: selectedValues } = useSelector(
    (state: RootState) => state.requestList.filters
  );
  const { initCompleted } = useSelector(
    (state: RootState) => state.requestList
  );

  const initOnce = useRef(false);
  const [filterInitCompleted, setFilterInitCompleted] = useState(false);

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  useEffect(() => {
    // wait for request list init to finish before we run this init
    if (!initCompleted || initOnce.current) {
      return;
    }
    initOnce.current = true;
    // load only selected values to init, no workflow restrictions

    const promise =
      !selectedValues || selectedValues.length === 0
        ? Promise.resolve()
        : thunkDispatch(
            initPeopleFilter({
              apiClient,
              account,
              selectedValues,
            })
          );

    promise.then(() => setFilterInitCompleted(true));

    // on open, load the available options + add selected
  }, [apiClient, account, thunkDispatch, initCompleted, selectedValues]);

  useEffect(() => {
    if (!filterInitCompleted) {
      return;
    }
    thunkDispatch(
      loadPeopleFilterOptions({
        apiClient,
        account,
        type: "assignee",
      })
    );
  }, [apiClient, account, thunkDispatch, filterInitCompleted, workflows]);

  return (
    <Box>
      <Select
        key={`${workflows}`}
        multi={true}
        className={themeClass}
        options={userFilterOptions}
        values={value as PersonFilterValue[]}
        onChange={onChange}
        searchable={true}
        valueField="key"
        dropdownGap={0}
        keepSelectedInList
        //clearable
        contentRenderer={ContentRenderer}
        dropdownRenderer={DropdownRenderer}
        dropdownHeight="300"
        backspaceDelete={false}
      />
    </Box>
  );
};
