import { Box, Button, Divider, Grid, GridItem, HStack } from "@chakra-ui/react";
import {
  ApiBudgetSummary,
  ApiPurchaseTransaction,
  ApiTransactionType,
  ApiVendor,
  ApiWorkflow,
  CreateApiPurchaseTransaction,
  PurchaseType,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";

import { InfoIcon } from "@chakra-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import axios, { AxiosProgressEvent } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  Attachment,
  Attachments,
} from "../../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../../../components/form-helpers/BudgetAutocompleteControl";
import { DatePickerControl } from "../../../../../components/form-helpers/DatePickerControl";
import FocusError from "../../../../../components/form-helpers/FocusError";
import { NumberInputControl } from "../../../../../components/form-helpers/NumberInputControl";
import { RadioButtonsControl } from "../../../../../components/form-helpers/RadioButtonsControl";
import { TextInputControl } from "../../../../../components/form-helpers/TextInputControl";
import { VendorAutocompleteControl } from "../../../../../components/form-helpers/VendorsAutocompleteControl";
import { useShowToast } from "../../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../../store";
import {
  addAttachment,
  AttachmentTypeEnum,
  cleanTransactionAttachements,
  removeAttachmentReducer,
  setTotalPurchaseTransactionChange,
  updateAttachment,
} from "../../../../../store/request-form/request-form.slice";
import { createTransaction } from "../../../../../store/request-form/thunks/createTransaction.thunk";
import { createTransactionAttachment } from "../../../../../store/request-form/thunks/createTransactionAttachment.thunk";
import { deleteTransactionAttachment } from "../../../../../store/request-form/thunks/deleteTransactionAttachment.thunk";
import { loadTransactionAttachments } from "../../../../../store/request-form/thunks/loadTransactionAttachments.thunk";
import { ItemsSection } from "./ItemListSection";

const radioButtonOptions = [
  { label: "Supplies", schemeColor: "blue", value: PurchaseType.supplies },
  { label: "Vendor", schemeColor: "blue", value: PurchaseType.vendor },
];

const PurchaseFormSchema = (type: PurchaseType) =>
  yup.object().shape({
    type: yup.mixed().oneOf([ApiTransactionType.purchase]),
    purchaseType: yup
      .mixed()
      .oneOf([PurchaseType.vendor, PurchaseType.supplies]),
    vendor: yup.object().required("This field is required").nullable(),
    invoiceNumber: yup
      .string()
      .max(255, "Field is too long")
      .optional()
      .nullable(),
    additionalFees: yup
      .number()
      .max(999999.99, "Field must be less than 999999.99")
      .min(-999999.99, "Field must be greater than 999999.99")
      .optional()
      .nullable(),
    datePerformed: yup.string().required().nullable(),
    budget: yup
      .object()
      .shape({ id: yup.string().uuid() })
      .optional()
      .nullable(),
  });

export interface Item {
  itemName: string;
  quantity: number;
  unitCost: number;
}

export interface VerifyScannedReceiptProps {
  manufacturer?: ApiVendor | null;
  transactionId: string | null;
  handleCloseModal: () => void;
  workingPurchase: UpdateApiPurchaseTransaction | null;
  scannedPurchase?: ApiPurchaseTransaction;
  thumbnailPicture?: Attachment;
  itemList: Item[];
}

export const VerifyScannedReceipt: React.FC<VerifyScannedReceiptProps> = ({
  transactionId,
  workingPurchase,
  manufacturer,
  handleCloseModal,
  scannedPurchase,
  thumbnailPicture,
  itemList,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [purchase, setPurchase] = useState<
    CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction
  >();

  const [workflowForm, setWorkflowForm] = useState<ApiWorkflow | null>();
  const [itemsList, setItemsList] = useState<Item[]>(
    itemList.length === 0
      ? [{ itemName: "", quantity: 0, unitCost: 0 }]
      : itemList
  );

  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const showToast = useShowToast();
  const { request, workflow } = useSelector(
    (state: RootState) => state.requestForm
  );
  const { workflows } = useSelector((state: RootState) => state.localCache);
  const { workingTransactionAttachments } = useSelector(
    (state: RootState) => state.requestForm.transactions
  );
  const [hasPurchaseItem, setHasPurchaseItem] = useState(false);

  const defaultPurchaseType = useMemo(() => {
    return workflow ? workflow.defaultExpenseType : PurchaseType.supplies;
  }, [workflow]);

  const [purchaseType, setPurchaseType] = useState(
    workingPurchase?.purchaseType || defaultPurchaseType
  );
  const purchaseInitValues: CreateApiPurchaseTransaction = useMemo(
    () => ({
      type: ApiTransactionType.purchase,
      purchaseType: scannedPurchase?.purchaseType || defaultPurchaseType,
      checkNumber: scannedPurchase?.checkNumber || null,
      paymentDate: scannedPurchase?.paymentDate || "",
      vendor: manufacturer || null,
      description: scannedPurchase?.description || "",
      invoiceNumber: scannedPurchase?.invoiceNumber || null,
      quantity: scannedPurchase?.quantity || 1,
      unitCost: scannedPurchase?.unitCost || 0,
      additionalFees: 0,
      datePerformed: scannedPurchase?.datePerformed || "",
      budget: request ? request.budget : null,
    }),
    [request, defaultPurchaseType, manufacturer, scannedPurchase]
  );

  useEffect(() => {
    if (workingPurchase !== null) {
      setPurchase(workingPurchase);
    } else {
      setPurchase(purchaseInitValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingPurchase]);

  useEffect(() => {
    const hasValidItem = itemsList.some(
      (items) =>
        items.itemName.trim() !== "" && items.quantity > 0 && items.unitCost > 0
    );
    setHasPurchaseItem(hasValidItem);
  }, [itemsList, setHasPurchaseItem]);

  useEffect(() => {
    if (workingPurchase !== null && transactionId && request) {
      thunkDispatch(
        loadTransactionAttachments({
          apiClient,
          accountId: currentAccount.id,
          requestIdOrKey: request?.id,
          transactionId: transactionId,
        })
      );
    }
    return () => {
      dispatch(cleanTransactionAttachements());
    };
  }, [
    thunkDispatch,
    dispatch,
    workingPurchase,
    apiClient,
    currentAccount,
    request,
    transactionId,
  ]);

  useEffect(() => {
    if (!workingPurchase) {
      setWorkflowForm(workflow);
      return;
    }
    const key = workingPurchase.requestKey?.split("-");
    if (key && key.length) {
      const slug = key[0];
      const workflowTransaction = workflows.find(
        (work) => work.requestSlug === slug
      );
      setWorkflowForm(workflow ? workflow : workflowTransaction);
      return;
    }
    setWorkflowForm(workflow ? workflow : undefined);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWorkflowForm]);

  const onSubmitForm = async (
    values: CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction,
    actions: FormikHelpers<
      CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction
    >
  ) => {
    setIsSaving(true);
    const purchaseValues = values as CreateApiPurchaseTransaction;
    if (!request) {
      return;
    }
    if (!hasPurchaseItem) {
      showToast("error", "Please add at least one purchase transaction.");
      setIsSaving(false);
      return;
    }

    const promises = await itemsList.map(async (item) => {
      if (
        item.itemName.trim() === "" ||
        item.quantity <= 0 ||
        item.unitCost <= 0
      ) {
        return;
      }
      const response = await thunkDispatch(
        createTransaction({
          apiClient,
          account: currentAccount,
          request,
          transaction: {
            checkNumber: purchaseValues.checkNumber,
            paymentDate: purchaseValues.paymentDate,
            type: ApiTransactionType.purchase,
            purchaseType: purchaseValues.purchaseType,
            vendor: purchaseValues.vendor,
            description: item.itemName ?? "N/A",
            quantity: item.quantity,
            unitCost: item.unitCost,
            additionalFees: parseFloat(
              purchaseValues.additionalFees.toString()
            ),
            datePerformed: purchaseValues.datePerformed,
            invoiceNumber: purchaseValues.invoiceNumber,
            budget: purchaseValues.budget,
          },
        })
      ).then(unwrapResult);

      const createdPurchase = response as ApiPurchaseTransaction;
      const newAttachments = workingTransactionAttachments.filter(
        (x) => !!x.uploadId
      );

      dispatch(
        setTotalPurchaseTransactionChange(item.quantity * item.unitCost)
      );

      const attachmentPromises = newAttachments.map(async (attachment) =>
        thunkDispatch(
          createTransactionAttachment({
            apiClient,
            accountId: currentAccount.id,
            requestIdOrKey: request.id,
            transactionId: createdPurchase.id,
            attachment: {
              // newAttachments is filtered already, typescript doesnt follow
              uploadId: attachment.uploadId!,
              name: attachment.name,
            },
          })
        ).then(unwrapResult)
      );

      await Promise.all(attachmentPromises);
    });

    Promise.all(promises)
      .then(() => {
        handleCloseModal();
        showToast("success", "All transactions created successfully.");
        actions.setSubmitting(false);
        setIsSaving(false);
      })
      .catch((error) => {
        showToast(error, "Error creating transactions");
        actions.setSubmitting(false);
        setIsSaving(false);
      });
  };

  const savePurchaseAttachment = useCallback(
    (files: Attachment[]) => {
      if (request) {
        files.forEach(async (file) => {
          if (!file.file) return;

          const loadResponse = await apiClient
            .createUpload(currentAccount.id)
            .then(async (uploadedFile) => {
              const newAttachment: Attachment = {
                ...file,
                isUploading: true,
                uploadId: uploadedFile.id,
                progress: 0,
              };

              dispatch(
                addAttachment({
                  ...newAttachment,
                  attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                })
              );
              return { newAttachment, uploadedFile };
            })
            .then(async ({ newAttachment, uploadedFile }) => {
              return axios
                .put(uploadedFile.url, newAttachment.file, {
                  headers: { "Content-type": newAttachment.file?.type },
                  onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
                    dispatch(
                      updateAttachment({
                        ...newAttachment,
                        progress: Math.round(
                          (progressEvent.loaded * 100) /
                            (progressEvent.total || 1)
                        ),
                        attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                      })
                    );
                  },
                })
                .then(() => ({ newAttachment, uploadedFile }));
            })
            .then(({ newAttachment, uploadedFile }) => {
              const updated: Attachment = {
                ...newAttachment,
                isUploading: false,
                progress: undefined,
              };

              dispatch(
                updateAttachment({
                  ...updated,
                  attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                })
              );
              return Promise.resolve({
                attachment: newAttachment,
                uploadedFile,
              });
            })
            .catch((error) => {
              showToast("error", "Could not load files");
            });

          if (
            transactionId &&
            loadResponse &&
            loadResponse.attachment.uploadId
          ) {
            thunkDispatch(
              createTransactionAttachment({
                apiClient,
                accountId: currentAccount.id,
                requestIdOrKey: request.id,
                transactionId: transactionId,
                attachment: {
                  uploadId: loadResponse.attachment.uploadId,
                  name: loadResponse.attachment.name,
                },
              })
            )
              .then(unwrapResult)
              .catch(() => {
                showToast("error", "Couldn't save attachment");
              });
          }
        });
      }
    },
    [
      apiClient,
      currentAccount,
      transactionId,
      request,
      thunkDispatch,
      dispatch,
      showToast,
    ]
  );

  const handleNewAttachments = useCallback(
    (files: Attachment[]) => {
      savePurchaseAttachment(files);
    },
    [savePurchaseAttachment]
  );

  const handleRemoveAttachment = useCallback(
    async (attachment: Attachment) => {
      if (request && attachment.uploadId) {
        if (transactionId) {
          await thunkDispatch(
            deleteTransactionAttachment({
              apiClient,
              accountId: currentAccount.id,
              requestIdOrKey: request.id,
              transactionId: transactionId,
              id: attachment.uploadId,
            })
          )
            .then(unwrapResult)
            .catch((error) => {
              showToast("error", "Error Deleting Purchase Attachment");
            });
        }

        dispatch(
          removeAttachmentReducer({
            ...attachment,
            attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
          })
        );
      }
    },
    [
      apiClient,
      currentAccount,
      transactionId,
      request,
      thunkDispatch,
      dispatch,
      showToast,
    ]
  );

  useEffect(() => {
    if (thumbnailPicture) handleNewAttachments([thumbnailPicture]);
  }, [thumbnailPicture, handleNewAttachments]);

  return (
    <Box>
      {purchase && (
        <Formik
          onSubmit={onSubmitForm}
          initialValues={purchase}
          validationSchema={PurchaseFormSchema(purchaseType)}
        >
          {(props) => {
            return (
              <Form>
                <Grid>
                  <GridItem>
                    <RadioButtonsControl
                      label="Type of purchase"
                      value={purchaseType}
                      name="purchaseType"
                      radioOptions={radioButtonOptions}
                      setLocalState={(value) =>
                        setPurchaseType(value as PurchaseType)
                      }
                      cleanValues={[["vendor", null]]}
                    />
                  </GridItem>
                  <GridItem pt={4}>
                    {manufacturer ? (
                      <VendorAutocompleteControl
                        name="vendor"
                        label="Vendor"
                        addVendorLink
                        value={
                          purchase.vendor
                            ? (purchase.vendor as ApiVendor)
                            : null
                        }
                        isServiceProvider={
                          props.values.purchaseType === PurchaseType.vendor
                        }
                        isSupplier={
                          props.values.purchaseType === PurchaseType.supplies
                        }
                      />
                    ) : (
                      <HStack>
                        <VendorAutocompleteControl
                          name="vendor"
                          label="Vendor"
                          addVendorLink
                          aiSearch={scannedPurchase?.vendor?.name}
                          value={
                            purchase.vendor
                              ? (purchase.vendor as ApiVendor)
                              : null
                          }
                          isServiceProvider={
                            props.values.purchaseType === PurchaseType.vendor
                          }
                          isSupplier={
                            props.values.purchaseType === PurchaseType.supplies
                          }
                        />
                        <Box mt={6}>
                          <InfoIcon color="blue.500" />
                        </Box>
                      </HStack>
                    )}
                  </GridItem>
                  <GridItem pt={4}>
                    {scannedPurchase?.checkNumber ? (
                      <NumberInputControl
                        label="Check Number"
                        name="checkNumber"
                        value={purchase?.checkNumber || 0}
                      />
                    ) : (
                      <HStack>
                        <NumberInputControl
                          label="Check Number"
                          name="checkNumber"
                          value={purchase?.checkNumber || 0}
                        />
                        <Box mt={6}>
                          <InfoIcon color="blue.500" />
                        </Box>
                      </HStack>
                    )}
                  </GridItem>

                  <ItemsSection
                    value={itemsList}
                    setValues={setItemsList}
                    setHasPurchaseItem={setHasPurchaseItem}
                  />

                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4} pt={2}>
                    <GridItem pt={0} colSpan={2}>
                      {scannedPurchase?.additionalFees ? (
                        <NumberInputControl
                          label="Any additional Cost. Ex. shipping "
                          name="additionalFees"
                          value={purchase.additionalFees || 0}
                          prefix="$"
                          precision={2}
                        />
                      ) : (
                        <HStack>
                          <NumberInputControl
                            label="Any additional Cost. Ex. shipping "
                            name="additionalFees"
                            value={purchase.additionalFees || 0}
                            prefix="$"
                            precision={2}
                          />
                          <Box mt={6}>
                            <InfoIcon color="blue.500" />
                          </Box>
                        </HStack>
                      )}
                    </GridItem>
                  </Grid>

                  <Divider mt={4} mb={4} />
                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4}>
                    <GridItem
                      colSpan={[2, null, null, 1]}
                      pt={[4, null, null, 4]}
                      maxW={180}
                    >
                      {scannedPurchase?.datePerformed ? (
                        <DatePickerControl
                          value={purchase.datePerformed || null}
                          name="datePerformed"
                          label="Date of purchase"
                        />
                      ) : (
                        <HStack>
                          <DatePickerControl
                            value={purchase.datePerformed || null}
                            name="datePerformed"
                            label="Date of purchase"
                          />
                          <Box mt={6}>
                            <InfoIcon color="blue.500" />
                          </Box>
                        </HStack>
                      )}
                    </GridItem>
                    <GridItem
                      colSpan={[2, null, null, 1]}
                      pt={[0, null, null, 4]}
                      maxW={180}
                    >
                      {scannedPurchase?.paymentDate ? (
                        <DatePickerControl
                          value={purchase?.paymentDate || null}
                          name="paymentDate"
                          label="Payment Date"
                        />
                      ) : (
                        <HStack>
                          <DatePickerControl
                            value={purchase?.paymentDate || null}
                            name="paymentDate"
                            label="Payment Date"
                          />
                          <Box mt={6}>
                            <InfoIcon color="blue.500" />
                          </Box>
                        </HStack>
                      )}
                    </GridItem>
                  </Grid>
                  <GridItem pt={4}>
                    {scannedPurchase?.invoiceNumber ? (
                      <TextInputControl
                        label="PO / Invoice Number"
                        name="invoiceNumber"
                        value={purchase?.invoiceNumber || ""}
                        placeholder="ex: 345 67-AB"
                      />
                    ) : (
                      <HStack>
                        <TextInputControl
                          label="PO / Invoice Number"
                          name="invoiceNumber"
                          value={purchase?.invoiceNumber || ""}
                          placeholder="ex: 345 67-AB"
                        />
                        <Box mt={6}>
                          <InfoIcon color="blue.500" />
                        </Box>
                      </HStack>
                    )}
                  </GridItem>
                  {workflowForm && workflowForm.allowBudgetsOnTransactions && (
                    <GridItem pt={4}>
                      <BudgetAutocompleteControl
                        label="Credit from budget"
                        name="budget"
                        value={(purchase?.budget as ApiBudgetSummary) || null}
                        placeholder="Search existing budgets"
                        helperText="This expense will pull from this budget."
                      />
                    </GridItem>
                  )}
                  <GridItem pt={4}>
                    <Attachments
                      attachments={workingTransactionAttachments}
                      onDeleteAttachment={handleRemoveAttachment}
                      onNewAttachments={handleNewAttachments}
                      gridColumns={2}
                    />
                  </GridItem>
                  <Divider my={4} />
                  <GridItem textAlign="center">
                    <Button
                      type="submit"
                      size="sm"
                      height="36px"
                      width="200px"
                      variant="solid"
                      colorScheme="blue"
                      isLoading={isSaving}
                    >
                      Add Purchase
                    </Button>
                  </GridItem>
                  <FocusError />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};
