import { Flex, useColorModeValue } from "@chakra-ui/react";
import { ApiProjectStatus } from "@operations-hero/lib-api-client";
import {
  GroupBase,
  OptionProps,
  Select,
  SingleValueProps,
} from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { StatusBadge } from "../badges/StatusBadge";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";

export interface ProjectStatusSelectProps {
  status: ApiProjectStatus | undefined;
  onChange?: (status: ApiProjectStatus) => void;
  statuses?: ApiProjectStatus[];
}

type ValueLabel = {
  value: ApiProjectStatus;
  label: string;
};

type ValueAndLabel = {
  value: ApiProjectStatus;
  label: ApiProjectStatus;
};

const CustomOptionComponent = (
  props: OptionProps<ValueAndLabel, false, GroupBase<ValueAndLabel>>
) => {
  const { data, innerRef, innerProps } = props;
  const hoverBgColor = useColorModeValue("gray.100", "whiteAlpha.100");
  return (
    <Flex
      {...innerProps}
      p={2}
      ref={innerRef}
      _hover={{ backgroundColor: hoverBgColor }}
      cursor="pointer"
    >
      <StatusBadge status={data.value} />
    </Flex>
  );
};

const CustomSingleValueComponent = (
  props: SingleValueProps<ValueAndLabel, false, GroupBase<ValueAndLabel>>
) => {
  const { data, innerProps } = props;
  return (
    <Flex {...innerProps}>
      <StatusBadge status={data.value} />
    </Flex>
  );
};

export const ProjectStatusSelect = ({
  status,
  statuses,
  onChange,
}: ProjectStatusSelectProps) => {
  const statusOptionsFromWorkflow = useCallback(() => {
    const { pending, approved, started, completed } = ApiProjectStatus;
    let options: ApiProjectStatus[] = statuses
      ? statuses
      : [pending, approved, started, completed];

    return options;
  }, [statuses]);

  const components = useMemo(
    () => ({
      SingleValue: CustomSingleValueComponent,
      Option: CustomOptionComponent,
      ...getCustomSelectComponents(),
    }),
    []
  );

  const options = useMemo(() => {
    const options = statusOptionsFromWorkflow();

    return options.map((x) => ({
      value: x,
      label: x,
    }));
  }, [statusOptionsFromWorkflow]);

  const handleChange = useCallback(
    (item: ValueLabel | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange]
  );

  return (
    <Select
      options={options}
      components={components}
      onChange={handleChange}
      chakraStyles={commonStyles}
      value={options.find((x) => x.value === status) || null}
    />
  );
};
