export const getFormattedInputHour = (value: number) => {
  const h = Math.floor(value);
  const m = Math.round((value - h) * 60);
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
};

export const getUnformattedInputHour = (timeString: string) => {
  const [h, m] = timeString.split(":").map(Number);
  const result = h + m / 60;
  return Number(result.toFixed(2));
};
