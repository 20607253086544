import { Box, Icon, Text } from "@chakra-ui/react";
import { ApiLocationType } from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";

import { LocationTypeIconMap } from "../badges/LocationBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  SingleValueSelect,
} from "./select-overrides-types";

export interface LocationTypeSelectProps {
  value: ApiLocationType;
  onChange?: (status: ApiLocationType) => void;
}

type ValueLabel = {
  value: ApiLocationType;
  label: ApiLocationType;
};

const CustomOption = ({ value }: { value: ValueLabel }) => {
  return (
    <Box p={2}>
      <Text>
        <Icon
          as={LocationTypeIconMap[value.value as ApiLocationType]}
          mr={2}
          mb={1}
        />
        {value.value.toUpperCase()}
      </Text>
    </Box>
  );
};

const CustomSingleValueComponent = (props: SingleValueSelect<ValueLabel>) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps} p={2}>
      <Text>
        <Icon
          as={LocationTypeIconMap[data.value as ApiLocationType]}
          mr={2}
          mb={1}
        />
        {data.value.toUpperCase()}
      </Text>
    </Box>
  );
};

export const LocationTypeSelect = ({
  value,
  onChange,
}: LocationTypeSelectProps) => {
  const CustomOptionComponent = createOptionComponent(CustomOption);

  const options: ValueLabel[] = useMemo(
    () =>
      Object.values(ApiLocationType).map((x) => ({
        value: x,
        label: x,
      })),
    [],
  );

  const handleChange = useCallback(
    (item: ValueLabel | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange],
  );

  const components = useMemo(
    () => ({
      ...getCustomSelectComponents(),
      SingleValue: (props: SingleValueSelect<ValueLabel>) =>
        CustomSingleValueComponent(props),
      Option: (props: CustomOptionsProps<ValueLabel>) =>
        CustomOptionComponent(props),
    }),
    [CustomOptionComponent],
  );

  return (
    <Select
      defaultValue={options.find((x) => x.value === value)}
      options={options}
      onChange={handleChange}
      chakraStyles={commonStyles}
      components={components}
    />
  );
};
