import { Box } from "@chakra-ui/react";
import { FC, Suspense } from "react";
import { FallbackSpinner } from "../../../components/code-splitting/FallbackSpinner";

interface AccountRouteWrapperProps {
  Element: React.FC | React.LazyExoticComponent<() => JSX.Element>;
}

export const AccountWrapper: FC<AccountRouteWrapperProps> = ({ Element }) => {
  return (
    <Box flex="1">
      <Suspense fallback={<FallbackSpinner />}>
        <Element />
      </Suspense>
    </Box>
  );
};
