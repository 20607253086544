import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { RootState } from "../../../store";
import { AccountWrapper } from "./AccountRoutesWrapper";

const RateSheet = lazy(() => import("../rate-sheet/RateSheet"));
const NewVenueRateSheet = lazy(() => import("../rate-sheet/add-rate/NewRate"));

const InvoicesListPage = lazy(() => import("../invoice-list/InvoicesListPage"));
const InvoiceDetailPage = lazy(
  () => import("../invoice-list/InvoiceDetailPage"),
);

const PaymentsListPage = lazy(
  () => import("../payments-list/PaymentsListPage"),
);

export const useInvoiceRoutes = (hasEvents: boolean) => {
  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice,
  );

  if (!hasEvents || !enableInvoicesForEvents) return { routes: [] };

  const routes: React.ReactNode[] = [
    <Route
      path="rate-sheet"
      key="routes::invoices/rate-sheet"
      element={<AccountWrapper Element={RateSheet} />}
    />,
    <Route
      path="rate-sheet/:type/:id?"
      key="routes::invoices/rate-sheet/id"
      element={<AccountWrapper Element={NewVenueRateSheet} />}
    />,
    <Route
      path="payments-list"
      key="routes::invoices/payment-list"
      element={<AccountWrapper Element={PaymentsListPage} />}
    />,
    <Route
      path="invoice-list"
      key="routes::invoices/invoice-list"
      element={<AccountWrapper Element={InvoicesListPage} />}
    />,
    <Route
      path="invoice-list/:invoiceId"
      key="routes::invoices/invoice-list/invoiceId"
      element={<AccountWrapper Element={InvoiceDetailPage} />}
    />,
  ];

  return { routes };
};
