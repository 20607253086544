import {
  ApiAccount,
  ApiAccountSettings,
  ApiBudget,
  ApiClient,
  ApiTransaction,
  ApiTransactionType,
  ApiUserSummary,
  ApiVendor,
  ApiWorkflow,
  FindTransactionsOptions,
  LaborType,
  PurchaseType,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import getRangeRelativeDate from "../utils/getRangeRelativeDate";

export interface InitTransactionListThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
}

const TRANSACTION_LIST_FILTERS = "transaction-list-filters";

export interface PersonFilterReferenceValue {
  type: PersonFilterValueType;
  user: ApiUserSummary | string;
}
export interface InitFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  selectedValues: string[];
}
export interface LoadFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
}

export const initTransactionList = createAsyncThunk(
  "transaction-list/init",
  async ({ apiClient, account }: InitTransactionListThunkParams, thunkAPI) => {
    const filters = { ...filtersInitialState };
    const { workflows } = (thunkAPI.getState() as RootState).localCache;
    const settingsTransactionFilters = await apiClient.getCurrentUserSettings(
      account.id,
      [TRANSACTION_LIST_FILTERS]
    );
    const userSavedFilters =
      settingsTransactionFilters[TRANSACTION_LIST_FILTERS] || [];

    return { userSavedFilters, filters, workflows };
  }
);

export const initBudgetFilter = createAsyncThunk(
  "transaction-list/init-budget-filter",
  async (
    { apiClient, account, selectedValues }: InitFilterThunkParams,
    thunkAPI
  ) => {
    const options = { ids: selectedValues };
    const { data } = await apiClient.findBudgets(account.id, options);
    return data;
  }
);
export const loadBudgetFilter = createAsyncThunk(
  "transaction-list/load-budget-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams, thunkAPI) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
    };
    const { data } = await apiClient.findBudgets(account.id, options);
    return data;
  }
);

export const initVendorFilter = createAsyncThunk(
  "transaction-list/init-vendor-filter",
  async (
    { apiClient, account, selectedValues }: InitFilterThunkParams,
    thunkAPI
  ) => {
    const options = { ids: selectedValues };
    const { data } = await apiClient.findVendors(account.id, options);
    return data;
  }
);
export const loadVendorFilter = createAsyncThunk(
  "transaction-list/load-vendor-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams, thunkAPI) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
    };
    const { data } = await apiClient.findVendors(account.id, options);
    return data;
  }
);

export interface LoadPeopleFilterOptionsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  type: PersonFilterValueType;
  search?: string;
}
export type TransactionActionType = "edit" & "delete";
export const loadPeopleFilterOptions = createAsyncThunk(
  "transaction-list/people-filter-options",
  async (
    { apiClient, account, search, type }: LoadPeopleFilterOptionsThunkParams,
    thunkAPI
  ) => {
    const { data } = await apiClient.findAccountUsers(account.id, {
      pageSize: 20,
      search,
    });

    return data.map<PersonFilterValue>((user) => ({
      type,
      user,
      key: `${type}::${user.id}`,
    }));
  }
);

export interface LoadTransactionsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  page?: number;
}

export const loadTransactions = createAsyncThunk(
  "transaction-list/load-list",
  async ({ apiClient, account }: LoadTransactionsThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const { sort, filters } = state.transactionList;
    const { workflows } = (thunkAPI.getState() as RootState).localCache;
    const options = getTransactionFilter(sort, filters, workflows);
    const transactions = await apiClient.findAccountTransactions(
      account.id,
      options
    );

    return { transactions };
  }
);

export function getTransactionFilter(
  sort: {
    direction: "asc" | "desc";
    field: string;
  },
  filters: TransactionListFilterState,
  workflows: ApiWorkflow[]
) {
  const options: FindTransactionsOptions = {
    pageSize: filters.pageSize,
    sort: sort.field,
    direction: sort.direction,
    page: filters.currentPage,
    search: filters?.search,
    purchaseType: filters?.purchaseType as PurchaseType[],
    laborType: filters?.laborType as LaborType[],
    type: filters?.type as ApiTransactionType[],
    workflow: filters?.workflows.map(
      (x) => workflows.find((y) => y.id === x)!.requestSlug
    ),
    budget: filters?.budgets,
    vendor: filters?.vendor,
    createdBy: filters?.persons
      .filter((x) => x.type === "createdBy")
      .map((x) => (typeof x.user === "string" ? x.user : x.user.id)),
    [filters.date ? filters.date.field : ""]: filters.date
      ? filters.date.type === "relative"
        ? getRangeRelativeDate(filters.date.value)
        : filters.date.value
      : null,
  };

  if (filters.date && filters.date.field) {
    // @ts-ignore
    options[filters.date.field] =
      filters.date.type === "relative"
        ? getRangeRelativeDate(filters.date.value)
        : filters.date.value;
  }

  Object.entries(options).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length === 0) {
      // @ts-ignore
      delete options[key];
      return;
    }

    if (value == null) {
      // @ts-ignore
      delete options[key];
    }
  });

  return options;
}

export interface UpdatedSavedFilterProps {
  apiClient: ApiClient;
  accountId: string;
  savedFilters: ApiAccountSettings[];
}
export const updatedSavedFilters = createAsyncThunk(
  "transaction-list/update-saved-filters",
  async ({ apiClient, accountId, savedFilters }: UpdatedSavedFilterProps) => {
    return apiClient.updateCurrentUserSettings(accountId, {
      "transaction-list-filters": savedFilters,
    });
  }
);

export const DateFilterValues: Record<
  DateFields | RelativeDateOptions,
  string
> = {
  created: "Created",
  updated: "Updated",
  datePerformed: "Date Performed",

  last7d: "Last 7 days",
  last30d: "Last 30 days",
  thisWeek: "This week (Sun - Sat)",
  lastWeek: "Last Week(Sun - Sat)",
  thisMonth: "This Month (1st- EOM)",
  lastMonth: "Last Month (1st- EOM)",
};

export type DateFields = "created" | "updated" | "datePerformed";

export type RelativeDateOptions =
  | "last7d"
  | "last30d"
  | "thisWeek"
  | "lastWeek"
  | "thisMonth"
  | "lastMonth";

export type AbsoluteDateFilter = {
  field: DateFields;
  type: "absolute";
  value: (string | null)[];
};

export type RelativeDateFilter = {
  field: DateFields;
  type: "relative";
  value: RelativeDateOptions;
};

export type DateFilter = {
  field: DateFields;
  type: "relative" | "absolute";
  value: (string | null)[];
};

export type PersonFilterValueType = "createdBy";

export interface PersonFilterValue {
  type: PersonFilterValueType;
  user: ApiUserSummary;
  key: string;
}

export interface TransactionListFilterState {
  workflows: string[];
  budgets: string[];
  locations: string[];
  persons: PersonFilterReferenceValue[];
  purchaseType: string[];
  laborType: string[];
  created: string[];
  updated: string[];
  date: RelativeDateFilter | AbsoluteDateFilter | undefined;
  dateRelative: RelativeDateFilter | undefined;
  currentPage: number;
  pageSize: number;
  search: string;
  type: string[];
  vendor: string[];
}

export const filtersInitialState: TransactionListFilterState = {
  workflows: [],
  budgets: [],
  locations: [],
  purchaseType: [],
  laborType: [],
  created: [],
  updated: [],
  persons: [],
  date: undefined,
  dateRelative: undefined,
  currentPage: 1,
  pageSize: 50,
  search: "",
  type: [],
  vendor: [],
};

export interface TransactionListSliceState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  transactions: ApiTransaction[];
  totalTransactions: number;
  filters: TransactionListFilterState;
  searchEnabled: boolean;
  initActive: boolean;
  sort: {
    direction: "asc" | "desc";
    field: string;
  };
  dateField: DateFields | undefined;
  isCustomDate: boolean;
  queryStringFilter: string;
  availablePersons: PersonFilterValue[];
  availablePersonsLoading: boolean;
  budgets: ApiBudget[];
  vendors: ApiVendor[];
  showLaborCosts: boolean;
  initCompleted: boolean;
}

const transactionListSliceInitialState: TransactionListSliceState = {
  loading: "idle",
  transactions: [],
  totalTransactions: 0,
  sort: {
    direction: "desc",
    field: "created",
  },
  filters: { ...filtersInitialState },
  searchEnabled: false,
  initActive: false,
  queryStringFilter: "",
  initCompleted: false,
  dateField: undefined,
  isCustomDate: false,
  availablePersons: [],
  availablePersonsLoading: false,
  budgets: [],
  vendors: [],
  showLaborCosts: false,
};

export const transactionListSlice = createSlice({
  name: "transaction-list",
  initialState: transactionListSliceInitialState,
  reducers: {
    unloadTransactionList: (state) => {
      state.loading = "idle";
      state.transactions = [];
      state.totalTransactions = 0;
      state.filters = { ...filtersInitialState };
      state.queryStringFilter = "";
    },
    cleanAllFilters: (state) => {
      state.filters = { ...filtersInitialState };
      state.sort.field = "created";
      state.sort.direction = "desc";
    },
    updateTransactionFilters: (
      state,
      action: PayloadAction<Partial<TransactionListFilterState>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
        ...{ currentPage: 1 },
      };

      const queryStringFilter: TransactionListFilterState = {
        ...state.filters,
        persons: state.filters.persons.map((x) => ({
          type: x.type,
          user: typeof x.user === "string" ? x.user : x.user.id,
        })),
      };
      state.queryStringFilter = btoa(JSON.stringify(queryStringFilter));
    },
    setInitCompleted: (state, action: PayloadAction<boolean>) => {
      state.initCompleted = action.payload;
    },
    setCurrentPage: (state, payload: PayloadAction<number>) => {
      state.filters.currentPage = payload.payload;
    },
    setSortDirection: (state, payload: PayloadAction<"asc" | "desc">) => {
      state.sort.direction = payload.payload;
      state.filters.currentPage = 1;
    },
    setSortField: (state, payload: PayloadAction<string>) => {
      state.sort.field = payload.payload;
      state.filters.currentPage = 1;
    },
    setDateField: (state, action: PayloadAction<DateFields>) => {
      state.dateField = action.payload;
    },
    setRelativeDate: (state, action: PayloadAction<RelativeDateFilter>) => {
      state.filters.date = action.payload;
    },
    setAbsoluteDate: (state, action: PayloadAction<AbsoluteDateFilter>) => {
      state.filters.date = action.payload;
    },
    setIsCustomDate: (state, action: PayloadAction<boolean>) => {
      state.isCustomDate = action.payload;
    },
    setEnableSearch: (state, action: PayloadAction<boolean>) => {
      state.searchEnabled = action.payload;
    },
    setInitActive: (state, action: PayloadAction<boolean>) => {
      state.initActive = action.payload;
    },
    setShowLaborCosts: (state) => {
      state.showLaborCosts = !state.showLaborCosts;
    },
    deleteTransactions: (state, action: PayloadAction<string>) => {
      const index = state.transactions.findIndex(
        (x) => x.id === action.payload
      );
      if (index !== -1) {
        state.transactions.splice(index, 1);
      }
    },
    updateTransactions: (state, action: PayloadAction<ApiTransaction>) => {
      const index = state.transactions.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index !== -1) {
        state.transactions[index] = action.payload;
      }
    },
    addBudget: (state, action: PayloadAction<ApiBudget>) => {
      state.budgets.unshift(action.payload);
    },
    deleteBudget: (state, action: PayloadAction<string>) => {
      const budgetIndex = state.budgets.findIndex(
        (x) => x.id === action.payload
      );
      state.budgets.splice(budgetIndex, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initTransactionList.fulfilled, (state, action) => {
      state.queryStringFilter = "";
      state.filters = {
        ...action.payload.filters,
      };
      state.dateField = undefined;
    });
    builder.addCase(initBudgetFilter.fulfilled, (state, action) => {
      state.budgets = action.payload;
    });
    builder.addCase(loadBudgetFilter.fulfilled, (state, action) => {
      const selected = state.filters.budgets;
      const itemsToAdd = selected;
      let uniqueMap: Record<string, ApiBudget> = {};
      uniqueMap = itemsToAdd.reduce<Record<string, ApiBudget>>((result, id) => {
        const hydrated = state.budgets.find((budget) => budget.id === id);
        if (hydrated) result[id] = hydrated as ApiBudget;
        return result;
      }, {});

      uniqueMap = action.payload.reduce<Record<string, ApiBudget>>(
        (result, item) => {
          result[item.id] = item;
          return result;
        },
        uniqueMap
      );

      state.budgets = Object.values(uniqueMap);
    });
    builder.addCase(initVendorFilter.fulfilled, (state, action) => {
      state.vendors = action.payload;
    });
    builder.addCase(loadVendorFilter.fulfilled, (state, action) => {
      const selected = state.filters.vendor;
      const itemsToAdd = selected;
      let uniqueMap: Record<string, ApiVendor> = {};
      uniqueMap = itemsToAdd.reduce<Record<string, ApiVendor>>((result, id) => {
        const hydrated = state.vendors.find((vendor) => vendor.id === id);
        if (hydrated) result[id] = hydrated as ApiVendor;
        return result;
      }, {});

      uniqueMap = action.payload.reduce<Record<string, ApiVendor>>(
        (result, item) => {
          result[item.id] = item;
          return result;
        },
        uniqueMap
      );

      state.vendors = Object.values(uniqueMap);
    });
    builder.addCase(loadTransactions.fulfilled, (state, action) => {
      const { data, total, options } = action.payload.transactions;
      state.loading = "succeeded";
      state.filters.currentPage = options.page || 1;
      state.transactions = data;
      state.totalTransactions = total;
    });
    builder.addCase(loadTransactions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadTransactions.rejected, (state) => {
      state.loading = "failed";
      state.transactions = [];
    });

    builder.addCase(loadPeopleFilterOptions.fulfilled, (state, action) => {
      state.availablePersons = action.payload;
    });
  },
});

export const {
  unloadTransactionList,
  updateTransactionFilters,
  setCurrentPage,
  setInitCompleted,
  setSortDirection,
  setSortField,
  setDateField,
  setRelativeDate,
  setAbsoluteDate,
  setIsCustomDate,
  deleteTransactions,
  updateTransactions,
  setEnableSearch,
  setInitActive,
  addBudget,
  deleteBudget,
  setShowLaborCosts,
  cleanAllFilters,
} = transactionListSlice.actions;

export default transactionListSlice.reducer;
