import { Box, Button, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useRef } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { GameOfLife } from "./GameOfLife";
export const ErrorPage = () => {
  const { resetBoundary } = useErrorBoundary();

  const canvasRef = useRef<HTMLCanvasElement>();

  const goBack = useCallback(() => {
    resetBoundary();
  }, [resetBoundary]);

  useEffect(() => {
    canvasRef.current = document.getElementById("canvas") as HTMLCanvasElement;
    const { current: canvas } = canvasRef;
    try {
      if (canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        const game = new GameOfLife(canvas);
        game.random();
        game.start();
      }
    } catch (e) {
      console.error(`GoF error ${e}`);
    }
  }, []);

  return (
    <Box
      style={{
        position: "relative",
        height: "100vh",
      }}
    >
      <Box
        style={{
          position: "absolute",
          zIndex: "3",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        }}
        display="flex"
        flexDirection="column"
        gap="6"
        px="10"
        py="10"
        maxWidth={["full", "full", "full", "60%"]}
        mx={[5, 5, 5, "8%"]}
        mt="8%"
        rounded="md"
      >
        <Text color="blue.700" fontWeight="semibold" fontSize="x-large">
          {" "}
          Oops! looks like something has gone wrong. Please try again. {"\n"}If
          the problem continues contact support.{" "}
        </Text>
        <Button
          onClick={goBack}
          maxWidth="fit-content"
          colorScheme="blue"
          variant="solid"
        >
          Go back
        </Button>
      </Box>

      <canvas
        id="canvas"
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      />
    </Box>
  );
};
