import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiLaborTransaction,
  ApiTransaction,
  ApiTransactionType,
  LaborType,
} from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { Pager } from "../../../../components/pager/Pager";
import { RootState, useThunkDispatch } from "../../../../store";
import { setLaborsTransactionsCurrentPage } from "../../../../store/request-form/request-form.slice";
import { loadTransactions } from "../../../../store/request-form/thunks/loadTransactions.thunk";
import { getVisibleFields } from "../../../../utils/getVisibleFields";
import { RemoveTransactionModal } from "../RemoveTransactionModal";
import { LaborTransactionModal } from "./LaborTransactionModal";
import LaborTransactionTable from "./LaborTransactionsTable";
import { MobileLaborTransactionTable } from "./MobileLaborTransactionTable";

export const LaborsSection = () => {
  const { currentUser, apiClient, currentAccount, isProductAdmin } =
    useAuthentication();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const isDesktop = useBreakpointValue({
    md: true,
    base: false,
  });
  const textColor = useColorModeValue("gradarkslategrey", "white");

  const request = useSelector((state: RootState) => state.requestForm.request);
  const policy = useSelector((state: RootState) => state.requestForm.policy);
  const workflow = useSelector(
    (state: RootState) => state.requestForm.workflow
  );
  const { laborsData, laborsTotal, laborsCurrentPage } = useSelector(
    (state: RootState) => state.requestForm.transactions.labors
  );

  const visibleFields = useSelector(
    (state: RootState) => state.requestForm.visibleFields
  );

  const [showLaborForm, setShowLaborForm] = useState(false);
  const [workingTransaction, setWorkingTransaction] =
    useState<ApiLaborTransaction | null>();

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const { showLabor } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const canAdd = useMemo(() => {
    if (!policy) {
      return false;
    }
    return (
      isProductAdmin ||
      policy.admin ||
      policy.reviewer ||
      policy.technician ||
      policy.contractor
    );
  }, [policy, isProductAdmin]);

  const canEdit = useCallback(
    (transaction: ApiLaborTransaction) => {
      if (!policy) {
        return false;
      }
      return (
        isProductAdmin ||
        policy.admin ||
        transaction.laborer.id === currentUser.id ||
        transaction.createdBy.id === currentUser.id
      );
    },
    [policy, currentUser, isProductAdmin]
  );

  const canDelete = useCallback(
    (transaction: ApiLaborTransaction) => {
      if (!policy || !transaction) {
        return false;
      }
      return (
        isProductAdmin ||
        policy.admin ||
        transaction.laborer.id === currentUser.id ||
        transaction.createdBy.id === currentUser.id
      );
    },
    [policy, currentUser, isProductAdmin]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      if (!request) {
        return;
      }

      dispatch(setLaborsTransactionsCurrentPage(page));
      thunkDispatch(
        loadTransactions({
          apiClient,
          account: currentAccount,
          key: request.key,
          transactionType: ApiTransactionType.labor,
        })
      );
    },
    [dispatch, thunkDispatch, apiClient, currentAccount, request]
  );

  const handleAddLaborTransaction = useCallback(() => {
    if (!request) {
      return;
    }

    const now = new Date().toISOString();
    setWorkingTransaction({
      id: "",
      created: now,
      createdBy: currentUser,
      datePerformed: now,
      hours: 0.25,
      laborer: currentUser,
      type: ApiTransactionType.labor,
      updated: now,
      updatedBy: currentUser,
      laborType: LaborType.regular,
      attachmentCount: 0,
      budget: null,
      hourlyRate: 0,
      total: 0,
      requestId: request.id,
      requestKey: request.key,
    });
    setShowLaborForm(true);
  }, [currentUser, request]);

  const handleEditLaborTransaction = useCallback(
    (transaction: ApiLaborTransaction) => {
      if (!workflow || !showLabor || !canEdit(transaction)) {
        return;
      }
      setWorkingTransaction(transaction);
      setShowLaborForm(true);
    },
    [workflow, canEdit, showLabor]
  );

  const handleDeleteLaborTransaction = useCallback(
    (transaction: ApiLaborTransaction) => {
      if (!workflow || !showLabor || !canEdit(transaction)) {
        return;
      }
      setWorkingTransaction(transaction);
      deleteOnOpen();
    },
    [workflow, canEdit, deleteOnOpen, showLabor]
  );

  const handleLaborModalClose = useCallback((transaction?: ApiTransaction) => {
    setWorkingTransaction(null);
    setShowLaborForm(false);
  }, []);

  const handleOnDeleteClose = useCallback(() => {
    setWorkingTransaction(null);
    deleteOnClose();
  }, [deleteOnClose]);

  useEffect(() => {
    if (!request?.key) {
      return;
    }

    thunkDispatch(
      loadTransactions({
        apiClient,
        account: currentAccount,
        key: request.key,
        transactionType: "labor",
      })
    );
  }, [thunkDispatch, apiClient, currentAccount, request?.key]);

  return workflow && (laborsData.length > 0 || showLabor) ? (
    <>
      <Stack>
        <Grid
          templateColumns={["repeat(8, 1fr)"]}
          sx={{ mt: "4", mb: "6" }}
          verticalAlign="center"
        >
          <GridItem colSpan={5}>
            <Heading size="md">Labor</Heading>
          </GridItem>
          <GridItem colSpan={3} textAlign="right">
            {showLabor && canAdd && (
              <Button
                size="sm"
                colorScheme="blue"
                variant="outline"
                onClick={handleAddLaborTransaction}
              >
                <Icon mr={2} as={IoAddOutline} />
                Add Hours
              </Button>
            )}
          </GridItem>
        </Grid>
        {laborsData.length === 0 && (
          <Text pb="6" color={textColor} fontSize="md">
            Add time spent completing work.
          </Text>
        )}
        {isDesktop && laborsData.length > 0 ? (
          <LaborTransactionTable
            canEdit={canEdit}
            canDelete={canDelete}
            onEdit={handleEditLaborTransaction}
            onDelete={handleDeleteLaborTransaction}
          />
        ) : (
          <MobileLaborTransactionTable
            canEdit={canEdit}
            canDelete={canDelete}
            onEdit={handleEditLaborTransaction}
            onDelete={handleDeleteLaborTransaction}
          />
        )}
        {laborsTotal > 20 && (
          <Box>
            <Pager
              currentPage={laborsCurrentPage || 1}
              total={laborsTotal}
              pageSize={20}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Stack>
      {workingTransaction && (
        <LaborTransactionModal
          isOpen={showLaborForm}
          transaction={workingTransaction}
          onClose={handleLaborModalClose}
        />
      )}
      {deleteIsOpen && workingTransaction ? (
        <RemoveTransactionModal
          isOpen={deleteIsOpen}
          onClose={handleOnDeleteClose}
          transaction={workingTransaction || null}
        />
      ) : null}
    </>
  ) : null;
};
