import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RequestSliceState } from "../request-form.slice";

export const DEFAULT_REQUEST_NOTIFICATION = {
  loading: "idle",
  page: 1,
  total: 0,
  pageSize: 10,
  data: [],
};

export interface LoadRequestNotificationParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  user?: string | string[];
}

export const loadRequestNotification = createAsyncThunk(
  "request/notification",
  async (params: LoadRequestNotificationParams, thunkAPI) => {
    const { apiClient, accountId, requestId, user } = params;

    const { pageSize, page } = (thunkAPI.getState() as RootState).requestForm
      .notifications;

    const response = await apiClient.findRequestNotificationHistory(
      accountId,
      requestId,
      {
        user: user || [],
        page,
        pageSize,
      }
    );

    return response;
  }
);

export const requestNotificationHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadRequestNotification.fulfilled, (state, action) => {
    const { data, options, total } = action.payload;
    state.notifications.loading = "succeeded";
    state.notifications.data = data;
    state.notifications.total = total;
    state.notifications.page = options.page || 1;
  });
  builder.addCase(loadRequestNotification.rejected, (state) => {
    state.notifications.loading = "failed";
  });
  builder.addCase(loadRequestNotification.pending, (state) => {
    state.notifications.loading = "pending";
  });
};
