import { Box, Button, Flex, Icon, Image } from "@chakra-ui/react";
import React, { FC } from "react";
import { MdAdd, MdRefresh, MdRemove } from "react-icons/md";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";

interface ZoomImageAttachmentProps {
  name: string;
  url: string | null;
}

export const ZoomableImageAttachment: FC<ZoomImageAttachmentProps> = ({
  name,
  url,
}) => {
  const Controls = React.memo(() => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <Flex>
        <Button
          onClick={() => zoomIn()}
          position="absolute"
          top="10px"
          left="10px"
          zIndex="1"
          border="none"
          p={2}
          _hover={{ bg: "rgba(0, 0, 0, 0.2)" }}
          size="sm"
          bgColor="gray.800"
          color="white"
        >
          <Icon as={MdAdd} />
        </Button>
        <Button
          onClick={() => zoomOut()}
          position="absolute"
          top="45px"
          left="10px"
          zIndex="1"
          border="none"
          p={2}
          _hover={{ bg: "rgba(0, 0, 0, 0.2)" }}
          size="sm"
          bgColor="gray.800"
          color="white"
        >
          <Icon as={MdRemove} />
        </Button>
        <Button
          onClick={() => resetTransform()}
          position="absolute"
          top="80px"
          left="10px"
          zIndex="1"
          border="none"
          p={2}
          _hover={{ bg: "rgba(0, 0, 0, 0.2)" }}
          size="sm"
          bgColor="gray.800"
          color="white"
        >
          <Icon as={MdRefresh} />
        </Button>
      </Flex>
    );
  });

  return (
    <Flex h="full">
      <TransformWrapper
        minScale={0.5}
        maxScale={10}
        wheel={{ step: 0.1 }}
        initialScale={1}
        centerOnInit={true}
      >
        <Box
          position="relative"
          w="100%"
          h="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          <Controls />
          <TransformComponent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              overflow="hidden"
            >
              <Image
                alt={name}
                src={url || ""}
                w="100vw"
                h="100vw"
                maxWidth="100vw"
                maxHeight="100vh"
                objectFit="contain"
              />
            </Box>
          </TransformComponent>
        </Box>
      </TransformWrapper>
    </Flex>
  );
};
