import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { PhoneInput } from "../inputs/PhoneInput";
import { REQUIRED_PHONE_ERROR } from "../smsNotificationSettings/PhoneVerificationFormSchema";
interface PhoneValue {
  formated: string;
  e164: string;
}

export interface PhoneInputControlProps {
  label?: string;
  name: string;
  value?: PhoneValue;
  placeholder?: string;
}

export const PhoneInputControl = ({
  name,
  value,
  placeholder,
  label,
}: PhoneInputControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField<PhoneValue | undefined>({
    name,
    value,
    placeholder,
  });

  const onChange = useCallback(
    (phoneValue?: PhoneValue) => {
      helper.setTouched(true);
      helper.setValue(phoneValue);
    },
    [helper]
  );

  return (
    <FormControl
      isInvalid={
        !!meta.error &&
        (meta.touched || submitCount > 0) &&
        meta.error.includes(REQUIRED_PHONE_ERROR)
      }
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <PhoneInput
        {...field}
        name={name}
        value={field.value}
        onChange={onChange}
        placeholder={"(555) 555-5555"}
      ></PhoneInput>
      {meta.error && meta.error.includes(REQUIRED_PHONE_ERROR) && (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      )}
    </FormControl>
  );
};
