import {
  ApiClient,
  CreateApiAttachment,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface CreateTransactionAttachmentThunkParams {
  apiClient: ApiClient;
  accountId: string;
  requestIdOrKey: string;
  transactionId: string;
  attachment: CreateApiAttachment;
}

export const createTransactionAttachment = createAsyncThunk(
  "request/createTransactionAttachment",
  async (params: CreateTransactionAttachmentThunkParams, thunkAPI) => {
    const { apiClient, accountId, requestIdOrKey, transactionId, attachment } =
      params;

    const result = await apiClient.createRequestTransactionAttachment(
      accountId,
      requestIdOrKey,
      transactionId,
      attachment
    );

    return { transactionId, attachment: result };
  }
);

export const createTransactionAttachmentHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(createTransactionAttachment.fulfilled, (state, action) => {
    const transaction = state.transactions.purchases.purchasesData.find(
      (x) => action.payload.transactionId === x.id
    );

    if (!transaction) return;

    transaction.attachmentCount += 1;
  });
};
