import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../../lotties/AnimationGrey.json";

interface LoadingAssetModalProps {
  text?: string;
}

export const AiProcessingAnimation: React.FC<LoadingAssetModalProps> = ({
  text = "Processing Image...",
}: LoadingAssetModalProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <Box h={500} w="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={40}
        mb={10}
      >
        <Lottie options={defaultOptions} height={100} width={100} />
      </Box>
      <Flex w="100%" justifyContent="center">
        <HStack p={3} borderRadius="4">
          <Text>{text}</Text>
        </HStack>
      </Flex>
    </Box>
  );
};

export default AiProcessingAnimation;
