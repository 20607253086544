import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
} from "@chakra-ui/react";

import { FC } from "react";

export interface ChangeModalSkeletonProps {
  isOpen: boolean;
  onCancel: () => void;
}

export const ChangeModalSkeleton: FC<ChangeModalSkeletonProps> = ({
  isOpen,
  onCancel,
}: ChangeModalSkeletonProps): JSX.Element | null => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent mt={0}>
        <ModalHeader>
          <Skeleton h={25} w="45%" />
        </ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <ModalBody overflowY="auto">
          <Divider mb={6} />
          <Stack spacing={3}>
            <Stack spacing={2}>
              <Skeleton height={5} w="30%" />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap={2}
                pt={2}
              >
                <Skeleton height={3} w="30%" />
                <Skeleton height={3} w="30%" />
              </Box>
              <Skeleton height={5} w="100%" />
              <Skeleton h={65} w="100%" />
            </Stack>
          </Stack>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="space-between"
            w="100%"
            mt={8}
          >
            <Skeleton height={6} w="15%" />
            <Skeleton height={6} w="15%" />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
