import {
  ApiAccount,
  ApiClient,
  ApiEventGroup,
  ApiRentableEquipment,
  ApiService,
  ApiUser,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { EventListSlice } from "../event-list.slice";

export interface InitFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  selectedValues: string[];
}
export interface LoadFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
  currentUser?: ApiUser;
  filterByManager?: boolean;
}
export const initVenueFilter = createAsyncThunk(
  "event-list/init-venue-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findVenues(account.id, options);
    return data.data;
  }
);
export const loadVenueFilter = createAsyncThunk(
  "event-list/load-venue-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
    };
    const data = await apiClient.findVenues(account.id, options);
    return data.data;
  }
);
export const initServiceFilter = createAsyncThunk(
  "event-list/init-service-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findServices(account.id, options);
    return data.data;
  }
);
export const loadServiceFilter = createAsyncThunk(
  "event-list/load-service-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
    };
    const data = await apiClient.findServices(account.id, options);
    return data.data;
  }
);

export const initEquipmentFilter = createAsyncThunk(
  "event-list/init-equipment-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findRentableEquipment(account.id, options);
    return data.data;
  }
);
export const loadEquipmentFilter = createAsyncThunk(
  "event-list/load-equipment-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
    };
    const data = await apiClient.findRentableEquipment(account.id, options);
    return data.data;
  }
);
export const initEventGroupFilter = createAsyncThunk(
  "event-list/init-eventGroup-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findEventGroups(account.id, options);
    return data.data;
  }
);
export const loadEventGroupFilter = createAsyncThunk(
  "event-list/load-eventGroup-filter",
  async ({
    apiClient,
    account,
    search,
    currentUser,
  }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
      users: currentUser?.id,
    };
    const data = await apiClient.findEventGroups(account.id, options);
    return data.data;
  }
);
export const filterEventHandlers = (
  builder: ActionReducerMapBuilder<EventListSlice>
) => {
  builder.addCase(initVenueFilter.fulfilled, (state, action) => {
    state.venues = action.payload;
  });
  builder.addCase(loadVenueFilter.fulfilled, (state, action) => {
    const selected = state.filters.venues;
    const itemsToAdd = selected;
    let uniqueMap: Record<string, ApiVenueSummary> = {};
    uniqueMap = itemsToAdd.reduce<Record<string, ApiVenueSummary>>(
      (result, id) => {
        const hydrated = state.venues.find((Venue) => Venue.id === id);
        if (hydrated) result[id] = hydrated as ApiVenueSummary;
        return result;
      },
      {}
    );

    uniqueMap = action.payload.reduce<Record<string, ApiVenueSummary>>(
      (result, item) => {
        result[item.id] = item;
        return result;
      },
      uniqueMap
    );

    state.venues = Object.values(uniqueMap);
  });
  builder.addCase(initServiceFilter.fulfilled, (state, action) => {
    state.services = action.payload;
  });
  builder.addCase(loadServiceFilter.fulfilled, (state, action) => {
    const selected = state.filters.services;
    const itemsToAdd = selected;
    let uniqueMap: Record<string, ApiService> = {};
    uniqueMap = itemsToAdd.reduce<Record<string, ApiService>>((result, id) => {
      const hydrated = state.services.find((serv) => serv.id === id);
      if (hydrated) result[id] = hydrated as ApiService;
      return result;
    }, {});

    uniqueMap = action.payload.reduce<Record<string, ApiService>>(
      (result, item) => {
        result[item.id] = item;
        return result;
      },
      uniqueMap
    );

    state.services = Object.values(uniqueMap);
  });
  builder.addCase(initEquipmentFilter.fulfilled, (state, action) => {
    state.equipments = action.payload;
  });
  builder.addCase(loadEquipmentFilter.fulfilled, (state, action) => {
    const selected = state.filters.equipments;
    const itemsToAdd = selected;
    let uniqueMap: Record<string, ApiRentableEquipment> = {};
    uniqueMap = itemsToAdd.reduce<Record<string, ApiRentableEquipment>>(
      (result, id) => {
        const hydrated = state.equipments.find((eq) => eq.id === id);
        if (hydrated) result[id] = hydrated as ApiRentableEquipment;
        return result;
      },
      {}
    );

    uniqueMap = action.payload.reduce<Record<string, ApiRentableEquipment>>(
      (result, item) => {
        result[item.id] = item;
        return result;
      },
      uniqueMap
    );

    state.equipments = Object.values(uniqueMap);
  });
  builder.addCase(initEventGroupFilter.fulfilled, (state, action) => {
    state.eventGroups = action.payload;
  });
  builder.addCase(loadEventGroupFilter.fulfilled, (state, action) => {
    const selected = state.filters.eventGroups;
    const itemsToAdd = selected;
    let uniqueMap: Record<string, ApiEventGroup> = {};
    uniqueMap = itemsToAdd.reduce<Record<string, ApiEventGroup>>(
      (result, id) => {
        const hydrated = state.eventGroups.find((Venue) => Venue.id === id);
        if (hydrated) result[id] = hydrated as ApiEventGroup;
        return result;
      },
      {}
    );

    uniqueMap = action.payload.reduce<Record<string, ApiEventGroup>>(
      (result, item) => {
        result[item.id] = item;
        return result;
      },
      uniqueMap
    );

    state.eventGroups = Object.values(uniqueMap);
  });
};
