import { GridItem, GridItemProps } from "@chakra-ui/layout";
import { Divider } from "@chakra-ui/react";
import React, { forwardRef, ReactNode } from "react";

interface FormSectionProps {
  children: ReactNode;
  isVisible: boolean;
  gridProps?: GridItemProps;
  name: string;
}
export const FormSection = forwardRef<HTMLDivElement, FormSectionProps>(
  ({ children, isVisible, gridProps, name }, ref) => {
    return (
      <>
        {isVisible ? (
          <>
            <GridItem colSpan={[12, null, null, 12]} pt={4}>
              <Divider />
            </GridItem>
            <GridItem
              {...gridProps}
              colSpan={[12, null, null, 12]}
              pt={4}
              ref={ref}
            >
              {children}
            </GridItem>
          </>
        ) : null}
      </>
    );
  }
);
