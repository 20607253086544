import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React, { FC, useCallback } from "react";
import HoursSpinner from "../inputs/HoursSpinner";

export interface HoursFieldInputProps {
  name: string;
  label?: string;
  value: number;
  placeholder?: string;
  includeStepButtons?: boolean;
  includeQuickButtons?: boolean;
}

export const HoursFieldInputControl: FC<HoursFieldInputProps> = ({
  name,
  label,
  value,
  placeholder,
  includeStepButtons,
  includeQuickButtons,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({
    name,
    value: value ? value : 0,
    placeholder,
  });

  const handleOnChange = useCallback(
    (value: number) => {
      helper.setTouched(true);
      helper.setValue(value);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <HoursSpinner
        {...field}
        onBlur={() => {}}
        includeStepButtons={includeStepButtons}
        placeholder={placeholder || "Ex. 2h 15m"}
        onChange={handleOnChange}
        includeQuickButtons={includeQuickButtons}
      />
      <FormErrorMessage>{meta.error ? meta.error : ""}</FormErrorMessage>
    </FormControl>
  );
};
