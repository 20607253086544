import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ApiLaborTransaction,
  ApiPurchaseTransaction,
  ApiTransactionType,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { useThunkDispatch } from "../../../store";
import {
  setTotalHoursTransactionChange,
  setTotalPurchaseTransactionChange,
} from "../../../store/request-form/request-form.slice";
import { removeTransaction } from "../../../store/request-form/thunks/removeTransaction.thunk";
import { deleteTransactions } from "../../../store/transaction-list.slice";

export interface RemoveTransactionProps {
  transaction: ApiLaborTransaction | ApiPurchaseTransaction | null;
  isOpen: boolean;
  onClose: () => void;
}
export const RemoveTransactionModal: React.FC<RemoveTransactionProps> = ({
  transaction,
  isOpen,
  onClose,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useToast();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const handleRemoveLabor = async () => {
    if (!transaction) {
      return;
    }
    try {
      thunkDispatch(
        removeTransaction({
          apiClient,
          account: currentAccount,
          idOrKey: transaction.requestKey,
          transactionId: transaction.id,
          transactionType: transaction.type,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (transaction.type === ApiTransactionType.labor) {
            dispatch(setTotalHoursTransactionChange(-transaction.hours));
          }
          if (transaction.type === ApiTransactionType.purchase) {
            dispatch(setTotalPurchaseTransactionChange(-transaction.total));
          }
          dispatch(deleteTransactions(response.transactionId));
        });
    } catch (error) {
      toast({
        position: "top",
        duration: 3000,
        isClosable: true,
        status: "error",
        title: "Error deleting transaction",
      });
    }
    onClose();
  };

  return (
    <>
      {transaction !== null && (
        <Modal isOpen={isOpen} onClose={onClose} closeOnEsc closeOnOverlayClick>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Remove Transaction</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text as="span">
                {transaction.type === ApiTransactionType.purchase
                  ? `Remove purchase from ${transaction.vendor?.name} for ${transaction.total}?`
                  : null}
                {transaction.type === ApiTransactionType.labor
                  ? `Remove labor for ${transaction.laborer.firstName} ${transaction.laborer.lastName}?`
                  : null}
              </Text>
            </ModalBody>
            <ModalFooter justifyContent="space-between" mt={4}>
              <Button
                variant="outline"
                colorScheme="blue"
                size="sm"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                size="sm"
                onClick={handleRemoveLabor}
              >
                Remove
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
