import {
  ApiClient,
  ApiInvoice,
  FindInvoicesOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface LoadEventGroupsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: FindInvoicesOptions;
}

export const loadInvoices = createAsyncThunk(
  "invoices",
  async (params: LoadEventGroupsThunkParams, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState).invoicesList;
    const newFilters = params.filters
      ? { ...filters, ...params.filters }
      : filters;
    const { apiClient, accountId } = params;

    const response = await apiClient.findInvoices(accountId, newFilters);
    return response;
  }
);

export interface InvoicesListState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: ApiInvoice[];
  total: number;
  filters: FindInvoicesOptions;
}

const DEFAULT_INVOICES_FILTERS: FindInvoicesOptions = {
  page: 1,
  pageSize: 20,
  direction: "desc",
};

export const invoicesList = createSlice({
  name: "invoicesList",
  initialState: {
    loading: "idle",
    data: [],
    total: 1,
    filters: { ...DEFAULT_INVOICES_FILTERS },
  } as InvoicesListState,
  reducers: {
    unloadInvoices: (state: InvoicesListState) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_INVOICES_FILTERS;
    },
    updateFilters: (
      state: InvoicesListState,
      action: PayloadAction<FindInvoicesOptions>
    ) => {
      if (
        (!action.payload.page || action.payload.search) &&
        state.filters.page !== 1
      ) {
        state.filters = {
          ...state.filters,
          ...action.payload,
          ...{ page: 1 },
        };
        return;
      }
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    updateInvoice: (
      state: InvoicesListState,
      action: PayloadAction<{ value: Partial<ApiInvoice>; invoiceId: string }>
    ) => {
      const { value, invoiceId } = action.payload;
      const index = state.data.findIndex((invoice) => invoice.id === invoiceId);
      if (index !== -1) {
        const invoiceCopy = { ...state.data[index], ...value };
        state.data[index] = invoiceCopy;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadInvoices.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.loading = "succeeded";
      state.filters.page = options.page;
      state.data = data;
      state.total = total;
    });
    builder.addCase(loadInvoices.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadInvoices.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const { unloadInvoices, updateFilters, updateInvoice } =
  invoicesList.actions;

export default invoicesList.reducer;
