import { Text } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { format, formatDistanceStrict } from "date-fns";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getDateFromISOString } from "../../../utils/timezone";

interface CityStateTextProps {
  city?: string | null;
  state?: string | null;
}

export const CityStateText: FC<CityStateTextProps> = ({ city, state }) => {
  const { states } = useSelector((state: RootState) => state.localCache);
  return (
    <Text>
      <Text as="span" fontStyle={city ? "normal" : "italic"}>
        {city ? `${city}, ` : "no city assigned, "}
      </Text>
      <Text as="span" fontStyle={state ? "normal" : "italic"}>
        {state
          ? `${states[state] ? states[state].isoCode : state}`
          : " no state assigned"}
      </Text>
    </Text>
  );
};

interface InsuranceExpTextProps {
  insuranceExpiration: string | null;
}

export const InsuranceExpirationText: FC<InsuranceExpTextProps> = ({
  insuranceExpiration,
}) => {
  const insuranceExpFormat = "MMM-dd-yyy";

  return (
    <Text color="gray.600">
      {insuranceExpiration ? (
        `Insurance expires ${format(
          new Date(insuranceExpiration),
          insuranceExpFormat
        )}`
      ) : (
        <Text as="i">No expiration date</Text>
      )}
    </Text>
  );
};

interface GroupOwnerProps {
  owner: ApiUserSummary;
}

export const GroupOwner: FC<GroupOwnerProps> = ({ owner }) => {
  return (
    <Text fontWeight="bold">
      {`${owner.firstName} ${owner.lastName} `}
      <Text as="span" color="gray.600" fontWeight="400" isTruncated>
        - Group Owner
      </Text>
    </Text>
  );
};

interface LastActiveProps {
  lastActive?: string | null;
}

export const LastActive: FC<LastActiveProps> = ({ lastActive }) => {
  return lastActive ? (
    <Text>
      {`Last active
  ${formatDistanceStrict(getDateFromISOString(lastActive), new Date())} ago`}
    </Text>
  ) : (
    <Text as="i">No updates</Text>
  );
};
