import {
  Box,
  Button,
  Divider,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiLaborTransaction,
  ApiRequestTaskBookDetail,
  ApiTaskBookType,
  ApiTransactionType,
  LaborType,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { StepIcon } from "../../../../components/custom-icons/StepIcon";
import { RootState, useThunkDispatch } from "../../../../store";
import { setIsAutomaticTransition } from "../../../../store/request-form/request-form.slice";
import { createUpdateRequestComment } from "../../../../store/request-form/thunks/createEditCommentThunk";
import { getVisibleFields } from "../../../../utils/getVisibleFields";
import { TransactionLaborerWithGroups } from "../../../request-form/transactions/labors/LaborTransactionForm";
import { CommentForm } from "../../comments/CommentForm";
import { LaborTransactionForm } from "../../transactions/labors/LaborTransactionForm";
import { RATING_SCALE } from "../TaskbooksData";

interface TaskbookModalProps {
  onClose: () => void;
  workingTaskbook: ApiRequestTaskBookDetail;
  requestId: string;
  taskbookId?: string | null;
}

export const UpdateTaskbookModal: FC<TaskbookModalProps> = ({
  workingTaskbook,
  onClose,
  requestId,
  taskbookId,
}) => {
  const { key, requestTaskbookId } = useParams<{
    key?: string;
    requestTaskbookId?: string;
  }>();

  const [comment, setComment] = useState<ApiComment>();
  const [transaction, setTransaction] =
    useState<TransactionLaborerWithGroups>();
  const [completeAllTasks, setCompleteAllTasks] = useState(false);

  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const { visibleFields } = useSelector(
    (state: RootState) => state.requestForm,
  );

  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const taskbookIdParam = taskbookId ? taskbookId : requestTaskbookId;

  const { showComments, showLabor } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const verifyAllTaskStatus = useCallback(() => {
    if (workingTaskbook.tasks) {
      let isTaskBookCompleted = false;

      const hasUnmarkedTasks = workingTaskbook.tasks.some(
        (task) => task.status === null,
      );
      const hasIncompletedTasks = workingTaskbook.tasks.some(
        (task) => task.status === "incomplete",
      );

      if (workingTaskbook.taskbook.type === ApiTaskBookType.passfail) {
        isTaskBookCompleted = hasUnmarkedTasks ? false : true;
      } else {
        isTaskBookCompleted = !hasIncompletedTasks && !hasIncompletedTasks;
      }
      setCompleteAllTasks(isTaskBookCompleted);
    }
  }, [workingTaskbook.taskbook.type, workingTaskbook.tasks]);

  const handleLaborTransactionChange = useCallback(
    (transaction: ApiLaborTransaction) => {
      setTransaction(transaction);
    },
    [],
  );

  const saveComment = useCallback(
    (workingComment: ApiComment, isPublic: boolean) => {
      thunkDispatch(
        createUpdateRequestComment({
          apiClient,
          accountId: currentAccount.id,
          requestIdOrKey: requestId,
          commentId: workingComment.id,
          isPublic,
          commentText: workingComment.comment,
        }),
      );
    },
    [apiClient, currentAccount.id, requestId, thunkDispatch],
  );

  const updateTaskbookStatus = useCallback(async () => {
    const status = completeAllTasks ? "complete" : "incomplete";

    if (key && taskbookIdParam) {
      try {
        comment &&
          (await apiClient.createRequestComment(
            currentAccount.id,
            key,
            comment,
          ));

        if (
          transaction &&
          transaction.laborer.isGroup &&
          transaction.laborer.groupMembers
        ) {
          Promise.all(
            transaction.laborer.groupMembers.map((member) => {
              return apiClient.createRequestTransaction(
                currentAccount.id,
                key,
                {
                  type: transaction.type,
                  hours: transaction.hours,
                  hourlyRate: transaction.hourlyRate,
                  budget: transaction.budget,
                  laborer: member.id,
                  laborType: transaction.laborType,
                  datePerformed: transaction.datePerformed,
                },
              );
            }),
          );
        } else if (transaction) {
          await apiClient.createRequestTransaction(currentAccount.id, key, {
            type: transaction.type,
            hours: transaction.hours,
            hourlyRate: transaction.hourlyRate,
            budget: transaction.budget,
            laborer: transaction.laborer.id,
            laborType: transaction.laborType,
            datePerformed: transaction.datePerformed,
          });
        }

        await apiClient.updateRequestTaskbook(
          currentAccount.id,
          key,
          taskbookIdParam,
          { status, completed: new Date().toISOString() },
        );

        if (location.pathname === `/requests/${key}`) {
          // This condition will trigger if the modal was opened in the request form
          // It will  first close the modal and then update the location status to trigger
          // the logic that updates the request status
          onClose();
          navigate(`/requests/${key}`, {
            state: { checkTaskbookComplete: true },
            replace: true,
          });
        } else {
          // This special state forces allows a useEffect hook in `TaskbooksData.tsx`
          // to execute the automatic transition when all the taskbooks are completed
          navigate(`/requests/${key}`, {
            state: { checkTaskbookComplete: true },
          });
        }
        dispatch(setIsAutomaticTransition(true));
      } catch (error) {
        toast({
          duration: 3000,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Error updating a request task book",
        });
      }
    }
  }, [
    key,
    toast,
    comment,
    apiClient,
    transaction,
    completeAllTasks,
    currentAccount,
    taskbookIdParam,
    navigate,
    location,
    onClose,
    dispatch,
  ]);

  const calculateScore = useCallback(() => {
    const totalScore = workingTaskbook.tasks
      .map((t) => t.score)
      .reduce((pre, cu) => pre + cu, 0);
    const averageScore = totalScore / workingTaskbook.tasks.length;
    const maxScore = workingTaskbook.tasks.length * RATING_SCALE;
    return { totalScore, averageScore, maxScore };
  }, [workingTaskbook.tasks]);

  useEffect(() => {
    const currentDate = new Date().toISOString();
    setTransaction({
      id: "",
      hours: 0.25,
      budget: null,
      attachmentCount: 0,
      laborer: currentUser,
      created: currentDate,
      updated: currentDate,
      createdBy: currentUser,
      updatedBy: currentUser,
      datePerformed: currentDate,
      laborType: LaborType.regular,
      type: ApiTransactionType.labor,
      total: 0,
      hourlyRate: 0,
      requestId: "",
      requestKey: "",
    });
  }, [setTransaction, currentUser]);

  useEffect(() => {
    verifyAllTaskStatus();
  }, [verifyAllTaskStatus]);

  return (
    <ModalContent>
      <ModalHeader display="flex">
        <Text fontWeight="bold">
          {key} {workingTaskbook.taskbook.name}
          <Text as="span" fontWeight={400}>
            {` has been marked `}
            <Text as="span" fontWeight={700}>
              {completeAllTasks ? "Finished" : "Did Not Finish"}
            </Text>
          </Text>
        </Text>
      </ModalHeader>
      <ModalBody>
        {workingTaskbook.tasks.map((item) => (
          <Box
            display="flex"
            my={3}
            color="blue.500"
            fontWeight={600}
            key={item.id}
          >
            <StepIcon
              type={item.status === null ? "number" : item.status}
              step={item.task.order}
            />
            <Text ml={1}>{item.task.name}</Text>
          </Box>
        ))}
        {workingTaskbook.taskbook.type === ApiTaskBookType.scoring && (
          <Text verticalAlign="sub" as="span">
            {`Scoring - Total Score: ${calculateScore().totalScore} of ${
              calculateScore().maxScore
            } and Average Score: ${calculateScore().averageScore}`}
          </Text>
        )}
        {transaction && showLabor && (
          <>
            <Divider my={4} />
            <Text fontSize="lg" fontWeight={700}>
              Add labor
            </Text>
            <LaborTransactionForm
              transaction={transaction}
              onChange={handleLaborTransactionChange}
              displayLeadGroups
            />
          </>
        )}
        {showComments && (
          <>
            <Divider my={4} />
            <Text fontSize="lg" fontWeight={700}>
              Add a comment
            </Text>
            <CommentForm
              comment={comment}
              autoFocusComment={false}
              setCurrentComment={setComment}
              commentFrom="request"
              onSave={saveComment}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter display="flex">
        <Box w="100%">
          <Button
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            float="right"
            onClick={updateTaskbookStatus}
          >
            Done
          </Button>
        </Box>
      </ModalFooter>
    </ModalContent>
  );
};
