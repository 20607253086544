import { Box, Checkbox, Image, useColorModeValue } from "@chakra-ui/react";
import { FC, useState } from "react";
import {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Swiper as SwiperType,
  Thumbs,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

interface TwoRowCarouselProps {
  images: string[];
  showCheckbox?: boolean;
  defaultImageUrl?: string;
  showSecondSwiperInMobile?: boolean;
  onClickCheckbox?: (url: string) => void;
}

export const TwoRowCarousel: FC<TwoRowCarouselProps> = ({
  images,
  showCheckbox,
  defaultImageUrl,
  onClickCheckbox,
  showSecondSwiperInMobile = true,
}) => {
  const thumbnailColor = useColorModeValue("gray.50", "whiteAlpha.400");
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();

  return (
    <>
      <Swiper
        spaceBetween={10}
        autoplay={{ delay: 3000 }}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs, Pagination, Autoplay]}
      >
        {images.map((src, idx) => (
          <SwiperSlide key={`mainSwiper::${src}::${idx}`}>
            <Box position="relative">
              <Box>
                <Image
                  mb={4}
                  w="100%"
                  h="300px"
                  src={src}
                  borderRadius={12}
                  objectFit="cover"
                  alt={`attachment:${idx}`}
                />
              </Box>
              {showCheckbox && (
                <Checkbox
                  m={2}
                  p={1}
                  bottom={0}
                  borderRadius={6}
                  position="absolute"
                  bgColor={thumbnailColor}
                  isChecked={defaultImageUrl === src}
                  onChange={() => onClickCheckbox && onClickCheckbox(src)}
                >
                  Set as thumbnail
                </Checkbox>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      {showSecondSwiperInMobile && images.length > 1 && (
        <Swiper
          freeMode={true}
          spaceBetween={20}
          slidesPerView={3}
          className="mini-swiper"
          watchSlidesProgress={true}
          onSwiper={setThumbsSwiper}
          modules={[FreeMode, Navigation, Thumbs, Autoplay, Pagination]}
        >
          {images.map((src, idx) => (
            <Box minW="100%" bgColor="red" key={`mini-swiper2::${src}::${idx}`}>
              <SwiperSlide>
                <Image
                  src={src}
                  h="80px"
                  cursor="pointer"
                  borderRadius={8}
                  alt={`attachment:${idx}`}
                />
              </SwiperSlide>
            </Box>
          ))}
        </Swiper>
      )}
    </>
  );
};
